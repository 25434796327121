import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';
import { Boundary } from '../hooks/useDemographicStore';
import { DemographicData } from './demographicSearch';

const env = parseEnv({ PLOTR_API: process.env.PLOTR_API });

const citylifeLocalBusinessCodes = {
  '4411%': 'Auto Dealers',
  '812112': 'Beauty Salons',
  '621310': 'Chiropractors',
  '2383%': 'Building Contractors',
  '2382%': 'Equipment Contractors',
  '2381%': 'Building Contractors',
  '2389%': 'Specialty Contractors',
  '621210': 'Dentists',
  '6211%': 'Physicians',
  '453110': 'Florists',
  '524210': 'Insurance Agencies',
  '5414%': 'Design Services',
  '448310': 'Jewelry Stores',
  '522310': 'Loan Brokers',
  '621399': 'Health Practitioners',
  '621320': 'Optometrists',
  '522110': 'Commercial Banking',
  '523930': 'Investment Advice',
  '622110': 'Hospitals',
  '531210': 'Real Estate Agents',
  '561730': 'Landscaping Services',
  '2361%': 'Residential Construction',
  '922110': 'Law Practices', // Naics code for "courts", for some reason. No results found for lawyers.
  '541940': 'Veterinary Services',
  '713940': 'Fitness Centers',
  '561720': 'Janitorial Services',
  '6233%': 'Assisted Living Facilities',
  '711310': 'Event Facilities',
};

export async function fetchLocalBusinessCounts(
  boundary: Boundary,
  accessToken: string
): Promise<DemographicData | null> {
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    };

    const response = await axios.post(
      `${env.PLOTR_API}/poi-place-search-boundary`,
      {
        industries: Object.keys(citylifeLocalBusinessCodes),
        returnData: false,
        boundary: boundary.geometry,
      },
      { headers }
    );

    const additionalData = response.data;

    return {
      name: 'Local Business Count',
      locked: false,
      fields: Object.entries(additionalData).map(([key, value]) => ({
        name: `${key.charAt(0).toUpperCase() + key.slice(1)}`,
        value: value as number,
        type: 'integer',
      })),
    };
  } catch (error) {
    console.error('Error fetching local business counts:', error);
    return null;
  }
}
