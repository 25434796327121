import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Box, Chip, TextField, Typography } from '@mui/material';
import {
  CustomPin,
  CustomTerritory,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { getDemographicEntity } from '../../context-menu/helpers/getDemographicEntity';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useMapContext from '../../dynamic-map/hooks/useMapContext';

interface PinTerritoriesAutocompleteProps {
  pin: CustomPin;
}

const PinTerritoriesAutocomplete: FC<PinTerritoriesAutocompleteProps> = ({
  pin,
}) => {
  const [territoriesInput, setTerritoriesInput] = useState('');

  const customTerritoriesMethods = plotrMultiplayerData.methods?.territories;
  const customTerritories = useCustomTerritories();

  const map = useMapContext();

  const pinTerritories = useMemo<CustomTerritory[]>(
    () =>
      pin
        ? customTerritories.filter((territory) =>
            territory.pins?.includes(pin.id)
          )
        : [],
    [customTerritories, pin]
  );

  const handleChangeTerritories = useCallback(
    (_e: SyntheticEvent, value: CustomTerritory[]) => {
      const addedAssociations = value.filter(
        (territory) => !pinTerritories.includes(territory)
      );
      const removedAssociations = pinTerritories.filter(
        (territory) => !value.includes(territory)
      );

      addedAssociations.forEach((newAssociation) =>
        customTerritoriesMethods?.setPins(newAssociation.id, [
          ...(newAssociation.pins ?? []),
          ...(pin ? [pin.id] : []),
        ])
      );

      removedAssociations.forEach((removedAssociation) =>
        customTerritoriesMethods?.setPins(
          removedAssociation.id,
          removedAssociation.pins?.filter((id) => id !== pin?.id) ?? []
        )
      );
    },
    [customTerritoriesMethods, pin, pinTerritories]
  );

  const recommendedTerritories = useMemo(() => {
    if (map && pin?.pos) {
      const convertedPos = map?.project(pin.pos);
      const features = map.queryRenderedFeatures(convertedPos, {});

      const territoriesUnderPin: CustomTerritory[] = [];
      features.forEach((curValue) => {
        const { territory } = getDemographicEntity(curValue, customTerritories);
        if (territory) territoriesUnderPin.push(territory);
      });

      return territoriesUnderPin.filter(
        (territory, index, self) =>
          !pinTerritories.includes(territory) &&
          self.findIndex((t) => t.id === territory.id) === index
      );
    }

    return [];
  }, [customTerritories, map, pin?.pos, pinTerritories]);

  return (
    <Box>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id="pin-territories"
        options={customTerritories}
        value={pinTerritories}
        onChange={handleChangeTerritories}
        inputValue={territoriesInput}
        onInputChange={(_e, value) => setTerritoriesInput(value)}
        getOptionLabel={(option) => option.label}
        getOptionKey={(option) => option.id}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Pin Territories"
            variant="outlined"
            fullWidth
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index: number) => {
            const label = option?.label ?? '';
            return (
              <Chip
                label={label}
                {...getTagProps({ index })}
                key={option?.id}
                color="secondary"
                variant="filled"
                size="small"
              />
            );
          })
        }
      />
      {recommendedTerritories && recommendedTerritories.length > 0 && (
        <Box
          display={'flex'}
          flexDirection={'row'}
          flexWrap={'wrap'}
          gap={1}
          mt={1}
        >
          <Typography variant="body1" component={'p'}>
            Recommended:{' '}
          </Typography>
          {recommendedTerritories.map((territory) => (
            <Chip
              key={territory.id}
              label={territory.label}
              color="secondary"
              size="small"
              variant="outlined"
              onDelete={() => {
                customTerritoriesMethods?.setPins(territory.id, [
                  ...(territory.pins ?? []),
                  ...(pin ? [pin.id] : []),
                ]);
              }}
              onClick={() =>
                customTerritoriesMethods?.setPins(territory.id, [
                  ...(territory.pins ?? []),
                  ...(pin ? [pin.id] : []),
                ])
              }
              deleteIcon={<AddIcon />}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PinTerritoriesAutocomplete;
