import { useEffect } from 'react';

import PopupWithoutWheel from '~/src/features/dynamic-map/components/PopupWithoutWheel';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import useContextMenuStore from './useContextMenuStore';

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  styled,
} from '@mui/material';

const StyledPopup = styled(PopupWithoutWheel)`
  .mapboxgl-popup-content {
    border-radius: 0.5rem;
  }
`;

const useContextMenuRemoval = () => {
  const map = useMapContext();
  const setContextMenu = useContextMenuStore((state) => state.setContextMenu);

  // remove position when map is left clicked or esc is pressed
  useEffect(() => {
    if (map == null) return;

    const handleClose = () => {
      setContextMenu({ position: null });
    };

    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    map.on('click', handleClose);
    window.addEventListener('keydown', handleEsc);

    return () => {
      map.off('click', handleClose);
      window.removeEventListener('keydown', handleEsc);
    };
  }, [map, setContextMenu]);
};

export default function ContextMenu() {
  useContextMenuRemoval();

  const { options, position, offset, setContextMenu } = useContextMenuStore();
  const shouldRender = position != null && options.length > 0;

  return shouldRender ? (
    <StyledPopup
      latitude={position.lat}
      longitude={position.lng}
      closeButton={false}
      closeOnClick={false}
      offset={offset}
    >
      <MenuList disablePadding>
        {options.map((item) => (
          <MenuItem
            selected={true}
            key={item.label}
            onClick={() => {
              item.onClick();
              if (!item?.remainOpenOnClick) {
                setContextMenu({ position: null });
              }
            }}
            style={{
              whiteSpace: 'wrap',
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
            }}
          >
            {item.icon && (
              <ListItemIcon style={{ marginRight: '0.5rem', minWidth: 'auto' }}>
                {item.icon}
              </ListItemIcon>
            )}
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </StyledPopup>
  ) : null;
}
