import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { create } from 'zustand';
import * as PricingDataService from '../services/pricingDataService.js';

export interface PricingData {
  brand: string;
  data: FeatureCollection<Geometry, GeoJsonProperties>;
  color: string;
}

export interface PricingDataState {
  pricingData: PricingData[];
  hiddenBrands: string[];
  error: Error | null;
  isLoading: boolean;
}

export interface PricingDataActions {
  fetchPricingData: () => Promise<void>;
  toggleBrandVisibility: (brand: string) => void;
  toggleColor: (brand: string, color: string) => void;
}

const getColorForBrand = (index: number): string => {
  const colors = [
    '#E63946',
    '#F4A261',
    '#2A9D8F',
    '#264653',
    '#E9C46A',
    '#F77F00',
    '#003049',
    '#8338EC',
    '#06D6A0',
    '#FFD166',
  ];
  return colors[index % colors.length];
};

const usePricingDataStore = create<PricingDataState & PricingDataActions>(
  (set, get) => ({
    pricingData: [],
    hiddenBrands: [],
    error: null,
    isLoading: false,
    async fetchPricingData() {
      set({ isLoading: true, error: null });
      try {
        const dataCollections = await PricingDataService.fetchAllPricingData();
        const pricingData = dataCollections.map((data, index) => ({
          brand: data?.features[0].properties?.brand || `Brand ${index + 1}`,
          data,
          color: getColorForBrand(index), // Assign a color using the helper function
        }));

        // Initialize all brands as hidden
        const newHiddenBrands = pricingData.map((data) => data.brand);

        set({ pricingData, hiddenBrands: newHiddenBrands, isLoading: false });
      } catch (error) {
        const isError = error instanceof Error;
        set({
          error: isError ? error : new Error('An unknown error occurred'),
          isLoading: false,
        });
      }
    },
    toggleColor: (brand: string, color: string) => {
      set((state) => ({
        ...state,
        pricingData: state.pricingData.map((data) =>
          data.brand === brand ? { ...data, color } : data
        ),
      }));
    },
    toggleBrandVisibility: (brand) => {
      set((state) => {
        const isCurrentlyHidden = state.hiddenBrands.includes(brand);
        const newHiddenBrands = isCurrentlyHidden
          ? state.hiddenBrands.filter((b) => b !== brand)
          : [...state.hiddenBrands, brand];
        return { hiddenBrands: newHiddenBrands };
      });
    },
  })
);

export default usePricingDataStore;
