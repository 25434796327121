import { z } from 'zod';

const CreateTerritorySchema = z.object({
  Name: z
    .string({ message: 'Name is required field' })
    .min(1, { message: 'Name is required field' }),
  Group: z
    .string({ message: 'Group is required field' })
    .trim()
    .min(1, { message: 'Group is required field' }),
  AssignToPin: z.boolean(),
});
type CreateTerritorySchemaType = z.infer<typeof CreateTerritorySchema>;

export { CreateTerritorySchema as default, CreateTerritorySchemaType };
