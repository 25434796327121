import { Grid, Typography } from '@mui/material';
import StatDisplay from '../StatDisplay';
import { MobileDataConfig } from '~/src/constants';
import BlurTextLock from '../BlurTextLock';
import RankingProgressBar from './RankingProgressBar';
import { calculatePercentile } from '../MobileDataUtils';
import { HubspotModalType } from '../../HubspotModal';

interface MarketRankDisplayProps {
  marketRanking?: number;
  totalNumberOfMarketRankedBrandStores?: number;
  hasPermission: boolean | null;
  getCBSAName: () => string;
  contextString?: HubspotModalType;
}

function MarketRankDisplay({
  marketRanking,
  totalNumberOfMarketRankedBrandStores,
  hasPermission,
  getCBSAName,
  contextString = HubspotModalType.Sonar,
}: MarketRankDisplayProps) {
  const {
    MARKET_RANKING,
    MARKET_RANKING_DESCRIPTION,
    MARKET_RANKING_PERCENTILE,
    MOBILE_STAT_FONT_SIZE,
    MOBILE_TOTAL_RANKING_NUMBER_FONT_SIZE,
  } = MobileDataConfig;

  return (
    <>
      <Grid item xs={12}>
        <StatDisplay
          label={MARKET_RANKING}
          value={
            marketRanking && totalNumberOfMarketRankedBrandStores ? (
              <>
                <Typography
                  component="span"
                  fontWeight="bold"
                  fontSize={MOBILE_STAT_FONT_SIZE}
                  mr={1}
                >
                  {hasPermission ? (
                    marketRanking
                  ) : (
                    <BlurTextLock intensity={7} contextString={contextString}>
                      4/1000
                    </BlurTextLock>
                  )}
                </Typography>
                {' / '}
                <Typography
                  component="span"
                  fontSize={MOBILE_TOTAL_RANKING_NUMBER_FONT_SIZE}
                  ml={2}
                >
                  {hasPermission && totalNumberOfMarketRankedBrandStores}
                </Typography>
              </>
            ) : (
              <BlurTextLock intensity={7} contextString={contextString}>
                4/1000
              </BlurTextLock>
            )
          }
          description={<>{MARKET_RANKING_DESCRIPTION(getCBSAName())}</>}
        />
      </Grid>
      {marketRanking &&
        totalNumberOfMarketRankedBrandStores &&
        hasPermission && (
          <Grid item xs={12}>
            <RankingProgressBar
              value={parseInt(
                calculatePercentile(
                  marketRanking,
                  totalNumberOfMarketRankedBrandStores
                )
              )}
              title={MARKET_RANKING_PERCENTILE}
            />
          </Grid>
        )}
    </>
  );
}

export default MarketRankDisplay;
