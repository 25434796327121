import { Box, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { endOfMonth, endOfYear, format, startOfMonth } from 'date-fns';
import startOfYear from 'date-fns/startOfYear';
import subMonths from 'date-fns/subMonths';
import React, { useEffect, useMemo } from 'react';
import { MobileDataConfig } from '~/src/constants';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import {
  FetchTradeAreaOverlapDataParams,
  fetchTradeAreaOverlapDataFromBackend,
} from '~/src/features/dynamic-map/services/poiService';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import useDemographicStore from '../../../demographic-point-lookup/hooks/useDemographicStore';
import RelativeDateButton from '../MobileData/RelativeDateButton';
import { convertToLocalDate } from '../MobileData/MobileDataUtils';
import MobileTradeAreaOverlapData from './MobileTradeAreaOverlapData';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import { filterSurroundingPins } from '../../helpers/filterSurroundingPins';
import axios, { AxiosError } from 'axios';
import { generateTradeAreaOverlapCacheKey } from '~/src/features/dynamic-map/helpers/generateTradeAreaOverlapCacheKey';
import { MapColorLegend } from './MapColorLegend';
import {
  OVERLAP_COLORS,
  NON_OVERLAP_COLOR,
} from './TradeAreaOverlapHeatmapSource';
import useDateRangePicker from '../../hooks/useDateRangePicker';
import useMobileOverlapStore from '~/src/features/demographic-point-lookup/hooks/useMobileOverlapStore';

const { MOBILE_ERROR, INITIAL_START_DATE, INITIAL_END_DATE } = MobileDataConfig;

const MobileTradeAreaOverlapContainer: React.FC = () => {
  const { accessToken } = useAccessToken();

  const { evaluatedPinId } = useDynamicMapStore((state) => ({
    evaluatedPinId: state.evaluatedPinId,
  }));
  const customPins = useCustomPins();

  const evaluatedPin = evaluatedPinId
    ? customPins.find((pin) => pin.id === evaluatedPinId)
    : null;

  const surroundingPins = useMemo(
    () =>
      evaluatedPin
        ? filterSurroundingPins({
            evaluatedPin,
            surroundingPins: customPins.filter(
              (pin) => pin.id !== evaluatedPin.id
            ),
            unit: 'miles',
            maxDistance: 50,
          })
        : [],
    [evaluatedPin, customPins]
  );

  const {
    tradeAreaOverlapData,
    setTradeAreaOverlapData,
    setTradeAreaOverlapDataError,
    tradeAreaOverlapDataIsLoading,
    setTradeAreaOverlapDataIsLoading,
    showTradeAreaOverlapHeatmap,
    setShowTradeAreaOverlapHeatmap,
    showTradeAreaOverlapHeatmapData,
    setShowTradeAreaOverlapHeatmapData,
    tradeAreaOverlapDataCache,
    setTradeAreaOverlapDataCache,
  } = useDemographicStore((state) => ({
    tradeAreaOverlapData: state.tradeAreaOverlapData,
    setTradeAreaOverlapData: state.setTradeAreaOverlapData,
    setTradeAreaOverlapDataError: state.setTradeAreaOverlapDataError,
    tradeAreaOverlapDataIsLoading: state.tradeAreaOverlapDataIsLoading,
    setTradeAreaOverlapDataIsLoading: state.setTradeAreaOverlapDataIsLoading,
    showTradeAreaOverlapHeatmap: state.showTradeAreaOverlapHeatmap,
    setShowTradeAreaOverlapHeatmap: state.setShowTradeAreaOverlapHeatmap,
    showTradeAreaOverlapHeatmapData: state.showTradeAreaOverlapHeatmapData,
    setShowTradeAreaOverlapHeatmapData:
      state.setShowTradeAreaOverlapHeatmapData,
    tradeAreaOverlapDataCache: state.tradeAreaOverlapDataCache,
    setTradeAreaOverlapDataCache: state.setTradeAreaOverlapDataCache,
  }));

  const { setMobileOverlapData, setMobileOverlapDataIsLoading } =
    useMobileOverlapStore((state) => ({
      setMobileOverlapData: state.setMobileOverlapData,
      setMobileOverlapDataIsLoading: state.setMobileOverlapDataIsLoading,
    }));

  const {
    startDate,
    endDate,
    handleStartDateChange: hookHandleStartDateChange,
    handleEndDateChange: hookHandleEndDateChange,
    minDate,
    maxDate,
  } = useDateRangePicker(tradeAreaOverlapData);

  // Override the hook's handleStartDateChange to clear selectedRange
  const handleStartDateChange = (newDate: Date | null) => {
    hookHandleStartDateChange(newDate); // Call the hook's original handler
  };

  // Override the hook's handleEndDateChange to clear selectedRange
  const handleEndDateChange = (newDate: Date | null) => {
    hookHandleEndDateChange(newDate); // Call the hook's original handler
  };

  const fetchReportTradeAreaOverlapData = async (
    abortController: AbortController
  ) => {
    if (!accessToken || !evaluatedPin) return;

    setMobileOverlapDataIsLoading(true);

    const params: FetchTradeAreaOverlapDataParams = {
      evaluatedPin: {
        id: evaluatedPin.id,
        lat: evaluatedPin.pos.lat,
        lng: evaluatedPin.pos.lng,
      },
      surroundingPins: surroundingPins.map((pin) => ({
        id: pin.id,
        lat: pin.pos.lat,
        lng: pin.pos.lng,
      })),
      startDate: format(startOfMonth(subMonths(new Date(), 6)), 'yyyy-MM-dd'),
      endDate: format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
    };

    const cacheKey = generateTradeAreaOverlapCacheKey(params);
    const cachedData = tradeAreaOverlapDataCache[cacheKey];

    try {
      if (cachedData) {
        setMobileOverlapData(cachedData);
        return;
      }

      const data = await fetchTradeAreaOverlapDataFromBackend(
        params,
        abortController.signal
      );

      if (data != null && typeof data !== 'string') {
        setMobileOverlapData(data);
        setTradeAreaOverlapDataCache(cacheKey, data);
      }
    } catch (err) {
      console.error('Error fetching mobile overlap data:', err);
    } finally {
      if (!abortController.signal.aborted) {
        setMobileOverlapDataIsLoading(false);
      }
    }
  };

  const fetchTradeAreaOverlapData = async (
    abortController: AbortController
  ) => {
    if (!accessToken || !evaluatedPin) return;

    setTradeAreaOverlapDataIsLoading(true);
    setTradeAreaOverlapDataError(null);

    const params: FetchTradeAreaOverlapDataParams = {
      evaluatedPin: {
        id: evaluatedPin.id,
        lat: evaluatedPin.pos.lat,
        lng: evaluatedPin.pos.lng,
      },
      surroundingPins: surroundingPins.map((pin) => ({
        id: pin.id,
        lat: pin.pos.lat,
        lng: pin.pos.lng,
      })),
      startDate: startDate || INITIAL_START_DATE,
      endDate: endDate || INITIAL_END_DATE,
    };

    const cacheKey = generateTradeAreaOverlapCacheKey(params);
    const cachedData = tradeAreaOverlapDataCache[cacheKey];

    try {
      if (cachedData) {
        setTradeAreaOverlapData(cachedData);
        setTradeAreaOverlapDataError(null);
        return;
      }

      const data = await fetchTradeAreaOverlapDataFromBackend(
        params,
        abortController.signal
      );

      if (data != null && typeof data !== 'string') {
        setTradeAreaOverlapData(data);
        setTradeAreaOverlapDataCache(cacheKey, data);
      } else {
        setTradeAreaOverlapData(null);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }

      setTradeAreaOverlapData(null);
      if (err instanceof AxiosError) {
        setTradeAreaOverlapDataError(
          err.response?.data?.message || MOBILE_ERROR
        );
        return;
      }

      setTradeAreaOverlapDataError(MOBILE_ERROR);
    } finally {
      if (!abortController.signal.aborted) {
        setTradeAreaOverlapDataIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    fetchTradeAreaOverlapData(abortController);

    return () => {
      abortController.abort();
    };
  }, [accessToken, evaluatedPinId, startDate, endDate, surroundingPins]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchReportTradeAreaOverlapData(abortController);

    return () => {
      abortController.abort();
    };
  }, [accessToken, evaluatedPinId, surroundingPins]);

  // Function to handle relative date changes from buttons
  const handleRelativeDateChange = (start: Date, end: Date) => {
    handleStartDateChange(start);
    handleEndDateChange(end);
  };

  // Relative date buttons
  const relativeDateButtons = (
    <Grid container spacing={0.7} justifyContent="center">
      <RelativeDateButton
        label="Last Month"
        startDate={startOfMonth(subMonths(new Date(), 1))}
        endDate={endOfMonth(subMonths(new Date(), 1))}
        onClick={() =>
          handleRelativeDateChange(
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1))
          )
        }
        isActive={
          startDate ===
            format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd') &&
          endDate === format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
      />
      <RelativeDateButton
        label="Last 3 Months"
        startDate={startOfMonth(subMonths(new Date(), 3))}
        endDate={endOfMonth(subMonths(new Date(), 1))}
        onClick={() =>
          handleRelativeDateChange(
            startOfMonth(subMonths(new Date(), 3)),
            endOfMonth(subMonths(new Date(), 1))
          )
        }
        isActive={
          startDate ===
            format(startOfMonth(subMonths(new Date(), 3)), 'yyyy-MM-dd') &&
          endDate === format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
      />
      <RelativeDateButton
        label="Last 6 Months"
        startDate={startOfMonth(subMonths(new Date(), 6))}
        endDate={endOfMonth(subMonths(new Date(), 1))}
        onClick={() =>
          handleRelativeDateChange(
            startOfMonth(subMonths(new Date(), 6)),
            endOfMonth(subMonths(new Date(), 1))
          )
        }
        isActive={
          startDate ===
            format(startOfMonth(subMonths(new Date(), 6)), 'yyyy-MM-dd') &&
          endDate === format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
      />
      <RelativeDateButton
        label="Last 9 Months"
        startDate={startOfMonth(subMonths(new Date(), 9))} // First day, nine months ago
        endDate={endOfMonth(subMonths(new Date(), 1))} // Last day of the previous month
        onClick={() =>
          handleRelativeDateChange(
            startOfMonth(subMonths(new Date(), 9)),
            endOfMonth(subMonths(new Date(), 1))
          )
        }
        isActive={
          startDate ===
            format(startOfMonth(subMonths(new Date(), 9)), 'yyyy-MM-dd') &&
          endDate === format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
      />
      <RelativeDateButton
        label="Last 12 Months"
        startDate={startOfMonth(subMonths(new Date(), 12))}
        endDate={endOfMonth(subMonths(new Date(), 1))}
        onClick={() =>
          handleRelativeDateChange(
            startOfMonth(subMonths(new Date(), 12)),
            endOfMonth(subMonths(new Date(), 1))
          )
        }
        isActive={
          startDate ===
            format(startOfMonth(subMonths(new Date(), 12)), 'yyyy-MM-dd') &&
          endDate === format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
      />
      <RelativeDateButton
        label="Last Calendar Year"
        startDate={startOfYear(subMonths(new Date(), 12))}
        endDate={endOfYear(subMonths(new Date(), 12))}
        onClick={() =>
          handleRelativeDateChange(
            startOfYear(subMonths(new Date(), 12)),
            endOfYear(subMonths(new Date(), 12))
          )
        }
        isActive={
          startDate ===
            format(startOfYear(subMonths(new Date(), 12)), 'yyyy-MM-dd') &&
          endDate === format(endOfYear(subMonths(new Date(), 12)), 'yyyy-MM-dd')
        }
      />
    </Grid>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        padding: 3,
        width: '90%',
        margin: 2,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
        },
      }}
    >
      <MapColorLegend
        data={[
          {
            colors: OVERLAP_COLORS,
            label: 'Overlapping Locations',
          },
          {
            colors: [NON_OVERLAP_COLOR],
            label: 'Non-Overlapping Locations',
          },
        ]}
      />
      <Grid container spacing={3} justifyContent="center">
        {/* Date Pickers and Relative Date Buttons */}
        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={2}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                views={['year', 'month']}
                label="Start"
                value={(!!startDate && convertToLocalDate(startDate)) || null}
                onChange={handleStartDateChange}
                minDate={new Date(minDate)}
                maxDate={new Date(maxDate)}
                sx={{ width: '100%' }}
              />
              <DatePicker
                views={['year', 'month']}
                label="End"
                value={(!!endDate && convertToLocalDate(endDate)) || null}
                onChange={handleEndDateChange}
                minDate={new Date(minDate)}
                maxDate={new Date(maxDate)}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>

            {relativeDateButtons}
          </Box>
        </Grid>
        {/* Toggle Switch */}
        {tradeAreaOverlapData !== null && !tradeAreaOverlapDataIsLoading && (
          <Grid item xs={12} m={-1}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
                width="100%"
              >
                {/* Heatmap Switch */}
                <FormControlLabel
                  control={
                    <Switch
                      checked={showTradeAreaOverlapHeatmap}
                      onChange={() =>
                        setShowTradeAreaOverlapHeatmap(
                          !showTradeAreaOverlapHeatmap
                        )
                      }
                      color="secondary"
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={{ fontSize: '0.8rem', color: '#666' }}
                    >
                      Heatmap
                    </Typography>
                  }
                />

                {/* Show Data Switch, always rendered but only enabled when Heatmap is active */}
                <FormControlLabel
                  control={
                    <Switch
                      checked={showTradeAreaOverlapHeatmapData}
                      onChange={() =>
                        setShowTradeAreaOverlapHeatmapData(
                          !showTradeAreaOverlapHeatmapData
                        )
                      }
                      color="secondary"
                      disabled={!showTradeAreaOverlapHeatmap} // Disable when Heatmap is not active
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={{ fontSize: '0.8rem', color: '#666' }}
                    >
                      Show Data
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          {startDate !== null && endDate !== null && (
            <MobileTradeAreaOverlapData />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MobileTradeAreaOverlapContainer;
