import { Box, Popper } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import CustomColorPicker from './CustomColorPicker';

export const FullWidthColorPicker = ({
  selectedColor,
  setSelectedColor,
}: {
  selectedColor: string;
  setSelectedColor: (color: string) => void;
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);

  const anchorEl = useRef<HTMLDivElement>(null);
  const colorPickerRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = useCallback((event: MouseEvent) => {
    if (
      colorPickerRef.current &&
      !colorPickerRef.current.contains(event.target as Node) &&
      !anchorEl.current?.contains(event.target as Node)
    ) {
      setShowColorPicker(false);
    }
  }, []);

  useEffect(() => {
    if (showColorPicker) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
  }, [showColorPicker, handleOutsideClick]);

  return (
    <>
      <Box
        onClick={() => setShowColorPicker((prev) => !prev)}
        ref={anchorEl}
        sx={{
          backgroundColor: selectedColor,
          width: '100%',
          height: 36,
          cursor: 'pointer',
        }}
      >
        <Box width={50} height={24} />
      </Box>
      <Popper
        open={showColorPicker}
        anchorEl={anchorEl.current}
        placement="auto-start"
        sx={{ zIndex: 1000 }}
        ref={colorPickerRef}
      >
        <CustomColorPicker
          width={'15rem'}
          disableAlpha
          color={selectedColor}
          onChange={(color) => {
            setSelectedColor(color.hex);
          }}
        />
      </Popper>
    </>
  );
};
