import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';
import { useMutation, useQuery } from 'react-query';

const env = parseEnv({
  API_V2: process.env.API_V2,
});

export type FileResponse = {
  id: string;
  clientId: string;
  fileType: string;
  s3Key: string;
  s3Path: string;
};

const GET_ALL_USER_FILES_QUERY_KEY = 'GET_ALL_USER_FILES';
const DOWNLOAD_USER_FILE_QUERY_KEY = 'DOWNLOAD_USER_FILE';

// Get files list

const getAllUserFiles = async () => {
  const response = await axios.get<FileResponse[]>(
    `${env.API_V2}/user-uploads/someClientId`
  );
  return response.data;
};

export const useUserFiles = () => {
  return useQuery({
    queryKey: [GET_ALL_USER_FILES_QUERY_KEY],
    queryFn: getAllUserFiles,
  });
};

// Handle delete file

export const deleteUserFile = async (fileId: string) => {
  const response = await axios.delete(`${env.API_V2}/user-uploads/${fileId}`);
  return response.data;
};

export const useDeleteUserFile = () => {
  const { refetch } = useUserFiles();

  return useMutation({
    mutationFn: deleteUserFile,
    onSuccess: () => {
      refetch();
    },
  });
};

// Handle download file

export const downloadUserFile = async (fileId: string) => {
  try {
    const response = await axios.get(
      `${env.API_V2}/user-uploads/${fileId}/download`,
      {
        responseType: 'stream',
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

export const useDownloadUserFile = (fileId: string) => {
  return useQuery({
    queryKey: [DOWNLOAD_USER_FILE_QUERY_KEY, fileId],
    queryFn: () => downloadUserFile(fileId),
    onError: (error) => {
      console.error('Error downloading file:', error);
    },
    enabled: false,
  });
};

// Handle upload file

export const uploadUserFile = async (files: FileList) => {
  const formData = new FormData();
  const promises = Array.from(files).map(async (file) => {
    formData.append('file', file);
    formData.append('clientId', 'someClientId');
    formData.append('fileType', file.type);

    const response = await axios.post(`${env.API_V2}/user-uploads`, formData);
    return response.data;
  });

  return Promise.all(promises);
};

export const useUploadUserFile = () => {
  const { refetch } = useUserFiles();

  return useMutation({
    mutationFn: uploadUserFile,
    onSuccess: () => {
      refetch();
    },
  });
};
