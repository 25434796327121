import { parseEnv } from '@plotr/common-utils';
import { supportedCountries } from '@plotr/plotr-multiplayer-data/src';
import circle from '@turf/circle';
import axios from 'axios';
import { Feature, MultiPolygon, Point, Polygon } from 'geojson';
import { UserSettings } from '~/src/global/services/userSettingsService';
import { Boundary } from '../hooks/useDemographicStore';
import { fetchLocalBusinessCounts } from './localBusinessCountSearch';

const env = parseEnv({
  API_V1: process.env.API_V1,
  API_V2: process.env.API_V2,
  MAPBOX_API_KEY: process.env.MAPBOX_API_KEY,
});

export type DemographicResponse =
  | {
      boundary_type: 'radius';
      boundary_measure: 'miles';
      measure_value: number;
      data: DemographicData[];
    }
  | {
      boundary_type: 'custom';
      data: DemographicData[];
    };

export interface DemographicData {
  name: string;
  locked?: boolean;
  fields: DemographicField[];
}

export interface DemographicField {
  name: string;
  value: number | null;
  type: 'integer' | 'percent' | 'USD';
  description?: string;
}

export type DemographicSearchParams = {
  accessToken: string;
  feature: Feature<Point | Polygon | MultiPolygon>;
  radius?: number;
  userSettings: UserSettings | Record<string, never> | null;
  abortSignal?: AbortSignal;
};

export default async function demographicSearch(
  params: DemographicSearchParams
): Promise<{ us: DemographicResponse | null; ca: DemographicResponse | null }> {
  const headers = {
    'Content-Type': 'application/json',
    ...(params.accessToken && {
      Authorization: `Bearer ${params.accessToken}`,
    }),
  };

  const demographicPromise = (async () => {
    if (params.feature.geometry.type === 'Point' && params.radius != null) {
      const response = await axios.post(
        `${env.API_V2}/radius_search`,
        {
          latitude: params.feature.geometry.coordinates[1],
          longitude: params.feature.geometry.coordinates[0],
          radii: [params.radius],
        },
        {
          headers,
          signal: params.abortSignal,
        }
      );

      return response.data[0];
    } else {
      const [usaResponse, caResponse] = await Promise.all([
        axios.post(
          `${env.API_V2}/boundary_search_bg`,
          {
            customBoundary: params.feature,
            country: supportedCountries.USA,
          },
          {
            headers,
            signal: params.abortSignal,
          }
        ),
        axios.post(
          `${env.API_V2}/boundary_search_bg`,
          {
            customBoundary: params.feature,
            country: supportedCountries.CANADA,
          },
          {
            headers,
            signal: params.abortSignal,
          }
        ),
      ]);

      return {
        us: usaResponse.data,
        ca: caResponse.data,
      };
    }
  })();

  const localBusinessCountsPromise = (async () => {
    if (
      params.userSettings?.featureFlags?.['citylife-local-business-count'] ===
      true
    ) {
      let boundary;
      if (params.feature.geometry.type === 'Point' && params.radius != null) {
        boundary = circle(params.feature.geometry.coordinates, params.radius, {
          units: 'miles',
          steps: 64,
        });
      } else {
        boundary = params.feature;
      }
      try {
        return await fetchLocalBusinessCounts(
          boundary as Boundary,
          params.accessToken
        );
      } catch (error) {
        console.error('Error in localBusinessCountsPromise:', error);
        return null;
      }
    }
    return;
  })();

  const [demographicResponse, localBusinessCounts] = await Promise.all([
    demographicPromise,
    localBusinessCountsPromise,
  ]);

  if (
    localBusinessCounts &&
    demographicResponse?.us.data &&
    demographicResponse?.us.data.length > 0
  ) {
    demographicResponse.us.data.unshift(localBusinessCounts);
  }

  return {
    us:
      demographicResponse?.us.data.length < 1 ? null : demographicResponse?.us,
    ca:
      demographicResponse?.ca.data.length < 1 ? null : demographicResponse?.ca,
  };
}
