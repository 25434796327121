import { FetchTradeAreaOverlapDataParams } from '../services/poiService';

export function generateTradeAreaOverlapCacheKey(
  params: FetchTradeAreaOverlapDataParams
): string {
  const surroundingPinsString = params.surroundingPins
    .map((pin) => `${pin.id}-${pin.lat}-${pin.lng}`)
    .join('|');

  return `${params.tradeAreaType && `${params.tradeAreaType}-`}${params.evaluatedPin.id}-${params.evaluatedPin.lat}-${params.evaluatedPin.lng}-${surroundingPinsString}-${params.startDate}-${params.endDate}`;
}
