import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import HomeIcon from '@mui/icons-material/Home';
import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingUp from '@mui/icons-material/TrendingUp';
import WorkIcon from '@mui/icons-material/Work';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  subMonths,
} from 'date-fns';
import startOfYear from 'date-fns/startOfYear';
import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { MobileDataConfig, Permissions } from '~/src/constants';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import {
  FetchMobileDataParams,
  FetchTradeAreaDataParams,
  fetchMobileData,
  fetchTradeAreaDataFromBackend,
} from '~/src/features/dynamic-map/services/poiService';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import usePermissionCheck from '~/src/global/hooks/usePermissionCheck';
import useDemographicStore from '../../../demographic-point-lookup/hooks/useDemographicStore';
import ErrorFetchingData from '../../components/MapLayersMenu/ErrorFetchingData';
import BlurTextLock from './BlurTextLock';
import { convertToLocalDate } from './MobileDataUtils';
import RelativeDateButton from './RelativeDateButton';
import StatDisplay from './StatDisplay';
import useDateRangePicker from './useDateRangePicker';

const {
  DATE_ERROR,
  MOBILE_ERROR,
  TOTAL_VISITS,
  TOTAL_VISITS_TREND,
  VISITORS_STAT_LABEL,
  AVG_DAILY_VISITORS,
  AVG_DAILY_VISITORS_STAT_LABEL,
  MOBILE_STAT_FONT_SIZE,
  MOBILE_TRENDING_STAT_FONT_SIZE,
  MAX_TREND_YEAR,
  MIN_TREND_YEAR,
  MOBILE_DATA_DUMMY_NUMBER,
  INITIAL_START_DATE,
  INITIAL_END_DATE,
} = MobileDataConfig;

const MobileDataContainer: React.FC = () => {
  const { accessToken } = useAccessToken();
  const hasPermission = usePermissionCheck(Permissions.READ_MOBILE_DATA);
  const mobileData = useDemographicStore((state) => state.mobileData);
  const setMobileData = useDemographicStore((state) => state.setMobileData);
  const tradeAreaData = useDemographicStore((state) => state.tradeAreaData);
  const setTradeAreaData = useDemographicStore(
    (state) => state.setTradeAreaData
  );
  const setTradeAreaDataError = useDemographicStore(
    (state) => state.setTradeAreaDataError
  );
  const setTradeAreaDataIsLoading = useDemographicStore(
    (state) => state.setTradeAreaDataIsLoading
  );

  const clickedPOiFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature
  );

  const tradeAreaType = useDemographicStore((state) => state.tradeAreaType);
  const setTradeAreaType = useDemographicStore(
    (state) => state.setTradeAreaType
  );
  const showTradeAreaHeatmap = useDemographicStore(
    (state) => state.showTradeAreaHeatmap
  );
  const setShowTradeAreaHeatmap = useDemographicStore(
    (state) => state.setShowTradeAreaHeatmap
  );

  const showTradeAreaHeatmapData = useDemographicStore(
    (state) => state.showTradeAreaHeatmapData
  );
  const setShowTradeAreaHeatmapData = useDemographicStore(
    (state) => state.setShowTradeAreaHeatmapData
  );

  const tradeAreaRankingYear = useDemographicStore(
    (state) => state.tradeAreaRankingYear
  );
  const setTradeAreaRankingYear = useDemographicStore(
    (state) => state.setTradeAreaRankingYear
  );

  const mobileDataCalculated = useDemographicStore(
    (state) => state.mobileDataCalculated
  );
  const setMobileDataCalculated = useDemographicStore(
    (state) => state.setMobileDataCalculated
  );

  const {
    startDate,
    endDate,
    validateDates,
    handleStartDateChange: hookHandleStartDateChange,
    handleEndDateChange: hookHandleEndDateChange,
    minDate,
    maxDate,
  } = useDateRangePicker(mobileData);

  // Override the hook's handleStartDateChange to clear selectedRange
  const handleStartDateChange = (newDate: Date | null) => {
    hookHandleStartDateChange(newDate); // Call the hook's original handler
  };

  // Override the hook's handleEndDateChange to clear selectedRange
  const handleEndDateChange = (newDate: Date | null) => {
    hookHandleEndDateChange(newDate); // Call the hook's original handler
  };

  const [loadingData, setLoadingData] = useState<boolean>(false);

  const [mobileDataError, setMobileDataError] = useState<string | null>(null);
  const [loadingYoY, setLoadingYoY] = useState<boolean>(false);

  function processFootTrafficData(startDate: string, endDate: string) {
    const foot_traffic_poi = mobileData?.foot_traffic_poi;

    if (isEmpty(foot_traffic_poi)) {
      setMobileDataError(MobileDataConfig.MOBILE_DATES_NOT_AVAILABLE);
      return {
        visits_sum: null,
        visits_p50: null,
      };
    }

    // clear any previous errors
    setMobileDataError(null);

    // Parse start and end dates without forcing UTC (no 'Z' at the end)
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Filter foot_traffic_poi data based on overlapping startDate and endDate
    const filteredFootTraffic = foot_traffic_poi?.filter((item: any) => {
      const observationStartDate = new Date(item.observationStartDate);
      const observationEndDate = new Date(item.observationEndDate);

      // Check if the observation period overlaps with the given date range
      return (
        (observationStartDate >= startDateObj &&
          observationStartDate <= endDateObj) ||
        (observationEndDate >= startDateObj &&
          observationEndDate <= endDateObj) ||
        (observationStartDate <= startDateObj &&
          observationEndDate >= endDateObj)
      );
    });

    // If no data is available for the selected date range, set error and return null values
    if (filteredFootTraffic?.length === 0 || !filteredFootTraffic) {
      setMobileDataError(MobileDataConfig.MOBILE_DATES_NOT_AVAILABLE);
      return {
        visits_sum: null,
        visits_p50: null,
      };
    }

    const visitsSum = filteredFootTraffic.reduce(
      (acc: number, curr: any) => acc + curr.visitsSum,
      0
    );
    const visitsP50 = Math.round(
      filteredFootTraffic.reduce(
        (acc: number, curr: any) => acc + curr.visitsP50,
        0
      ) / filteredFootTraffic.length
    );

    return {
      visits_sum: visitsSum,
      visits_p50: visitsP50,
    };
  }

  const fetchMobileDataForTrendYear = async () => {
    if (!accessToken || !clickedPOiFeature) return;

    setLoadingYoY(true);
    setMobileDataError(null);

    try {
      const params: FetchMobileDataParams = {
        id: clickedPOiFeature?.properties?.id,
        trend_year: tradeAreaRankingYear.toString(),
        access_token: accessToken,
      };

      const data = await fetchMobileData(params);
      if (data != null && typeof data !== 'string') {
        setMobileData(data);
        // Only set error for trend data if it's missing
        if (!data.hasTrendData) {
          setMobileDataError('Trend data not available for selected year');
        }
      } else {
        setMobileDataError(MOBILE_ERROR);
      }
    } catch (err) {
      setMobileDataError(MOBILE_ERROR);
    } finally {
      setLoadingYoY(false);
    }
  };

  const fetchFullMobileData = async () => {
    if (!accessToken || !clickedPOiFeature) return;

    setLoadingData(true); // General loading for full data
    setMobileDataError(null);

    try {
      const params: FetchMobileDataParams = {
        id: clickedPOiFeature?.properties?.id,
        trend_year: tradeAreaRankingYear.toString(),
        access_token: accessToken,
      };

      const data = await fetchMobileData(params);
      if (data != null && typeof data !== 'string') {
        setMobileData(data);
      } else {
        setMobileDataError(MOBILE_ERROR); // Set error if no valid data is returned
      }
    } catch (err) {
      setMobileDataError(MOBILE_ERROR);
    } finally {
      setLoadingData(false);
    }
  };

  // Store the unfiltered trade area data as an array
  const [unfilteredTradeAreaData, setUnfilteredTradeAreaData] = useState<
    Map<string, number>
  >(new Map());

  // Fetch the data only once without filtering by type
  const fetchTradeAreaData = async () => {
    if (!accessToken || !clickedPOiFeature) return;

    setTradeAreaDataIsLoading(true); // General loading for full data
    setTradeAreaDataError(null);

    try {
      // Fetch all trade area data without filtering by type
      const params: FetchTradeAreaDataParams = {
        id: clickedPOiFeature?.properties?.id,
        startDate: startDate || INITIAL_START_DATE,
        endDate: endDate || INITIAL_END_DATE,
        trend_year: tradeAreaRankingYear.toString(),
        trade_area_type: tradeAreaType,
      };

      // Fetch data from backend and store as array
      const data = await fetchTradeAreaDataFromBackend(params);

      if (data && !isEmpty(data)) {
        setUnfilteredTradeAreaData(data); // Store the unfiltered data as array
      } else {
        setUnfilteredTradeAreaData(new Map());
      }
    } catch (error) {
      setTradeAreaDataError(MOBILE_ERROR);
    } finally {
      setTradeAreaDataIsLoading(false);
    }
  };

  // Filter the unfiltered data based on tradeAreaType
  const filterTradeAreaData = () => {
    const filteredMap = new Map();

    unfilteredTradeAreaData.forEach((item: any) => {
      // Include all items if tradeAreaType is 'BOTH', otherwise filter by current tradeAreaType
      if (tradeAreaType === 'BOTH' || item.trade_area_type === tradeAreaType) {
        const { trade_area_location_id, people_fraction } = item;

        // Sum people_fraction if the location_id is already in the map
        if (filteredMap.has(trade_area_location_id)) {
          const existingValue = filteredMap.get(trade_area_location_id);
          filteredMap.set(
            trade_area_location_id,
            existingValue + people_fraction
          );
        } else {
          filteredMap.set(trade_area_location_id, people_fraction);
        }
      }
    });

    setTradeAreaData(filteredMap);
  };

  // Fetch the data once when component mounts or dependencies change
  useEffect(() => {
    fetchTradeAreaData();
  }, [accessToken, clickedPOiFeature, hasPermission, startDate, endDate]);

  // Re-run filtering whenever tradeAreaType changes
  useEffect(() => {
    filterTradeAreaData();
  }, [tradeAreaType, unfilteredTradeAreaData]);

  useEffect(() => {
    fetchFullMobileData();
  }, [accessToken, clickedPOiFeature, hasPermission]);

  useEffect(() => {
    fetchMobileDataForTrendYear();
  }, [tradeAreaRankingYear, accessToken, clickedPOiFeature, hasPermission]);

  // Process the data when date pickers change
  useEffect(() => {
    // Clear error when new data is processed
    if (mobileData) {
      setMobileDataError(null);
    }

    // Ensure mobileData is present
    if (!mobileData) {
      setMobileDataError(MOBILE_ERROR);
      return;
    }

    // Validate the dates; if invalid, set an error
    if (!validateDates(startDate, endDate)) {
      setMobileDataError(DATE_ERROR);
      return;
    }

    if (mobileData && mobileData.foot_traffic_poi) {
      const processed = processFootTrafficData(startDate ?? '', endDate ?? '');
      setMobileDataCalculated(processed);
    }
  }, [startDate, endDate, mobileData, mobileDataError]);

  const determineNumberToDisplay = (data: number) => {
    if (data < 1000) return data;
    if (data < 1000000) return (data / 1000).toFixed(1) + 'K';
    return (data / 1000000).toFixed(1) + 'M';
  };

  // Function to handle relative date changes from buttons
  const handleRelativeDateChange = (start: Date, end: Date) => {
    handleStartDateChange(start);
    handleEndDateChange(end);
  };

  // Relative date buttons
  const relativeDateButtons = (
    <Grid container spacing={0.7} justifyContent="center">
      <RelativeDateButton
        label="Last Month"
        startDate={startOfMonth(subMonths(new Date(), 1))}
        endDate={endOfMonth(subMonths(new Date(), 1))}
        onClick={() =>
          handleRelativeDateChange(
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1))
          )
        }
        isActive={
          startDate ===
            format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd') &&
          endDate === format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
      />
      <RelativeDateButton
        label="Last 3 Months"
        startDate={startOfMonth(subMonths(new Date(), 3))}
        endDate={endOfMonth(subMonths(new Date(), 1))}
        onClick={() =>
          handleRelativeDateChange(
            startOfMonth(subMonths(new Date(), 3)),
            endOfMonth(subMonths(new Date(), 1))
          )
        }
        isActive={
          startDate ===
            format(startOfMonth(subMonths(new Date(), 3)), 'yyyy-MM-dd') &&
          endDate === format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
      />
      <RelativeDateButton
        label="Last 6 Months"
        startDate={startOfMonth(subMonths(new Date(), 6))}
        endDate={endOfMonth(subMonths(new Date(), 1))}
        onClick={() =>
          handleRelativeDateChange(
            startOfMonth(subMonths(new Date(), 6)),
            endOfMonth(subMonths(new Date(), 1))
          )
        }
        isActive={
          startDate ===
            format(startOfMonth(subMonths(new Date(), 6)), 'yyyy-MM-dd') &&
          endDate === format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
      />
      <RelativeDateButton
        label="Last 9 Months"
        startDate={startOfMonth(subMonths(new Date(), 9))} // First day, nine months ago
        endDate={endOfMonth(subMonths(new Date(), 1))} // Last day of the previous month
        onClick={() =>
          handleRelativeDateChange(
            startOfMonth(subMonths(new Date(), 9)),
            endOfMonth(subMonths(new Date(), 1))
          )
        }
        isActive={
          startDate ===
            format(startOfMonth(subMonths(new Date(), 9)), 'yyyy-MM-dd') &&
          endDate === format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
      />
      <RelativeDateButton
        label="Last 12 Months"
        startDate={startOfMonth(subMonths(new Date(), 12))}
        endDate={endOfMonth(subMonths(new Date(), 1))}
        onClick={() =>
          handleRelativeDateChange(
            startOfMonth(subMonths(new Date(), 12)),
            endOfMonth(subMonths(new Date(), 1))
          )
        }
        isActive={
          startDate ===
            format(startOfMonth(subMonths(new Date(), 12)), 'yyyy-MM-dd') &&
          endDate === format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
        }
      />
      <RelativeDateButton
        label="Last Calendar Year"
        startDate={startOfYear(subMonths(new Date(), 12))}
        endDate={endOfYear(subMonths(new Date(), 12))}
        onClick={() =>
          handleRelativeDateChange(
            startOfYear(subMonths(new Date(), 12)),
            endOfYear(subMonths(new Date(), 12))
          )
        }
        isActive={
          startDate ===
            format(startOfYear(subMonths(new Date(), 12)), 'yyyy-MM-dd') &&
          endDate === format(endOfYear(subMonths(new Date(), 12)), 'yyyy-MM-dd')
        }
      />
    </Grid>
  );

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        padding: 3,
        width: '90%',
        margin: 2,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
        },
      }}
    >
      {/* Date Pickers and Relative Date Buttons */}
      <Box>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap={2}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month']}
                  label="Start"
                  value={
                    (startDate !== null && convertToLocalDate(startDate)) ||
                    null
                  }
                  onChange={handleStartDateChange}
                  minDate={new Date(minDate)}
                  maxDate={new Date(maxDate)}
                  sx={{ width: '100%' }}
                />
                <DatePicker
                  views={['year', 'month']}
                  label="End"
                  value={
                    (endDate !== null && convertToLocalDate(endDate)) || null
                  }
                  onChange={handleEndDateChange}
                  minDate={new Date(minDate)}
                  maxDate={new Date(maxDate)}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>

              {relativeDateButtons}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Conditionally Render Rest of the UI */}
      {startDate !== null && endDate !== null && (
        <>
          {/* Display Stats */}
          {mobileDataError ? (
            <ErrorFetchingData errorContent={mobileDataError} />
          ) : loadingData ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              mt={3}
            >
              <CircularProgress />
            </Box>
          ) : (
            hasPermission &&
            mobileData && (
              <>
                <Grid container spacing={3} mt={1}>
                  <Grid item xs={12} m={-1}>
                    {/* Toggle Switch */}
                    {!loadingData && tradeAreaData !== null && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap={2}
                      >
                        {/* Center the switches horizontally */}
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          gap={1}
                          width="100%"
                        >
                          {/* Heatmap Switch */}
                          <FormControlLabel
                            control={
                              <Switch
                                checked={showTradeAreaHeatmap}
                                onChange={() => {
                                  setShowTradeAreaHeatmap(
                                    !showTradeAreaHeatmap
                                  );
                                  setShowTradeAreaHeatmapData(true);
                                }}
                                color="secondary"
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                style={{ fontSize: '0.8rem', color: '#666' }}
                              >
                                Heatmap
                              </Typography>
                            }
                          />

                          {/* Show Data Switch, always rendered but only enabled when Heatmap is active */}
                          <FormControlLabel
                            control={
                              <Switch
                                checked={showTradeAreaHeatmapData}
                                onChange={() =>
                                  setShowTradeAreaHeatmapData(
                                    !showTradeAreaHeatmapData
                                  )
                                }
                                color="secondary"
                                disabled={!showTradeAreaHeatmap} // Disable when Heatmap is not active
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                style={{ fontSize: '0.8rem', color: '#666' }}
                              >
                                Show Data
                              </Typography>
                            }
                          />
                        </Box>

                        {/* Toggle Button Group positioned below the switches */}
                        {showTradeAreaHeatmap && (
                          <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                          >
                            <ToggleButtonGroup
                              value={tradeAreaType}
                              exclusive
                              onChange={(_, newValue) => {
                                if (newValue !== null) {
                                  setTradeAreaType(newValue);
                                }
                              }}
                              aria-label="work home toggle"
                              sx={{ height: 30 }} // Adjust height of the buttons
                            >
                              <ToggleButton
                                value="HOME"
                                aria-label="home"
                                sx={{
                                  fontSize: '0.75rem', // Smaller font
                                  padding: '5px 9px', // Adjust padding for smaller size
                                }}
                              >
                                <HomeIcon sx={{ mr: 1, fontSize: '1rem' }} />
                                Home
                              </ToggleButton>

                              <ToggleButton
                                value="BOTH"
                                aria-label="both"
                                sx={{
                                  fontSize: '0.7rem', // Smaller font
                                  padding: '4px 8px', // Adjust padding for smaller size
                                }}
                              >
                                <CompareArrowsIcon
                                  sx={{ mr: 1, fontSize: '1rem' }}
                                />
                                Both
                              </ToggleButton>
                              <ToggleButton
                                value="WORK"
                                aria-label="work"
                                sx={{
                                  fontSize: '0.7rem', // Smaller font
                                  padding: '4px 8px', // Adjust padding for smaller size
                                }}
                              >
                                <WorkIcon sx={{ mr: 1, fontSize: '1rem' }} />
                                Work
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {mobileDataError ? (
                      <Typography
                        color="text.secondary"
                        align="center"
                        sx={{
                          backgroundColor: '#f5f5f5',
                          padding: 2,
                          borderRadius: 1,
                          marginTop: 2,
                        }}
                      >
                        {mobileDataError}
                      </Typography>
                    ) : (
                      <>
                        <StatDisplay
                          value={
                            <Typography
                              component="span"
                              fontWeight="bold"
                              fontSize={MOBILE_STAT_FONT_SIZE}
                            >
                              {hasPermission ? (
                                determineNumberToDisplay(
                                  mobileDataCalculated.visits_sum
                                )
                              ) : (
                                <BlurTextLock
                                  intensity={7}
                                  contextString="mobile"
                                >
                                  {MOBILE_DATA_DUMMY_NUMBER}
                                </BlurTextLock>
                              )}
                            </Typography>
                          }
                          label={TOTAL_VISITS}
                          sx={{ flex: 1 }}
                          description={VISITORS_STAT_LABEL}
                        />
                        <Grid item xs={12}>
                          <StatDisplay
                            value={
                              <Typography
                                component="span"
                                fontWeight="bold"
                                fontSize={MOBILE_STAT_FONT_SIZE}
                              >
                                {hasPermission ? (
                                  determineNumberToDisplay(
                                    mobileDataCalculated.visits_p50
                                  )
                                ) : (
                                  <BlurTextLock
                                    intensity={7}
                                    contextString="mobile"
                                  >
                                    {MOBILE_DATA_DUMMY_NUMBER}
                                  </BlurTextLock>
                                )}
                              </Typography>
                            }
                            label={AVG_DAILY_VISITORS}
                            sx={{ flex: 1 }}
                            description={AVG_DAILY_VISITORS_STAT_LABEL}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>

                {/* Trend Display */}
                <Box mt={3}>
                  {/* Date Pickers and Relative Date Button */}
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        gap={2}
                      >
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            views={['year']}
                            label="Year"
                            value={new Date(tradeAreaRankingYear, 0)}
                            onChange={(newValue) => {
                              if (newValue) {
                                setTradeAreaRankingYear(newValue.getFullYear());
                              }
                            }}
                            minDate={new Date(Number(MIN_TREND_YEAR), 0)}
                            maxDate={new Date(Number(MAX_TREND_YEAR), 0)}
                            sx={{ width: '100%' }}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {loadingYoY ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    mt={3}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  mobileData && (
                    <Grid container justifyContent="center" mt={1}>
                      <Grid item xs={12}>
                        {mobileData.hasTrendData ? (
                          <StatDisplay
                            value={
                              <>
                                {mobileData.trendWord === 'up' ? (
                                  <TrendingUp
                                    sx={{
                                      color: 'green',
                                      fontSize: 24,
                                      marginRight: 1,
                                    }}
                                  />
                                ) : (
                                  <TrendingDown
                                    sx={{
                                      color: 'red',
                                      fontSize: 24,
                                      marginRight: 1,
                                    }}
                                  />
                                )}
                                <Typography
                                  component="span"
                                  fontWeight="bold"
                                  fontSize={MOBILE_TRENDING_STAT_FONT_SIZE}
                                >
                                  {hasPermission ? (
                                    `${mobileData.trendingPercentage}%`
                                  ) : (
                                    <BlurTextLock
                                      intensity={7}
                                      contextString="mobile"
                                    >
                                      {MOBILE_DATA_DUMMY_NUMBER}
                                    </BlurTextLock>
                                  )}
                                </Typography>
                              </>
                            }
                            label={TOTAL_VISITS_TREND}
                            sx={{ flex: 1 }}
                            description={
                              hasPermission
                                ? `Total visits is trending ${mobileData.trendWord} by ${mobileData.trendingPercentage}% YoY.`
                                : `Total visits is trending ${mobileData.trendWord}.`
                            }
                          />
                        ) : (
                          <Typography
                            color="text.secondary"
                            align="center"
                            sx={{
                              backgroundColor: '#f5f5f5',
                              padding: 2,
                              borderRadius: 1,
                              marginTop: 2,
                            }}
                          >
                            Year-over-year trend data not available for{' '}
                            {tradeAreaRankingYear}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  )
                )}
              </>
            )
          )}
        </>
      )}
    </Box>
  );
};

export default MobileDataContainer;
