import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  SxProps,
} from '@mui/material';
import {
  PinTemplate,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import CollapseCard from '~/src/common/components/CollapseCard';
import {
  ModalNames,
  useModalDataContext,
} from '~/src/common/hooks/useModalDataContext';
import usePinTemplates from '~/src/global/hooks/usePinTemplates';
import EditTemplateModal from '../templates/EditTemplateModal';

export const TemplateCard = ({
  template,
  sx,
  onSelect,
}: {
  template: PinTemplate;
  sx?: SxProps;
  onSelect: (template: PinTemplate) => void;
}) => {
  const templateMethods = plotrMultiplayerData.methods?.templates;

  return (
    <Card sx={sx}>
      <CardHeader
        titleTypographyProps={{ variant: 'body1', fontWeight: '500' }}
        title={template.label}
      />
      <CardActions sx={{ justifyContent: 'end' }}>
        <Button onClick={() => onSelect(template)}>Select</Button>
        <Button onClick={() => templateMethods?.removeTemplate(template.id)}>
          Delete
        </Button>
        <EditTemplateModal template={template} />
      </CardActions>
    </Card>
  );
};

// TODO: REMOVE IF NOT USED AFTER TEMPLATED UI UPDATE
const TemplatesCollapseSection = () => {
  const templates = usePinTemplates();

  const { onOpenChange } = useModalDataContext();
  const handleOpen = () => onOpenChange(ModalNames.CREATE_TEMPLATE, true);

  return (
    <CollapseCard title="Pin Templates">
      <Box py={1} px={2} maxHeight={'10rem'}>
        <Button
          onClick={handleOpen}
          fullWidth
          color="primary"
          variant="outlined"
        >
          Create a new template
        </Button>
        {templates.map((template) => (
          <Box key={template.id} my={2}>
            <TemplateCard template={template} onSelect={() => {}} />
          </Box>
        ))}
      </Box>
    </CollapseCard>
  );
};

export default TemplatesCollapseSection;
