import { CustomPin } from '@plotr/plotr-multiplayer-data/src';
import { distance, Units } from '@turf/turf';

type Props = {
  evaluatedPin: CustomPin;
  surroundingPins: CustomPin[];
  unit?: Units;
  maxDistance: number;
};

export function filterSurroundingPins({
  evaluatedPin,
  surroundingPins,
  unit = 'miles',
  maxDistance,
}: Props) {
  const evaluatedPinPosition = [evaluatedPin.pos.lng, evaluatedPin.pos.lat];

  return surroundingPins.filter(({ pos: { lng, lat } }) => {
    const pinPosition = [lng, lat];
    const distanceBetweenPins = distance(evaluatedPinPosition, pinPosition, {
      units: unit,
    });

    return distanceBetweenPins <= maxDistance;
  });
}
