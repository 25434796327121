import { Box, Tooltip, Typography } from '@mui/material';
import { Hexagon, Honeycomb } from '~/src/common/components/Honeycomb';

interface BreakDownHoneycombProps {
  title: string;
  items: Array<{ title?: string; value?: string | number; bgColor?: string }>;
}

export const BreakDownHoneycomb = ({
  title,
  items,
}: BreakDownHoneycombProps) => {
  return (
    <Box>
      <Typography component="h3" variant="h5" my={2}>
        {title}
      </Typography>
      <Honeycomb
        columns={4}
        items={items.filter(
          (item) => item.value || !Object.hasOwn(item, 'value')
        )}
        size={72}
        renderItem={(item) => (
          <Hexagon style={{ backgroundColor: item.bgColor ?? '' }} gap={6}>
            {Object.keys(item).length > 0 && (
              <Tooltip
                title={
                  <Typography
                    variant="body2"
                    textAlign={'center'}
                    color={'white'}
                  >
                    {typeof item.value === 'string'
                      ? item.value.length > 0
                        ? item.value
                        : 'N/A'
                      : (item?.value ?? 'N/A')}
                  </Typography>
                }
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  width={'100%'}
                  height={'100%'}
                  p={1}
                  gap={0.5}
                >
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    textAlign={'center'}
                    color={'white'}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    fontSize={12}
                    textAlign={'center'}
                    color={'white'}
                    variant="body2"
                  >
                    {typeof item.value === 'string'
                      ? item.value.length > 35
                        ? item.value.slice(0, 35) + '...'
                        : item.value
                      : item.value}
                  </Typography>
                </Box>
              </Tooltip>
            )}
          </Hexagon>
        )}
      />
    </Box>
  );
};
