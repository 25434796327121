import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useLayersStore, {
  LayerCard,
} from '../../../../../dynamic-map/hooks/useLayersStore';

const MapPreview = ({ selectedLayer }: { selectedLayer: LayerCard | null }) => (
  <img
    src={selectedLayer?.imageUrl || 'https://via.placeholder.com/150'}
    alt="Map Preview"
    style={{
      width: '100%',
      aspectRatio: '1/1',
    }}
  />
);

interface EditLayerProps {
  selectedLayer: LayerCard | null;
  onClose: () => void;
  onSave: (layerDetails: LayerCard) => void;
}

const EditLayerModal = ({ selectedLayer, onClose, onSave }: EditLayerProps) => {
  const layers = useLayersStore((state) => state.layers);
  const [layerDisplayName, setLayerDisplayName] = useState<string>('');
  const [layerDetails, setLayerDetails] = useState<LayerCard | null>(
    selectedLayer
  );
  const [error, setError] = useState<string>('');

  useEffect(() => {
    // Reset form fields when modal opens or selectedLayer changes
    if (selectedLayer) {
      setLayerDisplayName(selectedLayer?.displayName || '');
      setLayerDetails(selectedLayer ?? null);
      setError('');
    }
  }, [selectedLayer]);

  const handleDisplayNameChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setLayerDisplayName(value);
    setError(validateLayerName(value));
  };

  const validateLayerName = (name: string): string => {
    if (!name.trim()) return 'Layer name cannot be empty.';
    if (name.length > 50) return 'Layer name must be 50 characters or less.';
    if (!/^[a-zA-Z0-9-_ ]+$/.test(name))
      return 'Layer name contains invalid characters.';
    const doesNameExist = layers.some(
      (layer: LayerCard) =>
        layer.displayName === name &&
        layer.displayName !== selectedLayer?.displayName
    );
    if (doesNameExist)
      return 'A layer with this name already exists. Please choose a different name.';
    return '';
  };

  const handleSave = () => {
    if (!layerDetails) return;

    const error = validateLayerName(layerDisplayName);
    if (error) return setError(error);

    onSave({ ...layerDetails, displayName: layerDisplayName });
    clearForm();
  };

  const clearForm = () => {
    setLayerDisplayName('');
    setLayerDetails(null);
    setError('');
    onClose();
  };

  return (
    <Dialog open={!!selectedLayer} onClose={onClose} fullWidth>
      <DialogTitle>Edit Layer</DialogTitle>
      {layerDetails && (
        <>
          <DialogContent>
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Grid item xs={12}>
                    <MapPreview selectedLayer={selectedLayer} />
                  </Grid>
                  <Grid item xs={12} sx={{ alignContent: 'center' }}></Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    name="id"
                    label="Layer Display Name"
                    value={layerDisplayName}
                    margin="dense"
                    variant="outlined"
                    error={!!error}
                    helperText={error}
                    fullWidth
                    onChange={handleDisplayNameChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={clearForm}>Cancel</Button>
            <Button onClick={handleSave} disabled={!!error}>
              Save Changes
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EditLayerModal;
