import { Lock as MUILockIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';

export const LockIcon = ({
  active,
  styles,
}: {
  active: boolean;
  styles: CSSProperties;
}) => {
  const isActive = active ?? false;
  const [isAnimating, setIsAnimating] = useState(false);

  const animationSeconds = 1;

  useEffect(() => {
    if (isActive && !isAnimating) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, animationSeconds * 1000);
    }
  }, [isActive, setIsAnimating, isAnimating]);

  return (
    <MUILockIcon
      key={isActive.toString()}
      sx={{
        color: 'gold',
        animation: `wiggle ${animationSeconds}s ease-in-out`,
        ...styles,
      }}
    />
  );
};

export const LockableButton = ({
  children,
  isLocked,
  onClick,
}: {
  children: JSX.Element;
  isLocked: boolean;
  onClick?: () => void;
}) => {
  return (isLocked ?? false) ? (
    <Button
      onClick={onClick}
      sx={{
        width: '100%',
        padding: 0,
        textTransform: 'none',
        textAlign: 'left',
        transition: 'all 0.1s ease-in-out',
        '&:hover': {
          backgroundColor: '#f5f5f5',
          transform: 'scale(1.05)',
          cursor: 'pointer',
        },
        '.MuiPaper-root': {
          width: '100%',
          minWidth: 'auto',
        },
      }}
    >
      {children}
    </Button>
  ) : (
    children
  );
};
