import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FlyToIcon from '@mui/icons-material/FlightTakeoff';
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material';

export const BoundaryCard = ({
  boundaryId,
  activeEditBoundaryId,
  disableActions,
  isEditEnabled,
  onRemove,
  onEdit,
  onFlyTo,
  onHover,
}: {
  boundaryId: string;
  activeEditBoundaryId: string | null;
  disableActions?: boolean;
  isEditEnabled?: boolean;
  onRemove: () => void;
  onEdit: () => void;
  onFlyTo: (boundaryId: string) => void;
  onHover?: (boundaryId: string, value: boolean) => void;
}) => {
  const isActiveEdit = activeEditBoundaryId === boundaryId;
  const hasActiveId = !!activeEditBoundaryId;

  const handleMouseEnter = () => {
    onHover?.(boundaryId, true);
  };

  const handleMouseLeave = () => {
    onHover?.(boundaryId, false);
  };

  return (
    <Paper
      key={boundaryId}
      variant="outlined"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 1,
        marginBottom: 1,
        height: '100%',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          maxWidth: '70%',
        }}
      >
        <Tooltip title={boundaryId}>
          <Typography
            variant="body2"
            color="textPrimary"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {boundaryId}
          </Typography>
        </Tooltip>
      </Box>
      <IconButton
        aria-label="flyTo"
        size="small"
        onClick={() => onFlyTo(boundaryId)}
        sx={{
          maxWidth: isEditEnabled ? '10%' : '15%',
        }}
      >
        <FlyToIcon />
      </IconButton>
      {isEditEnabled && (
        <IconButton
          aria-label="edit"
          onClick={onEdit}
          size="small"
          sx={{
            maxWidth: '10%',
          }}
          color={isActiveEdit ? 'secondary' : 'default'}
          disabled={(!isActiveEdit && hasActiveId) || disableActions}
        >
          {isActiveEdit ? (
            <Tooltip title="Exit edit mode">
              <CancelIcon />
            </Tooltip>
          ) : (
            <EditIcon />
          )}
        </IconButton>
      )}
      <IconButton
        aria-label="remove"
        onClick={onRemove}
        size="small"
        sx={{
          maxWidth: isEditEnabled ? '10%' : '15%',
        }}
        disabled={hasActiveId || disableActions}
      >
        <DeleteIcon />
      </IconButton>
    </Paper>
  );
};
