import {
  FlightTakeoff as FlyToIcon,
  Insights as InsightsIcon,
} from '@mui/icons-material';
import { Box, Grid, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { parseEnv } from '@plotr/common-utils';
import { CustomPin } from '@plotr/plotr-multiplayer-data/src';
import { useEffect, useState } from 'react';

import axios from 'axios';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '../../dynamic-map/hooks/useMapContext';

const env = parseEnv({ MAPBOX_API_KEY: process.env.MAPBOX_API_KEY });

const PinCard = ({ pin }: { pin: CustomPin }) => {
  const map = useMapContext();
  const [address, setAddress] = useState<string | null>(null);

  const theme = useTheme();

  const {
    evaluatedDemographicEntity,
    setEvaluatedDemographicEntity,
    setEvaluatedPinId,
    setClickedPOiFeature,
  } = useDynamicMapStore((state) => ({
    evaluatedDemographicEntity: state.evaluatedDemographicEntity,
    setEvaluatedDemographicEntity: state.setEvaluatedDemographicEntity,
    setEvaluatedPinId: state.setEvaluatedPinId,
    setClickedPOiFeature: state.setClickedPOiFeature,
  }));

  const evaluatedDemographicPoint =
    evaluatedDemographicEntity?.type === 'point'
      ? evaluatedDemographicEntity.pos
      : null;

  // fetch pin address based on lat/lng
  useEffect(() => {
    axios
      .get<{ features: { place_name: string }[] }>(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${pin.pos.lng},${pin.pos.lat}.json?access_token=${env.MAPBOX_API_KEY}`
      )
      .then(({ data }) => {
        const address = data.features[0].place_name;
        setAddress(address);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [pin.pos]);

  const onClickFlyTo = () => {
    map?.flyTo({ center: pin.pos, zoom: 15 });
  };

  const onClickDemographics = () => {
    if (
      evaluatedDemographicPoint?.lat === pin.pos.lat &&
      evaluatedDemographicPoint?.lng === pin.pos.lng
    ) {
      setEvaluatedDemographicEntity(null);
      return;
    }
    setClickedPOiFeature(null);
    setEvaluatedDemographicEntity({
      type: 'point',
      pos: pin.pos,
    });
    setEvaluatedPinId(pin.id);
  };

  const determineDemographicButtonBackground =
    evaluatedDemographicPoint?.lat === pin.pos.lat &&
    evaluatedDemographicPoint?.lng === pin.pos.lng
      ? 'primary'
      : 'disabled';

  return (
    <Paper key={pin.id} variant="outlined" sx={{ marginBottom: 1 }}>
      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid
            display="flex"
            flexDirection="column"
            justifyContent="center"
            item
            xs={10}
          >
            <Typography variant="h6" gutterBottom>
              {pin.label}
            </Typography>
            <Tooltip title={address ?? 'Loading address...'}>
              <Typography variant="caption" noWrap>
                <Box
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {address ?? 'Loading address...'}
                </Box>
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <FlyToIcon
              onClick={onClickFlyTo}
              cursor="pointer"
              sx={{
                marginBottom: 1,
                ':hover': {
                  color: theme.palette.primary.main,
                },
              }}
              color="disabled"
            />
            <InsightsIcon
              color={determineDemographicButtonBackground}
              onClick={onClickDemographics}
              cursor="pointer"
              sx={{
                ':hover': {
                  color: theme.palette.primary.main,
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default PinCard;
