import { useState } from 'react';
import {
  Check as CheckIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Edit as EditIcon,
  List as ListIcon,
} from '@mui/icons-material';
import {
  Paper,
  Box,
  TextField,
  IconButton,
  Typography,
  Button,
} from '@mui/material';
import { plotrMultiplayerData } from '@plotr/plotr-multiplayer-data';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';

const PinGroupCard = ({
  group,
  pinCount,
  onAction,
}: {
  group: string;
  pinCount: number;
  onAction: () => void;
}) => {
  const customPinMethods = plotrMultiplayerData.methods?.pins;
  const [newGroupName, setNewGroupName] = useState<string>('');
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { hiddenPinGroups, toggleGroupVisibility } = useDynamicMapStore(
    (state) => state
  );

  const submitNewName = () => {
    if (customPinMethods == null) return;

    customPinMethods.renameGroup(group, newGroupName);
    setIsEditing(false);
  };

  return (
    <Paper key={group} variant="outlined" sx={{ marginBottom: 1 }}>
      <Box padding={2}>
        <Box
          width="100%"
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom={1.5}
        >
          {isEditing ? (
            <>
              <TextField
                autoFocus
                size="small"
                value={newGroupName}
                onChange={(event) => setNewGroupName(event.target.value)}
                onBlur={submitNewName}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    submitNewName();
                  } else if (e.key === 'Escape') {
                    setIsEditing(false);
                  }
                }}
                disabled={customPinMethods == null}
              />
              <IconButton onClick={submitNewName}>
                <CheckIcon fontSize="small" />
              </IconButton>
            </>
          ) : (
            <>
              <Typography variant="body1" fontWeight={500}>
                {group}
              </Typography>
              <IconButton
                onClick={() => {
                  setIsEditing(true);
                  setNewGroupName(group);
                }}
                size="small"
                sx={{ marginTop: '-5px' }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => {
              toggleGroupVisibility(group);
            }}
            sx={{ padding: 0.5 }}
          >
            {hiddenPinGroups.includes(group) ? (
              <VisibilityOffIcon />
            ) : (
              <VisibilityIcon />
            )}
          </IconButton>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<ListIcon />}
            onClick={onAction}
            size="small"
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              minWidth: '120px',
              marginX: 2,
            }}
          >
            View Pins ({pinCount})
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default PinGroupCard;
