import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { ReportItem } from '../services/pulse-service';

export const formatReportsToHeatmap = (
  reports: ReportItem[]
): FeatureCollection<Geometry, GeoJsonProperties> => {
  const formattedReports = {
    type: 'FeatureCollection',
    features: reports.map((report) => ({
      type: 'Feature',
      properties: report,
      geometry: { type: 'Point', coordinates: [report.lng, report.lat, 0] },
    })),
  } as FeatureCollection<Geometry, GeoJsonProperties>;

  return formattedReports;
};
