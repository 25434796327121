import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { BreakDownHoneycomb } from './BreakDownHoneycomb';
import { usePsychographicTypeDetails } from './usePsychographicTypeDetails';

interface PsychographicDetailsModalProps {
  setPsychographicTypeId: Dispatch<SetStateAction<number | null>>;
  psychographicTypeId: number | null;
}

export const PLOTR_COLORS = {
  lightOrange: '#F3B866',
  orange: '#EE8A05',
  lightBlue: '#D0DEF4',
  blue: '#90B9F7',
  purple: '#3604C2',
  darkGray: '#3B3B3B',
  gray: '#A6A6A6',
};

export const PsychographicDetailsModal = ({
  setPsychographicTypeId,
  psychographicTypeId,
}: PsychographicDetailsModalProps) => {
  const { data, isLoading } = usePsychographicTypeDetails(psychographicTypeId);

  return isLoading ? (
    <Modal
      open={!!psychographicTypeId}
      onClose={() => setPsychographicTypeId(null)}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <CircularProgress />
      </Box>
    </Modal>
  ) : (
    <Dialog
      open={!!psychographicTypeId}
      onClose={() => setPsychographicTypeId(null)}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          borderRadius: 10,
          width: 'fit-content',
          padding: 8,
        },
      }}
    >
      <DialogTitle id="id">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h4" component="h2" textAlign="center">
              {data?.code}: {data?.name}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={() => setPsychographicTypeId(null)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" flexDirection={'column'} gap={4}>
            <Box display="flex" flexDirection="row" alignItems="center" gap={8}>
              <Box sx={{ borderRadius: 8, paddingY: 2 }}>
                <img
                  src={data?.imageUrl ?? ''}
                  alt={data?.name}
                  width={300}
                  height={300}
                  style={{
                    borderRadius: 16,
                    boxShadow: '0 0 1em black',
                  }}
                />
              </Box>
              <Typography width={600}>{data?.description}</Typography>
            </Box>
            <BreakDownHoneycomb
              title="Socioeconomic Breakdown"
              items={[
                {
                  title: 'Avg. Age',
                  value: data?.age,
                  bgColor: PLOTR_COLORS.lightOrange,
                },
                {},
                {
                  title: 'Home',
                  value: data?.urbanicity,
                  bgColor: PLOTR_COLORS.gray,
                },
                {},
                {
                  title: 'Avg. Income',
                  value: `${data?.income.toLocaleString()} $`,
                  bgColor: PLOTR_COLORS.orange,
                },
                {
                  title: 'Degree',
                  value: `${data?.education} %`,
                  bgColor: PLOTR_COLORS.blue,
                },
                {
                  title: 'Technology',
                  value: data?.technology,
                  bgColor: PLOTR_COLORS.darkGray,
                },
                {
                  title: 'Political Group',
                  value: data?.politicalGroup,
                  bgColor: PLOTR_COLORS.purple,
                },
                {
                  title: 'Donations',
                  value: data?.donations,
                  bgColor: PLOTR_COLORS.gray,
                },
                {},
                {
                  title: 'Contribution',
                  value: data?.politicalContribution,
                  bgColor: PLOTR_COLORS.orange,
                },
                {},
              ]}
            />
            <BreakDownHoneycomb
              title="Consumer Behavior Breakdown"
              items={[
                {
                  title: 'Subscriptions',
                  value: data?.subscriptions,
                  bgColor: PLOTR_COLORS.lightOrange,
                },
                {},
                {
                  title: 'Banking',
                  value: data?.banking,
                  bgColor: PLOTR_COLORS.orange,
                },
                {},
                {
                  title: 'Television',
                  value: data?.television,
                  bgColor: PLOTR_COLORS.orange,
                },
                {
                  title: 'Vehicles',
                  value: data?.commonVehicles,
                  bgColor: PLOTR_COLORS.blue,
                },
                {
                  title: 'Restaurants',
                  value: data?.commonRestaurants,
                  bgColor: PLOTR_COLORS.darkGray,
                },
                {
                  title: 'Airlines',
                  value: data?.airlines,
                  bgColor: PLOTR_COLORS.gray,
                },
                {
                  title: 'Hotels',
                  value: data?.hotels,
                  bgColor: PLOTR_COLORS.gray,
                },
                {},
                {
                  title: 'Shopping',
                  value: data?.shopping,
                  bgColor: PLOTR_COLORS.purple,
                },
                {},
              ]}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
