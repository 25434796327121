import { create } from 'zustand';
import type { UserSettings } from '../services/userSettingsService';
import * as ClientService from '../services/userSettingsService';

export interface ClientStore {
  userSettings: UserSettings | Record<string, never> | null;
  error: Error | null;
  isLoading: boolean;
  fetchUserSettings(accessToken: string): Promise<void>;
}

const useSettingsStore = create<ClientStore>((set) => ({
  userSettings: null,
  error: null,
  isLoading: false,
  async fetchUserSettings(accessToken): Promise<void> {
    set({ isLoading: true, error: null });

    try {
      const userSettings = await ClientService.fetchUserSettings(accessToken);
      if (userSettings == null) {
        set({ userSettings: {}, error: null, isLoading: false });
      } else {
        set({ userSettings: userSettings, error: null, isLoading: false });
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error, isLoading: false });
      } else {
        console.error('Error fetching user settings: ', error);
        set({ error: new Error('Unknown error'), isLoading: false });
      }
    }
  },
}));

export default useSettingsStore;
