import React from 'react';
import { Box, Typography } from '@mui/material';
import { MobileDataConfig } from '~/src/constants';
import BlurTextLock from '../BlurTextLock';
import { HubspotModalType } from '../../HubspotModal';

const { LIKELIHOOD_TO_CLOSE_COLORS } = MobileDataConfig;

interface LikelihoodDisplayProps {
  likelihood: 'low' | 'medium' | 'high';
  isBlurred?: boolean;
  contextString: HubspotModalType;
}

const getLikelihoodColor = (likelihood: 'low' | 'medium' | 'high'): string => {
  switch (likelihood) {
    case 'low':
      return LIKELIHOOD_TO_CLOSE_COLORS['low'];
    case 'medium':
      return LIKELIHOOD_TO_CLOSE_COLORS['medium'];
    case 'high':
      return LIKELIHOOD_TO_CLOSE_COLORS['high'];
    default:
      // default to gray
      return '#9e9e9e';
  }
};

const LikelihoodDisplay: React.FC<LikelihoodDisplayProps> = ({
  likelihood,
  isBlurred = true,
  contextString,
}) => {
  const color = getLikelihoodColor(likelihood);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
        borderRadius: 2,
        backgroundColor: !isBlurred ? color : 'gray',
        color: 'white',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h6"
        sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
      >
        {!isBlurred ? (
          likelihood
        ) : (
          <BlurTextLock intensity={3} contextString={contextString}>
            Low
          </BlurTextLock>
        )}
      </Typography>
      <Typography fontSize={14} sx={{ color: 'rgba(255, 255, 255, 0.8)' }}>
        Likelihood to Close
      </Typography>
    </Box>
  );
};

export default LikelihoodDisplay;
