import { Autocomplete, Box, TextField } from '@mui/material';
import { debounce } from '@plotr/common-utils/src';
import {
  CustomPin,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import { useCallback, useEffect, useState } from 'react';
import usePrevious from '~/src/common/hooks/usePrevious';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import PinTerritoriesAutocomplete from '../PinTerritoriesAutocomplete';
import PinTags from './PinTags';

type GeneralTabProps = {
  pinGroups: string[];
  pin: CustomPin;
  defaultGroupName: string;
};

const GeneralTab = ({ pinGroups, pin, defaultGroupName }: GeneralTabProps) => {
  const customPinMethods = plotrMultiplayerData.methods?.pins;

  const [pinNameInput, setPinNameInput] = useState(pin?.label ?? '');
  const [pinGroupInput, setPinGroupInput] = useState(defaultGroupName);

  const selectedPinId = useDynamicMapStore(
    (state) => state.selectedCustomPinId
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps -- debounce has no deps
  const debouncedSetLabel = useCallback(
    debounce((value: string) => {
      if (customPinMethods != null && pin?.id != null) {
        customPinMethods.setLabel(pin.id, value);
      }
    }, 250),
    [pin?.id]
  );

  useEffect(() => {
    debouncedSetLabel(pinNameInput);
  }, [pinNameInput, debouncedSetLabel]);

  const previousPinLabel = usePrevious(pin?.label);
  useEffect(() => {
    if (pin?.label != null && previousPinLabel == null) {
      setPinNameInput(pin.label);
    }
  }, [pin?.label, previousPinLabel]);

  const previousPinGroup = usePrevious(pin?.group);
  useEffect(() => {
    if (pin?.group != null && previousPinGroup == null) {
      setPinGroupInput(pin.group);
    }
  }, [pin?.group, previousPinGroup]);

  const previousPinId = usePrevious(selectedPinId);
  useEffect(() => {
    if (selectedPinId === previousPinId) return;

    setPinNameInput(pin?.label ?? '');
    setPinGroupInput(pin?.group ?? defaultGroupName);
  }, [selectedPinId, previousPinId, pin, defaultGroupName]);

  const handleChangeGroup = useCallback(() => {
    if (pinGroupInput.length > 0) {
      customPinMethods?.setGroup(pin?.id ?? '', pinGroupInput);
    } else {
      customPinMethods?.setGroup(pin?.id ?? '', defaultGroupName);
      setPinGroupInput(defaultGroupName);
    }
  }, [customPinMethods, defaultGroupName, pin?.id, pinGroupInput]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      minHeight={10}
      display="flex"
      flexDirection={'column'}
      gap={2}
      mt={1}
    >
      <TextField
        id="pin-name"
        label="Pin Name"
        variant="outlined"
        fullWidth
        value={pinNameInput}
        onChange={(e) => setPinNameInput(e.target.value)}
        inputProps={{ maxLength: 40 }}
      />
      <Autocomplete
        id="pin-group"
        freeSolo
        options={pinGroups}
        inputValue={pinGroupInput}
        autoSelect
        onInputChange={(_e, value) => setPinGroupInput(value)}
        onChange={handleChangeGroup}
        onBlur={handleChangeGroup}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps }}
            label="Pin Group"
            variant="outlined"
          />
        )}
      />
      <PinTerritoriesAutocomplete pin={pin} />
      <PinTags pin={pin} />
    </Box>
  );
};

export default GeneralTab;
