export const kidstrongCustomerFacilities = [
  'Norwalk',
  'Danbury',
  'Sayville',
  'Fairfield',
  'Bethpage',
  'Livermore',
  'Westborough',
  'Powell',
  'Waldwick',
  'Wayne',
  'Middletown',
  'West Orange',
  'Coral Springs',
  'Orange CT',
  'West Plano',
  'Columbia',
  'West Babylon',
  'Oviedo',
  'Carle Place',
  'Thornwood',
  'West Chester',
  'Westside',
  'Windermere',
  'Natick',
  'Kendall',
  'Commack',
  'West Windsor',
  'Crestview Hills',
  'Bloomfield Hills',
  'Allentown',
  'Acadiana',
  'Doylestown',
  'Henderson',
  'Mason',
  'Gilbert',
  'Cotswold',
  'Sugar Land',
  'West End',
  'Prosper',
  'Plantation',
  'Raleigh',
  'Turnersville',
  'Lancaster',
  'Voorhees',
  'Orange Park',
  'Fort Meyers',
  'North Austin',
  'Virginia Beach',
  'Omaha',
  'Folsom',
  'North Hill',
  'Mount Pleasant',
  'Colorado Springs',
  'North Hills',
  'South Tampa',
  'Puyallup',
  'Timonium',
  'Highlands Ranch',
  'Lexington',
  'Summerville',
  'Collegeville',
  'Polaris',
  'Gainesville',
  'Pike Creek',
  'Nexton',
  'St. Johns',
  'Fairfax',
  'Annapolis',
  'South Austin',
  'Parker',
  'Everett',
  'Lake Oswego',
  'Oklahoma City',
  'Stone Oak',
  'Alamo Ranch',
  'Cedar Park',
  'Lake Forest',
  'Fort Collins',
  'Cary',
  'Woodbury',
  'Vestavia Hills',
  'Stratham',
  'Johns Creek',
  'Meridian',
  'Mansfield',
  'Orange',
  'Auburn',
  'Southside',
  'Naperville',
  'Frisco West',
  'Overland Park',
  'Rocklin',
  'Kenwood',
  'Greystone',
  'Batavia',
  'Palm Beach Gardens',
  'Edmond',
  'Toledo',
  'Blue Springs',
  'Carol Stream',
  'Mandeville',
  'Prairieville',
  'Juban Crossing',
  'Cibolo',
  'Elmwood Park',
  'North Scottsdale',
  'Carmel',
  'Noblesville',
  'Novi',
  'Katy',
  'Pflugerville',
  'Olathe',
  'Sun Prairie',
  'Eagan',
  'Corpus Christi',
  'Creve Coeur',
  'Frisco',
  'South Jordan',
  'McKinney',
  'Flower Mound',
  'Cypress',
  'Littleton',
  'Paradise Valley',
  'Northeast Heights',
  'Carlsbad',
  'Bothell',
  'Edmonds',
  'Burlington',
  'MacLeod Trail',
  'Symons Valley',
  'Sheerwood Park',
];
