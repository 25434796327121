import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import People from '@mui/icons-material/People';
import MobileIcon from '@mui/icons-material/PhoneIphone';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { MobileDataConfig } from '~/src/constants';
import usePermissionCheck from '~/src/global/hooks/usePermissionCheck';
import CustomDrawer from '../../custom-drawer/CustomDrawer';
import DemographicPulseData from '../../demographic-point-lookup/DemographicPulseData';
import useDemographicStore from '../../demographic-point-lookup/hooks/useDemographicStore';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import { PulseReportModal } from '../../pulse-report/PulseReportModal';
import { LoadingCard } from '../components/LoadingCard';
import ErrorFetchingData from '../components/MapLayersMenu/ErrorFetchingData';
import { MainMenuNames } from '../DynamicMapController';
import { BoundaryController } from './BoundaryController';
import MobileDataContainer from './MobileData/MobileDataContainer';
import { checkFeatureType } from './MobileData/MobileDataUtils';
import MouseOverPopover from './MobileData/MouseOverPopover';
import POIFeatureDetails from './MobileData/POIFeatureDetails';
import NoBrandDataSonarCard from './MobileData/Sonar/NoBrandDataSonarCard';
import SonarCard from './MobileData/Sonar/SonarCard';
import SonarIcon from './MobileData/Sonar/SonarIcon';
import MobileTradeAreaOverlapContainer from './MobileTradeAreaOverlapData/MobileTradeAreaOverlapContainer';
import { PsychographicsAccordion } from './PshychographicAccordion';

const {
  DEMOGRAPHIC_TITLE,
  DEMOGRAPHIC_HEADER,
  MOBILE_TITLE,
  MOBILE_VISITS_HEADER,
  MOBILE_TRADE_AREA_TITLE,
  MOBILE_TRADE_AREA_HEADER,
  SONAR_HEADER,
  SONAR_TITLE,
  MOBILE_ERROR,
  SONAR_CARD_DESCRIPTION,
  MOBILE_DESCRIPTION,
  DEMOGRAPHICS_DESCRIPTION,
  PSYCHOGRAPHICS_TITLE,
  PSYCHOGRAPHICS_DESCRIPTION,
  MOBILE_TRADE_AREA_DESCRIPTION,
} = MobileDataConfig;

export const accordionStyles = {
  '& .MuiAccordionSummary-root': {
    backgroundColor: 'rgba(217, 217, 217, 0.3)',
  },
};

export const accordionTitleStyle = {
  fontWeight: 'bold',
  marginLeft: 1,
};

export const accordionDetailStyle = {
  maxHeight: 'calc(48vh)',
  overflowY: 'scroll',
  scrollbarWidth: 'none',
};

const POIAndSonarBlockedError = () => {
  return (
    <Box
      sx={{
        border: '1px solid #FFC0CB',
        padding: 2,
        margin: 2,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      <Typography variant="body1">
        To see Mobile Visits and Sonar data, Get Pulse on a point of interest{' '}
      </Typography>
    </Box>
  );
};

export default function PulseDrawer() {
  const {
    evaluatedPinId,
    evaluatedDemographicEntity,
    setEvaluatedDemographicEntity,
    clickedPOiFeature,
    setClickedPOIFeature,
    isDrawingTerritory,
    expandedMobileDataAccordion,
    setExpandedMobileDataAccordion,
  } = useDynamicMapStore((state) => ({
    evaluatedPinId: state.evaluatedPinId,
    evaluatedDemographicEntity: state.evaluatedDemographicEntity,
    setEvaluatedDemographicEntity: state.setEvaluatedDemographicEntity,
    clickedPOiFeature: state.clickedPOiFeature,
    setClickedPOIFeature: state.setClickedPOiFeature,
    isDrawingTerritory: state.isDrawingTerritory,
    expandedMobileDataAccordion: state.expandedMobileDataAccordion,
    setExpandedMobileDataAccordion: state.setExpandedMobileDataAccordion,
  }));

  const {
    poiDataIsLoading,
    poiData,
    poiDataError,
    sonarData,
    sonarDataError,
    setMobileData,
  } = useDemographicStore((state) => ({
    poiDataIsLoading: state.poiDataIsLoading,
    poiData: state.poiData,
    poiDataError: state.poiDataError,
    sonarData: state.sonarData,
    sonarDataError: state.sonarDataError,
    setMobileData: state.setMobileData,
  }));

  const [, setActiveMenu] = useState<MainMenuNames>(MainMenuNames.Layers);
  const [shouldDrawerBeOpen, setShouldDrawerBeOpen] = useState(false);

  const toggleDrawer = () => {
    setClickedPOIFeature(null);
    setEvaluatedDemographicEntity(null);
    setShouldDrawerBeOpen(false); // Immediately set drawer to closed
  };

  useEffect(() => {
    if (evaluatedPinId != null) {
      setActiveMenu(MainMenuNames.Points);
    }
  }, [evaluatedPinId]);

  useEffect(() => {
    const shouldOpen =
      (evaluatedDemographicEntity != null || clickedPOiFeature != null) &&
      !isDrawingTerritory;
    setShouldDrawerBeOpen(shouldOpen);
  }, [evaluatedDemographicEntity, clickedPOiFeature, isDrawingTerritory]);

  useEffect(() => {
    setMobileData(null);
  }, [evaluatedDemographicEntity, clickedPOiFeature, setMobileData]);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedMobileDataAccordion(isExpanded ? panel : false);
    };

  const isPoi = useMemo(() => {
    if (!clickedPOiFeature) return false;
    const featureType = checkFeatureType(clickedPOiFeature);

    if (featureType.isPOI && !featureType.isTerritory) {
      return true;
    }

    return false;
  }, [clickedPOiFeature]);

  const hasAdminPermission = usePermissionCheck('read:plotr_admin');

  return (
    <CustomDrawer
      drawerOpen={shouldDrawerBeOpen}
      handleClose={toggleDrawer}
      showCloseButton
    >
      {shouldDrawerBeOpen ? ( // Only render content when the drawer is open
        <Box
          flex={1}
          marginY={2}
          overflow="hidden"
          position="relative"
          height="100%"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <BoundaryController />
          {isPoi ? (
            <>
              {poiDataIsLoading ? (
                // Show loading spinner while data is being fetched
                <LoadingCard />
              ) : poiData ? (
                // Show the POI details when data is available
                <Box
                  position="sticky"
                  top={0}
                  zIndex={1}
                  sx={{ backgroundColor: 'white', paddingBottom: 2 }}
                >
                  <POIFeatureDetails />
                </Box>
              ) : poiDataError ? (
                // Show error component if there is an error fetching data
                <ErrorFetchingData
                  errorContent={poiDataError ?? MOBILE_ERROR}
                />
              ) : (
                // Fallback if no data and no error
                <></>
              )}

              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Accordion
                  expanded={expandedMobileDataAccordion === 'demographic'}
                  onChange={handleAccordionChange('demographic')}
                  sx={accordionStyles}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="demographic-content"
                    id={DEMOGRAPHIC_HEADER}
                  >
                    <People />
                    <Typography sx={accordionTitleStyle}>
                      {DEMOGRAPHIC_TITLE}
                    </Typography>
                    <Box marginLeft={1}>
                      <MouseOverPopover text={DEMOGRAPHICS_DESCRIPTION}>
                        <InfoOutlined style={{ color: 'gray' }} />
                      </MouseOverPopover>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={accordionDetailStyle}>
                    <DemographicPulseData />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedMobileDataAccordion === 'mobileVisits'}
                  onChange={handleAccordionChange('mobileVisits')}
                  sx={accordionStyles}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="mobileVisits-content"
                    id={MOBILE_VISITS_HEADER}
                  >
                    <MobileIcon />

                    <Typography sx={accordionTitleStyle}>
                      {MOBILE_TITLE}
                    </Typography>
                    <Box marginLeft={1}>
                      <MouseOverPopover text={MOBILE_DESCRIPTION}>
                        <InfoOutlined style={{ color: 'gray' }} />
                      </MouseOverPopover>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={accordionDetailStyle}>
                    <MobileDataContainer />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expandedMobileDataAccordion === 'sonar'}
                  onChange={handleAccordionChange('sonar')}
                  sx={accordionStyles}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="sonar-content"
                    id={SONAR_HEADER}
                  >
                    <SonarIcon />
                    <Typography
                      color="black"
                      gutterBottom
                      align="left"
                      sx={{ fontWeight: 'bold', marginLeft: 1 }}
                    >
                      {SONAR_TITLE}
                    </Typography>
                    <Box marginLeft={1}>
                      <MouseOverPopover text={SONAR_CARD_DESCRIPTION}>
                        <InfoOutlined style={{ color: 'gray' }} />
                      </MouseOverPopover>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={accordionDetailStyle}>
                    {sonarData !== null ? (
                      <SonarCard />
                    ) : (
                      <NoBrandDataSonarCard />
                    )}
                  </AccordionDetails>
                </Accordion>
                <PsychographicsAccordion
                  title={PSYCHOGRAPHICS_TITLE}
                  description={PSYCHOGRAPHICS_DESCRIPTION}
                  handleAccordionChange={handleAccordionChange}
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {evaluatedPinId && <PulseReportModal />}
              <Accordion
                expanded={expandedMobileDataAccordion === 'demographic'}
                onChange={handleAccordionChange('demographic')}
                sx={accordionStyles}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="demographic-content"
                  id={DEMOGRAPHIC_HEADER}
                >
                  <People />
                  <Typography sx={accordionTitleStyle}>
                    {DEMOGRAPHIC_TITLE}
                  </Typography>
                  <Box marginLeft={1}>
                    <MouseOverPopover text={DEMOGRAPHICS_DESCRIPTION}>
                      <InfoOutlined style={{ color: 'gray' }} />
                    </MouseOverPopover>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailStyle}>
                  <DemographicPulseData />
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expandedMobileDataAccordion === 'mobileVisits'}
                onChange={handleAccordionChange('mobileVisits')}
                sx={accordionStyles}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="mobileVisits-content"
                  id={MOBILE_VISITS_HEADER}
                >
                  <MobileIcon />
                  <Typography sx={accordionTitleStyle}>
                    {MOBILE_TITLE}
                  </Typography>
                  <Box marginLeft={1}>
                    <MouseOverPopover text={MOBILE_DESCRIPTION}>
                      <InfoOutlined style={{ color: 'gray' }} />
                    </MouseOverPopover>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailStyle}>
                  <POIAndSonarBlockedError />
                </AccordionDetails>
              </Accordion>

              {hasAdminPermission &&
                evaluatedDemographicEntity?.type === 'point' && (
                  <Accordion
                    expanded={
                      expandedMobileDataAccordion === 'mobileTradeAreaOverlap'
                    }
                    onChange={handleAccordionChange('mobileTradeAreaOverlap')}
                    sx={accordionStyles}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="mobileTradeArea-content"
                      id={MOBILE_TRADE_AREA_HEADER}
                    >
                      <MobileIcon />
                      <Typography sx={accordionTitleStyle}>
                        {MOBILE_TRADE_AREA_TITLE}
                      </Typography>
                      <Box marginLeft={1}>
                        <MouseOverPopover text={MOBILE_TRADE_AREA_DESCRIPTION}>
                          <InfoOutlined style={{ color: 'gray' }} />
                        </MouseOverPopover>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={accordionDetailStyle}>
                      <MobileTradeAreaOverlapContainer />
                    </AccordionDetails>
                  </Accordion>
                )}
              <Accordion
                expanded={expandedMobileDataAccordion === 'sonar'}
                onChange={handleAccordionChange('sonar')}
                sx={accordionStyles}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="sonar-content"
                  id={SONAR_HEADER}
                >
                  <SonarIcon />
                  <Typography
                    color="black"
                    gutterBottom
                    align="left"
                    sx={{ fontWeight: 'bold', marginLeft: 1 }}
                  >
                    {SONAR_TITLE}
                  </Typography>
                  <Box marginLeft={1}>
                    <MouseOverPopover text={SONAR_CARD_DESCRIPTION}>
                      <InfoOutlined style={{ color: 'gray' }} />
                    </MouseOverPopover>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={accordionDetailStyle}>
                  {isPoi ? (
                    sonarData !== null ? (
                      <SonarCard />
                    ) : (
                      <ErrorFetchingData
                        errorContent={sonarDataError ?? MOBILE_ERROR}
                      />
                    )
                  ) : (
                    <NoBrandDataSonarCard />
                  )}
                </AccordionDetails>
              </Accordion>
              <PsychographicsAccordion
                title={PSYCHOGRAPHICS_TITLE}
                description={PSYCHOGRAPHICS_DESCRIPTION}
                handleAccordionChange={handleAccordionChange}
              />
            </Box>
          )}
        </Box>
      ) : (
        <></>
      )}
    </CustomDrawer>
  );
}
