import { create } from 'zustand';
import type { CensusBlockGroupSet } from '../services/blockGroupsService';
import * as BlockGroupsService from '../services/blockGroupsService';

export interface BlockGroupsStore {
  blockGroups: CensusBlockGroupSet[];
  error: Error | null;
  isLoading: boolean;
  fetchBlockGroups(apiPath: string): Promise<void>;
}

const useBlockGroupsStore = create<BlockGroupsStore>((set) => ({
  blockGroups: [],
  error: null,
  isLoading: false,
  async fetchBlockGroups(apiPath: string): Promise<void> {
    set({ isLoading: true, error: null });

    try {
      const blockGroups = await BlockGroupsService.fetchBlockGroups(apiPath);

      set({ blockGroups, error: null, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error, isLoading: false });
      } else {
        console.error('Error fetching block groups: ', error);
        set({ error: new Error('Unknown error'), isLoading: false });
      }
    }
  },
}));

export default useBlockGroupsStore;
