import { parseEnv } from '@plotr/common-utils/src';
import axios from 'axios';
import { useQuery } from 'react-query';
import {
  SimilarityData,
  SimilarityReportParams,
} from '../types/customerSummary';

const SIMILARITY_CARD_DATA_QUERY_KEY = 'similarityCardData';

const { API_V2: API_V2_URL } = parseEnv({
  API_V2: process.env.API_V2,
});
export const getCustomerSummaryData = async (
  params: SimilarityReportParams | null
) => {
  const response = await axios.post<SimilarityData>(
    `${API_V2_URL}/similarity-report`,
    params
  );

  return response.data;
};

export const useCustomerSummary = (params: SimilarityReportParams | null) => {
  return useQuery({
    queryKey: [SIMILARITY_CARD_DATA_QUERY_KEY, params],
    queryFn: () => getCustomerSummaryData(params),
    enabled: !!params,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });
};
