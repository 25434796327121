import getPinURL from '~/src/common/helpers/getPinURL';

interface MakeReportPinElementProps {
  isSelected: boolean;
  color?: string;
}

export const makeReportPinElement = ({
  isSelected = false,
  color = '#3FB1CE',
}: MakeReportPinElementProps): HTMLElement => {
  const pinURL = getPinURL({
    color: '#C3C3C3',
    background: '#FFFFFF',
  });

  const selectedPinUrl = getPinURL({
    color,
    background: '#FFFFFF',
  });

  // Create the main div element for the pin
  const pinElement = document.createElement('div');
  pinElement.style.display = 'flex';
  pinElement.style.flexDirection = 'column';
  pinElement.style.alignItems = 'center';
  pinElement.style.cursor = 'pointer';
  pinElement.style.transform = 'scale(0.35)';
  pinElement.style.transformOrigin = 'center bottom';

  // Create the image element for the pin
  const imgElement = document.createElement('img');
  imgElement.style.width = '2rem';
  imgElement.style.height = 'auto';
  imgElement.src = isSelected ? selectedPinUrl : pinURL;
  imgElement.alt = 'custom pin';

  // Append the image to the div
  pinElement.appendChild(imgElement);

  return pinElement;
};
