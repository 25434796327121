import { Box, CircularProgress, Typography } from '@mui/material';
import { useMemo } from 'react';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import ErrorFetchingData from '../../components/MapLayersMenu/ErrorFetchingData';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import StatDisplay from '../MobileData/StatDisplay';
import { MobileDataConfig } from '~/src/constants';
import { formatPercentage } from '~/src/common/helpers/formatPercentage';
import { OverlapPerPin } from './OverlapPerPin';

const MobileTradeAreaOverlapData: React.FC = () => {
  const { TOTAL_OVERLAP, OVERLAP_PER_PIN_LABEL, MOBILE_STAT_FONT_SIZE } =
    MobileDataConfig;

  const {
    tradeAreaOverlapData,
    tradeAreaOverlapDataError,
    tradeAreaOverlapDataIsLoading,
  } = useDemographicStore((state) => ({
    tradeAreaOverlapData: state.tradeAreaOverlapData,
    tradeAreaOverlapDataError: state.tradeAreaOverlapDataError,
    tradeAreaOverlapDataIsLoading: state.tradeAreaOverlapDataIsLoading,
  }));

  const customPins = useCustomPins();

  const overlapPerPin = useMemo(() => {
    return Object.entries(tradeAreaOverlapData?.overlapPerPinId ?? {})
      .flatMap(([pinId, overlapFraction]) => {
        const pin = customPins.find((pin) => pin.id === pinId);

        if (!pin) return [];

        return {
          label: pin.label,
          value: overlapFraction,
        };
      })
      .sort((a, b) => (b.value.percentage || 0) - (a.value.percentage || 0));
  }, [tradeAreaOverlapData?.overlapPerPinId, customPins]);

  return (
    <>
      {tradeAreaOverlapDataError ? (
        <ErrorFetchingData
          errorContent={tradeAreaOverlapDataError}
          sx={{ marginTop: 0 }}
        />
      ) : tradeAreaOverlapDataIsLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          mt={3}
        >
          <CircularProgress />
        </Box>
      ) : (
        tradeAreaOverlapData && (
          <StatDisplay
            value={
              <Typography
                component="span"
                fontWeight="bold"
                fontSize={MOBILE_STAT_FONT_SIZE}
              >
                {formatPercentage(tradeAreaOverlapData.overallOverlap)}
              </Typography>
            }
            label={TOTAL_OVERLAP}
            sx={{ flex: 1 }}
            description={OVERLAP_PER_PIN_LABEL}
          >
            <OverlapPerPin data={overlapPerPin} hideVisits />
          </StatDisplay>
        )
      )}
    </>
  );
};

export default MobileTradeAreaOverlapData;
