import {
  LayerConfig,
  LayerType,
} from '~/src/features/dynamic-map-controller/components/MapLayersMenu/';
import { CensusMatchButtonGroup } from '../components/layerControllers/CensusMatchButtonGroup';

export const getEnterpriseLayers = (
  heatmapLayerIds: string[],
  blockGroupMatchLayerIds: string[]
): {
  retail_proximity_heatmap?: LayerConfig;
  block_group_match?: LayerConfig;
} => {
  return {
    retail_proximity_heatmap: {
      type: LayerType.Enterprise,
      featureType: 'polygon',
      displayName: 'Retail Proximity Heatmap',
      component: { layerIds: heatmapLayerIds },
      styleConfig: {
        colors: ['#008000', '#FFFF00', '#FFA500', '#FF8C00', '#FF0000'],
        threshold: [8, 6, 4, 2, 0],
        unit: 'cells',
        boundLabels: ['Far', 'Near'],
      },
      permission: 'read:enterprise_retail_proximity',
      category: 'Retail.RetailProximity',
      groupName: 'custom_layers',
    },
    block_group_match: {
      type: LayerType.Enterprise,
      featureType: 'polygon',
      displayName: 'Customer Block Group Match',
      component: { layerIds: blockGroupMatchLayerIds },
      styleConfig: {
        threshold: ['C', 'B', 'A'],
        colors: [
          'rgba(54, 4, 194, 0.3)',
          'rgba(54, 4, 194, 0.65)',
          'rgba(54, 4, 194, 1)',
        ],
        unit: 'match grade',
      },
      permission: 'read:enterprise_bg_match',
      category: 'Miscellaneous',
      groupName: 'custom_layers',
      controllableComponent: CensusMatchButtonGroup,
    },
  };
};
