import { Download as DownloadIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';
import { FileResponse } from '../files-service';

const env = parseEnv({
  API_V2: process.env.API_V2,
});

export const DownloadFileButton = ({ file }: { file: FileResponse }) => {
  const handleDownloadFile = async () => {
    const response = await axios.get(
      `${env.API_V2}/user-uploads/${file.id}/download`,
      {
        responseType: 'blob',
      }
    );
    const blob = response.data;
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.download = file.s3Key || 'downloaded-file';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Tooltip title="Download file">
      <IconButton onClick={handleDownloadFile}>
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
};
