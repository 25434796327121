import { Box, ToggleButton, Typography } from '@mui/material';

import { ToggleButtonGroup } from '@mui/material';
import tinycolor from 'tinycolor2';
import getMatchOpacity from '~/src/features/dynamic-map/helpers/getMatchOpacity';
import useBlockGroupsStore from '~/src/features/dynamic-map/hooks/useBlockGroupStore';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';

export const CensusMatchButtonGroup = () => {
  const blockGroups = useBlockGroupsStore((state) => state.blockGroups);
  const selectedBlockGroups = useDynamicMapStore(
    (state) => state.selectedBlockGroups
  );
  const setSelectedBlockGroups = useDynamicMapStore(
    (state) => state.setSelectedBlockGroups
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        pt: 1,
        alignItems: 'center',
      }}
    >
      <ToggleButtonGroup
        value={selectedBlockGroups}
        onChange={(_, selectedGroups: string[]) => {
          setSelectedBlockGroups(selectedGroups);
        }}
        aria-label="toggle layer groups"
        exclusive={false}
        sx={{ marginRight: 1 }}
      >
        {blockGroups.map((blockGroup) => {
          const backgroundColor = `rgba(54, 4, 194, ${getMatchOpacity(
            blockGroup.groupName
          )})`;
          const textColor =
            tinycolor(backgroundColor).getLuminance() < 0.6 ? '#fff' : '#000';
          const hoverColor = tinycolor(backgroundColor)
            .darken(10)
            .toHexString();

          return (
            <ToggleButton
              key={blockGroup.groupName}
              value={`block_group_match-${blockGroup.groupName}`}
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.action.disabledBackground,
                '&.Mui-selected': {
                  backgroundColor,
                  color: textColor,
                  '&:hover': {
                    backgroundColor: hoverColor,
                  },
                },
              }}
            >
              {blockGroup.groupName}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <Typography component="span">
        {blockGroups ? 'Block Group Match Grade' : 'Census Tract Match Grade'}
      </Typography>
    </Box>
  );
};
