export const MobileDataConfig = {
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_ERROR: 'The end date must be after the start date',
  MOBILE_DATA_READ_PERMISSION: 'read:mobile',
  INITIAL_START_DATE:
    new Date(new Date().setMonth(new Date().getMonth() - 4))
      .toISOString()
      .split('-')
      .slice(0, 2)
      .join('-') + '-01',
  INITIAL_END_DATE:
    new Date(new Date().setMonth(new Date().getMonth() - 3))
      .toISOString()
      .split('-')
      .slice(0, 2)
      .join('-') + '-30',
  LAST_12_MONTHS_START_DATE:
    new Date(new Date().setMonth(new Date().getMonth() - 12))
      .toISOString()
      .split('-')
      .slice(0, 2)
      .join('-') + '-01',
  MIN_DATE: '2019-01-01',
  MAX_DATE: new Date().toISOString().split('T')[0],
  MAX_RANKING_YEAR: '2024',
  MAX_TREND_YEAR: '2023',
  MIN_TREND_YEAR: '2020',
  TOTAL_VISITS: 'Total Visits',
  TOTAL_VISITS_TREND: 'Total Visits YoY',
  AVG_DAILY_VISITORS: 'Avg. Daily Visits',
  DEMOGRAPHIC_TITLE: 'Demographics',
  DEMOGRAPHIC_HEADER: 'demographic-header',
  MOBILE_TITLE: 'Mobile Visits',
  MOBILE_DATA_DUMMY_NUMBER: 1000,
  MOBILE_VISITS_HEADER: 'mobile-visits-header',
  MOBILE_TRADE_AREA_TITLE: 'Mobile Trade Area Overlap',
  MOBILE_TRADE_AREA_HEADER: 'mobile-trade-area-overlap-header',
  MOBILE_TRADE_AREA_DESCRIPTION:
    'The mobile trade area overlap represents the percentage of mobile visitors that visited both this location and the selected competitor location within the selected time frame.',
  SONAR_TITLE: 'Sonar',
  SONAR_HEADER: 'sonar-header',
  CBSA_LABEL: '(CBSA)',
  VISITORS_STAT_LABEL: 'Number of total visits in the selected time frame',
  TOTAL_OVERLAP: 'Total Overlap',
  OVERLAP_PER_PIN_LABEL:
    'Percentage of mobile visitors that can visit both the evaluated pin and surrounding ones',
  AVG_DAILY_VISITORS_STAT_LABEL:
    'Average number of visits per day at this location',
  MARKET_RANKING: 'Market Rank',
  MARKET_RANKING_DESCRIPTION: (cbsaMarket: string) =>
    `The market ranking for this location represents its position relative to other locations in the ${cbsaMarket} market in 2023.`,
  NATIONAL_RANKING: 'National Rank',
  NATIONAL_RANKING_DESCRIPTION: (locationName: string) =>
    `The national ranking for this location represents its position relative to other ${locationName} locations across the country in 2023.`,
  NATIONAL_RANKING_PERCENTILE: 'National Rank Percentile',
  MARKET_RANKING_PERCENTILE: 'Market Rank Percentile',
  SONAR_LIKELIHOOD_TO_CLOSE: 'Likelihood to Close',
  MOBILE_ERROR:
    ' We apologize. There was an error while fetching data from the backend. Please try again later. We apologize for the inconvenience.',
  MOBILE_DATES_NOT_AVAILABLE:
    'We apologize. There is no data available for the selected date range. Please try a different date range.',
  MOBILE_STAT_FONT_SIZE: 22,
  MOBILE_TRENDING_STAT_FONT_SIZE: 16,
  MOBILE_TOTAL_RANKING_NUMBER_FONT_SIZE: 14,
  MOBILE_RANKING_PERCENTAGE_FONT_SIZE: 18,
  MOBILE_RANKING_PERCENTAGE_LABEL_FONT_SIZE: 11,
  MOBILE_DATA_DESCRIPTION_LABEL_FONT_SIZE: 10,
  LIKELIHOOD_TO_CLOSE_COLORS: {
    low: '#4caf50', // Green for low
    medium: '#ff9800', // Orange for medium
    high: '#f44336', // Red for high
  },
  NATIONAL_RANKING_BAR_FILL_COLOR: {
    red: '#FF6347', // Red
    orange: '#FFA07A', // Orange
    yellow: '#FFFF00', // Yellow
    lightGreen: '#99CC99', // Light Green
    green: '#008000', // Green
  },
  SONAR_CARD_DESCRIPTION:
    'Sonar analyzes key performance factors to assess the likelihood of a store closing in the near future. By identifying stores at risk of closure, you can proactively identify new opportunities for growth, company and market health, and potential risk patterns. The sonar data presented below is for the year 2023. ',
  DEMOGRAPHICS_DESCRIPTION:
    'Demographics offer key insights into the characteristics of a population, including age, income, education, and household size. Understanding these trends helps businesses tailor strategies for site selection, marketing, and product offerings to better align with local preferences and behaviors.',
  MOBILE_DESCRIPTION:
    'Mobile data tracks movement patterns through GPS-enabled devices, providing real-time insights into foot traffic and customer behavior. Analyzing this data alongside historical trends offers a clear view of visitor patterns over time, helping you identify peak times, shifts in preferences, and the impact of external factors.',
  EMAIL_TO_JORDAN_SUBJECT: (feature: string) =>
    `Requesting access to ${feature}`,

  EMAIL_TO_JORDAN_BODY: (feature: string) =>
    `Hi Jordan, I am reaching out to request access to the ${feature} feature.
  Thank You,
  [Your Name]
  [Your Company]
    `,

  PSYCHOGRAPHICS_TITLE: 'Psychographics',
  PSYCHOGRAPHICS_DESCRIPTION:
    'Psychographics is a way to understand the behavior and attitudes of people in a specific area. It is a way to understand the behavior and attitudes of people in a specific area.',
  MAX_SURROUNDING_PIN_DISTANCE: 50,
};

export const PulseReportConfig = {
  EVALUATED_PIN_COLOR: '#00C957',
};

export const SimilarityDataConfig = {
  TITLE: 'Site Summary',
  DESCRIPTION:
    'This summary provides an analysis of key site metrics compared to benchmark values. The qualification status is determined by the number of indicators that meet or exceed these benchmarks.',
  ICON_SIZE: 48,
  DIVIDER_COLOR: 'black',
  BACKGROUND_COLOR: '#ebf3ff',
  DATA_VALUES_COLORS: ['#ee773b', '#4687e3'],
};

export const HUBSPOT = {
  REGION: 'na1',
  PORTAL_ID: '21646637',
  SONAR: '94379c92-fbd8-4860-9d3c-98198ae4206c',
  MOBILE: '9284bb3b-3203-4865-adda-ac27aeea1679',
};

export const ERROR_MESSAGES = {
  INVALID_INPUT_PARAMS:
    'Invalid input parameters passed in, please check and retry',
  INTERNAL_SERVER: 'Internal Server Error',
  SONAR: {
    NOT_SUPPORTED_FOR_BRAND:
      'Sonar is not currently supported by this brand at this time.',
    NATIONAL_RANKING_DATA_NOT_FOUND:
      'National ranking data was not found for this POI',
    CBSA_NAME_NOT_FOUND: 'The CBSA name was not found for this POI',
    CBSA_RANKING_DATA_NOT_FOUND: 'CBSA ranking data was not found for this POI',
    CATCH_ALL: 'There was an error fetching sonar data.',
  },
};

export const layerOpacityMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];

export const customDayTimeTrafficMarks = [...Array(24)].map((_, index) => {
  const hour = index === 0 || index === 12 ? 12 : index % 12;
  const period = index < 12 ? 'AM' : 'PM';
  return { value: index, label: index % 4 === 0 ? `${hour}${period}` : '' };
});

export enum Permissions {
  READ_PLOTR_ADMIN = 'read:plotr_admin',
  READ_PRO = 'read:pro',
  READ_IDEALSPOT_REPORTING = 'read:idealspot_reporting',
  READ_TRAFFIC = 'read:traffic',
  READ_MOBILE_DATA = 'read:mobile',
  READ_SONAR_DATA = 'read:sonar',
  READ_MOBILE_TRADE_AREA_OVERLAP = 'read:mobile-trade-area-overlap',
}
