import { Box, IconButton, Popper } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import CustomColorPicker from './CustomColorPicker';

export const IconPickerButton = ({
  itemId,
  selectedItemId,
  setSelectedItemId,
  externalColor,
  setExternalColor,
}: {
  itemId: string;
  selectedItemId: string | null;
  setSelectedItemId: (id: string | null) => void;
  externalColor: string;
  setExternalColor: (color: string) => void;
}) => {
  const anchorEl = useRef<HTMLButtonElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const [color, setColor] = useState(externalColor);

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node) &&
        !anchorEl.current?.contains(event.target as Node)
      ) {
        setSelectedItemId(null);
      }
    },
    [popperRef, setSelectedItemId]
  );

  useEffect(() => {
    if (itemId === selectedItemId) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }
  }, [handleOutsideClick, itemId, selectedItemId]);

  return (
    <Box>
      <IconButton
        ref={anchorEl}
        onClick={() => {
          setSelectedItemId(selectedItemId === itemId ? null : itemId);
        }}
        size="small"
        sx={{
          width: 36,
          height: 36,
          color: 'transparent',
          backgroundColor: color,
          marginRight: 1,
          marginLeft: 1,
          '&:hover': { backgroundColor: color },
        }}
      >
        <Box width={24} height={24} />
      </IconButton>
      <Popper
        open={selectedItemId === itemId}
        anchorEl={anchorEl.current}
        placement="auto-start"
        sx={{ zIndex: 1000 }}
        ref={popperRef}
      >
        <CustomColorPicker
          width={'15rem'}
          disableAlpha
          color={color}
          onChange={(color) => {
            setColor(color.hex);
            setExternalColor(color.hex);
          }}
        />
      </Popper>
    </Box>
  );
};
