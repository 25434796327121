import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Chip, Grid, TextField, Typography } from '@mui/material';
import {
  PinTemplate,
  plotrMultiplayerData,
} from '@plotr/plotr-multiplayer-data/src';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ValidateTemplateSchema, {
  ValidateTemplateSchemaType,
} from '~/src/validation/templates.schema';
import KeyValueTable from '../../demographic-point-lookup/components/KeyValueTable';

type EditTemplateFormProps = {
  template: PinTemplate | { id: string };
  closeForm?: () => void;
};

const EditTemplateForm = ({
  template,
  closeForm = () => {},
}: EditTemplateFormProps) => {
  const templateMethods = plotrMultiplayerData.methods?.templates;
  const [tagsInput, setTagsInput] = useState('');

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<ValidateTemplateSchemaType>({
    resolver: zodResolver(ValidateTemplateSchema),
    defaultValues: {
      ...(template as PinTemplate),
    },
  });

  const handleSave: SubmitHandler<ValidateTemplateSchemaType> = (
    data: ValidateTemplateSchemaType
  ) => {
    const newTemplate: PinTemplate = {
      id: template?.id ?? crypto.randomUUID(),
      label: data.label,
      group: data.group,
      tags: data.tags,
      keyValuePairs: data.keyValue,
    };

    templateMethods?.checkTemplateExists(newTemplate?.id)
      ? templateMethods?.updateTemplate(newTemplate)
      : templateMethods?.addTemplate(newTemplate);
    closeForm();
  };

  return (
    <Box display="flex" flexDirection={'column'} gap={2}>
      <Box component={'form'} onSubmit={handleSubmit(handleSave)}>
        <Controller
          control={control}
          name="label"
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.label}
              fullWidth
              label="Label"
              margin="normal"
            />
          )}
        />
        {errors.label?.message && (
          <Typography color="red">{errors.label.message}</Typography>
        )}
        <Controller
          control={control}
          name="group"
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.group}
              fullWidth
              label="Group"
              margin="normal"
            />
          )}
        />
        {errors.group?.message && (
          <Typography color="red">{errors.group.message}</Typography>
        )}
        <Controller
          control={control}
          name="tags"
          render={({ field }) => (
            <Grid container spacing={1} mb={1} alignItems="center">
              <Grid item xs={4} mt={1}>
                <TextField
                  id="tag"
                  label="Add Tag"
                  variant="outlined"
                  size="small"
                  value={tagsInput}
                  error={!!errors.tags}
                  onChange={(e) => setTagsInput(e.target.value)}
                  inputProps={{ maxLength: 30 }}
                  onKeyDown={(keyEvent) => {
                    const finalValue = tagsInput.toLowerCase().trim();
                    if (keyEvent.key === 'Enter' && finalValue.length > 0) {
                      if (field.value?.includes(finalValue)) {
                        setError('tags', { message: 'Tag already exists' });
                      } else {
                        clearErrors('tags');
                        setTagsInput('');
                        field?.onChange([...(field.value ?? []), finalValue]);
                      }
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {field.value?.map((tag) => (
                <Grid item key={tag}>
                  <Chip
                    size="small"
                    label={tag}
                    color="primary"
                    onDelete={() => {
                      field.onChange([
                        ...(field.value ?? []).filter((t) => t !== tag),
                      ]);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        />
        {errors.tags?.message && (
          <Typography color="red">{errors.tags.message}</Typography>
        )}
        <Controller
          control={control}
          name="keyValue"
          render={({ field }) => (
            <KeyValueTable
              data={new Map(Object.entries(field.value || {}))}
              addKeyValuePair={(key, value) =>
                field.onChange({ ...field.value, [key]: value })
              }
              updateKeyValuePair={(key, value) =>
                field.onChange({ ...field.value, [key]: value })
              }
              removeKeyValuePair={(key) =>
                field.onChange({ ...field.value, [key]: undefined })
              }
            />
          )}
        />
      </Box>
      <Box ml={'auto'}>
        <Button
          color="primary"
          variant="outlined"
          type="submit"
          onClick={handleSubmit(handleSave)}
        >
          Save
        </Button>
        <Button sx={{ marginLeft: 1 }} color="secondary" onClick={closeForm}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default EditTemplateForm;
