import {
  Close as CloseIcon,
  Download as DownloadIcon,
  QuestionMark,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { CustomerSummary } from '~/src/common/components/CustomerSummary';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import CreatePulseTerritoryModal from '../demographic-point-lookup/components/CreatePulseTerritoryModal';
import useDemographicStore, {
  searchSettings,
} from '../demographic-point-lookup/hooks/useDemographicStore';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';
import { TradeAreaMap } from './TradeAreaMap';
import { CompetitorsSection } from './competitor-map/CompetitorsSection';
import { GeneratorsSection } from './generators-map/GeneratorsSection';
import { useReportLoadingStates } from './useReportLoadingStates';

import { parseEnv } from '@plotr/common-utils/src';
import { startOfDay, startOfMonth, subYears } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { MapRef } from 'react-map-gl';
import {
  Similarity,
  SimilarityData,
} from '~/src/common/components/CustomerSummary/types';
import { PlacesRequest } from '../dynamic-map/services/poiService';
import { getMapImage } from './helpers/getMapImage';
import { prepareCompetitorsData } from './helpers/prepareCompretitorsData';
import usePulseReportStore, { DataSections } from './hooks/usePulseReportStore';
import { useCustomerSummary } from './services/useCustomerSummary';
import {
  SimilarityInsightType,
  SimilarityInsightTypeIcon,
  SimilarityReportParams,
} from './types/customerSummary';
import { InitialViewState } from './types/initialViewState';

const PDF_PADDING = 12.7;
const PDF_PAGE_WIDTH = 457;
const PDF_PAGE_HEIGHT = 285;

const env = parseEnv({
  MAPBOX_API_KEY: process.env.MAPBOX_API_KEY,
});

export const PulseReportModal = () => {
  const { mapRefs, mapInitialViewStates, mapPins } = usePulseReportStore(
    (state) => ({
      mapRefs: state.mapRefs,
      mapInitialViewStates: state.mapInitialViewStates,
      mapPins: state.mapPins,
    })
  );
  const [isMapOpen, setIsMapOpen] = useState(false);
  const userSettings = useSettingsStore((state) => state.userSettings);
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const { boundaryData, boundaryType, searchRadius } = useDemographicStore(
    (state) => ({
      boundaryData: state.boundaryData,
      boundaryType: state.boundaryType,
      searchRadius: state.demographicSearchRadius,
    })
  );
  const pins = useCustomPins();
  const evaluatedPin = pins.find((pin) => pin.id === evaluatedPinId);
  const brandConfig = Object.values(userSettings?.brandConfigs ?? {})[0];

  const canFetchCustomerSummary = evaluatedPin && brandConfig;

  const today = startOfMonth(startOfDay(new Date()));
  const oneYearAgo = subYears(today, 1);

  const [isPdfProcessing, setIsPdfProcessing] = useState(false);

  const { competitors, generators } = useMemo(
    () => prepareCompetitorsData(userSettings),
    [userSettings]
  );

  const customerSummaryParams: SimilarityReportParams | null =
    canFetchCustomerSummary
      ? {
          evaluatedLocation: {
            latitude: evaluatedPin.pos.lat,
            longitude: evaluatedPin.pos.lng,
          },
          boundaryParams: {
            type: boundaryType,
            value: searchRadius,
            tam: brandConfig.settings.tam,
          },
          searchQuery: brandConfig.searchQuery,
          dateRange: {
            startDate: oneYearAgo.toISOString().split('T')[0],
            endDate: today.toISOString().split('T')[0],
          },
          userSettings: {
            competitors: {
              brands: competitors.brands
                .filter((brand) => brand !== undefined)
                .map((brand) => ({
                  brandId: brand.brandId,
                  website: brand.website ?? '',
                })),
              queries: competitors.queries
                .filter((query) => query !== undefined)
                .map((query) => ({
                  id: query.id,
                  name: query.name,
                  query: query.query,
                })),
            },
            generators: {
              brands: generators.brands
                .filter((brand) => brand !== undefined)
                .map((brand) => ({
                  brandId: brand.brandId,
                  website: brand.website ?? '',
                })),
              queries: generators.queries
                .filter((query) => query !== undefined)
                .map((query) => ({
                  id: query.id,
                  name: query.name,
                  query: query.query,
                })),
            },
          },
        }
      : null;

  const customerSummary = useCustomerSummary(
    isMapOpen ? customerSummaryParams : null
  );
  const insights = Object.entries(customerSummary.data?.insights ?? {});
  const totalSimilarity = customerSummary.data
    ? Object.values(customerSummary.data?.insights ?? {})
        .flat()
        .reduce((acc, curr) => acc + curr.similarity, 0) / insights.length
    : null;
  const similarities: SimilarityData[] = useMemo(
    () =>
      insights.flatMap(([category, categoryInsights]) => {
        const icon =
          SimilarityInsightTypeIcon[category as SimilarityInsightType] ??
          QuestionMark;

        return categoryInsights.map((insight) => {
          const isComposedInsight = 'subInsights' in insight;

          if (isComposedInsight) {
            const subInsights: Similarity[] = insight.subInsights.map(
              (subInsight) => ({
                icon,
                title: subInsight.title,
                similarity: subInsight.similarity,
                variance: subInsight.variance,
                dataValues: [
                  subInsight.evaluatedValue,
                  ...(subInsight.benchmarkValue
                    ? [subInsight.benchmarkValue]
                    : []),
                ],
                ring: {
                  value: searchRadius,
                  unit: searchSettings[boundaryType].displayUnit,
                },
              })
            );

            return {
              icon,
              title: insight.title,
              subInsights,
              similarity: insight.similarity,
              variance: insight.variance,
              ring: {
                value: searchRadius,
                unit: searchSettings[boundaryType].displayUnit,
              },
            };
          }

          return {
            icon,
            title: insight.title,
            similarity: insight.similarity,
            variance: insight.variance,
            dataValues: [insight.evaluatedValue, insight.benchmarkValue],
            ring: {
              value: searchRadius,
              unit: searchSettings[boundaryType].displayUnit,
            },
          };
        });
      }),
    [insights, boundaryType, searchRadius]
  );

  const {
    report,
    isAllContentReady,
    setIsTradeAreaReady,
    setIsCustomerSummaryReady,
    isReportLoading,
  } = useReportLoadingStates({
    competitors: competitors.brands.filter((brand) => brand !== undefined),
    generators: generators.brands.filter((brand) => brand !== undefined),
    competitorQueries: competitors.queries.filter(
      (query) => query
    ) as PlacesRequest[],
    generatorQueries: generators.queries.filter(
      (query) => query
    ) as PlacesRequest[],
    evaluatedPin: evaluatedPin ?? null,
    boundaryData: boundaryData ?? null,
    isMapOpen,
  });

  const shouldShowPdfSpinner = !isAllContentReady || isPdfProcessing;

  const handleExportPDF = async () => {
    setIsPdfProcessing(true);

    const content = document.querySelector(
      '.pulse-report-content'
    ) as HTMLElement;
    if (!content) return;

    const sections = [
      content.querySelector('[data-section="trade-area"]'),
      content.querySelector('[data-section="customer-summary"]'),
      content.querySelector('[data-section="competitors"]'),
      content.querySelector('[data-section="generators"]'),
      content.querySelector('[data-section="mobile-overlap"]'),
    ] as (HTMLElement | null)[];

    let pdf: jsPDF | null = null;

    try {
      for (const _section of sections) {
        if (!_section) continue;

        const sectionClone = _section.cloneNode(true) as HTMLElement;

        const dataSection = _section.dataset.section as DataSections;
        const mapRef = mapRefs.get(dataSection);
        const mapInitialViewState = mapInitialViewStates.get(dataSection);
        const pins = mapPins.get(dataSection);
        const isFullMap = [DataSections.TradeArea].includes(dataSection);

        await processMapImage({
          dataSection,
          originalSection: _section,
          sectionClone,
          map: mapRef?.current,
          mapInitialViewState,
          pins,
          isFullMap,
        });

        const table = sectionClone.querySelector(
          '[data-section="table"]'
        ) as HTMLElement | null;
        const insightsContainer = sectionClone.querySelector(
          '[data-section="insights"]'
        ) as HTMLElement | null;

        if (insightsContainer) {
          pdf = await paginateSectionContent({
            container: insightsContainer,
            section: sectionClone,
            itemsOnFirstPage: 4,
            itemsPerPage: 6,
            pdf,
          });
        } else if (table) {
          pdf = await paginateSectionContent({
            container: table,
            table,
            section: sectionClone,
            itemsOnFirstPage: 5,
            itemsPerPage: 24,
            pdf,
          });
        } else {
          pdf = await addPageToPDF({
            section: sectionClone,
            pdf,
          });
        }
      }

      if (pdf) {
        pdf.save(`pulse-report-${new Date().toISOString().split('T')[0]}.pdf`);
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsPdfProcessing(false);
    }
  };

  const processMapImage = async ({
    originalSection,
    sectionClone,
    dataSection,
    map,
    mapInitialViewState,
    pins,
    isFullMap = false,
  }: {
    originalSection: HTMLElement;
    sectionClone: HTMLElement;
    dataSection?: DataSections;
    map?: MapRef | null;
    mapInitialViewState?: InitialViewState;
    pins?: JSX.Element[];
    isFullMap?: boolean;
  }) => {
    if (dataSection && map) {
      await getMapImage({
        section: sectionClone,
        map,
        mapInitialViewState,
        mapboxApiKey: env.MAPBOX_API_KEY,
        mapPins: pins,
        isFullMap,
      });
      return;
    }

    const originalMap = originalSection.querySelector(
      '.mapboxgl-map'
    ) as HTMLElement | null;
    if (!originalMap) return;

    const mapCanvas = await html2canvas(originalMap, {
      scale: 2,
      useCORS: true,
      logging: false,
    });
    const mapImageURL = mapCanvas.toDataURL('image/png');
    sectionClone.querySelector('.mapboxgl-map')!.innerHTML =
      `<img src="${mapImageURL}" style="width: 100%; height: 100%" />`;
  };

  const paginateSectionContent = async ({
    container,
    table,
    section,
    itemsOnFirstPage,
    itemsPerPage,
    pdf,
  }: {
    container: HTMLElement;
    table?: HTMLElement | null;
    section: HTMLElement;
    itemsOnFirstPage: number;
    itemsPerPage: number;
    pdf: jsPDF | null;
  }) => {
    const tbody = table?.querySelector('tbody');

    const items = tbody
      ? Array.from(tbody?.children ?? [])
      : Array.from(container.children);

    const { firstPageItems, remainingPages } = splitItemsByPage(
      items,
      itemsOnFirstPage,
      itemsPerPage
    );

    await renderPageWithItems({
      container: tbody ?? container,
      section,
      items: firstPageItems,
    });
    pdf = await addPageToPDF({ section, pdf });

    for (const pageItems of remainingPages) {
      await renderPageWithItems({
        container: tbody ?? container,
        section,
        items: pageItems,
      });
      pdf = await addPageToPDF({ section: container, pdf });
    }

    return pdf;
  };

  const splitItemsByPage = (
    items: Element[],
    itemsOnFirstPage: number,
    itemsPerPage: number
  ) => {
    const firstPageItems = items.slice(0, itemsOnFirstPage);
    const remainingPages = items
      .slice(itemsOnFirstPage)
      .reduce((pages, item, index) => {
        if (index % itemsPerPage === 0) pages.push([]);
        pages[pages.length - 1].push(item);

        return pages;
      }, [] as Element[][]);

    return { firstPageItems, remainingPages };
  };

  const renderPageWithItems = async ({
    container,
    section,
    items,
  }: {
    container: HTMLElement;
    section: HTMLElement;
    items: Element[];
  }) => {
    container.innerHTML = '';
    items.forEach((item) => container.appendChild(item));
    section.style.position = 'absolute';
    section.style.top = '-9999px';
  };

  const addPageToPDF = async ({
    section,
    pdf,
  }: {
    section: HTMLElement;
    pdf: jsPDF | null;
  }) => {
    document.body.appendChild(section);
    const canvas = await html2canvas(section, {
      scale: 2,
      useCORS: true,
      logging: false,
    });
    document.body.removeChild(section);

    const { width, height } = canvas;
    const aspectRatio = width / height;
    const imgWidth = PDF_PAGE_WIDTH - 2 * PDF_PADDING;
    const imgHeight = imgWidth / aspectRatio;
    const xOffset = (PDF_PAGE_WIDTH - imgWidth) / 2;
    const yOffset = PDF_PADDING;

    if (!pdf) {
      pdf = new jsPDF('landscape', 'mm', [PDF_PAGE_WIDTH, PDF_PAGE_HEIGHT]);
    } else {
      pdf.addPage([PDF_PAGE_WIDTH, PDF_PAGE_HEIGHT]);
    }

    pdf.addImage(
      canvas.toDataURL('image/png'),
      'PNG',
      xOffset,
      yOffset,
      imgWidth,
      imgHeight
    );
    return pdf;
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ px: 2, py: 1 }}>
        <Grid item xs={6}>
          <CreatePulseTerritoryModal selectedPin={evaluatedPin ?? null} />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setIsMapOpen(true)}
          >
            Get Report
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={isMapOpen}
        onClose={() => setIsMapOpen(false)}
        sx={{ py: '4rem', px: '4rem' }}
        PaperProps={{ sx: { borderRadius: '4px' } }}
      >
        <DialogTitle
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ borderBottom: '1px solid #ccc' }}
        >
          <Typography variant="h4" component="h4">
            Pulse Site Report
          </Typography>
          <Box display="flex" gap={1}>
            <IconButton
              onClick={handleExportPDF}
              disabled={shouldShowPdfSpinner}
              title={
                !isAllContentReady
                  ? 'Loading content...'
                  : isPdfProcessing
                    ? `Downloading PDF`
                    : `Download PDF`
              }
              sx={{ position: 'relative' }}
            >
              {shouldShowPdfSpinner ? (
                <CircularProgress
                  size={24}
                  color={!isAllContentReady ? 'inherit' : 'primary'}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : (
                <DownloadIcon />
              )}
            </IconButton>
            <IconButton onClick={() => setIsMapOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
          className="pulse-report-content"
        >
          <TradeAreaMap onMapLoad={() => setIsTradeAreaReady(true)} />
          <Box data-section="customer-summary">
            <CustomerSummary
              totalSimilarity={totalSimilarity as number}
              similarities={similarities}
              onLoad={() => setIsCustomerSummaryReady(true)}
              isLoading={customerSummary.isLoading}
              errorMessage={
                !canFetchCustomerSummary
                  ? 'No brand config available'
                  : customerSummary.isError
                    ? 'Error loading data'
                    : undefined
              }
            />
          </Box>
          <Box data-section="competitors">
            <CompetitorsSection
              competitors={report?.competitorsReport}
              isLoading={isReportLoading}
            />
          </Box>
          <Box data-section="generators">
            <GeneratorsSection
              generators={report?.generatorsReport}
              isLoading={isReportLoading}
            />
          </Box>
          {/* <Box height="100%" data-section="mobile-overlap">
            <MobileOverlapSection />
          </Box> */}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
