import z from 'zod';
import * as Y from 'yjs';
import { yFromJSON } from '@plotr/multiplayer-data';

export const TemplateSchema = z
  .object({
    id: z.string(),
    label: z.string(),
    group: z.string(),
    tags: z.array(z.string()),
    keyValuePairs: z.record(z.coerce.string()),
  })
  .partial({ group: true, tags: true, keyValuePairs: true });

export type PinTemplate = z.infer<typeof TemplateSchema>;

export type YPinTemplate = Y.Map<
  string | Y.Array<string> | Y.Map<number> | Y.Map<string>
>;

const getTemplates = (ydoc: Y.Doc) =>
  ydoc.getArray<YPinTemplate>('pinTemplates');

export default (ydoc: Y.Doc) => ({
  addTemplate: (template: PinTemplate) => {
    const yPinTemplate = yFromJSON(template) as YPinTemplate;
    const existingTemplates = getTemplates(ydoc);
    const templateIndex = existingTemplates
      .toArray()
      .findIndex(
        (existingTemplate) => existingTemplate.get('id') === template.id
      );

    // Remove existing row with same id, if it exists
    // This makes importing row from user files idempotent
    if (templateIndex !== -1) {
      existingTemplates.delete(templateIndex);
    }
    existingTemplates.push([yPinTemplate]);
  },
  updateTemplate: (template: PinTemplate) => {
    const existingTemplates = getTemplates(ydoc);

    const templateIndex = existingTemplates
      .toArray()
      .findIndex(
        (existingTemplate) => existingTemplate.get('id') === template.id
      );

    const templateFromYDoc = existingTemplates.get(templateIndex);
    template.label && templateFromYDoc.set('label', template.label);
    template.group && templateFromYDoc.set('group', template.group);
    template.tags && templateFromYDoc.set('tags', Y.Array.from(template.tags));
    if (template.keyValuePairs) {
      const map = new Y.Map<string>();
      Object.entries(template.keyValuePairs).forEach(([key, value]) => {
        map.set(key, value);
      });
      templateFromYDoc.set('keyValuePairs', map);
    }
  },
  removeTemplate: (templateId: string) => {
    const templates = getTemplates(ydoc);
    const templateIndex =
      templates
        .toArray()
        .findIndex((template) => template.get('id') === templateId) ?? -1;

    templates.delete(templateIndex);
  },
  checkTemplateExists: (templateId: string) => {
    const templates = getTemplates(ydoc);
    return (
      templates
        .toArray()
        .findIndex((template) => template.get('id') === templateId) !== -1
    );
  },
});
