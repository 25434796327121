import { Box, Button, Fade, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import EditTemplateForm from './EditTemplate.form';
import { PinTemplate } from '@plotr/plotr-multiplayer-data/src';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type EditTemplateModalProps = {
  template: PinTemplate;
};

const EditTemplateModal = ({ template }: EditTemplateModalProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Edit</Button>
      <Modal
        aria-labelledby="Edit template"
        aria-describedby="Edit template fields"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="Edit template" variant="h6" component="h2">
              Edit the template
            </Typography>
            <EditTemplateForm template={template} closeForm={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default EditTemplateModal;
