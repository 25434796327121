import {
  Brush as CustomInsightIcon,
  Place as CustomPinsIcon,
  EditLocation as CustomPOIsIcon,
  SignalCellularAlt as DemographicInsightIcon,
  Inventory2 as FileIcon,
  Language as GeographicBoundaryIcon,
  Map as MapIcon,
  Store as POIsIcon,
  PsychologyAlt as PsychographicInsightIcon,
  QueryStats as SearchInsightIcon,
  PhotoSizeSelectSmall as TerritoryGroupsIcon,
  Traffic as TrafficIcon,
} from '@mui/icons-material';
import { Box, Paper, styled, Tab, Tabs } from '@mui/material';
import { parseEnv } from '@plotr/common-utils/src';
import { TerritoryType } from '@plotr/plotr-multiplayer-data/src';
import { featureCollection, polygon } from '@turf/helpers';
import axios from 'axios';
import { Feature, Polygon } from 'geojson';
import { useEffect, useMemo, useState } from 'react';
import CustomPinsMenu from '~/src/features/custom-pins/menu/CustomPinsMenu';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import usePermissionCheck from '~/src/global/hooks/usePermissionCheck';
import useSettingsStore from '../../global/hooks/useSettingsStore';
import CustomDrawer from '../custom-drawer/CustomDrawer';
import CreateTemplateModal from '../custom-pins/templates/CreateTemplateModal';
import SelectTemplateForPinModal from '../custom-pins/templates/SelectTemplateForPinModal';
import { getBoundingBoxFromBoundaries } from '../custom-territories/helpers/BoundariesExtermePoints';
import CustomTerritoriesMenu from '../custom-territories/menu/CustomTerritoriesMenu';
import fitMapToBoundary from '../dynamic-map/helpers/fitMapToBoundary';
import useCustomTerritories from '../dynamic-map/hooks/useCustomTerritories';
import useZonesStore from '../dynamic-map/hooks/useZonesStore';
import FilesMenu from '../files-menu/FilesMenu';
import PricingDataMenu from '../pricing/menu/PricingDataMenu';
import ClientGeometriesMenu from './components/ClientGeometriesMenu';
import MapLayersMenu from './components/MapLayersMenu';
import AccordionSection from './components/MapLayersMenu/components/AccordionSection';
import { POIMenu } from './components/POIMenu';
import { ZonesMenu } from './components/ZonesMenu';
import './Sidebar.css';
import { Permissions } from '~/src/constants';

const env = parseEnv({ API_V2: process.env.API_V2 });

export enum MainMenuNames {
  Points = 'POINTS',
  Boundaries = 'BOUNDARIES',
  Layers = 'LAYERS',
  Files = 'FILES',
}

enum SubMenuNames {
  CustomPins = 'Your Pins',
  POIs = 'Points of Interest',
  Pricing = 'Pricing',
  CustomPOIs = 'Custom POI Brands',
  TerritoryGroups = 'Territory Groups',
  GeographicBoundaries = 'Geographic Boundaries',
  Zones = 'Zones',
  DemographicInsights = 'Demographic Insights',
  PsychographicInsights = 'Psychographic Insights',
  CustomInsights = 'Your Insights',
  SearchInsights = 'Search Insights',
  TrafficVolume = 'Traffic Volume',
  ClientGeometries = 'Client Geometries',
  Files = 'Files',
}

type MenusByName = {
  [key in MainMenuNames]: {
    [key in SubMenuNames]?: { icon: JSX.Element; content: JSX.Element };
  };
};

const AccordionContent = styled(Box)({
  height: '100%',
  overflow: 'auto',
});
// TODO: on mobile screen sizes, the drawer menu should take up the full screen

export default function DynamicMapController() {
  const zones = useZonesStore((state) => state.zones);
  const map = useMapContext();
  const userSettings = useSettingsStore((state) => state.userSettings);

  const isZonesMenuVisible = userSettings && zones && map;

  const hasAdminPermission = usePermissionCheck(Permissions.READ_PLOTR_ADMIN);

  const menusByName: MenusByName = {
    [MainMenuNames.Points]: {
      [SubMenuNames.CustomPins]: {
        icon: <CustomPinsIcon />,
        content: <CustomPinsMenu />,
      },
      [SubMenuNames.POIs]: {
        icon: <POIsIcon />,
        content: <POIMenu />,
      },
      [SubMenuNames.CustomPOIs]: {
        icon: <CustomPOIsIcon />,
        content: <POIMenu isCustomPOIBrands={true} />,
      },
    },
    [MainMenuNames.Boundaries]: {
      [SubMenuNames.TerritoryGroups]: {
        icon: <TerritoryGroupsIcon />,
        content: <CustomTerritoriesMenu />,
      },
      [SubMenuNames.GeographicBoundaries]: {
        icon: <GeographicBoundaryIcon />,
        content: <MapLayersMenu layersGroup="boundary_layers" />,
      },
      [SubMenuNames.ClientGeometries]: {
        icon: <MapIcon />,
        content: (
          <Box display="flex" flexDirection="column" height="100%">
            <ClientGeometriesMenu />
          </Box>
        ),
      },
      [SubMenuNames.Zones]: {
        icon: <MapIcon />,
        content: (
          <Box display="flex" flexDirection="column" height="100%">
            {isZonesMenuVisible ? (
              <>
                <AccordionSection title="Zones">
                  <AccordionContent>
                    <ZonesMenu />
                  </AccordionContent>
                </AccordionSection>

                <AccordionSection title="Layers">
                  <AccordionContent>
                    <MapLayersMenu layersGroup="Zones" />
                  </AccordionContent>
                </AccordionSection>
              </>
            ) : (
              // Display MapLayersMenu directly if ZonesMenu is not visible
              <MapLayersMenu layersGroup="Zones" />
            )}
          </Box>
        ),
      },
    },
    [MainMenuNames.Layers]: {
      [SubMenuNames.DemographicInsights]: {
        icon: <DemographicInsightIcon />,
        content: <MapLayersMenu layersGroup="demographic_layers" />,
      },
      [SubMenuNames.PsychographicInsights]: {
        icon: <PsychographicInsightIcon />,
        content: <MapLayersMenu layersGroup="psychographic_layers" />,
      },
      [SubMenuNames.CustomInsights]: {
        icon: <CustomInsightIcon />,
        content: <MapLayersMenu layersGroup="custom_layers" />,
      },
      [SubMenuNames.SearchInsights]: {
        icon: <SearchInsightIcon />,
        content: <MapLayersMenu layersGroup="search_layers" />,
      },
      [SubMenuNames.TrafficVolume]: {
        icon: <TrafficIcon />,
        content: <MapLayersMenu layersGroup="traffic_layers" />,
      },
    },
    [MainMenuNames.Files]: {},
  };

  if (hasAdminPermission) {
    menusByName[MainMenuNames.Layers][SubMenuNames.Pricing] = {
      icon: <POIsIcon />,
      content: <PricingDataMenu />,
    };
  }
  if (hasAdminPermission) {
    menusByName[MainMenuNames.Files][SubMenuNames.Files] = {
      icon: <FileIcon />,
      content: <FilesMenu />,
    };
  }

  const drawerMenuOpen = useDynamicMapStore((state) => state.drawerMenuOpen);
  const setDrawerMenuOpen = useDynamicMapStore(
    (state) => state.setDrawerMenuOpen
  );
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );
  const evaluatedTerritoryId =
    evaluatedDemographicEntity &&
    evaluatedDemographicEntity.type === 'territory' &&
    evaluatedDemographicEntity?.id;

  const [activeTab, setActiveTab] = useState<{
    main: MainMenuNames;
    sub: SubMenuNames;
  }>({ main: MainMenuNames.Points, sub: SubMenuNames.CustomPins });

  // if evaluatedPinId is set, show the CustomPins menu
  useEffect(() => {
    if (evaluatedPinId != null) {
      handleActiveMenu({
        main: MainMenuNames.Points,
        sub: SubMenuNames.CustomPins,
      });
    }
  }, [evaluatedPinId]);

  const customTerritories = useCustomTerritories();

  const evaluatedTerritory = useMemo(() => {
    if (evaluatedTerritoryId == null) return null;
    return customTerritories.find(({ id }) => id === evaluatedTerritoryId);
  }, [customTerritories, evaluatedTerritoryId]);

  useEffect(() => {
    if (evaluatedDemographicEntity?.type === 'territory') {
      if (evaluatedTerritory?.type === TerritoryType.Custom) {
        const boundingBoxCoordinates = getBoundingBoxFromBoundaries(
          Object.values(evaluatedTerritory?.boundaries ?? {})
        );
        const data = featureCollection([
          polygon(boundingBoxCoordinates),
        ]) as unknown as Feature<Polygon>;

        map && fitMapToBoundary(map, data);
      }
      if (evaluatedTerritory?.type === TerritoryType.Zip && map) {
        const zipcodes = Object.values(evaluatedTerritory.boundaries).map(
          (boundary) => boundary.id.padStart(5, '0')
        );

        try {
          axios
            .get(`${env.API_V2}/merge`, {
              params: { zipcodes, bbox: true },
            })
            .then((response) => fitMapToBoundary(map, response.data));
        } catch (err) {
          console.error('Error fetching merged boundaries:', err);
        }
      }
      setDrawerMenuOpen(true);
      handleActiveMenu({
        main: MainMenuNames.Boundaries,
        sub: SubMenuNames.TerritoryGroups,
      });
    }
  }, [evaluatedDemographicEntity]);

  const handleActiveMenu = (
    newState: Partial<{ main: MainMenuNames; sub: SubMenuNames }>
  ) => {
    const { main, sub } = newState;
    const activeSubTab: Partial<Record<MainMenuNames, SubMenuNames>> = {
      [MainMenuNames.Points]: SubMenuNames.CustomPins,
      [MainMenuNames.Boundaries]: SubMenuNames.TerritoryGroups,
      [MainMenuNames.Layers]: SubMenuNames.DemographicInsights,
      [MainMenuNames.Files]: SubMenuNames.Files,
    };

    if (main && !sub) newState.sub = activeSubTab[main];

    setActiveTab((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const availableMainMenus = Object.entries(menusByName)
    .filter(([_, subMenus]) => Object.keys(subMenus).length > 0)
    .map(([mainMenuName]) => mainMenuName as MainMenuNames);

  return (
    <CustomDrawer drawerOpen={drawerMenuOpen} compressed>
      <>
        <Box width="100%">
          <Paper elevation={8} square={true} sx={{ paddingBottom: '20px' }}>
            <Tabs
              value={activeTab.main}
              onChange={(e, value) => handleActiveMenu({ main: value })}
              centered
              indicatorColor="secondary"
              textColor="secondary"
              className="menu-tabs"
            >
              {availableMainMenus.map((menuName) => (
                <Tab key={menuName} label={menuName} value={menuName} />
              ))}
            </Tabs>
          </Paper>
        </Box>

        <Box height={1} className="tab-content-wrapper">
          <Box paddingY={4} paddingX={3}>
            {menusByName[activeTab.main][activeTab.sub]?.content}
          </Box>
          <Tabs
            value={activeTab.sub}
            onChange={(e, value) => handleActiveMenu({ sub: value })}
            orientation="vertical"
          >
            {Object.keys(menusByName[activeTab.main]).map((name) => {
              const subMenuName = name as SubMenuNames;

              const isTerritoriesMenu =
                subMenuName === SubMenuNames.TerritoryGroups;

              const isDisabledTerritoriesMenu =
                isTerritoriesMenu && !userSettings?.featureFlags?.territories;

              const isDisabled = isDisabledTerritoriesMenu;

              return (
                !isDisabled && (
                  <Tab
                    key={subMenuName}
                    label={subMenuName}
                    value={subMenuName}
                    icon={menusByName[activeTab.main][subMenuName]?.icon}
                  />
                )
              );
            })}
          </Tabs>
        </Box>
        <CreateTemplateModal />
        <SelectTemplateForPinModal />
      </>
    </CustomDrawer>
  );
}
