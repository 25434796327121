import { Box, CircularProgress, Grid } from '@mui/material';
import React from 'react';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import MarketRankDisplay from './MarketRankDisplay';
import NationalRankDisplay from './NationalRankDisplay';
import SonarDisplay from './SonarDisplay';
import RankingYearPicker from './RankingYearPicker';
import usePermissionCheck from '~/src/global/hooks/usePermissionCheck';
import { Permissions } from '~/src/constants';

const SonarCard: React.FC = () => {
  const poiData = useDemographicStore((state) => state.poiData);
  const sonarData = useDemographicStore((state) => state.sonarData);

  const sonarDataIsLoading = useDemographicStore(
    (state) => state.sonarDataIsLoading
  );
  const clickedPOIFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature
  );

  const sonarDataYear = useDemographicStore((state) => state.sonarDataYear);
  const setSonarDataYear = useDemographicStore(
    (state) => state.setSonarDataYear
  );

  const hasPermission = usePermissionCheck(Permissions.READ_SONAR_DATA);

  const { city, region } = clickedPOIFeature?.properties || {};
  const { cbsaName } = poiData || {};
  const {
    likelihood_to_close,
    ntlRanking,
    marketRanking,
    total_number_of_market_ranked_brand_stores,
    total_number_of_national_ranked_brand_stores,
  } = sonarData || {};

  const getCBSAName = () => {
    if (cbsaName) {
      return `${cbsaName}`;
    }
    return `${city}, ${region}`;
  };

  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        padding: 3,
        width: '90%',
        margin: 2,
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
        },
      }}
    >
      {sonarDataIsLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box position="relative">
          <Grid container spacing={2} justifyContent="center">
            <RankingYearPicker
              sonarDataYear={sonarDataYear}
              setSonarDataYear={setSonarDataYear}
            />
            <SonarDisplay
              likelihoodToClose={likelihood_to_close}
              hasPermission={hasPermission}
            />
            <NationalRankDisplay
              ntlRanking={ntlRanking}
              totalNumberOfNationalRankedBrandStores={
                total_number_of_national_ranked_brand_stores
              }
              hasPermission={hasPermission}
              clickedPOIFeature={clickedPOIFeature}
            />
            <MarketRankDisplay
              marketRanking={marketRanking}
              totalNumberOfMarketRankedBrandStores={
                total_number_of_market_ranked_brand_stores
              }
              hasPermission={hasPermission}
              getCBSAName={getCBSAName}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default SonarCard;
