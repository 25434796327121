import { yFromJSON } from '@plotr/multiplayer-data';
import * as Y from 'yjs';
import * as z from 'zod';

export enum TerritoryType {
  Zip = 'ZIP',
  Custom = 'CUSTOM',
}

const boundarySchema = z.object({
  id: z.string(),
  coordinates: z.array(z.array(z.number())).optional(),
});

export type CustomBoundary = z.infer<typeof boundarySchema>;

export type YCustomBoundary = Y.Map<string | Y.Array<Y.Array<number>>>;

export const territorySchema = z.object({
  id: z.string(),
  label: z.string(),
  group: z.string(),
  type: z.nativeEnum(TerritoryType).optional(),
  boundaries: z.record(boundarySchema),
  keyValuePairs: z.record(z.string()).optional(),
  tags: z.array(z.string()).optional(),
  pins: z.array(z.string()).optional(),
});

export type CustomTerritory = z.infer<typeof territorySchema>;

export type YCustomTerritory = Y.Map<
  | string
  | TerritoryType
  | Y.Map<YCustomBoundary>
  | Y.Map<string>
  | Y.Array<string>
>;

const getTerritories = (ydoc: Y.Doc) =>
  ydoc.getArray<YCustomTerritory>('customTerritories');

const findTerritory = (ydoc: Y.Doc, territoryId: string) => {
  const territories = getTerritories(ydoc);
  const tIndex =
    territories.toArray().findIndex((territory) => {
      // territory.get('id') as string === territoryId)
      const id = territory.get('id') as unknown as string;
      return id ? id === territoryId : false;
    }) ?? -1;
  return { territories, tIndex, territory: territories.get(tIndex) };
};

export default (ydoc: Y.Doc) => ({
  addTerritory: (territory: CustomTerritory) => {
    const yCustomTerritory = yFromJSON(territory) as YCustomTerritory;
    getTerritories(ydoc).push([yCustomTerritory]);
  },
  addTerritories: (territories: CustomTerritory[]) => {
    const yCustomTerritories = territories.map(yFromJSON) as YCustomTerritory[];
    getTerritories(ydoc).push(yCustomTerritories);
  },
  removeTerritory: (territoryId: string) => {
    const { territories, tIndex } = findTerritory(ydoc, territoryId);
    territories.delete(tIndex);
  },
  removeTerritories: (territoryIds: string[]) => {
    const { territories } = findTerritory(ydoc, '');
    territoryIds.forEach((territoryId) => {
      const tIndex =
        territories
          .toArray()
          .findIndex((territory) => territory.get('id') === territoryId) ?? -1;
      territories.delete(tIndex);
    });
  },
  setLabel: (territoryId: string, label: string) => {
    const { territory } = findTerritory(ydoc, territoryId);
    territory?.set('label', label);
  },
  setGroup: (territoryId: string, group: string) => {
    const { territory } = findTerritory(ydoc, territoryId);
    territory?.set('group', group);
  },
  setType: (territoryId: string, type: TerritoryType) => {
    const { territory } = findTerritory(ydoc, territoryId);
    territory?.set('type', type);
  },
  setPins: (territoryId: string, pinIds: string[]) => {
    const { territory } = findTerritory(ydoc, territoryId);
    territory?.set('pins', Y.Array.from(pinIds));
  },
  renameGroup: (oldName: string, newName: string) => {
    const territories = getTerritories(ydoc);
    territories.toArray().forEach((territory) => {
      if (territory.get('group') === oldName) {
        territory.set('group', newName);
      }
    });
  },
  addOrUpdateBoundary: ({
    territoryId,
    boundaryId,
    polygonData,
  }: {
    territoryId: string;
    boundaryId: string;
    polygonData?: number[][];
  }) => {
    const { territory } = findTerritory(ydoc, territoryId);

    if (!territory) {
      console.error('Territory not found');
      return;
    }

    let boundaries = territory.get('boundaries') as Y.Map<YCustomBoundary>;
    if (!boundaries) {
      boundaries = new Y.Map<YCustomBoundary>();
      territory.set('boundaries', boundaries);
    }

    let boundary = boundaries.get(boundaryId) as YCustomBoundary;
    if (!boundary) {
      boundary = new Y.Map();
      boundary.set('id', boundaryId); // Directly set the string value
      if (polygonData) {
        const yCoordinates = new Y.Array<Y.Array<number>>();
        polygonData.forEach((coord) => {
          const yCoord = new Y.Array<number>();
          yCoord.insert(0, coord); // Use insert to add coordinates
          yCoordinates.push([yCoord]);
        });
        boundary.set('coordinates', yCoordinates);
      }
      boundaries.set(boundaryId, boundary);
    } else {
      if (polygonData) {
        const yCoordinates = boundary.get('coordinates') as Y.Array<
          Y.Array<number>
        >;
        yCoordinates.delete(0, yCoordinates.length); // Clear existing coordinates
        polygonData.forEach((coord) => {
          const yCoord = new Y.Array<number>();
          yCoord.insert(0, coord);
          yCoordinates.push([yCoord]);
        });
      }
    }
  },
  removeBoundary: (territoryId: string, boundaryId: string) => {
    const { territory } = findTerritory(ydoc, territoryId);
    const boundaries = territory?.get('boundaries') as
      | Y.Map<YCustomBoundary>
      | undefined;

    boundaries?.delete(boundaryId);
  },
  removeAllBoundaries: (territoryId: string) => {
    const { territory } = findTerritory(ydoc, territoryId);
    territory?.set('boundaries', yFromJSON({}) as Y.Map<YCustomBoundary>);
  },
  addKeyValuePair: (territoryId: string, key: string, value: string) => {
    try {
      const { territory } = findTerritory(ydoc, territoryId);
      let keyValuePairs = territory?.get('keyValuePairs') as Y.Map<string>;

      if (!keyValuePairs) {
        keyValuePairs = new Y.Map<string>();
        territory?.set('keyValuePairs', keyValuePairs);
      }

      keyValuePairs.set(key, value);
    } catch (error) {
      console.error('Error adding key-value pair:', error);
    }
  },
  removeKeyValuePair: (territoryId: string, key: string) => {
    try {
      const { territory } = findTerritory(ydoc, territoryId);
      const keyValuePairs = territory?.get('keyValuePairs') as
        | Y.Map<string>
        | undefined;
      keyValuePairs?.delete(key);
    } catch (error) {
      console.error('Error removing key-value pair:', error);
    }
  },
  addKeyValuePairs: (
    territoryId: string,
    keyValuePairsToAdd: Record<string, string>
  ) => {
    try {
      const { territory } = findTerritory(ydoc, territoryId);
      let keyValuePairs = territory?.get('keyValuePairs') as
        | Y.Map<string>
        | undefined;

      if (!keyValuePairs) {
        keyValuePairs = new Y.Map<string>();
        territory?.set('keyValuePairs', keyValuePairs);
      }

      Object.entries(keyValuePairsToAdd).forEach(([key, value]) => {
        keyValuePairs?.set(key, value);
      });
    } catch (error) {
      console.error('Error adding key-value pairs:', error);
    }
  },
  removeKeyValuePairs: (territoryId: string, keys: string[]) => {
    try {
      const { territory } = findTerritory(ydoc, territoryId);
      const keyValuePairs = territory?.get('keyValuePairs') as
        | Y.Map<string>
        | undefined;

      keys.forEach((key) => {
        keyValuePairs?.delete(key);
      });
    } catch (error) {
      console.error('Error removing key-value pairs:', error);
    }
  },
  updateKeyValuePair: (territoryId: string, key: string, value: string) => {
    try {
      const { territory } = findTerritory(ydoc, territoryId);
      const keyValuePairs = territory?.get('keyValuePairs') as
        | Y.Map<string>
        | undefined;

      keyValuePairs?.set(key, value);
    } catch (error) {
      console.error('Error updating key-value pair:', error);
    }
  },
  addTag: (territoryId: string, tag: string) => {
    try {
      const { territory } = findTerritory(ydoc, territoryId);
      let tags = territory?.get('tags') as Y.Array<string>;

      if (!tags) {
        tags = new Y.Array<string>();
        territory?.set('tags', tags);
      }

      tags.push([tag]);
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  },
  removeTag: (territoryId: string, tag: string) => {
    try {
      const { territory } = findTerritory(ydoc, territoryId);
      const tags = territory?.get('tags') as Y.Array<string>;

      if (tags) {
        const tagIndex = tags.toArray().findIndex((t) => t === tag);
        tags.delete(tagIndex);
      }
    } catch (error) {
      console.error('Error removing tag:', error);
    }
  },
});
