import { Grid } from '@mui/material';
import LikelihoodDisplay from './LikelihoodDisplay';
import { HubspotModalType } from '../../HubspotModal';

interface SonarDisplayProps {
  likelihoodToClose?: string;
  hasPermission: boolean | null;
  contextString?: HubspotModalType;
}

function SonarDisplay({
  likelihoodToClose,
  hasPermission,
  contextString = HubspotModalType.Sonar,
}: SonarDisplayProps) {
  return (
    <Grid item xs={12}>
      <LikelihoodDisplay
        likelihood={(likelihoodToClose as 'high' | 'medium' | 'low') ?? 'low'}
        isBlurred={!hasPermission}
        contextString={contextString}
      />
    </Grid>
  );
}

export default SonarDisplay;
