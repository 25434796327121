import { Close as CloseIcon } from '@mui/icons-material';
import { Box } from '@mui/material';

export default function CustomDrawer({
  children,
  drawerOpen,
  showCloseButton = false,
  handleClose,
  compressed = false,
}: {
  children: JSX.Element;
  drawerOpen: boolean;
  showCloseButton?: boolean;
  handleClose?: () => void;
  compressed?: boolean;
}) {
  const classNames = ['custom-drawer '];

  if (drawerOpen) {
    classNames.push('open');
  }

  if (compressed) {
    classNames.push('compressed');
  }

  const className = classNames.join(' ');

  return (
    <Box id="custom-drawer" className={className} maxWidth="100%">
      {drawerOpen && showCloseButton && (
        <Box
          sx={{ textAlign: 'right', cursor: 'pointer' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </Box>
      )}
      {children}
    </Box>
  );
}
