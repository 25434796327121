import {
  CirclePaint,
  FillPaint,
  LinePaint,
  SymbolLayout,
  SymbolPaint,
} from 'mapbox-gl';
import { useEffect } from 'react';
import { Layer, Source } from 'react-map-gl';
import {
  Expression,
  InsightTilesetSources,
  LayerType,
  TilesetSource,
} from '~/src/features/dynamic-map-controller/components/MapLayersMenu';
import useLayerIds from '~/src/features/dynamic-map/hooks/useLayerIds';
import useLayersStore, {
  GroupComponentProps,
  LayerCard,
} from '~/src/features/dynamic-map/hooks/useLayersStore';
import useMapContext from '~/src/features/dynamic-map/hooks/useMapContext';
import { kidstrongCustomerFacilities } from '~/src/global/constants/kidstrongCustomersLocation';
import useFacilitiesStore from '../../hooks/useFacilitiesStore';
import BlockGroupsSource from './components/BlockGroupsSource';
import HeatmapZoneSources from './components/HeatmapZoneSources';
import TrafficSource from './components/TrafficSource';

function isTilesetSource(
  source: InsightTilesetSources
): source is TilesetSource {
  return 'source_layer' in source;
}

const CustomLayersSource = () => {
  const layers = useLayersStore((state) => state.layers);
  const map = useMapContext();
  const roadLabelLayer = useLayerIds((layer) =>
    layer.id.startsWith('road-label')
  )[0];
  const roadLayer = useLayerIds((layer) => layer.id.startsWith('road'))[0];
  const showData = useLayersStore((state) => state.showData);
  const facilities = useFacilitiesStore((state) => state.facilities);

  useEffect(() => {
    if (map == null) return;
    const orderLayers = () => {
      try {
        const coreLayerIds = layers.map((layer) => layer.id);
        const belowRoadLayers = layers.filter(
          (layer) => layer.featureType === 'polygon'
        );
        const aboveRoadLayers = layers.filter(
          (layer) =>
            layer.featureType === 'line' || layer.featureType === 'point'
        );

        //get all relevant layers
        const allLayerIds = map
          .getStyle()
          .layers.filter((l) => coreLayerIds.some((id) => l.id.startsWith(id)))
          .map((l) => l.id);

        // Reverse the order for above road layers and place them with companions
        aboveRoadLayers.forEach((layer, index, arr) => {
          const layerId = layer.id;
          const [companionLayers, otherCoreLayers] = allLayerIds.reduce<
            [string[], string[]]
          >(
            (acc, id) => {
              const companions = acc[0];
              const others = acc[1];

              if (
                id.startsWith(layerId) &&
                id !== layerId &&
                id.includes('-companion')
              ) {
                companions.push(id);
              } else if (
                id.startsWith(layerId) &&
                id !== layerId &&
                !id.includes('-companion')
              ) {
                others.push(id);
              }
              return acc;
            },
            [[], []]
          );

          const nextCoreLayer = arr[index - 1];
          const nextCoreLayerOther: string[] | undefined = map.getLayer(
            nextCoreLayer?.id
          )
            ? allLayerIds.filter(
                (id) =>
                  id.startsWith(nextCoreLayer.id) &&
                  id !== nextCoreLayer.id &&
                  !id.includes('-companion')
              )
            : undefined;
          //next core layer id should be next core layer or the lowest other core layer
          const nextCoreLayerId =
            nextCoreLayer?.id && allLayerIds.includes(nextCoreLayer?.id)
              ? nextCoreLayer.id
              : nextCoreLayerOther?.[0];

          if (map.getLayer(layerId)) {
            if (index === 0) {
              map.moveLayer(layerId, roadLabelLayer);
            } else {
              map.moveLayer(layerId, nextCoreLayerId || roadLabelLayer);
            }
          }
          otherCoreLayers.forEach((otherCoreId) => {
            if (map.getLayer(otherCoreId)) {
              if (index === 0) {
                map.moveLayer(otherCoreId, roadLabelLayer);
              } else {
                map.moveLayer(otherCoreId, nextCoreLayerId ?? roadLabelLayer);
              }
            }
          });
          companionLayers.forEach((companionId) => {
            if (map.getLayer(companionId)) {
              if (index === 0) {
                map.moveLayer(companionId, roadLabelLayer);
              } else {
                map.moveLayer(companionId, nextCoreLayerId ?? roadLabelLayer);
              }
            }
          });
        });
        // Reverse the order for below road layers and place them with companions
        belowRoadLayers.forEach((layer, index, arr) => {
          const layerId = layer.id;
          const companionLayers = allLayerIds.filter(
            (id) =>
              id.startsWith(layerId) &&
              id !== layerId &&
              id.includes('-companion')
          );
          const otherCoreLayers = allLayerIds.filter(
            //layers that match the core layer prefix but are not companion layers
            (id) =>
              id.startsWith(layerId) &&
              id !== layerId &&
              !id.includes('-companion')
          );

          const nextCoreLayer = arr[index - 1];
          const nextCoreLayerOther: string[] | undefined = map.getLayer(
            nextCoreLayer?.id
          )
            ? allLayerIds.filter(
                (id) =>
                  id.startsWith(nextCoreLayer.id) &&
                  id !== nextCoreLayer.id &&
                  !id.includes('-companion')
              )
            : undefined;
          //next core layer id should be next core layer or the lowest other core layer
          const nextCoreLayerId =
            nextCoreLayer?.id && allLayerIds.includes(nextCoreLayer?.id)
              ? nextCoreLayer.id
              : nextCoreLayerOther?.[0];

          if (map.getLayer(layerId)) {
            if (index === 0) {
              map.moveLayer(layerId, roadLayer);
            } else {
              map.moveLayer(layerId, nextCoreLayerId ?? roadLayer);
            }
          }

          otherCoreLayers.forEach((otherCoreId) => {
            if (map.getLayer(otherCoreId)) {
              if (index === 0) {
                map.moveLayer(otherCoreId, roadLayer);
              } else {
                map.moveLayer(otherCoreId, nextCoreLayerId ?? roadLayer);
              }
            }
          });

          companionLayers.forEach((companionId) => {
            if (map.getLayer(companionId)) {
              if (index === 0) {
                map.moveLayer(companionId, roadLabelLayer);
              } else {
                map.moveLayer(
                  companionId,
                  //FIXME: this line doesn't work when it points at traffic volume, no core layer
                  aboveRoadLayers?.reverse()[0]?.id ?? roadLabelLayer
                );
              }
            }
          });
        });
      } catch (error) {
        console.error('Error reordering layers:', error);
      }
    };

    orderLayers(); //TODO: needs to get this run on initial load after every layer has been added and is ready
  }, [map, layers, roadLayer, roadLabelLayer]);

  const getComplexLayerProps = (layerKey: string): GroupComponentProps => {
    const layer = layers.filter((layer) => layer?.id === layerKey)[0];
    let props = {
      beforeId: '',
      enabled: false,
      showLayerZoom: 6,
      opacity: layer?.opacity,
    };
    if (layer) {
      props = {
        ...props,
        enabled: true,
        ...layer?.layerGroup?.groupComponentProps,
      };
    }
    return props;
  };

  return (
    <>
      {layers.map((layer) => {
        if (layer.type === LayerType.Insights && layer.sourceTileset) {
          const sourceLayer = layer.sourceTileset;
          const sourceKey = layer.sourceTileset.source;
          const sourceUrl = `mapbox://${sourceKey}`;
          let layerRender = generateLayerByFeatureType(
            layer,
            roadLayer,
            roadLabelLayer
          );

          if (layer.id === 'kidstrong_customer_heatmap_v2') {
            const facilitiesToRender =
              facilities.length > 0 ? facilities : kidstrongCustomerFacilities;
            const kidstrongCustomerFilter = [] as Expression;

            facilitiesToRender.forEach((facility) => {
              kidstrongCustomerFilter.push([
                'has',
                facility.toLowerCase() + '_ks_cus',
              ]);
              kidstrongCustomerFilter.push(true);
            });

            if (kidstrongCustomerFilter.length > 0) {
              layerRender = generateLayerByFeatureType(
                {
                  ...layer,
                  filter: ['case', ...kidstrongCustomerFilter, false],
                  styleConfig: {
                    ...layer.styleConfig,
                    expression: [
                      '+',
                      ...facilitiesToRender.map((facility) => [
                        'coalesce',
                        ['get', facility.toLowerCase() + '_ks_cus'],
                        0,
                      ]),
                    ],
                  },
                },
                roadLayer,
                roadLabelLayer
              );
            }
          }

          const complementaryLayers = generateComplementaryLayers(
            layer,
            showData
          );

          if (!sourceLayer) return null;

          return (
            <Source key={layer.id} type="vector" url={sourceUrl}>
              {[layerRender, ...complementaryLayers]}
            </Source>
          );
        }

        return null;
      })}
      <HeatmapZoneSources
        {...getComplexLayerProps('retail_proximity_heatmap')}
      />
      <BlockGroupsSource {...getComplexLayerProps('block_group_match')} />
      <TrafficSource {...getComplexLayerProps('traffic_volume')} />
    </>
  );
};

const generateLayerByFeatureType = (
  layer: LayerCard,
  roadLayer: string,
  roadLabelLayer: string
) => {
  const sourceLayer = layer.sourceTileset;
  if (!sourceLayer) return null;

  const beforeId = layer.featureType === 'polygon' ? roadLayer : roadLabelLayer;

  if (layer.featureType === 'line') {
    return (
      <Layer
        id={layer.id}
        source={
          typeof sourceLayer.source === 'object'
            ? { type: 'geojson', data: sourceLayer.source }
            : sourceLayer.source
        }
        {...(isTilesetSource(sourceLayer)
          ? { 'source-layer': sourceLayer.source_layer }
          : {})}
        type="line"
        paint={getCoreLineLayerPaint(layer)}
        {...(layer.filter ? { filter: layer.filter } : {})}
        beforeId={beforeId}
        key={layer.id}
      />
    );
  }

  if (layer.featureType === 'polygon') {
    return (
      <Layer
        id={layer.id}
        source={
          typeof sourceLayer.source === 'object'
            ? { type: 'geojson', data: sourceLayer.source }
            : sourceLayer.source
        }
        {...(isTilesetSource(sourceLayer)
          ? { 'source-layer': sourceLayer.source_layer }
          : {})}
        type={'fill'}
        paint={getCoreFillLayerPaint(layer)}
        {...(layer.filter ? { filter: layer.filter } : {})}
        beforeId={beforeId}
        key={layer.id}
      />
    );
  }

  if (layer.featureType === 'point') {
    if (
      layer.styleConfig?.imageName &&
      layer.styleConfig.imageName.length > 0
    ) {
      // To add image color, upload it using map.addImage() with the sdf: true flag
      return (
        <Layer
          id={layer.id}
          source={
            typeof sourceLayer.source === 'object'
              ? { type: 'geojson', data: sourceLayer.source }
              : sourceLayer.source
          }
          {...(isTilesetSource(sourceLayer)
            ? { 'source-layer': sourceLayer.source_layer }
            : {})}
          type="symbol"
          {...getCoreSymbolLayerStyles(layer)}
          {...(layer.filter ? { filter: layer.filter } : {})}
          beforeId={beforeId}
          key={layer.id}
        />
      );
    }

    return (
      <Layer
        id={layer.id}
        source={
          typeof sourceLayer.source === 'object'
            ? { type: 'geojson', data: sourceLayer.source }
            : sourceLayer.source
        }
        {...(isTilesetSource(sourceLayer)
          ? { 'source-layer': sourceLayer.source_layer }
          : {})}
        type="circle"
        paint={getCorePointLayerPaint(layer)}
        {...(layer.filter ? { filter: layer.filter } : {})}
        beforeId={beforeId}
        key={layer.id}
      />
    );
  }

  return null;
};

//function to generate layers that complement/add info to the main layer, such as labels, borders, etc
const generateComplementaryLayers = (layer: LayerCard, showData: boolean) => {
  const sourceLayer = layer.sourceTileset;
  if (!sourceLayer) return [];
  // if the layer is a boundary or showData is enabled, add a highlight outline, else set it to null
  const highlightOutlineLayer =
    layer.isBoundary || showData ? (
      <Layer
        id={`${layer.id}-companion-highlight`}
        type="line"
        source={
          typeof sourceLayer.source === 'object'
            ? { type: 'geojson', data: sourceLayer.source }
            : sourceLayer.source
        }
        {...(isTilesetSource(sourceLayer)
          ? { 'source-layer': sourceLayer.source_layer }
          : {})}
        paint={{
          // TODO: change line color to black for lightly colored map styles, otherwise keep it yellow
          'line-color': layer.styleConfig.borderColor ?? '#333',
          'line-width': layer.styleConfig.borderWidth ?? [
            'interpolate',
            ['linear'],
            ['zoom'],
            0,
            ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.1],
            10,
            ['case', ['boolean', ['feature-state', 'hover'], false], 4, 0.5],
            22,
            ['case', ['boolean', ['feature-state', 'hover'], false], 10, 1],
          ],
        }}
        {...(layer.filter ? { filter: layer.filter } : {})}
        key={`${layer.id}-highlight`}
      />
    ) : null;

  const labelLayer = layer.styleConfig.idLabelFlag ? (
    <Layer
      id={`${layer.id}-companion-label`}
      {...(isTilesetSource(sourceLayer)
        ? { 'source-layer': sourceLayer.source_layer }
        : {})}
      type="symbol"
      minzoom={10}
      {...(layer.filter ? { filter: layer.filter } : {})}
      layout={{
        visibility: 'visible',
        'text-field': ['to-string', ['id']],
        'text-font': ['Arial Unicode MS Regular'],
        'text-size': 16,
        'text-allow-overlap': true,
      }}
      paint={{
        'text-color': 'black',
        'text-halo-color': 'rgba(255, 255, 255, 0.5)',
        'text-halo-width': 2,
        'text-opacity': [
          'interpolate',
          ['linear'],
          ['zoom'],
          10,
          0,
          11,
          layer.opacity > 0 ? 1 : 0,
        ],
      }}
      // beforeId={roadLabelLayer}
      key={`${layer.id}-label`}
    />
  ) : null;

  return [highlightOutlineLayer, labelLayer];
};

function hasUniformColor(layer: LayerCard): boolean {
  return layer.styleConfig.colors.length === 1;
}

function getCoreLineLayerPaint(layer: LayerCard): LinePaint {
  if (hasUniformColor(layer)) {
    return {
      'line-color': layer.styleConfig.colors[0],
      'line-opacity': layer.opacity,
    };
  } else {
    const {
      colors,
      threshold = [],
      opacityStops,
      expression,
    } = layer.styleConfig;
    return {
      'line-color': [
        'interpolate',
        ['linear'],
        expression,
        ...threshold.flatMap((t, index) => [t, colors[index]]),
      ],
      'line-opacity': opacityStops
        ? [
            'interpolate',
            ['linear'],
            expression,
            ...threshold.flatMap((t, index) => [
              t,
              Math.min(opacityStops[index] * layer.opacity, 1),
            ]),
          ]
        : layer.opacity,
    };
  }
}

function getCoreFillLayerPaint(layer: LayerCard): FillPaint {
  if (hasUniformColor(layer)) {
    return {
      'fill-color': layer.styleConfig.colors[0],
      'fill-outline-color': 'rgba(255,255,255,0.9)',
      'fill-opacity': layer.opacity,
    };
  } else {
    const {
      colors,
      threshold = [],
      opacityStops,
      expression,
      fallback,
    } = layer.styleConfig;
    const isThresholdNumeric = typeof threshold[0] === 'number';

    const safeExpression =
      fallback != null ? ['coalesce', expression, fallback] : expression;

    if (isThresholdNumeric) {
      // Handle numeric thresholds with interpolation
      return {
        'fill-outline-color': 'rgba(255,255,255,0.9)',
        'fill-color': [
          'interpolate',
          ['linear'],
          safeExpression,
          ...threshold.flatMap((t, index) => [t, colors[index]]),
        ],
        'fill-opacity': opacityStops
          ? [
              'interpolate',
              ['linear'],
              safeExpression,
              ...threshold.flatMap((t, index) => [
                t,
                Math.min(opacityStops[index] * layer.opacity, 1),
              ]),
            ]
          : layer.opacity,
      };
    } else {
      // Handle string thresholds with match
      return {
        'fill-outline-color': '#555',
        'fill-color': [
          'match',
          safeExpression,
          ...threshold.flatMap((t, index) => [t, colors[index]]),
          threshold
            .flatMap((t, index) => ({ t, c: colors[index] }))
            .find(({ t }) => t === fallback)?.c ?? null, // Fallback color if no match
        ],
        'fill-opacity': layer.opacity,
      };
    }
  }
}

function getCorePointLayerPaint(layer: LayerCard): CirclePaint {
  const {
    colors,
    threshold = [],
    expression,
    fallback,
    opacityStops,
  } = layer.styleConfig;

  // Check if the threshold is numeric
  const isThresholdNumeric = typeof threshold[0] === 'number';

  return {
    // Determine the paint properties for circle color based on threshold type
    'circle-color': isThresholdNumeric
      ? [
          'interpolate',
          ['linear'],
          expression,
          ...threshold.flatMap((t, index) => [t, colors[index]]),
        ]
      : [
          'match',
          expression,
          ...threshold.flatMap((t, index) => [t, colors[index]]),
          fallback || colors[0], // Fallback color if no match found
        ],
    // Determine the paint properties for circle opacity
    'circle-opacity': opacityStops
      ? [
          'interpolate',
          ['linear'],
          expression,
          ...threshold.flatMap((t, index) => [
            t,
            Math.min(opacityStops[index] * layer.opacity, 1),
          ]),
        ]
      : layer.opacity,
    // Example of dynamic circle radius based on zoom level or other data-driven approach
    'circle-radius': [
      'interpolate',
      ['linear'],
      ['zoom'],
      5,
      4, // Smaller radius at lower zoom levels
      15,
      8, // Larger radius at higher zoom levels
    ],
    'circle-stroke-color': 'black',
    'circle-stroke-width': opacityStops
      ? [
          'interpolate',
          ['linear'],
          expression,
          ...threshold.flatMap((t, index) => [
            t,
            opacityStops[index] * layer.opacity > 0 ? 1 : 0,
          ]),
        ]
      : layer.opacity > 0
        ? 1
        : 0,
  };
}

function getCoreSymbolLayerStyles(layer: LayerCard): {
  paint: SymbolPaint;
  layout: SymbolLayout;
} {
  const {
    colors,
    threshold = [],
    expression,
    fallback,
    opacityStops,
  } = layer.styleConfig;

  // Check if the threshold is numeric
  const isThresholdNumeric = typeof threshold[0] === 'number';

  return {
    paint: {
      // Determine the paint properties for circle color based on threshold type
      'icon-color': isThresholdNumeric
        ? [
            'interpolate',
            ['linear'],
            expression,
            ...threshold.flatMap((t, index) => [t, colors[index]]),
          ]
        : [
            'match',
            expression,
            ...threshold.flatMap((t, index) => [t, colors[index]]),
            fallback || colors[0], // Fallback color if no match found
          ],
      // Determine the paint properties for circle opacity
      'icon-opacity': opacityStops
        ? [
            'interpolate',
            ['linear'],
            expression,
            ...threshold.flatMap((t, index) => [
              t,
              Math.min(opacityStops[index] * layer.opacity, 1),
            ]),
          ]
        : layer.opacity,
      // Example of dynamic circle radius based on zoom level or other data-driven approach
    },
    layout: {
      'icon-image': layer.styleConfig.imageName,
      'icon-allow-overlap': true,
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        5,
        0.08, // Smaller radius at lower zoom levels
        15,
        0.2, // Larger radius at higher zoom levels
      ],
    },
  };
}

export default CustomLayersSource;
