import { UserSettings } from '~/src/global/services/userSettingsService';

export const prepareCompetitorsData = (
  userSettings: UserSettings | Record<string, never> | null
) => {
  const competitorPoi = userSettings?.poiGroups?.filter((poiGroup) => {
    return poiGroup.group.toLowerCase().includes('competitors');
  });

  const formattedCompetitors = {
    group: 'Competitors',
    color: competitorPoi?.[0]?.color ?? '',
    brands: [
      ...new Set(
        competitorPoi
          ?.flatMap((comp) => comp.brands)
          .filter((brand) => brand) ?? []
      ),
    ],
    queries: [
      ...new Set(
        competitorPoi
          ?.flatMap((comp) => comp.queries)
          .filter((query) => query) ?? []
      ),
    ],
  };

  const generatorPoi = userSettings?.poiGroups?.filter((poiGroup) => {
    return (
      poiGroup.group.toLowerCase().includes('cotenants') ||
      poiGroup.group.toLowerCase().includes('co-tenants')
    );
  });

  const formattedGenerators = {
    group: 'Cotenants',
    color: generatorPoi?.[0]?.color ?? '',
    brands: [
      ...new Set(
        generatorPoi?.flatMap((gen) => gen.brands).filter((brand) => brand) ??
          []
      ),
    ],
    queries: [
      ...new Set(
        generatorPoi?.flatMap((gen) => gen.queries).filter((query) => query) ??
          []
      ),
    ],
  };
  return {
    competitors: formattedCompetitors,
    generators: formattedGenerators,
  };
};
