import {
  Box,
  Divider,
  IconButton,
  Popover,
  Switch,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ColorState } from 'react-color';
import { CustomColorPicker } from '~/src/common/components/CustomColorPicker';
import usePricingDataStore from '../../dynamic-map/hooks/usePricingDataStore';

const PricingDataMenu = () => {
  const { pricingData, toggleBrandVisibility, hiddenBrands, toggleColor } =
    usePricingDataStore();

  const [selectedColor, setSelectedColor] = useState<{ [key: string]: string }>(
    {}
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [activeBrand, setActiveBrand] = useState<string | null>(null);

  const handleToggleVisibility = (brand: string) => {
    toggleBrandVisibility(brand);
  };

  const handleColorChange = (brand: string, color: string) => {
    toggleColor(brand, color);
    setSelectedColor((prev) => ({ ...prev, [brand]: color }));
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    brand: string
  ) => {
    setAnchorEl(event.currentTarget);
    setActiveBrand(activeBrand === brand ? null : brand);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveBrand(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={1}
      border={1}
      borderColor="grey.300"
      borderRadius={2}
      mb={2}
    >
      <Typography variant="h6" mb={2}>
        Pulse Price
      </Typography>
      {pricingData.map(({ brand, color }, index) => (
        <React.Fragment key={brand}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Box display="flex" alignItems="center" mr={1}>
              <IconButton
                onClick={(e) => handleClick(e, brand)}
                style={{
                  width: '24px',
                  height: '24px',
                  backgroundColor: selectedColor[brand] || color,
                }}
              />
              <Typography variant="body1" color="textSecondary" ml={1}>
                {brand}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" color="textSecondary" mr={1}>
                {hiddenBrands.includes(brand) ? 'Hidden' : 'Visible'}
              </Typography>
              <Switch
                checked={!hiddenBrands.includes(brand)}
                onChange={() => handleToggleVisibility(brand)}
                color="primary"
              />
            </Box>
          </Box>
          {index < pricingData.length - 1 && <Divider />}
        </React.Fragment>
      ))}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { style: { overflow: 'hidden' } },
        }}
      >
        {activeBrand && (
          <CustomColorPicker
            width={'15rem'}
            disableAlpha
            color={
              selectedColor[activeBrand] ||
              pricingData.find((p) => p.brand === activeBrand)?.color
            }
            onChange={(color: ColorState) =>
              handleColorChange(activeBrand, color.hex)
            }
          />
        )}
      </Popover>
    </Box>
  );
};

export default PricingDataMenu;
