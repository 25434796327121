import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import useFacilitiesStore from '~/src/features/dynamic-map/hooks/useFacilitiesStore';
import { kidstrongCustomerFacilities } from '../../constants/kidstrongCustomersLocation';

export const KidstrongCustomerHeatmapComponent = () => {
  const { facilities, setFacilities } = useFacilitiesStore();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const selectedFacilities = event.target.value;

    if (selectedFacilities.includes('all')) {
      setFacilities(
        facilities.length === kidstrongCustomerFacilities.length
          ? []
          : kidstrongCustomerFacilities
      );
    } else {
      setFacilities(selectedFacilities as string[]);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        pt: 1,
        alignItems: 'center',
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="facilities-label">Select Facilities</InputLabel>

        <Select
          label="Select Facilities"
          labelId="facilities-label"
          onChange={handleChange}
          value={facilities}
          multiple
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300, // Set the maximum height to 300px
                overflow: 'auto', // Enable scrolling
              },
            },
          }}
        >
          <MenuItem value={'all'}>
            {facilities.length === kidstrongCustomerFacilities.length
              ? 'Deselect All'
              : 'Select All'}
          </MenuItem>
          {kidstrongCustomerFacilities.sort().map((facility) => (
            <MenuItem key={facility} value={facility}>
              {facility}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
