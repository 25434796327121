import {
  LayerConfig,
  LayerType,
} from '~/src/features/dynamic-map-controller/components/MapLayersMenu/';
import { KidstrongCustomerHeatmapComponent } from '../components/layerControllers/KidstrongCustomerHeatmapComponent';
import { Color } from './colors';
import housingAffordabilityLayers from './housingAffordabilityLayers';
import { squareMetersPerSquareMile } from './measurementSystems';

export const customerLayers: Record<string, LayerConfig> = {
  topsail_final_seed_point: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Final Model - Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'trad_and_non_trad_seed_point_layer',
    },
    filter: ['has', 'taid'],
    styleConfig: {
      colors: [Color.transparent, Color.yellow, Color.red],
      threshold: [0, 1, 2],
      opacityStops: [0, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'qualified'], 'True'],
        2,
        ['==', ['get', 'qualified'], 'False'],
        1,
        0,
      ],
      hideBoundValues: false,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'CBSA' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { 'Population Density': 'pop_density' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        {
          'Qualification Status': {
            key: 'qualified',
            expression: [
              'case',
              ['==', ['get', 'qualified'], 'True'],
              'Qualified',
              ['==', ['get', 'qualified'], 'False'],
              'Not Qualified',
              'Unknown',
            ],
          },
        },
        { Address: 'address' },
      ],
    },
  },

  topsail_final_opt_points_primary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Primary - Topsail Final Model — Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'trad_and_non_trad_opt_points_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'primary']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        { 'POI Generator Count': 'count_within_0.25_miles' },
      ],
    },
  },
  topsail_final_opt_points_secondary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Secondary - Topsail Final Model — Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'trad_and_non_trad_opt_points_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'secondary']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        { 'POI Generator Count': 'count_within_0.25_miles' },
      ],
    },
  },
  topsail_final_opt_points_tertiary: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Tertiary - Topsail Final Model — Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_points',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'trad_and_non_trad_opt_points_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'tertiary']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        { 'POI Generator Count': 'count_within_0.25_miles' },
      ],
    },
  },

  topsail_final_opt_boundaries_primary: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Primary - Topsail Final Model — Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_boundaries',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'trad_and_non_trad_opt_polygons_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'primary']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        { 'POI Generator Count': 'count_within_0.25_miles' },
      ],
    },
  },
  topsail_final_opt_boundaries_secondary: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Secondary - Topsail Final Model — Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_boundaries',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'trad_and_non_trad_opt_polygons_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'secondary']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        { 'POI Generator Count': 'count_within_0.25_miles' },
      ],
    },
  },
  topsail_final_opt_boundaries_tertiary: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Tertiary - Topsail Final Model — Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    mergeGroup: 'topsail_final_opt_boundaries',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'trad_and_non_trad_opt_polygons_layer',
    },
    filter: ['all', ['has', 'taid'], ['==', ['get', 'ota_type'], 'tertiary']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow, Color.red],
      threshold: [0, 1, 2, 3],
      opacityStops: [0, 1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'ota_type'], 'primary'],
        1,
        ['==', ['get', 'ota_type'], 'secondary'],
        2,
        ['==', ['get', 'ota_type'], 'tertiary'],
        3,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          'Model Type': {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Traditional (Non-Seasonal)',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Non-Traditional (Seasonal)',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
        },
        {
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        { 'POI Generator Count': 'count_within_0.25_miles' },
      ],
    },
  },
  kidstrong_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '05/20/2024 — 06/20/2024 - Kidstrong',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 0 - not included
      // 90th percentile: 259
      // 95th percentile: 545
      // 99th percentile: 1550
      threshold: [0, 259, 545, 1550, 6000],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'kidstrong__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'kidstrong__date_range',
      fields: [
        {
          Impressions: {
            key: 'kidstrong__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'kidstrong__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'kidstrong__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  kidstrong_search_demand_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '09/17/2024 — 10/17/2024 - Kidstrong',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 0 - not included
      // 90th percentile: 259
      // 95th percentile: 545
      // 99th percentile: 1550
      threshold: [0, 259, 545, 1550, 6000],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'kidstrong_v2__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'kidstrong_v2__date_range',
      fields: [
        {
          Impressions: {
            key: 'kidstrong_v2__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'kidstrong_v2__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'kidstrong_v2__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  kids_gymnastics_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '05/25/2024 — 06/25/2024 - Kids Gymnastics',
    category: 'Gyms.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 0 - not included
      // 90th percentile: 80
      // 95th percentile: 205
      // 99th percentile: 439
      threshold: [0, 80, 205, 439, 1400],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'kids_gymnastics__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'kids_gymnastics__date_range',
      fields: [
        {
          Impressions: {
            key: 'kids_gymnastics__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'kids_gymnastics__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'kids_gymnastics__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  kids_gymnastics_search_demand_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '09/18/2024 — 10/18/2024 - Kids Gymnastics',
    category: 'Gyms.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 0 - not included
      // 90th percentile: 80
      // 95th percentile: 205
      // 99th percentile: 439
      threshold: [0, 80, 205, 439, 1400],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'kids_gymnastics_v2__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'kids_gymnastics_v2__date_range',
      fields: [
        {
          Impressions: {
            key: 'kids_gymnastics_v2__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'kids_gymnastics_v2__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'kids_gymnastics_v2__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  topsail_search_demand_v3: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '09/30/2024 — 10/30/2024 - Search Demand — Topsail',
    category: 'Restaurant.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'topsail_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 10
      // 90th percentile: 236
      // 95th percentile: 490
      // 99th percentile: 1067
      threshold: [0, 10, 236, 490, 1067, 6000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'topsail_v3__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'date_range',
      fields: [
        {
          Impressions: {
            key: 'topsail_v3__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'topsail_v3__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'topsail_v3__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  topsail_search_demand_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '06/07/2024 — 07/07/2024 - Search Demand — Topsail',
    category: 'Restaurant.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'topsail_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 10
      // 90th percentile: 236
      // 95th percentile: 490
      // 99th percentile: 1067
      threshold: [0, 10, 236, 490, 1067, 6000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'topsail_v2__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'date_range',
      fields: [
        {
          Impressions: {
            key: 'topsail_v2__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'topsail_v2__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'topsail_v2__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },

  topsail_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: '04/21/2024 — 05/21/2024 - Search Demand — Topsail',
    category: 'Restaurant.Search Demand',
    groupName: 'search_layers',
    mergeGroup: 'topsail_search_demand',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 10
      // 90th percentile: 236
      // 95th percentile: 490
      // 99th percentile: 1067
      threshold: [0, 10, 236, 490, 1067, 6000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'topsail__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'topsail__date_range',
      fields: [
        {
          Impressions: {
            key: 'topsail__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'topsail__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'topsail__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  kidstrong_customer_heatmap: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Zipcode - Customer Location Heatmap',
    category: 'Restaurant.Search Demand',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Client Data',
      source: 'luketruitt1.client_data_zipcode',
      source_layer: 'client_data_zipcode',
    },
    styleConfig: {
      threshold: [0, 1, 5, 10, 15],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
      ],
      expression: ['coalesce', ['get', 'kidstrong_cus_count'], 0],
      fallback: 0,
      unit: 'customers',
      boundLabels: ['Low', 'High'],
      hideBoundValues: true,
    },
    dataConfig: {
      header: 'zip_code',
      fields: [
        {
          'Customer Count': {
            expression: ['coalesce', ['get', 'kidstrong_cus_count'], 0],
          },
        },
      ],
    },
  },
  kidstrong_customer_heatmap_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Zipcode - Customer Location Heatmap by Facility',
    category: 'Restaurant.Search Demand',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Client Data',
      source: 'luketruitt1.client_data_zipcode_v2',
      source_layer: 'client_data_zipcode_v2',
    },
    styleConfig: {
      threshold: [0, 1, 5, 10, 15],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
      ],
      fallback: 0,
      unit: 'customers',
      boundLabels: ['Low', 'High'],
      hideBoundValues: true,
    },
    controllableComponent: KidstrongCustomerHeatmapComponent,
    dataConfig: {
      header: 'zip_code',
      fields: [],
    },
  },
  seafood_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Search Demand - Seafood',
    category: 'Restaurant.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      // 50th percentile: 18
      // 90th percentile: 357
      // 95th percentile: 737
      // 99th percentile: 2118
      threshold: [0, 18, 357, 737, 2118, 12000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'seafood__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'seafood__date_range',
      fields: [
        {
          Impressions: {
            key: 'seafood__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'seafood__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'seafood__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  fitness_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Search Demand - Fitness',
    category: 'Fitness.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 50, 530, 2115, 8400],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'fitness_demand__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'fitness_demand__date_range',
      fields: [
        {
          Impressions: {
            key: 'fitness_demand__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'fitness_demand__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'fitness_demand__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  city_lifestyle_zips: {
    type: LayerType.Insights,
    displayName: 'City Lifestyle - Zip Codes',
    featureType: 'polygon',
    category: 'Miscellaneous.City Lifestyle',
    groupName: 'custom_layers',
    styleConfig: {
      colors: ['rgba(173, 216, 230, 0.5)'],
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'primary_city',
      fields: [
        { Population: 'population' },
        {
          'Median Home Value': {
            key: 'avg_median_home_value',
            format: 'dollars',
          },
        },
        {
          'Median Household Income': {
            key: 'avg_median_household_income',
            format: 'dollars',
          },
        },
        { 'Owner Occupied Homes': 'owner_occupied_homes' },
        {
          'Percent Owner Occupied': {
            key: 'percent_owner_occupied',
            format: 'percent',
          },
        },
        { 'Seasonal Population': 'seasonal_population' },
        {
          'Percent Seasonal Population': {
            key: 'percent_seasonal_population',
            format: 'percent',
          },
        },
        {
          'Count of Local Businesses': {
            expression: [
              '+',
              ['coalesce', ['get', 'business_counts_Auto Dealers'], 0],
              ['coalesce', ['get', 'business_counts_Beauty Salons'], 0],
              ['coalesce', ['get', 'business_counts_Chiropractors'], 0],
              ['coalesce', ['get', 'business_counts_Building Contractors'], 0],
              ['coalesce', ['get', 'business_counts_Equipment Contractors'], 0],
              ['coalesce', ['get', 'business_counts_Specialty Contractors'], 0],
              ['coalesce', ['get', 'business_counts_Dentists'], 0],
              ['coalesce', ['get', 'business_counts_Physicians'], 0],
              ['coalesce', ['get', 'business_counts_Florists'], 0],
              ['coalesce', ['get', 'business_counts_Insurance Agencies'], 0],
              ['coalesce', ['get', 'business_counts_Design Services'], 0],
              ['coalesce', ['get', 'business_counts_Jewelry Stores'], 0],
              ['coalesce', ['get', 'business_counts_Loan Brokers'], 0],
              ['coalesce', ['get', 'business_counts_Health Practitioners'], 0],
              ['coalesce', ['get', 'business_counts_Optometrists'], 0],
              ['coalesce', ['get', 'business_counts_Commercial Banking'], 0],
              ['coalesce', ['get', 'business_counts_Investment Advice'], 0],
              ['coalesce', ['get', 'business_counts_Hospitals'], 0],
              ['coalesce', ['get', 'business_counts_Real Estate Agents'], 0],
              ['coalesce', ['get', 'business_counts_Landscaping Services'], 0],
              [
                'coalesce',
                ['get', 'business_counts_Residential Construction'],
                0,
              ],
              ['coalesce', ['get', 'business_counts_Law Practices'], 0],
              ['coalesce', ['get', 'business_counts_Veterinary Services'], 0],
            ],
          },
        },
      ],
    },
    sourceTileset: {
      label: 'Zip Codes',
      source: 'luketruitt1.city-life-zipcodes',
      source_layer: 'city-life-zipcodes',
    },
  },
  city_lifestyle_places: {
    type: LayerType.Insights,
    displayName: 'City Lifestyle - Cities',
    featureType: 'polygon',
    category: 'Miscellaneous.City Lifestyle',
    groupName: 'custom_layers',
    styleConfig: {
      colors: ['rgba(173, 216, 230, 0.5)'],
    },
    dataConfig: {
      header: 'city_id',
      fields: [
        { Population: 'population' },
        {
          'Median Home Value': {
            key: 'avg_median_home_value',
            format: 'dollars',
          },
        },
        {
          'Median Household Income': {
            key: 'avg_median_household_income',
            format: 'dollars',
          },
        },
        { 'Owner Occupied Homes': 'owner_occupied_homes' },
        {
          'Percent Owner Occupied': {
            key: 'percent_owner_occupied',
            format: 'percent',
          },
        },
        { 'Seasonal Population': 'seasonal_population' },
        {
          'Percent Seasonal Population': {
            key: 'percent_seasonal_population',
            format: 'percent',
          },
        },
        {
          'Count of Local Businesses': {
            expression: [
              '+',
              ['coalesce', ['get', 'business_counts_Auto Dealers'], 0],
              ['coalesce', ['get', 'business_counts_Beauty Salons'], 0],
              ['coalesce', ['get', 'business_counts_Chiropractors'], 0],
              ['coalesce', ['get', 'business_counts_Building Contractors'], 0],
              ['coalesce', ['get', 'business_counts_Equipment Contractors'], 0],
              ['coalesce', ['get', 'business_counts_Specialty Contractors'], 0],
              ['coalesce', ['get', 'business_counts_Dentists'], 0],
              ['coalesce', ['get', 'business_counts_Physicians'], 0],
              ['coalesce', ['get', 'business_counts_Florists'], 0],
              ['coalesce', ['get', 'business_counts_Insurance Agencies'], 0],
              ['coalesce', ['get', 'business_counts_Design Services'], 0],
              ['coalesce', ['get', 'business_counts_Jewelry Stores'], 0],
              ['coalesce', ['get', 'business_counts_Loan Brokers'], 0],
              ['coalesce', ['get', 'business_counts_Health Practitioners'], 0],
              ['coalesce', ['get', 'business_counts_Optometrists'], 0],
              ['coalesce', ['get', 'business_counts_Commercial Banking'], 0],
              ['coalesce', ['get', 'business_counts_Investment Advice'], 0],
              ['coalesce', ['get', 'business_counts_Hospitals'], 0],
              ['coalesce', ['get', 'business_counts_Real Estate Agents'], 0],
              ['coalesce', ['get', 'business_counts_Landscaping Services'], 0],
              [
                'coalesce',
                ['get', 'business_counts_Residential Construction'],
                0,
              ],
              ['coalesce', ['get', 'business_counts_Law Practices'], 0],
              ['coalesce', ['get', 'business_counts_Veterinary Services'], 0],
            ],
          },
        },
        { 'Zip Code Count': 'zip_code_count' },
        {
          'Zip Codes': {
            key: 'zip_codes',
            format: 'chips',
          },
        },
      ],
    },
    sourceTileset: {
      label: 'Cities',
      source: 'luketruitt1.cities',
      source_layer: 'cities',
    },
  },
  city_lifestyle_local_businesses: {
    type: LayerType.Insights,
    displayName: 'City Lifestyle - Local Businesses',
    featureType: 'polygon',
    category: 'Miscellaneous.City Lifestyle',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'City Lifestyle - Local Businesses',
      source: 'luketruitt1.local-business-zip-counts',
      source_layer: 'local-business-zip-counts',
    },
    styleConfig: {
      colors: [Color.white, Color.blue, Color.blue, Color.purple],
      threshold: [0, 50, 400, 800],
      opacityStops: [0, 0.5, 1, 1],
      fallback: 0,
      expression: [
        'case',
        ['==', ['get', 'area'], 0],
        0,
        [
          'round',
          [
            '*',
            ['/', ['get', 'total_businesses'], ['get', 'area']],
            squareMetersPerSquareMile,
          ],
        ],
      ],
      unit: 'businesses / mi\u00B2',
    },
    dataConfig: {
      header: 'zip_code',
      fields: [
        { 'Count of Local Businesses': 'total_businesses' },
        {
          'Local Businesses / mi\u00B2': {
            expression: [
              'case',
              ['==', ['get', 'area'], 0],
              0,
              [
                'round',
                [
                  '*',
                  ['/', ['get', 'total_businesses'], ['get', 'area']],
                  squareMetersPerSquareMile,
                ],
              ],
            ],
          },
        },
      ],
    },
  },
  multi_family_housing: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Block Group - Multi-Family Housing Units',
    category: 'Population.Income',
    groupName: 'demographic_layers',
    class: 'housing',
    sourceTileset: {
      label: 'Block Group Insights',
      source: 'luketruitt1.block_group_insights',
      source_layer: 'block_group_insights',
    },
    styleConfig: {
      threshold: [0, 100, 3000, 7500],
      colors: [Color.white, Color.yellow, Color.orange, Color.red],
      fallback: 0,
      expression: [
        'case',
        ['==', ['get', 'area'], 0],
        0,
        [
          'round',
          [
            '*',
            ['/', ['get', 'mfh_units_total'], ['get', 'area']],
            squareMetersPerSquareMile,
          ],
        ],
      ],
      opacityStops: [0, 1, 1, 1],
      unit: 'units / mi\u00B2',
    },
    dataConfig: {
      header: 'id',
      fields: [
        { 'Multi-Family Units': 'mfh_units_total' },
        {
          'Units / mi\u00B2': {
            expression: [
              'case',
              ['==', ['get', 'area'], 0],
              0,
              [
                'round',
                [
                  '*',
                  ['/', ['get', 'mfh_units_total'], ['get', 'area']],
                  squareMetersPerSquareMile,
                ],
              ],
            ],
          },
        },
      ],
    },
  },
  bg_opportunity_zones: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Block Group - Opportunity Zones',
    category: 'Population.Income',
    groupName: 'boundary_layers',
    sourceTileset: {
      label: 'Block Group Opportunity Zones',
      source: 'luketruitt1.bg_opportunity_zones',
      source_layer: 'bg_opportunity_zones',
    },
    filter: ['==', ['get', 'is_opportunity_zone'], true],
    styleConfig: {
      colors: [Color.purple],
      fallback: 0,
      opacityStops: [0, 1],
    },
    dataConfig: {
      header: 'id',
    },
  },
  ...housingAffordabilityLayers,
  zip_franchising_interest: {
    type: LayerType.Insights,
    featureType: 'polygon',
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 173, 706, 1766.48, 10000],
      opacityStops: [0, 0, 1, 1, 1],
      colors: [
        Color.yellow,
        Color.yellowOrange,
        Color.orange,
        Color.darkOrange,
        Color.red,
      ],
      expression: ['get', 'franchising_impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'id',
      fields: [{ 'Franchising Impressions': 'franchising_impressions' }],
    },
    sourceTileset: {
      label: 'Zip Codes',
      source: 'luketruitt1.insights_zipcode',
      source_layer: 'insights_zipcode',
    },
    displayName: 'Franchising Demand',
    permission: 'read:insight_franchising_interest',
    category: 'Retail.Franchise',
    groupName: 'search_layers',
  },
  zip_consumer_retail_intent: {
    type: LayerType.Insights,
    featureType: 'polygon',
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 11787.08, 34384.36, 98819.33, 134000],
      opacityStops: [0, 1, 1, 1, 1],
      colors: [
        Color.yellow,
        Color.yellowOrange,
        Color.orange,
        Color.darkOrange,
        Color.red,
      ],
      expression: ['get', 'retail_intent_impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'id',
      fields: [{ 'Retail Intent Impressions': 'retail_intent_impressions' }],
    },
    sourceTileset: {
      label: 'Zip Codes',
      source: 'luketruitt1.insights_zipcode',
      source_layer: 'insights_zipcode',
    },
    displayName: 'Consumer Demand',
    permission: 'read:insight_consumer_retail_intent',
    category: 'Retail',
    groupName: 'search_layers',
  },
  zip_search_carls_jr: {
    type: LayerType.Insights,
    featureType: 'polygon',
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 13, 27, 41, 42],
      opacityStops: [0, 1, 1, 1],
      colors: [
        Color.yellow,
        Color.yellowOrange,
        Color.orange,
        Color.darkOrange,
        Color.red,
      ],
      expression: ['get', 'carls_junior_impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'id',
      fields: [{ 'Carls Jr Impressions': 'carls_junior_impressions' }],
    },
    sourceTileset: {
      label: 'Zip Codes',
      source: 'luketruitt1.insights_zipcode',
      source_layer: 'insights_zipcode',
    },
    displayName: `Consumer Demand - Carls Jr`,
    permission: 'read:ent_walkons',
    category: 'Restaurant',
    groupName: 'search_layers',
  },
  designated_fuel_corridors: {
    type: LayerType.Insights,
    featureType: 'line',
    sourceTileset: {
      label: 'Aiquetech',
      source: 'luketruitt1.aiquetech',
      source_layer: 'designated_fuel_corridors',
    },
    displayName: 'Designated Fuel Corridors',
    category: 'Miscellaneous.Search American',
    groupName: 'custom_layers',
    styleConfig: { colors: ['#000'] },
  },
  power_transmission_lines: {
    type: LayerType.Insights,
    featureType: 'line',
    sourceTileset: {
      label: 'Aiquetech',
      source: 'luketruitt1.aiquetech',
      source_layer: 'power_transmission_lines',
    },
    displayName: 'Power Transmission Lines',
    styleConfig: { colors: [Color.red] },
    category: 'Miscellaneous.Search American',
    groupName: 'custom_layers',
  },
  topsail_traditional_seed_point: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Traditional Model Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_point_layer',
    },
    filter: [
      'match',
      ['get', 'performance_classification'],
      'primary',
      true,
      'secondary',
      true,
      'tertiary',
      true,
      false,
    ],
    styleConfig: {
      colors: [Color.darkGreen, Color.yellow, Color.red],
      threshold: [1, 2, 3],
      opacityStops: [1, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'performance_classification'], 'primary'],
        1,
        ['==', ['get', 'performance_classification'], 'secondary'],
        2,
        ['==', ['get', 'performance_classification'], 'tertiary'],
        3,
        0,
      ],
      boundLabels: ['Primary', 'Tertiary'],
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Performance Index': 'performance_index' },
        { 'Population Density': 'pop_density' },
        {
          'Urbanization Type': {
            expression: [
              'case',
              ['<=', ['get', 'urbanicity_classification'], 3],
              'Suburban',
              ['>=', ['get', 'urbanicity_classification'], 4],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Urbanization Classification': {
            expression: [
              'match',
              ['get', 'urbanicity_classification'],
              0,
              'Unknown',
              1,
              'Rural',
              2,
              'In-Town',
              3,
              'Suburban',
              4,
              'Metropolitan',
              5,
              'Urban',
              6,
              'Urban Core',
              'Unknown',
            ],
          },
        },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
      ],
    },
  },
  topsail_traditional_seed_point_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Traditional Model Seed Points V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'seed_point_layer_v2',
    },
    filter: ['has', 'taid'],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: ['case', ['has', 'taid'], 1, 0],
      hideBoundValues: false,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'CBSA' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { 'Population Density': 'pop_density' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_non_trad_seed_point: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Non-Traditional Model Seed Points',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'non_trad_seed_point_layer',
    },
    filter: ['has', 'taid'],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: ['case', ['has', 'taid'], 1, 0],
      hideBoundValues: false,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          Seasonality: {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Non-Seasonal',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Seasonal',
              'Unknown',
            ],
          },
        },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_sample_set: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Traditional Model Sample Set',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'sample_set_layer',
    },
    filter: [
      'case',
      ['<=', ['get', 'urbanization_classification'], 'suburban'],
      true,
      ['>=', ['get', 'urbanization_classification'], 'urban'],
      true,
      false,
    ],
    styleConfig: {
      colors: [Color.yellow, Color.red],
      threshold: [1, 2],
      opacityStops: [1, 1],
      expression: [
        'case',
        ['<=', ['get', 'urbanization_classification'], 'suburban'],
        1,
        ['>=', ['get', 'urbanization_classification'], 'urban'],
        2,
        0,
      ],
      fallback: 0,
      boundLabels: ['Suburban', 'Urban'],
      hideBoundValues: true,
    },
    dataConfig: {
      header: 'location_id',
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Location Name': 'location_name' },
        { 'Urbanization Classification': 'urbanization_classification' },
      ],
    },
  },
  topsail_traditional_primary_urban_points: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Primary Urban Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'primary'],
      ['>=', ['get', 'urbanicity_classification'], 4],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'primary'],
          ['>=', ['get', 'urbanicity_classification'], 4],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Performance Index': 'performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Classification': 'urbanicity_classification' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
      ],
    },
  },
  topsail_traditional_primary_suburban_points: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Primary Suburban Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'primary'],
      ['<=', ['get', 'urbanicity_classification'], 3],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'primary'],
          ['<=', ['get', 'urbanicity_classification'], 3],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Performance Index': 'performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Classification': 'urbanicity_classification' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
      ],
    },
  },
  topsail_traditional_primary_urban_boundaries: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Primary Urban Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'primary'],
      ['>=', ['get', 'urbanicity_classification'], 4],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'primary'],
          ['>=', ['get', 'urbanicity_classification'], 4],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Performance Index': 'performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Classification': 'urbanicity_classification' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
      ],
    },
  },
  topsail_traditional_primary_suburban_boundaries: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Primary Suburban Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'primary'],
      ['<=', ['get', 'urbanicity_classification'], 3],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'primary'],
          ['<=', ['get', 'urbanicity_classification'], 3],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Performance Index': 'performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Classification': 'urbanicity_classification' },
        { 'Workforce Density': 'workforce_density' },
        {
          Count: {
            expression: [
              'coalesce',
              ['get', 'count_within_0.25_miles'],
              ['get', 'count_within_0.5_miles'],
            ],
          },
        },
        {
          'Count Type': {
            expression: [
              'case',
              ['has', 'count_within_0.25_miles'],
              'within 0.25 miles',
              ['has', 'count_within_0.5_miles'],
              'within 0.5 miles',
              'N/A',
            ],
          },
        },
      ],
    },
  },
  plotr_retail_proximity_heatmap: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Plotr Retail Proximity Heatmap',
    category: 'Retail.RetailProximity',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Retail Proximity',
      source: 'luketruitt1.generators',
      source_layer: 'generators',
    },
    styleConfig: {
      colors: [
        Color.transparent,
        '#FFFF00', // Yellow
        '#FFA500', // Orange
        '#FF0000',
      ],
      threshold: [0, 1, 5, 9],
      opacityStops: [0, 1, 1, 1],
      unit: 'retail_proximity',
      boundLabels: ['Low', 'High'],
      expression: ['get', 'retail_proximity'],
    },
    dataConfig: {
      header: 'id',
      fields: [
        {
          'Retail Proximity Generators': {
            key: 'retail_proximity',
            format: 'integer',
          },
        },
      ],
    },
  },
  child_development_heatmap: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Plotr Child Development Heatmap',
    category: 'Education.ChildDevelopment',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Child Development',
      source: 'luketruitt1.generators',
      source_layer: 'generators',
    },
    styleConfig: {
      colors: [
        Color.transparent,
        '#FFFF00', // Yellow
        '#FFA500', // Orange
        '#FF0000',
      ],
      threshold: [0, 1, 3, 6],
      opacityStops: [0, 1, 1, 1],
      expression: ['get', 'child_development'],
      unit: 'child_development',
      boundLabels: ['Low', 'High'],
    },
    dataConfig: {
      header: 'id',
      fields: [
        {
          'Child Development Generators': {
            key: 'child_development',
            format: 'integer',
          },
        },
      ],
    },
  },
  topsail_traditional_primary_points_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Traditional Model - Primary Optimized Trade Areas V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer_v3',
    },
    filter: [
      'all',
      ['==', ['get', 'ota_type'], 'primary'],
      ['==', ['get', 'seasonality'], 'traditional'],
    ],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.darkGreen],
      threshold: [1],
      opacityStops: [1],
      expression: [
        'case',
        [
          'in',
          ['get', 'urbanicity'],
          [
            'literal',
            [
              'Rural (1)',
              'In-Town (2)',
              'Suburban (3)',
              'Metropolitan (4)',
              'Urban (5)',
              'Urban Core (6)',
            ],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Latitude, Longitude': {
            key: 'Latitude, Longitude',
            format: 'integer',
            expression: [
              'concat',
              ['number', ['get', 'latitude'], 6],
              ', ',
              ['number', ['get', 'longitude'], 6],
            ],
          },
        },
        { TAID: 'taid' },
        { Urbanicity: 'urbanicity' },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            format: 'percent',
          },
        },
        { 'Population Density - 3 Mile': 'pop_density' },
        { 'Workforce Density - 3 Mile': 'workforce_density' },
        { 'Drive Time': 'drivetime' },
        {
          Seasonality: {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Non-Seasonal',
              ['==', ['get', 'seasonality'], 'non-traditional'],
              'Seasonal',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        { 'Model Score': 'model_score' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Market Ranking': 'market_ranking' },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            format: 'percent',
          },
        },
        { 'POI Generator Count': 'poi_generator_count' },
      ],
    },
  },
  topsail_traditional_primary_boundaries_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Primary Drive Times V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer_v3',
    },
    filter: [
      'all',
      ['==', ['get', 'ota_type'], 'primary'],
      ['==', ['get', 'seasonality'], 'traditional'],
    ],
    styleConfig: {
      colors: [Color.darkGreen],
      threshold: [1],
      opacityStops: [1],
      expression: [
        'case',
        [
          'in',
          ['get', 'urbanicity'],
          [
            'literal',
            [
              'Rural (1)',
              'In-Town (2)',
              'Suburban (3)',
              'Metropolitan (4)',
              'Urban (5)',
              'Urban Core (6)',
            ],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        {
          'Latitude, Longitude': {
            format: 'integer',
            expression: [
              'concat',
              ['number', ['get', 'latitude'], 6],
              ', ',
              ['number', ['get', 'longitude'], 6],
            ],
          },
        },
        { TAID: 'taid' },
        { Urbanicity: 'urbanicity' },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            format: 'percent',
          },
        },
        { 'Population Density - 3 Mile': 'pop_density' },
        { 'Workforce Density - 3 Mile': 'workforce_density' },
        { 'Drive Time': 'drivetime' },
        {
          Seasonality: {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Non-Seasonal',
              ['==', ['get', 'seasonality'], 'non-traditional'],
              'Seasonal',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        { 'Model Score': 'model_score' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Market Ranking': 'market_ranking' },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              ['upcase', ['slice', ['get', 'ota_type'], 0, 1]],
              ['downcase', ['slice', ['get', 'ota_type'], 1]],
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            format: 'percent',
          },
        },
        { 'POI Generator Count': 'poi_generator_count' },
      ],
    },
  },
  topsail_traditional_secondary_urban_points_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Secondary Urban Optimized Trade Areas V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'secondary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.yellow],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'secondary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_secondary_suburban_points_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Secondary Suburban Optimized Trade Areas V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer_v3',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'secondary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.yellow],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'secondary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_secondary_urban_boundaries_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Secondary Urban Drive Times V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer_v2',
    },
    styleConfig: {
      colors: [Color.transparent, Color.yellow],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'secondary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_secondary_suburban_boundaries_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName:
      'Topsail Traditional Model - Secondary Suburban Drive Times V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'secondary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.yellow],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'secondary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_tertiary_urban_points_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Tertiary Urban Optimized Trade Areas V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'tertiary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'tertiary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_tertiary_suburban_points_v2: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName:
      'Topsail Traditional Model - Tertiary Suburban Optimized Trade Areas V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'tertiary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      imageName: 'geometry-point-pin-sdf',
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'tertiary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_tertiary_urban_boundaries_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Tertiary Urban Drive Times V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'tertiary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'tertiary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Urban (6)', 'Urban (5)', 'Urban (4)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_traditional_tertiary_suburban_boundaries_v2: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Traditional Model - Tertiary Suburban Drive Times V2',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_layer_v2',
    },
    filter: [
      'all',
      ['==', ['get', 'performance_classification'], 'tertiary'],
      [
        'in',
        ['get', 'urbanization_type'],
        ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
      ],
    ],
    styleConfig: {
      colors: [Color.transparent, Color.red],
      threshold: [0, 1],
      opacityStops: [0, 1],
      expression: [
        'case',
        [
          'all',
          ['==', ['get', 'performance_classification'], 'tertiary'],
          [
            'in',
            ['get', 'urbanization_type'],
            ['literal', ['Suburban (3)', 'Suburban (2)', 'Suburban (1)']],
          ],
        ],
        1,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'TAID: ', ['to-string', ['get', 'taid']]],
      fields: [
        { CBSA: 'cbsa' },
        { Latitude: 'latitude' },
        { Longitude: 'longitude' },
        { 'Market Ranking': 'market_ranking' },
        { 'National Ranking': 'ntl_ranking' },
        { 'Performance Classification': 'performance_classification' },
        { 'Predicted Performance Index': 'predicted_performance_index' },
        { 'Population Density': 'pop_density' },
        { 'Urbanization Type': 'urbanization_type' },
        { 'Urbanicity Type': 'urbanicity_type' },
        { 'Workforce Density': 'workforce_density' },
        { 'POI Generator Count': 'poi_generator_count' },
        { 'Top Profile Percentage': 'top_profile_pct' },
        { Seasonality: 'seasonality' },
        { Address: 'address' },
      ],
    },
  },
  topsail_non_trad_points: {
    type: LayerType.Insights,
    featureType: 'point',
    displayName: 'Topsail Non-Traditional Model - Optimized Trade Areas',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_points_non_trad_layer',
    },
    filter: ['all', ['==', ['get', 'seasonality'], 'non_traditional']],
    styleConfig: {
      imageName: 'geometry-point-pin-sdf',
      colors: [Color.transparent, Color.darkGreen, Color.yellow],
      threshold: [0, 1, 2],
      opacityStops: [0, 1, 1],
      expression: [
        'case',

        ['==', ['get', 'performance_classification'], 'primary'],
        1,
        ['==', ['get', 'performance_classification'], 'secondary'],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        // { 'Drive Time': 'drivetime' },
        {
          Seasonality: {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Non-Seasonal',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Seasonal',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              [
                'upcase',
                ['slice', ['get', 'performance_classification'], 0, 1],
              ],
              ['downcase', ['slice', ['get', 'performance_classification'], 1]],
            ],
          },
        },
        { 'POI Generator Count': 'count_within_0.25_miles' },
      ],
    },
  },
  topsail_non_trad_boundaries: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Topsail Non-Traditional Model - Drive Times',
    category: 'Restaurant.Topsail',
    groupName: 'Zones',
    sourceTileset: {
      label: 'Topsail',
      source: 'luketruitt1.topsail',
      source_layer: 'optimization_polygons_non_trad_layer',
    },
    filter: ['all', ['==', ['get', 'seasonality'], 'non_traditional']],
    styleConfig: {
      colors: [Color.transparent, Color.darkGreen, Color.yellow],
      threshold: [0, 1, 2],
      opacityStops: [0, 1, 1],
      expression: [
        'case',
        ['==', ['get', 'performance_classification'], 'primary'],
        1,
        ['==', ['get', 'performance_classification'], 'secondary'],
        2,
        0,
      ],
      fallback: 0,
      hideBoundValues: true,
    },
    dataConfig: {
      header: ['concat', 'Address: ', ['get', 'address']],
      fields: [
        { CBSA: 'cbsa' },
        { TAID: 'taid' },
        {
          Urbanicity: {
            key: 'urbanization',
            expression: [
              'case',
              ['<=', ['to-number', ['get', 'urbanization']], 3],
              'Suburban',
              ['>', ['to-number', ['get', 'urbanization']], 3],
              'Urban',
              'Unknown',
            ],
          },
        },
        {
          'Top Profile Percentage': {
            key: 'top_profile_pct',
            expression: ['/', ['to-number', ['get', 'top_profile_pct']], 100],
            format: 'percent',
          },
        },
        {
          'Population Density - 3 Mile': {
            key: 'pop_density',
            expression: ['round', ['to-number', ['get', 'pop_density']]],
            format: 'integer',
          },
        },
        {
          'Workforce Density - 3 Mile': {
            key: 'workforce_density',
            expression: ['round', ['to-number', ['get', 'workforce_density']]],
            format: 'integer',
          },
        },
        // { 'Drive Time': 'drivetime' },
        {
          Seasonality: {
            key: 'seasonality',
            expression: [
              'case',
              ['==', ['get', 'seasonality'], 'traditional'],
              'Non-Seasonal',
              ['==', ['get', 'seasonality'], 'non_traditional'],
              'Seasonal',
              'Unknown',
            ],
          },
        },
        { 'Model Type': 'urbanicity_type' },
        {
          'Model Score': {
            key: 'performance_index',
            expression: [
              'number',
              ['to-number', ['get', 'performance_index']],
              2,
            ],
          },
        },
        {
          'National Ranking': {
            key: 'ntl_ranking',
            expression: ['to-number', ['get', 'ntl_ranking']],
            format: 'integer',
          },
          'Market Ranking': {
            key: 'market_ranking',
            expression: ['to-number', ['get', 'market_ranking']],
            format: 'integer',
          },
        },
        {
          'OTA Type': {
            key: 'ota_type',
            expression: [
              'concat',
              [
                'upcase',
                ['slice', ['get', 'performance_classification'], 0, 1],
              ],
              ['downcase', ['slice', ['get', 'performance_classification'], 1]],
            ],
          },
        },
        { 'POI Generator Count': 'count_within_0.25_miles' },
      ],
    },
  },
  supermatt_walkin_self_service_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Search Demand - Supermatt Walk-in Self Service',
    category: 'Laundry.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 100, 500, 1000, 5000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'supermatt_walkin_self_service__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'supermatt_walkin_self_service__date_range',
      fields: [
        {
          Impressions: {
            key: 'supermatt_walkin_self_service__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'supermatt_walkin_self_service__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'supermatt_walkin_self_service__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  supermatt_pickup_delivery_search_demand: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Search Demand - Supermatt Pickup & Delivery',
    category: 'Laundry.Search Demand',
    groupName: 'search_layers',
    sourceTileset: {
      label: 'Search Demand',
      source: 'luketruitt1.search_demand_zipcode',
      source_layer: 'search_demand_zipcode',
    },
    styleConfig: {
      idLabelFlag: true,
      threshold: [0, 10, 100, 500, 1000, 5000],
      opacityStops: [0, 1, 1, 1, 1, 1],
      colors: [
        Color.white,
        Color.yellow,
        Color.orange,
        Color.red,
        Color.purple,
        Color.darkPurple,
      ],
      expression: ['get', 'supermatt_pickup_delivery__impressions'],
      fallback: 0,
      unit: 'impressions',
    },
    dataConfig: {
      header: 'zip_code',
      caption: 'supermatt_pickup_delivery__date_range',
      fields: [
        {
          Impressions: {
            key: 'supermatt_pickup_delivery__impressions',
            format: 'integer',
          },
        },
        {
          Clicks: {
            key: 'supermatt_pickup_delivery__clicks',
            format: 'integer',
          },
        },
        {
          'Avg. CPC': {
            key: 'supermatt_pickup_delivery__average_cpc',
            format: 'currency',
          },
        },
      ],
    },
  },
  efc_customer_heatmap: {
    type: LayerType.Insights,
    featureType: 'polygon',
    displayName: 'Customer Heatmap - EFC',
    category: 'EFC.Topsail',
    groupName: 'custom_layers',
    sourceTileset: {
      label: 'Customer Heatmap',
      source: 'luketruitt1.customer_heatmap_efc',
      source_layer: 'customer_heatmap_efc',
    },
    styleConfig: {
      threshold: [0, 1, 5, 10, 15, 20, 30],
      opacityStops: [0, 1, 1, 1, 1, 1, 1],
      colors: [
        Color.transparent,
        Color.yellow,
        Color.yellowOrange,
        Color.orange,
        Color.darkOrange,
        Color.red,
        Color.purple,
      ],
      expression: ['get', 'count'],
      fallback: 0,
    },
    dataConfig: {
      header: 'id',
      fields: [{ Customers: 'count' }],
    },
  },
};
