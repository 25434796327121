import { useAuth0 } from '@auth0/auth0-react';
import { parseEnv } from '@plotr/common-utils';
import { useCallback, useEffect, useState } from 'react';

const env = parseEnv({ AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE });

export default function useAccessToken() {
  const { getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated } =
    useAuth0();

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAccessToken = useCallback(async () => {
    if (!isAuthenticated) {
      // If the user is not authenticated, do nothing.
      return;
    }

    try {
      setIsLoading(true);

      const token = await getAccessTokenSilently({
        authorizationParams: { audience: env.AUTH0_AUDIENCE },
      });

      setAccessToken(token);
      setIsLoading(false);
    } catch (error) {
      console.error(
        'Silent token fetch failed, attempting fetch with popup',
        error
      );

      try {
        setIsLoading(true);

        const token = await getAccessTokenWithPopup({
          authorizationParams: { audience: env.AUTH0_AUDIENCE },
        });
        setAccessToken(token ?? null);
        setIsLoading(false);
      } catch (popupError) {
        console.error('Token fetch failed', popupError);
      }
    }
  }, [getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated]);

  useEffect(() => {
    getAccessToken();
  }, [isAuthenticated, getAccessToken]);

  return {
    accessToken,
    isLoading,
  };
}
