import { MultiplayerData } from '@plotr/multiplayer-data';

import pinMethods from './lib/pins';
import rulesetMethods from './lib/rulesets';
import territoryMethods from './lib/territories';
import templateMethods from './lib/templates';
import { statusToTagsAndRuleset } from './migrations/statusToTagsAndRuleset';

export const plotrMultiplayerData = new MultiplayerData({
  version: 1,
  migrations: {
    1: statusToTagsAndRuleset,
  },
  methods: (ydoc) => ({
    pins: pinMethods(ydoc),
    territories: territoryMethods(ydoc),
    rulesets: rulesetMethods(ydoc),
    templates: templateMethods(ydoc),
  }),
});
