import { Box, Fade, Grid, Modal, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import ReactDOM from 'react-dom';
import {
  ModalNames,
  useModalDataContext,
} from '~/src/common/hooks/useModalDataContext';
import usePinTemplates from '~/src/global/hooks/usePinTemplates';
import { TemplateCard } from '../menu/TemplatesCollapseSection';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SelectTemplateForPinModal = () => {
  const { open, onOpenChange, data } = useModalDataContext();
  const isOpen = open.selectTemplateForPinModal;
  const onSelect = data?.selectTemplateForPinModal?.onSelect;
  const templates = usePinTemplates();

  const onClose = () => {
    onOpenChange(ModalNames.SELECT_TEMPLATE_FOR_PIN, false);
  };

  const modalRoot = document.getElementById('root') as Element;
  const mainRender = (
    <Modal
      aria-labelledby="Create template"
      aria-describedby="Create a new pin template"
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <Typography id="Create template template" variant="h6" component="h2">
            Select template
          </Typography>

          <Grid container spacing={1}>
            {templates?.map((template) => (
              <Grid xs={12} item key={template?.id}>
                <TemplateCard template={template} onSelect={onSelect} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );

  return ReactDOM.createPortal(mainRender, modalRoot);
};

export default SelectTemplateForPinModal;
