import { Box, Typography } from '@mui/material';
import { Marker } from 'react-map-gl';
import getPinURL from '~/src/common/helpers/getPinURL';

export const ReportPin = ({
  pos,
  isSelected = false,
  isEvaluated = false,
  onClick,
  color = '#C3C3C3',
  selectedColor = '#3FB1CE',
  selectedBorder,
  disableSelection = false,
  label,
  zIndex,
}: {
  pos: { lng: number; lat: number };
  isSelected: boolean;
  isEvaluated?: boolean;
  onClick?: () => void;
  color?: string;
  selectedColor?: string;
  selectedBorder?: string;
  disableSelection?: boolean;
  label?: string;
  zIndex?: number;
}) => {
  const pinURL = getPinURL({
    color,
    background: '#FFFFFF',
  });

  const selectedPinUrl = getPinURL({
    color: selectedColor,
    background: '#FFFFFF',
    border: selectedBorder,
  });

  return (
    <Marker
      longitude={pos.lng}
      latitude={pos.lat}
      anchor="bottom"
      onClick={!disableSelection ? onClick : undefined}
      style={{ zIndex }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          cursor: onClick ? 'pointer' : 'default',
        }}
      >
        <img
          style={{ width: isEvaluated ? '3rem' : '2rem', height: 'auto' }}
          src={isSelected ? selectedPinUrl : pinURL}
          alt="custom pin"
        />
        {label && (
          <Typography
            variant="body1"
            position="absolute"
            sx={{
              transition: 'opacity 0.2s ease-in-out',
              top: '.4rem',
              textAlign: 'center',
              fontSize: '.75rem',
              fontWeight: 500,
            }}
          >
            {label}
          </Typography>
        )}
      </Box>
    </Marker>
  );
};
