import { z } from 'zod';

const ValidateTemplateSchema = z
  .object({
    label: z
      .string({ message: 'Label is required field' })
      .min(1, { message: 'Label is required field' }),
    tags: z.array(z.string()),
    keyValue: z.record(z.string(), z.string()),
    group: z.string(),
  })
  .partial({ tags: true, keyValue: true, group: true });
type ValidateTemplateSchemaType = z.infer<typeof ValidateTemplateSchema>;

export { ValidateTemplateSchema as default, ValidateTemplateSchemaType };
