import { CustomPin } from '@plotr/plotr-multiplayer-data';
import { useState } from 'react';
import { Boundary } from '../demographic-point-lookup/hooks/useDemographicStore';
// import useMobileOverlapStore from '../demographic-point-lookup/hooks/useMobileOverlapStore';
import { PlacesRequest } from '../dynamic-map/services/poiService';
import { usePulseReport } from './services/pulse-service';

interface ReportLoadingStatesProps {
  competitors: { brandId: string }[];
  generators: { brandId: string }[];
  competitorQueries: PlacesRequest[];
  generatorQueries: PlacesRequest[];
  evaluatedPin: CustomPin | null;
  boundaryData: Boundary | null;
  isMapOpen: boolean;
}

export const useReportLoadingStates = ({
  competitors,
  generators,
  competitorQueries,
  generatorQueries,
  evaluatedPin,
  boundaryData,
  isMapOpen,
}: ReportLoadingStatesProps) => {
  const [isTradeAreaReady, setIsTradeAreaReady] = useState(false);
  const [isCustomerSummaryReady, setIsCustomerSummaryReady] = useState(false);

  const { data: report, isLoading: isReportLoading } = usePulseReport(
    competitors.filter((brand) => brand !== undefined),
    generators.filter((brand) => brand !== undefined),
    competitorQueries,
    generatorQueries,
    evaluatedPin,
    boundaryData,
    isMapOpen
  );

  // const { mobileOverlapDataIsLoading } = useMobileOverlapStore();

  const isAllContentReady =
    isTradeAreaReady &&
    (!isReportLoading || (!competitors.length && !generators.length)) && // Modified condition
    // !mobileOverlapDataIsLoading &&
    isCustomerSummaryReady;

  return {
    isAllContentReady,
    setIsTradeAreaReady,
    setIsCustomerSummaryReady,
    report,
    isReportLoading,
  };
};
