import { useCallback, useMemo, useState } from 'react';
import { MobileDataConfig } from '~/src/constants';
import {
  FootTrafficData,
  MobileData,
} from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';

// New function to get the last 6 months date range
function getLastSixMonths() {
  const end = new Date();
  end.setDate(0); // Set to last day of the previous month

  const start = new Date(end);
  start.setMonth(start.getMonth() - 5);
  start.setDate(1); // Set to first day of the month

  return {
    startDate: start.toISOString().split('T')[0],
    endDate: end.toISOString().split('T')[0],
  };
}

function getMinMaxDatesForFootTrafficData(
  filteredFootTraffic: FootTrafficData[]
) {
  // Filter out invalid or missing date values
  const validDates = filteredFootTraffic
    .map((item: any) => new Date(item.date))
    .filter((date) => !isNaN(date.getTime())); // Ensure the date is valid

  // Handle case where no valid dates are present
  if (validDates.length === 0) {
    return { minDate: null, maxDate: null }; // Return null if no valid dates
  }

  // Find the min and max dates from the valid data
  const minDate = new Date(
    Math.min(...validDates.map((date) => date.getTime()))
  )
    .toISOString()
    .split('T')[0]; // Convert back to YYYY-MM-DD
  const maxDate = new Date(
    Math.max(...validDates.map((date) => date.getTime()))
  )
    .toISOString()
    .split('T')[0];

  return { minDate, maxDate };
}

const useDateRangePicker = (mobileData: MobileData | null) => {
  const { MIN_DATE, MAX_DATE } = MobileDataConfig;

  // Handle case where mobileData might be null
  const { minDate, maxDate } = useMemo(() => {
    if (mobileData && mobileData.foot_traffic_poi) {
      return getMinMaxDatesForFootTrafficData(mobileData.foot_traffic_poi);
    }
    return { minDate: MIN_DATE, maxDate: MAX_DATE }; // Use default values if mobileData is null
  }, [mobileData, MIN_DATE, MAX_DATE]);

  const { startDate: initialStartDate, endDate: initialEndDate } =
    getLastSixMonths();

  const [startDate, setStartDate] = useState<string | null>(initialStartDate);
  const [endDate, setEndDate] = useState<string | null>(initialEndDate);

  const validateDates = useCallback(
    (start: string | null, end: string | null): boolean => {
      if (!start || !end) return false; // If either date is null, consider it invalid
      const startDate = new Date(start);
      const endDate = new Date(end);
      return startDate <= endDate;
    },
    []
  );

  const handleStartDateChange = useCallback(
    (newDate: Date | null) => {
      if (newDate) {
        try {
          const year = newDate.getFullYear();
          const month = newDate.getMonth();

          const firstDayOfMonth = new Date(year, month, 1);
          const formattedStartDate = firstDayOfMonth
            .toISOString()
            .split('T')[0];

          setStartDate(formattedStartDate);
        } catch (error: any) {
          console.error(error.message);
        }
      } else {
        setStartDate(null); // Allow clearing the date
      }
    },
    [setStartDate]
  );

  const handleEndDateChange = useCallback(
    (newDate: Date | null) => {
      if (newDate) {
        try {
          const year = newDate.getFullYear();
          const month = newDate.getMonth();

          const lastDayOfMonth = new Date(year, month + 1, 0);

          // Clamp the end date to maxDate
          if (maxDate && lastDayOfMonth > new Date(maxDate)) {
            setEndDate(maxDate);
          } else {
            const formattedEndDate = lastDayOfMonth.toISOString().split('T')[0];
            setEndDate(formattedEndDate);
          }
        } catch (error: any) {
          console.error(error.message);
        }
      } else {
        setEndDate(null);
      }
    },
    [setEndDate, maxDate]
  );

  return {
    startDate,
    endDate,
    validateDates,
    handleStartDateChange,
    handleEndDateChange,
    minDate: minDate || MIN_DATE,
    maxDate: maxDate || MAX_DATE,
  };
};

export default useDateRangePicker;
