import TrendingDown from '@mui/icons-material/TrendingDown';
import TrendingUp from '@mui/icons-material/TrendingUp';
import { Box, Card, Divider, Typography } from '@mui/material';
import { SimilarityDataConfig } from '~/src/constants';
import { formatValue } from '~/src/global/helpers/formatValue';
import { Similarity } from './types';

export function SingleSimilarityCard({
  icon: Icon,
  title,
  ring,
  dataValues = [],
  similarity = 0,
  variance,
}: Similarity) {
  const { ICON_SIZE, DATA_VALUES_COLORS } = SimilarityDataConfig;

  const sanitizedDataValues = dataValues.map((item) => ({
    title: item?.title ?? 'Unknown',
    value: item?.value ?? 0,
    valueType: item?.valueType ?? 'absolute',
    unit: item?.unit ?? '',
  }));

  const similarityObject = {
    title: 'Similarity',
    valueType: 'percentage' as const,
    value: similarity,
    unit: undefined,
  };

  return (
    <Card
      sx={{
        height: '100%',
        border: '1px solid',
        borderColor: 'divider',
        '&:hover': {
          boxShadow: 3,
        },
      }}
    >
      <Box
        sx={{
          p: 1.5,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box display="flex" gap={1} alignItems="center" mb={1}>
          <Icon sx={{ fontSize: ICON_SIZE, color: 'primary.main' }} />
          <Box>
            <Typography variant="subtitle1" fontWeight={500}>
              {title || 'Unknown'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {ring?.value ?? 0} {ring?.unit ?? 'mi'} ring
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box display="flex" flexDirection="column" gap={1}>
          {[...sanitizedDataValues, similarityObject].map((item, index) => (
            <Box
              key={index}
              sx={{
                bgcolor: 'background.default',
                p: 1,
                borderRadius: 1,
              }}
            >
              <Typography
                variant="caption"
                color={DATA_VALUES_COLORS[index] ?? 'text.primary'}
                fontWeight={500}
                display="block"
              >
                {item.title}
              </Typography>
              <Typography variant="body2">
                {item.unit === '$' ? '$' : ''}
                {formatValue({
                  value: item.value,
                  valueType: item.valueType,
                  valueUnit: item.unit,
                })}
                {item.unit && item.unit !== '$' ? ` ${item.unit}` : ''}
              </Typography>
            </Box>
          ))}
        </Box>

        <Divider sx={{ my: 1, marginTop: 'auto' }} />

        <Box
          display="flex"
          alignItems="center"
          sx={{
            bgcolor: 'background.default',
            p: 1,
            borderRadius: 1,
          }}
        >
          {(variance?.value ?? 0) > 0 ? (
            <TrendingUp
              color="success"
              fontSize="small"
              sx={{ fontWeight: 'bold' }}
            />
          ) : (
            <TrendingDown
              color="error"
              fontSize="small"
              sx={{ fontWeight: 'bold' }}
            />
          )}
          <Typography variant="caption" fontWeight="bold">
            {variance?.unit === '$' ? '$' : ''}
            {formatValue({ value: variance?.value ?? 0 })}
            {variance?.unit && variance.unit !== '$' ? ` ${variance.unit}` : ''}
            {' ('}
            {formatValue({
              value: (variance?.percentage ?? 0) / 100,
              valueType: 'percentage',
            })}
            {')'}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
