import { TrendingDown, TrendingUp } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatValue } from '~/src/global/helpers/formatValue';
import { Similarity } from './types';

const HEADERS = ['', 'Current', 'Benchmark', 'Variance'];

interface MultipleSimilarityCardTableProps {
  similarities: Similarity[];
}

export function MultipleSimilarityCardTable({
  similarities,
}: MultipleSimilarityCardTableProps) {
  return (
    <Box overflow="auto">
      <Table size="small" sx={{ '& td, & th': { padding: '4px 8px' } }}>
        <TableHead>
          <TableRow>
            {HEADERS.map((header) => (
              <TableCell key={header}>
                <Typography variant="body2" fontWeight="bold">
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {similarities.map(({ title, variance, dataValues }) => {
            const [currentLocationData, marketAverageData] = dataValues;

            return (
              <TableRow key={title}>
                <TableCell>
                  <Typography variant="caption">{title}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="caption">
                    {currentLocationData.unit === '$'
                      ? `$${formatValue({ value: currentLocationData.value, valueType: currentLocationData.valueType })}`
                      : formatValue({
                          value: currentLocationData.value,
                          valueType: currentLocationData.valueType,
                        })}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="caption">
                    {marketAverageData && marketAverageData.unit === '$'
                      ? `$${formatValue({ value: marketAverageData.value, valueType: marketAverageData.valueType })}`
                      : marketAverageData
                        ? formatValue({
                            value: marketAverageData.value,
                            valueType: marketAverageData.valueType,
                          })
                        : ''}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={0.5}
                  >
                    {variance.value > 0 ? (
                      <TrendingUp
                        color="success"
                        fontSize="small"
                        sx={{ fontWeight: 'bold' }}
                      />
                    ) : (
                      <TrendingDown
                        color="error"
                        fontSize="small"
                        sx={{ fontWeight: 'bold' }}
                      />
                    )}
                    <Typography variant="caption">
                      {formatValue({
                        value: variance.value,
                        valueType: 'percentage',
                      })}
                      (
                      {formatValue({
                        value: variance.percentage / 100,
                        valueType: 'percentage',
                      })}
                      )
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}
