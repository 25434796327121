import { useMultiplayer } from '@plotr/react-multiplayer';
import { PinTemplate, YPinTemplate } from '@plotr/plotr-multiplayer-data';

export default function usePinTemplates() {
  return useMultiplayer.State<PinTemplate[]>((ydoc) => {
    const templates =
      ydoc?.getArray<YPinTemplate>('pinTemplates')?.toArray() ?? [];
    return templates.map((template) => template.toJSON() as PinTemplate);
  });
}
