{
    "a9ad3f25-fa1c-42fc-be9c-c1a6b10f2970": {
        "name": "Orange Theory Albuquerque - Midtown, NM",
        "address": "5010 Cutler Ave NE suite F Albuquerque, New Mexico 87110",
        "phone": "(505) 359-7011",
        "email": "studiomanager0569@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:15AM",
            "SUNDAY": "7:00AM - 11:15AM"
        },
        "geolocation": [
            35.1051292,
            -106.58853
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.22,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.13,
                    "duration": "indefinitely",
                    "model_conf": 150.39
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 9.37,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.7,
                    "duration": "indefinitely",
                    "model_conf": 169.3
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 14.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.07,
                    "duration": "indefinitely",
                    "model_conf": 108.85
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 17.72,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 29.53,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 39.3,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Albuquerque - Midtown, NM",
            "slug": "a9ad3f25-fa1c-42fc-be9c-c1a6b10f2970"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/2/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-mexico/albuquerque/5010-cutler-ave-ne-suite-f/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS NEW MEXICO, LLC"
    },
    "e8c0de60-4123-4a90-a700-fbce90071ad2": {
        "name": "Orange Theory Ardmore, PA",
        "address": "139 West Lancaster Ave Ardmore, Pennsylvania 19003",
        "phone": "(484) 258-1236",
        "email": "studiomanager0538@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.009758,
            -75.293709
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.47,
                    "duration": "indefinitely",
                    "model_conf": 149.33
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.79,
                    "duration": "indefinitely",
                    "model_conf": 180.71
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.48,
                    "duration": "indefinitely",
                    "model_conf": 109.22
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Ardmore, PA",
            "slug": "e8c0de60-4123-4a90-a700-fbce90071ad2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/24/18",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/ardmore/139-west-lancaster-ave/",
        "index": 108.325,
        "franchise-entity-name": "RAP VENTURES, LLC"
    },
    "01854994-801c-4643-882b-45ff4a1e4d05": {
        "name": "Orange Theory Southlands, CO",
        "address": "24300 E SMOKY HILL RD Aurora, Colorado 80016",
        "phone": "(720) 791-3003",
        "email": "studiomanager1050@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            39.59548,
            -104.70477
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.19,
                    "duration": "indefinitely",
                    "model_conf": 146.17
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.72,
                    "duration": "4 Months",
                    "model_conf": 177.5
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.02,
                    "duration": "indefinitely",
                    "model_conf": 106.59
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Southlands, CO",
            "slug": "01854994-801c-4643-882b-45ff4a1e4d05"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/13/18",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/southlands/24300-e-smokey-hill-road-suite-134/",
        "index": 98.425,
        "franchise-entity-name": "TEAM MGW LLC"
    },
    "7d03753d-c3b6-456e-b056-0a886231f42a": {
        "name": "Orange Theory Southern Pines, NC",
        "address": "11109 US-15 Aberdeen, North Carolina 28315",
        "phone": "(910) 745-7996",
        "email": "studiomanager1304@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "8:00AM - 11:15AM",
            "SUNDAY": "8:00AM - 11:15AM"
        },
        "geolocation": [
            35.1564598,
            -79.417542
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.22,
                    "duration": "3 Months",
                    "model_conf": 153.33
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.89,
                    "duration": "indefinitely",
                    "model_conf": 185.04
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.43,
                    "duration": "1 Months",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Southern Pines, NC",
            "slug": "7d03753d-c3b6-456e-b056-0a886231f42a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/7/19",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/aberdeen/11109-us-15/",
        "index": 98.425,
        "franchise-entity-name": "NC01-Southern Pines, LLC"
    },
    "9fd74853-4bad-4f1d-a9c7-fcbf27eb1651": {
        "name": "Orange Theory Abilene, TX",
        "address": "3950 Catclaw Drive Abilene, Texas 79606",
        "phone": "(325) 400-6191",
        "email": "studiomanager0862@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "8:45AM - 3:30PM",
            "SUNDAY": "8:45AM - 3:30PM"
        },
        "geolocation": [
            32.4039993,
            -99.77462
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.25,
                    "duration": "indefinitely",
                    "model_conf": 153.85
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.34,
                    "duration": "indefinitely",
                    "model_conf": 185.8
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.26,
                    "duration": "indefinitely",
                    "model_conf": 112.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Abilene, TX",
            "slug": "9fd74853-4bad-4f1d-a9c7-fcbf27eb1651"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/abilene/3950-catclaw-drive/",
        "index": 88.45,
        "franchise-entity-name": "FITNESS PREMISE ABILENE,LLC"
    },
    "2a5a5bc7-ea4a-4a2a-b166-56ce5e6ee7e2": {
        "name": "Orange Theory Acworth",
        "address": "4391 Acworth Dallas Rd NW Suite 212 Acworth, Georgia 30101",
        "phone": "(770) 674-8722",
        "email": "studiomanager1188@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            34.0584259,
            -84.723198
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.73,
                    "duration": "1 Months",
                    "model_conf": 151.33
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.15,
                    "duration": "indefinitely",
                    "model_conf": 185.09
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.75,
                    "duration": "indefinitely",
                    "model_conf": 110.48
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Acworth",
            "slug": "2a5a5bc7-ea4a-4a2a-b166-56ce5e6ee7e2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/17/19",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/acworth/4391-acworth-dallas-rd-nw-suite-212/",
        "index": 98.425,
        "franchise-entity-name": "JM ACWORTH LLC"
    },
    "20640046-f8b0-4f90-b92b-cf57433cce60": {
        "name": "Orange Theory Aiken, SC",
        "address": "250 East Gate Drive Aiken, South Carolina 29803",
        "phone": "(803) 220-0100",
        "email": "studiomanager1524@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            33.511443,
            -81.716165
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.15,
                    "duration": "indefinitely",
                    "model_conf": 153.86
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.13,
                    "duration": "indefinitely",
                    "model_conf": 184.43
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.15,
                    "duration": "indefinitely",
                    "model_conf": 112.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Aiken, SC",
            "slug": "20640046-f8b0-4f90-b92b-cf57433cce60"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/17/22",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/aiken/250-east-gate-drive-suite-16/",
        "index": 93.4,
        "franchise-entity-name": "JM AIKEN LLC"
    },
    "82e310f3-4eef-483f-9995-230be19ece8a": {
        "name": "Orange Theory Akron, OH",
        "address": "34 North Hawkins Avenue Suite 305 Akron, Ohio 44313",
        "phone": "(330) 864-5555",
        "email": "studiomanager0674@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 8:15PM",
            "TUESDAY": "4:30AM - 8:15PM",
            "WEDNESDAY": "4:30AM - 8:15PM",
            "THURSDAY": "4:30AM - 8:15PM",
            "FRIDAY": "4:30AM - 8:15PM",
            "SATURDAY": "6:30AM - 12:00PM",
            "SUNDAY": "6:30AM - 12:00PM"
        },
        "geolocation": [
            41.1106796,
            -81.569633
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.06,
                    "duration": "indefinitely",
                    "model_conf": 152.84
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.97,
                    "duration": "3 Months",
                    "model_conf": 184.62
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.47,
                    "duration": "indefinitely",
                    "model_conf": 110.57
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Akron, OH",
            "slug": "82e310f3-4eef-483f-9995-230be19ece8a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/akron/34-north-hawkins-avenue-suite-305/",
        "index": 93.4,
        "franchise-entity-name": "FIT FOR THE RED CARPET LLC"
    },
    "92a64a8e-8221-44bb-853d-17f82c731ebb": {
        "name": "Orange Theory Alameda, CA",
        "address": "2610 Fifth Street Alameda, California 94501",
        "phone": "(510) 927-4414",
        "email": "studiomanager0377@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            37.7859535,
            -122.28123
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.89,
                    "duration": "indefinitely",
                    "model_conf": 152.91
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.33,
                    "duration": "1 Months",
                    "model_conf": 183.55
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.13,
                    "duration": "indefinitely",
                    "model_conf": 110.09
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Alameda, CA",
            "slug": "92a64a8e-8221-44bb-853d-17f82c731ebb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/13/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/alameda/2610-fifth-street/",
        "index": 108.325,
        "franchise-entity-name": "SFFIT ALA, LLC"
    },
    "1ecf3328-2df6-4395-a389-ece010ade7fe": {
        "name": "Orange Theory Alamo, CA",
        "address": "170C Alamo Plaza Alamo, California 94507",
        "phone": "(925) 378-5610",
        "email": "studiomanager0453@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:15PM",
            "TUESDAY": "5:00AM - 6:15PM",
            "WEDNESDAY": "5:00AM - 6:15PM",
            "THURSDAY": "5:00AM - 6:15PM",
            "FRIDAY": "5:00AM - 6:15PM",
            "SATURDAY": "7:15AM - 10:45AM",
            "SUNDAY": "7:15AM - 10:45AM"
        },
        "geolocation": [
            37.8512344,
            -122.03571
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.49,
                    "duration": "1 Months",
                    "model_conf": 152.76
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.21,
                    "duration": "indefinitely",
                    "model_conf": 182.74
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.21,
                    "duration": "1 Months",
                    "model_conf": 110.98
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Alamo, CA",
            "slug": "1ecf3328-2df6-4395-a389-ece010ade7fe"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/alamo/170c-alamo-plaza/",
        "index": 108.325,
        "franchise-entity-name": "TKVI, LLC"
    },
    "c6d24d6f-4057-47d9-8bf1-fdd689e8233c": {
        "name": "Orange Theory Solano- Albany, CA",
        "address": "1495 Solano Ave. Albany, California 94706",
        "phone": "(510) 560-4020",
        "email": "studiomanager0101@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 6:30PM",
            "TUESDAY": "6:15AM - 6:30PM",
            "WEDNESDAY": "6:15AM - 6:30PM",
            "THURSDAY": "6:15AM - 6:30PM",
            "FRIDAY": "6:15AM - 6:30PM",
            "SATURDAY": "7:25AM - 11:55AM",
            "SUNDAY": "7:25AM - 11:55AM"
        },
        "geolocation": [
            37.8911552,
            -122.28768
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 100.02,
                    "duration": "indefinitely",
                    "model_conf": 153.38
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.43,
                    "duration": "1 Months",
                    "model_conf": 184.68
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.46,
                    "duration": "indefinitely",
                    "model_conf": 110.61
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Solano- Albany, CA",
            "slug": "c6d24d6f-4057-47d9-8bf1-fdd689e8233c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/albany/1495-solano-ave/",
        "index": 108.325,
        "franchise-entity-name": "SFFIT SOL, LLC"
    },
    "deb9dc3d-4a1a-4d63-8fdb-babf54157a6b": {
        "name": "Orange Theory Albany, NY",
        "address": "1704 Western Avenue Albany, New York 12203",
        "phone": "(518) 992-4910",
        "email": "studiomanager0385@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "6:30AM - 12:30PM",
            "SUNDAY": "6:30AM - 12:30PM"
        },
        "geolocation": [
            42.6605187,
            -73.773949
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.81,
                    "duration": "indefinitely",
                    "model_conf": 150.94
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.1,
                    "duration": "2 Months",
                    "model_conf": 173.93
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.48,
                    "duration": "indefinitely",
                    "model_conf": 111.5
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Albany, NY",
            "slug": "deb9dc3d-4a1a-4d63-8fdb-babf54157a6b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/3/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/albany/1704-western-avenue/",
        "index": 98.425,
        "franchise-entity-name": "MJP ALBANY, LLC"
    },
    "8fa08c76-c2a9-472f-8c68-95769ec6d830": {
        "name": "Orange Theory Albuquerque - Westside, NM",
        "address": "6361 Riverside Plaza Lane NW Albuquerque, New Mexico 87120",
        "phone": "(505) 633-8009",
        "email": "studiomanager0566@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            35.1574249,
            -106.68017
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.22,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.66,
                    "duration": "1 Months",
                    "model_conf": 145.78
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 9.37,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.55,
                    "duration": "indefinitely",
                    "model_conf": 144.66
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 14.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.76,
                    "duration": "indefinitely",
                    "model_conf": 105.62
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 17.72,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 29.53,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 39.3,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Albuquerque - Westside, NM",
            "slug": "8fa08c76-c2a9-472f-8c68-95769ec6d830"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/25/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-mexico/albuquerque/6361-riverside-plaza-lane-nw/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS NEW MEXICO, LLC"
    },
    "8b3a33fd-7cf3-4df6-8ff5-6d6fa2a9b0e5": {
        "name": "Orange Theory Albuquerque Far Northeast Heights, NM",
        "address": "8850 Holly Avenue NE Albuquerque, New Mexico 87122",
        "phone": "(505) 633-8048",
        "email": "studiomanager0567@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            35.1751633,
            -106.5453
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.22,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.53,
                    "duration": "indefinitely",
                    "model_conf": 151.58
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 9.37,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.23,
                    "duration": "2 Months",
                    "model_conf": 176.12
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 14.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.56,
                    "duration": "indefinitely",
                    "model_conf": 109.54
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 17.72,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 29.53,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 39.3,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Albuquerque Far Northeast Heights, NM",
            "slug": "8b3a33fd-7cf3-4df6-8ff5-6d6fa2a9b0e5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/21/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-mexico/albuquerque/8850-holly-avenue-ne/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS NEW MEXICO, LLC"
    },
    "8a8656d5-0e3b-45a0-b26e-ffcbae1b2c90": {
        "name": "Orange Theory Old Town Alexandria, VA",
        "address": "1101 King Street Alexandria, Virginia 22314",
        "phone": "(571) 919-0379",
        "email": "studiomanager0482@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            38.8063545,
            -77.051682
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.84,
                    "duration": "indefinitely",
                    "model_conf": 124.4
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.18,
                    "duration": "2 Months",
                    "model_conf": 147.68
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.45,
                    "duration": "indefinitely",
                    "model_conf": 88.93
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Old Town Alexandria, VA",
            "slug": "8a8656d5-0e3b-45a0-b26e-ffcbae1b2c90"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/17/16",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/alexandria/1101-king-street/",
        "index": 98.425,
        "franchise-entity-name": "BAA MANAGEMENT, LLC"
    },
    "0902ce40-3537-47f4-bbab-a3169c2b0aee": {
        "name": "Orange Theory Carlyle",
        "address": "2381 Eisenhower Avenue Alexandria, Virginia 22314",
        "phone": "(571) 363-3232",
        "email": "studiomanager1159@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:15AM - 1:00PM",
            "SUNDAY": "8:15AM - 1:00PM"
        },
        "geolocation": [
            38.8011932,
            -77.070305
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.41,
                    "duration": "indefinitely",
                    "model_conf": 121.04
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.6,
                    "duration": "1 Months",
                    "model_conf": 142.03
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.21,
                    "duration": "indefinitely",
                    "model_conf": 85.51
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Carlyle",
            "slug": "0902ce40-3537-47f4-bbab-a3169c2b0aee"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/5/19",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/alexandria/2381-eisenhower-avenue/",
        "index": 98.425,
        "franchise-entity-name": "BAMCarlyle, LLC"
    },
    "f7f02529-42cb-4d32-ae1e-610f98ffacf6": {
        "name": "Orange Theory Bradlee Center",
        "address": "3624 King Street Alexandria, Virginia 22302",
        "phone": "(703) 771-3800",
        "email": "studiomanager1132@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            38.8279419,
            -77.091003
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.16,
                    "duration": "4 Months",
                    "model_conf": 108.88
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.81,
                    "duration": "indefinitely",
                    "model_conf": 129.02
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.16,
                    "duration": "1 Months",
                    "model_conf": 76.44
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Bradlee Center",
            "slug": "f7f02529-42cb-4d32-ae1e-610f98ffacf6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/28/19",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/alexandria/3624-king-street/",
        "index": 98.425,
        "franchise-entity-name": "NHCNOVA IV LLC"
    },
    "03a3010e-654f-4dde-af6e-b016ee225852": {
        "name": "Orange Theory Kingstowne, VA",
        "address": "5900 Kingstowne Towne Ctr Alexandria, Virginia 22315",
        "phone": "(571) 303-1933",
        "email": "studiomanager0801@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            38.7717133,
            -77.138237
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.76,
                    "duration": "indefinitely",
                    "model_conf": 136.38
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.37,
                    "duration": "5 Months",
                    "model_conf": 160.46
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.44,
                    "duration": "indefinitely",
                    "model_conf": 96.66
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Kingstowne, VA",
            "slug": "03a3010e-654f-4dde-af6e-b016ee225852"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/26/18",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/alexandria/5900-kingstowne-center-suite-100/",
        "index": 98.425,
        "franchise-entity-name": "BA MANAGEMENT, LLC"
    },
    "fd47c711-7f66-4060-9a9b-7cfba7aeb0bb": {
        "name": "Orange Theory Potomac Yard, VA",
        "address": "650 Potomac Yard Alexandria, Virginia 22301",
        "phone": "(571) 403-9831",
        "email": "studiomanager0802@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            38.8323937,
            -77.051056
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.85,
                    "duration": "indefinitely",
                    "model_conf": 114.36
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.02,
                    "duration": "3 Months",
                    "model_conf": 136.3
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.5,
                    "duration": "indefinitely",
                    "model_conf": 82.31
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Potomac Yard, VA",
            "slug": "fd47c711-7f66-4060-9a9b-7cfba7aeb0bb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/14/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/alexandria/1801-richmond-highway/",
        "index": 98.425,
        "franchise-entity-name": "BBAB MANAGEMENT, LLC"
    },
    "e26b6a0c-2ad0-45b6-a0bc-9bd53efafdf9": {
        "name": "Orange Theory Mount Vernon-Alexandria, VA",
        "address": "7716 Richmond Hwy Alexandria, Virginia 22306",
        "phone": "(703) 342-0968",
        "email": "studiomanager1440@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 6:45PM",
            "TUESDAY": "5:30AM - 6:45PM",
            "WEDNESDAY": "5:30AM - 6:45PM",
            "THURSDAY": "5:30AM - 6:45PM",
            "FRIDAY": "5:30AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            38.748422,
            -77.084856
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.33,
                    "duration": "indefinitely",
                    "model_conf": 142.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.88,
                    "duration": "3 Months",
                    "model_conf": 169.98
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.89,
                    "duration": "indefinitely",
                    "model_conf": 102.58
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Mount Vernon-Alexandria, VA",
            "slug": "e26b6a0c-2ad0-45b6-a0bc-9bd53efafdf9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/10/22",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/alexandria/7694-richmond-hwy/",
        "index": 98.425,
        "franchise-entity-name": "HH MT. VERNON LLC"
    },
    "97b9b6d8-45e1-484a-9c04-91415f3e8a80": {
        "name": "Orange Theory Algonquin, IL",
        "address": "1624 S Randall Rd. Algonquin, Illinois 60102",
        "phone": "(847) 565-5711",
        "email": "studiomanager0986@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            42.1505318,
            -88.338013
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 92.42,
                    "duration": "indefinitely",
                    "model_conf": 152.75
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 125.98,
                    "duration": "9 Months",
                    "model_conf": 184.35
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.96,
                    "duration": "indefinitely",
                    "model_conf": 108.52
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Algonquin, IL",
            "slug": "97b9b6d8-45e1-484a-9c04-91415f3e8a80"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/9/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/algonquin/1624-s-randall-rd/",
        "index": 103.375,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-NORTH EAST, LLC"
    },
    "844dba85-ac0c-410f-ac00-fc30d8d216a3": {
        "name": "Orange Theory Allen, TX",
        "address": "945 W Stacy Rd. Suite 180 Allen, Texas 75013",
        "phone": "(972) 521-3700",
        "email": "studiomanager0205@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:45PM",
            "TUESDAY": "4:45AM - 7:45PM",
            "WEDNESDAY": "4:45AM - 7:45PM",
            "THURSDAY": "4:45AM - 7:45PM",
            "FRIDAY": "4:45AM - 7:45PM",
            "SATURDAY": "6:00AM - 1:15PM",
            "SUNDAY": "6:00AM - 1:15PM"
        },
        "geolocation": [
            33.1292877,
            -96.67421
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.89,
                    "duration": "indefinitely",
                    "model_conf": 142.59
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.86,
                    "duration": "indefinitely",
                    "model_conf": 173.02
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.92,
                    "duration": "indefinitely",
                    "model_conf": 107.1
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Allen, TX",
            "slug": "844dba85-ac0c-410f-ac00-fc30d8d216a3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/30/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/allen/945-w-stacy-rd-suite-180/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA FITNESS-ALLEN, LLC"
    },
    "e866b63f-1bd5-48bf-af9e-1e56a7ffe344": {
        "name": "Orange Theory Allentown, PA",
        "address": "4713 Tilghman Street Allentown, Pennsylvania 18104",
        "phone": "(610) 366-0090",
        "email": "studiomanager1021@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            40.5911484,
            -75.56147
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.11,
                    "duration": "indefinitely",
                    "model_conf": 153.47
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.93,
                    "duration": "indefinitely",
                    "model_conf": 185.37
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.02,
                    "duration": "indefinitely",
                    "model_conf": 111.47
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Allentown, PA",
            "slug": "e866b63f-1bd5-48bf-af9e-1e56a7ffe344"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/28/19",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/allentown/4713-tilghman-street/",
        "index": 98.425,
        "franchise-entity-name": "SAUCON FITNESS, LLC"
    },
    "abc9d4c2-4467-48b6-95ab-4a9260362fc7": {
        "name": "Orange Theory Alpharetta, GA",
        "address": "3450 Old Milton Parkway, Ste. 210 Alpharetta, Georgia 30005",
        "phone": "(770) 686-5352",
        "email": "studiomanager0125@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            34.068058,
            -84.260445
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.83,
                    "duration": "indefinitely",
                    "model_conf": 139.6
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.24,
                    "duration": "2 Months",
                    "model_conf": 180.35
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.19,
                    "duration": "indefinitely",
                    "model_conf": 99.33
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Alpharetta, GA",
            "slug": "abc9d4c2-4467-48b6-95ab-4a9260362fc7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/5/14",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/alpharetta/3450-old-milton-parkway-ste-210/",
        "index": 98.425,
        "franchise-entity-name": "VAILLANTINO, LLC"
    },
    "16c13609-c4a6-4bb0-9069-c3bba7f21e56": {
        "name": "Orange Theory Altamonte Springs, FL",
        "address": "397 E. Altamonte Dr Suite 1450 Altamonte, Florida 32701",
        "phone": "(321) 800-3004",
        "email": "studiomanager0167@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:00PM",
            "TUESDAY": "6:00AM - 7:00PM",
            "WEDNESDAY": "6:00AM - 7:00PM",
            "THURSDAY": "6:00AM - 7:00PM",
            "FRIDAY": "6:00AM - 7:00PM",
            "SATURDAY": "6:30AM - 12:00PM",
            "SUNDAY": "6:30AM - 12:00PM"
        },
        "geolocation": [
            28.6643829,
            -81.379349
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.73,
                    "duration": "indefinitely",
                    "model_conf": 121.59
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.13,
                    "duration": "2 Months",
                    "model_conf": 142.92
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.38,
                    "duration": "indefinitely",
                    "model_conf": 84.91
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Altamonte Springs, FL",
            "slug": "16c13609-c4a6-4bb0-9069-c3bba7f21e56"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/altamonte/397-e-altamonte-dr-suite-1450/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS, LLC"
    },
    "7258584e-7be7-4378-996a-81e8b2917884": {
        "name": "Orange Theory Amarillo, TX",
        "address": "4280 South Soncy Rd. Suite 400 Amarillo, Texas 79119",
        "phone": "(806) 358-0907",
        "email": "studiomanager1109@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:15AM - 11:30AM",
            "SUNDAY": "8:15AM - 11:30AM"
        },
        "geolocation": [
            35.1615219,
            -101.93789
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.25,
                    "duration": "indefinitely",
                    "model_conf": 153.77
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.34,
                    "duration": "indefinitely",
                    "model_conf": 185.84
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.26,
                    "duration": "indefinitely",
                    "model_conf": 112.08
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Amarillo, TX",
            "slug": "7258584e-7be7-4378-996a-81e8b2917884"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/29/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/amarillo/4280-south-soncy-rd-suite-400/",
        "index": 88.45,
        "franchise-entity-name": "Interval Fitness of Amarillo, LLC"
    },
    "69c35462-8bd2-4abb-9f64-195cb0a50d7d": {
        "name": "Orange Theory American Fork, UT",
        "address": "499 S 500 E Suite A American Fork, Utah 84003",
        "phone": "(801) 893-3900",
        "email": "studiomanager1234@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            40.3672943,
            -111.78499
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.97,
                    "duration": "indefinitely",
                    "model_conf": 152.98
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.55,
                    "duration": "1 Months",
                    "model_conf": 183.49
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.96,
                    "duration": "indefinitely",
                    "model_conf": 110.54
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "American Fork, UT",
            "slug": "69c35462-8bd2-4abb-9f64-195cb0a50d7d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/25/19",
        "url": "https://www.orangetheory.com/en-us/locations/utah/american-fork/499-s-500-e-suite-a/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS UTAH, LLC"
    },
    "65bdf5fc-d064-4523-9759-1ee2dff41fc6": {
        "name": "Orange Theory Amherst - Transit Road, NY",
        "address": "8242 transit road Amherst, New York 14223",
        "phone": "(716) 200-1350",
        "email": "studiomanager0069@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            42.9933815,
            -78.696999
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.64,
                    "duration": "indefinitely",
                    "model_conf": 149.09
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.45,
                    "duration": "1 Months",
                    "model_conf": 185.13
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.59,
                    "duration": "indefinitely",
                    "model_conf": 111.46
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Amherst - Transit Road, NY",
            "slug": "65bdf5fc-d064-4523-9759-1ee2dff41fc6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/27/15",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/buffalo/8242-transit-rd/",
        "index": 98.425,
        "franchise-entity-name": "TRANSIT FITNESS, LLC"
    },
    "d97782b9-25c2-4c7b-b2a9-09e471fd8f9b": {
        "name": "Orange Theory Idaho Falls, ID",
        "address": "2674 E Sunnyside Road Ammon, Idaho 83406",
        "phone": "(208) 541-1702",
        "email": "studiomanager1049@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 10:15AM",
            "SUNDAY": "7:00AM - 10:15AM"
        },
        "geolocation": [
            43.4663048,
            -111.98046
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 3.54,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.52,
                    "duration": "3 Months",
                    "model_conf": 152.71
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 5.94,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.26,
                    "duration": "indefinitely",
                    "model_conf": 185.78
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.59,
                    "duration": "indefinitely",
                    "model_conf": 109.63
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Idaho Falls, ID",
            "slug": "d97782b9-25c2-4c7b-b2a9-09e471fd8f9b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/17/18",
        "url": "https://www.orangetheory.com/en-us/locations/idaho/ammon/2674-e-sunnyside-road/",
        "index": 88.45,
        "franchise-entity-name": "J AND J FITNESS IF LLC"
    },
    "5ca514ad-f86a-4cd7-a5ef-7cf731d9ff5e": {
        "name": "Orange Theory Anaheim Hills, CA",
        "address": "8108 East Santa Ana Canyon Drive Ste 162 Space 3B Anaheim, California 92808",
        "phone": "(657) 208-4000",
        "email": "studiomanager1017@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:35PM",
            "TUESDAY": "5:00AM - 6:35PM",
            "WEDNESDAY": "5:00AM - 6:35PM",
            "THURSDAY": "5:00AM - 6:35PM",
            "FRIDAY": "5:00AM - 6:35PM",
            "SATURDAY": "6:30AM - 12:30PM",
            "SUNDAY": "6:30AM - 12:30PM"
        },
        "geolocation": [
            33.8658104,
            -117.74939
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.11,
                    "duration": "3 Months",
                    "model_conf": 148.81
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 165.12
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.32,
                    "duration": "1 Months",
                    "model_conf": 97.67
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Anaheim Hills, CA",
            "slug": "5ca514ad-f86a-4cd7-a5ef-7cf731d9ff5e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/1/21",
        "url": "https://www.orangetheory.com/en-us/locations/california/anaheim/8108-east-santa-ana-canyon-drive-suite-162-space-3b/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT AH LLC"
    },
    "436fc69e-0c2c-476f-a10c-bf400df092a8": {
        "name": "Orange Theory South Anchorage, AK",
        "address": "345 W 104th Ave. Anchorage, Alaska 99515",
        "phone": "(907) 519-0077",
        "email": "studiomanager0669@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            61.1266518,
            -149.88609
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.21,
                    "duration": "indefinitely",
                    "model_conf": 153.44
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.76,
                    "duration": "indefinitely",
                    "model_conf": 185.8
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.88,
                    "duration": "indefinitely",
                    "model_conf": 110.54
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "South Anchorage, AK",
            "slug": "436fc69e-0c2c-476f-a10c-bf400df092a8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/31/17",
        "url": "https://www.orangetheory.com/en-us/locations/alaska/anchorage/345-w-104th-ave/",
        "index": 88.45,
        "franchise-entity-name": "S&amp;B ALASKA, LLC"
    },
    "f0dd0070-8f92-486d-8d13-711c85838072": {
        "name": "Orange Theory Anderson Township, OH",
        "address": "7632 Beechmont Ave Anderson Township, Ohio 45255",
        "phone": "(513) 795-8085",
        "email": "studiomanager1135@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            39.0728684,
            -84.346489
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.34,
                    "duration": "indefinitely",
                    "model_conf": 151.57
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.72,
                    "duration": "indefinitely",
                    "model_conf": 185.19
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.37,
                    "duration": "indefinitely",
                    "model_conf": 110.13
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Anderson Township, OH",
            "slug": "f0dd0070-8f92-486d-8d13-711c85838072"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/31/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/anderson-township/7632-beechmont-ave/",
        "index": 93.4,
        "franchise-entity-name": "DAVIS FITNESS ANDERSON INC."
    },
    "33329627-b582-42d6-8caa-fb401c29fda9": {
        "name": "Orange Theory North Andover, MA",
        "address": "554 Turnpike Street, Andover, Massachusetts 01845",
        "phone": "(978) 494-0906",
        "email": "studiomanager0906@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            42.6663628,
            -71.115402
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.85,
                    "duration": "5 Months",
                    "model_conf": 153.58
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.37,
                    "duration": "indefinitely",
                    "model_conf": 185.31
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.27,
                    "duration": "1 Months",
                    "model_conf": 111.3
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "North Andover, MA",
            "slug": "33329627-b582-42d6-8caa-fb401c29fda9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/andover/554-turnpike-street/",
        "index": 103.375,
        "franchise-entity-name": "INTERVAL ZONE FITNESS, LLC"
    },
    "8fe111d7-30e0-4199-96b3-3cf2a42b98ec": {
        "name": "Orange Theory Ankeny, IA",
        "address": "833 East 1st Street Ankeny, Iowa 50021",
        "phone": "(515) 218-9778",
        "email": "studiomanager0631@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:25PM",
            "TUESDAY": "5:00AM - 7:25PM",
            "WEDNESDAY": "5:00AM - 7:25PM",
            "THURSDAY": "5:00AM - 7:25PM",
            "FRIDAY": "5:00AM - 7:25PM",
            "SATURDAY": "6:15AM - 11:00AM",
            "SUNDAY": "6:15AM - 11:00AM"
        },
        "geolocation": [
            41.7313156,
            -93.585464
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.57,
                    "duration": "indefinitely",
                    "model_conf": 152.51
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.43,
                    "duration": "2 Months",
                    "model_conf": 185.41
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.18,
                    "duration": "indefinitely",
                    "model_conf": 111.28
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Ankeny, IA",
            "slug": "8fe111d7-30e0-4199-96b3-3cf2a42b98ec"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/30/16",
        "url": "https://www.orangetheory.com/en-us/locations/iowa/ankeny/833-east-1st-street/",
        "index": 93.4,
        "franchise-entity-name": "OT IOWA ANKENY, LLC"
    },
    "58e63330-9f50-4a0f-992c-b9356e6e9bfe": {
        "name": "Orange Theory Ann Arbor, MI",
        "address": "2246 S Main St Ann Arbor, Michigan 48103",
        "phone": "(734) 418-6260",
        "email": "studiomanager0359@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            42.2534027,
            -83.751495
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.02,
                    "duration": "indefinitely",
                    "model_conf": 151.77
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.23,
                    "duration": "indefinitely",
                    "model_conf": 178.84
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.14,
                    "duration": "indefinitely",
                    "model_conf": 110.25
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Ann Arbor, MI",
            "slug": "58e63330-9f50-4a0f-992c-b9356e6e9bfe"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/10/15",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/ann-arbor/2246-s-main-st/",
        "index": 98.425,
        "franchise-entity-name": "277 INVESTMENTS LLC"
    },
    "8294a16d-5d6b-4536-a397-89a681aba8fe": {
        "name": "Orange Theory Ann Arbor NE, MI",
        "address": "2643 Plymouth Road Ann Arbor, Michigan 48105",
        "phone": "(734) 881-9669",
        "email": "studiomanager0593@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:15AM - 12:30PM",
            "SUNDAY": "7:15AM - 12:30PM"
        },
        "geolocation": [
            42.3041954,
            -83.709328
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.03,
                    "duration": "4 Months",
                    "model_conf": 152.34
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.45,
                    "duration": "indefinitely",
                    "model_conf": 181.13
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.44,
                    "duration": "1 Months",
                    "model_conf": 110.79
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Ann Arbor NE, MI",
            "slug": "8294a16d-5d6b-4536-a397-89a681aba8fe"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/ann-arbor/2643-plymouth-road/",
        "index": 98.425,
        "franchise-entity-name": "OT ANN ARBOR 2, LLC"
    },
    "14d4b8ad-a8e7-4ecf-b2a6-554433d47f12": {
        "name": "Orange Theory Annapolis, MD",
        "address": "2645-B Housley Rd Annapolis, Maryland 21401",
        "phone": "(443) 926-9334",
        "email": "studiomanager1037@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            38.9876633,
            -76.556541
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.29,
                    "duration": "indefinitely",
                    "model_conf": 152.52
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.21,
                    "duration": "indefinitely",
                    "model_conf": 184.65
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.34,
                    "duration": "indefinitely",
                    "model_conf": 111.73
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Annapolis, MD",
            "slug": "14d4b8ad-a8e7-4ecf-b2a6-554433d47f12"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/8/18",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/annapolis/2645-b-housley-rd/",
        "index": 108.325,
        "franchise-entity-name": "ANNAPOLIS FITNESS PARTNERS, LLC"
    },
    "a437fb5f-9a30-4390-9f65-a2e960574bf3": {
        "name": "Orange Theory Apopka, FL",
        "address": "3030 E Semoran Blvd Apopka, Florida 32703",
        "phone": "(407) 618-8500",
        "email": "studiomanager0861@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:45AM - 11:15AM",
            "SUNDAY": "7:45AM - 11:15AM"
        },
        "geolocation": [
            28.669987,
            -81.454839
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.02,
                    "duration": "indefinitely",
                    "model_conf": 139.44
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.69,
                    "duration": "4 Months",
                    "model_conf": 165.37
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.85,
                    "duration": "indefinitely",
                    "model_conf": 94.71
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Apopka, FL",
            "slug": "a437fb5f-9a30-4390-9f65-a2e960574bf3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/6/23",
        "url": "https://www.orangetheory.com/en-us/locations/florida/apopka/3060-e-semoran-blvd/",
        "index": 98.425,
        "franchise-entity-name": "NASH FITNESS APOPKA, LLC"
    },
    "a50b94bb-4549-47ba-a61b-3a043e2875c2": {
        "name": "Orange Theory Apple Valley, MN",
        "address": "15624 Pilot Knob Road Apple Valley, Minnesota 55124",
        "phone": "(952) 683-1440",
        "email": "studiomanager0175@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            44.7222824,
            -93.173523
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.2,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.31,
                    "duration": "indefinitely",
                    "model_conf": 152.29
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.05,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.39,
                    "duration": "2 Months",
                    "model_conf": 184.87
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.33,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.75,
                    "duration": "indefinitely",
                    "model_conf": 111.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Apple Valley, MN",
            "slug": "a50b94bb-4549-47ba-a61b-3a043e2875c2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/7/14",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/apple-valley/15624-pilot-knob-road/",
        "index": 88.45,
        "franchise-entity-name": "GO FORWARD BRANDS L.L.C."
    },
    "60d743f5-00ef-47f5-9e8b-5b4463a6eea4": {
        "name": "Orange Theory Appleton, WI",
        "address": "4301 W Wisconsin Ave. Appleton, Wisconsin 54913",
        "phone": "(920) 202-8114",
        "email": "studiomanager1145@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            44.2679901,
            -88.470993
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.75,
                    "duration": "indefinitely",
                    "model_conf": 153.75
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.07,
                    "duration": "3 Months",
                    "model_conf": 184.69
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.37,
                    "duration": "indefinitely",
                    "model_conf": 111.92
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Appleton, WI",
            "slug": "60d743f5-00ef-47f5-9e8b-5b4463a6eea4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/21/18",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/appleton/4301-w-wisconsin-ave/",
        "index": 93.4,
        "franchise-entity-name": "OT WISCONSIN APPLETON, LLC"
    },
    "14f5c03f-a2aa-432a-94c0-3e883dc6c603": {
        "name": "Orange Theory North Arlington, TX",
        "address": "1805 N Collins Suite 111 Arlington, Texas 76011",
        "phone": "(682) 206-0879",
        "email": "studiomanager1096@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 1:15PM",
            "SUNDAY": "6:15AM - 1:15PM"
        },
        "geolocation": [
            32.7628479,
            -97.096672
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.95,
                    "duration": "indefinitely",
                    "model_conf": 150.58
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.78,
                    "duration": "indefinitely",
                    "model_conf": 185.03
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.59,
                    "duration": "indefinitely",
                    "model_conf": 110.53
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "North Arlington, TX",
            "slug": "14f5c03f-a2aa-432a-94c0-3e883dc6c603"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/9/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/arlington/1805-n-collins-suite-111/",
        "index": 93.4,
        "franchise-entity-name": "Valencia Fitness-North Arlington, LLC"
    },
    "8fa90e6e-c550-4fd4-b2e5-22523cb81066": {
        "name": "Orange Theory Arlington Highlands, TX",
        "address": "4000 5 Points Boulevard #155 Arlington, Texas 76018",
        "phone": "(817) 419-2505",
        "email": "studiomanager0529@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 1:15PM",
            "SUNDAY": "6:30AM - 1:15PM"
        },
        "geolocation": [
            32.6799393,
            -97.107277
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.18,
                    "duration": "indefinitely",
                    "model_conf": 151.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.43,
                    "duration": "2 Months",
                    "model_conf": 184.99
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.6,
                    "duration": "indefinitely",
                    "model_conf": 110.38
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Arlington Highlands, TX",
            "slug": "8fa90e6e-c550-4fd4-b2e5-22523cb81066"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/19/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/arlington/4000-five-points-blvd-155/",
        "index": 93.4,
        "franchise-entity-name": "VSB VENTURES III, LLC"
    },
    "11cefd47-991c-4825-9857-8f26b862f32c": {
        "name": "Orange Theory Pentagon City, VA",
        "address": "1221 S. Fern Street, C-1 Arlington, Virginia 22202",
        "phone": "(571) 349-0033",
        "email": "studiomanager0464@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:35PM",
            "TUESDAY": "5:00AM - 8:35PM",
            "WEDNESDAY": "5:00AM - 8:35PM",
            "THURSDAY": "5:00AM - 8:35PM",
            "FRIDAY": "5:00AM - 8:35PM",
            "SATURDAY": "8:35AM - 11:45AM",
            "SUNDAY": "8:35AM - 11:45AM"
        },
        "geolocation": [
            38.8617287,
            -77.055923
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.88,
                    "duration": "indefinitely",
                    "model_conf": 107.58
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.2,
                    "duration": "indefinitely",
                    "model_conf": 128.69
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.19,
                    "duration": "indefinitely",
                    "model_conf": 78.24
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Pentagon City, VA",
            "slug": "11cefd47-991c-4825-9857-8f26b862f32c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/12/16",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/arlington/1221-s-fern-street-c-1/",
        "index": 98.425,
        "franchise-entity-name": "MDMS CAPITAL VA3, LLC"
    },
    "bb06a638-e359-4ea9-b528-d18846bcf0e1": {
        "name": "Orange Theory Arlington - Rosslyn, VA",
        "address": "1776 Wilson Blvd. Arlington, Virginia 22209",
        "phone": "(571) 431-6140",
        "email": "studiomanager0201@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:45AM",
            "SUNDAY": "7:30AM - 11:45AM"
        },
        "geolocation": [
            38.8937492,
            -77.078331
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.88,
                    "duration": "indefinitely",
                    "model_conf": 110.97
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.95,
                    "duration": "indefinitely",
                    "model_conf": 130.82
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.12,
                    "duration": "indefinitely",
                    "model_conf": 80.44
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Arlington - Rosslyn, VA",
            "slug": "bb06a638-e359-4ea9-b528-d18846bcf0e1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/29/14",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/arlington/1776-wilson-blvd/",
        "index": 98.425,
        "franchise-entity-name": "MDMS CAPITAL VA1 LLC"
    },
    "ce67f95a-e3d4-450e-a266-17f5694d9ddf": {
        "name": "Orange Theory Clarendon, VA",
        "address": "3001 Washington Blvd Arlington, Virginia 22201",
        "phone": "(202) 868-6767",
        "email": "studiomanager0812@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 1:00PM",
            "SUNDAY": "7:30AM - 1:00PM"
        },
        "geolocation": [
            38.8857193,
            -77.094467
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.29,
                    "duration": "3 Months",
                    "model_conf": 110.67
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.61,
                    "duration": "indefinitely",
                    "model_conf": 128.77
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.54,
                    "duration": "indefinitely",
                    "model_conf": 79.75
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Clarendon, VA",
            "slug": "ce67f95a-e3d4-450e-a266-17f5694d9ddf"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/30/16",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/arlington/3001-washington-blvd/",
        "index": 98.425,
        "franchise-entity-name": "MDMS CAPITAL VA4 LLC"
    },
    "a750c0a7-592c-4ce5-825c-e8326d520621": {
        "name": "Orange Theory Arlington - Ballston, VA",
        "address": "4201 Wilson Blvd Arlington, Virginia 22203",
        "phone": "(571) 257-0050",
        "email": "studiomanager0202@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:45AM",
            "SUNDAY": "7:30AM - 11:45AM"
        },
        "geolocation": [
            38.8805046,
            -77.110931
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.12,
                    "duration": "4 Months",
                    "model_conf": 113.43
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.64,
                    "duration": "indefinitely",
                    "model_conf": 132.25
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.62,
                    "duration": "indefinitely",
                    "model_conf": 82.29
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Arlington - Ballston, VA",
            "slug": "a750c0a7-592c-4ce5-825c-e8326d520621"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/15/15",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/arlington/4201-wilson-blvd/",
        "index": 98.425,
        "franchise-entity-name": "MDMS CAPITAL VA2 LLC"
    },
    "9f9412e0-9a65-4c33-bb14-89a9c8e8acc7": {
        "name": "Orange Theory Arlington-Columbia Pike, VA",
        "address": "4231 Columbia Pike Arlington, Virginia 22204",
        "phone": "(703) 348-2884",
        "email": "studiomanager1261@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:50PM",
            "SUNDAY": "7:30AM - 12:50PM"
        },
        "geolocation": [
            38.8585625,
            -77.102104
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.21,
                    "duration": "3 Months",
                    "model_conf": 108.92
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.79,
                    "duration": "indefinitely",
                    "model_conf": 128.85
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.51,
                    "duration": "1 Months",
                    "model_conf": 77.79
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Arlington-Columbia Pike, VA",
            "slug": "9f9412e0-9a65-4c33-bb14-89a9c8e8acc7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/12/19",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/arlington/4231-columbia-pike/",
        "index": 98.425,
        "franchise-entity-name": "MDMS CAPITAL VA5, LLC"
    },
    "633fde71-0e41-4640-9772-e19aeb34dd29": {
        "name": "Orange Theory Arvada, CO",
        "address": "8031 Wadsworth Boulevard A-2 Arvada, Colorado 80003",
        "phone": "(303) 647-1515",
        "email": "studiomanager0561@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            39.8433266,
            -105.08347
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.28,
                    "duration": "indefinitely",
                    "model_conf": 148.56
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 122.41,
                    "duration": "indefinitely",
                    "model_conf": 182.29
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.25,
                    "duration": "3 Months",
                    "model_conf": 110.54
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Arvada, CO",
            "slug": "633fde71-0e41-4640-9772-e19aeb34dd29"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/13/17",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/arvada/8031-wadsworth-boulevard-a-2/",
        "index": 98.425,
        "franchise-entity-name": "FIT IN ANYWHERE, LLC"
    },
    "fa21f59c-d663-4770-bcd5-708ddd7cdedf": {
        "name": "Orange Theory Ashburn, VA",
        "address": "44050 Ashburn Shopping Plaza #189 Ashburn, Virginia 20147",
        "phone": "(703) 881-9110",
        "email": "studiomanager0196@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            39.0438538,
            -77.473595
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.21,
                    "duration": "indefinitely",
                    "model_conf": 146.29
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.44,
                    "duration": "5 Months",
                    "model_conf": 176.59
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.78,
                    "duration": "indefinitely",
                    "model_conf": 107.65
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Ashburn, VA",
            "slug": "fa21f59c-d663-4770-bcd5-708ddd7cdedf"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/ashburn/44050-ashburn-shopping-plaza-189/",
        "index": 98.425,
        "franchise-entity-name": "JKOLA FITNESS ONE, LLC"
    },
    "89d79850-2568-462d-9a64-34b7c1d87b37": {
        "name": "Orange Theory Asheville, NC",
        "address": "1816 Hendersonville Rd #60 Asheville, North Carolina 28803",
        "phone": "(828) 610-5500",
        "email": "studiomanager0650@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 7:45PM",
            "TUESDAY": "4:30AM - 7:45PM",
            "WEDNESDAY": "4:30AM - 7:45PM",
            "THURSDAY": "4:30AM - 7:45PM",
            "FRIDAY": "4:30AM - 7:45PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            35.5056992,
            -82.522705
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.95,
                    "duration": "indefinitely",
                    "model_conf": 151.38
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.54,
                    "duration": "indefinitely",
                    "model_conf": 181.58
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.22,
                    "duration": "indefinitely",
                    "model_conf": 109.34
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Asheville, NC",
            "slug": "89d79850-2568-462d-9a64-34b7c1d87b37"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/4/16",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/asheville/1816-hendersonville-rd-60/",
        "index": 98.425,
        "franchise-entity-name": "SUGAR FITNESS, LLC"
    },
    "313f179b-306b-4180-9c50-f4ffb2c7be7a": {
        "name": "Orange Theory Ditmars, NY",
        "address": "22-56 31St Street 2nd Floor Astoria, New York 11105",
        "phone": "(917) 814-6272",
        "email": "studiomanager1562@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:30PM",
            "TUESDAY": "5:15AM - 8:30PM",
            "WEDNESDAY": "5:15AM - 8:30PM",
            "THURSDAY": "5:15AM - 8:30PM",
            "FRIDAY": "5:15AM - 8:30PM",
            "SATURDAY": "7:45AM - 4:00PM",
            "SUNDAY": "7:45AM - 4:00PM"
        },
        "geolocation": [
            40.775089,
            -73.912304
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.83,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.19,
                    "duration": "indefinitely",
                    "model_conf": 125.61
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.78,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.47,
                    "duration": "indefinitely",
                    "model_conf": 151.26
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 9.7,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 200.48,
                    "duration": "indefinitely",
                    "model_conf": 92.0
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 12.19,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 21.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 29.2,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Ditmars, NY",
            "slug": "313f179b-306b-4180-9c50-f4ffb2c7be7a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/19/23",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/22-56-31st-street-2nd-floor",
        "index": 108.325,
        "franchise-entity-name": "92 FITNESS CREW NY7, LLC"
    },
    "50c39bba-04be-4473-84c7-18034e6c7bb5": {
        "name": "Orange Theory Astoria, NY",
        "address": "31-57 31st St Astoria, New York 11106",
        "phone": "(718) 962-0800",
        "email": "studiomanager0547@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:25PM",
            "TUESDAY": "5:00AM - 9:25PM",
            "WEDNESDAY": "5:00AM - 9:25PM",
            "THURSDAY": "5:00AM - 9:25PM",
            "FRIDAY": "5:00AM - 9:25PM",
            "SATURDAY": "8:00AM - 2:25PM",
            "SUNDAY": "8:00AM - 2:25PM"
        },
        "geolocation": [
            40.7628136,
            -73.924248
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.83,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.99,
                    "duration": "indefinitely",
                    "model_conf": 114.33
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.78,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.99,
                    "duration": "indefinitely",
                    "model_conf": 137.63
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 9.7,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 200.71,
                    "duration": "indefinitely",
                    "model_conf": 84.12
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 12.19,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 21.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 29.2,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Astoria, NY",
            "slug": "50c39bba-04be-4473-84c7-18034e6c7bb5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/19/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/astoria/31-57-31st-st/",
        "index": 108.325,
        "franchise-entity-name": "92 FITNESS CREW NEW YORK 4, LLC"
    },
    "cb460b1f-49bc-4138-8a88-f2721fdfe5cc": {
        "name": "Orange Theory Athens",
        "address": "196 Alps Road Athens, Georgia 30606",
        "phone": "(706) 521-0595",
        "email": "studiomanager0355@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            33.9452438,
            -83.40976
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.0,
                    "duration": "indefinitely",
                    "model_conf": 153.57
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.13,
                    "duration": "indefinitely",
                    "model_conf": 184.97
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.98,
                    "duration": "indefinitely",
                    "model_conf": 111.24
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Athens",
            "slug": "cb460b1f-49bc-4138-8a88-f2721fdfe5cc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/athens/196-alps-road/",
        "index": 98.425,
        "franchise-entity-name": "HILTON AND BUTLER FITNESS, LLC"
    },
    "296881bb-6347-4815-b246-329b637fd0da": {
        "name": "Orange Theory The Athens - The Mark, GA",
        "address": "265 Oconee Street, Suite G Athens, Georgia 30601",
        "phone": "(706) 715-4699",
        "email": "studiomanager1012@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 6:45PM",
            "TUESDAY": "6:00AM - 6:45PM",
            "WEDNESDAY": "6:00AM - 6:45PM",
            "THURSDAY": "6:00AM - 6:45PM",
            "FRIDAY": "6:00AM - 6:45PM",
            "SATURDAY": "8:45AM - 1:30PM",
            "SUNDAY": "8:45AM - 1:30PM"
        },
        "geolocation": [
            33.9569435,
            -83.370316
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.94,
                    "duration": "indefinitely",
                    "model_conf": 153.44
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.1,
                    "duration": "indefinitely",
                    "model_conf": 184.42
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.96,
                    "duration": "indefinitely",
                    "model_conf": 111.64
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "The Athens - The Mark, GA",
            "slug": "296881bb-6347-4815-b246-329b637fd0da"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/athens/265-oconee-street-suite-g/",
        "index": 98.425,
        "franchise-entity-name": "HILTON FITNESS LLC"
    },
    "7911a906-3a1d-43d2-90ce-8eb7c0629bf3": {
        "name": "Orange Theory Northlake, GA",
        "address": "2152 Henderson Mill Road Atlanta, Georgia 30341",
        "phone": "(678) 679-3400",
        "email": "studiomanager0270@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.8528824,
            -84.255173
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.51,
                    "duration": "2 Months",
                    "model_conf": 137.01
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.68,
                    "duration": "indefinitely",
                    "model_conf": 166.4
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.21,
                    "duration": "1 Months",
                    "model_conf": 100.91
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Northlake, GA",
            "slug": "7911a906-3a1d-43d2-90ce-8eb7c0629bf3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/13/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/2152-henderson-mill-road/",
        "index": 98.425,
        "franchise-entity-name": "VVDC NORTHLAKE, LLC"
    },
    "ed77a529-522a-4834-a02d-1227c704a209": {
        "name": "Orange Theory South Buckhead",
        "address": "2255 Peachtree Rd NE Atlanta, Georgia 30309",
        "phone": "(770) 267-5777",
        "email": "studiomanager1424@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 8:00PM",
            "TUESDAY": "6:15AM - 8:00PM",
            "WEDNESDAY": "6:15AM - 8:00PM",
            "THURSDAY": "6:15AM - 8:00PM",
            "FRIDAY": "6:15AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:30PM",
            "SUNDAY": "7:30AM - 1:30PM"
        },
        "geolocation": [
            33.815819,
            -84.39035
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.25,
                    "duration": "3 Months",
                    "model_conf": 112.72
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.8,
                    "duration": "indefinitely",
                    "model_conf": 147.48
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.57,
                    "duration": "indefinitely",
                    "model_conf": 81.62
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "South Buckhead",
            "slug": "ed77a529-522a-4834-a02d-1227c704a209"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/8/20",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/2255-peachtree-rd-ne/",
        "index": 98.425,
        "franchise-entity-name": "JM South Buckhead LLC"
    },
    "a451dc0d-2eb6-4342-b58b-8bee0db955b6": {
        "name": "Orange Theory Inman Park, GA",
        "address": "299 N Highland Ave, Suite H1 &amp; H2 Atlanta, Georgia 30307",
        "phone": "(404) 662-2308",
        "email": "studiomanager1421@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            33.7619782,
            -84.358543
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.84,
                    "duration": "indefinitely",
                    "model_conf": 128.13
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.31,
                    "duration": "2 Months",
                    "model_conf": 165.01
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.82,
                    "duration": "indefinitely",
                    "model_conf": 93.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Inman Park, GA",
            "slug": "a451dc0d-2eb6-4342-b58b-8bee0db955b6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/21/21",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/299-highland-ave-ste-h1-and-h2/",
        "index": 98.425,
        "franchise-entity-name": "JM INMAN PARK LLC"
    },
    "146d24d5-8849-46dd-ad96-86f4a912ed04": {
        "name": "Orange Theory Atlanta - Buckhead, GA",
        "address": "3097 Piedmont Rd Atlanta, Georgia 30305",
        "phone": "(404) 719-0170",
        "email": "studiomanager0162@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.8403359,
            -84.368835
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.06,
                    "duration": "indefinitely",
                    "model_conf": 107.89
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.45,
                    "duration": "1 Months",
                    "model_conf": 135.43
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.92,
                    "duration": "indefinitely",
                    "model_conf": 79.05
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Atlanta - Buckhead, GA",
            "slug": "146d24d5-8849-46dd-ad96-86f4a912ed04"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/11/14",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/3097-piedmont-rd/",
        "index": 98.425,
        "franchise-entity-name": "BLACKBERRY VISION, LLC"
    },
    "098f68a4-a208-4571-b780-a7a84bb11c23": {
        "name": "Orange Theory Brookhaven, GA",
        "address": "4260 Peachtree Rd. Atlanta, Georgia 30319",
        "phone": "(404) 772-8120",
        "email": "studiomanager0400@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:30PM",
            "SUNDAY": "7:30AM - 1:30PM"
        },
        "geolocation": [
            33.8676033,
            -84.335884
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.89,
                    "duration": "5 Months",
                    "model_conf": 104.25
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.15,
                    "duration": "indefinitely",
                    "model_conf": 127.18
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.19,
                    "duration": "1 Months",
                    "model_conf": 77.97
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Brookhaven, GA",
            "slug": "098f68a4-a208-4571-b780-a7a84bb11c23"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/10/16",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/4260-peachtree-rd/",
        "index": 98.425,
        "franchise-entity-name": "CRANBERRY VISION LLC"
    },
    "3908b9f4-029a-4fa8-9a79-b94c2fe07e83": {
        "name": "Orange Theory Chastain Park, GA",
        "address": "4279 Roswell Road, Suite 501 Atlanta, Georgia 30342",
        "phone": "(404) 434-2129",
        "email": "studiomanager0247@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.8714066,
            -84.380074
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.76,
                    "duration": "1 Months",
                    "model_conf": 115.36
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.3,
                    "duration": "indefinitely",
                    "model_conf": 139.88
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.08,
                    "duration": "indefinitely",
                    "model_conf": 85.42
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Chastain Park, GA",
            "slug": "3908b9f4-029a-4fa8-9a79-b94c2fe07e83"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/5/14",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/4279-roswell-road-suite-501/",
        "index": 98.425,
        "franchise-entity-name": "RASBERRY VISION LLC"
    },
    "3ff5810c-0e9c-45a4-9b89-d1d643fc1653": {
        "name": "Orange Theory Sandy Springs, GA",
        "address": "5975 Roswell Road Suite A113 Atlanta, Georgia 30328",
        "phone": "(770) 905-2600",
        "email": "studiomanager0083@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.9185295,
            -84.377953
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.9,
                    "duration": "indefinitely",
                    "model_conf": 118.46
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.01,
                    "duration": "indefinitely",
                    "model_conf": 146.79
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.58,
                    "duration": "indefinitely",
                    "model_conf": 87.94
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sandy Springs, GA",
            "slug": "3ff5810c-0e9c-45a4-9b89-d1d643fc1653"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/6/13",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/5975-roswell-road-suite-a113/",
        "index": 98.425,
        "franchise-entity-name": "CONFLUENCE GROUP LLC"
    },
    "47b3f7f7-92e1-47c3-8ec3-d9889002ad92": {
        "name": "Orange Theory Emory Point, GA",
        "address": "855 Emory Point Drive, Suite 115 Atlanta, Georgia 30329",
        "phone": "(404) 698-0251",
        "email": "studiomanager0190@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            33.7999535,
            -84.325623
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.26,
                    "duration": "3 Months",
                    "model_conf": 119.8
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.71,
                    "duration": "indefinitely",
                    "model_conf": 152.89
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.44,
                    "duration": "1 Months",
                    "model_conf": 87.92
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Emory Point, GA",
            "slug": "47b3f7f7-92e1-47c3-8ec3-d9889002ad92"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/14/14",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/855-emory-point-drive-suite-115/",
        "index": 98.425,
        "franchise-entity-name": "SIMPLE BERRY LLC"
    },
    "64cfacea-9e67-4fa6-86df-fc0514840127": {
        "name": "Orange Theory Atlanta-Howell Mill, GA",
        "address": "857 Collier Rd STE 15 Atlanta, Georgia 30318",
        "phone": "(404) 455-0402",
        "email": "studiomanager0127@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:30PM",
            "SUNDAY": "7:30AM - 1:30PM"
        },
        "geolocation": [
            33.8104248,
            -84.416008
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.46,
                    "duration": "7 Months",
                    "model_conf": 118.11
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.59,
                    "duration": "indefinitely",
                    "model_conf": 154.94
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.14,
                    "duration": "1 Months",
                    "model_conf": 86.34
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Atlanta-Howell Mill, GA",
            "slug": "64cfacea-9e67-4fa6-86df-fc0514840127"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/4/13",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/857-collier-rd-ste-15/",
        "index": 98.425,
        "franchise-entity-name": "BLUEBERRY VISION INVESTMENTS, INC."
    },
    "67deaa8f-a536-43c6-9803-69f2e86cf0af": {
        "name": "Orange Theory Atlanta - Midtown, GA",
        "address": "933 Peachtree St., Suite 939A Atlanta, Georgia 30309",
        "phone": "(404) 747-7107",
        "email": "studiomanager0137@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.7804031,
            -84.384071
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.64,
                    "duration": "indefinitely",
                    "model_conf": 118.98
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.98,
                    "duration": "indefinitely",
                    "model_conf": 157.57
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.93,
                    "duration": "indefinitely",
                    "model_conf": 85.96
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Atlanta - Midtown, GA",
            "slug": "67deaa8f-a536-43c6-9803-69f2e86cf0af"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/14/14",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/933-peachtree-st-suite-939a/",
        "index": 98.425,
        "franchise-entity-name": "STRAWBERRY VISION, LLC"
    },
    "b00343f7-49a2-4a86-991e-7a6e0b7fd1fc": {
        "name": "Orange Theory Madison Yards, GA",
        "address": "975 Memorial Drive, Ste 816 Atlanta, Georgia 30316",
        "phone": "(470) 489-5701",
        "email": "studiomanager0382@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            33.7459297,
            -84.356415
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.98,
                    "duration": "indefinitely",
                    "model_conf": 135.81
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.36,
                    "duration": "2 Months",
                    "model_conf": 171.39
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.91,
                    "duration": "indefinitely",
                    "model_conf": 98.87
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Madison Yards, GA",
            "slug": "b00343f7-49a2-4a86-991e-7a6e0b7fd1fc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/3/20",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/975-memorial-drive-ste-816/",
        "index": 98.425,
        "franchise-entity-name": "EFFECTIVE FITNESS, LLC"
    },
    "2e844732-1f98-48ab-9f40-e6bec322d3f1": {
        "name": "Orange Theory Atlanta-West Midtown, GA",
        "address": "980 Howell Mill Rd Atlanta, Georgia 30318",
        "phone": "(770) 368-6267",
        "email": "studiomanager1422@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 6:45PM",
            "TUESDAY": "6:15AM - 6:45PM",
            "WEDNESDAY": "6:15AM - 6:45PM",
            "THURSDAY": "6:15AM - 6:45PM",
            "FRIDAY": "6:15AM - 6:45PM",
            "SATURDAY": "8:15AM - 12:15PM",
            "SUNDAY": "8:15AM - 12:15PM"
        },
        "geolocation": [
            33.781034,
            -84.411233
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.06,
                    "duration": "indefinitely",
                    "model_conf": 121.07
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.99,
                    "duration": "3 Months",
                    "model_conf": 160.15
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.11,
                    "duration": "indefinitely",
                    "model_conf": 88.3
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Atlanta-West Midtown, GA",
            "slug": "2e844732-1f98-48ab-9f40-e6bec322d3f1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/3/22",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/atlanta/980-howell-mill-rd/",
        "index": 98.425,
        "franchise-entity-name": "JM WEST MIDTOWN LLC"
    },
    "295befad-e471-432d-b381-31a6d55ed80e": {
        "name": "Orange Theory Attleboro, MA",
        "address": "228 Washington St., Suite B290 Attleboro, Massachusetts 02703",
        "phone": "(508) 916-6903",
        "email": "studiomanager0865@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            41.9233704,
            -71.356956
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.58,
                    "duration": "2 Months",
                    "model_conf": 149.77
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.32,
                    "duration": "indefinitely",
                    "model_conf": 173.4
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.77,
                    "duration": "indefinitely",
                    "model_conf": 102.74
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Attleboro, MA",
            "slug": "295befad-e471-432d-b381-31a6d55ed80e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/attleboro/228-washington-st-suite-b290/",
        "index": 98.425,
        "franchise-entity-name": "JD FAMILY FITNESS ENTERPRISES 1 LLC"
    },
    "ba41b5cf-7e76-4fb9-9c05-acd8e510d95e": {
        "name": "Orange Theory Auburn, AL",
        "address": "1345 Opelika Road, Suite 1&amp;2 Auburn, Alabama 36830",
        "phone": "(334) 521-8081",
        "email": "studiomanager0445@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:15AM - 3:45PM",
            "SUNDAY": "7:15AM - 3:45PM"
        },
        "geolocation": [
            32.6217156,
            -85.453705
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.23,
                    "duration": "4 Months",
                    "model_conf": 153.81
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.44,
                    "duration": "indefinitely",
                    "model_conf": 185.78
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.48,
                    "duration": "1 Months",
                    "model_conf": 111.98
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Auburn, AL",
            "slug": "ba41b5cf-7e76-4fb9-9c05-acd8e510d95e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/9/16",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/auburn/1345-opelika-road-suite-1-2/",
        "index": 93.4,
        "franchise-entity-name": "NICHEFITNESS2, LLC"
    },
    "4777fc7d-27fe-4cf8-8a10-93a23f0eaae6": {
        "name": "Orange Theory Lake Tapps",
        "address": "1408 Lake Tapps Parkway, Suites 103 &amp; 104 Auburn, Washington 98092",
        "phone": "(253) 507-7654",
        "email": "studiomanager1212@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            47.2445908,
            -122.21003
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 7.9,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.17,
                    "duration": "4 Months",
                    "model_conf": 153.47
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 11.9,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.05,
                    "duration": "indefinitely",
                    "model_conf": 185.2
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 17.9,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.23,
                    "duration": "1 Months",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 22.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 37.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 49.9,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lake Tapps",
            "slug": "4777fc7d-27fe-4cf8-8a10-93a23f0eaae6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/washington/auburn/1408-lake-tapps-parkway-suites-103-104/",
        "index": 98.425,
        "franchise-entity-name": "LAKE TAPPS FITNESS, LLC"
    },
    "5e21066f-e86d-4795-9cd3-3475100ccec6": {
        "name": "Orange Theory Audubon, PA",
        "address": "613 South Trooper Rd. Audubon, Pennsylvania 19403",
        "phone": "(484) 235-0596",
        "email": "studiomanager0591@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.1282196,
            -75.404228
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.46,
                    "duration": "indefinitely",
                    "model_conf": 146.12
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.03,
                    "duration": "5 Months",
                    "model_conf": 179.04
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.91,
                    "duration": "indefinitely",
                    "model_conf": 104.71
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Audubon, PA",
            "slug": "5e21066f-e86d-4795-9cd3-3475100ccec6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/audubon/613-south-trooper-rd/",
        "index": 103.375,
        "franchise-entity-name": "CENIVIVA FITNESS LLC"
    },
    "abbb1c4e-4e02-49d2-a0c3-ba4e73f501d1": {
        "name": "Orange Theory Augusta, GA",
        "address": "3602 Exchange Lane Augusta, Georgia 30909",
        "phone": "(706) 780-6016",
        "email": "studiomanager1489@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 5:30PM",
            "TUESDAY": "5:00AM - 5:30PM",
            "WEDNESDAY": "5:00AM - 5:30PM",
            "THURSDAY": "5:00AM - 5:30PM",
            "FRIDAY": "5:00AM - 5:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            33.489979,
            -82.075643
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.19,
                    "duration": "indefinitely",
                    "model_conf": 150.6
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.2,
                    "duration": "5 Months",
                    "model_conf": 185.03
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.76,
                    "duration": "indefinitely",
                    "model_conf": 111.0
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Augusta, GA",
            "slug": "abbb1c4e-4e02-49d2-a0c3-ba4e73f501d1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/29/22",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/augusta/3602-exchange-lane/",
        "index": 98.425,
        "franchise-entity-name": "JM Augusta 2 LLC"
    },
    "5c87e8a5-3c08-468d-aa83-e9c61933336a": {
        "name": "Orange Theory Southeast Aurora, CO",
        "address": "4211 South Buckley Road Aurora, Colorado 80013",
        "phone": "(303) 645-4292",
        "email": "studiomanager0517@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            39.6403122,
            -104.79235
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.79,
                    "duration": "5 Months",
                    "model_conf": 139.48
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.01,
                    "duration": "indefinitely",
                    "model_conf": 168.64
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.44,
                    "duration": "1 Months",
                    "model_conf": 101.89
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Southeast Aurora, CO",
            "slug": "5c87e8a5-3c08-468d-aa83-e9c61933336a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/18/16",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/aurora/4211-south-buckley-road/",
        "index": 98.425,
        "franchise-entity-name": "OTF AURORA, LLC"
    },
    "9c72b311-8655-40f7-bb92-e5e1e7e5950a": {
        "name": "Orange Theory Arapahoe Crossings",
        "address": "6554 S. Parker Road Aurora, Colorado 80016",
        "phone": "(303) 645-4290",
        "email": "studiomanager0029@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 12:30PM",
            "TUESDAY": "5:00AM - 12:30PM",
            "WEDNESDAY": "5:00AM - 12:30PM",
            "THURSDAY": "5:00AM - 12:30PM",
            "FRIDAY": "5:00AM - 12:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.597783,
            -104.80314
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.95,
                    "duration": "5 Months",
                    "model_conf": 128.82
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.94,
                    "duration": "indefinitely",
                    "model_conf": 155.66
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.21,
                    "duration": "1 Months",
                    "model_conf": 94.23
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Arapahoe Crossings",
            "slug": "9c72b311-8655-40f7-bb92-e5e1e7e5950a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/28/12",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/aurora/6554-s-parker-road/",
        "index": 98.425,
        "franchise-entity-name": "MEADOW INC."
    },
    "bf6a2d14-b0d4-4653-bdf1-01167710fc23": {
        "name": "Orange Theory Arboretum - Arbor Town Square, TX",
        "address": "10721 Research Blvd., Suite A-100 Austin, Texas 78759",
        "phone": "(512) 900-3223",
        "email": "studiomanager0104@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            30.3991013,
            -97.744995
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.76,
                    "duration": "indefinitely",
                    "model_conf": 133.84
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.46,
                    "duration": "indefinitely",
                    "model_conf": 144.68
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.17,
                    "duration": "indefinitely",
                    "model_conf": 102.91
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Arboretum - Arbor Town Square, TX",
            "slug": "bf6a2d14-b0d4-4653-bdf1-01167710fc23"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/10/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/10721-research-blvd-suite-a-100/",
        "index": 108.325,
        "franchise-entity-name": "ARBOR TOWN SQUARE FITNESS PARTNERS, LLC"
    },
    "2ef00ad3-afd0-4b17-afd2-c670f1bb68eb": {
        "name": "Orange Theory South Lamar, TX",
        "address": "1100 S Lamar Blvd, Austin, Texas 78704",
        "phone": "(512) 807-0400",
        "email": "studiomanager0750@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 9:00PM",
            "TUESDAY": "5:15AM - 9:00PM",
            "WEDNESDAY": "5:15AM - 9:00PM",
            "THURSDAY": "5:15AM - 9:00PM",
            "FRIDAY": "5:15AM - 9:00PM",
            "SATURDAY": "7:45AM - 1:30PM",
            "SUNDAY": "7:45AM - 1:30PM"
        },
        "geolocation": [
            30.2558804,
            -97.762886
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.89,
                    "duration": "indefinitely",
                    "model_conf": 147.55
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.73,
                    "duration": "indefinitely",
                    "model_conf": 174.2
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.35,
                    "duration": "1 Months",
                    "model_conf": 109.41
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "South Lamar, TX",
            "slug": "2ef00ad3-afd0-4b17-afd2-c670f1bb68eb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/10/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/1100-s-lamar-blvd/",
        "index": 108.325,
        "franchise-entity-name": "SOUTH LAMAR FITNESS PARTNERS, LLC"
    },
    "26763ae6-6b4f-49d6-aacf-3a1785ab9598": {
        "name": "Orange Theory Saltillo, TX",
        "address": "1211 East 5th Street Austin, Texas 78702",
        "phone": "(512) 436-0733",
        "email": "studiomanager1311@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 1:30PM",
            "SUNDAY": "7:30AM - 1:30PM"
        },
        "geolocation": [
            30.2630692,
            -97.730721
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.23,
                    "duration": "indefinitely",
                    "model_conf": 147.59
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.65,
                    "duration": "indefinitely",
                    "model_conf": 175.34
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.51,
                    "duration": "indefinitely",
                    "model_conf": 109.68
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Saltillo, TX",
            "slug": "26763ae6-6b4f-49d6-aacf-3a1785ab9598"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/28/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/1211-east-5th-street/",
        "index": 108.325,
        "franchise-entity-name": "EAST AUSTIN FITNESS PARTNERS LLC"
    },
    "63d54646-cbc1-48b1-bf52-63189ef99182": {
        "name": "Orange Theory Tech Ridge, TX",
        "address": "12314 N Interstate Hwy 35 #105 Austin, Texas 78753",
        "phone": "(512) 983-2876",
        "email": "studiomanager0678@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            30.4005032,
            -97.675011
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.3,
                    "duration": "indefinitely",
                    "model_conf": 140.78
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.79,
                    "duration": "indefinitely",
                    "model_conf": 159.88
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.32,
                    "duration": "indefinitely",
                    "model_conf": 105.84
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Tech Ridge, TX",
            "slug": "63d54646-cbc1-48b1-bf52-63189ef99182"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/12314-n-interstate-hwy-35-105/",
        "index": 108.325,
        "franchise-entity-name": "TECH RIDGE FITNESS PARTNERSLLC"
    },
    "120fc597-b19f-49b0-b7c3-8e2427ac4055": {
        "name": "Orange Theory Dripping Springs, TX",
        "address": "12680 W US 290 Austin, Texas 78737",
        "phone": "(512) 994-2664",
        "email": "studiomanager0681@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:45AM - 1:00PM",
            "SUNDAY": "7:45AM - 1:00PM"
        },
        "geolocation": [
            30.210871,
            -97.968778
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.89,
                    "duration": "indefinitely",
                    "model_conf": 153.07
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.36,
                    "duration": "4 Months",
                    "model_conf": 182.86
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.23,
                    "duration": "indefinitely",
                    "model_conf": 111.52
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Dripping Springs, TX",
            "slug": "120fc597-b19f-49b0-b7c3-8e2427ac4055"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/6/22",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/2680-w-us-290-suite-150/",
        "index": 103.375,
        "franchise-entity-name": "DRIPPINGS SPRINGS FITNESS PARTNERS LLC"
    },
    "8a16710f-9fc4-437b-a8cf-66984d345a73": {
        "name": "Orange Theory Lakeline, TX",
        "address": "13000 N FM 620 #105 Austin, Texas 78750",
        "phone": "(512) 807-0442",
        "email": "studiomanager0644@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            30.4687367,
            -97.799469
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.8,
                    "duration": "indefinitely",
                    "model_conf": 133.54
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.41,
                    "duration": "indefinitely",
                    "model_conf": 145.75
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.66,
                    "duration": "indefinitely",
                    "model_conf": 104.72
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Lakeline, TX",
            "slug": "8a16710f-9fc4-437b-a8cf-66984d345a73"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/13000-n-fm-620-105/",
        "index": 108.325,
        "franchise-entity-name": "MAXIMUM FITNESS, LAKELINE LLC"
    },
    "47cddc38-3edd-4162-8451-86b8ba2a66f4": {
        "name": "Orange Theory Avery Ranch, TX",
        "address": "14900 Avery Ranch Blvd, Suite A-100 Austin, Texas 78717",
        "phone": "(512) 669-5272",
        "email": "studiomanager0309@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:45PM",
            "SUNDAY": "7:00AM - 1:45PM"
        },
        "geolocation": [
            30.4983387,
            -97.777069
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.37,
                    "duration": "2 Months",
                    "model_conf": 139.03
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.62,
                    "duration": "indefinitely",
                    "model_conf": 157.8
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.47,
                    "duration": "1 Months",
                    "model_conf": 106.47
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Avery Ranch, TX",
            "slug": "47cddc38-3edd-4162-8451-86b8ba2a66f4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/14/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/14900-avery-ranch-blvd-suite-a-100/",
        "index": 108.325,
        "franchise-entity-name": "NORTH AUSTIN FITNESS PARTNERS II, LLC"
    },
    "77761521-3a82-4e69-a8d6-681f65778cac": {
        "name": "Orange Theory Domain, TX",
        "address": "3100 Esperanza Crossing, Suite 118 Austin, Texas 78758",
        "phone": "(512) 807-0444",
        "email": "studiomanager0817@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:45PM",
            "TUESDAY": "5:15AM - 8:45PM",
            "WEDNESDAY": "5:15AM - 8:45PM",
            "THURSDAY": "5:15AM - 8:45PM",
            "FRIDAY": "5:15AM - 8:45PM",
            "SATURDAY": "7:30AM - 1:00PM",
            "SUNDAY": "7:30AM - 1:00PM"
        },
        "geolocation": [
            30.3997364,
            -97.722435
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.51,
                    "duration": "1 Months",
                    "model_conf": 134.07
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.67,
                    "duration": "indefinitely",
                    "model_conf": 145.16
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.87,
                    "duration": "indefinitely",
                    "model_conf": 102.99
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Domain, TX",
            "slug": "77761521-3a82-4e69-a8d6-681f65778cac"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/3100-esperanza-crossing-suite-118/",
        "index": 108.325,
        "franchise-entity-name": "DOMAIN FITNESS PARTNERS LLC"
    },
    "ea57484d-c379-403d-87b0-583545d785ac": {
        "name": "Orange Theory Triangle, TX",
        "address": "4601 N. Lamar Blvd., #508 Austin, Texas 78751",
        "phone": "(512) 807-0446",
        "email": "studiomanager0711@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 5:15PM",
            "SUNDAY": "7:00AM - 5:15PM"
        },
        "geolocation": [
            30.315073,
            -97.735825
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.37,
                    "duration": "2 Months",
                    "model_conf": 139.22
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.43,
                    "duration": "indefinitely",
                    "model_conf": 161.92
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.66,
                    "duration": "indefinitely",
                    "model_conf": 106.73
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Triangle, TX",
            "slug": "ea57484d-c379-403d-87b0-583545d785ac"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/27/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/4601-n-lamar-blvd-508/",
        "index": 108.325,
        "franchise-entity-name": "TRIANGLE FITNESS PARTNERS, LLC"
    },
    "a6a4b1c4-1ea6-443b-a7f5-fce40069c6b6": {
        "name": "Orange Theory Sunset Valley, TX",
        "address": "5601 Brodie Lane Suite 1200B Austin, Texas 78745",
        "phone": "(512) 807-0404",
        "email": "studiomanager0920@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:00PM",
            "SUNDAY": "7:30AM - 1:00PM"
        },
        "geolocation": [
            30.2263374,
            -97.819664
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.15,
                    "duration": "indefinitely",
                    "model_conf": 150.22
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.57,
                    "duration": "1 Months",
                    "model_conf": 175.07
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.87,
                    "duration": "indefinitely",
                    "model_conf": 109.53
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Sunset Valley, TX",
            "slug": "a6a4b1c4-1ea6-443b-a7f5-fce40069c6b6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/18/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/5601-brodie-lane-suite-1200b/",
        "index": 108.325,
        "franchise-entity-name": "SUNSET VALLEY FITNESS, LLC"
    },
    "4e1b23a3-8829-4542-89b4-69d8f4a8c01b": {
        "name": "Orange Theory Circle C, TX",
        "address": "5700 W Slaughter Lane, Suite 260 Austin, Texas 78749",
        "phone": "(512) 858-8008",
        "email": "studiomanager0947@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            30.2017365,
            -97.868317
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.84,
                    "duration": "indefinitely",
                    "model_conf": 151.94
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.8,
                    "duration": "3 Months",
                    "model_conf": 178.12
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.78,
                    "duration": "indefinitely",
                    "model_conf": 110.34
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Circle C, TX",
            "slug": "4e1b23a3-8829-4542-89b4-69d8f4a8c01b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/5700-w-slaughter-lane-suite-260/",
        "index": 103.375,
        "franchise-entity-name": "CIRCLE C FITNESS LLC"
    },
    "44cc3ce2-f6e5-4393-83c1-9b0bd984c94a": {
        "name": "Orange Theory Austin - Four Points, TX",
        "address": "7301 N FM 620, Suite 175 Austin, Texas 78726",
        "phone": "(512) 368-9343",
        "email": "studiomanager0236@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            30.4052753,
            -97.851364
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.43,
                    "duration": "2 Months",
                    "model_conf": 147.82
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.26,
                    "duration": "indefinitely",
                    "model_conf": 173.44
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.81,
                    "duration": "indefinitely",
                    "model_conf": 109.43
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Austin - Four Points, TX",
            "slug": "44cc3ce2-f6e5-4393-83c1-9b0bd984c94a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/18/14",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/7301-n-fm-620-suite-175/",
        "index": 108.325,
        "franchise-entity-name": "FOUR POINTS FITNESS PARTNERS LLC"
    },
    "216976bf-d7ac-4346-827c-ff770c19e6eb": {
        "name": "Orange Theory Lantana, TX",
        "address": "7415 Southwest Pkwy building 5D Austin, Texas 78735",
        "phone": "(512) 537-7815",
        "email": "studiomanager1329@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 1:00PM",
            "SUNDAY": "7:30AM - 1:00PM"
        },
        "geolocation": [
            30.2545357,
            -97.868309
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 100.33,
                    "duration": "indefinitely",
                    "model_conf": 150.61
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 137.7,
                    "duration": "3 Months",
                    "model_conf": 176.55
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.76,
                    "duration": "indefinitely",
                    "model_conf": 109.92
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Lantana, TX",
            "slug": "216976bf-d7ac-4346-827c-ff770c19e6eb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/7415-southwest-pkwy-building-5d/",
        "index": 108.325,
        "franchise-entity-name": "Lantana Fitness Partners LLC"
    },
    "64006ccd-e58a-4d84-be79-3067212e53d9": {
        "name": "Orange Theory South Austin, TX",
        "address": "9500 South IH 35 Ste. L800 Austin, Texas 78748",
        "phone": "(512) 969-6626",
        "email": "studiomanager0349@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            30.1610565,
            -97.792023
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.36,
                    "duration": "indefinitely",
                    "model_conf": 151.23
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.21,
                    "duration": "2 Months",
                    "model_conf": 174.24
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.11,
                    "duration": "indefinitely",
                    "model_conf": 109.32
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "South Austin, TX",
            "slug": "64006ccd-e58a-4d84-be79-3067212e53d9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/8/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/austin/9500-south-ih-35-ste-l800/",
        "index": 103.375,
        "franchise-entity-name": "AUSTIN MTM FITNESS, LLC"
    },
    "a891b0b6-d4c1-454f-be57-35e46a832fbb": {
        "name": "Orange Theory Aventura, FL",
        "address": "18839 NE Biscayne Boulevard, Suite A Aventura, Florida 33180",
        "phone": "(305) 400-0544",
        "email": "studiomanager0015@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "8:15AM - 12:45PM",
            "SUNDAY": "8:15AM - 12:45PM"
        },
        "geolocation": [
            25.9508076,
            -80.146553
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.7,
                    "duration": "indefinitely",
                    "model_conf": 149.65
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.17,
                    "duration": "2 Months",
                    "model_conf": 183.77
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.37,
                    "duration": "indefinitely",
                    "model_conf": 110.28
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Aventura, FL",
            "slug": "a891b0b6-d4c1-454f-be57-35e46a832fbb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/23/12",
        "url": "https://www.orangetheory.com/en-us/locations/florida/aventura/18839-ne-biscayne-boulevard-suite-a/",
        "index": 98.425,
        "franchise-entity-name": "FIT AVENTURA, LLC"
    },
    "b40ddf84-05a2-4a0e-a630-9b8ddf20dbda": {
        "name": "Orange Theory Avon, IN",
        "address": "8100 E. US 36, Suite 8 Avon, Indiana 46123",
        "phone": "(317) 542-2999",
        "email": "studiomanager1095@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            39.7647247,
            -86.379562
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.73,
                    "duration": "indefinitely",
                    "model_conf": 152.13
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.08,
                    "duration": "3 Months",
                    "model_conf": 184.65
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 170.17,
                    "duration": "indefinitely",
                    "model_conf": 110.77
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Avon, IN",
            "slug": "b40ddf84-05a2-4a0e-a630-9b8ddf20dbda"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/29/18",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/avon/8100-e-us-36-suite-8/",
        "index": 93.4,
        "franchise-entity-name": "PVO Fitness, LLC"
    },
    "b518a78a-3442-41f5-8c83-60dc8b0be4b1": {
        "name": "Orange Theory Avon Commons, OH",
        "address": "35880 Detroit Rd, Unit I Avon, Ohio 44011",
        "phone": "(440) 467-1333",
        "email": "studiomanager1363@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:00PM",
            "TUESDAY": "5:15AM - 7:00PM",
            "WEDNESDAY": "5:15AM - 7:00PM",
            "THURSDAY": "5:15AM - 7:00PM",
            "FRIDAY": "5:15AM - 7:00PM",
            "SATURDAY": "7:15AM - 11:15AM",
            "SUNDAY": "7:15AM - 11:15AM"
        },
        "geolocation": [
            41.4632988,
            -82.014366
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.26,
                    "duration": "indefinitely",
                    "model_conf": 151.63
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.61,
                    "duration": "indefinitely",
                    "model_conf": 185.19
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.44,
                    "duration": "1 Months",
                    "model_conf": 109.76
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Avon Commons, OH",
            "slug": "b518a78a-3442-41f5-8c83-60dc8b0be4b1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/avon/35880-detroit-rd-unit-i/",
        "index": 93.4,
        "franchise-entity-name": "KSA6 LLC"
    },
    "6ed918fb-9759-4cde-91e1-396167dce06c": {
        "name": "Orange Theory Bedford, NH",
        "address": "121 South River Rd, Unit 4, Bedford, New Hampshire 03110",
        "phone": "(603) 347-8002",
        "email": "studiomanager0663@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "6:15AM - 12:25PM",
            "SUNDAY": "6:15AM - 12:25PM"
        },
        "geolocation": [
            42.9547882,
            -71.476334
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 81.13,
                    "duration": "indefinitely",
                    "model_conf": 152.71
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.57,
                    "duration": "7 Months",
                    "model_conf": 185.29
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.17,
                    "duration": "indefinitely",
                    "model_conf": 112.02
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Bedford, NH",
            "slug": "6ed918fb-9759-4cde-91e1-396167dce06c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-hampshire/bedford/121-south-river-rd-unit-4/",
        "index": 98.425,
        "franchise-entity-name": "COSMIA, LLC"
    },
    "7253b1ba-ba58-42ab-ab21-9a9ae875c1ca": {
        "name": "Orange Theory Billings, MT",
        "address": "824 Shiloh Crossing Blvd. Suite 3 Billings, Montana 59102",
        "phone": "(406) 656-1144",
        "email": "studiomanager0721@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:45AM - 12:45PM",
            "SUNDAY": "6:45AM - 12:45PM"
        },
        "geolocation": [
            45.7547112,
            -108.61584
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.08,
                    "duration": "indefinitely",
                    "model_conf": 152.35
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.73,
                    "duration": "1 Months",
                    "model_conf": 185.55
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.58,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Billings, MT",
            "slug": "7253b1ba-ba58-42ab-ab21-9a9ae875c1ca"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/30/17",
        "url": "https://www.orangetheory.com/en-us/locations/montana/billings/824-shiloh-crossing-blvd-suite-3/",
        "index": 88.45,
        "franchise-entity-name": "BIL-ORANGE, LLC"
    },
    "31b37289-5894-451d-9685-9c4151f4ce46": {
        "name": "Orange Theory Bakersfield, CA",
        "address": "12838 Stockdale Hwy Bakersfield, California 93314",
        "phone": "(661) 546-6001",
        "email": "studiomanager1003@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            35.3546715,
            -119.14487
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.79,
                    "duration": "indefinitely",
                    "model_conf": 153.72
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.28,
                    "duration": "indefinitely",
                    "model_conf": 185.8
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.69,
                    "duration": "indefinitely",
                    "model_conf": 111.95
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Bakersfield, CA",
            "slug": "31b37289-5894-451d-9685-9c4151f4ce46"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/16/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/bakersfield/12838-stockdale-hwy-suites-4a-1-4a-2/",
        "index": 103.375,
        "franchise-entity-name": "SUN ON THE FACE LLC"
    },
    "ddb66c1c-ad24-499d-b9a9-5050bddba88c": {
        "name": "Orange Theory Baltimore-Fed Hill, MD",
        "address": "101 W. Cross St. Baltimore, Maryland 21230",
        "phone": "(443) 951-4927",
        "email": "studiomanager1325@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 11:45AM",
            "SUNDAY": "8:00AM - 11:45AM"
        },
        "geolocation": [
            39.276204,
            -76.617411
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.01,
                    "duration": "indefinitely",
                    "model_conf": 137.86
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.3,
                    "duration": "1 Months",
                    "model_conf": 176.35
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.48,
                    "duration": "1 Months",
                    "model_conf": 99.24
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Baltimore-Fed Hill, MD",
            "slug": "ddb66c1c-ad24-499d-b9a9-5050bddba88c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/24/23",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/baltimore/101-w-cross-st",
        "index": 108.325,
        "franchise-entity-name": "Charm City FItness Partners LLC"
    },
    "d30c25b4-d00b-4784-8e63-5b6e0cd75fbd": {
        "name": "Orange Theory Harbor East, MD",
        "address": "1415 Aliceanna Street Baltimore, Maryland 21231",
        "phone": "(410) 675-1201",
        "email": "studiomanager0575@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            39.2832146,
            -76.597473
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.33,
                    "duration": "2 Months",
                    "model_conf": 131.71
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.35,
                    "duration": "indefinitely",
                    "model_conf": 173.09
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.09,
                    "duration": "1 Months",
                    "model_conf": 94.0
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Harbor East, MD",
            "slug": "d30c25b4-d00b-4784-8e63-5b6e0cd75fbd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/12/17",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/baltimore/1415-aliceanna-street/",
        "index": 108.325,
        "franchise-entity-name": "INNER HARBOR FITNESS PARTNERS, LLC"
    },
    "28689686-0842-418f-978b-dbc1bed88f5e": {
        "name": "Orange Theory Barboursville, WV",
        "address": "3034 Champion Dr. Barboursville, West Virginia 25504",
        "phone": "(304) 521-1500",
        "email": "studiomanager0823@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "8:00AM - 11:00AM",
            "SUNDAY": "8:00AM - 11:00AM"
        },
        "geolocation": [
            38.4218712,
            -82.287888
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.27,
                    "duration": "indefinitely",
                    "model_conf": 153.54
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.36,
                    "duration": "2 Months",
                    "model_conf": 185.78
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.14,
                    "duration": "indefinitely",
                    "model_conf": 111.91
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Barboursville, WV",
            "slug": "28689686-0842-418f-978b-dbc1bed88f5e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/17",
        "url": "https://www.orangetheory.com/en-us/locations/west-virginia/barboursville/3034-champion-dr/",
        "index": 93.4,
        "franchise-entity-name": "BNC FITNESS, LLC"
    },
    "d18158ce-a3b4-4683-910c-b84f0221a3d8": {
        "name": "Orange Theory Bartlett",
        "address": "813 S IL Route 59 Bartlett, Illinois 60103",
        "phone": "(630) 246-3333",
        "email": "studiomanager1034@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 10:00PM",
            "TUESDAY": "5:00AM - 10:00PM",
            "WEDNESDAY": "5:00AM - 10:00PM",
            "THURSDAY": "5:00AM - 10:00PM",
            "FRIDAY": "5:00AM - 10:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            41.9767838,
            -88.205948
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.93,
                    "duration": "indefinitely",
                    "model_conf": 150.47
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.79,
                    "duration": "indefinitely",
                    "model_conf": 181.12
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.6,
                    "duration": "indefinitely",
                    "model_conf": 110.2
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Bartlett",
            "slug": "d18158ce-a3b4-4683-910c-b84f0221a3d8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/21/19",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/bartlett/813-s-il-route-59/",
        "index": 103.375,
        "franchise-entity-name": "WINCITY FITNESSS GROUP II, LLC"
    },
    "f1309407-d347-44de-bd3d-f14685a5152e": {
        "name": "Orange Theory Baton Rouge - Long Farm, LA",
        "address": "14650 Village Market St. #103 Baton Rouge, Louisiana 70817",
        "phone": "(225) 412-0455",
        "email": "studiomanager0930@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            30.3612595,
            -91.012718
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.64,
                    "duration": "7 Months",
                    "model_conf": 143.14
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.53,
                    "duration": "indefinitely",
                    "model_conf": 158.71
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.93,
                    "duration": "2 Months",
                    "model_conf": 101.17
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Baton Rouge - Long Farm, LA",
            "slug": "f1309407-d347-44de-bd3d-f14685a5152e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/26/17",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/baton-rouge/14650-village-market-st/",
        "index": 93.4,
        "franchise-entity-name": "GREEN TIGERS LLC"
    },
    "63138ca8-22db-4763-a4ae-b038f522a3ba": {
        "name": "Orange Theory Baton Rouge - Towne Center, LA",
        "address": "2373 Towne Center Blvd., Suite 710 Baton Rouge, Louisiana 70806",
        "phone": "(225) 424-5000",
        "email": "studiomanager0597@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:44AM - 12:00PM",
            "SUNDAY": "7:44AM - 12:00PM"
        },
        "geolocation": [
            30.4325905,
            -91.11467
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.6,
                    "duration": "indefinitely",
                    "model_conf": 46.92
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.46,
                    "duration": "1 Months",
                    "model_conf": 55.23
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.34,
                    "duration": "indefinitely",
                    "model_conf": 39.26
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Baton Rouge - Towne Center, LA",
            "slug": "63138ca8-22db-4763-a4ae-b038f522a3ba"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/9/16",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/baton-rouge/2373-towne-center-blvd-suite-710/",
        "index": 93.4,
        "franchise-entity-name": "TRI-POWER RED STICK #1, LLC"
    },
    "6099b2de-8a40-4e33-a6e6-b84b27611494": {
        "name": "Orange Theory Baton Rouge-Arlington Market",
        "address": "636 Arlington Creek Center Blvd. Bldg 4-A Baton Rouge, Louisiana 70820",
        "phone": "(225) 590-3400",
        "email": "studiomanager1024@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:45AM - 12:00PM",
            "SUNDAY": "7:45AM - 12:00PM"
        },
        "geolocation": [
            30.3906288,
            -91.161942
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.99,
                    "duration": "indefinitely",
                    "model_conf": 131.92
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.12,
                    "duration": "indefinitely",
                    "model_conf": 159.34
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.25,
                    "duration": "indefinitely",
                    "model_conf": 96.74
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Baton Rouge-Arlington Market",
            "slug": "6099b2de-8a40-4e33-a6e6-b84b27611494"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/19/18",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/baton-rouge/636-arlington-creek-center-blvd-bldg-4-a/",
        "index": 93.4,
        "franchise-entity-name": "GREEN TIGERS2 LLC"
    },
    "d56506db-47fe-403e-bcce-f95befaacefe": {
        "name": "Orange Theory Bayonne",
        "address": "189 E 22nd St Bayonne, New Jersey 07002",
        "phone": "(201) 515-6689",
        "email": "studiomanager1448@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            40.6597977,
            -74.112968
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.27,
                    "duration": "indefinitely",
                    "model_conf": 146.2
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.31,
                    "duration": "2 Months",
                    "model_conf": 175.68
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.98,
                    "duration": "indefinitely",
                    "model_conf": 107.09
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Bayonne",
            "slug": "d56506db-47fe-403e-bcce-f95befaacefe"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/21",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/bayonne/189-e-22nd-st/",
        "index": 103.375,
        "franchise-entity-name": "M2 Bayonne LLC"
    },
    "698c2568-d11e-4c93-9ffb-67ea12fec705": {
        "name": "Orange Theory Bay Shore, NY",
        "address": "1701 Sunrise Highway Bayshore, New York 11706",
        "phone": "(631) 954-2969",
        "email": "studiomanager1263@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:30AM - 12:30PM",
            "SUNDAY": "7:30AM - 12:30PM"
        },
        "geolocation": [
            40.7396355,
            -73.246681
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.58,
                    "duration": "indefinitely",
                    "model_conf": 152.16
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 137.99,
                    "duration": "2 Months",
                    "model_conf": 183.64
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.57,
                    "duration": "indefinitely",
                    "model_conf": 111.08
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Bay Shore, NY",
            "slug": "698c2568-d11e-4c93-9ffb-67ea12fec705"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/bayshore/1701-sunrise-highway/",
        "index": 108.325,
        "franchise-entity-name": "ADL3 LLC"
    },
    "fcf4b0cd-d551-4843-9e44-0b457fb9cfe8": {
        "name": "Orange Theory Bayside, NY",
        "address": "41-19 Bell Boulevard Bayside, New York 11361",
        "phone": "(347) 382-9099",
        "email": "studiomanager0938@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:15AM - 1:00PM",
            "SUNDAY": "7:15AM - 1:00PM"
        },
        "geolocation": [
            40.7630768,
            -73.770309
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.46,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.64,
                    "duration": "5 Months",
                    "model_conf": 136.83
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.26,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.42,
                    "duration": "indefinitely",
                    "model_conf": 165.34
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.24,
                    "duration": "1 Months",
                    "model_conf": 100.13
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 11.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 20.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 26.96,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Bayside, NY",
            "slug": "fcf4b0cd-d551-4843-9e44-0b457fb9cfe8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/23/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/bayside/41-19-bell-boulevard/",
        "index": 108.325,
        "franchise-entity-name": "1QUEENFITNESS LLC"
    },
    "f367b32a-ba8d-4a2e-9bf4-96209fca57e6": {
        "name": "Orange Theory Beachwood, OH",
        "address": "2101 Richmond Rd Beachwood, Ohio 44122",
        "phone": "(216) 307-2777",
        "email": "studiomanager1098@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            41.5004234,
            -81.496796
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.36,
                    "duration": "9 Months",
                    "model_conf": 152.73
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.77,
                    "duration": "indefinitely",
                    "model_conf": 185.33
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.02,
                    "duration": "2 Months",
                    "model_conf": 110.49
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Beachwood, OH",
            "slug": "f367b32a-ba8d-4a2e-9bf4-96209fca57e6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/27/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/beachwood/2101-richmond-rd/",
        "index": 93.4,
        "franchise-entity-name": "KSA10 LLC"
    },
    "70f553f5-29b4-42d0-aeba-34735fbcc17c": {
        "name": "Orange Theory Bear, DE",
        "address": "46-48 Fox Hunt Drive Bear, Delaware 19701",
        "phone": "(302) 467-1517",
        "email": "studiomanager1287@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            39.6071205,
            -75.712845
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.73,
                    "duration": "1 Months",
                    "model_conf": 152.75
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.54,
                    "duration": "indefinitely",
                    "model_conf": 184.9
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.68,
                    "duration": "indefinitely",
                    "model_conf": 110.42
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Bear, DE",
            "slug": "70f553f5-29b4-42d0-aeba-34735fbcc17c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/27/20",
        "url": "https://www.orangetheory.com/en-us/locations/delaware/bear/10-412-fox-hunt-drive/",
        "index": 93.4,
        "franchise-entity-name": "TC BUSINESS HOCKESSIN, LLC"
    },
    "2df1c5a2-a73d-43ea-95ce-500d18b5d584": {
        "name": "Orange Theory Beavercreek",
        "address": "2484 N Fairfield Road Beavercreek, Ohio 45431",
        "phone": "(937) 986-1000",
        "email": "studiomanager1175@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:30AM - 1:30PM",
            "SUNDAY": "7:30AM - 1:30PM"
        },
        "geolocation": [
            39.7616806,
            -84.051964
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.52,
                    "duration": "indefinitely",
                    "model_conf": 153.45
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.48,
                    "duration": "1 Months",
                    "model_conf": 185.42
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.06,
                    "duration": "indefinitely",
                    "model_conf": 111.13
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Beavercreek",
            "slug": "2df1c5a2-a73d-43ea-95ce-500d18b5d584"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/6/19",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/beavercreek/2484-north-fairfield-road/",
        "index": 93.4,
        "franchise-entity-name": "Flyers of Fitness LLC"
    },
    "aba7e2b4-d53e-4a99-bb4a-d48fb0fe5cef": {
        "name": "Orange Theory Beaverton, OR",
        "address": "3905 SW 117th Ave. Suite H Beaverton, Oregon 97005",
        "phone": "(503) 512-5800",
        "email": "studiomanager0596@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            45.4908333,
            -122.79855
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.43,
                    "duration": "12 Months",
                    "model_conf": 152.53
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 122.1,
                    "duration": "indefinitely",
                    "model_conf": 170.49
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.19,
                    "duration": "3 Months",
                    "model_conf": 109.28
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Beaverton, OR",
            "slug": "aba7e2b4-d53e-4a99-bb4a-d48fb0fe5cef"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/10/17",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/beaverton/3905-sw-117th-ave-suite-h/",
        "index": 98.425,
        "franchise-entity-name": "JM WEST BEAVERTON, LLC"
    },
    "9982fc0f-992a-4112-b90c-afcba35e74e8": {
        "name": "Orange Theory Bedminster, NJ",
        "address": "434 U.S. 202 Bedminster, New Jersey 07921",
        "phone": "(908) 698-4600",
        "email": "studiomanager0613@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.6821785,
            -74.640564
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.31,
                    "duration": "8 Months",
                    "model_conf": 151.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.89,
                    "duration": "indefinitely",
                    "model_conf": 183.03
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.77,
                    "duration": "2 Months",
                    "model_conf": 110.83
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Bedminster, NJ",
            "slug": "9982fc0f-992a-4112-b90c-afcba35e74e8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/bedminster/434-u-s-202/",
        "index": 98.425,
        "franchise-entity-name": "BIGVFITNESS, LLC"
    },
    "4f653bb4-aa0b-4790-b85d-13301f289032": {
        "name": "Orange Theory Bel Air South, MD",
        "address": "331 Baltimore Pike, Suite 2 Bel Air South, Maryland 21014",
        "phone": "(410) 779-9333",
        "email": "studiomanager1131@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            39.5298348,
            -76.349892
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.05,
                    "duration": "indefinitely",
                    "model_conf": 153.23
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.11,
                    "duration": "indefinitely",
                    "model_conf": 184.98
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.82,
                    "duration": "indefinitely",
                    "model_conf": 108.98
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Bel Air South, MD",
            "slug": "4f653bb4-aa0b-4790-b85d-13301f289032"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/13/18",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/bel-air-south/331-baltimore-pike-suite-2/",
        "index": 108.325,
        "franchise-entity-name": "Team Bel Air, LLC"
    },
    "23c12aaa-c062-4f93-912c-a260834cbd3f": {
        "name": "Orange Theory Bellevue, WA",
        "address": "30 Bellevue Way NE Bellevue, Washington 98004",
        "phone": "(206) 785-9190",
        "email": "studiomanager0578@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            47.6107864,
            -122.20119
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.68,
                    "duration": "5 Months",
                    "model_conf": 142.45
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.04,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.69,
                    "duration": "indefinitely",
                    "model_conf": 183.65
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.17,
                    "duration": "1 Months",
                    "model_conf": 96.74
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 45.45,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 60.5,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Bellevue, WA",
            "slug": "23c12aaa-c062-4f93-912c-a260834cbd3f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/30/17",
        "url": "https://www.orangetheory.com/en-us/locations/washington/bellevue/30-bellevue-way-ne/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF BELLEVUE CBD, LLC"
    },
    "054dec44-f4e9-426a-8e8f-21a06d3e7f27": {
        "name": "Orange Theory Belmont",
        "address": "280 Trapelo Rd Belmont, Massachusetts 02478",
        "phone": "(617) 600-0033",
        "email": "studiomanager1224@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            42.3834839,
            -71.181633
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.55,
                    "duration": "6 Months",
                    "model_conf": 148.85
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.91,
                    "duration": "indefinitely",
                    "model_conf": 182.97
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.66,
                    "duration": "2 Months",
                    "model_conf": 110.74
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Belmont",
            "slug": "054dec44-f4e9-426a-8e8f-21a06d3e7f27"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/13/20",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/belmont/280-trapelo-rd/",
        "index": 103.375,
        "franchise-entity-name": "SDA ONE, LLC"
    },
    "a172c850-b20a-4353-9d5e-bffc0eadb6df": {
        "name": "Orange Theory Bend, OR",
        "address": "320 SW Powerhouse Drive, #150, Bend, Oregon 97702",
        "phone": "(541) 904-5123",
        "email": "studiomanager0932@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:10AM - 12:35PM",
            "SUNDAY": "7:10AM - 12:35PM"
        },
        "geolocation": [
            44.0477676,
            -121.31435
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.99,
                    "duration": "indefinitely",
                    "model_conf": 153.65
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.98,
                    "duration": "indefinitely",
                    "model_conf": 185.37
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.0,
                    "duration": "indefinitely",
                    "model_conf": 112.15
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Bend, OR",
            "slug": "a172c850-b20a-4353-9d5e-bffc0eadb6df"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/bend/320-sw-powerhouse-drive-150/",
        "index": 98.425,
        "franchise-entity-name": "BEND FIT, LIMITED LIABILITY COMPANY"
    },
    "caf9edde-29e1-4db8-a863-cb31f4ac0ab6": {
        "name": "Orange Theory Bentonville",
        "address": "1201 S Walton Blvd. Suite 105 Bentonville, Arkansas 72712",
        "phone": "(479) 364-0347",
        "email": "studiomanager1431@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            36.3589249,
            -94.214157
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.99,
                    "duration": "indefinitely",
                    "model_conf": 149.27
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.61,
                    "duration": "1 Months",
                    "model_conf": 184.87
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.17,
                    "duration": "indefinitely",
                    "model_conf": 109.87
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Bentonville",
            "slug": "caf9edde-29e1-4db8-a863-cb31f4ac0ab6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/7/20",
        "url": "https://www.orangetheory.com/en-us/locations/arkansas/bentonville/1201-s-walton-blvd-suite-105/",
        "index": 93.4,
        "franchise-entity-name": "AR HIIT, LLC"
    },
    "de0b3ba7-076f-42ca-8746-8fb2a7321684": {
        "name": "Orange Theory Berkeley, CA",
        "address": "2475 Shattuck Ave. Berkley, California 94704",
        "phone": "(510) 849-6404",
        "email": "studiomanager0666@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:30PM",
            "TUESDAY": "6:15AM - 7:30PM",
            "WEDNESDAY": "6:15AM - 7:30PM",
            "THURSDAY": "6:15AM - 7:30PM",
            "FRIDAY": "6:15AM - 7:30PM",
            "SATURDAY": "8:35AM - 11:55AM",
            "SUNDAY": "8:35AM - 11:55AM"
        },
        "geolocation": [
            37.8644409,
            -122.26733
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.52,
                    "duration": "indefinitely",
                    "model_conf": 153.15
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.89,
                    "duration": "indefinitely",
                    "model_conf": 184.38
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.7,
                    "duration": "indefinitely",
                    "model_conf": 110.27
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Berkeley, CA",
            "slug": "de0b3ba7-076f-42ca-8746-8fb2a7321684"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/berkley/2475-shattuck-ave/",
        "index": 108.325,
        "franchise-entity-name": "SFFIT BRK, LLC"
    },
    "ec38dfac-8d9a-4d37-9b0b-e3ae606738d9": {
        "name": "Orange Theory South Hills, PA",
        "address": "301 South Hills Village Bethel Park, Pennsylvania 15241",
        "phone": "(412) 275-0446",
        "email": "studiomanager1033@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.3422241,
            -80.055595
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.17,
                    "duration": "4 Months",
                    "model_conf": 153.08
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.13,
                    "duration": "indefinitely",
                    "model_conf": 184.07
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.18,
                    "duration": "1 Months",
                    "model_conf": 109.24
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "South Hills, PA",
            "slug": "ec38dfac-8d9a-4d37-9b0b-e3ae606738d9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/bethel-park/301-south-hills-village/",
        "index": 93.4,
        "franchise-entity-name": "TANGERINE RETAIL VENTURES IV, LLC"
    },
    "6b24dbaa-bfed-4b1f-a13f-8e24cdf1bab6": {
        "name": "Orange Theory Bethesda, MD",
        "address": "7955 Woodmont Avenue Bethesda, Maryland 20814",
        "phone": "(301) 565-0366",
        "email": "studiomanager1036@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            38.989696,
            -77.096173
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.68,
                    "duration": "1 Months",
                    "model_conf": 121.68
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.3,
                    "duration": "indefinitely",
                    "model_conf": 141.47
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.69,
                    "duration": "indefinitely",
                    "model_conf": 76.36
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Bethesda, MD",
            "slug": "6b24dbaa-bfed-4b1f-a13f-8e24cdf1bab6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/8/22",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/bethesda/7955-woodmont-avenue/",
        "index": 108.325,
        "franchise-entity-name": "BETHESDA FITNESS PARTNERS, LLC"
    },
    "9fc3101d-d777-4874-9558-c0adb072e9b9": {
        "name": "Orange Theory Bethpage, NY",
        "address": "4041B Hempstead Turnpike Bethpage, New York 11714",
        "phone": "(516) 597-4740",
        "email": "studiomanager0246@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 1:30PM",
            "SUNDAY": "6:30AM - 1:30PM"
        },
        "geolocation": [
            40.7261047,
            -73.486458
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 96.41,
                    "duration": "10 Months",
                    "model_conf": 112.06
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 141.76,
                    "duration": "indefinitely",
                    "model_conf": 133.34
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 197.53,
                    "duration": "2 Months",
                    "model_conf": 73.75
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Bethpage, NY",
            "slug": "9fc3101d-d777-4874-9558-c0adb072e9b9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/bethpage/4041b-hempstead-turnpike/",
        "index": 108.325,
        "franchise-entity-name": "LEAD3, LLC"
    },
    "a656428d-2bea-4ee0-a123-a29559981d19": {
        "name": "Orange Theory Bexley, OH",
        "address": "2224 E. Main Street Bexley, Ohio 43209",
        "phone": "(614) 360-1212",
        "email": "studiomanager0611@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            39.9577751,
            -82.939774
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.7,
                    "duration": "1 Months",
                    "model_conf": 126.2
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.7,
                    "duration": "indefinitely",
                    "model_conf": 152.53
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.6,
                    "duration": "indefinitely",
                    "model_conf": 93.0
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Bexley, OH",
            "slug": "a656428d-2bea-4ee0-a123-a29559981d19"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/9/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/bexley/2224-e-main-street/",
        "index": 93.4,
        "franchise-entity-name": "BUCKEYE 22 FITNESS, LLC"
    },
    "cb754190-b61b-4169-a632-0626eb67704b": {
        "name": "Orange Theory Downtown Birmingham, AL",
        "address": "117 20th Street South Birmingham, Alabama 35233",
        "phone": "(205) 716-2111",
        "email": "studiomanager0563@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.5115166,
            -86.803619
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.77,
                    "duration": "indefinitely",
                    "model_conf": 151.29
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.86,
                    "duration": "4 Months",
                    "model_conf": 185.22
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.18,
                    "duration": "indefinitely",
                    "model_conf": 111.81
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Downtown Birmingham, AL",
            "slug": "cb754190-b61b-4169-a632-0626eb67704b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/birmingham/117-20th-street-south/",
        "index": 93.4,
        "franchise-entity-name": "NICHEFITNESS7, LLC"
    },
    "a8f21925-bc2c-4ad2-8c14-145276176ebc": {
        "name": "Orange Theory Trussville",
        "address": "2050 Gadsden Highway Suite 124 Birmingham, Alabama 35235",
        "phone": "(205) 387-3828",
        "email": "studiomanager1358@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 3:45PM",
            "SUNDAY": "7:30AM - 3:45PM"
        },
        "geolocation": [
            33.6126022,
            -86.62426
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.17,
                    "duration": "indefinitely",
                    "model_conf": 153.61
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.41,
                    "duration": "indefinitely",
                    "model_conf": 185.76
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.07,
                    "duration": "indefinitely",
                    "model_conf": 111.13
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Trussville",
            "slug": "a8f21925-bc2c-4ad2-8c14-145276176ebc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/8/19",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/birmingham/2050-gadsden-highway-suite-124/",
        "index": 93.4,
        "franchise-entity-name": "NICHEFITNESS10, LLC"
    },
    "8b734efa-7f8a-4f33-aab4-f0b2cf79d249": {
        "name": "Orange Theory Inverness, AL",
        "address": "210 Inverness Corners Birmingham, Alabama 35242",
        "phone": "(205) 991-9700",
        "email": "studiomanager0364@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            33.4184456,
            -86.69529
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.17,
                    "duration": "4 Months",
                    "model_conf": 151.06
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.74,
                    "duration": "indefinitely",
                    "model_conf": 185.12
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.21,
                    "duration": "1 Months",
                    "model_conf": 111.29
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Inverness, AL",
            "slug": "8b734efa-7f8a-4f33-aab4-f0b2cf79d249"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/8/15",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/birmingham/210-inverness-corners/",
        "index": 93.4,
        "franchise-entity-name": "NICHEFITNESS6, LLC"
    },
    "4210cd9f-cbef-49f6-b501-7f24ad4d2c24": {
        "name": "Orange Theory Birmingham, MI",
        "address": "633 South Adams Road Birmingham, Michigan 48009",
        "phone": "(248) 712-4548",
        "email": "studiomanager0231@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:00PM",
            "TUESDAY": "5:30AM - 8:00PM",
            "WEDNESDAY": "5:30AM - 8:00PM",
            "THURSDAY": "5:30AM - 8:00PM",
            "FRIDAY": "5:30AM - 8:00PM",
            "SATURDAY": "5:30AM - 1:00PM",
            "SUNDAY": "5:30AM - 1:00PM"
        },
        "geolocation": [
            42.5427361,
            -83.204041
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.28,
                    "duration": "indefinitely",
                    "model_conf": 126.04
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.56,
                    "duration": "1 Months",
                    "model_conf": 155.73
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.13,
                    "duration": "indefinitely",
                    "model_conf": 98.86
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Birmingham, MI",
            "slug": "4210cd9f-cbef-49f6-b501-7f24ad4d2c24"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/birmingham/633-south-adams-road/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS MICHIGAN, LLC - 0231"
    },
    "cff9b197-8220-4512-a6b3-2c3283afe21d": {
        "name": "Orange Theory Bloomington, IL",
        "address": "1500 E Empire St. A-13 Bloomington, Illinois 61701",
        "phone": "(309) 434-5500",
        "email": "studiomanager0804@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            40.490799,
            -88.957893
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.69
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.75
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Bloomington, IL",
            "slug": "cff9b197-8220-4512-a6b3-2c3283afe21d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/17/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/bloomington/1500-e-empire-st-a-13/",
        "index": 93.4,
        "franchise-entity-name": "JH FITNESS, LLC"
    },
    "53431445-d50c-43c6-ac29-70176d08aa8d": {
        "name": "Orange Theory Bloomington-Kirkwood",
        "address": "210 East Kirkwood Avenue Bloomington, Indiana 47408",
        "phone": "(812) 610-4444",
        "email": "studiomanager1468@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:45PM",
            "TUESDAY": "6:00AM - 7:45PM",
            "WEDNESDAY": "6:00AM - 7:45PM",
            "THURSDAY": "6:00AM - 7:45PM",
            "FRIDAY": "6:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 12:15PM",
            "SUNDAY": "8:00AM - 12:15PM"
        },
        "geolocation": [
            39.166266,
            -86.531405
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.99,
                    "duration": "indefinitely",
                    "model_conf": 152.91
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.83,
                    "duration": "indefinitely",
                    "model_conf": 185.82
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.42,
                    "duration": "indefinitely",
                    "model_conf": 111.96
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Bloomington-Kirkwood",
            "slug": "53431445-d50c-43c6-ac29-70176d08aa8d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/9/21",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/bloomington/210-e-kirkwood-ave/",
        "index": 93.4,
        "franchise-entity-name": "Btown Fitness South, LLC"
    },
    "aebaaf9b-b410-4f87-a9eb-a1a15ba1e85e": {
        "name": "Orange Theory Bloomington-Eastside",
        "address": "2894 E. 3rd St #113 Bloomington, Indiana 47401",
        "phone": "(812) 727-4002",
        "email": "studiomanager0910@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:45AM - 12:45PM",
            "SUNDAY": "6:45AM - 12:45PM"
        },
        "geolocation": [
            39.1612129,
            -86.495384
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.63,
                    "duration": "2 Months",
                    "model_conf": 152.5
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.89,
                    "duration": "indefinitely",
                    "model_conf": 185.84
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.07,
                    "duration": "indefinitely",
                    "model_conf": 111.88
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Bloomington-Eastside",
            "slug": "aebaaf9b-b410-4f87-a9eb-a1a15ba1e85e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/bloomington/2894-e-3rd-st-113/",
        "index": 93.4,
        "franchise-entity-name": "BTOWN FITNESS, LLC"
    },
    "eb6cd7be-0eb2-4d6f-8941-9af066b31935": {
        "name": "Orange Theory Blue Bell, PA",
        "address": "1510 DeKalb Pike Blue Bell, Pennsylvania 19422",
        "phone": "(610) 272-1300",
        "email": "studiomanager0342@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            40.1544724,
            -75.301895
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.82,
                    "duration": "indefinitely",
                    "model_conf": 150.07
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.84,
                    "duration": "3 Months",
                    "model_conf": 182.95
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.14,
                    "duration": "indefinitely",
                    "model_conf": 108.73
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Blue Bell, PA",
            "slug": "eb6cd7be-0eb2-4d6f-8941-9af066b31935"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/5/15",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/blue-bell/1510-dekalb-pike/",
        "index": 103.375,
        "franchise-entity-name": "WET BEAVER FITNESS - BLUE BELL, LLC"
    },
    "a2577591-9b53-443e-84a6-1de13b8bbf38": {
        "name": "Orange Theory Youngstown, OH",
        "address": "463 Boardman Poland Road Boardman, Ohio 44512",
        "phone": "(330) 792-7272",
        "email": "studiomanager1082@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "6:30AM - 12:30PM",
            "SUNDAY": "6:30AM - 12:30PM"
        },
        "geolocation": [
            41.0243111,
            -80.648849
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.84
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.84
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.78
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Youngstown, OH",
            "slug": "a2577591-9b53-443e-84a6-1de13b8bbf38"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/7/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/boardman/463-boardman-poland-road/",
        "index": 93.4,
        "franchise-entity-name": "ALIVE FITNESS, LLC"
    },
    "a006cb05-ad0a-469a-9d2d-4a52d1b38963": {
        "name": "Orange Theory Boca Raton - East, FL",
        "address": "10 East Palmetto Park Road Boca Raton, Florida 33432",
        "phone": "(561) 571-7740",
        "email": "studiomanager0460@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 6:30PM",
            "TUESDAY": "6:00AM - 6:30PM",
            "WEDNESDAY": "6:00AM - 6:30PM",
            "THURSDAY": "6:00AM - 6:30PM",
            "FRIDAY": "6:00AM - 6:30PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            26.3500404,
            -80.087143
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.2,
                    "duration": "indefinitely",
                    "model_conf": 139.33
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.96,
                    "duration": "3 Months",
                    "model_conf": 178.94
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.23,
                    "duration": "indefinitely",
                    "model_conf": 103.35
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Boca Raton - East, FL",
            "slug": "a006cb05-ad0a-469a-9d2d-4a52d1b38963"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/9/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/boca-raton/10-e-palmetto-park-rd/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS, LLC"
    },
    "3faf5e12-327c-42e6-adb7-1363b57c4d04": {
        "name": "Orange Theory Boca Raton - Central, FL",
        "address": "2240 N.W. 19th Street Boca Raton, Florida 33486",
        "phone": "(561) 750-0900",
        "email": "studiomanager0011@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:15PM",
            "TUESDAY": "5:15AM - 8:15PM",
            "WEDNESDAY": "5:15AM - 8:15PM",
            "THURSDAY": "5:15AM - 8:15PM",
            "FRIDAY": "5:15AM - 8:15PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            26.3657112,
            -80.12542
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.83,
                    "duration": "1 Months",
                    "model_conf": 140.59
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.5,
                    "duration": "1 Months",
                    "model_conf": 179.89
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.94,
                    "duration": "indefinitely",
                    "model_conf": 104.26
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Boca Raton - Central, FL",
            "slug": "3faf5e12-327c-42e6-adb7-1363b57c4d04"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/6/12",
        "url": "https://www.orangetheory.com/en-us/locations/florida/boca-raton/2240-n-w-19th-street/",
        "index": 88.45,
        "franchise-entity-name": "OTF PROPERTY HOLDINGS, LLC"
    },
    "28f91413-9af6-4361-9d71-db5070465a64": {
        "name": "Orange Theory Boca Raton - West, FL",
        "address": "8903 Glades Rd, Ste H13 Boca Raton, Florida 33434",
        "phone": "(561) 488-1955",
        "email": "studiomanager0098@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:00PM",
            "TUESDAY": "5:30AM - 8:00PM",
            "WEDNESDAY": "5:30AM - 8:00PM",
            "THURSDAY": "5:30AM - 8:00PM",
            "FRIDAY": "5:30AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            26.3697186,
            -80.185211
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.38,
                    "duration": "3 Months",
                    "model_conf": 142.46
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.14,
                    "duration": "indefinitely",
                    "model_conf": 179.73
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.51,
                    "duration": "1 Months",
                    "model_conf": 105.46
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Boca Raton - West, FL",
            "slug": "28f91413-9af6-4361-9d71-db5070465a64"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/3/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/boca-raton/8903-glades-rd-ste-h13/",
        "index": 98.425,
        "franchise-entity-name": "OTF PROPERTY HOLDINGS, LLC"
    },
    "be6290c3-e70b-4e65-b793-a581704b0911": {
        "name": "Orange Theory Boise, ID",
        "address": "979 E Parkcenter Blvd., Boise, Idaho 83706",
        "phone": "(208) 246-8600",
        "email": "studiomanager0800@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 7:45PM",
            "TUESDAY": "4:55AM - 7:45PM",
            "WEDNESDAY": "4:55AM - 7:45PM",
            "THURSDAY": "4:55AM - 7:45PM",
            "FRIDAY": "4:55AM - 7:45PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            43.5825005,
            -116.17136
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 4.74,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.88,
                    "duration": "indefinitely",
                    "model_conf": 149.61
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.8,
                    "duration": "indefinitely",
                    "model_conf": 185.4
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 10.74,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.95,
                    "duration": "indefinitely",
                    "model_conf": 111.61
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 13.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 22.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Boise, ID",
            "slug": "be6290c3-e70b-4e65-b793-a581704b0911"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/26/17",
        "url": "https://www.orangetheory.com/en-us/locations/idaho/boise/979-e-parkcenter-blvd/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS IDAHO, LLC"
    },
    "d5aec246-accd-4726-bdcb-afd2d2c5bc96": {
        "name": "Orange Theory Boston - Back Bay, MA",
        "address": "10 Saint James Avenue Boston, Massachusetts 02116",
        "phone": "(617) 936-4890",
        "email": "studiomanager0285@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            42.3503761,
            -71.070953
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 118.01,
                    "duration": "3 Months",
                    "model_conf": 145.88
                }
            },
            "Elite": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 170.68,
                    "duration": "indefinitely",
                    "model_conf": 181.48
                }
            },
            "Premier": {
                "cost": 229,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 227.67,
                    "duration": "2 Months",
                    "model_conf": 109.83
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 219,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 229,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 159,
            "elite": 169,
            "additional-session-2": 22,
            "name": "Boston - Back Bay, MA",
            "slug": "d5aec246-accd-4726-bdcb-afd2d2c5bc96"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/10/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/boston/10-saint-james-avenue/",
        "index": 121.225,
        "franchise-entity-name": "BISH 2 MERGE LLC"
    },
    "b9476a09-bd09-45ed-b0d3-ed6d938774ce": {
        "name": "Orange Theory Bothell, WA",
        "address": "22627 Bothell Everett Hwy, Suite A Bothell, Washington 98021",
        "phone": "(425) 318-7353",
        "email": "studiomanager0473@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "5:45AM - 1:30PM",
            "SUNDAY": "5:45AM - 1:30PM"
        },
        "geolocation": [
            47.7926827,
            -122.21368
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.4,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.05,
                    "duration": "indefinitely",
                    "model_conf": 151.44
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.6,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.58,
                    "duration": "1 Months",
                    "model_conf": 182.18
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.9,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.48,
                    "duration": "1 Months",
                    "model_conf": 107.28
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 26.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 47.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 62.9,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Bothell, WA",
            "slug": "b9476a09-bd09-45ed-b0d3-ed6d938774ce"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/14/16",
        "url": "https://www.orangetheory.com/en-us/locations/washington/bothell/22627-bothell-everett-hwy-suite-a/",
        "index": 108.325,
        "franchise-entity-name": "KAIZEN CULTURE LLC"
    },
    "314d50dd-5bba-4299-a108-4dd410a933ef": {
        "name": "Orange Theory Boulder, CO",
        "address": "1850 29th Street Boulder, Colorado 80301",
        "phone": "(303) 530-9663",
        "email": "studiomanager0174@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 6:45PM",
            "TUESDAY": "5:30AM - 6:45PM",
            "WEDNESDAY": "5:30AM - 6:45PM",
            "THURSDAY": "5:30AM - 6:45PM",
            "FRIDAY": "5:30AM - 6:45PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            40.0196114,
            -105.25625
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.77,
                    "duration": "indefinitely",
                    "model_conf": 151.68
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.98,
                    "duration": "3 Months",
                    "model_conf": 183.63
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.65,
                    "duration": "indefinitely",
                    "model_conf": 110.83
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Boulder, CO",
            "slug": "314d50dd-5bba-4299-a108-4dd410a933ef"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/19/14",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/boulder/1850-29th-street/",
        "index": 98.425,
        "franchise-entity-name": "WSH BOULDER 29th, LLC"
    },
    "ed965f58-dd75-43d3-be29-0dcfa4494c89": {
        "name": "Orange Theory Boulder Table Mesa, CO",
        "address": "649A S Broadway St Boulder, Colorado 80303",
        "phone": "(720) 893-0809",
        "email": "studiomanager1312@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:30AM - 6:30PM",
            "TUESDAY": "6:30AM - 6:30PM",
            "WEDNESDAY": "6:30AM - 6:30PM",
            "THURSDAY": "6:30AM - 6:30PM",
            "FRIDAY": "6:30AM - 6:30PM",
            "SATURDAY": "7:45AM - 11:15AM",
            "SUNDAY": "7:45AM - 11:15AM"
        },
        "geolocation": [
            39.9836044,
            -105.24996
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.52,
                    "duration": "indefinitely",
                    "model_conf": 152.18
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.19,
                    "duration": "5 Months",
                    "model_conf": 184.39
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.28,
                    "duration": "indefinitely",
                    "model_conf": 111.19
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Boulder Table Mesa, CO",
            "slug": "ed965f58-dd75-43d3-be29-0dcfa4494c89"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/25/21",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/boulder/649a-s-broadway-st/",
        "index": 98.425,
        "franchise-entity-name": "WSH South Boulder, LLC"
    },
    "04c40bb9-b14e-47b2-803e-341c8cb3210f": {
        "name": "Orange Theory Bountiful, UT",
        "address": "85 West 500 South Bountiful, Utah 84010",
        "phone": "(801) 784-6477",
        "email": "studiomanager0393@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            40.8836975,
            -111.88276
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.54,
                    "duration": "indefinitely",
                    "model_conf": 151.99
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.74,
                    "duration": "indefinitely",
                    "model_conf": 182.98
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.68,
                    "duration": "indefinitely",
                    "model_conf": 111.34
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Bountiful, UT",
            "slug": "04c40bb9-b14e-47b2-803e-341c8cb3210f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/9/15",
        "url": "https://www.orangetheory.com/en-us/locations/utah/bountiful/85-west-500-south/",
        "index": 93.4,
        "franchise-entity-name": "HBTANNER HOLDINGS I LLC"
    },
    "641ffd47-92bd-44a7-8686-ad7a6ea7b432": {
        "name": "Orange Theory Bowling Green, KY",
        "address": "760 Campbell Lane Bowling Green, Kentucky 42104",
        "phone": "(270) 957-8866",
        "email": "studiomanager1218@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            36.9623604,
            -86.464066
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 3.54,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.25,
                    "duration": "indefinitely",
                    "model_conf": 153.62
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 5.94,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.34,
                    "duration": "indefinitely",
                    "model_conf": 185.06
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.26,
                    "duration": "indefinitely",
                    "model_conf": 112.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Bowling Green, KY",
            "slug": "641ffd47-92bd-44a7-8686-ad7a6ea7b432"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/9/19",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/bowling-green/760-campbell-lane/",
        "index": 88.45,
        "franchise-entity-name": "INMI, LLC"
    },
    "1f6f9184-8ece-46c4-91ab-b82c3ec08a3c": {
        "name": "Orange Theory Boynton Beach, FL",
        "address": "395 N. Congress Ave. Unit 10 Boynton Beach, Florida 33426",
        "phone": "(561) 734-6777",
        "email": "studiomanager0048@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:50AM",
            "SUNDAY": "7:30AM - 11:50AM"
        },
        "geolocation": [
            26.5299645,
            -80.090416
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.57,
                    "duration": "2 Months",
                    "model_conf": 151.5
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.56,
                    "duration": "indefinitely",
                    "model_conf": 180.34
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.82,
                    "duration": "indefinitely",
                    "model_conf": 110.67
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Boynton Beach, FL",
            "slug": "1f6f9184-8ece-46c4-91ab-b82c3ec08a3c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/15/13",
        "url": "https://www.orangetheory.com/en-us/locations/florida/boynton-beach/395-n-congress-ave-unit-10/",
        "index": 98.425,
        "franchise-entity-name": "OTPPC2, LLC"
    },
    "240e47ad-6f16-49c6-9642-5af6833375ac": {
        "name": "Orange Theory Bozeman, MT",
        "address": "1040 S. Fowler Ave Suite 103 Bozeman, Montana 59715",
        "phone": "(406) 577-3000",
        "email": "studiomanager0720@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            45.6688995,
            -111.08237
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.25,
                    "duration": "indefinitely",
                    "model_conf": 153.75
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.34,
                    "duration": "indefinitely",
                    "model_conf": 185.38
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.26,
                    "duration": "indefinitely",
                    "model_conf": 110.86
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Bozeman, MT",
            "slug": "240e47ad-6f16-49c6-9642-5af6833375ac"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/2/19",
        "url": "https://www.orangetheory.com/en-us/locations/montana/bozeman/1040-s-fowler-ave-suite-103/",
        "index": 88.45,
        "franchise-entity-name": "BOZ-ORANGE, LLC"
    },
    "7cc297b0-7959-4992-b17f-55d339e6171f": {
        "name": "Orange Theory Lakewood Ranch, FL",
        "address": "5496 Lena Rd. Bradenton, Florida 34211",
        "phone": "(941) 444-2442",
        "email": "studiomanager0255@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:10PM",
            "SUNDAY": "7:00AM - 12:10PM"
        },
        "geolocation": [
            27.4407482,
            -82.454865
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.39,
                    "duration": "indefinitely",
                    "model_conf": 153.11
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.27,
                    "duration": "5 Months",
                    "model_conf": 184.48
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.76,
                    "duration": "indefinitely",
                    "model_conf": 110.47
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lakewood Ranch, FL",
            "slug": "7cc297b0-7959-4992-b17f-55d339e6171f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/28/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/bradenton/5496-lena-rd/",
        "index": 98.425,
        "franchise-entity-name": "LAKEWOOD RANCH FITNESS EXPRESS &quot; LLC &quot;"
    },
    "6f3e5de3-8d3d-4071-9fc9-72e28a16202d": {
        "name": "Orange Theory Braintree, MA",
        "address": "250 Granite Street Braintree, Massachusetts 02184",
        "phone": "(781) 399-4766",
        "email": "studiomanager1225@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45PM",
            "SUNDAY": "7:00AM - 11:45PM"
        },
        "geolocation": [
            42.2220917,
            -71.024231
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.98,
                    "duration": "4 Months",
                    "model_conf": 149.79
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.01,
                    "duration": "indefinitely",
                    "model_conf": 183.67
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.03,
                    "duration": "1 Months",
                    "model_conf": 111.02
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Braintree, MA",
            "slug": "6f3e5de3-8d3d-4071-9fc9-72e28a16202d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/30/22",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/braintree/250-granite-street/",
        "index": 103.375,
        "franchise-entity-name": "Braintree Fitness LLC"
    },
    "9133e5d1-3f9c-4b1f-b9a3-a640b5bc6518": {
        "name": "Orange Theory University Park, FL",
        "address": "5275 University Parkway, Unit #116 Brandenton, Florida 34201",
        "phone": "(941) 444-2435",
        "email": "studiomanager0087@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            27.3905335,
            -82.465851
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.91,
                    "duration": "indefinitely",
                    "model_conf": 152.22
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.58,
                    "duration": "4 Months",
                    "model_conf": 182.85
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.33,
                    "duration": "indefinitely",
                    "model_conf": 108.91
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "University Park, FL",
            "slug": "9133e5d1-3f9c-4b1f-b9a3-a640b5bc6518"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/29/13",
        "url": "https://www.orangetheory.com/en-us/locations/florida/brandenton/5275-university-parkway-unit-116/",
        "index": 98.425,
        "franchise-entity-name": "U. P Fitness Express LLC"
    },
    "18c598cf-32cf-4ade-86a7-e339c3bcf030": {
        "name": "Orange Theory Brandon, FL",
        "address": "2052 Badlands St Brandon, Florida 33511",
        "phone": "(813) 774-3800",
        "email": "studiomanager0195@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            27.9274178,
            -82.31633
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.65,
                    "duration": "indefinitely",
                    "model_conf": 152.98
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.97,
                    "duration": "3 Months",
                    "model_conf": 181.32
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.37,
                    "duration": "indefinitely",
                    "model_conf": 111.24
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Brandon, FL",
            "slug": "18c598cf-32cf-4ade-86a7-e339c3bcf030"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/29/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/brandon/2052-badlands-st/",
        "index": 98.425,
        "franchise-entity-name": "BRANDON COACHING, LLC"
    },
    "41d7bd23-2f7b-41fc-8217-8f37214b7e3e": {
        "name": "Orange Theory Brea, CA",
        "address": "3461 E. Imperial Highway Brea, California 92823",
        "phone": "(714) 510-5100",
        "email": "studiomanager0251@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:45AM - 12:55PM",
            "SUNDAY": "6:45AM - 12:55PM"
        },
        "geolocation": [
            33.9079399,
            -117.84982
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.52,
                    "duration": "indefinitely",
                    "model_conf": 147.41
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.3,
                    "duration": "2 Months",
                    "model_conf": 158.45
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.44,
                    "duration": "indefinitely",
                    "model_conf": 93.2
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Brea, CA",
            "slug": "41d7bd23-2f7b-41fc-8217-8f37214b7e3e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/12/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/brea/3461-e-imperial-highway/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT NC, LLC"
    },
    "04b93609-667b-44b7-bdfe-ac741fa2fef4": {
        "name": "Orange Theory Brecksville, OH",
        "address": "8259 Chippewa Road Brecksville, Ohio 44141",
        "phone": "(440) 550-4614",
        "email": "studiomanager0619@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:15PM",
            "SUNDAY": "6:30AM - 12:15PM"
        },
        "geolocation": [
            41.3207245,
            -81.624306
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.98,
                    "duration": "5 Months",
                    "model_conf": 153.44
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.5,
                    "duration": "indefinitely",
                    "model_conf": 185.47
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.29,
                    "duration": "1 Months",
                    "model_conf": 111.58
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Brecksville, OH",
            "slug": "04b93609-667b-44b7-bdfe-ac741fa2fef4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/21/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/brecksville/8259-chippewa-road/",
        "index": 93.4,
        "franchise-entity-name": "KSA9 LLC"
    },
    "2fafc95b-a5de-48f4-ac37-fb054fbdf84a": {
        "name": "Orange Theory Brentwood - Norcal, CA",
        "address": "2565 Sand Creek Road, Suite 132 Brentwood, California 94513",
        "phone": "(925) 418-4015",
        "email": "studiomanager0411@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            37.9461594,
            -121.73721
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.04,
                    "duration": "indefinitely",
                    "model_conf": 152.73
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.99,
                    "duration": "indefinitely",
                    "model_conf": 184.92
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.89,
                    "duration": "indefinitely",
                    "model_conf": 111.22
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Brentwood - Norcal, CA",
            "slug": "2fafc95b-a5de-48f4-ac37-fb054fbdf84a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/13/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/brentwood/2565-sand-creek-road-suite-132/",
        "index": 98.425,
        "franchise-entity-name": "ROCK STAR FITNESS LLC"
    },
    "1bf6dd42-421b-4c56-a0d4-2073e95faecf": {
        "name": "Orange Theory Nolensville, TN",
        "address": "1010 Gant Hill Drive Brentwood, Tennessee 37027",
        "phone": "(615) 647-8901",
        "email": "studiomanager1348@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "6:15AM - 12:15PM",
            "SUNDAY": "6:15AM - 12:15PM"
        },
        "geolocation": [
            35.993187,
            -86.68389
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.96,
                    "duration": "5 Months",
                    "model_conf": 152.6
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.45,
                    "duration": "indefinitely",
                    "model_conf": 183.53
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.04,
                    "duration": "2 Months",
                    "model_conf": 111.17
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Nolensville, TN",
            "slug": "1bf6dd42-421b-4c56-a0d4-2073e95faecf"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/24/22",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/brentwood/1010-gant-hill-drive0/",
        "index": 93.4,
        "franchise-entity-name": "Ultra Fit LLC"
    },
    "403b04a0-19b2-4625-8776-c23b112b0c83": {
        "name": "Orange Theory Brentwood, TN",
        "address": "690 Old Hickory Blvd. Suite 102 Brentwood, Tennessee 37207",
        "phone": "(615) 373-0333",
        "email": "studiomanager0143@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "6:00AM - 4:45PM",
            "SUNDAY": "6:00AM - 4:45PM"
        },
        "geolocation": [
            36.0409164,
            -86.770004
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.31,
                    "duration": "indefinitely",
                    "model_conf": 148.72
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.09,
                    "duration": "indefinitely",
                    "model_conf": 174.82
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 170.22,
                    "duration": "indefinitely",
                    "model_conf": 110.96
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Brentwood, TN",
            "slug": "403b04a0-19b2-4625-8776-c23b112b0c83"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/9/14",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/brentwood/690-old-hickory-blvd-suite-102/",
        "index": 93.4,
        "franchise-entity-name": "SEAFIT LLC"
    },
    "598b9ffa-6f1f-4bb3-b762-961bc51e2655": {
        "name": "Orange Theory Brick, NJ",
        "address": "744 NJ-70 Brick Township, New Jersey 08723",
        "phone": "(732) 640-5480",
        "email": "studiomanager0717@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:45PM",
            "SUNDAY": "6:00AM - 12:45PM"
        },
        "geolocation": [
            40.0624199,
            -74.143394
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.29,
                    "duration": "4 Months",
                    "model_conf": 153.61
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.65,
                    "duration": "indefinitely",
                    "model_conf": 185.58
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.8,
                    "duration": "indefinitely",
                    "model_conf": 111.88
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Brick, NJ",
            "slug": "598b9ffa-6f1f-4bb3-b762-961bc51e2655"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/4/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/brick-township/744-nj-70/",
        "index": 93.4,
        "franchise-entity-name": "HHH Fitness One LLC"
    },
    "211f3150-3127-4467-a41c-1eb4c38e4690": {
        "name": "Orange Theory Brickell, FL",
        "address": "800 Brickell Ave Suite 202 Brickell, Florida 33131",
        "phone": "(786) 558-4422",
        "email": "studiomanager1570@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            25.765954,
            -80.191765
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 101.18,
                    "duration": "indefinitely",
                    "model_conf": 143.27
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 136.32,
                    "duration": "7 Months",
                    "model_conf": 181.33
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 200.57,
                    "duration": "indefinitely",
                    "model_conf": 103.29
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Brickell, FL",
            "slug": "211f3150-3127-4467-a41c-1eb4c38e4690"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/26/23",
        "url": "https://www.orangetheory.com/en-us/locations/florida/brickell/800-brickell-ave-suite-202",
        "index": 108.325,
        "franchise-entity-name": "BRICKELL ELITE ATHLETICS LLC"
    },
    "80ffe0e6-6ffa-4601-8a9d-4aca989fee89": {
        "name": "Orange Theory Bridgeport, WV",
        "address": "309 S VIRGINIA AVE Bridgeport, West Virginia 26330",
        "phone": "(304) 982-7333",
        "email": "studiomanager1551@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 12:00PM",
            "SUNDAY": "8:00AM - 12:00PM"
        },
        "geolocation": [
            39.281848,
            -80.25633
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.0
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.83
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.1
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Bridgeport, WV",
            "slug": "80ffe0e6-6ffa-4601-8a9d-4aca989fee89"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/22/23",
        "url": "https://www.orangetheory.com/en-us/locations/west-virginia/bridgeport/309-south-virginia-ave/",
        "index": 93.4,
        "franchise-entity-name": "HM FITNESS II, LLC"
    },
    "f53bdb48-8d63-401b-a8fa-d22855b7ce0b": {
        "name": "Orange Theory Brighton, MA",
        "address": "375 Market Street Brighton, Massachusetts 02135",
        "phone": "(617) 208-8378",
        "email": "studiomanager0135@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            42.3505859,
            -71.152664
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.13,
                    "duration": "3 Months",
                    "model_conf": 145.85
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 182.38
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.19,
                    "duration": "1 Months",
                    "model_conf": 110.51
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Brighton, MA",
            "slug": "f53bdb48-8d63-401b-a8fa-d22855b7ce0b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/24/14",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/brighton/375-market-street/",
        "index": 103.375,
        "franchise-entity-name": "BISH ONE MERGE LLC"
    },
    "3631646e-8e52-4761-9da4-f47be5a23586": {
        "name": "Orange Theory Brighton",
        "address": "8491 West Grand River Brighton, Michigan 48116",
        "phone": "(810) 360-2448",
        "email": "studiomanager0911@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            42.5434303,
            -83.787949
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.14,
                    "duration": "indefinitely",
                    "model_conf": 153.7
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.9,
                    "duration": "indefinitely",
                    "model_conf": 185.47
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.18,
                    "duration": "indefinitely",
                    "model_conf": 111.98
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Brighton",
            "slug": "3631646e-8e52-4761-9da4-f47be5a23586"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/brighton/8491-west-grand-river/",
        "index": 98.425,
        "franchise-entity-name": "Brighton 277 LLC"
    },
    "7f505351-80dd-409a-ad99-c70028cea30f": {
        "name": "Orange Theory Bristow, VA",
        "address": "10295 Bristow Center Drive Bristow, Virginia 20136",
        "phone": "(571) 249-2511",
        "email": "studiomanager0921@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            38.7341805,
            -77.549034
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.87,
                    "duration": "indefinitely",
                    "model_conf": 149.23
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.0,
                    "duration": "indefinitely",
                    "model_conf": 179.87
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.57,
                    "duration": "indefinitely",
                    "model_conf": 109.73
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Bristow, VA",
            "slug": "7f505351-80dd-409a-ad99-c70028cea30f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/30/18",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/bristow/10295-bristow-center-drive/",
        "index": 98.425,
        "franchise-entity-name": "G3 FITNESS GROUP III, LLC"
    },
    "07d6c6a1-9ff7-4833-89ab-e042eeceac37": {
        "name": "Orange Theory Brookfield, WI",
        "address": "95 N. Moorland Rd. Unit F10 Brookfield, Wisconsin 53005",
        "phone": "(262) 439-8402",
        "email": "studiomanager0095@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "5:45AM - 11:45AM",
            "SUNDAY": "5:45AM - 11:45AM"
        },
        "geolocation": [
            43.0309258,
            -88.108818
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.25,
                    "duration": "4 Months",
                    "model_conf": 152.47
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.38,
                    "duration": "indefinitely",
                    "model_conf": 180.36
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.47,
                    "duration": "1 Months",
                    "model_conf": 108.48
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Brookfield, WI",
            "slug": "07d6c6a1-9ff7-4833-89ab-e042eeceac37"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/2/14",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/brookfield/95-n-moorland-rd-unit-f10/",
        "index": 93.4,
        "franchise-entity-name": "OT WISCONSIN BROOKFIELD, LLC"
    },
    "8576f2b7-f9fa-413c-bc6e-bfd89ec19e5a": {
        "name": "Orange Theory Brooklyn Park, MN",
        "address": "9774 Schreiber Terrace Brooklyn Park, Minnesota 55445",
        "phone": "(763) 280-5114",
        "email": "studiomanager1434@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            45.133947,
            -93.357827
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.44,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 57.73,
                    "duration": "8 Months",
                    "model_conf": 151.38
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.45,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.19,
                    "duration": "indefinitely",
                    "model_conf": 183.63
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.96,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.58,
                    "duration": "1 Months",
                    "model_conf": 110.18
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Brooklyn Park, MN",
            "slug": "8576f2b7-f9fa-413c-bc6e-bfd89ec19e5a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/23/23",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/brooklyn-park/9774-schreiber-ter/",
        "index": 88.45,
        "franchise-entity-name": "Champlin Brooklyn Park Fitness, LLC"
    },
    "78fce473-fa58-465b-9362-032ccdedb83e": {
        "name": "Orange Theory Brooklyn - Williamsburg, NY",
        "address": "157 Kent Avenue Brooklyn, New York 11249",
        "phone": "(917) 201-7014",
        "email": "studiomanager0269@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 9:15PM",
            "TUESDAY": "6:00AM - 9:15PM",
            "WEDNESDAY": "6:00AM - 9:15PM",
            "THURSDAY": "6:00AM - 9:15PM",
            "FRIDAY": "6:00AM - 9:15PM",
            "SATURDAY": "8:00AM - 2:35PM",
            "SUNDAY": "8:00AM - 2:35PM"
        },
        "geolocation": [
            40.7186394,
            -73.963448
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.83,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.86,
                    "duration": "indefinitely",
                    "model_conf": 95.58
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.78,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.73,
                    "duration": "indefinitely",
                    "model_conf": 115.28
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 9.7,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 201.03,
                    "duration": "indefinitely",
                    "model_conf": 71.27
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 12.19,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 21.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 29.2,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Brooklyn - Williamsburg, NY",
            "slug": "78fce473-fa58-465b-9362-032ccdedb83e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/4/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/brooklyn/157-kent-avenue/",
        "index": 108.325,
        "franchise-entity-name": "92 FITNESS CREW NEW YORK 2, LLC"
    },
    "07718da1-4259-4639-9c1b-ed9b11aab3b0": {
        "name": "Orange Theory Brooklyn Heights, NY",
        "address": "186 Montague Street, 2nd Floor Brooklyn, New York 11201",
        "phone": "(917) 201-7013",
        "email": "studiomanager0546@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:15PM",
            "TUESDAY": "6:00AM - 8:15PM",
            "WEDNESDAY": "6:00AM - 8:15PM",
            "THURSDAY": "6:00AM - 8:15PM",
            "FRIDAY": "6:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            40.6938438,
            -73.991669
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.8,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 120.68,
                    "duration": "indefinitely",
                    "model_conf": 113.12
                }
            },
            "Elite": {
                "cost": 189,
                "tax-amount": 9.21,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 186.78,
                    "duration": "4 Months",
                    "model_conf": 134.84
                }
            },
            "Premier": {
                "cost": 249,
                "tax-amount": 12.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 250.31,
                    "duration": "indefinitely",
                    "model_conf": 82.35
                }
            }
        },
        "services": {
            "10 Session Pack - $290": {
                "cost": 290,
                "tax-amount": 14.14,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $550": {
                "cost": 550,
                "tax-amount": 26.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $790": {
                "cost": 790,
                "tax-amount": 38.51,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 32,
            "late-cancel-2": 16,
            "10-pack": 290,
            "20-pack": 550,
            "premier-family-addon-6-month-corp-2": 239,
            "surcharge-2": 10,
            "30-pack": 790,
            "premier": 249,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 189,
            "additional-session-2": 24,
            "name": "Brooklyn Heights, NY",
            "slug": "07718da1-4259-4639-9c1b-ed9b11aab3b0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/5/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/brooklyn/186-montague-street-2nd-floor/",
        "index": 127.375,
        "franchise-entity-name": "92 FITNESS CREW NEW YORK 3, LLC"
    },
    "7a874500-f470-4a55-b358-4bc94a825fb8": {
        "name": "Orange Theory Brooklyn - Park Slope, NY",
        "address": "248 Flatbush Avenue Brooklyn, New York 11217",
        "phone": "(718) 475-9452",
        "email": "studiomanager0218@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:15PM",
            "TUESDAY": "6:00AM - 8:15PM",
            "WEDNESDAY": "6:00AM - 8:15PM",
            "THURSDAY": "6:00AM - 8:15PM",
            "FRIDAY": "6:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 2:25PM",
            "SUNDAY": "7:00AM - 2:25PM"
        },
        "geolocation": [
            40.6794891,
            -73.974548
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.8,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 121.89,
                    "duration": "indefinitely",
                    "model_conf": 120.37
                }
            },
            "Elite": {
                "cost": 189,
                "tax-amount": 9.21,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 186.24,
                    "duration": "5 Months",
                    "model_conf": 142.85
                }
            },
            "Premier": {
                "cost": 249,
                "tax-amount": 12.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 250.91,
                    "duration": "indefinitely",
                    "model_conf": 87.09
                }
            }
        },
        "services": {
            "10 Session Pack - $290": {
                "cost": 290,
                "tax-amount": 14.14,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $550": {
                "cost": 550,
                "tax-amount": 26.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $790": {
                "cost": 790,
                "tax-amount": 38.51,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 32,
            "late-cancel-2": 16,
            "10-pack": 290,
            "20-pack": 550,
            "premier-family-addon-6-month-corp-2": 239,
            "surcharge-2": 10,
            "30-pack": 790,
            "premier": 249,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 189,
            "additional-session-2": 24,
            "name": "Brooklyn - Park Slope, NY",
            "slug": "7a874500-f470-4a55-b358-4bc94a825fb8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/13/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/brooklyn/248-flatbush-avenue/",
        "index": 127.375,
        "franchise-entity-name": "92 FITNESS CREW NEW YORK 1, LLC"
    },
    "bfe68c2c-fb76-45d4-bd14-050a5406bb5f": {
        "name": "Orange Theory Brooklyn-Midwood, NY",
        "address": "485 Kings Hwy Brooklyn, New York 11223",
        "phone": "(347) 696-8544",
        "email": "studiomanager1174@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:15PM",
            "TUESDAY": "5:00AM - 9:15PM",
            "WEDNESDAY": "5:00AM - 9:15PM",
            "THURSDAY": "5:00AM - 9:15PM",
            "FRIDAY": "5:00AM - 9:15PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            40.6044998,
            -73.971527
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.83,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 100.42,
                    "duration": "indefinitely",
                    "model_conf": 149.0
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.78,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 137.22,
                    "duration": "4 Months",
                    "model_conf": 179.18
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 9.7,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.46,
                    "duration": "indefinitely",
                    "model_conf": 108.43
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 12.19,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 21.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 29.2,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Brooklyn-Midwood, NY",
            "slug": "bfe68c2c-fb76-45d4-bd14-050a5406bb5f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/31/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/brooklyn/485-kings-hwy/",
        "index": 108.325,
        "franchise-entity-name": "OTB2NY, LLC"
    },
    "ac866235-7598-49b0-98ad-43b1d0d44894": {
        "name": "Orange Theory Broomfield, CO",
        "address": "1285 E 1ST AVENUE Broomfield, Colorado 80020",
        "phone": "(303) 469-4019",
        "email": "studiomanager0071@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.9154091,
            -105.05496
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.58,
                    "duration": "2 Months",
                    "model_conf": 149.13
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.5,
                    "duration": "indefinitely",
                    "model_conf": 182.42
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.75,
                    "duration": "indefinitely",
                    "model_conf": 110.05
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Broomfield, CO",
            "slug": "ac866235-7598-49b0-98ad-43b1d0d44894"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/14/13",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/broomfield/1285-e-1st-avenue/",
        "index": 98.425,
        "franchise-entity-name": "OTF BROOMFIELD, INC."
    },
    "0da2855d-ff9f-4191-8d64-67a6c6ce3b2d": {
        "name": "Orange Theory Brownsboro, KY",
        "address": "4164 Summit Plaza Drive Brownsboro, Kentucky 40241",
        "phone": "(502) 333-9310",
        "email": "studiomanager1232@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 1:30AM",
            "SUNDAY": "6:30AM - 1:30AM"
        },
        "geolocation": [
            38.3075409,
            -85.577576
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 4.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.58,
                    "duration": "2 Months",
                    "model_conf": 133.02
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 6.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.82,
                    "duration": "indefinitely",
                    "model_conf": 172.32
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.99,
                    "duration": "2 Months",
                    "model_conf": 111.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Brownsboro, KY",
            "slug": "0da2855d-ff9f-4191-8d64-67a6c6ce3b2d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/26/19",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/brownsboro/4164-summit-plaza-drive/",
        "index": 93.4,
        "franchise-entity-name": "DAVIS FITNESS BROWNSBORO INC."
    },
    "ad886357-8516-4719-85c1-57563ebfbfef": {
        "name": "Orange Theory Brownsville, TX",
        "address": "3230 Pablo Kisel Blvd, Suite E-109 and E110 Brownsville, Texas 78520",
        "phone": "(956) 443-0206",
        "email": "studiomanager0967@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 2:00PM",
            "SUNDAY": "6:15AM - 2:00PM"
        },
        "geolocation": [
            25.9641399,
            -97.503883
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.11,
                    "duration": "indefinitely",
                    "model_conf": 152.7
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.21,
                    "duration": "indefinitely",
                    "model_conf": 185.72
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.97
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Brownsville, TX",
            "slug": "ad886357-8516-4719-85c1-57563ebfbfef"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/brownsville/3230-pablo-kisel-blvd-suite-e-109-and-e110/",
        "index": 93.4,
        "franchise-entity-name": "REGULUS FITNESS, LLC"
    },
    "bdefcf3e-b1ce-4707-aa0f-b0b84e844ecd": {
        "name": "Orange Theory Bryant, AR",
        "address": "7357 Alcoa Rd. Bryant, Arkansas 72202",
        "phone": "(501) 758-5656",
        "email": "studiomanager1146@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:15AM - 4:30PM",
            "SUNDAY": "8:15AM - 4:30PM"
        },
        "geolocation": [
            34.6040459,
            -92.529854
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.41
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.19,
                    "duration": "indefinitely",
                    "model_conf": 185.75
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.11,
                    "duration": "indefinitely",
                    "model_conf": 111.77
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Bryant, AR",
            "slug": "bdefcf3e-b1ce-4707-aa0f-b0b84e844ecd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/arkansas/bryant/7357-alcoa-rd/",
        "index": 93.4,
        "franchise-entity-name": "AR HIIT, LLC"
    },
    "4b434510-0aba-40fc-8aef-e951d35cb62e": {
        "name": "Orange Theory Burbank",
        "address": "139 N. San Fernando Blvd Burbank, California 91502",
        "phone": "(818) 405-0009",
        "email": "studiomanager0307@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            34.1812515,
            -118.30964
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 100.0,
                    "duration": "indefinitely",
                    "model_conf": 152.21
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 147.9,
                    "duration": "2 Months",
                    "model_conf": 183.25
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.16,
                    "duration": "indefinitely",
                    "model_conf": 109.32
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Burbank",
            "slug": "4b434510-0aba-40fc-8aef-e951d35cb62e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/1/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/burbank/139-n-san-fernando-blvd/",
        "index": 111.325,
        "franchise-entity-name": "WCF WEST LA LLC"
    },
    "2fa5d86b-06f3-4422-901b-90ee74aab54c": {
        "name": "Orange Theory Burke, VA",
        "address": "9534 Old Keene Mill Road Burke, Virginia 22015",
        "phone": "(703) 559-8880",
        "email": "studiomanager0797@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            38.7772713,
            -77.274765
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.86,
                    "duration": "indefinitely",
                    "model_conf": 140.06
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.82,
                    "duration": "3 Months",
                    "model_conf": 163.79
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.68,
                    "duration": "indefinitely",
                    "model_conf": 100.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Burke, VA",
            "slug": "2fa5d86b-06f3-4422-901b-90ee74aab54c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/21/21",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/burke/9534-old-keene-mill-road/",
        "index": 98.425,
        "franchise-entity-name": "NCHNOVA II LLC"
    },
    "dc746423-90d8-436f-af9c-3fd712ec48ff": {
        "name": "Orange Theory Burleson, TX",
        "address": "1561 SW Wilshire Blvd, Suite 405 Burleson, Texas 76028",
        "phone": "(682) 350-8242",
        "email": "studiomanager0963@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            32.5182381,
            -97.348892
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.54,
                    "duration": "2 Months",
                    "model_conf": 153.19
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.14,
                    "duration": "indefinitely",
                    "model_conf": 185.06
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.74,
                    "duration": "indefinitely",
                    "model_conf": 112.06
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Burleson, TX",
            "slug": "dc746423-90d8-436f-af9c-3fd712ec48ff"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/15/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/burleson/1561-sw-wilshire-blvd-suite-405/",
        "index": 93.4,
        "franchise-entity-name": "K&amp;M FITNESS VI, LLC"
    },
    "3a004d23-bd6f-4533-aedf-177fa3e4500b": {
        "name": "Orange Theory Burlington, MA",
        "address": "112 Mall Road Burlington, Massachusetts 01803",
        "phone": "(781) 552-3838",
        "email": "studiomanager0524@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            42.4854622,
            -71.21891
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.5,
                    "duration": "indefinitely",
                    "model_conf": 151.13
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.27,
                    "duration": "2 Months",
                    "model_conf": 182.53
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.55,
                    "duration": "indefinitely",
                    "model_conf": 110.49
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Burlington, MA",
            "slug": "3a004d23-bd6f-4533-aedf-177fa3e4500b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/burlington/112-mall-road/",
        "index": 103.375,
        "franchise-entity-name": "BURLINGTON FITNESS ACQUISITION, LLC"
    },
    "2b3c4728-911f-4dc8-b914-69067b0b9e7f": {
        "name": "Orange Theory Burlington, VT",
        "address": "580 Shelburne Rd. Burlington, Vermont 05401",
        "phone": "(802) 444-0181",
        "email": "studiomanager1060@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:45AM - 2:00PM",
            "SUNDAY": "6:45AM - 2:00PM"
        },
        "geolocation": [
            44.4487648,
            -73.208092
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.91,
                    "duration": "indefinitely",
                    "model_conf": 152.46
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.28,
                    "duration": "indefinitely",
                    "model_conf": 185.01
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.87,
                    "duration": "indefinitely",
                    "model_conf": 110.55
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Burlington, VT",
            "slug": "2b3c4728-911f-4dc8-b914-69067b0b9e7f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/15/18",
        "url": "https://www.orangetheory.com/en-us/locations/vermont/burlington/580-shelburne-rd/",
        "index": 98.425,
        "franchise-entity-name": "ORIOLE I, LLC"
    },
    "3fbcf6a0-0b25-4f0c-9429-0dac66d4d3b0": {
        "name": "Orange Theory Burnsville, MN",
        "address": "1008 County Rd 42 W Burnsville, Minnesota 55337",
        "phone": "(952) 277-1100",
        "email": "studiomanager0961@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            44.7472,
            -93.292534
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.2,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 57.53,
                    "duration": "9 Months",
                    "model_conf": 149.43
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.05,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 100.42,
                    "duration": "indefinitely",
                    "model_conf": 182.22
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.33,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.14,
                    "duration": "2 Months",
                    "model_conf": 109.16
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.18,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 25.58,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 35.55,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Burnsville, MN",
            "slug": "3fbcf6a0-0b25-4f0c-9429-0dac66d4d3b0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/2/18",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/burnsville/1008-county-rd-42-w/",
        "index": 88.45,
        "franchise-entity-name": "BURNSVILLE FITNESS, LLC"
    },
    "281104c8-3948-4583-a998-e87439c3b2f5": {
        "name": "Orange Theory Agoura Hills - Calabasas",
        "address": "5741 Kanan Road Calabasas Agoura, California 91301",
        "phone": "(818) 991-1020",
        "email": "studiomanager0413@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 6:30PM",
            "TUESDAY": "6:15AM - 6:30PM",
            "WEDNESDAY": "6:15AM - 6:30PM",
            "THURSDAY": "6:15AM - 6:30PM",
            "FRIDAY": "6:15AM - 6:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.1557007,
            -118.75798
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.32,
                    "duration": "2 Months",
                    "model_conf": 152.98
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.71,
                    "duration": "indefinitely",
                    "model_conf": 185.29
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.27,
                    "duration": "1 Months",
                    "model_conf": 111.97
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Agoura Hills - Calabasas",
            "slug": "281104c8-3948-4583-a998-e87439c3b2f5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/5/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/calabasas-agoura/5741-kanan-road/",
        "index": 103.375,
        "franchise-entity-name": "LA BIRER GROUP, INC."
    },
    "242b6ccb-f78f-4152-aaa3-3b1e6c0033e6": {
        "name": "Orange Theory Canton Holly Springs, Ga",
        "address": "3640 Marietta Hwy Canton, Georgia 30114",
        "phone": "(470) 592-0098",
        "email": "studiomanager0496@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            34.1972694,
            -84.509613
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.73,
                    "duration": "indefinitely",
                    "model_conf": 151.41
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.65,
                    "duration": "4 Months",
                    "model_conf": 185.05
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.05,
                    "duration": "indefinitely",
                    "model_conf": 110.97
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Canton Holly Springs, Ga",
            "slug": "242b6ccb-f78f-4152-aaa3-3b1e6c0033e6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/8/17",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/canton/3640-marietta-hwy/",
        "index": 98.425,
        "franchise-entity-name": "JW CANTON, LLC"
    },
    "5b05fbce-ff4d-4304-a1a1-a74789ecc38f": {
        "name": "Orange Theory Cedar Park, TX",
        "address": "5001 183A Toll Road Suite L100 Cedar Park, Texas 78613",
        "phone": "(512) 807-0402",
        "email": "studiomanager0749@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            30.5272274,
            -97.819023
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.44,
                    "duration": "2 Months",
                    "model_conf": 143.75
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.86,
                    "duration": "indefinitely",
                    "model_conf": 167.94
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.57,
                    "duration": "indefinitely",
                    "model_conf": 108.68
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Cedar Park, TX",
            "slug": "5b05fbce-ff4d-4304-a1a1-a74789ecc38f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/9/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/cedar-park/5001-183a-toll-road-suite-l100/",
        "index": 108.325,
        "franchise-entity-name": "CEDAR PARK FITNESS PARTNERS, LLC"
    },
    "8ddf88bd-574c-4acc-82ce-991bc875b5d9": {
        "name": "Orange Theory Chicago - Lakeview, IL",
        "address": "3010 N. Ashland Ave Chicago, Illinois 60657",
        "phone": "(312) 778-8830",
        "email": "studiomanager0304@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "8:30AM - 12:00PM",
            "SUNDAY": "8:30AM - 12:00PM"
        },
        "geolocation": [
            41.936264,
            -87.669487
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 120.24,
                    "duration": "indefinitely",
                    "model_conf": 95.4
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 157.55,
                    "duration": "3 Months",
                    "model_conf": 115.07
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.85,
                    "duration": "indefinitely",
                    "model_conf": 71.83
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago - Lakeview, IL",
            "slug": "8ddf88bd-574c-4acc-82ce-991bc875b5d9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/13/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/3010-n-ashland-ave/",
        "index": 118.225,
        "franchise-entity-name": "PERLOFF PROVIDENCE STUDIO #2 LLC"
    },
    "45c683c7-0720-4fb7-a576-c6d2e66d8472": {
        "name": "Orange Theory Columbia, MO",
        "address": "3103 W. Broadway, #115 Columbia, Missouri 65203",
        "phone": "(573) 381-0388",
        "email": "studiomanager0640@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            38.955677,
            -92.383751
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.09,
                    "duration": "indefinitely",
                    "model_conf": 153.25
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.89,
                    "duration": "indefinitely",
                    "model_conf": 185.66
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.88,
                    "duration": "indefinitely",
                    "model_conf": 112.0
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Columbia, MO",
            "slug": "45c683c7-0720-4fb7-a576-c6d2e66d8472"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/18/18",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/columbia/3103-w-broadway-115/",
        "index": 93.4,
        "franchise-entity-name": "ALMR TWO, LLC"
    },
    "e2e0d6d6-2cca-4d9d-a778-31944d4728c8": {
        "name": "Orange Theory Kendall Square, MA",
        "address": "270 Third Street Cambridge, Massachusetts 02142",
        "phone": "(617) 714-5280",
        "email": "studiomanager0780@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            42.3660278,
            -71.082008
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.91,
                    "duration": "indefinitely",
                    "model_conf": 145.95
                }
            },
            "Elite": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 168.6,
                    "duration": "indefinitely",
                    "model_conf": 181.13
                }
            },
            "Premier": {
                "cost": 229,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 230.21,
                    "duration": "indefinitely",
                    "model_conf": 109.68
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 219,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 229,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 159,
            "elite": 169,
            "additional-session-2": 22,
            "name": "Kendall Square, MA",
            "slug": "e2e0d6d6-2cca-4d9d-a778-31944d4728c8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/1/16",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/cambridge/270-third-street/",
        "index": 121.225,
        "franchise-entity-name": "BISH THREE LLC"
    },
    "b47e9ea7-d9e8-4c76-8151-25fd632213e5": {
        "name": "Orange Theory Campbell, CA",
        "address": "1725 S Bascom Ave, Suite 1150 Campbell, California 95008",
        "phone": "(408) 684-8662",
        "email": "studiomanager0123@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 8:45PM",
            "TUESDAY": "6:15AM - 8:45PM",
            "WEDNESDAY": "6:15AM - 8:45PM",
            "THURSDAY": "6:15AM - 8:45PM",
            "FRIDAY": "6:15AM - 8:45PM",
            "SATURDAY": "7:30AM - 10:50PM",
            "SUNDAY": "7:30AM - 10:50PM"
        },
        "geolocation": [
            37.2917938,
            -121.93262
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.17,
                    "duration": "indefinitely",
                    "model_conf": 153.24
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.05,
                    "duration": "indefinitely",
                    "model_conf": 171.61
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.72,
                    "duration": "indefinitely",
                    "model_conf": 110.76
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Campbell, CA",
            "slug": "b47e9ea7-d9e8-4c76-8151-25fd632213e5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/22/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/campbell/1725-s-bascom-ave/",
        "index": 111.325,
        "franchise-entity-name": "CAMPBELL FITNESS, LLC"
    },
    "e2e6dcf8-0343-48d2-a15e-bfd157210039": {
        "name": "Orange Theory Camarillo, CA",
        "address": "1656-A Arneill Road Camrarillo, California 93010",
        "phone": "(805) 764-1515",
        "email": "studiomanager0555@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            34.2356491,
            -119.03869
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.8,
                    "duration": "indefinitely",
                    "model_conf": 151.62
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.62,
                    "duration": "indefinitely",
                    "model_conf": 183.68
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.54,
                    "duration": "indefinitely",
                    "model_conf": 111.51
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Camarillo, CA",
            "slug": "e2e6dcf8-0343-48d2-a15e-bfd157210039"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/camrarillo/1656-a-arneill-road/",
        "index": 103.375,
        "franchise-entity-name": "SOCALFIT, LLC"
    },
    "515e7e45-3c71-4329-b4b5-71c9dc262da0": {
        "name": "Orange Theory Canal Winchester, OH",
        "address": "6047 Gender Road, Canal Winchester, Ohio 43110",
        "phone": "(614) 254-5523",
        "email": "studiomanager0936@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 4:30PM",
            "SUNDAY": "6:30AM - 4:30PM"
        },
        "geolocation": [
            39.8571281,
            -82.828079
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.99,
                    "duration": "indefinitely",
                    "model_conf": 152.01
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.05,
                    "duration": "indefinitely",
                    "model_conf": 183.9
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.99,
                    "duration": "indefinitely",
                    "model_conf": 110.94
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Canal Winchester, OH",
            "slug": "515e7e45-3c71-4329-b4b5-71c9dc262da0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/31/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/canal-winchester/6047-gender-road/",
        "index": 93.4,
        "franchise-entity-name": "DEAN MCBURNEY COMPANIES LLC"
    },
    "df988f36-ce61-4b14-812a-02f7c3e3bd31": {
        "name": "Orange Theory Canton, MI",
        "address": "41818 Ford Road Canton, Michigan 48187",
        "phone": "(734) 844-6100",
        "email": "studiomanager0147@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 8:00PM",
            "TUESDAY": "4:45AM - 8:00PM",
            "WEDNESDAY": "4:45AM - 8:00PM",
            "THURSDAY": "4:45AM - 8:00PM",
            "FRIDAY": "4:45AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            42.3239594,
            -83.454132
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.04,
                    "duration": "9 Months",
                    "model_conf": 150.55
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 121.15,
                    "duration": "indefinitely",
                    "model_conf": 183.75
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.42,
                    "duration": "3 Months",
                    "model_conf": 109.2
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Canton, MI",
            "slug": "df988f36-ce61-4b14-812a-02f7c3e3bd31"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/25/14",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/canton/41818-ford-road/",
        "index": 98.425,
        "franchise-entity-name": "MBCS GROUP, LLC"
    },
    "fccf302f-4dd2-48b2-9a90-e7a35996e121": {
        "name": "Orange Theory Canton, OH",
        "address": "4342 Belden Village St NW Canton, Ohio 44718",
        "phone": "(330) 423-1733",
        "email": "studiomanager0673@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:00PM",
            "TUESDAY": "5:30AM - 8:00PM",
            "WEDNESDAY": "5:30AM - 8:00PM",
            "THURSDAY": "5:30AM - 8:00PM",
            "FRIDAY": "5:30AM - 8:00PM",
            "SATURDAY": "6:45AM - 11:45AM",
            "SUNDAY": "6:45AM - 11:45AM"
        },
        "geolocation": [
            40.8550797,
            -81.430038
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.68,
                    "duration": "1 Months",
                    "model_conf": 137.92
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.96,
                    "duration": "indefinitely",
                    "model_conf": 172.69
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.88,
                    "duration": "indefinitely",
                    "model_conf": 111.9
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Canton, OH",
            "slug": "fccf302f-4dd2-48b2-9a90-e7a35996e121"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/5/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/canton/4342-belden-village-st-nw/",
        "index": 93.4,
        "franchise-entity-name": "FIT FOR THE RUNWAY LLC"
    },
    "9615bacb-1b0a-4e0d-8ec6-2306a66d7608": {
        "name": "Orange Theory Cape Coral-Santa Barbara",
        "address": "2311 Santa Barbara Blvd Cape Coral, Florida 33991",
        "phone": "(239) 218-4644",
        "email": "studiomanager0284@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            26.6095676,
            -81.973572
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.97,
                    "duration": "indefinitely",
                    "model_conf": 149.09
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.3,
                    "duration": "indefinitely",
                    "model_conf": 179.75
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.83,
                    "duration": "indefinitely",
                    "model_conf": 111.22
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cape Coral-Santa Barbara",
            "slug": "9615bacb-1b0a-4e0d-8ec6-2306a66d7608"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/19",
        "url": "https://www.orangetheory.com/en-us/locations/florida/cape-coral/2311-santa-barbara-blvd/",
        "index": 98.425,
        "franchise-entity-name": "CAPE CORAL COACHING LLC"
    },
    "d95c9cb1-5436-491f-8148-0cf0b08a8b55": {
        "name": "Orange Theory Capitol Hill, DC",
        "address": "330 7th Street, SE. Capitol Hill, District Of Columbia 20003",
        "phone": "(202) 688-3161",
        "email": "studiomanager1097@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 6:30PM",
            "TUESDAY": "4:45AM - 6:30PM",
            "WEDNESDAY": "4:45AM - 6:30PM",
            "THURSDAY": "4:45AM - 6:30PM",
            "FRIDAY": "4:45AM - 6:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            38.8857231,
            -76.995918
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.01,
                    "duration": "indefinitely",
                    "model_conf": 111.26
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.42,
                    "duration": "1 Months",
                    "model_conf": 131.89
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.85,
                    "duration": "indefinitely",
                    "model_conf": 83.09
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Capitol Hill, DC",
            "slug": "d95c9cb1-5436-491f-8148-0cf0b08a8b55"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/24/18",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/capitol-hill/330-7th-street-se/",
        "index": 118.225,
        "franchise-entity-name": "HH FITNESS CAPITOL HILL, LLC"
    },
    "2eb79fa5-3370-4ec1-af11-5909b6bd72d5": {
        "name": "Orange Theory Capitola, CA",
        "address": "1501 41st Avenue, Suite N Capitola, California 95010",
        "phone": "(831) 359-4227",
        "email": "studiomanager0519@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:30PM",
            "TUESDAY": "6:15AM - 7:30PM",
            "WEDNESDAY": "6:15AM - 7:30PM",
            "THURSDAY": "6:15AM - 7:30PM",
            "FRIDAY": "6:15AM - 7:30PM",
            "SATURDAY": "8:15AM - 1:00PM",
            "SUNDAY": "8:15AM - 1:00PM"
        },
        "geolocation": [
            36.972332,
            -121.96705
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.03,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.09,
                    "duration": "2 Months",
                    "model_conf": 184.94
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.91,
                    "duration": "indefinitely",
                    "model_conf": 112.12
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Capitola, CA",
            "slug": "2eb79fa5-3370-4ec1-af11-5909b6bd72d5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/12/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/capitola/1501-41st-avenue-suite-n/",
        "index": 108.325,
        "franchise-entity-name": "CAPITOLA FITNESS LLC"
    },
    "443aff38-fabe-48d1-b270-49e84f877df4": {
        "name": "Orange Theory Carle Place, NY",
        "address": "211 Glen Cove Road Carle Place, New York 11514",
        "phone": "(516) 268-6272",
        "email": "studiomanager0146@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "6:30AM - 12:15PM",
            "SUNDAY": "6:30AM - 12:15PM"
        },
        "geolocation": [
            40.7472763,
            -73.616417
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 96.17,
                    "duration": "11 Months",
                    "model_conf": 103.07
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 141.95,
                    "duration": "indefinitely",
                    "model_conf": 125.05
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 196.95,
                    "duration": "4 Months",
                    "model_conf": 76.69
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Carle Place, NY",
            "slug": "443aff38-fabe-48d1-b270-49e84f877df4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/8/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/carle-place/211-glen-cove-road/",
        "index": 108.325,
        "franchise-entity-name": "ARDCORE, LLC"
    },
    "73b7aa51-953a-438e-b4e1-45fff7f681f8": {
        "name": "Orange Theory Carlsbad, CA",
        "address": "1820 Marron Road, Suite 104 Carlsbad, California 92008",
        "phone": "(760) 517-9090",
        "email": "studiomanager0117@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:50PM",
            "TUESDAY": "5:00AM - 6:50PM",
            "WEDNESDAY": "5:00AM - 6:50PM",
            "THURSDAY": "5:00AM - 6:50PM",
            "FRIDAY": "5:00AM - 6:50PM",
            "SATURDAY": "7:20AM - 11:50AM",
            "SUNDAY": "7:20AM - 11:50AM"
        },
        "geolocation": [
            33.177681,
            -117.33978
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.83,
                    "duration": "indefinitely",
                    "model_conf": 151.99
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.33,
                    "duration": "indefinitely",
                    "model_conf": 181.6
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.88,
                    "duration": "indefinitely",
                    "model_conf": 110.98
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Carlsbad, CA",
            "slug": "73b7aa51-953a-438e-b4e1-45fff7f681f8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/23/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/carlsbad/1820-marron-road-suite-104/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT5 LLC"
    },
    "3c3d39dc-49c4-4693-8487-a8abf5c62451": {
        "name": "Orange Theory La Costa, CA",
        "address": "6985 El Camino Real, Suite 101 Carlsbad, California 92009",
        "phone": "(760) 331-2610",
        "email": "studiomanager1106@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:20PM",
            "TUESDAY": "5:00AM - 7:20PM",
            "WEDNESDAY": "5:00AM - 7:20PM",
            "THURSDAY": "5:00AM - 7:20PM",
            "FRIDAY": "5:00AM - 7:20PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            33.1026611,
            -117.26747
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.11,
                    "duration": "indefinitely",
                    "model_conf": 148.98
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.72,
                    "duration": "3 Months",
                    "model_conf": 180.56
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 108.92
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "La Costa, CA",
            "slug": "3c3d39dc-49c4-4693-8487-a8abf5c62451"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/15/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/carlsbad/6985-el-camino-real-suite-101/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT10, LLC"
    },
    "69143d2f-c8e1-4c1b-87dc-3df0941b2ea2": {
        "name": "Orange Theory Carmel, IN",
        "address": "2438 E. 146th St Carmel, Indiana 46033",
        "phone": "(317) 688-1348",
        "email": "studiomanager0274@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:05AM - 2:30PM",
            "SUNDAY": "6:05AM - 2:30PM"
        },
        "geolocation": [
            40.0003204,
            -86.119186
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.84,
                    "duration": "indefinitely",
                    "model_conf": 127.43
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.2,
                    "duration": "2 Months",
                    "model_conf": 154.55
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.59,
                    "duration": "indefinitely",
                    "model_conf": 92.1
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Carmel, IN",
            "slug": "69143d2f-c8e1-4c1b-87dc-3df0941b2ea2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/24/15",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/carmel/2438-e-146th-st/",
        "index": 93.4,
        "franchise-entity-name": "OT INDIANA CARMEL, LLC"
    },
    "6e7f06c4-9ade-4131-9522-0b07240be954": {
        "name": "Orange Theory West Carmel, IN",
        "address": "4000 West 106th St. Suite 145 Carmel, Indiana 46032",
        "phone": "(317) 449-2100",
        "email": "studiomanager0372@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "6:45AM - 12:30PM",
            "SUNDAY": "6:45AM - 12:30PM"
        },
        "geolocation": [
            39.9417076,
            -86.234535
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.8,
                    "duration": "indefinitely",
                    "model_conf": 130.47
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.23,
                    "duration": "2 Months",
                    "model_conf": 157.32
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.85,
                    "duration": "indefinitely",
                    "model_conf": 95.31
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "West Carmel, IN",
            "slug": "6e7f06c4-9ade-4131-9522-0b07240be954"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/19/17",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/carmel/4000-west-106th-st-suite-145/",
        "index": 93.4,
        "franchise-entity-name": "OT INDIANA WEST CARMEL, LLC"
    },
    "b4e71289-4331-4d55-b0b2-1deee6a6c643": {
        "name": "Orange Theory Carmichael",
        "address": "4005 Manzanita Ave Carmichael, California 95608",
        "phone": "(916) 525-8333",
        "email": "studiomanager1372@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:30PM",
            "TUESDAY": "5:15AM - 7:30PM",
            "WEDNESDAY": "5:15AM - 7:30PM",
            "THURSDAY": "5:15AM - 7:30PM",
            "FRIDAY": "5:15AM - 7:30PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            38.637722,
            -121.33186
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.0,
                    "duration": "indefinitely",
                    "model_conf": 153.26
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.92,
                    "duration": "indefinitely",
                    "model_conf": 185.37
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.04,
                    "duration": "indefinitely",
                    "model_conf": 111.48
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Carmichael",
            "slug": "b4e71289-4331-4d55-b0b2-1deee6a6c643"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/30/21",
        "url": "https://www.orangetheory.com/en-us/locations/california/carmichael/4005-manzanita-avenue/",
        "index": 103.375,
        "franchise-entity-name": "The Capital of Cali Fitness, LLC"
    },
    "ef65bf52-f5e7-4835-8065-c192bd0b461e": {
        "name": "Orange Theory Carol Stream-Wheaton North, IL",
        "address": "547 S. Schmale Rd. Carol Stream, Illinois 60188",
        "phone": "(630) 402-6789",
        "email": "studiomanager1084@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45PM",
            "SUNDAY": "7:00AM - 11:45PM"
        },
        "geolocation": [
            41.888855,
            -88.104492
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.65,
                    "duration": "1 Months",
                    "model_conf": 141.73
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.96,
                    "duration": "3 Months",
                    "model_conf": 169.3
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.14,
                    "duration": "1 Months",
                    "model_conf": 103.36
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Carol Stream-Wheaton North, IL",
            "slug": "ef65bf52-f5e7-4835-8065-c192bd0b461e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/13/18",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/carol-stream/547-s-schmale-rd/",
        "index": 103.375,
        "franchise-entity-name": "WIN WHEAT FIT, INC."
    },
    "b3f53a63-52ae-41d7-9bdf-6f8f53d570d1": {
        "name": "Orange Theory Cary-MacGregor, NC",
        "address": "107 Edinburgh S Dr. Cary Apex, North Carolina 27511",
        "phone": "(919) 230-2544",
        "email": "studiomanager0769@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:15AM - 11:45AM",
            "SUNDAY": "7:15AM - 11:45AM"
        },
        "geolocation": [
            35.7367477,
            -78.79866
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.49,
                    "duration": "indefinitely",
                    "model_conf": 131.02
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.3,
                    "duration": "2 Months",
                    "model_conf": 157.48
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.24,
                    "duration": "indefinitely",
                    "model_conf": 97.22
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cary-MacGregor, NC",
            "slug": "b3f53a63-52ae-41d7-9bdf-6f8f53d570d1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/cary-apex/107-edinburgh-s-dr/",
        "index": 98.425,
        "franchise-entity-name": "JOELE FITNESS SIX, LLC"
    },
    "255320d3-94a6-4b72-8b2c-1095700e048f": {
        "name": "Orange Theory West Cary, NC",
        "address": "1204 Parkside Main Street Cary, North Carolina 27519",
        "phone": "(919) 234-7454",
        "email": "studiomanager0498@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            35.8484535,
            -78.890228
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.39,
                    "duration": "indefinitely",
                    "model_conf": 145.84
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.44,
                    "duration": "1 Months",
                    "model_conf": 174.79
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.07,
                    "duration": "indefinitely",
                    "model_conf": 106.65
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West Cary, NC",
            "slug": "255320d3-94a6-4b72-8b2c-1095700e048f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/cary/1204-parkside-main-street/",
        "index": 98.425,
        "franchise-entity-name": "JACKELLE FITNESS FOUR, LLC"
    },
    "9773e500-464f-4260-b6ba-d19a34feb81f": {
        "name": "Orange Theory Castle Rock, CO",
        "address": "3990 Limelight Avenue Unit F Castle Rock, Colorado 80109",
        "phone": "(303) 747-4003",
        "email": "studiomanager0504@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:15AM - 12:15PM",
            "SUNDAY": "6:15AM - 12:15PM"
        },
        "geolocation": [
            39.4029656,
            -104.88184
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.32,
                    "duration": "indefinitely",
                    "model_conf": 152.11
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.47,
                    "duration": "1 Months",
                    "model_conf": 183.83
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.24,
                    "duration": "indefinitely",
                    "model_conf": 109.59
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Castle Rock, CO",
            "slug": "9773e500-464f-4260-b6ba-d19a34feb81f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/27/16",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/castle-rock/3990-limelight-avenue-unit-f/",
        "index": 98.425,
        "franchise-entity-name": "DR CASTLE ROCK, LLC"
    },
    "db2a5fef-7f4e-4c84-aa7c-c3c3106dbe38": {
        "name": "Orange Theory Castro Valley",
        "address": "3642 Village Drive Castro Valley, California 94546",
        "phone": "(510) 244-1070",
        "email": "studiomanager1255@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            37.6973381,
            -122.07433
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.97,
                    "duration": "indefinitely",
                    "model_conf": 151.43
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.74,
                    "duration": "indefinitely",
                    "model_conf": 182.41
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.67,
                    "duration": "indefinitely",
                    "model_conf": 109.19
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Castro Valley",
            "slug": "db2a5fef-7f4e-4c84-aa7c-c3c3106dbe38"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/31/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/castro-valley/3642-village-drive/",
        "index": 108.325,
        "franchise-entity-name": "TFP CALIFORNIA FITNESS PARTNERS VI, LLC - 1255"
    },
    "7297c1e4-ed61-467d-8c48-07fb95e21ff4": {
        "name": "Orange Theory Roanoke, VA",
        "address": "5010 Keagy Rd Cave Spring, Virginia 24018",
        "phone": "(540) 300-7804",
        "email": "studiomanager0926@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            37.249115,
            -80.039291
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.34,
                    "duration": "4 Months",
                    "model_conf": 153.64
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.46,
                    "duration": "indefinitely",
                    "model_conf": 185.82
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.27,
                    "duration": "1 Months",
                    "model_conf": 111.9
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Roanoke, VA",
            "slug": "7297c1e4-ed61-467d-8c48-07fb95e21ff4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/16/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/cave-spring/5010-keagy-rd/",
        "index": 88.45,
        "franchise-entity-name": "2MD ROANOKE LLC"
    },
    "1d82b5c1-7b0b-4236-93b7-f78bc41ab9f2": {
        "name": "Orange Theory Cedar Falls, IA",
        "address": "936 Viking Rd., Suite 109 Cedar Falls, Iowa 50613",
        "phone": "(319) 449-6951",
        "email": "studiomanager1259@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "6:30AM - 10:15AM",
            "SUNDAY": "6:30AM - 10:15AM"
        },
        "geolocation": [
            42.4840546,
            -92.457001
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.75
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.71
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Cedar Falls, IA",
            "slug": "1d82b5c1-7b0b-4236-93b7-f78bc41ab9f2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/iowa/cedar-falls/936-viking-rd-suite-109/",
        "index": 93.4,
        "franchise-entity-name": "PANORAMA HOLDINGS, LLC"
    },
    "ebeb3639-5aa0-4939-99f1-2fbdb9d037a7": {
        "name": "Orange Theory Cedar Hill, TX",
        "address": "305 W FM 1382 #220 Cedar Hill, Texas 75104",
        "phone": "(214) 919-4191",
        "email": "studiomanager0690@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            32.6025658,
            -96.942131
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.0,
                    "duration": "indefinitely",
                    "model_conf": 148.87
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.78,
                    "duration": "4 Months",
                    "model_conf": 185.61
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.63,
                    "duration": "indefinitely",
                    "model_conf": 111.53
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Cedar Hill, TX",
            "slug": "ebeb3639-5aa0-4939-99f1-2fbdb9d037a7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/19/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/cedar-hill/305-w-fm-1382-220/",
        "index": 93.4,
        "franchise-entity-name": "KMM FITNESS II LLC"
    },
    "a39df043-3350-4412-a257-52ba88f545ab": {
        "name": "Orange Theory Cedar Rapids, IA",
        "address": "4824 1st Ave NE Cedar Rapids, Iowa 52402",
        "phone": "(319) 450-0665",
        "email": "studiomanager0814@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "6:30AM - 11:00AM",
            "SUNDAY": "6:30AM - 11:00AM"
        },
        "geolocation": [
            42.0273018,
            -91.620888
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.05,
                    "duration": "indefinitely",
                    "model_conf": 152.45
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.03,
                    "duration": "indefinitely",
                    "model_conf": 185.76
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.8,
                    "duration": "indefinitely",
                    "model_conf": 112.09
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Cedar Rapids, IA",
            "slug": "a39df043-3350-4412-a257-52ba88f545ab"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/iowa/cedar-rapids/4824-1st-ave-ne/",
        "index": 93.4,
        "franchise-entity-name": "IAFIT CR LLC"
    },
    "7a4557df-0d5f-464f-a2c5-19da846028b5": {
        "name": "Orange Theory Celebration, FL",
        "address": "49 Blake Boulevard Celebration, Florida 34747",
        "phone": "(407) 613-2161",
        "email": "studiomanager0598@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:30PM",
            "TUESDAY": "4:55AM - 8:30PM",
            "WEDNESDAY": "4:55AM - 8:30PM",
            "THURSDAY": "4:55AM - 8:30PM",
            "FRIDAY": "4:55AM - 8:30PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            28.3305016,
            -81.527634
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.82,
                    "duration": "indefinitely",
                    "model_conf": 153.41
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.08,
                    "duration": "indefinitely",
                    "model_conf": 182.9
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.78,
                    "duration": "indefinitely",
                    "model_conf": 111.55
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Celebration, FL",
            "slug": "7a4557df-0d5f-464f-a2c5-19da846028b5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/18/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/celebration/49-blake-blvd/",
        "index": 98.425,
        "franchise-entity-name": "FLORIDA FITNESS WORKS3, LLC"
    },
    "0eda324a-8659-4b85-85c2-7e7291004d66": {
        "name": "Orange Theory Centreville",
        "address": "14145 Saint Germaine Drive Centreville, Virginia 20121",
        "phone": "(703) 997-9682",
        "email": "studiomanager1124@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            38.8362694,
            -77.437523
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.95,
                    "duration": "indefinitely",
                    "model_conf": 136.79
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.29,
                    "duration": "indefinitely",
                    "model_conf": 169.36
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.87,
                    "duration": "indefinitely",
                    "model_conf": 102.05
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Centreville",
            "slug": "0eda324a-8659-4b85-85c2-7e7291004d66"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/4/19",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/centreville/14145-saint-germaine-drive/",
        "index": 98.425,
        "franchise-entity-name": "CBVRG, LLC"
    },
    "a05ae687-519f-4b8b-aa92-a9f2cce1725d": {
        "name": "Orange Theory Chamblee",
        "address": "5211 PEACHTREE BLVD Chamblee, Georgia 30341",
        "phone": "(404) 994-5054",
        "email": "studiomanager1205@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.8899994,
            -84.300003
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.22,
                    "duration": "indefinitely",
                    "model_conf": 111.14
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.76,
                    "duration": "indefinitely",
                    "model_conf": 138.08
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.89,
                    "duration": "indefinitely",
                    "model_conf": 83.1
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Chamblee",
            "slug": "a05ae687-519f-4b8b-aa92-a9f2cce1725d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/13/20",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/chamblee/5211-peachtree-blvd/",
        "index": 98.425,
        "franchise-entity-name": "VVDC CHAMBLEE, LLC"
    },
    "4d752db2-7149-484e-8b5a-c9115ab46c2b": {
        "name": "Orange Theory Champaign, IL",
        "address": "2035 S. Neil St Champaign, Illinois 61820",
        "phone": "(217) 302-6500",
        "email": "studiomanager1010@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            40.0909424,
            -88.246368
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.06,
                    "duration": "indefinitely",
                    "model_conf": 152.95
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.63,
                    "duration": "1 Months",
                    "model_conf": 183.8
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.25,
                    "duration": "indefinitely",
                    "model_conf": 112.08
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Champaign, IL",
            "slug": "4d752db2-7149-484e-8b5a-c9115ab46c2b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/21/19",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/champaign/2035-s-neil-st/",
        "index": 93.4,
        "franchise-entity-name": "ILLINI FITNESS LLC"
    },
    "431de988-1bd5-4306-8575-19f15d370854": {
        "name": "Orange Theory Chandler-Pecos Ranch, AZ",
        "address": "1900 West Germann Road, Ste 6 Chandler, Arizona 85286",
        "phone": "(480) 699-6115",
        "email": "studiomanager0010@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            33.2770882,
            -111.87485
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 1.34,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.64,
                    "duration": "1 Months",
                    "model_conf": 129.35
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 1.94,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.62,
                    "duration": "indefinitely",
                    "model_conf": 155.15
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 2.84,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.61,
                    "duration": "indefinitely",
                    "model_conf": 95.27
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 3.68,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 5.7,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 7.65,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Chandler-Pecos Ranch, AZ",
            "slug": "431de988-1bd5-4306-8575-19f15d370854"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/12/11",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/chandler/1900-west-germann-road-ste-6/",
        "index": 103.375,
        "franchise-entity-name": "EPOC ENERGY LLC"
    },
    "f75fdfdd-b467-4999-86ae-0f8f923c741f": {
        "name": "Orange Theory South Chandler, AZ",
        "address": "4850 S. Gilbert Rd. Suite 7 Chandler, Arizona 85249",
        "phone": "(480) 794-1055",
        "email": "studiomanager0115@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "6:30AM - 11:30AM",
            "SUNDAY": "6:30AM - 11:30AM"
        },
        "geolocation": [
            33.2364197,
            -111.79001
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 1.34,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.96,
                    "duration": "indefinitely",
                    "model_conf": 140.09
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 1.94,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.87,
                    "duration": "3 Months",
                    "model_conf": 168.75
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 2.84,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.86,
                    "duration": "indefinitely",
                    "model_conf": 101.2
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 3.68,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 5.7,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 7.65,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "South Chandler, AZ",
            "slug": "f75fdfdd-b467-4999-86ae-0f8f923c741f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/31/14",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/chandler/4850-s-gilbert-rd-suite-7/",
        "index": 103.375,
        "franchise-entity-name": "AZOTF2, LLC"
    },
    "2a792b14-cd1d-4d53-9ba6-65e66e8b049e": {
        "name": "Orange Theory Ahwatukee, AZ",
        "address": "900 N 54th Street, Suite 2 Chandler, Arizona 85226",
        "phone": "(480) 686-9054",
        "email": "studiomanager0007@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            33.3181076,
            -111.96935
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 1.34,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.23,
                    "duration": "indefinitely",
                    "model_conf": 136.95
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 1.94,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.63,
                    "duration": "1 Months",
                    "model_conf": 162.47
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 2.84,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.53,
                    "duration": "indefinitely",
                    "model_conf": 99.41
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 3.68,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 5.7,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 7.65,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Ahwatukee, AZ",
            "slug": "2a792b14-cd1d-4d53-9ba6-65e66e8b049e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/28/12",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/chandler/900-n-54th-street-suite-2/",
        "index": 103.375,
        "franchise-entity-name": "MAXIMUM FITNESS, WESTLAKE LLC"
    },
    "fc73759b-8605-480a-85c1-6ea681e769fb": {
        "name": "Orange Theory Chanhassen, MN",
        "address": "460 Lake Dr. #170 Chanhassen, Minnesota 55317",
        "phone": "(952) 777-4243",
        "email": "studiomanager0281@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:45AM - 12:15AM",
            "SUNDAY": "6:45AM - 12:15AM"
        },
        "geolocation": [
            44.8565521,
            -93.532501
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.35,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.08,
                    "duration": "6 Months",
                    "model_conf": 149.13
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.3,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.72,
                    "duration": "indefinitely",
                    "model_conf": 181.59
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.73,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 157.85,
                    "duration": "2 Months",
                    "model_conf": 108.83
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Chanhassen, MN",
            "slug": "fc73759b-8605-480a-85c1-6ea681e769fb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/14/16",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/chanhassen/460-lake-dr-170/",
        "index": 88.45,
        "franchise-entity-name": "GO FORWARD BRANDS 4 L.L.C."
    },
    "ca3a4e2e-d8fc-4bd7-bd84-e0724e151c38": {
        "name": "Orange Theory Chapel Hill, NC",
        "address": "104 Meadowmont Village Circle Chapel Hill, North Carolina 27517",
        "phone": "(919) 883-9424",
        "email": "studiomanager0182@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 2:00PM",
            "SUNDAY": "7:15AM - 2:00PM"
        },
        "geolocation": [
            35.9047203,
            -79.011726
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.2,
                    "duration": "indefinitely",
                    "model_conf": 151.54
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.83,
                    "duration": "indefinitely",
                    "model_conf": 179.58
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.11,
                    "duration": "indefinitely",
                    "model_conf": 107.06
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Chapel Hill, NC",
            "slug": "ca3a4e2e-d8fc-4bd7-bd84-e0724e151c38"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/31/14",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/chapel-hill/104-meadowmont-village-circle/",
        "index": 98.425,
        "franchise-entity-name": "JACKELLE FITNESS CHAPEL HILL, LLC"
    },
    "c9b6229e-f156-40ed-84ad-a676ead2963a": {
        "name": "Orange Theory Chapel Hill-Timberlyne",
        "address": "1129 Weaver Dairy Rd., Suite AA-1 Chapel Hill, North Carolina 27514",
        "phone": "(919) 636-7761",
        "email": "studiomanager1203@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            35.9626465,
            -79.053764
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.88,
                    "duration": "indefinitely",
                    "model_conf": 152.51
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.24,
                    "duration": "indefinitely",
                    "model_conf": 181.5
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.94,
                    "duration": "indefinitely",
                    "model_conf": 108.58
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Chapel Hill-Timberlyne",
            "slug": "c9b6229e-f156-40ed-84ad-a676ead2963a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/18",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/chapel-hill-north/1129-weaver-dairy-rd-suite-aa-1/",
        "index": 98.425,
        "franchise-entity-name": "JACKELLE FITNESS SEVEN, LLC"
    },
    "188f62bc-3bf3-4207-aa15-abd2773c02dd": {
        "name": "Orange Theory Charleston, SC",
        "address": "975 Savannah hwy Charleston, South Carolina 29407",
        "phone": "(843) 277-9036",
        "email": "studiomanager0192@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 12:15PM",
            "SUNDAY": "6:15AM - 12:15PM"
        },
        "geolocation": [
            32.7820625,
            -79.990799
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.38,
                    "duration": "3 Months",
                    "model_conf": 150.63
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.68,
                    "duration": "indefinitely",
                    "model_conf": 183.94
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.84,
                    "duration": "indefinitely",
                    "model_conf": 107.67
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Charleston, SC",
            "slug": "188f62bc-3bf3-4207-aa15-abd2773c02dd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/19/14",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/charleston/975-savannah-hwy/",
        "index": 93.4,
        "franchise-entity-name": "ORANGE GODDESS I, LLC"
    },
    "92b0654a-0e80-45bb-9c04-c457304733af": {
        "name": "Orange Theory Charleston, WV",
        "address": "310 Southridge Blvd Charleston, West Virginia 25309",
        "phone": "(304) 529-1100",
        "email": "studiomanager0824@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            38.3182602,
            -81.715508
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.18,
                    "duration": "indefinitely",
                    "model_conf": 153.55
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.07,
                    "duration": "indefinitely",
                    "model_conf": 185.46
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.08,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Charleston, WV",
            "slug": "92b0654a-0e80-45bb-9c04-c457304733af"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/14/18",
        "url": "https://www.orangetheory.com/en-us/locations/west-virginia/charleston/310-southridge-blvd/",
        "index": 93.4,
        "franchise-entity-name": "BNC FITNESS II, LLC"
    },
    "2a67f1b9-cdbc-49bd-b1d8-6e2a37051a9c": {
        "name": "Orange Theory Charlotte West 3rd St, NC",
        "address": "100 W 3rd St STE C Charlotte, North Carolina 28202",
        "phone": "(980) 580-7712",
        "email": "studiomanager1406@orangetheoryfitness.com",
        "hours": {},
        "geolocation": [
            35.226371,
            -80.84555
        ],
        "memberships": {},
        "services": {},
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Charlotte West 3rd St, NC",
            "slug": "2a67f1b9-cdbc-49bd-b1d8-6e2a37051a9c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": true,
        "opening_date": "7/15/23",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/100-w-3rd-st-ste-c",
        "index": 0,
        "franchise-entity-name": ""
    },
    "2f06c3ac-3d69-42b5-b10f-80659cfe934c": {
        "name": "Orange Theory Charlotte-Railyard, NC",
        "address": "1414 S. Tryon Street Suite 160 Charlotte, North Carolina 28203",
        "phone": "(704) 885-1300",
        "email": "studiomanager1165@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 12:30PM",
            "SUNDAY": "8:00AM - 12:30PM"
        },
        "geolocation": [
            35.2172241,
            -80.855659
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.95,
                    "duration": "indefinitely",
                    "model_conf": 134.62
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.83,
                    "duration": "3 Months",
                    "model_conf": 161.04
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.61,
                    "duration": "indefinitely",
                    "model_conf": 103.61
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Charlotte-Railyard, NC",
            "slug": "2f06c3ac-3d69-42b5-b10f-80659cfe934c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/charlotte/1414-s-tryon-street-suite-160/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO NINE CLT FITNESS, INC."
    },
    "ea87aa6b-4c66-411b-8974-fab73a3a70b0": {
        "name": "Orange Theory Ballantyne, NC",
        "address": "14825 Ballantyne Village Way, Suite A180 Charlotte, North Carolina 28277",
        "phone": "(704) 412-4369",
        "email": "studiomanager0746@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 12:45PM",
            "SUNDAY": "6:30AM - 12:45PM"
        },
        "geolocation": [
            35.0541573,
            -80.851921
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.2,
                    "duration": "indefinitely",
                    "model_conf": 130.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.01,
                    "duration": "indefinitely",
                    "model_conf": 156.23
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.28,
                    "duration": "indefinitely",
                    "model_conf": 101.8
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Ballantyne, NC",
            "slug": "ea87aa6b-4c66-411b-8974-fab73a3a70b0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/9/16",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/charlotte/14825-ballantyne-village-way-suite-a180/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO FOUR CTL FITNESS, INC."
    },
    "a8ed0503-7b46-4961-87f2-516d4ad9bc5a": {
        "name": "Orange Theory Charlotte South End, NC",
        "address": "2317 South Blvd Charlotte, North Carolina 28203",
        "phone": "(704) 626-7322",
        "email": "studiomanager0289@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            35.2047234,
            -80.862617
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.91,
                    "duration": "indefinitely",
                    "model_conf": 129.71
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.85,
                    "duration": "3 Months",
                    "model_conf": 155.81
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.53,
                    "duration": "indefinitely",
                    "model_conf": 101.74
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Charlotte South End, NC",
            "slug": "a8ed0503-7b46-4961-87f2-516d4ad9bc5a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/4/16",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/charlotte/2317-south-blvd/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO THREE CLT FITNESS, INC."
    },
    "5ef5a192-eadd-4316-b3e8-80b3481b14a8": {
        "name": "Orange Theory Charlotte-Cotswold, NC",
        "address": "2907 Providence Road, Unit A103 Charlotte, North Carolina 28211",
        "phone": "(980) 443-6425",
        "email": "studiomanager1053@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            35.170638,
            -80.806597
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.75,
                    "duration": "indefinitely",
                    "model_conf": 111.79
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.06,
                    "duration": "3 Months",
                    "model_conf": 134.85
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.52,
                    "duration": "indefinitely",
                    "model_conf": 94.0
                }
            }
        },
        "services": {
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Charlotte-Cotswold, NC",
            "slug": "5ef5a192-eadd-4316-b3e8-80b3481b14a8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/13/23",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/charlotte/2935-providence-rd-unit-a103/",
        "index": 98.425,
        "franchise-entity-name": "EPOC THREE LLC"
    },
    "7b7ec05d-7565-48b5-b147-57591ac61b2c": {
        "name": "Orange Theory Charlotte-University, NC",
        "address": "3016-B Prosperity Church Rd Charlotte, North Carolina 28269",
        "phone": "(704) 705-2377",
        "email": "studiomanager0506@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            35.3430443,
            -80.768433
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.81,
                    "duration": "indefinitely",
                    "model_conf": 147.13
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.08,
                    "duration": "3 Months",
                    "model_conf": 181.66
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.69,
                    "duration": "indefinitely",
                    "model_conf": 109.9
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Charlotte-University, NC",
            "slug": "7b7ec05d-7565-48b5-b147-57591ac61b2c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/5/16",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/charlotte/3016-b-prosperity-church-rd/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO SIX CLT FITNESS, INC."
    },
    "ff841696-b456-4157-9de7-514e98521abc": {
        "name": "Orange Theory Charlotte-Park Road, NC",
        "address": "4301-D Park Road Charlotte, North Carolina 28209",
        "phone": "(704) 885-2540",
        "email": "studiomanager1389@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:45PM",
            "TUESDAY": "4:45AM - 7:45PM",
            "WEDNESDAY": "4:45AM - 7:45PM",
            "THURSDAY": "4:45AM - 7:45PM",
            "FRIDAY": "4:45AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            35.173788,
            -80.847803
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.37,
                    "duration": "indefinitely",
                    "model_conf": 109.92
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.19,
                    "duration": "5 Months",
                    "model_conf": 134.02
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.97,
                    "duration": "indefinitely",
                    "model_conf": 93.96
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Charlotte-Park Road, NC",
            "slug": "ff841696-b456-4157-9de7-514e98521abc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/9/21",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/charlotte/4301-park-rd-suite-d/",
        "index": 98.425,
        "franchise-entity-name": "JR Word, LLC"
    },
    "e3a25f30-eb50-4566-9458-3954bc6a88f2": {
        "name": "Orange Theory Charlotte -Midtown, NC",
        "address": "601 S. Kings Drive Suite J Charlotte, North Carolina 28204",
        "phone": "(704) 412-9612",
        "email": "studiomanager0999@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            35.2098351,
            -80.835617
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.92,
                    "duration": "indefinitely",
                    "model_conf": 130.44
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.86,
                    "duration": "3 Months",
                    "model_conf": 156.29
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.58,
                    "duration": "indefinitely",
                    "model_conf": 101.86
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Charlotte -Midtown, NC",
            "slug": "e3a25f30-eb50-4566-9458-3954bc6a88f2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/25/18",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/charlotte/601-s-kings-drive-suite-j/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO FIVE CLT FITNESS, INC."
    },
    "022a6450-427f-4a20-8c21-cccf2a94551d": {
        "name": "Orange Theory Charlotte - The Arboretum, NC",
        "address": "8038 Providence Rd Suite #100 Charlotte, North Carolina 28277",
        "phone": "(704) 612-2102",
        "email": "studiomanager0288@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            35.096958,
            -80.778877
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.63,
                    "duration": "6 Months",
                    "model_conf": 121.22
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 121.03,
                    "duration": "indefinitely",
                    "model_conf": 143.2
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.22,
                    "duration": "1 Months",
                    "model_conf": 97.22
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Charlotte - The Arboretum, NC",
            "slug": "022a6450-427f-4a20-8c21-cccf2a94551d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/29/15",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/charlotte/8038-providence-road-suite-100/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO TWO CLT FITNESS, INC."
    },
    "1d152e35-6ace-42b7-8bbd-e69a846cdf97": {
        "name": "Orange Theory Charlotte-Quail Corners, NC",
        "address": "8402 Park Road Charlotte, North Carolina 28210",
        "phone": "(704) 247-6137",
        "email": "studiomanager0141@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            35.1169663,
            -80.856621
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.84,
                    "duration": "indefinitely",
                    "model_conf": 124.66
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.03,
                    "duration": "indefinitely",
                    "model_conf": 148.8
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.2,
                    "duration": "indefinitely",
                    "model_conf": 99.49
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Charlotte-Quail Corners, NC",
            "slug": "1d152e35-6ace-42b7-8bbd-e69a846cdf97"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/19/14",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/charlotte/8402-park-road/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO ONE CLT FITNESS, LLC"
    },
    "da52aed2-e846-4619-bad2-f3be739c566f": {
        "name": "Orange Theory Charlotte-College Downs",
        "address": "8420 University City Blvd Charlotte, North Carolina 28213",
        "phone": "(704) 885-1833",
        "email": "studiomanager1399@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 6:45PM",
            "TUESDAY": "5:30AM - 6:45PM",
            "WEDNESDAY": "5:30AM - 6:45PM",
            "THURSDAY": "5:30AM - 6:45PM",
            "FRIDAY": "5:30AM - 6:45PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            35.29573,
            -80.741522
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.65,
                    "duration": "indefinitely",
                    "model_conf": 149.53
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.36,
                    "duration": "2 Months",
                    "model_conf": 181.65
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.57,
                    "duration": "indefinitely",
                    "model_conf": 110.38
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Charlotte-College Downs",
            "slug": "da52aed2-e846-4619-bad2-f3be739c566f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/9/22",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/charlotte/8420-university-city-blvd/",
        "index": 98.425,
        "franchise-entity-name": "Studio Eleven CLT Fitness Inc"
    },
    "2d67f6a6-3fd5-4c5a-a1fc-41d7cf6d485d": {
        "name": "Orange Theory Barracks-Charlottesville, VA",
        "address": "1935 Arlington Boulevard Charlottesville, Virginia 22903",
        "phone": "(434) 483-5757",
        "email": "studiomanager0748@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 6:30PM",
            "TUESDAY": "6:15AM - 6:30PM",
            "WEDNESDAY": "6:15AM - 6:30PM",
            "THURSDAY": "6:15AM - 6:30PM",
            "FRIDAY": "6:15AM - 6:30PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            38.0488472,
            -78.505089
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.55,
                    "duration": "2 Months",
                    "model_conf": 152.77
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.43,
                    "duration": "indefinitely",
                    "model_conf": 181.91
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.66,
                    "duration": "indefinitely",
                    "model_conf": 110.82
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Barracks-Charlottesville, VA",
            "slug": "2d67f6a6-3fd5-4c5a-a1fc-41d7cf6d485d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/charlottesville/1935-arlington-boulevard/",
        "index": 98.425,
        "franchise-entity-name": "SMASH FITNESS, LLC"
    },
    "c27fedd0-d0b0-4db7-aec0-9c4d721caa74": {
        "name": "Orange Theory Pantops-Charlottesville, VA",
        "address": "930 Olympia Drive Suite 8 Charlottesville, Virginia 22911",
        "phone": "(434) 473-7000",
        "email": "studiomanager1467@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            38.033699,
            -78.448685
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.52,
                    "duration": "2 Months",
                    "model_conf": 153.03
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.6,
                    "duration": "indefinitely",
                    "model_conf": 182.91
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.67,
                    "duration": "indefinitely",
                    "model_conf": 111.09
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Pantops-Charlottesville, VA",
            "slug": "c27fedd0-d0b0-4db7-aec0-9c4d721caa74"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/20/22",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/charlottesville/930-olympia-drive-suite-8/",
        "index": 98.425,
        "franchise-entity-name": "Blast Fitness LLC"
    },
    "a0ff6e00-7bd6-4953-898d-ad0a211bb0dd": {
        "name": "Orange Theory Northridge, CA",
        "address": "9201 Winnetka Ave Suite C Chatsworth, California 91311",
        "phone": "(818) 956-0005",
        "email": "studiomanager0180@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 9:00PM",
            "TUESDAY": "5:30AM - 9:00PM",
            "WEDNESDAY": "5:30AM - 9:00PM",
            "THURSDAY": "5:30AM - 9:00PM",
            "FRIDAY": "5:30AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            34.23743,
            -118.57112
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.53,
                    "duration": "1 Months",
                    "model_conf": 153.31
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.46,
                    "duration": "indefinitely",
                    "model_conf": 185.14
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.33,
                    "duration": "1 Months",
                    "model_conf": 111.69
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Northridge, CA",
            "slug": "a0ff6e00-7bd6-4953-898d-ad0a211bb0dd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/chatsworth/9201-c-winnetka-avenue/",
        "index": 108.325,
        "franchise-entity-name": "TRISTAR FITNESS II, LLC"
    },
    "d13deff3-801e-4b6e-8642-83cc89c20f6d": {
        "name": "Orange Theory Northshore, TN",
        "address": "300 Cherokee Blvd Chattanooga, Tennessee 37403",
        "phone": "(423) 438-1588",
        "email": "studiomanager0860@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            35.0637894,
            -85.312088
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.18,
                    "duration": "indefinitely",
                    "model_conf": 153.77
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.81,
                    "duration": "indefinitely",
                    "model_conf": 183.43
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.04,
                    "duration": "indefinitely",
                    "model_conf": 111.04
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Northshore, TN",
            "slug": "d13deff3-801e-4b6e-8642-83cc89c20f6d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/10/17",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/chattanooga/300-cherokee-blvd/",
        "index": 93.4,
        "franchise-entity-name": "JM NORTHSHORE, LLC"
    },
    "97672720-0b86-4eca-bea1-e0b398717f18": {
        "name": "Orange Theory Chattanooga, TN",
        "address": "7407 Igou Gap Road Chattanooga, Tennessee 37421",
        "phone": "(423) 681-2593",
        "email": "studiomanager0435@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            35.0267868,
            -85.155968
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.23,
                    "duration": "indefinitely",
                    "model_conf": 153.77
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.88,
                    "duration": "indefinitely",
                    "model_conf": 183.9
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.08,
                    "duration": "indefinitely",
                    "model_conf": 110.53
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Chattanooga, TN",
            "slug": "97672720-0b86-4eca-bea1-e0b398717f18"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/chattanooga/7407-igou-gap-road/",
        "index": 93.4,
        "franchise-entity-name": "JM CHATTANOOGA, LLC"
    },
    "64bb37ea-56b0-421b-a2b8-3e519bb3d89c": {
        "name": "Orange Theory Lancaster, NY",
        "address": "4900 Transit Rd., Suite 400 Cheektowaga, New York 14043",
        "phone": "(716) 247-5005",
        "email": "studiomanager1379@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "7:15AM - 10:45AM",
            "SUNDAY": "7:15AM - 10:45AM"
        },
        "geolocation": [
            42.877389,
            -78.697867
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.41,
                    "duration": "indefinitely",
                    "model_conf": 148.95
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.97,
                    "duration": "6 Months",
                    "model_conf": 184.94
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.69,
                    "duration": "indefinitely",
                    "model_conf": 111.45
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lancaster, NY",
            "slug": "64bb37ea-56b0-421b-a2b8-3e519bb3d89c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/31/22",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/cheektowaga/4908-transit-road/",
        "index": 98.425,
        "franchise-entity-name": "OTF Buffalo II, LLC"
    },
    "6b2a6c89-1204-4b28-a631-322d67272485": {
        "name": "Orange Theory Chelmsford, MA",
        "address": "90 Drum Hill Rd #24 Chelmsford, Massachusetts 01824",
        "phone": "(978) 577-5901",
        "email": "studiomanager1026@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:40PM",
            "TUESDAY": "5:00AM - 7:40PM",
            "WEDNESDAY": "5:00AM - 7:40PM",
            "THURSDAY": "5:00AM - 7:40PM",
            "FRIDAY": "5:00AM - 7:40PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            42.6230698,
            -71.361305
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.9,
                    "duration": "indefinitely",
                    "model_conf": 153.23
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.84,
                    "duration": "3 Months",
                    "model_conf": 184.84
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.65,
                    "duration": "indefinitely",
                    "model_conf": 111.51
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Chelmsford, MA",
            "slug": "6b2a6c89-1204-4b28-a631-322d67272485"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/18",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/chelmsford/90-drum-hill-rd-24/",
        "index": 98.425,
        "franchise-entity-name": "TROIS FILLES FITNESS, LLC"
    },
    "29ab7295-a2fd-44f5-89c8-108aaa677e9b": {
        "name": "Orange Theory Cherry Hill, NJ",
        "address": "929 Haddonfield rd. Suite A Cherry Hill, New Jersey 08002",
        "phone": "(856) 282-1122",
        "email": "studiomanager0667@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            39.9253922,
            -75.033958
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.78,
                    "duration": "indefinitely",
                    "model_conf": 151.14
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.77,
                    "duration": "4 Months",
                    "model_conf": 167.39
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.52,
                    "duration": "indefinitely",
                    "model_conf": 97.22
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cherry Hill, NJ",
            "slug": "29ab7295-a2fd-44f5-89c8-108aaa677e9b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/cherry-hill/929-haddonfield-rd-suite-a/",
        "index": 98.425,
        "franchise-entity-name": "ALPHA FITNESS VOORHEES LLC"
    },
    "eb749130-36e2-42a4-bc74-b547bfd9f75f": {
        "name": "Orange Theory Greenbrier, VA",
        "address": "801 Volvo Parkway Suite 138 Chesapeake, Virginia 23320",
        "phone": "(757) 335-6896",
        "email": "studiomanager1027@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            36.7675629,
            -76.228783
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.45,
                    "duration": "3 Months",
                    "model_conf": 153.0
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.07,
                    "duration": "indefinitely",
                    "model_conf": 184.46
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.44,
                    "duration": "1 Months",
                    "model_conf": 111.85
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Greenbrier, VA",
            "slug": "eb749130-36e2-42a4-bc74-b547bfd9f75f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/14/19",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/chesapeake/801-volvo-parkway-suite-138/",
        "index": 93.4,
        "franchise-entity-name": "FIT FAMILY ENTERPRISES, LLC"
    },
    "321b9ab8-c506-11e7-985e-0648b611ac08": {
        "name": "Orange Theory Chesapeake, VA",
        "address": "940 Cedar Road Suite #108 Chesapeake, Virginia 23322",
        "phone": "(757) 785-4455",
        "email": "studiomanager0901@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            36.7121735,
            -76.275963
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.76,
                    "duration": "1 Months",
                    "model_conf": 153.29
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.74,
                    "duration": "indefinitely",
                    "model_conf": 184.06
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.97,
                    "duration": "indefinitely",
                    "model_conf": 111.79
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Chesapeake, VA",
            "slug": "321b9ab8-c506-11e7-985e-0648b611ac08"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/6/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/chesapeake/940-cedar-rd-ste-108/",
        "index": 88.45,
        "franchise-entity-name": "VIRGINIA WORK GROUP, LLC"
    },
    "841e091b-e6ba-48e8-ae24-89a41e4bdbdd": {
        "name": "Orange Theory Chesterfield, MO",
        "address": "1674 Clarkson Road Chesterfield, Missouri 63017",
        "phone": "(314) 384-9025",
        "email": "studiomanager0186@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:30AM - 12:30PM",
            "SUNDAY": "6:30AM - 12:30PM"
        },
        "geolocation": [
            38.6432266,
            -90.564835
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.98,
                    "duration": "5 Months",
                    "model_conf": 125.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.03,
                    "duration": "indefinitely",
                    "model_conf": 153.54
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.4,
                    "duration": "1 Months",
                    "model_conf": 92.5
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Chesterfield, MO",
            "slug": "841e091b-e6ba-48e8-ae24-89a41e4bdbdd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/28/15",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/chesterfield/1674-clarkson-road/",
        "index": 93.4,
        "franchise-entity-name": "OTF CLAYTON LLC"
    },
    "b0b2e103-821d-43e5-828a-05f9efae6f11": {
        "name": "Orange Theory Chicago - Mccormick",
        "address": "110 East Cermak Road Chicago, Illinois 60616",
        "phone": "(312) 635-7474",
        "email": "studiomanager1351@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:15AM",
            "SUNDAY": "7:00AM - 11:15AM"
        },
        "geolocation": [
            41.853101,
            -87.623566
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.63,
                    "duration": "indefinitely",
                    "model_conf": 126.46
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.21,
                    "duration": "1 Months",
                    "model_conf": 151.82
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.67,
                    "duration": "indefinitely",
                    "model_conf": 93.83
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago - Mccormick",
            "slug": "b0b2e103-821d-43e5-828a-05f9efae6f11"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/10/22",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/110-e-cermak/",
        "index": 118.225,
        "franchise-entity-name": "South Loop 2 Fitness, LLC"
    },
    "7037d10e-a716-413b-823d-96ab41fee73e": {
        "name": "Orange Theory Chicago - Edgewater, IL",
        "address": "1126 W Granville Ave Chicago, Illinois 60660",
        "phone": "(773) 234-0311",
        "email": "studiomanager0318@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:45PM",
            "TUESDAY": "6:00AM - 7:45PM",
            "WEDNESDAY": "6:00AM - 7:45PM",
            "THURSDAY": "6:00AM - 7:45PM",
            "FRIDAY": "6:00AM - 7:45PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            41.9945679,
            -87.659492
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.36,
                    "duration": "indefinitely",
                    "model_conf": 134.82
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.47,
                    "duration": "1 Months",
                    "model_conf": 159.57
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.59,
                    "duration": "indefinitely",
                    "model_conf": 98.64
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago - Edgewater, IL",
            "slug": "7037d10e-a716-413b-823d-96ab41fee73e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/10/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/1126-w-granville-ave/",
        "index": 118.225,
        "franchise-entity-name": "CHICAGOLAND FITNESS I, LLC"
    },
    "c37d31c4-b7f0-4a1c-92fd-227cbf7137aa": {
        "name": "Orange Theory Chicago - Old Town, IL",
        "address": "1513 N Wells Street Chicago, Illinois 60610",
        "phone": "(312) 625-1204",
        "email": "studiomanager0331@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:45PM",
            "TUESDAY": "6:00AM - 7:45PM",
            "WEDNESDAY": "6:00AM - 7:45PM",
            "THURSDAY": "6:00AM - 7:45PM",
            "FRIDAY": "6:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            41.9096184,
            -87.634247
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.88,
                    "duration": "indefinitely",
                    "model_conf": 87.47
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 157.74,
                    "duration": "3 Months",
                    "model_conf": 102.43
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.45,
                    "duration": "indefinitely",
                    "model_conf": 65.34
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago - Old Town, IL",
            "slug": "c37d31c4-b7f0-4a1c-92fd-227cbf7137aa"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/24/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/1513-n-wells-street/",
        "index": 118.225,
        "franchise-entity-name": "STUDIO FITNESS GROUP LLC"
    },
    "dd9e7290-f03d-4e0f-8583-3e60aa01cd7f": {
        "name": "Orange Theory Chicago-Millennium Park, IL",
        "address": "155 N Michigan Ave. Chicago, Illinois 60601",
        "phone": "(312) 757-5144",
        "email": "studiomanager1229@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 6:45PM",
            "TUESDAY": "6:00AM - 6:45PM",
            "WEDNESDAY": "6:00AM - 6:45PM",
            "THURSDAY": "6:00AM - 6:45PM",
            "FRIDAY": "6:00AM - 6:45PM",
            "SATURDAY": "8:30AM - 12:00PM",
            "SUNDAY": "8:30AM - 12:00PM"
        },
        "geolocation": [
            41.8850594,
            -87.624146
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 120.77,
                    "duration": "indefinitely",
                    "model_conf": 100.04
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 157.22,
                    "duration": "4 Months",
                    "model_conf": 118.81
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 220.18,
                    "duration": "indefinitely",
                    "model_conf": 75.93
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago-Millennium Park, IL",
            "slug": "dd9e7290-f03d-4e0f-8583-3e60aa01cd7f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/6/19",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/155-n-michigan-ave/",
        "index": 118.225,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-NORTH EAST, LLC"
    },
    "ae28b6ab-29ee-4651-b1d3-20e2e6b27eb0": {
        "name": "Orange Theory Chicago - Wicker Park, IL",
        "address": "1634 W North Ave Chicago, Illinois 60622",
        "phone": "(312) 757-1644",
        "email": "studiomanager0321@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 12:00PM",
            "SUNDAY": "8:00AM - 12:00PM"
        },
        "geolocation": [
            41.9108696,
            -87.669212
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.37,
                    "duration": "indefinitely",
                    "model_conf": 86.09
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.28,
                    "duration": "1 Months",
                    "model_conf": 101.69
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.21,
                    "duration": "indefinitely",
                    "model_conf": 64.31
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago - Wicker Park, IL",
            "slug": "ae28b6ab-29ee-4651-b1d3-20e2e6b27eb0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/29/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/1634-w-north-ave/",
        "index": 118.225,
        "franchise-entity-name": "JS FITNESS WICKER PARK LLC"
    },
    "33a99912-2764-4bce-ba26-d9e04da71a0c": {
        "name": "Orange Theory Chicago-Clybourn Commons, IL",
        "address": "2000 North Clybourn Avenue Chicago, Illinois 60614",
        "phone": "(773) 466-2436",
        "email": "studiomanager1517@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 6:45PM",
            "TUESDAY": "4:55AM - 6:45PM",
            "WEDNESDAY": "4:55AM - 6:45PM",
            "THURSDAY": "4:55AM - 6:45PM",
            "FRIDAY": "4:55AM - 6:45PM",
            "SATURDAY": "8:30AM - 12:00PM",
            "SUNDAY": "8:30AM - 12:00PM"
        },
        "geolocation": [
            41.917482,
            -87.659793
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.68,
                    "duration": "indefinitely",
                    "model_conf": 81.63
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.12,
                    "duration": "2 Months",
                    "model_conf": 96.23
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.46,
                    "duration": "indefinitely",
                    "model_conf": 61.25
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago-Clybourn Commons, IL",
            "slug": "33a99912-2764-4bce-ba26-d9e04da71a0c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/4/22",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/2000-north-clybourn-ave/",
        "index": 118.225,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-NORTHEAST, LLC"
    },
    "ad494399-5f40-4981-9442-27b500bbb165": {
        "name": "Orange Theory Chicago-Streeterville, IL",
        "address": "211 E Grand Avenue Chicago, Illinois 60611",
        "phone": "(312) 778-8998",
        "email": "studiomanager0334@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "8:12AM - 12:00PM",
            "SUNDAY": "8:12AM - 12:00PM"
        },
        "geolocation": [
            41.8915558,
            -87.622231
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.94,
                    "duration": "indefinitely",
                    "model_conf": 96.86
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.13,
                    "duration": "2 Months",
                    "model_conf": 114.73
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 220.02,
                    "duration": "indefinitely",
                    "model_conf": 73.46
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago-Streeterville, IL",
            "slug": "ad494399-5f40-4981-9442-27b500bbb165"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/211-e-grand-street/",
        "index": 118.225,
        "franchise-entity-name": "STUDIO FITNESS GROUP STREETERVILLE LLC"
    },
    "52ac2c66-58ed-4b54-b810-db52bf7a0433": {
        "name": "Orange Theory Chicago - Lincoln Park, IL",
        "address": "2209 N. Halsted Street Chicago, Illinois 60614",
        "phone": "(312) 762-5556",
        "email": "studiomanager0032@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "8:30AM - 12:00PM",
            "SUNDAY": "8:30AM - 12:00PM"
        },
        "geolocation": [
            41.9221649,
            -87.648315
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 120.3,
                    "duration": "indefinitely",
                    "model_conf": 84.92
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 157.53,
                    "duration": "3 Months",
                    "model_conf": 100.32
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.95,
                    "duration": "indefinitely",
                    "model_conf": 63.45
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago - Lincoln Park, IL",
            "slug": "52ac2c66-58ed-4b54-b810-db52bf7a0433"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/27/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/2209-n-halsted-street/",
        "index": 118.225,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-NORTH EAST, LLC"
    },
    "ff630de8-e992-4897-b308-587114ac617f": {
        "name": "Orange Theory Chicago - Logan Square, IL",
        "address": "2251 N. Milwaukee Avenue Chicago, Illinois 60647",
        "phone": "(312) 874-7227",
        "email": "studiomanager0427@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:50PM",
            "TUESDAY": "5:00AM - 8:50PM",
            "WEDNESDAY": "5:00AM - 8:50PM",
            "THURSDAY": "5:00AM - 8:50PM",
            "FRIDAY": "5:00AM - 8:50PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            41.922184,
            -87.694977
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.9,
                    "duration": "indefinitely",
                    "model_conf": 106.02
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 157.89,
                    "duration": "2 Months",
                    "model_conf": 127.82
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.83,
                    "duration": "indefinitely",
                    "model_conf": 78.73
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago - Logan Square, IL",
            "slug": "ff630de8-e992-4897-b308-587114ac617f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/3/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/2251-n-milwaukee-avenue/",
        "index": 118.225,
        "franchise-entity-name": "CHICAGOLAND FITNESS V, LLC"
    },
    "f03b2a66-a095-477a-b8cd-17ee363af41a": {
        "name": "Orange Theory Chicago - Roscoe Village, IL",
        "address": "3328 N. Western Ave Chicago, Illinois 60618",
        "phone": "(312) 971-6223",
        "email": "studiomanager0424@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            41.9412689,
            -87.689438
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.7,
                    "duration": "indefinitely",
                    "model_conf": 107.92
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.28,
                    "duration": "1 Months",
                    "model_conf": 130.56
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.64,
                    "duration": "indefinitely",
                    "model_conf": 80.59
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago - Roscoe Village, IL",
            "slug": "f03b2a66-a095-477a-b8cd-17ee363af41a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/2/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/3328-n-western-ave/",
        "index": 118.225,
        "franchise-entity-name": "CHICAGOLAND FITNESS II, LLC"
    },
    "c031a529-b26e-4178-bbb6-1517692f77e0": {
        "name": "Orange Theory Chicago - Wrigleyville, IL",
        "address": "3738 N Halsted St #2 Chicago, Illinois 60613",
        "phone": "(224) 252-0242",
        "email": "studiomanager0305@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "8:30AM - 12:00PM",
            "SUNDAY": "8:30AM - 12:00PM"
        },
        "geolocation": [
            41.9504967,
            -87.649612
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.59,
                    "duration": "indefinitely",
                    "model_conf": 107.82
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.54,
                    "duration": "1 Months",
                    "model_conf": 129.92
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.37,
                    "duration": "indefinitely",
                    "model_conf": 80.23
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago - Wrigleyville, IL",
            "slug": "c031a529-b26e-4178-bbb6-1517692f77e0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/1/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/3738-n-halsted-st-2/",
        "index": 118.225,
        "franchise-entity-name": "PERLOFF - PROVIDENCE STUDIO #3 LLC"
    },
    "27d768a1-b2c6-443a-8bbe-92c33771ce07": {
        "name": "Orange Theory Chicago - Lincoln Square, IL",
        "address": "4728 N Lincoln Avenue Chicago, Illinois 60625",
        "phone": "(312) 755-6650",
        "email": "studiomanager0425@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            41.9673348,
            -87.688004
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.92,
                    "duration": "indefinitely",
                    "model_conf": 122.66
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.03,
                    "duration": "2 Months",
                    "model_conf": 147.15
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.84,
                    "duration": "indefinitely",
                    "model_conf": 90.68
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago - Lincoln Square, IL",
            "slug": "27d768a1-b2c6-443a-8bbe-92c33771ce07"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/10/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/4728-n-lincoln-avenue/",
        "index": 118.225,
        "franchise-entity-name": "CHICAGOLAND FITNESS III, LLC"
    },
    "00cf5a22-32df-411f-80db-ee2ba31a2d59": {
        "name": "Orange Theory Chicago-Andersonville",
        "address": "5012 N Clark St Chicago, Illinois 60640",
        "phone": "(773) 717-7999",
        "email": "studiomanager0684@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            41.9729157,
            -87.668312
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.37,
                    "duration": "indefinitely",
                    "model_conf": 123.63
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.44,
                    "duration": "1 Months",
                    "model_conf": 148.08
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.45,
                    "duration": "indefinitely",
                    "model_conf": 91.27
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago-Andersonville",
            "slug": "00cf5a22-32df-411f-80db-ee2ba31a2d59"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/5012-n-clark-st/",
        "index": 118.225,
        "franchise-entity-name": "CHICAGOLAND FITNESS VI, LLC"
    },
    "ed15ef18-cc3d-497f-bebb-c047276b66ff": {
        "name": "Orange Theory Chicago-Hyde Park, IL",
        "address": "5109 S. Harper Ave Chicago, Illinois 60615",
        "phone": "(773) 377-4747",
        "email": "studiomanager0743@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 12:15PM",
            "SUNDAY": "7:15AM - 12:15PM"
        },
        "geolocation": [
            41.8017693,
            -87.589142
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 118.92,
                    "duration": "indefinitely",
                    "model_conf": 147.92
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 159.97,
                    "duration": "indefinitely",
                    "model_conf": 178.49
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.08,
                    "duration": "indefinitely",
                    "model_conf": 107.83
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago-Hyde Park, IL",
            "slug": "ed15ef18-cc3d-497f-bebb-c047276b66ff"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/7/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/5109-s-harper-ave/",
        "index": 118.225,
        "franchise-entity-name": "JOMIL LLC"
    },
    "26feff69-fb16-4792-b6f7-f24a3816419b": {
        "name": "Orange Theory Chicago-River North",
        "address": "640 N. Wells St. Chicago, Illinois 60654",
        "phone": "(312) 724-7600",
        "email": "studiomanager0333@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "8:30AM - 12:30PM",
            "SUNDAY": "8:30AM - 12:30PM"
        },
        "geolocation": [
            41.8937378,
            -87.634445
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 120.11,
                    "duration": "indefinitely",
                    "model_conf": 89.45
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 157.96,
                    "duration": "2 Months",
                    "model_conf": 105.02
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.75,
                    "duration": "indefinitely",
                    "model_conf": 67.92
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago-River North",
            "slug": "26feff69-fb16-4792-b6f7-f24a3816419b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/640-n-wells-st/",
        "index": 118.225,
        "franchise-entity-name": "STUDIO FITNESS GROUP RIVER NORTH LLC"
    },
    "bfaaf80b-b652-4d73-88aa-952925f9d48e": {
        "name": "Orange Theory Chicago-River West",
        "address": "731 N Milwaukee Ave Chicago, Illinois 60642",
        "phone": "(312) 832-9899",
        "email": "studiomanager0534@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 12:00PM",
            "SUNDAY": "8:00AM - 12:00PM"
        },
        "geolocation": [
            41.8950844,
            -87.653381
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.96,
                    "duration": "indefinitely",
                    "model_conf": 88.05
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.0,
                    "duration": "2 Months",
                    "model_conf": 103.08
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.51,
                    "duration": "indefinitely",
                    "model_conf": 66.16
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago-River West",
            "slug": "bfaaf80b-b652-4d73-88aa-952925f9d48e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/7/18",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/731-n-milwaukee-ave/",
        "index": 118.225,
        "franchise-entity-name": "FULTON RIVER FITNESS, LLC"
    },
    "f0153801-87fe-4da7-b596-0083a8ee55a7": {
        "name": "Orange Theory Chicago- West Loop, IL",
        "address": "823 W. Madison St. Chicago, Illinois 60607",
        "phone": "(773) 741-1600",
        "email": "studiomanager0330@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:30AM - 12:30PM",
            "SUNDAY": "7:30AM - 12:30PM"
        },
        "geolocation": [
            41.8817558,
            -87.648468
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 120.47,
                    "duration": "indefinitely",
                    "model_conf": 96.49
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 157.72,
                    "duration": "3 Months",
                    "model_conf": 113.97
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 220.36,
                    "duration": "indefinitely",
                    "model_conf": 73.08
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Chicago- West Loop, IL",
            "slug": "f0153801-87fe-4da7-b596-0083a8ee55a7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/chicago/823-w-madison-st/",
        "index": 118.225,
        "franchise-entity-name": "STUDIO FITNESS GROUP WEST LOOP LLC"
    },
    "e76a5aaf-f38e-45ca-82fc-4e704a0d69aa": {
        "name": "Orange Theory Chico, CA",
        "address": "874 East Avenue Chico, California 95926",
        "phone": "(530) 722-4000",
        "email": "studiomanager0758@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 7:45PM",
            "TUESDAY": "4:30AM - 7:45PM",
            "WEDNESDAY": "4:30AM - 7:45PM",
            "THURSDAY": "4:30AM - 7:45PM",
            "FRIDAY": "4:30AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.7608528,
            -121.84425
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.47,
                    "duration": "3 Months",
                    "model_conf": 153.65
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.83,
                    "duration": "indefinitely",
                    "model_conf": 182.26
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.42,
                    "duration": "indefinitely",
                    "model_conf": 111.98
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Chico, CA",
            "slug": "e76a5aaf-f38e-45ca-82fc-4e704a0d69aa"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/18/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/chico/874-east-avenue/",
        "index": 88.45,
        "franchise-entity-name": "JSA FIT 1, LP"
    },
    "cb77d462-795b-4259-8051-1395565e3afb": {
        "name": "Orange Theory Chino Hills, CA",
        "address": "4525 Chino Hills Parkway, Unit A Chino Hills, California 91709",
        "phone": "(909) 906-1771",
        "email": "studiomanager0363@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:30AM - 11:30AM",
            "SUNDAY": "6:30AM - 11:30AM"
        },
        "geolocation": [
            33.9813156,
            -117.70565
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.25,
                    "duration": "3 Months",
                    "model_conf": 152.04
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.75,
                    "duration": "indefinitely",
                    "model_conf": 181.92
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.81,
                    "duration": "indefinitely",
                    "model_conf": 109.11
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Chino Hills, CA",
            "slug": "cb77d462-795b-4259-8051-1395565e3afb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/chino-hills/4525-chino-hills-parkway-shop-7/",
        "index": 103.375,
        "franchise-entity-name": "LNC FITNESS, LLC"
    },
    "6718d5d6-e5e3-4254-837e-93f1eba5ed25": {
        "name": "Orange Theory Christiansburg",
        "address": "2767 MARKET ST Christiansburg, Virginia 24073",
        "phone": "(540) 300-7022",
        "email": "studiomanager0925@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:45AM",
            "SUNDAY": "7:30AM - 11:45AM"
        },
        "geolocation": [
            37.167773,
            -80.420375
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.26,
                    "duration": "indefinitely",
                    "model_conf": 153.8
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.42,
                    "duration": "indefinitely",
                    "model_conf": 185.83
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.29,
                    "duration": "indefinitely",
                    "model_conf": 112.0
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Christiansburg",
            "slug": "6718d5d6-e5e3-4254-837e-93f1eba5ed25"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/14/20",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/christiansburg/2767-market-st/",
        "index": 88.45,
        "franchise-entity-name": "2MD HOKIES LLC"
    },
    "bf6ffa89-dad8-4f18-9044-e10dd31cfb5e": {
        "name": "Orange Theory San Diego - Eastlake, CA",
        "address": "2015 Birch Rd, Suite 1701 Chula Vista, California 91915",
        "phone": "(619) 642-2400",
        "email": "studiomanager0952@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:15AM - 12:15PM",
            "SUNDAY": "6:15AM - 12:15PM"
        },
        "geolocation": [
            32.6221046,
            -116.96725
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.37,
                    "duration": "indefinitely",
                    "model_conf": 152.94
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.64,
                    "duration": "1 Months",
                    "model_conf": 184.73
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.12,
                    "duration": "indefinitely",
                    "model_conf": 110.38
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Diego - Eastlake, CA",
            "slug": "bf6ffa89-dad8-4f18-9044-e10dd31cfb5e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/16/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/chula-vista/2015-birch-rd-suite-1701/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT8, LLC"
    },
    "e30e7c3f-15ae-4093-86d8-534e774afe95": {
        "name": "Orange Theory Cibolo, TX",
        "address": "513 Cibolo Valley Drive Cibolo, Texas 78108",
        "phone": "(210) 501-0299",
        "email": "studiomanager0713@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            29.5723095,
            -98.234558
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.83,
                    "duration": "indefinitely",
                    "model_conf": 153.21
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.27,
                    "duration": "indefinitely",
                    "model_conf": 184.54
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.12,
                    "duration": "indefinitely",
                    "model_conf": 111.53
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cibolo, TX",
            "slug": "e30e7c3f-15ae-4093-86d8-534e774afe95"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/4/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/cibolo/513-cibolo-valley-dr/",
        "index": 98.425,
        "franchise-entity-name": "AVANTI FITNESS OF SCHERTZ, LLC"
    },
    "19eefedb-1008-4d19-b7fa-f0cf09d4ef94": {
        "name": "Orange Theory Oakley",
        "address": "3202 Vandercar Way Cincinnati, Ohio 45209",
        "phone": "(513) 808-4882",
        "email": "studiomanager0371@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:10PM",
            "TUESDAY": "5:00AM - 8:10PM",
            "WEDNESDAY": "5:00AM - 8:10PM",
            "THURSDAY": "5:00AM - 8:10PM",
            "FRIDAY": "5:00AM - 8:10PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            39.1583405,
            -84.427582
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.43,
                    "duration": "indefinitely",
                    "model_conf": 147.46
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.5,
                    "duration": "1 Months",
                    "model_conf": 184.05
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.61,
                    "duration": "indefinitely",
                    "model_conf": 108.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Oakley",
            "slug": "19eefedb-1008-4d19-b7fa-f0cf09d4ef94"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/cincinnati/3202-vandercar-dr/",
        "index": 93.4,
        "franchise-entity-name": "CINCY FITNESS LLC"
    },
    "fe78408e-edbe-496a-a296-d27d2c9f1654": {
        "name": "Orange Theory Rookwood",
        "address": "3831 Edwards Road Cincinnati, Ohio 45209",
        "phone": "(513) 906-5550",
        "email": "studiomanager1343@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 1:00PM",
            "SUNDAY": "8:00AM - 1:00PM"
        },
        "geolocation": [
            39.1501961,
            -84.442619
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.23,
                    "duration": "indefinitely",
                    "model_conf": 147.85
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.44,
                    "duration": "1 Months",
                    "model_conf": 183.95
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.16,
                    "duration": "indefinitely",
                    "model_conf": 108.42
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Rookwood",
            "slug": "fe78408e-edbe-496a-a296-d27d2c9f1654"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/23/19",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/cincinnati/3831-edwards-road/",
        "index": 93.4,
        "franchise-entity-name": "Cincy Fitness The Second LLC"
    },
    "0a68b3a6-5626-48ce-9003-04e7c6786c08": {
        "name": "Orange Theory Cincy West, OH",
        "address": "5655 Harrison Rd Cincinnati, Ohio 45248",
        "phone": "(513) 694-0585",
        "email": "studiomanager1393@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.16746,
            -84.636747
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.73,
                    "duration": "1 Months",
                    "model_conf": 153.05
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.81,
                    "duration": "indefinitely",
                    "model_conf": 185.6
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.56,
                    "duration": "1 Months",
                    "model_conf": 111.43
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Cincy West, OH",
            "slug": "0a68b3a6-5626-48ce-9003-04e7c6786c08"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/22",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/cincinnati/5655-harrison-rd-ste-a/",
        "index": 93.4,
        "franchise-entity-name": "S&amp;C Fitness IV, LLC"
    },
    "6e32835e-fb7a-48ca-92d1-2d39214048bf": {
        "name": "Orange Theory Kenwood, OH",
        "address": "8154 Montgomery Road, Cincinnati, Ohio 45236",
        "phone": "(513) 318-9342",
        "email": "studiomanager0904@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:20AM - 8:00PM",
            "TUESDAY": "5:20AM - 8:00PM",
            "WEDNESDAY": "5:20AM - 8:00PM",
            "THURSDAY": "5:20AM - 8:00PM",
            "FRIDAY": "5:20AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            39.2038918,
            -84.368103
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.5,
                    "duration": "indefinitely",
                    "model_conf": 146.26
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.19,
                    "duration": "indefinitely",
                    "model_conf": 184.9
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.86,
                    "duration": "indefinitely",
                    "model_conf": 106.97
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Kenwood, OH",
            "slug": "6e32835e-fb7a-48ca-92d1-2d39214048bf"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/12/17",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/cincinnati/8154-montgomery-road/",
        "index": 93.4,
        "franchise-entity-name": "STUDIO NUMBER THREE, LLC"
    },
    "10905774-79bc-464e-ad79-2aedbab47e3c": {
        "name": "Orange Theory Claremont, CA",
        "address": "2213 East Baseline Rd Unit 100 Claremont, California 91711",
        "phone": "(909) 929-1275",
        "email": "studiomanager0550@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            34.1206589,
            -117.74971
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.73,
                    "duration": "indefinitely",
                    "model_conf": 152.43
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.85,
                    "duration": "3 Months",
                    "model_conf": 184.36
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.1,
                    "duration": "indefinitely",
                    "model_conf": 109.77
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Claremont, CA",
            "slug": "10905774-79bc-464e-ad79-2aedbab47e3c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/14/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/claremont/2213-baseline-rd-suite-100/",
        "index": 103.375,
        "franchise-entity-name": "HIBI FITNESS MANAGEMENT I, LLC"
    },
    "1be0cdb8-203a-4803-acb6-45b99cba48ec": {
        "name": "Orange Theory Clarksburg, MD",
        "address": "22750 Newcut Road, Suite 4 Clarksburg, Maryland 20871",
        "phone": "(443) 927-9100",
        "email": "studiomanager0082@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            39.2293396,
            -77.247635
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.61,
                    "duration": "1 Months",
                    "model_conf": 153.61
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.48,
                    "duration": "1 Months",
                    "model_conf": 185.32
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.7,
                    "duration": "indefinitely",
                    "model_conf": 111.86
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Clarksburg, MD",
            "slug": "1be0cdb8-203a-4803-acb6-45b99cba48ec"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/25/15",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/clarksburg/22750-newcut-road-suite-4/",
        "index": 108.325,
        "franchise-entity-name": "CLARKSBURG FITNESS PARTNERS LLC"
    },
    "fe31ea74-8f3e-475c-b745-6b2acaba9871": {
        "name": "Orange Theory Clarksville, IN",
        "address": "1025 Veterans Parkway, Suite 200 Clarksville, Indiana 47129",
        "phone": "(812) 822-5570",
        "email": "studiomanager1398@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            38.326981,
            -85.766369
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.63,
                    "duration": "indefinitely",
                    "model_conf": 146.25
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.94,
                    "duration": "3 Months",
                    "model_conf": 180.45
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.62,
                    "duration": "indefinitely",
                    "model_conf": 109.93
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Clarksville, IN",
            "slug": "fe31ea74-8f3e-475c-b745-6b2acaba9871"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/23/23",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/clarksville/1025-veterans-pkwy-suite-200/",
        "index": 93.4,
        "franchise-entity-name": "DAVIS FITNESS STUDIO VI LLC"
    },
    "629bbbc4-b6b9-4aa4-bd42-0d9844373b08": {
        "name": "Orange Theory Clarksville, TN",
        "address": "1011 Winn Way Suite 125 Clarksville, Tennessee 37043",
        "phone": "(615) 380-7344",
        "email": "studiomanager1156@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            36.5208321,
            -87.233749
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.88,
                    "duration": "indefinitely",
                    "model_conf": 152.75
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.04,
                    "duration": "indefinitely",
                    "model_conf": 185.1
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.61,
                    "duration": "indefinitely",
                    "model_conf": 111.99
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Clarksville, TN",
            "slug": "629bbbc4-b6b9-4aa4-bd42-0d9844373b08"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/14/19",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/clarksville/1011-winn-way-suite-125/",
        "index": 93.4,
        "franchise-entity-name": "LKB2 FITNESS LLC"
    },
    "a60f81a3-90a3-453e-a682-b4bceb2773e3": {
        "name": "Orange Theory Clearwater , FL",
        "address": "25853 U.S. 19 N Clearwater, Florida 33763",
        "phone": "(727) 288-9897",
        "email": "studiomanager0134@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            28.0072479,
            -82.729919
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.81,
                    "duration": "indefinitely",
                    "model_conf": 151.68
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.88,
                    "duration": "indefinitely",
                    "model_conf": 172.7
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.74,
                    "duration": "indefinitely",
                    "model_conf": 109.05
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Clearwater , FL",
            "slug": "a60f81a3-90a3-453e-a682-b4bceb2773e3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/clearwater/25853-u-s-19-n/",
        "index": 98.425,
        "franchise-entity-name": "DREAM FITNESS, LLC"
    },
    "c7fb7ffd-c62f-4ced-842c-1fe83b75ecbd": {
        "name": "Orange Theory Clemson, SC",
        "address": "1390 Tiger Blvd. Unit 112 Clemson, South Carolina 29631",
        "phone": "(864) 618-4144",
        "email": "studiomanager1290@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            34.6881027,
            -82.817657
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.11,
                    "duration": "indefinitely",
                    "model_conf": 153.84
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.17,
                    "duration": "indefinitely",
                    "model_conf": 185.7
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.12,
                    "duration": "indefinitely",
                    "model_conf": 112.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Clemson, SC",
            "slug": "c7fb7ffd-c62f-4ced-842c-1fe83b75ecbd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/clemson/1390-tiger-blvd-unit-112/",
        "index": 93.4,
        "franchise-entity-name": "JM CLEMSON LLC"
    },
    "f9da62be-36c7-4431-94dc-03276b169e23": {
        "name": "Orange Theory Clermont, FL",
        "address": "13900 County Rd 455 Clermont, Florida 34711",
        "phone": "(407) 910-2333",
        "email": "studiomanager0599@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:45AM - 11:30AM",
            "SUNDAY": "6:45AM - 11:30AM"
        },
        "geolocation": [
            28.5494385,
            -81.682274
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.64,
                    "duration": "indefinitely",
                    "model_conf": 153.43
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.36,
                    "duration": "2 Months",
                    "model_conf": 184.51
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.41,
                    "duration": "indefinitely",
                    "model_conf": 111.03
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Clermont, FL",
            "slug": "f9da62be-36c7-4431-94dc-03276b169e23"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/12/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/clermont/13900-county-rd-455/",
        "index": 98.425,
        "franchise-entity-name": "FLORIDA FITNESS WORKS 4, LLC"
    },
    "503b5682-75f3-4f0d-a2a4-eae36a1bb05d": {
        "name": "Orange Theory Uptown Cleveland, OH",
        "address": "11500 Euclid Avenue Cleveland, Ohio 44106",
        "phone": "(216) 930-4546",
        "email": "studiomanager0907@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 6:45PM",
            "TUESDAY": "6:15AM - 6:45PM",
            "WEDNESDAY": "6:15AM - 6:45PM",
            "THURSDAY": "6:15AM - 6:45PM",
            "FRIDAY": "6:15AM - 6:45PM",
            "SATURDAY": "8:45AM - 11:15PM",
            "SUNDAY": "8:45AM - 11:15PM"
        },
        "geolocation": [
            41.5100784,
            -81.603355
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.92,
                    "duration": "indefinitely",
                    "model_conf": 152.8
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.76,
                    "duration": "indefinitely",
                    "model_conf": 185.46
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.43,
                    "duration": "indefinitely",
                    "model_conf": 111.72
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Uptown Cleveland, OH",
            "slug": "503b5682-75f3-4f0d-a2a4-eae36a1bb05d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/cleveland/11500-euclid-avenue/",
        "index": 93.4,
        "franchise-entity-name": "KSA8 LLC"
    },
    "cb5ce473-97ad-45e2-97c8-a2bc033e2664": {
        "name": "Orange Theory Downtown Cleveland, OH",
        "address": "740 Prospect Avenue East Cleveland, Ohio 44115",
        "phone": "(216) 816-1105",
        "email": "studiomanager0956@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:45AM - 12:15PM",
            "SUNDAY": "8:45AM - 12:15PM"
        },
        "geolocation": [
            41.4985199,
            -81.68721
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.75,
                    "duration": "1 Months",
                    "model_conf": 152.58
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.79,
                    "duration": "indefinitely",
                    "model_conf": 185.56
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.43,
                    "duration": "indefinitely",
                    "model_conf": 111.78
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Downtown Cleveland, OH",
            "slug": "cb5ce473-97ad-45e2-97c8-a2bc033e2664"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/cleveland/740-prospect-avenue-east/",
        "index": 93.4,
        "franchise-entity-name": "KSA3 LLC"
    },
    "5c73c1c7-1787-4764-b8fa-7d58e5f4e824": {
        "name": "Orange Theory Cleveland, TN",
        "address": "640 Paul Huff Parkway, Suite 303 Cleveland, Tennessee 37312",
        "phone": "(423) 244-0774",
        "email": "studiomanager1412@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            35.2107468,
            -84.862694
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.13,
                    "duration": "indefinitely",
                    "model_conf": 153.84
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.15,
                    "duration": "indefinitely",
                    "model_conf": 185.81
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.12,
                    "duration": "indefinitely",
                    "model_conf": 111.95
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Cleveland, TN",
            "slug": "5c73c1c7-1787-4764-b8fa-7d58e5f4e824"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/21/19",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/cleveland/640-paul-huff-parkway-suite-303/",
        "index": 93.4,
        "franchise-entity-name": "JM Cleveland, LLC"
    },
    "c8c0cc0a-2754-44c8-a53c-37ee599d425d": {
        "name": "Orange Theory Clifton Park, NY",
        "address": "54 Crossing Blvd., Suite V Clifton Park, New York 12065",
        "phone": "(518) 644-4757",
        "email": "studiomanager0386@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:35PM",
            "TUESDAY": "5:00AM - 7:35PM",
            "WEDNESDAY": "5:00AM - 7:35PM",
            "THURSDAY": "5:00AM - 7:35PM",
            "FRIDAY": "5:00AM - 7:35PM",
            "SATURDAY": "6:30AM - 12:10PM",
            "SUNDAY": "6:30AM - 12:10PM"
        },
        "geolocation": [
            42.8598862,
            -73.773064
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.69,
                    "duration": "indefinitely",
                    "model_conf": 152.43
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.02,
                    "duration": "6 Months",
                    "model_conf": 181.37
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.99,
                    "duration": "indefinitely",
                    "model_conf": 111.47
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Clifton Park, NY",
            "slug": "c8c0cc0a-2754-44c8-a53c-37ee599d425d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/7/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/clifton-park/54-crossing-blvd-suite-v/",
        "index": 98.425,
        "franchise-entity-name": "MJP SARATOGA, LLC"
    },
    "82903d30-e78f-4fad-9f32-0e86cd873550": {
        "name": "Orange Theory Clinton",
        "address": "65 WALMART PLZ Clinton, New Jersey 08809",
        "phone": "(908) 520-6800",
        "email": "studiomanager1137@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "7:15AM - 11:45AM",
            "SUNDAY": "7:15AM - 11:45AM"
        },
        "geolocation": [
            40.62753,
            -74.9208
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.96,
                    "duration": "5 Months",
                    "model_conf": 153.68
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.32,
                    "duration": "indefinitely",
                    "model_conf": 185.63
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.28,
                    "duration": "1 Months",
                    "model_conf": 112.0
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Clinton",
            "slug": "82903d30-e78f-4fad-9f32-0e86cd873550"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/18/21",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/clinton/65-walmart-plz-suite-23/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO FITNESS IV, LLC"
    },
    "5994bf4b-ceb7-4b70-8fd0-57d1e12a47c3": {
        "name": "Orange Theory Clive, IA",
        "address": "2171 NW 111th St Clive, Iowa 50325",
        "phone": "(515) 207-3430",
        "email": "studiomanager0347@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:45AM - 12:00PM",
            "SUNDAY": "6:45AM - 12:00PM"
        },
        "geolocation": [
            41.6131325,
            -93.771461
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.09,
                    "duration": "indefinitely",
                    "model_conf": 153.78
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.63,
                    "duration": "4 Months",
                    "model_conf": 181.06
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.79,
                    "duration": "indefinitely",
                    "model_conf": 111.61
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Clive, IA",
            "slug": "5994bf4b-ceb7-4b70-8fd0-57d1e12a47c3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/12/15",
        "url": "https://www.orangetheory.com/en-us/locations/iowa/clive/2171-nw-111th-st/",
        "index": 93.4,
        "franchise-entity-name": "RILEYSQUIN ENTERPRISES, LC"
    },
    "7bd80270-1148-45e5-9d06-41a3ffffaccd": {
        "name": "Orange Theory Closter, NJ",
        "address": "111 Vervalen St. Closter, New Jersey 07624",
        "phone": "(201) 279-0877",
        "email": "studiomanager0047@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            40.9695244,
            -73.955009
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.17,
                    "duration": "3 Months",
                    "model_conf": 140.27
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.22,
                    "duration": "indefinitely",
                    "model_conf": 167.15
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.28,
                    "duration": "1 Months",
                    "model_conf": 106.36
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Closter, NJ",
            "slug": "7bd80270-1148-45e5-9d06-41a3ffffaccd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/closter/111-vervalen-st/",
        "index": 103.375,
        "franchise-entity-name": "EPOC CL, LLC"
    },
    "47a573ef-f9ff-4160-b0b5-3c6c637d2779": {
        "name": "Orange Theory Clovis, CA",
        "address": "1785 Herndon Ave, Suite 103/104 Clovis, California 93611",
        "phone": "(559) 271-6900",
        "email": "studiomanager0466@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            36.8384056,
            -119.68495
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.05,
                    "duration": "indefinitely",
                    "model_conf": 153.22
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.05,
                    "duration": "indefinitely",
                    "model_conf": 184.99
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.93,
                    "duration": "indefinitely",
                    "model_conf": 110.93
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Clovis, CA",
            "slug": "47a573ef-f9ff-4160-b0b5-3c6c637d2779"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/17/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/clovis/1785-herndon-ave-suite-103-104/",
        "index": 98.425,
        "franchise-entity-name": "FRESH SQUEEZE FITNESS, LLC"
    },
    "848d2b50-313f-49f0-a803-5e9782837219": {
        "name": "Orange Theory Coconut Creek, FL",
        "address": "4449 Lyons Road, J-103 Coconut Creek, Florida 33073",
        "phone": "(954) 510-9400",
        "email": "studiomanager0286@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:05PM",
            "SUNDAY": "7:00AM - 12:05PM"
        },
        "geolocation": [
            26.2846947,
            -80.187111
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.24,
                    "duration": "3 Months",
                    "model_conf": 136.65
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.78,
                    "duration": "indefinitely",
                    "model_conf": 177.17
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.3,
                    "duration": "1 Months",
                    "model_conf": 103.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Coconut Creek, FL",
            "slug": "848d2b50-313f-49f0-a803-5e9782837219"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/25/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/coconut-creek/4449-lyons-road-j-103/",
        "index": 98.425,
        "franchise-entity-name": "COCONUT CREEK FITNESS PARTNERS, INC."
    },
    "0b71e409-8455-4de7-84bb-3d8606ef4046": {
        "name": "Orange Theory College Park, MD",
        "address": "8321 Baltimore Ave., Suite A College Park, Maryland 20740",
        "phone": "(240) 667-7226",
        "email": "studiomanager1194@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "8:15AM - 1:00PM",
            "SUNDAY": "8:15AM - 1:00PM"
        },
        "geolocation": [
            38.9928665,
            -76.932747
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.64,
                    "duration": "indefinitely",
                    "model_conf": 148.6
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 137.99,
                    "duration": "2 Months",
                    "model_conf": 179.04
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.38,
                    "duration": "indefinitely",
                    "model_conf": 107.72
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "College Park, MD",
            "slug": "0b71e409-8455-4de7-84bb-3d8606ef4046"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/college-park/8321-baltimore-ave-suite-a/",
        "index": 108.325,
        "franchise-entity-name": "MARYLAND FITNESS PARTNERS, LLC - 1194"
    },
    "63d348f4-5a26-4b93-94ad-f3b5cfca2ebf": {
        "name": "Orange Theory College Station, TX",
        "address": "1025 University Dr #103 College Station, Texas 77845",
        "phone": "(979) 213-5696",
        "email": "studiomanager0818@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            30.6240768,
            -96.339737
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.25,
                    "duration": "indefinitely",
                    "model_conf": 153.42
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.66,
                    "duration": "1 Months",
                    "model_conf": 185.66
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.34,
                    "duration": "indefinitely",
                    "model_conf": 111.97
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "College Station, TX",
            "slug": "63d348f4-5a26-4b93-94ad-f3b5cfca2ebf"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/college-station/1025-university-dr-103/",
        "index": 103.375,
        "franchise-entity-name": "COLLEGE STATION FITNESS PARTNERS LLC"
    },
    "0bfcefbd-b588-4290-a9e8-7350ac458b44": {
        "name": "Orange Theory Southern College Station, TX",
        "address": "11655 FM 2154 Suite 200 College Station, Texas 77845",
        "phone": "(979) 401-3104",
        "email": "studiomanager1302@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            30.5839653,
            -96.324387
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.99,
                    "duration": "indefinitely",
                    "model_conf": 153.34
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.94,
                    "duration": "indefinitely",
                    "model_conf": 185.58
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.21,
                    "duration": "indefinitely",
                    "model_conf": 112.03
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Southern College Station, TX",
            "slug": "0bfcefbd-b588-4290-a9e8-7350ac458b44"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/5/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/college-station/11655-fm-2154-suite-200/",
        "index": 103.375,
        "franchise-entity-name": "SOUTHERN COLLEGE STATION LLC"
    },
    "68359147-b692-4de5-8f58-befe20e383b8": {
        "name": "Orange Theory Colleyville, TX",
        "address": "4709 Colleyville Blvd., Suite 500 Colleyville, Texas 76034",
        "phone": "(817) 840-3400",
        "email": "studiomanager0188@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 1:30PM",
            "SUNDAY": "6:15AM - 1:30PM"
        },
        "geolocation": [
            32.8788261,
            -97.156403
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.71,
                    "duration": "1 Months",
                    "model_conf": 147.86
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.26,
                    "duration": "indefinitely",
                    "model_conf": 183.63
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.89,
                    "duration": "indefinitely",
                    "model_conf": 111.46
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Colleyville, TX",
            "slug": "68359147-b692-4de5-8f58-befe20e383b8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/14/14",
        "url": "https://www.orangetheory.com/en-us/locations/texas/colleyville/4709-colleyville-blvd-suite-500/",
        "index": 93.4,
        "franchise-entity-name": "WOOD FITNESS, INC."
    },
    "68cda7fb-792e-40d9-ada3-1e932d648c2a": {
        "name": "Orange Theory Daly City, CA",
        "address": "51 Colma Blvd Colma, California 94014",
        "phone": "(650) 389-9988",
        "email": "studiomanager0983@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            37.6784096,
            -122.46803
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.26,
                    "duration": "2 Months",
                    "model_conf": 152.88
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.7,
                    "duration": "indefinitely",
                    "model_conf": 183.47
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.85,
                    "duration": "indefinitely",
                    "model_conf": 110.61
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Daly City, CA",
            "slug": "68cda7fb-792e-40d9-ada3-1e932d648c2a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/6/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/colma/51-colma-blvd/",
        "index": 111.325,
        "franchise-entity-name": "Santara Daly City LLC"
    },
    "65313379-0852-4d46-8835-1533c02f9d64": {
        "name": "Orange Theory Broadmoor-Colorado Springs, CO",
        "address": "104 East Cheyenne Road Colorado Springs, Colorado 80906",
        "phone": "(720) 386-5817",
        "email": "studiomanager1438@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            38.806942,
            -104.82438
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.44,
                    "duration": "indefinitely",
                    "model_conf": 150.24
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.61,
                    "duration": "1 Months",
                    "model_conf": 183.62
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.18,
                    "duration": "indefinitely",
                    "model_conf": 110.94
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Broadmoor-Colorado Springs, CO",
            "slug": "65313379-0852-4d46-8835-1533c02f9d64"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/8/22",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/colorado-springs/104-east-cheyenne-road-suite-120/",
        "index": 98.425,
        "franchise-entity-name": "WSH Broadmoor, LLC"
    },
    "49f2b120-9791-4626-b4b3-8d9c11cf3295": {
        "name": "Orange Theory Colorado Springs Northgate",
        "address": "13492 Bass Pro Dr. Colorado Springs, Colorado 80921",
        "phone": "(719) 425-4253",
        "email": "studiomanager1023@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            39.0268173,
            -104.82523
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.67,
                    "duration": "6 Months",
                    "model_conf": 148.56
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.74,
                    "duration": "indefinitely",
                    "model_conf": 182.18
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.88,
                    "duration": "2 Months",
                    "model_conf": 110.44
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Colorado Springs Northgate",
            "slug": "49f2b120-9791-4626-b4b3-8d9c11cf3295"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/colorado-springs/13492-bass-pro-dr/",
        "index": 98.425,
        "franchise-entity-name": "NORTHGATE FITNESS, LLC"
    },
    "77d3ac32-47df-4866-a50e-4a1dfc2e2002": {
        "name": "Orange Theory Colorado Springs - Stetson Hills, CO",
        "address": "5990 Stetson Hills Blvd. Colorado Springs, Colorado 80923",
        "phone": "(719) 632-3777",
        "email": "studiomanager0164@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:15AM - 12:00PM",
            "SUNDAY": "6:15AM - 12:00PM"
        },
        "geolocation": [
            38.9132996,
            -104.71659
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 75.75,
                    "duration": "indefinitely",
                    "model_conf": 109.42
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 122.77,
                    "duration": "indefinitely",
                    "model_conf": 158.3
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 176.63,
                    "duration": "5 Months",
                    "model_conf": 100.84
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Colorado Springs - Stetson Hills, CO",
            "slug": "77d3ac32-47df-4866-a50e-4a1dfc2e2002"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/colorado-springs/5990-stetson-hills-blvd/",
        "index": 98.425,
        "franchise-entity-name": "CIRCLE ONE NINE, LLC"
    },
    "d1c1ef24-5283-4993-a233-9c77f4253996": {
        "name": "Orange Theory Colorado Springs - Academy, CO",
        "address": "7469 N Academy Blvd Colorado Springs, Colorado 80920",
        "phone": "(719) 204-4442",
        "email": "studiomanager0502@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:15PM",
            "SUNDAY": "6:30AM - 12:15PM"
        },
        "geolocation": [
            38.9391022,
            -104.79748
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.81,
                    "duration": "5 Months",
                    "model_conf": 128.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.38,
                    "duration": "indefinitely",
                    "model_conf": 170.73
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.13,
                    "duration": "1 Months",
                    "model_conf": 106.03
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Colorado Springs - Academy, CO",
            "slug": "d1c1ef24-5283-4993-a233-9c77f4253996"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/colorado-springs/7469-n-academy-blvd/",
        "index": 98.425,
        "franchise-entity-name": "CIRCLING AGAIN, LLC"
    },
    "1b74e44b-7b3d-4627-9f63-e4b59105996d": {
        "name": "Orange Theory Columbia, MD",
        "address": "6181 Old Dobbin Ln Suite 700 Columbia, Maryland 21045",
        "phone": "(443) 393-7778",
        "email": "studiomanager0839@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:15PM",
            "TUESDAY": "5:00AM - 9:15PM",
            "WEDNESDAY": "5:00AM - 9:15PM",
            "THURSDAY": "5:00AM - 9:15PM",
            "FRIDAY": "5:00AM - 9:15PM",
            "SATURDAY": "6:00AM - 1:15PM",
            "SUNDAY": "6:00AM - 1:15PM"
        },
        "geolocation": [
            39.2004356,
            -76.812088
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.2,
                    "duration": "indefinitely",
                    "model_conf": 152.62
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.02,
                    "duration": "indefinitely",
                    "model_conf": 185.06
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.27,
                    "duration": "indefinitely",
                    "model_conf": 110.89
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Columbia, MD",
            "slug": "1b74e44b-7b3d-4627-9f63-e4b59105996d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/18",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/columbia/6181-old-dobbin-ln-suite-700/",
        "index": 108.325,
        "franchise-entity-name": "COLUMBIA FITNESS PARTNERS, LLC"
    },
    "336ebbcb-3fba-4e58-9753-329c4f63c6d0": {
        "name": "Orange Theory NE Columbia, SC",
        "address": "10296 Two Notch Rd., Suite 2 Columbia, South Carolina 29229",
        "phone": "(803) 486-2206",
        "email": "studiomanager0881@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.1242905,
            -80.873085
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.0,
                    "duration": "indefinitely",
                    "model_conf": 151.93
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.5,
                    "duration": "indefinitely",
                    "model_conf": 185.01
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.62,
                    "duration": "indefinitely",
                    "model_conf": 111.23
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "NE Columbia, SC",
            "slug": "336ebbcb-3fba-4e58-9753-329c4f63c6d0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/columbia/10296-two-notch-rd-suite-2/",
        "index": 93.4,
        "franchise-entity-name": "JM NE COLUMBIA LLC"
    },
    "d180603d-a93d-47b3-a601-fcb14532ccc9": {
        "name": "Orange Theory Downtown Columbia, SC",
        "address": "940 Harden Street Columbia, South Carolina 29205",
        "phone": "(803) 324-6655",
        "email": "studiomanager1525@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 5:30PM",
            "TUESDAY": "5:00AM - 5:30PM",
            "WEDNESDAY": "5:00AM - 5:30PM",
            "THURSDAY": "5:00AM - 5:30PM",
            "FRIDAY": "5:00AM - 5:30PM",
            "SATURDAY": "7:30AM - 12:30PM",
            "SUNDAY": "7:30AM - 12:30PM"
        },
        "geolocation": [
            34.003053,
            -81.01741
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.64,
                    "duration": "indefinitely",
                    "model_conf": 149.68
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.22,
                    "duration": "2 Months",
                    "model_conf": 185.41
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.21,
                    "duration": "indefinitely",
                    "model_conf": 111.84
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Downtown Columbia, SC",
            "slug": "d180603d-a93d-47b3-a601-fcb14532ccc9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/31/22",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/columbia/940-harden-street/",
        "index": 93.4,
        "franchise-entity-name": "JM DOWNTOWN COLUMBIA LLC"
    },
    "133fce2b-c3af-4485-8587-88332d44bbb1": {
        "name": "Orange Theory Columbus, GA",
        "address": "5592-B Whitesville Road Columbus, Georgia 31909",
        "phone": "(706) 905-1870",
        "email": "studiomanager0299@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:30PM",
            "SUNDAY": "7:30AM - 12:30PM"
        },
        "geolocation": [
            32.5214958,
            -84.958908
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.91,
                    "duration": "5 Months",
                    "model_conf": 152.43
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.2,
                    "duration": "indefinitely",
                    "model_conf": 184.3
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.48,
                    "duration": "1 Months",
                    "model_conf": 111.95
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Columbus, GA",
            "slug": "133fce2b-c3af-4485-8587-88332d44bbb1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/16/16",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/columbus/5592-b-whitesville-road/",
        "index": 98.425,
        "franchise-entity-name": "B.O.B. FITNESS LLC"
    },
    "c89f8e79-26a5-485a-8657-372e4a650e7a": {
        "name": "Orange Theory Grandview, OH",
        "address": "1009 W. 5th Avenue Columbus, Ohio 43212",
        "phone": "(614) 305-7555",
        "email": "studiomanager0470@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            39.9881897,
            -83.0327
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.95,
                    "duration": "indefinitely",
                    "model_conf": 108.38
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.62,
                    "duration": "1 Months",
                    "model_conf": 131.38
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.09,
                    "duration": "indefinitely",
                    "model_conf": 80.5
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Grandview, OH",
            "slug": "c89f8e79-26a5-485a-8657-372e4a650e7a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/19/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/columbus/1009-w-5th-avenue/",
        "index": 93.4,
        "franchise-entity-name": "FIT PATH, LLC"
    },
    "85ef7181-acc1-4e06-a47a-d86117ba1be7": {
        "name": "Orange Theory Upper Arlington, OH",
        "address": "3096 KINGSDALE CTR Columbus, Ohio 43221",
        "phone": "(614) 441-9657",
        "email": "studiomanager1140@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.020492,
            -83.060122
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.14,
                    "duration": "4 Months",
                    "model_conf": 128.48
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.97,
                    "duration": "indefinitely",
                    "model_conf": 155.35
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.67,
                    "duration": "indefinitely",
                    "model_conf": 94.5
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Upper Arlington, OH",
            "slug": "85ef7181-acc1-4e06-a47a-d86117ba1be7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/28/21",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/columbus/3096-kingsdale-ctr/",
        "index": 93.4,
        "franchise-entity-name": "YOUNG FITNESS 4, LLC"
    },
    "56b1f3d0-4c3e-4bf9-9a09-40a3455ef179": {
        "name": "Orange Theory Clintonville, OH",
        "address": "3383 N High Street Columbus, Ohio 43202",
        "phone": "(614) 481-0101",
        "email": "studiomanager0955@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            40.03111,
            -83.01593
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.16,
                    "duration": "4 Months",
                    "model_conf": 128.52
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.92,
                    "duration": "indefinitely",
                    "model_conf": 155.51
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.97,
                    "duration": "indefinitely",
                    "model_conf": 94.65
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Clintonville, OH",
            "slug": "56b1f3d0-4c3e-4bf9-9a09-40a3455ef179"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/7/20",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/columbus/3383-n-high-street/",
        "index": 93.4,
        "franchise-entity-name": "TSSP, LLC"
    },
    "a0c7e7e7-f582-42b3-8e73-187c0a9c9f43": {
        "name": "Orange Theory Blacklick, OH",
        "address": "6819 E Broad Street Columbus, Ohio 43213",
        "phone": "(614) 427-0121",
        "email": "studiomanager0608@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.9806709,
            -82.819832
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.53,
                    "duration": "2 Months",
                    "model_conf": 149.19
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.77,
                    "duration": "indefinitely",
                    "model_conf": 181.19
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.41,
                    "duration": "1 Months",
                    "model_conf": 109.31
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Blacklick, OH",
            "slug": "a0c7e7e7-f582-42b3-8e73-187c0a9c9f43"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/8/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/columbus/6819-e-broad-street/",
        "index": 93.4,
        "franchise-entity-name": "MCB FITNESS 2 LLC"
    },
    "d06d0bfa-8ca1-4ed3-9c1b-984791b41737": {
        "name": "Orange Theory Commerce Township, MI",
        "address": "39700 West 14 Mile Road Commerce Charter Township, Michigan 48390",
        "phone": "(248) 716-3710",
        "email": "studiomanager0675@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            42.528605,
            -83.439632
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.02,
                    "duration": "indefinitely",
                    "model_conf": 137.23
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.04,
                    "duration": "indefinitely",
                    "model_conf": 164.84
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.1,
                    "duration": "indefinitely",
                    "model_conf": 100.83
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Commerce Township, MI",
            "slug": "d06d0bfa-8ca1-4ed3-9c1b-984791b41737"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/commerce-township/39700-west-14-mile-rd/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS MICHIGAN, LLC - 0675"
    },
    "5fcabd38-070c-4805-9114-a7e68d4faf7a": {
        "name": "Orange Theory Concord, CA",
        "address": "5100 Clayton Road, Suite B3 Concord, California 94521",
        "phone": "(925) 332-7280",
        "email": "studiomanager0412@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            37.9550247,
            -121.96776
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.86,
                    "duration": "indefinitely",
                    "model_conf": 153.13
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.57,
                    "duration": "1 Months",
                    "model_conf": 181.8
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.61,
                    "duration": "indefinitely",
                    "model_conf": 110.67
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Concord, CA",
            "slug": "5fcabd38-070c-4805-9114-a7e68d4faf7a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/concord/5100-clayton-road-suite-b3/",
        "index": 108.325,
        "franchise-entity-name": "ROCK STAR ENTERPRISES LLC"
    },
    "11470a94-56ec-4984-8ff5-d0a1707c7cb7": {
        "name": "Orange Theory Concord, MA",
        "address": "80 Beharrell Street, Suite C Concord, Massachusetts 01742",
        "phone": "(978) 776-6434",
        "email": "studiomanager0816@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:45AM - 6:45PM",
            "TUESDAY": "5:45AM - 6:45PM",
            "WEDNESDAY": "5:45AM - 6:45PM",
            "THURSDAY": "5:45AM - 6:45PM",
            "FRIDAY": "5:45AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            42.4581871,
            -71.394592
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.98,
                    "duration": "indefinitely",
                    "model_conf": 153.35
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.3,
                    "duration": "2 Months",
                    "model_conf": 185.16
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.89,
                    "duration": "indefinitely",
                    "model_conf": 111.73
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Concord, MA",
            "slug": "11470a94-56ec-4984-8ff5-d0a1707c7cb7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/concord/80-beharrell-street-suite-c/",
        "index": 103.375,
        "franchise-entity-name": "JDH FITNESS INC"
    },
    "f34f9d49-8d05-4c05-a61b-77759e4a4d67": {
        "name": "Orange Theory Concord, NH",
        "address": "80 Storrs Street Concord, New Hampshire 03301",
        "phone": "(603) 783-5628",
        "email": "studiomanager1367@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:15AM - 10:30AM",
            "SUNDAY": "8:15AM - 10:30AM"
        },
        "geolocation": [
            43.203846,
            -71.534729
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.15,
                    "duration": "indefinitely",
                    "model_conf": 153.82
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.9,
                    "duration": "indefinitely",
                    "model_conf": 185.84
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.05,
                    "duration": "indefinitely",
                    "model_conf": 112.01
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Concord, NH",
            "slug": "f34f9d49-8d05-4c05-a61b-77759e4a4d67"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/3/20",
        "url": "https://www.orangetheory.com/en-us/locations/new-hampshire/concord/50-storrs-street-unit-4/",
        "index": 98.425,
        "franchise-entity-name": "DK 1 LLC"
    },
    "9d10ae37-79f3-4fb7-85d0-1f98207b195b": {
        "name": "Orange Theory Conroe, TX",
        "address": "2105 W Davis St Suite B Conroe, Texas 77304",
        "phone": "(936) 270-8757",
        "email": "studiomanager0966@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "8:15AM - 12:15PM",
            "SUNDAY": "8:15AM - 12:15PM"
        },
        "geolocation": [
            30.316576,
            -95.477936
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.02,
                    "duration": "4 Months",
                    "model_conf": 152.33
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.29,
                    "duration": "indefinitely",
                    "model_conf": 184.61
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.85,
                    "duration": "2 Months",
                    "model_conf": 111.61
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Conroe, TX",
            "slug": "9d10ae37-79f3-4fb7-85d0-1f98207b195b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/12/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/conroe/2105-w-davis-st-suite-b/",
        "index": 98.425,
        "franchise-entity-name": "RDK FITNESS CONROE LLC"
    },
    "f8eac547-d92f-4b5e-a64f-35d960681add": {
        "name": "Orange Theory Conshohocken, PA",
        "address": "48 E RIDGE PIKE Conshohocken, Pennsylvania 19428",
        "phone": "(484) 773-1422",
        "email": "studiomanager0732@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            40.09302,
            -75.28075
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.47,
                    "duration": "indefinitely",
                    "model_conf": 150.63
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.32,
                    "duration": "2 Months",
                    "model_conf": 183.12
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.11,
                    "duration": "indefinitely",
                    "model_conf": 109.22
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Conshohocken, PA",
            "slug": "f8eac547-d92f-4b5e-a64f-35d960681add"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/25/21",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/conshohocken/48-e-ridge-pike-suite-b3/",
        "index": 103.375,
        "franchise-entity-name": "P&amp;L FITNESS 1, LLC"
    },
    "36992807-3d73-4d4d-9d53-bb9e4f1ee681": {
        "name": "Orange Theory Coon Rapids, MN",
        "address": "12617 Riverdale Blvd NW Coon Rapids, Minnesota 55448",
        "phone": "(763) 244-1994",
        "email": "studiomanager0976@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            45.2002525,
            -93.350578
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.2,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.44,
                    "duration": "3 Months",
                    "model_conf": 152.37
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.05,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.18,
                    "duration": "indefinitely",
                    "model_conf": 183.9
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.33,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.0,
                    "duration": "2 Months",
                    "model_conf": 110.58
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Coon Rapids, MN",
            "slug": "36992807-3d73-4d4d-9d53-bb9e4f1ee681"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/14/20",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/coon-rapids/12617-riverdale-blvd-nw/",
        "index": 88.45,
        "franchise-entity-name": "COON RAPIDS FITNESS, LLC"
    },
    "1b9deb0b-5e65-4317-a256-3c92c72ca8dd": {
        "name": "Orange Theory Cooper City, FL",
        "address": "5874 S Flamingo Road Cooper City, Florida 33330",
        "phone": "(954) 434-6244",
        "email": "studiomanager0172@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            26.0466595,
            -80.311317
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.23,
                    "duration": "indefinitely",
                    "model_conf": 145.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.64,
                    "duration": "4 Months",
                    "model_conf": 181.45
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.06,
                    "duration": "indefinitely",
                    "model_conf": 110.36
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cooper City, FL",
            "slug": "1b9deb0b-5e65-4317-a256-3c92c72ca8dd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/25/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/cooper-city/5874-s-flamingo-road/",
        "index": 98.425,
        "franchise-entity-name": "NEC FITNESS, LLC"
    },
    "fa5bfde8-d4fd-4c8e-a471-9119631e97e8": {
        "name": "Orange Theory Copley",
        "address": "4161 Heritage Center Dr. Copley, Ohio 44321",
        "phone": "(330) 801-6404",
        "email": "studiomanager1197@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:45AM - 12:45PM",
            "SUNDAY": "6:45AM - 12:45PM"
        },
        "geolocation": [
            41.1346474,
            -81.659737
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.21,
                    "duration": "indefinitely",
                    "model_conf": 152.77
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.23,
                    "duration": "indefinitely",
                    "model_conf": 184.67
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.01,
                    "duration": "indefinitely",
                    "model_conf": 110.71
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Copley",
            "slug": "fa5bfde8-d4fd-4c8e-a471-9119631e97e8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/31/19",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/copley/4161-heritage-center-dr/",
        "index": 93.4,
        "franchise-entity-name": "FIT FOR FIFTH AVENUE LLC"
    },
    "0ac852dc-f223-4a1a-9684-ae79ee8cc06f": {
        "name": "Orange Theory Coppell, TX",
        "address": "240 N. Denton Tap Road Suite 440 Coppell, Texas 75019",
        "phone": "(214) 300-9983",
        "email": "studiomanager0138@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:15PM",
            "TUESDAY": "5:00AM - 9:15PM",
            "WEDNESDAY": "5:00AM - 9:15PM",
            "THURSDAY": "5:00AM - 9:15PM",
            "FRIDAY": "5:00AM - 9:15PM",
            "SATURDAY": "6:15AM - 1:15PM",
            "SUNDAY": "6:15AM - 1:15PM"
        },
        "geolocation": [
            32.9744606,
            -96.992996
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.59,
                    "duration": "2 Months",
                    "model_conf": 144.37
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.42,
                    "duration": "indefinitely",
                    "model_conf": 180.4
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.34,
                    "duration": "1 Months",
                    "model_conf": 109.72
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Coppell, TX",
            "slug": "0ac852dc-f223-4a1a-9684-ae79ee8cc06f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/4/14",
        "url": "https://www.orangetheory.com/en-us/locations/texas/coppell/240-n-denton-tap-road-suite-440/",
        "index": 93.4,
        "franchise-entity-name": "K &amp; M FITNESS I, LLC"
    },
    "ff2d144f-69c4-4145-9cdd-bd41b48fdf82": {
        "name": "Orange Theory Coral Gables, FL",
        "address": "2300 Salzedo St Coral Gables, Florida 33134",
        "phone": "(305) 460-6006",
        "email": "studiomanager0077@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:00PM",
            "TUESDAY": "5:30AM - 8:00PM",
            "WEDNESDAY": "5:30AM - 8:00PM",
            "THURSDAY": "5:30AM - 8:00PM",
            "FRIDAY": "5:30AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            25.75107,
            -80.260765
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.31,
                    "duration": "indefinitely",
                    "model_conf": 141.35
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.04,
                    "duration": "indefinitely",
                    "model_conf": 181.93
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.86,
                    "duration": "indefinitely",
                    "model_conf": 102.58
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Coral Gables, FL",
            "slug": "ff2d144f-69c4-4145-9cdd-bd41b48fdf82"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/24/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/coral-gables/2300-salzedo-st/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS LLC"
    },
    "0af5ef43-47b9-473d-b0ae-59ffc3a79c0b": {
        "name": "Orange Theory Coral Springs - South, FL",
        "address": "1308 Coral Ridge Drive Coral Springs, Florida 33071",
        "phone": "(954) 800-2721",
        "email": "studiomanager0106@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:35PM",
            "TUESDAY": "5:00AM - 8:35PM",
            "WEDNESDAY": "5:00AM - 8:35PM",
            "THURSDAY": "5:00AM - 8:35PM",
            "FRIDAY": "5:00AM - 8:35PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            26.2476521,
            -80.280281
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.29,
                    "duration": "3 Months",
                    "model_conf": 147.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.05,
                    "duration": "indefinitely",
                    "model_conf": 182.78
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.33,
                    "duration": "1 Months",
                    "model_conf": 109.26
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Coral Springs - South, FL",
            "slug": "0af5ef43-47b9-473d-b0ae-59ffc3a79c0b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/coral-springs/1308-coral-ridge-drive/",
        "index": 98.425,
        "franchise-entity-name": "CORAL SPRINGS FITNESS PARTNERS, INC."
    },
    "0969bbe8-8f6d-4762-8bd8-8925d6035e7d": {
        "name": "Orange Theory Parkland - Coral Springs, FL",
        "address": "6230 Coral Ridge Drive Coral Springs, Florida 33076",
        "phone": "(954) 345-7518",
        "email": "studiomanager0028@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            26.3076038,
            -80.280357
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.33,
                    "duration": "3 Months",
                    "model_conf": 148.36
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.69,
                    "duration": "indefinitely",
                    "model_conf": 183.23
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.08,
                    "duration": "1 Months",
                    "model_conf": 109.25
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Parkland - Coral Springs, FL",
            "slug": "0969bbe8-8f6d-4762-8bd8-8925d6035e7d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/14/12",
        "url": "https://www.orangetheory.com/en-us/locations/florida/coral-springs/6230-coral-ridge-drive/",
        "index": 98.425,
        "franchise-entity-name": "KB ORANGE GROUP, INC."
    },
    "c1efd752-b3c7-4dfa-94d2-739ce063833d": {
        "name": "Orange Theory Coralville, IA",
        "address": "1303 5th Street Coralville, Iowa 52241",
        "phone": "(319) 800-6282",
        "email": "studiomanager0867@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            41.676609,
            -91.581192
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 152.05
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.84
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Coralville, IA",
            "slug": "c1efd752-b3c7-4dfa-94d2-739ce063833d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/iowa/coralville/1303-5th-street/",
        "index": 93.4,
        "franchise-entity-name": "FITNESS IC INC"
    },
    "c611ef1c-2e26-4158-804e-1ec71b7a15fc": {
        "name": "Orange Theory Robinson-North Fayette, PA",
        "address": "630 Chauvet Dr Coraopolis, Pennsylvania 15275",
        "phone": "(412) 206-5680",
        "email": "studiomanager0869@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:15AM - 12:15PM",
            "SUNDAY": "8:15AM - 12:15PM"
        },
        "geolocation": [
            40.4475632,
            -80.1819
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.5,
                    "duration": "2 Months",
                    "model_conf": 153.24
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.37,
                    "duration": "indefinitely",
                    "model_conf": 184.12
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.19,
                    "duration": "indefinitely",
                    "model_conf": 111.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Robinson-North Fayette, PA",
            "slug": "c611ef1c-2e26-4158-804e-1ec71b7a15fc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/30/17",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/coraopolis/630-chauvet-dr/",
        "index": 93.4,
        "franchise-entity-name": "TANGERINE RETAIL VENTURES III, LLC"
    },
    "2d29586b-62bb-4098-bd91-e17d17e5aac0": {
        "name": "Orange Theory Lake Norman - Cornelius, NC",
        "address": "20619 Torrence Chapel Rd. Cornelius, North Carolina 28031",
        "phone": "(704) 964-2557",
        "email": "studiomanager0389@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            35.4842606,
            -80.878845
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.57,
                    "duration": "indefinitely",
                    "model_conf": 150.34
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.61,
                    "duration": "1 Months",
                    "model_conf": 184.38
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.18,
                    "duration": "indefinitely",
                    "model_conf": 111.22
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lake Norman - Cornelius, NC",
            "slug": "2d29586b-62bb-4098-bd91-e17d17e5aac0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/4/15",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/cornelius/20619-torrence-chapel-rd/",
        "index": 98.425,
        "franchise-entity-name": "KMOD FIT, LLC"
    },
    "06305eaf-7007-4fe5-8c20-5cc449a13383": {
        "name": "Orange Theory Corona Del Mar, CA",
        "address": "3021 E Pacific Coast Hwy. Corona Del Mar, California 92625",
        "phone": "(949) 491-8700",
        "email": "studiomanager0500@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:20PM",
            "TUESDAY": "5:00AM - 7:20PM",
            "WEDNESDAY": "5:00AM - 7:20PM",
            "THURSDAY": "5:00AM - 7:20PM",
            "FRIDAY": "5:00AM - 7:20PM",
            "SATURDAY": "8:00AM - 12:30PM",
            "SUNDAY": "8:00AM - 12:30PM"
        },
        "geolocation": [
            33.5985832,
            -117.87196
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.17,
                    "duration": "indefinitely",
                    "model_conf": 147.25
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.74,
                    "duration": "indefinitely",
                    "model_conf": 183.05
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.07,
                    "duration": "indefinitely",
                    "model_conf": 110.48
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Corona Del Mar, CA",
            "slug": "06305eaf-7007-4fe5-8c20-5cc449a13383"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/corona-del-mar/3021-e-pacific-coast-hwy/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT CDM, LLC"
    },
    "1794587d-b411-4583-adcc-27535368f32c": {
        "name": "Orange Theory Corona, CA",
        "address": "2540 Tuscany Street, #102-103 Corona, California 92881",
        "phone": "(951) 683-9662",
        "email": "studiomanager0469@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            33.8241119,
            -117.51695
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.33,
                    "duration": "indefinitely",
                    "model_conf": 151.13
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.7,
                    "duration": "indefinitely",
                    "model_conf": 184.16
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.39,
                    "duration": "indefinitely",
                    "model_conf": 110.31
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Corona, CA",
            "slug": "1794587d-b411-4583-adcc-27535368f32c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/24/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/corona/2540-tuscany-street-102-103/",
        "index": 103.375,
        "franchise-entity-name": "INLAND FITNESS CORONA, INC."
    },
    "089e247b-4b47-4369-bced-c08371380546": {
        "name": "Orange Theory Corpus Christi, TX",
        "address": "4938 South Staples St., Suite C-18 Corpus Christi, Texas 78412",
        "phone": "(361) 500-6015",
        "email": "studiomanager1139@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            27.709926,
            -97.367084
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.66,
                    "duration": "indefinitely",
                    "model_conf": 151.43
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.29,
                    "duration": "2 Months",
                    "model_conf": 185.59
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.63,
                    "duration": "indefinitely",
                    "model_conf": 111.13
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Corpus Christi, TX",
            "slug": "089e247b-4b47-4369-bced-c08371380546"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/29/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/corpus-christi/4938-south-staples-st-suite-c-18/",
        "index": 93.4,
        "franchise-entity-name": "5A FITNESS CORPUS CHRISTI #1, L.L.C."
    },
    "d6f1a80c-c4a8-4bab-bb1a-cbe7d1b9276a": {
        "name": "Orange Theory Corvallis, OR",
        "address": "1838 NW 9th St., Suite 112 Corvallis, Oregon 97330",
        "phone": "(541) 716-5006",
        "email": "studiomanager0756@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            44.5859489,
            -123.25652
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.96,
                    "duration": "indefinitely",
                    "model_conf": 153.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.01,
                    "duration": "indefinitely",
                    "model_conf": 185.84
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.98,
                    "duration": "indefinitely",
                    "model_conf": 111.71
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Corvallis, OR",
            "slug": "d6f1a80c-c4a8-4bab-bb1a-cbe7d1b9276a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/corvallis/1838-nw-9th-st-suite-112/",
        "index": 98.425,
        "franchise-entity-name": "ICON ACTIVE LLC"
    },
    "001a50da-023b-41b8-8243-921086a263af": {
        "name": "Orange Theory Cottage Grove, MN",
        "address": "8621 East Point Douglas Road Cottage Grove, Minnesota 55016",
        "phone": "(612) 276-6701",
        "email": "studiomanager1069@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:40PM",
            "TUESDAY": "5:00AM - 7:40PM",
            "WEDNESDAY": "5:00AM - 7:40PM",
            "THURSDAY": "5:00AM - 7:40PM",
            "FRIDAY": "5:00AM - 7:40PM",
            "SATURDAY": "6:30AM - 11:00AM",
            "SUNDAY": "6:30AM - 11:00AM"
        },
        "geolocation": [
            44.8167305,
            -92.93161
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.2,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 60.02,
                    "duration": "indefinitely",
                    "model_conf": 153.18
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.05,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.23,
                    "duration": "indefinitely",
                    "model_conf": 184.64
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.32,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.56,
                    "duration": "indefinitely",
                    "model_conf": 111.2
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Cottage Grove, MN",
            "slug": "001a50da-023b-41b8-8243-921086a263af"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/18",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/cottage-grove/8621-east-point-douglas-road/",
        "index": 88.45,
        "franchise-entity-name": "COTTAGE GROVE FITNESS LLC"
    },
    "75976563-814a-44a7-9ba9-3805d45872f6": {
        "name": "Orange Theory Cottonwood Heights, UT",
        "address": "6953 S 1300 E. Cottonwood Heights, Utah 84121",
        "phone": "(801) 463-6748",
        "email": "studiomanager0226@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            40.6249809,
            -111.85504
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.41,
                    "duration": "indefinitely",
                    "model_conf": 135.39
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.52,
                    "duration": "1 Months",
                    "model_conf": 178.2
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.66,
                    "duration": "indefinitely",
                    "model_conf": 99.6
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Cottonwood Heights, UT",
            "slug": "75976563-814a-44a7-9ba9-3805d45872f6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/25/15",
        "url": "https://www.orangetheory.com/en-us/locations/utah/cottonwood-heights/6953-s-1300-e/",
        "index": 93.4,
        "franchise-entity-name": "HEARTZONE FITNESS III LLC"
    },
    "0b9bb90f-0870-4bee-8b4a-32e86d140c46": {
        "name": "Orange Theory Cranston, RI",
        "address": "4 Chapel View Blvd Cranston, Rhode Island 02920",
        "phone": "(401) 830-2812",
        "email": "studiomanager0742@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.7559319,
            -71.461021
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.57,
                    "duration": "indefinitely",
                    "model_conf": 147.19
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.25,
                    "duration": "2 Months",
                    "model_conf": 168.12
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.37,
                    "duration": "indefinitely",
                    "model_conf": 96.88
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cranston, RI",
            "slug": "0b9bb90f-0870-4bee-8b4a-32e86d140c46"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/26/16",
        "url": "https://www.orangetheory.com/en-us/locations/rhode-island/cranston/4-chapel-view-boulevard/",
        "index": 98.425,
        "franchise-entity-name": "FITNESS CAPITAL VENTURES #2, LLC"
    },
    "90bc2446-c77a-4658-8ab2-3f9d3823d049": {
        "name": "Orange Theory Crown Point, IN",
        "address": "852 N Superior Dr Crown Point, Indiana 46307",
        "phone": "(219) 306-8448",
        "email": "studiomanager0680@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:30PM",
            "SUNDAY": "6:00AM - 12:30PM"
        },
        "geolocation": [
            41.4258614,
            -87.336609
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.19,
                    "duration": "indefinitely",
                    "model_conf": 153.85
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.2,
                    "duration": "indefinitely",
                    "model_conf": 184.37
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.09
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Crown Point, IN",
            "slug": "90bc2446-c77a-4658-8ab2-3f9d3823d049"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/crown-point/852-n-superior-dr/",
        "index": 93.4,
        "franchise-entity-name": "CO-OP FITNESS NWI LLC"
    },
    "42d11a97-4c55-4a6f-ac9f-f74e939ef78a": {
        "name": "Orange Theory Culver City, CA",
        "address": "8770 Washington Blvd Suite D Culver City, California 90232",
        "phone": "(424) 666-2525",
        "email": "studiomanager0477@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            34.0283356,
            -118.38568
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 100.33,
                    "duration": "indefinitely",
                    "model_conf": 149.26
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 147.81,
                    "duration": "3 Months",
                    "model_conf": 181.4
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.71,
                    "duration": "indefinitely",
                    "model_conf": 108.68
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Culver City, CA",
            "slug": "42d11a97-4c55-4a6f-ac9f-f74e939ef78a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/12/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/culver-city/8770-washington-blvd/",
        "index": 111.325,
        "franchise-entity-name": "WCF CULVER CITY LLC"
    },
    "838fabac-73d3-474b-b09a-8c9205f9d334": {
        "name": "Orange Theory Cumming, GA",
        "address": "1735 Buford Highway, Suite 220 Cumming, Georgia 30041",
        "phone": "(770) 887-2223",
        "email": "studiomanager0300@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:30PM",
            "SUNDAY": "7:30AM - 12:30PM"
        },
        "geolocation": [
            34.1635742,
            -84.114784
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.59,
                    "duration": "2 Months",
                    "model_conf": 148.16
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.47,
                    "duration": "indefinitely",
                    "model_conf": 183.55
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.52,
                    "duration": "1 Months",
                    "model_conf": 109.04
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cumming, GA",
            "slug": "838fabac-73d3-474b-b09a-8c9205f9d334"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/31/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/cumming/1735-buford-highway-suite-220/",
        "index": 98.425,
        "franchise-entity-name": "NSU FITNESS, LLC"
    },
    "bd13275a-29e8-40e8-8cd0-583b6a296d26": {
        "name": "Orange Theory Cupertino, CA",
        "address": "19409 Stevens Creek Blvd., Ste 120 Cupertino, California 95014",
        "phone": "(408) 663-5175",
        "email": "studiomanager0216@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:15AM - 11:45PM",
            "SUNDAY": "8:15AM - 11:45PM"
        },
        "geolocation": [
            37.3238602,
            -122.00939
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.31,
                    "duration": "indefinitely",
                    "model_conf": 153.34
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.63,
                    "duration": "indefinitely",
                    "model_conf": 175.93
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.11,
                    "duration": "indefinitely",
                    "model_conf": 110.6
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Cupertino, CA",
            "slug": "bd13275a-29e8-40e8-8cd0-583b6a296d26"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/23/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/cupertino/19409-stevens-creek-blvd-suite-120/",
        "index": 111.325,
        "franchise-entity-name": "MKS PARTNERS TWO, LLC"
    },
    "6705a7e3-ae29-4af8-aace-3ffb85cd7fbb": {
        "name": "Orange Theory Cutler Bay, FL",
        "address": "18455 South Dixie Hwy Cutler Bay, Florida 33157",
        "phone": "(305) 234-1141",
        "email": "studiomanager0337@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            25.5979881,
            -80.35437
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.53,
                    "duration": "indefinitely",
                    "model_conf": 149.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.47,
                    "duration": "1 Months",
                    "model_conf": 183.48
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.39,
                    "duration": "indefinitely",
                    "model_conf": 108.54
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Cutler Bay, FL",
            "slug": "6705a7e3-ae29-4af8-aace-3ffb85cd7fbb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/3/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/cutler-bay/18455-south-dixie-hwy/",
        "index": 93.4,
        "franchise-entity-name": "I &amp; R FITNESS LLC"
    },
    "9cb3d001-0d7b-4142-8e1b-2458f21512df": {
        "name": "Orange Theory Cypress, CA",
        "address": "6911 Katella Ave. Cypress, California 90630",
        "phone": "(657) 777-4151",
        "email": "studiomanager1015@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:40PM",
            "TUESDAY": "5:00AM - 7:40PM",
            "WEDNESDAY": "5:00AM - 7:40PM",
            "THURSDAY": "5:00AM - 7:40PM",
            "FRIDAY": "5:00AM - 7:40PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            33.8040619,
            -118.01175
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.41,
                    "duration": "indefinitely",
                    "model_conf": 151.11
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.27,
                    "duration": "2 Months",
                    "model_conf": 171.43
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.46,
                    "duration": "indefinitely",
                    "model_conf": 100.66
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Cypress, CA",
            "slug": "9cb3d001-0d7b-4142-8e1b-2458f21512df"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/14/20",
        "url": "https://www.orangetheory.com/en-us/locations/california/cypress/6911-katella-avenue/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT CYP LLC"
    },
    "33558c21-cc77-4b12-a455-9d609d9bb8d9": {
        "name": "Orange Theory Fairfield, TX",
        "address": "28404 Highway 290, Suite G21 Cypress, Texas 77433",
        "phone": "(281) 810-8343",
        "email": "studiomanager1080@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            29.9917641,
            -95.7435
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.35,
                    "duration": "indefinitely",
                    "model_conf": 151.98
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.68,
                    "duration": "1 Months",
                    "model_conf": 183.92
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.95,
                    "duration": "indefinitely",
                    "model_conf": 111.21
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fairfield, TX",
            "slug": "33558c21-cc77-4b12-a455-9d609d9bb8d9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/10/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/cypress/28404-highway-290-suite-g21/",
        "index": 98.425,
        "franchise-entity-name": "Fairfield Coaching LLC"
    },
    "b40d41cb-3a7f-4790-a0f4-9a989c29c942": {
        "name": "Orange Theory Cypress - Towne Lake, TX",
        "address": "9925 Barker Cypress Rd., Suite 143 Cypress, Texas 77433",
        "phone": "(281) 661-1006",
        "email": "studiomanager0405@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            29.9224682,
            -95.686409
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.43,
                    "duration": "indefinitely",
                    "model_conf": 150.32
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.74,
                    "duration": "indefinitely",
                    "model_conf": 182.06
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.24,
                    "duration": "indefinitely",
                    "model_conf": 109.92
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cypress - Towne Lake, TX",
            "slug": "b40d41cb-3a7f-4790-a0f4-9a989c29c942"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/7/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/cypress/9925-barker-cypress-rd-suite-143/",
        "index": 98.425,
        "franchise-entity-name": "TOWNE LAKE COACHING LLC"
    },
    "5dc87c45-50be-4871-939c-b5f70c3f9734": {
        "name": "Orange Theory Buford - Hamilton Mill, GA",
        "address": "2463 Hamilton Mill Parkway Dacula, Georgia 30019",
        "phone": "(470) 443-1800",
        "email": "studiomanager0317@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.0734253,
            -83.915436
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.99,
                    "duration": "indefinitely",
                    "model_conf": 151.57
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.16,
                    "duration": "indefinitely",
                    "model_conf": 184.85
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.3,
                    "duration": "indefinitely",
                    "model_conf": 107.18
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Buford - Hamilton Mill, GA",
            "slug": "5dc87c45-50be-4871-939c-b5f70c3f9734"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/18/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/dacula/2463-hamilton-mill-parkway/",
        "index": 98.425,
        "franchise-entity-name": "JM SUGAR HILL LLC"
    },
    "831204cf-9f35-4b1c-a1de-8cf13cb3ddcb": {
        "name": "Orange Theory Preston-Frankford, TX",
        "address": "18101 Preston Road, Unit 105 Dallas, Texas 75252",
        "phone": "(972) 244-4111",
        "email": "studiomanager0139@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            32.9986992,
            -96.798821
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.44,
                    "duration": "8 Months",
                    "model_conf": 108.86
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 111.01,
                    "duration": "indefinitely",
                    "model_conf": 130.35
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.72,
                    "duration": "2 Months",
                    "model_conf": 83.0
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Preston-Frankford, TX",
            "slug": "831204cf-9f35-4b1c-a1de-8cf13cb3ddcb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/10/14",
        "url": "https://www.orangetheory.com/en-us/locations/texas/dallas/18101-preston-road-unit-105/",
        "index": 93.4,
        "franchise-entity-name": "K &amp; M FITNESS II, LLC"
    },
    "2d61a874-3ca5-4c5e-bda2-d7ee88d0a6f0": {
        "name": "Orange Theory Lakewood, TX",
        "address": "1911 Abrams Rd. Dallas, Texas 75214",
        "phone": "(972) 474-0401",
        "email": "studiomanager1018@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 9:15PM",
            "TUESDAY": "4:30AM - 9:15PM",
            "WEDNESDAY": "4:30AM - 9:15PM",
            "THURSDAY": "4:30AM - 9:15PM",
            "FRIDAY": "4:30AM - 9:15PM",
            "SATURDAY": "6:00AM - 2:15PM",
            "SUNDAY": "6:00AM - 2:15PM"
        },
        "geolocation": [
            32.8129578,
            -96.752274
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.96,
                    "duration": "4 Months",
                    "model_conf": 146.2
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.19,
                    "duration": "indefinitely",
                    "model_conf": 173.7
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.34,
                    "duration": "1 Months",
                    "model_conf": 108.13
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Lakewood, TX",
            "slug": "2d61a874-3ca5-4c5e-bda2-d7ee88d0a6f0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/12/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/dallas/1911-abrams-rd/",
        "index": 103.375,
        "franchise-entity-name": "VALENCIA FITNESS - WHITE ROCK, LLC"
    },
    "fce6ee04-e704-4e4e-a68f-b0469d4a964e": {
        "name": "Orange Theory Uptown, TX",
        "address": "4447 N. Central expy. Suite 100 Dallas, Texas 75205",
        "phone": "(972) 474-0382",
        "email": "studiomanager1029@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 8:45PM",
            "TUESDAY": "4:30AM - 8:45PM",
            "WEDNESDAY": "4:30AM - 8:45PM",
            "THURSDAY": "4:30AM - 8:45PM",
            "FRIDAY": "4:30AM - 8:45PM",
            "SATURDAY": "6:45AM - 3:15PM",
            "SUNDAY": "6:45AM - 3:15PM"
        },
        "geolocation": [
            32.8204155,
            -96.787613
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.85,
                    "duration": "5 Months",
                    "model_conf": 144.45
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.5,
                    "duration": "indefinitely",
                    "model_conf": 170.54
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.18,
                    "duration": "1 Months",
                    "model_conf": 107.18
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Uptown, TX",
            "slug": "fce6ee04-e704-4e4e-a68f-b0469d4a964e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/dallas/4447-n-central-expy-suite-100/",
        "index": 103.375,
        "franchise-entity-name": "VALENCIA FITNESS UPTOWN, LLC"
    },
    "22ed85f9-0ed4-4b63-a17c-9325df179eeb": {
        "name": "Orange Theory Addison, TX",
        "address": "5290 Belt Line Road, Suite 156 Dallas, Texas 75254",
        "phone": "(214) 758-0338",
        "email": "studiomanager0140@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 2:30PM",
            "SUNDAY": "7:30AM - 2:30PM"
        },
        "geolocation": [
            32.9530907,
            -96.81694
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.17,
                    "duration": "8 Months",
                    "model_conf": 131.85
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 131.24,
                    "duration": "indefinitely",
                    "model_conf": 158.72
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.62,
                    "duration": "2 Months",
                    "model_conf": 97.1
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Addison, TX",
            "slug": "22ed85f9-0ed4-4b63-a17c-9325df179eeb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/17/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/dallas/5290-belt-line-road-suite-156/",
        "index": 103.375,
        "franchise-entity-name": "K &amp; M FITNESS III, LLC"
    },
    "64226f32-01da-4118-baaa-3da81143ea84": {
        "name": "Orange Theory Lovers Lane, TX",
        "address": "5549 W. Lovers Lane Dallas, Texas 75209",
        "phone": "(972) 474-0725",
        "email": "studiomanager1423@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:45AM - 7:45PM",
            "TUESDAY": "5:45AM - 7:45PM",
            "WEDNESDAY": "5:45AM - 7:45PM",
            "THURSDAY": "5:45AM - 7:45PM",
            "FRIDAY": "5:45AM - 7:45PM",
            "SATURDAY": "7:45AM - 1:30PM",
            "SUNDAY": "7:45AM - 1:30PM"
        },
        "geolocation": [
            32.8515472,
            -96.817833
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.51,
                    "duration": "2 Months",
                    "model_conf": 142.31
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.63,
                    "duration": "indefinitely",
                    "model_conf": 167.48
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.28,
                    "duration": "1 Months",
                    "model_conf": 105.53
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Lovers Lane, TX",
            "slug": "64226f32-01da-4118-baaa-3da81143ea84"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/20",
        "url": "https://www.orangetheory.com/en-us/locations/texas/dallas/5549-w-lovers-lane/",
        "index": 103.375,
        "franchise-entity-name": "Valencia Fitness-Dallas Lovers Lane, LLC"
    },
    "98055c83-d0d3-4203-9b1b-61f6d2e2c731": {
        "name": "Orange Theory North Dallas",
        "address": "6065 Forest Lane Dallas, Texas 75230",
        "phone": "(972) 474-0720",
        "email": "studiomanager1407@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:45AM - 6:45PM",
            "TUESDAY": "5:45AM - 6:45PM",
            "WEDNESDAY": "5:45AM - 6:45PM",
            "THURSDAY": "5:45AM - 6:45PM",
            "FRIDAY": "5:45AM - 6:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            32.9100723,
            -96.802155
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.49,
                    "duration": "6 Months",
                    "model_conf": 135.53
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 131.2,
                    "duration": "indefinitely",
                    "model_conf": 160.46
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.25,
                    "duration": "1 Months",
                    "model_conf": 100.03
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "North Dallas",
            "slug": "98055c83-d0d3-4203-9b1b-61f6d2e2c731"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/20",
        "url": "https://www.orangetheory.com/en-us/locations/texas/dallas/6065-forest-lane/",
        "index": 103.375,
        "franchise-entity-name": "Valencia Fitness-North Dallas, LLC"
    },
    "06b4695e-b5dd-4b6e-8c99-e6d2ce3b035d": {
        "name": "Orange Theory Dana Point,CA",
        "address": "24040 Camino Del Avion Space 2A Dana Point, California 92629",
        "phone": "(949) 617-2717",
        "email": "studiomanager0253@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:10PM",
            "TUESDAY": "5:00AM - 7:10PM",
            "WEDNESDAY": "5:00AM - 7:10PM",
            "THURSDAY": "5:00AM - 7:10PM",
            "FRIDAY": "5:00AM - 7:10PM",
            "SATURDAY": "6:30AM - 11:30AM",
            "SUNDAY": "6:30AM - 11:30AM"
        },
        "geolocation": [
            33.4890251,
            -117.70918
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.3,
                    "duration": "indefinitely",
                    "model_conf": 143.75
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.45,
                    "duration": "1 Months",
                    "model_conf": 183.63
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.07,
                    "duration": "indefinitely",
                    "model_conf": 111.34
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Dana Point,CA",
            "slug": "06b4695e-b5dd-4b6e-8c99-e6d2ce3b035d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/27/21",
        "url": "https://www.orangetheory.com/en-us/locations/california/dana-point/24040-camino-del-avion-space-2-a/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT DP, LLC"
    },
    "a7afbfc2-2160-4c89-a019-067bd3953be6": {
        "name": "Orange Theory Danbury East, CT",
        "address": "7 Eagle Road Danbury, Connecticut 06810",
        "phone": "(203) 409-2020",
        "email": "studiomanager0808@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            41.4105034,
            -73.415726
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.75,
                    "duration": "indefinitely",
                    "model_conf": 153.44
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.01,
                    "duration": "3 Months",
                    "model_conf": 183.67
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.44,
                    "duration": "indefinitely",
                    "model_conf": 111.97
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Danbury East, CT",
            "slug": "a7afbfc2-2160-4c89-a019-067bd3953be6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/danbury/7-eagle-road/",
        "index": 98.425,
        "franchise-entity-name": "TL FITNESS, LLC"
    },
    "71c7fa92-a5c2-45fe-b4e2-794ef33eea8c": {
        "name": "Orange Theory Danvers, MA",
        "address": "35 Independence Way Danvers, Massachusetts 01923",
        "phone": "(978) 381-4332",
        "email": "studiomanager0689@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            42.5500755,
            -70.939461
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.35,
                    "duration": "indefinitely",
                    "model_conf": 151.34
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.33,
                    "duration": "2 Months",
                    "model_conf": 182.38
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.4,
                    "duration": "indefinitely",
                    "model_conf": 111.39
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Danvers, MA",
            "slug": "71c7fa92-a5c2-45fe-b4e2-794ef33eea8c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/danvers/35-independence-way/",
        "index": 103.375,
        "franchise-entity-name": "HH FITNESS DANVERS, LLC"
    },
    "219ff803-fc75-4484-a566-907a29d05560": {
        "name": "Orange Theory Danville, CA",
        "address": "409 Railroad Avenue Danville, California 94526",
        "phone": "(925) 553-4777",
        "email": "studiomanager1119@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 6:30PM",
            "TUESDAY": "6:15AM - 6:30PM",
            "WEDNESDAY": "6:15AM - 6:30PM",
            "THURSDAY": "6:15AM - 6:30PM",
            "FRIDAY": "6:15AM - 6:30PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            37.8191338,
            -121.99776
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.31,
                    "duration": "indefinitely",
                    "model_conf": 152.18
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.16,
                    "duration": "indefinitely",
                    "model_conf": 181.44
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.87,
                    "duration": "indefinitely",
                    "model_conf": 110.47
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Danville, CA",
            "slug": "219ff803-fc75-4484-a566-907a29d05560"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/danville/409-railroad-avenue/",
        "index": 108.325,
        "franchise-entity-name": "TKV4 LLC"
    },
    "b3ed6599-0885-44b6-9650-60895d2a1e0a": {
        "name": "Orange Theory Daphne, AL",
        "address": "6900 US HWY 90 Daphne, Alabama 36526",
        "phone": "(251) 626-3767",
        "email": "studiomanager0877@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            30.6540966,
            -87.903763
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.99,
                    "duration": "indefinitely",
                    "model_conf": 153.7
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.2,
                    "duration": "indefinitely",
                    "model_conf": 181.03
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.27,
                    "duration": "1 Months",
                    "model_conf": 112.0
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Daphne, AL",
            "slug": "b3ed6599-0885-44b6-9650-60895d2a1e0a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/daphne/6900-us-hwy-90/",
        "index": 93.4,
        "franchise-entity-name": "GRIFFIT INC."
    },
    "fab5f6c9-892d-44e7-a029-2c2359d658c1": {
        "name": "Orange Theory Darien",
        "address": "364 Boston Post Road Darien, Connecticut 06820",
        "phone": "(203) 309-1669",
        "email": "studiomanager0872@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.0860901,
            -73.460831
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.33,
                    "duration": "indefinitely",
                    "model_conf": 121.28
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 122.48,
                    "duration": "indefinitely",
                    "model_conf": 147.37
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.35,
                    "duration": "3 Months",
                    "model_conf": 89.29
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Darien",
            "slug": "fab5f6c9-892d-44e7-a029-2c2359d658c1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/16/19",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/darien/364-boston-post-road/",
        "index": 98.425,
        "franchise-entity-name": "VO2 MAX TWO, LLC"
    },
    "e3ea7584-3d0c-4b0f-901f-24865d876f97": {
        "name": "Orange Theory Davenport, IA",
        "address": "4520 E. 53 St. Davenport, Iowa 52807",
        "phone": "(563) 594-6683",
        "email": "studiomanager0854@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:15AM - 12:00PM",
            "SUNDAY": "6:15AM - 12:00PM"
        },
        "geolocation": [
            41.5751,
            -90.505508
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.58,
                    "duration": "2 Months",
                    "model_conf": 150.34
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.84,
                    "duration": "indefinitely",
                    "model_conf": 183.32
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.77,
                    "duration": "indefinitely",
                    "model_conf": 106.45
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Davenport, IA",
            "slug": "e3ea7584-3d0c-4b0f-901f-24865d876f97"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/26/17",
        "url": "https://www.orangetheory.com/en-us/locations/iowa/davenport/4520-e-53-st/",
        "index": 93.4,
        "franchise-entity-name": "IAFIT DAVENPORT LLC"
    },
    "0fa24b4b-99d1-4836-96c5-65ffefd6feac": {
        "name": "Orange Theory DeLand, FL",
        "address": "2365 South Woodland Boulevard Deland, Florida 32720",
        "phone": "(386) 951-3455",
        "email": "studiomanager1413@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            28.9871235,
            -81.299881
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.9,
                    "duration": "indefinitely",
                    "model_conf": 153.46
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.0,
                    "duration": "indefinitely",
                    "model_conf": 185.43
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.69,
                    "duration": "indefinitely",
                    "model_conf": 111.92
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "DeLand, FL",
            "slug": "0fa24b4b-99d1-4836-96c5-65ffefd6feac"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/25/20",
        "url": "https://www.orangetheory.com/en-us/locations/florida/deland/2365-south-woodland-boulevard/",
        "index": 98.425,
        "franchise-entity-name": "Florida Fitness Works 6, LLC"
    },
    "ce74bac6-cea8-44d9-9814-bd80e148f231": {
        "name": "Orange Theory Dearborn, MI",
        "address": "22001 Michigan Ave, Suite 180 Dearborn, Michigan 48126",
        "phone": "(313) 986-1000",
        "email": "studiomanager1046@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 8:00PM",
            "TUESDAY": "4:45AM - 8:00PM",
            "WEDNESDAY": "4:45AM - 8:00PM",
            "THURSDAY": "4:45AM - 8:00PM",
            "FRIDAY": "4:45AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            42.3060684,
            -83.244133
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.13,
                    "duration": "indefinitely",
                    "model_conf": 153.31
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.04,
                    "duration": "indefinitely",
                    "model_conf": 185.37
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.16,
                    "duration": "indefinitely",
                    "model_conf": 111.57
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Dearborn, MI",
            "slug": "ce74bac6-cea8-44d9-9814-bd80e148f231"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/2/19",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/dearborn/22001-michigan-ave-suite-180/",
        "index": 98.425,
        "franchise-entity-name": "RMS FITNESS LLC"
    },
    "bc0acd51-298c-483b-9324-b28d789b8f26": {
        "name": "Orange Theory Decatur, GA",
        "address": "319 W. Ponce de Leon Ave Decatur, Georgia 30030",
        "phone": "(770) 797-5080",
        "email": "studiomanager0383@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            33.775692,
            -84.300636
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.06,
                    "duration": "4 Months",
                    "model_conf": 133.38
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.11,
                    "duration": "indefinitely",
                    "model_conf": 166.95
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.0,
                    "duration": "2 Months",
                    "model_conf": 98.18
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Decatur, GA",
            "slug": "bc0acd51-298c-483b-9324-b28d789b8f26"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/11/16",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/decatur/319-w-ponce-de-leon-ave/",
        "index": 98.425,
        "franchise-entity-name": "EFFECTIVE FITNESS DECATUR, LLC"
    },
    "dc669e7d-1b9a-4f83-80d6-2256a4630efd": {
        "name": "Orange Theory Dedham, MA",
        "address": "610 Providence Highway Dedham, Massachusetts 02026",
        "phone": "(617) 307-7260",
        "email": "studiomanager0443@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            42.2434845,
            -71.174416
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.94,
                    "duration": "indefinitely",
                    "model_conf": 142.23
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.01,
                    "duration": "indefinitely",
                    "model_conf": 180.78
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.75,
                    "duration": "indefinitely",
                    "model_conf": 109.31
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Dedham, MA",
            "slug": "dc669e7d-1b9a-4f83-80d6-2256a4630efd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/18/15",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/dedham/610-providence-highway/",
        "index": 103.375,
        "franchise-entity-name": "THREE BLIND MICE LLC"
    },
    "cd7ccb23-bd97-4eec-a99b-f5facf417d08": {
        "name": "Orange Theory Delafield, WI",
        "address": "3149 Golf Rd Delafield, Wisconsin 53018",
        "phone": "(262) 510-0566",
        "email": "studiomanager0096@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "6:00AM - 10:45AM",
            "SUNDAY": "6:00AM - 10:45AM"
        },
        "geolocation": [
            43.0532913,
            -88.366379
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.58,
                    "duration": "indefinitely",
                    "model_conf": 153.27
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.62,
                    "duration": "1 Months",
                    "model_conf": 184.06
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.73,
                    "duration": "indefinitely",
                    "model_conf": 111.09
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Delafield, WI",
            "slug": "cd7ccb23-bd97-4eec-a99b-f5facf417d08"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/6/16",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/delafield/3149-golf-rd/",
        "index": 93.4,
        "franchise-entity-name": "OT WISCONSIN DELAFIELD, LLC"
    },
    "004d1e46-4e9d-4911-ae29-f0af4681c35e": {
        "name": "Orange Theory Delray Beach North, FL",
        "address": "264-278 SE 5th Avenue Delray Beach, Florida 33483",
        "phone": "(561) 675-0600",
        "email": "studiomanager1333@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:30PM",
            "TUESDAY": "6:00AM - 7:30PM",
            "WEDNESDAY": "6:00AM - 7:30PM",
            "THURSDAY": "6:00AM - 7:30PM",
            "FRIDAY": "6:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            26.4566975,
            -80.06823
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.0,
                    "duration": "indefinitely",
                    "model_conf": 150.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.99,
                    "duration": "indefinitely",
                    "model_conf": 183.3
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.73,
                    "duration": "indefinitely",
                    "model_conf": 110.22
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Delray Beach North, FL",
            "slug": "004d1e46-4e9d-4911-ae29-f0af4681c35e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/13/20",
        "url": "https://www.orangetheory.com/en-us/locations/florida/delray-beach/264-278-se-5th-avenue/",
        "index": 98.425,
        "franchise-entity-name": "DRBNORTE, LLC"
    },
    "5f30273e-e535-4486-835c-afce6f1ba5f7": {
        "name": "Orange Theory Delray Beach -East, FL",
        "address": "3100 S Federal Hwy #1 Delray Beach, Florida 33483",
        "phone": "(561) 274-2550",
        "email": "studiomanager0012@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            26.4262981,
            -80.07328
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.35,
                    "duration": "indefinitely",
                    "model_conf": 149.06
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.38,
                    "duration": "2 Months",
                    "model_conf": 182.87
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.03,
                    "duration": "indefinitely",
                    "model_conf": 109.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Delray Beach -East, FL",
            "slug": "5f30273e-e535-4486-835c-afce6f1ba5f7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/23/13",
        "url": "https://www.orangetheory.com/en-us/locations/florida/delray-beach/3100-s-federal-hwy-1/",
        "index": 98.425,
        "franchise-entity-name": "OTPBC1, LLC"
    },
    "2ba85c04-db4a-454d-bff9-daeb93d96154": {
        "name": "Orange Theory Delray Beach - West, FL",
        "address": "9097 West Atlantic Avenue, Suite 110 Delray Beach, Florida 33446",
        "phone": "(561) 376-1127",
        "email": "studiomanager0698@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            26.4546413,
            -80.190819
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.22,
                    "duration": "indefinitely",
                    "model_conf": 150.72
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.57,
                    "duration": "1 Months",
                    "model_conf": 182.89
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.97,
                    "duration": "indefinitely",
                    "model_conf": 110.11
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Delray Beach - West, FL",
            "slug": "2ba85c04-db4a-454d-bff9-daeb93d96154"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/9/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/delray-beach/9097-west-atlantic-avenue-suite-110/",
        "index": 98.425,
        "franchise-entity-name": "WEST DELRAY FIT LLC"
    },
    "538320e6-d0b0-4daa-afbb-b418738438c1": {
        "name": "Orange Theory Texoma - Sherman, TX",
        "address": "5101 Gateway Blvd Denison, Texas 75020",
        "phone": "(903) 677-4390",
        "email": "studiomanager1558@orangetheory.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            33.709598,
            -96.592893
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.11,
                    "duration": "indefinitely",
                    "model_conf": 153.65
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.17,
                    "duration": "indefinitely",
                    "model_conf": 185.54
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.12,
                    "duration": "indefinitely",
                    "model_conf": 112.06
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Texoma - Sherman, TX",
            "slug": "538320e6-d0b0-4daa-afbb-b418738438c1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "08/01/23",
        "url": "https://www.orangetheory.com/en-us/locations/texas/sherman/gym-near-you-in-sherman-tx/",
        "index": 93.4,
        "franchise-entity-name": ""
    },
    "27e31d11-e5b4-4270-b27c-69e68c04ccce": {
        "name": "Orange Theory Union Station/Denver, CO",
        "address": "1400 Wewatta St, Suite 110 Denver, Colorado 80202",
        "phone": "(303) 573-3925",
        "email": "studiomanager0129@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            39.7507896,
            -105.00343
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.7,
                    "duration": "indefinitely",
                    "model_conf": 141.82
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.98,
                    "duration": "3 Months",
                    "model_conf": 175.27
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.44,
                    "duration": "indefinitely",
                    "model_conf": 105.04
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Union Station/Denver, CO",
            "slug": "27e31d11-e5b4-4270-b27c-69e68c04ccce"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/14",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/denver/1400-wewatta-st-suite-110/",
        "index": 98.425,
        "franchise-entity-name": "DR FITNESS LLC"
    },
    "e8bfaa32-2e49-46f8-90d2-96df691feed2": {
        "name": "Orange Theory Lowry, CO",
        "address": "200 Quebec, Bldg. 700, Unit 101 Denver, Colorado 80230",
        "phone": "(303) 366-3311",
        "email": "studiomanager0063@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            39.7204399,
            -104.90268
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.97,
                    "duration": "indefinitely",
                    "model_conf": 139.58
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.67,
                    "duration": "1 Months",
                    "model_conf": 170.9
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.15,
                    "duration": "indefinitely",
                    "model_conf": 102.11
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lowry, CO",
            "slug": "e8bfaa32-2e49-46f8-90d2-96df691feed2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/13/13",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/denver/200-quebec-bldg-700-unit-101/",
        "index": 98.425,
        "franchise-entity-name": "WSH LOWRY, LLC"
    },
    "790c128e-7a85-4e00-bd21-b0a2fb785acb": {
        "name": "Orange Theory Cherry Creek, CO",
        "address": "2780 E. Second Avenue Denver, Colorado 80206",
        "phone": "(720) 767-1557",
        "email": "studiomanager1235@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 6:45PM",
            "TUESDAY": "6:15AM - 6:45PM",
            "WEDNESDAY": "6:15AM - 6:45PM",
            "THURSDAY": "6:15AM - 6:45PM",
            "FRIDAY": "6:15AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.7192955,
            -104.9548
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.54,
                    "duration": "indefinitely",
                    "model_conf": 135.62
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.16,
                    "duration": "2 Months",
                    "model_conf": 167.91
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.44,
                    "duration": "indefinitely",
                    "model_conf": 99.66
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cherry Creek, CO",
            "slug": "790c128e-7a85-4e00-bd21-b0a2fb785acb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/7/19",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/denver/2780-e-second-avenue/",
        "index": 98.425,
        "franchise-entity-name": "WSH CHERRY CREEK, LLC - 1235"
    },
    "ab8f73e6-541e-4277-808a-67d68214a960": {
        "name": "Orange Theory Highlands - Denver, CO",
        "address": "3300 W 32nd Avenue, Ste 101 Denver, Colorado 80211",
        "phone": "(720) 409-9509",
        "email": "studiomanager0019@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            39.7619667,
            -105.03008
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.1,
                    "duration": "indefinitely",
                    "model_conf": 144.11
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.63,
                    "duration": "1 Months",
                    "model_conf": 177.47
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.94,
                    "duration": "indefinitely",
                    "model_conf": 106.69
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Highlands - Denver, CO",
            "slug": "ab8f73e6-541e-4277-808a-67d68214a960"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/30/12",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/denver/3300-w-32nd-avenue-ste-101/",
        "index": 98.425,
        "franchise-entity-name": "DR HOLDINGS, LLC"
    },
    "683ed6a1-3621-4df5-8419-7121c8e86509": {
        "name": "Orange Theory Baker Denver, CO",
        "address": "381 S Broadway, Suite A Denver, Colorado 80209",
        "phone": "(720) 408-0333",
        "email": "studiomanager1236@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:15AM - 1:00PM",
            "SUNDAY": "8:15AM - 1:00PM"
        },
        "geolocation": [
            39.7096443,
            -104.98785
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.48,
                    "duration": "indefinitely",
                    "model_conf": 136.21
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.22,
                    "duration": "2 Months",
                    "model_conf": 168.54
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.34,
                    "duration": "indefinitely",
                    "model_conf": 100.55
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Baker Denver, CO",
            "slug": "683ed6a1-3621-4df5-8419-7121c8e86509"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/28/19",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/denver/381-s-broadway-suite-a/",
        "index": 98.425,
        "franchise-entity-name": "WSH WEST WASHINGTON PARK, LLC"
    },
    "31a7840f-96e2-4ed0-9fc8-8879652fd5b6": {
        "name": "Orange Theory Uptown Denver, CO",
        "address": "450 E. 17th Ave., Suite 128 Denver, Colorado 80203",
        "phone": "(720) 485-4518",
        "email": "studiomanager0559@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "7:30AM - 1:30PM",
            "SUNDAY": "7:30AM - 1:30PM"
        },
        "geolocation": [
            39.7429886,
            -104.98145
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.82,
                    "duration": "indefinitely",
                    "model_conf": 140.29
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.89,
                    "duration": "3 Months",
                    "model_conf": 173.56
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.61,
                    "duration": "indefinitely",
                    "model_conf": 103.65
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Uptown Denver, CO",
            "slug": "31a7840f-96e2-4ed0-9fc8-8879652fd5b6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/30/16",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/denver/450-e-17th-ave-suite-128/",
        "index": 98.425,
        "franchise-entity-name": "WSH DENVER UPTOWN, LLC"
    },
    "0ab0dd85-9cc4-4694-84b0-79d8aec735cf": {
        "name": "Orange Theory Denver - Cherry Hills, CO",
        "address": "5010 E Hampden Avenue Denver, Colorado 80222",
        "phone": "(303) 757-8888",
        "email": "studiomanager0041@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.6529007,
            -104.92877
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.75,
                    "duration": "1 Months",
                    "model_conf": 124.44
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.06,
                    "duration": "indefinitely",
                    "model_conf": 149.86
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.18,
                    "duration": "indefinitely",
                    "model_conf": 91.48
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Denver - Cherry Hills, CO",
            "slug": "0ab0dd85-9cc4-4694-84b0-79d8aec735cf"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/7/14",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/denver/5010-e-hampden-avenue/",
        "index": 98.425,
        "franchise-entity-name": "GG2G, LLC"
    },
    "38b8e0fd-1dc9-468b-822c-cf1065d9f817": {
        "name": "Orange Theory Denver Tech Center, CO",
        "address": "6770 East Chenango Avenue Denver, Colorado 80237",
        "phone": "(720) 504-1015",
        "email": "studiomanager0558@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            39.624836,
            -104.90764
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.0,
                    "duration": "4 Months",
                    "model_conf": 113.43
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.03,
                    "duration": "indefinitely",
                    "model_conf": 136.38
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.52,
                    "duration": "1 Months",
                    "model_conf": 83.35
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Denver Tech Center, CO",
            "slug": "38b8e0fd-1dc9-468b-822c-cf1065d9f817"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/10/16",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/denver/6770-east-chenango-avenue/",
        "index": 98.425,
        "franchise-entity-name": "WSH DTC, LLC"
    },
    "043032f0-b8c3-4e10-a3ac-e896b389a95f": {
        "name": "Orange Theory Central Park CO",
        "address": "7349 East 29th Avenue Denver, Colorado 80238",
        "phone": "(303) 388-3600",
        "email": "studiomanager0249@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:15AM - 2:00PM",
            "SUNDAY": "6:15AM - 2:00PM"
        },
        "geolocation": [
            39.7581558,
            -104.90234
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.59,
                    "duration": "indefinitely",
                    "model_conf": 145.73
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.01,
                    "duration": "3 Months",
                    "model_conf": 177.8
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.53,
                    "duration": "indefinitely",
                    "model_conf": 106.43
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Central Park CO",
            "slug": "043032f0-b8c3-4e10-a3ac-e896b389a95f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/14",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/denver/7349-east-29th-avenue/",
        "index": 98.425,
        "franchise-entity-name": "WSH STAPLETON, LLC"
    },
    "6cbb812e-0d9b-4150-a557-74dd6681334a": {
        "name": "Orange Theory Washington Park (The Logan Collection), CO",
        "address": "999 S Logan Street, Suite 100 Denver, Colorado 80209",
        "phone": "(303) 722-0544",
        "email": "studiomanager0037@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            39.6984444,
            -104.9828
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.57,
                    "duration": "indefinitely",
                    "model_conf": 134.82
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.09,
                    "duration": "2 Months",
                    "model_conf": 166.18
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.44,
                    "duration": "indefinitely",
                    "model_conf": 99.29
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Washington Park (The Logan Collection), CO",
            "slug": "6cbb812e-0d9b-4150-a557-74dd6681334a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/31/15",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/denver/999-s-logan-street-suite-100/",
        "index": 98.425,
        "franchise-entity-name": "WASHINGTON PARK WSH, LLC"
    },
    "f79d872f-38bc-41a5-8ef1-4a875fadffa0": {
        "name": "Orange Theory Denville, NJ",
        "address": "3130 NJ-10 Denville, New Jersey 07834",
        "phone": "(973) 282-8555",
        "email": "studiomanager0781@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            40.8713722,
            -74.517349
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.03,
                    "duration": "indefinitely",
                    "model_conf": 149.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.47,
                    "duration": "indefinitely",
                    "model_conf": 173.94
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.26,
                    "duration": "indefinitely",
                    "model_conf": 107.58
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Denville, NJ",
            "slug": "f79d872f-38bc-41a5-8ef1-4a875fadffa0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/8/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/denville/3130-nj-10/",
        "index": 98.425,
        "franchise-entity-name": "HUDSON FITNESS NJ 2, LLC"
    },
    "6dd2f5b9-20e1-4828-abfc-1b55bf7918af": {
        "name": "Orange Theory Deptford, NJ",
        "address": "1500 Almonesson Rd #1 Deptford, New Jersey 08096",
        "phone": "(856) 499-0502",
        "email": "studiomanager1256@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            39.8265495,
            -75.102333
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.45,
                    "duration": "indefinitely",
                    "model_conf": 153.48
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.64,
                    "duration": "4 Months",
                    "model_conf": 183.38
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.84,
                    "duration": "indefinitely",
                    "model_conf": 110.12
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Deptford, NJ",
            "slug": "6dd2f5b9-20e1-4828-abfc-1b55bf7918af"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/29/21",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/deptford/1500-almonesson-rd/",
        "index": 98.425,
        "franchise-entity-name": "PASSION ARROW INC."
    },
    "0f393421-3872-463e-a4f0-572696fe31c6": {
        "name": "Orange Theory Des Peres, MO",
        "address": "11943 Manchester Rd Des Peres, Missouri 63131",
        "phone": "(314) 646-8691",
        "email": "studiomanager1368@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 4:00PM",
            "SUNDAY": "7:00AM - 4:00PM"
        },
        "geolocation": [
            38.602334,
            -90.433869
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.28,
                    "duration": "indefinitely",
                    "model_conf": 115.16
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.49,
                    "duration": "5 Months",
                    "model_conf": 143.7
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.41,
                    "duration": "indefinitely",
                    "model_conf": 83.81
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Des Peres, MO",
            "slug": "0f393421-3872-463e-a4f0-572696fe31c6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/10/23",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/des-peres/11943-manchester-rd/",
        "index": 93.4,
        "franchise-entity-name": "Wolverine Enterprises LLC"
    },
    "97ed65a9-71b1-40bf-9690-559f2866e053": {
        "name": "Orange Theory Destin, FL",
        "address": "4495 Furling Lane Destin, Florida 32541",
        "phone": "(850) 533-0084",
        "email": "studiomanager1085@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:15AM",
            "SUNDAY": "7:30AM - 11:15AM"
        },
        "geolocation": [
            30.387661,
            -86.404884
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.38,
                    "duration": "3 Months",
                    "model_conf": 153.5
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.18,
                    "duration": "indefinitely",
                    "model_conf": 185.66
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.29,
                    "duration": "1 Months",
                    "model_conf": 111.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Destin, FL",
            "slug": "97ed65a9-71b1-40bf-9690-559f2866e053"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/florida/destin/4495-furling-lane/",
        "index": 93.4,
        "franchise-entity-name": "ALYKAY FITNESS IV, LLC"
    },
    "461954c4-71c9-4e01-bea3-2a173178d687": {
        "name": "Orange Theory Detroit, MI",
        "address": "2911 W Grand Boulevard, Suite 128 Detroit, Michigan 48202",
        "phone": "(313) 499-0040",
        "email": "studiomanager1239@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "8:15AM - 1:30PM",
            "SUNDAY": "8:15AM - 1:30PM"
        },
        "geolocation": [
            42.368145,
            -83.08036
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.14,
                    "duration": "indefinitely",
                    "model_conf": 153.3
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.13,
                    "duration": "indefinitely",
                    "model_conf": 185.45
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.1,
                    "duration": "indefinitely",
                    "model_conf": 111.53
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Detroit, MI",
            "slug": "461954c4-71c9-4e01-bea3-2a173178d687"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/31/20",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/detroit/2911-w-grand-boulevard-suite-128/",
        "index": 98.425,
        "franchise-entity-name": "L5 Fitness Michigan, LLC - 1239"
    },
    "a7223d4c-d18c-4d59-9e78-6d0d8e72eca2": {
        "name": "Orange Theory Dominion",
        "address": "22211 W I-10 Frontage Rd Unit 1113 Dominion, Texas 78257",
        "phone": "(210) 966-8634",
        "email": "studiomanager1247@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            29.6486301,
            -98.624443
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.43,
                    "duration": "indefinitely",
                    "model_conf": 147.52
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.83,
                    "duration": "3 Months",
                    "model_conf": 178.16
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.84,
                    "duration": "indefinitely",
                    "model_conf": 110.0
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Dominion",
            "slug": "a7223d4c-d18c-4d59-9e78-6d0d8e72eca2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/dominion/22111-ih-10-w/",
        "index": 103.375,
        "franchise-entity-name": "DOMINION FITNESS, LLC"
    },
    "d995c89e-c1f0-40b9-8412-1f41be516f3d": {
        "name": "Orange Theory Doral, FL",
        "address": "5875 NW 105th Ct. Ste #106 Doral, Florida 33178",
        "phone": "(305) 468-8028",
        "email": "studiomanager0039@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 9:15PM",
            "TUESDAY": "5:30AM - 9:15PM",
            "WEDNESDAY": "5:30AM - 9:15PM",
            "THURSDAY": "5:30AM - 9:15PM",
            "FRIDAY": "5:30AM - 9:15PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            25.8099518,
            -80.368454
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.94,
                    "duration": "indefinitely",
                    "model_conf": 146.07
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.83,
                    "duration": "3 Months",
                    "model_conf": 182.25
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.27,
                    "duration": "indefinitely",
                    "model_conf": 107.91
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Doral, FL",
            "slug": "d995c89e-c1f0-40b9-8412-1f41be516f3d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/18/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/doral/3887-nw-107th-ave/",
        "index": 98.425,
        "franchise-entity-name": "RAK FITNESS II LLC"
    },
    "6389bf42-9f51-4da7-821a-190593c20c99": {
        "name": "Orange Theory Dothan, AL",
        "address": "100 Apple Avenue Suite 3 Dothan, Alabama 36303",
        "phone": "(334) 232-8993",
        "email": "studiomanager0942@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:15AM - 3:00PM",
            "SUNDAY": "8:15AM - 3:00PM"
        },
        "geolocation": [
            31.2390842,
            -85.464836
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.31,
                    "duration": "3 Months",
                    "model_conf": 153.82
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.49,
                    "duration": "indefinitely",
                    "model_conf": 185.67
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.56,
                    "duration": "1 Months",
                    "model_conf": 111.28
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Dothan, AL",
            "slug": "6389bf42-9f51-4da7-821a-190593c20c99"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/15/17",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/dothan/100-apple-avenue-suite-3/",
        "index": 93.4,
        "franchise-entity-name": "COL 317, LLC"
    },
    "369ddbc3-1093-4de1-a4ac-e26ad33d6ade": {
        "name": "Orange Theory Douglasville, GA",
        "address": "2750 Chapel Hill Road, Suite 100 &amp; 200 Douglasville, Georgia 30135",
        "phone": "(770) 755-8772",
        "email": "studiomanager0495@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            33.7308922,
            -84.735725
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.44,
                    "duration": "2 Months",
                    "model_conf": 153.71
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.69,
                    "duration": "indefinitely",
                    "model_conf": 185.35
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.61,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Douglasville, GA",
            "slug": "369ddbc3-1093-4de1-a4ac-e26ad33d6ade"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/douglasville/2750-chapel-hill-road-suite-100-200/",
        "index": 98.425,
        "franchise-entity-name": "CAP FITNESS DOUGLASVILLE, LLC LLC"
    },
    "6e287ebf-1dda-472a-9e6f-00c2fa82f8da": {
        "name": "Orange Theory Dover, DE",
        "address": "50 N Dupont Hwy Dover, Delaware 19901",
        "phone": "(302) 469-3572",
        "email": "studiomanager1347@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            39.1639977,
            -75.516991
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.39,
                    "duration": "3 Months",
                    "model_conf": 153.86
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.59,
                    "duration": "indefinitely",
                    "model_conf": 185.74
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.58,
                    "duration": "indefinitely",
                    "model_conf": 112.1
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Dover, DE",
            "slug": "6e287ebf-1dda-472a-9e6f-00c2fa82f8da"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/6/19",
        "url": "https://www.orangetheory.com/en-us/locations/delaware/dover/50-n-dupont-hwy/",
        "index": 98.425,
        "franchise-entity-name": "B&amp;B Fitness LLC"
    },
    "f69f2ce7-fb94-41dc-9d9d-51690cd3b280": {
        "name": "Orange Theory Dover, NH",
        "address": "865A Central Avenue Dover, New Hampshire 03820",
        "phone": "(603) 466-7486",
        "email": "studiomanager0940@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:15PM",
            "TUESDAY": "5:30AM - 7:15PM",
            "WEDNESDAY": "5:30AM - 7:15PM",
            "THURSDAY": "5:30AM - 7:15PM",
            "FRIDAY": "5:30AM - 7:15PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            43.2187882,
            -70.88063
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.96,
                    "duration": "indefinitely",
                    "model_conf": 153.62
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.13,
                    "duration": "indefinitely",
                    "model_conf": 185.73
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.99,
                    "duration": "indefinitely",
                    "model_conf": 112.12
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Dover, NH",
            "slug": "f69f2ce7-fb94-41dc-9d9d-51690cd3b280"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/6/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-hampshire/dover/865a-central-avenue/",
        "index": 98.425,
        "franchise-entity-name": "PERSONAL BEST - DOVER, LLC"
    },
    "9037c911-f454-4719-bd01-17a17a0e45e4": {
        "name": "Orange Theory Downtown Austin, TX",
        "address": "401 West Third Street Downtown Austin, Texas 78701",
        "phone": "(512) 807-0445",
        "email": "studiomanager1111@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            30.2660561,
            -97.74762
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.28,
                    "duration": "indefinitely",
                    "model_conf": 146.69
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.33,
                    "duration": "1 Months",
                    "model_conf": 173.67
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.53,
                    "duration": "indefinitely",
                    "model_conf": 109.3
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Downtown Austin, TX",
            "slug": "9037c911-f454-4719-bd01-17a17a0e45e4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/14/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/downtown-austin/401-west-third-street/",
        "index": 108.325,
        "franchise-entity-name": "Downtown Austin Fitness Partners LLC"
    },
    "9b4a9a2e-6c27-4904-bc12-b9941d720eff": {
        "name": "Orange Theory Downtown Indy, IN",
        "address": "91 N. New Jersey St Downtown Indianapolis, Indiana 46204",
        "phone": "(317) 912-1515",
        "email": "studiomanager1013@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "8:00AM - 1:00PM",
            "SUNDAY": "8:00AM - 1:00PM"
        },
        "geolocation": [
            39.7681885,
            -86.150742
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.89,
                    "duration": "indefinitely",
                    "model_conf": 150.76
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.0,
                    "duration": "3 Months",
                    "model_conf": 181.89
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.01,
                    "duration": "indefinitely",
                    "model_conf": 109.59
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Downtown Indy, IN",
            "slug": "9b4a9a2e-6c27-4904-bc12-b9941d720eff"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/9/18",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/downtown-indianapolis/91-n-new-jersey-st/",
        "index": 93.4,
        "franchise-entity-name": "317 FITNESS, LLC"
    },
    "13760cfb-9194-4cce-a2dd-89ab14b3fb32": {
        "name": "Orange Theory Downtown Omaha, NE",
        "address": "302 S. 11th St Downtown Omaha, Nebraska 68102",
        "phone": "(402) 915-0042",
        "email": "studiomanager1043@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.2571297,
            -95.931
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.18,
                    "duration": "4 Months",
                    "model_conf": 151.84
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.09,
                    "duration": "indefinitely",
                    "model_conf": 182.98
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.07,
                    "duration": "2 Months",
                    "model_conf": 111.41
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Downtown Omaha, NE",
            "slug": "13760cfb-9194-4cce-a2dd-89ab14b3fb32"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/12/19",
        "url": "https://www.orangetheory.com/en-us/locations/nebraska/downtown-omaha/302-s-11th-st/",
        "index": 93.4,
        "franchise-entity-name": "MZ STRONG 2, LLC"
    },
    "259ac27f-0e75-45ef-a64d-a39ba588af8f": {
        "name": "Orange Theory Downtown Wichita, KS",
        "address": "645 E DOUGLAS AVE, STE 106 Wichita, Kansas 67202",
        "phone": "(316) 867-0787",
        "email": "studiomanager1546@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "6:30AM - 11:00AM",
            "SUNDAY": "6:30AM - 11:00AM"
        },
        "geolocation": [
            37.685539,
            -97.330747
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 6.56,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.37
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 10.36,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.94,
                    "duration": "indefinitely",
                    "model_conf": 185.56
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 16.06,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.95,
                    "duration": "indefinitely",
                    "model_conf": 111.87
                }
            }
        },
        "services": {
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 34.1,
                "per": "",
                "term": "",
                "description": ""
            },
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 18.9,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 47.4,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Downtown Wichita, KS",
            "slug": "259ac27f-0e75-45ef-a64d-a39ba588af8f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/12/23",
        "url": "https://www.orangetheory.com/en-us/locations/kansas/wichita/645-e-douglas-ave-ste-106/",
        "index": 93.4,
        "franchise-entity-name": "WAYMAN FITNESS III, INC."
    },
    "191d1238-eb4c-42e4-9cf4-15f82ae6a55b": {
        "name": "Orange Theory Draper, UT",
        "address": "111 East 13800 South Draper, Utah 84020",
        "phone": "(385) 275-6698",
        "email": "studiomanager0727@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:45AM - 11:45AM",
            "SUNDAY": "6:45AM - 11:45AM"
        },
        "geolocation": [
            40.5008011,
            -111.88851
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.66,
                    "duration": "indefinitely",
                    "model_conf": 146.26
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.25,
                    "duration": "6 Months",
                    "model_conf": 181.23
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 170.1,
                    "duration": "indefinitely",
                    "model_conf": 106.61
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Draper, UT",
            "slug": "191d1238-eb4c-42e4-9cf4-15f82ae6a55b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/5/17",
        "url": "https://www.orangetheory.com/en-us/locations/utah/draper/111-east-13800-south/",
        "index": 93.4,
        "franchise-entity-name": "HEARTZONE FITNESS IV, LLC"
    },
    "2b06e706-80e1-47ea-a1d8-ccc42dba8a46": {
        "name": "Orange Theory Dublin, CA",
        "address": "5258 Dublin Blvd Dublin, California 94568",
        "phone": "(925) 587-5182",
        "email": "studiomanager0376@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:20AM - 11:50AM",
            "SUNDAY": "7:20AM - 11:50AM"
        },
        "geolocation": [
            37.7056503,
            -121.89114
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.01,
                    "duration": "indefinitely",
                    "model_conf": 151.96
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.68,
                    "duration": "indefinitely",
                    "model_conf": 180.08
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.89,
                    "duration": "indefinitely",
                    "model_conf": 109.87
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Dublin, CA",
            "slug": "2b06e706-80e1-47ea-a1d8-ccc42dba8a46"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/15/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/dublin/5258-dublin-blvd/",
        "index": 108.325,
        "franchise-entity-name": "SFFIT DUB, LLC"
    },
    "5921c9b2-5519-4b1c-9770-6c0bdc36c3b3": {
        "name": "Orange Theory Dublin Avery Sq, OH",
        "address": "6505 Perimeter Drive Dublin, Ohio 43016",
        "phone": "(614) 852-4222",
        "email": "studiomanager0709@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 12:00PM",
            "SUNDAY": "6:30AM - 12:00PM"
        },
        "geolocation": [
            40.105175,
            -83.162643
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.39,
                    "duration": "3 Months",
                    "model_conf": 147.46
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.99,
                    "duration": "indefinitely",
                    "model_conf": 176.9
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.9,
                    "duration": "indefinitely",
                    "model_conf": 107.62
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Dublin Avery Sq, OH",
            "slug": "5921c9b2-5519-4b1c-9770-6c0bdc36c3b3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/25/17",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/dublin/6505-perimeter-drive/",
        "index": 93.4,
        "franchise-entity-name": "DUBLIN 22 FITNESS CENTER, LLC"
    },
    "ba5b0517-12f3-4059-b45a-40dfb18174ce": {
        "name": "Orange Theory Dublin, OH",
        "address": "7581 Sawmill Road Dublin, Ohio 43016",
        "phone": "(614) 356-9999",
        "email": "studiomanager0148@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            40.1206093,
            -83.092178
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.36,
                    "duration": "indefinitely",
                    "model_conf": 145.86
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.43,
                    "duration": "2 Months",
                    "model_conf": 173.44
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.3,
                    "duration": "indefinitely",
                    "model_conf": 106.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Dublin, OH",
            "slug": "ba5b0517-12f3-4059-b45a-40dfb18174ce"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/9/14",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/dublin/7581-sawmill-road/",
        "index": 93.4,
        "franchise-entity-name": "DOUBLE-S P, LLC"
    },
    "261d17b9-6429-4a93-981f-978814d65115": {
        "name": "Orange Theory Dubuque, IA",
        "address": "2515 NW Arterial Dubuque, Iowa 52002",
        "phone": "(563) 557-6886",
        "email": "studiomanager1285@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            42.5187988,
            -90.742035
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.84
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.03
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Dubuque, IA",
            "slug": "261d17b9-6429-4a93-981f-978814d65115"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/10/19",
        "url": "https://www.orangetheory.com/en-us/locations/iowa/dubuque/2515-nw-arterial/",
        "index": 93.4,
        "franchise-entity-name": "IAFIT DUBUQUE LLC"
    },
    "df4a0f0f-b2e0-4aee-b0aa-47afd2706af0": {
        "name": "Orange Theory Sugarloaf Duluth, GA",
        "address": "2220 Peachtree Industrial Blvd Duluth, Georgia 30097",
        "phone": "(678) 648-5600",
        "email": "studiomanager0316@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            34.0206604,
            -84.119865
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.31,
                    "duration": "indefinitely",
                    "model_conf": 143.34
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.45,
                    "duration": "1 Months",
                    "model_conf": 182.68
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.16,
                    "duration": "indefinitely",
                    "model_conf": 104.24
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sugarloaf Duluth, GA",
            "slug": "df4a0f0f-b2e0-4aee-b0aa-47afd2706af0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/duluth/2220-peachtree-industrial-blvd/",
        "index": 98.425,
        "franchise-entity-name": "JM DULUTH, LLC"
    },
    "1ce9fa59-f94c-4d7d-b65e-8de27e89cc13": {
        "name": "Orange Theory Montclair, VA",
        "address": "3966 Fettler Park Dr Unit B Dumfries, Virginia 22025",
        "phone": "(571) 931-6333",
        "email": "studiomanager0799@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "8:00AM - 11:00AM",
            "SUNDAY": "8:00AM - 11:00AM"
        },
        "geolocation": [
            38.582545,
            -77.330515
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.58,
                    "duration": "2 Months",
                    "model_conf": 149.02
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.32,
                    "duration": "indefinitely",
                    "model_conf": 175.26
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.48,
                    "duration": "1 Months",
                    "model_conf": 107.65
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Montclair, VA",
            "slug": "1ce9fa59-f94c-4d7d-b65e-8de27e89cc13"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/25/2023",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/3966-fettler-park-dr-unit-b",
        "index": 98.425,
        "franchise-entity-name": "HART FITNESS 3 INC"
    },
    "b2bbb80e-2b85-4e4d-8ada-13bc4979ba27": {
        "name": "Orange Theory Dunwoody, GA",
        "address": "2480 Jett Ferry Road Dunwoody, Georgia 30338",
        "phone": "(678) 580-8095",
        "email": "studiomanager0731@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            33.9585571,
            -84.302902
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.31,
                    "duration": "3 Months",
                    "model_conf": 131.79
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.42,
                    "duration": "indefinitely",
                    "model_conf": 165.21
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.39,
                    "duration": "1 Months",
                    "model_conf": 95.43
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Dunwoody, GA",
            "slug": "b2bbb80e-2b85-4e4d-8ada-13bc4979ba27"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/17",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/dunwoody/2480-jett-ferry-road/",
        "index": 98.425,
        "franchise-entity-name": "VVDC DUNWOODY, LLC"
    },
    "959e80de-62fe-424f-9ad3-19f0b98c8d71": {
        "name": "Orange Theory Durham - Southwest, NC",
        "address": "1843 Martin Luther King Jr Pkwy Durham, North Carolina 27707",
        "phone": "(919) 313-4168",
        "email": "studiomanager0768@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            35.9608994,
            -78.959625
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.87,
                    "duration": "indefinitely",
                    "model_conf": 151.13
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.16,
                    "duration": "indefinitely",
                    "model_conf": 177.9
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.81,
                    "duration": "indefinitely",
                    "model_conf": 105.23
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Durham - Southwest, NC",
            "slug": "959e80de-62fe-424f-9ad3-19f0b98c8d71"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/durham/1843-martin-luther-king-jr-pkwy/",
        "index": 98.425,
        "franchise-entity-name": "JOELE FITNESS FIVE, LLC"
    },
    "e63e014a-02cd-4b39-85f7-551f04858b83": {
        "name": "Orange Theory Durham Southpoint, NC",
        "address": "202 W North Carolina 54 #503 Durham, North Carolina 27713",
        "phone": "(919) 808-1051",
        "email": "studiomanager1303@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            35.9133606,
            -78.935631
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.52,
                    "duration": "indefinitely",
                    "model_conf": 149.65
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.42,
                    "duration": "1 Months",
                    "model_conf": 177.89
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.18,
                    "duration": "indefinitely",
                    "model_conf": 106.43
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Durham Southpoint, NC",
            "slug": "e63e014a-02cd-4b39-85f7-551f04858b83"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/9/19",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/durham/202-w-north-carolina-54-503/",
        "index": 98.425,
        "franchise-entity-name": "NC01-Durham Southpoint, LCL"
    },
    "d789f17e-45ee-4c3a-b242-ba99c457dd03": {
        "name": "Orange Theory Dyer, IN",
        "address": "835 Joliet Street Dyer, Indiana 46311",
        "phone": "(219) 224-2660",
        "email": "studiomanager0679@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:30PM",
            "SUNDAY": "6:00AM - 12:30PM"
        },
        "geolocation": [
            41.4944763,
            -87.506828
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.8,
                    "duration": "1 Months",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.2,
                    "duration": "indefinitely",
                    "model_conf": 185.2
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.12,
                    "duration": "indefinitely",
                    "model_conf": 111.58
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Dyer, IN",
            "slug": "d789f17e-45ee-4c3a-b242-ba99c457dd03"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/1/16",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/dyer/835-joliet-street/",
        "index": 93.4,
        "franchise-entity-name": "CO-OP FITNESS LLC"
    },
    "853f8c0f-ff7f-4f19-b44c-d99af2fafd39": {
        "name": "Orange Theory El Dorado Hills, CA",
        "address": "4356 Town Center Blvd., suite 120 El Dorado Hills, California 95762",
        "phone": "(916) 758-2212",
        "email": "studiomanager0279@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:30PM",
            "TUESDAY": "4:45AM - 7:30PM",
            "WEDNESDAY": "4:45AM - 7:30PM",
            "THURSDAY": "4:45AM - 7:30PM",
            "FRIDAY": "4:45AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            38.6504402,
            -121.06644
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.35,
                    "duration": "7 Months",
                    "model_conf": 152.15
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.75,
                    "duration": "indefinitely",
                    "model_conf": 184.06
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.67,
                    "duration": "2 Months",
                    "model_conf": 110.81
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "El Dorado Hills, CA",
            "slug": "853f8c0f-ff7f-4f19-b44c-d99af2fafd39"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/30/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/el-dorado-hills/4356-town-center-blvd-suite-120/",
        "index": 103.375,
        "franchise-entity-name": "LECLAIR FITNESS III, LLC"
    },
    "613aa549-36a4-4227-9dd0-88156357e1d9": {
        "name": "Orange Theory Eagan, MN",
        "address": "3325 Central Park Village Dr. Suite 120 Eagan, Minnesota 55121",
        "phone": "(651) 705-0300",
        "email": "studiomanager0788@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:15AM - 12:15PM",
            "SUNDAY": "6:15AM - 12:15PM"
        },
        "geolocation": [
            44.8359337,
            -93.170151
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.2,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.71,
                    "duration": "1 Months",
                    "model_conf": 141.98
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.05,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.58,
                    "duration": "1 Months",
                    "model_conf": 178.96
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.33,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.45,
                    "duration": "1 Months",
                    "model_conf": 103.21
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.18,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 25.58,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 35.55,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Eagan, MN",
            "slug": "613aa549-36a4-4227-9dd0-88156357e1d9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/eagan/3325-central-park-village-dr-suite-120/",
        "index": 88.45,
        "franchise-entity-name": "EAGAN FITNESS LLC"
    },
    "50dd2e7c-4805-4784-94eb-f18a41389d8c": {
        "name": "Orange Theory Eagle Island, ID",
        "address": "1240 W Chinden Blvd., Suite 106 Eagle Island, Idaho 83646",
        "phone": "(208) 352-3844",
        "email": "studiomanager1405@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 7:45PM",
            "TUESDAY": "4:55AM - 7:45PM",
            "WEDNESDAY": "4:55AM - 7:45PM",
            "THURSDAY": "4:55AM - 7:45PM",
            "FRIDAY": "4:55AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            43.6641042,
            -116.40894
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 4.74,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.08,
                    "duration": "4 Months",
                    "model_conf": 151.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.08,
                    "duration": "indefinitely",
                    "model_conf": 185.11
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 10.74,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.58,
                    "duration": "indefinitely",
                    "model_conf": 111.86
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 13.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 22.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Eagle Island, ID",
            "slug": "50dd2e7c-4805-4784-94eb-f18a41389d8c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/20",
        "url": "https://www.orangetheory.com/en-us/locations/idaho/eagle-island/1240-w-chinden-blvd-suite-106/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS IDAHO, LLC"
    },
    "b781afc6-a0cf-4bf2-98f0-042697d1685e": {
        "name": "Orange Theory East Greenwich, RI",
        "address": "1191 Division Street East Greenwich, Rhode Island 02818",
        "phone": "(401) 287-2595",
        "email": "studiomanager1352@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.663971,
            -71.495865
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.67,
                    "duration": "indefinitely",
                    "model_conf": 141.68
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.93,
                    "duration": "3 Months",
                    "model_conf": 179.92
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.34,
                    "duration": "indefinitely",
                    "model_conf": 104.67
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "East Greenwich, RI",
            "slug": "b781afc6-a0cf-4bf2-98f0-042697d1685e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/13/20",
        "url": "https://www.orangetheory.com/en-us/locations/rhode-island/warwick/1200-quaker-lane/",
        "index": 98.425,
        "franchise-entity-name": "Fitness Capital Ventures #4, LLC"
    },
    "61eba128-6821-4e4c-843e-1fcc4656fb81": {
        "name": "Orange Theory East Lansing, MI",
        "address": "4790 Hagadorn Road Suite 139 East Lansing, Michigan 48823",
        "phone": "(517) 885-3150",
        "email": "studiomanager0243@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            42.7200432,
            -84.460266
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.91,
                    "duration": "indefinitely",
                    "model_conf": 151.55
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.07,
                    "duration": "indefinitely",
                    "model_conf": 185.7
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.95,
                    "duration": "indefinitely",
                    "model_conf": 111.83
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "East Lansing, MI",
            "slug": "61eba128-6821-4e4c-843e-1fcc4656fb81"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/21/17",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/east-lansing/4790-hagadorn-road-suite-139/",
        "index": 98.425,
        "franchise-entity-name": "EAST LANSING 277, LLC"
    },
    "6d6608dd-b781-41af-8332-bb9b9c431c86": {
        "name": "Orange Theory East Longmeadow, MA",
        "address": "434 North Main St East Longmeadow, Massachusetts 01028",
        "phone": "(413) 930-4500",
        "email": "studiomanager1125@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 11:00AM",
            "SUNDAY": "6:30AM - 11:00AM"
        },
        "geolocation": [
            42.0770416,
            -72.526627
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.72,
                    "duration": "1 Months",
                    "model_conf": 153.49
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.36,
                    "duration": "indefinitely",
                    "model_conf": 185.56
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.75,
                    "duration": "indefinitely",
                    "model_conf": 111.94
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "East Longmeadow, MA",
            "slug": "6d6608dd-b781-41af-8332-bb9b9c431c86"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/14/19",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/east-longmeadow/434-north-main-st/",
        "index": 98.425,
        "franchise-entity-name": "SILVERSTRAND FITNESS 1, LLC"
    },
    "33b12a3e-bbf8-44c7-8fd6-14cc86a0489c": {
        "name": "Orange Theory East Meadow, NY",
        "address": "2314A Hempstead Turnpike East Meadow, New York 11554",
        "phone": "(516) 301-5909",
        "email": "studiomanager0601@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            40.7245407,
            -73.550308
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 96.0,
                    "duration": "11 Months",
                    "model_conf": 122.21
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 142.84,
                    "duration": "indefinitely",
                    "model_conf": 147.27
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 197.04,
                    "duration": "3 Months",
                    "model_conf": 88.23
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "East Meadow, NY",
            "slug": "33b12a3e-bbf8-44c7-8fd6-14cc86a0489c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/24/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/east-meadow/2314a-hempstead-turnpike/",
        "index": 108.325,
        "franchise-entity-name": "EM FITNESS, LLC"
    },
    "5a0a8fff-4d59-4fd4-9d2a-471ba48f3de1": {
        "name": "Orange Theory East Nashville, TN",
        "address": "1214 Gallatin Ave Suite 125 East Nashville, Tennessee 37206",
        "phone": "(615) 285-8090",
        "email": "studiomanager1157@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 1:30PM",
            "SUNDAY": "7:15AM - 1:30PM"
        },
        "geolocation": [
            36.191597,
            -86.746178
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.63,
                    "duration": "indefinitely",
                    "model_conf": 149.52
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.93,
                    "duration": "3 Months",
                    "model_conf": 176.62
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.64,
                    "duration": "indefinitely",
                    "model_conf": 111.34
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "East Nashville, TN",
            "slug": "5a0a8fff-4d59-4fd4-9d2a-471ba48f3de1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/21/18",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/east-nashville/1214-gallatin-ave-suite-125/",
        "index": 93.4,
        "franchise-entity-name": "TYCEN LLC"
    },
    "5b303696-9865-45c8-b779-475af8aa7a49": {
        "name": "Orange Theory East Northport, NY",
        "address": "4000 Jericho Turnpike East Northport, New York 11731",
        "phone": "(631) 499-4500",
        "email": "studiomanager0016@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "6:15AM - 1:00PM",
            "SUNDAY": "6:15AM - 1:00PM"
        },
        "geolocation": [
            40.8390732,
            -73.315186
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.02,
                    "duration": "3 Months",
                    "model_conf": 149.01
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.46,
                    "duration": "indefinitely",
                    "model_conf": 181.11
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.51,
                    "duration": "indefinitely",
                    "model_conf": 109.8
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "East Northport, NY",
            "slug": "5b303696-9865-45c8-b779-475af8aa7a49"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/16/12",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/east-northport/4000-jericho-turnpike/",
        "index": 108.325,
        "franchise-entity-name": "ENP FITNESS LLC"
    },
    "efe9e3a9-2e83-4c83-b9c2-3904931af92a": {
        "name": "Orange Theory East Rutherford",
        "address": "60 Route 17-N East Rutherford, New Jersey 07073",
        "phone": "(201) 537-4786",
        "email": "studiomanager1400@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            40.828415,
            -74.092417
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.82,
                    "duration": "indefinitely",
                    "model_conf": 143.47
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.28,
                    "duration": "2 Months",
                    "model_conf": 173.15
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.42,
                    "duration": "indefinitely",
                    "model_conf": 106.47
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "East Rutherford",
            "slug": "efe9e3a9-2e83-4c83-b9c2-3904931af92a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/20/21",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/east-rutherford/150-route-17/",
        "index": 103.375,
        "franchise-entity-name": "EPOC Eight LLC"
    },
    "835aab45-9bd8-4adb-86c7-c61395e178a1": {
        "name": "Orange Theory Easton,PA",
        "address": "3758 Easton-Nazareth Highway Easton, Pennsylvania 18045",
        "phone": "(484) 291-1113",
        "email": "studiomanager1375@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.7166672,
            -75.283119
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.42,
                    "duration": "indefinitely",
                    "model_conf": 153.55
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.34,
                    "duration": "5 Months",
                    "model_conf": 182.73
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.93,
                    "duration": "indefinitely",
                    "model_conf": 108.87
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Easton,PA",
            "slug": "835aab45-9bd8-4adb-86c7-c61395e178a1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/2/21",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/easton/3758-easton-nazareth-highway/",
        "index": 98.425,
        "franchise-entity-name": "Valley Fitness LLC"
    },
    "a64ee6b6-5138-437c-944c-3938bd4a5db4": {
        "name": "Orange Theory Eastvale, CA",
        "address": "12459 Limonite Ave Eastvale, California 91752",
        "phone": "(909) 575-0111",
        "email": "studiomanager1559@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "5:00AM - 1:00PM",
            "SUNDAY": "5:00AM - 1:00PM"
        },
        "geolocation": [
            33.975663,
            -117.55444
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.85,
                    "duration": "indefinitely",
                    "model_conf": 152.36
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.85,
                    "duration": "indefinitely",
                    "model_conf": 183.12
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.89,
                    "duration": "indefinitely",
                    "model_conf": 110.27
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Eastvale, CA",
            "slug": "a64ee6b6-5138-437c-944c-3938bd4a5db4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/17/22",
        "url": "https://www.orangetheory.com/en-us/locations/california/eastvale/12459-limonite-avenue/",
        "index": 103.375,
        "franchise-entity-name": "MIGA DESERT CONSULTANTS, LLC"
    },
    "8429e25f-cea2-4dee-9870-2e53b16d8445": {
        "name": "Orange Theory Eden Prairie, MN",
        "address": "8264 Commonwealth Dr. Eden Prairie, Minnesota 55344",
        "phone": "(952) 232-1809",
        "email": "studiomanager0176@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            44.8540001,
            -93.433777
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.44,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.35,
                    "duration": "indefinitely",
                    "model_conf": 136.53
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.45,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.21,
                    "duration": "indefinitely",
                    "model_conf": 170.75
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.96,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.7,
                    "duration": "indefinitely",
                    "model_conf": 100.75
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Eden Prairie, MN",
            "slug": "8429e25f-cea2-4dee-9870-2e53b16d8445"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/21/15",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/eden-prairie/8264-commonwealth-dr/",
        "index": 88.45,
        "franchise-entity-name": "GO FORWARD BRANDS 2 L.L.C."
    },
    "6703c04c-719a-40f0-b186-03d92feb8a82": {
        "name": "Orange Theory Denver-Sloans Lake/SloHi, CO",
        "address": "1931-A Sheridan Blvd. Edgewater, Colorado 80214",
        "phone": "(720) 575-9366",
        "email": "studiomanager1409@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 6:45PM",
            "TUESDAY": "5:15AM - 6:45PM",
            "WEDNESDAY": "5:15AM - 6:45PM",
            "THURSDAY": "5:15AM - 6:45PM",
            "FRIDAY": "5:15AM - 6:45PM",
            "SATURDAY": "7:45AM - 12:45PM",
            "SUNDAY": "7:45AM - 12:45PM"
        },
        "geolocation": [
            39.7474657,
            -105.05437
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.07,
                    "duration": "indefinitely",
                    "model_conf": 143.46
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.55,
                    "duration": "4 Months",
                    "model_conf": 176.49
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.7,
                    "duration": "indefinitely",
                    "model_conf": 106.08
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Denver-Sloans Lake/SloHi, CO",
            "slug": "6703c04c-719a-40f0-b186-03d92feb8a82"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/25/21",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/edgewater/1931-a-sheridan-blvd/",
        "index": 98.425,
        "franchise-entity-name": "DR SLOHI, LLC"
    },
    "23687b51-a3e5-4da0-8404-c8962998619a": {
        "name": "Orange Theory Edgewater, NJ",
        "address": "725 River Road Edgewater, New Jersey 07020",
        "phone": "(201) 212-6388",
        "email": "studiomanager0045@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:00AM - 2:00PM",
            "SUNDAY": "6:00AM - 2:00PM"
        },
        "geolocation": [
            40.8200645,
            -73.977219
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.22,
                    "duration": "indefinitely",
                    "model_conf": 134.68
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.14,
                    "duration": "indefinitely",
                    "model_conf": 161.88
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.34,
                    "duration": "indefinitely",
                    "model_conf": 98.73
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Edgewater, NJ",
            "slug": "23687b51-a3e5-4da0-8404-c8962998619a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/13/13",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/edgewater/725-river-road/",
        "index": 103.375,
        "franchise-entity-name": "OTF NJ ONE LLC"
    },
    "6712b90b-d0ba-4a2e-ba83-5976e75e1107": {
        "name": "Orange Theory Edina, MN",
        "address": "3523 W 70th St. Edina, Minnesota 55435",
        "phone": "(612) 930-4630",
        "email": "studiomanager0276@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:15AM - 12:15PM",
            "SUNDAY": "6:15AM - 12:15PM"
        },
        "geolocation": [
            44.8763008,
            -93.326004
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.29,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.85,
                    "duration": "indefinitely",
                    "model_conf": 119.86
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.2,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.69,
                    "duration": "1 Months",
                    "model_conf": 157.1
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.57,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.18,
                    "duration": "indefinitely",
                    "model_conf": 92.61
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.48,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 26.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 36.3,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Edina, MN",
            "slug": "6712b90b-d0ba-4a2e-ba83-5976e75e1107"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/4/16",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/edina/3523-w-70th-st/",
        "index": 88.45,
        "franchise-entity-name": "HAPPY TIME SURFSIDE, INC."
    },
    "13257d89-0f38-4af1-b318-7655e310dbde": {
        "name": "Orange Theory Edison-Menlo Park Mall",
        "address": "55 Parsonage Rd Suite 2400C Edison, New Jersey 08837",
        "phone": "(973) 546-7635",
        "email": "studiomanager1181@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 11:30AM",
            "SUNDAY": "6:15AM - 11:30AM"
        },
        "geolocation": [
            40.5479012,
            -74.334885
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.69,
                    "duration": "11 Months",
                    "model_conf": 148.76
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 121.61,
                    "duration": "indefinitely",
                    "model_conf": 179.22
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.38,
                    "duration": "3 Months",
                    "model_conf": 108.42
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Edison-Menlo Park Mall",
            "slug": "13257d89-0f38-4af1-b318-7655e310dbde"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/5/21",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/edison/55-parsonage-rd-suite-2400c/",
        "index": 98.425,
        "franchise-entity-name": "M2 Woodbridge LLC"
    },
    "6d360702-c6c7-4168-8f77-bf589d233cc9": {
        "name": "Orange Theory Edmond, OK",
        "address": "3325 S Boulevard St., Suite 141 Edmond, Oklahoma 73013",
        "phone": "(405) 445-0809",
        "email": "studiomanager0604@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:30AM - 1:15PM",
            "SUNDAY": "6:30AM - 1:15PM"
        },
        "geolocation": [
            35.6219826,
            -97.479202
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.52,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.85,
                    "duration": "indefinitely",
                    "model_conf": 123.03
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 9.82,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.18,
                    "duration": "2 Months",
                    "model_conf": 167.45
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 14.77,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.06,
                    "duration": "indefinitely",
                    "model_conf": 95.97
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 18.56,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 30.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 41.17,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Edmond, OK",
            "slug": "6d360702-c6c7-4168-8f77-bf589d233cc9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/oklahoma/edmond/3325-s-boulevard-st-suite-141/",
        "index": 98.425,
        "franchise-entity-name": "OKC FITNESS STUDIO, LLC AND/OR ITS LLC ASSIGNEE"
    },
    "427413e8-326d-4d89-b186-cadbf33937d4": {
        "name": "Orange Theory Edwardsville",
        "address": "6654 Edwardsville Crossing Drive, Suite D Edwardsville, Illinois 62065",
        "phone": "(618) 669-0011",
        "email": "studiomanager1227@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 6:45PM",
            "TUESDAY": "4:55AM - 6:45PM",
            "WEDNESDAY": "4:55AM - 6:45PM",
            "THURSDAY": "4:55AM - 6:45PM",
            "FRIDAY": "4:55AM - 6:45PM",
            "SATURDAY": "7:15AM - 10:45AM",
            "SUNDAY": "7:15AM - 10:45AM"
        },
        "geolocation": [
            38.788202,
            -89.953531
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.14,
                    "duration": "indefinitely",
                    "model_conf": 153.76
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.85,
                    "duration": "indefinitely",
                    "model_conf": 185.73
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.18,
                    "duration": "indefinitely",
                    "model_conf": 112.13
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Edwardsville",
            "slug": "427413e8-326d-4d89-b186-cadbf33937d4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/3/19",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/edwardsville/6654-edwardsville-crossing-drive-suite-e/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-WEST, LLC"
    },
    "f514ee46-2b6d-4c3b-b860-5b502b603dc5": {
        "name": "Orange Theory Coronado, TX",
        "address": "6801 N. Mesa Street, Suite B-8 El Paso, Texas 79912",
        "phone": "(915) 213-1226",
        "email": "studiomanager1289@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            31.8339443,
            -106.54584
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.42,
                    "duration": "2 Months",
                    "model_conf": 152.93
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.6,
                    "duration": "indefinitely",
                    "model_conf": 179.1
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.51,
                    "duration": "1 Months",
                    "model_conf": 111.46
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Coronado, TX",
            "slug": "f514ee46-2b6d-4c3b-b860-5b502b603dc5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/17/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/el-paso/6801-n-mesa-street-suite-b-8/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS NEW MEXICO, LLC"
    },
    "cd8cca32-c772-4814-80d4-1924d5ae85ea": {
        "name": "Orange Theory El Segundo, CA",
        "address": "2041 Rosecrans Ave. El Segundo, California 90245",
        "phone": "(310) 400-5662",
        "email": "studiomanager0085@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:45PM",
            "TUESDAY": "5:30AM - 7:45PM",
            "WEDNESDAY": "5:30AM - 7:45PM",
            "THURSDAY": "5:30AM - 7:45PM",
            "FRIDAY": "5:30AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            33.9022827,
            -118.38906
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.18,
                    "duration": "indefinitely",
                    "model_conf": 138.1
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.5,
                    "duration": "1 Months",
                    "model_conf": 163.63
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.27,
                    "duration": "indefinitely",
                    "model_conf": 99.24
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "El Segundo, CA",
            "slug": "cd8cca32-c772-4814-80d4-1924d5ae85ea"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/21",
        "url": "https://www.orangetheory.com/en-us/locations/california/manhattan-beach/2041-rosecrans-avenue/",
        "index": 111.325,
        "franchise-entity-name": "OTF MB, LLC"
    },
    "334aaa97-fead-4ac6-93de-07c2a016375c": {
        "name": "Orange Theory Elk Grove, CA",
        "address": "9610 Bruceville Rd., Suite 102 Elk Grove, California 95757",
        "phone": "(916) 226-2683",
        "email": "studiomanager0357@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            38.4085922,
            -121.4195
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.37,
                    "duration": "2 Months",
                    "model_conf": 153.06
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 184.49
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.56,
                    "duration": "indefinitely",
                    "model_conf": 109.6
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Elk Grove, CA",
            "slug": "334aaa97-fead-4ac6-93de-07c2a016375c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/15/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/elk-grove/9610-bruceville-rd-suite-102/",
        "index": 103.375,
        "franchise-entity-name": "STANSBEFIT, LLC"
    },
    "93232179-26fa-409f-9810-041125dcffb8": {
        "name": "Orange Theory Ellisville, MO",
        "address": "15949 Manchester Rd Ellisville, Missouri 63011",
        "phone": "(314) 300-6996",
        "email": "studiomanager0452@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 6:45PM",
            "TUESDAY": "4:55AM - 6:45PM",
            "WEDNESDAY": "4:55AM - 6:45PM",
            "THURSDAY": "4:55AM - 6:45PM",
            "FRIDAY": "4:55AM - 6:45PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            38.593029,
            -90.588936
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.43,
                    "duration": "3 Months",
                    "model_conf": 132.49
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.36,
                    "duration": "indefinitely",
                    "model_conf": 160.37
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.86,
                    "duration": "indefinitely",
                    "model_conf": 96.2
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Ellisville, MO",
            "slug": "93232179-26fa-409f-9810-041125dcffb8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/16/16",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/ellisville/15949-manchester-rd/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS MISSOURI, LLC"
    },
    "c82de33a-504c-4f3b-9ad2-db96eb234108": {
        "name": "Orange Theory Elmhurst, IL",
        "address": "187 S IL Route 83 Elmhurst, Illinois 60126",
        "phone": "(331) 642-1500",
        "email": "studiomanager0319@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            41.894554,
            -87.961206
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.3,
                    "duration": "indefinitely",
                    "model_conf": 120.2
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.71,
                    "duration": "indefinitely",
                    "model_conf": 146.94
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.23,
                    "duration": "indefinitely",
                    "model_conf": 88.88
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Elmhurst, IL",
            "slug": "c82de33a-504c-4f3b-9ad2-db96eb234108"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/29/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/elmhurst/187-s-il-route-83/",
        "index": 103.375,
        "franchise-entity-name": "OTF WHEATON CORP"
    },
    "b5703383-93b6-42be-a953-1f16a044d098": {
        "name": "Orange Theory Emeryville, CA",
        "address": "5959 Shellmound St. Emeryvillre, California 94608",
        "phone": "(510) 560-4565",
        "email": "studiomanager0102@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:20AM - 11:50AM",
            "SUNDAY": "7:20AM - 11:50AM"
        },
        "geolocation": [
            37.8411217,
            -122.2936
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.2,
                    "duration": "indefinitely",
                    "model_conf": 153.16
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.07,
                    "duration": "indefinitely",
                    "model_conf": 183.95
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.27,
                    "duration": "1 Months",
                    "model_conf": 110.11
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Emeryville, CA",
            "slug": "b5703383-93b6-42be-a953-1f16a044d098"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/24/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/emeryvillre/5959-shellmound-st/",
        "index": 108.325,
        "franchise-entity-name": "SFFIT EMV, LLC"
    },
    "7445d34d-cf25-41d3-95c9-48ba999c3c21": {
        "name": "Orange Theory Encinitas, CA",
        "address": "215 S El Camino Real, Suite A Encinitas, California 92024",
        "phone": "(760) 297-4545",
        "email": "studiomanager0091@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:20AM - 11:50AM",
            "SUNDAY": "7:20AM - 11:50AM"
        },
        "geolocation": [
            33.0434265,
            -117.25905
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.73,
                    "duration": "indefinitely",
                    "model_conf": 150.5
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 126.9,
                    "duration": "6 Months",
                    "model_conf": 180.28
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.3,
                    "duration": "indefinitely",
                    "model_conf": 109.27
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Encinitas, CA",
            "slug": "7445d34d-cf25-41d3-95c9-48ba999c3c21"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/12/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/encinitas/215-s-el-camino-real-suite-a/",
        "index": 103.375,
        "franchise-entity-name": "OTF-SD1 LLC"
    },
    "b6f0ec9b-01f4-4f48-b68c-1cd02d2bdaf0": {
        "name": "Orange Theory Marlboro, NJ",
        "address": "104 U.S. 9 Englishtown, New Jersey 07726",
        "phone": "(732) 475-0333",
        "email": "studiomanager0262@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:45PM",
            "TUESDAY": "5:30AM - 7:45PM",
            "WEDNESDAY": "5:30AM - 7:45PM",
            "THURSDAY": "5:30AM - 7:45PM",
            "FRIDAY": "5:30AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            40.3525963,
            -74.306969
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 82.75,
                    "duration": "indefinitely",
                    "model_conf": 144.8
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 114.48,
                    "duration": "indefinitely",
                    "model_conf": 180.41
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 181.45,
                    "duration": "indefinitely",
                    "model_conf": 106.22
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Marlboro, NJ",
            "slug": "b6f0ec9b-01f4-4f48-b68c-1cd02d2bdaf0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/15/15",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/englishtown/104-u-s-9/",
        "index": 98.425,
        "franchise-entity-name": "DOGWOOD VENTURES NJ TWO LLC"
    },
    "1fff2c97-d2be-4024-86e9-ad8ee366cc72": {
        "name": "Orange Theory Estero, FL",
        "address": "10171 Estero Town Commons Place Estero, Florida 33928",
        "phone": "(239) 895-9891",
        "email": "studiomanager1092@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            26.431053,
            -81.784871
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.35,
                    "duration": "indefinitely",
                    "model_conf": 152.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.38,
                    "duration": "2 Months",
                    "model_conf": 184.89
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.26,
                    "duration": "indefinitely",
                    "model_conf": 110.34
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Estero, FL",
            "slug": "1fff2c97-d2be-4024-86e9-ad8ee366cc72"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/24/23",
        "url": "https://www.orangetheory.com/en-us/locations/florida/estero/10171-estero-town-commons-place/",
        "index": 98.425,
        "franchise-entity-name": "ESTERO COACHING LLC"
    },
    "0983cee1-0de3-4b70-88ab-1a0df678d58d": {
        "name": "Orange Theory Eugene, OR",
        "address": "23 Oakway Center Eugene, Oregon 97401",
        "phone": "(541) 636-0095",
        "email": "studiomanager0806@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            44.0656891,
            -123.07655
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.99,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.98,
                    "duration": "indefinitely",
                    "model_conf": 185.82
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.0,
                    "duration": "indefinitely",
                    "model_conf": 112.06
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Eugene, OR",
            "slug": "0983cee1-0de3-4b70-88ab-1a0df678d58d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/6/18",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/eugene/23-oakway-center/",
        "index": 98.425,
        "franchise-entity-name": "ICON FITNESS LLC"
    },
    "fa68fc1a-5e26-4644-88b4-e5cc1821fdb0": {
        "name": "Orange Theory Evans, GA",
        "address": "4274 Washington Road Evans, Georgia 30809",
        "phone": "(706) 814-3024",
        "email": "studiomanager0368@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            33.5334396,
            -82.127647
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.69,
                    "duration": "indefinitely",
                    "model_conf": 152.37
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.14,
                    "duration": "2 Months",
                    "model_conf": 185.57
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.35,
                    "duration": "indefinitely",
                    "model_conf": 111.8
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Evans, GA",
            "slug": "fa68fc1a-5e26-4644-88b4-e5cc1821fdb0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/28/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/evans/4274-washington-road/",
        "index": 98.425,
        "franchise-entity-name": "JM AUGUSTA LLC"
    },
    "e2a1504e-291d-4737-ab16-c1855a2e21a1": {
        "name": "Orange Theory Evanston, IL",
        "address": "2800 Central St Evanston, Illinois 60201",
        "phone": "(847) 491-9090",
        "email": "studiomanager0126@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            42.0642319,
            -87.713234
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.46,
                    "duration": "2 Months",
                    "model_conf": 145.43
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.26,
                    "duration": "indefinitely",
                    "model_conf": 161.56
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.2,
                    "duration": "indefinitely",
                    "model_conf": 106.12
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Evanston, IL",
            "slug": "e2a1504e-291d-4737-ab16-c1855a2e21a1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/7/14",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/evanston/2800-central-street/",
        "index": 103.375,
        "franchise-entity-name": "NORTHSHORE OTF, LLC"
    },
    "7b9de23e-fcc1-48da-8492-fc4f692a7377": {
        "name": "Orange Theory Evansville, IN",
        "address": "3431 N. Green River Road Evansville, Indiana 47711",
        "phone": "(812) 645-9712",
        "email": "studiomanager0819@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            38.0090485,
            -87.492249
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.06,
                    "duration": "5 Months",
                    "model_conf": 153.18
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.11,
                    "duration": "indefinitely",
                    "model_conf": 185.18
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.66,
                    "duration": "indefinitely",
                    "model_conf": 111.98
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Evansville, IN",
            "slug": "7b9de23e-fcc1-48da-8492-fc4f692a7377"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/evansville/3431-n-green-river-road/",
        "index": 93.4,
        "franchise-entity-name": "3TW, LLC"
    },
    "6f5784aa-6446-43fc-bb34-c363d99fc0e5": {
        "name": "Orange Theory Mill Creek, WA",
        "address": "12902 Bothell Everett Highway Everrett, Washington 98208",
        "phone": "(206) 330-0511",
        "email": "studiomanager0583@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            47.8801613,
            -122.20728
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 9.7,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.67,
                    "duration": "indefinitely",
                    "model_conf": 150.78
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 13.62,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.44,
                    "duration": "1 Months",
                    "model_conf": 178.41
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 19.5,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.61,
                    "duration": "indefinitely",
                    "model_conf": 104.66
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 24.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 44.1,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 58.7,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Mill Creek, WA",
            "slug": "6f5784aa-6446-43fc-bb34-c363d99fc0e5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/28/16",
        "url": "https://www.orangetheory.com/en-us/locations/washington/everrett/12902-bothell-everett-highway/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF MILL CREEK, LLC"
    },
    "e19e1984-544e-45b6-b247-96663392f223": {
        "name": "Orange Theory Exton, PA",
        "address": "294 Main St. Exton, Pennsylvania 19343",
        "phone": "(610) 298-5400",
        "email": "studiomanager0662@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:00PM",
            "TUESDAY": "5:30AM - 8:00PM",
            "WEDNESDAY": "5:30AM - 8:00PM",
            "THURSDAY": "5:30AM - 8:00PM",
            "FRIDAY": "5:30AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            40.0225487,
            -75.628105
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.49,
                    "duration": "2 Months",
                    "model_conf": 150.08
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.56,
                    "duration": "indefinitely",
                    "model_conf": 183.65
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.67,
                    "duration": "indefinitely",
                    "model_conf": 96.86
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Exton, PA",
            "slug": "e19e1984-544e-45b6-b247-96663392f223"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/21/16",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/exton/294-main-st/",
        "index": 103.375,
        "franchise-entity-name": "F.O.L FITNESS II, LLC"
    },
    "f87407c4-ee33-4461-b310-946e12e22514": {
        "name": "Orange Theory Fair Oaks, CA",
        "address": "8921 Madison Ave Fair Oaks, California 95628",
        "phone": "(916) 436-7003",
        "email": "studiomanager0957@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            38.6653023,
            -121.22471
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.86,
                    "duration": "indefinitely",
                    "model_conf": 152.86
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.83,
                    "duration": "indefinitely",
                    "model_conf": 184.81
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.74,
                    "duration": "indefinitely",
                    "model_conf": 110.9
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Fair Oaks, CA",
            "slug": "f87407c4-ee33-4461-b310-946e12e22514"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/18/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/fair-oaks/8921-madison-ave-300/",
        "index": 103.375,
        "franchise-entity-name": "SAC IT TO ME BASSEL LLC"
    },
    "a817c220-25c6-43f4-976b-5cd709ae834e": {
        "name": "Orange Theory Fairfax City, VA",
        "address": "10621 Braddock Road Suite J Fairfax City, Virginia 22032",
        "phone": "(703) 595-2231",
        "email": "studiomanager0796@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            38.8252182,
            -77.313995
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.03,
                    "duration": "indefinitely",
                    "model_conf": 130.23
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.78,
                    "duration": "indefinitely",
                    "model_conf": 156.91
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.91,
                    "duration": "indefinitely",
                    "model_conf": 93.72
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fairfax City, VA",
            "slug": "a817c220-25c6-43f4-976b-5cd709ae834e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/fairfax-city/10621-braddock-road-suite-j/",
        "index": 98.425,
        "franchise-entity-name": "NHCNOVA I LLOC"
    },
    "06f294eb-6b51-469f-8e07-a80f4c778fdb": {
        "name": "Orange Theory Lorton-Fairfax Station, VA",
        "address": "9027 Silverbrook Rd. #101 Fairfax Station, Virginia 22039",
        "phone": "(703) 424-9894",
        "email": "studiomanager0892@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            38.7276917,
            -77.25827
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.88,
                    "duration": "indefinitely",
                    "model_conf": 144.92
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.99,
                    "duration": "3 Months",
                    "model_conf": 164.55
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.06,
                    "duration": "indefinitely",
                    "model_conf": 102.23
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lorton-Fairfax Station, VA",
            "slug": "06f294eb-6b51-469f-8e07-a80f4c778fdb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/11/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/fairfax-station/9027-silverbrook-rd-101/",
        "index": 98.425,
        "franchise-entity-name": "NHCNOVA III LLC"
    },
    "598d825f-50ea-49ac-9832-db03d962f2fd": {
        "name": "Orange Theory Fairfax Circle",
        "address": "10384 FAIRFAX BLVD Fairfax, Virginia 22030",
        "phone": "(571) 520-2100",
        "email": "studiomanager1445@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:30PM",
            "TUESDAY": "6:00AM - 7:30PM",
            "WEDNESDAY": "6:00AM - 7:30PM",
            "THURSDAY": "6:00AM - 7:30PM",
            "FRIDAY": "6:00AM - 7:30PM",
            "SATURDAY": "7:45AM - 12:15PM",
            "SUNDAY": "7:45AM - 12:15PM"
        },
        "geolocation": [
            38.860625,
            -77.302342
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.83,
                    "duration": "indefinitely",
                    "model_conf": 122.61
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.92,
                    "duration": "indefinitely",
                    "model_conf": 148.77
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.78,
                    "duration": "indefinitely",
                    "model_conf": 88.79
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fairfax Circle",
            "slug": "598d825f-50ea-49ac-9832-db03d962f2fd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/12/21",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/fairfax/10334-fairfax-blvd-unit-210/",
        "index": 98.425,
        "franchise-entity-name": "Fairfax Circle Fitness, LLC"
    },
    "c2885ff5-75dc-44ba-8883-11acd628f03f": {
        "name": "Orange Theory Fairfax, VA",
        "address": "13063 Lee Jackson Memorial Highway Fairfax, Virginia 22033",
        "phone": "(703) 961-8985",
        "email": "studiomanager0062@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:45AM - 1:45PM",
            "SUNDAY": "6:45AM - 1:45PM"
        },
        "geolocation": [
            38.8811531,
            -77.398148
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.41,
                    "duration": "indefinitely",
                    "model_conf": 127.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.39,
                    "duration": "1 Months",
                    "model_conf": 158.69
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.11,
                    "duration": "indefinitely",
                    "model_conf": 95.34
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fairfax, VA",
            "slug": "c2885ff5-75dc-44ba-8883-11acd628f03f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/11/13",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/fairfax/13063-lee-jackson-memorial-highway/",
        "index": 98.425,
        "franchise-entity-name": "RA LIFE A, LLC"
    },
    "87273ee6-ad7c-48e3-ab30-051c991e238d": {
        "name": "Orange Theory Fairfield, CT",
        "address": "525 Tunxis Hill Cut Off Fairfield, Connecticut 06825",
        "phone": "(203) 883-8220",
        "email": "studiomanager0258@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:45PM",
            "TUESDAY": "5:30AM - 7:45PM",
            "WEDNESDAY": "5:30AM - 7:45PM",
            "THURSDAY": "5:30AM - 7:45PM",
            "FRIDAY": "5:30AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            41.1776238,
            -73.240646
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.6,
                    "duration": "1 Months",
                    "model_conf": 134.8
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.68,
                    "duration": "indefinitely",
                    "model_conf": 173.19
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.57,
                    "duration": "indefinitely",
                    "model_conf": 103.56
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fairfield, CT",
            "slug": "87273ee6-ad7c-48e3-ab30-051c991e238d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/24/14",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/fairfield/525-tunxis-hill-cut-off/",
        "index": 98.425,
        "franchise-entity-name": "EMPIRE FAIRFIELD LLC"
    },
    "e8b66922-2925-4e1f-ac7e-1f739c396027": {
        "name": "Orange Theory Falls Church, VA",
        "address": "510 South Washington Street Falls Church, Virginia 22046",
        "phone": "(540) 779-0079",
        "email": "studiomanager0708@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            38.879734,
            -77.176872
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.4,
                    "duration": "2 Months",
                    "model_conf": 109.48
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.79,
                    "duration": "indefinitely",
                    "model_conf": 128.46
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.6,
                    "duration": "indefinitely",
                    "model_conf": 83.2
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Falls Church, VA",
            "slug": "e8b66922-2925-4e1f-ac7e-1f739c396027"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/23/18",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/falls-church/510-s-washington-street/",
        "index": 98.425,
        "franchise-entity-name": "HH FITNESS FALLS CHURCH, LLC"
    },
    "6dfad5a0-b37b-4399-85b1-b44dd6357a3e": {
        "name": "Orange Theory Lake Barcroft, VA",
        "address": "6341 Columbia Pike, Ste D Falls Church, Virginia 22041",
        "phone": "(703) 962-1500",
        "email": "studiomanager1264@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            38.8363075,
            -77.154846
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.74,
                    "duration": "1 Months",
                    "model_conf": 124.25
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.07,
                    "duration": "indefinitely",
                    "model_conf": 149.93
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.99,
                    "duration": "indefinitely",
                    "model_conf": 90.14
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lake Barcroft, VA",
            "slug": "6dfad5a0-b37b-4399-85b1-b44dd6357a3e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/11/19",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/falls-church/6341-columbia-pike-ste-d/",
        "index": 98.425,
        "franchise-entity-name": "G3 FITNESS GROUP IV, LLC"
    },
    "40e49f3e-a676-4339-80a8-b8f2562a29a7": {
        "name": "Orange Theory Seven Corners, VA",
        "address": "6410 Arlington Blvd Falls Church, Virginia 22042",
        "phone": "(571) 766-0809",
        "email": "studiomanager1335@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:30AM - 7:15PM",
            "TUESDAY": "6:30AM - 7:15PM",
            "WEDNESDAY": "6:30AM - 7:15PM",
            "THURSDAY": "6:30AM - 7:15PM",
            "FRIDAY": "6:30AM - 7:15PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            38.87247,
            -77.16152
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.8,
                    "duration": "5 Months",
                    "model_conf": 113.28
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.33,
                    "duration": "indefinitely",
                    "model_conf": 132.14
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.24,
                    "duration": "1 Months",
                    "model_conf": 84.85
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Seven Corners, VA",
            "slug": "40e49f3e-a676-4339-80a8-b8f2562a29a7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/20",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/falls-church/6404d-arlington-blvd/",
        "index": 98.425,
        "franchise-entity-name": "HH FITNESS SEVEN CORNERS, LLC"
    },
    "a09970a8-6521-48c1-9bc6-200f4e3ba8e9": {
        "name": "Orange Theory Idylwood, VA",
        "address": "7505 Leesburg Pike Suite M Falls Church, Virginia 22043",
        "phone": "(703) 436-8080",
        "email": "studiomanager1455@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:15AM",
            "SUNDAY": "7:30AM - 11:15AM"
        },
        "geolocation": [
            38.89569,
            -77.19188
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.81,
                    "duration": "indefinitely",
                    "model_conf": 108.7
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.41,
                    "duration": "indefinitely",
                    "model_conf": 133.08
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.74,
                    "duration": "indefinitely",
                    "model_conf": 83.03
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Idylwood, VA",
            "slug": "a09970a8-6521-48c1-9bc6-200f4e3ba8e9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/2/20",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/falls-church/7505-leesburg-pike-suite-m/",
        "index": 98.425,
        "franchise-entity-name": "River5 Fitness 3, LLC"
    },
    "7204c027-c774-41e3-86f6-d1bf228e6ae8": {
        "name": "Orange Theory Fargo, ND",
        "address": "4501 15th Ave. Fargo, North Dakota 58103",
        "phone": "(701) 369-2200",
        "email": "studiomanager0615@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            46.8588829,
            -96.863205
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.79,
                    "duration": "6 Months",
                    "model_conf": 152.39
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.5,
                    "duration": "indefinitely",
                    "model_conf": 185.69
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.65,
                    "duration": "indefinitely",
                    "model_conf": 110.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fargo, ND",
            "slug": "7204c027-c774-41e3-86f6-d1bf228e6ae8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/6/17",
        "url": "https://www.orangetheory.com/en-us/locations/north-dakota/fargo/4501-15th-ave/",
        "index": 93.4,
        "franchise-entity-name": "FARGO FITNESS, LLC"
    },
    "bf421ba6-a13e-4da3-a62d-4b0f685e4ee5": {
        "name": "Orange Theory Farmingdale",
        "address": "253 Airport Plaza Blvd Farmingdale, New York 11735",
        "phone": "(631) 386-8088",
        "email": "studiomanager1009@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            40.738369,
            -73.416031
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.93,
                    "duration": "4 Months",
                    "model_conf": 133.97
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.42,
                    "duration": "indefinitely",
                    "model_conf": 160.73
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.6,
                    "duration": "indefinitely",
                    "model_conf": 93.21
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Farmingdale",
            "slug": "bf421ba6-a13e-4da3-a62d-4b0f685e4ee5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/29/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/farmingdale/253-airport-plaza-blvd/",
        "index": 108.325,
        "franchise-entity-name": "FD Fitness LLC"
    },
    "9502f39c-9348-4b2c-b8db-0a7dd9b19c16": {
        "name": "Orange Theory Farmington Hills, MI",
        "address": "31229 W 14 Mile Road Farmington Hills, Michigan 48334",
        "phone": "(248) 579-4844",
        "email": "studiomanager0786@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:15AM - 11:45AM",
            "SUNDAY": "7:15AM - 11:45AM"
        },
        "geolocation": [
            42.5276489,
            -83.358406
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.5,
                    "duration": "2 Months",
                    "model_conf": 135.8
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.41,
                    "duration": "indefinitely",
                    "model_conf": 164.7
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.72,
                    "duration": "indefinitely",
                    "model_conf": 100.18
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Farmington Hills, MI",
            "slug": "9502f39c-9348-4b2c-b8db-0a7dd9b19c16"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/10/17",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/farmington-hills/31229-w-14-mile-road/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS MICHIGAN, LLC - 0786"
    },
    "4f54ff0c-4364-4742-bfbb-1f45d23c2014": {
        "name": "Orange Theory Fayetteville AR",
        "address": "100 E. Joyce Blvd. Suite #8 Fayetteville, Arkansas 72703",
        "phone": "(479) 595-8709",
        "email": "studiomanager0996@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 3:00PM",
            "SUNDAY": "7:00AM - 3:00PM"
        },
        "geolocation": [
            36.1254959,
            -94.155258
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.79,
                    "duration": "indefinitely",
                    "model_conf": 150.75
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.25,
                    "duration": "2 Months",
                    "model_conf": 181.36
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.46,
                    "duration": "indefinitely",
                    "model_conf": 111.47
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fayetteville AR",
            "slug": "4f54ff0c-4364-4742-bfbb-1f45d23c2014"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/28/18",
        "url": "https://www.orangetheory.com/en-us/locations/arkansas/fayetteville/100-east-joyce-blvd-107/",
        "index": 93.4,
        "franchise-entity-name": "AR HIIT, LLC"
    },
    "2890f72e-b3e4-45b7-b954-77677463f7ba": {
        "name": "Orange Theory Fayetteville, NC",
        "address": "1800 Skibo Road Fayetteville, North Carolina 28303",
        "phone": "(910) 818-5473",
        "email": "studiomanager0895@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 12:15PM",
            "SUNDAY": "7:15AM - 12:15PM"
        },
        "geolocation": [
            35.074791,
            -78.965439
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.91,
                    "duration": "10 Months",
                    "model_conf": 152.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 121.45,
                    "duration": "indefinitely",
                    "model_conf": 178.64
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.35,
                    "duration": "3 Months",
                    "model_conf": 111.58
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fayetteville, NC",
            "slug": "2890f72e-b3e4-45b7-b954-77677463f7ba"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/fayetteville/1800-skibo-road/",
        "index": 98.425,
        "franchise-entity-name": "ATHAYES2016, LLC"
    },
    "c284ea9d-f691-422f-b9f4-a53b6bf9f0b2": {
        "name": "Orange Theory Federal Way, WA",
        "address": "35007 Enchanted Parkway South Federal Way, Washington 98003",
        "phone": "(253) 275-0204",
        "email": "studiomanager1211@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "8:15AM - 11:30AM",
            "SUNDAY": "8:15AM - 11:30AM"
        },
        "geolocation": [
            47.2877884,
            -122.31409
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 7.98,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.06,
                    "duration": "4 Months",
                    "model_conf": 152.68
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 12.02,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.02,
                    "duration": "indefinitely",
                    "model_conf": 183.77
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 18.08,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.21,
                    "duration": "1 Months",
                    "model_conf": 111.59
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 22.72,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 37.88,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 50.4,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Federal Way, WA",
            "slug": "c284ea9d-f691-422f-b9f4-a53b6bf9f0b2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/16/21",
        "url": "https://www.orangetheory.com/en-us/locations/washington/federal-way/35007-enchanted-parkway-south/",
        "index": 98.425,
        "franchise-entity-name": "FEDERAL WAY FITNESS, LLC"
    },
    "adc730dd-785f-43c2-ad11-a990b1f1b314": {
        "name": "Orange Theory Fenton, MO",
        "address": "528 Old Smizer Mill Rd Fenton, Missouri 63026",
        "phone": "(636) 203-8424",
        "email": "studiomanager0724@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "7:00AM - 11:15AM",
            "SUNDAY": "7:00AM - 11:15AM"
        },
        "geolocation": [
            38.5164986,
            -90.451477
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.4,
                    "duration": "indefinitely",
                    "model_conf": 144.42
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.64,
                    "duration": "1 Months",
                    "model_conf": 174.87
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.81,
                    "duration": "indefinitely",
                    "model_conf": 105.03
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fenton, MO",
            "slug": "adc730dd-785f-43c2-ad11-a990b1f1b314"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/2/18",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/fenton/528-old-smizer-mill-rd/",
        "index": 93.4,
        "franchise-entity-name": "FENTON FITNESS MO, LLC."
    },
    "9d29a58a-5256-4d34-a37d-46eebd44df06": {
        "name": "Orange Theory Fishers-Geist, IN",
        "address": "11695 Olio Road Fishers, Indiana 46037",
        "phone": "(317) 960-4100",
        "email": "studiomanager0275@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:00AM - 1:30PM",
            "SUNDAY": "6:00AM - 1:30PM"
        },
        "geolocation": [
            39.9592629,
            -85.918602
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.09,
                    "duration": "indefinitely",
                    "model_conf": 146.07
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.02,
                    "duration": "7 Months",
                    "model_conf": 181.49
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.3,
                    "duration": "indefinitely",
                    "model_conf": 104.19
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fishers-Geist, IN",
            "slug": "9d29a58a-5256-4d34-a37d-46eebd44df06"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/10/16",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/fishers/11695-olio-road/",
        "index": 93.4,
        "franchise-entity-name": "OT INDIANA FISHERS-GEIST, LLC"
    },
    "8a49e42a-353f-4033-b7d3-7c151245c846": {
        "name": "Orange Theory Fishers-Nickel Plate, IN",
        "address": "8700 North Street Fishers, Indiana 46038",
        "phone": "(317) 806-1212",
        "email": "studiomanager1166@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            39.9586334,
            -86.015785
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.63,
                    "duration": "2 Months",
                    "model_conf": 131.89
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.71,
                    "duration": "indefinitely",
                    "model_conf": 169.27
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.65,
                    "duration": "indefinitely",
                    "model_conf": 92.16
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fishers-Nickel Plate, IN",
            "slug": "8a49e42a-353f-4033-b7d3-7c151245c846"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/25/19",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/fishers/8700-north-street/",
        "index": 93.4,
        "franchise-entity-name": "OT Indiana Nickel Plate, LLC"
    },
    "c6c36700-5741-45ad-8a76-338b3e0444df": {
        "name": "Orange Theory Flagstaff, AZ",
        "address": "601 E. Piccadilly Dr., Suite 25 Flagstaff, Arizona 86001",
        "phone": "(928) 288-5300",
        "email": "studiomanager0908@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            35.19104,
            -111.64288
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.18,
                    "duration": "indefinitely",
                    "model_conf": 153.27
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.56,
                    "duration": "1 Months",
                    "model_conf": 185.57
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.22,
                    "duration": "indefinitely",
                    "model_conf": 111.73
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Flagstaff, AZ",
            "slug": "c6c36700-5741-45ad-8a76-338b3e0444df"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/25/17",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/flagstaff/601-e-piccadilly-dr-suite-25/",
        "index": 103.375,
        "franchise-entity-name": "FLAGSTAFF FITNESS PARTNERS, LLC"
    },
    "ec730fee-e072-4769-8042-3a133369b213": {
        "name": "Orange Theory Fleming Island, FL",
        "address": "1615-100 County Road 220 Fleming Island, Florida 32003",
        "phone": "(904) 406-7343",
        "email": "studiomanager0573@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:30AM - 10:45AM",
            "SUNDAY": "7:30AM - 10:45AM"
        },
        "geolocation": [
            30.1021061,
            -81.72567
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.48,
                    "duration": "indefinitely",
                    "model_conf": 152.51
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.35,
                    "duration": "2 Months",
                    "model_conf": 184.76
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.12,
                    "duration": "indefinitely",
                    "model_conf": 111.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fleming Island, FL",
            "slug": "ec730fee-e072-4769-8042-3a133369b213"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/8/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/fleming-island/1615-100-county-road-220/",
        "index": 93.4,
        "franchise-entity-name": "F5 ENTERPRISES, LLC"
    },
    "5c984002-7438-4d4d-b23f-eb367af9fba3": {
        "name": "Orange Theory Flemington, NJ",
        "address": "295 US- 202 Flemington, New Jersey 08822",
        "phone": "(908) 366-7600",
        "email": "studiomanager0795@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.5018234,
            -74.854935
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.59,
                    "duration": "6 Months",
                    "model_conf": 152.54
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.6,
                    "duration": "indefinitely",
                    "model_conf": 182.89
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.8,
                    "duration": "2 Months",
                    "model_conf": 111.52
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Flemington, NJ",
            "slug": "5c984002-7438-4d4d-b23f-eb367af9fba3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/flemington/295-us-202/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO FITNESS II LLC"
    },
    "c44d5151-9101-4905-bfab-71f893ccb349": {
        "name": "Orange Theory Florence, SC",
        "address": "1901 West Palmetto Street Florence, South Carolina 29501",
        "phone": "(843) 799-6220",
        "email": "studiomanager1396@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.1881943,
            -79.810669
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.88,
                    "duration": "indefinitely",
                    "model_conf": 153.15
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.9,
                    "duration": "indefinitely",
                    "model_conf": 185.51
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.03,
                    "duration": "indefinitely",
                    "model_conf": 110.99
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Florence, SC",
            "slug": "c44d5151-9101-4905-bfab-71f893ccb349"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/20/20",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/florence/1945-west-palmetto-street/",
        "index": 93.4,
        "franchise-entity-name": "JM FLORENCE LLC"
    },
    "5aa5d8b0-34dc-415e-87f2-d5cb3fae84da": {
        "name": "Orange Theory Flower Mound, TX",
        "address": "4271 Esplanade Place Flower Mound, Texas 75028",
        "phone": "(972) 474-0425",
        "email": "studiomanager0879@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 8:00PM",
            "TUESDAY": "4:45AM - 8:00PM",
            "WEDNESDAY": "4:45AM - 8:00PM",
            "THURSDAY": "4:45AM - 8:00PM",
            "FRIDAY": "4:45AM - 8:00PM",
            "SATURDAY": "6:00AM - 2:30PM",
            "SUNDAY": "6:00AM - 2:30PM"
        },
        "geolocation": [
            33.0432167,
            -97.068893
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.72,
                    "duration": "indefinitely",
                    "model_conf": 138.65
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.08,
                    "duration": "3 Months",
                    "model_conf": 178.8
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.26,
                    "duration": "indefinitely",
                    "model_conf": 110.55
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Flower Mound, TX",
            "slug": "5aa5d8b0-34dc-415e-87f2-d5cb3fae84da"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/28/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/flower-mound/4271-esplanade-place/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA FITNESS - FLOWER MOUND, LLC"
    },
    "b3940c38-983c-4e22-a130-01556e00dd40": {
        "name": "Orange Theory Flushing at Tangram, NY",
        "address": "37-12 Prince st Flushing, New York 11354",
        "phone": "(917) 672-3611",
        "email": "studiomanager0939@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:30PM",
            "TUESDAY": "6:00AM - 7:30PM",
            "WEDNESDAY": "6:00AM - 7:30PM",
            "THURSDAY": "6:00AM - 7:30PM",
            "FRIDAY": "6:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            40.76051,
            -73.83291
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.46,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.89,
                    "duration": "indefinitely",
                    "model_conf": 140.31
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.26,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.22,
                    "duration": "indefinitely",
                    "model_conf": 169.23
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.16,
                    "duration": "indefinitely",
                    "model_conf": 102.89
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 11.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 20.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 26.96,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Flushing at Tangram, NY",
            "slug": "b3940c38-983c-4e22-a130-01556e00dd40"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/14/22",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/flushing/37-12-prince-st/",
        "index": 108.325,
        "franchise-entity-name": "2QUEENFITNESS LLC"
    },
    "cff09c8a-8baa-46e4-8338-daf1bf3f15bb": {
        "name": "Orange Theory Folsom, CA",
        "address": "230 Palladio Parkway, Suite 1221 Folsom, California 95630",
        "phone": "(916) 790-6810",
        "email": "studiomanager0278@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            38.6482239,
            -121.11946
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.95,
                    "duration": "4 Months",
                    "model_conf": 151.87
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.99,
                    "duration": "indefinitely",
                    "model_conf": 183.6
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.19,
                    "duration": "1 Months",
                    "model_conf": 110.42
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Folsom, CA",
            "slug": "cff09c8a-8baa-46e4-8338-daf1bf3f15bb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/14/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/folsom/230-palladio-parkway-suite-1221/",
        "index": 103.375,
        "franchise-entity-name": "LECLAIR FITNESS II, LLC"
    },
    "22e8654e-6146-49c5-9006-5e9ab3e9477d": {
        "name": "Orange Theory Lake Forest Foothill Ranch, CA",
        "address": "27482 Portola Parkway Foothill Ranch, California 92610",
        "phone": "(949) 899-8181",
        "email": "studiomanager0677@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:35PM",
            "TUESDAY": "5:00AM - 7:35PM",
            "WEDNESDAY": "5:00AM - 7:35PM",
            "THURSDAY": "5:00AM - 7:35PM",
            "FRIDAY": "5:00AM - 7:35PM",
            "SATURDAY": "6:30AM - 11:30AM",
            "SUNDAY": "6:30AM - 11:30AM"
        },
        "geolocation": [
            33.6703606,
            -117.65197
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.06,
                    "duration": "4 Months",
                    "model_conf": 145.88
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.76,
                    "duration": "indefinitely",
                    "model_conf": 182.79
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.2,
                    "duration": "1 Months",
                    "model_conf": 110.98
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Lake Forest Foothill Ranch, CA",
            "slug": "22e8654e-6146-49c5-9006-5e9ab3e9477d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/4/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/foothill-ranch/27482-portola-parkway/",
        "index": 103.375,
        "franchise-entity-name": "MJD LLC"
    },
    "3219688d-ecab-40f0-b7d1-29ca38428601": {
        "name": "Orange Theory Forest Hills",
        "address": "107-14 70th Rd Forest Hills, New York 11375",
        "phone": "(718) 701-6070",
        "email": "studiomanager1061@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 9:20PM",
            "TUESDAY": "5:15AM - 9:20PM",
            "WEDNESDAY": "5:15AM - 9:20PM",
            "THURSDAY": "5:15AM - 9:20PM",
            "FRIDAY": "5:15AM - 9:20PM",
            "SATURDAY": "7:00AM - 2:30PM",
            "SUNDAY": "7:00AM - 2:30PM"
        },
        "geolocation": [
            40.7209778,
            -73.845604
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.83,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.28,
                    "duration": "indefinitely",
                    "model_conf": 141.45
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.78,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.16,
                    "duration": "2 Months",
                    "model_conf": 170.77
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 9.7,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.09,
                    "duration": "indefinitely",
                    "model_conf": 103.25
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 12.19,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 21.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 29.2,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Forest Hills",
            "slug": "3219688d-ecab-40f0-b7d1-29ca38428601"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/forest-hills/107-14-70th-rd/",
        "index": 108.325,
        "franchise-entity-name": "FIT4U, LLC"
    },
    "37f59c09-519c-4416-ad47-4cabb3840929": {
        "name": "Orange Theory Fort Collins - Drake Road, CO",
        "address": "2261 East Drake Road, Suite 140 Fort Collins, Colorado 80525",
        "phone": "(970) 632-2020",
        "email": "studiomanager0173@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            40.5522232,
            -105.036
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.85,
                    "duration": "5 Months",
                    "model_conf": 144.33
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.21,
                    "duration": "indefinitely",
                    "model_conf": 184.72
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.3,
                    "duration": "1 Months",
                    "model_conf": 108.59
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fort Collins - Drake Road, CO",
            "slug": "37f59c09-519c-4416-ad47-4cabb3840929"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/fort-collins/2261-east-drake-road-suite-140/",
        "index": 98.425,
        "franchise-entity-name": "WSH FT. COLLINS DRAKE, LLC"
    },
    "6266ff86-5701-4e5c-9d3d-660af4cce28d": {
        "name": "Orange Theory Fort Collins-Harmony Rd, CO",
        "address": "250 E. Harmony Road, F-6 Fort Collins, Colorado 80525",
        "phone": "(970) 658-5758",
        "email": "studiomanager1051@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            40.523922,
            -105.07351
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.83,
                    "duration": "indefinitely",
                    "model_conf": 140.43
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.92,
                    "duration": "indefinitely",
                    "model_conf": 184.39
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.03,
                    "duration": "indefinitely",
                    "model_conf": 107.13
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fort Collins-Harmony Rd, CO",
            "slug": "6266ff86-5701-4e5c-9d3d-660af4cce28d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/fort-collins/250-e-harmony-road-f-6/",
        "index": 98.425,
        "franchise-entity-name": "WSH Ft. Collins Harmony, LLC"
    },
    "a0c5a75a-76bf-4425-ab34-0c0619f95072": {
        "name": "Orange Theory Fort Mill, SC",
        "address": "1329 Broadcloth St. Suite 105 Fort Mill, South Carolina 29715",
        "phone": "(704) 251-7944",
        "email": "studiomanager0507@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            35.026165,
            -80.961708
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.21,
                    "duration": "indefinitely",
                    "model_conf": 145.91
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.46,
                    "duration": "5 Months",
                    "model_conf": 175.57
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.86,
                    "duration": "indefinitely",
                    "model_conf": 107.57
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fort Mill, SC",
            "slug": "a0c5a75a-76bf-4425-ab34-0c0619f95072"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/16/17",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/fort-mill/1329-broadcloth-street-rnsuite-105/",
        "index": 98.425,
        "franchise-entity-name": "EMPIRE FORT MILL LLC"
    },
    "cdb0b3f7-bcbc-4a59-a43c-a55bb9b82be9": {
        "name": "Orange Theory Fort Myers - Six Mile Cypress, FL",
        "address": "8001 Dani Drive Fort Myers, Florida 33966",
        "phone": "(239) 292-5826",
        "email": "studiomanager0283@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            26.6058712,
            -81.810425
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.95,
                    "duration": "indefinitely",
                    "model_conf": 151.03
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.3,
                    "duration": "indefinitely",
                    "model_conf": 182.1
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.82,
                    "duration": "indefinitely",
                    "model_conf": 111.98
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fort Myers - Six Mile Cypress, FL",
            "slug": "cdb0b3f7-bcbc-4a59-a43c-a55bb9b82be9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/15/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/fort-myers/8001-dani-drive/",
        "index": 98.425,
        "franchise-entity-name": "SIX MILE CYPRESS COACHING LLC"
    },
    "82d79d25-5a5f-4165-90b1-55b0812d454b": {
        "name": "Orange Theory Fort Smith,AR",
        "address": "6808 Rogers Ave, Fort Smith, Arkansas 72903",
        "phone": "(479) 769-2052",
        "email": "studiomanager1429@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:30PM",
            "SUNDAY": "7:00AM - 11:30PM"
        },
        "geolocation": [
            35.3541756,
            -94.357277
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.79
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.76
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.19
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fort Smith,AR",
            "slug": "82d79d25-5a5f-4165-90b1-55b0812d454b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/20/20",
        "url": "https://www.orangetheory.com/en-us/locations/arkansas/fort-smith/6808-rogers-ave/",
        "index": 93.4,
        "franchise-entity-name": "AR HIIT, LLC"
    },
    "d879f67b-73ab-4e21-b6b3-04c2b0d88395": {
        "name": "Orange Theory Fort Wayne SW, IN",
        "address": "1034 S. Thomas Road, Suite 105 Fort Wayne, Indiana 46804",
        "phone": "(260) 579-4534",
        "email": "studiomanager0815@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 12:00PM",
            "SUNDAY": "8:00AM - 12:00PM"
        },
        "geolocation": [
            41.0774269,
            -85.203537
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.89,
                    "duration": "indefinitely",
                    "model_conf": 153.5
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.32,
                    "duration": "indefinitely",
                    "model_conf": 185.77
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.85,
                    "duration": "indefinitely",
                    "model_conf": 107.65
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fort Wayne SW, IN",
            "slug": "d879f67b-73ab-4e21-b6b3-04c2b0d88395"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/10/18",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/fort-wayne/1034-thomas-road-suite-105/",
        "index": 93.4,
        "franchise-entity-name": "EPOC FITNESS SW, LLC"
    },
    "530b8261-0fd4-4b1a-84e2-30180a4b1826": {
        "name": "Orange Theory Fort Wayne-North, IN",
        "address": "1517 West Dupont Road Fort Wayne, Indiana 46825",
        "phone": "(260) 579-4535",
        "email": "studiomanager0998@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 12:00PM",
            "SUNDAY": "8:00AM - 12:00PM"
        },
        "geolocation": [
            41.1776848,
            -85.163353
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.1,
                    "duration": "indefinitely",
                    "model_conf": 153.63
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.88,
                    "duration": "indefinitely",
                    "model_conf": 185.72
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.75,
                    "duration": "indefinitely",
                    "model_conf": 110.38
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fort Wayne-North, IN",
            "slug": "530b8261-0fd4-4b1a-84e2-30180a4b1826"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/21/21",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/fort-wayne/1517-west-dupont-road/",
        "index": 93.4,
        "franchise-entity-name": "EPOC FITNESS NORTH, LLC"
    },
    "31931b55-880b-43e0-88e9-6ffeea446876": {
        "name": "Orange Theory Fort Worth - Montgomery Plaza, TX",
        "address": "2600 West 7th Street, Suite 131 Fort Worth, Texas 76107",
        "phone": "(817) 888-8842",
        "email": "studiomanager0268@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 1:15PM",
            "SUNDAY": "6:15AM - 1:15PM"
        },
        "geolocation": [
            32.7520866,
            -97.353294
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.64,
                    "duration": "1 Months",
                    "model_conf": 143.85
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.88,
                    "duration": "indefinitely",
                    "model_conf": 170.95
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.84,
                    "duration": "indefinitely",
                    "model_conf": 108.65
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Fort Worth - Montgomery Plaza, TX",
            "slug": "31931b55-880b-43e0-88e9-6ffeea446876"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/25/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/fort-worth/2600-west-7th-street-suite-131/",
        "index": 103.375,
        "franchise-entity-name": "K &amp; M FITNESS IV, LLC"
    },
    "99874e21-723a-4829-a0cc-1309167d3fd5": {
        "name": "Orange Theory Fort Worth-TCU, TX",
        "address": "3050 Waits Ave Fort Worth, Texas 76109",
        "phone": "(469) 209-6565",
        "email": "studiomanager1284@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 6:30PM",
            "TUESDAY": "6:00AM - 6:30PM",
            "WEDNESDAY": "6:00AM - 6:30PM",
            "THURSDAY": "6:00AM - 6:30PM",
            "FRIDAY": "6:00AM - 6:30PM",
            "SATURDAY": "9:15AM - 12:45PM",
            "SUNDAY": "9:15AM - 12:45PM"
        },
        "geolocation": [
            32.706709,
            -97.357478
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.72,
                    "duration": "5 Months",
                    "model_conf": 135.51
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.54,
                    "duration": "indefinitely",
                    "model_conf": 159.06
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.8,
                    "duration": "indefinitely",
                    "model_conf": 109.14
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Fort Worth-TCU, TX",
            "slug": "99874e21-723a-4829-a0cc-1309167d3fd5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/10/22",
        "url": "https://www.orangetheory.com/en-us/locations/texas/fort-worth/3050-waits-ave/",
        "index": 103.375,
        "franchise-entity-name": "K&amp;M FITNESS IX, LLC"
    },
    "0adfa543-0194-4da2-bad7-0785a486a5a5": {
        "name": "Orange Theory SW Fort Worth, TX",
        "address": "5701 Bryant Irvin Road Suite 106 Fort Worth, Texas 76132",
        "phone": "(817) 770-0181",
        "email": "studiomanager0715@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            32.6659279,
            -97.41925
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.25,
                    "duration": "9 Months",
                    "model_conf": 113.36
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 111.04,
                    "duration": "indefinitely",
                    "model_conf": 124.96
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.76,
                    "duration": "2 Months",
                    "model_conf": 107.59
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "SW Fort Worth, TX",
            "slug": "0adfa543-0194-4da2-bad7-0785a486a5a5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/fort-worth/5701-bryant-irvin-road-suite-106/",
        "index": 93.4,
        "franchise-entity-name": "KMP FITNESS V, LLC"
    },
    "bb181d09-ba59-4417-bb8f-3cd3c9a167fd": {
        "name": "Orange Theory Alliance, TX",
        "address": "9500 Feather Grass Ln Fort Worth, Texas 76177",
        "phone": "(817) 741-8101",
        "email": "studiomanager0832@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "6:15AM - 1:30PM",
            "SUNDAY": "6:15AM - 1:30PM"
        },
        "geolocation": [
            32.9114571,
            -97.310654
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.07,
                    "duration": "indefinitely",
                    "model_conf": 150.88
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.57,
                    "duration": "1 Months",
                    "model_conf": 184.48
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.07,
                    "duration": "indefinitely",
                    "model_conf": 111.09
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Alliance, TX",
            "slug": "bb181d09-ba59-4417-bb8f-3cd3c9a167fd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/2/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/fort-worth/9500-feather-grass-ln/",
        "index": 93.4,
        "franchise-entity-name": "VSB VENTURES IV, LLC"
    },
    "4964f430-a1b0-4161-8257-85e8d7f79471": {
        "name": "Orange Theory Fort Wright, KY",
        "address": "3453 Valley Plaza Pkwy Fort Wright, Kentucky 41017",
        "phone": "(859) 297-7377",
        "email": "studiomanager1394@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 1:00PM",
            "TUESDAY": "5:00AM - 1:00PM",
            "WEDNESDAY": "5:00AM - 1:00PM",
            "THURSDAY": "5:00AM - 1:00PM",
            "FRIDAY": "5:00AM - 1:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            39.035153,
            -84.53298
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 4.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.81,
                    "duration": "1 Months",
                    "model_conf": 152.63
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 6.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.05,
                    "duration": "indefinitely",
                    "model_conf": 185.14
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.4,
                    "duration": "1 Months",
                    "model_conf": 111.21
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fort Wright, KY",
            "slug": "4964f430-a1b0-4161-8257-85e8d7f79471"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/24/22",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/fort-wright/3453-valley-plaza-parkway/",
        "index": 93.4,
        "franchise-entity-name": "S&amp;C Fitness V, LLC"
    },
    "3dcbfa34-b034-420f-982d-0b754892db68": {
        "name": "Orange Theory Foster City, CA",
        "address": "983 E Hillsdale Blvd Foster City, California 94404",
        "phone": "(650) 293-0929",
        "email": "studiomanager0562@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            37.5574074,
            -122.27473
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.63,
                    "duration": "5 Months",
                    "model_conf": 153.33
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 150.32,
                    "duration": "indefinitely",
                    "model_conf": 182.29
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 207.93,
                    "duration": "1 Months",
                    "model_conf": 108.74
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Foster City, CA",
            "slug": "3dcbfa34-b034-420f-982d-0b754892db68"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/23/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/foster-city/983-e-hillsdale-blvd/",
        "index": 111.325,
        "franchise-entity-name": "KOMA VENTURES IV, LLC"
    },
    "7000b09c-fb90-4391-ac0d-f5c84304cbb0": {
        "name": "Orange Theory Frankfort, IL",
        "address": "21000 S. LaGrange Road Frankfort, Illinois 60423",
        "phone": "(630) 394-5050",
        "email": "studiomanager0544@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 8:30PM",
            "TUESDAY": "4:45AM - 8:30PM",
            "WEDNESDAY": "4:45AM - 8:30PM",
            "THURSDAY": "4:45AM - 8:30PM",
            "FRIDAY": "4:45AM - 8:30PM",
            "SATURDAY": "6:00AM - 12:15PM",
            "SUNDAY": "6:00AM - 12:15PM"
        },
        "geolocation": [
            41.5081558,
            -87.850098
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.49,
                    "duration": "2 Months",
                    "model_conf": 153.02
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.61,
                    "duration": "indefinitely",
                    "model_conf": 183.41
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.86,
                    "duration": "indefinitely",
                    "model_conf": 111.2
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Frankfort, IL",
            "slug": "7000b09c-fb90-4391-ac0d-f5c84304cbb0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/10/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/frankfort/21000-s-lagrange-road/",
        "index": 103.375,
        "franchise-entity-name": "HEARNE FITNESS, LLC"
    },
    "54cb5a3a-bda0-4322-8302-6078473baea8": {
        "name": "Orange Theory Franklin, TN",
        "address": "790 Jordan Rd Suite 106 Franklin, Tennessee 37067",
        "phone": "(615) 504-1110",
        "email": "studiomanager0154@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 1:30PM",
            "SUNDAY": "6:00AM - 1:30PM"
        },
        "geolocation": [
            35.9416695,
            -86.822136
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.46,
                    "duration": "3 Months",
                    "model_conf": 151.95
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.39,
                    "duration": "indefinitely",
                    "model_conf": 180.65
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.42,
                    "duration": "1 Months",
                    "model_conf": 109.83
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Franklin, TN",
            "slug": "54cb5a3a-bda0-4322-8302-6078473baea8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/19/14",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/franklin/790-jordan-rd-suite-106/",
        "index": 93.4,
        "franchise-entity-name": "MSL FRANKLIN LLC"
    },
    "c73d606c-3698-43ea-a225-25e923ffe135": {
        "name": "Orange Theory Frederick, MD",
        "address": "7820 Q Wormans Mill Road Frederick, Maryland 21701",
        "phone": "(240) 750-6090",
        "email": "studiomanager1039@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            39.445652,
            -77.396774
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.52,
                    "duration": "indefinitely",
                    "model_conf": 153.37
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.68,
                    "duration": "indefinitely",
                    "model_conf": 183.82
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.31,
                    "duration": "indefinitely",
                    "model_conf": 112.09
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Frederick, MD",
            "slug": "c73d606c-3698-43ea-a225-25e923ffe135"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/26/18",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/frederick/7820-q-wormans-mill-road/",
        "index": 108.325,
        "franchise-entity-name": "114 YEARS, LLC"
    },
    "9c61df24-594c-41fb-8b9d-a2b81877e34f": {
        "name": "Orange Theory Fredericksburg, VA",
        "address": "10002 Southpoint Pkwy Fredericksburg, Virginia 22407",
        "phone": "(540) 300-6688",
        "email": "studiomanager0762@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            38.2371559,
            -77.506233
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.79,
                    "duration": "1 Months",
                    "model_conf": 153.34
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.27,
                    "duration": "indefinitely",
                    "model_conf": 183.76
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.81,
                    "duration": "indefinitely",
                    "model_conf": 111.76
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fredericksburg, VA",
            "slug": "9c61df24-594c-41fb-8b9d-a2b81877e34f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/29/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/fredericksburg/10002-southpoint-pkwy/",
        "index": 98.425,
        "franchise-entity-name": "BAMFREDERICKSBURG, LLC"
    },
    "448f5f7b-0255-4331-bb5c-89d1a3134b7d": {
        "name": "Orange Theory Freehold, NJ",
        "address": "4345 Highway 9 Freehold, New Jersey 07728",
        "phone": "(848) 444-1085",
        "email": "studiomanager0718@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            40.2834663,
            -74.29306
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.14,
                    "duration": "indefinitely",
                    "model_conf": 150.36
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.92,
                    "duration": "6 Months",
                    "model_conf": 182.86
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.41,
                    "duration": "indefinitely",
                    "model_conf": 109.76
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Freehold, NJ",
            "slug": "448f5f7b-0255-4331-bb5c-89d1a3134b7d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/24/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/freehold/4345-highway-9/",
        "index": 98.425,
        "franchise-entity-name": "HHH Fitness Two LLC"
    },
    "9783d05e-6e98-4f58-93bf-3a39ac066ab3": {
        "name": "Orange Theory Fremont/Artist Walk, CA",
        "address": "37140 Fremont Blvd Fremont, California 94555",
        "phone": "(510) 736-5646",
        "email": "studiomanager0704@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:10PM",
            "TUESDAY": "5:00AM - 9:10PM",
            "WEDNESDAY": "5:00AM - 9:10PM",
            "THURSDAY": "5:00AM - 9:10PM",
            "FRIDAY": "5:00AM - 9:10PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            37.5603371,
            -122.00973
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.53,
                    "duration": "1 Months",
                    "model_conf": 152.49
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.18,
                    "duration": "indefinitely",
                    "model_conf": 183.43
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.59,
                    "duration": "indefinitely",
                    "model_conf": 110.38
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Fremont/Artist Walk, CA",
            "slug": "9783d05e-6e98-4f58-93bf-3a39ac066ab3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/17/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/fremont/37140-fremont-blvd/",
        "index": 108.325,
        "franchise-entity-name": "TFP CALIFORNIA FITNESS PARTNERS IV, LLC."
    },
    "bc310370-1d5d-44f0-9df3-28ce8536e36a": {
        "name": "Orange Theory Fremont - Warm Springs, CA",
        "address": "46505 Mission Boulevard Fremont, California 94539",
        "phone": "(510) 735-0650",
        "email": "studiomanager0703@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 9:45AM",
            "SUNDAY": "7:30AM - 9:45AM"
        },
        "geolocation": [
            37.492884,
            -121.92815
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.16,
                    "duration": "indefinitely",
                    "model_conf": 152.94
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.83,
                    "duration": "indefinitely",
                    "model_conf": 183.73
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.42,
                    "duration": "indefinitely",
                    "model_conf": 111.07
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Fremont - Warm Springs, CA",
            "slug": "bc310370-1d5d-44f0-9df3-28ce8536e36a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/15/22",
        "url": "https://www.orangetheory.com/en-us/locations/california/fremont/46505-mission-boulevard/",
        "index": 111.325,
        "franchise-entity-name": "TFP CALIFORNIA FITNESS PARTNERS III, LLC."
    },
    "ca096e18-d958-41b0-95b2-f2ec115ced49": {
        "name": "Orange Theory Fresno, CA",
        "address": "7735 N. Blackstone Ave. Ste. 106-112 Fresno, California 93720",
        "phone": "(559) 326-9255",
        "email": "studiomanager0842@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:45PM",
            "SUNDAY": "6:30AM - 12:45PM"
        },
        "geolocation": [
            36.8489647,
            -119.79096
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.99,
                    "duration": "indefinitely",
                    "model_conf": 152.6
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.14,
                    "duration": "indefinitely",
                    "model_conf": 184.27
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.82,
                    "duration": "indefinitely",
                    "model_conf": 111.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fresno, CA",
            "slug": "ca096e18-d958-41b0-95b2-f2ec115ced49"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/24/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/fresno/7735-n-blackstone-ave-ste-106-112/",
        "index": 98.425,
        "franchise-entity-name": "SUNNY SIDE FITNESS, LLC"
    },
    "f4513975-1674-4a2c-a707-8ae7bbf5fe2e": {
        "name": "Orange Theory Friendswood, TX",
        "address": "1769 S Friendswood Dr. Suite 100 Friendswood, Texas 77546",
        "phone": "(832) 481-4483",
        "email": "studiomanager0982@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            29.5070286,
            -95.18618
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.66,
                    "duration": "indefinitely",
                    "model_conf": 128.64
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.93,
                    "duration": "3 Months",
                    "model_conf": 173.47
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.48,
                    "duration": "indefinitely",
                    "model_conf": 103.55
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Friendswood, TX",
            "slug": "f4513975-1674-4a2c-a707-8ae7bbf5fe2e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/friendswood/1769-s-friendswood-dr-suite-100/",
        "index": 98.425,
        "franchise-entity-name": "FRIENDS COACHING LLC"
    },
    "82f8c528-68de-4704-b31c-81fbbecf3978": {
        "name": "Orange Theory McKinney, TX",
        "address": "15910 Eldorado Parkway Suite # 500 Frisco, Texas 75035",
        "phone": "(469) 634-2100",
        "email": "studiomanager0338@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            33.174366,
            -96.733101
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.5,
                    "duration": "2 Months",
                    "model_conf": 133.43
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.2,
                    "duration": "indefinitely",
                    "model_conf": 162.81
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.75,
                    "duration": "indefinitely",
                    "model_conf": 105.81
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "McKinney, TX",
            "slug": "82f8c528-68de-4704-b31c-81fbbecf3978"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/3/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/frisco/15910-eldorado-parkway/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA FITNESS - MCKINNEY, LLC"
    },
    "fba5ca41-ee4d-4028-8f26-5d65cebd48d9": {
        "name": "Orange Theory West Frisco, TX",
        "address": "3555 Legacy Drive Frisco, Texas 75034",
        "phone": "(972) 474-0389",
        "email": "studiomanager0876@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 7:45PM",
            "TUESDAY": "4:30AM - 7:45PM",
            "WEDNESDAY": "4:30AM - 7:45PM",
            "THURSDAY": "4:30AM - 7:45PM",
            "FRIDAY": "4:30AM - 7:45PM",
            "SATURDAY": "6:15AM - 1:15PM",
            "SUNDAY": "6:15AM - 1:15PM"
        },
        "geolocation": [
            33.1080818,
            -96.842384
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.04,
                    "duration": "5 Months",
                    "model_conf": 141.37
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.77,
                    "duration": "indefinitely",
                    "model_conf": 168.81
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.24,
                    "duration": "1 Months",
                    "model_conf": 104.0
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "West Frisco, TX",
            "slug": "fba5ca41-ee4d-4028-8f26-5d65cebd48d9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/15/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/frisco/3555-legacy-dr-suite-100/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA FITNESS - WEST FRISCO, LLC"
    },
    "b698bbf5-b39a-4c4e-ad35-112c5b1ced7c": {
        "name": "Orange Theory Frisco, TX",
        "address": "4350 W. Main Street, Suite 100 Frisco, Texas 75034",
        "phone": "(972) 521-3600",
        "email": "studiomanager0130@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 8:15PM",
            "TUESDAY": "4:45AM - 8:15PM",
            "WEDNESDAY": "4:45AM - 8:15PM",
            "THURSDAY": "4:45AM - 8:15PM",
            "FRIDAY": "4:45AM - 8:15PM",
            "SATURDAY": "6:30AM - 1:45PM",
            "SUNDAY": "6:30AM - 1:45PM"
        },
        "geolocation": [
            33.1545601,
            -96.849899
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.11,
                    "duration": "5 Months",
                    "model_conf": 143.98
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.99,
                    "duration": "indefinitely",
                    "model_conf": 172.69
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.12,
                    "duration": "2 Months",
                    "model_conf": 107.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Frisco, TX",
            "slug": "b698bbf5-b39a-4c4e-ad35-112c5b1ced7c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/texas/frisco/4350-w-main-street-suite-100/",
        "index": 93.4,
        "franchise-entity-name": "OTF FRISCO LLC"
    },
    "2a6c3f24-9d94-4a86-a92b-50690818857c": {
        "name": "Orange Theory North Ft. Lauderdale - Commercial, FL",
        "address": "1759 E Commercial Blvd. Ft. Lauderdale, Florida 33334",
        "phone": "(954) 229-9800",
        "email": "studiomanager0005@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:00PM",
            "TUESDAY": "5:30AM - 7:00PM",
            "WEDNESDAY": "5:30AM - 7:00PM",
            "THURSDAY": "5:30AM - 7:00PM",
            "FRIDAY": "5:30AM - 7:00PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            26.1891365,
            -80.123512
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.71,
                    "duration": "1 Months",
                    "model_conf": 145.91
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.35,
                    "duration": "indefinitely",
                    "model_conf": 181.56
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.66,
                    "duration": "indefinitely",
                    "model_conf": 108.89
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "North Ft. Lauderdale - Commercial, FL",
            "slug": "2a6c3f24-9d94-4a86-a92b-50690818857c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/4/11",
        "url": "https://www.orangetheory.com/en-us/locations/florida/ft-lauderdale/1759-e-commercial-blvd/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS, LLC"
    },
    "90857aca-6ed0-4459-a7f9-d8b9bfac9942": {
        "name": "Orange Theory Fort Lauderdale, FL",
        "address": "1835 Cordova Rd. Ft. Lauderdale, Florida 33316",
        "phone": "(954) 765-6014",
        "email": "studiomanager0001@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:00PM",
            "TUESDAY": "5:30AM - 7:00PM",
            "WEDNESDAY": "5:30AM - 7:00PM",
            "THURSDAY": "5:30AM - 7:00PM",
            "FRIDAY": "5:30AM - 7:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            26.0990257,
            -80.13076
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.77,
                    "duration": "1 Months",
                    "model_conf": 148.03
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.03,
                    "duration": "indefinitely",
                    "model_conf": 183.05
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.73,
                    "duration": "indefinitely",
                    "model_conf": 110.27
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fort Lauderdale, FL",
            "slug": "90857aca-6ed0-4459-a7f9-d8b9bfac9942"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/17/10",
        "url": "https://www.orangetheory.com/en-us/locations/florida/ft-lauderdale/1835-cordova-rd/",
        "index": 98.425,
        "franchise-entity-name": "OTF PROPERTY HOLDINGS, LLC"
    },
    "75bb851c-6d3f-48f3-9bb7-d30c572c1fc5": {
        "name": "Orange Theory Central Fort Lauderdale, FL",
        "address": "2354 N Federal Hwy Ft. Lauderdale, Florida 33305",
        "phone": "(954) 372-2361",
        "email": "studiomanager0373@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "7:00AM - 7:00PM",
            "TUESDAY": "7:00AM - 7:00PM",
            "WEDNESDAY": "7:00AM - 7:00PM",
            "THURSDAY": "7:00AM - 7:00PM",
            "FRIDAY": "7:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            26.1542587,
            -80.117386
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.42,
                    "duration": "2 Months",
                    "model_conf": 148.41
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.32,
                    "duration": "indefinitely",
                    "model_conf": 182.88
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.11,
                    "duration": "1 Months",
                    "model_conf": 110.17
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Central Fort Lauderdale, FL",
            "slug": "75bb851c-6d3f-48f3-9bb7-d30c572c1fc5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/7/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/ft-lauderdale/2354-n-federal-hwy/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS, LLC"
    },
    "c00d0934-3147-4fc7-b59d-910ca4ae1c3d": {
        "name": "Orange Theory Ft. Worth Camp Bowie, TX",
        "address": "6201 Sunset Drive, Suite 680 Ft. Worth, Texas 76116",
        "phone": "(682) 200-6320",
        "email": "studiomanager0964@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:45AM - 1:45PM",
            "SUNDAY": "7:45AM - 1:45PM"
        },
        "geolocation": [
            32.7264366,
            -97.416573
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.21,
                    "duration": "10 Months",
                    "model_conf": 128.06
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 111.51,
                    "duration": "indefinitely",
                    "model_conf": 147.68
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.85,
                    "duration": "2 Months",
                    "model_conf": 108.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Ft. Worth Camp Bowie, TX",
            "slug": "c00d0934-3147-4fc7-b59d-910ca4ae1c3d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/ft-worth/6201-sunset-drive-suite-680/",
        "index": 93.4,
        "franchise-entity-name": "K&amp;M FITNESS VII, LLC"
    },
    "e924b9c9-bd0b-45ae-980f-164754144bef": {
        "name": "Orange Theory Georgetown, TX",
        "address": "1500 Rivery Blvd, Suite 340 Georgetown, Texas 78628",
        "phone": "(512) 630-0252",
        "email": "studiomanager0627@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 2:00PM",
            "SUNDAY": "7:00AM - 2:00PM"
        },
        "geolocation": [
            30.6491604,
            -97.687943
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.96,
                    "duration": "5 Months",
                    "model_conf": 152.2
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.99,
                    "duration": "indefinitely",
                    "model_conf": 184.02
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.02,
                    "duration": "2 Months",
                    "model_conf": 111.69
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Georgetown, TX",
            "slug": "e924b9c9-bd0b-45ae-980f-164754144bef"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/georgetown/1500-rivery-blvd-suite-340/",
        "index": 98.425,
        "franchise-entity-name": "SIX.FOURTEEN.TWELVE I, LLC"
    },
    "88f08619-84ad-4c5b-85bc-07c13c3aabd7": {
        "name": "Orange Theory Greenville, SC",
        "address": "101 Falls Park Drive Suite 101 Greenville, South Carolina 29601",
        "phone": "(864) 509-9147",
        "email": "studiomanager0430@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 1:15PM",
            "SUNDAY": "6:15AM - 1:15PM"
        },
        "geolocation": [
            34.8459053,
            -82.403442
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.9,
                    "duration": "6 Months",
                    "model_conf": 152.18
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.06,
                    "duration": "indefinitely",
                    "model_conf": 166.68
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.15,
                    "duration": "1 Months",
                    "model_conf": 100.47
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Greenville, SC",
            "slug": "88f08619-84ad-4c5b-85bc-07c13c3aabd7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/9/18",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/greenville/101-falls-park-drive-suite-101/",
        "index": 93.4,
        "franchise-entity-name": "GREENVILLE GODDESS I, LLC"
    },
    "bd8f79a1-2719-4d89-8945-60ee6136bce9": {
        "name": "Orange Theory GAHANNA, OH",
        "address": "319 StoneRidge Lane Gahanna, Ohio 43230",
        "phone": "(614) 210-3955",
        "email": "studiomanager1103@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            40.051754,
            -82.8675
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.83,
                    "duration": "6 Months",
                    "model_conf": 145.82
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.26,
                    "duration": "indefinitely",
                    "model_conf": 177.6
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.18,
                    "duration": "1 Months",
                    "model_conf": 107.23
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "GAHANNA, OH",
            "slug": "bd8f79a1-2719-4d89-8945-60ee6136bce9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/1/19",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/gahanna/319-stoneridge-lane/",
        "index": 93.4,
        "franchise-entity-name": "KO 2 GROUP FITNESS"
    },
    "5c6618f0-c154-4b54-82bf-709689a1f68d": {
        "name": "Orange Theory Gainesville, FL",
        "address": "2005 Northwest 43rd Street Gainesville, Florida 32605",
        "phone": "(352) 559-3937",
        "email": "studiomanager0161@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            29.6716843,
            -82.388107
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.97,
                    "duration": "indefinitely",
                    "model_conf": 153.6
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.34,
                    "duration": "2 Months",
                    "model_conf": 181.73
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.58,
                    "duration": "indefinitely",
                    "model_conf": 111.42
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Gainesville, FL",
            "slug": "5c6618f0-c154-4b54-82bf-709689a1f68d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/17/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/gainesville/2005-nw-43rd-street/",
        "index": 93.4,
        "franchise-entity-name": "GAINESVILLE COACHING LLC"
    },
    "87ed2bd1-b376-495c-b5a8-48eefa9b6525": {
        "name": "Orange Theory Gainesville SW, FL",
        "address": "3205 Clark Butler Road, Suite 20 Gainesville, Florida 32608",
        "phone": "(352) 242-0020",
        "email": "studiomanager0652@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 2:45PM",
            "SUNDAY": "7:30AM - 2:45PM"
        },
        "geolocation": [
            29.6242199,
            -82.382042
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.45,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.99,
                    "duration": "3 Months",
                    "model_conf": 175.61
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.21,
                    "duration": "indefinitely",
                    "model_conf": 110.9
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Gainesville SW, FL",
            "slug": "87ed2bd1-b376-495c-b5a8-48eefa9b6525"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/3/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/gainesville/3205-clark-butler-road-suite-20/",
        "index": 93.4,
        "franchise-entity-name": "GAINESVILLE COACHING II LLC"
    },
    "6674ffc4-5672-41da-8514-ab24a84d0be8": {
        "name": "Orange Theory Gainesville University, FL",
        "address": "50 NW 13th St Gainesville, Florida 32601",
        "phone": "(352) 415-3080",
        "email": "studiomanager1365@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "7:45AM - 6:45PM",
            "TUESDAY": "7:45AM - 6:45PM",
            "WEDNESDAY": "7:45AM - 6:45PM",
            "THURSDAY": "7:45AM - 6:45PM",
            "FRIDAY": "7:45AM - 6:45PM",
            "SATURDAY": "9:15AM - 12:00PM",
            "SUNDAY": "9:15AM - 12:00PM"
        },
        "geolocation": [
            29.6526165,
            -82.339539
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.2,
                    "duration": "indefinitely",
                    "model_conf": 153.6
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.28,
                    "duration": "indefinitely",
                    "model_conf": 181.71
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.0,
                    "duration": "indefinitely",
                    "model_conf": 111.44
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Gainesville University, FL",
            "slug": "6674ffc4-5672-41da-8514-ab24a84d0be8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/22/20",
        "url": "https://www.orangetheory.com/en-us/locations/florida/gainesville/50-nw-13th-st/",
        "index": 93.4,
        "franchise-entity-name": "GAINESVILLE COACHING III LLC"
    },
    "ab3e2540-989b-4f31-aa32-cd656253b5d1": {
        "name": "Orange Theory Gainesville, GA",
        "address": "833 Dawsonville Hwy Suite 220 Gainesville, Georgia 30501",
        "phone": "(770) 631-5966",
        "email": "studiomanager1397@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.2965088,
            -83.85865
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.38,
                    "duration": "indefinitely",
                    "model_conf": 153.72
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.59,
                    "duration": "1 Months",
                    "model_conf": 185.43
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.32,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Gainesville, GA",
            "slug": "ab3e2540-989b-4f31-aa32-cd656253b5d1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/19",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/gainesville/833-dawsonville-hwy-suite-220/",
        "index": 98.425,
        "franchise-entity-name": "JM Gainesville LLC"
    },
    "523e7aae-d316-4904-9e33-ab1211034fee": {
        "name": "Orange Theory Gainesville, VA",
        "address": "14062 Promenade Commons Ct Gainesville, Virginia 20155",
        "phone": "(571) 222-5448",
        "email": "studiomanager0636@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            38.7896385,
            -77.606033
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.62,
                    "duration": "indefinitely",
                    "model_conf": 143.92
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.21,
                    "duration": "2 Months",
                    "model_conf": 172.92
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.25,
                    "duration": "indefinitely",
                    "model_conf": 107.61
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Gainesville, VA",
            "slug": "523e7aae-d316-4904-9e33-ab1211034fee"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/21/16",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/gainesville/14062-promenade-commons-ct/",
        "index": 98.425,
        "franchise-entity-name": "G3 FITNESS GROUP DC1, LLC"
    },
    "35dabff1-aeb2-4d87-99c4-5d799bf6aa73": {
        "name": "Orange Theory Gaithersburg, MD",
        "address": "622 Center Point Way Gaithersburg, Maryland 20697",
        "phone": "(301) 250-1060",
        "email": "studiomanager0081@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.1219482,
            -77.232712
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.7,
                    "duration": "indefinitely",
                    "model_conf": 151.73
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 137.9,
                    "duration": "3 Months",
                    "model_conf": 181.98
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.14,
                    "duration": "indefinitely",
                    "model_conf": 110.07
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Gaithersburg, MD",
            "slug": "35dabff1-aeb2-4d87-99c4-5d799bf6aa73"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/14",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/gaithersburg/622-center-point-way/",
        "index": 108.325,
        "franchise-entity-name": "KENTLANDS FITNESS PARTNERS, LLC"
    },
    "47c1af6b-5859-4654-820d-c884b27666c8": {
        "name": "Orange Theory Gambrills, MD",
        "address": "2515 Evergreen Rd Suite 103 Gambrills, Maryland 21054",
        "phone": "(410) 697-1700",
        "email": "studiomanager1327@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.0326119,
            -76.692421
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.74,
                    "duration": "indefinitely",
                    "model_conf": 152.17
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.05,
                    "duration": "2 Months",
                    "model_conf": 184.46
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.49,
                    "duration": "indefinitely",
                    "model_conf": 111.21
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Gambrills, MD",
            "slug": "47c1af6b-5859-4654-820d-c884b27666c8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/23/22",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/gambrills/2515-evergreen-rd-suite-103/",
        "index": 108.325,
        "franchise-entity-name": "Chesapeake Fitness Partners LLC"
    },
    "fdbed7e8-7aaa-497f-84b4-bc49360d4429": {
        "name": "Orange Theory Garden City Park, NY",
        "address": "2333 Jericho Turnpike Garden City Park, New York 11040",
        "phone": "(516) 564-2000",
        "email": "studiomanager0789@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 1:30PM",
            "SUNDAY": "6:30AM - 1:30PM"
        },
        "geolocation": [
            40.7422409,
            -73.662498
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 94.68,
                    "duration": "indefinitely",
                    "model_conf": 74.41
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 144.23,
                    "duration": "indefinitely",
                    "model_conf": 90.52
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 195.88,
                    "duration": "6 Months",
                    "model_conf": 54.01
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Garden City Park, NY",
            "slug": "fdbed7e8-7aaa-497f-84b4-bc49360d4429"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/10/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/garden-city-park/2333-jericho-turnpike/",
        "index": 108.325,
        "franchise-entity-name": "RADCORE, LLC"
    },
    "353b38a3-9648-48c9-a721-a779ae89205e": {
        "name": "Orange Theory Garwood, NJ",
        "address": "502 North Avenue Garwood, New Jersey 07027",
        "phone": "(908) 737-1390",
        "email": "studiomanager0046@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            40.6527519,
            -74.329277
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.19,
                    "duration": "indefinitely",
                    "model_conf": 122.81
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 122.23,
                    "duration": "indefinitely",
                    "model_conf": 146.16
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.18,
                    "duration": "3 Months",
                    "model_conf": 91.42
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Garwood, NJ",
            "slug": "353b38a3-9648-48c9-a721-a779ae89205e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/garwood/502-north-avenue/",
        "index": 98.425,
        "franchise-entity-name": "OTF NJ TWO LLC"
    },
    "78e1c2f3-109f-488b-b801-e60f9c170841": {
        "name": "Orange Theory Geneva, IL",
        "address": "2401 Kaneville Road Geneva, Illinois 60134",
        "phone": "(630) 503-6818",
        "email": "studiomanager0537@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:15AM - 1:00PM",
            "SUNDAY": "7:15AM - 1:00PM"
        },
        "geolocation": [
            41.8836441,
            -88.339111
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.35,
                    "duration": "2 Months",
                    "model_conf": 146.76
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.75,
                    "duration": "indefinitely",
                    "model_conf": 173.64
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.3,
                    "duration": "1 Months",
                    "model_conf": 110.78
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Geneva, IL",
            "slug": "78e1c2f3-109f-488b-b801-e60f9c170841"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/8/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/geneva/2401-kaneville-road/",
        "index": 103.375,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-WEST, LLC"
    },
    "3a0f599e-0394-47b3-8b1b-90799342f8c6": {
        "name": "Orange Theory Georgetown",
        "address": "1815 Wisconsin Ave NW Georgetown, District Of Columbia 20007",
        "phone": "(202) 916-8800",
        "email": "studiomanager1189@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:30PM",
            "TUESDAY": "6:15AM - 7:30PM",
            "WEDNESDAY": "6:15AM - 7:30PM",
            "THURSDAY": "6:15AM - 7:30PM",
            "FRIDAY": "6:15AM - 7:30PM",
            "SATURDAY": "9:00AM - 11:45AM",
            "SUNDAY": "9:00AM - 11:45AM"
        },
        "geolocation": [
            38.9151993,
            -77.067192
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.62,
                    "duration": "indefinitely",
                    "model_conf": 114.65
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 159.0,
                    "duration": "indefinitely",
                    "model_conf": 136.96
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.12,
                    "duration": "indefinitely",
                    "model_conf": 83.36
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Georgetown",
            "slug": "3a0f599e-0394-47b3-8b1b-90799342f8c6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/20",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/georgetown/1815-wisconsin-ave-nw/",
        "index": 118.225,
        "franchise-entity-name": "GLOVER PARK FITNESS LLC"
    },
    "b7b0230e-2d40-464d-9e74-b7990cde988b": {
        "name": "Orange Theory Germantown, TN",
        "address": "9067 Poplar Ave., Suite 105 Germantown, Tennessee 38138",
        "phone": "(901) 907-0298",
        "email": "studiomanager0145@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            35.0678291,
            -89.761894
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.33,
                    "duration": "indefinitely",
                    "model_conf": 139.74
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.56,
                    "duration": "1 Months",
                    "model_conf": 185.46
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.6,
                    "duration": "indefinitely",
                    "model_conf": 102.68
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Germantown, TN",
            "slug": "b7b0230e-2d40-464d-9e74-b7990cde988b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/9/14",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/germantown/9067-poplar-ave-suite-105/",
        "index": 93.4,
        "franchise-entity-name": "RECON2013, LLC"
    },
    "3021be5c-a7b9-4a1d-bec7-43f5f9e5f5b0": {
        "name": "Orange Theory Gig Harbor",
        "address": "4935 Point Fosdick F500 Gig Harbor, Washington 98335",
        "phone": "(253) 358-2232",
        "email": "studiomanager1213@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            47.3035049,
            -122.57713
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.95,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.8,
                    "duration": "indefinitely",
                    "model_conf": 152.32
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 10.47,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.37,
                    "duration": "indefinitely",
                    "model_conf": 183.85
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 15.75,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.7,
                    "duration": "indefinitely",
                    "model_conf": 111.98
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 19.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 33,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 43.91,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Gig Harbor",
            "slug": "3021be5c-a7b9-4a1d-bec7-43f5f9e5f5b0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/washington/gig-harbor/4935-point-fosdick-f500/",
        "index": 98.425,
        "franchise-entity-name": "GIG HARBOR FITNESS, LLC"
    },
    "5ff9c0d1-08f9-4083-a55f-d146fad267c1": {
        "name": "Orange Theory Gilbert, AZ",
        "address": "1110 S Gilbert Rd., Suite 102 Gilbert, Arizona 85296",
        "phone": "(480) 272-9400",
        "email": "studiomanager0230@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            33.3297272,
            -111.79078
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 1.34,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.3,
                    "duration": "indefinitely",
                    "model_conf": 116.3
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 1.94,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.34,
                    "duration": "5 Months",
                    "model_conf": 139.53
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 2.84,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.69,
                    "duration": "indefinitely",
                    "model_conf": 83.64
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 3.68,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 5.7,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 7.65,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Gilbert, AZ",
            "slug": "5ff9c0d1-08f9-4083-a55f-d146fad267c1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/15/15",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/gilbert/1110-s-gilbert-rd-suite-102/",
        "index": 103.375,
        "franchise-entity-name": "GILBERT FIT, LLC"
    },
    "e694348e-50c1-4961-bdd0-b5956889d39b": {
        "name": "Orange Theory Gilbert- East, AZ",
        "address": "1440 S. Higley Road, Suite 105 (between Ray and 202) Gilbert, Arizona 85296",
        "phone": "(480) 685-4950",
        "email": "studiomanager0292@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "5:30AM - 12:00PM",
            "SUNDAY": "5:30AM - 12:00PM"
        },
        "geolocation": [
            33.3244247,
            -111.72182
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 1.34,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.68,
                    "duration": "indefinitely",
                    "model_conf": 136.05
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 1.94,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.05,
                    "duration": "5 Months",
                    "model_conf": 163.57
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 2.84,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.18,
                    "duration": "indefinitely",
                    "model_conf": 97.72
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 3.68,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 5.7,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 7.65,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Gilbert- East, AZ",
            "slug": "e694348e-50c1-4961-bdd0-b5956889d39b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/5/17",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/gilbert/1440-s-higley-road-suite-105-between-ray-and-202/",
        "index": 103.375,
        "franchise-entity-name": "EAST GILBERT FIT, LLC"
    },
    "52a9c976-188b-48c5-b88b-430b3d88b07f": {
        "name": "Orange Theory Gilroy, CA",
        "address": "1415 1st St, Ste 102 Gilroy, California 95020",
        "phone": "(408) 430-0823",
        "email": "studiomanager1128@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            37.013658,
            -121.59712
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.72,
                    "duration": "1 Months",
                    "model_conf": 153.81
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.2,
                    "duration": "2 Months",
                    "model_conf": 185.72
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.63,
                    "duration": "indefinitely",
                    "model_conf": 112.1
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Gilroy, CA",
            "slug": "52a9c976-188b-48c5-b88b-430b3d88b07f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/18/22",
        "url": "https://www.orangetheory.com/en-us/locations/california/gilroy/14151st-st-ste-102/",
        "index": 111.325,
        "franchise-entity-name": "After Burn, LLC"
    },
    "82d0e38d-bcba-4bfe-aa37-16435cd488c1": {
        "name": "Orange Theory Glastonbury, CT",
        "address": "27 Welles Street Glastonbury, Connecticut 06033",
        "phone": "(860) 856-5807",
        "email": "studiomanager0623@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            41.7148933,
            -72.606331
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.21,
                    "duration": "3 Months",
                    "model_conf": 152.07
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.31,
                    "duration": "indefinitely",
                    "model_conf": 185.13
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.61,
                    "duration": "indefinitely",
                    "model_conf": 111.56
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Glastonbury, CT",
            "slug": "82d0e38d-bcba-4bfe-aa37-16435cd488c1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/glastonbury/27-welles-street/",
        "index": 98.425,
        "franchise-entity-name": "M2 GLASTONBURY LLC."
    },
    "6e762f48-b64b-4e77-9315-6747bed2fc0e": {
        "name": "Orange Theory Glen Ellyn, IL",
        "address": "613 Roosevelt Road Glen Ellyn, Illinois 60137",
        "phone": "(630) 984-6886",
        "email": "studiomanager0326@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.85932,
            -88.06302
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.83,
                    "duration": "indefinitely",
                    "model_conf": 124.89
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.34,
                    "duration": "5 Months",
                    "model_conf": 148.84
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.65,
                    "duration": "indefinitely",
                    "model_conf": 91.23
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Glen Ellyn, IL",
            "slug": "6e762f48-b64b-4e77-9315-6747bed2fc0e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/18/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/glen-ellyn/862-roosevelt-road/",
        "index": 103.375,
        "franchise-entity-name": "CHICAGOLAND FITNESS XII LLC"
    },
    "45faccbc-24d7-4ed5-b8d2-6aaf94cdbb5c": {
        "name": "Orange Theory Glen Mills, PA",
        "address": "101 Applied Bank Boulevard Suite D-1 Glen Mills, Pennsylvania 19342",
        "phone": "(484) 588-5419",
        "email": "studiomanager1292@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            39.8774796,
            -75.543045
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.81,
                    "duration": "indefinitely",
                    "model_conf": 150.84
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.3,
                    "duration": "indefinitely",
                    "model_conf": 184.86
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.57,
                    "duration": "indefinitely",
                    "model_conf": 107.67
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Glen Mills, PA",
            "slug": "45faccbc-24d7-4ed5-b8d2-6aaf94cdbb5c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/23/19",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/glen-mills/101-applied-bank-boulevard-suite-d-1/",
        "index": 98.425,
        "franchise-entity-name": "P&amp;L FITNESS 3, LLC"
    },
    "b3517be3-3a13-4b3f-8ab5-fdcb98c4a42b": {
        "name": "Orange Theory Arrowhead, AZ",
        "address": "17530 N 75th Ave, Ste 100 Glendale, Arizona 85308",
        "phone": "(623) 266-9671",
        "email": "studiomanager0072@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:00AM - 11:15AM",
            "SUNDAY": "6:00AM - 11:15AM"
        },
        "geolocation": [
            33.6427536,
            -112.22097
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 2.58,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.62,
                    "duration": "6 Months",
                    "model_conf": 150.91
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 3.74,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.84,
                    "duration": "indefinitely",
                    "model_conf": 177.96
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 5.48,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.11,
                    "duration": "1 Months",
                    "model_conf": 110.26
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 7.1,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 11.02,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 14.79,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Arrowhead, AZ",
            "slug": "b3517be3-3a13-4b3f-8ab5-fdcb98c4a42b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/23/13",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/glendale/17530-n-75th-ave-ste-100/",
        "index": 103.375,
        "franchise-entity-name": "ARROWHEAD OTF LLC"
    },
    "4b955a5a-36f4-4756-8c2d-1acba2ce3172": {
        "name": "Orange Theory Glendale-Thunderbird, AZ",
        "address": "5839 W. Thunderbird Rd Ste A-102 Glendale, Arizona 85306",
        "phone": "(623) 439-2300",
        "email": "studiomanager1270@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:30AM - 11:15AM",
            "SUNDAY": "7:30AM - 11:15AM"
        },
        "geolocation": [
            33.6093979,
            -112.18484
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 2.58,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.36,
                    "duration": "2 Months",
                    "model_conf": 150.36
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 3.74,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.72,
                    "duration": "indefinitely",
                    "model_conf": 178.32
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 5.48,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.34,
                    "duration": "1 Months",
                    "model_conf": 109.56
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 7.1,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 11.02,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 14.79,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Glendale-Thunderbird, AZ",
            "slug": "4b955a5a-36f4-4756-8c2d-1acba2ce3172"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/16/19",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/glendale/5839-w-thunderbird-rd-ste-a-102/",
        "index": 103.375,
        "franchise-entity-name": "Glendale Fitness Partners, LLC"
    },
    "c805b7d8-5ec0-47e7-8d51-d44fa401bb55": {
        "name": "Orange Theory Glendale - Silver Lake, CA",
        "address": "101 N. Brand Suite 140 Glendale, California 91203",
        "phone": "(818) 398-7155",
        "email": "studiomanager0183@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            34.1468773,
            -118.25547
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.78,
                    "duration": "indefinitely",
                    "model_conf": 151.25
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.33,
                    "duration": "1 Months",
                    "model_conf": 180.36
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.42,
                    "duration": "indefinitely",
                    "model_conf": 106.0
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Glendale - Silver Lake, CA",
            "slug": "c805b7d8-5ec0-47e7-8d51-d44fa401bb55"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/9/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/glendale/101-n-brand-suite-140/",
        "index": 111.325,
        "franchise-entity-name": "FITNESS FANATICS LOS FELIZ LLC"
    },
    "92dd7af8-752e-4fbe-86af-9a4192c44667": {
        "name": "Orange Theory Glendale, CO",
        "address": "760 S. Colorado Blvd. Unit C Glendale, Colorado 80211",
        "phone": "(303) 996-8854",
        "email": "studiomanager0040@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            39.7028122,
            -104.93993
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.42,
                    "duration": "indefinitely",
                    "model_conf": 134.13
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.24,
                    "duration": "2 Months",
                    "model_conf": 164.79
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.48,
                    "duration": "indefinitely",
                    "model_conf": 98.36
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Glendale, CO",
            "slug": "92dd7af8-752e-4fbe-86af-9a4192c44667"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/16/13",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/glendale/760-s-colorado-blvd-unit-c/",
        "index": 98.425,
        "franchise-entity-name": "OTF COLORADO BOULEVARD, LLC"
    },
    "72e506ce-af00-461d-91a9-74b877c4161d": {
        "name": "Orange Theory Glendora, CA",
        "address": "651 S Grand Ave Glendora, California 91740",
        "phone": "(626) 804-1655",
        "email": "studiomanager0551@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            34.1279678,
            -117.87402
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.38,
                    "duration": "indefinitely",
                    "model_conf": 152.05
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.36,
                    "duration": "1 Months",
                    "model_conf": 183.78
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.81,
                    "duration": "indefinitely",
                    "model_conf": 109.7
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Glendora, CA",
            "slug": "72e506ce-af00-461d-91a9-74b877c4161d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/2/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/glendora/651-s-grand-ave/",
        "index": 103.375,
        "franchise-entity-name": "HIBI FITNESS MANAGEMENT III, LLC"
    },
    "da24ab9b-8a58-4532-9596-1d22233be464": {
        "name": "Orange Theory Glenview, IL",
        "address": "1464 Waukegan Road Glenview, Illinois 60025",
        "phone": "(847) 486-9100",
        "email": "studiomanager0237@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            42.0820694,
            -87.798477
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.66,
                    "duration": "indefinitely",
                    "model_conf": 144.39
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.79,
                    "duration": "3 Months",
                    "model_conf": 166.63
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.99,
                    "duration": "indefinitely",
                    "model_conf": 105.29
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Glenview, IL",
            "slug": "da24ab9b-8a58-4532-9596-1d22233be464"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/27/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/glenview/1464-waukegan-road/",
        "index": 103.375,
        "franchise-entity-name": "C &amp; Z FIT, LLC"
    },
    "d109ae75-d1ac-4b03-b0da-67da5d768e3b": {
        "name": "Orange Theory Santa Barbara -Goleta, CA",
        "address": "5700 Calle Real Goleta, California 93117",
        "phone": "(805) 699-5551",
        "email": "studiomanager0549@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:45AM - 6:30PM",
            "TUESDAY": "5:45AM - 6:30PM",
            "WEDNESDAY": "5:45AM - 6:30PM",
            "THURSDAY": "5:45AM - 6:30PM",
            "FRIDAY": "5:45AM - 6:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            34.441639,
            -119.82419
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.91,
                    "duration": "indefinitely",
                    "model_conf": 153.68
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.34,
                    "duration": "indefinitely",
                    "model_conf": 185.37
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.24,
                    "duration": "indefinitely",
                    "model_conf": 111.89
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Santa Barbara -Goleta, CA",
            "slug": "d109ae75-d1ac-4b03-b0da-67da5d768e3b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/9/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/goleta/5700-calle-real/",
        "index": 103.375,
        "franchise-entity-name": "CENTRAL COAST FITNESS SOLUTIONS LLC"
    },
    "34fc8738-eabe-41dd-98ee-8a27f3696bc1": {
        "name": "Orange Theory Gonzales, LA",
        "address": "577 Hwy 30, Bldg L, Ste E Gonzales, Louisiana 70737",
        "phone": "(225) 238-3033",
        "email": "studiomanager1515@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:45AM - 10:45AM",
            "SUNDAY": "8:45AM - 10:45AM"
        },
        "geolocation": [
            30.210803,
            -90.916304
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.89,
                    "duration": "indefinitely",
                    "model_conf": 153.66
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.47,
                    "duration": "indefinitely",
                    "model_conf": 185.21
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.91,
                    "duration": "indefinitely",
                    "model_conf": 111.87
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Gonzales, LA",
            "slug": "34fc8738-eabe-41dd-98ee-8a27f3696bc1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/13/21",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/gonzales/577-hwy-30-bldg-l-ste-e/",
        "index": 93.4,
        "franchise-entity-name": "GREEN TIGERS3 LLC"
    },
    "77884aa6-a584-4069-ae00-eba5b68f59d7": {
        "name": "Orange Theory Litchfield, AZ",
        "address": "14175 W Indian School Rd., Suite A07 Goodyear, Arizona 85395",
        "phone": "(623) 977-0025",
        "email": "studiomanager1100@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            33.484066,
            -112.36344
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.76,
                    "duration": "1 Months",
                    "model_conf": 152.31
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.22,
                    "duration": "indefinitely",
                    "model_conf": 182.73
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.82,
                    "duration": "indefinitely",
                    "model_conf": 110.62
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Litchfield, AZ",
            "slug": "77884aa6-a584-4069-ae00-eba5b68f59d7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/10/18",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/goodyear/14175-w-indian-school-rd-suite-a07/",
        "index": 103.375,
        "franchise-entity-name": "Litchfield Fitness Partners, LLC"
    },
    "9add6024-948a-42d1-833c-958a2f4c6a9e": {
        "name": "Orange Theory Goodyear, AZ",
        "address": "783 Cotton Lane, Suite C-5, Goodyear, Arizona 85338",
        "phone": "(623) 935-2222",
        "email": "studiomanager0213@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:45AM - 11:15PM",
            "SUNDAY": "7:45AM - 11:15PM"
        },
        "geolocation": [
            33.4394379,
            -112.42388
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 2.22,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.39,
                    "duration": "2 Months",
                    "model_conf": 153.16
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 3.22,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.71,
                    "duration": "indefinitely",
                    "model_conf": 184.64
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 4.72,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.56,
                    "duration": "indefinitely",
                    "model_conf": 111.41
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 6.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 9.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 12.75,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Goodyear, AZ",
            "slug": "9add6024-948a-42d1-833c-958a2f4c6a9e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/18/17",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/goodyear/783-cotton-lane-suite-c-5/",
        "index": 103.375,
        "franchise-entity-name": "FITNESS BY KAFORA AT LITCHFIELD PARK LLC"
    },
    "ff68d4b8-c93f-4eb3-954b-bfd4e4a06298": {
        "name": "Orange Theory Grand Blanc, MI",
        "address": "12821 S. Saginaw St., Suite D-3 Grand Blanc, Michigan 48439",
        "phone": "(810) 344-4143",
        "email": "studiomanager1170@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:45PM",
            "TUESDAY": "4:45AM - 7:45PM",
            "WEDNESDAY": "4:45AM - 7:45PM",
            "THURSDAY": "4:45AM - 7:45PM",
            "FRIDAY": "4:45AM - 7:45PM",
            "SATURDAY": "6:45AM - 12:00PM",
            "SUNDAY": "6:45AM - 12:00PM"
        },
        "geolocation": [
            42.9188728,
            -83.617706
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.03,
                    "duration": "indefinitely",
                    "model_conf": 153.8
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.34,
                    "duration": "indefinitely",
                    "model_conf": 185.73
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.11,
                    "duration": "indefinitely",
                    "model_conf": 111.85
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Grand Blanc, MI",
            "slug": "ff68d4b8-c93f-4eb3-954b-bfd4e4a06298"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/20/19",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/grand-blanc/12821-s-saginaw-st-suite-d-3/",
        "index": 93.4,
        "franchise-entity-name": "L and S 2018, LLC"
    },
    "0a9108e5-ed16-4b95-b5db-4849a6e08632": {
        "name": "Orange Theory Grand Forks, ND",
        "address": "2800 S Columbia Road #342 Grand Forks, North Dakota 58201",
        "phone": "(701) 248-0043",
        "email": "studiomanager1065@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            47.8929863,
            -97.071152
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.64
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Grand Forks, ND",
            "slug": "0a9108e5-ed16-4b95-b5db-4849a6e08632"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/4/18",
        "url": "https://www.orangetheory.com/en-us/locations/north-dakota/grand-forks/2800-s-columbia-road-342/",
        "index": 93.4,
        "franchise-entity-name": "GRAND FORKS FITNESS, LLC"
    },
    "6a4bdd16-252a-4f8a-b714-cea7f055cf30": {
        "name": "Orange Theory North Grand Rapids-Knapp&#39;s Crossing, MI",
        "address": "2052 East Beltline Ave NE Grand Rapids, Michigan 49525",
        "phone": "(616) 818-7220",
        "email": "studiomanager1064@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:05PM",
            "TUESDAY": "5:00AM - 9:05PM",
            "WEDNESDAY": "5:00AM - 9:05PM",
            "THURSDAY": "5:00AM - 9:05PM",
            "FRIDAY": "5:00AM - 9:05PM",
            "SATURDAY": "6:05AM - 1:45PM",
            "SUNDAY": "6:05AM - 1:45PM"
        },
        "geolocation": [
            43.0012779,
            -85.590096
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 81.98,
                    "duration": "indefinitely",
                    "model_conf": 153.37
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 115.44,
                    "duration": "11 Months",
                    "model_conf": 183.66
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 181.12,
                    "duration": "indefinitely",
                    "model_conf": 109.66
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "North Grand Rapids-Knapp&#39;s Crossing, MI",
            "slug": "6a4bdd16-252a-4f8a-b714-cea7f055cf30"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/20/18",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/grand-rapids/2052-east-beltline-ave-ne/",
        "index": 98.425,
        "franchise-entity-name": "HEDGE FITNESS, LLC"
    },
    "b03e6d46-e5ac-4fe8-8c60-73aa23f2299f": {
        "name": "Orange Theory Granger, IN",
        "address": "7135 Heritage Square Drive Granger, Indiana 46530",
        "phone": "(574) 314-6300",
        "email": "studiomanager0629@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 8:00PM",
            "TUESDAY": "4:45AM - 8:00PM",
            "WEDNESDAY": "4:45AM - 8:00PM",
            "THURSDAY": "4:45AM - 8:00PM",
            "FRIDAY": "4:45AM - 8:00PM",
            "SATURDAY": "7:15AM - 1:00PM",
            "SUNDAY": "7:15AM - 1:00PM"
        },
        "geolocation": [
            41.7255402,
            -86.176353
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.8,
                    "duration": "indefinitely",
                    "model_conf": 152.95
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.21,
                    "duration": "2 Months",
                    "model_conf": 185.82
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.46,
                    "duration": "indefinitely",
                    "model_conf": 112.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Granger, IN",
            "slug": "b03e6d46-e5ac-4fe8-8c60-73aa23f2299f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/11/16",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/granger/7135-heritage-square-drive/",
        "index": 93.4,
        "franchise-entity-name": "ELLSWORTH FITNESS, LLC"
    },
    "1755cbfd-e344-4bd1-b419-6bd662be651a": {
        "name": "Orange Theory Great Falls, VA",
        "address": "1025 Seneca Rd Great Falls, Virginia 22066",
        "phone": "(703) 637-2365",
        "email": "studiomanager1565@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:45PM",
            "TUESDAY": "5:30AM - 7:45PM",
            "WEDNESDAY": "5:30AM - 7:45PM",
            "THURSDAY": "5:30AM - 7:45PM",
            "FRIDAY": "5:30AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            39.001535,
            -77.342997
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.83,
                    "duration": "indefinitely",
                    "model_conf": 140.27
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.35,
                    "duration": "indefinitely",
                    "model_conf": 168.39
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.77,
                    "duration": "indefinitely",
                    "model_conf": 102.74
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Great Falls, VA",
            "slug": "1755cbfd-e344-4bd1-b419-6bd662be651a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/5/23",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/great-falls/1025-seneca-rd/",
        "index": 98.425,
        "franchise-entity-name": "EMPIRE STUDIO 1565 LLC"
    },
    "7bca22da-64a2-46af-9c4d-10e00516de89": {
        "name": "Orange Theory Greeley, CO",
        "address": "4111 Centerplace Dr, Unit A Greeley, Colorado 80634",
        "phone": "(970) 400-1579",
        "email": "studiomanager1504@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            40.395272,
            -104.74713
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.56,
                    "duration": "indefinitely",
                    "model_conf": 150.25
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.4,
                    "duration": "2 Months",
                    "model_conf": 185.65
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.12,
                    "duration": "indefinitely",
                    "model_conf": 111.85
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Greeley, CO",
            "slug": "7bca22da-64a2-46af-9c4d-10e00516de89"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/22/22",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/greeley/4111-centerplace-dr-unit-a/",
        "index": 93.4,
        "franchise-entity-name": "WSH GREELEY LLC"
    },
    "a91cca24-2527-4787-82d8-5db610cd6d60": {
        "name": "Orange Theory Greenbrae-Marin, CA",
        "address": "230 Bon Air Center Greenbrae, California 94904",
        "phone": "(415) 481-0876",
        "email": "studiomanager0392@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            37.9456978,
            -122.52444
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.75,
                    "duration": "indefinitely",
                    "model_conf": 152.75
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.67,
                    "duration": "indefinitely",
                    "model_conf": 185.12
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.57,
                    "duration": "indefinitely",
                    "model_conf": 111.49
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Greenbrae-Marin, CA",
            "slug": "a91cca24-2527-4787-82d8-5db610cd6d60"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/greenbrae/230-bon-air-center/",
        "index": 118.225,
        "franchise-entity-name": "FITROCK, LLC"
    },
    "4cf69b1c-88d4-41f9-900d-1e079cc55d28": {
        "name": "Orange Theory Greensboro, NC",
        "address": "1410 Westover Terrace, Suite 100 B Greensboro, North Carolina 27408",
        "phone": "(336) 346-2977",
        "email": "studiomanager0654@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            36.0912132,
            -79.813988
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.19,
                    "duration": "3 Months",
                    "model_conf": 150.46
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.16,
                    "duration": "indefinitely",
                    "model_conf": 182.52
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.31,
                    "duration": "1 Months",
                    "model_conf": 111.87
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Greensboro, NC",
            "slug": "4cf69b1c-88d4-41f9-900d-1e079cc55d28"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/10/16",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/greensboro/1410-westover-terrace-suite-100-b/",
        "index": 98.425,
        "franchise-entity-name": "NC01-GREENSBORO 1, LLC"
    },
    "c2c53aba-fc78-4499-bb90-47bc3cd060b5": {
        "name": "Orange Theory Jamestown, NC",
        "address": "5710 Gate City Boulevard Suites Suite T Greensboro, North Carolina 27407",
        "phone": "(336) 663-1056",
        "email": "studiomanager0655@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:45PM",
            "TUESDAY": "6:00AM - 7:45PM",
            "WEDNESDAY": "6:00AM - 7:45PM",
            "THURSDAY": "6:00AM - 7:45PM",
            "FRIDAY": "6:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 11:15AM",
            "SUNDAY": "8:00AM - 11:15AM"
        },
        "geolocation": [
            36.0170021,
            -79.897102
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.74,
                    "duration": "1 Months",
                    "model_conf": 151.48
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.42,
                    "duration": "indefinitely",
                    "model_conf": 184.12
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.67,
                    "duration": "indefinitely",
                    "model_conf": 111.88
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Jamestown, NC",
            "slug": "c2c53aba-fc78-4499-bb90-47bc3cd060b5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/greensboro/5710-gate-city-boulevard-suites-suite-t/",
        "index": 98.425,
        "franchise-entity-name": "NC01-JAMESTOWN, LLC"
    },
    "7242135a-156a-4882-b0b8-7f83d8ed79c2": {
        "name": "Orange Theory Greenvale,NY",
        "address": "61 Glen Cove Rd Greenvale, New York 11548",
        "phone": "(516) 535-0900",
        "email": "studiomanager1160@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            40.8128243,
            -73.627838
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.44,
                    "duration": "6 Months",
                    "model_conf": 135.32
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 141.15,
                    "duration": "indefinitely",
                    "model_conf": 163.1
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.08,
                    "duration": "1 Months",
                    "model_conf": 99.3
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Greenvale,NY",
            "slug": "7242135a-156a-4882-b0b8-7f83d8ed79c2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/22/21",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/greenvale/61-glen-cove-rd/",
        "index": 108.325,
        "franchise-entity-name": "Greenvale Fitness LLC"
    },
    "2118a86b-cfcd-4798-a1c6-013cdb7decf1": {
        "name": "Orange Theory Greenville",
        "address": "518 Greenville Blvd SE Suite D Greenville, North Carolina 27858",
        "phone": "(252) 689-8428",
        "email": "studiomanager1184@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            35.5826225,
            -77.373177
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.09,
                    "duration": "indefinitely",
                    "model_conf": 149.27
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.88,
                    "duration": "indefinitely",
                    "model_conf": 184.6
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.13,
                    "duration": "indefinitely",
                    "model_conf": 111.78
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Greenville",
            "slug": "2118a86b-cfcd-4798-a1c6-013cdb7decf1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/greenville/518-greenville-blvd-se-suite-d/",
        "index": 98.425,
        "franchise-entity-name": "JACKELLE FITNESS SIX LLC"
    },
    "a64c0b92-596f-4611-aab3-03d0222007d5": {
        "name": "Orange Theory Greenwood, IN",
        "address": "1675 W. Smith Valley Road Suite D5 Greenwood, Indiana 46142",
        "phone": "(317) 247-0000",
        "email": "studiomanager0787@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            39.6049919,
            -86.157059
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.26,
                    "duration": "4 Months",
                    "model_conf": 137.59
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.73,
                    "duration": "indefinitely",
                    "model_conf": 129.49
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.98,
                    "duration": "2 Months",
                    "model_conf": 83.85
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Greenwood, IN",
            "slug": "a64c0b92-596f-4611-aab3-03d0222007d5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/greenwood/1675-w-smith-valley-road-suite-d5/",
        "index": 93.4,
        "franchise-entity-name": "COLOR ME FIT ENTERPRISES, LLC"
    },
    "61c46480-9d43-468a-9a35-be61ca806043": {
        "name": "Orange Theory Greer",
        "address": "870 E. Suber Rd, Suite 120 Greer, South Carolina 29650",
        "phone": "(864) 469-5199",
        "email": "studiomanager1257@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.9011612,
            -82.255783
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.6,
                    "duration": "2 Months",
                    "model_conf": 153.15
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.43,
                    "duration": "indefinitely",
                    "model_conf": 179.5
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.0,
                    "duration": "indefinitely",
                    "model_conf": 108.01
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Greer",
            "slug": "61c46480-9d43-468a-9a35-be61ca806043"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/11/19",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/greer/870-e-suber-rd-suite-110/",
        "index": 93.4,
        "franchise-entity-name": "JM GREER LLC"
    },
    "fcc7d1bf-e389-4c7b-ae74-efbaacef8349": {
        "name": "Orange Theory Gresham, OR",
        "address": "697 NW Division Street Gresham, Oregon 97030",
        "phone": "(503) 489-4334",
        "email": "studiomanager1186@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            45.5060196,
            -122.43874
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.09,
                    "duration": "indefinitely",
                    "model_conf": 153.55
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.04,
                    "duration": "indefinitely",
                    "model_conf": 183.39
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.18,
                    "duration": "indefinitely",
                    "model_conf": 111.56
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Gresham, OR",
            "slug": "fcc7d1bf-e389-4c7b-ae74-efbaacef8349"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/6/19",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/gresham/697-nw-division-street/",
        "index": 98.425,
        "franchise-entity-name": "JM GRESHAM, LLC"
    },
    "1d5206e7-6643-4e9b-b488-655814127b6e": {
        "name": "Orange Theory Grosse Pointe Woods",
        "address": "20853 Mack Avenue Grosse Pointe Woods, Michigan 48236",
        "phone": "(313) 960-4445",
        "email": "studiomanager0905@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 9:05PM",
            "TUESDAY": "4:55AM - 9:05PM",
            "WEDNESDAY": "4:55AM - 9:05PM",
            "THURSDAY": "4:55AM - 9:05PM",
            "FRIDAY": "4:55AM - 9:05PM",
            "SATURDAY": "5:15AM - 2:15PM",
            "SUNDAY": "5:15AM - 2:15PM"
        },
        "geolocation": [
            42.4462204,
            -82.906868
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.27,
                    "duration": "indefinitely",
                    "model_conf": 153.38
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.76,
                    "duration": "indefinitely",
                    "model_conf": 184.95
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.17,
                    "duration": "indefinitely",
                    "model_conf": 111.84
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Grosse Pointe Woods",
            "slug": "1d5206e7-6643-4e9b-b488-655814127b6e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/21/19",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/grosse-pointe-woods/20853-mack-avenue/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS MICHIGAN, LLC-0905"
    },
    "b4040e91-02c8-4ede-b7d2-03ce63a01f80": {
        "name": "Orange Theory Grove City, OH",
        "address": "2718 London Groveport Rd. Grove City, Ohio 43123",
        "phone": "(614) 852-4700",
        "email": "studiomanager0752@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            39.83938,
            -83.07801
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.77,
                    "duration": "indefinitely",
                    "model_conf": 150.49
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.8,
                    "duration": "4 Months",
                    "model_conf": 181.29
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.99,
                    "duration": "indefinitely",
                    "model_conf": 108.8
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Grove City, OH",
            "slug": "b4040e91-02c8-4ede-b7d2-03ce63a01f80"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/24/17",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/grove-city/2718-london-groveport-rd/",
        "index": 93.4,
        "franchise-entity-name": "BENNET GROUP FITNESS, LLC"
    },
    "b529ade7-ea7a-49bd-83ef-ef1b0ba0229a": {
        "name": "Orange Theory Guilford, CT",
        "address": "1057-1069 Boston Post Rd Guilford, Connecticut 06437",
        "phone": "(203) 295-7030",
        "email": "studiomanager1070@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            41.2882309,
            -72.684921
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.15,
                    "duration": "indefinitely",
                    "model_conf": 152.92
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.77,
                    "duration": "indefinitely",
                    "model_conf": 185.61
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.95,
                    "duration": "indefinitely",
                    "model_conf": 111.99
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Guilford, CT",
            "slug": "b529ade7-ea7a-49bd-83ef-ef1b0ba0229a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/guilford/1057-1069-boston-post-rd/",
        "index": 98.425,
        "franchise-entity-name": "CT SHORELINE FITNESS ENTERPRISE, INC."
    },
    "d241f26a-b062-4c0c-9cfd-1f70835cbee1": {
        "name": "Orange Theory West Highlands Ranch, CO",
        "address": "1493 Park Central Dr. Highlands Ranch, Colorado 80129",
        "phone": "(720) 996-0461",
        "email": "studiomanager0250@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:30PM",
            "TUESDAY": "5:15AM - 7:30PM",
            "WEDNESDAY": "5:15AM - 7:30PM",
            "THURSDAY": "5:15AM - 7:30PM",
            "FRIDAY": "5:15AM - 7:30PM",
            "SATURDAY": "6:30AM - 11:15AM",
            "SUNDAY": "6:30AM - 11:15AM"
        },
        "geolocation": [
            39.5534515,
            -105.00533
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.81,
                    "duration": "indefinitely",
                    "model_conf": 135.84
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.8,
                    "duration": "3 Months",
                    "model_conf": 164.8
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.52,
                    "duration": "indefinitely",
                    "model_conf": 99.15
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West Highlands Ranch, CO",
            "slug": "d241f26a-b062-4c0c-9cfd-1f70835cbee1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/17",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/highlands-ranch/1493-park-central-dr/",
        "index": 98.425,
        "franchise-entity-name": "WSH WEST HIGHLANDS RANCH, LLC"
    },
    "9c6b0081-6cc7-4d69-8675-a068498cca7f": {
        "name": "Orange Theory Kakaako, HI",
        "address": "660 Ala Moana Blvd. Ste. 217 Honolulu, Hawaii 96813",
        "phone": "(808) 762-2004",
        "email": "studiomanager0423@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            21.2979832,
            -157.86163
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.66,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.74,
                    "duration": "indefinitely",
                    "model_conf": 152.93
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.55,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.26,
                    "duration": "2 Months",
                    "model_conf": 185.79
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 9.38,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.69,
                    "duration": "indefinitely",
                    "model_conf": 111.12
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 11.78,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 21.2,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 28.22,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Kakaako, HI",
            "slug": "9c6b0081-6cc7-4d69-8675-a068498cca7f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/10/18",
        "url": "https://www.orangetheory.com/en-us/locations/hawaii/honolulu/660-ala-moana-blvd-ste-217/",
        "index": 108.325,
        "franchise-entity-name": "HELLOBERRY HAWAII, LLC"
    },
    "abc4ea15-2383-4023-8693-fb6627d2b407": {
        "name": "Orange Theory Hackensack, NJ",
        "address": "450 Hackensack Ave Hackensack, New Jersey 07601",
        "phone": "(201) 450-9663",
        "email": "studiomanager0261@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:30PM",
            "TUESDAY": "5:00AM - 9:30PM",
            "WEDNESDAY": "5:00AM - 9:30PM",
            "THURSDAY": "5:00AM - 9:30PM",
            "FRIDAY": "5:00AM - 9:30PM",
            "SATURDAY": "6:00AM - 1:25PM",
            "SUNDAY": "6:00AM - 1:25PM"
        },
        "geolocation": [
            40.909668,
            -74.03051
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.68,
                    "duration": "indefinitely",
                    "model_conf": 141.66
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.04,
                    "duration": "2 Months",
                    "model_conf": 168.74
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.3,
                    "duration": "indefinitely",
                    "model_conf": 107.35
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Hackensack, NJ",
            "slug": "abc4ea15-2383-4023-8693-fb6627d2b407"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/23/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/hackensack/450-hackensack-ave/",
        "index": 103.375,
        "franchise-entity-name": "EPOC EB, LLC"
    },
    "58ca5660-edcb-4c82-ab09-064248eb762e": {
        "name": "Orange Theory Hadley, MA",
        "address": "7 South Maple St, Suite B Hadley, Massachusetts 01035",
        "phone": "(413) 642-2300",
        "email": "studiomanager1126@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:05AM - 7:40PM",
            "TUESDAY": "6:05AM - 7:40PM",
            "WEDNESDAY": "6:05AM - 7:40PM",
            "THURSDAY": "6:05AM - 7:40PM",
            "FRIDAY": "6:05AM - 7:40PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            42.3556671,
            -72.551598
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.98,
                    "duration": "indefinitely",
                    "model_conf": 153.37
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.01,
                    "duration": "indefinitely",
                    "model_conf": 185.77
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.99,
                    "duration": "indefinitely",
                    "model_conf": 112.1
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Hadley, MA",
            "slug": "58ca5660-edcb-4c82-ab09-064248eb762e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/14/20",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/hadley/7-south-maple-st-suite-b/",
        "index": 98.425,
        "franchise-entity-name": "SILVERSTRAND FITNESS 2, LLC"
    },
    "89f58053-3e63-479e-9422-13673f4cf310": {
        "name": "Orange Theory Southtowns, NY",
        "address": "3701 McKinley Parkway Hamburg, New York 14219",
        "phone": "(716) 338-4630",
        "email": "studiomanager0259@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:45PM",
            "SUNDAY": "6:30AM - 12:45PM"
        },
        "geolocation": [
            42.7845421,
            -78.807472
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.37,
                    "duration": "indefinitely",
                    "model_conf": 151.31
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.54,
                    "duration": "4 Months",
                    "model_conf": 185.39
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.75,
                    "duration": "indefinitely",
                    "model_conf": 111.7
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Southtowns, NY",
            "slug": "89f58053-3e63-479e-9422-13673f4cf310"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/hamburg/3701-mckinley-parkway/",
        "index": 98.425,
        "franchise-entity-name": "OTF BUFFALO I, LLC"
    },
    "c8722d1b-4e36-4445-b891-ab6d2e683549": {
        "name": "Orange Theory Hamden- North Haven, CT",
        "address": "2335 Dixwell Ave Suite F-1 Hamden, Connecticut 06514",
        "phone": "(203) 764-2211",
        "email": "studiomanager0873@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.3723068,
            -72.915749
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.67,
                    "duration": "1 Months",
                    "model_conf": 152.51
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.2,
                    "duration": "indefinitely",
                    "model_conf": 185.04
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.74,
                    "duration": "indefinitely",
                    "model_conf": 111.52
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Hamden- North Haven, CT",
            "slug": "c8722d1b-4e36-4445-b891-ab6d2e683549"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/hamden/2335-dixwell-ave-suite-f-1/",
        "index": 98.425,
        "franchise-entity-name": "HAMDEN FIT LLC"
    },
    "03d04214-aeb4-4c28-847d-feaeedc0b0db": {
        "name": "Orange Theory Fairfield",
        "address": "3397 Princeton Rd #105 Hamilton, Ohio 45011",
        "phone": "(513) 334-0511",
        "email": "studiomanager1176@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:05PM",
            "TUESDAY": "5:00AM - 7:05PM",
            "WEDNESDAY": "5:00AM - 7:05PM",
            "THURSDAY": "5:00AM - 7:05PM",
            "FRIDAY": "5:00AM - 7:05PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            39.3901825,
            -84.503357
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.98,
                    "duration": "indefinitely",
                    "model_conf": 150.63
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.71,
                    "duration": "1 Months",
                    "model_conf": 184.9
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.76,
                    "duration": "indefinitely",
                    "model_conf": 108.59
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Fairfield",
            "slug": "03d04214-aeb4-4c28-847d-feaeedc0b0db"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/19/19",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/hamilton/3397-princeton-rd-105/",
        "index": 93.4,
        "franchise-entity-name": "BASSELNNATI FITNESS, LLC"
    },
    "e5f36602-7a3e-483b-ad50-e9f3bb5161c6": {
        "name": "Orange Theory Hanover, MA",
        "address": "2057 Washington Street, Suite 301 Hanover, Massachusetts 02339",
        "phone": "(781) 561-1606",
        "email": "studiomanager1199@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            42.1560288,
            -70.84906
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.91,
                    "duration": "indefinitely",
                    "model_conf": 150.9
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.44,
                    "duration": "1 Months",
                    "model_conf": 179.2
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.01,
                    "duration": "indefinitely",
                    "model_conf": 107.91
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Hanover, MA",
            "slug": "e5f36602-7a3e-483b-ad50-e9f3bb5161c6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/hanover/2057-washington-street-suite-301/",
        "index": 103.375,
        "franchise-entity-name": "Nahom Bravo, LLC"
    },
    "4542824d-0569-440d-904f-bf6ec7d46935": {
        "name": "Orange Theory Happy Valley, OR",
        "address": "16126 SE Happy Valley Town Center Dr #201 Happy Valley, Oregon 97086",
        "phone": "(503) 882-0087",
        "email": "studiomanager0510@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 1:15PM",
            "SUNDAY": "6:30AM - 1:15PM"
        },
        "geolocation": [
            45.4275322,
            -122.49805
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.34,
                    "duration": "3 Months",
                    "model_conf": 153.5
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.96,
                    "duration": "indefinitely",
                    "model_conf": 181.47
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.51,
                    "duration": "1 Months",
                    "model_conf": 111.37
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Happy Valley, OR",
            "slug": "4542824d-0569-440d-904f-bf6ec7d46935"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/19",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/happy-valley/16126-se-happy-valley-town-center-dr-201/",
        "index": 98.425,
        "franchise-entity-name": "FITZONE 2, LLC"
    },
    "b5e34349-3524-46d6-ac62-42d59e84c11c": {
        "name": "Orange Theory Harker Heights, TX",
        "address": "3045 Stillhouse Lake Road, Suite #108 Harker Heights, Texas 76548",
        "phone": "(254) 870-4700",
        "email": "studiomanager1193@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            31.0527706,
            -97.654442
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.18,
                    "duration": "indefinitely",
                    "model_conf": 153.81
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.39,
                    "duration": "indefinitely",
                    "model_conf": 185.84
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.24,
                    "duration": "indefinitely",
                    "model_conf": 111.93
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Harker Heights, TX",
            "slug": "b5e34349-3524-46d6-ac62-42d59e84c11c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/31/20",
        "url": "https://www.orangetheory.com/en-us/locations/texas/harker-heights/3045-stillhouse-lake-road-suite-108/",
        "index": 88.45,
        "franchise-entity-name": "KMM FITNESS VI LLC"
    },
    "90181582-b80c-401f-9509-c38c31c2e7a9": {
        "name": "Orange Theory Harlingen, TX",
        "address": "6710 W Expressway 83, Suite 104 Harlingen, Texas 78552",
        "phone": "(956) 752-3470",
        "email": "studiomanager1216@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:15PM",
            "TUESDAY": "5:00AM - 9:15PM",
            "WEDNESDAY": "5:00AM - 9:15PM",
            "THURSDAY": "5:00AM - 9:15PM",
            "FRIDAY": "5:00AM - 9:15PM",
            "SATURDAY": "6:30AM - 12:45PM",
            "SUNDAY": "6:30AM - 12:45PM"
        },
        "geolocation": [
            26.188364,
            -97.756142
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.1,
                    "duration": "indefinitely",
                    "model_conf": 153.72
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.22,
                    "duration": "indefinitely",
                    "model_conf": 185.6
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.91
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Harlingen, TX",
            "slug": "90181582-b80c-401f-9509-c38c31c2e7a9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/harlingen/6710-w-expressway-83-suite-104/",
        "index": 93.4,
        "franchise-entity-name": "REGULUS FITNESS II, LLC"
    },
    "980da232-f236-408a-a80f-0e2cd61cbf80": {
        "name": "Orange Theory Harrisburg, NC",
        "address": "4075 Harris Square Drive Harrisburg, North Carolina 28075",
        "phone": "(704) 464-4242",
        "email": "studiomanager0954@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            35.3214226,
            -80.646889
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.25,
                    "duration": "indefinitely",
                    "model_conf": 151.65
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.95,
                    "duration": "indefinitely",
                    "model_conf": 184.52
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.09,
                    "duration": "indefinitely",
                    "model_conf": 111.43
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Harrisburg, NC",
            "slug": "980da232-f236-408a-a80f-0e2cd61cbf80"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/25/19",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/harrisburg/4075-harris-square-drive/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO EIGHT CLT FITNESS, INC."
    },
    "9114de78-d869-4647-a22e-f058f413a117": {
        "name": "Orange Theory Harrisburg, PA",
        "address": "4640 High Pointe Blvd Suite A7 Harrisburg, Pennsylvania 17111",
        "phone": "(717) 288-1320",
        "email": "studiomanager0610@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.2466507,
            -76.806244
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.37,
                    "duration": "3 Months",
                    "model_conf": 152.23
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.97,
                    "duration": "indefinitely",
                    "model_conf": 184.84
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.55,
                    "duration": "1 Months",
                    "model_conf": 110.46
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Harrisburg, PA",
            "slug": "9114de78-d869-4647-a22e-f058f413a117"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/23/17",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/harrisburg/4640-high-pointe-blvd-suite-a7/",
        "index": 93.4,
        "franchise-entity-name": "MAB MANAGEMENT, LLC"
    },
    "b2dc2f1f-b0ff-4561-9f5f-386d8713f38d": {
        "name": "Orange Theory Harrisonburg, VA",
        "address": "1005 Port Republic Rd. Harrisonburg, Virginia 22801",
        "phone": "(540) 324-2314",
        "email": "studiomanager0757@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            38.4209366,
            -78.870972
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.25,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.34,
                    "duration": "indefinitely",
                    "model_conf": 183.47
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.26,
                    "duration": "indefinitely",
                    "model_conf": 112.01
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Harrisonburg, VA",
            "slug": "b2dc2f1f-b0ff-4561-9f5f-386d8713f38d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/harrisonburg/1005-port-republic-rd/",
        "index": 88.45,
        "franchise-entity-name": "2MD FITNESS LLC"
    },
    "3a727d50-8eb5-4755-978d-c350f7b7a608": {
        "name": "Orange Theory Harwood Heights-Portage Park, IL",
        "address": "4747 N Harlem Ave Suite 1115-A Harwood Heights, Illinois 60706",
        "phone": "(708) 400-8648",
        "email": "studiomanager0685@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            41.9671326,
            -87.805748
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.96,
                    "duration": "indefinitely",
                    "model_conf": 145.42
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.12,
                    "duration": "2 Months",
                    "model_conf": 174.56
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.49,
                    "duration": "indefinitely",
                    "model_conf": 105.9
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Harwood Heights-Portage Park, IL",
            "slug": "3a727d50-8eb5-4755-978d-c350f7b7a608"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/24/20",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/harwood-heights/4747-n-harlem-ave-suite-1115-a/",
        "index": 103.375,
        "franchise-entity-name": "CHICAGOLAND FITNESS VII, LLC"
    },
    "cef3d369-da77-4011-b570-1bec25f9a530": {
        "name": "Orange Theory Hattiesburg, MS",
        "address": "109 South 31st Avenue, Suite 10 Hattiesburg, Mississippi 39401",
        "phone": "(601) 651-3100",
        "email": "studiomanager0995@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "8:00AM - 10:30AM",
            "SUNDAY": "8:00AM - 10:30AM"
        },
        "geolocation": [
            31.3237705,
            -89.33596
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.19,
                    "duration": "indefinitely",
                    "model_conf": 153.57
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.59,
                    "duration": "1 Months",
                    "model_conf": 184.77
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.3,
                    "duration": "indefinitely",
                    "model_conf": 111.96
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Hattiesburg, MS",
            "slug": "cef3d369-da77-4011-b570-1bec25f9a530"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/28/18",
        "url": "https://www.orangetheory.com/en-us/locations/mississippi/hattiesburg/109-south-31st-avenue-suite-10/",
        "index": 93.4,
        "franchise-entity-name": "TRI-POWER HUB CITY, LLC"
    },
    "76e672d3-a684-40d3-a017-ef2a00faf343": {
        "name": "Orange Theory Hayward, CA",
        "address": "28541 Hesperian Blvd Hayward, California 94545",
        "phone": "(510) 244-1355",
        "email": "studiomanager0702@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            37.6147804,
            -122.087
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.51,
                    "duration": "1 Months",
                    "model_conf": 152.17
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.17,
                    "duration": "indefinitely",
                    "model_conf": 182.81
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.4,
                    "duration": "1 Months",
                    "model_conf": 109.84
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Hayward, CA",
            "slug": "76e672d3-a684-40d3-a017-ef2a00faf343"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/hayward/28541-hesperian-blvd/",
        "index": 108.325,
        "franchise-entity-name": "TFP CALIFORNIA FITNESS PARTNERS II, LLC."
    },
    "56344b76-bbce-4d47-8bfa-4933575a29c4": {
        "name": "Orange Theory Las Vegas South, NV",
        "address": "10271 South Eastern Avenue 103-105 Henderson, Nevada 89052",
        "phone": "(702) 834-8300",
        "email": "studiomanager0142@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            36.0029984,
            -115.11058
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.87,
                    "duration": "indefinitely",
                    "model_conf": 149.1
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.02,
                    "duration": "3 Months",
                    "model_conf": 182.61
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.78,
                    "duration": "indefinitely",
                    "model_conf": 111.57
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Las Vegas South, NV",
            "slug": "56344b76-bbce-4d47-8bfa-4933575a29c4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/2/14",
        "url": "https://www.orangetheory.com/en-us/locations/nevada/henderson/10271-eastern-ave-103-105/",
        "index": 98.425,
        "franchise-entity-name": "OTLV1, LLC"
    },
    "70e729c1-9cba-4bab-b107-a76b9d8a94ed": {
        "name": "Orange Theory Las Vegas East, NV",
        "address": "691 Marks Street, Suite-D Henderson, Nevada 89014",
        "phone": "(702) 850-2511",
        "email": "studiomanager0223@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            36.0669174,
            -115.03667
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.29,
                    "duration": "indefinitely",
                    "model_conf": 150.17
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.52,
                    "duration": "1 Months",
                    "model_conf": 183.34
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.24,
                    "duration": "indefinitely",
                    "model_conf": 111.58
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Las Vegas East, NV",
            "slug": "70e729c1-9cba-4bab-b107-a76b9d8a94ed"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/28/15",
        "url": "https://www.orangetheory.com/en-us/locations/nevada/henderson/691-marks-street-suite-d/",
        "index": 98.425,
        "franchise-entity-name": "LVE FITNESS LLC"
    },
    "af88a714-db3e-462a-9155-d7941d5afbe0": {
        "name": "Orange Theory Hendersonville, TN",
        "address": "281 Indian Lake Blvd., Suite 200 Hendersonville, Tennessee 37075",
        "phone": "(615) 815-5970",
        "email": "studiomanager0366@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:15AM - 12:30PM",
            "SUNDAY": "7:15AM - 12:30PM"
        },
        "geolocation": [
            36.3284493,
            -86.594536
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.23,
                    "duration": "indefinitely",
                    "model_conf": 153.69
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.26,
                    "duration": "indefinitely",
                    "model_conf": 185.73
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.85,
                    "duration": "indefinitely",
                    "model_conf": 111.65
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Hendersonville, TN",
            "slug": "af88a714-db3e-462a-9155-d7941d5afbe0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/27/15",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/hendersonville/281-indian-lake-blvd-suite-200/",
        "index": 93.4,
        "franchise-entity-name": "TYC, LLC"
    },
    "4f3ad6a4-b18a-4ec5-876b-1253504be3d5": {
        "name": "Orange Theory Short Pump, VA",
        "address": "11819 West Broad St. Henrico, Virginia 23233",
        "phone": "(804) 823-7530",
        "email": "studiomanager0419@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:35PM",
            "TUESDAY": "5:00AM - 7:35PM",
            "WEDNESDAY": "5:00AM - 7:35PM",
            "THURSDAY": "5:00AM - 7:35PM",
            "FRIDAY": "5:00AM - 7:35PM",
            "SATURDAY": "7:00AM - 11:15AM",
            "SUNDAY": "7:00AM - 11:15AM"
        },
        "geolocation": [
            37.6572762,
            -77.630272
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.62,
                    "duration": "indefinitely",
                    "model_conf": 117.24
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.7,
                    "duration": "indefinitely",
                    "model_conf": 141.91
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.54,
                    "duration": "indefinitely",
                    "model_conf": 86.72
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Short Pump, VA",
            "slug": "4f3ad6a4-b18a-4ec5-876b-1253504be3d5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/9/15",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/henrico/11819-west-broad-st/",
        "index": 98.425,
        "franchise-entity-name": "S. VA FITNESS R1 LLC"
    },
    "23b5fa60-f6a3-415f-a65e-0cb5c076da79": {
        "name": "Orange Theory Richmond- Glenside, VA",
        "address": "6984 Forest Avenue Henrico, Virginia 23230",
        "phone": "(804) 655-0722",
        "email": "studiomanager0461@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:50AM - 8:35PM",
            "TUESDAY": "4:50AM - 8:35PM",
            "WEDNESDAY": "4:50AM - 8:35PM",
            "THURSDAY": "4:50AM - 8:35PM",
            "FRIDAY": "4:50AM - 8:35PM",
            "SATURDAY": "7:00AM - 12:20PM",
            "SUNDAY": "7:00AM - 12:20PM"
        },
        "geolocation": [
            37.6028633,
            -77.52581
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.29,
                    "duration": "indefinitely",
                    "model_conf": 131.53
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.94,
                    "duration": "indefinitely",
                    "model_conf": 159.12
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.23,
                    "duration": "indefinitely",
                    "model_conf": 96.34
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Richmond- Glenside, VA",
            "slug": "23b5fa60-f6a3-415f-a65e-0cb5c076da79"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/19/18",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/henrico/6984-forest-avenue/",
        "index": 98.425,
        "franchise-entity-name": "S. VA FITNESS R2, LLC"
    },
    "54315421-62f1-48f7-8a3f-dfd8a965db99": {
        "name": "Orange Theory Hewlett, NY",
        "address": "1312 Broadway Hewlett, New York 11557",
        "phone": "(516) 307-4777",
        "email": "studiomanager0329@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.6396332,
            -73.700546
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 96.11,
                    "duration": "11 Months",
                    "model_conf": 146.54
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 142.08,
                    "duration": "indefinitely",
                    "model_conf": 176.55
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 197.7,
                    "duration": "2 Months",
                    "model_conf": 106.67
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Hewlett, NY",
            "slug": "54315421-62f1-48f7-8a3f-dfd8a965db99"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/11/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/hewlett/1312-broadway/",
        "index": 108.325,
        "franchise-entity-name": "HWLT FITNESS LLC"
    },
    "cbaf2bb1-e80c-4764-9205-f6928fd1eed8": {
        "name": "Orange Theory Highland Park, IL",
        "address": "1835 Second Street Highland Park, Illinois 60035",
        "phone": "(847) 631-1122",
        "email": "studiomanager0687@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 6:30PM",
            "TUESDAY": "6:00AM - 6:30PM",
            "WEDNESDAY": "6:00AM - 6:30PM",
            "THURSDAY": "6:00AM - 6:30PM",
            "FRIDAY": "6:00AM - 6:30PM",
            "SATURDAY": "7:15AM - 10:45AM",
            "SUNDAY": "7:15AM - 10:45AM"
        },
        "geolocation": [
            42.1858292,
            -87.800964
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.75,
                    "duration": "indefinitely",
                    "model_conf": 148.92
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.66,
                    "duration": "4 Months",
                    "model_conf": 178.79
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.39,
                    "duration": "indefinitely",
                    "model_conf": 108.63
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Highland Park, IL",
            "slug": "cbaf2bb1-e80c-4764-9205-f6928fd1eed8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/17/18",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/highland-park/1835-second-street/",
        "index": 103.375,
        "franchise-entity-name": "CHICAGOLAND FITNESS IX, LLC"
    },
    "4e248ae0-edb6-4189-b7fd-b76603934092": {
        "name": "Orange Theory Highlands Ranch, CO",
        "address": "9559 S. University Boulevard, Units 101-102 Highlands Ranch, Colorado 80126",
        "phone": "(303) 683-3312",
        "email": "studiomanager0009@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            39.5422325,
            -104.94595
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.09,
                    "duration": "indefinitely",
                    "model_conf": 124.32
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.86,
                    "duration": "indefinitely",
                    "model_conf": 152.77
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.02,
                    "duration": "indefinitely",
                    "model_conf": 90.51
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Highlands Ranch, CO",
            "slug": "4e248ae0-edb6-4189-b7fd-b76603934092"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/9/12",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/highlands-ranch/9559-s-university-boulevard-units-101-102/",
        "index": 98.425,
        "franchise-entity-name": "WSH HIGHLANDS RANCH, LLC"
    },
    "c717c8e9-f5eb-40fa-8f32-61b6a7e16d0e": {
        "name": "Orange Theory Hilliard, OH",
        "address": "3959 Britton Parkway Hilliard, Ohio 43026",
        "phone": "(614) 721-7444",
        "email": "studiomanager0380@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.0337029,
            -83.131538
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.0,
                    "duration": "5 Months",
                    "model_conf": 140.53
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.96,
                    "duration": "indefinitely",
                    "model_conf": 169.81
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.45,
                    "duration": "1 Months",
                    "model_conf": 102.37
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Hilliard, OH",
            "slug": "c717c8e9-f5eb-40fa-8f32-61b6a7e16d0e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/10/15",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/hilliard/3959-britton-parkway/",
        "index": 93.4,
        "franchise-entity-name": "YOUNG FITNESS 1, LLC"
    },
    "81088f5d-c472-499f-9bd3-7fdcdf238971": {
        "name": "Orange Theory Hillsboro, OR",
        "address": "965 NE Orenco Station Parkway Hillsboro, Oregon 97124",
        "phone": "(503) 828-3323",
        "email": "studiomanager0509@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 12:15PM",
            "SUNDAY": "6:30AM - 12:15PM"
        },
        "geolocation": [
            45.5313835,
            -122.91681
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.5,
                    "duration": "7 Months",
                    "model_conf": 153.23
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.61,
                    "duration": "indefinitely",
                    "model_conf": 183.02
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.08,
                    "duration": "1 Months",
                    "model_conf": 111.23
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Hillsboro, OR",
            "slug": "81088f5d-c472-499f-9bd3-7fdcdf238971"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/17/16",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/hillsboro/965-ne-orenco-station-parkway/",
        "index": 98.425,
        "franchise-entity-name": "FITZONE 1 LLC"
    },
    "aba9efa4-3011-4977-b481-e2a2cf94a4f5": {
        "name": "Orange Theory Hilton Head, SC",
        "address": "430 William Hilton Parkway Hilton Head, South Carolina 29926",
        "phone": "(843) 473-4505",
        "email": "studiomanager0935@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            32.2147293,
            -80.704971
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.87,
                    "duration": "indefinitely",
                    "model_conf": 153.4
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.09,
                    "duration": "indefinitely",
                    "model_conf": 185.83
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.93,
                    "duration": "indefinitely",
                    "model_conf": 112.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Hilton Head, SC",
            "slug": "aba9efa4-3011-4977-b481-e2a2cf94a4f5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/hilton-head/430-william-hilton-parkway/",
        "index": 93.4,
        "franchise-entity-name": "KELLY O, LLC"
    },
    "a7b12e71-e4db-47c7-9ffa-3ff48b155025": {
        "name": "Orange Theory Hingham, MA",
        "address": "15 Shipyard Dr., Suite 1B Hingham, Massachusetts 02043",
        "phone": "(781) 328-1800",
        "email": "studiomanager0632@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 4:45PM",
            "SUNDAY": "6:30AM - 4:45PM"
        },
        "geolocation": [
            42.2514267,
            -70.92263
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.07,
                    "duration": "indefinitely",
                    "model_conf": 151.48
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.27,
                    "duration": "indefinitely",
                    "model_conf": 183.62
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.3,
                    "duration": "indefinitely",
                    "model_conf": 110.74
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Hingham, MA",
            "slug": "a7b12e71-e4db-47c7-9ffa-3ff48b155025"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/24/16",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/hingham/15-shipyard-dr-suite-1b/",
        "index": 103.375,
        "franchise-entity-name": "IRISH CROSSINGS I LLC"
    },
    "0b73601f-a942-4f5a-ab94-88f032430a3b": {
        "name": "Orange Theory Hixson, TN",
        "address": "5207 Highway 153 Suite 113 Hixson, Tennessee 37343",
        "phone": "(423) 588-7270",
        "email": "studiomanager1279@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            35.1238174,
            -85.240791
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.45,
                    "duration": "indefinitely",
                    "model_conf": 153.71
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.3,
                    "duration": "2 Months",
                    "model_conf": 183.92
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.54,
                    "duration": "indefinitely",
                    "model_conf": 111.29
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Hixson, TN",
            "slug": "0b73601f-a942-4f5a-ab94-88f032430a3b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/6/19",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/hixson/5207-highway-153-suite-113/",
        "index": 93.4,
        "franchise-entity-name": "JM HIXSON, LLC"
    },
    "cae6ad82-e684-4395-a322-775f02bba940": {
        "name": "Orange Theory Palatine, Illinois",
        "address": "1481 West Palatine Rd. Hoffman Estates, Illinois 60192",
        "phone": "(847) 963-2133",
        "email": "studiomanager1228@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 6:45PM",
            "TUESDAY": "4:55AM - 6:45PM",
            "WEDNESDAY": "4:55AM - 6:45PM",
            "THURSDAY": "4:55AM - 6:45PM",
            "FRIDAY": "4:55AM - 6:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            42.1092873,
            -88.115616
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.76,
                    "duration": "indefinitely",
                    "model_conf": 143.05
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.17,
                    "duration": "5 Months",
                    "model_conf": 172.37
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.85,
                    "duration": "indefinitely",
                    "model_conf": 104.38
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Palatine, Illinois",
            "slug": "cae6ad82-e684-4395-a322-775f02bba940"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/19",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/hoffman-estates/1477-1479-palatine-road/",
        "index": 103.375,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-NORTH EAST, LLC"
    },
    "88b45448-d2ff-458e-83f5-fa13d07e7d41": {
        "name": "Orange Theory Hoffman Estates - S. Barrington, IL",
        "address": "2626 N Sutton Road Hoffman Estates, Illinois 60192",
        "phone": "(224) 422-2454",
        "email": "studiomanager0991@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            42.0718613,
            -88.189934
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.46,
                    "duration": "indefinitely",
                    "model_conf": 148.93
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.38,
                    "duration": "1 Months",
                    "model_conf": 180.45
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.63,
                    "duration": "indefinitely",
                    "model_conf": 108.05
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Hoffman Estates - S. Barrington, IL",
            "slug": "88b45448-d2ff-458e-83f5-fa13d07e7d41"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/1/18",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/hoffman-estates/2626-n-sutton-road/",
        "index": 103.375,
        "franchise-entity-name": "CHICAGOLAND FITNESS XIII LLC"
    },
    "a1790757-289f-413e-9d8b-402005061768": {
        "name": "Orange Theory Holladay, UT",
        "address": "4655 S. 2300 E. Suite 103 Holladay, Utah 84117",
        "phone": "(801) 610-1020",
        "email": "studiomanager1355@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            40.6694717,
            -111.82415
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.67,
                    "duration": "1 Months",
                    "model_conf": 136.33
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.02,
                    "duration": "indefinitely",
                    "model_conf": 178.2
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.37,
                    "duration": "indefinitely",
                    "model_conf": 100.04
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Holladay, UT",
            "slug": "a1790757-289f-413e-9d8b-402005061768"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/utah/holladay/4655-s-2300-e-suite-103/",
        "index": 93.4,
        "franchise-entity-name": "Heartzone Fitness X, LLC"
    },
    "9b3aab67-0ad2-44ca-a93c-e6fd5c1de194": {
        "name": "Orange Theory Holland, MI",
        "address": "12365 James Street, Suite 20 Holland, Michigan 49424",
        "phone": "(616) 377-4911",
        "email": "studiomanager1120@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 8:00PM",
            "TUESDAY": "4:45AM - 8:00PM",
            "WEDNESDAY": "4:45AM - 8:00PM",
            "THURSDAY": "4:45AM - 8:00PM",
            "FRIDAY": "4:45AM - 8:00PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            42.8154526,
            -86.08754
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.04,
                    "duration": "indefinitely",
                    "model_conf": 153.07
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.92,
                    "duration": "indefinitely",
                    "model_conf": 185.8
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.06,
                    "duration": "indefinitely",
                    "model_conf": 110.99
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Holland, MI",
            "slug": "9b3aab67-0ad2-44ca-a93c-e6fd5c1de194"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/holland/12365-james-street-suite-20/",
        "index": 98.425,
        "franchise-entity-name": "JPT Development LLC"
    },
    "36b825d3-dd35-4f1f-ac91-e2a7fd9a0c62": {
        "name": "Orange Theory Holly Springs, NC",
        "address": "141 Grand Hill Place Holly Springs, North Carolina 27540",
        "phone": "(919) 267-1683",
        "email": "studiomanager0358@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            35.6636887,
            -78.847374
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.69,
                    "duration": "11 Months",
                    "model_conf": 144.88
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 121.87,
                    "duration": "indefinitely",
                    "model_conf": 177.33
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.14,
                    "duration": "4 Months",
                    "model_conf": 108.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Holly Springs, NC",
            "slug": "36b825d3-dd35-4f1f-ac91-e2a7fd9a0c62"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/23/15",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/holly-springs/141-grand-hill-place/",
        "index": 98.425,
        "franchise-entity-name": "JOELE FITNESS SEVEN, LLC"
    },
    "253ee5b8-38e2-4b3a-bcfc-ba111042e7ab": {
        "name": "Orange Theory Hollywood, FL",
        "address": "3251 Hollywood Blvd Hollywood, Florida 33021",
        "phone": "(954) 591-8053",
        "email": "studiomanager0049@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            26.0139275,
            -80.173988
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.19,
                    "duration": "3 Months",
                    "model_conf": 148.41
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.59,
                    "duration": "indefinitely",
                    "model_conf": 183.51
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.06,
                    "duration": "2 Months",
                    "model_conf": 110.5
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Hollywood, FL",
            "slug": "253ee5b8-38e2-4b3a-bcfc-ba111042e7ab"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/21/13",
        "url": "https://www.orangetheory.com/en-us/locations/florida/hollywood/3251-hollywood-blvd/",
        "index": 98.425,
        "franchise-entity-name": "OTF PROPERTY HOLDINGS, LLC"
    },
    "eba7c1ae-6ecd-4f1f-9a5c-0fdc24fff083": {
        "name": "Orange Theory Homestead, FL",
        "address": "803 N. Homestead Blvd Homestead, Florida 33030",
        "phone": "(786) 632-7800",
        "email": "studiomanager0607@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            25.4779701,
            -80.464241
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.2,
                    "duration": "indefinitely",
                    "model_conf": 153.73
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.05,
                    "duration": "indefinitely",
                    "model_conf": 185.75
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.12,
                    "duration": "indefinitely",
                    "model_conf": 111.76
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Homestead, FL",
            "slug": "eba7c1ae-6ecd-4f1f-9a5c-0fdc24fff083"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/9/20",
        "url": "https://www.orangetheory.com/en-us/locations/florida/homestead/803-n-homestead-blvd/",
        "index": 93.4,
        "franchise-entity-name": "HOMESTEAD FITNESS, LLC."
    },
    "d2aaee6e-026e-4d9c-9cc1-8128a1397561": {
        "name": "Orange Theory Waikiki, HI",
        "address": "449 Kapahulu Avenue, Suite 101 Honolulu, Hawaii 96815",
        "phone": "(808) 445-9227",
        "email": "studiomanager0481@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            21.2749348,
            -157.81537
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.66,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.76,
                    "duration": "indefinitely",
                    "model_conf": 152.89
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.55,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.25,
                    "duration": "2 Months",
                    "model_conf": 185.55
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 9.38,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.73,
                    "duration": "indefinitely",
                    "model_conf": 110.51
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 11.78,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 21.2,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 28.22,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Waikiki, HI",
            "slug": "d2aaee6e-026e-4d9c-9cc1-8128a1397561"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/17/16",
        "url": "https://www.orangetheory.com/en-us/locations/hawaii/honolulu/449-kapahulu-avenue-suite-101/",
        "index": 108.325,
        "franchise-entity-name": "ALOHABERRY, LLC"
    },
    "9fca1074-3432-4d45-bb2d-77532ec8517c": {
        "name": "Orange Theory Hoover, AL",
        "address": "4441 Creekside Avenue Ste 141 Hoover, Alabama 35244",
        "phone": "(205) 623-0202",
        "email": "studiomanager0211@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:45AM - 11:30AM",
            "SUNDAY": "7:45AM - 11:30AM"
        },
        "geolocation": [
            33.373436,
            -86.81572
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.58,
                    "duration": "8 Months",
                    "model_conf": 149.11
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.69,
                    "duration": "indefinitely",
                    "model_conf": 185.14
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.74,
                    "duration": "2 Months",
                    "model_conf": 110.59
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Hoover, AL",
            "slug": "9fca1074-3432-4d45-bb2d-77532ec8517c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/2/15",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/hoover/4441-creekside-avenue-ste-141/",
        "index": 93.4,
        "franchise-entity-name": "NICHEFITNESS5, LLC"
    },
    "1ad5594b-775f-4750-bc8d-b4d0b820533a": {
        "name": "Orange Theory Energy Corridor-Houston, TX",
        "address": "1111 Eldridge Pkwy Houston, Texas 77077",
        "phone": "(832) 916-2745",
        "email": "studiomanager1316@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            29.7680855,
            -95.623009
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.69,
                    "duration": "1 Months",
                    "model_conf": 137.89
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.93,
                    "duration": "indefinitely",
                    "model_conf": 164.53
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.98,
                    "duration": "indefinitely",
                    "model_conf": 99.41
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Energy Corridor-Houston, TX",
            "slug": "1ad5594b-775f-4750-bc8d-b4d0b820533a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/1111-eldridge-pkwy/",
        "index": 98.425,
        "franchise-entity-name": "Energy Corridor Coaching LLC"
    },
    "fc31eede-55f2-4f7d-9ca6-0024bcfcd084": {
        "name": "Orange Theory River Oaks-Houston, TX",
        "address": "1428 W Gray St Houston, Texas 77019",
        "phone": "(281) 404-5295",
        "email": "studiomanager1318@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            29.753896,
            -95.399463
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.75,
                    "duration": "1 Months",
                    "model_conf": 126.45
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.06,
                    "duration": "indefinitely",
                    "model_conf": 151.5
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.61,
                    "duration": "indefinitely",
                    "model_conf": 91.51
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "River Oaks-Houston, TX",
            "slug": "fc31eede-55f2-4f7d-9ca6-0024bcfcd084"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/14/22",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/1428-w-gray-st/",
        "index": 98.425,
        "franchise-entity-name": "RIVER OAKS COACHING LLC"
    },
    "777af320-0d0f-4b8a-86f5-f1b41c30528b": {
        "name": "Orange Theory Vintage Park, TX",
        "address": "15556 Cutten Road, Suite 100 Houston, Texas 77070",
        "phone": "(281) 656-6645",
        "email": "studiomanager0350@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            29.9982891,
            -95.562439
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.55,
                    "duration": "2 Months",
                    "model_conf": 148.88
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.71,
                    "duration": "indefinitely",
                    "model_conf": 183.03
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.84,
                    "duration": "2 Months",
                    "model_conf": 111.13
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Vintage Park, TX",
            "slug": "777af320-0d0f-4b8a-86f5-f1b41c30528b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/30/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/15556-cutten-road-suite-100/",
        "index": 98.425,
        "franchise-entity-name": "RDK FITNESS I VINTAGE, LLC"
    },
    "aa1df03d-e542-4c71-abd9-c9a1e2d27cfa": {
        "name": "Orange Theory Atascocita, TX",
        "address": "16402 W. Lake Houston Parkway, Suite 200 Houston, Texas 77044",
        "phone": "(281) 810-7445",
        "email": "studiomanager1079@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 11:45PM",
            "SUNDAY": "6:30AM - 11:45PM"
        },
        "geolocation": [
            29.9626484,
            -95.167725
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.51,
                    "duration": "indefinitely",
                    "model_conf": 149.79
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.41,
                    "duration": "1 Months",
                    "model_conf": 170.3
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.22,
                    "duration": "indefinitely",
                    "model_conf": 107.34
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Atascocita, TX",
            "slug": "aa1df03d-e542-4c71-abd9-c9a1e2d27cfa"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/16402-w-lake-houston-parkway-suite-200/",
        "index": 98.425,
        "franchise-entity-name": "Atascocita Coaching LLC"
    },
    "a3f577ec-3fda-4883-a825-e2433da0ba22": {
        "name": "Orange Theory Sawyer Heights-Houston, TX",
        "address": "2315 Edwards St #135 Houston, Texas 77007",
        "phone": "(832) 916-2138",
        "email": "studiomanager0472@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 2:45PM",
            "SUNDAY": "7:00AM - 2:45PM"
        },
        "geolocation": [
            29.7706604,
            -95.381462
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.68,
                    "duration": "1 Months",
                    "model_conf": 136.9
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.15,
                    "duration": "indefinitely",
                    "model_conf": 164.41
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.59,
                    "duration": "indefinitely",
                    "model_conf": 99.49
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sawyer Heights-Houston, TX",
            "slug": "a3f577ec-3fda-4883-a825-e2433da0ba22"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/30/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/2315-edwards-st-135/",
        "index": 98.425,
        "franchise-entity-name": "SAWYER HEIGHTS COACHING LLC"
    },
    "45fc3d57-4d7e-4ce5-ae10-da0552989ece": {
        "name": "Orange Theory Highland Village-Houston, TX",
        "address": "2400 Mid Lane Rd. Houston, Texas 77027",
        "phone": "(832) 919-6452",
        "email": "studiomanager1314@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "8:15AM - 1:00PM",
            "SUNDAY": "8:15AM - 1:00PM"
        },
        "geolocation": [
            29.7425633,
            -95.450546
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.61,
                    "duration": "1 Months",
                    "model_conf": 112.69
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.33,
                    "duration": "indefinitely",
                    "model_conf": 136.48
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.41,
                    "duration": "1 Months",
                    "model_conf": 81.36
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Highland Village-Houston, TX",
            "slug": "45fc3d57-4d7e-4ce5-ae10-da0552989ece"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/15/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/2400-mid-lane-rd/",
        "index": 98.425,
        "franchise-entity-name": "MIDLANE COACHING LLC"
    },
    "ba5c8e7b-0055-4982-9ea4-1b36631da2cd": {
        "name": "Orange Theory West University-Houston, TX",
        "address": "2511 Bissonnet Street Houston, Texas 77005",
        "phone": "(832) 510-2866",
        "email": "studiomanager0450@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 2:15PM",
            "SUNDAY": "7:00AM - 2:15PM"
        },
        "geolocation": [
            29.7252369,
            -95.417389
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.86,
                    "duration": "indefinitely",
                    "model_conf": 122.12
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.06,
                    "duration": "indefinitely",
                    "model_conf": 146.18
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.66,
                    "duration": "indefinitely",
                    "model_conf": 88.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West University-Houston, TX",
            "slug": "ba5c8e7b-0055-4982-9ea4-1b36631da2cd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/28/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/2511-bissonnet-street/",
        "index": 98.425,
        "franchise-entity-name": "BISSONNET COACHING LLC"
    },
    "93529240-0ff0-4b85-9220-ea1bfd9a703b": {
        "name": "Orange Theory The Heights-Houston, TX",
        "address": "3004 Yale St. Suite 200 Houston, Texas 77018",
        "phone": "(832) 930-9941",
        "email": "studiomanager0981@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 2:00PM",
            "SUNDAY": "6:30AM - 2:00PM"
        },
        "geolocation": [
            29.814661,
            -95.397797
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.23,
                    "duration": "3 Months",
                    "model_conf": 143.09
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.88,
                    "duration": "indefinitely",
                    "model_conf": 172.33
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.39,
                    "duration": "1 Months",
                    "model_conf": 104.13
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "The Heights-Houston, TX",
            "slug": "93529240-0ff0-4b85-9220-ea1bfd9a703b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/28/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/3004-yale-st-suite-200/",
        "index": 98.425,
        "franchise-entity-name": "Heights Coaching LLC"
    },
    "2403bee6-d285-468a-87e3-52a7239dbf06": {
        "name": "Orange Theory Midtown-Houston, TX",
        "address": "3415 Louisiana St. Houston, Texas 77002",
        "phone": "(832) 699-6166",
        "email": "studiomanager0980@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            29.7413273,
            -95.380844
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.49,
                    "duration": "2 Months",
                    "model_conf": 132.72
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.43,
                    "duration": "indefinitely",
                    "model_conf": 158.93
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.4,
                    "duration": "1 Months",
                    "model_conf": 96.24
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Midtown-Houston, TX",
            "slug": "2403bee6-d285-468a-87e3-52a7239dbf06"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/3415-louisiana-st/",
        "index": 98.425,
        "franchise-entity-name": "MIDTOWN COACHING LLC"
    },
    "08715b4b-72d4-405b-8ff8-1141c26c8955": {
        "name": "Orange Theory Bellaire-Houston, TX",
        "address": "3905 Bellaire Rd Houston, Texas 77025",
        "phone": "(832) 404-2008",
        "email": "studiomanager0773@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 2:15PM",
            "SUNDAY": "6:30AM - 2:15PM"
        },
        "geolocation": [
            29.7053795,
            -95.439217
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.09,
                    "duration": "indefinitely",
                    "model_conf": 126.37
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.88,
                    "duration": "indefinitely",
                    "model_conf": 152.03
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.91,
                    "duration": "indefinitely",
                    "model_conf": 91.54
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Bellaire-Houston, TX",
            "slug": "08715b4b-72d4-405b-8ff8-1141c26c8955"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/29/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/3905-bellaire-rd/",
        "index": 98.425,
        "franchise-entity-name": "BELLAIRE COACHING LLC"
    },
    "67898f7a-3bb2-4b4c-99b4-f227fa6c5b54": {
        "name": "Orange Theory San Felipe-Houston, TX",
        "address": "6415 A San Felipe Road Houston, Texas 77057",
        "phone": "(713) 952-9917",
        "email": "studiomanager0109@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            29.749527,
            -95.498077
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.04,
                    "duration": "indefinitely",
                    "model_conf": 92.31
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.01,
                    "duration": "indefinitely",
                    "model_conf": 114.84
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.7,
                    "duration": "indefinitely",
                    "model_conf": 68.03
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "San Felipe-Houston, TX",
            "slug": "67898f7a-3bb2-4b4c-99b4-f227fa6c5b54"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/27/13",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/6415-a-san-felipe-road/",
        "index": 98.425,
        "franchise-entity-name": "OTF PARTNERS SAN FELIPE, LLC"
    },
    "fc02e97f-362f-4cbb-a9fa-c595a78c9f7b": {
        "name": "Orange Theory Medical Center-Houston, TX",
        "address": "7205 Fannin Street Houston, Texas 77030",
        "phone": "(281) 404-9294",
        "email": "studiomanager1319@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            29.698663,
            -95.401165
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.4,
                    "duration": "indefinitely",
                    "model_conf": 136.13
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.51,
                    "duration": "1 Months",
                    "model_conf": 163.29
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.12,
                    "duration": "indefinitely",
                    "model_conf": 98.68
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Medical Center-Houston, TX",
            "slug": "fc02e97f-362f-4cbb-a9fa-c595a78c9f7b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/23",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/7205-fannin-st/",
        "index": 98.425,
        "franchise-entity-name": "MED CENTER COACHING LLC"
    },
    "fca9c8a4-7c42-4b42-881e-cdfdc8cd2683": {
        "name": "Orange Theory Town and Country-Houston, TX",
        "address": "788 W. SAM HOUSTON PARKWAY N. STE 128 Houston, Texas 77024",
        "phone": "(281) 769-1064",
        "email": "studiomanager0297@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            29.777888,
            -95.562031
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.26,
                    "duration": "indefinitely",
                    "model_conf": 115.87
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.75,
                    "duration": "indefinitely",
                    "model_conf": 137.01
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.97,
                    "duration": "indefinitely",
                    "model_conf": 85.54
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Town and Country-Houston, TX",
            "slug": "fca9c8a4-7c42-4b42-881e-cdfdc8cd2683"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/17/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/houston/700-town-and-country-blvd-suite-2430/",
        "index": 98.425,
        "franchise-entity-name": "CAMBIO MANAGEMENT IV LLC"
    },
    "2a8c532d-8794-42fd-ba3a-247175fd985f": {
        "name": "Orange Theory Hudson, OH",
        "address": "5827 Darrow Rd Hudson, Ohio 44236",
        "phone": "(330) 595-4055",
        "email": "studiomanager0896@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:00PM",
            "TUESDAY": "5:30AM - 8:00PM",
            "WEDNESDAY": "5:30AM - 8:00PM",
            "THURSDAY": "5:30AM - 8:00PM",
            "FRIDAY": "5:30AM - 8:00PM",
            "SATURDAY": "6:45AM - 12:30PM",
            "SUNDAY": "6:45AM - 12:30PM"
        },
        "geolocation": [
            41.2237701,
            -81.439476
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.4,
                    "duration": "9 Months",
                    "model_conf": 152.98
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.72,
                    "duration": "indefinitely",
                    "model_conf": 185.39
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.5,
                    "duration": "3 Months",
                    "model_conf": 110.82
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Hudson, OH",
            "slug": "2a8c532d-8794-42fd-ba3a-247175fd985f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/9/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/hudson/5827-darrow-rd/",
        "index": 93.4,
        "franchise-entity-name": "PRESTIGE FITNESS HUDSON, LLC"
    },
    "b50fb8eb-539e-44d0-950e-4e8591ad8b92": {
        "name": "Orange Theory Hunt Valley, MD",
        "address": "118 Shawan Rd., Suite BB0323 Hunt Valley, Maryland 21030",
        "phone": "(410) 777-8836",
        "email": "studiomanager0576@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.4988556,
            -76.654877
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.41,
                    "duration": "2 Months",
                    "model_conf": 150.42
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.14,
                    "duration": "indefinitely",
                    "model_conf": 180.42
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.65,
                    "duration": "indefinitely",
                    "model_conf": 111.46
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Hunt Valley, MD",
            "slug": "b50fb8eb-539e-44d0-950e-4e8591ad8b92"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/11/16",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/hunt-valley/118-shawan-rd-suite-bb0323/",
        "index": 108.325,
        "franchise-entity-name": "HUNT VALLEY FITNESS PARTNERS, LLC"
    },
    "baeae7e2-c0ea-4087-9b2d-14285631c933": {
        "name": "Orange Theory Huntington Beach - Brookhurst, CA",
        "address": "10036 Adams Ave. Huntington Beach, California 92646",
        "phone": "(714) 489-8800",
        "email": "studiomanager0099@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:20PM",
            "TUESDAY": "5:00AM - 7:20PM",
            "WEDNESDAY": "5:00AM - 7:20PM",
            "THURSDAY": "5:00AM - 7:20PM",
            "FRIDAY": "5:00AM - 7:20PM",
            "SATURDAY": "6:30AM - 11:30AM",
            "SUNDAY": "6:30AM - 11:30AM"
        },
        "geolocation": [
            33.6722984,
            -117.95311
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.77,
                    "duration": "1 Months",
                    "model_conf": 146.31
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.52,
                    "duration": "indefinitely",
                    "model_conf": 178.61
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.81,
                    "duration": "indefinitely",
                    "model_conf": 106.98
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Huntington Beach - Brookhurst, CA",
            "slug": "baeae7e2-c0ea-4087-9b2d-14285631c933"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/huntington-beach/10036-adams-ave/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT HBR LLC"
    },
    "457744f2-a22c-4130-af71-302dc89f9cef": {
        "name": "Orange Theory HB - Bella Terra, CA",
        "address": "7881 Edinger Avenue, suite 116 Huntington Beach, California 92647",
        "phone": "(714) 780-2350",
        "email": "studiomanager0953@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:20PM",
            "TUESDAY": "5:00AM - 7:20PM",
            "WEDNESDAY": "5:00AM - 7:20PM",
            "THURSDAY": "5:00AM - 7:20PM",
            "FRIDAY": "5:00AM - 7:20PM",
            "SATURDAY": "7:30AM - 10:50AM",
            "SUNDAY": "7:30AM - 10:50AM"
        },
        "geolocation": [
            33.7313385,
            -117.99124
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.73,
                    "duration": "5 Months",
                    "model_conf": 148.88
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 175.68
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.14,
                    "duration": "1 Months",
                    "model_conf": 104.23
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "HB - Bella Terra, CA",
            "slug": "457744f2-a22c-4130-af71-302dc89f9cef"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/20/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/huntington-beach/7881-edinger-avenue-suite-116/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT HBT LLC"
    },
    "63ba1bab-23df-4dba-a772-5e4d6ce5c021": {
        "name": "Orange Theory Huntsville, AL",
        "address": "930 Bob Wallace Ave S.W, Suite 229 Huntsville, Alabama 35801",
        "phone": "(256) 534-4344",
        "email": "studiomanager0446@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            34.7119408,
            -86.586296
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.71,
                    "duration": "indefinitely",
                    "model_conf": 153.5
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.94,
                    "duration": "3 Months",
                    "model_conf": 182.26
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.95,
                    "duration": "indefinitely",
                    "model_conf": 111.2
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Huntsville, AL",
            "slug": "63ba1bab-23df-4dba-a772-5e4d6ce5c021"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/huntsville/930-bob-wallace-ave-s-w-suite-229/",
        "index": 93.4,
        "franchise-entity-name": "ALABAMAFIT3, LLC"
    },
    "83f6b503-7e48-4c2a-828b-fb37d56f3eae": {
        "name": "Orange Theory Pflugerville, TX",
        "address": "4810 Gattis School Road Hutto, Texas 78634",
        "phone": "(512) 298-3350",
        "email": "studiomanager0621@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            30.5007839,
            -97.584808
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.16,
                    "duration": "indefinitely",
                    "model_conf": 147.66
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.09,
                    "duration": "indefinitely",
                    "model_conf": 176.12
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.45,
                    "duration": "indefinitely",
                    "model_conf": 110.17
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Pflugerville, TX",
            "slug": "83f6b503-7e48-4c2a-828b-fb37d56f3eae"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/13/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/hutto/4810-gattis-school-road/",
        "index": 103.375,
        "franchise-entity-name": "COWAN FITNESS, LLC"
    },
    "a0559f33-2e24-4777-be6a-503010b58e9e": {
        "name": "Orange Theory Indianapolis-Ironworks at Keystone, IN",
        "address": "2727 E. 86th Street, Suite 115 Indianapolis, Indiana 46240",
        "phone": "(317) 542-3102",
        "email": "studiomanager0515@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            39.9115906,
            -86.116249
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.36,
                    "duration": "9 Months",
                    "model_conf": 108.09
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 111.59,
                    "duration": "indefinitely",
                    "model_conf": 130.09
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.76,
                    "duration": "2 Months",
                    "model_conf": 80.08
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Indianapolis-Ironworks at Keystone, IN",
            "slug": "a0559f33-2e24-4777-be6a-503010b58e9e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/20/16",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/indianapolis/2727-e-86th-street-suite-115/",
        "index": 93.4,
        "franchise-entity-name": "OT INDIANA IRONWORKS, LLC"
    },
    "ad168b5e-46ff-42f8-bfac-18f07f376be7": {
        "name": "Orange Theory Broad Ripple",
        "address": "5858 North College Avenue Indianapolis, Indiana 46220",
        "phone": "(317) 616-3800",
        "email": "studiomanager1191@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            39.861145,
            -86.146553
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.12,
                    "duration": "indefinitely",
                    "model_conf": 131.14
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.34,
                    "duration": "2 Months",
                    "model_conf": 158.67
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.66,
                    "duration": "indefinitely",
                    "model_conf": 96.2
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Broad Ripple",
            "slug": "ad168b5e-46ff-42f8-bfac-18f07f376be7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/21/19",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/indianapolis/5858-n-college-avenue-suite-b/",
        "index": 93.4,
        "franchise-entity-name": "OT Indiana Broad Ripple, LLC"
    },
    "ef177679-5d48-41ab-94a3-7804207794bc": {
        "name": "Orange Theory IRMO, SC",
        "address": "7467 St. Andrews Road Suite 7B Irmo, South Carolina 29063",
        "phone": "(803) 220-0019",
        "email": "studiomanager0880@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.0863266,
            -81.185692
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.06,
                    "duration": "indefinitely",
                    "model_conf": 152.31
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.04,
                    "duration": "indefinitely",
                    "model_conf": 184.9
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.72,
                    "duration": "indefinitely",
                    "model_conf": 111.48
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "IRMO, SC",
            "slug": "ef177679-5d48-41ab-94a3-7804207794bc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/21/18",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/irmo/7467-st-andrews-road-suite-7b/",
        "index": 93.4,
        "franchise-entity-name": "COLA 1, LLC"
    },
    "64b55e3d-f167-420b-bf02-f47d799267cc": {
        "name": "Orange Theory Irvine, CA",
        "address": "5675 Alton Parkway Irvine, California 92618",
        "phone": "(949) 439-2633",
        "email": "studiomanager0103@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:20PM",
            "TUESDAY": "5:00AM - 7:20PM",
            "WEDNESDAY": "5:00AM - 7:20PM",
            "THURSDAY": "5:00AM - 7:20PM",
            "FRIDAY": "5:00AM - 7:20PM",
            "SATURDAY": "6:30AM - 11:30AM",
            "SUNDAY": "6:30AM - 11:30AM"
        },
        "geolocation": [
            33.6697769,
            -117.7858
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.23,
                    "duration": "indefinitely",
                    "model_conf": 143.21
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.74,
                    "duration": "indefinitely",
                    "model_conf": 180.61
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.08,
                    "duration": "indefinitely",
                    "model_conf": 109.09
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Irvine, CA",
            "slug": "64b55e3d-f167-420b-bf02-f47d799267cc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/15/13",
        "url": "https://www.orangetheory.com/en-us/locations/california/irvine/5675-alton-parkway/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT IRV LLC"
    },
    "74387af9-24b6-4b36-a6fa-08a1d03854ee": {
        "name": "Orange Theory Las Colinas, TX",
        "address": "7600 N. MacArthur Blvd. #140 Irving, Texas 75063",
        "phone": "(972) 474-0404",
        "email": "studiomanager1030@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 10:00PM",
            "TUESDAY": "5:00AM - 10:00PM",
            "WEDNESDAY": "5:00AM - 10:00PM",
            "THURSDAY": "5:00AM - 10:00PM",
            "FRIDAY": "5:00AM - 10:00PM",
            "SATURDAY": "7:00AM - 3:30PM",
            "SUNDAY": "7:00AM - 3:30PM"
        },
        "geolocation": [
            32.9098702,
            -96.956886
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.72,
                    "duration": "1 Months",
                    "model_conf": 147.51
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.08,
                    "duration": "indefinitely",
                    "model_conf": 181.18
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.62,
                    "duration": "indefinitely",
                    "model_conf": 109.84
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Las Colinas, TX",
            "slug": "74387af9-24b6-4b36-a6fa-08a1d03854ee"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/29/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/irving/7600-n-macarthur-blvd-140/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA FITNESS LAS COLINAS, LLC"
    },
    "ed77915c-bbbf-44c3-95b9-a39bd21c30c1": {
        "name": "Orange Theory Issaquah, WA",
        "address": "1676 9th Avenue NE, Suite 100 Issaquah, Washington 98029",
        "phone": "(425) 616-3565",
        "email": "studiomanager0408@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            47.5447197,
            -122.01736
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.86,
                    "duration": "indefinitely",
                    "model_conf": 151.44
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.04,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.84,
                    "duration": "indefinitely",
                    "model_conf": 183.01
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.01,
                    "duration": "indefinitely",
                    "model_conf": 108.92
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 45.45,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 60.5,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Issaquah, WA",
            "slug": "ed77915c-bbbf-44c3-95b9-a39bd21c30c1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/7/18",
        "url": "https://www.orangetheory.com/en-us/locations/washington/issaquah/1676-9th-avenue-ne-suite-100/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF ISSAQUAH, LLC"
    },
    "b4f00055-a204-4bbd-be73-8b0a3fa56db1": {
        "name": "Orange Theory Ithaca",
        "address": "614 South Meadow Street Ithaca, New York 14851",
        "phone": "(607) 252-4811",
        "email": "studiomanager1133@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            42.432621,
            -76.512535
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.99,
                    "duration": "indefinitely",
                    "model_conf": 153.16
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.98,
                    "duration": "indefinitely",
                    "model_conf": 185.79
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.0,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Ithaca",
            "slug": "b4f00055-a204-4bbd-be73-8b0a3fa56db1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/16/20",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/ithaca/614-south-meadow-street-ithaca-ny-14850/",
        "index": 98.425,
        "franchise-entity-name": "Ithaca Fitness, LLC"
    },
    "63e0a542-dcde-4cb5-82bd-179a240f03ff": {
        "name": "Orange Theory Jackson, MS",
        "address": "120 District Blvd East, Suite 105, Jackson, Mississippi 39211",
        "phone": "(601) 714-8060",
        "email": "studiomanager0626@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 3:00PM",
            "SUNDAY": "7:30AM - 3:00PM"
        },
        "geolocation": [
            32.3922081,
            -90.111633
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.75,
                    "duration": "1 Months",
                    "model_conf": 138.11
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.89,
                    "duration": "indefinitely",
                    "model_conf": 176.66
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.06,
                    "duration": "indefinitely",
                    "model_conf": 111.59
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Jackson, MS",
            "slug": "63e0a542-dcde-4cb5-82bd-179a240f03ff"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/19/17",
        "url": "https://www.orangetheory.com/en-us/locations/mississippi/jackson/120-district-blvd-east-suite-105/",
        "index": 93.4,
        "franchise-entity-name": "EPOC FITNESS VENTURE II, LLC"
    },
    "1fd067d7-4885-4ac3-b3a0-c967fe7d2a2e": {
        "name": "Orange Theory Jackson Hole, WY",
        "address": "150 W. Simpson Ave. Jackson, Wyoming 83001",
        "phone": "(307) 459-6141",
        "email": "studiomanager1291@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:30PM",
            "TUESDAY": "5:15AM - 7:30PM",
            "WEDNESDAY": "5:15AM - 7:30PM",
            "THURSDAY": "5:15AM - 7:30PM",
            "FRIDAY": "5:15AM - 7:30PM",
            "SATURDAY": "8:00AM - 12:00PM",
            "SUNDAY": "8:00AM - 12:00PM"
        },
        "geolocation": [
            43.4770889,
            -110.76443
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.73,
                    "duration": "1 Months",
                    "model_conf": 153.12
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.62,
                    "duration": "1 Months",
                    "model_conf": 185.84
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.74,
                    "duration": "indefinitely",
                    "model_conf": 112.12
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Jackson Hole, WY",
            "slug": "1fd067d7-4885-4ac3-b3a0-c967fe7d2a2e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/wyoming/jackson-hole/150-w-simpson-ave/",
        "index": 108.325,
        "franchise-entity-name": "OTtetons, LLC"
    },
    "47ec4a0e-fa50-4c09-9743-0f07b88cf55e": {
        "name": "Orange Theory Jacksonville Beach, FL",
        "address": "3994 Third Street S, Suite 9 Jacksonville Beach, Florida 32250",
        "phone": "(904) 930-4009",
        "email": "studiomanager0837@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            30.258318,
            -81.387741
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.39,
                    "duration": "indefinitely",
                    "model_conf": 150.31
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.91,
                    "duration": "3 Months",
                    "model_conf": 183.72
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.14,
                    "duration": "indefinitely",
                    "model_conf": 108.54
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Jacksonville Beach, FL",
            "slug": "47ec4a0e-fa50-4c09-9743-0f07b88cf55e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/jacksonville-beach/3994-third-street/",
        "index": 93.4,
        "franchise-entity-name": "F8 ENTERPRISES, LLC"
    },
    "65d6d5e0-3972-4576-b22e-7aebcb2ef34d": {
        "name": "Orange Theory Jacksonville, FL",
        "address": "11111 San Jose Blvd., Suite 13 Jacksonville, Florida 32223",
        "phone": "(904) 886-3377",
        "email": "studiomanager0151@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            30.1735077,
            -81.62674
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.06,
                    "duration": "indefinitely",
                    "model_conf": 146.34
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.81,
                    "duration": "indefinitely",
                    "model_conf": 185.13
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.6,
                    "duration": "indefinitely",
                    "model_conf": 103.28
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Jacksonville, FL",
            "slug": "65d6d5e0-3972-4576-b22e-7aebcb2ef34d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/2/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/jacksonville/11111-san-jose-blvd/",
        "index": 93.4,
        "franchise-entity-name": "F 2 ENTERPRISES LLC"
    },
    "7bfbf835-52ad-4074-8ab3-b76ce9cd7169": {
        "name": "Orange Theory Jacksonville - Hodges Pointe, FL",
        "address": "13500 Beach Boulevard Suite 30 Jacksonville, Florida 32224",
        "phone": "(904) 385-4336",
        "email": "studiomanager0152@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            30.2846832,
            -81.460739
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.32,
                    "duration": "indefinitely",
                    "model_conf": 145.84
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.66,
                    "duration": "1 Months",
                    "model_conf": 184.72
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.48,
                    "duration": "indefinitely",
                    "model_conf": 102.4
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Jacksonville - Hodges Pointe, FL",
            "slug": "7bfbf835-52ad-4074-8ab3-b76ce9cd7169"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/13/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/jacksonville/13500-beach-boulevard-suite-30/",
        "index": 93.4,
        "franchise-entity-name": "F 4 ENTERPRISES, LLC"
    },
    "9b605e08-6dc5-423a-bed0-2d90fbb2c4a9": {
        "name": "Orange Theory San Marco, FL",
        "address": "2039 Hendricks Ave #215 Jacksonville, Florida 32207",
        "phone": "(904) 712-6949",
        "email": "studiomanager1538@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "6:45AM - 10:45AM",
            "SUNDAY": "6:45AM - 10:45AM"
        },
        "geolocation": [
            30.304648,
            -81.6528
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.16,
                    "duration": "indefinitely",
                    "model_conf": 141.99
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.04,
                    "duration": "3 Months",
                    "model_conf": 184.56
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.56,
                    "duration": "1 Months",
                    "model_conf": 97.95
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "San Marco, FL",
            "slug": "9b605e08-6dc5-423a-bed0-2d90fbb2c4a9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/10/22",
        "url": "https://www.orangetheory.com/en-us/locations/florida/jacksonville/2039-hendricks-ave/",
        "index": 93.4,
        "franchise-entity-name": "F16 ENTERPRISES, LLC"
    },
    "38fc705c-b99f-4ee6-a00c-0d086fae8c50": {
        "name": "Orange Theory Downtown Jacksonville - Ortega, FL",
        "address": "4495 Roosevelt Blvd, Suite 318 Jacksonville, Florida 32210",
        "phone": "(904) 328-6905",
        "email": "studiomanager0345@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:30PM",
            "TUESDAY": "5:15AM - 7:30PM",
            "WEDNESDAY": "5:15AM - 7:30PM",
            "THURSDAY": "5:15AM - 7:30PM",
            "FRIDAY": "5:15AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            30.279974,
            -81.718803
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.51,
                    "duration": "indefinitely",
                    "model_conf": 148.72
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.86,
                    "duration": "indefinitely",
                    "model_conf": 184.58
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.26,
                    "duration": "indefinitely",
                    "model_conf": 106.08
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Downtown Jacksonville - Ortega, FL",
            "slug": "38fc705c-b99f-4ee6-a00c-0d086fae8c50"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/27/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/jacksonville/4495-roosevelt-blvd-suite-318/",
        "index": 93.4,
        "franchise-entity-name": "RGA 1, LLC"
    },
    "0b39685b-39e7-4ecf-b0e7-c61156b1afe8": {
        "name": "Orange Theory Oakleaf, FL",
        "address": "9610 Applecross Rd. Suite 102 Jacksonville, Florida 32222",
        "phone": "(904) 661-1958",
        "email": "studiomanager0898@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:30PM",
            "SUNDAY": "7:00AM - 11:30PM"
        },
        "geolocation": [
            30.196415,
            -81.827187
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.47,
                    "duration": "indefinitely",
                    "model_conf": 152.91
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.55,
                    "duration": "1 Months",
                    "model_conf": 184.44
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.17,
                    "duration": "indefinitely",
                    "model_conf": 111.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Oakleaf, FL",
            "slug": "0b39685b-39e7-4ecf-b0e7-c61156b1afe8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/jacksonville/9610-applecross-rd-suite-102/",
        "index": 93.4,
        "franchise-entity-name": "RCS OAKLEAF, LLC"
    },
    "1eb77075-05e8-4e38-bd30-66fcd70383fe": {
        "name": "Orange Theory Jacksonville - Southside, FL",
        "address": "9734 Deer Lake Court, Suite 9 Jacksonville, Florida 32246",
        "phone": "(904) 559-1200",
        "email": "studiomanager0836@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            30.2650452,
            -81.554955
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.16,
                    "duration": "indefinitely",
                    "model_conf": 130.85
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.81,
                    "duration": "indefinitely",
                    "model_conf": 183.44
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.19,
                    "duration": "indefinitely",
                    "model_conf": 81.72
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Jacksonville - Southside, FL",
            "slug": "1eb77075-05e8-4e38-bd30-66fcd70383fe"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/jacksonville/9734-deer-lake-court-suite-6-7/",
        "index": 93.4,
        "franchise-entity-name": "F7 ENTERPRISES, LLC"
    },
    "a0080573-595d-45dd-8fbf-24aca1f5b342": {
        "name": "Orange Theory Jensen Beach, FL",
        "address": "4215 NW Federal HWY Jensen Beach, Florida 34957",
        "phone": "(772) 261-8119",
        "email": "studiomanager0900@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            27.2574978,
            -80.280312
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.0,
                    "duration": "indefinitely",
                    "model_conf": 149.8
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.21,
                    "duration": "indefinitely",
                    "model_conf": 185.76
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.04,
                    "duration": "indefinitely",
                    "model_conf": 111.03
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Jensen Beach, FL",
            "slug": "a0080573-595d-45dd-8fbf-24aca1f5b342"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/15/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/jensen-beach/4215-nw-federal-hwy/",
        "index": 98.425,
        "franchise-entity-name": "FOXHOP FITNESS, LLC"
    },
    "cf87521e-b2c5-4e48-8664-173e6db02632": {
        "name": "Orange Theory Jersey City, NJ",
        "address": "475 Washington Blvd Jersey City, New Jersey 07310",
        "phone": "(973) 318-9189",
        "email": "studiomanager0706@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:30PM",
            "TUESDAY": "5:00AM - 9:30PM",
            "WEDNESDAY": "5:00AM - 9:30PM",
            "THURSDAY": "5:00AM - 9:30PM",
            "FRIDAY": "5:00AM - 9:30PM",
            "SATURDAY": "7:00AM - 2:30PM",
            "SUNDAY": "7:00AM - 2:30PM"
        },
        "geolocation": [
            40.7242699,
            -74.036598
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.43,
                    "duration": "indefinitely",
                    "model_conf": 117.95
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.71,
                    "duration": "indefinitely",
                    "model_conf": 141.79
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.14,
                    "duration": "indefinitely",
                    "model_conf": 86.96
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Jersey City, NJ",
            "slug": "cf87521e-b2c5-4e48-8664-173e6db02632"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/17/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/jersey-city/475-washington-blvd/",
        "index": 103.375,
        "franchise-entity-name": "M2 JERSEY CITY LLC"
    },
    "1dd3133b-f0d3-491e-b8cb-5f50bb379249": {
        "name": "Orange Theory Johns Creek - Rivermont, GA",
        "address": "8465 Holcomb Bridge Road, Suite 430 Johns Creek, Georgia 30022",
        "phone": "(470) 388-3159",
        "email": "studiomanager0189@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            33.9877091,
            -84.270065
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.86,
                    "duration": "indefinitely",
                    "model_conf": 137.88
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.82,
                    "duration": "indefinitely",
                    "model_conf": 174.11
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.68,
                    "duration": "indefinitely",
                    "model_conf": 99.32
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Johns Creek - Rivermont, GA",
            "slug": "1dd3133b-f0d3-491e-b8cb-5f50bb379249"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/4/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/johns-creek/8465-holcomb-bridge-road-suite-430/",
        "index": 98.425,
        "franchise-entity-name": "VAILLANTINO LS, LLC"
    },
    "1dd50fe4-96c3-4f12-bd36-e30218ec25ef": {
        "name": "Orange Theory Johnson City, TN",
        "address": "3211 Peoples St Suite 12 Johnson City, Tennessee 37604",
        "phone": "(423) 497-3461",
        "email": "studiomanager1420@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            36.345277,
            -82.400617
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.17
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.82
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.75
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Johnson City, TN",
            "slug": "1dd50fe4-96c3-4f12-bd36-e30218ec25ef"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/3/22",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/johnson-city/3211-peoples-st-suite-400/",
        "index": 93.4,
        "franchise-entity-name": "JM Johnson City LLC"
    },
    "636bc6fa-ca4f-4b4b-a3cc-4afc4b357456": {
        "name": "Orange Theory Jonesboro",
        "address": "2110 Fair Park Blvd Jonesboro, Arkansas 72401",
        "phone": "(870) 277-0333",
        "email": "studiomanager1147@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 3:30PM",
            "SUNDAY": "7:00AM - 3:30PM"
        },
        "geolocation": [
            35.8184929,
            -90.672821
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.8
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.83
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.99
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Jonesboro",
            "slug": "636bc6fa-ca4f-4b4b-a3cc-4afc4b357456"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/arkansas/jonesboro/2110-fair-park-blvd/",
        "index": 93.4,
        "franchise-entity-name": "AR HIIT, LLC"
    },
    "1021e151-bc81-4db1-a9e2-ec9e79236cc5": {
        "name": "Orange Theory Jupiter, FL",
        "address": "6390 W. Indiantown Road Suite 24A Jupiter, Florida 33458",
        "phone": "(561) 743-7770",
        "email": "studiomanager0054@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            26.9338265,
            -80.132317
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.0,
                    "duration": "4 Months",
                    "model_conf": 151.57
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.11,
                    "duration": "indefinitely",
                    "model_conf": 185.09
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.12,
                    "duration": "1 Months",
                    "model_conf": 111.43
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Jupiter, FL",
            "slug": "1021e151-bc81-4db1-a9e2-ec9e79236cc5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/10/13",
        "url": "https://www.orangetheory.com/en-us/locations/florida/jupiter/6390-w-indiantown-road-suite-24a/",
        "index": 98.425,
        "franchise-entity-name": "VICEDWARD, INC."
    },
    "4ebc393d-3f00-46e1-937e-61c940485b53": {
        "name": "Orange Theory Kalamazoo, MI",
        "address": "1750 S. Drake Road Suite C Kalamazoo, Michigan 49006",
        "phone": "(269) 276-0888",
        "email": "studiomanager0944@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            42.2743263,
            -85.6483
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.0,
                    "duration": "indefinitely",
                    "model_conf": 153.34
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.76,
                    "duration": "indefinitely",
                    "model_conf": 185.55
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.72,
                    "duration": "indefinitely",
                    "model_conf": 111.87
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Kalamazoo, MI",
            "slug": "4ebc393d-3f00-46e1-937e-61c940485b53"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/kalamazoo/1750-s-drake-road-suite-c/",
        "index": 93.4,
        "franchise-entity-name": "AHRLELMG LLC"
    },
    "f428a42b-37c3-4818-8321-d80918e6c321": {
        "name": "Orange Theory Downtown KC, MO",
        "address": "1706 Baltimore Ave. Kansas City, Missouri 64108",
        "phone": "(816) 659-1055",
        "email": "studiomanager1167@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            39.092453,
            -94.585052
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.56,
                    "duration": "8 Months",
                    "model_conf": 153.01
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.89,
                    "duration": "indefinitely",
                    "model_conf": 184.67
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.71,
                    "duration": "2 Months",
                    "model_conf": 111.34
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Downtown KC, MO",
            "slug": "f428a42b-37c3-4818-8321-d80918e6c321"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/15/19",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/kansas-city/1706-baltimore-av/",
        "index": 93.4,
        "franchise-entity-name": "MSM3 FITNESS KS, LLC"
    },
    "c61c1f71-042e-4d9b-bc4f-6177f2f89821": {
        "name": "Orange Theory Kansas City - Parkville, MO",
        "address": "6006 NW 63rd Terrace Kansas City, Missouri 64151",
        "phone": "(816) 945-6551",
        "email": "studiomanager0989@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "8:00AM - 8:00PM",
            "TUESDAY": "8:00AM - 8:00PM",
            "WEDNESDAY": "8:00AM - 8:00PM",
            "THURSDAY": "8:00AM - 8:00PM",
            "FRIDAY": "8:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            39.2091713,
            -94.65004
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.34,
                    "duration": "indefinitely",
                    "model_conf": 153.43
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.73,
                    "duration": "indefinitely",
                    "model_conf": 184.7
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.33,
                    "duration": "indefinitely",
                    "model_conf": 111.23
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Kansas City - Parkville, MO",
            "slug": "c61c1f71-042e-4d9b-bc4f-6177f2f89821"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/17/18",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/kansas-city/6006-nw-63rd-terrace/",
        "index": 93.4,
        "franchise-entity-name": "HIIT MO, L.L.C."
    },
    "83782799-96be-44de-941b-d30e8a6d618e": {
        "name": "Orange Theory Brookside, MO",
        "address": "6236 Main Street Kansas City, Missouri 64113",
        "phone": "(816) 656-8390",
        "email": "studiomanager0734@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 2:00PM",
            "SUNDAY": "6:30AM - 2:00PM"
        },
        "geolocation": [
            39.0146141,
            -94.588646
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.94,
                    "duration": "5 Months",
                    "model_conf": 151.57
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.5,
                    "duration": "indefinitely",
                    "model_conf": 183.09
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.38,
                    "duration": "1 Months",
                    "model_conf": 109.43
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Brookside, MO",
            "slug": "83782799-96be-44de-941b-d30e8a6d618e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/31/17",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/kansas-city/6236-main-street/",
        "index": 93.4,
        "franchise-entity-name": "MSM3 FITNESS KS, LLC"
    },
    "c7893ced-8492-4e90-9412-c4e5a2415592": {
        "name": "Orange Theory Katy - Cinco Ranch, TX",
        "address": "23144 Cinco Ranch Blvd. Katy, Texas 77494",
        "phone": "(281) 727-0098",
        "email": "studiomanager0471@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            29.7449474,
            -95.771347
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.25,
                    "duration": "indefinitely",
                    "model_conf": 132.86
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.26,
                    "duration": "2 Months",
                    "model_conf": 172.96
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.4,
                    "duration": "indefinitely",
                    "model_conf": 81.87
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Katy - Cinco Ranch, TX",
            "slug": "c7893ced-8492-4e90-9412-c4e5a2415592"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/5/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/katy/23144-cinco-ranch-blvd/",
        "index": 98.425,
        "franchise-entity-name": "CINCO RANCH COACHING, LLC"
    },
    "70f68f70-e163-480c-a46e-d79a5bb45908": {
        "name": "Orange Theory Fulshear, TX",
        "address": "4906 FM 1463 Katy, Texas 77494",
        "phone": "(281) 725-6435",
        "email": "studiomanager1317@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            29.72215,
            -95.846405
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.78,
                    "duration": "indefinitely",
                    "model_conf": 136.69
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.71,
                    "duration": "7 Months",
                    "model_conf": 174.55
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.9,
                    "duration": "indefinitely",
                    "model_conf": 88.5
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fulshear, TX",
            "slug": "70f68f70-e163-480c-a46e-d79a5bb45908"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/13/21",
        "url": "https://www.orangetheory.com/en-us/locations/texas/katy/4906-fm-1463-suite-500/",
        "index": 98.425,
        "franchise-entity-name": "FULSHEAR COACHING LLC"
    },
    "ffbe3466-8b83-4dca-b24a-cbec3cf144fb": {
        "name": "Orange Theory Kaysville, UT",
        "address": "349 North Flint Street Kaysville, Utah 84037",
        "phone": "(385) 245-7528",
        "email": "studiomanager0394@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            41.0404129,
            -111.96565
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.78,
                    "duration": "indefinitely",
                    "model_conf": 153.23
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.5,
                    "duration": "1 Months",
                    "model_conf": 184.65
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.71,
                    "duration": "indefinitely",
                    "model_conf": 111.86
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Kaysville, UT",
            "slug": "ffbe3466-8b83-4dca-b24a-cbec3cf144fb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/6/16",
        "url": "https://www.orangetheory.com/en-us/locations/utah/kaysville/349-north-flint-street/",
        "index": 93.4,
        "franchise-entity-name": "HBTANNER HOLDINGS II LLC"
    },
    "550a2b79-e2f7-47c5-8e0f-1ae1bac0bff6": {
        "name": "Orange Theory Keller, TX",
        "address": "101 Town Center Ln, Suite A108 Keller, Texas 76248",
        "phone": "(817) 502-2525",
        "email": "studiomanager0244@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            32.9328308,
            -97.226776
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.18,
                    "duration": "indefinitely",
                    "model_conf": 147.6
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.55,
                    "duration": "1 Months",
                    "model_conf": 183.59
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.28,
                    "duration": "indefinitely",
                    "model_conf": 111.52
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Keller, TX",
            "slug": "550a2b79-e2f7-47c5-8e0f-1ae1bac0bff6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/keller/101-town-center-ln-suite-a108/",
        "index": 93.4,
        "franchise-entity-name": "VSB VENTURES II, LLC"
    },
    "2fac59ee-1ed8-4e19-984c-2177853aaf08": {
        "name": "Orange Theory Kennewick,WA",
        "address": "4101 W 27th Place Ste B110/120 Kennewick, Washington 99338",
        "phone": "(509) 581-3012",
        "email": "studiomanager1298@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:50AM - 7:45PM",
            "TUESDAY": "4:50AM - 7:45PM",
            "WEDNESDAY": "4:50AM - 7:45PM",
            "THURSDAY": "4:50AM - 7:45PM",
            "FRIDAY": "4:50AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            46.1853714,
            -119.1764
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 6,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.8,
                    "duration": "indefinitely",
                    "model_conf": 151.17
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 9.48,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.91,
                    "duration": "3 Months",
                    "model_conf": 184.71
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 14.7,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.56,
                    "duration": "indefinitely",
                    "model_conf": 112.06
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 17.31,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 31.23,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 43.41,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Kennewick,WA",
            "slug": "2fac59ee-1ed8-4e19-984c-2177853aaf08"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/18/21",
        "url": "https://www.orangetheory.com/en-us/locations/washington/kennewick/4101-w-27th-place-ste-b110-120/",
        "index": 93.4,
        "franchise-entity-name": "WAFIT KENNEWICK LLC"
    },
    "433944d0-dcf5-4726-af90-fb72d15254c6": {
        "name": "Orange Theory Kildeer, IL",
        "address": "20393 North Rand Road Kildeer, Illinois 60074",
        "phone": "(224) 662-7177",
        "email": "studiomanager0646@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            42.1599464,
            -88.047447
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 91.05,
                    "duration": "indefinitely",
                    "model_conf": 126.39
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 125.98,
                    "duration": "9 Months",
                    "model_conf": 151.27
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.96,
                    "duration": "indefinitely",
                    "model_conf": 92.37
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Kildeer, IL",
            "slug": "433944d0-dcf5-4726-af90-fb72d15254c6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/2/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/kildeer/20393-north-rand-road/",
        "index": 103.375,
        "franchise-entity-name": "C &amp; Z FIT II, LLC"
    },
    "3860c95f-ba5b-4093-8232-f0e5de8fcd8c": {
        "name": "Orange Theory Kingston, MA",
        "address": "160 Summer Street Kingston, Massachusetts 02364",
        "phone": "(781) 209-5090",
        "email": "studiomanager0916@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            42.0079422,
            -70.732582
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.94,
                    "duration": "indefinitely",
                    "model_conf": 153.76
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.87,
                    "duration": "indefinitely",
                    "model_conf": 185.77
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.81,
                    "duration": "indefinitely",
                    "model_conf": 111.91
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Kingston, MA",
            "slug": "3860c95f-ba5b-4093-8232-f0e5de8fcd8c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/7/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/kingston/160-summer-street/",
        "index": 103.375,
        "franchise-entity-name": "NAHOM ALPHA , LLC"
    },
    "e6d1e07d-a28f-494f-92f0-257beeaa4b3e": {
        "name": "Orange Theory Wilkes-Barre",
        "address": "401 Market Street, Unit 101 Kingston, Pennsylvania 18704",
        "phone": "(570) 794-5353",
        "email": "studiomanager1162@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            41.2570114,
            -75.892014
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.75
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.76
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.02
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Wilkes-Barre",
            "slug": "e6d1e07d-a28f-494f-92f0-257beeaa4b3e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/7/20",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/kingston/401-market-street-unit-101/",
        "index": 93.4,
        "franchise-entity-name": "WB FITNESS, LLC - 1162"
    },
    "ad5ea8c4-a4a3-4583-979a-f2d89249b678": {
        "name": "Orange Theory Kingwood, TX",
        "address": "4525 Kingwood Drive, Suite 100 Kingwood, Texas 77345",
        "phone": "(281) 516-5787",
        "email": "studiomanager0406@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:45PM",
            "SUNDAY": "6:30AM - 12:45PM"
        },
        "geolocation": [
            30.0501537,
            -95.184235
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.56,
                    "duration": "indefinitely",
                    "model_conf": 151.18
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.2,
                    "duration": "2 Months",
                    "model_conf": 175.97
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.27,
                    "duration": "indefinitely",
                    "model_conf": 108.93
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Kingwood, TX",
            "slug": "ad5ea8c4-a4a3-4583-979a-f2d89249b678"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/4/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/kingwood/4525-kingwood-drive-suite-100/",
        "index": 98.425,
        "franchise-entity-name": "KINGWOOD COACHING LLC"
    },
    "933ab64a-961c-4d57-bcc2-fd1c763625ee": {
        "name": "Orange Theory Kirkland, WA",
        "address": "217 Park Lane Kirkland, Washington 98033",
        "phone": "(425) 502-8094",
        "email": "studiomanager0579@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            47.6763649,
            -122.20469
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.1,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.5,
                    "duration": "indefinitely",
                    "model_conf": 145.32
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.18,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.36,
                    "duration": "1 Months",
                    "model_conf": 184.64
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.3,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.24,
                    "duration": "indefinitely",
                    "model_conf": 100.96
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 45.9,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 61.1,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Kirkland, WA",
            "slug": "933ab64a-961c-4d57-bcc2-fd1c763625ee"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/28/19",
        "url": "https://www.orangetheory.com/en-us/locations/washington/kirkland/207-park-lane/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF KIRKLAND, LLC"
    },
    "06b369e1-1a1c-49b3-bb7e-854868773be3": {
        "name": "Orange Theory Hunters Creek, FL",
        "address": "1021 West Osceola Parkway Kissimmee, Florida 34741",
        "phone": "(407) 720-4646",
        "email": "studiomanager0166@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            28.3413162,
            -81.412003
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.49,
                    "duration": "indefinitely",
                    "model_conf": 153.14
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.47,
                    "duration": "1 Months",
                    "model_conf": 178.38
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.34,
                    "duration": "indefinitely",
                    "model_conf": 111.42
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Hunters Creek, FL",
            "slug": "06b369e1-1a1c-49b3-bb7e-854868773be3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/13/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/kissimmee/1021-w-osceola-pkwy/",
        "index": 98.425,
        "franchise-entity-name": "DOUBLE EXCEL LLC"
    },
    "69b259a2-3266-44d0-a330-36ac495b0574": {
        "name": "Orange Theory Farragut, TN",
        "address": "11674 Parkside Drive, Suite 674 Knoxville, Tennessee 37934",
        "phone": "(865) 223-6699",
        "email": "studiomanager0260@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:45AM - 12:45PM",
            "SUNDAY": "6:45AM - 12:45PM"
        },
        "geolocation": [
            35.8928795,
            -84.170753
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.29,
                    "duration": "indefinitely",
                    "model_conf": 152.59
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.72,
                    "duration": "indefinitely",
                    "model_conf": 184.5
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.06,
                    "duration": "indefinitely",
                    "model_conf": 111.86
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Farragut, TN",
            "slug": "69b259a2-3266-44d0-a330-36ac495b0574"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/24/15",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/knoxville/11674-parkside-drive-suite-674/",
        "index": 93.4,
        "franchise-entity-name": "HH FARRAGUT LLC"
    },
    "12c05a30-2645-459b-a7ae-6097fce30316": {
        "name": "Orange Theory Bearden, TN",
        "address": "122 N. Forest Park Blvd. Knoxville, Tennessee 37919",
        "phone": "(865) 224-6480",
        "email": "studiomanager0828@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            35.9420662,
            -83.981407
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.54,
                    "duration": "indefinitely",
                    "model_conf": 151.01
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.72,
                    "duration": "indefinitely",
                    "model_conf": 164.91
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.07,
                    "duration": "indefinitely",
                    "model_conf": 110.34
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Bearden, TN",
            "slug": "12c05a30-2645-459b-a7ae-6097fce30316"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/knoxville/122-n-forest-park-blvd/",
        "index": 93.4,
        "franchise-entity-name": "HH BEARDEN LLC"
    },
    "8ce7ef6a-023b-4d92-8f53-0562938291ed": {
        "name": "Orange Theory Kyle, TX",
        "address": "5940 Kyle Parkway Kyle, Texas 78640",
        "phone": "(512) 807-0403",
        "email": "studiomanager0783@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            30.0092945,
            -97.856438
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.77,
                    "duration": "1 Months",
                    "model_conf": 153.13
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.25,
                    "duration": "indefinitely",
                    "model_conf": 183.77
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.66,
                    "duration": "indefinitely",
                    "model_conf": 111.67
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Kyle, TX",
            "slug": "8ce7ef6a-023b-4d92-8f53-0562938291ed"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/10/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/kyle/5940-kyle-parkway/",
        "index": 103.375,
        "franchise-entity-name": "KYLE FITNESS PARTNERS, LLC"
    },
    "6f099c50-7348-468d-96c8-a462b274996c": {
        "name": "Orange Theory La Ca\u00f1ada, CA",
        "address": "639 Foothill Blvd. La Ca\u00f1ada Flintridge, California 91011",
        "phone": "(626) 773-8191",
        "email": "studiomanager1072@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 11:15AM",
            "SUNDAY": "6:30AM - 11:15AM"
        },
        "geolocation": [
            34.2009354,
            -118.19518
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.03,
                    "duration": "indefinitely",
                    "model_conf": 151.18
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.03,
                    "duration": "2 Months",
                    "model_conf": 178.48
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.61,
                    "duration": "indefinitely",
                    "model_conf": 103.71
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "La Ca\u00f1ada, CA",
            "slug": "6f099c50-7348-468d-96c8-a462b274996c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/29/21",
        "url": "https://www.orangetheory.com/en-us/locations/california/la-canada/638-foothill-blvd-suite-639-641/",
        "index": 103.375,
        "franchise-entity-name": "MIGA DESERT CONSULTANTS, LLC AND/OR ITS LLC ASSIGNEE #1072"
    },
    "2a2e3ed7-1279-46ed-986f-717614af385a": {
        "name": "Orange Theory La Grange, IL",
        "address": "10 N Ashland Avenue La Grange, Illinois 60525",
        "phone": "(708) 793-7003",
        "email": "studiomanager0238@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 12:00PM",
            "SUNDAY": "6:30AM - 12:00PM"
        },
        "geolocation": [
            41.8159638,
            -87.872665
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.26,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.48,
                    "duration": "1 Months",
                    "model_conf": 169.89
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.74,
                    "duration": "indefinitely",
                    "model_conf": 102.5
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "La Grange, IL",
            "slug": "2a2e3ed7-1279-46ed-986f-717614af385a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/1/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/la-grange/10-n-ashland-avenue/",
        "index": 103.375,
        "franchise-entity-name": "MADGRA COMPANY"
    },
    "bdae2f43-cc26-4e21-9644-e4cac0f2f723": {
        "name": "Orange Theory La Habra, CA",
        "address": "1216 S Idaho St La Habra, California 90631",
        "phone": "(714) 739-4800",
        "email": "studiomanager1016@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "6:30AM - 11:30AM",
            "SUNDAY": "6:30AM - 11:30AM"
        },
        "geolocation": [
            33.9158363,
            -117.95829
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.76,
                    "duration": "1 Months",
                    "model_conf": 147.32
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.98,
                    "duration": "indefinitely",
                    "model_conf": 170.96
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.88,
                    "duration": "indefinitely",
                    "model_conf": 101.14
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "La Habra, CA",
            "slug": "bdae2f43-cc26-4e21-9644-e4cac0f2f723"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/la-habra/1216-s-idaho-st/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT LH LLC"
    },
    "d8edd87f-615e-4926-8335-00efc732be76": {
        "name": "Orange Theory La Mesa, CA",
        "address": "8322 Parkway Dr. La Mesa, California 91942",
        "phone": "(619) 916-5999",
        "email": "studiomanager0118@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:20AM - 1:00PM",
            "SUNDAY": "7:20AM - 1:00PM"
        },
        "geolocation": [
            32.7791367,
            -117.01772
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.09,
                    "duration": "3 Months",
                    "model_conf": 150.92
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.61,
                    "duration": "indefinitely",
                    "model_conf": 181.07
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.98,
                    "duration": "2 Months",
                    "model_conf": 104.53
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "La Mesa, CA",
            "slug": "d8edd87f-615e-4926-8335-00efc732be76"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/4/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/la-mesa/8322-parkway-dr/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT4 LLC"
    },
    "519fc3ca-94f2-458a-8a43-91b3cbeb2ca0": {
        "name": "Orange Theory Ladue, MO",
        "address": "10277 Clayton Road Ladue, Missouri 63124",
        "phone": "(314) 272-0641",
        "email": "studiomanager0187@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:00AM - 1:15PM",
            "SUNDAY": "6:00AM - 1:15PM"
        },
        "geolocation": [
            38.633049,
            -90.404511
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.63,
                    "duration": "indefinitely",
                    "model_conf": 119.91
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.66,
                    "duration": "1 Months",
                    "model_conf": 150.11
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.37,
                    "duration": "indefinitely",
                    "model_conf": 87.47
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Ladue, MO",
            "slug": "519fc3ca-94f2-458a-8a43-91b3cbeb2ca0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/21/18",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/ladue/10277-clayton-road/",
        "index": 93.4,
        "franchise-entity-name": "OTF CREVE COEUR LLC"
    },
    "3885872c-fb6e-4d19-8886-c5d024b7c2f9": {
        "name": "Orange Theory Lafayette - Moraga, CA",
        "address": "3517 Mt. Diablo Blvd Lafayette, California 94549",
        "phone": "(925) 299-9800",
        "email": "studiomanager0503@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:15PM",
            "TUESDAY": "5:00AM - 6:15PM",
            "WEDNESDAY": "5:00AM - 6:15PM",
            "THURSDAY": "5:00AM - 6:15PM",
            "FRIDAY": "5:00AM - 6:15PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            37.891983,
            -122.1174
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 100.0,
                    "duration": "indefinitely",
                    "model_conf": 152.98
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.19,
                    "duration": "2 Months",
                    "model_conf": 183.53
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.05,
                    "duration": "indefinitely",
                    "model_conf": 110.86
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Lafayette - Moraga, CA",
            "slug": "3885872c-fb6e-4d19-8886-c5d024b7c2f9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/lafayette/3517-mt-diablo-blvd/",
        "index": 108.325,
        "franchise-entity-name": "KOMA VENTURES III, LLC"
    },
    "39dd3050-eb9b-4427-9daf-fdeb94d65b37": {
        "name": "Orange Theory Louisville Lafayette, CO",
        "address": "1455 Coal Creek Dr Lafayette, Colorado 80026",
        "phone": "(720) 613-8818",
        "email": "studiomanager0536@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:15AM - 12:30PM",
            "SUNDAY": "7:15AM - 12:30PM"
        },
        "geolocation": [
            39.985363,
            -105.0965
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 81.98,
                    "duration": "indefinitely",
                    "model_conf": 148.37
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 115.42,
                    "duration": "11 Months",
                    "model_conf": 181.27
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 181.04,
                    "duration": "indefinitely",
                    "model_conf": 108.88
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Louisville Lafayette, CO",
            "slug": "39dd3050-eb9b-4427-9daf-fdeb94d65b37"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/30/18",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/lafayette/1455-coal-creek-dr/",
        "index": 98.425,
        "franchise-entity-name": "LVFIT, LLC"
    },
    "c00cee22-3f17-4375-934d-e1e4e800b7f5": {
        "name": "Orange Theory Lafayette, LA",
        "address": "109 Old Camp Road Lafayette, Louisiana 70508",
        "phone": "(337) 735-1877",
        "email": "studiomanager0775@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:00PM",
            "TUESDAY": "4:45AM - 7:00PM",
            "WEDNESDAY": "4:45AM - 7:00PM",
            "THURSDAY": "4:45AM - 7:00PM",
            "FRIDAY": "4:45AM - 7:00PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            30.1540012,
            -92.043465
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.6,
                    "duration": "indefinitely",
                    "model_conf": 152.5
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.73,
                    "duration": "indefinitely",
                    "model_conf": 179.39
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.48,
                    "duration": "indefinitely",
                    "model_conf": 110.68
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lafayette, LA",
            "slug": "c00cee22-3f17-4375-934d-e1e4e800b7f5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/6/17",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/lafayette/109-old-camp-road/",
        "index": 93.4,
        "franchise-entity-name": "CONNECTOME &amp; ASSOCIATES LLC"
    },
    "e2116a78-f018-49e3-b536-3a38619b1c3c": {
        "name": "Orange Theory Laguna Niguel, CA",
        "address": "24034 Aliso Creek Rd Laguna Niguel, California 92677",
        "phone": "(949) 667-9999",
        "email": "studiomanager0044@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            33.554657,
            -117.71071
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.58,
                    "duration": "1 Months",
                    "model_conf": 123.25
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.61,
                    "duration": "indefinitely",
                    "model_conf": 179.98
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.67,
                    "duration": "indefinitely",
                    "model_conf": 110.01
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Laguna Niguel, CA",
            "slug": "e2116a78-f018-49e3-b536-3a38619b1c3c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/1/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/laguna-niguel/24034-aliso-creek-rd/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT LN, LLC"
    },
    "b676074f-a695-45a1-ae99-4245a9d63d6c": {
        "name": "Orange Theory Lake Charles, LA",
        "address": "5685 Nelson Rd. Suite G-H Lake Charles, Louisiana 70605",
        "phone": "(337) 735-2474",
        "email": "studiomanager1047@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:30AM - 11:30AM",
            "SUNDAY": "8:30AM - 11:30AM"
        },
        "geolocation": [
            30.15059,
            -93.24915
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.46
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.7
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lake Charles, LA",
            "slug": "b676074f-a695-45a1-ae99-4245a9d63d6c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/12/18",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/lake-charles/4540-nelson-road-suite-a/",
        "index": 93.4,
        "franchise-entity-name": "108 OTC LLC"
    },
    "3ab6007a-7831-481f-a9ec-f1c0f3902746": {
        "name": "Orange Theory Lake Forest, IL",
        "address": "1050 N. Western Ave Lake Forest, Illinois 60045",
        "phone": "(847) 728-8700",
        "email": "studiomanager0554@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:15AM - 11:00AM",
            "SUNDAY": "7:15AM - 11:00AM"
        },
        "geolocation": [
            42.2588768,
            -87.842583
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.33,
                    "duration": "indefinitely",
                    "model_conf": 150.98
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.58,
                    "duration": "4 Months",
                    "model_conf": 182.61
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.93,
                    "duration": "indefinitely",
                    "model_conf": 110.23
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Lake Forest, IL",
            "slug": "3ab6007a-7831-481f-a9ec-f1c0f3902746"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/4/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/lake-forest/1050-n-western-ave/",
        "index": 103.375,
        "franchise-entity-name": "CHICAGO NORTHSHORE FITNESS, INC."
    },
    "c17c04db-b173-437a-b7f7-746a991364ce": {
        "name": "Orange Theory Lake Mary, FL",
        "address": "3801 W Lake Mary Blvd Unit 161 Lake Mary, Florida 32746",
        "phone": "(407) 833-9300",
        "email": "studiomanager0136@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:00PM",
            "TUESDAY": "5:30AM - 7:00PM",
            "WEDNESDAY": "5:30AM - 7:00PM",
            "THURSDAY": "5:30AM - 7:00PM",
            "FRIDAY": "5:30AM - 7:00PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            28.7556934,
            -81.345207
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.42,
                    "duration": "2 Months",
                    "model_conf": 144.81
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.06,
                    "duration": "indefinitely",
                    "model_conf": 164.31
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.77,
                    "duration": "indefinitely",
                    "model_conf": 96.98
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lake Mary, FL",
            "slug": "c17c04db-b173-437a-b7f7-746a991364ce"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/14/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/lake-mary/3801-w-lake-mary-blvd-unit-161/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS LLC"
    },
    "adec2c5f-719c-42ec-b440-875a43fd221e": {
        "name": "Orange Theory Lake Oswego",
        "address": "4025 MERCANTILE DRIVE Lake Oswego, Oregon 97035",
        "phone": "(971) 808-4700",
        "email": "studiomanager1198@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            45.414858,
            -122.71745
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.02,
                    "duration": "4 Months",
                    "model_conf": 152.97
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.31,
                    "duration": "indefinitely",
                    "model_conf": 175.22
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.55,
                    "duration": "indefinitely",
                    "model_conf": 110.15
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lake Oswego",
            "slug": "adec2c5f-719c-42ec-b440-875a43fd221e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/20/21",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/lake-oswego/4025-mercantile-drive-suite-120/",
        "index": 98.425,
        "franchise-entity-name": "LO Fitness, LLC"
    },
    "01e71b5c-1570-430c-82fc-9d5e76a912c9": {
        "name": "Orange Theory Lake Ridge, VA",
        "address": "4172 Merchant Plaza Lake Ridge, Virginia 22192",
        "phone": "(571) 589-8088",
        "email": "studiomanager0798@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            38.6745644,
            -77.333801
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.68,
                    "duration": "1 Months",
                    "model_conf": 148.8
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.31,
                    "duration": "indefinitely",
                    "model_conf": 171.92
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.07,
                    "duration": "indefinitely",
                    "model_conf": 106.32
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lake Ridge, VA",
            "slug": "01e71b5c-1570-430c-82fc-9d5e76a912c9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/23/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/lake-ridge/4172-merchant-plaza/",
        "index": 98.425,
        "franchise-entity-name": "HART FITNESS 2 INC"
    },
    "24dd64b4-2614-44fb-a1d9-3c82901d4e54": {
        "name": "Orange Theory Lake St. Louis, MO",
        "address": "6307 Ronald Reagan Drive Lake St. Louis, Missouri 63367",
        "phone": "(636) 614-4040",
        "email": "studiomanager0639@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            38.7680855,
            -90.784904
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.98,
                    "duration": "5 Months",
                    "model_conf": 149.73
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.36,
                    "duration": "indefinitely",
                    "model_conf": 182.43
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.66,
                    "duration": "indefinitely",
                    "model_conf": 109.55
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lake St. Louis, MO",
            "slug": "24dd64b4-2614-44fb-a1d9-3c82901d4e54"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/lake-st-louis/6307-ronald-reagan-drive/",
        "index": 93.4,
        "franchise-entity-name": "ALMR ONE, LLC"
    },
    "e4996c95-ee9c-4d42-904d-3c7754dad007": {
        "name": "Orange Theory Lake Worth, FL",
        "address": "6177 Jog Road #9D Lake Worth, Florida 33467",
        "phone": "(561) 327-6100",
        "email": "studiomanager0700@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            26.5874291,
            -80.147499
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.01,
                    "duration": "4 Months",
                    "model_conf": 151.89
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.36,
                    "duration": "indefinitely",
                    "model_conf": 173.26
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.42,
                    "duration": "1 Months",
                    "model_conf": 110.36
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lake Worth, FL",
            "slug": "e4996c95-ee9c-4d42-904d-3c7754dad007"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/18/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/lake-worth/6177-jog-road-9d/",
        "index": 98.425,
        "franchise-entity-name": "SAE FITNESS LLC"
    },
    "9c11fc0b-9c55-4c9f-8634-94c1dc3c3539": {
        "name": "Orange Theory Lake Zurich",
        "address": "480 S. Rand Rd. Lake Zurich, Illinois 60047",
        "phone": "(847) 740-3988",
        "email": "studiomanager1450@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            42.1892395,
            -88.104393
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 91.05,
                    "duration": "indefinitely",
                    "model_conf": 143.38
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 125.25,
                    "duration": "11 Months",
                    "model_conf": 172.68
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 191.16,
                    "duration": "indefinitely",
                    "model_conf": 104.6
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Lake Zurich",
            "slug": "9c11fc0b-9c55-4c9f-8634-94c1dc3c3539"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/1/20",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/lake-zurich/480-s-rand-rd/",
        "index": 103.375,
        "franchise-entity-name": "C&amp;Z Fit V, LLC"
    },
    "6f7f9a57-2486-4364-b870-ff7966bcae40": {
        "name": "Orange Theory Lakeland, FL",
        "address": "1489 Town Center Dr. Lakeland, Florida 33803",
        "phone": "(863) 226-1303",
        "email": "studiomanager0428@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            27.9998188,
            -81.98114
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.86,
                    "duration": "indefinitely",
                    "model_conf": 151.69
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.68,
                    "duration": "4 Months",
                    "model_conf": 185.19
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.87,
                    "duration": "indefinitely",
                    "model_conf": 111.96
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lakeland, FL",
            "slug": "6f7f9a57-2486-4364-b870-ff7966bcae40"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/6/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/lakeland/1489-town-center-dr/",
        "index": 98.425,
        "franchise-entity-name": "SANUS VITAE, LLC"
    },
    "82627a4d-828f-4cea-8570-2688c84e60f6": {
        "name": "Orange Theory Memphis-Lakeland, TN",
        "address": "2961 Canada Road Suite 111 - 112 Lakeland, Tennessee 38002",
        "phone": "(901) 302-6132",
        "email": "studiomanager0499@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 10:00PM",
            "TUESDAY": "5:00AM - 10:00PM",
            "WEDNESDAY": "5:00AM - 10:00PM",
            "THURSDAY": "5:00AM - 10:00PM",
            "FRIDAY": "5:00AM - 10:00PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            35.2064209,
            -89.734337
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 66.96,
                    "duration": "11 Months",
                    "model_conf": 151.05
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 111.14,
                    "duration": "indefinitely",
                    "model_conf": 184.96
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.44,
                    "duration": "3 Months",
                    "model_conf": 111.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Memphis-Lakeland, TN",
            "slug": "82627a4d-828f-4cea-8570-2688c84e60f6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/30/15",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/lakeland/2961-canada-road-suite-111-112/",
        "index": 93.4,
        "franchise-entity-name": "CORE&amp;MORE, INC."
    },
    "5807613b-ffa3-49f7-b39a-517a83f6b8f0": {
        "name": "Orange Theory Lakeway, TX",
        "address": "1516 620 South Ste 100 Lakeway, Texas 78734",
        "phone": "(512) 430-1323",
        "email": "studiomanager0241@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:30PM",
            "SUNDAY": "7:15AM - 12:30PM"
        },
        "geolocation": [
            30.355011,
            -97.963539
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.76,
                    "duration": "4 Months",
                    "model_conf": 152.29
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.77,
                    "duration": "indefinitely",
                    "model_conf": 176.89
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.05,
                    "duration": "1 Months",
                    "model_conf": 109.99
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Lakeway, TX",
            "slug": "5807613b-ffa3-49f7-b39a-517a83f6b8f0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/20/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/lakeway/1516-620-south-ste-100/",
        "index": 108.325,
        "franchise-entity-name": "LAKEWAY FITNESS PARTNERS LLC"
    },
    "fd4b785a-2efd-4bd9-97eb-62c70a4d03e2": {
        "name": "Orange Theory Belmar, CO",
        "address": "7700 W Virginia Avenue, Ste A Lakewood, Colorado 80226",
        "phone": "(720) 402-3888",
        "email": "studiomanager0535@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            39.7067604,
            -105.0827
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.2,
                    "duration": "indefinitely",
                    "model_conf": 144.85
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.74,
                    "duration": "indefinitely",
                    "model_conf": 177.48
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.06,
                    "duration": "indefinitely",
                    "model_conf": 106.34
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Belmar, CO",
            "slug": "fd4b785a-2efd-4bd9-97eb-62c70a4d03e2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/15",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/lakewood/7700-w-virginia-avenue-ste-a/",
        "index": 98.425,
        "franchise-entity-name": "BMFIT LLC"
    },
    "f871e99d-7fab-4d7c-9ada-f35d5fe929b9": {
        "name": "Orange Theory Lakewood",
        "address": "14202 Detroit Rd Lakewood, Ohio 44107",
        "phone": "(216) 352-0782",
        "email": "studiomanager1269@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:15AM - 12:45PM",
            "SUNDAY": "6:15AM - 12:45PM"
        },
        "geolocation": [
            41.4859543,
            -81.792183
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.51,
                    "duration": "8 Months",
                    "model_conf": 152.71
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.67,
                    "duration": "indefinitely",
                    "model_conf": 185.38
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.5,
                    "duration": "1 Months",
                    "model_conf": 111.59
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lakewood",
            "slug": "f871e99d-7fab-4d7c-9ada-f35d5fe929b9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/11/19",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/lakewood/14202-detroit-rd/",
        "index": 93.4,
        "franchise-entity-name": "KSA5 LLC"
    },
    "547aa7b1-a10f-4f03-9cfe-81eb68f9284f": {
        "name": "Orange Theory University Place, WA",
        "address": "3626 Market Place West Suite 104 Lakewood, Washington 98466",
        "phone": "(360) 712-7565",
        "email": "studiomanager1076@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            47.2250938,
            -122.53661
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 7.9,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.4,
                    "duration": "2 Months",
                    "model_conf": 153.26
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 11.9,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.99,
                    "duration": "indefinitely",
                    "model_conf": 184.4
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 17.9,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.47,
                    "duration": "1 Months",
                    "model_conf": 112.05
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 22.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 37.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 49.9,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "University Place, WA",
            "slug": "547aa7b1-a10f-4f03-9cfe-81eb68f9284f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/2/19",
        "url": "https://www.orangetheory.com/en-us/locations/washington/lakewood/3626-market-place-west-suite-104/",
        "index": 98.425,
        "franchise-entity-name": "LAKEWOOD FITNESS, LLC"
    },
    "a41c6a0a-ba4b-457c-96e7-ade6a8cfb5fa": {
        "name": "Orange Theory Lancaster, PA",
        "address": "1655 Lititz Pike Lancaster, Pennsylvania 17601",
        "phone": "(717) 925-7884",
        "email": "studiomanager0951@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            40.0685501,
            -76.307411
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.96,
                    "duration": "indefinitely",
                    "model_conf": 153.53
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.8,
                    "duration": "indefinitely",
                    "model_conf": 185.83
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.79,
                    "duration": "indefinitely",
                    "model_conf": 112.03
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lancaster, PA",
            "slug": "a41c6a0a-ba4b-457c-96e7-ade6a8cfb5fa"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/12/18",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/lancaster/1655-lititz-pike/",
        "index": 93.4,
        "franchise-entity-name": "TC BUSINESS LANCASTER, LLC"
    },
    "31558489-b876-47ad-8871-561fbda4f499": {
        "name": "Orange Theory Lancaster West, PA",
        "address": "2963 Columbia Ave Lancaster, Pennsylvania 17603",
        "phone": "(717) 715-1030",
        "email": "studiomanager1357@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            40.0419083,
            -76.386574
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.96,
                    "duration": "indefinitely",
                    "model_conf": 153.5
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.8,
                    "duration": "indefinitely",
                    "model_conf": 185.83
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.78,
                    "duration": "indefinitely",
                    "model_conf": 112.02
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lancaster West, PA",
            "slug": "31558489-b876-47ad-8871-561fbda4f499"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/29/19",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/lancaster/2963-columbia-ave/",
        "index": 93.4,
        "franchise-entity-name": "TC Business Lancaster, LLC"
    },
    "6c1b1d80-4122-4d63-b94e-a8719e2f90f5": {
        "name": "Orange Theory Langhorne, PA",
        "address": "144 N Flowers Mill Rd Langhorne, Pennsylvania 19047",
        "phone": "(215) 839-1111",
        "email": "studiomanager0360@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.1798477,
            -74.907524
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.23,
                    "duration": "indefinitely",
                    "model_conf": 153.02
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.59,
                    "duration": "1 Months",
                    "model_conf": 183.66
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.28,
                    "duration": "indefinitely",
                    "model_conf": 110.68
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Langhorne, PA",
            "slug": "6c1b1d80-4122-4d63-b94e-a8719e2f90f5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/17/15",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/langhorne/144-n-flowers-mill-rd/",
        "index": 103.375,
        "franchise-entity-name": "LANGHORNE FITNESS PARTNERS, LLC"
    },
    "ab73589a-8a3c-4712-b7aa-81046a22a3c6": {
        "name": "Orange Theory Laredo, TX",
        "address": "3502 E. Del Mar, Suite 6 Laredo, Texas 78045",
        "phone": "(956) 231-0232",
        "email": "studiomanager0672@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            27.5836201,
            -99.448822
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.75
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.91
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Laredo, TX",
            "slug": "ab73589a-8a3c-4712-b7aa-81046a22a3c6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/7/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/laredo/3502-e-del-mar-suite-6/",
        "index": 93.4,
        "franchise-entity-name": "5A FITNESS LAREDO #1, L.L.C."
    },
    "7b3acc9d-4c1b-4a17-81f1-6f338ed3486e": {
        "name": "Orange Theory Largo, FL",
        "address": "10500 Ulmerton Road, Suite 220 Largo, Florida 33771",
        "phone": "(727) 400-3933",
        "email": "studiomanager0346@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 2:00PM",
            "SUNDAY": "7:00AM - 2:00PM"
        },
        "geolocation": [
            27.8922787,
            -82.785416
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.76,
                    "duration": "indefinitely",
                    "model_conf": 151.9
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.24,
                    "duration": "2 Months",
                    "model_conf": 169.33
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.68,
                    "duration": "indefinitely",
                    "model_conf": 110.99
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Largo, FL",
            "slug": "7b3acc9d-4c1b-4a17-81f1-6f338ed3486e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/24/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/largo/10500-ulmerton-road-suite-220/",
        "index": 98.425,
        "franchise-entity-name": "CONTRAILS FITNESS PARTNERS, LLC"
    },
    "e974250a-cd4d-49a9-85c9-6cfb7103ebba": {
        "name": "Orange Theory Las Vegas West Flamingo, NV",
        "address": "4245 South Grand Canyon Drive #109 Las Vegas, Nevada 89147",
        "phone": "(702) 420-2142",
        "email": "studiomanager0858@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            36.1122246,
            -115.3072
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.46,
                    "duration": "2 Months",
                    "model_conf": 146.3
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.54,
                    "duration": "indefinitely",
                    "model_conf": 180.66
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.78,
                    "duration": "indefinitely",
                    "model_conf": 111.21
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Las Vegas West Flamingo, NV",
            "slug": "e974250a-cd4d-49a9-85c9-6cfb7103ebba"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/26/17",
        "url": "https://www.orangetheory.com/en-us/locations/nevada/las-vegas/4245-south-grand-canyon-drive-109/",
        "index": 98.425,
        "franchise-entity-name": "LV FITNESS FLAMINGO, LLC"
    },
    "87769838-a7d7-4152-9566-09425ec6d3d7": {
        "name": "Orange Theory Las Vegas Spring Valley, NV",
        "address": "5990 S Rainbow Blvd, Suite 200 Las Vegas, Nevada 89118",
        "phone": "(702) 868-8300",
        "email": "studiomanager0728@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            36.0801163,
            -115.24223
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.82,
                    "duration": "5 Months",
                    "model_conf": 146.36
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.44,
                    "duration": "indefinitely",
                    "model_conf": 180.98
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.34,
                    "duration": "1 Months",
                    "model_conf": 111.21
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Las Vegas Spring Valley, NV",
            "slug": "87769838-a7d7-4152-9566-09425ec6d3d7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/17",
        "url": "https://www.orangetheory.com/en-us/locations/nevada/las-vegas/5990-s-rainbow-blvd-suite-200/",
        "index": 98.425,
        "franchise-entity-name": "OTLV2, LLC"
    },
    "7c036803-69e2-4bcc-876d-7670176a94b6": {
        "name": "Orange Theory Las Vegas Mountains Edge",
        "address": "8085 Blue Diamond Rd, Suite 108 Las Vegas, Nevada 89178",
        "phone": "(702) 660-9246",
        "email": "studiomanager0439@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            36.020092,
            -115.26768
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.29,
                    "duration": "3 Months",
                    "model_conf": 149.87
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.01,
                    "duration": "indefinitely",
                    "model_conf": 182.9
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.6,
                    "duration": "indefinitely",
                    "model_conf": 111.42
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Las Vegas Mountains Edge",
            "slug": "7c036803-69e2-4bcc-876d-7670176a94b6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/8/19",
        "url": "https://www.orangetheory.com/en-us/locations/nevada/las-vegas/8085-blue-diamond-road-suite-101/",
        "index": 98.425,
        "franchise-entity-name": "MOUNTAINS EDGE FITNESS LLC"
    },
    "ab050924-e40c-4212-bbce-af5fec9af7b0": {
        "name": "Orange Theory Las Vegas Centennial, NV",
        "address": "8431 W. Farm Rd., Suite 160 Las Vegas, Nevada 89131",
        "phone": "(702) 948-9029",
        "email": "studiomanager0440@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            36.2988396,
            -115.27924
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.55,
                    "duration": "indefinitely",
                    "model_conf": 152.31
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.5,
                    "duration": "1 Months",
                    "model_conf": 184.73
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.34,
                    "duration": "indefinitely",
                    "model_conf": 109.66
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Las Vegas Centennial, NV",
            "slug": "ab050924-e40c-4212-bbce-af5fec9af7b0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/26/18",
        "url": "https://www.orangetheory.com/en-us/locations/nevada/las-vegas/8431-w-farm-rd-suite-160/",
        "index": 98.425,
        "franchise-entity-name": "CENTENNIAL FITNESS LLC"
    },
    "42bb824b-5bf2-4cc2-b3ce-96dace482e9d": {
        "name": "Orange Theory Las Vegas - West Sahara, NV",
        "address": "9326 W. Sahara Ste. 4 Las Vegas, Nevada 89117",
        "phone": "(702) 577-2884",
        "email": "studiomanager0076@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            36.1455612,
            -115.29951
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.32,
                    "duration": "indefinitely",
                    "model_conf": 147.93
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.71,
                    "duration": "indefinitely",
                    "model_conf": 181.85
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.29,
                    "duration": "indefinitely",
                    "model_conf": 111.09
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Las Vegas - West Sahara, NV",
            "slug": "42bb824b-5bf2-4cc2-b3ce-96dace482e9d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/7/14",
        "url": "https://www.orangetheory.com/en-us/locations/nevada/las-vegas/9326-w-sahara-ste-4/",
        "index": 98.425,
        "franchise-entity-name": "LV FITNESS SUMMERLINLLC"
    },
    "950ab5bd-35d1-45fb-b3bb-4cdf1c0a8c01": {
        "name": "Orange Theory Latham, NY",
        "address": "800 Loudon Rd, Suite 900 Latham, New York 12110",
        "phone": "(518) 621-0553",
        "email": "studiomanager1221@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:50AM - 7:45PM",
            "TUESDAY": "4:50AM - 7:45PM",
            "WEDNESDAY": "4:50AM - 7:45PM",
            "THURSDAY": "4:50AM - 7:45PM",
            "FRIDAY": "4:50AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:30PM",
            "SUNDAY": "6:30AM - 12:30PM"
        },
        "geolocation": [
            42.7462235,
            -73.761345
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.45,
                    "duration": "indefinitely",
                    "model_conf": 146.72
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.19,
                    "duration": "5 Months",
                    "model_conf": 151.17
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.96,
                    "duration": "indefinitely",
                    "model_conf": 110.83
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Latham, NY",
            "slug": "950ab5bd-35d1-45fb-b3bb-4cdf1c0a8c01"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/5/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/latham/800-loudon-rd-suite-900/",
        "index": 98.425,
        "franchise-entity-name": "MJP FITNESS, LLC"
    },
    "69351322-2123-4d40-b93e-4b669a02549f": {
        "name": "Orange Theory LAWRENCE, KS",
        "address": "565 Wakarusa Dr, Suite C Lawrence, Kansas 66049",
        "phone": "(785) 727-4220",
        "email": "studiomanager1280@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            38.9720001,
            -95.307678
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 5.35,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.33,
                    "duration": "indefinitely",
                    "model_conf": 153.13
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 8.45,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.52,
                    "duration": "1 Months",
                    "model_conf": 185.53
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 13.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.19,
                    "duration": "indefinitely",
                    "model_conf": 111.69
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 15.42,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 27.82,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 38.67,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "LAWRENCE, KS",
            "slug": "69351322-2123-4d40-b93e-4b669a02549f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/kansas/lawrence/565-wakarusa-dr-suite-c/",
        "index": 93.4,
        "franchise-entity-name": "BOTK1, LLC"
    },
    "e351ad2c-b1f0-42f4-8e3d-24531b304779": {
        "name": "Orange Theory Webb Gin, GA",
        "address": "1250 Scenic Highway, Suite 1264 Lawrenceville, Georgia 30045",
        "phone": "(770) 450-1234",
        "email": "studiomanager0271@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            33.9006157,
            -84.004158
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.83,
                    "duration": "indefinitely",
                    "model_conf": 150.0
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.04,
                    "duration": "indefinitely",
                    "model_conf": 183.73
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.76,
                    "duration": "indefinitely",
                    "model_conf": 105.91
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Webb Gin, GA",
            "slug": "e351ad2c-b1f0-42f4-8e3d-24531b304779"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/13/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/lawrenceville/1250-scenic-highway-suite-1264/",
        "index": 98.425,
        "franchise-entity-name": "VVDC WEBB GIN, LLC"
    },
    "366a36c7-c042-4dd1-b9d1-7cae0ccb1aae": {
        "name": "Orange Theory Leander, TX",
        "address": "3421 N. Lakeline Blvd. Leander, Texas 78641",
        "phone": "(512) 807-0443",
        "email": "studiomanager0645@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:30PM",
            "SUNDAY": "7:30AM - 12:30PM"
        },
        "geolocation": [
            30.5417137,
            -97.863037
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.67,
                    "duration": "1 Months",
                    "model_conf": 149.65
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.54,
                    "duration": "indefinitely",
                    "model_conf": 178.39
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.74,
                    "duration": "indefinitely",
                    "model_conf": 110.64
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Leander, TX",
            "slug": "366a36c7-c042-4dd1-b9d1-7cae0ccb1aae"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/17/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/leander/3421-n-lakeline-blvd/",
        "index": 108.325,
        "franchise-entity-name": "MAXIMUM FITNESS, LEANDER LLC"
    },
    "1fc0bc00-316d-444a-a8cc-ef1a54c5ee83": {
        "name": "Orange Theory Lee&#39;s Summit, MO",
        "address": "910 NW Blue Parkway Suite A Lee&#39;S Summit, Missouri 64086",
        "phone": "(816) 944-3222",
        "email": "studiomanager0532@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "5:45AM - 12:15PM",
            "SUNDAY": "5:45AM - 12:15PM"
        },
        "geolocation": [
            38.9310989,
            -94.402008
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.04,
                    "duration": "indefinitely",
                    "model_conf": 152.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.09,
                    "duration": "indefinitely",
                    "model_conf": 184.12
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.98,
                    "duration": "indefinitely",
                    "model_conf": 110.25
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lee&#39;s Summit, MO",
            "slug": "1fc0bc00-316d-444a-a8cc-ef1a54c5ee83"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/4/18",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/lee-s-summit/910-nw-blue-parkway-suite-a/",
        "index": 93.4,
        "franchise-entity-name": "KC FITNESS 3, LLC"
    },
    "60d6b56d-b453-411f-a553-3fb8c5d4b847": {
        "name": "Orange Theory Leesburg, VA",
        "address": "545 East Market Street Suite A Leesburg, Virginia 20176",
        "phone": "(703) 646-7129",
        "email": "studiomanager1108@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "6:30AM - 12:15PM",
            "SUNDAY": "6:30AM - 12:15PM"
        },
        "geolocation": [
            39.107605,
            -77.551773
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.26,
                    "duration": "indefinitely",
                    "model_conf": 150.51
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.53,
                    "duration": "1 Months",
                    "model_conf": 182.24
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.91,
                    "duration": "indefinitely",
                    "model_conf": 110.47
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Leesburg, VA",
            "slug": "60d6b56d-b453-411f-a553-3fb8c5d4b847"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/27/18",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/leesburg/545-east-market-street-suite-a/",
        "index": 98.425,
        "franchise-entity-name": "NOVAFIT LLC"
    },
    "075c0b54-db84-4fc7-9dea-38a5f4a86670": {
        "name": "Orange Theory Lehi, UT",
        "address": "1536 E 3100 N #101 Lehi, Utah 84043",
        "phone": "(801) 653-9665",
        "email": "studiomanager0528@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 7:45PM",
            "TUESDAY": "4:55AM - 7:45PM",
            "WEDNESDAY": "4:55AM - 7:45PM",
            "THURSDAY": "4:55AM - 7:45PM",
            "FRIDAY": "4:55AM - 7:45PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            40.426716,
            -111.86717
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.54,
                    "duration": "indefinitely",
                    "model_conf": 151.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.06,
                    "duration": "6 Months",
                    "model_conf": 184.08
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.7,
                    "duration": "indefinitely",
                    "model_conf": 110.65
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lehi, UT",
            "slug": "075c0b54-db84-4fc7-9dea-38a5f4a86670"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/9/17",
        "url": "https://www.orangetheory.com/en-us/locations/utah/lehi/1536-e-3500-n-101/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS UTAH, LLC"
    },
    "8eebabef-db5b-437c-80cf-6b843ca102a0": {
        "name": "Orange Theory Lenexa, KS",
        "address": "17140 W 87th St Lenexa, Kansas 66219",
        "phone": "(913) 712-0505",
        "email": "studiomanager0950@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            38.9709129,
            -94.786095
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.46,
                    "duration": "indefinitely",
                    "model_conf": 146.95
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 11.28,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.39,
                    "duration": "2 Months",
                    "model_conf": 177.04
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 17.49,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.4,
                    "duration": "indefinitely",
                    "model_conf": 102.16
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 20.6,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 37.16,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 51.65,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lenexa, KS",
            "slug": "8eebabef-db5b-437c-80cf-6b843ca102a0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/15/17",
        "url": "https://www.orangetheory.com/en-us/locations/kansas/lenexa/17140-w-87th-st/",
        "index": 93.4,
        "franchise-entity-name": "KEYS TO FITNESS LLC"
    },
    "fa28ed72-88ef-4ef7-861b-0afb8a291a75": {
        "name": "Orange Theory Lewis Center, OH",
        "address": "6473 Pullman Dr Lewis Center, Ohio 43035",
        "phone": "(740) 777-9619",
        "email": "studiomanager0844@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.202179,
            -83.026527
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.08,
                    "duration": "indefinitely",
                    "model_conf": 149.43
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.15,
                    "duration": "3 Months",
                    "model_conf": 179.78
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.6,
                    "duration": "indefinitely",
                    "model_conf": 109.13
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lewis Center, OH",
            "slug": "fa28ed72-88ef-4ef7-861b-0afb8a291a75"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/9/17",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/lewis-center/6473-pullman-dr/",
        "index": 93.4,
        "franchise-entity-name": "YOUNG FITNESS 3, LLC"
    },
    "a3d79687-04da-4733-ae32-8f7963b3bbd8": {
        "name": "Orange Theory Castle Hills, TX",
        "address": "6200 N Josey Ln Suite #200 Lewisville, Texas 75056",
        "phone": "(972) 521-8404",
        "email": "studiomanager0320@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:30PM",
            "TUESDAY": "4:45AM - 7:30PM",
            "WEDNESDAY": "4:45AM - 7:30PM",
            "THURSDAY": "4:45AM - 7:30PM",
            "FRIDAY": "4:45AM - 7:30PM",
            "SATURDAY": "7:30AM - 2:30PM",
            "SUNDAY": "7:30AM - 2:30PM"
        },
        "geolocation": [
            33.065197,
            -96.88871
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.29,
                    "duration": "9 Months",
                    "model_conf": 143.11
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.99,
                    "duration": "indefinitely",
                    "model_conf": 173.14
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.03,
                    "duration": "2 Months",
                    "model_conf": 105.31
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Castle Hills, TX",
            "slug": "a3d79687-04da-4733-ae32-8f7963b3bbd8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/7/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/lewisville/6201-n-josey-ln-200/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA FITNESS - CASTLE HILLS, LLC"
    },
    "d90ea9a6-0dd3-4a57-bdeb-26d71fbf5568": {
        "name": "Orange Theory Lexington, KY",
        "address": "2369 Richmond Road, Suite #120 Lexington, Kentucky 40502",
        "phone": "(859) 898-1077",
        "email": "studiomanager0178@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:00AM - 1:45PM",
            "SUNDAY": "7:00AM - 1:45PM"
        },
        "geolocation": [
            38.0163536,
            -84.462547
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 4.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.38,
                    "duration": "3 Months",
                    "model_conf": 153.24
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 6.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.0,
                    "duration": "indefinitely",
                    "model_conf": 185.36
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.34,
                    "duration": "1 Months",
                    "model_conf": 105.28
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lexington, KY",
            "slug": "d90ea9a6-0dd3-4a57-bdeb-26d71fbf5568"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/21/15",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/lexington/2369-richmond-road-suite-120/",
        "index": 93.4,
        "franchise-entity-name": "DSC FITNESS LLC"
    },
    "570bf43c-50d5-4d85-9e6f-956a023fdcc0": {
        "name": "Orange Theory Lexington-Fountains, KY",
        "address": "3880 Fountain Blue Lane Lexington, Kentucky 40513",
        "phone": "(859) 300-0114",
        "email": "studiomanager1503@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            38.0021391,
            -84.573929
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 4.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.27,
                    "duration": "9 Months",
                    "model_conf": 153.23
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 6.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.85,
                    "duration": "indefinitely",
                    "model_conf": 185.39
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.76,
                    "duration": "2 Months",
                    "model_conf": 104.87
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lexington-Fountains, KY",
            "slug": "570bf43c-50d5-4d85-9e6f-956a023fdcc0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/21",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/lexington/3880-fountain-blue-ln/",
        "index": 93.4,
        "franchise-entity-name": "Fountains of Fitness LLC"
    },
    "74e6d62a-ba88-4125-9556-3229ccf2ff81": {
        "name": "Orange Theory Lexington KY, The Summit",
        "address": "4040 Finn Way Lexington, Kentucky 40517",
        "phone": "(859) 402-1020",
        "email": "studiomanager1086@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:25PM",
            "TUESDAY": "5:00AM - 7:25PM",
            "WEDNESDAY": "5:00AM - 7:25PM",
            "THURSDAY": "5:00AM - 7:25PM",
            "FRIDAY": "5:00AM - 7:25PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            37.9781647,
            -84.527382
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 4.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 66.88,
                    "duration": "12 Months",
                    "model_conf": 152.39
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 6.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 111.12,
                    "duration": "indefinitely",
                    "model_conf": 184.79
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.17,
                    "duration": "1 Months",
                    "model_conf": 93.81
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lexington KY, The Summit",
            "slug": "74e6d62a-ba88-4125-9556-3229ccf2ff81"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/23/18",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/lexington/4040-finn-way/",
        "index": 93.4,
        "franchise-entity-name": "THE SUMMIT OF FITNESS IN LEXINGTON LLC"
    },
    "104af994-b88a-49d1-8e0f-8e30a444cbaa": {
        "name": "Orange Theory Lexington, SC",
        "address": "5230 Sunset Blvd, Suite D Lexington, South Carolina 29072",
        "phone": "(803) 220-0080",
        "email": "studiomanager0883@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            34.0053635,
            -81.204567
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.92,
                    "duration": "indefinitely",
                    "model_conf": 152.49
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.4,
                    "duration": "indefinitely",
                    "model_conf": 185.4
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.88,
                    "duration": "indefinitely",
                    "model_conf": 111.5
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lexington, SC",
            "slug": "104af994-b88a-49d1-8e0f-8e30a444cbaa"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/16/17",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/lexington/5230-sunset-blvd-suite-d/",
        "index": 93.4,
        "franchise-entity-name": "JM LEX LLC"
    },
    "d90dcff6-236a-4b85-b532-cbe98d86df17": {
        "name": "Orange Theory Liberty, MO",
        "address": "117 S Stewart Rd Liberty, Missouri 64068",
        "phone": "(816) 368-1277",
        "email": "studiomanager0949@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "8:00AM - 8:00PM",
            "TUESDAY": "8:00AM - 8:00PM",
            "WEDNESDAY": "8:00AM - 8:00PM",
            "THURSDAY": "8:00AM - 8:00PM",
            "FRIDAY": "8:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            39.2433815,
            -94.453514
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.08,
                    "duration": "10 Months",
                    "model_conf": 153.62
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 111.42,
                    "duration": "indefinitely",
                    "model_conf": 185.35
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.83,
                    "duration": "2 Months",
                    "model_conf": 111.78
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Liberty, MO",
            "slug": "d90dcff6-236a-4b85-b532-cbe98d86df17"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/19/18",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/liberty/117-s-stewart-rd/",
        "index": 93.4,
        "franchise-entity-name": "HIIT LIBERTY, L.L.C."
    },
    "c6b4bb69-2fc3-4124-8fb6-cab3ad433635": {
        "name": "Orange Theory Pompano Beach, FL",
        "address": "3778 N Federal Hwy. Lighthouse Point, Florida 33062",
        "phone": "(954) 246-4735",
        "email": "studiomanager0006@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:00PM",
            "TUESDAY": "5:30AM - 7:00PM",
            "WEDNESDAY": "5:30AM - 7:00PM",
            "THURSDAY": "5:30AM - 7:00PM",
            "FRIDAY": "5:30AM - 7:00PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            26.277647,
            -80.097
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.37,
                    "duration": "4 Months",
                    "model_conf": 143.23
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.83,
                    "duration": "indefinitely",
                    "model_conf": 180.7
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.09,
                    "duration": "indefinitely",
                    "model_conf": 106.23
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Pompano Beach, FL",
            "slug": "c6b4bb69-2fc3-4124-8fb6-cab3ad433635"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/28/12",
        "url": "https://www.orangetheory.com/en-us/locations/florida/lighthouse-point/3778-n-federal-hwy/",
        "index": 88.45,
        "franchise-entity-name": "OTF STUDIOS, LLC"
    },
    "2ef9aca7-8deb-471b-8066-e044fc86e2d9": {
        "name": "Orange Theory Lincoln - Preserve",
        "address": "7811 Pioneers Blvd Lincoln, Nebraska 68506",
        "phone": "(402) 480-6292",
        "email": "studiomanager1371@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 11:45AM",
            "SUNDAY": "6:00AM - 11:45AM"
        },
        "geolocation": [
            40.7691917,
            -96.613838
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.78,
                    "duration": "1 Months",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.63,
                    "duration": "indefinitely",
                    "model_conf": 185.81
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.84,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lincoln - Preserve",
            "slug": "2ef9aca7-8deb-471b-8066-e044fc86e2d9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/3/20",
        "url": "https://www.orangetheory.com/en-us/locations/nebraska/lincoln/7811-pioneers-blvd/",
        "index": 93.4,
        "franchise-entity-name": "Lincoln Burn 2, LLC"
    },
    "d455ab08-3b7c-4e3e-8fb7-bbda9478e145": {
        "name": "Orange Theory Lincoln - Wilderness Hills",
        "address": "8601 S 30th St. Lincoln, Nebraska 68516",
        "phone": "(402) 480-6450",
        "email": "studiomanager0946@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 11:45AM",
            "SUNDAY": "7:15AM - 11:45AM"
        },
        "geolocation": [
            40.7255135,
            -96.678459
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.03,
                    "duration": "indefinitely",
                    "model_conf": 153.81
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.34,
                    "duration": "indefinitely",
                    "model_conf": 185.81
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.99,
                    "duration": "indefinitely",
                    "model_conf": 112.02
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Lincoln - Wilderness Hills",
            "slug": "d455ab08-3b7c-4e3e-8fb7-bbda9478e145"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/15/18",
        "url": "https://www.orangetheory.com/en-us/locations/nebraska/lincoln/8601-s-30th-st/",
        "index": 93.4,
        "franchise-entity-name": "LINCOLN BURN 1, LLC"
    },
    "61be117e-4d10-44d7-9866-fb8153bd5f7e": {
        "name": "Orange Theory Little Rock West, AR",
        "address": "16900 Chenal Parkway, Suite 110 Little Rock, Arkansas 72223",
        "phone": "(501) 817-3166",
        "email": "studiomanager0404@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 3:45PM",
            "SUNDAY": "8:00AM - 3:45PM"
        },
        "geolocation": [
            34.7616196,
            -92.45414
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.01,
                    "duration": "indefinitely",
                    "model_conf": 152.55
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.87,
                    "duration": "indefinitely",
                    "model_conf": 184.81
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.25,
                    "duration": "indefinitely",
                    "model_conf": 111.89
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Little Rock West, AR",
            "slug": "61be117e-4d10-44d7-9866-fb8153bd5f7e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/4/15",
        "url": "https://www.orangetheory.com/en-us/locations/arkansas/little-rock/16900-chenal-parkway-suite-110/",
        "index": 93.4,
        "franchise-entity-name": "AR HIIT, LLC"
    },
    "0ffd56b6-30f2-44f2-a19a-69405c4a12d3": {
        "name": "Orange Theory Little Rock Mid-Town, AR",
        "address": "6831 Cantrell Road Little Rock, Arkansas 72207",
        "phone": "",
        "email": "studiomanager0695@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            34.7679443,
            -92.351387
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.65,
                    "duration": "indefinitely",
                    "model_conf": 152.87
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.05,
                    "duration": "indefinitely",
                    "model_conf": 185.07
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.08,
                    "duration": "indefinitely",
                    "model_conf": 111.94
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Little Rock Mid-Town, AR",
            "slug": "0ffd56b6-30f2-44f2-a19a-69405c4a12d3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/23/16",
        "url": "https://www.orangetheory.com/en-us/locations/arkansas/little-rock/6831-cantrell-road/",
        "index": 93.4,
        "franchise-entity-name": "AR HIIT, LLC"
    },
    "42071a66-054f-4653-af04-3b2b330f0b98": {
        "name": "Orange Theory Littleton, CO",
        "address": "8555 W Belleview Ave. Littleton, Colorado 80123",
        "phone": "(303) 979-6878",
        "email": "studiomanager0052@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            39.6250954,
            -105.09266
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.45,
                    "duration": "indefinitely",
                    "model_conf": 146.65
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.42,
                    "duration": "1 Months",
                    "model_conf": 175.99
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.35,
                    "duration": "indefinitely",
                    "model_conf": 106.74
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Littleton, CO",
            "slug": "42071a66-054f-4653-af04-3b2b330f0b98"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/littleton/8555-w-belleview-ave/",
        "index": 98.425,
        "franchise-entity-name": "N2 FITNESS LLC"
    },
    "3d37774c-c3ab-45dc-9924-d8cce546ad04": {
        "name": "Orange Theory Livermore",
        "address": "1518 First St. Livermore, California 94550",
        "phone": "(925) 621-0987",
        "email": "studiomanager0457@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            37.6802445,
            -121.77778
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.3,
                    "duration": "indefinitely",
                    "model_conf": 153.14
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.01,
                    "duration": "2 Months",
                    "model_conf": 184.0
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.17,
                    "duration": "indefinitely",
                    "model_conf": 110.89
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Livermore",
            "slug": "3d37774c-c3ab-45dc-9924-d8cce546ad04"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/26/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/livermore/1518-first-st/",
        "index": 108.325,
        "franchise-entity-name": "TKV2, LLC"
    },
    "c56a87fa-418c-4b0f-acd5-d2caae3ba468": {
        "name": "Orange Theory Syracuse-Clay",
        "address": "3881 Route 31, Suite 400 Liverpool, New York 13090",
        "phone": "(315) 930-4424",
        "email": "studiomanager0835@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            43.1860313,
            -76.244591
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.72,
                    "duration": "indefinitely",
                    "model_conf": 150.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.05,
                    "duration": "3 Months",
                    "model_conf": 184.81
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.4,
                    "duration": "indefinitely",
                    "model_conf": 111.6
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Syracuse-Clay",
            "slug": "c56a87fa-418c-4b0f-acd5-d2caae3ba468"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/27/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/liverpool/3881-route-31-suite-400/",
        "index": 98.425,
        "franchise-entity-name": "NORTH SYRACUSE FITNESS, LLC"
    },
    "62d41501-8214-45f3-bc17-95f2158199c1": {
        "name": "Orange Theory Lombard - Yorktown Center",
        "address": "202 Yorktown Center Drive Lombard, Illinois 60148",
        "phone": "(630) 451-9960",
        "email": "studiomanager0985@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            41.839579,
            -88.006103
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.07,
                    "duration": "4 Months",
                    "model_conf": 97.83
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.83,
                    "duration": "indefinitely",
                    "model_conf": 118.22
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.01,
                    "duration": "2 Months",
                    "model_conf": 73.31
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Lombard - Yorktown Center",
            "slug": "62d41501-8214-45f3-bc17-95f2158199c1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/19/18",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/lombard/202-yorktown-shopping-center/",
        "index": 103.375,
        "franchise-entity-name": "PROVIDENCE VENTURE STUDIO #4 LLC"
    },
    "142361b9-3b50-412a-8339-e86b590465f6": {
        "name": "Orange Theory Londonderry, NH",
        "address": "30 Main St. Londonderry, New Hampshire 03053",
        "phone": "(603) 434-6833",
        "email": "studiomanager0959@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            42.8727608,
            -71.348137
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.31,
                    "duration": "indefinitely",
                    "model_conf": 152.82
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.97,
                    "duration": "indefinitely",
                    "model_conf": 185.54
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.97,
                    "duration": "indefinitely",
                    "model_conf": 111.94
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Londonderry, NH",
            "slug": "142361b9-3b50-412a-8339-e86b590465f6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/29/20",
        "url": "https://www.orangetheory.com/en-us/locations/new-hampshire/londonderry/30-main-st/",
        "index": 98.425,
        "franchise-entity-name": "FYF FITNESS, LLC"
    },
    "e4f705a5-0622-42a9-8d07-24d940033ca7": {
        "name": "Orange Theory Lone Tree, CO",
        "address": "9090 Sky Ridge Avenue Lone Tree, Colorado 80124",
        "phone": "(970) 829-0081",
        "email": "studiomanager1115@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:15PM",
            "TUESDAY": "5:15AM - 8:15PM",
            "WEDNESDAY": "5:15AM - 8:15PM",
            "THURSDAY": "5:15AM - 8:15PM",
            "FRIDAY": "5:15AM - 8:15PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            39.533546,
            -104.88457
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.22,
                    "duration": "indefinitely",
                    "model_conf": 129.01
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.7,
                    "duration": "indefinitely",
                    "model_conf": 156.1
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.25,
                    "duration": "indefinitely",
                    "model_conf": 94.28
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lone Tree, CO",
            "slug": "e4f705a5-0622-42a9-8d07-24d940033ca7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/13/22",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/lone-tree/9090-skyridge-avenue/",
        "index": 98.425,
        "franchise-entity-name": "WSH Lone Tree, LLC"
    },
    "12c01827-5d8d-4eef-bb84-37ac34de4dd1": {
        "name": "Orange Theory Long Beach - Lakewood, CA",
        "address": "3850 Worsham Avenue, Suite 420 Long Beach, California 90712",
        "phone": "(562) 731-0077",
        "email": "studiomanager0501@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 1:20PM",
            "SUNDAY": "6:00AM - 1:20PM"
        },
        "geolocation": [
            33.8293533,
            -118.1455
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.02,
                    "duration": "indefinitely",
                    "model_conf": 148.68
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.23,
                    "duration": "indefinitely",
                    "model_conf": 175.05
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.01,
                    "duration": "indefinitely",
                    "model_conf": 103.92
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Long Beach - Lakewood, CA",
            "slug": "12c01827-5d8d-4eef-bb84-37ac34de4dd1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/6/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/long-beach/3850-worsham-avenue-suite-420/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT LBX LLC"
    },
    "922229c5-e558-4e46-b15d-cefb56219f97": {
        "name": "Orange Theory Long Beach - Marina, CA",
        "address": "6620 E Pacific Coast Hwy Long Beach, California 90803",
        "phone": "(562) 475-4788",
        "email": "studiomanager0252@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            33.7540855,
            -118.1089
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.5,
                    "duration": "indefinitely",
                    "model_conf": 151.53
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.55,
                    "duration": "1 Months",
                    "model_conf": 178.91
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.08,
                    "duration": "indefinitely",
                    "model_conf": 106.63
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Long Beach - Marina, CA",
            "slug": "922229c5-e558-4e46-b15d-cefb56219f97"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/6/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/long-beach/6620-e-pacific-coast-hwy/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT LB, LLC"
    },
    "fa5065a8-6dd0-4de1-9349-3b24184585e7": {
        "name": "Orange Theory Long Island City",
        "address": "21-10 44th Drive Long Island City, New York 11101",
        "phone": "(718) 881-8111",
        "email": "studiomanager1334@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:35PM",
            "TUESDAY": "6:00AM - 8:35PM",
            "WEDNESDAY": "6:00AM - 8:35PM",
            "THURSDAY": "6:00AM - 8:35PM",
            "FRIDAY": "6:00AM - 8:35PM",
            "SATURDAY": "7:15AM - 1:35PM",
            "SUNDAY": "7:15AM - 1:35PM"
        },
        "geolocation": [
            40.747776,
            -73.94696
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.83,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.72,
                    "duration": "indefinitely",
                    "model_conf": 88.97
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.78,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.06,
                    "duration": "2 Months",
                    "model_conf": 107.31
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 9.7,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.56,
                    "duration": "indefinitely",
                    "model_conf": 66.69
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 12.19,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 21.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 29.2,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Long Island City",
            "slug": "fa5065a8-6dd0-4de1-9349-3b24184585e7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/18/20",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/long-island-city/21-10-44th-drive/",
        "index": 108.325,
        "franchise-entity-name": "92 Fitness Crew NY6, LLC"
    },
    "72fe4d10-f1af-49e5-a78f-3f3ed1aac14d": {
        "name": "Orange Theory Patchogue",
        "address": "392 West Sunrise Highway Long Island, New York 11772",
        "phone": "(631) 562-5625",
        "email": "studiomanager1006@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:15PM",
            "TUESDAY": "5:00AM - 9:15PM",
            "WEDNESDAY": "5:00AM - 9:15PM",
            "THURSDAY": "5:00AM - 9:15PM",
            "FRIDAY": "5:00AM - 9:15PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            40.7770042,
            -73.036461
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.43,
                    "duration": "indefinitely",
                    "model_conf": 151.91
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.4,
                    "duration": "1 Months",
                    "model_conf": 183.41
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.8,
                    "duration": "indefinitely",
                    "model_conf": 110.41
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Patchogue",
            "slug": "72fe4d10-f1af-49e5-a78f-3f3ed1aac14d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/11/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/long-island/392-west-sunrise-highway/",
        "index": 108.325,
        "franchise-entity-name": "PA FITNESS LLC"
    },
    "f77f42fa-d11e-4f88-bc6a-a020612dfa3b": {
        "name": "Orange Theory Century City, CA",
        "address": "10250 Santa Monica Blvd. Suite 1420 Los Angeles, California 90067",
        "phone": "(310) 405-0009",
        "email": "studiomanager0522@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "7:00AM - 8:15PM",
            "TUESDAY": "7:00AM - 8:15PM",
            "WEDNESDAY": "7:00AM - 8:15PM",
            "THURSDAY": "7:00AM - 8:15PM",
            "FRIDAY": "7:00AM - 8:15PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            34.0590019,
            -118.41887
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.89,
                    "duration": "indefinitely",
                    "model_conf": 150.47
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 147.6,
                    "duration": "3 Months",
                    "model_conf": 182.82
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.02,
                    "duration": "indefinitely",
                    "model_conf": 109.8
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Century City, CA",
            "slug": "f77f42fa-d11e-4f88-bc6a-a020612dfa3b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/5/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/los-angeles/10250-santa-monica-blvd-suite-1420/",
        "index": 111.325,
        "franchise-entity-name": "CENTURY CITY FITNESS LLC"
    },
    "c879e8f4-6b7a-415a-827a-5f96a92d545e": {
        "name": "Orange Theory South Park",
        "address": "1120 South Grand Avenue Suite 102 Los Angeles, California 90015",
        "phone": "(213) 483-0800",
        "email": "studiomanager1123@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:00PM",
            "TUESDAY": "5:30AM - 8:00PM",
            "WEDNESDAY": "5:30AM - 8:00PM",
            "THURSDAY": "5:30AM - 8:00PM",
            "FRIDAY": "5:30AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            34.0404587,
            -118.26244
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.15,
                    "duration": "indefinitely",
                    "model_conf": 150.65
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.79,
                    "duration": "indefinitely",
                    "model_conf": 180.57
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.11,
                    "duration": "indefinitely",
                    "model_conf": 108.62
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "South Park",
            "slug": "c879e8f4-6b7a-415a-827a-5f96a92d545e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/7/20",
        "url": "https://www.orangetheory.com/en-us/locations/california/southpark-la/1120-south-grand-street-suite-3/",
        "index": 111.325,
        "franchise-entity-name": "Southpark Fit, LLC"
    },
    "287386af-6673-489d-8ca2-73018e2c8abc": {
        "name": "Orange Theory Los Angeles - Brentwood, CA",
        "address": "11661 San Vicente Blvd. Suite 103 Los Angeles, California 90049",
        "phone": "(310) 694-5656",
        "email": "studiomanager0120@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:45PM",
            "TUESDAY": "5:30AM - 7:45PM",
            "WEDNESDAY": "5:30AM - 7:45PM",
            "THURSDAY": "5:30AM - 7:45PM",
            "FRIDAY": "5:30AM - 7:45PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            34.0542793,
            -118.46436
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.7,
                    "duration": "indefinitely",
                    "model_conf": 151.5
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 147.44,
                    "duration": "4 Months",
                    "model_conf": 183.86
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.91,
                    "duration": "indefinitely",
                    "model_conf": 110.68
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Los Angeles - Brentwood, CA",
            "slug": "287386af-6673-489d-8ca2-73018e2c8abc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/6/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/los-angeles/11661-san-vicente-boulevard-suite-103/",
        "index": 111.325,
        "franchise-entity-name": "WCF BRENTWOOD LLC"
    },
    "2fcdef47-34df-483b-b921-cd2b830da8f0": {
        "name": "Orange Theory Mar Vista, CA",
        "address": "11924 W Washington Blvd Los Angeles, California 90066",
        "phone": "(323) 426-4188",
        "email": "studiomanager1411@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:45AM - 8:00PM",
            "TUESDAY": "5:45AM - 8:00PM",
            "WEDNESDAY": "5:45AM - 8:00PM",
            "THURSDAY": "5:45AM - 8:00PM",
            "FRIDAY": "5:45AM - 8:00PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            33.997567,
            -118.42118
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.83,
                    "duration": "indefinitely",
                    "model_conf": 148.64
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.01,
                    "duration": "2 Months",
                    "model_conf": 180.99
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.0,
                    "duration": "indefinitely",
                    "model_conf": 107.99
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Mar Vista, CA",
            "slug": "2fcdef47-34df-483b-b921-cd2b830da8f0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/14/22",
        "url": "https://www.orangetheory.com/en-us/locations/california/los-angeles/11942-w-washington-blvd/",
        "index": 111.325,
        "franchise-entity-name": "WCF MAR VISTA LLC"
    },
    "a0e8dfe3-ce78-4ad5-89b4-d3e84d6e46cb": {
        "name": "Orange Theory Downtown LA",
        "address": "515 S Figueroa St. Suite 102 Los Angeles, California 90071",
        "phone": "(213) 279-2900",
        "email": "studiomanager0630@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:30PM",
            "TUESDAY": "5:30AM - 7:30PM",
            "WEDNESDAY": "5:30AM - 7:30PM",
            "THURSDAY": "5:30AM - 7:30PM",
            "FRIDAY": "5:30AM - 7:30PM",
            "SATURDAY": "8:00AM - 12:00PM",
            "SUNDAY": "8:00AM - 12:00PM"
        },
        "geolocation": [
            34.0521736,
            -118.2579
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.02,
                    "duration": "indefinitely",
                    "model_conf": 150.84
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.97,
                    "duration": "indefinitely",
                    "model_conf": 180.56
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.15,
                    "duration": "indefinitely",
                    "model_conf": 108.43
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Downtown LA",
            "slug": "a0e8dfe3-ce78-4ad5-89b4-d3e84d6e46cb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/downtown-la/515-south-figueroa-street/",
        "index": 111.325,
        "franchise-entity-name": "FITNESS FANATICS DOWNTOWN LA, LLC"
    },
    "7749acb3-1ca6-4064-a22d-cd3edeea1216": {
        "name": "Orange Theory Hollywood, CA",
        "address": "5524 Hollywood Blvd Los Angeles, California 90028",
        "phone": "(323) 515-1002",
        "email": "studiomanager1480@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            34.101565,
            -118.31002
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 100.3,
                    "duration": "indefinitely",
                    "model_conf": 151.35
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 147.43,
                    "duration": "4 Months",
                    "model_conf": 182.05
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 210.22,
                    "duration": "indefinitely",
                    "model_conf": 109.07
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Hollywood, CA",
            "slug": "7749acb3-1ca6-4064-a22d-cd3edeea1216"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/18/23",
        "url": "https://www.orangetheory.com/en-us/locations/california/los-angeles/5524-hollywood-blvd/",
        "index": 111.325,
        "franchise-entity-name": "WCF HOLLYWOOD LLC"
    },
    "2ae9d9fd-9fb7-4041-90f3-2800a30e8618": {
        "name": "Orange Theory Melrose, CA",
        "address": "640 N. La Brea Ave Los Angeles, California 90038",
        "phone": "(323) 450-2200",
        "email": "studiomanager0476@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 5:30PM",
            "TUESDAY": "5:30AM - 5:30PM",
            "WEDNESDAY": "5:30AM - 5:30PM",
            "THURSDAY": "5:30AM - 5:30PM",
            "FRIDAY": "5:30AM - 5:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            34.0830612,
            -118.34406
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.94,
                    "duration": "indefinitely",
                    "model_conf": 150.89
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.08,
                    "duration": "2 Months",
                    "model_conf": 182.16
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.99,
                    "duration": "indefinitely",
                    "model_conf": 109.36
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Melrose, CA",
            "slug": "2ae9d9fd-9fb7-4041-90f3-2800a30e8618"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/6/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/los-angeles/640-n-la-brea-ave/",
        "index": 111.325,
        "franchise-entity-name": "WCF MELROSE CROSSING, LLC"
    },
    "68b8b4f7-2833-4e6e-bbc8-d4dfd53c1d32": {
        "name": "Orange Theory Koreatown",
        "address": "687 Hobart Blvd Suite B Los Angeles, California 90005",
        "phone": "(213) 943-3007",
        "email": "studiomanager1063@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:45PM",
            "TUESDAY": "5:30AM - 8:45PM",
            "WEDNESDAY": "5:30AM - 8:45PM",
            "THURSDAY": "5:30AM - 8:45PM",
            "FRIDAY": "5:30AM - 8:45PM",
            "SATURDAY": "7:45AM - 12:30PM",
            "SUNDAY": "7:45AM - 12:30PM"
        },
        "geolocation": [
            34.0611916,
            -118.30574
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.49,
                    "duration": "indefinitely",
                    "model_conf": 150.86
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.96,
                    "duration": "indefinitely",
                    "model_conf": 181.44
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.82,
                    "duration": "indefinitely",
                    "model_conf": 109.11
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Koreatown",
            "slug": "68b8b4f7-2833-4e6e-bbc8-d4dfd53c1d32"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/20",
        "url": "https://www.orangetheory.com/en-us/locations/california/los-angeles/687-hobart-blvd/",
        "index": 111.325,
        "franchise-entity-name": "REDONDO FITNESS AND/OR ITS LLC ASSIGNEE"
    },
    "4497f518-954c-449f-bff8-311219c70aad": {
        "name": "Orange Theory Los Gatos, CA",
        "address": "16250 Los Gatos Blvd. Los Gatos, California 95032",
        "phone": "(408) 384-4220",
        "email": "studiomanager0354@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "8:15AM - 11:45PM",
            "SUNDAY": "8:15AM - 11:45PM"
        },
        "geolocation": [
            37.2326126,
            -121.96407
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.05,
                    "duration": "indefinitely",
                    "model_conf": 153.53
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.54,
                    "duration": "indefinitely",
                    "model_conf": 180.3
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.84,
                    "duration": "indefinitely",
                    "model_conf": 111.37
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Los Gatos, CA",
            "slug": "4497f518-954c-449f-bff8-311219c70aad"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/17/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/los-gatos/16250-los-gatos-blvd/",
        "index": 111.325,
        "franchise-entity-name": "MKS PARTNERS THREE, LLC"
    },
    "d9395d37-39ca-4358-aba3-ccb82963109f": {
        "name": "Orange Theory Louisville - St. Matthews, KY",
        "address": "116 Sears Avenue Louisville, Kentucky 40207",
        "phone": "(502) 259-9199",
        "email": "studiomanager0171@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:50PM",
            "TUESDAY": "5:00AM - 6:50PM",
            "WEDNESDAY": "5:00AM - 6:50PM",
            "THURSDAY": "5:00AM - 6:50PM",
            "FRIDAY": "5:00AM - 6:50PM",
            "SATURDAY": "Closed",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            38.2530937,
            -85.647003
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 4.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.21,
                    "duration": "4 Months",
                    "model_conf": 125.31
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 6.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.79,
                    "duration": "indefinitely",
                    "model_conf": 169.61
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.21,
                    "duration": "1 Months",
                    "model_conf": 110.88
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Louisville - St. Matthews, KY",
            "slug": "d9395d37-39ca-4358-aba3-ccb82963109f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/9/14",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/louisville/116-sears-avenue/",
        "index": 93.4,
        "franchise-entity-name": "DAVIS FITNESS STUDIO I INC."
    },
    "79845cf3-aa17-430f-98a0-c12b396d316b": {
        "name": "Orange Theory Louisville-Middletown, KY",
        "address": "13317 Shelbyville Road, Unit #103 Louisville, Kentucky 40223",
        "phone": "(502) 242-3385",
        "email": "studiomanager0399@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "8:15AM - 1:15PM",
            "SUNDAY": "8:15AM - 1:15PM"
        },
        "geolocation": [
            38.2424583,
            -85.507256
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 4.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.86,
                    "duration": "indefinitely",
                    "model_conf": 136.95
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 6.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.36,
                    "duration": "indefinitely",
                    "model_conf": 174.8
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.01,
                    "duration": "indefinitely",
                    "model_conf": 111.19
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Louisville-Middletown, KY",
            "slug": "79845cf3-aa17-430f-98a0-c12b396d316b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/31/15",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/louisville/13317-shelbyville-road-unit-103/",
        "index": 93.4,
        "franchise-entity-name": "DAVIS FITNESS STUDIO II INC."
    },
    "d1fd0ac0-1330-40ff-ae94-0fc479b77cb2": {
        "name": "Orange Theory Louisville - Highlands, KY",
        "address": "918 Baxter Ave. Louisville, Kentucky 40204",
        "phone": "(502) 968-1400",
        "email": "studiomanager0868@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "8:15AM - 1:00PM",
            "SUNDAY": "8:15AM - 1:00PM"
        },
        "geolocation": [
            38.2418098,
            -85.72599
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 4.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.11,
                    "duration": "indefinitely",
                    "model_conf": 146.93
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 6.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.53,
                    "duration": "1 Months",
                    "model_conf": 181.53
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.17,
                    "duration": "indefinitely",
                    "model_conf": 111.38
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Louisville - Highlands, KY",
            "slug": "d1fd0ac0-1330-40ff-ae94-0fc479b77cb2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/16/17",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/louisville/918-baxter-ave/",
        "index": 93.4,
        "franchise-entity-name": "DAVIS FITNESS STUDIO III INC"
    },
    "848fb7e2-fc40-4f33-a7fe-9126343623be": {
        "name": "Orange Theory Lubbock - North",
        "address": "4410 19th St Unit 120 Lubbock North, Texas 79407",
        "phone": "(806) 785-5400",
        "email": "studiomanager0863@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            33.5784645,
            -101.90664
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.16,
                    "duration": "indefinitely",
                    "model_conf": 152.59
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.94,
                    "duration": "indefinitely",
                    "model_conf": 184.61
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.55,
                    "duration": "1 Months",
                    "model_conf": 111.82
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Lubbock - North",
            "slug": "848fb7e2-fc40-4f33-a7fe-9126343623be"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/26/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/lubbock-north/4410-19th-st-unit-120/",
        "index": 88.45,
        "franchise-entity-name": "INTERVAL FITNESS OF SAN ANGELO, LLC"
    },
    "961b30db-e79c-40ed-845e-43e28eebdd9c": {
        "name": "Orange Theory Lubbock, TX",
        "address": "5214 98th Street, Suite 300 Lubbock, Texas 79424",
        "phone": "(806) 749-1200",
        "email": "studiomanager0301@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            33.5057106,
            -101.92406
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.1,
                    "duration": "indefinitely",
                    "model_conf": 151.38
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.84,
                    "duration": "indefinitely",
                    "model_conf": 183.44
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.67,
                    "duration": "indefinitely",
                    "model_conf": 111.59
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Lubbock, TX",
            "slug": "961b30db-e79c-40ed-845e-43e28eebdd9c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/19/14",
        "url": "https://www.orangetheory.com/en-us/locations/texas/lubbock/5214-98th-street-suite-300/",
        "index": 88.45,
        "franchise-entity-name": "I AM SECOND, INC."
    },
    "a7eeff1e-7674-4313-bd5e-50644f1ece1a": {
        "name": "Orange Theory Lynnwood, WA",
        "address": "19723 Highway 99 Suite L Lynwood, Washington 98036",
        "phone": "(425) 224-3637",
        "email": "studiomanager0585@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 1:00PM",
            "SUNDAY": "8:00AM - 1:00PM"
        },
        "geolocation": [
            47.8193436,
            -122.31567
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.4,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.79,
                    "duration": "indefinitely",
                    "model_conf": 151.77
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.6,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.43,
                    "duration": "indefinitely",
                    "model_conf": 181.81
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.9,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.53,
                    "duration": "indefinitely",
                    "model_conf": 107.68
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 26.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 47.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 62.9,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Lynnwood, WA",
            "slug": "a7eeff1e-7674-4313-bd5e-50644f1ece1a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/3/18",
        "url": "https://www.orangetheory.com/en-us/locations/washington/lynwood/19723-highway-99-suite-l/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF LYNNWOOD, LLC"
    },
    "fac09035-87b1-435c-a0d5-8479b9db9d6e": {
        "name": "Orange Theory Missoula, MT",
        "address": "3624 Brooks Street, Space 1014 Missoula, Montana 59801",
        "phone": "(406) 552-1510",
        "email": "studiomanager0707@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            46.8371429,
            -114.03611
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.54,
                    "duration": "3 Months",
                    "model_conf": 150.09
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.69,
                    "duration": "indefinitely",
                    "model_conf": 182.8
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.26,
                    "duration": "1 Months",
                    "model_conf": 112.13
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Missoula, MT",
            "slug": "fac09035-87b1-435c-a0d5-8479b9db9d6e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/10/17",
        "url": "https://www.orangetheory.com/en-us/locations/montana/missoula/3624-brooks-street-space-1014/",
        "index": 88.45,
        "franchise-entity-name": "RIVER CITY FITNESS, LLC"
    },
    "1ff3b38b-cd4f-47bb-88d6-d8334703305a": {
        "name": "Orange Theory Mableton, GA",
        "address": "1025 Veterans Memorial Hwy, Suite 610 Mableton, Georgia 30126",
        "phone": "(678) 368-4444",
        "email": "studiomanager1528@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:15PM",
            "TUESDAY": "5:00AM - 6:15PM",
            "WEDNESDAY": "5:00AM - 6:15PM",
            "THURSDAY": "5:00AM - 6:15PM",
            "FRIDAY": "5:00AM - 6:15PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            33.805789,
            -84.525521
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.83,
                    "duration": "indefinitely",
                    "model_conf": 147.73
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.16,
                    "duration": "indefinitely",
                    "model_conf": 180.97
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.42,
                    "duration": "1 Months",
                    "model_conf": 108.6
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Mableton, GA",
            "slug": "1ff3b38b-cd4f-47bb-88d6-d8334703305a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/5/22",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/mableton/1025-veterans-memorial-hwy-suite-610/",
        "index": 98.425,
        "franchise-entity-name": "JM MABLETON LLC"
    },
    "38564330-a46e-4142-9e78-4b5674f9b6ea": {
        "name": "Orange Theory Macedonia, OH",
        "address": "746 E Aurora Rd Ste 4 Macedonia, Ohio 44056",
        "phone": "(216) 245-3327",
        "email": "studiomanager1336@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "6:45AM - 11:15AM",
            "SUNDAY": "6:45AM - 11:15AM"
        },
        "geolocation": [
            41.3134003,
            -81.51207
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.06,
                    "duration": "indefinitely",
                    "model_conf": 152.39
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.23,
                    "duration": "indefinitely",
                    "model_conf": 184.52
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.59,
                    "duration": "indefinitely",
                    "model_conf": 109.87
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Macedonia, OH",
            "slug": "38564330-a46e-4142-9e78-4b5674f9b6ea"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/macedonia/746-e-aurora-rd-ste-4/",
        "index": 93.4,
        "franchise-entity-name": "KSA12 LLC"
    },
    "58487010-8ac3-404d-b9e0-69371fe29a3d": {
        "name": "Orange Theory Macon, GA",
        "address": "4357 Forsyth Road, Suite 250 Macon, Georgia 31210",
        "phone": "(478) 845-4505",
        "email": "studiomanager1148@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            32.8686752,
            -83.695915
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.98,
                    "duration": "indefinitely",
                    "model_conf": 153.55
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.97,
                    "duration": "indefinitely",
                    "model_conf": 184.2
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.0,
                    "duration": "indefinitely",
                    "model_conf": 112.04
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Macon, GA",
            "slug": "58487010-8ac3-404d-b9e0-69371fe29a3d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/2/18",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/macon/4357-forsyth-road-suite-250/",
        "index": 98.425,
        "franchise-entity-name": "JCM MACON, LLC"
    },
    "1eaedcf3-cde9-4406-8374-dc17f71fc11a": {
        "name": "Orange Theory Madison, AL",
        "address": "8141 Hwy 72 W Suite K Madison, Alabama 35757",
        "phone": "(256) 400-0070",
        "email": "studiomanager0962@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:15PM",
            "SUNDAY": "6:00AM - 12:15PM"
        },
        "geolocation": [
            34.7497826,
            -86.754578
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.15,
                    "duration": "indefinitely",
                    "model_conf": 151.67
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.5,
                    "duration": "1 Months",
                    "model_conf": 171.59
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.81,
                    "duration": "indefinitely",
                    "model_conf": 111.71
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Madison, AL",
            "slug": "1eaedcf3-cde9-4406-8374-dc17f71fc11a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/madison/8141-hwy-72-w-suite-k/",
        "index": 93.4,
        "franchise-entity-name": "ALABAMAFIT7, LLC"
    },
    "08ade1ef-88e1-4e62-b9f2-52ba1868170f": {
        "name": "Orange Theory Madison, MS",
        "address": "111 Colony Crossing Suite #460 Madison, Mississippi 39110",
        "phone": "(601) 707-9202",
        "email": "studiomanager0625@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 3:00PM",
            "SUNDAY": "7:00AM - 3:00PM"
        },
        "geolocation": [
            32.4761543,
            -90.142807
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.65,
                    "duration": "1 Months",
                    "model_conf": 137.56
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.96,
                    "duration": "indefinitely",
                    "model_conf": 174.24
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.01,
                    "duration": "indefinitely",
                    "model_conf": 111.51
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Madison, MS",
            "slug": "08ade1ef-88e1-4e62-b9f2-52ba1868170f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/10/16",
        "url": "https://www.orangetheory.com/en-us/locations/mississippi/madison/111-colony-crossing-suite-460/",
        "index": 93.4,
        "franchise-entity-name": "EPOC FITNESS VENTURE, LLC"
    },
    "27350688-c9ea-41b5-af5d-12fcb4b6f240": {
        "name": "Orange Theory Madison, WI",
        "address": "2501 W Beltline Highway, Suite 105 Madison, Wisconsin 53713",
        "phone": "(608) 709-8099",
        "email": "studiomanager0341@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            43.0344772,
            -89.421059
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.47,
                    "duration": "2 Months",
                    "model_conf": 153.32
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.84,
                    "duration": "indefinitely",
                    "model_conf": 184.42
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.92,
                    "duration": "2 Months",
                    "model_conf": 110.87
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Madison, WI",
            "slug": "27350688-c9ea-41b5-af5d-12fcb4b6f240"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/10/15",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/madison/2501-w-beltline-highway-suite-105/",
        "index": 93.4,
        "franchise-entity-name": "UNBOUNDED FITNESS MADISON, LLC"
    },
    "319332e0-c88b-4f17-a258-2831df6d10f1": {
        "name": "Orange Theory Madison West, WI",
        "address": "7803 Mineral Point Road Madison, Wisconsin 53717",
        "phone": "(608) 960-4044",
        "email": "studiomanager0340@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 11:30AM",
            "SUNDAY": "6:15AM - 11:30AM"
        },
        "geolocation": [
            43.0604515,
            -89.517494
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.66,
                    "duration": "1 Months",
                    "model_conf": 153.62
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.84,
                    "duration": "indefinitely",
                    "model_conf": 185.29
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.42,
                    "duration": "1 Months",
                    "model_conf": 110.42
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Madison West, WI",
            "slug": "319332e0-c88b-4f17-a258-2831df6d10f1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/11/15",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/madison/7803-mineral-point-road/",
        "index": 93.4,
        "franchise-entity-name": "RESOLUTE FITNESS MADISON, LLC"
    },
    "c4ced03d-d683-4e7c-923e-92fcc2308bf5": {
        "name": "Orange Theory Mamaroneck, NY",
        "address": "811 Mamaroneck Ave. Mamaroneck, New York 10543",
        "phone": "(914) 893-6100",
        "email": "studiomanager0725@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:15PM",
            "TUESDAY": "6:00AM - 8:15PM",
            "WEDNESDAY": "6:00AM - 8:15PM",
            "THURSDAY": "6:00AM - 8:15PM",
            "FRIDAY": "6:00AM - 8:15PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            40.9571381,
            -73.737755
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 86.88,
                    "duration": "9 Months",
                    "model_conf": 116.16
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 131.48,
                    "duration": "indefinitely",
                    "model_conf": 140.22
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.24,
                    "duration": "3 Months",
                    "model_conf": 86.59
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Mamaroneck, NY",
            "slug": "c4ced03d-d683-4e7c-923e-92fcc2308bf5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/25/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/mamaroneck/811-mamaroneck-ave/",
        "index": 103.375,
        "franchise-entity-name": "PFM WC-1, LLC"
    },
    "4748cd65-6152-4cd0-9b47-5a3c6b33185e": {
        "name": "Orange Theory Manchester",
        "address": "1500B Pleasant Valley Rd Manchester, Connecticut 06042",
        "phone": "(860) 438-6390",
        "email": "studiomanager0624@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.8052826,
            -72.558373
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.94,
                    "duration": "indefinitely",
                    "model_conf": 150.18
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.03,
                    "duration": "3 Months",
                    "model_conf": 184.69
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.12,
                    "duration": "indefinitely",
                    "model_conf": 111.52
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Manchester",
            "slug": "4748cd65-6152-4cd0-9b47-5a3c6b33185e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/12/19",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/manchester/1500b-pleasant-valley-rd/",
        "index": 98.425,
        "franchise-entity-name": "M2 AVON LLC."
    },
    "e144b759-f9a6-461b-a3bf-3597de83b4ba": {
        "name": "Orange Theory N Manchester, NH",
        "address": "545 Hooksett Road Manchester, New Hampshire 03109",
        "phone": "(603) 686-8402",
        "email": "studiomanager1057@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:40PM",
            "TUESDAY": "4:45AM - 7:40PM",
            "WEDNESDAY": "4:45AM - 7:40PM",
            "THURSDAY": "4:45AM - 7:40PM",
            "FRIDAY": "4:45AM - 7:40PM",
            "SATURDAY": "7:15AM - 11:45AM",
            "SUNDAY": "7:15AM - 11:45AM"
        },
        "geolocation": [
            43.0150909,
            -71.451874
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.37,
                    "duration": "indefinitely",
                    "model_conf": 153.12
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.4,
                    "duration": "1 Months",
                    "model_conf": 185.54
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.32,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "N Manchester, NH",
            "slug": "e144b759-f9a6-461b-a3bf-3597de83b4ba"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/21/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-hampshire/manchester/545-hooksett-road/",
        "index": 98.425,
        "franchise-entity-name": "TRILLIUM FITNESS, LLC"
    },
    "f0c8f030-4360-43d8-a96b-d4fdf4416c91": {
        "name": "Orange Theory Mandeville, LA",
        "address": "3549 Highway 190 Mandeville, Louisiana 70417",
        "phone": "(985) 302-0668",
        "email": "studiomanager0929@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 10:15AM",
            "SUNDAY": "8:00AM - 10:15AM"
        },
        "geolocation": [
            30.3944302,
            -90.08551
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.34,
                    "duration": "indefinitely",
                    "model_conf": 153.82
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.51,
                    "duration": "1 Months",
                    "model_conf": 183.94
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 106.79
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Mandeville, LA",
            "slug": "f0c8f030-4360-43d8-a96b-d4fdf4416c91"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/7/17",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/mandeville/3549-highway-190/",
        "index": 93.4,
        "franchise-entity-name": "HAWTHORNE NOLA3 LLC"
    },
    "6e7c8992-0928-4d1a-bc38-e258976353cb": {
        "name": "Orange Theory Manhattan-East 39th St., NY",
        "address": "605 Third Ave. Manhattan, New York 10158",
        "phone": "(646) 838-1020",
        "email": "studiomanager0912@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:40PM",
            "TUESDAY": "6:00AM - 8:40PM",
            "WEDNESDAY": "6:00AM - 8:40PM",
            "THURSDAY": "6:00AM - 8:40PM",
            "FRIDAY": "6:00AM - 8:40PM",
            "SATURDAY": "8:00AM - 12:30PM",
            "SUNDAY": "8:00AM - 12:30PM"
        },
        "geolocation": [
            40.7489777,
            -73.975159
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.04,
                    "duration": "indefinitely",
                    "model_conf": 83.28
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.14,
                    "duration": "1 Months",
                    "model_conf": 99.87
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 278.83,
                    "duration": "indefinitely",
                    "model_conf": 62.62
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan-East 39th St., NY",
            "slug": "6e7c8992-0928-4d1a-bc38-e258976353cb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/2/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/manhattan/605-third-ave/",
        "index": 133.3,
        "franchise-entity-name": "MIDTOWN FITNESS PARTNERS, LLC"
    },
    "dcbdb298-2a53-44b4-a954-244c09761c6a": {
        "name": "Orange Theory Mansfield, TX",
        "address": "3141 E Broad Street Suite 221 Mansfield, Texas 76063",
        "phone": "(817) 225-4104",
        "email": "studiomanager1056@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 1:30PM",
            "SUNDAY": "6:15AM - 1:30PM"
        },
        "geolocation": [
            32.5752754,
            -97.087738
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.39,
                    "duration": "indefinitely",
                    "model_conf": 153.06
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.66,
                    "duration": "1 Months",
                    "model_conf": 185.54
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.52,
                    "duration": "indefinitely",
                    "model_conf": 111.76
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Mansfield, TX",
            "slug": "dcbdb298-2a53-44b4-a954-244c09761c6a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/14/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/mansfield/3141-e-broad-street-suite-221/",
        "index": 93.4,
        "franchise-entity-name": "VSB VENTURES V, LLC"
    },
    "52cb8f9e-fdb9-4eba-9534-d51ba1dde3cc": {
        "name": "Orange Theory Maple Grove, MN",
        "address": "8121 Wedgewood Lane North Maple Grove, Minnesota 55369",
        "phone": "(763) 657-1313",
        "email": "studiomanager0024@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:40PM",
            "TUESDAY": "5:00AM - 8:40PM",
            "WEDNESDAY": "5:00AM - 8:40PM",
            "THURSDAY": "5:00AM - 8:40PM",
            "FRIDAY": "5:00AM - 8:40PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            45.1025276,
            -93.450569
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.29,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.2,
                    "duration": "indefinitely",
                    "model_conf": 150.69
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.2,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.55,
                    "duration": "1 Months",
                    "model_conf": 181.69
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.57,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.21,
                    "duration": "indefinitely",
                    "model_conf": 108.11
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Maple Grove, MN",
            "slug": "52cb8f9e-fdb9-4eba-9534-d51ba1dde3cc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/11",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/maple-grove/8121-wedgewood-lane-north/",
        "index": 88.45,
        "franchise-entity-name": "GO FAR, INC."
    },
    "e42ca3fb-1beb-435a-9866-a0f74e48637d": {
        "name": "Orange Theory Maple Valley, WA",
        "address": "26555 Maple Valley Black Diamond SE, Suite 102 Maple Valley, Washington 98038",
        "phone": "(206) 330-0509",
        "email": "studiomanager0587@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            47.3634338,
            -122.02133
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 8.61,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.12,
                    "duration": "3 Months",
                    "model_conf": 153.31
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 12.09,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.13,
                    "duration": "indefinitely",
                    "model_conf": 184.81
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 17.31,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.41,
                    "duration": "1 Months",
                    "model_conf": 111.76
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 21.75,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 39.15,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 52.11,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Maple Valley, WA",
            "slug": "e42ca3fb-1beb-435a-9866-a0f74e48637d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/washington/maple-valley/26555-maple-valley-black-diamond-se-suite-102/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF MAPLE VALLEY, LLC"
    },
    "d092fded-97ab-4efc-9bde-79d18d1fea24": {
        "name": "Orange Theory Maplewood, MN",
        "address": "3095 White Bear Ave N. Maplewood, Minnesota 55109",
        "phone": "(651) 998-2219",
        "email": "studiomanager0693@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:15PM",
            "SUNDAY": "6:30AM - 12:15PM"
        },
        "geolocation": [
            45.0353775,
            -93.019531
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.35,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.62,
                    "duration": "indefinitely",
                    "model_conf": 140.13
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.3,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.15,
                    "duration": "indefinitely",
                    "model_conf": 153.82
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.73,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.0,
                    "duration": "indefinitely",
                    "model_conf": 96.04
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Maplewood, MN",
            "slug": "d092fded-97ab-4efc-9bde-79d18d1fea24"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/5/17",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/maplewood/3095-white-bear-ave-n/",
        "index": 88.45,
        "franchise-entity-name": "BMO FITNESS L.L.C."
    },
    "a9d36abd-8cc2-4c33-9d18-65f98f922789": {
        "name": "Orange Theory Marana, AZ",
        "address": "9170 N Silverbell Rd. Suite 120 Marana, Arizona 85743",
        "phone": "(520) 445-8999",
        "email": "studiomanager1090@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            32.3738403,
            -111.13667
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 1.78,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.81,
                    "duration": "indefinitely",
                    "model_conf": 151.76
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 2.58,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.18,
                    "duration": "indefinitely",
                    "model_conf": 183.89
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 3.78,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.95,
                    "duration": "indefinitely",
                    "model_conf": 110.74
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 4.9,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 7.6,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 10.2,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Marana, AZ",
            "slug": "a9d36abd-8cc2-4c33-9d18-65f98f922789"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/28/18",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/marana/9170-n-silverbell-rd-suite-120/",
        "index": 103.375,
        "franchise-entity-name": "TUCSON FIT 5, LLC"
    },
    "dc9ac509-b5be-4520-87e4-00ef7c665fb1": {
        "name": "Orange Theory East Cobb - Marietta, GA",
        "address": "1401 Johnson Ferry Road, Suite 320 Marietta, Georgia 30062",
        "phone": "(678) 920-9455",
        "email": "studiomanager0124@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 1:30PM",
            "SUNDAY": "6:15AM - 1:30PM"
        },
        "geolocation": [
            33.9846382,
            -84.427734
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.0,
                    "duration": "indefinitely",
                    "model_conf": 134.88
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.31,
                    "duration": "indefinitely",
                    "model_conf": 173.41
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.06,
                    "duration": "indefinitely",
                    "model_conf": 99.52
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "East Cobb - Marietta, GA",
            "slug": "dc9ac509-b5be-4520-87e4-00ef7c665fb1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/22/13",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/marietta/1401-johnson-ferry-road-suite-320/",
        "index": 98.425,
        "franchise-entity-name": "VAILLANTINO JC, LLC"
    },
    "04fe02e2-55e7-4217-9523-964caf127bd7": {
        "name": "Orange Theory Marietta, GA",
        "address": "1750 Powder Springs Rd., Suite 270 Marietta, Georgia 30064",
        "phone": "(678) 810-1814",
        "email": "studiomanager0436@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            33.9043312,
            -84.605194
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.8,
                    "duration": "indefinitely",
                    "model_conf": 150.79
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.14,
                    "duration": "indefinitely",
                    "model_conf": 183.46
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.64,
                    "duration": "indefinitely",
                    "model_conf": 110.34
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Marietta, GA",
            "slug": "04fe02e2-55e7-4217-9523-964caf127bd7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/8/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/marietta/1750-powder-springs-rd-suite-270/",
        "index": 98.425,
        "franchise-entity-name": "JM MARIETTA HOLDINGS, LLC"
    },
    "0c57d64c-fdb1-462e-8b66-a43679716d83": {
        "name": "Orange Theory Kennesaw, GA",
        "address": "2555 Prado LN, Suite 1400 Marietta, Georgia 30066",
        "phone": "(770) 687-3625",
        "email": "studiomanager0263@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            34.014576,
            -84.553123
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.03,
                    "duration": "indefinitely",
                    "model_conf": 144.26
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.05,
                    "duration": "indefinitely",
                    "model_conf": 182.95
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.09,
                    "duration": "indefinitely",
                    "model_conf": 105.65
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Kennesaw, GA",
            "slug": "0c57d64c-fdb1-462e-8b66-a43679716d83"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/11/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/marietta/2555-prado-ln-suite-1400/",
        "index": 98.425,
        "franchise-entity-name": "JM CANTON, LLC"
    },
    "d065dbc2-c7a3-4cc2-b60f-bc42b9e4b093": {
        "name": "Orange Theory Sandy Plains - Marietta, GA",
        "address": "2960 Shallowford Road, Suite 103 Marietta, Georgia 30066",
        "phone": "(404) 326-9873",
        "email": "studiomanager0370@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            34.0351677,
            -84.466591
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.59,
                    "duration": "indefinitely",
                    "model_conf": 136.94
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.42,
                    "duration": "1 Months",
                    "model_conf": 179.69
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.48,
                    "duration": "indefinitely",
                    "model_conf": 102.13
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sandy Plains - Marietta, GA",
            "slug": "d065dbc2-c7a3-4cc2-b60f-bc42b9e4b093"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/marietta/2960-shallowford-road-suite-103/",
        "index": 98.425,
        "franchise-entity-name": "VVDC SANDY PLAINS, LLC"
    },
    "0a48b6cc-6750-463f-a114-d8aa007add30": {
        "name": "Orange Theory Cumberland, GA",
        "address": "3000 Windy Hill Road, Suite 156 Marietta, Georgia 30067",
        "phone": "(678) 701-5510",
        "email": "studiomanager0374@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:15AM - 12:15PM",
            "SUNDAY": "8:15AM - 12:15PM"
        },
        "geolocation": [
            33.9043274,
            -84.466522
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 81.08,
                    "duration": "indefinitely",
                    "model_conf": 138.85
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.5,
                    "duration": "8 Months",
                    "model_conf": 170.3
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.67,
                    "duration": "indefinitely",
                    "model_conf": 103.4
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cumberland, GA",
            "slug": "0a48b6cc-6750-463f-a114-d8aa007add30"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/5/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/marietta/3000-windy-hill-road-suite-156/",
        "index": 98.425,
        "franchise-entity-name": "CONFLUENCE GROUP II, LLC"
    },
    "1d4bbb66-bd45-4f4c-9daa-b9a1a689e949": {
        "name": "Orange Theory West Cobb - Marietta, GA",
        "address": "3600 Dallas Highway, Suite 100 Marietta, Georgia 30064",
        "phone": "(770) 876-4339",
        "email": "studiomanager0265@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            33.9551735,
            -84.656242
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.85,
                    "duration": "indefinitely",
                    "model_conf": 151.68
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.97,
                    "duration": "3 Months",
                    "model_conf": 184.93
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.44,
                    "duration": "indefinitely",
                    "model_conf": 110.53
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West Cobb - Marietta, GA",
            "slug": "1d4bbb66-bd45-4f4c-9daa-b9a1a689e949"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/24/14",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/marietta/3600-dallas-highway-suite-100/",
        "index": 98.425,
        "franchise-entity-name": "JM ATHENS, LLC"
    },
    "3f51a62f-4b10-4eee-bf98-a8f59a2b8e11": {
        "name": "Orange Theory Marina Del Rey, CA",
        "address": "4250 Via Marina, Suite 10-S Marina Del Rey, California 90292",
        "phone": "(424) 226-9400",
        "email": "studiomanager0479@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:30PM",
            "TUESDAY": "6:00AM - 7:30PM",
            "WEDNESDAY": "6:00AM - 7:30PM",
            "THURSDAY": "6:00AM - 7:30PM",
            "FRIDAY": "6:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            33.9777489,
            -118.45866
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.62,
                    "duration": "indefinitely",
                    "model_conf": 150.02
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.11,
                    "duration": "2 Months",
                    "model_conf": 182.06
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.11,
                    "duration": "indefinitely",
                    "model_conf": 109.05
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Marina Del Rey, CA",
            "slug": "3f51a62f-4b10-4eee-bf98-a8f59a2b8e11"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/4/20",
        "url": "https://www.orangetheory.com/en-us/locations/california/marina-del-rey/4250-via-marina-suite-10/",
        "index": 111.325,
        "franchise-entity-name": "WCF WILSHIRE, LLC"
    },
    "ec3eaed5-f856-4dc4-a154-04ee83bc720e": {
        "name": "Orange Theory Marlborough, MA",
        "address": "40 Boston Post Road West Marlborough, Massachusetts 01752",
        "phone": "(508) 762-1808",
        "email": "studiomanager0617@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            42.3402634,
            -71.583542
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.27,
                    "duration": "indefinitely",
                    "model_conf": 152.76
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.01,
                    "duration": "indefinitely",
                    "model_conf": 185.28
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.36,
                    "duration": "indefinitely",
                    "model_conf": 111.7
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Marlborough, MA",
            "slug": "ec3eaed5-f856-4dc4-a154-04ee83bc720e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/16",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/marlborough/42-boston-post-rd-west/",
        "index": 98.425,
        "franchise-entity-name": "FITNESS CAPITAL VENTURES, LLC"
    },
    "2becd7f9-2d32-44e8-9a88-3d39e0df3b63": {
        "name": "Orange Theory Mason, OH",
        "address": "8267 Arbor Square Drive Mason, Ohio 45040",
        "phone": "(513) 445-5844",
        "email": "studiomanager0418@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            39.3194695,
            -84.315498
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.18,
                    "duration": "indefinitely",
                    "model_conf": 144.16
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.28,
                    "duration": "indefinitely",
                    "model_conf": 184.73
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.01,
                    "duration": "indefinitely",
                    "model_conf": 105.77
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Mason, OH",
            "slug": "2becd7f9-2d32-44e8-9a88-3d39e0df3b63"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/10/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/mason/8267-arbor-square-drive/",
        "index": 93.4,
        "franchise-entity-name": "S&amp;C FITNESS I, LLC"
    },
    "0f0c8b0b-f04c-4c27-bed0-f7717983c36c": {
        "name": "Orange Theory Massapequa Park, NY",
        "address": "4914 Merrick Road Massapequa, New York 11762",
        "phone": "(516) 590-7676",
        "email": "studiomanager0158@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 1:30PM",
            "SUNDAY": "6:30AM - 1:30PM"
        },
        "geolocation": [
            40.6664391,
            -73.454575
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.8,
                    "duration": "4 Months",
                    "model_conf": 142.01
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.29,
                    "duration": "indefinitely",
                    "model_conf": 170.74
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.84,
                    "duration": "indefinitely",
                    "model_conf": 101.89
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Massapequa Park, NY",
            "slug": "0f0c8b0b-f04c-4c27-bed0-f7717983c36c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/13/15",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/massapequa/4914-merrick-road/",
        "index": 108.325,
        "franchise-entity-name": "ASHA3, LLC"
    },
    "6483300c-4608-4009-ba63-49fc4eea10ed": {
        "name": "Orange Theory Matthews, NC",
        "address": "1813 Matthews Township Pkwy, Ste B Matthews, North Carolina 28105",
        "phone": "(980) 920-8151",
        "email": "studiomanager1535@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            35.125109,
            -80.709838
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.65,
                    "duration": "1 Months",
                    "model_conf": 134.24
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.81,
                    "duration": "indefinitely",
                    "model_conf": 160.63
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.84,
                    "duration": "indefinitely",
                    "model_conf": 102.69
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Matthews, NC",
            "slug": "6483300c-4608-4009-ba63-49fc4eea10ed"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/22",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/matthews/1813-matthews-township-pkwy/",
        "index": 98.425,
        "franchise-entity-name": "EMPIRE EH LLC"
    },
    "fff3c51d-034a-49c7-b4cb-94622c8f0cd0": {
        "name": "Orange Theory Mission",
        "address": "2700 W Expressway 83 Mcallen, Texas 78503",
        "phone": "(956) 377-4737",
        "email": "studiomanager1443@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            26.1900005,
            -98.25
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.4,
                    "duration": "3 Months",
                    "model_conf": 153.34
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.34,
                    "duration": "indefinitely",
                    "model_conf": 185.14
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.52,
                    "duration": "1 Months",
                    "model_conf": 111.77
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Mission",
            "slug": "fff3c51d-034a-49c7-b4cb-94622c8f0cd0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/29/21",
        "url": "https://www.orangetheory.com/en-us/locations/texas/mcallen/2700-w-expressway-83/",
        "index": 93.4,
        "franchise-entity-name": "5A Fitness Mission, LLC"
    },
    "14f55a59-0c45-465d-80b1-6b82ce959ce2": {
        "name": "Orange Theory McAllen, TX",
        "address": "5800 North 10th Street suite 110 Mcallen, Texas 78504",
        "phone": "(956) 331-2181",
        "email": "studiomanager0933@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            26.2554836,
            -98.220184
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.49,
                    "duration": "2 Months",
                    "model_conf": 152.56
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.49,
                    "duration": "indefinitely",
                    "model_conf": 185.68
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.01,
                    "duration": "2 Months",
                    "model_conf": 111.95
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "McAllen, TX",
            "slug": "14f55a59-0c45-465d-80b1-6b82ce959ce2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/mcallen/5800-north-10th-street-suite-110/",
        "index": 93.4,
        "franchise-entity-name": "5A FITNESS MCALLEN #1, L.L.C."
    },
    "e64faf20-85f8-4ba8-a78f-0ffbd9de46b0": {
        "name": "Orange Theory McDonough, GA",
        "address": "1663 GA-20 W Mcdonough, Georgia 30253",
        "phone": "(678) 505-7877",
        "email": "studiomanager1149@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.4187851,
            -84.190224
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.69,
                    "duration": "1 Months",
                    "model_conf": 153.46
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.42,
                    "duration": "indefinitely",
                    "model_conf": 184.06
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.76,
                    "duration": "indefinitely",
                    "model_conf": 111.92
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "McDonough, GA",
            "slug": "e64faf20-85f8-4ba8-a78f-0ffbd9de46b0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/26/19",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/mcdonough/1663-highway-20-west/",
        "index": 98.425,
        "franchise-entity-name": "JCM MCDONOUGH, LLC"
    },
    "7067bdf1-7b93-46e8-91bb-955c86d76924": {
        "name": "Orange Theory North McKinney",
        "address": "3625 W University Drive Suite 100 Mckinney, Texas 75071",
        "phone": "(972) 474-0691",
        "email": "studiomanager1260@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 7:45PM",
            "TUESDAY": "4:30AM - 7:45PM",
            "WEDNESDAY": "4:30AM - 7:45PM",
            "THURSDAY": "4:30AM - 7:45PM",
            "FRIDAY": "4:30AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            33.2166405,
            -96.661751
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.89,
                    "duration": "indefinitely",
                    "model_conf": 148.95
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.08,
                    "duration": "indefinitely",
                    "model_conf": 180.47
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.98,
                    "duration": "indefinitely",
                    "model_conf": 110.53
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "North McKinney",
            "slug": "7067bdf1-7b93-46e8-91bb-955c86d76924"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/22/21",
        "url": "https://www.orangetheory.com/en-us/locations/texas/mckinney/3625-w-university-drive-suite-100/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA FITNESS - NORTH MCKINNEY, LLC"
    },
    "633f7631-b3ad-480c-90d7-7f4894e3bb6a": {
        "name": "Orange Theory McLean, VA",
        "address": "6623 Old Dominion Drive Mclean, Virginia 22101",
        "phone": "(703) 722-0601",
        "email": "studiomanager0637@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 5:15PM",
            "TUESDAY": "5:00AM - 5:15PM",
            "WEDNESDAY": "5:00AM - 5:15PM",
            "THURSDAY": "5:00AM - 5:15PM",
            "FRIDAY": "5:00AM - 5:15PM",
            "SATURDAY": "7:30AM - 11:45AM",
            "SUNDAY": "7:30AM - 11:45AM"
        },
        "geolocation": [
            38.9303169,
            -77.17112
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.95,
                    "duration": "indefinitely",
                    "model_conf": 122.16
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.7,
                    "duration": "indefinitely",
                    "model_conf": 90.37
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.91,
                    "duration": "indefinitely",
                    "model_conf": 150.25
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "McLean, VA",
            "slug": "633f7631-b3ad-480c-90d7-7f4894e3bb6a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/mclean/6623-old-dominion-drive/",
        "index": 98.425,
        "franchise-entity-name": "G3 FITNESS GROUP DC1, LLC"
    },
    "41a605c4-7b2a-4f02-a5b2-ec45a69c3aab": {
        "name": "Orange Theory Mechanicsburg, PA",
        "address": "6416 Carlisle Pike Mechaniscburg, Pennsylvania 17050",
        "phone": "(717) 288-1243",
        "email": "studiomanager1442@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            40.247715,
            -77.006342
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 66.29,
                    "duration": "indefinitely",
                    "model_conf": 131.42
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 112.06,
                    "duration": "indefinitely",
                    "model_conf": 173.74
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.5,
                    "duration": "3 Months",
                    "model_conf": 89.87
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Mechanicsburg, PA",
            "slug": "41a605c4-7b2a-4f02-a5b2-ec45a69c3aab"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/28/23",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/mechanicsburg/6416-carlisle-pike/",
        "index": 93.4,
        "franchise-entity-name": "MAB Management, LLC- 1442"
    },
    "c2af3db4-39d8-4461-bcbf-d6c36d580893": {
        "name": "Orange Theory Medford, OR",
        "address": "171 Rossanley Drive Suite 109 Medford, Oregon 97501",
        "phone": "(458) 202-1229",
        "email": "studiomanager1187@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            42.3423615,
            -122.88348
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.99,
                    "duration": "indefinitely",
                    "model_conf": 153.66
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.98,
                    "duration": "indefinitely",
                    "model_conf": 185.68
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.0,
                    "duration": "indefinitely",
                    "model_conf": 111.69
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Medford, OR",
            "slug": "c2af3db4-39d8-4461-bcbf-d6c36d580893"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/21/18",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/medford/171-rossanley-drive-suite-109/",
        "index": 98.425,
        "franchise-entity-name": "JM MEDFORD, LLC"
    },
    "77751929-8e6b-4219-80f8-abbbc0f4448a": {
        "name": "Orange Theory Medina, OH",
        "address": "1120 North Court St Medina, Ohio 44256",
        "phone": "(330) 636-0388",
        "email": "studiomanager1116@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 9:15PM",
            "TUESDAY": "5:15AM - 9:15PM",
            "WEDNESDAY": "5:15AM - 9:15PM",
            "THURSDAY": "5:15AM - 9:15PM",
            "FRIDAY": "5:15AM - 9:15PM",
            "SATURDAY": "6:30AM - 11:15AM",
            "SUNDAY": "6:30AM - 11:15AM"
        },
        "geolocation": [
            41.1563835,
            -81.860939
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.94,
                    "duration": "indefinitely",
                    "model_conf": 153.71
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.38,
                    "duration": "2 Months",
                    "model_conf": 185.61
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.6,
                    "duration": "indefinitely",
                    "model_conf": 112.0
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Medina, OH",
            "slug": "77751929-8e6b-4219-80f8-abbbc0f4448a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/7/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/medina/1120-north-court-st/",
        "index": 93.4,
        "franchise-entity-name": "Conquer 1, LLC"
    },
    "414223ea-2fc8-410b-8e9a-3aaa2617b134": {
        "name": "Orange Theory Medway, MA",
        "address": "67 Main Street Medway, Massachusetts 02053",
        "phone": "(508) 669-7879",
        "email": "studiomanager1040@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45PM",
            "SUNDAY": "7:00AM - 11:45PM"
        },
        "geolocation": [
            42.1520653,
            -71.405037
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.75,
                    "duration": "indefinitely",
                    "model_conf": 152.61
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.06,
                    "duration": "2 Months",
                    "model_conf": 183.83
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.64,
                    "duration": "indefinitely",
                    "model_conf": 111.18
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Medway, MA",
            "slug": "414223ea-2fc8-410b-8e9a-3aaa2617b134"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/medway/67-main-street/",
        "index": 103.375,
        "franchise-entity-name": "BERRY FITNESS LLC"
    },
    "8bd7ad1d-f9f0-43b3-904b-aae3a84f44d3": {
        "name": "Orange Theory North Melbourne, FL",
        "address": "6365 Wickham Road , D301 Melbourne, Florida 32940",
        "phone": "(321) 800-4466",
        "email": "studiomanager0417@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            28.2165318,
            -80.672157
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.87,
                    "duration": "6 Months",
                    "model_conf": 149.65
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.3,
                    "duration": "indefinitely",
                    "model_conf": 185.26
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.94,
                    "duration": "2 Months",
                    "model_conf": 111.87
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "North Melbourne, FL",
            "slug": "8bd7ad1d-f9f0-43b3-904b-aae3a84f44d3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/3/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/melbourne/6365-wickham-road-d301/",
        "index": 93.4,
        "franchise-entity-name": "VIERA ALL FITNESS LLC"
    },
    "5f9d6cf6-8588-48a4-b49b-795ea230a5e8": {
        "name": "Orange Theory Melville, NY",
        "address": "500 Walt Whitman Rd. Melville, New York 11747",
        "phone": "(631) 683-8311",
        "email": "studiomanager0328@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            40.8126335,
            -73.409172
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.57,
                    "duration": "1 Months",
                    "model_conf": 145.57
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.48,
                    "duration": "indefinitely",
                    "model_conf": 175.46
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.79,
                    "duration": "indefinitely",
                    "model_conf": 105.24
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Melville, NY",
            "slug": "5f9d6cf6-8588-48a4-b49b-795ea230a5e8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/13/15",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/melville/500-walt-whitman-rd/",
        "index": 108.325,
        "franchise-entity-name": "CIC FIT I, LLC"
    },
    "6ea1ee9d-68f1-43e6-b317-d10bf409fb62": {
        "name": "Orange Theory East Memphis, TN",
        "address": "4615 Poplar Avenue, Suite 12 Memphis, Tennessee 38117",
        "phone": "(901) 421-5785",
        "email": "studiomanager0438@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 4:35PM",
            "SUNDAY": "7:15AM - 4:35PM"
        },
        "geolocation": [
            35.1159859,
            -89.908241
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.78,
                    "duration": "1 Months",
                    "model_conf": 147.94
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.37,
                    "duration": "indefinitely",
                    "model_conf": 184.86
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.7,
                    "duration": "indefinitely",
                    "model_conf": 110.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "East Memphis, TN",
            "slug": "6ea1ee9d-68f1-43e6-b317-d10bf409fb62"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/28/15",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/memphis/4615-poplar-avenue-suite-12/",
        "index": 93.4,
        "franchise-entity-name": "MISTRAL 2015 LLC"
    },
    "eb51aefc-4bbc-4710-8327-9e086b464d7a": {
        "name": "Orange Theory Mendham,NJ",
        "address": "84 East Main Street Mendham, New Jersey 07945",
        "phone": "(973) 813-9995",
        "email": "studiomanager0794@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:15PM",
            "TUESDAY": "5:15AM - 8:15PM",
            "WEDNESDAY": "5:15AM - 8:15PM",
            "THURSDAY": "5:15AM - 8:15PM",
            "FRIDAY": "5:15AM - 8:15PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.7823029,
            -74.588966
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.12,
                    "duration": "4 Months",
                    "model_conf": 151.25
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.3,
                    "duration": "indefinitely",
                    "model_conf": 181.28
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.53,
                    "duration": "1 Months",
                    "model_conf": 110.2
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Mendham,NJ",
            "slug": "eb51aefc-4bbc-4710-8327-9e086b464d7a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/26/20",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/mendham/84-east-main-street/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO FITNESS I LLC"
    },
    "75ed7604-efa2-4c28-a050-8b32ac3b5007": {
        "name": "Orange Theory Menomonee Falls",
        "address": "W180N9460 Premier Lane Menomonee Falls, Wisconsin 53051",
        "phone": "(262) 509-3004",
        "email": "studiomanager1324@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            43.1881447,
            -88.134201
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.62,
                    "duration": "indefinitely",
                    "model_conf": 153.26
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.84,
                    "duration": "indefinitely",
                    "model_conf": 184.51
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.39,
                    "duration": "indefinitely",
                    "model_conf": 111.55
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Menomonee Falls",
            "slug": "75ed7604-efa2-4c28-a050-8b32ac3b5007"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/1/19",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/menomonee-falls/w180n9460-premier-lane/",
        "index": 93.4,
        "franchise-entity-name": "OT WISCONSIN MENOMONEE FALLS, LLC"
    },
    "087cc6cd-e368-48e8-b735-1f6d27f8715d": {
        "name": "Orange Theory Mentor, OH",
        "address": "7519 Mentor Avenue, Suite #A14 Mentor, Ohio 44060",
        "phone": "(440) 290-5859",
        "email": "studiomanager0829@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:15AM - 1:15PM",
            "SUNDAY": "6:15AM - 1:15PM"
        },
        "geolocation": [
            41.656044,
            -81.372971
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 2.07,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.27,
                    "duration": "indefinitely",
                    "model_conf": 153.62
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 3.27,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.4,
                    "duration": "2 Months",
                    "model_conf": 185.38
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 5.07,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.02,
                    "duration": "indefinitely",
                    "model_conf": 111.88
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 5.97,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 10.77,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 14.97,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Mentor, OH",
            "slug": "087cc6cd-e368-48e8-b735-1f6d27f8715d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/mentor/7519-mentor-avenue-suite-a14/",
        "index": 93.4,
        "franchise-entity-name": "KSA7 LLC"
    },
    "b894f91f-abb7-42b3-90ec-aa1faecbaf3b": {
        "name": "Orange Theory Mequon, WI",
        "address": "6071 W. Mequon Rd. Mequon, Wisconsin 53092",
        "phone": "(262) 457-7880",
        "email": "studiomanager0441@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 7:00PM",
            "TUESDAY": "4:30AM - 7:00PM",
            "WEDNESDAY": "4:30AM - 7:00PM",
            "THURSDAY": "4:30AM - 7:00PM",
            "FRIDAY": "4:30AM - 7:00PM",
            "SATURDAY": "5:30AM - 1:00PM",
            "SUNDAY": "5:30AM - 1:00PM"
        },
        "geolocation": [
            43.2218437,
            -87.983147
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.03,
                    "duration": "indefinitely",
                    "model_conf": 153.6
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.18,
                    "duration": "indefinitely",
                    "model_conf": 184.32
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.08,
                    "duration": "indefinitely",
                    "model_conf": 111.21
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Mequon, WI",
            "slug": "b894f91f-abb7-42b3-90ec-aa1faecbaf3b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/25/16",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/mequon/6071-w-mequon-rd/",
        "index": 93.4,
        "franchise-entity-name": "BEN I FIT LLC"
    },
    "f0daa14f-ecc6-4f3a-b5fd-7a1d6c9f6afd": {
        "name": "Orange Theory Mercer Island, WA",
        "address": "2601 76th Ave SE Mercer Island, Washington 98040",
        "phone": "(425) 278-5709",
        "email": "studiomanager0588@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            47.5876389,
            -122.2377
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.66,
                    "duration": "indefinitely",
                    "model_conf": 147.25
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.04,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 137.42,
                    "duration": "4 Months",
                    "model_conf": 183.3
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.58,
                    "duration": "indefinitely",
                    "model_conf": 102.94
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 45.45,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 60.5,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Mercer Island, WA",
            "slug": "f0daa14f-ecc6-4f3a-b5fd-7a1d6c9f6afd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/9/17",
        "url": "https://www.orangetheory.com/en-us/locations/washington/mercer-island/2601-76th-ave-se/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF MERCER ISLAND, LLC"
    },
    "cdf47a64-8aa8-4c7f-9e06-7486c9f84f8b": {
        "name": "Orange Theory Meridian, ID",
        "address": "2970 N Eagle Rd Suite #105 Meridian, Idaho 83646",
        "phone": "(208) 895-9076",
        "email": "studiomanager0523@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 7:45PM",
            "TUESDAY": "4:55AM - 7:45PM",
            "WEDNESDAY": "4:55AM - 7:45PM",
            "THURSDAY": "4:55AM - 7:45PM",
            "FRIDAY": "4:55AM - 7:45PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            43.6318359,
            -116.35388
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 4.74,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.92,
                    "duration": "indefinitely",
                    "model_conf": 148.36
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.25,
                    "duration": "2 Months",
                    "model_conf": 184.68
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 10.74,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.63,
                    "duration": "indefinitely",
                    "model_conf": 111.76
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 13.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 22.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Meridian, ID",
            "slug": "cdf47a64-8aa8-4c7f-9e06-7486c9f84f8b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/29/16",
        "url": "https://www.orangetheory.com/en-us/locations/idaho/meridian/2970-n-eagle-rd-suite-105/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS IDAHO, LLC"
    },
    "91a9ac1f-ae0b-4077-9369-f1e04f9bc28c": {
        "name": "Orange Theory Merrick, NY",
        "address": "1976 Merrick Rd Merrick, New York 11566",
        "phone": "(516) 299-9901",
        "email": "studiomanager0059@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.6562195,
            -73.549629
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 96.13,
                    "duration": "11 Months",
                    "model_conf": 138.97
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 142.26,
                    "duration": "indefinitely",
                    "model_conf": 166.54
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 197.24,
                    "duration": "3 Months",
                    "model_conf": 101.63
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Merrick, NY",
            "slug": "91a9ac1f-ae0b-4077-9369-f1e04f9bc28c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/merrick/1976-merrick-rd/",
        "index": 108.325,
        "franchise-entity-name": "OTF LI, LLC"
    },
    "d4ac53b4-75f9-4ba5-9aca-2bb450436dbb": {
        "name": "Orange Theory East Mesa - Red Mountain, AZ",
        "address": "2836 N Power Rd. Suite 102 Mesa, Arizona 85215",
        "phone": "(480) 634-6666",
        "email": "studiomanager0210@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            33.4672813,
            -111.68552
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.13,
                    "duration": "indefinitely",
                    "model_conf": 150.79
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.82,
                    "duration": "indefinitely",
                    "model_conf": 182.68
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.35,
                    "duration": "indefinitely",
                    "model_conf": 110.47
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "East Mesa - Red Mountain, AZ",
            "slug": "d4ac53b4-75f9-4ba5-9aca-2bb450436dbb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/3/14",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/mesa/2836-n-power-rd-suite-102/",
        "index": 103.375,
        "franchise-entity-name": "EAST MESA FIT, LLC"
    },
    "7fd08e60-cb1a-47a0-abf0-2ca6d7b14d02": {
        "name": "Orange Theory Mesa - Dana Park, AZ",
        "address": "3426 East Baseline Road, Suite 118 Mesa, Arizona 85204",
        "phone": "(480) 350-7750",
        "email": "studiomanager0020@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            33.3812027,
            -111.75739
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.62,
                    "duration": "indefinitely",
                    "model_conf": 135.34
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.43,
                    "duration": "1 Months",
                    "model_conf": 162.93
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.63,
                    "duration": "indefinitely",
                    "model_conf": 98.75
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Mesa - Dana Park, AZ",
            "slug": "7fd08e60-cb1a-47a0-abf0-2ca6d7b14d02"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/4/13",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/mesa/3426-east-baseline-road-suite-118/",
        "index": 103.375,
        "franchise-entity-name": "OTF1, LLC"
    },
    "15ee6b67-bee1-497d-a4b2-ce8f4b33445a": {
        "name": "Orange Theory Mesquite, TX",
        "address": "1765 North Town East Boulevard Suite #141 Mesquite, Texas 75150",
        "phone": "(972) 698-0525",
        "email": "studiomanager0212@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:15PM",
            "TUESDAY": "4:45AM - 7:15PM",
            "WEDNESDAY": "4:45AM - 7:15PM",
            "THURSDAY": "4:45AM - 7:15PM",
            "FRIDAY": "4:45AM - 7:15PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            32.8109932,
            -96.626228
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.63,
                    "duration": "2 Months",
                    "model_conf": 152.31
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.18,
                    "duration": "indefinitely",
                    "model_conf": 183.57
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.55,
                    "duration": "1 Months",
                    "model_conf": 110.77
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Mesquite, TX",
            "slug": "15ee6b67-bee1-497d-a4b2-ce8f4b33445a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/10/14",
        "url": "https://www.orangetheory.com/en-us/locations/texas/mesquite/1765-north-town-easy-blvd-suite-141/",
        "index": 88.45,
        "franchise-entity-name": "VALENCIA FITNESS-MESQUITE, LLC"
    },
    "3cf9537d-73ff-4add-a9e2-d3a1a018dee1": {
        "name": "Orange Theory Metairie, LA",
        "address": "3620 Hessmer Ave Metairie, Louisiana 70002",
        "phone": "(504) 313-3839",
        "email": "studiomanager1249@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            30.0076866,
            -90.163803
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.47,
                    "duration": "2 Months",
                    "model_conf": 121.98
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.1,
                    "duration": "indefinitely",
                    "model_conf": 177.31
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.7,
                    "duration": "indefinitely",
                    "model_conf": 107.39
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Metairie, LA",
            "slug": "3cf9537d-73ff-4add-a9e2-d3a1a018dee1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/metairie/3620-hessmer-ave/",
        "index": 93.4,
        "franchise-entity-name": "HAWTHORNE NOLA5 LLC"
    },
    "3eeb05c9-1ab6-47a2-8ed1-5dc2953134ad": {
        "name": "Orange Theory Methuen, MA",
        "address": "90 Pleasant Valley Street Methuen, Massachusetts 01844",
        "phone": "(978) 620-5850",
        "email": "studiomanager1025@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            42.7435226,
            -71.15892
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.57,
                    "duration": "7 Months",
                    "model_conf": 153.64
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 121.09,
                    "duration": "indefinitely",
                    "model_conf": 185.17
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.86,
                    "duration": "2 Months",
                    "model_conf": 111.25
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Methuen, MA",
            "slug": "3eeb05c9-1ab6-47a2-8ed1-5dc2953134ad"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/methuen/90-pleasant-valley-street/",
        "index": 98.425,
        "franchise-entity-name": "DREAMITUP, LLC"
    },
    "eaac10d6-513e-4290-b8a8-c8a9ccc6c3f4": {
        "name": "Orange Theory South Beach, FL",
        "address": "1115 5th Street Miami Beach, Florida 33139",
        "phone": "(305) 695-0085",
        "email": "studiomanager0053@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "7:15AM - 7:45PM",
            "TUESDAY": "7:15AM - 7:45PM",
            "WEDNESDAY": "7:15AM - 7:45PM",
            "THURSDAY": "7:15AM - 7:45PM",
            "FRIDAY": "7:15AM - 7:45PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            25.774601,
            -80.139885
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.33,
                    "duration": "indefinitely",
                    "model_conf": 147.93
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.47,
                    "duration": "1 Months",
                    "model_conf": 182.77
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.22,
                    "duration": "indefinitely",
                    "model_conf": 107.35
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "South Beach, FL",
            "slug": "eaac10d6-513e-4290-b8a8-c8a9ccc6c3f4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/9/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami-beach/1115-5th-street/",
        "index": 108.325,
        "franchise-entity-name": "TIFF &amp; RON, L.L.C."
    },
    "c37e66ff-c69b-4986-b10d-ff8124fd1ecb": {
        "name": "Orange Theory Miami Lakes, FL",
        "address": "15490-B NW 77th Court Miami Lakes, Florida 33016",
        "phone": "(305) 824-0420",
        "email": "studiomanager0038@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:30PM",
            "TUESDAY": "5:00AM - 9:30PM",
            "WEDNESDAY": "5:00AM - 9:30PM",
            "THURSDAY": "5:00AM - 9:30PM",
            "FRIDAY": "5:00AM - 9:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            25.9152355,
            -80.327141
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.78,
                    "duration": "indefinitely",
                    "model_conf": 146.93
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.21,
                    "duration": "2 Months",
                    "model_conf": 181.92
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.57,
                    "duration": "indefinitely",
                    "model_conf": 109.7
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Miami Lakes, FL",
            "slug": "c37e66ff-c69b-4986-b10d-ff8124fd1ecb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/22/13",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami-lakes/15490-b-nw-77th-court/",
        "index": 98.425,
        "franchise-entity-name": "RAK FITNESS LLC"
    },
    "9ffb6fea-329a-4a3b-99b2-5231ef2860a7": {
        "name": "Orange Theory Miami Shores, FL",
        "address": "9017 Biscayne Blvd Miami Shores, Florida 33138",
        "phone": "(305) 615-1299",
        "email": "studiomanager0570@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            25.8579578,
            -80.183075
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.85,
                    "duration": "indefinitely",
                    "model_conf": 147.24
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.36,
                    "duration": "2 Months",
                    "model_conf": 183.38
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.45,
                    "duration": "indefinitely",
                    "model_conf": 107.65
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Miami Shores, FL",
            "slug": "9ffb6fea-329a-4a3b-99b2-5231ef2860a7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami-shores/9017-biscayne-blvd/",
        "index": 98.425,
        "franchise-entity-name": "ALYKAY FITNESS, LLC"
    },
    "dd73eaa2-6a30-4f4f-ad8a-4cfeb2f9026a": {
        "name": "Orange Theory Tamiami, FL",
        "address": "1315 -B SW 107th Avenue Miami, Florida 33174",
        "phone": "(786) 899-5082",
        "email": "studiomanager0864@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "8:00AM - 12:00PM",
            "SUNDAY": "8:00AM - 12:00PM"
        },
        "geolocation": [
            25.7565956,
            -80.367065
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.64,
                    "duration": "indefinitely",
                    "model_conf": 147.03
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.11,
                    "duration": "2 Months",
                    "model_conf": 183.05
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.13,
                    "duration": "indefinitely",
                    "model_conf": 107.12
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Tamiami, FL",
            "slug": "dd73eaa2-6a30-4f4f-ad8a-4cfeb2f9026a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/18",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami/1315-b-sw-107th-avenue/",
        "index": 98.425,
        "franchise-entity-name": "TAMIAMI FITNESS, LLC"
    },
    "9d788418-58c1-41e6-89d0-de4e724344d3": {
        "name": "Orange Theory Country Walk, FL",
        "address": "13550 S.W 120th Street, Suite 402 Miami, Florida 33186",
        "phone": "(305) 254-2683",
        "email": "studiomanager0324@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:30PM",
            "TUESDAY": "5:00AM - 9:30PM",
            "WEDNESDAY": "5:00AM - 9:30PM",
            "THURSDAY": "5:00AM - 9:30PM",
            "FRIDAY": "5:00AM - 9:30PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            25.6554527,
            -80.414955
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.11,
                    "duration": "indefinitely",
                    "model_conf": 148.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.44,
                    "duration": "5 Months",
                    "model_conf": 183.45
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.6,
                    "duration": "indefinitely",
                    "model_conf": 107.32
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Country Walk, FL",
            "slug": "9d788418-58c1-41e6-89d0-de4e724344d3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/14/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami/13550-s-w-120th-street-suite-402/",
        "index": 98.425,
        "franchise-entity-name": "RAK FITNESS III LLC"
    },
    "d79d0e9b-f6f5-4efb-a2da-7917c1bb348e": {
        "name": "Orange Theory West Kendall, FL",
        "address": "14627 SW 56th St. Miami, Florida 33175",
        "phone": "(305) 615-1095",
        "email": "studiomanager0097@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:15PM",
            "TUESDAY": "5:00AM - 9:15PM",
            "WEDNESDAY": "5:00AM - 9:15PM",
            "THURSDAY": "5:00AM - 9:15PM",
            "FRIDAY": "5:00AM - 9:15PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            25.7139607,
            -80.429688
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.69,
                    "duration": "indefinitely",
                    "model_conf": 149.63
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.93,
                    "duration": "3 Months",
                    "model_conf": 183.93
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.49,
                    "duration": "indefinitely",
                    "model_conf": 108.5
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West Kendall, FL",
            "slug": "d79d0e9b-f6f5-4efb-a2da-7917c1bb348e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/29/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami/14627-sw-56th-st/",
        "index": 98.425,
        "franchise-entity-name": "OTF WEST KENDALL, LLC"
    },
    "0f839148-1ac2-4aaa-8875-9dc147022605": {
        "name": "Orange Theory Downtown Miami - Vizcayne, FL",
        "address": "269 NE 2nd Street Miami, Florida 33132",
        "phone": "(786) 589-7231",
        "email": "studiomanager0026@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:00PM",
            "TUESDAY": "5:30AM - 7:00PM",
            "WEDNESDAY": "5:30AM - 7:00PM",
            "THURSDAY": "5:30AM - 7:00PM",
            "FRIDAY": "5:30AM - 7:00PM",
            "SATURDAY": "8:00AM - 12:30PM",
            "SUNDAY": "8:00AM - 12:30PM"
        },
        "geolocation": [
            25.7769909,
            -80.188797
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 81.09,
                    "duration": "indefinitely",
                    "model_conf": 143.18
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.96,
                    "duration": "6 Months",
                    "model_conf": 181.52
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.33,
                    "duration": "indefinitely",
                    "model_conf": 103.4
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Downtown Miami - Vizcayne, FL",
            "slug": "0f839148-1ac2-4aaa-8875-9dc147022605"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/6/13",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami/269-ne-2nd-street/",
        "index": 98.425,
        "franchise-entity-name": "RAG FITNESS, LLC"
    },
    "57395fb8-65fd-4d50-acdb-97df0060042c": {
        "name": "Orange Theory Miami-Bird Rd, FL",
        "address": "7396 SW 40th ST Miami Miami, Florida 33155",
        "phone": "(305) 264-0087",
        "email": "studiomanager0090@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 9:00PM",
            "TUESDAY": "5:15AM - 9:00PM",
            "WEDNESDAY": "5:15AM - 9:00PM",
            "THURSDAY": "5:15AM - 9:00PM",
            "FRIDAY": "5:15AM - 9:00PM",
            "SATURDAY": "8:00AM - 12:30PM",
            "SUNDAY": "8:00AM - 12:30PM"
        },
        "geolocation": [
            25.7329598,
            -80.31498
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.49,
                    "duration": "indefinitely",
                    "model_conf": 144.92
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.39,
                    "duration": "1 Months",
                    "model_conf": 182.85
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.98,
                    "duration": "indefinitely",
                    "model_conf": 105.12
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Miami-Bird Rd, FL",
            "slug": "57395fb8-65fd-4d50-acdb-97df0060042c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/8/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami-lakes/7396-sw-40th-st/",
        "index": 98.425,
        "franchise-entity-name": "OTF BIRD ROAD, LLC"
    },
    "6d95a289-e7fe-4e14-b799-4b81507c65c8": {
        "name": "Orange Theory Pinecrest, FL",
        "address": "8511 SW 136th Street Miami, Florida 33156",
        "phone": "(305) 232-7722",
        "email": "studiomanager0022@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            25.6440125,
            -80.331337
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.28,
                    "duration": "indefinitely",
                    "model_conf": 147.34
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.49,
                    "duration": "4 Months",
                    "model_conf": 182.67
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.69,
                    "duration": "indefinitely",
                    "model_conf": 106.27
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Pinecrest, FL",
            "slug": "6d95a289-e7fe-4e14-b799-4b81507c65c8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/19/12",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami/8511-sw-136th-street/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS LLC"
    },
    "975b43ef-398a-4976-893a-3aae69150a80": {
        "name": "Orange Theory Downtown Dadeland, FL",
        "address": "9045 SW 72nd Place, Suite E150C Miami, Florida 33156",
        "phone": "(305) 222-7400",
        "email": "studiomanager0712@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:00PM",
            "TUESDAY": "4:45AM - 7:00PM",
            "WEDNESDAY": "4:45AM - 7:00PM",
            "THURSDAY": "4:45AM - 7:00PM",
            "FRIDAY": "4:45AM - 7:00PM",
            "SATURDAY": "6:45AM - 1:00PM",
            "SUNDAY": "6:45AM - 1:00PM"
        },
        "geolocation": [
            25.6866741,
            -80.312828
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.22,
                    "duration": "indefinitely",
                    "model_conf": 146.04
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.33,
                    "duration": "2 Months",
                    "model_conf": 182.94
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.88,
                    "duration": "indefinitely",
                    "model_conf": 105.6
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Downtown Dadeland, FL",
            "slug": "975b43ef-398a-4976-893a-3aae69150a80"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/29/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami/9045-sw-72nd-place-suite-e150c/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS LLC"
    },
    "7dbc89a1-ba29-4b69-ad3d-d27b8cb33b33": {
        "name": "Orange Theory Miami - Design District, FL",
        "address": "89 NE 38th Street Miami-Design District, Florida 33137",
        "phone": "(786) 667-3757",
        "email": "studiomanager0857@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:00PM",
            "TUESDAY": "5:30AM - 8:00PM",
            "WEDNESDAY": "5:30AM - 8:00PM",
            "THURSDAY": "5:30AM - 8:00PM",
            "FRIDAY": "5:30AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            25.8120384,
            -80.193581
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.01,
                    "duration": "indefinitely",
                    "model_conf": 142.95
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.96,
                    "duration": "3 Months",
                    "model_conf": 181.25
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.59,
                    "duration": "indefinitely",
                    "model_conf": 103.57
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Miami - Design District, FL",
            "slug": "7dbc89a1-ba29-4b69-ad3d-d27b8cb33b33"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/15/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miami-design-district/89-ne-38th-street/",
        "index": 98.425,
        "franchise-entity-name": "DESIGN FITNESS, LLC"
    },
    "0102a9f6-b57b-4cb5-8262-bc5f4b93ca61": {
        "name": "Orange Theory Miamisburg, OH",
        "address": "3645 Rigby Rd Miamisburg, Ohio 45342",
        "phone": "(937) 641-8783",
        "email": "studiomanager0968@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            39.5995407,
            -84.232498
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.86,
                    "duration": "indefinitely",
                    "model_conf": 153.38
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.29,
                    "duration": "2 Months",
                    "model_conf": 185.38
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.51,
                    "duration": "indefinitely",
                    "model_conf": 110.96
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Miamisburg, OH",
            "slug": "0102a9f6-b57b-4cb5-8262-bc5f4b93ca61"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/18/17",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/miamisburg/3645-rigby-rd/",
        "index": 93.4,
        "franchise-entity-name": "ORANGE GEM LLC"
    },
    "7ce1753d-d11a-4b28-866e-bed6f49f9525": {
        "name": "Orange Theory Middletown, DE",
        "address": "476 Middletown Warwick Rd Middletown, Delaware 19709",
        "phone": "(302) 426-2284",
        "email": "studiomanager1067@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            39.4523354,
            -75.723503
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.57,
                    "duration": "2 Months",
                    "model_conf": 153.78
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.63,
                    "duration": "indefinitely",
                    "model_conf": 185.58
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.72,
                    "duration": "indefinitely",
                    "model_conf": 112.08
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Middletown, DE",
            "slug": "7ce1753d-d11a-4b28-866e-bed6f49f9525"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/23/18",
        "url": "https://www.orangetheory.com/en-us/locations/delaware/middletown/476-middletown-warwick-rd/",
        "index": 98.425,
        "franchise-entity-name": "ALPHA FITNESS MIDDLETOWN LLC"
    },
    "703aecb1-d53e-4799-b5ac-d7268c14309a": {
        "name": "Orange Theory Middletown, NJ",
        "address": "846 Route 35S Middletown, New Jersey 07748",
        "phone": "(908) 738-1200",
        "email": "studiomanager0542@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.3895416,
            -74.099861
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 82.2,
                    "duration": "indefinitely",
                    "model_conf": 135.4
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.06,
                    "duration": "9 Months",
                    "model_conf": 163.79
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 181.35,
                    "duration": "indefinitely",
                    "model_conf": 92.71
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Middletown, NJ",
            "slug": "703aecb1-d53e-4799-b5ac-d7268c14309a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/middletown/846-route-35s/",
        "index": 98.425,
        "franchise-entity-name": "MIDDLETOWN FITNESS, LLC"
    },
    "6d9e86f5-51b5-4ed8-a3ca-88acfddb83d1": {
        "name": "Orange Theory Midland, TX",
        "address": "4410 N. Midkiff Rd., Suite B1 Midland, Texas 79705",
        "phone": "(432) 695-9898",
        "email": "studiomanager0618@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            32.0325508,
            -102.12756
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.36,
                    "duration": "4 Months",
                    "model_conf": 153.65
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.46,
                    "duration": "indefinitely",
                    "model_conf": 184.45
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.78,
                    "duration": "indefinitely",
                    "model_conf": 111.52
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Midland, TX",
            "slug": "6d9e86f5-51b5-4ed8-a3ca-88acfddb83d1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/27/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/midland/4410-n-midkiff-rd-suite-b1/",
        "index": 88.45,
        "franchise-entity-name": "INTERVAL FITNESS OF MIDLAND, LLC"
    },
    "f7b29ff7-e3f8-4c3c-ae07-874e5b25a425": {
        "name": "Orange Theory Midlothian, VA",
        "address": "15801 City View Drive Midlothian, Virginia 23113",
        "phone": "(804) 510-0300",
        "email": "studiomanager0462@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:50AM - 7:35PM",
            "TUESDAY": "4:50AM - 7:35PM",
            "WEDNESDAY": "4:50AM - 7:35PM",
            "THURSDAY": "4:50AM - 7:35PM",
            "FRIDAY": "4:50AM - 7:35PM",
            "SATURDAY": "6:30AM - 1:15PM",
            "SUNDAY": "6:30AM - 1:15PM"
        },
        "geolocation": [
            37.5137672,
            -77.698746
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.91,
                    "duration": "indefinitely",
                    "model_conf": 149.12
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.88,
                    "duration": "indefinitely",
                    "model_conf": 177.44
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.9,
                    "duration": "indefinitely",
                    "model_conf": 108.7
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Midlothian, VA",
            "slug": "f7b29ff7-e3f8-4c3c-ae07-874e5b25a425"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/28/16",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/midlothian/15801-city-view-drive/",
        "index": 98.425,
        "franchise-entity-name": "S. VA FITNESS R3 LLC"
    },
    "010cab8d-90e1-42e9-b459-9fe17227db71": {
        "name": "Orange Theory Midtown Omaha, NE",
        "address": "159 North 78th Street Midtown Omaha, Nebraska 68114",
        "phone": "(402) 281-2555",
        "email": "studiomanager0820@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            41.2590256,
            -96.034882
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.78,
                    "duration": "1 Months",
                    "model_conf": 143.69
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.92,
                    "duration": "indefinitely",
                    "model_conf": 176.56
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.86,
                    "duration": "indefinitely",
                    "model_conf": 108.79
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Midtown Omaha, NE",
            "slug": "010cab8d-90e1-42e9-b459-9fe17227db71"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/nebraska/midtown-omaha/159-north-78th-street/",
        "index": 93.4,
        "franchise-entity-name": "OMAHA FIT 3, LLC"
    },
    "c3c8f032-8a0b-44cc-aae4-c6f36a1c31f7": {
        "name": "Orange Theory Milford, CT",
        "address": "1682 Boston Post Road Milford, Connecticut 06460",
        "phone": "(203) 872-2876",
        "email": "studiomanager0871@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "6:45AM - 1:00PM",
            "SUNDAY": "6:45AM - 1:00PM"
        },
        "geolocation": [
            41.2482948,
            -73.024361
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.19,
                    "duration": "8 Months",
                    "model_conf": 133.51
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 121.39,
                    "duration": "indefinitely",
                    "model_conf": 173.3
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.09,
                    "duration": "1 Months",
                    "model_conf": 101.96
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Milford, CT",
            "slug": "c3c8f032-8a0b-44cc-aae4-c6f36a1c31f7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/milford/1682-boston-post-road/",
        "index": 98.425,
        "franchise-entity-name": "CFP-MILFORD, LLC"
    },
    "8209fe41-ae7e-41a7-af67-80a38c6885d5": {
        "name": "Orange Theory Strawberry Village-Mill Valley, CA",
        "address": "750 Redwood Hwy, Frontage Rd, Ste 1214 Mill Valley, California 94941",
        "phone": "(415) 433-1200",
        "email": "studiomanager0699@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:30PM",
            "TUESDAY": "6:00AM - 7:30PM",
            "WEDNESDAY": "6:00AM - 7:30PM",
            "THURSDAY": "6:00AM - 7:30PM",
            "FRIDAY": "6:00AM - 7:30PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            37.8953934,
            -122.51534
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.57,
                    "duration": "indefinitely",
                    "model_conf": 152.87
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.82,
                    "duration": "indefinitely",
                    "model_conf": 184.6
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.46,
                    "duration": "indefinitely",
                    "model_conf": 111.16
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Strawberry Village-Mill Valley, CA",
            "slug": "8209fe41-ae7e-41a7-af67-80a38c6885d5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/15/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/mill-valley/750-redwood-highway-frontage-rd-ste-1214/",
        "index": 118.225,
        "franchise-entity-name": "B.P.S. PARTNERS LLC"
    },
    "4169661e-7c34-4c6a-95de-3e05781a9819": {
        "name": "Orange Theory Millburn, NJ",
        "address": "290 Millburn Avenue Millburn, New Jersey 07041",
        "phone": "(973) 315-8870",
        "email": "studiomanager1208@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:30PM",
            "TUESDAY": "6:00AM - 7:30PM",
            "WEDNESDAY": "6:00AM - 7:30PM",
            "THURSDAY": "6:00AM - 7:30PM",
            "FRIDAY": "6:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            40.7242622,
            -74.303253
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.9,
                    "duration": "5 Months",
                    "model_conf": 122.26
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.09,
                    "duration": "indefinitely",
                    "model_conf": 146.52
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.43,
                    "duration": "1 Months",
                    "model_conf": 92.62
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Millburn, NJ",
            "slug": "4169661e-7c34-4c6a-95de-3e05781a9819"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/millburn/290-millburn-avenue/",
        "index": 98.425,
        "franchise-entity-name": "EPOC SEVEN LLC"
    },
    "a0602f4b-888e-40ba-93af-b07bf403124d": {
        "name": "Orange Theory Milpitas - The Fields, CA",
        "address": "1317 McCandless Drive Milpitas, California 95035",
        "phone": "(408) 834-8143",
        "email": "studiomanager0928@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:15AM - 1:00PM",
            "SUNDAY": "8:15AM - 1:00PM"
        },
        "geolocation": [
            37.412259,
            -121.89937
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.0,
                    "duration": "indefinitely",
                    "model_conf": 152.78
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.9,
                    "duration": "indefinitely",
                    "model_conf": 180.77
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.44,
                    "duration": "indefinitely",
                    "model_conf": 110.86
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Milpitas - The Fields, CA",
            "slug": "a0602f4b-888e-40ba-93af-b07bf403124d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/1/23",
        "url": "https://www.orangetheory.com/en-us/locations/california/milpitas/1317-mccandless-drive",
        "index": 111.325,
        "franchise-entity-name": "KAM FITNESS, LLC"
    },
    "5d9a5663-0e6c-4276-9303-0dd3f8733271": {
        "name": "Orange Theory Milton, GA",
        "address": "12635 Crabapple Rd Milton, Georgia 30004",
        "phone": "(404) 890-7388",
        "email": "studiomanager0866@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            34.0889854,
            -84.34317
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.04,
                    "duration": "indefinitely",
                    "model_conf": 143.14
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.99,
                    "duration": "indefinitely",
                    "model_conf": 180.86
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.4,
                    "duration": "indefinitely",
                    "model_conf": 102.89
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Milton, GA",
            "slug": "5d9a5663-0e6c-4276-9303-0dd3f8733271"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/7/19",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/milton/12635-crabapple-rd/",
        "index": 98.425,
        "franchise-entity-name": "VVDC MILTON, LLC"
    },
    "0d7bc53c-f1b8-4f41-9737-4408d07ef4b1": {
        "name": "Orange Theory Third Ward, WI",
        "address": "336 N Milwaukee St Milwaukee, Wisconsin 53202",
        "phone": "(414) 269-5311",
        "email": "studiomanager1143@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            43.0349312,
            -87.90567
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.95,
                    "duration": "5 Months",
                    "model_conf": 153.68
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.83,
                    "duration": "indefinitely",
                    "model_conf": 149.19
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.08,
                    "duration": "2 Months",
                    "model_conf": 106.52
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Third Ward, WI",
            "slug": "0d7bc53c-f1b8-4f41-9737-4408d07ef4b1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/21/18",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/milwaukee/336-n-milwaukee-st/",
        "index": 93.4,
        "franchise-entity-name": "OT WISCONSIN THIRD WARD, LLC"
    },
    "701b6a46-5ac1-4249-995e-2d8f7efc2957": {
        "name": "Orange Theory North Loop, MN",
        "address": "411 WASHINGTON AVE N Minneapolis, Minnesota 55401",
        "phone": "(612) 217-8665",
        "email": "studiomanager0277@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:45PM",
            "TUESDAY": "5:30AM - 7:45PM",
            "WEDNESDAY": "5:30AM - 7:45PM",
            "THURSDAY": "5:30AM - 7:45PM",
            "FRIDAY": "5:30AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            44.98548,
            -93.274526
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.44,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.06,
                    "duration": "6 Months",
                    "model_conf": 135.2
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.46,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.84,
                    "duration": "indefinitely",
                    "model_conf": 170.24
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.97,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.62,
                    "duration": "indefinitely",
                    "model_conf": 103.14
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.98,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 27.03,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 37.58,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "North Loop, MN",
            "slug": "701b6a46-5ac1-4249-995e-2d8f7efc2957"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/22/21",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/minneapolis/411-washington-ave-n/",
        "index": 88.45,
        "franchise-entity-name": "MINNESOTA FITNESS SOLUTIONS, INC."
    },
    "7bcf0e4c-70aa-444d-877c-6a17c78a4593": {
        "name": "Orange Theory Minnetonka, MN",
        "address": "4703 County Road 101 Minnetonka, Minnesota 55345",
        "phone": "(952) 444-9693",
        "email": "studiomanager0978@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 1:30PM",
            "SUNDAY": "6:30AM - 1:30PM"
        },
        "geolocation": [
            44.9182625,
            -93.50248
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.44,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.14,
                    "duration": "5 Months",
                    "model_conf": 143.12
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.45,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.67,
                    "duration": "indefinitely",
                    "model_conf": 176.74
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.96,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.4,
                    "duration": "1 Months",
                    "model_conf": 103.76
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Minnetonka, MN",
            "slug": "7bcf0e4c-70aa-444d-877c-6a17c78a4593"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/5/18",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/minnetonka/4703-county-road-101/",
        "index": 88.45,
        "franchise-entity-name": "MINNETONKA WAYZATA FITNESS, LLC"
    },
    "9f3bcf07-cdf0-47e1-90ea-5ff7bff0ee21": {
        "name": "Orange Theory Miramar, FL",
        "address": "12316 Miramar Parkway Miramar, Florida 33025",
        "phone": "(954) 908-3636",
        "email": "studiomanager0156@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            25.9781532,
            -80.310432
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.13,
                    "duration": "indefinitely",
                    "model_conf": 143.68
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.64,
                    "duration": "4 Months",
                    "model_conf": 180.18
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.8,
                    "duration": "indefinitely",
                    "model_conf": 109.35
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Miramar, FL",
            "slug": "9f3bcf07-cdf0-47e1-90ea-5ff7bff0ee21"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/4/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/miramar/12316-miramar-parkway/",
        "index": 98.425,
        "franchise-entity-name": "MIRJEN, LLC"
    },
    "c1d27da9-8776-47ad-a8e6-f3fe1bc859ba": {
        "name": "Orange Theory Mission Viejo, CA",
        "address": "26012 Marguerite Parkway, Suite P Mission Viejo, California 92692",
        "phone": "(949) 335-7600",
        "email": "studiomanager0157@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:20PM",
            "TUESDAY": "5:00AM - 7:20PM",
            "WEDNESDAY": "5:00AM - 7:20PM",
            "THURSDAY": "5:00AM - 7:20PM",
            "FRIDAY": "5:00AM - 7:20PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            33.5863762,
            -117.6581
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.5,
                    "duration": "2 Months",
                    "model_conf": 129.74
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.53,
                    "duration": "indefinitely",
                    "model_conf": 180.89
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.62,
                    "duration": "indefinitely",
                    "model_conf": 110.31
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Mission Viejo, CA",
            "slug": "c1d27da9-8776-47ad-a8e6-f3fe1bc859ba"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/27/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/mission-viejo/26012-marguerite-parkway-suite-p/",
        "index": 103.375,
        "franchise-entity-name": "SPH ENTERPRISES, LLC"
    },
    "758f73a7-4ed7-4aa9-b1ca-fcf60141f804": {
        "name": "Orange Theory Mobile, AL",
        "address": "3664-B Airport Blvd Mobile, Alabama 36608",
        "phone": "(251) 340-7525",
        "email": "studiomanager0616@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "6:30AM - 10:30AM",
            "SUNDAY": "6:30AM - 10:30AM"
        },
        "geolocation": [
            30.6770687,
            -88.133522
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.93,
                    "duration": "indefinitely",
                    "model_conf": 153.29
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.36,
                    "duration": "2 Months",
                    "model_conf": 185.23
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.6,
                    "duration": "indefinitely",
                    "model_conf": 110.11
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Mobile, AL",
            "slug": "758f73a7-4ed7-4aa9-b1ca-fcf60141f804"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/mobile/3664-b-airport-blvd/",
        "index": 93.4,
        "franchise-entity-name": "GRIFFIT, INC."
    },
    "fe4d28c9-856e-4525-a0b5-99d78e34a784": {
        "name": "Orange Theory Modesto, CA",
        "address": "2001 McHenry Ave, Suite P Modesto, California 95350",
        "phone": "(209) 222-3662",
        "email": "studiomanager1000@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 3:00PM",
            "SUNDAY": "6:00AM - 3:00PM"
        },
        "geolocation": [
            37.6714859,
            -120.99583
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.74
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.7
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Modesto, CA",
            "slug": "fe4d28c9-856e-4525-a0b5-99d78e34a784"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/26/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/modesto/2001-mchenry-ave-suite-p/",
        "index": 93.4,
        "franchise-entity-name": "TYLER FITNESS, LLC"
    },
    "cd2cad24-1d07-4da5-9d88-2fb5fcc3695a": {
        "name": "Orange Theory Monroe, NY",
        "address": "300 Larkin Drive Monroe, New York 10950",
        "phone": "(845) 640-4005",
        "email": "studiomanager1178@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.3185234,
            -74.145981
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.9,
                    "duration": "indefinitely",
                    "model_conf": 153.43
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.03,
                    "duration": "indefinitely",
                    "model_conf": 185.68
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.92,
                    "duration": "indefinitely",
                    "model_conf": 111.83
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Monroe, NY",
            "slug": "cd2cad24-1d07-4da5-9d88-2fb5fcc3695a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/16/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/monroe/300-larkin-drive/",
        "index": 98.425,
        "franchise-entity-name": "MONROE FITNESS LLC"
    },
    "8915be6e-9d22-4da7-b747-38cc499c9ab3": {
        "name": "Orange Theory Monroeville, PA",
        "address": "4145 William Penn Highway Monroeville, Pennsylvania 15146",
        "phone": "(412) 643-3752",
        "email": "studiomanager1243@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:30AM - 12:00PM",
            "SUNDAY": "8:30AM - 12:00PM"
        },
        "geolocation": [
            40.4393807,
            -79.765854
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.33,
                    "duration": "3 Months",
                    "model_conf": 153.54
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.85,
                    "duration": "indefinitely",
                    "model_conf": 183.96
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.51,
                    "duration": "1 Months",
                    "model_conf": 109.75
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Monroeville, PA",
            "slug": "8915be6e-9d22-4da7-b747-38cc499c9ab3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/20/20",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/monroeville/4145-william-penn-highway/",
        "index": 93.4,
        "franchise-entity-name": "TANGERINE RETAIL VENTURES V, LLC"
    },
    "8f1942ce-bcfe-4827-87d2-fffb52c6b90a": {
        "name": "Orange Theory Montgomery, AL",
        "address": "1645 Perry Hill Road Montgomery, Alabama 36106",
        "phone": "(334) 409-2007",
        "email": "studiomanager0365@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            32.3560944,
            -86.243759
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.4,
                    "duration": "indefinitely",
                    "model_conf": 153.73
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.57,
                    "duration": "1 Months",
                    "model_conf": 185.39
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.27,
                    "duration": "indefinitely",
                    "model_conf": 111.89
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Montgomery, AL",
            "slug": "8f1942ce-bcfe-4827-87d2-fffb52c6b90a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/2/15",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/montgomery/1645-perry-hill-road/",
        "index": 93.4,
        "franchise-entity-name": "NICHEFITNESS, LLC"
    },
    "3c3a778c-cb13-44b0-b179-741ba6b381e6": {
        "name": "Orange Theory Montgomery, OH",
        "address": "11206 Montgomery Rd. Montgomery, Ohio 45249",
        "phone": "(513) 878-2400",
        "email": "studiomanager1295@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:20PM",
            "TUESDAY": "5:00AM - 7:20PM",
            "WEDNESDAY": "5:00AM - 7:20PM",
            "THURSDAY": "5:00AM - 7:20PM",
            "FRIDAY": "5:00AM - 7:20PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            39.2677002,
            -84.330978
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.1,
                    "duration": "indefinitely",
                    "model_conf": 145.97
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.37,
                    "duration": "indefinitely",
                    "model_conf": 185.09
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.32,
                    "duration": "indefinitely",
                    "model_conf": 106.85
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Montgomery, OH",
            "slug": "3c3a778c-cb13-44b0-b179-741ba6b381e6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/21",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/montgomery/11206-montgomery-rd/",
        "index": 93.4,
        "franchise-entity-name": "THE SEVEN FITNESS HILLS LLC"
    },
    "a7e31ab7-bc33-4782-9ead-83d170e4b242": {
        "name": "Orange Theory Montvale, NJ",
        "address": "44 Farm View Montvale, New Jersey 07645",
        "phone": "(201) 285-7818",
        "email": "studiomanager1054@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            41.0499916,
            -74.061272
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.26,
                    "duration": "3 Months",
                    "model_conf": 144.85
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.27,
                    "duration": "indefinitely",
                    "model_conf": 173.03
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.68,
                    "duration": "indefinitely",
                    "model_conf": 109.45
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Montvale, NJ",
            "slug": "a7e31ab7-bc33-4782-9ead-83d170e4b242"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/montvale/44-farm-view/",
        "index": 103.375,
        "franchise-entity-name": "EPOC FOUR LLC"
    },
    "f7568801-e855-462f-9d6c-a1269b3b89f0": {
        "name": "Orange Theory Moore, OK",
        "address": "825 SW 19th St., Suite 6 Moore, Oklahoma 73160",
        "phone": "(405) 703-5259",
        "email": "studiomanager0747@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            35.3198052,
            -97.505539
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.6,
                    "duration": "2 Months",
                    "model_conf": 153.53
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 8.42,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.73,
                    "duration": "indefinitely",
                    "model_conf": 185.43
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 13.52,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.3,
                    "duration": "indefinitely",
                    "model_conf": 111.91
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 16.92,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 30.52,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 42.42,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Moore, OK",
            "slug": "f7568801-e855-462f-9d6c-a1269b3b89f0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/oklahoma/moore/825-sw-19th-st-suite-6/",
        "index": 88.45,
        "franchise-entity-name": "JB FITNESS LLC"
    },
    "752b5152-e048-4991-a03d-ff710e101391": {
        "name": "Orange Theory Moorestown, NJ",
        "address": "400 West Route 38 Unit #1400 Moorestown, New Jersey 08057",
        "phone": "(856) 581-4801",
        "email": "studiomanager0327@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            39.9443932,
            -74.95919
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.79,
                    "duration": "indefinitely",
                    "model_conf": 151.04
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.27,
                    "duration": "2 Months",
                    "model_conf": 163.85
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.44,
                    "duration": "indefinitely",
                    "model_conf": 92.47
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Moorestown, NJ",
            "slug": "752b5152-e048-4991-a03d-ff710e101391"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/18/14",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/moorestown/400-west-route-38-unit-1400/",
        "index": 98.425,
        "franchise-entity-name": "JLD FITNESS LLC"
    },
    "e7646dda-4132-4af8-a68f-4acaa14e9b16": {
        "name": "Orange Theory Lake Norman - Mooresville, NC",
        "address": "631 Brawley School Rd. Mooresville, North Carolina 28117",
        "phone": "(704) 626-7515",
        "email": "studiomanager0390@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            35.5817528,
            -80.875946
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.84,
                    "duration": "indefinitely",
                    "model_conf": 151.57
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.21,
                    "duration": "indefinitely",
                    "model_conf": 184.29
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.86,
                    "duration": "indefinitely",
                    "model_conf": 111.64
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lake Norman - Mooresville, NC",
            "slug": "e7646dda-4132-4af8-a68f-4acaa14e9b16"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/5/17",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/mooresville/631-brawley-school-rd/",
        "index": 98.425,
        "franchise-entity-name": "ODAY FITNESS, LLC"
    },
    "5e56fd22-68ca-4945-bec4-0e83e2ffcbf4": {
        "name": "Orange Theory Morgan Hill, CA",
        "address": "50 E 3rd St Suite 100 Morgan Hill, California 95037",
        "phone": "(408) 762-5858",
        "email": "studiomanager0432@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:30PM",
            "TUESDAY": "6:00AM - 7:30PM",
            "WEDNESDAY": "6:00AM - 7:30PM",
            "THURSDAY": "6:00AM - 7:30PM",
            "FRIDAY": "6:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            37.1280861,
            -121.65128
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.49,
                    "duration": "indefinitely",
                    "model_conf": 153.82
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 147.87,
                    "duration": "2 Months",
                    "model_conf": 184.98
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.11,
                    "duration": "indefinitely",
                    "model_conf": 112.02
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Morgan Hill, CA",
            "slug": "5e56fd22-68ca-4945-bec4-0e83e2ffcbf4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/9/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/morgan-hill/50-e-3rd-st/",
        "index": 111.325,
        "franchise-entity-name": "LITTLE ROW FITNESS, LLC"
    },
    "c0aa4523-cac7-4e72-936b-45a551cc87c8": {
        "name": "Orange Theory Morgantown, WV",
        "address": "360 Suncrest Towne Centre Bldg. D. Morgantown, West Virginia 26505",
        "phone": "(304) 596-0500",
        "email": "studiomanager1439@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            39.6533699,
            -79.942261
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.67,
                    "duration": "1 Months",
                    "model_conf": 153.01
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.19,
                    "duration": "indefinitely",
                    "model_conf": 185.82
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.87,
                    "duration": "indefinitely",
                    "model_conf": 110.39
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Morgantown, WV",
            "slug": "c0aa4523-cac7-4e72-936b-45a551cc87c8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/2/20",
        "url": "https://www.orangetheory.com/en-us/locations/west-virginia/morgantown/360-suncrest-towne-centre/",
        "index": 93.4,
        "franchise-entity-name": "HM Fitness, LLC"
    },
    "537ea365-d4bc-4b5a-b634-ccc1ee62a3e9": {
        "name": "Orange Theory Morristown, NJ",
        "address": "191 E Hanover Avenue, E-7 Morris Township, New Jersey 07960",
        "phone": "(908) 991-7511",
        "email": "studiomanager0170@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.813378,
            -74.47367
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.29,
                    "duration": "indefinitely",
                    "model_conf": 145.82
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.74,
                    "duration": "indefinitely",
                    "model_conf": 170.56
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.43,
                    "duration": "indefinitely",
                    "model_conf": 105.14
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Morristown, NJ",
            "slug": "537ea365-d4bc-4b5a-b634-ccc1ee62a3e9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/26/23",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/191-e-hanover-avenue-e-7",
        "index": 98.425,
        "franchise-entity-name": ""
    },
    "ba5dbaa5-6058-4bbd-b2d0-b5dafbee3e1a": {
        "name": "Orange Theory Morrisville, NC",
        "address": "1112 Market Center Drive Morrisville, North Carolina 27560",
        "phone": "(919) 883-9469",
        "email": "studiomanager0133@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "8:00AM - 12:30PM",
            "SUNDAY": "8:00AM - 12:30PM"
        },
        "geolocation": [
            35.8078499,
            -78.814812
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.88,
                    "duration": "indefinitely",
                    "model_conf": 118.65
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.08,
                    "duration": "indefinitely",
                    "model_conf": 139.14
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.97,
                    "duration": "indefinitely",
                    "model_conf": 88.53
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Morrisville, NC",
            "slug": "ba5dbaa5-6058-4bbd-b2d0-b5dafbee3e1a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/31/14",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/morrisville/1112-market-center-drive/",
        "index": 98.425,
        "franchise-entity-name": "JACKELLE FITNESS, LLC"
    },
    "5c1afbd1-8640-404c-9330-117d3a3568c4": {
        "name": "Orange Theory Mount Dora, FL",
        "address": "3208 County Road 44B Mount Dora, Florida 32757",
        "phone": "(352) 448-6505",
        "email": "studiomanager0970@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            28.8241425,
            -81.64373
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.02,
                    "duration": "indefinitely",
                    "model_conf": 153.81
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.94,
                    "duration": "indefinitely",
                    "model_conf": 185.72
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.97,
                    "duration": "indefinitely",
                    "model_conf": 111.95
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Mount Dora, FL",
            "slug": "5c1afbd1-8640-404c-9330-117d3a3568c4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/7/20",
        "url": "https://www.orangetheory.com/en-us/locations/florida/mount-dora/3208-sr-44/",
        "index": 98.425,
        "franchise-entity-name": "FLORIDA FITNESS WORKS 1, LLC"
    },
    "784211d9-71e9-4f3b-a840-42193e4944c8": {
        "name": "Orange Theory Mountain View, CA",
        "address": "555 San Antonio Rd. Suite 40 Mountain View, California 94040",
        "phone": "(650) 810-2008",
        "email": "studiomanager0475@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 8:45PM",
            "TUESDAY": "6:15AM - 8:45PM",
            "WEDNESDAY": "6:15AM - 8:45PM",
            "THURSDAY": "6:15AM - 8:45PM",
            "FRIDAY": "6:15AM - 8:45PM",
            "SATURDAY": "8:15AM - 11:45PM",
            "SUNDAY": "8:15AM - 11:45PM"
        },
        "geolocation": [
            37.4026184,
            -122.11135
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.34,
                    "duration": "indefinitely",
                    "model_conf": 153.01
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.69,
                    "duration": "indefinitely",
                    "model_conf": 179.4
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.83,
                    "duration": "indefinitely",
                    "model_conf": 109.83
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Mountain View, CA",
            "slug": "784211d9-71e9-4f3b-a840-42193e4944c8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/25/22",
        "url": "https://www.orangetheory.com/en-us/locations/california/mountain-view/555-san-antonio-rd-suite-41/",
        "index": 111.325,
        "franchise-entity-name": "MKS PARTNERS FOUR, LLC"
    },
    "ee7a5e5d-c4a2-4194-a8c6-615bd479773b": {
        "name": "Orange Theory Mount Juliet, TN",
        "address": "11199 Lebanon Rd. Mt. Juliet, Tennessee 37122",
        "phone": "(615) 981-3800",
        "email": "studiomanager0367@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:15PM",
            "SUNDAY": "6:00AM - 12:15PM"
        },
        "geolocation": [
            36.230938,
            -86.513222
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.26,
                    "duration": "indefinitely",
                    "model_conf": 153.49
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.89,
                    "duration": "indefinitely",
                    "model_conf": 185.48
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.03,
                    "duration": "indefinitely",
                    "model_conf": 111.01
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Mount Juliet, TN",
            "slug": "ee7a5e5d-c4a2-4194-a8c6-615bd479773b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/7/17",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/mt-juliet/11199-lebanon-rd/",
        "index": 93.4,
        "franchise-entity-name": "HH MOUNT JULIET LLC"
    },
    "6a68214b-62a9-449c-be8f-a6473408ba7b": {
        "name": "Orange Theory Mt. Kisco, NY",
        "address": "222 E Main Street Mt. Kisco, New York 10549",
        "phone": "(914) 227-2757",
        "email": "studiomanager0692@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "6:45AM - 11:30AM",
            "SUNDAY": "6:45AM - 11:30AM"
        },
        "geolocation": [
            41.203064,
            -73.726578
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.56,
                    "duration": "1 Months",
                    "model_conf": 151.62
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.48,
                    "duration": "indefinitely",
                    "model_conf": 182.77
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.7,
                    "duration": "indefinitely",
                    "model_conf": 99.8
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Mt. Kisco, NY",
            "slug": "6a68214b-62a9-449c-be8f-a6473408ba7b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/mt-kisco/222-e-main-street/",
        "index": 103.375,
        "franchise-entity-name": "EU VORTEX V LLC"
    },
    "235057ba-443b-4a26-98f0-eb147c91cba0": {
        "name": "Orange Theory Mt. Pleasant, SC",
        "address": "695 Johnnie Dodds Blvd Mt. Pleasant, South Carolina 29464",
        "phone": "(843) 523-9938",
        "email": "studiomanager0874@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            32.8074112,
            -79.878242
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.11,
                    "duration": "5 Months",
                    "model_conf": 149.54
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.78,
                    "duration": "indefinitely",
                    "model_conf": 184.05
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.15,
                    "duration": "1 Months",
                    "model_conf": 107.58
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Mt. Pleasant, SC",
            "slug": "235057ba-443b-4a26-98f0-eb147c91cba0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/19/17",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/mt-pleasant/695-johnnie-dodds-blvd/",
        "index": 93.4,
        "franchise-entity-name": "JM MT. PLEASANT, LLC"
    },
    "51768c59-d1e4-4850-ba34-df2254227593": {
        "name": "Orange Theory Mueller, TX",
        "address": "1911 Aldrich St. Suite 175 Mueller, Texas 78723",
        "phone": "(512) 807-0401",
        "email": "studiomanager0941@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            30.2986507,
            -97.704376
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.22,
                    "duration": "indefinitely",
                    "model_conf": 145.15
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.63,
                    "duration": "1 Months",
                    "model_conf": 171.37
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.13,
                    "duration": "indefinitely",
                    "model_conf": 108.88
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Mueller, TX",
            "slug": "51768c59-d1e4-4850-ba34-df2254227593"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/9/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/mueller/1911-aldrich-st-suite-175/",
        "index": 108.325,
        "franchise-entity-name": "CENTRAL AUSTIN FITNESS PARTNERS LLC"
    },
    "8bcbde4a-9a8d-41b1-ba97-c193baa9b0e2": {
        "name": "Orange Theory Murfreesboro, TN",
        "address": "2615 Medical Center Parkway, Suite 1730 Murfreesboro, Tennessee 37129",
        "phone": "(615) 546-0255",
        "email": "studiomanager0155@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:30PM",
            "SUNDAY": "7:30AM - 12:30PM"
        },
        "geolocation": [
            35.8588944,
            -86.444244
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.08,
                    "duration": "indefinitely",
                    "model_conf": 153.74
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.83,
                    "duration": "indefinitely",
                    "model_conf": 185.48
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.05,
                    "duration": "indefinitely",
                    "model_conf": 111.93
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Murfreesboro, TN",
            "slug": "8bcbde4a-9a8d-41b1-ba97-c193baa9b0e2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/4/18",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/murfreesboro/2615-medical-center-parkway-suite-1730/",
        "index": 93.4,
        "franchise-entity-name": "MSL MURFREESBORO LLC"
    },
    "c6badd65-d2b3-4883-8c39-bd8ad2aa4e45": {
        "name": "Orange Theory Murphy, TX",
        "address": "601 E FM 544, Suite 300 Murphy, Texas 75094",
        "phone": "(972) 355-0809",
        "email": "studiomanager0530@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.01177,
            -96.59876
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.96,
                    "duration": "indefinitely",
                    "model_conf": 146.73
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.27,
                    "duration": "2 Months",
                    "model_conf": 174.37
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.46,
                    "duration": "indefinitely",
                    "model_conf": 107.45
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Murphy, TX",
            "slug": "c6badd65-d2b3-4883-8c39-bd8ad2aa4e45"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/8/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/murphy/601-14th-st-unit-300/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA FITNESS -ROCKWALL, LLC"
    },
    "1f0f0ac6-2ad3-4fb1-b3a5-93d5b3cd986e": {
        "name": "Orange Theory Myrtle Beach, SC",
        "address": "7753 North Kings Highway Myrtle Beach, South Carolina 29572",
        "phone": "(843) 353-0008",
        "email": "studiomanager0885@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            33.7485008,
            -78.817558
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.93,
                    "duration": "indefinitely",
                    "model_conf": 153.34
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.92,
                    "duration": "indefinitely",
                    "model_conf": 182.71
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.74,
                    "duration": "indefinitely",
                    "model_conf": 111.63
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Myrtle Beach, SC",
            "slug": "1f0f0ac6-2ad3-4fb1-b3a5-93d5b3cd986e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/15/19",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/myrtle-beach/7753-north-kings-highway/",
        "index": 93.4,
        "franchise-entity-name": "JM MYRTLE BEACH LLC"
    },
    "5a6782bf-ca8f-4748-a1ed-d6467c1d7ab1": {
        "name": "Orange Theory N.Olmsted, OH",
        "address": "26092 Brookpark Rd. N.Olmsted, Ohio 44070",
        "phone": "(440) 340-1229",
        "email": "studiomanager1129@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            41.4189911,
            -81.911995
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.64,
                    "duration": "indefinitely",
                    "model_conf": 152.27
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.12,
                    "duration": "3 Months",
                    "model_conf": 185.43
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.58,
                    "duration": "indefinitely",
                    "model_conf": 110.54
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "N.Olmsted, OH",
            "slug": "5a6782bf-ca8f-4748-a1ed-d6467c1d7ab1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/7/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/n-olmsted/26092-brookpark-rd/",
        "index": 93.4,
        "franchise-entity-name": "KSA4 LLC"
    },
    "31ba57c7-085d-4654-a36e-a77eb40a3db4": {
        "name": "Orange Theory New Orleans - Uptown, LA",
        "address": "5300 Tchoupitoulas Street New Orleans, Louisiana 70115",
        "phone": "(504) 308-1232",
        "email": "studiomanager0493@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:45PM",
            "TUESDAY": "6:00AM - 7:45PM",
            "WEDNESDAY": "6:00AM - 7:45PM",
            "THURSDAY": "6:00AM - 7:45PM",
            "FRIDAY": "6:00AM - 7:45PM",
            "SATURDAY": "9:15AM - 11:00AM",
            "SUNDAY": "9:15AM - 11:00AM"
        },
        "geolocation": [
            29.9160957,
            -90.114105
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.89,
                    "duration": "indefinitely",
                    "model_conf": 133.01
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.77,
                    "duration": "4 Months",
                    "model_conf": 177.25
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.83,
                    "duration": "indefinitely",
                    "model_conf": 108.94
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "New Orleans - Uptown, LA",
            "slug": "31ba57c7-085d-4654-a36e-a77eb40a3db4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/16/17",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/new-orleans/5300-tchoupitoulas-street/",
        "index": 93.4,
        "franchise-entity-name": "HAWTHORNE NOLA2, LLC"
    },
    "a528d9eb-d4e4-4b26-aab4-581325dab3c2": {
        "name": "Orange Theory Northbrook, IL",
        "address": "3101 Dundee Road. Suite A Northbrook, Illinois 60062",
        "phone": "(224) 541-6300",
        "email": "studiomanager0426@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            42.1370659,
            -87.855957
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.53,
                    "duration": "indefinitely",
                    "model_conf": 138.36
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.19,
                    "duration": "2 Months",
                    "model_conf": 165.41
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.81,
                    "duration": "indefinitely",
                    "model_conf": 100.85
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Northbrook, IL",
            "slug": "a528d9eb-d4e4-4b26-aab4-581325dab3c2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/northbrook/3101-dundee-road-suite-a/",
        "index": 103.375,
        "franchise-entity-name": "CHICAGOLAND FITNESS IV, LLC"
    },
    "b36214cf-7f04-467a-93a2-1caadde28d50": {
        "name": "Orange Theory Nanuet, NY",
        "address": "27 Rockland Plaza Nanuet, New York 10954",
        "phone": "(845) 367-7721",
        "email": "studiomanager0656@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:55PM",
            "TUESDAY": "5:00AM - 8:55PM",
            "WEDNESDAY": "5:00AM - 8:55PM",
            "THURSDAY": "5:00AM - 8:55PM",
            "FRIDAY": "5:00AM - 8:55PM",
            "SATURDAY": "6:30AM - 12:00PM",
            "SUNDAY": "6:30AM - 12:00PM"
        },
        "geolocation": [
            41.0992394,
            -74.01535
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.25,
                    "duration": "3 Months",
                    "model_conf": 149.7
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.03,
                    "duration": "indefinitely",
                    "model_conf": 179.73
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.5,
                    "duration": "1 Months",
                    "model_conf": 110.71
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Nanuet, NY",
            "slug": "b36214cf-7f04-467a-93a2-1caadde28d50"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/18/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/nanuet/27-rockland-plaza/",
        "index": 103.375,
        "franchise-entity-name": "EU VORTEX I, LLC"
    },
    "29d6c049-bbb5-42d1-8068-020f1a051122": {
        "name": "Orange Theory Napa, CA",
        "address": "3270 \u201a\u00c4\u00f6\u221a\u00d1\u221a\u00a8 F California Blvd Napa, California 94558",
        "phone": "(707) 345-1560",
        "email": "studiomanager0850@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:15AM - 11:30AM",
            "SUNDAY": "6:15AM - 11:30AM"
        },
        "geolocation": [
            38.320137,
            -122.3061
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.39,
                    "duration": "2 Months",
                    "model_conf": 153.31
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.03,
                    "duration": "indefinitely",
                    "model_conf": 185.42
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.24,
                    "duration": "1 Months",
                    "model_conf": 111.48
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Napa, CA",
            "slug": "29d6c049-bbb5-42d1-8068-020f1a051122"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/26/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/napa/3270-f-california-blvd/",
        "index": 108.325,
        "franchise-entity-name": "WINDSOR 3G, LLC"
    },
    "8f4e6e9e-636f-4c58-848e-04bc3f76daac": {
        "name": "Orange Theory Naperville, IL",
        "address": "24 W Gartner Rd, Suite 112 Naperville, Illinois 60540",
        "phone": "(331) 444-3232",
        "email": "studiomanager0031@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "6:00AM - 1:15PM",
            "SUNDAY": "6:00AM - 1:15PM"
        },
        "geolocation": [
            41.755333,
            -88.141815
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.77,
                    "duration": "indefinitely",
                    "model_conf": 143.08
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.84,
                    "duration": "indefinitely",
                    "model_conf": 174.92
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.44,
                    "duration": "indefinitely",
                    "model_conf": 105.09
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Naperville, IL",
            "slug": "8f4e6e9e-636f-4c58-848e-04bc3f76daac"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/11/13",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/naperville/8-west-gartner-road-suite-120/",
        "index": 103.375,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-WEST, LLC"
    },
    "d363952e-9531-4835-ac3e-860f2d0fbe97": {
        "name": "Orange Theory South Naperville, IL",
        "address": "2936 Showplace Drive Naperville, Illinois 60564",
        "phone": "(630) 857-9238",
        "email": "studiomanager0227@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            41.7111435,
            -88.20723
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.1,
                    "duration": "indefinitely",
                    "model_conf": 147.29
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.66,
                    "duration": "4 Months",
                    "model_conf": 179.65
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.76,
                    "duration": "indefinitely",
                    "model_conf": 108.01
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "South Naperville, IL",
            "slug": "d363952e-9531-4835-ac3e-860f2d0fbe97"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/29/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/naperville/2936-showplace-drive/",
        "index": 103.375,
        "franchise-entity-name": "MAJAMINO, LLC"
    },
    "62a9cda1-398b-4ee7-964a-d08ac0f10caf": {
        "name": "Orange Theory Naples Coastland Center, FL",
        "address": "1900 Tamiami Trail N Naples, Florida 34102",
        "phone": "(239) 687-4808",
        "email": "studiomanager0391@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            26.1699467,
            -81.797073
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.6,
                    "duration": "indefinitely",
                    "model_conf": 152.16
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.54,
                    "duration": "1 Months",
                    "model_conf": 185.63
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.49,
                    "duration": "indefinitely",
                    "model_conf": 103.1
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Naples Coastland Center, FL",
            "slug": "62a9cda1-398b-4ee7-964a-d08ac0f10caf"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/18",
        "url": "https://www.orangetheory.com/en-us/locations/florida/naples/1900-tamiami-trail-n/",
        "index": 98.425,
        "franchise-entity-name": "NAPLES COASTLAND COACHING LLC"
    },
    "27793b48-dd34-4e7d-9a66-3ce55c9b553f": {
        "name": "Orange Theory Naples, FL",
        "address": "7935 Airport Pulling Road, Unit 1 Naples, Florida 34109",
        "phone": "(239) 599-5650",
        "email": "studiomanager0229@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:40PM",
            "TUESDAY": "5:00AM - 7:40PM",
            "WEDNESDAY": "5:00AM - 7:40PM",
            "THURSDAY": "5:00AM - 7:40PM",
            "FRIDAY": "5:00AM - 7:40PM",
            "SATURDAY": "5:30AM - 1:55PM",
            "SUNDAY": "5:30AM - 1:55PM"
        },
        "geolocation": [
            26.2414665,
            -81.77047
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.82,
                    "duration": "indefinitely",
                    "model_conf": 151.37
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.0,
                    "duration": "indefinitely",
                    "model_conf": 185.56
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.03,
                    "duration": "indefinitely",
                    "model_conf": 99.0
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Naples, FL",
            "slug": "27793b48-dd34-4e7d-9a66-3ce55c9b553f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/12/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/naples/7935-airport-pulling-road-unit-1/",
        "index": 98.425,
        "franchise-entity-name": "NAPLES COACHING LLC"
    },
    "bf90e795-cca9-42e3-be3f-5de647685f36": {
        "name": "Orange Theory South Nashua, NH",
        "address": "104 Spit Brook Road Suite C Nashua, New Hampshire 03062",
        "phone": "(603) 413-8757",
        "email": "studiomanager0468@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 11:00AM",
            "SUNDAY": "7:15AM - 11:00AM"
        },
        "geolocation": [
            42.7083702,
            -71.456886
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.03,
                    "duration": "indefinitely",
                    "model_conf": 153.29
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.14,
                    "duration": "indefinitely",
                    "model_conf": 185.0
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.86,
                    "duration": "indefinitely",
                    "model_conf": 111.26
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "South Nashua, NH",
            "slug": "bf90e795-cca9-42e3-be3f-5de647685f36"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-hampshire/nashua/104-spit-brook-road-suite-c/",
        "index": 98.425,
        "franchise-entity-name": "AGASTACHE, LLC"
    },
    "4a202265-38b2-4322-b6e0-f42f2ef4efd2": {
        "name": "Orange Theory Nashua, NH",
        "address": "345 Amherst Street Nashua, New Hampshire 03063",
        "phone": "(603) 882-6800",
        "email": "studiomanager0222@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 6:45PM",
            "TUESDAY": "4:45AM - 6:45PM",
            "WEDNESDAY": "4:45AM - 6:45PM",
            "THURSDAY": "4:45AM - 6:45PM",
            "FRIDAY": "4:45AM - 6:45PM",
            "SATURDAY": "6:00AM - 10:45AM",
            "SUNDAY": "6:00AM - 10:45AM"
        },
        "geolocation": [
            42.7833099,
            -71.503227
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.81,
                    "duration": "indefinitely",
                    "model_conf": 153.49
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.42,
                    "duration": "indefinitely",
                    "model_conf": 185.2
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.75,
                    "duration": "indefinitely",
                    "model_conf": 111.55
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Nashua, NH",
            "slug": "4a202265-38b2-4322-b6e0-f42f2ef4efd2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/new-hampshire/nashua/345-amherst-street/",
        "index": 98.425,
        "franchise-entity-name": "AUREUM, LLC"
    },
    "506d039c-3b55-4e08-b8bb-2b5d13dd9953": {
        "name": "Orange Theory Downtown Nashville, TN",
        "address": "1022 Church Street Nashville, Tennessee 37209",
        "phone": "(615) 866-3522",
        "email": "studiomanager1466@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:30PM",
            "TUESDAY": "5:15AM - 7:30PM",
            "WEDNESDAY": "5:15AM - 7:30PM",
            "THURSDAY": "5:15AM - 7:30PM",
            "FRIDAY": "5:15AM - 7:30PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            35.236318,
            -88.245705
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.61
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.84
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.11
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Downtown Nashville, TN",
            "slug": "506d039c-3b55-4e08-b8bb-2b5d13dd9953"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/10/23",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/nashville/1022-church-st/",
        "index": 93.4,
        "franchise-entity-name": "Downtown Fit LLC"
    },
    "63a17301-d907-4ef0-b557-faa12ea67ff0": {
        "name": "Orange Theory Nashville-Midtown, TN",
        "address": "121 17th Avenue South Nashville, Tennessee 37203",
        "phone": "(615) 988-9288",
        "email": "studiomanager0240@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 2:30PM",
            "SUNDAY": "7:30AM - 2:30PM"
        },
        "geolocation": [
            36.1520767,
            -86.793449
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 71.22,
                    "duration": "indefinitely",
                    "model_conf": 138.34
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 105.9,
                    "duration": "11 Months",
                    "model_conf": 150.95
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 170.46,
                    "duration": "indefinitely",
                    "model_conf": 110.06
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Nashville-Midtown, TN",
            "slug": "63a17301-d907-4ef0-b557-faa12ea67ff0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/26/17",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/nashville/121-17th-avenue-south/",
        "index": 93.4,
        "franchise-entity-name": "Fit to Be LLC"
    },
    "676ceabe-62ed-4f13-945f-91edfb6cfdcb": {
        "name": "Orange Theory Nashville-Green Hills, TN",
        "address": "2104 Crestmoor Rd Nashville, Tennessee 37215",
        "phone": "(615) 800-6118",
        "email": "studiomanager0144@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            36.1088829,
            -86.813133
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.7,
                    "duration": "1 Months",
                    "model_conf": 132.25
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.89,
                    "duration": "indefinitely",
                    "model_conf": 136.53
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.75,
                    "duration": "indefinitely",
                    "model_conf": 109.78
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Nashville-Green Hills, TN",
            "slug": "676ceabe-62ed-4f13-945f-91edfb6cfdcb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/25/15",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/nashville/2104-crestmoor-rd/",
        "index": 93.4,
        "franchise-entity-name": "SOFIT LLC"
    },
    "f123d531-b425-4897-b007-f33890f3efde": {
        "name": "Orange Theory Nashville - Melrose, TN",
        "address": "2603 8th Ave South Nashville, Tennessee 37204",
        "phone": "(615) 988-1334",
        "email": "studiomanager1200@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 2:30PM",
            "SUNDAY": "7:00AM - 2:30PM"
        },
        "geolocation": [
            36.1220665,
            -86.776138
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.67,
                    "duration": "indefinitely",
                    "model_conf": 137.08
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.6,
                    "duration": "1 Months",
                    "model_conf": 148.5
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.07,
                    "duration": "indefinitely",
                    "model_conf": 110.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Nashville - Melrose, TN",
            "slug": "f123d531-b425-4897-b007-f33890f3efde"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/19/19",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/nashville/2603-8th-ave-south/",
        "index": 93.4,
        "franchise-entity-name": "NASHFIT LLC"
    },
    "c5242f58-df7c-4527-9dc9-dce70e4ca12f": {
        "name": "Orange Theory West Nashville, TN",
        "address": "6720 Charlotte Pike, Suite 102 Nashville, Tennessee 37209",
        "phone": "(615) 933-0084",
        "email": "studiomanager0396@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            36.1350746,
            -86.888489
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.14,
                    "duration": "indefinitely",
                    "model_conf": 144.41
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.5,
                    "duration": "1 Months",
                    "model_conf": 165.73
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.48,
                    "duration": "indefinitely",
                    "model_conf": 110.86
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "West Nashville, TN",
            "slug": "c5242f58-df7c-4527-9dc9-dce70e4ca12f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/15",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/nashville/6720-charlotte-pike-suite-102/",
        "index": 93.4,
        "franchise-entity-name": "LKB FITNESS, LLC"
    },
    "e4b0a8da-790e-4e31-91bd-ceef1effd7f5": {
        "name": "Orange Theory Natick, MA",
        "address": "21 Main Street Natick, Massachusetts 01760",
        "phone": "(508) 455-5524",
        "email": "studiomanager0401@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            42.284317,
            -71.347725
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.76,
                    "duration": "1 Months",
                    "model_conf": 152.6
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.8,
                    "duration": "indefinitely",
                    "model_conf": 185.17
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.08,
                    "duration": "indefinitely",
                    "model_conf": 111.83
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Natick, MA",
            "slug": "e4b0a8da-790e-4e31-91bd-ceef1effd7f5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/19/15",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/natick/21-main-street/",
        "index": 103.375,
        "franchise-entity-name": "QIG FITNESS NATICK LLC"
    },
    "b043ffb1-7e68-4117-8f4b-990ce1075630": {
        "name": "Orange Theory New Albany, OH",
        "address": "7340 Fodor Rd. New Albany, Ohio 43054",
        "phone": "(614) 918-7038",
        "email": "studiomanager0433@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            40.0882378,
            -82.826149
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.69,
                    "duration": "1 Months",
                    "model_conf": 150.54
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.92,
                    "duration": "indefinitely",
                    "model_conf": 182.38
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.25,
                    "duration": "1 Months",
                    "model_conf": 110.23
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "New Albany, OH",
            "slug": "b043ffb1-7e68-4117-8f4b-990ce1075630"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/6/15",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/new-albany/7340-fodor-rd/",
        "index": 93.4,
        "franchise-entity-name": "KO GROUP FITNESS LLC"
    },
    "6424829c-b398-4bb8-8633-d3823db194f7": {
        "name": "Orange Theory New Braunfels, TX",
        "address": "1691 W State Highway 46 New Braunfels, Texas 78132",
        "phone": "(830) 312-4660",
        "email": "studiomanager0516@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            29.714407,
            -98.160362
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.85,
                    "duration": "indefinitely",
                    "model_conf": 153.69
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.07,
                    "duration": "indefinitely",
                    "model_conf": 184.38
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.65,
                    "duration": "indefinitely",
                    "model_conf": 111.27
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "New Braunfels, TX",
            "slug": "6424829c-b398-4bb8-8633-d3823db194f7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/14/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/new-braunfels/1691-w-state-highway-46/",
        "index": 98.425,
        "franchise-entity-name": "AVANTI HOLDINGS, LLC"
    },
    "d6676d13-0dc3-4c4a-83a2-1666df76c69c": {
        "name": "Orange Theory New City, NY",
        "address": "195 South Main Street New City, New York 10956",
        "phone": "(845) 213-2121",
        "email": "studiomanager0658@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            41.1399307,
            -73.988869
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.71,
                    "duration": "1 Months",
                    "model_conf": 152.08
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.16,
                    "duration": "indefinitely",
                    "model_conf": 183.12
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.77,
                    "duration": "indefinitely",
                    "model_conf": 111.35
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "New City, NY",
            "slug": "d6676d13-0dc3-4c4a-83a2-1666df76c69c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/13/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-city/195-south-main-street/",
        "index": 103.375,
        "franchise-entity-name": "EU VORTEX III, LLC"
    },
    "31196000-7f87-41e6-be5a-080add5a4f20": {
        "name": "Orange Theory New Haven, CT",
        "address": "25 Grove Street New Haven, Connecticut 06511",
        "phone": "(203) 821-7051",
        "email": "studiomanager1251@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:45AM - 12:45PM",
            "SUNDAY": "6:45AM - 12:45PM"
        },
        "geolocation": [
            41.31006,
            -72.921207
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.92,
                    "duration": "indefinitely",
                    "model_conf": 150.89
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.98,
                    "duration": "indefinitely",
                    "model_conf": 184.04
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.67,
                    "duration": "indefinitely",
                    "model_conf": 110.57
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "New Haven, CT",
            "slug": "31196000-7f87-41e6-be5a-080add5a4f20"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/22",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/new-haven/25-grove-st/",
        "index": 98.425,
        "franchise-entity-name": "3 JUDGES FITNESS LLC"
    },
    "bfb7ac4d-72eb-417d-91e2-059638ee607a": {
        "name": "Orange Theory New Hyde Park, NY",
        "address": "1706 Lakeville Rd New Hyde Park, New York 11040",
        "phone": "(516) 858-5600",
        "email": "studiomanager1258@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "6:30AM - 12:45PM",
            "SUNDAY": "6:30AM - 12:45PM"
        },
        "geolocation": [
            40.743811,
            -73.693531
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 96.31,
                    "duration": "10 Months",
                    "model_conf": 105.5
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 142.01,
                    "duration": "indefinitely",
                    "model_conf": 127.51
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 197.19,
                    "duration": "3 Months",
                    "model_conf": 76.58
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "New Hyde Park, NY",
            "slug": "bfb7ac4d-72eb-417d-91e2-059638ee607a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/23/21",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-hyde-park/1706-lakeville-road/",
        "index": 108.325,
        "franchise-entity-name": "BMDcore, LLC"
    },
    "191ef85a-0e83-4c90-b3cd-17f820235f59": {
        "name": "Orange Theory New Lenox-Manhattan",
        "address": "2081 E. Laraway Road New Lenox, Illinois 60451",
        "phone": "(815) 669-1800",
        "email": "studiomanager1349@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "6:15AM - 11:00AM",
            "SUNDAY": "6:15AM - 11:00AM"
        },
        "geolocation": [
            41.483551,
            -87.94088
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.22,
                    "duration": "indefinitely",
                    "model_conf": 152.35
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.68,
                    "duration": "indefinitely",
                    "model_conf": 182.34
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.37,
                    "duration": "indefinitely",
                    "model_conf": 110.53
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "New Lenox-Manhattan",
            "slug": "191ef85a-0e83-4c90-b3cd-17f820235f59"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/1/20",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/new-lenox/2081-e-laraway-road/",
        "index": 103.375,
        "franchise-entity-name": "Hearne Fitness Two LLC"
    },
    "d7ca037d-9e16-4c8c-b2a9-7ce855ca50cd": {
        "name": "Orange Theory New Orleans-Mid City, LA",
        "address": "4141 Bienville St. , Suite 107 New Orleans, Louisiana 70119",
        "phone": "(504) 408-2602",
        "email": "studiomanager0295@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            29.9775715,
            -90.099998
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.84,
                    "duration": "indefinitely",
                    "model_conf": 117.26
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.82,
                    "duration": "indefinitely",
                    "model_conf": 176.41
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.49,
                    "duration": "1 Months",
                    "model_conf": 107.2
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "New Orleans-Mid City, LA",
            "slug": "d7ca037d-9e16-4c8c-b2a9-7ce855ca50cd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/15",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/new-orleans/4141-bienville-st-suite-107/",
        "index": 93.4,
        "franchise-entity-name": "HAWTHORNE NOLA1, LLC"
    },
    "a4cb1c72-93fd-4b19-8f5a-b7850d3f8638": {
        "name": "Orange Theory New Providence, NJ",
        "address": "1260 Springfield Avenue, #11A New Providence, New Jersey 07974",
        "phone": "(908) 458-9353",
        "email": "studiomanager0169@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            40.7049141,
            -74.40387
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 73.26,
                    "duration": "indefinitely",
                    "model_conf": 114.18
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 125.53,
                    "duration": "indefinitely",
                    "model_conf": 137.9
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 175.25,
                    "duration": "8 Months",
                    "model_conf": 85.11
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "New Providence, NJ",
            "slug": "a4cb1c72-93fd-4b19-8f5a-b7850d3f8638"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/2/15",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/new-providence/1260-springfield-avenue-11a/",
        "index": 98.425,
        "franchise-entity-name": "MORRIS FITNESS LLC"
    },
    "e148d9b3-7e0b-43b4-8a51-aaee750deda9": {
        "name": "Orange Theory New Smyrna Beach, FL",
        "address": "1944 SR 44 New Smyrna Beach, Florida 32168",
        "phone": "(386) 951-6201",
        "email": "studiomanager1536@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "6:15AM - 10:15AM",
            "SUNDAY": "6:15AM - 10:15AM"
        },
        "geolocation": [
            29.011795,
            -80.946254
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.9,
                    "duration": "indefinitely",
                    "model_conf": 153.04
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.2,
                    "duration": "indefinitely",
                    "model_conf": 185.49
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.79,
                    "duration": "indefinitely",
                    "model_conf": 112.14
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "New Smyrna Beach, FL",
            "slug": "e148d9b3-7e0b-43b4-8a51-aaee750deda9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/12/21",
        "url": "https://www.orangetheory.com/en-us/locations/florida/new-smyrna-beach/1944-sr-44/",
        "index": 98.425,
        "franchise-entity-name": "FLORIDA FITNESS WORKS 9, LLC"
    },
    "9f84b513-d43a-42ab-98dd-0293b98c8e62": {
        "name": "Orange Theory Manhattan-FIDI",
        "address": "100 William St. New York, New York 10038",
        "phone": "(646) 650-2122",
        "email": "studiomanager0894@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:30PM",
            "TUESDAY": "5:30AM - 8:30PM",
            "WEDNESDAY": "5:30AM - 8:30PM",
            "THURSDAY": "5:30AM - 8:30PM",
            "FRIDAY": "5:30AM - 8:30PM",
            "SATURDAY": "7:30AM - 1:30AM",
            "SUNDAY": "7:30AM - 1:30AM"
        },
        "geolocation": [
            40.7083817,
            -74.007202
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 120.14,
                    "duration": "indefinitely",
                    "model_conf": 110.0
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 197.42,
                    "duration": "3 Months",
                    "model_conf": 131.93
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 279.88,
                    "duration": "indefinitely",
                    "model_conf": 80.77
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan-FIDI",
            "slug": "9f84b513-d43a-42ab-98dd-0293b98c8e62"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/25/21",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/100-william-st/",
        "index": 133.3,
        "franchise-entity-name": "DOWNTOWN FITNESS PARTNERS LLC"
    },
    "1793ebdf-91f3-460e-86e9-381d3b054955": {
        "name": "Orange Theory Manhattan - UWS W 72",
        "address": "120 West 72nd Street, New York, NY 10023 New York, New York 10023",
        "phone": "(646) 679-6618",
        "email": "studiomanager0035@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 9:15PM",
            "TUESDAY": "6:00AM - 9:15PM",
            "WEDNESDAY": "6:00AM - 9:15PM",
            "THURSDAY": "6:00AM - 9:15PM",
            "FRIDAY": "6:00AM - 9:15PM",
            "SATURDAY": "8:15AM - 3:30PM",
            "SUNDAY": "8:15AM - 3:30PM"
        },
        "geolocation": [
            40.7775154,
            -73.979744
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 118.1,
                    "duration": "2 Months",
                    "model_conf": 109.17
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 199.7,
                    "duration": "indefinitely",
                    "model_conf": 130.97
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 277.6,
                    "duration": "1 Months",
                    "model_conf": 80.35
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan - UWS W 72",
            "slug": "1793ebdf-91f3-460e-86e9-381d3b054955"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/120-west-72nd-street-new-york-ny-10023/",
        "index": 133.3,
        "franchise-entity-name": "EPOC UWS LLC"
    },
    "9e41d683-0acf-46c5-9b56-e02b9a8c4b43": {
        "name": "Orange Theory Manhattan - Chelsea, NY",
        "address": "124 West 23rd St New York, New York 10011",
        "phone": "(646) 588-0133",
        "email": "studiomanager0034@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:45PM",
            "TUESDAY": "6:00AM - 8:45PM",
            "WEDNESDAY": "6:00AM - 8:45PM",
            "THURSDAY": "6:00AM - 8:45PM",
            "FRIDAY": "6:00AM - 8:45PM",
            "SATURDAY": "9:30AM - 2:15PM",
            "SUNDAY": "9:30AM - 2:15PM"
        },
        "geolocation": [
            40.7430649,
            -73.993927
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.48,
                    "duration": "indefinitely",
                    "model_conf": 89.37
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.68,
                    "duration": "indefinitely",
                    "model_conf": 106.8
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 279.27,
                    "duration": "indefinitely",
                    "model_conf": 66.93
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan - Chelsea, NY",
            "slug": "9e41d683-0acf-46c5-9b56-e02b9a8c4b43"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/6/15",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/124-west-23rd-st/",
        "index": 133.3,
        "franchise-entity-name": "OTF MAN ONE LLC"
    },
    "ccd386bf-2b3c-4ba9-964f-1be73e192b00": {
        "name": "Orange Theory Amherst - The Blvd, NY",
        "address": "1551 Niagara Falls Blvd New York, New York 14228",
        "phone": "(716) 970-4441",
        "email": "studiomanager0458@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 11:45AM",
            "SUNDAY": "7:15AM - 11:45AM"
        },
        "geolocation": [
            42.9963036,
            -78.82016
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.0,
                    "duration": "indefinitely",
                    "model_conf": 150.7
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.99,
                    "duration": "3 Months",
                    "model_conf": 185.23
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.77,
                    "duration": "indefinitely",
                    "model_conf": 111.54
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Amherst - The Blvd, NY",
            "slug": "ccd386bf-2b3c-4ba9-964f-1be73e192b00"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/20/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/1551-niagara-falls-blvd/",
        "index": 98.425,
        "franchise-entity-name": "BOULEVARD FITNESS, LLC"
    },
    "5bf7bb42-6d2e-46c9-96ec-398601f9805d": {
        "name": "Orange Theory Manhattan-UES E86th St., NY",
        "address": "220 East 86th St New York, New York 10028",
        "phone": "(646) 979-1020",
        "email": "studiomanager1244@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            40.7782173,
            -73.953201
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.01,
                    "duration": "indefinitely",
                    "model_conf": 111.43
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.88,
                    "duration": "indefinitely",
                    "model_conf": 133.82
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 279.36,
                    "duration": "indefinitely",
                    "model_conf": 81.77
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan-UES E86th St., NY",
            "slug": "5bf7bb42-6d2e-46c9-96ec-398601f9805d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/15/21",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/220-east-86th-st/",
        "index": 133.3,
        "franchise-entity-name": "MIDTOWN FITNESS PARTNERS LLC - 1244"
    },
    "bbe5bf2b-9964-4d01-8cf3-430872e7424e": {
        "name": "Orange Theory Manhattan - Midtown West, NY",
        "address": "321 West 37th Street New York, New York 10018",
        "phone": "(646) 829-9330",
        "email": "studiomanager0730@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:45PM",
            "TUESDAY": "6:00AM - 7:45PM",
            "WEDNESDAY": "6:00AM - 7:45PM",
            "THURSDAY": "6:00AM - 7:45PM",
            "FRIDAY": "6:00AM - 7:45PM",
            "SATURDAY": "9:00AM - 12:30PM",
            "SUNDAY": "9:00AM - 12:30PM"
        },
        "geolocation": [
            40.754631,
            -73.992813
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.01,
                    "duration": "indefinitely",
                    "model_conf": 92.7
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.49,
                    "duration": "indefinitely",
                    "model_conf": 110.64
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 278.77,
                    "duration": "indefinitely",
                    "model_conf": 69.12
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan - Midtown West, NY",
            "slug": "bbe5bf2b-9964-4d01-8cf3-430872e7424e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/13/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/321-west-37th-street/",
        "index": 133.3,
        "franchise-entity-name": "EMPIRE 37TH ST LLC"
    },
    "b66ff3c9-d679-43c5-b247-985493a47dec": {
        "name": "Orange Theory Manhattan-West Village",
        "address": "391 6TH AVE New York, New York 10014",
        "phone": "(646) 626-4412",
        "email": "studiomanager1402@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:45AM - 8:30PM",
            "TUESDAY": "5:45AM - 8:30PM",
            "WEDNESDAY": "5:45AM - 8:30PM",
            "THURSDAY": "5:45AM - 8:30PM",
            "FRIDAY": "5:45AM - 8:30PM",
            "SATURDAY": "8:15AM - 1:00PM",
            "SUNDAY": "8:15AM - 1:00PM"
        },
        "geolocation": [
            40.73334,
            -74.00013
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.92,
                    "duration": "indefinitely",
                    "model_conf": 94.09
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.48,
                    "duration": "1 Months",
                    "model_conf": 112.75
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 279.5,
                    "duration": "indefinitely",
                    "model_conf": 70.22
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan-West Village",
            "slug": "b66ff3c9-d679-43c5-b247-985493a47dec"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/1/23",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/391-6th-ave/",
        "index": 133.3,
        "franchise-entity-name": "EMPIRE WEST VILLAGE LLC"
    },
    "f3b1bab5-405a-4eef-9d31-7707a64cca71": {
        "name": "Orange Theory Manhattan - Astor Place, NY",
        "address": "51 Astor Place New York, New York 10003",
        "phone": "(646) 357-3057",
        "email": "studiomanager0198@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:45PM",
            "TUESDAY": "5:30AM - 7:45PM",
            "WEDNESDAY": "5:30AM - 7:45PM",
            "THURSDAY": "5:30AM - 7:45PM",
            "FRIDAY": "5:30AM - 7:45PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            40.7301445,
            -73.990425
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 120.17,
                    "duration": "indefinitely",
                    "model_conf": 89.97
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 197.69,
                    "duration": "2 Months",
                    "model_conf": 108.0
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 279.45,
                    "duration": "indefinitely",
                    "model_conf": 67.33
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan - Astor Place, NY",
            "slug": "f3b1bab5-405a-4eef-9d31-7707a64cca71"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/12/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/51-astor-place/",
        "index": 133.3,
        "franchise-entity-name": "JM ASTOR PLACE FITNESS LLC"
    },
    "aba42e7f-676c-493a-aac1-63398e469795": {
        "name": "Orange Theory Manhattan - SoHo- Crosby St",
        "address": "62 Crosby Street New York, New York 10012",
        "phone": "(646) 876-0050",
        "email": "studiomanager0302@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:45AM - 8:15PM",
            "TUESDAY": "6:45AM - 8:15PM",
            "WEDNESDAY": "6:45AM - 8:15PM",
            "THURSDAY": "6:45AM - 8:15PM",
            "FRIDAY": "6:45AM - 8:15PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            40.722374,
            -73.998573
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 120.41,
                    "duration": "indefinitely",
                    "model_conf": 98.04
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 197.86,
                    "duration": "2 Months",
                    "model_conf": 117.68
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 279.95,
                    "duration": "indefinitely",
                    "model_conf": 72.77
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan - SoHo- Crosby St",
            "slug": "aba42e7f-676c-493a-aac1-63398e469795"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/27/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/62-crosby-street/",
        "index": 133.3,
        "franchise-entity-name": "OTF STUDIOS NOCAL LLC"
    },
    "b1fcf53b-3d7b-46a8-83a6-478a75c8869c": {
        "name": "Orange Theory Manhattan-Plaza District",
        "address": "715 Lexington Avenue New York, New York 10022",
        "phone": "(646) 838-1243",
        "email": "studiomanager1395@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 6:30PM",
            "TUESDAY": "6:15AM - 6:30PM",
            "WEDNESDAY": "6:15AM - 6:30PM",
            "THURSDAY": "6:15AM - 6:30PM",
            "FRIDAY": "6:15AM - 6:30PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            40.761331,
            -73.968511
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.06,
                    "duration": "indefinitely",
                    "model_conf": 92.87
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.04,
                    "duration": "1 Months",
                    "model_conf": 111.42
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 278.95,
                    "duration": "indefinitely",
                    "model_conf": 69.0
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan-Plaza District",
            "slug": "b1fcf53b-3d7b-46a8-83a6-478a75c8869c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/11/22",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/715-lexington-avenue/",
        "index": 133.3,
        "franchise-entity-name": "OTF Studios, LLC - 1395"
    },
    "b894e945-3a30-4a40-9183-c4b7be651841": {
        "name": "Orange Theory Manhattan-UWS W 92, NY",
        "address": "73 W 92ND ST New York, New York 10014",
        "phone": "(646) 829-9595",
        "email": "studiomanager0036@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 3:00PM",
            "SUNDAY": "8:00AM - 3:00PM"
        },
        "geolocation": [
            40.79011,
            -73.96879
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 118.81,
                    "duration": "indefinitely",
                    "model_conf": 119.21
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 199.58,
                    "duration": "indefinitely",
                    "model_conf": 143.25
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 278.4,
                    "duration": "indefinitely",
                    "model_conf": 87.39
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan-UWS W 92, NY",
            "slug": "b894e945-3a30-4a40-9183-c4b7be651841"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/24/22",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/73-w-92nd-st/",
        "index": 133.3,
        "franchise-entity-name": "EPOC 92nd ST LLC"
    },
    "0b58e83d-d28d-49b1-a752-fe3c11f44c33": {
        "name": "Orange Theory Manhattan-Hell&#39;s Kitchen, NY",
        "address": "730 9th Ave New York, New York 10019",
        "phone": "(646) 650-2910",
        "email": "studiomanager1403@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:15PM",
            "TUESDAY": "5:30AM - 7:15PM",
            "WEDNESDAY": "5:30AM - 7:15PM",
            "THURSDAY": "5:30AM - 7:15PM",
            "FRIDAY": "5:30AM - 7:15PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            40.763104,
            -73.98888
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 5.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 118.69,
                    "duration": "1 Months",
                    "model_conf": 97.49
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.29,
                    "duration": "1 Months",
                    "model_conf": 116.57
                }
            },
            "Premier": {
                "cost": 279,
                "tax-amount": 12.56,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 278.19,
                    "duration": "1 Months",
                    "model_conf": 72.32
                }
            }
        },
        "services": {
            "10 Session Pack - $320": {
                "cost": 320,
                "tax-amount": 14.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $600": {
                "cost": 600,
                "tax-amount": 27,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $850": {
                "cost": 850,
                "tax-amount": 38.25,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 34,
            "late-cancel-2": 20,
            "10-pack": 320,
            "20-pack": 600,
            "premier-family-addon-6-month-corp-2": 249,
            "surcharge-2": 20,
            "30-pack": 850,
            "premier": 279,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 179,
            "elite": 199,
            "additional-session-2": 31,
            "name": "Manhattan-Hell&#39;s Kitchen, NY",
            "slug": "0b58e83d-d28d-49b1-a752-fe3c11f44c33"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/22",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/new-york/730-9th-ave/",
        "index": 133.3,
        "franchise-entity-name": "EMPIRE HELL&#39;S KITCHEN LLC"
    },
    "78011b89-191c-4afe-80de-6504ed23ec2c": {
        "name": "Orange Theory Newark, DE",
        "address": "230 E Main Street #105 Newark, Delaware 19711",
        "phone": "(302) 273-8301",
        "email": "studiomanager0965@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.684967,
            -75.743225
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.69,
                    "duration": "1 Months",
                    "model_conf": 151.43
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.57,
                    "duration": "indefinitely",
                    "model_conf": 183.91
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.66,
                    "duration": "indefinitely",
                    "model_conf": 108.83
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Newark, DE",
            "slug": "78011b89-191c-4afe-80de-6504ed23ec2c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/29/17",
        "url": "https://www.orangetheory.com/en-us/locations/delaware/newark/230-e-main-st-28a/",
        "index": 98.425,
        "franchise-entity-name": "ALPHA FITNESS NEWARK LLC"
    },
    "b40afbed-6b4b-4122-8307-a970ca97da70": {
        "name": "Orange Theory Christiana, DE",
        "address": "3136 Fashion Center Blvd Newark, Delaware 19702",
        "phone": "(302) 691-9949",
        "email": "studiomanager1066@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:30PM",
            "SUNDAY": "7:30AM - 11:30PM"
        },
        "geolocation": [
            39.6703911,
            -75.649788
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.75,
                    "duration": "indefinitely",
                    "model_conf": 148.2
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.09,
                    "duration": "2 Months",
                    "model_conf": 182.16
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.38,
                    "duration": "indefinitely",
                    "model_conf": 105.14
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Christiana, DE",
            "slug": "b40afbed-6b4b-4122-8307-a970ca97da70"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/19/18",
        "url": "https://www.orangetheory.com/en-us/locations/delaware/newark/3136-fashion-center-blvd/",
        "index": 98.425,
        "franchise-entity-name": "ALPHA FITNESS CHRISTIANA LLC"
    },
    "09bb0ee0-aa1f-4c5a-bba3-c0ac53dcc591": {
        "name": "Orange Theory Newburgh",
        "address": "1223 NY 300 Newburgh, New York 12550",
        "phone": "(845) 477-5911",
        "email": "studiomanager1179@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            41.4998398,
            -74.071548
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.04,
                    "duration": "indefinitely",
                    "model_conf": 153.39
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.83,
                    "duration": "indefinitely",
                    "model_conf": 185.24
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.04,
                    "duration": "indefinitely",
                    "model_conf": 111.85
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Newburgh",
            "slug": "09bb0ee0-aa1f-4c5a-bba3-c0ac53dcc591"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/12/20",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/newburgh/1217-ny-300/",
        "index": 98.425,
        "franchise-entity-name": "NEWBURGH FITNESS LLC"
    },
    "89ec7824-fd78-46b5-89d7-264ef441bff4": {
        "name": "Orange Theory Thousand Oaks - Newbury Park, CA",
        "address": "1714 Newbury Rd, Suite T Newbury Park, California 91320",
        "phone": "(805) 764-1010",
        "email": "studiomanager0403@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            34.1835671,
            -118.91808
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.54,
                    "duration": "6 Months",
                    "model_conf": 152.22
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 131.22,
                    "duration": "indefinitely",
                    "model_conf": 184.69
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.69,
                    "duration": "2 Months",
                    "model_conf": 111.77
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Thousand Oaks - Newbury Park, CA",
            "slug": "89ec7824-fd78-46b5-89d7-264ef441bff4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/18/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/newbury-park/1714-newbury-rd-suite-t/",
        "index": 103.375,
        "franchise-entity-name": "MLR Fitness LLC"
    },
    "b2fccf1b-c476-45d3-9384-2d7909222c36": {
        "name": "Orange Theory Newnan, GA",
        "address": "1731-A Newnan Crossing Blvd. East Newnan, Georgia 30265",
        "phone": "(470) 240-7787",
        "email": "studiomanager0612@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            33.3783569,
            -84.752686
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.58,
                    "duration": "indefinitely",
                    "model_conf": 153.58
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.12,
                    "duration": "2 Months",
                    "model_conf": 184.9
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.23,
                    "duration": "indefinitely",
                    "model_conf": 111.78
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Newnan, GA",
            "slug": "b2fccf1b-c476-45d3-9384-2d7909222c36"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/6/16",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/newnan/1731-a-newnan-crossing-blvd-east/",
        "index": 98.425,
        "franchise-entity-name": "CAP FITNESS LLC"
    },
    "fa61666b-d527-46e7-9b4b-9ed1be3073f2": {
        "name": "Orange Theory Newport Beach, CA",
        "address": "1040 Irvine Avenue Newport Beach, California 92660",
        "phone": "(949) 630-0555",
        "email": "studiomanager0043@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:25PM",
            "SUNDAY": "7:00AM - 12:25PM"
        },
        "geolocation": [
            33.6282196,
            -117.90645
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.12,
                    "duration": "3 Months",
                    "model_conf": 143.77
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.41,
                    "duration": "indefinitely",
                    "model_conf": 180.25
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.2,
                    "duration": "1 Months",
                    "model_conf": 108.27
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Newport Beach, CA",
            "slug": "fa61666b-d527-46e7-9b4b-9ed1be3073f2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/25/13",
        "url": "https://www.orangetheory.com/en-us/locations/california/newport-beach/1040-irvine-avenue/",
        "index": 103.375,
        "franchise-entity-name": "OTF NB, LLC"
    },
    "00ce47d5-255f-4b1a-b283-d856e0f010c0": {
        "name": "Orange Theory Newport News, VA",
        "address": "12515 Jefferson Ave Newport News, Virginia 23602",
        "phone": "(757) 849-0220",
        "email": "studiomanager0764@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 11:30AM",
            "SUNDAY": "7:15AM - 11:30AM"
        },
        "geolocation": [
            37.1268082,
            -76.512077
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.14,
                    "duration": "indefinitely",
                    "model_conf": 151.2
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.04,
                    "duration": "indefinitely",
                    "model_conf": 184.76
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.51,
                    "duration": "indefinitely",
                    "model_conf": 111.89
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Newport News, VA",
            "slug": "00ce47d5-255f-4b1a-b283-d856e0f010c0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/20/16",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/newport-news/12515-jefferson-ave/",
        "index": 93.4,
        "franchise-entity-name": "HAMMER FITNESS LLC"
    },
    "cce2a62d-084a-4154-a892-5336be9065e1": {
        "name": "Orange Theory Newport, KY",
        "address": "185 Pavilion Parkway Newport, Kentucky 41071",
        "phone": "(859) 838-4555",
        "email": "studiomanager0505@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:10PM",
            "TUESDAY": "5:00AM - 9:10PM",
            "WEDNESDAY": "5:00AM - 9:10PM",
            "THURSDAY": "5:00AM - 9:10PM",
            "FRIDAY": "5:00AM - 9:10PM",
            "SATURDAY": "7:00AM - 12:40PM",
            "SUNDAY": "7:00AM - 12:40PM"
        },
        "geolocation": [
            39.0870819,
            -84.476891
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 4.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.4,
                    "duration": "indefinitely",
                    "model_conf": 149.27
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 6.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.27,
                    "duration": "indefinitely",
                    "model_conf": 184.37
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.43,
                    "duration": "indefinitely",
                    "model_conf": 109.09
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Newport, KY",
            "slug": "cce2a62d-084a-4154-a892-5336be9065e1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/7/16",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/newport/185-pavilion-parkway/",
        "index": 93.4,
        "franchise-entity-name": "S&amp;C FITNESS II, L.L.C."
    },
    "82cba04f-56c4-4644-81a9-cfe9d4f30c6f": {
        "name": "Orange Theory Newtown, PA",
        "address": "2140 South Eagle Road Newtown, Pennsylvania 18940",
        "phone": "(215) 957-1700",
        "email": "studiomanager0352@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 6:45PM",
            "TUESDAY": "5:30AM - 6:45PM",
            "WEDNESDAY": "5:30AM - 6:45PM",
            "THURSDAY": "5:30AM - 6:45PM",
            "FRIDAY": "5:30AM - 6:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            40.2304192,
            -74.940498
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.85,
                    "duration": "indefinitely",
                    "model_conf": 153.09
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.21,
                    "duration": "indefinitely",
                    "model_conf": 184.26
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.92,
                    "duration": "indefinitely",
                    "model_conf": 111.16
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Newtown, PA",
            "slug": "82cba04f-56c4-4644-81a9-cfe9d4f30c6f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/31/15",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/newtown/2140-south-eagle-road/",
        "index": 103.375,
        "franchise-entity-name": "THE FITNESS SQUAD, LLC"
    },
    "5378c2da-636e-42dc-80a3-58f614ef2492": {
        "name": "Orange Theory Nichols Hills",
        "address": "6488A Avondale Drive Nichols Hills, Oklahoma 73116",
        "phone": "(405) 367-3166",
        "email": "studiomanager1048@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:00AM - 2:15PM",
            "SUNDAY": "6:00AM - 2:15PM"
        },
        "geolocation": [
            35.5391045,
            -97.531517
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.72,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.19,
                    "duration": "3 Months",
                    "model_conf": 149.54
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 10.12,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.67,
                    "duration": "indefinitely",
                    "model_conf": 182.83
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 15.22,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.2,
                    "duration": "1 Months",
                    "model_conf": 109.81
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 19.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 31.88,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 42.42,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Nichols Hills",
            "slug": "5378c2da-636e-42dc-80a3-58f614ef2492"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/18",
        "url": "https://www.orangetheory.com/en-us/locations/oklahoma/nichols-hills/6488a-avondale-drive/",
        "index": 98.425,
        "franchise-entity-name": "NICHOLS HOLLS FITNESS STUDIO LLC"
    },
    "6df599fa-3851-4a22-b933-4650c0e010e2": {
        "name": "Orange Theory Noblesville, IN",
        "address": "17167 Mercantile Blvd Noblesville, Indiana 46060",
        "phone": "(317) 983-2113",
        "email": "studiomanager1404@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            40.038007,
            -85.994357
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.28,
                    "duration": "indefinitely",
                    "model_conf": 136.45
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.64,
                    "duration": "1 Months",
                    "model_conf": 178.3
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.21,
                    "duration": "indefinitely",
                    "model_conf": 93.56
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Noblesville, IN",
            "slug": "6df599fa-3851-4a22-b933-4650c0e010e2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/10/21",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/noblesville/17167-mercantile-blvd/",
        "index": 93.4,
        "franchise-entity-name": "OT Indiana Noblesville, LLC"
    },
    "79265aa0-f691-4163-81cf-b14f3356f69d": {
        "name": "Orange Theory Norcross, GA",
        "address": "5270 Peachtree Parkway 105 Norcross, Georgia 30092",
        "phone": "(678) 786-4100",
        "email": "studiomanager0315@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.9760857,
            -84.216438
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.65,
                    "duration": "indefinitely",
                    "model_conf": 140.7
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.91,
                    "duration": "3 Months",
                    "model_conf": 176.95
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.49,
                    "duration": "indefinitely",
                    "model_conf": 102.18
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Norcross, GA",
            "slug": "79265aa0-f691-4163-81cf-b14f3356f69d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/3/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/norcross/5270-peachtree-parkway-suite-105/",
        "index": 98.425,
        "franchise-entity-name": "JM NORCROSS, LLC"
    },
    "c2d67216-e326-4694-bf11-d4701ee50ad4": {
        "name": "Orange Theory Norfolk, VA",
        "address": "333 W. 21st St Norfolk, Virginia 23517",
        "phone": "(757) 656-6499",
        "email": "studiomanager1112@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 11:30AM",
            "SUNDAY": "7:15AM - 11:30AM"
        },
        "geolocation": [
            36.8683624,
            -76.289604
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.26,
                    "duration": "4 Months",
                    "model_conf": 153.35
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.78,
                    "duration": "indefinitely",
                    "model_conf": 184.37
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.72,
                    "duration": "indefinitely",
                    "model_conf": 111.89
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Norfolk, VA",
            "slug": "c2d67216-e326-4694-bf11-d4701ee50ad4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/4/19",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/norfolk/333-w-21st-st/",
        "index": 93.4,
        "franchise-entity-name": "Pedro Gizmo, LLC"
    },
    "84c011f5-9ce6-474c-aa28-ff5f851f63b4": {
        "name": "Orange Theory Norman, OK",
        "address": "3700 West Robinson, Suite 120-124 Norman, Oklahoma 73072",
        "phone": "(405) 217-0889",
        "email": "studiomanager1240@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 1:15PM",
            "SUNDAY": "6:00AM - 1:15PM"
        },
        "geolocation": [
            35.2322846,
            -97.495811
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.91,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.76,
                    "duration": "1 Months",
                    "model_conf": 153.34
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 10.41,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.57,
                    "duration": "indefinitely",
                    "model_conf": 185.36
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 15.66,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.44,
                    "duration": "1 Months",
                    "model_conf": 112.08
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 19.69,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 32.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 43.66,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Norman, OK",
            "slug": "84c011f5-9ce6-474c-aa28-ff5f851f63b4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/18",
        "url": "https://www.orangetheory.com/en-us/locations/oklahoma/norman/3700-west-robinson-suite-120-124/",
        "index": 98.425,
        "franchise-entity-name": "Norman Fitness Studio LLC"
    },
    "25242112-57d6-47d5-98ee-babee8a6fc55": {
        "name": "Orange Theory N. Brunswick, NJ",
        "address": "764 Shoppes Blvd. North Brunswick Township, New Jersey 08902",
        "phone": "(732) 979-2447",
        "email": "studiomanager0668@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45PM",
            "SUNDAY": "7:00AM - 11:45PM"
        },
        "geolocation": [
            40.4608765,
            -74.456162
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 75.77,
                    "duration": "indefinitely",
                    "model_conf": 151.55
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 122.69,
                    "duration": "indefinitely",
                    "model_conf": 183.18
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 176.75,
                    "duration": "4 Months",
                    "model_conf": 110.19
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "N. Brunswick, NJ",
            "slug": "25242112-57d6-47d5-98ee-babee8a6fc55"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/north-brunswick-township/764-shoppes-blvd/",
        "index": 98.425,
        "franchise-entity-name": "ALPHA FITNESS STUDIO LLC"
    },
    "72e59921-7aa6-4be5-9e0a-2ae469a05342": {
        "name": "Orange Theory North Hollywood, CA",
        "address": "5200 Lankershim Boulevard Suite 130 North Hollywood, California 91602",
        "phone": "(818) 432-3388",
        "email": "studiomanager1447@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:40PM",
            "TUESDAY": "6:00AM - 7:40PM",
            "WEDNESDAY": "6:00AM - 7:40PM",
            "THURSDAY": "6:00AM - 7:40PM",
            "FRIDAY": "6:00AM - 7:40PM",
            "SATURDAY": "8:00AM - 12:30PM",
            "SUNDAY": "8:00AM - 12:30PM"
        },
        "geolocation": [
            34.1653633,
            -118.37454
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.6,
                    "duration": "indefinitely",
                    "model_conf": 151.7
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.37,
                    "duration": "1 Months",
                    "model_conf": 183.57
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.26,
                    "duration": "indefinitely",
                    "model_conf": 109.99
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "North Hollywood, CA",
            "slug": "72e59921-7aa6-4be5-9e0a-2ae469a05342"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/21",
        "url": "https://www.orangetheory.com/en-us/locations/california/north-hollywood/orangetheory-fitness-north-hollywood---5200-lankershim-boulevard/",
        "index": 111.325,
        "franchise-entity-name": "WCF North Hollywood LLC"
    },
    "bb84fc43-5704-4147-9eaa-a74ba0a69c43": {
        "name": "Orange Theory North Little Rock, AR",
        "address": "4832 N Hills Blvd North Little Rock, Arkansas 72116",
        "phone": "(501) 404-7505",
        "email": "studiomanager0997@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 1:45PM",
            "SUNDAY": "7:30AM - 1:45PM"
        },
        "geolocation": [
            34.7995606,
            -92.237801
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.22,
                    "duration": "indefinitely",
                    "model_conf": 153.63
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.58
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.96,
                    "duration": "indefinitely",
                    "model_conf": 112.09
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "North Little Rock, AR",
            "slug": "bb84fc43-5704-4147-9eaa-a74ba0a69c43"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/15/18",
        "url": "https://www.orangetheory.com/en-us/locations/arkansas/north-little-rock/4832-n-hills-blvd/",
        "index": 93.4,
        "franchise-entity-name": "AR HIIT, LLC"
    },
    "e9a67d76-9bdc-4b11-8453-c372f9c728cc": {
        "name": "Orange Theory North Mt. Pleasant, SC",
        "address": "1137 Oakland Market Rd North Mt. Pleasant, South Carolina 29466",
        "phone": "(843) 800-0988",
        "email": "studiomanager1185@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "6:15AM - 12:00PM",
            "SUNDAY": "6:15AM - 12:00PM"
        },
        "geolocation": [
            32.8627892,
            -79.785645
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.82,
                    "duration": "1 Months",
                    "model_conf": 152.77
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.25,
                    "duration": "indefinitely",
                    "model_conf": 185.26
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.64,
                    "duration": "indefinitely",
                    "model_conf": 111.01
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "North Mt. Pleasant, SC",
            "slug": "e9a67d76-9bdc-4b11-8453-c372f9c728cc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/28/18",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/north-mt-pleasant/1137-oakland-market-rd/",
        "index": 93.4,
        "franchise-entity-name": "JM NORTH MT. PLEASANT, LLC"
    },
    "e96d6c13-a3f5-4440-96f0-e9914a249b07": {
        "name": "Orange Theory Gwynedd, PA",
        "address": "1210 Bethlehem Pike , #A5 North Wales, Pennsylvania 19454",
        "phone": "(215) 643-2033",
        "email": "studiomanager0343@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            40.2072144,
            -75.225456
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.85,
                    "duration": "indefinitely",
                    "model_conf": 147.66
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.21,
                    "duration": "indefinitely",
                    "model_conf": 178.43
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.71,
                    "duration": "indefinitely",
                    "model_conf": 106.79
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Gwynedd, PA",
            "slug": "e96d6c13-a3f5-4440-96f0-e9914a249b07"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/4/15",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/north-wales/1210-bethlehem-pike-a5/",
        "index": 103.375,
        "franchise-entity-name": "WET BEAVER FITNESS - GWYNEDD, LLC"
    },
    "68eff51a-2128-4987-832a-de1f5621f11e": {
        "name": "Orange Theory Northville, MI",
        "address": "39725 Traditions Dr. Northville, Michigan 48168",
        "phone": "(248) 468-1604",
        "email": "studiomanager0488@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "5:00AM - 1:00PM",
            "SUNDAY": "5:00AM - 1:00PM"
        },
        "geolocation": [
            42.4231796,
            -83.437546
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.28,
                    "duration": "3 Months",
                    "model_conf": 129.55
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.02,
                    "duration": "indefinitely",
                    "model_conf": 171.68
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.8,
                    "duration": "indefinitely",
                    "model_conf": 105.91
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Northville, MI",
            "slug": "68eff51a-2128-4987-832a-de1f5621f11e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/12/16",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/northville/39725-traditions-dr/",
        "index": 98.425,
        "franchise-entity-name": "L5 Fitness Michigan, LLC - 0488"
    },
    "1fddb042-1a4a-4c7f-b8f9-b2804ded02a2": {
        "name": "Orange Theory Norwalk, CT",
        "address": "205 Main Street Norwalk, Connecticut 06851",
        "phone": "(203) 883-6070",
        "email": "studiomanager0682@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.1316185,
            -73.423691
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 75.09,
                    "duration": "indefinitely",
                    "model_conf": 100.68
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 124.02,
                    "duration": "indefinitely",
                    "model_conf": 122.11
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 176.43,
                    "duration": "5 Months",
                    "model_conf": 77.12
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Norwalk, CT",
            "slug": "1fddb042-1a4a-4c7f-b8f9-b2804ded02a2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/8/16",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/norwalk/205-main-street/",
        "index": 98.425,
        "franchise-entity-name": "EMPIRE NORWALK LLC"
    },
    "cd8fe908-c869-4f52-bb2e-8ebef2241d95": {
        "name": "Orange Theory Novato, CA",
        "address": "975 Diablo Blvd. Novato, California 94947",
        "phone": "(415) 761-1119",
        "email": "studiomanager0714@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            38.1045303,
            -122.5722
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.71,
                    "duration": "1 Months",
                    "model_conf": 153.78
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.02,
                    "duration": "indefinitely",
                    "model_conf": 185.65
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.79,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Novato, CA",
            "slug": "cd8fe908-c869-4f52-bb2e-8ebef2241d95"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/7/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/novato/975-diablo-blvd/",
        "index": 108.325,
        "franchise-entity-name": "B.P.S. PARTNERS II, LLC"
    },
    "15b9e588-bddc-49c8-836d-249c986b1668": {
        "name": "Orange Theory Orlando - Sodo",
        "address": "1725 South Orange Avenue Orlando, Florida 32806",
        "phone": "(407) 477-5388",
        "email": "studiomanager0197@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "7:00AM - 7:00PM",
            "TUESDAY": "7:00AM - 7:00PM",
            "WEDNESDAY": "7:00AM - 7:00PM",
            "THURSDAY": "7:00AM - 7:00PM",
            "FRIDAY": "7:00AM - 7:00PM",
            "SATURDAY": "6:00AM - 12:30PM",
            "SUNDAY": "6:00AM - 12:30PM"
        },
        "geolocation": [
            28.5218067,
            -81.376137
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.96,
                    "duration": "5 Months",
                    "model_conf": 144.79
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.54,
                    "duration": "indefinitely",
                    "model_conf": 159.68
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.25,
                    "duration": "1 Months",
                    "model_conf": 105.72
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Orlando - Sodo",
            "slug": "15b9e588-bddc-49c8-836d-249c986b1668"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/25/18",
        "url": "https://www.orangetheory.com/en-us/locations/florida/orlando/1725-south-orange-avenue/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS, LLC"
    },
    "ba5178af-47fe-4653-937b-8c6ff3f800c2": {
        "name": "Orange Theory Oviedo, FL",
        "address": "976 West Mitchell Hammock Road Suite 120 Oviedo, Florida 32765",
        "phone": "(407) 995-6222",
        "email": "studiomanager0310@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 7:00PM",
            "TUESDAY": "4:30AM - 7:00PM",
            "WEDNESDAY": "4:30AM - 7:00PM",
            "THURSDAY": "4:30AM - 7:00PM",
            "FRIDAY": "4:30AM - 7:00PM",
            "SATURDAY": "5:30AM - 12:00PM",
            "SUNDAY": "5:30AM - 12:00PM"
        },
        "geolocation": [
            28.6552677,
            -81.222824
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.02,
                    "duration": "indefinitely",
                    "model_conf": 145.21
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.07,
                    "duration": "indefinitely",
                    "model_conf": 173.81
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.84,
                    "duration": "indefinitely",
                    "model_conf": 105.73
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Oviedo, FL",
            "slug": "ba5178af-47fe-4653-937b-8c6ff3f800c2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/5/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/oviedo/976-west-mitchell-hammock-road-suite-120/",
        "index": 98.425,
        "franchise-entity-name": "OTF PROPERTY HOLDINGS, LLC"
    },
    "86fe2452-1cd8-4bd3-be3d-2efcd05d9031": {
        "name": "Orange Theory Downtown Oak Park, IL",
        "address": "1046 Lake St Oak Park, Illinois 60301",
        "phone": "(708) 547-7771",
        "email": "studiomanager1350@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            41.888921,
            -87.802333
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.99,
                    "duration": "indefinitely",
                    "model_conf": 143.66
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.56,
                    "duration": "1 Months",
                    "model_conf": 173.26
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.77,
                    "duration": "indefinitely",
                    "model_conf": 103.79
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Downtown Oak Park, IL",
            "slug": "86fe2452-1cd8-4bd3-be3d-2efcd05d9031"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/23",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/oak-park/1046-lake-st/",
        "index": 103.375,
        "franchise-entity-name": "Hearne Fitness Three LLC - 1350"
    },
    "8fcd5065-a046-4bce-9391-57697fff2d9e": {
        "name": "Orange Theory Oak Park, IL",
        "address": "7121 W North Avenue Oak Park, Illinois 60302",
        "phone": "(708) 793-9219",
        "email": "studiomanager0217@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 11:45AM",
            "SUNDAY": "6:00AM - 11:45AM"
        },
        "geolocation": [
            41.9082909,
            -87.805328
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.87,
                    "duration": "indefinitely",
                    "model_conf": 143.77
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.86,
                    "duration": "indefinitely",
                    "model_conf": 173.53
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 104.15
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Oak Park, IL",
            "slug": "8fcd5065-a046-4bce-9391-57697fff2d9e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/22/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/oak-park/7121-w-north-avenue/",
        "index": 103.375,
        "franchise-entity-name": "ALVAREZ &amp; HEARNE HOLDINGS,LLC"
    },
    "6343a2c3-4d24-424e-b76f-bf7c2e7b1500": {
        "name": "Orange Theory Oakland-Montclair",
        "address": "2084 MOUNTAIN BLVD STE 102 Oakland, California 94611",
        "phone": "(510) 394-0559",
        "email": "studiomanager1339@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 1:00PM",
            "TUESDAY": "5:00AM - 1:00PM",
            "WEDNESDAY": "5:00AM - 1:00PM",
            "THURSDAY": "5:00AM - 1:00PM",
            "FRIDAY": "5:00AM - 1:00PM",
            "SATURDAY": "7:10AM - 12:50PM",
            "SUNDAY": "7:10AM - 12:50PM"
        },
        "geolocation": [
            37.825744,
            -122.20939
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.3,
                    "duration": "indefinitely",
                    "model_conf": 152.91
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.78,
                    "duration": "indefinitely",
                    "model_conf": 184.51
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.22,
                    "duration": "1 Months",
                    "model_conf": 110.81
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Oakland-Montclair",
            "slug": "6343a2c3-4d24-424e-b76f-bf7c2e7b1500"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/26/22",
        "url": "https://www.orangetheory.com/en-us/locations/california/oakland/2080-mountain-blvd-suite-3/",
        "index": 108.325,
        "franchise-entity-name": "SFFIT OMC LLC"
    },
    "a21ad6af-9cf0-493b-987e-61a94df97091": {
        "name": "Orange Theory Oakland-Downtown, CA",
        "address": "2315 Valdez St, Suite 3 Oakland, California 94612",
        "phone": "(510) 992-4117",
        "email": "studiomanager1337@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:50PM",
            "TUESDAY": "5:00AM - 7:50PM",
            "WEDNESDAY": "5:00AM - 7:50PM",
            "THURSDAY": "5:00AM - 7:50PM",
            "FRIDAY": "5:00AM - 7:50PM",
            "SATURDAY": "7:20AM - 11:50AM",
            "SUNDAY": "7:20AM - 11:50AM"
        },
        "geolocation": [
            37.812468,
            -122.26447
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.58,
                    "duration": "indefinitely",
                    "model_conf": 152.84
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.73,
                    "duration": "indefinitely",
                    "model_conf": 183.75
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.52,
                    "duration": "indefinitely",
                    "model_conf": 110.17
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Oakland-Downtown, CA",
            "slug": "a21ad6af-9cf0-493b-987e-61a94df97091"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/24/23",
        "url": "https://www.orangetheory.com/en-us/locations/california/oakland/2315-valdez-st-ste-3/",
        "index": 108.325,
        "franchise-entity-name": "SFFIT VENTURES LLC - 1337"
    },
    "2343d83b-1d74-4ed7-bec0-39402963b9dc": {
        "name": "Orange Theory Ocala, FL",
        "address": "4414 SW College Rd., Suite 960 Ocala, Florida 34474",
        "phone": "(352) 433-0220",
        "email": "studiomanager0671@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            29.139986,
            -82.192436
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.54,
                    "duration": "indefinitely",
                    "model_conf": 153.57
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.37,
                    "duration": "2 Months",
                    "model_conf": 185.83
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.87,
                    "duration": "indefinitely",
                    "model_conf": 112.09
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Ocala, FL",
            "slug": "2343d83b-1d74-4ed7-bec0-39402963b9dc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/ocala/4414-sw-college-rd-suite-960/",
        "index": 93.4,
        "franchise-entity-name": "OUR DIVINE PURPOSE LLC"
    },
    "04c0112d-4dfb-485e-bc55-7720926a23a0": {
        "name": "Orange Theory Oceanside, NY",
        "address": "3563 Long Beach Rd Oceanside, New York 11572",
        "phone": "(516) 399-1225",
        "email": "studiomanager1344@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.6250725,
            -73.638046
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 96.32,
                    "duration": "10 Months",
                    "model_conf": 145.29
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 141.72,
                    "duration": "indefinitely",
                    "model_conf": 173.89
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 197.54,
                    "duration": "2 Months",
                    "model_conf": 106.6
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Oceanside, NY",
            "slug": "04c0112d-4dfb-485e-bc55-7720926a23a0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/22/21",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/oceanside/3563-long-beach-rd/",
        "index": 108.325,
        "franchise-entity-name": "OS FITNESS LLC"
    },
    "a2c65770-b692-485b-8e0f-0191193a6a79": {
        "name": "Orange Theory Trinity, FL",
        "address": "12546 State Rd 54 Odessa, Florida 33556",
        "phone": "(727) 500-1212",
        "email": "studiomanager0398@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            28.1895142,
            -82.607658
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.21,
                    "duration": "indefinitely",
                    "model_conf": 152.89
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.76,
                    "duration": "indefinitely",
                    "model_conf": 184.96
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.18,
                    "duration": "indefinitely",
                    "model_conf": 111.28
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Trinity, FL",
            "slug": "a2c65770-b692-485b-8e0f-0191193a6a79"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/24/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/odessa/12546-state-rd-54/",
        "index": 98.425,
        "franchise-entity-name": "DREAM FITNESS II, LLC"
    },
    "0e210eb9-57c3-4e82-97db-da39ca8b4728": {
        "name": "Orange Theory Northwest Oklahoma City, OK",
        "address": "12220 N. MacArthur Blvd. Suite E Oklahoma City, Oklahoma 73162",
        "phone": "(405) 878-8060",
        "email": "studiomanager0859@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 2:00PM",
            "SUNDAY": "6:00AM - 2:00PM"
        },
        "geolocation": [
            35.5935783,
            -97.620651
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.81,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.42,
                    "duration": "indefinitely",
                    "model_conf": 150.89
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 10.26,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.55,
                    "duration": "1 Months",
                    "model_conf": 183.66
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 15.44,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.15,
                    "duration": "indefinitely",
                    "model_conf": 110.45
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 19.41,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 32.34,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 43.04,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Northwest Oklahoma City, OK",
            "slug": "0e210eb9-57c3-4e82-97db-da39ca8b4728"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/31/17",
        "url": "https://www.orangetheory.com/en-us/locations/oklahoma/oklahoma-city/12220-n-macarthur-blvd-suite-e/",
        "index": 98.425,
        "franchise-entity-name": "NWOKC FITNESS STUDIO, LLC"
    },
    "1b912086-38c0-4d3a-b49f-36ab695aaaaa": {
        "name": "Orange Theory Olathe Pointe, KS",
        "address": "14995 W 119th St. Olathe, Kansas 66062",
        "phone": "(913) 397-7500",
        "email": "studiomanager0353@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            38.912693,
            -94.758881
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 7.06,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.7,
                    "duration": "1 Months",
                    "model_conf": 134.93
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 11.14,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.92,
                    "duration": "indefinitely",
                    "model_conf": 162.36
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 17.28,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.04,
                    "duration": "indefinitely",
                    "model_conf": 83.32
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 20.35,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 36.71,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 51.02,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Olathe Pointe, KS",
            "slug": "1b912086-38c0-4d3a-b49f-36ab695aaaaa"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/15/15",
        "url": "https://www.orangetheory.com/en-us/locations/kansas/olathe/14995-w-119th-st/",
        "index": 93.4,
        "franchise-entity-name": "KC FITNESS 1 LLC"
    },
    "3b8002cc-597e-4d02-84bf-470606426bb0": {
        "name": "Orange Theory Olathe - South, KS",
        "address": "20144 W 153rd St Olathe, Kansas 66062",
        "phone": "(913) 274-1415",
        "email": "studiomanager0914@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:45PM",
            "SUNDAY": "7:00AM - 11:45PM"
        },
        "geolocation": [
            38.8516884,
            -94.818558
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 6.36,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.59,
                    "duration": "indefinitely",
                    "model_conf": 148.78
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 10.06,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.25,
                    "duration": "2 Months",
                    "model_conf": 179.98
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 15.59,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.68,
                    "duration": "indefinitely",
                    "model_conf": 105.24
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 18.36,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 33.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 46.03,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Olathe - South, KS",
            "slug": "3b8002cc-597e-4d02-84bf-470606426bb0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/23/17",
        "url": "https://www.orangetheory.com/en-us/locations/kansas/olathe/20144-w-153rd-st/",
        "index": 93.4,
        "franchise-entity-name": "KC FITNESS 4, LLC"
    },
    "8a898af2-f0ca-4e02-9aee-9356f3459102": {
        "name": "Orange Theory Olive Branch, MS",
        "address": "5155 Goodman Rd Olive Branch, Mississippi 38654",
        "phone": "(662) 932-0100",
        "email": "studiomanager0791@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:15AM - 3:00PM",
            "SUNDAY": "7:15AM - 3:00PM"
        },
        "geolocation": [
            34.9623489,
            -89.897469
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.71,
                    "duration": "indefinitely",
                    "model_conf": 152.74
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.88,
                    "duration": "3 Months",
                    "model_conf": 185.73
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 170.16,
                    "duration": "indefinitely",
                    "model_conf": 111.27
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Olive Branch, MS",
            "slug": "8a898af2-f0ca-4e02-9aee-9356f3459102"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/mississippi/olive-branch/5155-goodman-rd/",
        "index": 93.4,
        "franchise-entity-name": "FREEVIE 2016 LLC"
    },
    "a4986dd7-c08d-41a1-bb62-0ca17e749d47": {
        "name": "Orange Theory Olney, MD",
        "address": "18205 Hillcrest Ave. Olney, Maryland 20832",
        "phone": "(443) 221-6700",
        "email": "studiomanager0290@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            39.1543579,
            -77.065117
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.38,
                    "duration": "indefinitely",
                    "model_conf": 152.51
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 137.9,
                    "duration": "3 Months",
                    "model_conf": 183.46
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.37,
                    "duration": "indefinitely",
                    "model_conf": 110.46
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Olney, MD",
            "slug": "a4986dd7-c08d-41a1-bb62-0ca17e749d47"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/3/15",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/olney/18205-hillcrest-ave/",
        "index": 108.325,
        "franchise-entity-name": "OLNEY FITNESS PARTNERS, LLC"
    },
    "14d363a5-9210-4d4e-aa2b-59af388d5a84": {
        "name": "Orange Theory West Olympia, WA",
        "address": "1520 Cooper Point Road, Suite 480 Olympia, Washington 98502",
        "phone": "(253) 301-0643",
        "email": "studiomanager1214@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 10:30AM",
            "SUNDAY": "7:15AM - 10:30AM"
        },
        "geolocation": [
            47.03479,
            -122.9361
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 6.49,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.35,
                    "duration": "indefinitely",
                    "model_conf": 153.25
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 10.25,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.87,
                    "duration": "indefinitely",
                    "model_conf": 185.69
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 15.89,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.32,
                    "duration": "indefinitely",
                    "model_conf": 110.44
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 18.71,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 33.75,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 46.91,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "West Olympia, WA",
            "slug": "14d363a5-9210-4d4e-aa2b-59af388d5a84"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/21/19",
        "url": "https://www.orangetheory.com/en-us/locations/washington/olympia/1520-cooper-point-road-suite-480/",
        "index": 93.4,
        "franchise-entity-name": "OLYMPIA FITNESS, LLC"
    },
    "dc48a794-9574-485f-9153-23e5aa5eed68": {
        "name": "Orange Theory NW Omaha, NE",
        "address": "14450 Eagle Run Drive, Suite 110 Omaha, Nebraska 68116",
        "phone": "(402) 704-7003",
        "email": "studiomanager0497@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:20PM",
            "TUESDAY": "5:00AM - 7:20PM",
            "WEDNESDAY": "5:00AM - 7:20PM",
            "THURSDAY": "5:00AM - 7:20PM",
            "FRIDAY": "5:00AM - 7:20PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.2882729,
            -96.140022
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.53,
                    "duration": "2 Months",
                    "model_conf": 133.01
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.41,
                    "duration": "indefinitely",
                    "model_conf": 165.3
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.02,
                    "duration": "indefinitely",
                    "model_conf": 107.27
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "NW Omaha, NE",
            "slug": "dc48a794-9574-485f-9153-23e5aa5eed68"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/16",
        "url": "https://www.orangetheory.com/en-us/locations/nebraska/omaha/14450-eagle-run-drive-suite-110/",
        "index": 93.4,
        "franchise-entity-name": "OMAHA FIT 2, LLC"
    },
    "43c1c2b5-524f-4856-9e8c-a56502166a7a": {
        "name": "Orange Theory West Omaha, NE",
        "address": "16909 Burke Street, Suite 122 Omaha, Nebraska 68118",
        "phone": "(402) 281-0950",
        "email": "studiomanager0410@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:45PM",
            "SUNDAY": "6:30AM - 12:45PM"
        },
        "geolocation": [
            41.2584839,
            -96.179939
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.33,
                    "duration": "9 Months",
                    "model_conf": 133.36
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.52,
                    "duration": "indefinitely",
                    "model_conf": 167.03
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.74,
                    "duration": "2 Months",
                    "model_conf": 107.53
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "West Omaha, NE",
            "slug": "43c1c2b5-524f-4856-9e8c-a56502166a7a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/2/15",
        "url": "https://www.orangetheory.com/en-us/locations/nebraska/omaha/16909-burke-street-suite-122/",
        "index": 93.4,
        "franchise-entity-name": "OMAHA FIT 1, LLC"
    },
    "66a979bc-b762-4b10-8a09-68b3a7f080b4": {
        "name": "Orange Theory Orlando - Waterford Park, FL",
        "address": "12301 Lake Underhill Rd Orlando Oralndo, Florida 32828",
        "phone": "(407) 440-2906",
        "email": "studiomanager0107@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            28.5480309,
            -81.203133
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.78,
                    "duration": "1 Months",
                    "model_conf": 149.49
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.35,
                    "duration": "indefinitely",
                    "model_conf": 175.46
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.88,
                    "duration": "indefinitely",
                    "model_conf": 108.8
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Orlando - Waterford Park, FL",
            "slug": "66a979bc-b762-4b10-8a09-68b3a7f080b4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/3/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/orlando/12301-lake-underhill-road/",
        "index": 98.425,
        "franchise-entity-name": "OTF WATERFORD, LLC"
    },
    "95896570-f41c-4e06-bdac-20034f7d6501": {
        "name": "Orange Theory Orange, CA",
        "address": "1500 E Village Way, Suite 2340 Orange, California 92865",
        "phone": "(714) 978-4040",
        "email": "studiomanager0287@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:25PM",
            "TUESDAY": "5:00AM - 8:25PM",
            "WEDNESDAY": "5:00AM - 8:25PM",
            "THURSDAY": "5:00AM - 8:25PM",
            "FRIDAY": "5:00AM - 8:25PM",
            "SATURDAY": "6:45AM - 11:30AM",
            "SUNDAY": "6:45AM - 11:30AM"
        },
        "geolocation": [
            33.8256798,
            -117.83853
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.31,
                    "duration": "indefinitely",
                    "model_conf": 148.89
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.4,
                    "duration": "4 Months",
                    "model_conf": 157.78
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.0,
                    "duration": "indefinitely",
                    "model_conf": 92.32
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Orange, CA",
            "slug": "95896570-f41c-4e06-bdac-20034f7d6501"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/13/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/orange/1500-e-village-way-suite-2340/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT ORA LLC"
    },
    "a0ea27e4-1f61-4489-8b5d-7965d5a6ee91": {
        "name": "Orange Theory Orange, OH",
        "address": "200 Park Avenue,Suite 144 Orange, Ohio 44122",
        "phone": "(216) 930-4800",
        "email": "studiomanager0744@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:30AM - 6:45PM",
            "TUESDAY": "6:30AM - 6:45PM",
            "WEDNESDAY": "6:30AM - 6:45PM",
            "THURSDAY": "6:30AM - 6:45PM",
            "FRIDAY": "6:30AM - 6:45PM",
            "SATURDAY": "9:00AM - 11:15AM",
            "SUNDAY": "9:00AM - 11:15AM"
        },
        "geolocation": [
            41.4531212,
            -81.48645
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 2.07,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.51,
                    "duration": "8 Months",
                    "model_conf": 153.14
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 3.27,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 111.32,
                    "duration": "indefinitely",
                    "model_conf": 185.58
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 5.07,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.67,
                    "duration": "3 Months",
                    "model_conf": 111.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 5.97,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 10.77,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 14.97,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Orange, OH",
            "slug": "a0ea27e4-1f61-4489-8b5d-7965d5a6ee91"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/31/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/orange/200-park-avenue-suite-144/",
        "index": 93.4,
        "franchise-entity-name": "KSA11 LLC"
    },
    "4042d80c-dcf6-4055-9b3e-4b29a27cb8d0": {
        "name": "Orange Theory Oregon City, OR",
        "address": "395 Beavercreek Road Oregon City, Oregon 97045",
        "phone": "(503) 468-7243",
        "email": "studiomanager0553@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            45.3337135,
            -122.5919
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.59,
                    "duration": "2 Months",
                    "model_conf": 153.6
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.13,
                    "duration": "indefinitely",
                    "model_conf": 184.34
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.05,
                    "duration": "2 Months",
                    "model_conf": 111.68
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Oregon City, OR",
            "slug": "4042d80c-dcf6-4055-9b3e-4b29a27cb8d0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/oregon-city/395-beavercreek-road/",
        "index": 98.425,
        "franchise-entity-name": "HH FITNESS OREGON CITY, LLC"
    },
    "213c0dfe-acbb-4bb2-a42a-e0d5189d26c1": {
        "name": "Orange Theory Provo/Orem, UT",
        "address": "1345 S. State Street Orem, Utah 84097",
        "phone": "(801) 896-9058",
        "email": "studiomanager0381@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            40.2724114,
            -111.68401
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.35,
                    "duration": "indefinitely",
                    "model_conf": 153.48
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.64,
                    "duration": "1 Months",
                    "model_conf": 183.89
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.65,
                    "duration": "indefinitely",
                    "model_conf": 111.68
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Provo/Orem, UT",
            "slug": "213c0dfe-acbb-4bb2-a42a-e0d5189d26c1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/6/15",
        "url": "https://www.orangetheory.com/en-us/locations/utah/orem/1345-s-state-street/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS UTAH, LLC"
    },
    "802f243d-b123-47e6-9159-444c75f1dc4d": {
        "name": "Orange Theory Auburn Hills - Lk Orion, MI",
        "address": "3067 S. Baldwin Rd. Orion Township, Michigan 48359",
        "phone": "(248) 232-2550",
        "email": "studiomanager1044@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "6:45AM - 12:00PM",
            "SUNDAY": "6:45AM - 12:00PM"
        },
        "geolocation": [
            42.7352142,
            -83.307869
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.43,
                    "duration": "indefinitely",
                    "model_conf": 148.94
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.77,
                    "duration": "indefinitely",
                    "model_conf": 180.24
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.73,
                    "duration": "indefinitely",
                    "model_conf": 109.81
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Auburn Hills - Lk Orion, MI",
            "slug": "802f243d-b123-47e6-9159-444c75f1dc4d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/8/17",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/orion-township/3067-s-baldwin-rd/",
        "index": 98.425,
        "franchise-entity-name": "JAJK, LLC"
    },
    "f4fcf335-3f90-4cee-b2b2-358f906001c0": {
        "name": "Orange Theory Orland Park, IL",
        "address": "15200 S. LaGrange Road Orland Park, Illinois 60462",
        "phone": "(708) 860-8292",
        "email": "studiomanager0033@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            41.6141625,
            -87.853432
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.89,
                    "duration": "indefinitely",
                    "model_conf": 153.17
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.84,
                    "duration": "indefinitely",
                    "model_conf": 184.45
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.22,
                    "duration": "indefinitely",
                    "model_conf": 111.55
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Orland Park, IL",
            "slug": "f4fcf335-3f90-4cee-b2b2-358f906001c0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/23/12",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/orland-park/15200-s-lagrange-road/",
        "index": 103.375,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-WEST, LLC"
    },
    "10b6bce6-5682-44ee-97c3-5082c9d65c5e": {
        "name": "Orange Theory Nona South, FL",
        "address": "12851 NARCOOSSEE RD Orlando, Florida 32832",
        "phone": "(407) 734-0527",
        "email": "studiomanager1509@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:25PM",
            "TUESDAY": "5:00AM - 7:25PM",
            "WEDNESDAY": "5:00AM - 7:25PM",
            "THURSDAY": "5:00AM - 7:25PM",
            "FRIDAY": "5:00AM - 7:25PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            28.379381,
            -81.244211
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.24,
                    "duration": "indefinitely",
                    "model_conf": 153.57
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.56,
                    "duration": "1 Months",
                    "model_conf": 183.56
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.34,
                    "duration": "indefinitely",
                    "model_conf": 111.5
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Nona South, FL",
            "slug": "10b6bce6-5682-44ee-97c3-5082c9d65c5e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/21/21",
        "url": "https://www.orangetheory.com/en-us/locations/florida/orlando/12851-narcoossee-rd-suite-104/",
        "index": 98.425,
        "franchise-entity-name": "FORIDA FITNESS WORKS 7, LLC"
    },
    "64338379-e346-4353-be36-e8546a8048f1": {
        "name": "Orange Theory Colonial Plaza, FL",
        "address": "2714 E Colonial Drive Orlando, Florida 32803",
        "phone": "(407) 898-1888",
        "email": "studiomanager0068@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "7:00AM - 7:00PM",
            "TUESDAY": "7:00AM - 7:00PM",
            "WEDNESDAY": "7:00AM - 7:00PM",
            "THURSDAY": "7:00AM - 7:00PM",
            "FRIDAY": "7:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            28.55233,
            -81.348671
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.7,
                    "duration": "6 Months",
                    "model_conf": 135.56
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.57,
                    "duration": "indefinitely",
                    "model_conf": 151.85
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.18,
                    "duration": "1 Months",
                    "model_conf": 99.02
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Colonial Plaza, FL",
            "slug": "64338379-e346-4353-be36-e8546a8048f1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/24/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/orlando/2714-e-colonial-dr/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS, LLC"
    },
    "e4e73736-27a8-4335-ba79-c7e16adf0e10": {
        "name": "Orange Theory UCF, FL",
        "address": "4100 N Alafaya Trail Suite #143 Orlando, Florida 32826",
        "phone": "(407) 723-7700",
        "email": "studiomanager1513@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:15PM",
            "TUESDAY": "5:00AM - 6:15PM",
            "WEDNESDAY": "5:00AM - 6:15PM",
            "THURSDAY": "5:00AM - 6:15PM",
            "FRIDAY": "5:00AM - 6:15PM",
            "SATURDAY": "7:00AM - 10:15AM",
            "SUNDAY": "7:00AM - 10:15AM"
        },
        "geolocation": [
            28.598582,
            -81.208407
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.24,
                    "duration": "3 Months",
                    "model_conf": 147.16
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.14,
                    "duration": "indefinitely",
                    "model_conf": 174.59
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.46,
                    "duration": "1 Months",
                    "model_conf": 107.09
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "UCF, FL",
            "slug": "e4e73736-27a8-4335-ba79-c7e16adf0e10"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/3/21",
        "url": "https://www.orangetheory.com/en-us/locations/florida/orlando/4100-n-alfaya-trail-143/",
        "index": 98.425,
        "franchise-entity-name": "FLORIDA FITNESS WORKS 8, LLC"
    },
    "3c6f38ed-fbca-47ee-8e72-8f01560de59f": {
        "name": "Orange Theory Orlando - Dr Phillips, FL",
        "address": "7343 W Sand Lake Rd., Suite 1001 Orlando, Florida 32819",
        "phone": "(407) 982-2822",
        "email": "studiomanager0067@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:30AM - 12:00PM",
            "SUNDAY": "8:30AM - 12:00PM"
        },
        "geolocation": [
            28.4509831,
            -81.481194
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.79,
                    "duration": "indefinitely",
                    "model_conf": 152.4
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.89,
                    "duration": "3 Months",
                    "model_conf": 175.27
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.52,
                    "duration": "indefinitely",
                    "model_conf": 110.73
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Orlando - Dr Phillips, FL",
            "slug": "3c6f38ed-fbca-47ee-8e72-8f01560de59f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/19/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/orlando/7343-w-sand-lake-rd-suite-1001/",
        "index": 98.425,
        "franchise-entity-name": "OTF ORLANDO, LLC"
    },
    "1dbcfa51-5f6f-41a8-855c-c13223bc2acc": {
        "name": "Orange Theory Nona North, FL",
        "address": "9161 Narcoossee Road, Suite #104 Orlando, Florida 32827",
        "phone": "(407) 995-6220",
        "email": "studiomanager0311@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:05PM",
            "TUESDAY": "5:00AM - 9:05PM",
            "WEDNESDAY": "5:00AM - 9:05PM",
            "THURSDAY": "5:00AM - 9:05PM",
            "FRIDAY": "5:00AM - 9:05PM",
            "SATURDAY": "8:00AM - 12:30PM",
            "SUNDAY": "8:00AM - 12:30PM"
        },
        "geolocation": [
            28.4340649,
            -81.256271
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.3,
                    "duration": "3 Months",
                    "model_conf": 152.87
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.68,
                    "duration": "indefinitely",
                    "model_conf": 180.3
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.56,
                    "duration": "indefinitely",
                    "model_conf": 110.8
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Nona North, FL",
            "slug": "1dbcfa51-5f6f-41a8-855c-c13223bc2acc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/11/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/orlando/9161-narcoossee-road-suite-104/",
        "index": 98.425,
        "franchise-entity-name": "FLORIDA FITNESS WORKS 5, LLC"
    },
    "eaabc63a-de73-4c5d-8051-9ca5cb3da2f1": {
        "name": "Orange Theory Overland Park, KS",
        "address": "13621 Metcalf Ave. Overland Park, Kansas 66223",
        "phone": "(913) 213-1012",
        "email": "studiomanager0531@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 1:15PM",
            "SUNDAY": "6:15AM - 1:15PM"
        },
        "geolocation": [
            38.8825722,
            -94.667732
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.81,
                    "duration": "1 Months",
                    "model_conf": 141.73
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 11.28,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.74,
                    "duration": "indefinitely",
                    "model_conf": 170.85
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 17.49,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.86,
                    "duration": "indefinitely",
                    "model_conf": 94.43
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 20.6,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 37.16,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 51.65,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Overland Park, KS",
            "slug": "eaabc63a-de73-4c5d-8051-9ca5cb3da2f1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/kansas/overland-park/13621-metcalf-ave/",
        "index": 93.4,
        "franchise-entity-name": "KC FITNESS 2, LLC"
    },
    "fb2484eb-7875-4049-889d-7ac95f9a78c1": {
        "name": "Orange Theory Nall Hills, KS",
        "address": "9538 Nall Avenue Overland Park, Kansas 66207",
        "phone": "(913) 440-0010",
        "email": "studiomanager0514@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            38.955204,
            -94.651184
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 6.8,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.05,
                    "duration": "indefinitely",
                    "model_conf": 144.69
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 10.74,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.26,
                    "duration": "2 Months",
                    "model_conf": 174.81
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 16.65,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.92,
                    "duration": "indefinitely",
                    "model_conf": 99.25
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 19.6,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 35.36,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 49.15,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Nall Hills, KS",
            "slug": "fb2484eb-7875-4049-889d-7ac95f9a78c1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/kansas/overland-park/9538-nall-avenue/",
        "index": 93.4,
        "franchise-entity-name": "MSM3 FITNESS, LLC"
    },
    "f67897a4-bf9f-41fe-9a38-fec005664df0": {
        "name": "Orange Theory Owensboro, KY",
        "address": "2490 Calumet Trace Owensboro, Kentucky 42303",
        "phone": "(270) 228-3938",
        "email": "studiomanager1492@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            37.76406,
            -87.065906
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 3.54,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.24,
                    "duration": "indefinitely",
                    "model_conf": 153.8
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 5.94,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.35,
                    "duration": "indefinitely",
                    "model_conf": 185.77
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.25,
                    "duration": "indefinitely",
                    "model_conf": 111.89
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Owensboro, KY",
            "slug": "f67897a4-bf9f-41fe-9a38-fec005664df0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/13/23",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/owensboro/2490-calumet-trace",
        "index": 88.45,
        "franchise-entity-name": "MAR10, LLC"
    },
    "ac0583c9-d904-4b65-aad4-12f9d02a07a2": {
        "name": "Orange Theory Owings Mills, MD",
        "address": "9175-9177 Reisterstown Rd Owings Mills, Maryland 21117",
        "phone": "(410) 415-1878",
        "email": "studiomanager1038@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            39.3973999,
            -76.748405
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.52,
                    "duration": "1 Months",
                    "model_conf": 150.97
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.54,
                    "duration": "indefinitely",
                    "model_conf": 178.55
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.58,
                    "duration": "indefinitely",
                    "model_conf": 111.18
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Owings Mills, MD",
            "slug": "ac0583c9-d904-4b65-aad4-12f9d02a07a2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/26/19",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/owings-mills/9175-9177-reisterstown-rd/",
        "index": 108.325,
        "franchise-entity-name": "BALTIMORE FITNESS PARTNERS, LLC"
    },
    "b0846449-1b96-40ac-836e-de131c805c7c": {
        "name": "Orange Theory Oxford, MS",
        "address": "1801 Jackson Ave West, Suite D115 Oxford, Mississippi 38655",
        "phone": "(662) 380-5149",
        "email": "studiomanager0444@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 2:00PM",
            "SUNDAY": "7:30AM - 2:00PM"
        },
        "geolocation": [
            34.3655624,
            -89.553894
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.62
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 184.35
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.74
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Oxford, MS",
            "slug": "b0846449-1b96-40ac-836e-de131c805c7c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/4/15",
        "url": "https://www.orangetheory.com/en-us/locations/mississippi/oxford/1801-jackson-ave-west-suite-d115/",
        "index": 93.4,
        "franchise-entity-name": "HH OXFORD LLC"
    },
    "12df4080-5b38-4667-a559-30a6ed1834c9": {
        "name": "Orange Theory Petaluma, CA",
        "address": "401 Kenilworth Dr Suites 310/320 Petaluma, California 94952",
        "phone": "(707) 345-1160",
        "email": "studiomanager0388@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            38.2447624,
            -122.62845
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.0,
                    "duration": "indefinitely",
                    "model_conf": 152.89
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.1,
                    "duration": "indefinitely",
                    "model_conf": 185.13
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.98,
                    "duration": "indefinitely",
                    "model_conf": 111.71
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Petaluma, CA",
            "slug": "12df4080-5b38-4667-a559-30a6ed1834c9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/11/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/petaluma/401-kenilworth-dr-suites-310-320/",
        "index": 108.325,
        "franchise-entity-name": "DYER ENTERPRISES II, LLC"
    },
    "414e2240-c491-48c7-a312-4fc9f2037b29": {
        "name": "Orange Theory Port Washington, NY",
        "address": "1013 Port Washington Blvd Port Washington, New York 11050",
        "phone": "(516) 302-4044",
        "email": "studiomanager0323@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:05AM - 12:30PM",
            "SUNDAY": "6:05AM - 12:30PM"
        },
        "geolocation": [
            40.8305206,
            -73.683807
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.94,
                    "duration": "4 Months",
                    "model_conf": 137.55
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.17,
                    "duration": "indefinitely",
                    "model_conf": 166.09
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.25,
                    "duration": "1 Months",
                    "model_conf": 101.21
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Port Washington, NY",
            "slug": "414e2240-c491-48c7-a312-4fc9f2037b29"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/7/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/port-washington/1013-port-washington-blvd/",
        "index": 108.325,
        "franchise-entity-name": "PWLI FITNESS LLC"
    },
    "44564f1d-d66b-4158-bf5f-b938354d9cda": {
        "name": "Orange Theory Slabtown, OR",
        "address": "2195 NW Raleigh Street Portland Downtown, Oregon 97209",
        "phone": "(503) 278-5326",
        "email": "studiomanager0467@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            45.5342941,
            -122.69666
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.54,
                    "duration": "indefinitely",
                    "model_conf": 153.18
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.11,
                    "duration": "6 Months",
                    "model_conf": 165.01
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.8,
                    "duration": "indefinitely",
                    "model_conf": 108.64
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Slabtown, OR",
            "slug": "44564f1d-d66b-4158-bf5f-b938354d9cda"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/31/18",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/portland-downtown/2195-nw-raleigh-street/",
        "index": 98.425,
        "franchise-entity-name": "HOODBERRY, LLC"
    },
    "ef318305-2afa-4f68-9c00-83a9b6d24e5a": {
        "name": "Orange Theory Pacifica",
        "address": "535 SAN PEDRO AVE Pacifica, California 94044",
        "phone": "(650) 239-6965",
        "email": "studiomanager1288@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            37.5942383,
            -122.50711
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.48,
                    "duration": "2 Months",
                    "model_conf": 153.54
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.73,
                    "duration": "indefinitely",
                    "model_conf": 184.96
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.81,
                    "duration": "indefinitely",
                    "model_conf": 111.41
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Pacifica",
            "slug": "ef318305-2afa-4f68-9c00-83a9b6d24e5a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/20/20",
        "url": "https://www.orangetheory.com/en-us/locations/california/pacifica/535-san-pedro-ave/",
        "index": 111.325,
        "franchise-entity-name": "ROCK STAR VENTURES, LLC"
    },
    "c1164f1c-3c0a-4ed7-9430-98a57d33075b": {
        "name": "Orange Theory Palm Beach Gardens East, FL",
        "address": "2612 PGA Blvd. Palm Beach Gardens, Florida 33410",
        "phone": "(561) 859-0683",
        "email": "studiomanager0219@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:10PM",
            "SUNDAY": "7:00AM - 1:10PM"
        },
        "geolocation": [
            26.8446236,
            -80.075317
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.85,
                    "duration": "5 Months",
                    "model_conf": 140.77
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.9,
                    "duration": "indefinitely",
                    "model_conf": 182.18
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.49,
                    "duration": "1 Months",
                    "model_conf": 108.66
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Palm Beach Gardens East, FL",
            "slug": "c1164f1c-3c0a-4ed7-9430-98a57d33075b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/19/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/palm-beach-gardens/2612-pga-blvd/",
        "index": 98.425,
        "franchise-entity-name": "92 FITNESS CREW FLORIDA I, LLC"
    },
    "3820e690-4c04-422b-8873-561d1b567b6f": {
        "name": "Orange Theory Palm Beach Gardens, FL",
        "address": "6271 PGA Boulevard, #203 Palm Beach Gardens, Florida 33418",
        "phone": "(561) 702-2308",
        "email": "studiomanager0056@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 6:45PM",
            "TUESDAY": "5:15AM - 6:45PM",
            "WEDNESDAY": "5:15AM - 6:45PM",
            "THURSDAY": "5:15AM - 6:45PM",
            "FRIDAY": "5:15AM - 6:45PM",
            "SATURDAY": "7:00AM - 12:05PM",
            "SUNDAY": "7:00AM - 12:05PM"
        },
        "geolocation": [
            26.8415947,
            -80.134094
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.83,
                    "duration": "5 Months",
                    "model_conf": 147.0
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.44,
                    "duration": "indefinitely",
                    "model_conf": 184.14
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.32,
                    "duration": "1 Months",
                    "model_conf": 110.25
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Palm Beach Gardens, FL",
            "slug": "3820e690-4c04-422b-8873-561d1b567b6f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/28/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/palm-beach-gardens/6271-pga-boulevard-203/",
        "index": 98.425,
        "franchise-entity-name": "92 FITNESS CREW FLORIDA II, LLC"
    },
    "95e24a22-0f12-460e-94cb-a4f8cae362ff": {
        "name": "Orange Theory Palm Desert, CA",
        "address": "73-470 El Paseo Palm Desert, California 92260",
        "phone": "(760) 624-8010",
        "email": "studiomanager0548@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            33.7199974,
            -116.38341
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.95,
                    "duration": "indefinitely",
                    "model_conf": 153.77
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.44,
                    "duration": "indefinitely",
                    "model_conf": 184.14
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.01,
                    "duration": "indefinitely",
                    "model_conf": 111.69
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Palm Desert, CA",
            "slug": "95e24a22-0f12-460e-94cb-a4f8cae362ff"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/palm-desert/73-470-el-paseo/",
        "index": 103.375,
        "franchise-entity-name": "DESERT FITNESS GROUP, LLC"
    },
    "d16510f1-8126-4745-9ae5-4f8c45eb6f3c": {
        "name": "Orange Theory Palm Harbor, FL",
        "address": "33645 US Highway, 19 North Palm Harbor, Florida 34660",
        "phone": "(727) 288-9901",
        "email": "studiomanager0121@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            28.0783997,
            -82.738441
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.79,
                    "duration": "indefinitely",
                    "model_conf": 151.87
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.13,
                    "duration": "2 Months",
                    "model_conf": 176.95
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.62,
                    "duration": "indefinitely",
                    "model_conf": 110.11
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Palm Harbor, FL",
            "slug": "d16510f1-8126-4745-9ae5-4f8c45eb6f3c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/11/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/palm-harbor/33645-us-highway-19-north/",
        "index": 98.425,
        "franchise-entity-name": "PALM HARBOR COACHING,LLC"
    },
    "548f4e61-1e57-44d2-b06f-b4a92c3f59f5": {
        "name": "Orange Theory Palm Springs",
        "address": "333 South Palm Canyon Drive Palm Springs, California 92262",
        "phone": "(760) 764-8010",
        "email": "studiomanager1271@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 6:30PM",
            "TUESDAY": "6:15AM - 6:30PM",
            "WEDNESDAY": "6:15AM - 6:30PM",
            "THURSDAY": "6:15AM - 6:30PM",
            "FRIDAY": "6:15AM - 6:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            33.819124,
            -116.54717
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.78,
                    "duration": "indefinitely",
                    "model_conf": 153.77
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.49,
                    "duration": "1 Months",
                    "model_conf": 185.44
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.39,
                    "duration": "indefinitely",
                    "model_conf": 111.88
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Palm Springs",
            "slug": "548f4e61-1e57-44d2-b06f-b4a92c3f59f5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/18/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/palm-springs/333-south-palm-canyon-drive/",
        "index": 103.375,
        "franchise-entity-name": "COACHELLA VALLEY FITNESS GROUP, LLC"
    },
    "090b341c-e411-4bfe-93db-00dca9120002": {
        "name": "Orange Theory Palo Alto, CA",
        "address": "2190 W Bayshore Road, Suite 150 Palo Alto, California 94303",
        "phone": "(650) 328-1550",
        "email": "studiomanager0215@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:40PM",
            "TUESDAY": "5:00AM - 6:40PM",
            "WEDNESDAY": "5:00AM - 6:40PM",
            "THURSDAY": "5:00AM - 6:40PM",
            "FRIDAY": "5:00AM - 6:40PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            37.4480705,
            -122.12637
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.87,
                    "duration": "indefinitely",
                    "model_conf": 153.31
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.35,
                    "duration": "indefinitely",
                    "model_conf": 182.4
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.3,
                    "duration": "indefinitely",
                    "model_conf": 110.72
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Palo Alto, CA",
            "slug": "090b341c-e411-4bfe-93db-00dca9120002"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/10/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/palo-alto/2190-w-bayshore-road-suite-150/",
        "index": 111.325,
        "franchise-entity-name": "MKS PARTNERS ONE, LLC"
    },
    "c9ebb6e6-804f-462b-8e99-6d545c7f6bd7": {
        "name": "Orange Theory Panama City Beach, FL",
        "address": "15500 Panama City Beach Pkwy, Unit 780 Panama City Beach, Florida 32413",
        "phone": "(850) 299-4999",
        "email": "studiomanager0848@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            30.22207,
            -85.86483
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.63,
                    "duration": "2 Months",
                    "model_conf": 153.81
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.09,
                    "duration": "indefinitely",
                    "model_conf": 185.75
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.98,
                    "duration": "indefinitely",
                    "model_conf": 111.91
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Panama City Beach, FL",
            "slug": "c9ebb6e6-804f-462b-8e99-6d545c7f6bd7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/7/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/panama-city/15500-panama-city-parkway-780/",
        "index": 93.4,
        "franchise-entity-name": "PANAMA CITY FITNESS GROUP LLC"
    },
    "7ddb8c09-88f9-410c-a87e-f247c19ab79c": {
        "name": "Orange Theory Paoli, PA",
        "address": "82 East Lancaster Ave. Paoli, Pennsylvania 19301",
        "phone": "(484) 674-6400",
        "email": "studiomanager0572@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:30PM",
            "TUESDAY": "5:30AM - 7:30PM",
            "WEDNESDAY": "5:30AM - 7:30PM",
            "THURSDAY": "5:30AM - 7:30PM",
            "FRIDAY": "5:30AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            40.0415421,
            -75.479362
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.17,
                    "duration": "indefinitely",
                    "model_conf": 150.66
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.27,
                    "duration": "indefinitely",
                    "model_conf": 183.19
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.01,
                    "duration": "indefinitely",
                    "model_conf": 106.97
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Paoli, PA",
            "slug": "7ddb8c09-88f9-410c-a87e-f247c19ab79c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/11/16",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/paoli/82-east-lancaster-ave/",
        "index": 103.375,
        "franchise-entity-name": "F.O.L. FITNESS, LLC"
    },
    "cb8fedc0-321a-4ada-8591-6a4eca3b6bc9": {
        "name": "Orange Theory Papillion Bellevue",
        "address": "9820 S 71st Plaza Papillion, Nebraska 68133",
        "phone": "(402) 915-0111",
        "email": "studiomanager1042@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.1620293,
            -96.021812
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.06,
                    "duration": "indefinitely",
                    "model_conf": 145.9
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.46,
                    "duration": "5 Months",
                    "model_conf": 175.58
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.11,
                    "duration": "indefinitely",
                    "model_conf": 109.86
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Papillion Bellevue",
            "slug": "cb8fedc0-321a-4ada-8591-6a4eca3b6bc9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/9/18",
        "url": "https://www.orangetheory.com/en-us/locations/nebraska/papillion/9820-s-71st-plaza/",
        "index": 93.4,
        "franchise-entity-name": "MZ STRONG 1, LLC"
    },
    "d09bdca5-17fa-416e-86bb-6dd15fee8712": {
        "name": "Orange Theory Park City, UT",
        "address": "1678 West Redstone Center Drive Park City, Utah 84098",
        "phone": "(435) 575-5575",
        "email": "studiomanager0518@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            40.7206001,
            -111.54208
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.98,
                    "duration": "indefinitely",
                    "model_conf": 153.75
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.63,
                    "duration": "1 Months",
                    "model_conf": 182.87
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.17,
                    "duration": "indefinitely",
                    "model_conf": 111.89
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Park City, UT",
            "slug": "d09bdca5-17fa-416e-86bb-6dd15fee8712"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/13/16",
        "url": "https://www.orangetheory.com/en-us/locations/utah/park-city/1678-west-redstone-center-drive/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS UTAH, LLC"
    },
    "891a165c-756c-4443-a222-3f008af97c97": {
        "name": "Orange Theory Park Ridge, IL",
        "address": "10 N. Northwest Hwy. Park Ridge, Illinois 60068",
        "phone": "(847) 318-3804",
        "email": "studiomanager0332@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:00AM - 1:30PM",
            "SUNDAY": "6:00AM - 1:30PM"
        },
        "geolocation": [
            42.0115089,
            -87.830185
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.08,
                    "duration": "indefinitely",
                    "model_conf": 146.26
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.05,
                    "duration": "indefinitely",
                    "model_conf": 174.01
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.62,
                    "duration": "indefinitely",
                    "model_conf": 106.53
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Park Ridge, IL",
            "slug": "891a165c-756c-4443-a222-3f008af97c97"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/3/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/park-ridge/10-n-northwest-hwy/",
        "index": 103.375,
        "franchise-entity-name": "STUDIO FITNESS GROUP HIGHLAND PARK LLC"
    },
    "119123be-059f-4ba1-861a-c42447fd3f89": {
        "name": "Orange Theory Pine Bluffs, CO",
        "address": "12227 Pine Bluffs Way, Suite 205 Parker, Colorado 80134",
        "phone": "(720) 600-4711",
        "email": "studiomanager1362@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "6:30AM - 11:30AM",
            "SUNDAY": "6:30AM - 11:30AM"
        },
        "geolocation": [
            39.4940643,
            -104.75582
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.64,
                    "duration": "indefinitely",
                    "model_conf": 145.58
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.37,
                    "duration": "2 Months",
                    "model_conf": 176.56
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.27,
                    "duration": "indefinitely",
                    "model_conf": 105.94
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Pine Bluffs, CO",
            "slug": "119123be-059f-4ba1-861a-c42447fd3f89"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/28/20",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/parker/12227-pine-bluffs-way-suite-205/",
        "index": 98.425,
        "franchise-entity-name": "Fit In Pine Bluffs LLC"
    },
    "54903d98-8315-4eea-a011-040c8d30acf2": {
        "name": "Orange Theory Parker, CO",
        "address": "17051 Lincoln Ave Parker, Colorado 80134",
        "phone": "(720) 636-8606",
        "email": "studiomanager0074@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "6:15AM - 12:45PM",
            "SUNDAY": "6:15AM - 12:45PM"
        },
        "geolocation": [
            39.5348358,
            -104.79031
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.45,
                    "duration": "indefinitely",
                    "model_conf": 131.63
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.77,
                    "duration": "indefinitely",
                    "model_conf": 160.55
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.15,
                    "duration": "indefinitely",
                    "model_conf": 96.3
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Parker, CO",
            "slug": "54903d98-8315-4eea-a011-040c8d30acf2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/10/14",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/parker/17051-lincoln-ave/",
        "index": 98.425,
        "franchise-entity-name": "FIT IN PARKER"
    },
    "be4c73b9-c8ba-430d-b5f4-cdd2d7cfd4ce": {
        "name": "Orange Theory Parsippany, NJ",
        "address": "38 Waterview Boulevard Parsippany, New Jersey 07054",
        "phone": "(973) 559-6100",
        "email": "studiomanager0782@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            40.8756752,
            -74.426292
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 82.57,
                    "duration": "indefinitely",
                    "model_conf": 149.25
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 114.88,
                    "duration": "indefinitely",
                    "model_conf": 175.56
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 181.89,
                    "duration": "indefinitely",
                    "model_conf": 107.77
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Parsippany, NJ",
            "slug": "be4c73b9-c8ba-430d-b5f4-cdd2d7cfd4ce"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/parsippany/38-waterview-boulevard/",
        "index": 98.425,
        "franchise-entity-name": "HUDSON FITNESS NJ 2, LLC"
    },
    "e3518a3f-ab79-4a63-800f-3848f852755b": {
        "name": "Orange Theory Pasadena, CA",
        "address": "2091 East Colorado Blvd. Pasadena, California 91107",
        "phone": "(626) 788-9755",
        "email": "studiomanager0168@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:30AM - 11:15AM",
            "SUNDAY": "6:30AM - 11:15AM"
        },
        "geolocation": [
            34.1463509,
            -118.10725
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.42,
                    "duration": "indefinitely",
                    "model_conf": 152.21
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.18,
                    "duration": "2 Months",
                    "model_conf": 162.99
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.24,
                    "duration": "indefinitely",
                    "model_conf": 87.51
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Pasadena, CA",
            "slug": "e3518a3f-ab79-4a63-800f-3848f852755b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/1/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/pasadena/2091-east-colorado-blvd/",
        "index": 103.375,
        "franchise-entity-name": "INLAND FITNESS, LLC"
    },
    "6160337e-e717-43ba-a994-a354e9083d73": {
        "name": "Orange Theory Pasadena, TX",
        "address": "7730 Spencer Highway Pasadena, Texas 77505",
        "phone": "(281) 377-5300",
        "email": "studiomanager1275@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            29.6631107,
            -95.120529
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.47,
                    "duration": "12 Months",
                    "model_conf": 129.35
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 122.4,
                    "duration": "indefinitely",
                    "model_conf": 168.54
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.47,
                    "duration": "3 Months",
                    "model_conf": 103.15
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Pasadena, TX",
            "slug": "6160337e-e717-43ba-a994-a354e9083d73"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/18/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/pasadena/7730-spencer-highway/",
        "index": 98.425,
        "franchise-entity-name": "JJ SPARKS INVESTORS, LLC"
    },
    "6f453cbd-5657-45bb-a5e6-e9f3b7c8ec1c": {
        "name": "Orange Theory Peachtree City, GA",
        "address": "130 Peachtree East Shopping Center, Suite 140 Peachtree City, Georgia 30269",
        "phone": "(678) 263-3086",
        "email": "studiomanager0298@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.4235344,
            -84.557648
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.25,
                    "duration": "indefinitely",
                    "model_conf": 153.66
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.6,
                    "duration": "1 Months",
                    "model_conf": 185.09
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.2,
                    "duration": "indefinitely",
                    "model_conf": 111.9
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Peachtree City, GA",
            "slug": "6f453cbd-5657-45bb-a5e6-e9f3b7c8ec1c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/17/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/peachtree-city/130-peachtree-east-shopping-center-suite-140/",
        "index": 98.425,
        "franchise-entity-name": "BMM FITNESS, LLC"
    },
    "41cf1ca8-97a8-4ed7-ba80-f0e0350c1e8b": {
        "name": "Orange Theory Pearland, TX",
        "address": "9517 W Broadway, Suite 115 Pearland, Texas 77584",
        "phone": "(281) 377-1723",
        "email": "studiomanager0361@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            29.5576,
            -95.367538
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.37,
                    "duration": "3 Months",
                    "model_conf": 151.02
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.74,
                    "duration": "indefinitely",
                    "model_conf": 183.37
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.48,
                    "duration": "1 Months",
                    "model_conf": 109.88
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Pearland, TX",
            "slug": "41cf1ca8-97a8-4ed7-ba80-f0e0350c1e8b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/3/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/pearland/9517-w-broadway-suite-115/",
        "index": 98.425,
        "franchise-entity-name": "ALL SPORTS FITNESS STUDIOS LLC"
    },
    "2025027e-8a6d-4f75-bf84-70f34cb12ca1": {
        "name": "Orange Theory Pembroke Pines, FL",
        "address": "14918 Pines Blvd Pembroke Pines, Florida 33027",
        "phone": "(954) 534-9949",
        "email": "studiomanager0004@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            26.0063057,
            -80.348145
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.74,
                    "duration": "indefinitely",
                    "model_conf": 146.38
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.28,
                    "duration": "2 Months",
                    "model_conf": 181.82
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.62,
                    "duration": "indefinitely",
                    "model_conf": 109.87
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Pembroke Pines, FL",
            "slug": "2025027e-8a6d-4f75-bf84-70f34cb12ca1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/11/11",
        "url": "https://www.orangetheory.com/en-us/locations/florida/pembroke-pines/14918-pines-blvd/",
        "index": 98.425,
        "franchise-entity-name": "PINES OTF, INC."
    },
    "167beb1d-059b-4e0e-a4d3-7f26ea88b283": {
        "name": "Orange Theory Pensacola, FL",
        "address": "5555 North Davis Highway Pensacola, Florida 32503",
        "phone": "(850) 449-2232",
        "email": "studiomanager0664@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            30.477253,
            -87.225639
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.31,
                    "duration": "3 Months",
                    "model_conf": 153.11
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.69,
                    "duration": "indefinitely",
                    "model_conf": 184.17
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.34,
                    "duration": "1 Months",
                    "model_conf": 106.0
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Pensacola, FL",
            "slug": "167beb1d-059b-4e0e-a4d3-7f26ea88b283"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/30/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/pensacola/5555-north-davis-highway/",
        "index": 93.4,
        "franchise-entity-name": "ALYKAY FITNESS II, LLC"
    },
    "66a812c1-2fdd-47d9-8191-3d3d58bc4ebc": {
        "name": "Orange Theory Lake Pleasant, AZ",
        "address": "25314 N Lake Pleasant Pkwy, Suite 1260 Peoria, Arizona 85383",
        "phone": "(623) 566-1221",
        "email": "studiomanager0073@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            33.7135506,
            -112.27165
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 2.49,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.37,
                    "duration": "2 Months",
                    "model_conf": 151.08
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 3.61,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.87,
                    "duration": "indefinitely",
                    "model_conf": 180.54
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 5.29,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.59,
                    "duration": "indefinitely",
                    "model_conf": 110.74
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 6.86,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 10.64,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 14.28,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Lake Pleasant, AZ",
            "slug": "66a812c1-2fdd-47d9-8191-3d3d58bc4ebc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/10/14",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/peoria/25314-n-lake-pleasant-pkwy-suite-1260/",
        "index": 103.375,
        "franchise-entity-name": "LAKE PLEASANT OTF LLC"
    },
    "2c4c04d1-cf4d-49cb-a4f0-0ee492a815ee": {
        "name": "Orange Theory Perrysburg, OH",
        "address": "3190 Chappel Dr Perrysburg, Ohio 43551",
        "phone": "(419) 469-6911",
        "email": "studiomanager0777@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            41.5265274,
            -83.639473
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.17,
                    "duration": "indefinitely",
                    "model_conf": 152.82
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.05,
                    "duration": "indefinitely",
                    "model_conf": 185.23
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.25,
                    "duration": "indefinitely",
                    "model_conf": 111.99
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Perrysburg, OH",
            "slug": "2c4c04d1-cf4d-49cb-a4f0-0ee492a815ee"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/perrysburg/3190-chappel-dr/",
        "index": 93.4,
        "franchise-entity-name": "FORT MEIGS 22 FITNESS CENTER, LLC"
    },
    "ff202e6f-253c-4332-8fc2-b7852d5c385f": {
        "name": "Orange Theory Northern Liberties, PA",
        "address": "1050 N Hancock St #58 Philadelphia, Pennsylvania 19123",
        "phone": "(267) 331-4445",
        "email": "studiomanager0899@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            39.966628,
            -75.139023
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 118.7,
                    "duration": "indefinitely",
                    "model_conf": 152.77
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.63,
                    "duration": "indefinitely",
                    "model_conf": 182.04
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.44,
                    "duration": "indefinitely",
                    "model_conf": 108.89
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Northern Liberties, PA",
            "slug": "ff202e6f-253c-4332-8fc2-b7852d5c385f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/5/18",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/philadelphia/1050-n-hancock-street-suite-58/",
        "index": 118.225,
        "franchise-entity-name": "TAYLOR FITNESS, LLC"
    },
    "b0713cad-0c71-40e3-ac99-89f4a6bd80af": {
        "name": "Orange Theory Center City, PA",
        "address": "1625 Chestnut Street Philadelphia, Pennsylvania 19103",
        "phone": "(215) 543-3833",
        "email": "studiomanager0556@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:15AM - 2:30PM",
            "SUNDAY": "7:15AM - 2:30PM"
        },
        "geolocation": [
            39.9518547,
            -75.167953
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.26,
                    "duration": "indefinitely",
                    "model_conf": 152.64
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.12,
                    "duration": "2 Months",
                    "model_conf": 182.6
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.79,
                    "duration": "indefinitely",
                    "model_conf": 109.35
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Center City, PA",
            "slug": "b0713cad-0c71-40e3-ac99-89f4a6bd80af"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/philadelphia/1625-chestnut-street/",
        "index": 118.225,
        "franchise-entity-name": "CENTER CITY FITNESS LLC"
    },
    "0069d782-cb18-4ce6-9be1-67eee007dc50": {
        "name": "Orange Theory Paradise Valley, AZ",
        "address": "10810 North Tatum Boulevard, Suite 108 Phoenix, Arizona 85028",
        "phone": "(602) 493-2002",
        "email": "studiomanager0025@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            33.5845337,
            -111.97906
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.36,
                    "duration": "2 Months",
                    "model_conf": 110.9
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.08,
                    "duration": "indefinitely",
                    "model_conf": 130.78
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.52,
                    "duration": "indefinitely",
                    "model_conf": 81.79
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Paradise Valley, AZ",
            "slug": "0069d782-cb18-4ce6-9be1-67eee007dc50"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/28/12",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/phoenix/10810-north-tatum-boulevard-suite-108/",
        "index": 103.375,
        "franchise-entity-name": "CITRUS FITNESS, LLC"
    },
    "d030cdb3-4a1b-46a3-9e87-0af542473b5e": {
        "name": "Orange Theory Camelback Colonnade, AZ",
        "address": "1928 E. Highland Ave., Suite 105 Phoenix, Arizona 85016",
        "phone": "(602) 277-5400",
        "email": "studiomanager0638@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:45AM - 1:00PM",
            "SUNDAY": "7:45AM - 1:00PM"
        },
        "geolocation": [
            33.5060425,
            -112.04141
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.61,
                    "duration": "indefinitely",
                    "model_conf": 88.61
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.52,
                    "duration": "1 Months",
                    "model_conf": 105.66
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.32,
                    "duration": "indefinitely",
                    "model_conf": 65.44
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Camelback Colonnade, AZ",
            "slug": "d030cdb3-4a1b-46a3-9e87-0af542473b5e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/21/16",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/phoenix/1928-e-highland-ave-suite-105/",
        "index": 103.375,
        "franchise-entity-name": "LIVEFIT3, LLC"
    },
    "027d24f1-df3c-45d3-9968-5599165889b8": {
        "name": "Orange Theory South Mountain, AZ",
        "address": "2485 E Baseline Road, Suite #152 Phoenix, Arizona 85042",
        "phone": "(602) 362-6835",
        "email": "studiomanager0239@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            33.377079,
            -112.02785
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.81,
                    "duration": "indefinitely",
                    "model_conf": 143.15
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.07,
                    "duration": "indefinitely",
                    "model_conf": 171.89
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.25,
                    "duration": "indefinitely",
                    "model_conf": 104.09
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "South Mountain, AZ",
            "slug": "027d24f1-df3c-45d3-9968-5599165889b8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/11/17",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/phoenix/2485-e-baseline-road-suite-152/",
        "index": 103.375,
        "franchise-entity-name": "SOUTH MOUNTAIN FITNESS PARTNERS, LLC"
    },
    "852e8d44-d7bc-49fe-b0d7-24bc7c494e39": {
        "name": "Orange Theory Happy Valley, AZ",
        "address": "2501 West Happy Valley Road Suite 32-1020 Phoenix, Arizona 85085",
        "phone": "(602) 456-6900",
        "email": "studiomanager0088@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            33.7107658,
            -112.1096
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.05,
                    "duration": "4 Months",
                    "model_conf": 148.78
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.43,
                    "duration": "indefinitely",
                    "model_conf": 177.68
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.26,
                    "duration": "1 Months",
                    "model_conf": 109.27
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Happy Valley, AZ",
            "slug": "852e8d44-d7bc-49fe-b0d7-24bc7c494e39"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/4/13",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/phoenix/2501-west-happy-valley-road-suite-32-1020/",
        "index": 103.375,
        "franchise-entity-name": "MOON VALLEY FITNESS LLC"
    },
    "e23ce6b5-cfd8-4d70-a8a5-5c5666fed4ff": {
        "name": "Orange Theory Arcadia, AZ",
        "address": "3923 E. Camelback Rd Phoenix, Arizona 85018",
        "phone": "(602) 955-1798",
        "email": "studiomanager0030@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:45AM - 1:00PM",
            "SUNDAY": "7:45AM - 1:00PM"
        },
        "geolocation": [
            33.5095596,
            -111.99628
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.8,
                    "duration": "indefinitely",
                    "model_conf": 111.34
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 126.9,
                    "duration": "6 Months",
                    "model_conf": 133.35
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.19,
                    "duration": "indefinitely",
                    "model_conf": 82.33
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Arcadia, AZ",
            "slug": "e23ce6b5-cfd8-4d70-a8a5-5c5666fed4ff"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/3/13",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/phoenix/3923-e-camelback-rd/",
        "index": 103.375,
        "franchise-entity-name": "FIT READY LLC"
    },
    "0d13fbab-b23c-4192-8855-c784850de0bc": {
        "name": "Orange Theory Downtown Phoenix, AZ",
        "address": "50 W Jefferson Street, Suite 150 Phoenix, Arizona 85003",
        "phone": "(602) 254-2929",
        "email": "studiomanager0232@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:45AM - 12:45PM",
            "SUNDAY": "8:45AM - 12:45PM"
        },
        "geolocation": [
            33.4474831,
            -112.07463
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.76,
                    "duration": "5 Months",
                    "model_conf": 123.21
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.44,
                    "duration": "indefinitely",
                    "model_conf": 149.46
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.3,
                    "duration": "1 Months",
                    "model_conf": 91.47
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Downtown Phoenix, AZ",
            "slug": "0d13fbab-b23c-4192-8855-c784850de0bc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/26/15",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/phoenix/50-w-jefferson-street-suite-150/",
        "index": 103.375,
        "franchise-entity-name": "FITNESS BY KAFORA AT DOWNTOWN, LLC"
    },
    "200baa72-a6b9-4bf4-96bb-db96a1e9933e": {
        "name": "Orange Theory Scottsdale, AZ",
        "address": "7000 E Mayo Blvd Building 1 Ste 1016 Phoenix, Arizona 85054",
        "phone": "(480) 473-5593",
        "email": "studiomanager0003@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            33.6569061,
            -111.92989
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.89,
                    "duration": "4 Months",
                    "model_conf": 105.13
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.86,
                    "duration": "indefinitely",
                    "model_conf": 121.36
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.93,
                    "duration": "2 Months",
                    "model_conf": 78.24
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Scottsdale, AZ",
            "slug": "200baa72-a6b9-4bf4-96bb-db96a1e9933e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/11",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/phoenix/7000-e-mayo-blvd-building-1-ste-1016/",
        "index": 103.375,
        "franchise-entity-name": "FITNESS BY KAFORA AT SCOTTSDALE, LLC"
    },
    "91435976-9107-4b4e-aa26-5a6c46af011c": {
        "name": "Orange Theory Phoenix - Central, AZ",
        "address": "742 E Glendale Ave, Suite 172 Phoenix, Arizona 85020",
        "phone": "(602) 749-1111",
        "email": "studiomanager0248@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 12:15PM",
            "SUNDAY": "6:00AM - 12:15PM"
        },
        "geolocation": [
            33.5389595,
            -112.06481
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.81,
                    "duration": "indefinitely",
                    "model_conf": 113.0
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.34,
                    "duration": "indefinitely",
                    "model_conf": 134.51
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.56,
                    "duration": "indefinitely",
                    "model_conf": 83.31
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Phoenix - Central, AZ",
            "slug": "91435976-9107-4b4e-aa26-5a6c46af011c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/6/15",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/phoenix/742-e-glendale-ave-suite-172/",
        "index": 103.375,
        "franchise-entity-name": "BORN READY LLC"
    },
    "e94726bb-ec4d-4f8f-8b65-684dd4fbdf5c": {
        "name": "Orange Theory Phoenix-Midtown",
        "address": "3350 N 7th Ave #160 Phoenix-Midtown, Arizona 85013",
        "phone": "(602) 698-1714",
        "email": "studiomanager1093@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 12:30PM",
            "SUNDAY": "6:00AM - 12:30PM"
        },
        "geolocation": [
            33.4873428,
            -112.08321
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.03,
                    "duration": "4 Months",
                    "model_conf": 110.28
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.2,
                    "duration": "indefinitely",
                    "model_conf": 133.63
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.31,
                    "duration": "1 Months",
                    "model_conf": 81.78
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Phoenix-Midtown",
            "slug": "e94726bb-ec4d-4f8f-8b65-684dd4fbdf5c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/29/18",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/phoenix-midtown/3350-n-7th-ave-160/",
        "index": 103.375,
        "franchise-entity-name": "Lucky 7th LLC"
    },
    "1c8b93b1-3615-4d9d-89f6-b85a6da33e5c": {
        "name": "Orange Theory Moon Valley, AZ",
        "address": "753 East Bell Road, Suite 11 Phoeniz, Arizona 85022",
        "phone": "(602) 292-5636",
        "email": "studiomanager0089@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:45AM - 11:15AM",
            "SUNDAY": "7:45AM - 11:15AM"
        },
        "geolocation": [
            33.6391983,
            -112.06437
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.89,
                    "duration": "4 Months",
                    "model_conf": 140.22
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.71,
                    "duration": "indefinitely",
                    "model_conf": 166.73
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.28,
                    "duration": "1 Months",
                    "model_conf": 102.8
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Moon Valley, AZ",
            "slug": "1c8b93b1-3615-4d9d-89f6-b85a6da33e5c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/5/14",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/phoeniz/753-east-bell-road-suite-11/",
        "index": 103.375,
        "franchise-entity-name": "NORTH VALLEY OTF, LLC"
    },
    "a6de6b6b-fd5f-4002-b2b3-fc2c5aa01df3": {
        "name": "Orange Theory Pickerington, OH",
        "address": "10710 BLACKLICK EASTERN RD NW Pickerington, Ohio 43147",
        "phone": "(614) 347-1996",
        "email": "studiomanager1332@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            39.929307,
            -82.78582
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.18,
                    "duration": "4 Months",
                    "model_conf": 148.66
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.81,
                    "duration": "indefinitely",
                    "model_conf": 181.84
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.64,
                    "duration": "3 Months",
                    "model_conf": 108.85
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Pickerington, OH",
            "slug": "a6de6b6b-fd5f-4002-b2b3-fc2c5aa01df3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/21",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/pickerington/10710-blacklick-eastern-rd-nw/",
        "index": 93.4,
        "franchise-entity-name": "MCB Fitness, LLC"
    },
    "d693e668-9e8e-46af-b75e-0674b44630c4": {
        "name": "Orange Theory Fairfax-Main Street, VA",
        "address": "9420 Main Street #13 Pickett, Virginia 22031",
        "phone": "(703) 544-7755",
        "email": "studiomanager1110@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            38.84198,
            -77.270454
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.95,
                    "duration": "indefinitely",
                    "model_conf": 128.7
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.47,
                    "duration": "1 Months",
                    "model_conf": 155.62
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.85,
                    "duration": "indefinitely",
                    "model_conf": 93.56
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Fairfax-Main Street, VA",
            "slug": "d693e668-9e8e-46af-b75e-0674b44630c4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/6/18",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/pickett/9420-main-street-13/",
        "index": 98.425,
        "franchise-entity-name": "HH FITNESS FAIRFAX, LLC"
    },
    "e083f70f-b542-47cd-bdca-7b285b4b23a2": {
        "name": "Orange Theory Pinole, CA",
        "address": "1400 Pinole Valley Road Pinole, California 94564",
        "phone": "(510) 777-0600",
        "email": "studiomanager0807@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 11:30PM",
            "SUNDAY": "6:00AM - 11:30PM"
        },
        "geolocation": [
            37.9990234,
            -122.28735
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.81,
                    "duration": "indefinitely",
                    "model_conf": 153.42
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.26,
                    "duration": "2 Months",
                    "model_conf": 185.52
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.18,
                    "duration": "1 Months",
                    "model_conf": 111.76
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Pinole, CA",
            "slug": "e083f70f-b542-47cd-bdca-7b285b4b23a2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/pinole/1400-pinole-valley-road/",
        "index": 108.325,
        "franchise-entity-name": "SANTARA PINOLE, LLC"
    },
    "94dde5fd-1d53-4566-b5ca-16a758379e2d": {
        "name": "Orange Theory Pittsburgh, PA",
        "address": "5841 Penn Ave Pittsburgh, Pennsylvania 15206",
        "phone": "(412) 362-3616",
        "email": "studiomanager0207@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:15AM - 2:00PM",
            "SUNDAY": "7:15AM - 2:00PM"
        },
        "geolocation": [
            40.4623375,
            -79.927338
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.72,
                    "duration": "7 Months",
                    "model_conf": 152.92
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.92,
                    "duration": "indefinitely",
                    "model_conf": 183.62
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.82,
                    "duration": "2 Months",
                    "model_conf": 108.41
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Pittsburgh, PA",
            "slug": "94dde5fd-1d53-4566-b5ca-16a758379e2d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/26/15",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/pittsburgh/5841-penn-ave/",
        "index": 93.4,
        "franchise-entity-name": "TANGERINE RETAIL VENTURES I, LLC"
    },
    "ca151f59-3f63-4f7d-ab66-a33920b0f112": {
        "name": "Orange Theory Rochester - Pittsford, NY",
        "address": "3340 Monroe Ave Pittsford, New York 14445",
        "phone": "(1585) 445-7173",
        "email": "studiomanager0070@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:50AM - 7:30PM",
            "TUESDAY": "4:50AM - 7:30PM",
            "WEDNESDAY": "4:50AM - 7:30PM",
            "THURSDAY": "4:50AM - 7:30PM",
            "FRIDAY": "4:50AM - 7:30PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            43.1027222,
            -77.538132
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.02,
                    "duration": "4 Months",
                    "model_conf": 153.82
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.76,
                    "duration": "indefinitely",
                    "model_conf": 183.84
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.12,
                    "duration": "1 Months",
                    "model_conf": 111.66
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Rochester - Pittsford, NY",
            "slug": "ca151f59-3f63-4f7d-ab66-a33920b0f112"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/23/15",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/pittsford/3340-monroe-ave/",
        "index": 98.425,
        "franchise-entity-name": "PITTSFORD FITNESS, LLC"
    },
    "bfcbb24d-af09-4e84-9bf1-d44df14786f3": {
        "name": "Orange Theory North Richardson, TX",
        "address": "1300 W. Plano Parkway #140 Plano, Texas 75074",
        "phone": "(972) 200-4546",
        "email": "studiomanager1274@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 8:00PM",
            "TUESDAY": "4:30AM - 8:00PM",
            "WEDNESDAY": "4:30AM - 8:00PM",
            "THURSDAY": "4:30AM - 8:00PM",
            "FRIDAY": "4:30AM - 8:00PM",
            "SATURDAY": "7:15AM - 1:00PM",
            "SUNDAY": "7:15AM - 1:00PM"
        },
        "geolocation": [
            33.0082588,
            -96.717216
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.0,
                    "duration": "indefinitely",
                    "model_conf": 126.24
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.4,
                    "duration": "5 Months",
                    "model_conf": 148.69
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.84,
                    "duration": "indefinitely",
                    "model_conf": 92.94
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "North Richardson, TX",
            "slug": "bfcbb24d-af09-4e84-9bf1-d44df14786f3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/3/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/plano/1300-w-plano-parkway-140/",
        "index": 93.4,
        "franchise-entity-name": "CREEKSIDE FITNESS LLC"
    },
    "755549fd-8210-4142-9b74-2f5412cf2fd8": {
        "name": "Orange Theory West Plano, TX",
        "address": "2208 Dallas Parkway Suite 321 Plano, Texas 75093",
        "phone": "(972) 521-3500",
        "email": "studiomanager0131@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 8:00PM",
            "TUESDAY": "4:45AM - 8:00PM",
            "WEDNESDAY": "4:45AM - 8:00PM",
            "THURSDAY": "4:45AM - 8:00PM",
            "FRIDAY": "4:45AM - 8:00PM",
            "SATURDAY": "6:00AM - 2:00PM",
            "SUNDAY": "6:00AM - 2:00PM"
        },
        "geolocation": [
            33.0307541,
            -96.82798
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.3,
                    "duration": "9 Months",
                    "model_conf": 119.71
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.81,
                    "duration": "indefinitely",
                    "model_conf": 143.56
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.66,
                    "duration": "3 Months",
                    "model_conf": 89.72
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "West Plano, TX",
            "slug": "755549fd-8210-4142-9b74-2f5412cf2fd8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/11/14",
        "url": "https://www.orangetheory.com/en-us/locations/texas/plano/2208-dallas-parkway-suite-321/",
        "index": 93.4,
        "franchise-entity-name": "OTF WEST PLANO, LLC"
    },
    "0f0597cb-5e25-4e00-b3f4-e5674fd8e87e": {
        "name": "Orange Theory North Plano, TX",
        "address": "8245 Preston Rd. Suite 200 Plano, Texas 75024",
        "phone": "(469) 708-2090",
        "email": "studiomanager0813@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:45PM",
            "TUESDAY": "5:30AM - 8:45PM",
            "WEDNESDAY": "5:30AM - 8:45PM",
            "THURSDAY": "5:30AM - 8:45PM",
            "FRIDAY": "5:30AM - 8:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            33.089447,
            -96.805664
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.34,
                    "duration": "3 Months",
                    "model_conf": 135.81
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.56,
                    "duration": "indefinitely",
                    "model_conf": 161.93
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.57,
                    "duration": "indefinitely",
                    "model_conf": 99.86
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "North Plano, TX",
            "slug": "0f0597cb-5e25-4e00-b3f4-e5674fd8e87e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/18/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/plano/8245-preston-rd-suite-200/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA FITNESS- NORTH PLANO LLC"
    },
    "e3f33695-612a-41c2-9117-7fed735219b9": {
        "name": "Orange Theory Plantation, FL",
        "address": "10073 Cleary Boulevard Plantation, Florida 33324",
        "phone": "(954) 693-8444",
        "email": "studiomanager0027@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 1:30PM",
            "SUNDAY": "6:30AM - 1:30PM"
        },
        "geolocation": [
            26.1350441,
            -80.284225
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.02,
                    "duration": "indefinitely",
                    "model_conf": 147.27
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.93,
                    "duration": "indefinitely",
                    "model_conf": 181.99
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.11,
                    "duration": "indefinitely",
                    "model_conf": 111.08
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Plantation, FL",
            "slug": "e3f33695-612a-41c2-9117-7fed735219b9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/26/12",
        "url": "https://www.orangetheory.com/en-us/locations/florida/plantation/10073-cleary-boulevard/",
        "index": 98.425,
        "franchise-entity-name": "KBOG PLANTATION, INC."
    },
    "07ab789b-3c14-4903-bb46-8f9f890917d4": {
        "name": "Orange Theory Playa Vista, CA",
        "address": "12746 West Jefferson Blvd Suite 3-3120 Playa Vista, California 90094",
        "phone": "(213) 279-2006",
        "email": "studiomanager0736@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            33.9771385,
            -118.41759
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.62,
                    "duration": "indefinitely",
                    "model_conf": 148.16
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.19,
                    "duration": "2 Months",
                    "model_conf": 180.01
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.94,
                    "duration": "indefinitely",
                    "model_conf": 107.5
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Playa Vista, CA",
            "slug": "07ab789b-3c14-4903-bb46-8f9f890917d4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/20",
        "url": "https://www.orangetheory.com/en-us/locations/california/playa-vista/12746-west-jefferson-blvd-building-3/",
        "index": 111.325,
        "franchise-entity-name": "WCF PLAYA VISTA LLC"
    },
    "04df8100-2353-4f76-bae7-aa749dbaf170": {
        "name": "Orange Theory Pleasant Hill, CA",
        "address": "963 Contra Costa Blvd Pleasant Hill, California 94523",
        "phone": "(925) 400-9389",
        "email": "studiomanager0065@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:20AM - 11:50AM",
            "SUNDAY": "7:20AM - 11:50AM"
        },
        "geolocation": [
            37.9641419,
            -122.06227
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.9,
                    "duration": "indefinitely",
                    "model_conf": 153.13
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.71,
                    "duration": "indefinitely",
                    "model_conf": 180.8
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.59,
                    "duration": "indefinitely",
                    "model_conf": 109.88
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Pleasant Hill, CA",
            "slug": "04df8100-2353-4f76-bae7-aa749dbaf170"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/29/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/pleasant-hill/963-contra-costa-blvd/",
        "index": 108.325,
        "franchise-entity-name": "SFFIT PHL, LLC"
    },
    "af269862-7f11-458a-b023-6d5b2241dc36": {
        "name": "Orange Theory Pleasanton, CA",
        "address": "3020 Bernal Avenue Suite 140 Pleasanton, California 94566",
        "phone": "(925) 401-7399",
        "email": "studiomanager0200@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:20PM",
            "TUESDAY": "5:00AM - 7:20PM",
            "WEDNESDAY": "5:00AM - 7:20PM",
            "THURSDAY": "5:00AM - 7:20PM",
            "FRIDAY": "5:00AM - 7:20PM",
            "SATURDAY": "7:20AM - 11:50AM",
            "SUNDAY": "7:20AM - 11:50AM"
        },
        "geolocation": [
            37.6693687,
            -121.85658
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.08,
                    "duration": "indefinitely",
                    "model_conf": 152.86
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.81,
                    "duration": "indefinitely",
                    "model_conf": 182.79
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.11,
                    "duration": "indefinitely",
                    "model_conf": 110.86
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Pleasanton, CA",
            "slug": "af269862-7f11-458a-b023-6d5b2241dc36"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/17/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/pleasanton/3020-bernal-avenue-suite-140/",
        "index": 108.325,
        "franchise-entity-name": "SFFIT PTN, LLC"
    },
    "fc141e31-383e-480c-aed9-5541c1f42efa": {
        "name": "Orange Theory Plymouth, MN",
        "address": "2700 Annapolis Circle, Suite A Plymouth, Minnesota 55441",
        "phone": "(763) 300-3447",
        "email": "studiomanager0017@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:15AM - 2:00PM",
            "SUNDAY": "6:15AM - 2:00PM"
        },
        "geolocation": [
            45.00914,
            -93.451553
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.44,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.55,
                    "duration": "2 Months",
                    "model_conf": 142.99
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.45,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.94,
                    "duration": "indefinitely",
                    "model_conf": 175.83
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.96,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 157.72,
                    "duration": "3 Months",
                    "model_conf": 101.61
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.98,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 27.02,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 37.55,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Plymouth, MN",
            "slug": "fc141e31-383e-480c-aed9-5541c1f42efa"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/28/11",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/plymouth/2700-annapolis-circle-suite-a/",
        "index": 88.45,
        "franchise-entity-name": "ORANGE FITNESS, LLC"
    },
    "03025da0-cd84-4d83-91f4-fe01b267d86c": {
        "name": "Orange Theory Ponte Vedra Beach - Nocatee, FL",
        "address": "152 Capital Green Drive, Suite 34 Ponte Vedra, Florida 32081",
        "phone": "(904) 900-7644",
        "email": "studiomanager0838@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            30.1097012,
            -81.418327
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.82,
                    "duration": "1 Months",
                    "model_conf": 152.91
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.35,
                    "duration": "indefinitely",
                    "model_conf": 185.15
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.93,
                    "duration": "indefinitely",
                    "model_conf": 111.12
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Ponte Vedra Beach - Nocatee, FL",
            "slug": "03025da0-cd84-4d83-91f4-fe01b267d86c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/4/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/ponte-vedra/152-capital-green-drive-ste-32/",
        "index": 93.4,
        "franchise-entity-name": "F9 ENTERPRISES, LLC"
    },
    "2b0a000f-d8e7-4130-9280-047187c61684": {
        "name": "Orange Theory Pooler, GA",
        "address": "201 Blue Moon Crossing, Suites 3 &amp; 4 Pooler, Georgia 31322",
        "phone": "(912) 963-7076",
        "email": "studiomanager0379@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            32.0800934,
            -81.284195
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.03,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.95,
                    "duration": "indefinitely",
                    "model_conf": 184.77
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.05,
                    "duration": "indefinitely",
                    "model_conf": 111.81
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Pooler, GA",
            "slug": "2b0a000f-d8e7-4130-9280-047187c61684"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/8/18",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/pooler/201-blue-moon-crossing-suites-3-4/",
        "index": 98.425,
        "franchise-entity-name": "VVDC POOLER, LLC"
    },
    "e479cce7-331f-4fb1-a89f-42423d56d42f": {
        "name": "Orange Theory Port Jefferson",
        "address": "5040-5050 Nesconset Highway Port Jefferson Station, New York 11776",
        "phone": "(631) 760-1975",
        "email": "studiomanager1007@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            40.9197044,
            -73.055977
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 100.09,
                    "duration": "indefinitely",
                    "model_conf": 150.26
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.14,
                    "duration": "2 Months",
                    "model_conf": 183.45
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.69,
                    "duration": "indefinitely",
                    "model_conf": 111.86
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Port Jefferson",
            "slug": "e479cce7-331f-4fb1-a89f-42423d56d42f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/2/20",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/port-jefferson-station/5040-5050-nesconset-highway/",
        "index": 108.325,
        "franchise-entity-name": "Port Jeff Fitness LLC"
    },
    "4a4e0cdd-f19f-407f-a3ac-17ec56ae02a6": {
        "name": "Orange Theory Port Orange, FL",
        "address": "5521 South Williamson Boulevard Port Orange, Florida 32128",
        "phone": "(386) 492-0544",
        "email": "studiomanager0605@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            29.1108856,
            -81.033798
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.26,
                    "duration": "3 Months",
                    "model_conf": 148.96
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.83,
                    "duration": "indefinitely",
                    "model_conf": 185.56
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.17,
                    "duration": "1 Months",
                    "model_conf": 112.11
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Port Orange, FL",
            "slug": "4a4e0cdd-f19f-407f-a3ac-17ec56ae02a6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/19/20",
        "url": "https://www.orangetheory.com/en-us/locations/florida/port-orange/5521-south-williamson-boulevard/",
        "index": 98.425,
        "franchise-entity-name": "PORT ORANGE FITNESS COMPANY, LLC"
    },
    "3f3f8d18-3c6c-4738-8d72-8c21a75d7bad": {
        "name": "Orange Theory Port ST. Lucie, FL",
        "address": "884 SW St. Lucie West Blvd. Port St. Lucie, Florida 34986",
        "phone": "(772) 212-1470",
        "email": "studiomanager0449@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            27.3175468,
            -80.380646
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.99,
                    "duration": "indefinitely",
                    "model_conf": 151.72
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.82,
                    "duration": "indefinitely",
                    "model_conf": 185.75
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.88,
                    "duration": "indefinitely",
                    "model_conf": 111.9
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Port ST. Lucie, FL",
            "slug": "3f3f8d18-3c6c-4738-8d72-8c21a75d7bad"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/21/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/port-st-lucie/884-sw-st-lucie-west-blvd/",
        "index": 98.425,
        "franchise-entity-name": "PSL FITNESS, LLC"
    },
    "f96d00bd-1592-4a63-87fa-c0caca818881": {
        "name": "Orange Theory Portage MI",
        "address": "3762 W CENTRE AVE Portage, Michigan 49024",
        "phone": "(269) 286-8816",
        "email": "studiomanager1215@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            42.197693,
            -85.634539
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.0,
                    "duration": "indefinitely",
                    "model_conf": 153.27
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.76,
                    "duration": "indefinitely",
                    "model_conf": 185.54
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.72,
                    "duration": "indefinitely",
                    "model_conf": 111.87
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Portage MI",
            "slug": "f96d00bd-1592-4a63-87fa-c0caca818881"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/20",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/portage/3762-w-centre-ave/",
        "index": 93.4,
        "franchise-entity-name": "Grand Fitness Group, LLC"
    },
    "fd13c9bf-e52a-4291-87b2-2f132fad922e": {
        "name": "Orange Theory Portland, ME",
        "address": "23 Marginal Way Portland, Maine 04101",
        "phone": "(207) 536-5833",
        "email": "studiomanager0847@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            43.6604919,
            -70.266762
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.62,
                    "duration": "indefinitely",
                    "model_conf": 151.61
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.28,
                    "duration": "2 Months",
                    "model_conf": 185.62
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.32,
                    "duration": "indefinitely",
                    "model_conf": 108.45
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Portland, ME",
            "slug": "fd13c9bf-e52a-4291-87b2-2f132fad922e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/23/16",
        "url": "https://www.orangetheory.com/en-us/locations/maine/portland/23-marginal-way/",
        "index": 98.425,
        "franchise-entity-name": "DKBL ABUTILON, LLC"
    },
    "451a5dfb-8ade-454f-81a7-973da6e55b95": {
        "name": "Orange Theory Pearl District, OR",
        "address": "1010 NW Everett St, Ste 127 Portland, Oregon 97209",
        "phone": "(503) 400-6615",
        "email": "studiomanager0594@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            45.524996,
            -122.68144
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.51,
                    "duration": "indefinitely",
                    "model_conf": 152.94
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.21,
                    "duration": "5 Months",
                    "model_conf": 159.58
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.39,
                    "duration": "indefinitely",
                    "model_conf": 107.77
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Pearl District, OR",
            "slug": "451a5dfb-8ade-454f-81a7-973da6e55b95"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/10/22",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/portland/205-229-nw-10th-ave/",
        "index": 98.425,
        "franchise-entity-name": "JM PEARL DISTRICT LLC"
    },
    "c955972c-1cb3-4f59-bfd0-8368a3fd9f37": {
        "name": "Orange Theory Portland - Lloyd District, OR",
        "address": "1107 NE 9th Avenue Portland, Oregon 97232",
        "phone": "(503) 946-9409",
        "email": "studiomanager0486@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:30PM",
            "SUNDAY": "7:15AM - 12:30PM"
        },
        "geolocation": [
            45.5309029,
            -122.65666
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 81.3,
                    "duration": "indefinitely",
                    "model_conf": 152.76
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.39,
                    "duration": "8 Months",
                    "model_conf": 163.28
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.77,
                    "duration": "indefinitely",
                    "model_conf": 108.14
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Portland - Lloyd District, OR",
            "slug": "c955972c-1cb3-4f59-bfd0-8368a3fd9f37"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/11/16",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/portland/1107-ne-9th-avenue/",
        "index": 98.425,
        "franchise-entity-name": "ACAIBERRY, LLC"
    },
    "de0f81fa-7458-4018-a95a-f45dab93badd": {
        "name": "Orange Theory Cedar Mill, OR",
        "address": "11800 NW Cedar Falls Drive, Suite 108 Portland, Oregon 97229",
        "phone": "(503) 427-1915",
        "email": "studiomanager0336@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            45.5212708,
            -122.80089
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.63,
                    "duration": "11 Months",
                    "model_conf": 152.48
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 121.64,
                    "duration": "indefinitely",
                    "model_conf": 171.12
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.47,
                    "duration": "3 Months",
                    "model_conf": 109.4
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Cedar Mill, OR",
            "slug": "de0f81fa-7458-4018-a95a-f45dab93badd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/18/16",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/portland/11800-nw-cedar-falls-drive-suite-108/",
        "index": 98.425,
        "franchise-entity-name": "IN THE ZONE, LLC"
    },
    "cee498c6-6012-4411-8053-803943c003ca": {
        "name": "Orange Theory SE Powell, OR",
        "address": "3604 SE Powell Blvd Portland, Oregon 97202",
        "phone": "(503) 836-3719",
        "email": "studiomanager1457@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            45.4967346,
            -122.62603
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.82,
                    "duration": "indefinitely",
                    "model_conf": 153.0
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.49,
                    "duration": "8 Months",
                    "model_conf": 167.93
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.34,
                    "duration": "indefinitely",
                    "model_conf": 109.12
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "SE Powell, OR",
            "slug": "cee498c6-6012-4411-8053-803943c003ca"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/15/20",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/portland/3602-southeast-powell-boulevard-suite-2/",
        "index": 98.425,
        "franchise-entity-name": "JM SE Powell LLC"
    },
    "a22489af-26d2-4cb6-97a3-ff2e3e29515f": {
        "name": "Orange Theory Portland - South Waterfront, OR",
        "address": "3642 SW River Parkway Portland, Oregon 97239",
        "phone": "(503) 308-6782",
        "email": "studiomanager0487@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            45.4966774,
            -122.6693
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.99,
                    "duration": "indefinitely",
                    "model_conf": 153.05
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.69,
                    "duration": "7 Months",
                    "model_conf": 158.59
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.98,
                    "duration": "indefinitely",
                    "model_conf": 107.83
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Portland - South Waterfront, OR",
            "slug": "a22489af-26d2-4cb6-97a3-ff2e3e29515f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/4/17",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/portland/3642-sw-river-parkway/",
        "index": 98.425,
        "franchise-entity-name": "GOJIBERRY, LLC"
    },
    "d9df8e86-612f-44fb-b4cb-ff22cb14bc2d": {
        "name": "Orange Theory Goat Blocks, OR",
        "address": "888 SE 9th Ave Suite 100 Portland, Oregon 97214",
        "phone": "(503) 373-9722",
        "email": "studiomanager1099@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "8:45AM - 12:15PM",
            "SUNDAY": "8:45AM - 12:15PM"
        },
        "geolocation": [
            45.5168457,
            -122.65638
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 81.29,
                    "duration": "indefinitely",
                    "model_conf": 152.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 116.3,
                    "duration": "8 Months",
                    "model_conf": 161.18
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.57,
                    "duration": "indefinitely",
                    "model_conf": 108.03
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Goat Blocks, OR",
            "slug": "d9df8e86-612f-44fb-b4cb-ff22cb14bc2d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/29/18",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/portland/888-se-9th-ave-suite-100/",
        "index": 98.425,
        "franchise-entity-name": "JM SE PORTLAND, LLC"
    },
    "6d0a8259-517f-4687-8d2f-57a4bd96181f": {
        "name": "Orange Theory Portland-South, ME",
        "address": "364 Maine Mall Road, Space W-165 Portland-South, Maine 04106",
        "phone": "(207) 376-9100",
        "email": "studiomanager1055@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:10AM - 10:30AM",
            "SUNDAY": "7:10AM - 10:30AM"
        },
        "geolocation": [
            43.6342125,
            -70.336784
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.14,
                    "duration": "indefinitely",
                    "model_conf": 149.56
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.57,
                    "duration": "4 Months",
                    "model_conf": 185.69
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.45,
                    "duration": "indefinitely",
                    "model_conf": 105.32
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Portland-South, ME",
            "slug": "6d0a8259-517f-4687-8d2f-57a4bd96181f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/4/18",
        "url": "https://www.orangetheory.com/en-us/locations/maine/portland-south/364-maine-mall-road-space-w-165/",
        "index": 98.425,
        "franchise-entity-name": "TITHONIA, LLC"
    },
    "4991cfb3-fff6-44f0-a3d0-3bdb9437bbb9": {
        "name": "Orange Theory Portsmouth, NH",
        "address": "2454 Lafayette Road Portsmouth, New Hampshire 03801",
        "phone": "(603) 686-8287",
        "email": "studiomanager0592@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:45PM",
            "TUESDAY": "5:30AM - 7:45PM",
            "WEDNESDAY": "5:30AM - 7:45PM",
            "THURSDAY": "5:30AM - 7:45PM",
            "FRIDAY": "5:30AM - 7:45PM",
            "SATURDAY": "6:45AM - 12:00PM",
            "SUNDAY": "6:45AM - 12:00PM"
        },
        "geolocation": [
            43.0344734,
            -70.783455
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.95,
                    "duration": "indefinitely",
                    "model_conf": 153.68
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.06,
                    "duration": "indefinitely",
                    "model_conf": 185.75
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.99,
                    "duration": "indefinitely",
                    "model_conf": 112.11
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Portsmouth, NH",
            "slug": "4991cfb3-fff6-44f0-a3d0-3bdb9437bbb9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/26/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-hampshire/portsmouth/2454-lafayette-road/",
        "index": 98.425,
        "franchise-entity-name": "PERSONAL BEST, LLC"
    },
    "dfeee373-eea1-41da-b72e-d1828980986a": {
        "name": "Orange Theory Potomac, MD",
        "address": "12435 Park Potomac Avenue, R-10 Potomac, Maryland 20854",
        "phone": "(301) 265-5050",
        "email": "studiomanager0291@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            39.0595055,
            -77.157578
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.17,
                    "duration": "7 Months",
                    "model_conf": 144.46
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 141.02,
                    "duration": "indefinitely",
                    "model_conf": 170.85
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 197.58,
                    "duration": "2 Months",
                    "model_conf": 101.06
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Potomac, MD",
            "slug": "dfeee373-eea1-41da-b72e-d1828980986a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/9/16",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/potomac/12435-park-potomac-avenue-r-10/",
        "index": 108.325,
        "franchise-entity-name": "COLUMBIA FITNESS PARTNERS, LLC"
    },
    "a6eb14ef-914f-456c-9a8a-af00cae63172": {
        "name": "Orange Theory Poughkeepsie, NY",
        "address": "1895 South Road Poughkeepsie, New York 12601",
        "phone": "(845) 372-5656",
        "email": "studiomanager0442@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            41.61726,
            -73.917564
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.95,
                    "duration": "indefinitely",
                    "model_conf": 153.42
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.01,
                    "duration": "indefinitely",
                    "model_conf": 184.58
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.07,
                    "duration": "indefinitely",
                    "model_conf": 111.79
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Poughkeepsie, NY",
            "slug": "a6eb14ef-914f-456c-9a8a-af00cae63172"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/poughkeepsie/1895-south-road/",
        "index": 98.425,
        "franchise-entity-name": "POUGHKEEPSIE FITNESS LLC"
    },
    "6f3baf5e-e4f3-43bc-b8f2-2f5ce1840a59": {
        "name": "Orange Theory Poway, CA",
        "address": "12207 Poway Road, Poway, California 92025",
        "phone": "(858) 999-3004",
        "email": "studiomanager0092@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:15PM",
            "SUNDAY": "6:30AM - 12:15PM"
        },
        "geolocation": [
            32.9511147,
            -117.06664
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.98,
                    "duration": "indefinitely",
                    "model_conf": 152.78
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.01,
                    "duration": "indefinitely",
                    "model_conf": 182.0
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.04,
                    "duration": "indefinitely",
                    "model_conf": 109.29
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Poway, CA",
            "slug": "6f3baf5e-e4f3-43bc-b8f2-2f5ce1840a59"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/20/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/poway/12207-poway-road/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT LLC"
    },
    "d2d15d2a-705a-4870-9aa9-effa1ba5cf73": {
        "name": "Orange Theory Prattville, AL",
        "address": "571 Pinnacle Place Prattville, Alabama 36066",
        "phone": "(334) 380-5999",
        "email": "studiomanager1089@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 2:30PM",
            "SUNDAY": "8:00AM - 2:30PM"
        },
        "geolocation": [
            32.4635963,
            -86.397186
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.25,
                    "duration": "indefinitely",
                    "model_conf": 153.77
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.31,
                    "duration": "indefinitely",
                    "model_conf": 185.75
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.3,
                    "duration": "indefinitely",
                    "model_conf": 111.81
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Prattville, AL",
            "slug": "d2d15d2a-705a-4870-9aa9-effa1ba5cf73"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/4/19",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/prattville/571-pinnacle-place/",
        "index": 93.4,
        "franchise-entity-name": "PROV 163 LLC"
    },
    "95113487-2f86-414f-90f6-2fc2ded8aef3": {
        "name": "Orange Theory Preston Hollow, TX",
        "address": "7949 Walnut Hill Lane STE 165 Preston Hollow - Dallas, Texas 75230",
        "phone": "(469) 729-5866",
        "email": "studiomanager0339@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:30AM - 12:45PM",
            "SUNDAY": "7:30AM - 12:45PM"
        },
        "geolocation": [
            32.88019,
            -96.77144
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.28,
                    "duration": "3 Months",
                    "model_conf": 128.48
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.09,
                    "duration": "indefinitely",
                    "model_conf": 146.49
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.47,
                    "duration": "1 Months",
                    "model_conf": 98.02
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Preston Hollow, TX",
            "slug": "95113487-2f86-414f-90f6-2fc2ded8aef3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/22/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/dallas/7949-walnut-hill-ln-suite-165/",
        "index": 103.375,
        "franchise-entity-name": "VALENCIA FITNESS - PRESTON HOLLOW, LLC"
    },
    "9e038477-9d05-4e4d-ab8c-c055448edb29": {
        "name": "Orange Theory Princeton, NJ",
        "address": "640 Nassau Park Blvd Princeton, New Jersey 08540",
        "phone": "(609) 474-0090",
        "email": "studiomanager0378@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.3047066,
            -74.680634
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.73,
                    "duration": "indefinitely",
                    "model_conf": 146.55
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.69,
                    "duration": "4 Months",
                    "model_conf": 178.72
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.66,
                    "duration": "indefinitely",
                    "model_conf": 104.49
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Princeton, NJ",
            "slug": "9e038477-9d05-4e4d-ab8c-c055448edb29"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/5/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/princeton/640-nassau-park-blvd/",
        "index": 98.425,
        "franchise-entity-name": "EMPIRE STUDIO 378 LLC"
    },
    "f535e8be-12ca-47ea-ba03-a1bfed3458c5": {
        "name": "Orange Theory Prosper, TX",
        "address": "790 Preston Road Suite #40 Prosper, Texas 75078",
        "phone": "(972) 474-0422",
        "email": "studiomanager0778@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 8:00PM",
            "TUESDAY": "4:30AM - 8:00PM",
            "WEDNESDAY": "4:30AM - 8:00PM",
            "THURSDAY": "4:30AM - 8:00PM",
            "FRIDAY": "4:30AM - 8:00PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            33.2289238,
            -96.792709
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.84,
                    "duration": "indefinitely",
                    "model_conf": 147.04
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.44,
                    "duration": "indefinitely",
                    "model_conf": 176.95
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.25,
                    "duration": "indefinitely",
                    "model_conf": 110.02
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Prosper, TX",
            "slug": "f535e8be-12ca-47ea-ba03-a1bfed3458c5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/26/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/prosper/790-preston-road-suite-40/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA FITNESS - PROSPER, LLC"
    },
    "d4ec7b54-32c5-45a1-9334-ad1b9f5df78f": {
        "name": "Orange Theory Providence, RI",
        "address": "563 N Main St Providence, Massachusetts 02904",
        "phone": "(401) 369-8006",
        "email": "studiomanager0990@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            41.8367844,
            -71.408432
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.4,
                    "duration": "2 Months",
                    "model_conf": 149.11
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.66,
                    "duration": "indefinitely",
                    "model_conf": 178.99
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.42,
                    "duration": "1 Months",
                    "model_conf": 104.92
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Providence, RI",
            "slug": "d4ec7b54-32c5-45a1-9334-ad1b9f5df78f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/rhode-island/providence/563-n-main-st/",
        "index": 98.425,
        "franchise-entity-name": "FITNESS CAPITAL VENTURES #3, LLC"
    },
    "7bf7ad25-7037-4e3c-85e3-fe9213b88c5b": {
        "name": "Orange Theory Quarry Village, TX",
        "address": "320 E. Basse Road. Suite 320-107 Quarry Village, Texas 78209",
        "phone": "(210) 640-9447",
        "email": "studiomanager1341@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            29.4960499,
            -98.478264
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.75,
                    "duration": "1 Months",
                    "model_conf": 131.92
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.5,
                    "duration": "indefinitely",
                    "model_conf": 162.93
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.74,
                    "duration": "indefinitely",
                    "model_conf": 106.6
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Quarry Village, TX",
            "slug": "7bf7ad25-7037-4e3c-85e3-fe9213b88c5b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/17/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/quarry-village/320-e-basse-road-suite-320-107/",
        "index": 98.425,
        "franchise-entity-name": "Avanti Fitness Quarry LLC"
    },
    "e67432f7-3233-4fe2-8ef5-49019de884dd": {
        "name": "Orange Theory Queen Creek, AZ",
        "address": "20715 E Rittenhouse Road, Suite 102 Queen Creek, Arizona 85142",
        "phone": "(480) 646-3395",
        "email": "studiomanager0878@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "6:30AM - 12:45PM",
            "SUNDAY": "6:30AM - 12:45PM"
        },
        "geolocation": [
            33.2565422,
            -111.63648
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 2.22,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.67,
                    "duration": "indefinitely",
                    "model_conf": 149.5
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 3.22,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.52,
                    "duration": "1 Months",
                    "model_conf": 180.78
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 4.72,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 107.5
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 6.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 9.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 12.75,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Queen Creek, AZ",
            "slug": "e67432f7-3233-4fe2-8ef5-49019de884dd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/queen-creek/20715-e-rittenhouse-road-suite-102/",
        "index": 103.375,
        "franchise-entity-name": "QUEEN CREEK FIT, LLC"
    },
    "07cc5fa0-50c6-4484-9f79-7e7e967695f6": {
        "name": "Orange Theory Quincy, MA",
        "address": "21 Chestnut Street Quincy, Massachusetts 02169",
        "phone": "(617) 221-4045",
        "email": "studiomanager0565@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "6:30AM - 12:30PM",
            "SUNDAY": "6:30AM - 12:30PM"
        },
        "geolocation": [
            42.249218,
            -71.001251
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.54,
                    "duration": "2 Months",
                    "model_conf": 149.73
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.4,
                    "duration": "indefinitely",
                    "model_conf": 183.6
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.03,
                    "duration": "indefinitely",
                    "model_conf": 110.94
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Quincy, MA",
            "slug": "07cc5fa0-50c6-4484-9f79-7e7e967695f6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/7/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/quincy/21-chestnut-street/",
        "index": 103.375,
        "franchise-entity-name": "DELUCA FIT, LLC"
    },
    "3c7beb0e-9475-4fc6-9e9f-2c03f507470c": {
        "name": "Orange Theory Renton Highlands, WA",
        "address": "3895 NE 4th Street Renton, Washington 98056",
        "phone": "(425) 892-8405",
        "email": "studiomanager0474@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:30AM - 3:00PM",
            "SUNDAY": "6:30AM - 3:00PM"
        },
        "geolocation": [
            47.487812,
            -122.16628
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.99,
                    "duration": "3 Months",
                    "model_conf": 152.22
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.04,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.69,
                    "duration": "indefinitely",
                    "model_conf": 184.55
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.86,
                    "duration": "indefinitely",
                    "model_conf": 110.36
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 45.45,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 60.5,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Renton Highlands, WA",
            "slug": "3c7beb0e-9475-4fc6-9e9f-2c03f507470c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/20/18",
        "url": "https://www.orangetheory.com/en-us/locations/washington/renton/3895-ne-4th-street/",
        "index": 108.325,
        "franchise-entity-name": "KAIZEN CULTURE II, LLC"
    },
    "b3b035f2-5a96-4b85-be1f-45a948e424e2": {
        "name": "Orange Theory Richland, WA",
        "address": "1034 Queensgate Dr. Richland, Washington 99352",
        "phone": "(509) 492-5949",
        "email": "studiomanager0660@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            46.2489014,
            -119.30251
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 6,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.65,
                    "duration": "indefinitely",
                    "model_conf": 151.96
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 9.48,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.01,
                    "duration": "3 Months",
                    "model_conf": 184.96
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 14.7,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.25,
                    "duration": "indefinitely",
                    "model_conf": 111.97
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 17.31,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 31.23,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 43.41,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Richland, WA",
            "slug": "b3b035f2-5a96-4b85-be1f-45a948e424e2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/25/17",
        "url": "https://www.orangetheory.com/en-us/locations/washington/richland/1034-queensgate-dr/",
        "index": 93.4,
        "franchise-entity-name": "WAFIT RICHLAND LLC"
    },
    "9c5926b0-06c6-4bfa-96f3-0f341793ac6e": {
        "name": "Orange Theory Rockwall, TX",
        "address": "1063 E Interstate 30 #103 Rockwall, Texas 75087",
        "phone": "(469) 651-1410",
        "email": "studiomanager0620@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 8:00PM",
            "TUESDAY": "4:30AM - 8:00PM",
            "WEDNESDAY": "4:30AM - 8:00PM",
            "THURSDAY": "4:30AM - 8:00PM",
            "FRIDAY": "4:30AM - 8:00PM",
            "SATURDAY": "7:15AM - 1:00PM",
            "SUNDAY": "7:15AM - 1:00PM"
        },
        "geolocation": [
            32.9061012,
            -96.451019
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.49,
                    "duration": "2 Months",
                    "model_conf": 153.36
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.11,
                    "duration": "indefinitely",
                    "model_conf": 184.4
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.55,
                    "duration": "1 Months",
                    "model_conf": 111.1
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Rockwall, TX",
            "slug": "9c5926b0-06c6-4bfa-96f3-0f341793ac6e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/23/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/rockwall/1063-e-interstate-30-103/",
        "index": 93.4,
        "franchise-entity-name": "VALENCIA LAKE RH, LLC"
    },
    "1760e9c2-cd38-4ccb-90ac-b4d4e244016b": {
        "name": "Orange Theory Brier Creek, NC",
        "address": "4233 Corners Pkwy Suite 270 Raleigh Nc, North Carolina 27617",
        "phone": "(919) 867-4471",
        "email": "studiomanager0767@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            35.9197197,
            -78.788757
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.06,
                    "duration": "indefinitely",
                    "model_conf": 145.63
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.12,
                    "duration": "indefinitely",
                    "model_conf": 170.25
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.94,
                    "duration": "indefinitely",
                    "model_conf": 106.58
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Brier Creek, NC",
            "slug": "1760e9c2-cd38-4ccb-90ac-b4d4e244016b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/raleigh-nc/4233-corners-pkwy-suite-270/",
        "index": 98.425,
        "franchise-entity-name": "JOELE FITNESS FOUR, LLC"
    },
    "0bee6838-5ec7-4091-952e-01c9cef96d64": {
        "name": "Orange Theory Raleigh-Midtown, NC",
        "address": "200 Park at North Hills Street, Suite 131, Raleigh, North Carolina 27609",
        "phone": "(984) 833-4472",
        "email": "studiomanager0766@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 2:00PM",
            "SUNDAY": "7:00AM - 2:00PM"
        },
        "geolocation": [
            35.8359261,
            -78.637215
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.06,
                    "duration": "indefinitely",
                    "model_conf": 133.69
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.51,
                    "duration": "1 Months",
                    "model_conf": 157.28
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.17,
                    "duration": "indefinitely",
                    "model_conf": 99.0
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Raleigh-Midtown, NC",
            "slug": "0bee6838-5ec7-4091-952e-01c9cef96d64"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/7/18",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/raleigh/200-park-at-north-hills-street-suite-131/",
        "index": 98.425,
        "franchise-entity-name": "JOELE FITNESS THREE, LLC"
    },
    "eafb9888-e144-479d-b6f3-e07343592e57": {
        "name": "Orange Theory North Raleigh, NC",
        "address": "6625 Falls of Neuse Road, Suite 107 Raleigh, North Carolina 27615",
        "phone": "(919) 263-5668",
        "email": "studiomanager0266@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            35.8732529,
            -78.625015
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.97,
                    "duration": "indefinitely",
                    "model_conf": 139.44
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.95,
                    "duration": "indefinitely",
                    "model_conf": 164.75
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.71,
                    "duration": "indefinitely",
                    "model_conf": 102.61
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "North Raleigh, NC",
            "slug": "eafb9888-e144-479d-b6f3-e07343592e57"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/25/15",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/raleigh/6625-falls-of-neuse-road-suite-107/",
        "index": 98.425,
        "franchise-entity-name": "JACKELL FITNESS TRIANGLE, LLC"
    },
    "9a0dbfe5-f793-488a-a5d9-f84271a07283": {
        "name": "Orange Theory Ramsey,NJ",
        "address": "15 Interstate Shopping Center Ramsey, New Jersey 07446",
        "phone": "(201) 749-4282",
        "email": "studiomanager1207@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            41.0667992,
            -74.136856
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.16,
                    "duration": "indefinitely",
                    "model_conf": 145.72
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.34,
                    "duration": "indefinitely",
                    "model_conf": 173.64
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.18,
                    "duration": "indefinitely",
                    "model_conf": 109.78
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Ramsey,NJ",
            "slug": "9a0dbfe5-f793-488a-a5d9-f84271a07283"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/19/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/ramsey/15-interstate-shopping-center/",
        "index": 103.375,
        "franchise-entity-name": "EPOC SIX LLC"
    },
    "54adfb26-fc0b-4294-b883-b80b3e4ea3c6": {
        "name": "Orange Theory Rancho Cucamonga, CA",
        "address": "12273 Highland Ave., Suite 132 Rancho Cucamonga, California 91739",
        "phone": "(909) 922-2988",
        "email": "studiomanager0421@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:45AM - 12:00PM",
            "SUNDAY": "6:45AM - 12:00PM"
        },
        "geolocation": [
            34.1344185,
            -117.53728
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.69,
                    "duration": "5 Months",
                    "model_conf": 151.92
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.95,
                    "duration": "indefinitely",
                    "model_conf": 184.04
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.42,
                    "duration": "1 Months",
                    "model_conf": 110.04
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Rancho Cucamonga, CA",
            "slug": "54adfb26-fc0b-4294-b883-b80b3e4ea3c6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/21/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/rancho-cucamonga/12273-highland-ave-suite-132/",
        "index": 103.375,
        "franchise-entity-name": "WAKEGA GROUP, LLC"
    },
    "a65dfc03-b893-4091-8221-7bc088966839": {
        "name": "Orange Theory Rancho Santa Margarita, CA",
        "address": "22401 Antonio Parkway., Suite D-200 Rancho Santa Margarita, California 92688",
        "phone": "(949) 424-8884",
        "email": "studiomanager0149@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            33.6384201,
            -117.59114
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.44,
                    "duration": "2 Months",
                    "model_conf": 148.41
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.29,
                    "duration": "indefinitely",
                    "model_conf": 183.99
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.59,
                    "duration": "indefinitely",
                    "model_conf": 111.55
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Rancho Santa Margarita, CA",
            "slug": "a65dfc03-b893-4091-8221-7bc088966839"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/28/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/rancho-santa-margarita/22401-antonio-parkway-suite-d-200/",
        "index": 103.375,
        "franchise-entity-name": "DIAL FITNESS, LLC"
    },
    "b7c7aa3c-6607-4ff3-8aec-eb649aa52a1c": {
        "name": "Orange Theory Mount Prospect, IL",
        "address": "1032 Center Drive Randhurst, Illinois 60056",
        "phone": "(847) 737-5584",
        "email": "studiomanager0303@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "5:30AM - 12:00PM",
            "SUNDAY": "5:30AM - 12:00PM"
        },
        "geolocation": [
            42.0846798,
            -87.933874
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.24,
                    "duration": "indefinitely",
                    "model_conf": 119.19
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.2,
                    "duration": "indefinitely",
                    "model_conf": 143.46
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.32,
                    "duration": "indefinitely",
                    "model_conf": 87.54
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Mount Prospect, IL",
            "slug": "b7c7aa3c-6607-4ff3-8aec-eb649aa52a1c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/17/14",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/mount-prospect/1032-center-drive/",
        "index": 103.375,
        "franchise-entity-name": "PERLOFF - PROVIDENCE STUDIO #1 LLC"
    },
    "49272a61-26df-4abf-9e5a-aa8b7549a4b2": {
        "name": "Orange Theory Reading, MA",
        "address": "265 Main Street North Reading, Massachusetts 01864",
        "phone": "(978) 276-4385",
        "email": "studiomanager0776@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            42.59235,
            -71.11644
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.97,
                    "duration": "4 Months",
                    "model_conf": 153.01
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.45,
                    "duration": "indefinitely",
                    "model_conf": 184.71
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.55,
                    "duration": "indefinitely",
                    "model_conf": 111.2
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Reading, MA",
            "slug": "49272a61-26df-4abf-9e5a-aa8b7549a4b2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/31/19",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/reading/265-main-street-north/",
        "index": 103.375,
        "franchise-entity-name": "HH FITNESS READING, LLC"
    },
    "7711f2c6-2700-4fcc-8a0b-e442a4e99ee1": {
        "name": "Orange Theory Redding, CA",
        "address": "1675 Hilltop Drive, Suite G Redding, California 96002",
        "phone": "(530) 782-5000",
        "email": "studiomanager0759@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:30PM",
            "TUESDAY": "4:45AM - 7:30PM",
            "WEDNESDAY": "4:45AM - 7:30PM",
            "THURSDAY": "4:45AM - 7:30PM",
            "FRIDAY": "4:45AM - 7:30PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            40.5823059,
            -122.35696
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.82,
                    "duration": "1 Months",
                    "model_conf": 152.48
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.92,
                    "duration": "indefinitely",
                    "model_conf": 185.51
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.82,
                    "duration": "indefinitely",
                    "model_conf": 107.94
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Redding, CA",
            "slug": "7711f2c6-2700-4fcc-8a0b-e442a4e99ee1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/14/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/redding/1675-hilltop-drive-suite-g/",
        "index": 88.45,
        "franchise-entity-name": "JSA FIT 1, LP"
    },
    "1f7ebc89-5e78-4eb4-9aec-748c161957ec": {
        "name": "Orange Theory Redlands, CA",
        "address": "450 W Stuart Ave Suite 110 Redlands, California 92374",
        "phone": "(909) 654-4087",
        "email": "studiomanager0984@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            34.0596428,
            -117.18725
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.5,
                    "duration": "2 Months",
                    "model_conf": 153.2
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.63,
                    "duration": "indefinitely",
                    "model_conf": 184.64
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.63,
                    "duration": "indefinitely",
                    "model_conf": 111.17
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Redlands, CA",
            "slug": "1f7ebc89-5e78-4eb4-9aec-748c161957ec"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/redlands/450-w-stuart-ave-suite-110/",
        "index": 103.375,
        "franchise-entity-name": "HIBI FITNESS MANAGEMENT I LLC"
    },
    "5d4e57d0-f219-4c4b-99d8-c44af57d266c": {
        "name": "Orange Theory Redmond, WA",
        "address": "16535 NE 76th St. Redmond, Washington 98052",
        "phone": "(425) 522-4838",
        "email": "studiomanager0407@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            47.6718369,
            -122.12003
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.35,
                    "duration": "indefinitely",
                    "model_conf": 139.53
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.04,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.57,
                    "duration": "1 Months",
                    "model_conf": 183.47
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.35,
                    "duration": "indefinitely",
                    "model_conf": 93.02
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 45.45,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 60.5,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Redmond, WA",
            "slug": "5d4e57d0-f219-4c4b-99d8-c44af57d266c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/1/15",
        "url": "https://www.orangetheory.com/en-us/locations/washington/redmond/16535-ne-76th-st/",
        "index": 108.325,
        "franchise-entity-name": "BHE of REDMOND, LLC"
    },
    "ecb27697-40a5-4f11-bb31-14f49a3ac0d4": {
        "name": "Orange Theory Bel-Red, WA",
        "address": "2690 152nd Ave NE, Suite 150 Redmond, Washington 98052",
        "phone": "(425) 689-5988",
        "email": "studiomanager0582@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "8:45AM - 12:15PM",
            "SUNDAY": "8:45AM - 12:15PM"
        },
        "geolocation": [
            47.6337242,
            -122.13695
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.85,
                    "duration": "indefinitely",
                    "model_conf": 126.24
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.04,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.15,
                    "duration": "indefinitely",
                    "model_conf": 182.99
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.03,
                    "duration": "indefinitely",
                    "model_conf": 72.83
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 45.45,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 60.5,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Bel-Red, WA",
            "slug": "ecb27697-40a5-4f11-bb31-14f49a3ac0d4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/6/18",
        "url": "https://www.orangetheory.com/en-us/locations/washington/redmond/2690-152nd-ave-ne-suite-150/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF BELLEVUE SUB, LLC"
    },
    "64497ee1-2fe3-47d1-b33f-29405e9cd78c": {
        "name": "Orange Theory Redondo Beach, CA",
        "address": "409 Pacific Coast Highway Suite 205 Redondo Beach, California 90277",
        "phone": "(424) 400-7433",
        "email": "studiomanager0735@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:30PM",
            "TUESDAY": "5:15AM - 7:30PM",
            "WEDNESDAY": "5:15AM - 7:30PM",
            "THURSDAY": "5:15AM - 7:30PM",
            "FRIDAY": "5:15AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            33.8480835,
            -118.38936
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.96,
                    "duration": "indefinitely",
                    "model_conf": 131.73
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.18,
                    "duration": "2 Months",
                    "model_conf": 153.2
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.35,
                    "duration": "indefinitely",
                    "model_conf": 93.98
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Redondo Beach, CA",
            "slug": "64497ee1-2fe3-47d1-b33f-29405e9cd78c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/15/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/redondo-beach/409-north-pacific-coast-highway-suite-205/",
        "index": 111.325,
        "franchise-entity-name": "REDONDO FITNESS, LLC"
    },
    "a3fb1f2a-63a9-459f-b553-c0ca4dafb0f8": {
        "name": "Orange Theory Redwood City - Woodside Plaza, CA",
        "address": "322 Woodside Plaza Redwood City Ii, California 94061",
        "phone": "(650) 817-7050",
        "email": "studiomanager0975@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            37.4560356,
            -122.22911
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.45,
                    "duration": "2 Months",
                    "model_conf": 153.29
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.89,
                    "duration": "indefinitely",
                    "model_conf": 183.37
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.84,
                    "duration": "indefinitely",
                    "model_conf": 110.29
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Redwood City - Woodside Plaza, CA",
            "slug": "a3fb1f2a-63a9-459f-b553-c0ca4dafb0f8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/30/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/redwood-city-ii/322-woodside-plaza/",
        "index": 111.325,
        "franchise-entity-name": "ALL OUT FITNESS, LLC"
    },
    "a3caebf8-cbcb-4dc8-bb06-42f1ed73646e": {
        "name": "Orange Theory Redwood City-Redwood Shores, CA",
        "address": "236 Redwood Shores Parkway Redwood City, California 94065",
        "phone": "(650) 562-6577",
        "email": "studiomanager0395@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:30PM",
            "TUESDAY": "6:15AM - 7:30PM",
            "WEDNESDAY": "6:15AM - 7:30PM",
            "THURSDAY": "6:15AM - 7:30PM",
            "FRIDAY": "6:15AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            37.522049,
            -122.25144
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.01,
                    "duration": "3 Months",
                    "model_conf": 153.31
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.39,
                    "duration": "indefinitely",
                    "model_conf": 182.39
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 207.6,
                    "duration": "2 Months",
                    "model_conf": 109.18
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Redwood City-Redwood Shores, CA",
            "slug": "a3caebf8-cbcb-4dc8-bb06-42f1ed73646e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/redwood-city/236-redwood-shores-parkway/",
        "index": 111.325,
        "franchise-entity-name": "G&amp;K FITNESS, LLC"
    },
    "684ee29c-8053-465c-89ce-cbc80eb132cf": {
        "name": "Orange Theory Northwest Reno, NV",
        "address": "1575 Robb Drive Reno, Nevada 89523",
        "phone": "(775) 800-5515",
        "email": "studiomanager0490@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:45AM - 12:30PM",
            "SUNDAY": "6:45AM - 12:30PM"
        },
        "geolocation": [
            39.5262375,
            -119.88249
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.58,
                    "duration": "indefinitely",
                    "model_conf": 153.2
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.3,
                    "duration": "2 Months",
                    "model_conf": 183.97
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.33,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Northwest Reno, NV",
            "slug": "684ee29c-8053-465c-89ce-cbc80eb132cf"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/4/17",
        "url": "https://www.orangetheory.com/en-us/locations/nevada/reno/1575-robb-drive-suite-1/",
        "index": 98.425,
        "franchise-entity-name": "HH NW RENO LLC"
    },
    "17c49090-2e00-4169-a23f-706848ae0b46": {
        "name": "Orange Theory South Reno, NV",
        "address": "8056 South Virginia Street Reno, Nevada 89511",
        "phone": "(775) 800-2308",
        "email": "studiomanager0491@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            39.4539909,
            -119.77757
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.54,
                    "duration": "indefinitely",
                    "model_conf": 152.29
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.34,
                    "duration": "5 Months",
                    "model_conf": 181.37
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.02,
                    "duration": "indefinitely",
                    "model_conf": 111.96
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "South Reno, NV",
            "slug": "17c49090-2e00-4169-a23f-706848ae0b46"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/11/16",
        "url": "https://www.orangetheory.com/en-us/locations/nevada/reno/8056-south-virginia-street-suite-1/",
        "index": 98.425,
        "franchise-entity-name": "HH SOUTH RENO LLC"
    },
    "89149b58-f73b-4454-a652-d260461ea458": {
        "name": "Orange Theory Reston, VA",
        "address": "11410 Reston Station Blvd Reston, Virginia 20190",
        "phone": "(703) 822-5272",
        "email": "studiomanager0790@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            38.9498863,
            -77.339668
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.86,
                    "duration": "indefinitely",
                    "model_conf": 129.69
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.25,
                    "duration": "indefinitely",
                    "model_conf": 154.49
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.84,
                    "duration": "indefinitely",
                    "model_conf": 95.0
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Reston, VA",
            "slug": "89149b58-f73b-4454-a652-d260461ea458"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/reston/11410-reston-station-blvd/",
        "index": 98.425,
        "franchise-entity-name": "RESTON HEARTRATE FITNESS, LLC"
    },
    "2113fd6c-7dc5-4ee1-8977-b057b5e0b246": {
        "name": "Orange Theory Richfield, MN",
        "address": "1635 E 66th Street Richfield, Minnesota 55423",
        "phone": "(612) 503-4422",
        "email": "studiomanager0960@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:15AM - 1:30PM",
            "SUNDAY": "6:15AM - 1:30PM"
        },
        "geolocation": [
            44.8834457,
            -93.250725
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.44,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.73,
                    "duration": "1 Months",
                    "model_conf": 135.69
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.45,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.24,
                    "duration": "2 Months",
                    "model_conf": 172.03
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.96,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.31,
                    "duration": "indefinitely",
                    "model_conf": 102.64
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Richfield, MN",
            "slug": "2113fd6c-7dc5-4ee1-8977-b057b5e0b246"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/12/18",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/richfield/1635-e-66th-street/",
        "index": 88.45,
        "franchise-entity-name": "RICHFIELD FITNESS LLC"
    },
    "1af9e871-ff9b-4bc8-b580-e3ec825aae46": {
        "name": "Orange Theory Aliana, TX",
        "address": "10169 W. Grand Parkway S Suite 200 Richmond, Texas 77407",
        "phone": "(832) 239-8997",
        "email": "studiomanager1315@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            29.6604249,
            -95.711856
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.62,
                    "duration": "1 Months",
                    "model_conf": 146.36
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.72,
                    "duration": "indefinitely",
                    "model_conf": 180.49
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.74,
                    "duration": "indefinitely",
                    "model_conf": 103.28
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Aliana, TX",
            "slug": "1af9e871-ff9b-4bc8-b580-e3ec825aae46"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/15/20",
        "url": "https://www.orangetheory.com/en-us/locations/texas/richmond/10169-w-grand-parkway-s-suite-200/",
        "index": 98.425,
        "franchise-entity-name": "ALIANA COACHING LLC"
    },
    "f169121c-8d2c-4da5-a24b-205942b4eed9": {
        "name": "Orange Theory Ridgefield, CT",
        "address": "1 Ethan Allen Highway Ridgefield, Connecticut 06877",
        "phone": "(203) 617-1701",
        "email": "studiomanager0810@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "6:45AM - 11:50AM",
            "SUNDAY": "6:45AM - 11:50AM"
        },
        "geolocation": [
            41.264183,
            -73.44072
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.17,
                    "duration": "4 Months",
                    "model_conf": 151.52
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.06,
                    "duration": "indefinitely",
                    "model_conf": 182.94
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.05,
                    "duration": "2 Months",
                    "model_conf": 110.41
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Ridgefield, CT",
            "slug": "f169121c-8d2c-4da5-a24b-205942b4eed9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/30/19",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/ridgefield/1-ethan-allen-highway/",
        "index": 98.425,
        "franchise-entity-name": "TL FITNESS 3, LLC"
    },
    "98e21239-c33c-4359-bb86-2ef65ff192e6": {
        "name": "Orange Theory Herriman, UT",
        "address": "4534 W Patridgehill Ln Riverton, Utah 84096",
        "phone": "(801) 988-5544",
        "email": "studiomanager1356@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:35PM",
            "TUESDAY": "5:00AM - 6:35PM",
            "WEDNESDAY": "5:00AM - 6:35PM",
            "THURSDAY": "5:00AM - 6:35PM",
            "FRIDAY": "5:00AM - 6:35PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            40.509121,
            -111.99896
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.41,
                    "duration": "indefinitely",
                    "model_conf": 148.63
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.95,
                    "duration": "indefinitely",
                    "model_conf": 182.89
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.27,
                    "duration": "indefinitely",
                    "model_conf": 108.67
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Herriman, UT",
            "slug": "98e21239-c33c-4359-bb86-2ef65ff192e6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/1/21",
        "url": "https://www.orangetheory.com/en-us/locations/utah/riverton/4534-w-patridgehill-ln-suite-e-150/",
        "index": 93.4,
        "franchise-entity-name": "Heartzone Fitness XI, LLC"
    },
    "63f7264e-baaa-4cc9-80f9-f2b74fb2984f": {
        "name": "Orange Theory Riverview, FL",
        "address": "10173 Big Bend Rd., Suite 5 Riverview, Florida 33578",
        "phone": "(813) 607-5646",
        "email": "studiomanager0958@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            27.7919426,
            -82.33522
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.82,
                    "duration": "indefinitely",
                    "model_conf": 153.48
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.95,
                    "duration": "3 Months",
                    "model_conf": 183.65
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.55,
                    "duration": "indefinitely",
                    "model_conf": 111.16
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Riverview, FL",
            "slug": "63f7264e-baaa-4cc9-80f9-f2b74fb2984f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/riverview/10173-big-bend-rd-suite-5/",
        "index": 98.425,
        "franchise-entity-name": "RIVERVIEW COACHING, LLC"
    },
    "9bfdd2cd-dd21-4111-88ae-01e83c5391c9": {
        "name": "Orange Theory Buffalo Grove, IL",
        "address": "2037 Milwaukee Ave. Riverwoods, Illinois 60015",
        "phone": "(224) 521-0006",
        "email": "studiomanager0653@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            42.1701927,
            -87.921829
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.22,
                    "duration": "indefinitely",
                    "model_conf": 131.72
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.87,
                    "duration": "indefinitely",
                    "model_conf": 158.95
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.03,
                    "duration": "indefinitely",
                    "model_conf": 96.44
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Buffalo Grove, IL",
            "slug": "9bfdd2cd-dd21-4111-88ae-01e83c5391c9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/9/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/riverwoods/2037-milwaukee-ave/",
        "index": 103.375,
        "franchise-entity-name": "C &amp; Z FIT III, LLC"
    },
    "26e857aa-d5e2-45bf-a8d4-fb717eb8a833": {
        "name": "Orange Theory Robbinsville, NJ",
        "address": "691 US-130 E Robbinsville, New Jersey 08691",
        "phone": "(609) 380-3308",
        "email": "studiomanager0969@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:15AM - 11:45AM",
            "SUNDAY": "6:15AM - 11:45AM"
        },
        "geolocation": [
            40.1921349,
            -74.647476
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.13,
                    "duration": "indefinitely",
                    "model_conf": 152.6
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.88,
                    "duration": "indefinitely",
                    "model_conf": 184.43
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.0,
                    "duration": "indefinitely",
                    "model_conf": 110.89
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Robbinsville, NJ",
            "slug": "26e857aa-d5e2-45bf-a8d4-fb717eb8a833"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/robbinsville/691-us-130-e/",
        "index": 98.425,
        "franchise-entity-name": "TONOCITY, LLC"
    },
    "dbe7b0e7-f44c-4f62-9656-7079f50081f3": {
        "name": "Orange Theory Rochester Hills, MI",
        "address": "1470 North Rochester Road Rochester, Michigan 48307",
        "phone": "(248) 237-6720",
        "email": "studiomanager0422@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 9:15PM",
            "TUESDAY": "4:55AM - 9:15PM",
            "WEDNESDAY": "4:55AM - 9:15PM",
            "THURSDAY": "4:55AM - 9:15PM",
            "FRIDAY": "4:55AM - 9:15PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            42.6923828,
            -83.134224
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.22,
                    "duration": "3 Months",
                    "model_conf": 151.22
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.77,
                    "duration": "indefinitely",
                    "model_conf": 181.83
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.49,
                    "duration": "1 Months",
                    "model_conf": 110.07
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Rochester Hills, MI",
            "slug": "dbe7b0e7-f44c-4f62-9656-7079f50081f3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/18/15",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/rochester/1470-north-rochester-road/",
        "index": 98.425,
        "franchise-entity-name": "SKT, LLC"
    },
    "b8e6e362-d485-4635-8e69-9f82ef5276e9": {
        "name": "Orange Theory Rochester, MN",
        "address": "1008 Apache Mall (1201 12th Street) Rochester, Minnesota 55902",
        "phone": "(507) 722-2683",
        "email": "studiomanager1141@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            44.004509,
            -92.479507
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.79,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.18,
                    "duration": "indefinitely",
                    "model_conf": 153.43
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 8.04,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.03,
                    "duration": "indefinitely",
                    "model_conf": 184.03
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 12.91,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 160.36,
                    "duration": "indefinitely",
                    "model_conf": 111.92
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Rochester, MN",
            "slug": "b8e6e362-d485-4635-8e69-9f82ef5276e9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/3/18",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/rochester/1008-apache-mall-1201-12th-street/",
        "index": 88.45,
        "franchise-entity-name": "PEACH PERFECT LLC"
    },
    "1d7ccace-f3a9-4921-bc8e-2fcc302b49a7": {
        "name": "Orange Theory Rock Hill, MO",
        "address": "9434 Manchester Rd Rock Hill, Missouri 63119",
        "phone": "(314) 736-1170",
        "email": "studiomanager0191@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 4:00PM",
            "SUNDAY": "7:15AM - 4:00PM"
        },
        "geolocation": [
            38.6095963,
            -90.363037
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.64,
                    "duration": "indefinitely",
                    "model_conf": 111.6
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.21,
                    "duration": "2 Months",
                    "model_conf": 147.24
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.11,
                    "duration": "indefinitely",
                    "model_conf": 81.94
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Rock Hill, MO",
            "slug": "1d7ccace-f3a9-4921-bc8e-2fcc302b49a7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/rock-hill/9434-manchester-rd/",
        "index": 93.4,
        "franchise-entity-name": "INVICTUS HOLDINGS, LLC"
    },
    "542edc63-b25d-44c2-8811-816171ceccb4": {
        "name": "Orange Theory Rock Hill, SC",
        "address": "2674 Celanese Road Rock Hill, South Carolina 29732",
        "phone": "(803) 667-3388",
        "email": "studiomanager0774@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 12:00PM",
            "SUNDAY": "8:00AM - 12:00PM"
        },
        "geolocation": [
            34.9765816,
            -81.024399
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.74,
                    "duration": "1 Months",
                    "model_conf": 151.6
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.26,
                    "duration": "indefinitely",
                    "model_conf": 182.43
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.6,
                    "duration": "indefinitely",
                    "model_conf": 109.8
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Rock Hill, SC",
            "slug": "542edc63-b25d-44c2-8811-816171ceccb4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/31/17",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/rock-hill/2674-celanese-rd-suite-107/",
        "index": 98.425,
        "franchise-entity-name": "STUDIO SEVEN CLT FITNESS, INC."
    },
    "97223781-92e4-4c8c-b15e-aec71a402a1f": {
        "name": "Orange Theory Rockaway Beach, NY",
        "address": "133 Beach 116th Street Rockaway Park, New York 11694",
        "phone": "(347) 719-4040",
        "email": "studiomanager1331@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.578454,
            -73.836767
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.83,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.45,
                    "duration": "indefinitely",
                    "model_conf": 152.51
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.78,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.02,
                    "duration": "2 Months",
                    "model_conf": 184.22
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 9.7,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.85,
                    "duration": "indefinitely",
                    "model_conf": 111.09
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 12.19,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 21.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 29.2,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Rockaway Beach, NY",
            "slug": "97223781-92e4-4c8c-b15e-aec71a402a1f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/5/22",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/rockaway-park/133-beach-116th-st/",
        "index": 108.325,
        "franchise-entity-name": "G.E.C. Fitness LLC"
    },
    "8274e607-d066-44cc-b474-a7833f6fabca": {
        "name": "Orange Theory Rockaway Township, NJ",
        "address": "395 Mt. Hope Avenue Rockaway Township, New Jersey 07866",
        "phone": "(973) 315-5155",
        "email": "studiomanager1268@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            40.9070282,
            -74.548065
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.19,
                    "duration": "indefinitely",
                    "model_conf": 151.98
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.29,
                    "duration": "indefinitely",
                    "model_conf": 179.86
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.67,
                    "duration": "indefinitely",
                    "model_conf": 110.04
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Rockaway Township, NJ",
            "slug": "8274e607-d066-44cc-b474-a7833f6fabca"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/12/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/rockaway-township/395-mt-hope-avenue/",
        "index": 98.425,
        "franchise-entity-name": "HUDSON FITNESS NJ 4, LLC"
    },
    "144486d9-c9f4-4702-86c7-01ec932e2a4e": {
        "name": "Orange Theory Rockford, IL",
        "address": "6575 E State St, Rockford, Illinois 61108",
        "phone": "(815) 414-9900",
        "email": "studiomanager0971@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:00PM",
            "SUNDAY": "7:30AM - 11:00PM"
        },
        "geolocation": [
            42.2674789,
            -88.988297
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.38,
                    "duration": "indefinitely",
                    "model_conf": 153.09
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.37,
                    "duration": "indefinitely",
                    "model_conf": 182.93
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.25,
                    "duration": "indefinitely",
                    "model_conf": 111.9
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Rockford, IL",
            "slug": "144486d9-c9f4-4702-86c7-01ec932e2a4e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/15/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/rockford/6575-e-state-st/",
        "index": 93.4,
        "franchise-entity-name": "ROCKTOWN FIT 1, INC."
    },
    "937dbd8c-c70a-451e-b3df-77ecc752643b": {
        "name": "Orange Theory Rockville Centre, NY",
        "address": "313 Merrick Road Rockville Centre, New York 11571",
        "phone": "(516) 586-6555",
        "email": "studiomanager0058@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.6560783,
            -73.643776
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 95.86,
                    "duration": "12 Months",
                    "model_conf": 139.78
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 142.77,
                    "duration": "indefinitely",
                    "model_conf": 167.67
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 197.39,
                    "duration": "3 Months",
                    "model_conf": 102.69
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Rockville Centre, NY",
            "slug": "937dbd8c-c70a-451e-b3df-77ecc752643b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/1/14",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/rockville-centre/315-merrick-road/",
        "index": 108.325,
        "franchise-entity-name": "OTFLI RVC, LLC"
    },
    "1b123a17-659a-4c85-9d2b-e009f1627c13": {
        "name": "Orange Theory Rockville South, MD",
        "address": "1601 Rockville Pike #210A Rockville, Maryland 20852",
        "phone": "(240) 380-3311",
        "email": "studiomanager1195@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            39.0634918,
            -77.126663
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.37,
                    "duration": "6 Months",
                    "model_conf": 141.94
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 141.53,
                    "duration": "indefinitely",
                    "model_conf": 164.51
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 197.83,
                    "duration": "2 Months",
                    "model_conf": 96.59
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Rockville South, MD",
            "slug": "1b123a17-659a-4c85-9d2b-e009f1627c13"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/22/21",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/rockville/1601-rockville-pike-210a/",
        "index": 108.325,
        "franchise-entity-name": "MARYLAND FITNESS PARTNERS LLC - 1195"
    },
    "1667e0a7-e8f6-4d64-b1ad-f3fd6c92028f": {
        "name": "Orange Theory Rocky Point, NY",
        "address": "217 Rte 25 A Rocky Point, New York 11778",
        "phone": "(631) 615-1975",
        "email": "studiomanager0923@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 6:15PM",
            "TUESDAY": "5:15AM - 6:15PM",
            "WEDNESDAY": "5:15AM - 6:15PM",
            "THURSDAY": "5:15AM - 6:15PM",
            "FRIDAY": "5:15AM - 6:15PM",
            "SATURDAY": "6:45AM - 11:30AM",
            "SUNDAY": "6:45AM - 11:30AM"
        },
        "geolocation": [
            40.944238,
            -72.968888
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.78,
                    "duration": "indefinitely",
                    "model_conf": 151.44
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.27,
                    "duration": "indefinitely",
                    "model_conf": 182.34
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.08,
                    "duration": "indefinitely",
                    "model_conf": 111.69
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Rocky Point, NY",
            "slug": "1667e0a7-e8f6-4d64-b1ad-f3fd6c92028f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/25/22",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/rocky-point/217-rte-25-a/",
        "index": 108.325,
        "franchise-entity-name": "OTF ROCKY POINT LLC"
    },
    "b4fed84a-2c94-456b-abeb-d5b45c71d0cc": {
        "name": "Orange Theory Rocky River, OH",
        "address": "19800 Center Ridge Rd Rocky River, Ohio 44116",
        "phone": "(216) 370-3277",
        "email": "studiomanager0463@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 8:00PM",
            "TUESDAY": "5:30AM - 8:00PM",
            "WEDNESDAY": "5:30AM - 8:00PM",
            "THURSDAY": "5:30AM - 8:00PM",
            "FRIDAY": "5:30AM - 8:00PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            41.4651718,
            -81.842117
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.63,
                    "duration": "2 Months",
                    "model_conf": 152.29
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.46,
                    "duration": "indefinitely",
                    "model_conf": 185.55
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.17,
                    "duration": "indefinitely",
                    "model_conf": 111.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Rocky River, OH",
            "slug": "b4fed84a-2c94-456b-abeb-d5b45c71d0cc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/4/15",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/rocky-river/19800-center-ridge-rd/",
        "index": 93.4,
        "franchise-entity-name": "KSA1 LLC"
    },
    "92110c99-c404-4408-bb68-607b95e592b1": {
        "name": "Orange Theory Rogers, AR",
        "address": "5100 W. Pauline Pkwy, Suite #103 Rogers, Arkansas 72758",
        "phone": "(888) 723-7090",
        "email": "studiomanager0826@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            36.2973137,
            -94.18573
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.83,
                    "duration": "indefinitely",
                    "model_conf": 150.32
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.19,
                    "duration": "2 Months",
                    "model_conf": 184.23
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.44,
                    "duration": "indefinitely",
                    "model_conf": 107.94
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Rogers, AR",
            "slug": "92110c99-c404-4408-bb68-607b95e592b1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/21/16",
        "url": "https://www.orangetheory.com/en-us/locations/arkansas/rogers/5201-w-pauline-whitaker-pkwy/",
        "index": 93.4,
        "franchise-entity-name": "AR HIIT, LLC"
    },
    "2f5f7a8f-3eb2-4b87-832c-db73a6991b09": {
        "name": "Orange Theory Rohnert Park, CA",
        "address": "600 Raley&#39;s Towne Centre Rohnert Park, California 94928",
        "phone": "(707) 343-8004",
        "email": "studiomanager0792@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 10:30PM",
            "SUNDAY": "7:00AM - 10:30PM"
        },
        "geolocation": [
            38.3459129,
            -122.70512
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.34,
                    "duration": "indefinitely",
                    "model_conf": 153.0
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.91,
                    "duration": "indefinitely",
                    "model_conf": 184.93
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.53,
                    "duration": "indefinitely",
                    "model_conf": 111.58
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Rohnert Park, CA",
            "slug": "2f5f7a8f-3eb2-4b87-832c-db73a6991b09"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/16/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/rohnert-park/600-raley-s-towne-centre/",
        "index": 108.325,
        "franchise-entity-name": "JK VENTURES I, LLC"
    },
    "9a24c277-2ced-45b0-9209-8e4b02a7c3d0": {
        "name": "Orange Theory Rome, GA",
        "address": "1431 Turner McCall Boulevard Rome, Georgia 30161",
        "phone": "(770) 727-2943",
        "email": "studiomanager1523@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 5:30PM",
            "TUESDAY": "5:00AM - 5:30PM",
            "WEDNESDAY": "5:00AM - 5:30PM",
            "THURSDAY": "5:00AM - 5:30PM",
            "FRIDAY": "5:00AM - 5:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.219523,
            -85.174787
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.95,
                    "duration": "indefinitely",
                    "model_conf": 153.72
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.02,
                    "duration": "indefinitely",
                    "model_conf": 184.59
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.98,
                    "duration": "indefinitely",
                    "model_conf": 112.02
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Rome, GA",
            "slug": "9a24c277-2ced-45b0-9209-8e4b02a7c3d0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/16/21",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/rome/1431-turner-mccall-blvd/",
        "index": 98.425,
        "franchise-entity-name": "JM ROME FITNESS, LLC"
    },
    "0140a655-b523-46e8-aed2-34bcaea1cb0f": {
        "name": "Orange Theory Roselle-Bloomingdale, IL",
        "address": "39 Main Street Roselle, Illinois 60172",
        "phone": "(630) 283-8888",
        "email": "studiomanager0560@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 8:30PM",
            "TUESDAY": "4:45AM - 8:30PM",
            "WEDNESDAY": "4:45AM - 8:30PM",
            "THURSDAY": "4:45AM - 8:30PM",
            "FRIDAY": "4:45AM - 8:30PM",
            "SATURDAY": "7:45AM - 11:15AM",
            "SUNDAY": "7:45AM - 11:15AM"
        },
        "geolocation": [
            41.9836273,
            -88.078659
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.49,
                    "duration": "6 Months",
                    "model_conf": 147.91
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.45,
                    "duration": "indefinitely",
                    "model_conf": 178.29
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.16,
                    "duration": "indefinitely",
                    "model_conf": 107.25
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Roselle-Bloomingdale, IL",
            "slug": "0140a655-b523-46e8-aed2-34bcaea1cb0f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/roselle/39-main-street/",
        "index": 103.375,
        "franchise-entity-name": "WINCITY FITNESS GROUP, LLC"
    },
    "a314826e-6180-44b6-9bce-16124a567309": {
        "name": "Orange Theory Roseville, CA",
        "address": "2030 Douglas Boulevard Roseville, California 95661",
        "phone": "(916) 771-4683",
        "email": "studiomanager0150@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 10:30PM",
            "SUNDAY": "7:00AM - 10:30PM"
        },
        "geolocation": [
            38.7433395,
            -121.25043
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.7,
                    "duration": "1 Months",
                    "model_conf": 152.47
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.27,
                    "duration": "indefinitely",
                    "model_conf": 184.53
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.78,
                    "duration": "indefinitely",
                    "model_conf": 110.85
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Roseville, CA",
            "slug": "a314826e-6180-44b6-9bce-16124a567309"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/4/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/roseville/2030-douglas-boulevard-suite-39/",
        "index": 103.375,
        "franchise-entity-name": "CAL FIT STUDIO, LLC"
    },
    "13d3bb5e-c653-4f6f-aa17-197bb3fe1bb0": {
        "name": "Orange Theory Rocklin, CA",
        "address": "711 Pleasant Grove Blvd Roseville, California 95678",
        "phone": "(916) 245-9005",
        "email": "studiomanager0465@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45PM",
            "SUNDAY": "7:00AM - 11:45PM"
        },
        "geolocation": [
            38.7910843,
            -121.2782
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.05,
                    "duration": "indefinitely",
                    "model_conf": 152.55
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.8,
                    "duration": "indefinitely",
                    "model_conf": 184.37
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.03,
                    "duration": "indefinitely",
                    "model_conf": 111.1
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Rocklin, CA",
            "slug": "13d3bb5e-c653-4f6f-aa17-197bb3fe1bb0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/roseville/711-pleasant-grove-blvd-suite-100-and-120/",
        "index": 103.375,
        "franchise-entity-name": "BASSEL ROCK LLC"
    },
    "73eef9d4-042c-4a0e-977b-05d469be9cc5": {
        "name": "Orange Theory Roseville, MN",
        "address": "2195 Snelling Ave N Roseville, Minnesota 55113",
        "phone": "(651) 237-4444",
        "email": "studiomanager0973@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 12:15PM",
            "SUNDAY": "6:30AM - 12:15PM"
        },
        "geolocation": [
            45.0076103,
            -93.167831
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.35,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.45,
                    "duration": "indefinitely",
                    "model_conf": 134.58
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.3,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.87,
                    "duration": "indefinitely",
                    "model_conf": 166.16
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.73,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.17,
                    "duration": "indefinitely",
                    "model_conf": 96.65
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Roseville, MN",
            "slug": "73eef9d4-042c-4a0e-977b-05d469be9cc5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/19/18",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/roseville/2195-snelling-ave-n/",
        "index": 88.45,
        "franchise-entity-name": "KBM FITFAM L.L.C."
    },
    "9b846974-90fb-488a-97c3-b551aa96199c": {
        "name": "Orange Theory Roswell, GA",
        "address": "625 W Crossville Road, Suite #108 Roswell, Georgia 30075",
        "phone": "(770) 625-6122",
        "email": "studiomanager0193@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            34.060257,
            -84.381348
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.0,
                    "duration": "4 Months",
                    "model_conf": 135.46
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.05,
                    "duration": "indefinitely",
                    "model_conf": 177.43
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.21,
                    "duration": "1 Months",
                    "model_conf": 99.23
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Roswell, GA",
            "slug": "9b846974-90fb-488a-97c3-b551aa96199c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/18/14",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/roswell/625-w-crossville-road-suite-108/",
        "index": 98.425,
        "franchise-entity-name": "VIVENCIA FIT ONE LLC"
    },
    "2aee062b-ad33-44f6-a8a3-ec98408a8df2": {
        "name": "Orange Theory Round Rock - University, TX",
        "address": "311 University Blvd., Suite 100 Round Rock, Texas 78665",
        "phone": "(512) 954-9333",
        "email": "studiomanager0105@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            30.5597134,
            -97.683853
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.07,
                    "duration": "4 Months",
                    "model_conf": 144.51
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.85,
                    "duration": "indefinitely",
                    "model_conf": 171.76
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.22,
                    "duration": "1 Months",
                    "model_conf": 108.86
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Round Rock - University, TX",
            "slug": "2aee062b-ad33-44f6-a8a3-ec98408a8df2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/8/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/round-rock/311-university-blvd-suite-100/",
        "index": 103.375,
        "franchise-entity-name": "COWAN FITNESS NORTH ROUND ROCK LLC"
    },
    "b4a467c9-1afb-4017-aa5e-df936fb19650": {
        "name": "Orange Theory Round Rock, TX",
        "address": "661 Louis Henna Blvd, Suite 450 Round Rock, Texas 78664",
        "phone": "(512) 969-6625",
        "email": "studiomanager0308@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            30.482008,
            -97.656891
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.52,
                    "duration": "2 Months",
                    "model_conf": 133.82
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.54,
                    "duration": "indefinitely",
                    "model_conf": 152.01
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.64,
                    "duration": "indefinitely",
                    "model_conf": 105.0
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Round Rock, TX",
            "slug": "b4a467c9-1afb-4017-aa5e-df936fb19650"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/23/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/round-rock/661-louis-henna-blvd-suite-450/",
        "index": 103.375,
        "franchise-entity-name": "COWAN FITNESS SOUTH ROUND ROCK LLC"
    },
    "26d4e014-27fa-47d9-be54-40f3c82c2633": {
        "name": "Orange Theory Royal Oak Woodward, MI",
        "address": "30283 Woodward Ave Royal Oak, Michigan 48073",
        "phone": "(248) 257-5877",
        "email": "studiomanager1381@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "8:30AM - 12:00PM",
            "SUNDAY": "8:30AM - 12:00PM"
        },
        "geolocation": [
            42.511679,
            -83.181326
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.78,
                    "duration": "1 Months",
                    "model_conf": 136.75
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.41,
                    "duration": "indefinitely",
                    "model_conf": 168.41
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.84,
                    "duration": "indefinitely",
                    "model_conf": 104.3
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Royal Oak Woodward, MI",
            "slug": "26d4e014-27fa-47d9-be54-40f3c82c2633"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/21/22",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/royal-oak/30283-woodward-ave/",
        "index": 98.425,
        "franchise-entity-name": "L5 Fitness Michigan, LLC - 1381"
    },
    "3fde1e61-7e98-4ba0-894a-d461f17a74ae": {
        "name": "Orange Theory Royal Oak, MI",
        "address": "905B S. Main Street Royal Oak, Michigan 48067",
        "phone": "(248) 733-3344",
        "email": "studiomanager1019@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 9:05PM",
            "TUESDAY": "4:55AM - 9:05PM",
            "WEDNESDAY": "4:55AM - 9:05PM",
            "THURSDAY": "4:55AM - 9:05PM",
            "FRIDAY": "4:55AM - 9:05PM",
            "SATURDAY": "7:00AM - 2:45PM",
            "SUNDAY": "7:00AM - 2:45PM"
        },
        "geolocation": [
            42.4826355,
            -83.143539
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.02,
                    "duration": "indefinitely",
                    "model_conf": 146.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.23,
                    "duration": "indefinitely",
                    "model_conf": 178.64
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.99,
                    "duration": "indefinitely",
                    "model_conf": 108.76
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Royal Oak, MI",
            "slug": "3fde1e61-7e98-4ba0-894a-d461f17a74ae"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/13/18",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/royal-oak/905b-s-main-street/",
        "index": 98.425,
        "franchise-entity-name": "L5 Fitness Michigan, LLC - 1019"
    },
    "bdfc76e0-5eb9-4ad2-8523-56f3c25bc067": {
        "name": "Orange Theory Royal Palm Beach, FL",
        "address": "11021 Southern Blvd. S-130 Royal Palm Beach, Florida 33411",
        "phone": "(561) 753-8111",
        "email": "studiomanager0002@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            26.682581,
            -80.222878
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.76,
                    "duration": "1 Months",
                    "model_conf": 151.39
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.39,
                    "duration": "indefinitely",
                    "model_conf": 174.2
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.97,
                    "duration": "indefinitely",
                    "model_conf": 110.35
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Royal Palm Beach, FL",
            "slug": "bdfc76e0-5eb9-4ad2-8523-56f3c25bc067"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/10/11",
        "url": "https://www.orangetheory.com/en-us/locations/florida/royal-palm-beach/11021-southern-blvd-s-130/",
        "index": 98.425,
        "franchise-entity-name": "SAE FITNESS LLC"
    },
    "0d6f25a9-db2b-461d-a09d-e467abb69933": {
        "name": "Orange Theory Collegeville, PA",
        "address": "1836 E Ridge Pike #104 Royersford, Pennsylvania 19468",
        "phone": "(484) 273-2733",
        "email": "studiomanager0972@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 11:45AM",
            "SUNDAY": "7:15AM - 11:45AM"
        },
        "geolocation": [
            40.2129707,
            -75.498093
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.87,
                    "duration": "indefinitely",
                    "model_conf": 147.09
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 126.69,
                    "duration": "6 Months",
                    "model_conf": 179.84
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.8,
                    "duration": "indefinitely",
                    "model_conf": 107.29
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Collegeville, PA",
            "slug": "0d6f25a9-db2b-461d-a09d-e467abb69933"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/royersford/1836-e-ridge-pike-104/",
        "index": 103.375,
        "franchise-entity-name": "CENIVIVA FITNESS II LLC"
    },
    "4ba6aece-6a42-4dd0-9fb4-0d8e73300492": {
        "name": "Orange Theory Sayville, NY",
        "address": "5640 Sunrise Highway, Sayville, New York 11782",
        "phone": "(631) 865-1515",
        "email": "studiomanager0649@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            40.7658806,
            -73.074554
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.8,
                    "duration": "indefinitely",
                    "model_conf": 151.83
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 137.88,
                    "duration": "3 Months",
                    "model_conf": 183.14
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.01,
                    "duration": "indefinitely",
                    "model_conf": 110.2
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Sayville, NY",
            "slug": "4ba6aece-6a42-4dd0-9fb4-0d8e73300492"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/sayville/5640-sunrise-highway/",
        "index": 108.325,
        "franchise-entity-name": "BN FITNESS 2 LLC"
    },
    "03ab9809-b9fa-4cac-9760-60435ba0942a": {
        "name": "Orange Theory Forest Acres, SC",
        "address": "4708 Forest Drive Se Columbia, South Carolina 29206",
        "phone": "(803) 630-5200",
        "email": "studiomanager0882@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            34.0190964,
            -80.969841
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.76,
                    "duration": "indefinitely",
                    "model_conf": 147.71
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.39,
                    "duration": "5 Months",
                    "model_conf": 185.28
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.33,
                    "duration": "indefinitely",
                    "model_conf": 111.65
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Forest Acres, SC",
            "slug": "03ab9809-b9fa-4cac-9760-60435ba0942a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/19/17",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/se-columbia/4708-forest-drive/",
        "index": 93.4,
        "franchise-entity-name": "JM FOREST ACRES, LLC"
    },
    "8ace80e7-bf34-408d-bea0-20c07e2999bc": {
        "name": "Orange Theory Staten Island - Woodrow, NY",
        "address": "1275 Woodrow Rd Staten Island, New York 10309",
        "phone": "(347) 577-9877",
        "email": "studiomanager0483@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 9:00PM",
            "TUESDAY": "4:45AM - 9:00PM",
            "WEDNESDAY": "4:45AM - 9:00PM",
            "THURSDAY": "4:45AM - 9:00PM",
            "FRIDAY": "4:45AM - 9:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.5420494,
            -74.20726
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.46,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.4,
                    "duration": "indefinitely",
                    "model_conf": 151.33
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.26,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.95,
                    "duration": "indefinitely",
                    "model_conf": 182.48
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.69,
                    "duration": "indefinitely",
                    "model_conf": 110.01
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 11.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 20.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 26.96,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Staten Island - Woodrow, NY",
            "slug": "8ace80e7-bf34-408d-bea0-20c07e2999bc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/2/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/staten-island/1275-woodrow-rd/",
        "index": 108.325,
        "franchise-entity-name": "PFM SI-2, LLC"
    },
    "6e35b68d-f023-4946-a249-dab2dcb6ecdd": {
        "name": "Orange Theory Swampscott, MA",
        "address": "450 Paradise Road Swampscott, Massachusetts 01907",
        "phone": "(781) 356-6588",
        "email": "studiomanager0633@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            42.4832764,
            -70.904465
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.19,
                    "duration": "indefinitely",
                    "model_conf": 152.8
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.55,
                    "duration": "4 Months",
                    "model_conf": 184.63
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.1,
                    "duration": "indefinitely",
                    "model_conf": 111.8
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Swampscott, MA",
            "slug": "6e35b68d-f023-4946-a249-dab2dcb6ecdd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/swampscott/450-paradise-road/",
        "index": 103.375,
        "franchise-entity-name": "IRISH CROSSINGS II LLC"
    },
    "ab84f861-9463-4319-928f-a3faf107a67b": {
        "name": "Orange Theory Sacramento Arden-Arcade, CA",
        "address": "2095 Fair Oaks Blvd Sacramento Arden-Arcade, California 95825",
        "phone": "(916) 245-2203",
        "email": "studiomanager1001@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "7:45AM - 12:30PM",
            "SUNDAY": "7:45AM - 12:30PM"
        },
        "geolocation": [
            38.5743904,
            -121.41637
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.38,
                    "duration": "indefinitely",
                    "model_conf": 151.95
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.6,
                    "duration": "1 Months",
                    "model_conf": 183.74
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.19,
                    "duration": "indefinitely",
                    "model_conf": 110.32
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Sacramento Arden-Arcade, CA",
            "slug": "ab84f861-9463-4319-928f-a3faf107a67b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/9/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/sacramento-arden-arcade/2095-fair-oaks-blvd-suite-110/",
        "index": 103.375,
        "franchise-entity-name": "CALI STYLE FITNESS LLC"
    },
    "970f6838-4b87-4b9f-a6ae-9489f4e7b5c0": {
        "name": "Orange Theory Sacramento Mid-Town, CA",
        "address": "2901 K St. Suite 190 Sacramento, California 95816",
        "phone": "(916) 836-8383",
        "email": "studiomanager0280@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            38.572731,
            -121.46794
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.0,
                    "duration": "indefinitely",
                    "model_conf": 151.35
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.77,
                    "duration": "3 Months",
                    "model_conf": 183.56
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.7,
                    "duration": "indefinitely",
                    "model_conf": 110.34
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Sacramento Mid-Town, CA",
            "slug": "970f6838-4b87-4b9f-a6ae-9489f4e7b5c0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/21/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/sacramento/2901-k-st-suite-190/",
        "index": 103.375,
        "franchise-entity-name": "LECLAIR FITNESS IV, LLC"
    },
    "5aec8cac-d5a2-462e-bb62-4dbd7edfd719": {
        "name": "Orange Theory Sacramento-Natomas, CA",
        "address": "3270 Arena Blvd Sacramento, California 95834",
        "phone": "(916) 283-4683",
        "email": "studiomanager0235@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            38.6418152,
            -121.53456
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.56,
                    "duration": "indefinitely",
                    "model_conf": 152.64
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 126.89,
                    "duration": "6 Months",
                    "model_conf": 184.67
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 111.47
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Sacramento-Natomas, CA",
            "slug": "5aec8cac-d5a2-462e-bb62-4dbd7edfd719"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/30/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/sacramento/3270-arena-blvd-suite-510/",
        "index": 103.375,
        "franchise-entity-name": "CAL FIT STUDIO 1, LLC"
    },
    "a107a9f4-2914-4835-a138-03192025b2d8": {
        "name": "Orange Theory Land Park, CA",
        "address": "4710 Freeport Blvd Sacramento, California 95822",
        "phone": "(916) 800-5532",
        "email": "studiomanager1177@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:15AM - 12:45PM",
            "SUNDAY": "8:15AM - 12:45PM"
        },
        "geolocation": [
            38.5324707,
            -121.49525
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.68,
                    "duration": "1 Months",
                    "model_conf": 151.54
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.29,
                    "duration": "indefinitely",
                    "model_conf": 183.87
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.69,
                    "duration": "indefinitely",
                    "model_conf": 110.9
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Land Park, CA",
            "slug": "a107a9f4-2914-4835-a138-03192025b2d8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/30/21",
        "url": "https://www.orangetheory.com/en-us/locations/california/sacramento/4710-freeport-blvd-12k-unit-120/",
        "index": 103.375,
        "franchise-entity-name": "CALI STYLE FITNESS LLC"
    },
    "f9ab52ae-7fa5-41ea-a426-e2d7220320af": {
        "name": "Orange Theory Salem, NH",
        "address": "236 North Broadway, Unit A Salem, New Hampshire 03079",
        "phone": "(603) 898-3300",
        "email": "studiomanager0647@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            42.7917519,
            -71.234604
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.84,
                    "duration": "5 Months",
                    "model_conf": 153.36
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.43,
                    "duration": "indefinitely",
                    "model_conf": 185.28
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.16,
                    "duration": "1 Months",
                    "model_conf": 111.67
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Salem, NH",
            "slug": "f9ab52ae-7fa5-41ea-a426-e2d7220320af"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/24/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-hampshire/salem/236-north-broadway-unit-a/",
        "index": 98.425,
        "franchise-entity-name": "VANDAVAL , INC."
    },
    "1c48a405-5540-4b0b-8983-e7f029b3abed": {
        "name": "Orange Theory Salem, OR",
        "address": "4405 Commercial Street SE Suite 100 Salem, Oregon 97302",
        "phone": "(971) 599-7005",
        "email": "studiomanager0761@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            44.8900719,
            -123.0351
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.59,
                    "duration": "indefinitely",
                    "model_conf": 153.86
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.27,
                    "duration": "2 Months",
                    "model_conf": 185.76
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.42,
                    "duration": "indefinitely",
                    "model_conf": 112.12
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Salem, OR",
            "slug": "1c48a405-5540-4b0b-8983-e7f029b3abed"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/7/17",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/salem/4405-commercial-street-se-suite-100/",
        "index": 98.425,
        "franchise-entity-name": "HH FITNESS SALEM, LLC"
    },
    "e1b87f37-6428-4729-8741-586734b16cf3": {
        "name": "Orange Theory Sugar House, UT",
        "address": "1202 Wilmington Avenue #100 Salt Lake City, Utah 84106",
        "phone": "(385) 236-1122",
        "email": "studiomanager0204@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.723038,
            -111.8573
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.17,
                    "duration": "indefinitely",
                    "model_conf": 137.06
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.81,
                    "duration": "indefinitely",
                    "model_conf": 176.92
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.69,
                    "duration": "indefinitely",
                    "model_conf": 100.29
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Sugar House, UT",
            "slug": "e1b87f37-6428-4729-8741-586734b16cf3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/5/17",
        "url": "https://www.orangetheory.com/en-us/locations/utah/salt-lake-city/1202-e-wilmington-avenue-suite-100/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS UTAH, LLC"
    },
    "52ad909d-ac63-4bc5-8077-4f4fc920c537": {
        "name": "Orange Theory Salt Lake City, UT",
        "address": "602 East 500 S Salt Lake City, Utah 84102",
        "phone": "(801) 255-9050",
        "email": "studiomanager0228@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.7580986,
            -111.87315
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.83,
                    "duration": "indefinitely",
                    "model_conf": 143.28
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.17,
                    "duration": "2 Months",
                    "model_conf": 179.73
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.65,
                    "duration": "indefinitely",
                    "model_conf": 104.62
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Salt Lake City, UT",
            "slug": "52ad909d-ac63-4bc5-8077-4f4fc920c537"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/27/15",
        "url": "https://www.orangetheory.com/en-us/locations/utah/salt-lake-city/602-east-500-s/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS UTAH, LLC"
    },
    "822942ab-d4ab-4c23-803d-88582444aed0": {
        "name": "Orange Theory Sammamish, WA",
        "address": "22830 NE 8th Street, Suite 103 Sammamish, Washington 98074",
        "phone": "(425) 659-3414",
        "email": "studiomanager0447@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:30PM",
            "TUESDAY": "6:00AM - 7:30PM",
            "WEDNESDAY": "6:00AM - 7:30PM",
            "THURSDAY": "6:00AM - 7:30PM",
            "FRIDAY": "6:00AM - 7:30PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            47.6171913,
            -122.0345
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 100.05,
                    "duration": "indefinitely",
                    "model_conf": 148.33
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.04,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 137.65,
                    "duration": "3 Months",
                    "model_conf": 184.17
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.65,
                    "duration": "indefinitely",
                    "model_conf": 105.02
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 45.45,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 60.5,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Sammamish, WA",
            "slug": "822942ab-d4ab-4c23-803d-88582444aed0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/11/18",
        "url": "https://www.orangetheory.com/en-us/locations/washington/sammamish/22830-ne-8th-street-suite-103/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF SAMMAMISH, LLC"
    },
    "7242bd8c-778e-43a6-89b1-ebfdeef65621": {
        "name": "Orange Theory San Angelo",
        "address": "5582 Sherwood Way #600 San Angelo, Texas 76901",
        "phone": "(325) 703-2016",
        "email": "studiomanager1267@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            31.4293289,
            -100.51295
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.25,
                    "duration": "indefinitely",
                    "model_conf": 153.86
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.34,
                    "duration": "indefinitely",
                    "model_conf": 185.59
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.26,
                    "duration": "indefinitely",
                    "model_conf": 112.1
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "San Angelo",
            "slug": "7242bd8c-778e-43a6-89b1-ebfdeef65621"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/25/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-angelo/5582-sherwood-way-600/",
        "index": 88.45,
        "franchise-entity-name": "GTPAR, LLC"
    },
    "ff8fc44c-208a-48c9-97f3-b118cac1a7b2": {
        "name": "Orange Theory Potranco, TX",
        "address": "10538 Potranco Rd. San Antonio, Texas 78245",
        "phone": "(210) 802-4599",
        "email": "studiomanager1320@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            29.4356117,
            -98.700363
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.81,
                    "duration": "indefinitely",
                    "model_conf": 147.36
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.32,
                    "duration": "indefinitely",
                    "model_conf": 176.31
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.81,
                    "duration": "indefinitely",
                    "model_conf": 109.77
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Potranco, TX",
            "slug": "ff8fc44c-208a-48c9-97f3-b118cac1a7b2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/12/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-antonio/10538-potranco-rd/",
        "index": 103.375,
        "franchise-entity-name": "POTRANCO WORKOUT PARTNERS LLC"
    },
    "eca5a258-92cf-483b-b1f8-1f0b0fb350c4": {
        "name": "Orange Theory Bandera, TX",
        "address": "11600 Bandera Road San Antonio, Texas 78250",
        "phone": "(210) 899-0099",
        "email": "studiomanager0853@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            29.5522766,
            -98.663216
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.13,
                    "duration": "3 Months",
                    "model_conf": 137.08
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.15,
                    "duration": "indefinitely",
                    "model_conf": 160.29
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.2,
                    "duration": "1 Months",
                    "model_conf": 105.81
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Bandera, TX",
            "slug": "eca5a258-92cf-483b-b1f8-1f0b0fb350c4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-antonio/11600-bandera-road/",
        "index": 103.375,
        "franchise-entity-name": "BANDERA FITNESS PARTNERS LLC"
    },
    "173e0181-b891-452c-9cf6-feb3c60c8a4e": {
        "name": "Orange Theory Huebner, TX",
        "address": "12055 Vance Jackson Rd. San Antonio, Texas 78230",
        "phone": "(210) 899-0044",
        "email": "studiomanager0751@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            29.5527515,
            -98.572525
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.78,
                    "duration": "1 Months",
                    "model_conf": 128.17
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.2,
                    "duration": "indefinitely",
                    "model_conf": 152.81
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.92,
                    "duration": "indefinitely",
                    "model_conf": 102.8
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Huebner, TX",
            "slug": "173e0181-b891-452c-9cf6-feb3c60c8a4e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/2/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-antonio/12055-vance-jackson-rd/",
        "index": 98.425,
        "franchise-entity-name": "WALLINGMONT FITNESS LLC"
    },
    "66ead6a2-3953-4577-9377-3769b97ffead": {
        "name": "Orange Theory Bitters",
        "address": "13433 San Pedro Ave San Antonio, Texas 78216",
        "phone": "(210) 504-4596",
        "email": "studiomanager1245@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            29.5647278,
            -98.482895
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.92,
                    "duration": "indefinitely",
                    "model_conf": 137.63
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.9,
                    "duration": "3 Months",
                    "model_conf": 162.14
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.84,
                    "duration": "indefinitely",
                    "model_conf": 106.44
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Bitters",
            "slug": "66ead6a2-3953-4577-9377-3769b97ffead"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/12/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-antonio/13429-san-pedro-ave/",
        "index": 103.375,
        "franchise-entity-name": "BITTERS FITNESS PARTNERS, LLC"
    },
    "adeebfbf-f99d-405f-ae10-69a8d7274086": {
        "name": "Orange Theory La Cantera",
        "address": "15900 La Cantera Parkway, Suite 26160 San Antonio, Texas 78256",
        "phone": "(210) 876-3800",
        "email": "studiomanager0852@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 1:15PM",
            "SUNDAY": "8:00AM - 1:15PM"
        },
        "geolocation": [
            29.5935402,
            -98.614502
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.64,
                    "duration": "indefinitely",
                    "model_conf": 133.16
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.34,
                    "duration": "1 Months",
                    "model_conf": 160.81
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.47,
                    "duration": "indefinitely",
                    "model_conf": 104.68
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "La Cantera",
            "slug": "adeebfbf-f99d-405f-ae10-69a8d7274086"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/15/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-antonio/15900-la-cantera-parkway-suite-6698/",
        "index": 103.375,
        "franchise-entity-name": "LA CONTERRA FITNESS PARTNERS, LLC"
    },
    "74452554-0722-40a4-8662-30d173e80ba0": {
        "name": "Orange Theory Bulverde",
        "address": "18018 OVERLOOK LOOP San Antonio, Texas 78259",
        "phone": "(210) 660-3653",
        "email": "studiomanager1415@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:30AM - 1:45PM",
            "SUNDAY": "7:30AM - 1:45PM"
        },
        "geolocation": [
            29.603055,
            -98.421854
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.19,
                    "duration": "indefinitely",
                    "model_conf": 142.19
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.2,
                    "duration": "indefinitely",
                    "model_conf": 168.19
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.06,
                    "duration": "indefinitely",
                    "model_conf": 106.71
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Bulverde",
            "slug": "74452554-0722-40a4-8662-30d173e80ba0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/16/21",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-antonio/18018-overlook-loop-suite-101/",
        "index": 103.375,
        "franchise-entity-name": "Bulverde Fitness Partners LLC"
    },
    "87a6c43a-3359-4dd3-9f71-5593c8586cdc": {
        "name": "Orange Theory Stone Ridge, TX",
        "address": "20811 US HWY 281 San Antonio, Texas 78258",
        "phone": "(210) 899-0098",
        "email": "studiomanager0851@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            29.6406899,
            -98.455772
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.73,
                    "duration": "1 Months",
                    "model_conf": 139.02
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.65,
                    "duration": "indefinitely",
                    "model_conf": 164.4
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.81,
                    "duration": "indefinitely",
                    "model_conf": 105.4
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Stone Ridge, TX",
            "slug": "87a6c43a-3359-4dd3-9f71-5593c8586cdc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/29/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-antonio/20811-us-hwy-281/",
        "index": 103.375,
        "franchise-entity-name": "STONE RIDGE FITNESS PARTNERS LLC"
    },
    "a3b02202-81a2-415e-b883-d5e22d1faf2e": {
        "name": "Orange Theory Sonterra Village, TX",
        "address": "427 N. Loop 1604 W., Suite 106 San Antonio, Texas 78232",
        "phone": "(210) 802-4348",
        "email": "studiomanager0256@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            29.6093159,
            -98.499023
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.35,
                    "duration": "indefinitely",
                    "model_conf": 135.63
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.99,
                    "duration": "indefinitely",
                    "model_conf": 160.75
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.25,
                    "duration": "indefinitely",
                    "model_conf": 105.78
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sonterra Village, TX",
            "slug": "a3b02202-81a2-415e-b883-d5e22d1faf2e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/8/15",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-antonio/427-n-loop-1604-w-suite-106/",
        "index": 98.425,
        "franchise-entity-name": "AVANTI FITNESS SONTERRA LLC"
    },
    "104f5a96-ef73-44c7-9314-81b740f4ff5c": {
        "name": "Orange Theory Alamo Ranch, TX",
        "address": "5619 NW Loop 1604, Suite 122 San Antonio, Texas 78253",
        "phone": "(210) 899-0100",
        "email": "studiomanager0676@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            29.4935513,
            -98.70739
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.52,
                    "duration": "indefinitely",
                    "model_conf": 138.1
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.91,
                    "duration": "3 Months",
                    "model_conf": 161.13
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.25,
                    "duration": "indefinitely",
                    "model_conf": 106.1
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Alamo Ranch, TX",
            "slug": "104f5a96-ef73-44c7-9314-81b740f4ff5c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/6/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-antonio/5619-nw-loop-1604-suite-122/",
        "index": 93.4,
        "franchise-entity-name": "XDJ, LLC"
    },
    "87fc0172-1ac1-400b-8c02-dff4259e3b61": {
        "name": "Orange Theory Alamo Heights",
        "address": "6482 N New Braunfels San Antonio, Texas 78209",
        "phone": "(210) 960-4440",
        "email": "studiomanager1142@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            29.4898338,
            -98.458755
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.14,
                    "duration": "indefinitely",
                    "model_conf": 127.99
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.54,
                    "duration": "1 Months",
                    "model_conf": 161.13
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.25,
                    "duration": "indefinitely",
                    "model_conf": 106.21
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Alamo Heights",
            "slug": "87fc0172-1ac1-400b-8c02-dff4259e3b61"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/29/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-antonio/6482-n-new-braunfels/",
        "index": 98.425,
        "franchise-entity-name": "Avanti Fitness Alamo Heights LLC"
    },
    "d79d998b-bd1c-42dc-8b2e-e7e1e42f5b56": {
        "name": "Orange Theory San Clemente, CA",
        "address": "802 Avenida Pico San Clemente, California 92672",
        "phone": "(949) 485-4885",
        "email": "studiomanager0153@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            33.4425087,
            -117.61412
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.55,
                    "duration": "indefinitely",
                    "model_conf": 150.47
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.15,
                    "duration": "2 Months",
                    "model_conf": 184.62
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.4,
                    "duration": "indefinitely",
                    "model_conf": 111.55
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Clemente, CA",
            "slug": "d79d998b-bd1c-42dc-8b2e-e7e1e42f5b56"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/5/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-clemente/802-avenida-pico/",
        "index": 103.375,
        "franchise-entity-name": "CALISEA LLC"
    },
    "254e22ce-6c1b-4c07-b8e1-7d48ded754d7": {
        "name": "Orange Theory Carmel Valley, CA",
        "address": "11155 E Ocean Air Drive San Diego, California 92130",
        "phone": "(858) 207-3131",
        "email": "studiomanager0094@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            32.9205513,
            -117.21567
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.65,
                    "duration": "indefinitely",
                    "model_conf": 153.62
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.11,
                    "duration": "2 Months",
                    "model_conf": 170.41
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.54,
                    "duration": "indefinitely",
                    "model_conf": 100.63
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Carmel Valley, CA",
            "slug": "254e22ce-6c1b-4c07-b8e1-7d48ded754d7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/26/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-diego/11155-e-ocean-air-drive/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT2 LLC"
    },
    "c8684d21-1674-45f5-907a-7c760f48df8d": {
        "name": "Orange Theory San Diego-East Village, CA",
        "address": "1130 F ST San Diego, California 92101",
        "phone": "(619) 678-0071",
        "email": "studiomanager1459@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            32.71371,
            -117.15464
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.92,
                    "duration": "4 Months",
                    "model_conf": 152.64
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.92,
                    "duration": "indefinitely",
                    "model_conf": 181.28
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.21,
                    "duration": "1 Months",
                    "model_conf": 104.99
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Diego-East Village, CA",
            "slug": "c8684d21-1674-45f5-907a-7c760f48df8d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/17/23",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-diego/1130-f-st/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT9 LLC"
    },
    "5dd6ca7a-226d-44b9-8120-c53727fabed2": {
        "name": "Orange Theory San Diego - Del Sur, CA",
        "address": "16445 Paseo Del Sur, Suite E-105 San Diego, California 92127",
        "phone": "(858) 257-1777",
        "email": "studiomanager0526@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:10PM",
            "TUESDAY": "5:00AM - 8:10PM",
            "WEDNESDAY": "5:00AM - 8:10PM",
            "THURSDAY": "5:00AM - 8:10PM",
            "FRIDAY": "5:00AM - 8:10PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            33.0202599,
            -117.12403
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.55,
                    "duration": "indefinitely",
                    "model_conf": 152.0
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.3,
                    "duration": "2 Months",
                    "model_conf": 181.87
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.57,
                    "duration": "indefinitely",
                    "model_conf": 109.79
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Diego - Del Sur, CA",
            "slug": "5dd6ca7a-226d-44b9-8120-c53727fabed2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/6/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-diego/16445-paseo-del-sur-suite-e-105/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT 6 LLC"
    },
    "17bc2043-4a9f-4178-811e-87fd7d8f4733": {
        "name": "Orange Theory San Diego-Pacific Beach, CA",
        "address": "1762 Garnet Ave San Diego, California 92109",
        "phone": "(619) 573-9607",
        "email": "studiomanager1460@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            32.801812,
            -117.23787
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.94,
                    "duration": "4 Months",
                    "model_conf": 152.47
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.14,
                    "duration": "indefinitely",
                    "model_conf": 173.83
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.22,
                    "duration": "1 Months",
                    "model_conf": 101.81
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Diego-Pacific Beach, CA",
            "slug": "17bc2043-4a9f-4178-811e-87fd7d8f4733"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/19/23",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-diego/1762-garnet-ave/",
        "index": 103.375,
        "franchise-entity-name": "SDFIT PCB LLC"
    },
    "2b3644b4-fde2-4d6b-90ff-7db2d3fda62f": {
        "name": "Orange Theory San Diego - Bankers Hill, CA",
        "address": "2665 5th Avenue Suite 101 San Diego, California 92103",
        "phone": "(619) 363-4040",
        "email": "studiomanager0116@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:50PM",
            "TUESDAY": "5:00AM - 7:50PM",
            "WEDNESDAY": "5:00AM - 7:50PM",
            "THURSDAY": "5:00AM - 7:50PM",
            "FRIDAY": "5:00AM - 7:50PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            32.733038,
            -117.15999
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.8,
                    "duration": "5 Months",
                    "model_conf": 152.28
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.96,
                    "duration": "indefinitely",
                    "model_conf": 179.32
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.14,
                    "duration": "1 Months",
                    "model_conf": 102.83
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Diego - Bankers Hill, CA",
            "slug": "2b3644b4-fde2-4d6b-90ff-7db2d3fda62f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-diego/2665-5th-avenue-suite-101/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT 3, LLC"
    },
    "b94f2fb3-9fb8-4a68-bcb7-ec344c35d88f": {
        "name": "Orange Theory San Diego - Point Loma, CA",
        "address": "3980 W Point Loma Blvd. San Diego, California 92110",
        "phone": "(858) 216-2020",
        "email": "studiomanager0093@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:30AM - 2:45PM",
            "SUNDAY": "6:30AM - 2:45PM"
        },
        "geolocation": [
            32.7543297,
            -117.22323
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.04,
                    "duration": "4 Months",
                    "model_conf": 151.44
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.94,
                    "duration": "indefinitely",
                    "model_conf": 176.4
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.33,
                    "duration": "1 Months",
                    "model_conf": 101.0
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Diego - Point Loma, CA",
            "slug": "b94f2fb3-9fb8-4a68-bcb7-ec344c35d88f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/3/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-diego/3980-w-point-loma-blvd/",
        "index": 103.375,
        "franchise-entity-name": "OTF-SD LLC"
    },
    "0fba2c3d-9d6f-4028-a05f-6b65a933dd85": {
        "name": "Orange Theory Mission Valley, CA",
        "address": "7510 Hazard Center Drive, Suite 401 San Diego, California 92108",
        "phone": "(858) 248-8444",
        "email": "studiomanager0114@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:40PM",
            "SUNDAY": "7:00AM - 12:40PM"
        },
        "geolocation": [
            32.7707939,
            -117.15901
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.62,
                    "duration": "1 Months",
                    "model_conf": 152.13
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.51,
                    "duration": "indefinitely",
                    "model_conf": 175.74
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.55,
                    "duration": "indefinitely",
                    "model_conf": 100.19
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Mission Valley, CA",
            "slug": "0fba2c3d-9d6f-4028-a05f-6b65a933dd85"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/26/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-diego/7510-hazard-center-drive-suite-401/",
        "index": 103.375,
        "franchise-entity-name": "SDFIT UTC LLC"
    },
    "785da160-7e25-481f-91c6-1e93a8371e7c": {
        "name": "Orange Theory La Jolla, CA",
        "address": "7734 Girard Avenue #C San Diego, California 92037",
        "phone": "(858) 551-8750",
        "email": "studiomanager0112@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 6:30PM",
            "TUESDAY": "6:00AM - 6:30PM",
            "WEDNESDAY": "6:00AM - 6:30PM",
            "THURSDAY": "6:00AM - 6:30PM",
            "FRIDAY": "6:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            32.8442421,
            -117.2741
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.59,
                    "duration": "1 Months",
                    "model_conf": 152.93
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.35,
                    "duration": "indefinitely",
                    "model_conf": 173.0
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.9,
                    "duration": "indefinitely",
                    "model_conf": 102.36
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "La Jolla, CA",
            "slug": "785da160-7e25-481f-91c6-1e93a8371e7c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/14/13",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-diego/7734-girard-avenue-c/",
        "index": 103.375,
        "franchise-entity-name": "SDFIT LJA LLC"
    },
    "2048f249-8fdb-41b3-9385-8f7f4c97841f": {
        "name": "Orange Theory San Diego - UTC",
        "address": "8960 University Center Lane San Diego, California 92122",
        "phone": "(858) 262-4531",
        "email": "studiomanager0113@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:40PM",
            "TUESDAY": "5:00AM - 7:40PM",
            "WEDNESDAY": "5:00AM - 7:40PM",
            "THURSDAY": "5:00AM - 7:40PM",
            "FRIDAY": "5:00AM - 7:40PM",
            "SATURDAY": "7:20AM - 11:50AM",
            "SUNDAY": "7:20AM - 11:50AM"
        },
        "geolocation": [
            32.8707352,
            -117.2241
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.68,
                    "duration": "1 Months",
                    "model_conf": 153.52
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.42,
                    "duration": "indefinitely",
                    "model_conf": 160.67
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.99,
                    "duration": "indefinitely",
                    "model_conf": 93.66
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Diego - UTC",
            "slug": "2048f249-8fdb-41b3-9385-8f7f4c97841f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/27/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-diego/8960-university-center-lane/",
        "index": 103.375,
        "franchise-entity-name": "SDFIT LJA LLC"
    },
    "6388046c-43c5-4d7c-b494-3b35b8d63cbb": {
        "name": "Orange Theory San Francisco-North Beach",
        "address": "1255 Columbus Ave, Suite B San Francisco, California 94133",
        "phone": "(415) 604-0211",
        "email": "studiomanager1152@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 2:00PM",
            "SUNDAY": "7:00AM - 2:00PM"
        },
        "geolocation": [
            37.8056,
            -122.41824
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.32,
                    "duration": "indefinitely",
                    "model_conf": 153.37
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.15,
                    "duration": "1 Months",
                    "model_conf": 181.22
                }
            },
            "Premier": {
                "cost": 269,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 268.83,
                    "duration": "indefinitely",
                    "model_conf": 108.64
                }
            }
        },
        "services": {
            "10 Session Pack - $290": {
                "cost": 290,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $550": {
                "cost": 550,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $790": {
                "cost": 790,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 32,
            "late-cancel-2": 16,
            "10-pack": 290,
            "20-pack": 550,
            "premier-family-addon-6-month-corp-2": 259,
            "surcharge-2": 10,
            "30-pack": 790,
            "premier": 269,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 189,
            "elite": 199,
            "additional-session-2": 24,
            "name": "San Francisco-North Beach",
            "slug": "6388046c-43c5-4d7c-b494-3b35b8d63cbb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/8/22",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-francisco/1255-columbus-avenue/",
        "index": 131.875,
        "franchise-entity-name": "SFFIT NOR LLC"
    },
    "89d3ca47-da8c-4a4a-b79d-71eefee10bf5": {
        "name": "Orange Theory San Francisco - Mid Market",
        "address": "1411 Market St San Francisco, California 94103",
        "phone": "(415) 741-0099",
        "email": "studiomanager0456@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 2:00PM",
            "SUNDAY": "6:00AM - 2:00PM"
        },
        "geolocation": [
            37.7758179,
            -122.41724
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.19,
                    "duration": "indefinitely",
                    "model_conf": 153.28
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.28,
                    "duration": "1 Months",
                    "model_conf": 180.51
                }
            },
            "Premier": {
                "cost": 269,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 268.97,
                    "duration": "indefinitely",
                    "model_conf": 108.12
                }
            }
        },
        "services": {
            "10 Session Pack - $290": {
                "cost": 290,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $550": {
                "cost": 550,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $790": {
                "cost": 790,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 32,
            "late-cancel-2": 16,
            "10-pack": 290,
            "20-pack": 550,
            "premier-family-addon-6-month-corp-2": 259,
            "surcharge-2": 10,
            "30-pack": 790,
            "premier": 269,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 189,
            "elite": 199,
            "additional-session-2": 24,
            "name": "San Francisco - Mid Market",
            "slug": "89d3ca47-da8c-4a4a-b79d-71eefee10bf5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/18/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-francisco/1411-market-st/",
        "index": 131.875,
        "franchise-entity-name": "SFFIT MS LLC"
    },
    "3dc58384-e45c-4efd-a7b6-6c3e5032bc9e": {
        "name": "Orange Theory San Francisco-Pacific Heights, CA",
        "address": "1633 Fillmore Street San Francisco, California 94115",
        "phone": "(415) 800-1852",
        "email": "studiomanager1150@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:10AM - 7:30PM",
            "TUESDAY": "6:10AM - 7:30PM",
            "WEDNESDAY": "6:10AM - 7:30PM",
            "THURSDAY": "6:10AM - 7:30PM",
            "FRIDAY": "6:10AM - 7:30PM",
            "SATURDAY": "8:30AM - 12:50PM",
            "SUNDAY": "8:30AM - 12:50PM"
        },
        "geolocation": [
            37.7846451,
            -122.43339
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.5,
                    "duration": "indefinitely",
                    "model_conf": 153.34
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.36,
                    "duration": "1 Months",
                    "model_conf": 181.14
                }
            },
            "Premier": {
                "cost": 269,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 269.43,
                    "duration": "indefinitely",
                    "model_conf": 108.58
                }
            }
        },
        "services": {
            "10 Session Pack - $290": {
                "cost": 290,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $550": {
                "cost": 550,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $790": {
                "cost": 790,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 32,
            "late-cancel-2": 16,
            "10-pack": 290,
            "20-pack": 550,
            "premier-family-addon-6-month-corp-2": 259,
            "surcharge-2": 10,
            "30-pack": 790,
            "premier": 269,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 189,
            "elite": 199,
            "additional-session-2": 24,
            "name": "San Francisco-Pacific Heights, CA",
            "slug": "3dc58384-e45c-4efd-a7b6-6c3e5032bc9e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/16/23",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-francisco/1633-fillmore-street/",
        "index": 131.875,
        "franchise-entity-name": "SFFIT PH LLC"
    },
    "d3fdc532-2ad9-43d4-b809-623d503bb07f": {
        "name": "Orange Theory San Francisco - Mission Bay, CA",
        "address": "215 King Street San Francisco, California 94107",
        "phone": "(415) 569-2051",
        "email": "studiomanager0455@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 2:00PM",
            "SUNDAY": "7:00AM - 2:00PM"
        },
        "geolocation": [
            37.7775421,
            -122.39211
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 118.64,
                    "duration": "1 Months",
                    "model_conf": 153.32
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.03,
                    "duration": "1 Months",
                    "model_conf": 180.53
                }
            },
            "Premier": {
                "cost": 269,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 267.97,
                    "duration": "1 Months",
                    "model_conf": 108.17
                }
            }
        },
        "services": {
            "10 Session Pack - $290": {
                "cost": 290,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $550": {
                "cost": 550,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $790": {
                "cost": 790,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 32,
            "late-cancel-2": 16,
            "10-pack": 290,
            "20-pack": 550,
            "premier-family-addon-6-month-corp-2": 259,
            "surcharge-2": 10,
            "30-pack": 790,
            "premier": 269,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 189,
            "elite": 199,
            "additional-session-2": 24,
            "name": "San Francisco - Mission Bay, CA",
            "slug": "d3fdc532-2ad9-43d4-b809-623d503bb07f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/25/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-francisco/215-king-street/",
        "index": 131.875,
        "franchise-entity-name": "SFFIT MB LLC"
    },
    "46aacfa2-b816-4407-9824-1980616ca434": {
        "name": "Orange Theory San Francisco - Financial District, CA",
        "address": "343 Sansome St., Suite 125 San Francisco, California 94104",
        "phone": "(415) 569-2050",
        "email": "studiomanager0454@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 8:00PM",
            "TUESDAY": "6:00AM - 8:00PM",
            "WEDNESDAY": "6:00AM - 8:00PM",
            "THURSDAY": "6:00AM - 8:00PM",
            "FRIDAY": "6:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 2:00PM",
            "SUNDAY": "7:00AM - 2:00PM"
        },
        "geolocation": [
            37.7936974,
            -122.40173
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.11,
                    "duration": "indefinitely",
                    "model_conf": 153.23
                }
            },
            "Elite": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 198.27,
                    "duration": "1 Months",
                    "model_conf": 180.17
                }
            },
            "Premier": {
                "cost": 269,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 268.58,
                    "duration": "indefinitely",
                    "model_conf": 107.9
                }
            }
        },
        "services": {
            "10 Session Pack - $290": {
                "cost": 290,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $550": {
                "cost": 550,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $790": {
                "cost": 790,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 32,
            "late-cancel-2": 16,
            "10-pack": 290,
            "20-pack": 550,
            "premier-family-addon-6-month-corp-2": 259,
            "surcharge-2": 10,
            "30-pack": 790,
            "premier": 269,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 189,
            "elite": 199,
            "additional-session-2": 24,
            "name": "San Francisco - Financial District, CA",
            "slug": "46aacfa2-b816-4407-9824-1980616ca434"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/27/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-francisco/343-sansome-st-suite-125/",
        "index": 131.875,
        "franchise-entity-name": "SFFIT FD LLC"
    },
    "ad35d4a0-ca94-4213-b03b-4c8b441dfe0f": {
        "name": "Orange Theory Almaden Valley, CA",
        "address": "1080 Blossom Hill Rd.Suite C San Jose, California 95123",
        "phone": "(408) 550-7004",
        "email": "studiomanager0111@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 10:30PM",
            "SUNDAY": "7:00AM - 10:30PM"
        },
        "geolocation": [
            37.2501183,
            -121.87235
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.6,
                    "duration": "1 Months",
                    "model_conf": 153.12
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.81,
                    "duration": "indefinitely",
                    "model_conf": 173.25
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.93,
                    "duration": "indefinitely",
                    "model_conf": 109.42
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Almaden Valley, CA",
            "slug": "ad35d4a0-ca94-4213-b03b-4c8b441dfe0f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/25/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-jose/1080-blossom-hill-rd-suite-c/",
        "index": 111.325,
        "franchise-entity-name": "WCF BLOSSOM HILL LLC"
    },
    "701b945b-3059-48cc-a8aa-0189c6a1b491": {
        "name": "Orange Theory San Jose Downtown",
        "address": "111 West St. John Street San Jose, California 95113",
        "phone": "(408) 912-2886",
        "email": "studiomanager0934@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:45PM",
            "TUESDAY": "6:00AM - 7:45PM",
            "WEDNESDAY": "6:00AM - 7:45PM",
            "THURSDAY": "6:00AM - 7:45PM",
            "FRIDAY": "6:00AM - 7:45PM",
            "SATURDAY": "Closed",
            "SUNDAY": "Closed"
        },
        "geolocation": [
            37.337399,
            -121.8941
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.85,
                    "duration": "indefinitely",
                    "model_conf": 153.5
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.85,
                    "duration": "indefinitely",
                    "model_conf": 176.13
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.1,
                    "duration": "1 Months",
                    "model_conf": 111.09
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "San Jose Downtown",
            "slug": "701b945b-3059-48cc-a8aa-0189c6a1b491"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/27/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-jose/111-west-st-john-street-suite-100/",
        "index": 111.325,
        "franchise-entity-name": "DTSJ FITNESS SOUTH, LLC"
    },
    "949ed3e4-1ebf-499f-a876-215c45f2ddb9": {
        "name": "Orange Theory San Jose - Rose Garden, CA",
        "address": "1360 The Alameda San Jose, California 95126",
        "phone": "(408) 912-1992",
        "email": "studiomanager0434@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            37.33323,
            -121.91307
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.47,
                    "duration": "indefinitely",
                    "model_conf": 153.57
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.01,
                    "duration": "2 Months",
                    "model_conf": 174.71
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.46,
                    "duration": "1 Months",
                    "model_conf": 111.06
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "San Jose - Rose Garden, CA",
            "slug": "949ed3e4-1ebf-499f-a876-215c45f2ddb9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/13/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-jose/1360-the-alameda/",
        "index": 111.325,
        "franchise-entity-name": "SAN PEDRO SQUARE FITNESS, LLC"
    },
    "958709a8-2b99-48c7-9905-6e4de6754fac": {
        "name": "Orange Theory San Jose - Communication Hill, CA",
        "address": "551 W. Capitol Expressway San Jose, California 95136",
        "phone": "(408) 680-2048",
        "email": "studiomanager0429@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 7:45PM",
            "TUESDAY": "5:15AM - 7:45PM",
            "WEDNESDAY": "5:15AM - 7:45PM",
            "THURSDAY": "5:15AM - 7:45PM",
            "FRIDAY": "5:15AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            37.2758026,
            -121.85369
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.96,
                    "duration": "indefinitely",
                    "model_conf": 153.13
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.3,
                    "duration": "indefinitely",
                    "model_conf": 174.21
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.09,
                    "duration": "indefinitely",
                    "model_conf": 109.86
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "San Jose - Communication Hill, CA",
            "slug": "958709a8-2b99-48c7-9905-6e4de6754fac"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/6/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-jose/551-w-capitol-expressway/",
        "index": 111.325,
        "franchise-entity-name": "MS. FITNESS LLC"
    },
    "8ebd8b42-be83-4fcd-ac18-d0e3a2fd0a80": {
        "name": "Orange Theory San Leandro, CA",
        "address": "1252 Fairmont Dr. San Leandro, California 94578",
        "phone": "(510) 736-5683",
        "email": "studiomanager0701@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:20AM - 11:30AM",
            "SUNDAY": "7:20AM - 11:30AM"
        },
        "geolocation": [
            37.7022018,
            -122.12815
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.72,
                    "duration": "1 Months",
                    "model_conf": 152.44
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.75,
                    "duration": "indefinitely",
                    "model_conf": 184.12
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 197.86,
                    "duration": "2 Months",
                    "model_conf": 110.32
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "San Leandro, CA",
            "slug": "8ebd8b42-be83-4fcd-ac18-d0e3a2fd0a80"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/15/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-leandro/1252-fairmont-dr/",
        "index": 108.325,
        "franchise-entity-name": "TFP CALIFORNIA FITNESS PARTNERS I, LLC."
    },
    "9c226f68-5dcd-4184-8015-11fb8fa92209": {
        "name": "Orange Theory San Luis Obispo",
        "address": "3865 S Higuera St. San Luis Obispo, California 93401",
        "phone": "(559) 472-9191",
        "email": "studiomanager0843@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            35.2475205,
            -120.67255
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.84,
                    "duration": "indefinitely",
                    "model_conf": 153.72
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.99,
                    "duration": "indefinitely",
                    "model_conf": 185.73
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.86,
                    "duration": "indefinitely",
                    "model_conf": 112.06
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Luis Obispo",
            "slug": "9c226f68-5dcd-4184-8015-11fb8fa92209"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/26/21",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-luis-obispo/3865-s-higuera-st/",
        "index": 103.375,
        "franchise-entity-name": "OCEAN VIEW FITNESS, LLC"
    },
    "185e8e2d-ab90-4bd6-9c2a-5ab9851e9e64": {
        "name": "Orange Theory San Diego-San Marcos, CA",
        "address": "314 S. Twin Oaks Valley Road, #120 San Marcos, California 92078",
        "phone": "(760) 600-5610",
        "email": "studiomanager1273@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            33.12957,
            -117.16671
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 91.2,
                    "duration": "indefinitely",
                    "model_conf": 141.96
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 126.45,
                    "duration": "7 Months",
                    "model_conf": 174.71
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.65,
                    "duration": "indefinitely",
                    "model_conf": 104.27
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Diego-San Marcos, CA",
            "slug": "185e8e2d-ab90-4bd6-9c2a-5ab9851e9e64"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-marcos/314-s-twin-oaks-valley-road-120/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT11, LLC"
    },
    "17eccd1b-5d6a-4e1b-a668-9b8276572546": {
        "name": "Orange Theory San Marcos, TX",
        "address": "243 Wonder World Drive, Suite 108 San Marcos, Texas 78666",
        "phone": "(512) 357-3057",
        "email": "studiomanager0628@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            29.8623104,
            -97.959335
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.96,
                    "duration": "indefinitely",
                    "model_conf": 153.84
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.41,
                    "duration": "indefinitely",
                    "model_conf": 185.73
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.96,
                    "duration": "indefinitely",
                    "model_conf": 112.08
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "San Marcos, TX",
            "slug": "17eccd1b-5d6a-4e1b-a668-9b8276572546"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/26/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/san-marcos/243-wonder-world-drive-suite-108/",
        "index": 93.4,
        "franchise-entity-name": "SIX.FOURTEEN.TWELVE II, LLC"
    },
    "acc5ec82-f9d7-4e3d-81d2-e0b6f5215348": {
        "name": "Orange Theory San Mateo, CA",
        "address": "101 S B St San Mateo, California 94401",
        "phone": "(650) 487-0404",
        "email": "studiomanager0209@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:15AM - 1:00PM",
            "SUNDAY": "8:15AM - 1:00PM"
        },
        "geolocation": [
            37.5670776,
            -122.32371
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.35,
                    "duration": "6 Months",
                    "model_conf": 153.34
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 150.83,
                    "duration": "indefinitely",
                    "model_conf": 182.5
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 207.95,
                    "duration": "1 Months",
                    "model_conf": 108.85
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "San Mateo, CA",
            "slug": "acc5ec82-f9d7-4e3d-81d2-e0b6f5215348"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-mateo/101-s-b-st/",
        "index": 111.325,
        "franchise-entity-name": "KOMA VENTURES I, LLC"
    },
    "380270fb-03c8-4b38-89f7-240f749ed431": {
        "name": "Orange Theory San Ramon, CA",
        "address": "2415 San Ramon Valley Boulevard, Suite 3 San Ramon, California 94583",
        "phone": "(925) 905-9977",
        "email": "studiomanager0891@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "6:30AM - 12:25PM",
            "SUNDAY": "6:30AM - 12:25PM"
        },
        "geolocation": [
            37.7749023,
            -121.97922
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.0,
                    "duration": "indefinitely",
                    "model_conf": 151.24
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.2,
                    "duration": "indefinitely",
                    "model_conf": 180.06
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.59,
                    "duration": "indefinitely",
                    "model_conf": 109.41
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "San Ramon, CA",
            "slug": "380270fb-03c8-4b38-89f7-240f749ed431"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-ramon/2415-san-ramon-valley-boulevard-suite-3/",
        "index": 108.325,
        "franchise-entity-name": "TKV3, LLC"
    },
    "db79a9d0-1f42-474f-990d-941e4b244e3f": {
        "name": "Orange Theory Sandy, UT",
        "address": "10261 S. State St. Sandy, Utah 84070",
        "phone": "(801) 821-4675",
        "email": "studiomanager0225@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            40.5648651,
            -111.89016
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.78,
                    "duration": "indefinitely",
                    "model_conf": 137.29
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.1,
                    "duration": "6 Months",
                    "model_conf": 177.38
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 170.24,
                    "duration": "indefinitely",
                    "model_conf": 99.95
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Sandy, UT",
            "slug": "db79a9d0-1f42-474f-990d-941e4b244e3f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/24/16",
        "url": "https://www.orangetheory.com/en-us/locations/utah/sandy/10261-s-state-st/",
        "index": 93.4,
        "franchise-entity-name": "HEARTZONE FITNESS II LLC"
    },
    "f7997d37-46d1-4f45-ad61-8d71687d1d19": {
        "name": "Orange Theory Sanford - West, FL",
        "address": "4932 W. SR 46. Suite 1112 Sanford, Florida 32771",
        "phone": "(407) 986-6295",
        "email": "studiomanager1045@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 7:45PM",
            "TUESDAY": "4:55AM - 7:45PM",
            "WEDNESDAY": "4:55AM - 7:45PM",
            "THURSDAY": "4:55AM - 7:45PM",
            "FRIDAY": "4:55AM - 7:45PM",
            "SATURDAY": "7:15AM - 11:45AM",
            "SUNDAY": "7:15AM - 11:45AM"
        },
        "geolocation": [
            28.8119431,
            -81.343163
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.98,
                    "duration": "5 Months",
                    "model_conf": 145.53
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.22,
                    "duration": "indefinitely",
                    "model_conf": 159.65
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.12,
                    "duration": "1 Months",
                    "model_conf": 90.27
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sanford - West, FL",
            "slug": "f7997d37-46d1-4f45-ad61-8d71687d1d19"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/18",
        "url": "https://www.orangetheory.com/en-us/locations/florida/deltona/4932-w-sr-46-suite-1112/",
        "index": 98.425,
        "franchise-entity-name": "FLORIDA FITNESS WORKS 2, LLC"
    },
    "f6fb7ab6-ab25-4286-9854-59189b74e97e": {
        "name": "Orange Theory Santa Barbara, CA",
        "address": "651 Paseo Nuevo Santa Barbara, California 93101",
        "phone": "(805) 329-4100",
        "email": "studiomanager1071@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 6:45PM",
            "TUESDAY": "5:15AM - 6:45PM",
            "WEDNESDAY": "5:15AM - 6:45PM",
            "THURSDAY": "5:15AM - 6:45PM",
            "FRIDAY": "5:15AM - 6:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            34.418139,
            -119.69888
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.92,
                    "duration": "indefinitely",
                    "model_conf": 153.56
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.24,
                    "duration": "indefinitely",
                    "model_conf": 185.67
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.15,
                    "duration": "indefinitely",
                    "model_conf": 111.95
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Santa Barbara, CA",
            "slug": "f6fb7ab6-ab25-4286-9854-59189b74e97e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/17/21",
        "url": "https://www.orangetheory.com/en-us/locations/california/santa-barbara/651-paseo-nuevo-629/",
        "index": 103.375,
        "franchise-entity-name": "THE FITNESS SOLUTION, LLC"
    },
    "20950e79-6177-49cb-ba4e-04f492428bdd": {
        "name": "Orange Theory Mission Park, CA",
        "address": "2020 Wyatt Drive Suite 110 Santa Clara, California 95054",
        "phone": "(408) 550-7002",
        "email": "studiomanager0521@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 9:00PM",
            "TUESDAY": "6:15AM - 9:00PM",
            "WEDNESDAY": "6:15AM - 9:00PM",
            "THURSDAY": "6:15AM - 9:00PM",
            "FRIDAY": "6:15AM - 9:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            37.3880386,
            -121.95967
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.99,
                    "duration": "indefinitely",
                    "model_conf": 153.36
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.11,
                    "duration": "indefinitely",
                    "model_conf": 178.08
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.21,
                    "duration": "indefinitely",
                    "model_conf": 110.79
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Mission Park, CA",
            "slug": "20950e79-6177-49cb-ba4e-04f492428bdd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/16/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/santa-clara/2020-wyatt-drive/",
        "index": 111.325,
        "franchise-entity-name": "WCF MISSION PARK LLC"
    },
    "e5f3d8f3-fb0e-4ab7-b885-148beca86deb": {
        "name": "Orange Theory Valley Fair, CA",
        "address": "2855 Stevens Creek Blvd Suite 1604 Santa Clara, California 95050",
        "phone": "(408) 898-1312",
        "email": "studiomanager1062@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:30PM",
            "TUESDAY": "6:15AM - 7:30PM",
            "WEDNESDAY": "6:15AM - 7:30PM",
            "THURSDAY": "6:15AM - 7:30PM",
            "FRIDAY": "6:15AM - 7:30PM",
            "SATURDAY": "8:45AM - 11:00AM",
            "SUNDAY": "8:45AM - 11:00AM"
        },
        "geolocation": [
            37.3255653,
            -121.94719
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.95,
                    "duration": "indefinitely",
                    "model_conf": 153.63
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.88,
                    "duration": "indefinitely",
                    "model_conf": 173.5
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.39,
                    "duration": "1 Months",
                    "model_conf": 111.06
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Valley Fair, CA",
            "slug": "e5f3d8f3-fb0e-4ab7-b885-148beca86deb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/26/21",
        "url": "https://www.orangetheory.com/en-us/locations/california/santa-clara/2855-stevens-creek-blvd-ste-1604/",
        "index": 111.325,
        "franchise-entity-name": "WCF Valley Fair LLC"
    },
    "813b2e2f-abad-4c15-a34d-1a9d50454d04": {
        "name": "Orange Theory Santa Clara - Monticello, CA",
        "address": "3555 Monroe St Suite 10 Santa Clara, California 95051",
        "phone": "(408) 457-8989",
        "email": "studiomanager0520@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            37.3676224,
            -121.99258
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.92,
                    "duration": "indefinitely",
                    "model_conf": 153.26
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.93,
                    "duration": "indefinitely",
                    "model_conf": 175.4
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.93,
                    "duration": "indefinitely",
                    "model_conf": 110.25
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Santa Clara - Monticello, CA",
            "slug": "813b2e2f-abad-4c15-a34d-1a9d50454d04"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/21/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/santa-clara/3555-monroe-st-suite-10/",
        "index": 111.325,
        "franchise-entity-name": "CAPITOLA FITNESS AND/OR ITS LLC ASSIGNEE"
    },
    "abede4d2-4b35-4890-a47f-6d6eeb271764": {
        "name": "Orange Theory Santa Clarita, CA",
        "address": "25914 McBean Parkway Santa Clarita, California 91355",
        "phone": "(661) 505-9860",
        "email": "studiomanager0362@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "5:00AM - 10:45AM",
            "SUNDAY": "5:00AM - 10:45AM"
        },
        "geolocation": [
            34.4044571,
            -118.55101
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.88,
                    "duration": "indefinitely",
                    "model_conf": 153.64
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.26,
                    "duration": "indefinitely",
                    "model_conf": 185.15
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.8,
                    "duration": "indefinitely",
                    "model_conf": 111.49
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Santa Clarita, CA",
            "slug": "abede4d2-4b35-4890-a47f-6d6eeb271764"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/27/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/santa-clarita/25914-mcbean-parkway/",
        "index": 108.325,
        "franchise-entity-name": "CALIFORNIA FITNESS I, LLC"
    },
    "264f4064-e2fb-4e5e-bf9a-16c545ecd116": {
        "name": "Orange Theory Santa Fe- South Capital, NM",
        "address": "516 W. Cordova St. Santa Fe, New Mexico 87505",
        "phone": "(505) 395-7100",
        "email": "studiomanager0568@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            35.6725082,
            -105.9517
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.67,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.36,
                    "duration": "3 Months",
                    "model_conf": 153.71
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 10.04,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.6,
                    "duration": "indefinitely",
                    "model_conf": 185.77
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 15.1,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.67,
                    "duration": "indefinitely",
                    "model_conf": 111.98
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 18.98,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 31.64,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 42.1,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Santa Fe- South Capital, NM",
            "slug": "264f4064-e2fb-4e5e-bf9a-16c545ecd116"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-mexico/santa-fe/516-w-cordova-st/",
        "index": 98.425,
        "franchise-entity-name": "L5 FITNESS NEW MEXICO, LLC"
    },
    "c61f93ed-2884-46e0-aade-ff15a974ccf8": {
        "name": "Orange Theory Santa Monica, CA",
        "address": "1431 2nd Street Suite 101 Santa Monica, California 90401",
        "phone": "(310) 730-1399",
        "email": "studiomanager0086@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 8:00PM",
            "TUESDAY": "6:15AM - 8:00PM",
            "WEDNESDAY": "6:15AM - 8:00PM",
            "THURSDAY": "6:15AM - 8:00PM",
            "FRIDAY": "6:15AM - 8:00PM",
            "SATURDAY": "8:15AM - 1:15PM",
            "SUNDAY": "8:15AM - 1:15PM"
        },
        "geolocation": [
            34.0143967,
            -118.49604
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.36,
                    "duration": "indefinitely",
                    "model_conf": 151.79
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.3,
                    "duration": "1 Months",
                    "model_conf": 184.03
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.24,
                    "duration": "indefinitely",
                    "model_conf": 110.68
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Santa Monica, CA",
            "slug": "c61f93ed-2884-46e0-aade-ff15a974ccf8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/27/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/santa-monica/1431-2nd-street/",
        "index": 111.325,
        "franchise-entity-name": "OTF SM, LLC"
    },
    "94223e39-abc1-456f-8b6d-b41184227f35": {
        "name": "Orange Theory Santa Rosa, CA",
        "address": "733 Coddingtown Center #745B Santa Rosa, California 95401",
        "phone": "(707) 331-1270",
        "email": "studiomanager0387@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "6:45AM - 11:30AM",
            "SUNDAY": "6:45AM - 11:30AM"
        },
        "geolocation": [
            38.4564209,
            -122.7279
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.1,
                    "duration": "indefinitely",
                    "model_conf": 153.57
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.54,
                    "duration": "indefinitely",
                    "model_conf": 185.51
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.11,
                    "duration": "indefinitely",
                    "model_conf": 111.97
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Santa Rosa, CA",
            "slug": "94223e39-abc1-456f-8b6d-b41184227f35"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/santa-rosa/733-coddingtown-center-745b/",
        "index": 108.325,
        "franchise-entity-name": "DYER ENTERPRISES I, LLC"
    },
    "724da3c8-714e-4d3d-86c1-22824f3a8718": {
        "name": "Orange Theory Sarasota Downtown, FL",
        "address": "1605 Main St Sarasota, Florida 34236",
        "phone": "(941) 444-2440",
        "email": "studiomanager0254@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            27.336771,
            -82.538055
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.97,
                    "duration": "indefinitely",
                    "model_conf": 152.48
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.57,
                    "duration": "4 Months",
                    "model_conf": 181.69
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.45,
                    "duration": "indefinitely",
                    "model_conf": 108.03
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sarasota Downtown, FL",
            "slug": "724da3c8-714e-4d3d-86c1-22824f3a8718"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/18/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/sarasota/1605-main-st/",
        "index": 98.425,
        "franchise-entity-name": "NORTH SARASOTA FITNESS EXPRESS &quot; LLC &quot;"
    },
    "151eea66-e432-444d-842d-562c11fb8e65": {
        "name": "Orange Theory Sarasota, FL",
        "address": "6509 S Tamiami Trail Sarasota, Florida 34231",
        "phone": "(941) 444-2439",
        "email": "studiomanager0185@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            27.2602387,
            -82.522339
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.3,
                    "duration": "indefinitely",
                    "model_conf": 152.79
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.84,
                    "duration": "indefinitely",
                    "model_conf": 183.61
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.03,
                    "duration": "indefinitely",
                    "model_conf": 109.99
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sarasota, FL",
            "slug": "151eea66-e432-444d-842d-562c11fb8e65"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/23/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/sarasota/6509-s-tamiami-trail/",
        "index": 98.425,
        "franchise-entity-name": "SOUTH SARASOTA FITNESS EXPRESS &quot;LLC&quot;"
    },
    "800d7e9f-c192-4900-9e1d-23e3f1077cd4": {
        "name": "Orange Theory Saugus, MA",
        "address": "749 Broadway Saugus, Massachusetts 01906",
        "phone": "(781) 819-2444",
        "email": "studiomanager0945@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            42.4773827,
            -71.023895
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.02,
                    "duration": "indefinitely",
                    "model_conf": 151.95
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.29,
                    "duration": "indefinitely",
                    "model_conf": 183.48
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.15,
                    "duration": "indefinitely",
                    "model_conf": 110.99
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Saugus, MA",
            "slug": "800d7e9f-c192-4900-9e1d-23e3f1077cd4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/saugus/749-broadway/",
        "index": 103.375,
        "franchise-entity-name": "SIMPLE VENTURES 2, LLC"
    },
    "57bc8ef7-1982-4b72-8016-951c0f7809cf": {
        "name": "Orange Theory Savannah, GA",
        "address": "1800 East Victory Dr., Suite 2 Savannah, Georgia 31404",
        "phone": "(912) 921-9519",
        "email": "studiomanager0369@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 1:30PM",
            "SUNDAY": "6:15AM - 1:30PM"
        },
        "geolocation": [
            32.0450707,
            -81.07106
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.04,
                    "duration": "indefinitely",
                    "model_conf": 152.38
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.03,
                    "duration": "indefinitely",
                    "model_conf": 185.69
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.08,
                    "duration": "indefinitely",
                    "model_conf": 106.28
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Savannah, GA",
            "slug": "57bc8ef7-1982-4b72-8016-951c0f7809cf"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/savannah/1800-east-victory-dr-suite-2/",
        "index": 98.425,
        "franchise-entity-name": "VVDC SAVANNAH, LLC"
    },
    "6c48656d-2ddc-448f-b80b-d6ca94edd66f": {
        "name": "Orange Theory Scarsdale, NY",
        "address": "1019 Central Park Ave Scarsdale, New York 10583",
        "phone": "(914) 359-4567",
        "email": "studiomanager0696@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            40.9890213,
            -73.824722
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 86.87,
                    "duration": "9 Months",
                    "model_conf": 137.16
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 131.51,
                    "duration": "indefinitely",
                    "model_conf": 165.97
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.21,
                    "duration": "3 Months",
                    "model_conf": 100.62
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Scarsdale, NY",
            "slug": "6c48656d-2ddc-448f-b80b-d6ca94edd66f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/25/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/scarsdale/1019-central-park-ave/",
        "index": 103.375,
        "franchise-entity-name": "SOCIAL SUN LLC"
    },
    "cf027405-8fc2-42ce-9338-77c3cac1e0cb": {
        "name": "Orange Theory Schaumburg, IL",
        "address": "16 E Golf Road Schaumburg, Illinois 60173",
        "phone": "(224) 532-2442",
        "email": "studiomanager0325@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 10:45AM",
            "SUNDAY": "7:15AM - 10:45AM"
        },
        "geolocation": [
            42.0518608,
            -88.078651
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.47,
                    "duration": "indefinitely",
                    "model_conf": 142.16
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.28,
                    "duration": "2 Months",
                    "model_conf": 171.9
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.68,
                    "duration": "indefinitely",
                    "model_conf": 103.18
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Schaumburg, IL",
            "slug": "cf027405-8fc2-42ce-9338-77c3cac1e0cb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/19/16",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/schaumburg/16-e-golf-road/",
        "index": 103.375,
        "franchise-entity-name": "CHICAGOLAND FITNESS XI LLC"
    },
    "367109e7-7bc1-4295-b972-8f1e1af49922": {
        "name": "Orange Theory Niskayuna, NY",
        "address": "3333 Consaul Road Schenectady, New York 12304",
        "phone": "(518) 930-0283",
        "email": "studiomanager0384@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            42.7832031,
            -73.891327
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.86,
                    "duration": "indefinitely",
                    "model_conf": 150.43
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.34,
                    "duration": "2 Months",
                    "model_conf": 177.24
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.54,
                    "duration": "indefinitely",
                    "model_conf": 111.44
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Niskayuna, NY",
            "slug": "367109e7-7bc1-4295-b972-8f1e1af49922"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/27/15",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/schenectady/3333-consaul-road/",
        "index": 98.425,
        "franchise-entity-name": "MJP NISKAYUNA, LLC"
    },
    "e9d79819-6852-4493-a6bd-a8bd21fe4744": {
        "name": "Orange Theory Scottsdale- North, AZ",
        "address": "23757 North Scottsdale Road, Suite 125 Scottsdale, Arizona 85255",
        "phone": "(480) 447-4997",
        "email": "studiomanager0409@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            33.7030563,
            -111.92528
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.68,
                    "duration": "1 Months",
                    "model_conf": 137.3
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.3,
                    "duration": "indefinitely",
                    "model_conf": 162.66
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.51,
                    "duration": "1 Months",
                    "model_conf": 100.41
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Scottsdale- North, AZ",
            "slug": "e9d79819-6852-4493-a6bd-a8bd21fe4744"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/10/17",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/scottsdale/23733-n-scottsdale-road-suite-125/",
        "index": 103.375,
        "franchise-entity-name": "DESERT RIDGE OTF, LLC"
    },
    "c3064cb8-b639-417a-a01c-95bab9cd748d": {
        "name": "Orange Theory McCormick Ranch, AZ",
        "address": "7001 N. Scottsdale Road, Suite E-185 Scottsdale, Arizona 85253",
        "phone": "(480) 405-2737",
        "email": "studiomanager0075@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:30PM",
            "TUESDAY": "5:30AM - 7:30PM",
            "WEDNESDAY": "5:30AM - 7:30PM",
            "THURSDAY": "5:30AM - 7:30PM",
            "FRIDAY": "5:30AM - 7:30PM",
            "SATURDAY": "8:00AM - 11:45AM",
            "SUNDAY": "8:00AM - 11:45AM"
        },
        "geolocation": [
            33.5394173,
            -111.92398
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.68,
                    "duration": "indefinitely",
                    "model_conf": 130.11
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.22,
                    "duration": "2 Months",
                    "model_conf": 154.99
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.53,
                    "duration": "indefinitely",
                    "model_conf": 95.66
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "McCormick Ranch, AZ",
            "slug": "c3064cb8-b639-417a-a01c-95bab9cd748d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/17/15",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/scottsdale/7001-n-scottsdale-road-suite-e-185/",
        "index": 103.375,
        "franchise-entity-name": "ZEST FITNESS, LLC AND/OR IT&#39;S LLC ASSIGNER"
    },
    "6cb18c09-653d-492b-9f3c-79b4a6bfc7e5": {
        "name": "Orange Theory Old Town Scottsdale, AZ",
        "address": "7620 E. Indian School Road, Suite 107 Scottsdale, Arizona 85251",
        "phone": "(480) 405-2683",
        "email": "studiomanager0080@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            33.4966698,
            -111.91653
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.31,
                    "duration": "indefinitely",
                    "model_conf": 137.04
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.57,
                    "duration": "1 Months",
                    "model_conf": 164.15
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.25,
                    "duration": "indefinitely",
                    "model_conf": 99.86
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Old Town Scottsdale, AZ",
            "slug": "6cb18c09-653d-492b-9f3c-79b4a6bfc7e5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/17/15",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/scottsdale/7620-e-indian-school-road-suite-107/",
        "index": 103.375,
        "franchise-entity-name": "OLD TOWN SCOTTSDALE FITNESS PARTNERS, LLC"
    },
    "92917dde-4685-4c2d-9228-64ffa3ad30c7": {
        "name": "Orange Theory Scottsdale Ranch, AZ",
        "address": "9301 E. Shea Blvd. , Suite 114 Scottsdale, Arizona 85260",
        "phone": "(480) 551-1111",
        "email": "studiomanager0051@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            33.5809097,
            -111.88114
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 91.05,
                    "duration": "indefinitely",
                    "model_conf": 127.47
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 126.69,
                    "duration": "6 Months",
                    "model_conf": 150.1
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.34,
                    "duration": "indefinitely",
                    "model_conf": 93.68
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Scottsdale Ranch, AZ",
            "slug": "92917dde-4685-4c2d-9228-64ffa3ad30c7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/14/14",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/scottsdale/9301-e-shea-blvd-suite-114/",
        "index": 103.375,
        "franchise-entity-name": "ZEST FITNESS, LLC"
    },
    "90955d1c-f8aa-4607-94f1-ca8218ae95a2": {
        "name": "Orange Theory Seaford",
        "address": "3940 Merrick Rd Seaford, New York 11783",
        "phone": "(516) 246-6212",
        "email": "studiomanager0648@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.6661072,
            -73.485596
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.3,
                    "duration": "6 Months",
                    "model_conf": 139.71
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.8,
                    "duration": "indefinitely",
                    "model_conf": 167.84
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.33,
                    "duration": "1 Months",
                    "model_conf": 100.47
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Seaford",
            "slug": "90955d1c-f8aa-4607-94f1-ca8218ae95a2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/29/20",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/seaford/3940-merrick-rd/",
        "index": 108.325,
        "franchise-entity-name": "SF FITNESS LLC"
    },
    "d9f4cffe-38ca-480c-b8ce-0833192e968d": {
        "name": "Orange Theory Wallingford, WA",
        "address": "1325 N. 45th Street Seattle, Washington 98103",
        "phone": "(206) 922-3190",
        "email": "studiomanager0577@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            47.6612167,
            -122.341
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.15,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.44,
                    "duration": "2 Months",
                    "model_conf": 149.74
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.25,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.96,
                    "duration": "indefinitely",
                    "model_conf": 182.05
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.4,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.67,
                    "duration": "indefinitely",
                    "model_conf": 105.37
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.62,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 46.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 61.4,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Wallingford, WA",
            "slug": "d9f4cffe-38ca-480c-b8ce-0833192e968d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/20/18",
        "url": "https://www.orangetheory.com/en-us/locations/washington/seattle/1325-n-45th-street/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF WALLINGFORD, LLC"
    },
    "e13bae34-7bc5-49cb-8540-75122d7f9f4e": {
        "name": "Orange Theory Ballard, WA",
        "address": "2032 NW Market St. Seattle, Washington 98107",
        "phone": "(206) 330-0510",
        "email": "studiomanager0581@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:30PM",
            "TUESDAY": "6:15AM - 7:30PM",
            "WEDNESDAY": "6:15AM - 7:30PM",
            "THURSDAY": "6:15AM - 7:30PM",
            "FRIDAY": "6:15AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            47.6687737,
            -122.38361
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.15,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.53,
                    "duration": "1 Months",
                    "model_conf": 151.21
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.25,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.98,
                    "duration": "indefinitely",
                    "model_conf": 183.03
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.4,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.06,
                    "duration": "indefinitely",
                    "model_conf": 107.82
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.62,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 46.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 61.4,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Ballard, WA",
            "slug": "e13bae34-7bc5-49cb-8540-75122d7f9f4e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/6/16",
        "url": "https://www.orangetheory.com/en-us/locations/washington/seattle/2032-nw-market-st/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF BALLARD, LLC"
    },
    "d55265db-8c81-40f0-959a-4afc98db7ad1": {
        "name": "Orange Theory Belltown, WA",
        "address": "2310 5th Ave., Suite 3 Seattle, Washington 98121",
        "phone": "(206) 855-5089",
        "email": "studiomanager0580@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:45AM - 1:30PM",
            "SUNDAY": "8:45AM - 1:30PM"
        },
        "geolocation": [
            47.616127,
            -122.34312
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.15,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.53,
                    "duration": "1 Months",
                    "model_conf": 147.69
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.25,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.67,
                    "duration": "indefinitely",
                    "model_conf": 178.19
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.4,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.0,
                    "duration": "indefinitely",
                    "model_conf": 100.39
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.62,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 46.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 61.4,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Belltown, WA",
            "slug": "d55265db-8c81-40f0-959a-4afc98db7ad1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/9/17",
        "url": "https://www.orangetheory.com/en-us/locations/washington/seattle/2310-5th-ave-suite-3/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF BELLTOWN, LLC"
    },
    "6745335e-424c-4b11-bf8f-d600f13700be": {
        "name": "Orange Theory West Seattle, WA",
        "address": "4755 Fauntleroy Way SW, Suite 130 Seattle, Washington 98116",
        "phone": "(206) 438-9974",
        "email": "studiomanager0586@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            47.5597191,
            -122.38187
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.15,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.66,
                    "duration": "1 Months",
                    "model_conf": 151.88
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.25,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.42,
                    "duration": "indefinitely",
                    "model_conf": 182.69
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.4,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.89,
                    "duration": "indefinitely",
                    "model_conf": 108.66
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.62,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 46.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 61.4,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "West Seattle, WA",
            "slug": "6745335e-424c-4b11-bf8f-d600f13700be"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/1/18",
        "url": "https://www.orangetheory.com/en-us/locations/washington/seattle/4755-fauntleroy-way-sw-suite-130/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF WEST SEATTLE, LLC"
    },
    "ef82b122-7624-4414-92cd-38df9d515ca8": {
        "name": "Orange Theory Seattle - Lower Queen Anne, WA",
        "address": "500 Mercer St. Seattle, Washington 98109",
        "phone": "(206) 282-0557",
        "email": "studiomanager0159@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 8:00PM",
            "TUESDAY": "6:15AM - 8:00PM",
            "WEDNESDAY": "6:15AM - 8:00PM",
            "THURSDAY": "6:15AM - 8:00PM",
            "FRIDAY": "6:15AM - 8:00PM",
            "SATURDAY": "8:45AM - 1:30PM",
            "SUNDAY": "8:45AM - 1:30PM"
        },
        "geolocation": [
            47.624733,
            -122.34723
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.15,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.44,
                    "duration": "2 Months",
                    "model_conf": 147.94
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.25,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.81,
                    "duration": "indefinitely",
                    "model_conf": 178.6
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.4,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.83,
                    "duration": "indefinitely",
                    "model_conf": 100.92
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.62,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 46.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 61.4,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Seattle - Lower Queen Anne, WA",
            "slug": "ef82b122-7624-4414-92cd-38df9d515ca8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/15/15",
        "url": "https://www.orangetheory.com/en-us/locations/washington/seattle/500-mercer-st/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF LOWER QUEEN ANNE, LLC"
    },
    "a37423f7-33c5-4a20-bfac-7f28417a380e": {
        "name": "Orange Theory Seattle - Capitol Hill, WA",
        "address": "519 Broadway East Suite 8 Seattle, Washington 98102",
        "phone": "(206) 467-1690",
        "email": "studiomanager0160@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "8:45AM - 1:30PM",
            "SUNDAY": "8:45AM - 1:30PM"
        },
        "geolocation": [
            47.623699,
            -122.32121
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.15,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.6,
                    "duration": "1 Months",
                    "model_conf": 147.71
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.25,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.43,
                    "duration": "indefinitely",
                    "model_conf": 179.62
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.4,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.06,
                    "duration": "indefinitely",
                    "model_conf": 101.08
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.62,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 46.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 61.4,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Seattle - Capitol Hill, WA",
            "slug": "a37423f7-33c5-4a20-bfac-7f28417a380e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/25/14",
        "url": "https://www.orangetheory.com/en-us/locations/washington/seattle/519-broadway-avenue-east-suite-8/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF CAPITOL HILL, LLC"
    },
    "8b8e87c4-e188-499b-8440-9241dc0a9e15": {
        "name": "Orange Theory U District Washington, WA",
        "address": "7317 35th Avenue NE Seattle, Washington 98115",
        "phone": "(206) 330-0502",
        "email": "studiomanager0584@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "8:00AM - 1:15PM",
            "SUNDAY": "8:00AM - 1:15PM"
        },
        "geolocation": [
            47.6817856,
            -122.29072
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.15,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.1,
                    "duration": "indefinitely",
                    "model_conf": 149.65
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.25,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.93,
                    "duration": "indefinitely",
                    "model_conf": 184.32
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.4,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.66,
                    "duration": "indefinitely",
                    "model_conf": 106.56
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.62,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 46.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 61.4,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "U District Washington, WA",
            "slug": "8b8e87c4-e188-499b-8440-9241dc0a9e15"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/27/16",
        "url": "https://www.orangetheory.com/en-us/locations/washington/seattle/7317-35th-avenue-ne/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF U DISTRICT, LLC"
    },
    "31369e2f-40f6-44c0-aabb-6afb9293381c": {
        "name": "Orange Theory Washington Township, NJ",
        "address": "380 Egg Harbor Rd. Suite C2 Sewell, New Jersey 08080",
        "phone": "(856) 454-2015",
        "email": "studiomanager1087@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            39.7528267,
            -75.075172
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.1,
                    "duration": "indefinitely",
                    "model_conf": 153.51
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.01,
                    "duration": "3 Months",
                    "model_conf": 184.55
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.54,
                    "duration": "indefinitely",
                    "model_conf": 111.33
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Washington Township, NJ",
            "slug": "31369e2f-40f6-44c0-aabb-6afb9293381c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/sewell/380-egg-harbor-rd-suite-c2/",
        "index": 98.425,
        "franchise-entity-name": "LOCK MANAGEMENT INC."
    },
    "568414d7-a3bc-4a71-bfeb-11e8b87691dc": {
        "name": "Orange Theory Shakopee, MN",
        "address": "1451 Adams St. Shakopee, Minnesota 55379",
        "phone": "(612) 999-1555",
        "email": "studiomanager0177@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 10:45AM",
            "SUNDAY": "7:15AM - 10:45AM"
        },
        "geolocation": [
            44.779377,
            -93.538773
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.35,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.27,
                    "duration": "4 Months",
                    "model_conf": 152.04
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.3,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.77,
                    "duration": "indefinitely",
                    "model_conf": 184.14
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.73,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 157.23,
                    "duration": "4 Months",
                    "model_conf": 110.93
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.68,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 26.48,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 36.8,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Shakopee, MN",
            "slug": "568414d7-a3bc-4a71-bfeb-11e8b87691dc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/14/17",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/shakopee/1451-adams-st/",
        "index": 88.45,
        "franchise-entity-name": "GO FORWARD BRANDS 3 L.L.C."
    },
    "bf23b4cf-0732-4e28-9e1f-aab62e2c4437": {
        "name": "Orange Theory Shawnee, KS",
        "address": "11900 Shawnee Mission Pkwy Shawnee, Kansas 66216",
        "phone": "(913) 717-5999",
        "email": "studiomanager1297@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:15AM - 12:00PM",
            "SUNDAY": "6:15AM - 12:00PM"
        },
        "geolocation": [
            39.015255,
            -94.724518
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 6.62,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.26,
                    "duration": "indefinitely",
                    "model_conf": 149.92
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 10.46,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.74,
                    "duration": "indefinitely",
                    "model_conf": 180.82
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 16.22,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.27,
                    "duration": "indefinitely",
                    "model_conf": 106.49
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 19.1,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 34.46,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 47.9,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Shawnee, KS",
            "slug": "bf23b4cf-0732-4e28-9e1f-aab62e2c4437"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/13/19",
        "url": "https://www.orangetheory.com/en-us/locations/kansas/shawnee/11900-shawnee-mission-pkwy/",
        "index": 93.4,
        "franchise-entity-name": "Original Training Franchise, LLC"
    },
    "0cd6f050-1387-4b2b-9298-786692005210": {
        "name": "Orange Theory Shelby Township, MI",
        "address": "50560 Schoenherr Road Shelby Charter Township, Michigan 48315",
        "phone": "(586) 270-5117",
        "email": "studiomanager0600@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 9:00PM",
            "TUESDAY": "4:55AM - 9:00PM",
            "WEDNESDAY": "4:55AM - 9:00PM",
            "THURSDAY": "4:55AM - 9:00PM",
            "FRIDAY": "4:55AM - 9:00PM",
            "SATURDAY": "6:15AM - 12:00PM",
            "SUNDAY": "6:15AM - 12:00PM"
        },
        "geolocation": [
            42.6677208,
            -82.994545
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.96,
                    "duration": "indefinitely",
                    "model_conf": 150.12
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.47,
                    "duration": "5 Months",
                    "model_conf": 175.58
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.53,
                    "duration": "indefinitely",
                    "model_conf": 109.63
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Shelby Township, MI",
            "slug": "0cd6f050-1387-4b2b-9298-786692005210"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/13/17",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/shelby-charter-township/50560-schoenherr-road/",
        "index": 98.425,
        "franchise-entity-name": "ALH, LLC"
    },
    "755caa68-1216-4ca9-9409-3cf624a5605e": {
        "name": "Orange Theory Shelton, CT",
        "address": "704 Bridgeport Ave, Suite #201 Shelton, Connecticut 06484",
        "phone": "(203) 343-0900",
        "email": "studiomanager0199@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:45PM",
            "TUESDAY": "5:30AM - 7:45PM",
            "WEDNESDAY": "5:30AM - 7:45PM",
            "THURSDAY": "5:30AM - 7:45PM",
            "FRIDAY": "5:30AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.2750588,
            -73.122841
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.37,
                    "duration": "indefinitely",
                    "model_conf": 143.13
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.22,
                    "duration": "indefinitely",
                    "model_conf": 173.6
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.57,
                    "duration": "indefinitely",
                    "model_conf": 106.75
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Shelton, CT",
            "slug": "755caa68-1216-4ca9-9409-3cf624a5605e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/15/14",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/shelton/704-bridgeport-ave-suite-201/",
        "index": 98.425,
        "franchise-entity-name": "EMPIRE SHELTON LLC"
    },
    "1d4fc763-f8fd-4c68-b8c1-029d8076e811": {
        "name": "Orange Theory Sherman Oaks, CA",
        "address": "14006 Riverside Drive Suite 120 Sherman Oaks, California 91423",
        "phone": "(818) 405-0001",
        "email": "studiomanager0478@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:45PM",
            "TUESDAY": "5:15AM - 8:45PM",
            "WEDNESDAY": "5:15AM - 8:45PM",
            "THURSDAY": "5:15AM - 8:45PM",
            "FRIDAY": "5:15AM - 8:45PM",
            "SATURDAY": "7:00AM - 2:00PM",
            "SUNDAY": "7:00AM - 2:00PM"
        },
        "geolocation": [
            34.1565628,
            -118.437
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 100.12,
                    "duration": "indefinitely",
                    "model_conf": 152.11
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 147.7,
                    "duration": "3 Months",
                    "model_conf": 184.25
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.93,
                    "duration": "indefinitely",
                    "model_conf": 110.91
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Sherman Oaks, CA",
            "slug": "1d4fc763-f8fd-4c68-b8c1-029d8076e811"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/1/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/sherman-oaks/14006-riverside-drive/",
        "index": 111.325,
        "franchise-entity-name": "WCF SHERMAN OAKS LLC"
    },
    "515860a3-f643-4ae3-9ef2-104ee78c2e93": {
        "name": "Orange Theory Shoreline, WA",
        "address": "18336 Aurora Ave. N. Suite 108 Shoreline, Washington 98133",
        "phone": "(206) 504-1511",
        "email": "studiomanager0571@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:45AM - 1:15PM",
            "SUNDAY": "8:45AM - 1:15PM"
        },
        "geolocation": [
            47.7622147,
            -122.34451
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 10.2,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.06,
                    "duration": "3 Months",
                    "model_conf": 151.47
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 14.32,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.33,
                    "duration": "indefinitely",
                    "model_conf": 184.2
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 20.5,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.19,
                    "duration": "1 Months",
                    "model_conf": 109.34
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 25.75,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 46.35,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 61.7,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Shoreline, WA",
            "slug": "515860a3-f643-4ae3-9ef2-104ee78c2e93"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/25/16",
        "url": "https://www.orangetheory.com/en-us/locations/washington/shoreline/18336-aurora-ave-n-suite-108/",
        "index": 108.325,
        "franchise-entity-name": "BHE OF SHORELINE, LLC"
    },
    "da299558-8f1c-478f-8dcf-203390e22c43": {
        "name": "Orange Theory Shorewood, IL",
        "address": "956 Brookforest Ave Shorewood, Illinois 60404",
        "phone": "(708) 573-0858",
        "email": "studiomanager1230@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            41.537571,
            -88.197235
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.04,
                    "duration": "4 Months",
                    "model_conf": 150.64
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.71,
                    "duration": "indefinitely",
                    "model_conf": 180.28
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.31,
                    "duration": "1 Months",
                    "model_conf": 108.55
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Shorewood, IL",
            "slug": "da299558-8f1c-478f-8dcf-203390e22c43"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/19/19",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/shorewood/956-brookforest-ave/",
        "index": 103.375,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-NORTH EAST, LLC"
    },
    "9ea61cad-3d38-4fc6-ae99-051ff84daa17": {
        "name": "Orange Theory Shorewood, WI",
        "address": "4155 N. Oakland Ave. Shorewood, Wisconsin 53211",
        "phone": "(414) 930-4545",
        "email": "studiomanager0831@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "5:55AM - 11:45AM",
            "SUNDAY": "5:55AM - 11:45AM"
        },
        "geolocation": [
            43.0934792,
            -87.887451
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.97,
                    "duration": "5 Months",
                    "model_conf": 153.64
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.74,
                    "duration": "indefinitely",
                    "model_conf": 154.73
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.16,
                    "duration": "1 Months",
                    "model_conf": 107.34
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Shorewood, WI",
            "slug": "9ea61cad-3d38-4fc6-ae99-051ff84daa17"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/17",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/shorewood/4155-n-oakland-ave/",
        "index": 93.4,
        "franchise-entity-name": "OT WISCONSIN SHOREWOOD, LLC"
    },
    "eb70edea-357a-41e6-8169-0862044c5d05": {
        "name": "Orange Theory Short North Columbus, OH",
        "address": "977 N. High St. Short North Columbus, Ohio 43201",
        "phone": "(614) 721-0031",
        "email": "studiomanager1091@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            39.9822311,
            -83.004868
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.19,
                    "duration": "4 Months",
                    "model_conf": 99.28
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.56,
                    "duration": "indefinitely",
                    "model_conf": 119.59
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.43,
                    "duration": "1 Months",
                    "model_conf": 74.08
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Short North Columbus, OH",
            "slug": "eb70edea-357a-41e6-8169-0862044c5d05"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/15/18",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/short-north-columbus/977-n-high-st/",
        "index": 93.4,
        "franchise-entity-name": "SHORT N FIT, LLC"
    },
    "35d46697-d89a-4433-a5ce-f41716d37bdb": {
        "name": "Orange Theory Shreveport, LA",
        "address": "1370 E 70th St. Suite 1000 Shreveport, Louisiana 71105",
        "phone": "(318) 716-7776",
        "email": "studiomanager0770@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            32.4434204,
            -93.72805
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.66,
                    "duration": "indefinitely",
                    "model_conf": 141.47
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.06,
                    "duration": "6 Months",
                    "model_conf": 183.87
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.83,
                    "duration": "indefinitely",
                    "model_conf": 110.64
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Shreveport, LA",
            "slug": "35d46697-d89a-4433-a5ce-f41716d37bdb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/5/17",
        "url": "https://www.orangetheory.com/en-us/locations/louisiana/shreveport/1370-e-70th-st-suite-1000/",
        "index": 93.4,
        "franchise-entity-name": "KMM FITNESS IV LLC"
    },
    "2aefec86-27f1-47ec-bc94-7fb586956871": {
        "name": "Orange Theory Shrewsbury, MA",
        "address": "193 Boston Turnpike Shrewsbury, Massachusetts 01545",
        "phone": "(508) 365-1400",
        "email": "studiomanager0822@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            42.275116,
            -71.746117
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.74,
                    "duration": "6 Months",
                    "model_conf": 153.12
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.6,
                    "duration": "indefinitely",
                    "model_conf": 185.47
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.11,
                    "duration": "1 Months",
                    "model_conf": 111.76
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Shrewsbury, MA",
            "slug": "2aefec86-27f1-47ec-bc94-7fb586956871"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/17/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/shrewsbury/193-boston-turnpike/",
        "index": 98.425,
        "franchise-entity-name": "JD FAMILY FITNESS ENTERPRISES 2, LLC"
    },
    "a9619583-64b4-4228-ba86-d43684cc8c8b": {
        "name": "Orange Theory Shrewsbury, NJ",
        "address": "460 Shrewsbury Plaza Shrewsbury, New Jersey 07702",
        "phone": "(908) 345-2066",
        "email": "studiomanager0543@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45PM",
            "SUNDAY": "7:00AM - 11:45PM"
        },
        "geolocation": [
            40.3149796,
            -74.065643
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 83.13,
                    "duration": "indefinitely",
                    "model_conf": 70.81
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 113.82,
                    "duration": "indefinitely",
                    "model_conf": 68.64
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 182.0,
                    "duration": "indefinitely",
                    "model_conf": 59.2
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Shrewsbury, NJ",
            "slug": "a9619583-64b4-4228-ba86-d43684cc8c8b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/4/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/shrewsbury/460-shrewsbury-plaza/",
        "index": 98.425,
        "franchise-entity-name": "MONMOUTH FITNESS, LLC"
    },
    "1b0f259b-1500-4f83-85bf-21a22f0913b4": {
        "name": "Orange Theory San Jose Silver Creek, CA",
        "address": "5687 Silver Creek Valley Rd. Silver Creek, California 95138",
        "phone": "(408) 495-4180",
        "email": "studiomanager0811@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 8:30PM",
            "TUESDAY": "6:15AM - 8:30PM",
            "WEDNESDAY": "6:15AM - 8:30PM",
            "THURSDAY": "6:15AM - 8:30PM",
            "FRIDAY": "6:15AM - 8:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            37.2879562,
            -121.78058
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.1,
                    "duration": "indefinitely",
                    "model_conf": 153.54
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.22,
                    "duration": "indefinitely",
                    "model_conf": 182.35
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.23,
                    "duration": "indefinitely",
                    "model_conf": 110.96
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "San Jose Silver Creek, CA",
            "slug": "1b0f259b-1500-4f83-85bf-21a22f0913b4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/26/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/silver-creek/5687-silver-creek-valley-rd/",
        "index": 111.325,
        "franchise-entity-name": "SILVER CREEK FITNESS INC."
    },
    "52ddf947-ea3a-43b9-979f-00a63cf8f73f": {
        "name": "Orange Theory Silver Spring, MD",
        "address": "8455 Fenton Street Silver Spring, Maryland 20910",
        "phone": "(301) 798-7433",
        "email": "studiomanager0927@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:05AM - 1:30PM",
            "SUNDAY": "7:05AM - 1:30PM"
        },
        "geolocation": [
            38.9950714,
            -77.023972
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.1,
                    "duration": "indefinitely",
                    "model_conf": 137.44
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.45,
                    "duration": "1 Months",
                    "model_conf": 164.36
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.87,
                    "duration": "indefinitely",
                    "model_conf": 96.5
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Silver Spring, MD",
            "slug": "52ddf947-ea3a-43b9-979f-00a63cf8f73f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/17/18",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/silver-spring/8455-fenton-street/",
        "index": 108.325,
        "franchise-entity-name": "MAMMOTH FITNESS MD, LLC"
    },
    "e293eac3-1441-49ca-8393-3951b89ec411": {
        "name": "Orange Theory Simi Valley, CA",
        "address": "2975 COCHRAN ST, UNIT D Simi Valley, California 93065",
        "phone": "(805) 864-2626",
        "email": "studiomanager0887@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:15AM - 10:45AM",
            "SUNDAY": "7:15AM - 10:45AM"
        },
        "geolocation": [
            34.2810593,
            -118.73848
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.86,
                    "duration": "5 Months",
                    "model_conf": 152.57
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.72,
                    "duration": "indefinitely",
                    "model_conf": 183.97
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.27,
                    "duration": "1 Months",
                    "model_conf": 111.76
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Simi Valley, CA",
            "slug": "e293eac3-1441-49ca-8393-3951b89ec411"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/23/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/simi-valley/2975-cochran-st-unit-d/",
        "index": 98.425,
        "franchise-entity-name": "CALIFORNIA FITNESSS 2, LLC"
    },
    "1d0a9239-0404-42aa-8af4-f53c42cce00f": {
        "name": "Orange Theory Simpsonville,SC",
        "address": "655 Fairview Road Suite C-2 Simpsonville, South Carolina 29680",
        "phone": "(864) 327-3755",
        "email": "studiomanager1488@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.7112999,
            -82.255302
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.34,
                    "duration": "indefinitely",
                    "model_conf": 152.74
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.17,
                    "duration": "indefinitely",
                    "model_conf": 183.97
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.24,
                    "duration": "indefinitely",
                    "model_conf": 111.11
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Simpsonville,SC",
            "slug": "1d0a9239-0404-42aa-8af4-f53c42cce00f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/12/20",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/simpsonville/655-fairview-road-suite-c-2/",
        "index": 93.4,
        "franchise-entity-name": "JM Simpsonville LLC"
    },
    "d5c28d76-bd3e-48f2-b59d-6344bef6d1e8": {
        "name": "Orange Theory Sioux Falls, SD",
        "address": "419 W85th Street Sioux Falls, South Dakota 57108",
        "phone": "(605) 274-7700",
        "email": "studiomanager1163@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 10:00AM",
            "SUNDAY": "7:30AM - 10:00AM"
        },
        "geolocation": [
            43.4750252,
            -96.728065
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 3.84,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.93,
                    "duration": "indefinitely",
                    "model_conf": 153.61
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 6.44,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.74,
                    "duration": "1 Months",
                    "model_conf": 185.63
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 10.34,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.38,
                    "duration": "indefinitely",
                    "model_conf": 111.94
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 12.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 23.34,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 32.44,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Sioux Falls, SD",
            "slug": "d5c28d76-bd3e-48f2-b59d-6344bef6d1e8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/7/18",
        "url": "https://www.orangetheory.com/en-us/locations/south-dakota/sioux-falls/419-w85th-street/",
        "index": 88.45,
        "franchise-entity-name": "SD FITNESS, LLC"
    },
    "b75d4061-4839-47b0-8508-84a7704b171d": {
        "name": "Orange Theory Montgomery, NJ",
        "address": "1320 Van Horne Road Skillman, New Jersey 08558",
        "phone": "(908) 829-0244",
        "email": "studiomanager0614@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 6:30PM",
            "TUESDAY": "5:15AM - 6:30PM",
            "WEDNESDAY": "5:15AM - 6:30PM",
            "THURSDAY": "5:15AM - 6:30PM",
            "FRIDAY": "5:15AM - 6:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            40.40425,
            -74.649622
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 75.82,
                    "duration": "indefinitely",
                    "model_conf": 149.32
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 122.85,
                    "duration": "indefinitely",
                    "model_conf": 182.1
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 176.84,
                    "duration": "4 Months",
                    "model_conf": 106.1
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Montgomery, NJ",
            "slug": "b75d4061-4839-47b0-8508-84a7704b171d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/13/23",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/skillman/1320-van-horne-road",
        "index": 98.425,
        "franchise-entity-name": "M3FITNESS, LLC"
    },
    "a1ca338c-c830-4c35-b26a-60269182e05a": {
        "name": "Orange Theory Edgebrook - Skokie, IL",
        "address": "5230 W Touhy Ave Skokie, Illinois 60077",
        "phone": "(847) 423-6060",
        "email": "studiomanager0688@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            42.012394,
            -87.758614
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.78,
                    "duration": "indefinitely",
                    "model_conf": 144.2
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 126.66,
                    "duration": "7 Months",
                    "model_conf": 168.51
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.39,
                    "duration": "indefinitely",
                    "model_conf": 105.02
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Edgebrook - Skokie, IL",
            "slug": "a1ca338c-c830-4c35-b26a-60269182e05a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/skokie/5230-w-touhy-ave/",
        "index": 103.375,
        "franchise-entity-name": "CHICAGOLAND FITNESS X, LLC"
    },
    "c809d715-1954-4137-8906-e90141a0bcb6": {
        "name": "Orange Theory Smithtown, NY",
        "address": "62 E Main Street Smithtown, New York 11787",
        "phone": "(631) 652-6505",
        "email": "studiomanager1008@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            40.8546791,
            -73.191887
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.54,
                    "duration": "indefinitely",
                    "model_conf": 148.82
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.54,
                    "duration": "1 Months",
                    "model_conf": 182.72
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.34,
                    "duration": "indefinitely",
                    "model_conf": 111.58
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Smithtown, NY",
            "slug": "c809d715-1954-4137-8906-e90141a0bcb6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/smithtown/62-e-main-street/",
        "index": 108.325,
        "franchise-entity-name": "ST Fitness LLC"
    },
    "1be81cc1-ec69-40b9-82ce-2bc9faaac889": {
        "name": "Orange Theory Smyrna, GA",
        "address": "4495 W Village Way Smyrna, Georgia 30080",
        "phone": "(678) 539-9715",
        "email": "studiomanager0833@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.8445282,
            -84.49044
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.91,
                    "duration": "indefinitely",
                    "model_conf": 142.67
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.82,
                    "duration": "indefinitely",
                    "model_conf": 175.69
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.89,
                    "duration": "indefinitely",
                    "model_conf": 105.49
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Smyrna, GA",
            "slug": "1be81cc1-ec69-40b9-82ce-2bc9faaac889"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/12/17",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/smyrna/4495-w-village-way/",
        "index": 98.425,
        "franchise-entity-name": "JK FIT LLC"
    },
    "aa426c92-56f3-4b71-9514-d17c20b15461": {
        "name": "Orange Theory San Diego - Solana Beach, CA",
        "address": "667 San Rodolfo Drive Suite #126 Solana Beach, California 92075",
        "phone": "(858) 275-6200",
        "email": "studiomanager0527@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 6:45PM",
            "TUESDAY": "6:00AM - 6:45PM",
            "WEDNESDAY": "6:00AM - 6:45PM",
            "THURSDAY": "6:00AM - 6:45PM",
            "FRIDAY": "6:00AM - 6:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            32.9937286,
            -117.25939
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.8,
                    "duration": "indefinitely",
                    "model_conf": 152.78
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.99,
                    "duration": "3 Months",
                    "model_conf": 181.67
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.85,
                    "duration": "indefinitely",
                    "model_conf": 109.65
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "San Diego - Solana Beach, CA",
            "slug": "aa426c92-56f3-4b71-9514-d17c20b15461"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/24/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/solana-beach/667-san-rodolfo-drive-suite-126/",
        "index": 103.375,
        "franchise-entity-name": "SD FIT7 LLC"
    },
    "5577a225-e2d6-4ddd-a067-8022a8e2c425": {
        "name": "Orange Theory Solon, OH",
        "address": "6025 Kruse Drive Solon, Ohio 44139",
        "phone": "(440) 399-2999",
        "email": "studiomanager0710@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:15AM - 1:15PM",
            "SUNDAY": "6:15AM - 1:15PM"
        },
        "geolocation": [
            41.3931999,
            -81.444496
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.84,
                    "duration": "6 Months",
                    "model_conf": 152.76
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.86,
                    "duration": "indefinitely",
                    "model_conf": 185.7
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.25,
                    "duration": "1 Months",
                    "model_conf": 111.61
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Solon, OH",
            "slug": "5577a225-e2d6-4ddd-a067-8022a8e2c425"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/7/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/solon/6025-kruse-drive/",
        "index": 93.4,
        "franchise-entity-name": "PRESTIGE FITNESS, LLC"
    },
    "858742be-c2ab-4659-8a24-f2403d207ef6": {
        "name": "Orange Theory Somerville, MA",
        "address": "383 Revolution Drive #1135 Somerville, Massachusetts 02145",
        "phone": "(617) 702-4426",
        "email": "studiomanager0897@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            42.3920288,
            -71.078705
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.21,
                    "duration": "indefinitely",
                    "model_conf": 147.92
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.05,
                    "duration": "2 Months",
                    "model_conf": 181.63
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.09,
                    "duration": "indefinitely",
                    "model_conf": 109.81
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Somerville, MA",
            "slug": "858742be-c2ab-4659-8a24-f2403d207ef6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/somerville/383-revolution-drive-1135/",
        "index": 103.375,
        "franchise-entity-name": "SOMERVILLE FITNESS ACQUISITION, LLC, LLC"
    },
    "e4a65d99-3b1a-4fec-80aa-810bf8389a0a": {
        "name": "Orange Theory Somerville, NJ",
        "address": "882 Route 22 Somerville, New Jersey 08876",
        "phone": "(908) 526-1400",
        "email": "studiomanager1104@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            40.5805626,
            -74.615021
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 73.9,
                    "duration": "indefinitely",
                    "model_conf": 149.91
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 125.25,
                    "duration": "indefinitely",
                    "model_conf": 180.03
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 175.55,
                    "duration": "7 Months",
                    "model_conf": 108.49
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Somerville, NJ",
            "slug": "e4a65d99-3b1a-4fec-80aa-810bf8389a0a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/16/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/somerville/882-route-22/",
        "index": 98.425,
        "franchise-entity-name": "Circle Fitness LLC"
    },
    "a3f95f7e-6d1c-4aa6-b800-f68ae53f8533": {
        "name": "Orange Theory South Elgin, IL",
        "address": "454 Redington drive South Elgin, Illinois 60177",
        "phone": "(847) 565-6300",
        "email": "studiomanager1083@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "5:30AM - 12:00PM",
            "SUNDAY": "5:30AM - 12:00PM"
        },
        "geolocation": [
            41.9809303,
            -88.342865
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.33,
                    "duration": "2 Months",
                    "model_conf": 143.89
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.3,
                    "duration": "indefinitely",
                    "model_conf": 170.79
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.63,
                    "duration": "indefinitely",
                    "model_conf": 110.23
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "South Elgin, IL",
            "slug": "a3f95f7e-6d1c-4aa6-b800-f68ae53f8533"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/7/18",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/south-elgin/454-redington-drive/",
        "index": 103.375,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-WEST, LLC"
    },
    "94d478ec-321a-4b5e-bf25-8b252d39aa54": {
        "name": "Orange Theory Southglenn , CO",
        "address": "6860 South University Blvd., Unit 140 South Glenn, Colorado 80122",
        "phone": "(303) 741-2247",
        "email": "studiomanager0042@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 6:30PM",
            "TUESDAY": "5:15AM - 6:30PM",
            "WEDNESDAY": "5:15AM - 6:30PM",
            "THURSDAY": "5:15AM - 6:30PM",
            "FRIDAY": "5:15AM - 6:30PM",
            "SATURDAY": "6:45AM - 11:30AM",
            "SUNDAY": "6:45AM - 11:30AM"
        },
        "geolocation": [
            39.5924339,
            -104.96
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.5,
                    "duration": "2 Months",
                    "model_conf": 119.94
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.27,
                    "duration": "indefinitely",
                    "model_conf": 145.33
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.67,
                    "duration": "indefinitely",
                    "model_conf": 87.7
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Southglenn , CO",
            "slug": "94d478ec-321a-4b5e-bf25-8b252d39aa54"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/25/13",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/south-glenn/6860-south-university-blvd-unit-140/",
        "index": 98.425,
        "franchise-entity-name": "OTF DENVER, LLC"
    },
    "5805fef2-9308-4a59-a1c9-58e3e2d6bc18": {
        "name": "Orange Theory South Jordan, UT",
        "address": "11516 District Drive, Suite 600 South Jordan, Utah 84095",
        "phone": "(801) 758-0500",
        "email": "studiomanager0224@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:15PM",
            "SUNDAY": "6:00AM - 12:15PM"
        },
        "geolocation": [
            40.5424156,
            -111.981
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.51,
                    "duration": "indefinitely",
                    "model_conf": 143.52
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.5,
                    "duration": "1 Months",
                    "model_conf": 180.14
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.34,
                    "duration": "indefinitely",
                    "model_conf": 105.28
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "South Jordan, UT",
            "slug": "5805fef2-9308-4a59-a1c9-58e3e2d6bc18"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/17/14",
        "url": "https://www.orangetheory.com/en-us/locations/utah/south-jordan/11516-district-drive-suite-600/",
        "index": 93.4,
        "franchise-entity-name": "HEARTZONE FITNESS I LLC"
    },
    "9351f56c-ac81-4bfa-8813-0e9d42b13305": {
        "name": "Orange Theory Ogden",
        "address": "5755 S. Harrison Blvd. Ste. B, HWY 89 South Ogden, Utah 84403",
        "phone": "(385) 260-6776",
        "email": "studiomanager1169@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:30PM",
            "SUNDAY": "7:30AM - 12:30PM"
        },
        "geolocation": [
            41.1579628,
            -111.94019
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.44,
                    "duration": "indefinitely",
                    "model_conf": 153.05
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.45,
                    "duration": "1 Months",
                    "model_conf": 184.49
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.29,
                    "duration": "indefinitely",
                    "model_conf": 112.02
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Ogden",
            "slug": "9351f56c-ac81-4bfa-8813-0e9d42b13305"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/8/19",
        "url": "https://www.orangetheory.com/en-us/locations/utah/south-ogden/5755-s-harrison-blvd-ste-b-hwy-89/",
        "index": 93.4,
        "franchise-entity-name": "HB TANNER HOLDINGS III, LLC"
    },
    "41ac866b-a073-4f18-a366-c8ce1a205243": {
        "name": "Orange Theory South Riding, VA",
        "address": "25150 Loudoun County Parkway South Riding, Virginia 20152",
        "phone": "(571) 989-7600",
        "email": "studiomanager1122@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:25PM",
            "SUNDAY": "7:00AM - 12:25PM"
        },
        "geolocation": [
            38.9207268,
            -77.517258
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.1,
                    "duration": "indefinitely",
                    "model_conf": 134.42
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.16,
                    "duration": "indefinitely",
                    "model_conf": 169.05
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.91,
                    "duration": "indefinitely",
                    "model_conf": 102.35
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "South Riding, VA",
            "slug": "41ac866b-a073-4f18-a366-c8ce1a205243"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/8/19",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/south-riding/25150-loudoun-county-parkway/",
        "index": 98.425,
        "franchise-entity-name": "JKOLA Fitness Three, LLC"
    },
    "9e8b5227-51d8-4151-8d43-6339b60df30b": {
        "name": "Orange Theory South San Francisco",
        "address": "101 Oyster Point Blvd, Suite 101 South San Francisco, California 94080",
        "phone": "(650) 826-2009",
        "email": "studiomanager1266@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:30AM - 7:30PM",
            "TUESDAY": "6:30AM - 7:30PM",
            "WEDNESDAY": "6:30AM - 7:30PM",
            "THURSDAY": "6:30AM - 7:30PM",
            "FRIDAY": "6:30AM - 7:30PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            37.6626205,
            -122.39676
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.21,
                    "duration": "3 Months",
                    "model_conf": 153.4
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 149.17,
                    "duration": "indefinitely",
                    "model_conf": 184.18
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.15,
                    "duration": "1 Months",
                    "model_conf": 110.97
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "South San Francisco",
            "slug": "9e8b5227-51d8-4151-8d43-6339b60df30b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/26/20",
        "url": "https://www.orangetheory.com/en-us/locations/california/south-san-francisco/101-oyster-point-boulevard/",
        "index": 111.325,
        "franchise-entity-name": "KOMA VENTURES VII, LLC"
    },
    "1cd3b33f-2c3e-452e-a1ad-17ca97962eea": {
        "name": "Orange Theory Southlake, TX",
        "address": "621 East Southlake Blvd., Suite 100 Southlake, Texas 76092",
        "phone": "(817) 796-9660",
        "email": "studiomanager0132@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 8:00PM",
            "TUESDAY": "4:30AM - 8:00PM",
            "WEDNESDAY": "4:30AM - 8:00PM",
            "THURSDAY": "4:30AM - 8:00PM",
            "FRIDAY": "4:30AM - 8:00PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            32.9409256,
            -97.142441
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.68,
                    "duration": "1 Months",
                    "model_conf": 143.75
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.08,
                    "duration": "indefinitely",
                    "model_conf": 182.16
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.69,
                    "duration": "indefinitely",
                    "model_conf": 111.32
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Southlake, TX",
            "slug": "1cd3b33f-2c3e-452e-a1ad-17ca97962eea"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/8/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/southlake/621-east-southlake-blvd-suite-100/",
        "index": 93.4,
        "franchise-entity-name": "OTF SOUTHLAKE, LLC"
    },
    "522db9b4-48da-45f3-a472-6f5ee003c82a": {
        "name": "Orange Theory Spartanburg",
        "address": "1915 East Main St. STE 3 Spartanburg, South Carolina 29307",
        "phone": "(864) 326-3999",
        "email": "studiomanager0884@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.9686356,
            -81.8862
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.77,
                    "duration": "1 Months",
                    "model_conf": 153.38
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.29,
                    "duration": "indefinitely",
                    "model_conf": 184.99
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.73,
                    "duration": "indefinitely",
                    "model_conf": 111.83
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Spartanburg",
            "slug": "522db9b4-48da-45f3-a472-6f5ee003c82a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/29/18",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/spartanburg/1915-e-main-street/",
        "index": 93.4,
        "franchise-entity-name": "JM SPARTANBURG FITNESS LLC"
    },
    "d4a5b1f8-a7e1-4340-9f3f-135141d0de03": {
        "name": "Orange Theory Spokane South Hill, WA",
        "address": "2620 East 29th Avenue Spokane, Washington 99223",
        "phone": "(509) 774-0636",
        "email": "studiomanager0484@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            47.6281509,
            -117.37334
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 6.21,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.22,
                    "duration": "4 Months",
                    "model_conf": 152.07
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 9.81,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.15,
                    "duration": "indefinitely",
                    "model_conf": 185.66
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 15.21,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.09,
                    "duration": "2 Months",
                    "model_conf": 111.63
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 17.91,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 32.31,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 44.91,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Spokane South Hill, WA",
            "slug": "d4a5b1f8-a7e1-4340-9f3f-135141d0de03"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/18/15",
        "url": "https://www.orangetheory.com/en-us/locations/washington/spokane/2620-east-29th-avenue/",
        "index": 93.4,
        "franchise-entity-name": "WAFIT SOUTH LLC"
    },
    "e7a446a9-3e19-4e88-bfd3-648a18594a96": {
        "name": "Orange Theory Spokane Northside, WA",
        "address": "410 E Holland Ave, Ste. E Spokane, Washington 99218",
        "phone": "(509) 904-1440",
        "email": "studiomanager0485@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "6:45AM - 11:30AM",
            "SUNDAY": "6:45AM - 11:30AM"
        },
        "geolocation": [
            47.7434959,
            -117.40521
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 6.21,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.31,
                    "duration": "3 Months",
                    "model_conf": 152.31
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 9.81,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.97,
                    "duration": "indefinitely",
                    "model_conf": 185.68
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 15.21,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.09,
                    "duration": "2 Months",
                    "model_conf": 111.58
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 17.91,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 32.31,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 44.91,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Spokane Northside, WA",
            "slug": "e7a446a9-3e19-4e88-bfd3-648a18594a96"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/26/18",
        "url": "https://www.orangetheory.com/en-us/locations/washington/north-spokane/410-e-holland-ave-ste-e/",
        "index": 93.4,
        "franchise-entity-name": "WAFIT NORTH LLC"
    },
    "9e9dc2e4-e518-4ce6-9d31-8ef6c7900ed1": {
        "name": "Orange Theory Spring Hill,TN",
        "address": "2047 Wall Street Spring Hill, Tennessee 37174",
        "phone": "(615) 266-3580",
        "email": "studiomanager1171@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 12:30PM",
            "TUESDAY": "5:00AM - 12:30PM",
            "WEDNESDAY": "5:00AM - 12:30PM",
            "THURSDAY": "5:00AM - 12:30PM",
            "FRIDAY": "5:00AM - 12:30PM",
            "SATURDAY": "7:30AM - 12:30PM",
            "SUNDAY": "7:30AM - 12:30PM"
        },
        "geolocation": [
            35.7721558,
            -86.915276
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.54,
                    "duration": "2 Months",
                    "model_conf": 153.56
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.93,
                    "duration": "indefinitely",
                    "model_conf": 185.73
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.69,
                    "duration": "indefinitely",
                    "model_conf": 112.01
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Spring Hill,TN",
            "slug": "9e9dc2e4-e518-4ce6-9d31-8ef6c7900ed1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/7/20",
        "url": "https://www.orangetheory.com/en-us/locations/tennessee/spring-hill/2047-wall-street/",
        "index": 93.4,
        "franchise-entity-name": "MSL FITNESS LLC"
    },
    "205b961c-6b34-44db-b33f-ecdc02b5f962": {
        "name": "Orange Theory Spring, TX",
        "address": "6600 Spring Stuebner Rd Suite B9 Spring, Texas 77389",
        "phone": "(281) 907-9701",
        "email": "studiomanager0745@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            30.0848866,
            -95.518166
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.25,
                    "duration": "3 Months",
                    "model_conf": 145.81
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.83,
                    "duration": "indefinitely",
                    "model_conf": 180.65
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.16,
                    "duration": "1 Months",
                    "model_conf": 110.75
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Spring, TX",
            "slug": "205b961c-6b34-44db-b33f-ecdc02b5f962"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/spring/6600-spring-stuebner-rd-suite-b9/",
        "index": 98.425,
        "franchise-entity-name": "SPRING COACHING LLC"
    },
    "6035a583-0187-4f38-bbb5-6e2b47ca5f71": {
        "name": "Orange Theory Springfield, IL",
        "address": "2450 W. Wabash Avenue Springfield, Illinois 62704",
        "phone": "(217) 953-0054",
        "email": "studiomanager0805@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            39.7631149,
            -89.702591
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.2,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.56,
                    "duration": "indefinitely",
                    "model_conf": 185.56
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.12,
                    "duration": "indefinitely",
                    "model_conf": 112.0
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Springfield, IL",
            "slug": "6035a583-0187-4f38-bbb5-6e2b47ca5f71"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/11/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/springfield/2450-w-wabash-avenue/",
        "index": 93.4,
        "franchise-entity-name": "TMB FITNESS LLC"
    },
    "a1a78956-7a6e-4ff7-99b4-52d30299cd6f": {
        "name": "Orange Theory Springfield-West, MO",
        "address": "2100 W Republic Rd Springfield, Missouri 65807",
        "phone": "(417) 771-3628",
        "email": "studiomanager0785@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 12:00PM",
            "SUNDAY": "8:00AM - 12:00PM"
        },
        "geolocation": [
            37.137828,
            -93.322022
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.27,
                    "duration": "4 Months",
                    "model_conf": 153.71
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.13,
                    "duration": "indefinitely",
                    "model_conf": 172.95
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.18,
                    "duration": "2 Months",
                    "model_conf": 108.81
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Springfield-West, MO",
            "slug": "a1a78956-7a6e-4ff7-99b4-52d30299cd6f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/27/23",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/springfield/2100-west-republic-road/",
        "index": 88.45,
        "franchise-entity-name": "ZONE FITNESS 2 LLC"
    },
    "ee6228f2-820e-434a-9382-e20d0a9b2e17": {
        "name": "Orange Theory Springfield - East, MO",
        "address": "2862 S. Glenstone Springfield, Missouri 65804",
        "phone": "(417) 633-7133",
        "email": "studiomanager0784@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            37.1608505,
            -93.261734
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.83,
                    "duration": "1 Months",
                    "model_conf": 153.45
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.83,
                    "duration": "indefinitely",
                    "model_conf": 177.77
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.48,
                    "duration": "1 Months",
                    "model_conf": 109.9
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Springfield - East, MO",
            "slug": "ee6228f2-820e-434a-9382-e20d0a9b2e17"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/15/17",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/springfield/2862-s-glenstone/",
        "index": 88.45,
        "franchise-entity-name": "ZONE FITNESS LLC"
    },
    "58852e03-8d03-4837-b24f-9b01b4216257": {
        "name": "Orange Theory Springfield, PA",
        "address": "131 South State Road Springfield, Pennsylvania 19064",
        "phone": "(215) 618-2676",
        "email": "studiomanager0772@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            39.9380722,
            -75.348892
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.69,
                    "duration": "indefinitely",
                    "model_conf": 150.63
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.32,
                    "duration": "2 Months",
                    "model_conf": 183.31
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.55,
                    "duration": "indefinitely",
                    "model_conf": 110.27
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Springfield, PA",
            "slug": "58852e03-8d03-4837-b24f-9b01b4216257"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/10/17",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/springfield/131-s-state-road/",
        "index": 103.375,
        "franchise-entity-name": "P &amp; L FITNESS 2, LLC"
    },
    "79ce6b68-4632-4c62-ad08-867c27aab9c3": {
        "name": "Orange Theory West Springfield",
        "address": "7271 Commerce St Springfield, Virginia 22150",
        "phone": "(571) 380-3900",
        "email": "studiomanager1283@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            38.780056,
            -77.191017
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.58,
                    "duration": "indefinitely",
                    "model_conf": 136.76
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.33,
                    "duration": "5 Months",
                    "model_conf": 159.91
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.28,
                    "duration": "indefinitely",
                    "model_conf": 96.93
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West Springfield",
            "slug": "79ce6b68-4632-4c62-ad08-867c27aab9c3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/6/20",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/springfield/7269-commerce-st-suite-33b/",
        "index": 98.425,
        "franchise-entity-name": "BAMWESTSPRINGFIELD, LLC"
    },
    "eb6def6c-b74d-40d0-a270-e6fece777f80": {
        "name": "Orange Theory World Golf Village, FL",
        "address": "150 Village Commons Dr St. Augustine, Florida 32092",
        "phone": "(904) 930-4884",
        "email": "studiomanager1286@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 11:00AM",
            "SUNDAY": "7:15AM - 11:00AM"
        },
        "geolocation": [
            29.964028,
            -81.487805
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.14,
                    "duration": "indefinitely",
                    "model_conf": 153.34
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.02,
                    "duration": "indefinitely",
                    "model_conf": 185.53
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.29,
                    "duration": "indefinitely",
                    "model_conf": 111.92
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "World Golf Village, FL",
            "slug": "eb6def6c-b74d-40d0-a270-e6fece777f80"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/12/23",
        "url": "https://www.orangetheory.com/en-us/locations/florida/st-augustine/150-village-commons-drive-suite-104/",
        "index": 93.4,
        "franchise-entity-name": "F10 ENTERPRISES, LLC"
    },
    "807f9fe4-de12-48b7-a3b3-fb9bceb55c7b": {
        "name": "Orange Theory St. Augustine, FL",
        "address": "833 South Ponce de Leon Boulevard, Suite 1 St. Augustine, Florida 32084",
        "phone": "(904) 647-8088",
        "email": "studiomanager0841@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            29.8813305,
            -81.325096
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.35,
                    "duration": "indefinitely",
                    "model_conf": 153.63
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.83,
                    "duration": "indefinitely",
                    "model_conf": 185.56
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.19,
                    "duration": "indefinitely",
                    "model_conf": 111.99
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "St. Augustine, FL",
            "slug": "807f9fe4-de12-48b7-a3b3-fb9bceb55c7b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/st-augustine/833-south-ponce-de-leon-boulevard/",
        "index": 93.4,
        "franchise-entity-name": "F11 ENTERPRISES LLC"
    },
    "62e23d6b-0b63-47ac-9081-627d56d01527": {
        "name": "Orange Theory St. Charles, IL",
        "address": "124 Kirk Road St. Charles, Illinois 60174",
        "phone": "(331) 248-6050",
        "email": "studiomanager0893@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:15AM",
            "SUNDAY": "7:00AM - 11:15AM"
        },
        "geolocation": [
            41.9202309,
            -88.275642
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.83,
                    "duration": "indefinitely",
                    "model_conf": 148.73
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.19,
                    "duration": "5 Months",
                    "model_conf": 177.86
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.48,
                    "duration": "indefinitely",
                    "model_conf": 110.75
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "St. Charles, IL",
            "slug": "62e23d6b-0b63-47ac-9081-627d56d01527"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/24/17",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/st-charles/124-kirk-road/",
        "index": 103.375,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-WEST, LLC"
    },
    "40c21565-ddb6-43fc-870c-aec1dcabb138": {
        "name": "Orange Theory St Charles, MO",
        "address": "1650 Beale Street, Suite 170 St. Charles, Missouri 63303",
        "phone": "(314) 250-1037",
        "email": "studiomanager0737@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 6:45PM",
            "TUESDAY": "4:55AM - 6:45PM",
            "WEDNESDAY": "4:55AM - 6:45PM",
            "THURSDAY": "4:55AM - 6:45PM",
            "FRIDAY": "4:55AM - 6:45PM",
            "SATURDAY": "7:00AM - 10:30AM",
            "SUNDAY": "7:00AM - 10:30AM"
        },
        "geolocation": [
            38.7650452,
            -90.493729
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.94,
                    "duration": "indefinitely",
                    "model_conf": 145.5
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.86,
                    "duration": "indefinitely",
                    "model_conf": 175.1
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.71,
                    "duration": "indefinitely",
                    "model_conf": 105.18
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "St Charles, MO",
            "slug": "40c21565-ddb6-43fc-870c-aec1dcabb138"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/st-charles/1650-beale-street-suite-170/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS MISSOURI, LLC"
    },
    "4887751d-bc39-43c5-b522-1952565ca5ec": {
        "name": "Orange Theory St. George, UT",
        "address": "599 South Mall Dr. Space K-7 St. Geroge, Utah 84790",
        "phone": "(435) 767-0542",
        "email": "studiomanager0948@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 10:30AM",
            "SUNDAY": "6:30AM - 10:30AM"
        },
        "geolocation": [
            37.1009522,
            -113.53827
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.75,
                    "duration": "1 Months",
                    "model_conf": 153.8
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.03,
                    "duration": "indefinitely",
                    "model_conf": 184.92
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.58,
                    "duration": "indefinitely",
                    "model_conf": 111.9
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "St. George, UT",
            "slug": "4887751d-bc39-43c5-b522-1952565ca5ec"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/utah/st-geroge/599-south-mall-dr-space-k-7/",
        "index": 93.4,
        "franchise-entity-name": "DJD FITNESS LLC"
    },
    "bacfed2f-c6f5-4cd3-8698-ec81d19cb774": {
        "name": "Orange Theory N. St. Johns, FL",
        "address": "2851 CR 210 W #114 St. Johns, Florida 32259",
        "phone": "(904) 671-7071",
        "email": "studiomanager0635@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            30.05772,
            -81.53213
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.43,
                    "duration": "indefinitely",
                    "model_conf": 151.53
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.39,
                    "duration": "indefinitely",
                    "model_conf": 184.1
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.86,
                    "duration": "indefinitely",
                    "model_conf": 110.44
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "N. St. Johns, FL",
            "slug": "bacfed2f-c6f5-4cd3-8698-ec81d19cb774"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/7/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/st-johns/2851-cr-210/",
        "index": 93.4,
        "franchise-entity-name": "F6 ENTERPRISES LLC"
    },
    "770a03cb-5447-4e7d-b30e-2373a01a5d97": {
        "name": "Orange Theory St. Louis Park, MN",
        "address": "5602 Cedar Lake Rd Suite #025 St. Louis Park, Minnesota 55416",
        "phone": "(952) 222-3984",
        "email": "studiomanager0977@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:15AM - 2:00PM",
            "SUNDAY": "6:15AM - 2:00PM"
        },
        "geolocation": [
            44.964016,
            -93.350204
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.44,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.5,
                    "duration": "3 Months",
                    "model_conf": 132.25
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.45,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.6,
                    "duration": "1 Months",
                    "model_conf": 168.99
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.96,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.84,
                    "duration": "indefinitely",
                    "model_conf": 100.73
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "St. Louis Park, MN",
            "slug": "770a03cb-5447-4e7d-b30e-2373a01a5d97"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/st-louis-park/5600-cedar-lake-road-suite-025/",
        "index": 88.45,
        "franchise-entity-name": "ST. LOUIS PARK FITNESS, LLC"
    },
    "0a5acbb3-cfd0-4ebf-b5f4-c4fcddd6680a": {
        "name": "Orange Theory Creve Coeur",
        "address": "11457 Olive Blvd. St. Louis, Missouri 63141",
        "phone": "(314) 685-8846",
        "email": "studiomanager1035@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 7:45PM",
            "TUESDAY": "4:55AM - 7:45PM",
            "WEDNESDAY": "4:55AM - 7:45PM",
            "THURSDAY": "4:55AM - 7:45PM",
            "FRIDAY": "4:55AM - 7:45PM",
            "SATURDAY": "6:30AM - 10:30AM",
            "SUNDAY": "6:30AM - 10:30AM"
        },
        "geolocation": [
            38.6731529,
            -90.434242
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.31,
                    "duration": "3 Months",
                    "model_conf": 133.22
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.68,
                    "duration": "indefinitely",
                    "model_conf": 157.41
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.68,
                    "duration": "indefinitely",
                    "model_conf": 94.68
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Creve Coeur",
            "slug": "0a5acbb3-cfd0-4ebf-b5f4-c4fcddd6680a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/30/18",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/st-louis/11457-olive-blvd/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS MISSOURI, LLC"
    },
    "efe9959d-f5db-491e-902c-2d76849ab7a4": {
        "name": "Orange Theory Concord, MO",
        "address": "12434 Tesson Ferry Road St. Louis, Missouri 63128",
        "phone": "(314) 649-5050",
        "email": "studiomanager0723@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            38.5098534,
            -90.375038
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.61,
                    "duration": "indefinitely",
                    "model_conf": 146.84
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.53,
                    "duration": "1 Months",
                    "model_conf": 178.29
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.84,
                    "duration": "indefinitely",
                    "model_conf": 106.94
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Concord, MO",
            "slug": "efe9959d-f5db-491e-902c-2d76849ab7a4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/10/17",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/st-louis/12434-tesson-ferry-road/",
        "index": 93.4,
        "franchise-entity-name": "CONCORD FITNESS MO, LLC."
    },
    "b8e650d6-61f4-4d7d-b16c-bace5b8b8f40": {
        "name": "Orange Theory Central West End, MO",
        "address": "4653 Lindell Blvd. St. Louis, Missouri 63108",
        "phone": "(314) 590-3702",
        "email": "studiomanager0451@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:50PM",
            "TUESDAY": "4:55AM - 8:50PM",
            "WEDNESDAY": "4:55AM - 8:50PM",
            "THURSDAY": "4:55AM - 8:50PM",
            "FRIDAY": "4:55AM - 8:50PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            38.6431503,
            -90.261269
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.93,
                    "duration": "indefinitely",
                    "model_conf": 148.59
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.11,
                    "duration": "indefinitely",
                    "model_conf": 180.99
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.53,
                    "duration": "1 Months",
                    "model_conf": 108.54
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Central West End, MO",
            "slug": "b8e650d6-61f4-4d7d-b16c-bace5b8b8f40"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/10/17",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/clayton/4653-lindell-blvd/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS MISSOURI, LLC"
    },
    "673bd193-220d-4242-add1-a2fc897e7a55": {
        "name": "Orange Theory Oakville,MO",
        "address": "5670 Telegraph Rd St. Louis, Missouri 63129",
        "phone": "(314) 380-0888",
        "email": "studiomanager1081@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 7:30PM",
            "TUESDAY": "4:45AM - 7:30PM",
            "WEDNESDAY": "4:45AM - 7:30PM",
            "THURSDAY": "4:45AM - 7:30PM",
            "FRIDAY": "4:45AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            38.4661789,
            -90.303711
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.41,
                    "duration": "indefinitely",
                    "model_conf": 152.05
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.18,
                    "duration": "6 Months",
                    "model_conf": 183.64
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.68,
                    "duration": "indefinitely",
                    "model_conf": 110.75
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Oakville,MO",
            "slug": "673bd193-220d-4242-add1-a2fc897e7a55"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/20",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/st-louis/5670-telegraph-rd/",
        "index": 93.4,
        "franchise-entity-name": "ARNOLD FITNESS MO, LLC"
    },
    "6e71d423-c9d9-4c7e-a38b-95c1eafaf434": {
        "name": "Orange Theory Grand Ave-St.Paul",
        "address": "850 Grand Avenue St. Paul, Minnesota 55105",
        "phone": "(651) 364-6888",
        "email": "studiomanager0974@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 1:30PM",
            "SUNDAY": "6:30AM - 1:30PM"
        },
        "geolocation": [
            44.9396324,
            -93.136024
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.65,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 60.34,
                    "duration": "indefinitely",
                    "model_conf": 144.78
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.8,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 96.98,
                    "duration": "7 Months",
                    "model_conf": 178.22
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 12.52,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.29,
                    "duration": "indefinitely",
                    "model_conf": 106.36
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Grand Ave-St.Paul",
            "slug": "6e71d423-c9d9-4c7e-a38b-95c1eafaf434"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/24/20",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/st-paul/805-grand-avenue/",
        "index": 88.45,
        "franchise-entity-name": "BKM FITNESS L.L.C."
    },
    "7e65f632-5235-48e2-8c8f-afe4549ac082": {
        "name": "Orange Theory Cottleville, MO",
        "address": "6201- 6203 Mid Rivers Mall Dr St. Peters, Missouri 63304",
        "phone": "(636) 628-2457",
        "email": "studiomanager0740@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            38.7405014,
            -90.636787
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.0,
                    "duration": "indefinitely",
                    "model_conf": 79.42
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.6,
                    "duration": "1 Months",
                    "model_conf": 139.7
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.73,
                    "duration": "indefinitely",
                    "model_conf": 59.64
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Cottleville, MO",
            "slug": "7e65f632-5235-48e2-8c8f-afe4549ac082"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/missouri/st-peters/6201-6203-mid-rivers-mall-dr/",
        "index": 93.4,
        "franchise-entity-name": "L5 FITNESS MISSOURI, LLC"
    },
    "630b95cb-6b60-4a93-b1ef-44d2a94618ee": {
        "name": "Orange Theory St. Petersburg - Downtown, FL",
        "address": "640 1st Ave S, St. Petersburg - Downtown, Florida 33701",
        "phone": "(727) 513-5646",
        "email": "studiomanager0890@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            27.7700291,
            -82.642494
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.2,
                    "duration": "indefinitely",
                    "model_conf": 152.94
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.59,
                    "duration": "1 Months",
                    "model_conf": 181.44
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.06,
                    "duration": "indefinitely",
                    "model_conf": 111.71
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "St. Petersburg - Downtown, FL",
            "slug": "630b95cb-6b60-4a93-b1ef-44d2a94618ee"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/st-petersburg-downtown/640-1st-ave-s/",
        "index": 98.425,
        "franchise-entity-name": "DOWNTOWN ST. PETE COACHING, LLC"
    },
    "f95d541e-dd8d-4c82-94de-c2215a0d50f1": {
        "name": "Orange Theory Tyrone, FL",
        "address": "1530 66th St. North St. Petersburg, Florida 33710",
        "phone": "(727) 235-6998",
        "email": "studiomanager0078@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            27.7857437,
            -82.728363
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.9,
                    "duration": "indefinitely",
                    "model_conf": 152.55
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.86,
                    "duration": "3 Months",
                    "model_conf": 176.67
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.39,
                    "duration": "indefinitely",
                    "model_conf": 111.53
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Tyrone, FL",
            "slug": "f95d541e-dd8d-4c82-94de-c2215a0d50f1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/12/13",
        "url": "https://www.orangetheory.com/en-us/locations/florida/st-petersburg/1530-66th-st-north/",
        "index": 98.425,
        "franchise-entity-name": "TYRONE COACHING, LLC"
    },
    "402988a9-0482-4169-a137-755d1090bf67": {
        "name": "Orange Theory St. Petersburg, FL",
        "address": "5032 4th Street, North St. Petersburg, Florida 33703",
        "phone": "(727) 897-5566",
        "email": "studiomanager0013@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 2:00PM",
            "SUNDAY": "6:30AM - 2:00PM"
        },
        "geolocation": [
            27.8183823,
            -82.639168
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.84,
                    "duration": "indefinitely",
                    "model_conf": 152.59
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.78,
                    "duration": "3 Months",
                    "model_conf": 177.4
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.35,
                    "duration": "indefinitely",
                    "model_conf": 111.36
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "St. Petersburg, FL",
            "slug": "402988a9-0482-4169-a137-755d1090bf67"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/14/11",
        "url": "https://www.orangetheory.com/en-us/locations/florida/st-petersburg/5032-4th-street-north/",
        "index": 98.425,
        "franchise-entity-name": "4th Street Coaching, LLC"
    },
    "9ce9ff8b-c227-4da3-95dc-cab2936b576d": {
        "name": "Orange Theory Stafford",
        "address": "308 Worth Ave Stafford, Virginia 22556",
        "phone": "(540) 602-5800",
        "email": "studiomanager0763@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            38.4758415,
            -77.418694
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.61,
                    "duration": "1 Months",
                    "model_conf": 152.99
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.23,
                    "duration": "indefinitely",
                    "model_conf": 180.94
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.6,
                    "duration": "indefinitely",
                    "model_conf": 111.49
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Stafford",
            "slug": "9ce9ff8b-c227-4da3-95dc-cab2936b576d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/30/18",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/stafford/308-worth-ave/",
        "index": 98.425,
        "franchise-entity-name": "BAMSTAFFORD, LLC"
    },
    "413f44a1-db69-4362-a86a-f857de04ba96": {
        "name": "Orange Theory Stamford",
        "address": "1101 High Ridge Road Stamford, Connecticut 06905",
        "phone": "(203) 883-9518",
        "email": "studiomanager0064@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            41.1082573,
            -73.546822
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.12,
                    "duration": "9 Months",
                    "model_conf": 131.37
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 122.17,
                    "duration": "indefinitely",
                    "model_conf": 158.75
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.3,
                    "duration": "1 Months",
                    "model_conf": 96.42
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Stamford",
            "slug": "413f44a1-db69-4362-a86a-f857de04ba96"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/21/19",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/stamford/1101-high-ridge-road/",
        "index": 98.425,
        "franchise-entity-name": "VO2 MAX STAMFORD, LLC"
    },
    "5baa750a-7b1f-4880-9492-4f2969e8d0a0": {
        "name": "Orange Theory Stamford-West Side, CT",
        "address": "2000 W Main St, Ste 460 Stamford, Connecticut 06902",
        "phone": "(203) 783-4300",
        "email": "studiomanager1052@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "8:00AM - 12:30PM",
            "SUNDAY": "8:00AM - 12:30PM"
        },
        "geolocation": [
            41.048492,
            -73.563816
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 75.88,
                    "duration": "indefinitely",
                    "model_conf": 107.24
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 122.74,
                    "duration": "indefinitely",
                    "model_conf": 128.95
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 176.67,
                    "duration": "5 Months",
                    "model_conf": 82.43
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Stamford-West Side, CT",
            "slug": "5baa750a-7b1f-4880-9492-4f2969e8d0a0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/8/22",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/stamford/2000-w-main-st-ste-460/",
        "index": 98.425,
        "franchise-entity-name": "VO2 MAX ONE, LLC"
    },
    "6b389684-58b7-4d1a-a965-50cecbb4b8ba": {
        "name": "Orange Theory Starkville, MS",
        "address": "401 University Dr Unit E Starkville, Mississippi 39759",
        "phone": "(662) 268-2310",
        "email": "studiomanager1130@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:30AM - 3:15PM",
            "SUNDAY": "7:30AM - 3:15PM"
        },
        "geolocation": [
            33.4639854,
            -88.810196
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 152.95
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.69
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 112.01
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Starkville, MS",
            "slug": "6b389684-58b7-4d1a-a965-50cecbb4b8ba"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/31/18",
        "url": "https://www.orangetheory.com/en-us/locations/mississippi/starkville/401-university-dr-unit-e/",
        "index": 93.4,
        "franchise-entity-name": "MISSFIT, LLC"
    },
    "b3d29976-ac80-4acc-99c7-6c21788b795d": {
        "name": "Orange Theory State College, PA",
        "address": "106 South Atherton Street State College, Pennsylvania 16801",
        "phone": "(814) 625-2026",
        "email": "studiomanager1074@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:00AM - 12:45PM",
            "SUNDAY": "6:00AM - 12:45PM"
        },
        "geolocation": [
            40.7916794,
            -77.865242
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.84
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.75
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.28
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "State College, PA",
            "slug": "b3d29976-ac80-4acc-99c7-6c21788b795d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/23/18",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/state-college/106-south-atherton-street/",
        "index": 93.4,
        "franchise-entity-name": "MAB2 MANAGEMENT LLC"
    },
    "8490a327-f122-423c-a2db-bf04ca33432c": {
        "name": "Orange Theory Staten Island - Richmond, NY",
        "address": "2409 Richmond Avenue Staten Island, New York 10314",
        "phone": "(347) 442-7020",
        "email": "studiomanager0415@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:45AM - 9:00PM",
            "TUESDAY": "4:45AM - 9:00PM",
            "WEDNESDAY": "4:45AM - 9:00PM",
            "THURSDAY": "4:45AM - 9:00PM",
            "FRIDAY": "4:45AM - 9:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.5887566,
            -74.166725
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 4.46,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.95,
                    "duration": "indefinitely",
                    "model_conf": 150.11
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 6.26,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.04,
                    "duration": "indefinitely",
                    "model_conf": 180.3
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 8.96,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.18,
                    "duration": "indefinitely",
                    "model_conf": 109.46
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 11.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 20.25,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 26.96,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Staten Island - Richmond, NY",
            "slug": "8490a327-f122-423c-a2db-bf04ca33432c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/15/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/staten-island/2409-richmond-avenue/",
        "index": 108.325,
        "franchise-entity-name": "PFM SI-1, LLC"
    },
    "654ad274-7378-4d0a-a7ea-393c79d51c9c": {
        "name": "Orange Theory Steele Creek, NC",
        "address": "14154 Steele Creek Road Steele Creek, North Carolina 28273",
        "phone": "(704) 444-0122",
        "email": "studiomanager1201@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            35.1001358,
            -80.989304
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.57,
                    "duration": "indefinitely",
                    "model_conf": 139.93
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.58,
                    "duration": "1 Months",
                    "model_conf": 167.44
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.15,
                    "duration": "indefinitely",
                    "model_conf": 103.6
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Steele Creek, NC",
            "slug": "654ad274-7378-4d0a-a7ea-393c79d51c9c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/steele-creek/14154-steele-creek-road/",
        "index": 98.425,
        "franchise-entity-name": "EMPIRE STEELE CREEK LLC"
    },
    "abdd4594-f6a8-4485-9189-ae74edc878a5": {
        "name": "Orange Theory Sterling, VA",
        "address": "20806 Edds Lane Sterling, Virginia 20165",
        "phone": "(571) 257-0077",
        "email": "studiomanager0267@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "7:15AM - 11:45AM",
            "SUNDAY": "7:15AM - 11:45AM"
        },
        "geolocation": [
            39.040184,
            -77.412193
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.79,
                    "duration": "indefinitely",
                    "model_conf": 144.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.93,
                    "duration": "3 Months",
                    "model_conf": 174.53
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.48,
                    "duration": "indefinitely",
                    "model_conf": 106.53
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sterling, VA",
            "slug": "abdd4594-f6a8-4485-9189-ae74edc878a5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/6/15",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/sterling/20806-edds-lane/",
        "index": 98.425,
        "franchise-entity-name": "JKOLA FITNESS TWO, LLC"
    },
    "be6977f4-2e45-4a00-b951-8b7f07cb15fd": {
        "name": "Orange Theory Stillwater, MN",
        "address": "2030 Market Dr Stillwater, Minnesota 55082",
        "phone": "(651) 294-6300",
        "email": "studiomanager1554@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 2:00PM",
            "SUNDAY": "7:30AM - 2:00PM"
        },
        "geolocation": [
            45.037543,
            -92.841815
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.35,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 59.89,
                    "duration": "indefinitely",
                    "model_conf": 150.45
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.3,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.68,
                    "duration": "1 Months",
                    "model_conf": 182.57
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.73,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 160.0,
                    "duration": "indefinitely",
                    "model_conf": 108.5
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.68,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 26.48,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 36.8,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Stillwater, MN",
            "slug": "be6977f4-2e45-4a00-b951-8b7f07cb15fd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/24/23",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/stillwater/2030-market-dr/",
        "index": 88.45,
        "franchise-entity-name": "BLAINE SHOREVIEW FITNESS, LLC"
    },
    "2e43cfaa-7ae6-4eb9-be35-e09f15c576a2": {
        "name": "Orange Theory Stillwater",
        "address": "705 N. Main Street Stillwater, Oklahoma 74075",
        "phone": "(405) 296-4631",
        "email": "studiomanager1238@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:45AM - 2:15PM",
            "SUNDAY": "6:45AM - 2:15PM"
        },
        "geolocation": [
            36.1300354,
            -97.058609
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 6.42,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 10.15,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.8
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 15.73,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.97
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 18.53,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 33.42,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 46.46,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Stillwater",
            "slug": "2e43cfaa-7ae6-4eb9-be35-e09f15c576a2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/6/19",
        "url": "https://www.orangetheory.com/en-us/locations/oklahoma/stillwater/705-n-main-street/",
        "index": 93.4,
        "franchise-entity-name": "CHOPFIT II LLC"
    },
    "ce299a8e-fbf0-413f-8041-e12a37fa063c": {
        "name": "Orange Theory Stockton",
        "address": "6575 Pacific Ave Stockton, California 95207",
        "phone": "(209) 888-1342",
        "email": "studiomanager1226@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:10PM",
            "TUESDAY": "5:00AM - 8:10PM",
            "WEDNESDAY": "5:00AM - 8:10PM",
            "THURSDAY": "5:00AM - 8:10PM",
            "FRIDAY": "5:00AM - 8:10PM",
            "SATURDAY": "6:00AM - 11:45AM",
            "SUNDAY": "6:00AM - 11:45AM"
        },
        "geolocation": [
            38.0099144,
            -121.32024
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.42,
                    "duration": "indefinitely",
                    "model_conf": 151.91
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.2,
                    "duration": "indefinitely",
                    "model_conf": 185.78
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.38,
                    "duration": "indefinitely",
                    "model_conf": 111.18
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Stockton",
            "slug": "ce299a8e-fbf0-413f-8041-e12a37fa063c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/5/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/stockton/6575-pacific-ave/",
        "index": 93.4,
        "franchise-entity-name": "FITTYLER, LLC"
    },
    "873d4e10-e548-4ad4-b68c-85e4566cc003": {
        "name": "Orange Theory Stone Mountain, GA",
        "address": "1227 Rockbridge Road Southwest suite 204 Stone Mountain, Georgia 30087",
        "phone": "(770) 604-1812",
        "email": "studiomanager0494@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "8:00AM - 12:30PM",
            "SUNDAY": "8:00AM - 12:30PM"
        },
        "geolocation": [
            33.8411675,
            -84.132957
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.93,
                    "duration": "indefinitely",
                    "model_conf": 151.59
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.38,
                    "duration": "indefinitely",
                    "model_conf": 183.61
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.32,
                    "duration": "indefinitely",
                    "model_conf": 110.29
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Stone Mountain, GA",
            "slug": "873d4e10-e548-4ad4-b68c-85e4566cc003"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/19/16",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/stone-mountain/1227-rockbridge-road-sw/",
        "index": 98.425,
        "franchise-entity-name": "HBB GROUP LLC"
    },
    "e9bf90ee-68fd-495b-83b7-600151f42490": {
        "name": "Orange Theory Stony Brook, NY",
        "address": "2306 Nesconset Highway Stony Brook, New York 11790",
        "phone": "(631) 246-1040",
        "email": "studiomanager0060@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:45AM - 12:40PM",
            "SUNDAY": "6:45AM - 12:40PM"
        },
        "geolocation": [
            40.8750305,
            -73.12133
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.35,
                    "duration": "indefinitely",
                    "model_conf": 144.69
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.01,
                    "duration": "2 Months",
                    "model_conf": 180.14
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.22,
                    "duration": "indefinitely",
                    "model_conf": 111.39
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Stony Brook, NY",
            "slug": "e9bf90ee-68fd-495b-83b7-600151f42490"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/stony-brook/2306-nesconset-highway/",
        "index": 108.325,
        "franchise-entity-name": "STONY FITNESS LLC"
    },
    "cf62568f-1998-4250-9e86-a012fb8ec51e": {
        "name": "Orange Theory Strongsville, OH",
        "address": "18105 Southpark Center Strongsville, Ohio 44136",
        "phone": "(440) 249-0070",
        "email": "studiomanager0705@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 1:15PM",
            "SUNDAY": "7:30AM - 1:15PM"
        },
        "geolocation": [
            41.3102722,
            -81.823761
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.21,
                    "duration": "10 Months",
                    "model_conf": 151.72
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.28,
                    "duration": "indefinitely",
                    "model_conf": 185.47
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.17,
                    "duration": "1 Months",
                    "model_conf": 110.01
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Strongsville, OH",
            "slug": "cf62568f-1998-4250-9e86-a012fb8ec51e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/23/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/strongsville/18105-southpark-center/",
        "index": 93.4,
        "franchise-entity-name": "REVIVE HEALTH, LLC"
    },
    "441813f9-d2b4-4e4d-aa67-101d9e8d87a3": {
        "name": "Orange Theory Stuart, FL",
        "address": "2303 SE Federal Hwy Stuart, Florida 34994",
        "phone": "(772) 266-8344",
        "email": "studiomanager0206@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 11:30AM",
            "SUNDAY": "6:30AM - 11:30AM"
        },
        "geolocation": [
            27.1778965,
            -80.236603
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.58,
                    "duration": "2 Months",
                    "model_conf": 147.74
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.69,
                    "duration": "indefinitely",
                    "model_conf": 185.57
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.63,
                    "duration": "indefinitely",
                    "model_conf": 111.35
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Stuart, FL",
            "slug": "441813f9-d2b4-4e4d-aa67-101d9e8d87a3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/5/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/stuart/2303-se-federal-hwy/",
        "index": 98.425,
        "franchise-entity-name": "MAIJA FITNESS, LLC"
    },
    "bb2812d3-9de7-413e-b492-2e377ebfb550": {
        "name": "Orange Theory Sudbury, MA",
        "address": "534 Boston Post Road Suite B Sudbury, Massachusetts 01776",
        "phone": "(978) 822-1207",
        "email": "studiomanager1231@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            42.3608322,
            -71.43119
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.1,
                    "duration": "indefinitely",
                    "model_conf": 153.28
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.46,
                    "duration": "indefinitely",
                    "model_conf": 185.47
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.32,
                    "duration": "indefinitely",
                    "model_conf": 111.79
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Sudbury, MA",
            "slug": "bb2812d3-9de7-413e-b492-2e377ebfb550"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/22/19",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/sudbury/534-boston-post-road-suite-b/",
        "index": 103.375,
        "franchise-entity-name": "PARAGON VENTURES LLC"
    },
    "11d93f07-28b0-4079-beef-774b3758471b": {
        "name": "Orange Theory Suffolk, VA",
        "address": "1500 Bridgeport Way Suffolk, Virginia 23435",
        "phone": "(757) 935-0215",
        "email": "studiomanager1451@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            36.863658,
            -76.458153
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.21,
                    "duration": "5 Months",
                    "model_conf": 153.63
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.43,
                    "duration": "indefinitely",
                    "model_conf": 175.88
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.41,
                    "duration": "1 Months",
                    "model_conf": 111.86
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Suffolk, VA",
            "slug": "11d93f07-28b0-4079-beef-774b3758471b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/20/20",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/suffolk/1500-bridgeport-way-suite-108/",
        "index": 88.45,
        "franchise-entity-name": "Virginia Work Group, LLC"
    },
    "b0b9d022-304a-4a69-9878-deaa641c36bd": {
        "name": "Orange Theory Sugar Hill - Buford, GA",
        "address": "5019 West Broadstreet Suite 107/109 Sugar Hill, Georgia 30518",
        "phone": "(678) 786-4120",
        "email": "studiomanager0845@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 11:15AM",
            "SUNDAY": "7:30AM - 11:15AM"
        },
        "geolocation": [
            34.1188316,
            -84.075081
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.25,
                    "duration": "3 Months",
                    "model_conf": 149.12
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.88,
                    "duration": "indefinitely",
                    "model_conf": 184.39
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.52,
                    "duration": "1 Months",
                    "model_conf": 108.97
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sugar Hill - Buford, GA",
            "slug": "b0b9d022-304a-4a69-9878-deaa641c36bd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/sugar-hill/5019-west-broad-street-suite-107-109/",
        "index": 98.425,
        "franchise-entity-name": "JM BUFORD, LLC"
    },
    "5ff57fa1-62ed-4212-802d-1ff32a83d187": {
        "name": "Orange Theory Sugar Land, TX",
        "address": "15890 Southwest Freeway , Suite 300 Sugar Land, Texas 77478",
        "phone": "(281) 325-0018",
        "email": "studiomanager0110@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            29.6004238,
            -95.622849
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.43,
                    "duration": "indefinitely",
                    "model_conf": 150.78
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.33,
                    "duration": "2 Months",
                    "model_conf": 183.22
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.21,
                    "duration": "indefinitely",
                    "model_conf": 109.87
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sugar Land, TX",
            "slug": "5ff57fa1-62ed-4212-802d-1ff32a83d187"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/14/14",
        "url": "https://www.orangetheory.com/en-us/locations/texas/sugar-land/15890-southwest-freeway-suite-300/",
        "index": 98.425,
        "franchise-entity-name": "CAMBIO MANAGEMENT III LLC"
    },
    "2145a76b-b7e7-4656-9c7c-41ebca067bc4": {
        "name": "Orange Theory Riverstone, TX",
        "address": "18841 University Blvd, Suite 420 Sugar Land, Texas 77479",
        "phone": "(832) 930-4411",
        "email": "studiomanager0979@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            29.5491486,
            -95.594093
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.44,
                    "duration": "2 Months",
                    "model_conf": 152.26
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.22,
                    "duration": "indefinitely",
                    "model_conf": 184.65
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.48,
                    "duration": "1 Months",
                    "model_conf": 111.16
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Riverstone, TX",
            "slug": "2145a76b-b7e7-4656-9c7c-41ebca067bc4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/14/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/sugar-land/18841-university-blvd-suite-420/",
        "index": 98.425,
        "franchise-entity-name": "RIVERSTONE COACHING LLC"
    },
    "6606588f-e5e1-4bb8-a8f3-be412b471bfc": {
        "name": "Orange Theory Summerville, SC",
        "address": "1101 N. Main Street Summerville, South Carolina 29483",
        "phone": "(843) 970-2144",
        "email": "studiomanager0296@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.0321236,
            -80.162895
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.59,
                    "duration": "2 Months",
                    "model_conf": 153.8
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.06,
                    "duration": "indefinitely",
                    "model_conf": 184.67
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.84,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Summerville, SC",
            "slug": "6606588f-e5e1-4bb8-a8f3-be412b471bfc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/31/16",
        "url": "https://www.orangetheory.com/en-us/locations/south-carolina/summerville/1101-n-main-street/",
        "index": 93.4,
        "franchise-entity-name": "OG HORIZON, LLC"
    },
    "e47f82ce-9236-44d4-b0e3-831eea27b540": {
        "name": "Orange Theory Sun Prairie, WI",
        "address": "1261 Cabela Drive Sun Prairie, Wisconsin 53590",
        "phone": "(608) 234-5671",
        "email": "studiomanager1322@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 11:45AM",
            "SUNDAY": "6:00AM - 11:45AM"
        },
        "geolocation": [
            43.1653214,
            -89.271255
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.54,
                    "duration": "2 Months",
                    "model_conf": 152.17
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.62,
                    "duration": "indefinitely",
                    "model_conf": 185.34
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.54,
                    "duration": "1 Months",
                    "model_conf": 111.96
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Sun Prairie, WI",
            "slug": "e47f82ce-9236-44d4-b0e3-831eea27b540"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/23/19",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/sun-prairie/1261-cabela-drive/",
        "index": 93.4,
        "franchise-entity-name": "OT WISCONSIN SUN PRAIRIE, LLC"
    },
    "d303c0e6-82ae-4fa6-8ad3-7882696b7b63": {
        "name": "Orange Theory Downtown Sunnyvale, CA",
        "address": "155 South Frances Street Sunnyvale, California 94086",
        "phone": "(408) 508-6724",
        "email": "studiomanager0431@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "8:15AM - 1:00PM",
            "SUNDAY": "8:15AM - 1:00PM"
        },
        "geolocation": [
            37.3769379,
            -122.03125
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.84,
                    "duration": "indefinitely",
                    "model_conf": 152.95
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 147.54,
                    "duration": "3 Months",
                    "model_conf": 175.9
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 209.71,
                    "duration": "indefinitely",
                    "model_conf": 109.56
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Downtown Sunnyvale, CA",
            "slug": "d303c0e6-82ae-4fa6-8ad3-7882696b7b63"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/12/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/sunnyvale/155-s-frances-st/",
        "index": 111.325,
        "franchise-entity-name": "ROW-LIT FITNESS, LLC"
    },
    "67fb3bc3-8a42-4359-8026-8774a284863c": {
        "name": "Orange Theory Sunrise, FL",
        "address": "12532 W. Sunrise Blvd Sunrise, Florida 33323",
        "phone": "(954) 289-5100",
        "email": "studiomanager1078@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            26.145752,
            -80.315979
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.98,
                    "duration": "indefinitely",
                    "model_conf": 146.97
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.27,
                    "duration": "indefinitely",
                    "model_conf": 181.5
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.14,
                    "duration": "indefinitely",
                    "model_conf": 110.92
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Sunrise, FL",
            "slug": "67fb3bc3-8a42-4359-8026-8774a284863c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/15/19",
        "url": "https://www.orangetheory.com/en-us/locations/florida/sunrise/12532-w-sunrise-blvd/",
        "index": 98.425,
        "franchise-entity-name": "SUNRISE FITNESS PARTNERS, INC."
    },
    "c618d6e3-e60f-4ab8-9f2f-414a5ab66ed7": {
        "name": "Orange Theory Surprise, AZ",
        "address": "16846 W Bell Rd. Suite 106 Surprise, Arizona 85374",
        "phone": "(623) 556-8944",
        "email": "studiomanager0402@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:15AM - 11:00AM",
            "SUNDAY": "7:15AM - 11:00AM"
        },
        "geolocation": [
            33.64031,
            -112.42177
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 1.96,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.92,
                    "duration": "indefinitely",
                    "model_conf": 152.96
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 2.84,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.92,
                    "duration": "3 Months",
                    "model_conf": 184.36
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 4.16,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.74,
                    "duration": "indefinitely",
                    "model_conf": 111.77
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 5.39,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 8.36,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 11.22,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Surprise, AZ",
            "slug": "c618d6e3-e60f-4ab8-9f2f-414a5ab66ed7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/9/16",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/surprise/16846-w-bell-rd-suite-106/",
        "index": 103.375,
        "franchise-entity-name": "FITNESS BY KAFORA AT SURPRISE LLC"
    },
    "cf9c84bf-f848-4211-b58d-84805e96e5e6": {
        "name": "Orange Theory Suwanee - Brookwood, GA",
        "address": "2615 Peachtree Parkway, Suite 230 Suwanee, Georgia 30024",
        "phone": "(770) 688-0622",
        "email": "studiomanager0194@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 6:45PM",
            "TUESDAY": "6:00AM - 6:45PM",
            "WEDNESDAY": "6:00AM - 6:45PM",
            "THURSDAY": "6:00AM - 6:45PM",
            "FRIDAY": "6:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 1:30PM",
            "SUNDAY": "7:00AM - 1:30PM"
        },
        "geolocation": [
            34.0963516,
            -84.174736
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.86,
                    "duration": "indefinitely",
                    "model_conf": 138.16
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.03,
                    "duration": "indefinitely",
                    "model_conf": 180.92
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.83,
                    "duration": "indefinitely",
                    "model_conf": 102.52
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Suwanee - Brookwood, GA",
            "slug": "cf9c84bf-f848-4211-b58d-84805e96e5e6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/13/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/suwanee/2615-peachtree-parkway-suite-230/",
        "index": 98.425,
        "franchise-entity-name": "VIVENCIA FIT TWO LLC"
    },
    "1c7c5751-3827-4ab2-9815-b18c965d566b": {
        "name": "Orange Theory Syosset, NY",
        "address": "103 Jackson Avenue Syosset, New York 11791",
        "phone": "(516) 299-8309",
        "email": "studiomanager0322@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "6:00AM - 12:25PM",
            "SUNDAY": "6:00AM - 12:25PM"
        },
        "geolocation": [
            40.8231735,
            -73.502243
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.58,
                    "duration": "5 Months",
                    "model_conf": 139.36
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.79,
                    "duration": "indefinitely",
                    "model_conf": 166.33
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.33,
                    "duration": "1 Months",
                    "model_conf": 100.32
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Syosset, NY",
            "slug": "1c7c5751-3827-4ab2-9815-b18c965d566b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/10/15",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/syosset/103-jackson-avenue/",
        "index": 108.325,
        "franchise-entity-name": "GV FITNESS LLC"
    },
    "b0916ae8-56b2-4429-8436-23829fb8cfd8": {
        "name": "Orange Theory Syracuse, NY",
        "address": "3150 Erie Boulevard East Syracuse, New York 13214",
        "phone": "(315) 965-3555",
        "email": "studiomanager0834@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            43.0537872,
            -76.0774
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.83,
                    "duration": "indefinitely",
                    "model_conf": 153.55
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.3,
                    "duration": "indefinitely",
                    "model_conf": 185.28
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.72,
                    "duration": "indefinitely",
                    "model_conf": 111.32
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Syracuse, NY",
            "slug": "b0916ae8-56b2-4429-8436-23829fb8cfd8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/12/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/syracuse/3150-erie-boulevard-east/",
        "index": 98.425,
        "franchise-entity-name": "SYRACUSE FITNESS, LLC"
    },
    "6608110a-418b-430d-b8f0-2db01ab6e3f3": {
        "name": "Orange Theory Tacoma, WA",
        "address": "637 Division Ave Tacoma, Washington 98409",
        "phone": "(253) 212-2560",
        "email": "studiomanager1075@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            47.2636948,
            -122.44864
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 8.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.4,
                    "duration": "2 Months",
                    "model_conf": 153.43
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 12.26,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.12,
                    "duration": "indefinitely",
                    "model_conf": 184.27
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 18.44,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.44,
                    "duration": "1 Months",
                    "model_conf": 112.08
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 23.18,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 38.62,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 51.4,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Tacoma, WA",
            "slug": "6608110a-418b-430d-b8f0-2db01ab6e3f3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/2/18",
        "url": "https://www.orangetheory.com/en-us/locations/washington/tacoma/637-division-ave/",
        "index": 98.425,
        "franchise-entity-name": "TACOMA FITNESS, LLC"
    },
    "f46b8a60-6341-45f7-9b3b-b9e617dcc6dc": {
        "name": "Orange Theory Tallahassee, FL",
        "address": "1321 Thomasville Road, Unit 1 Tallahassee, Florida 32303",
        "phone": "(850) 210-0503",
        "email": "studiomanager0220@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 2:30PM",
            "SUNDAY": "7:00AM - 2:30PM"
        },
        "geolocation": [
            30.4570999,
            -84.276962
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.57,
                    "duration": "indefinitely",
                    "model_conf": 153.29
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.93,
                    "duration": "indefinitely",
                    "model_conf": 185.61
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.37,
                    "duration": "indefinitely",
                    "model_conf": 111.97
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Tallahassee, FL",
            "slug": "f46b8a60-6341-45f7-9b3b-b9e617dcc6dc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/2/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/tallahassee/1321-thomasville-road-unit-1/",
        "index": 93.4,
        "franchise-entity-name": "HAWTHORNE TALLYHO1, LLC"
    },
    "656b5449-90a3-4065-9862-4d499066bf7b": {
        "name": "Orange Theory Tallahassee - North, FL",
        "address": "1400 Village Square Blvd., Suite 26 Tallahassee, Florida 32309",
        "phone": "(850) 895-9683",
        "email": "studiomanager0221@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 1:15PM",
            "SUNDAY": "7:15AM - 1:15PM"
        },
        "geolocation": [
            30.5117436,
            -84.248688
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.15,
                    "duration": "indefinitely",
                    "model_conf": 151.98
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.63,
                    "duration": "4 Months",
                    "model_conf": 185.23
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.79,
                    "duration": "indefinitely",
                    "model_conf": 111.83
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Tallahassee - North, FL",
            "slug": "656b5449-90a3-4065-9862-4d499066bf7b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/3/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/tallahassee/1400-village-square-blvd-suite-26/",
        "index": 93.4,
        "franchise-entity-name": "HAWTHORNE TALLYHO2, LLC"
    },
    "1d3abf72-5fc4-414b-857b-a2f536912379": {
        "name": "Orange Theory Talleyville, DE",
        "address": "4120 Concord Pike Talleyville, Delaware 19803",
        "phone": "(302) 449-9448",
        "email": "studiomanager0665@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            39.8106995,
            -75.550507
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.95,
                    "duration": "indefinitely",
                    "model_conf": 144.34
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.94,
                    "duration": "indefinitely",
                    "model_conf": 183.69
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.21,
                    "duration": "indefinitely",
                    "model_conf": 97.15
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Talleyville, DE",
            "slug": "1d3abf72-5fc4-414b-857b-a2f536912379"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/21/16",
        "url": "https://www.orangetheory.com/en-us/locations/delaware/talleyville/4120-concord-pike/",
        "index": 98.425,
        "franchise-entity-name": "ALPHA FITNESS PILOT LLC"
    },
    "cfb9512a-d6fa-40d2-b57a-35378af88b8d": {
        "name": "Orange Theory Channelside, FL",
        "address": "1061 E Cumberland Ave Tampa, Florida 33602",
        "phone": "(813) 513-9845",
        "email": "studiomanager0181@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 3:00PM",
            "SUNDAY": "7:00AM - 3:00PM"
        },
        "geolocation": [
            27.94488,
            -82.449995
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.18,
                    "duration": "indefinitely",
                    "model_conf": 151.33
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.81,
                    "duration": "indefinitely",
                    "model_conf": 177.08
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.06,
                    "duration": "indefinitely",
                    "model_conf": 109.63
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Channelside, FL",
            "slug": "cfb9512a-d6fa-40d2-b57a-35378af88b8d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/19/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/tampa/140-s-meridian-avenue/",
        "index": 98.425,
        "franchise-entity-name": "GLOBAL FITNESS ENTERPRISES III, LLC"
    },
    "6bcb2cda-702e-443f-8580-b29fb7226825": {
        "name": "Orange Theory South Tampa, FL",
        "address": "115 South Dale Mabry Tampa, Florida 33609",
        "phone": "(813) 356-0620",
        "email": "studiomanager0018@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:45PM",
            "TUESDAY": "5:00AM - 8:45PM",
            "WEDNESDAY": "5:00AM - 8:45PM",
            "THURSDAY": "5:00AM - 8:45PM",
            "FRIDAY": "5:00AM - 8:45PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            27.9435902,
            -82.506142
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.15,
                    "duration": "indefinitely",
                    "model_conf": 151.52
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.81,
                    "duration": "indefinitely",
                    "model_conf": 175.43
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.2,
                    "duration": "indefinitely",
                    "model_conf": 108.52
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "South Tampa, FL",
            "slug": "6bcb2cda-702e-443f-8580-b29fb7226825"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/28/12",
        "url": "https://www.orangetheory.com/en-us/locations/florida/tampa/115-south-dale-mabry/",
        "index": 98.425,
        "franchise-entity-name": "South Tampa Coaching, LLC"
    },
    "a46063bc-2cc3-4a58-9bce-3ea00229d235": {
        "name": "Orange Theory Westchase - Tampa, FL",
        "address": "11721 W. Hillsborough Ave. Tampa, Florida 33635",
        "phone": "(813) 852-8009",
        "email": "studiomanager0066@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            28.015686,
            -82.615372
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.41,
                    "duration": "2 Months",
                    "model_conf": 151.44
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.44,
                    "duration": "indefinitely",
                    "model_conf": 153.34
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.82,
                    "duration": "indefinitely",
                    "model_conf": 96.5
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Westchase - Tampa, FL",
            "slug": "a46063bc-2cc3-4a58-9bce-3ea00229d235"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/6/13",
        "url": "https://www.orangetheory.com/en-us/locations/florida/tampa/11721-w-hillsborough-ave/",
        "index": 98.425,
        "franchise-entity-name": "JJ LUGO ENTERPRISES, INC."
    },
    "c08236d5-9aa7-45ef-9498-525fde854150": {
        "name": "Orange Theory Carrollwood, FL",
        "address": "13122 N Dale Mabry Hwy Tampa, Florida 33618",
        "phone": "(813) 964-3194",
        "email": "studiomanager0203@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 3:00PM",
            "SUNDAY": "7:00AM - 3:00PM"
        },
        "geolocation": [
            28.0659428,
            -82.505074
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.73,
                    "duration": "1 Months",
                    "model_conf": 151.8
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.24,
                    "duration": "indefinitely",
                    "model_conf": 176.73
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.9,
                    "duration": "indefinitely",
                    "model_conf": 108.21
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Carrollwood, FL",
            "slug": "c08236d5-9aa7-45ef-9498-525fde854150"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/27/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/tampa/13122-n-dale-mabry-hwy/",
        "index": 98.425,
        "franchise-entity-name": "GLOBAL FITNESS ENTERPRISES II, LLC"
    },
    "14e1bb20-3567-4b37-be22-ed11d09c1f39": {
        "name": "Orange Theory USF-Tampa, FL",
        "address": "2798-B Fowler Ave Tampa, Florida 33612",
        "phone": "(813) 400-2977",
        "email": "studiomanager1330@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            28.0550137,
            -82.427155
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.98,
                    "duration": "indefinitely",
                    "model_conf": 152.66
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.93,
                    "duration": "indefinitely",
                    "model_conf": 181.98
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.89,
                    "duration": "indefinitely",
                    "model_conf": 110.49
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "USF-Tampa, FL",
            "slug": "14e1bb20-3567-4b37-be22-ed11d09c1f39"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/15/19",
        "url": "https://www.orangetheory.com/en-us/locations/florida/tampa/2798-b-fowler-ave/",
        "index": 98.425,
        "franchise-entity-name": "Serendipty Fitness Partners, LLC"
    },
    "4840b200-53cf-44eb-81be-858ccd9deec5": {
        "name": "Orange Theory Tampa-Gandy, FL",
        "address": "4810 S Manhattan Ave, Ste 102 Tampa, Florida 33611",
        "phone": "(813) 773-6623",
        "email": "studiomanager1254@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            27.894426,
            -82.51818
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.37,
                    "duration": "indefinitely",
                    "model_conf": 152.28
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.36,
                    "duration": "2 Months",
                    "model_conf": 180.15
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.12,
                    "duration": "indefinitely",
                    "model_conf": 110.41
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Tampa-Gandy, FL",
            "slug": "4840b200-53cf-44eb-81be-858ccd9deec5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/21/22",
        "url": "https://www.orangetheory.com/en-us/locations/florida/tampa/4810-s-manhattan-ave-suite-102/",
        "index": 98.425,
        "franchise-entity-name": "GANDY COACHING LLC"
    },
    "4fa779a9-ba82-4e02-ba7a-bf62bc09862b": {
        "name": "Orange Theory Tarrytown, NY",
        "address": "624 White Plains Rd Tarrytown, New York 10591",
        "phone": "(914) 359-6200",
        "email": "studiomanager0659@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            41.0595551,
            -73.837311
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.06,
                    "duration": "4 Months",
                    "model_conf": 148.92
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.31,
                    "duration": "indefinitely",
                    "model_conf": 179.95
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.12,
                    "duration": "1 Months",
                    "model_conf": 108.82
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Tarrytown, NY",
            "slug": "4fa779a9-ba82-4e02-ba7a-bf62bc09862b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/3/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/tarrytown/624-white-plains-rd/",
        "index": 103.375,
        "franchise-entity-name": "EU VORTEX IV, LLC"
    },
    "a3004fe7-dd4f-4616-bca0-d490bb4f8b18": {
        "name": "Orange Theory Temecula, CA",
        "address": "32435 Temecula Parkway Temecula, California 92592",
        "phone": "(951) 395-5415",
        "email": "studiomanager0397@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:00AM - 10:45AM",
            "SUNDAY": "6:00AM - 10:45AM"
        },
        "geolocation": [
            33.4824143,
            -117.09107
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.95,
                    "duration": "4 Months",
                    "model_conf": 153.27
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.14,
                    "duration": "indefinitely",
                    "model_conf": 185.01
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.22,
                    "duration": "1 Months",
                    "model_conf": 111.04
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Temecula, CA",
            "slug": "a3004fe7-dd4f-4616-bca0-d490bb4f8b18"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/11/16",
        "url": "https://www.orangetheory.com/en-us/locations/california/temecula/32435-temecula-parkway/",
        "index": 103.375,
        "franchise-entity-name": "TIDEWATERS, LLC"
    },
    "11f41080-bd96-4862-880d-3c39623cd443": {
        "name": "Orange Theory North Tempe-ASU",
        "address": "116 E University Drive, Ste 102 Tempe, Arizona 85281",
        "phone": "(480) 531-6830",
        "email": "studiomanager1293@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:45PM",
            "TUESDAY": "6:00AM - 7:45PM",
            "WEDNESDAY": "6:00AM - 7:45PM",
            "THURSDAY": "6:00AM - 7:45PM",
            "FRIDAY": "6:00AM - 7:45PM",
            "SATURDAY": "8:15AM - 1:30PM",
            "SUNDAY": "8:15AM - 1:30PM"
        },
        "geolocation": [
            33.4220696,
            -111.93741
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 1.6,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.63,
                    "duration": "indefinitely",
                    "model_conf": 129.13
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 2.32,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.0,
                    "duration": "3 Months",
                    "model_conf": 154.93
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 3.4,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.69,
                    "duration": "indefinitely",
                    "model_conf": 91.76
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 4.41,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 6.84,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 9.18,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "North Tempe-ASU",
            "slug": "11f41080-bd96-4862-880d-3c39623cd443"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/17/19",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/tempe/116-e-university-drive-ste-102/",
        "index": 103.375,
        "franchise-entity-name": "North Tempe Fitness Partners, LLC"
    },
    "f79ae65c-efba-4a82-98f2-16482b805862": {
        "name": "Orange Theory Tempe, AZ",
        "address": "1855 E. Guadalupe Rd. Ste. A-102 Tempe, Arizona 85283",
        "phone": "(480) 300-5683",
        "email": "studiomanager0079@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            33.3631554,
            -111.90831
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 1.6,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.57,
                    "duration": "indefinitely",
                    "model_conf": 121.68
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 2.32,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.33,
                    "duration": "2 Months",
                    "model_conf": 146.03
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 3.4,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.62,
                    "duration": "indefinitely",
                    "model_conf": 87.41
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 4.41,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 6.84,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 9.18,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Tempe, AZ",
            "slug": "f79ae65c-efba-4a82-98f2-16482b805862"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/27/13",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/tempe/1855-e-guadalupe-rd-ste-a-102/",
        "index": 103.375,
        "franchise-entity-name": "TEMPE FITNESS PARTNERS, LLC"
    },
    "01b51b68-993f-445c-a3b2-05c073570cf9": {
        "name": "Orange Theory Temple, TX",
        "address": "3118 S 31st St Temple, Texas 76502",
        "phone": "(254) 262-0000",
        "email": "studiomanager1107@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            31.0705242,
            -97.369751
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.26,
                    "duration": "4 Months",
                    "model_conf": 153.83
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.97,
                    "duration": "indefinitely",
                    "model_conf": 183.74
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.27,
                    "duration": "1 Months",
                    "model_conf": 111.51
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Temple, TX",
            "slug": "01b51b68-993f-445c-a3b2-05c073570cf9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/temple/3118-s-31st-st/",
        "index": 88.45,
        "franchise-entity-name": "KMM Fitness V, LLC"
    },
    "c572ef38-327d-4e6b-8cb8-a42b2f289f44": {
        "name": "Orange Theory Thornton, CO",
        "address": "4243 E. 136th Ave Thornton, Colorado 80602",
        "phone": "(720) 929-1649",
        "email": "studiomanager0557@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 11:30PM",
            "SUNDAY": "6:15AM - 11:30PM"
        },
        "geolocation": [
            39.9441223,
            -104.93697
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.04,
                    "duration": "indefinitely",
                    "model_conf": 152.02
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.19,
                    "duration": "indefinitely",
                    "model_conf": 184.97
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.0,
                    "duration": "indefinitely",
                    "model_conf": 111.41
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Thornton, CO",
            "slug": "c572ef38-327d-4e6b-8cb8-a42b2f289f44"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/12/18",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/thornton/4243-e-136th-ave/",
        "index": 98.425,
        "franchise-entity-name": "WSH THORNTON, LLC"
    },
    "563ecbb4-05c9-4a4b-bd12-2e7cc5ed7b08": {
        "name": "Orange Theory Thousand Oaks - Westlake, CA",
        "address": "3755 East Thousand Oaks Blvd Thousand Oaks, California 91362",
        "phone": "(805) 715-3800",
        "email": "studiomanager0179@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "6:30AM - 12:00PM",
            "SUNDAY": "6:30AM - 12:00PM"
        },
        "geolocation": [
            34.1644478,
            -118.82849
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 97.98,
                    "duration": "4 Months",
                    "model_conf": 152.52
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 140.38,
                    "duration": "indefinitely",
                    "model_conf": 184.51
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.49,
                    "duration": "indefinitely",
                    "model_conf": 111.78
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Thousand Oaks - Westlake, CA",
            "slug": "563ecbb4-05c9-4a4b-bd12-2e7cc5ed7b08"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/31/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/thousand-oaks/3755-east-thousand-oaks-blvd/",
        "index": 108.325,
        "franchise-entity-name": "TRISTAR FITNESS, LLC"
    },
    "353c48eb-18cc-490e-afff-62f0ddd71f0d": {
        "name": "Orange Theory Tigard, OR",
        "address": "12268 SW Scholls Ferry Road Tigard, Oregon 97223",
        "phone": "(503) 782-4888",
        "email": "studiomanager0335@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            45.4430161,
            -122.80325
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.95,
                    "duration": "10 Months",
                    "model_conf": 152.87
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 121.79,
                    "duration": "indefinitely",
                    "model_conf": 176.2
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.55,
                    "duration": "3 Months",
                    "model_conf": 110.29
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Tigard, OR",
            "slug": "353c48eb-18cc-490e-afff-62f0ddd71f0d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/29/15",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/tigard/12268-sw-scholls-ferry-road/",
        "index": 98.425,
        "franchise-entity-name": "S &amp; H FITNESS, LLC"
    },
    "01955f22-a13b-4d0a-a3e9-0c79d7e65c7f": {
        "name": "Orange Theory Ottawa Hills, OH",
        "address": "3504 Secor Rd., Suite 310 Toledo, Ohio 43606",
        "phone": "(419) 586-1054",
        "email": "studiomanager0683@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            41.6842003,
            -83.622902
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.58,
                    "duration": "8 Months",
                    "model_conf": 151.08
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 111.06,
                    "duration": "indefinitely",
                    "model_conf": 185.55
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.86,
                    "duration": "2 Months",
                    "model_conf": 111.93
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Ottawa Hills, OH",
            "slug": "01955f22-a13b-4d0a-a3e9-0c79d7e65c7f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/16/17",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/toledo/3504-secor-rd-suite-310/",
        "index": 93.4,
        "franchise-entity-name": "GREAT LAKES 22 FITNESS CENTER, LLC"
    },
    "861fa750-b930-42b3-8d36-db172f4d37fd": {
        "name": "Orange Theory Torrance, CA",
        "address": "3730 Pacific Coast Highway, Unit 101 Torrance, California 90505",
        "phone": "(310) 948-3845",
        "email": "studiomanager0184@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "6:30AM - 12:15PM",
            "SUNDAY": "6:30AM - 12:15PM"
        },
        "geolocation": [
            33.8049164,
            -118.35097
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.47,
                    "duration": "indefinitely",
                    "model_conf": 131.3
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.59,
                    "duration": "1 Months",
                    "model_conf": 152.14
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.65,
                    "duration": "indefinitely",
                    "model_conf": 93.44
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "Torrance, CA",
            "slug": "861fa750-b930-42b3-8d36-db172f4d37fd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/20/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/torrance/3730-pacific-coast-highway-unit-101/",
        "index": 111.325,
        "franchise-entity-name": "FITNESS FANATICS REDONDO BEACH LLC"
    },
    "fa5e34e2-f692-4f22-9df0-df03597ece24": {
        "name": "Orange Theory Totowa, NJ",
        "address": "650 Union Blvd. Totowa, New Jersey 07512",
        "phone": "(973) 500-6600",
        "email": "studiomanager0348@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            40.8972321,
            -74.220894
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.67,
                    "duration": "indefinitely",
                    "model_conf": 147.24
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.03,
                    "duration": "indefinitely",
                    "model_conf": 177.61
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.09,
                    "duration": "indefinitely",
                    "model_conf": 109.69
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Totowa, NJ",
            "slug": "fa5e34e2-f692-4f22-9df0-df03597ece24"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/5/15",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/totowa/650-union-blvd/",
        "index": 103.375,
        "franchise-entity-name": "8 STREET INVESTMENTS LLC"
    },
    "94e2a457-ec6d-4b00-aa05-76bd855809d1": {
        "name": "Orange Theory Tracy, CA",
        "address": "1855 11TH STREET Tracy, California 95376",
        "phone": "(209) 407-4084",
        "email": "studiomanager0909@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 10:45AM",
            "SUNDAY": "7:00AM - 10:45AM"
        },
        "geolocation": [
            37.739418,
            -121.45097
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.52,
                    "duration": "2 Months",
                    "model_conf": 153.42
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.49,
                    "duration": "indefinitely",
                    "model_conf": 185.19
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.62,
                    "duration": "indefinitely",
                    "model_conf": 110.82
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Tracy, CA",
            "slug": "94e2a457-ec6d-4b00-aa05-76bd855809d1"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/15/17",
        "url": "https://www.orangetheory.com/en-us/locations/california/tracy/1855-11th-street/",
        "index": 93.4,
        "franchise-entity-name": "CALIFORNIA FITNESS 3, LLC"
    },
    "9ff1ceaf-105e-49e1-ace9-af54f2243fbe": {
        "name": "Orange Theory Troy, MI",
        "address": "3624 Rochester Road Troy, Michigan 48083",
        "phone": "(248) 864-2190",
        "email": "studiomanager0489@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            42.5716362,
            -83.127968
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.36,
                    "duration": "indefinitely",
                    "model_conf": 144.15
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.53,
                    "duration": "1 Months",
                    "model_conf": 174.54
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.26,
                    "duration": "indefinitely",
                    "model_conf": 106.62
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Troy, MI",
            "slug": "9ff1ceaf-105e-49e1-ace9-af54f2243fbe"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/18/16",
        "url": "https://www.orangetheory.com/en-us/locations/michigan/troy/3624-rochester-road/",
        "index": 98.425,
        "franchise-entity-name": "L5 Fitness Michigan, LLC - 0489"
    },
    "edf8b48c-e9fd-405d-a732-666887d30cac": {
        "name": "Orange Theory Tualatin, OR",
        "address": "19221 SW Martinazzi Ave. Tualatin, Oregon 97062",
        "phone": "(503) 487-0002",
        "email": "studiomanager0511@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:00PM",
            "SUNDAY": "6:30AM - 12:00PM"
        },
        "geolocation": [
            45.380867,
            -122.7606
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.03,
                    "duration": "4 Months",
                    "model_conf": 152.8
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.57,
                    "duration": "indefinitely",
                    "model_conf": 180.37
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.53,
                    "duration": "1 Months",
                    "model_conf": 110.96
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Tualatin, OR",
            "slug": "edf8b48c-e9fd-405d-a732-666887d30cac"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/tualatin/19221-sw-martinazzi-ave/",
        "index": 98.425,
        "franchise-entity-name": "FITZONE 3, LLC"
    },
    "7d827ca0-dd72-49a0-947c-487028d416d9": {
        "name": "Orange Theory Tuckahoe",
        "address": "150 Main Street Tuckahoe, New York 10707",
        "phone": "(914) 771-5105",
        "email": "studiomanager0827@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            40.9490433,
            -73.823227
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.42,
                    "duration": "6 Months",
                    "model_conf": 128.17
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.83,
                    "duration": "indefinitely",
                    "model_conf": 156.44
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.52,
                    "duration": "3 Months",
                    "model_conf": 94.77
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Tuckahoe",
            "slug": "7d827ca0-dd72-49a0-947c-487028d416d9"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/tuckahoe/150-main-street/",
        "index": 103.375,
        "franchise-entity-name": "REDWING3637, LLC"
    },
    "0bfa65fd-57f0-4b83-8ddf-4f708f527195": {
        "name": "Orange Theory Tucson - Central, AZ",
        "address": "2501 East Grant Road #101 Tucson, Arizona 85716",
        "phone": "(520) 300-9585",
        "email": "studiomanager0856@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            32.2507057,
            -110.93504
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 2.31,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 86.88,
                    "duration": "9 Months",
                    "model_conf": 140.6
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 3.35,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 131.59,
                    "duration": "indefinitely",
                    "model_conf": 173.45
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 4.91,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.53,
                    "duration": "3 Months",
                    "model_conf": 105.85
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 6.37,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 9.88,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 13.26,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Tucson - Central, AZ",
            "slug": "0bfa65fd-57f0-4b83-8ddf-4f708f527195"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/27/18",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/tucson/2501-east-grant-road-101/",
        "index": 103.375,
        "franchise-entity-name": "TUCSON FIT 4, LLC"
    },
    "50af3520-e89b-4e83-ab66-b83c411108c8": {
        "name": "Orange Theory Tucson - Foothills, AZ",
        "address": "5575 E. River, Suite 101 Tucson, Arizona 85750",
        "phone": "(520) 777-3221",
        "email": "studiomanager0294@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 1:15PM",
            "SUNDAY": "7:00AM - 1:15PM"
        },
        "geolocation": [
            32.2750359,
            -110.87379
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 86.31,
                    "duration": "11 Months",
                    "model_conf": 133.6
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 132.29,
                    "duration": "indefinitely",
                    "model_conf": 169.0
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.17,
                    "duration": "3 Months",
                    "model_conf": 102.59
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Tucson - Foothills, AZ",
            "slug": "50af3520-e89b-4e83-ab66-b83c411108c8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/18/16",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/tucson/5575-e-river-suite-101/",
        "index": 103.375,
        "franchise-entity-name": "TUCSON FIT 2, LLC"
    },
    "09bce986-53d9-4b52-a8b3-59041bb13204": {
        "name": "Orange Theory Tucson - East, AZ",
        "address": "6307 E Broadway Blvd., Suite 151 Tucson, Arizona 85710",
        "phone": "(520) 265-5222",
        "email": "studiomanager0855@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 1:45PM",
            "SUNDAY": "7:30AM - 1:45PM"
        },
        "geolocation": [
            32.2213326,
            -110.85725
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 2.22,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 86.33,
                    "duration": "11 Months",
                    "model_conf": 132.63
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 3.22,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 132.47,
                    "duration": "indefinitely",
                    "model_conf": 171.78
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 4.72,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.08,
                    "duration": "3 Months",
                    "model_conf": 103.26
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 6.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 9.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 12.75,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Tucson - East, AZ",
            "slug": "09bce986-53d9-4b52-a8b3-59041bb13204"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/tucson/6307-e-broadway-blvd-suite-151/",
        "index": 103.375,
        "franchise-entity-name": "TUCSON FIT 3, LLC"
    },
    "a2080cca-fc5b-4739-83fe-4b321a1e409e": {
        "name": "Orange Theory Tucson - Casas Adobes, AZ",
        "address": "7057 North Oracle Road Tucson, Arizona 85704",
        "phone": "(520) 838-0066",
        "email": "studiomanager0293@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 12:30PM",
            "SUNDAY": "6:00AM - 12:30PM"
        },
        "geolocation": [
            32.3358727,
            -110.97851
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 86.89,
                    "duration": "9 Months",
                    "model_conf": 99.28
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 131.36,
                    "duration": "indefinitely",
                    "model_conf": 144.02
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.42,
                    "duration": "3 Months",
                    "model_conf": 89.08
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Tucson - Casas Adobes, AZ",
            "slug": "a2080cca-fc5b-4739-83fe-4b321a1e409e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/21/16",
        "url": "https://www.orangetheory.com/en-us/locations/arizona/tucson/7057-n-oracle-rd/",
        "index": 103.375,
        "franchise-entity-name": "TUCSON FIT 1, LLC"
    },
    "0d2e5c67-a06a-4d73-96b4-af07112da244": {
        "name": "Orange Theory Midtown Tulsa",
        "address": "1551 E. 15th St. Suite 103 Tulsa, Oklahoma 74120",
        "phone": "(918) 900-2727",
        "email": "studiomanager0803@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 2:15PM",
            "SUNDAY": "6:30AM - 2:15PM"
        },
        "geolocation": [
            36.1407967,
            -95.9701
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 5.88,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.02,
                    "duration": "indefinitely",
                    "model_conf": 151.62
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 9.28,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.35,
                    "duration": "indefinitely",
                    "model_conf": 185.52
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 14.39,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.24,
                    "duration": "indefinitely",
                    "model_conf": 111.83
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 16.95,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 30.58,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 42.5,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Midtown Tulsa",
            "slug": "0d2e5c67-a06a-4d73-96b4-af07112da244"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/6/17",
        "url": "https://www.orangetheory.com/en-us/locations/oklahoma/tulsa-north-brookside/1551-e-15th-st-suite-103/",
        "index": 93.4,
        "franchise-entity-name": "CHOPFIT I, LLC"
    },
    "727e50cf-78fa-44fd-adab-9d72a6a63ef0": {
        "name": "Orange Theory Kingspointe Village, OK",
        "address": "6024 South Yale Ave Tulsa, Oklahoma 74135",
        "phone": "(918) 981-2204",
        "email": "studiomanager1241@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 12:30PM",
            "SUNDAY": "6:30AM - 12:30PM"
        },
        "geolocation": [
            36.076398,
            -95.923813
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.73,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.91,
                    "duration": "5 Months",
                    "model_conf": 149.0
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.53,
                    "duration": "indefinitely",
                    "model_conf": 185.68
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 15.24,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.31,
                    "duration": "1 Months",
                    "model_conf": 111.89
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 19.16,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 31.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 42.5,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Kingspointe Village, OK",
            "slug": "727e50cf-78fa-44fd-adab-9d72a6a63ef0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/09/23",
        "url": "https://www.orangetheory.com/en-us/locations/oklahoma/kingspointe-village/6024-south-yale-avenue/",
        "index": 98.425,
        "franchise-entity-name": "KINGSPOINTE FITNESS PARTNERS LLC"
    },
    "93b3d57c-0252-4716-b822-a48d7002eba2": {
        "name": "Orange Theory South Tulsa, OK",
        "address": "9118 S. Sheridan Rd. Tulsa, Oklahoma 74133",
        "phone": "(918) 612-4364",
        "email": "studiomanager0988@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:30PM",
            "SUNDAY": "6:30AM - 12:30PM"
        },
        "geolocation": [
            36.0311699,
            -95.904305
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.73,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.95,
                    "duration": "indefinitely",
                    "model_conf": 149.61
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.1,
                    "duration": "indefinitely",
                    "model_conf": 185.65
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 15.24,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.82,
                    "duration": "indefinitely",
                    "model_conf": 111.84
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 19.16,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 31.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 42.5,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "South Tulsa, OK",
            "slug": "93b3d57c-0252-4716-b822-a48d7002eba2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/22/17",
        "url": "https://www.orangetheory.com/en-us/locations/oklahoma/tulsa/9118-s-sheridan-rd/",
        "index": 98.425,
        "franchise-entity-name": "SOUTH TULSA FITNESS STUDIO, LLC"
    },
    "2d183eb4-12b7-4a76-89da-58979d808982": {
        "name": "Orange Theory Tuscaloosa, AL",
        "address": "1451 Doctor Edward Hillard Drive Tuscaloosa, Alabama 35404",
        "phone": "(205) 464-9637",
        "email": "studiomanager0771@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 4:30PM",
            "SUNDAY": "8:00AM - 4:30PM"
        },
        "geolocation": [
            33.1979981,
            -87.528732
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.12,
                    "duration": "indefinitely",
                    "model_conf": 153.81
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.67
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.73
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Tuscaloosa, AL",
            "slug": "2d183eb4-12b7-4a76-89da-58979d808982"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/17",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/tuscaloosa/1451-dr-edward-hillard-drive/",
        "index": 93.4,
        "franchise-entity-name": "NICHEFITNESS9, LLC"
    },
    "b1bf5487-768c-413a-b1d0-d274903b5a05": {
        "name": "Orange Theory Tustin - The Market Place, CA",
        "address": "2865 El Camino Real Tustin, California 92782",
        "phone": "(949) 864-6264",
        "email": "studiomanager0100@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "6:30AM - 12:40PM",
            "SUNDAY": "6:30AM - 12:40PM"
        },
        "geolocation": [
            33.7256241,
            -117.79419
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.43,
                    "duration": "indefinitely",
                    "model_conf": 147.35
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.37,
                    "duration": "1 Months",
                    "model_conf": 177.65
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.2,
                    "duration": "indefinitely",
                    "model_conf": 106.65
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Tustin - The Market Place, CA",
            "slug": "b1bf5487-768c-413a-b1d0-d274903b5a05"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/13/13",
        "url": "https://www.orangetheory.com/en-us/locations/california/tustin/2865-el-camino-real/",
        "index": 103.375,
        "franchise-entity-name": "OCFIT TUS LLC"
    },
    "b3d7ff14-4698-4ce0-bd03-1c4948496c72": {
        "name": "Orange Theory Tyler, TX",
        "address": "419 WSW Loop 323 Tyler, Texas 75701",
        "phone": "(903) 705-6580",
        "email": "studiomanager0691@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            32.3030586,
            -95.304573
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.43,
                    "duration": "indefinitely",
                    "model_conf": 152.82
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.19,
                    "duration": "2 Months",
                    "model_conf": 185.59
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.21,
                    "duration": "indefinitely",
                    "model_conf": 112.07
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Tyler, TX",
            "slug": "b3d7ff14-4698-4ce0-bd03-1c4948496c72"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/11/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/tyler/419-wsw-loop-323/",
        "index": 93.4,
        "franchise-entity-name": "KMM FITNESS III LLC"
    },
    "a7e45d5d-cd28-47ea-a785-10bc131a0780": {
        "name": "Orange Theory Tysons Corner",
        "address": "1430 Spring Hill Road, Suite 150 Tysons, Virginia 22102",
        "phone": "(571) 297-2229",
        "email": "studiomanager1114@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:45PM",
            "TUESDAY": "6:15AM - 7:45PM",
            "WEDNESDAY": "6:15AM - 7:45PM",
            "THURSDAY": "6:15AM - 7:45PM",
            "FRIDAY": "6:15AM - 7:45PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            38.9323273,
            -77.234138
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.6,
                    "duration": "indefinitely",
                    "model_conf": 95.85
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.07,
                    "duration": "6 Months",
                    "model_conf": 116.41
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 180.05,
                    "duration": "indefinitely",
                    "model_conf": 74.46
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Tysons Corner",
            "slug": "a7e45d5d-cd28-47ea-a785-10bc131a0780"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/tysons/1430-spring-hill-road-suite-150/",
        "index": 98.425,
        "franchise-entity-name": "Tysons Fitness LLC"
    },
    "2c2deb5a-f19f-4823-9741-a211ab823e9d": {
        "name": "Orange Theory Union Market",
        "address": "320 Florida Ave NE Union Market, District Of Columbia 20002",
        "phone": "(202) 688-3633",
        "email": "studiomanager1248@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:00AM - 7:45PM",
            "TUESDAY": "6:00AM - 7:45PM",
            "WEDNESDAY": "6:00AM - 7:45PM",
            "THURSDAY": "6:00AM - 7:45PM",
            "FRIDAY": "6:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            38.9078598,
            -77.001457
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.42,
                    "duration": "indefinitely",
                    "model_conf": 113.66
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.76,
                    "duration": "indefinitely",
                    "model_conf": 136.45
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.12,
                    "duration": "indefinitely",
                    "model_conf": 84.91
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Union Market",
            "slug": "2c2deb5a-f19f-4823-9741-a211ab823e9d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/19",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/union-market/320-florida-ave-ne/",
        "index": 118.225,
        "franchise-entity-name": "G3 FITNESS GROUP VI LLC"
    },
    "65d87cc6-278e-48b7-9ef0-d0a3f95b7517": {
        "name": "Orange Theory Union-Florence, KY",
        "address": "9031 US Highway 42, Suite C Union, Kentucky 41091",
        "phone": "(859) 305-1600",
        "email": "studiomanager1118@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:25PM",
            "TUESDAY": "5:00AM - 8:25PM",
            "WEDNESDAY": "5:00AM - 8:25PM",
            "THURSDAY": "5:00AM - 8:25PM",
            "FRIDAY": "5:00AM - 8:25PM",
            "SATURDAY": "7:00AM - 1:05PM",
            "SUNDAY": "7:00AM - 1:05PM"
        },
        "geolocation": [
            38.9623299,
            -84.679459
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 4.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.1,
                    "duration": "5 Months",
                    "model_conf": 153.58
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 6.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.98,
                    "duration": "indefinitely",
                    "model_conf": 185.07
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 10.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.14,
                    "duration": "1 Months",
                    "model_conf": 111.77
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 11.94,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 21.54,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 29.94,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Union-Florence, KY",
            "slug": "65d87cc6-278e-48b7-9ef0-d0a3f95b7517"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/18/19",
        "url": "https://www.orangetheory.com/en-us/locations/kentucky/union/9031-us-highway-42-suite-c/",
        "index": 93.4,
        "franchise-entity-name": "S&amp;C Fitness III, LLC"
    },
    "a5af1625-5a47-4904-ad05-5b4b89e0790d": {
        "name": "Orange Theory University City, PA",
        "address": "3711 Market Street University City, Pennsylvania 19104",
        "phone": "(215) 454-2996",
        "email": "studiomanager0574@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            39.9567032,
            -75.197113
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.4,
                    "duration": "indefinitely",
                    "model_conf": 152.39
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 157.58,
                    "duration": "3 Months",
                    "model_conf": 183.2
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.9,
                    "duration": "indefinitely",
                    "model_conf": 109.84
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "University City, PA",
            "slug": "a5af1625-5a47-4904-ad05-5b4b89e0790d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/18/19",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/university-city/3711-market-street/",
        "index": 118.225,
        "franchise-entity-name": "F.O.L. FITNESS III, LLC"
    },
    "22d829d2-c4e9-41fe-b394-9e5f341b498b": {
        "name": "Orange Theory Live Oak",
        "address": "3150 Pat Booker Road University City, Texas 78148",
        "phone": "(726) 444-1821",
        "email": "studiomanager1246@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            29.5618725,
            -98.326126
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.72,
                    "duration": "1 Months",
                    "model_conf": 151.52
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.2,
                    "duration": "indefinitely",
                    "model_conf": 182.13
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.97,
                    "duration": "indefinitely",
                    "model_conf": 110.83
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Live Oak",
            "slug": "22d829d2-c4e9-41fe-b394-9e5f341b498b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/20/19",
        "url": "https://www.orangetheory.com/en-us/locations/texas/university-city/3150-pat-booker-road/",
        "index": 103.375,
        "franchise-entity-name": "SAN ANTONIO AREA LLC -1246"
    },
    "0d82bcac-10f1-4ce6-bc2a-4a8c9c63cdff": {
        "name": "Orange Theory Vacaville, CA",
        "address": "2080-B Harbison Drive Vacaville, California 95687",
        "phone": "(707) 474-8844",
        "email": "studiomanager0917@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 7:45PM",
            "TUESDAY": "4:30AM - 7:45PM",
            "WEDNESDAY": "4:30AM - 7:45PM",
            "THURSDAY": "4:30AM - 7:45PM",
            "FRIDAY": "4:30AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            38.3627129,
            -121.96209
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.29,
                    "duration": "3 Months",
                    "model_conf": 152.29
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.52,
                    "duration": "indefinitely",
                    "model_conf": 184.89
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.51,
                    "duration": "1 Months",
                    "model_conf": 112.05
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Vacaville, CA",
            "slug": "0d82bcac-10f1-4ce6-bc2a-4a8c9c63cdff"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/21/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/vacaville/2080-b-harbison-drive/",
        "index": 103.375,
        "franchise-entity-name": "TANNER FITNESS VACAVILLE LLC"
    },
    "4f556963-6682-4315-84e5-73a602fad78f": {
        "name": "Orange Theory Valdosta, GA",
        "address": "3268 Inner Perimeter Road, Unit B Valdosta, Georgia 31602",
        "phone": "(229) 474-3804",
        "email": "studiomanager0272@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            30.8807812,
            -83.287727
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.99,
                    "duration": "indefinitely",
                    "model_conf": 153.26
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.98,
                    "duration": "indefinitely",
                    "model_conf": 185.79
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.0,
                    "duration": "indefinitely",
                    "model_conf": 112.0
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Valdosta, GA",
            "slug": "4f556963-6682-4315-84e5-73a602fad78f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/19",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/valdosta/3268-inner-perimeter-road-unit-b/",
        "index": 98.425,
        "franchise-entity-name": "VVDC LAWRENCEVILLE, LLC"
    },
    "ded670ca-178a-4423-ac68-e06ac8b5e227": {
        "name": "Orange Theory Vallejo, CA",
        "address": "173 Plaza Dr. #1001 Vallejo, California 94591",
        "phone": "(707) 366-0999",
        "email": "studiomanager0889@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:10PM",
            "TUESDAY": "5:00AM - 7:10PM",
            "WEDNESDAY": "5:00AM - 7:10PM",
            "THURSDAY": "5:00AM - 7:10PM",
            "FRIDAY": "5:00AM - 7:10PM",
            "SATURDAY": "7:15AM - 10:35AM",
            "SUNDAY": "7:15AM - 10:35AM"
        },
        "geolocation": [
            38.133152,
            -122.21837
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.76,
                    "duration": "indefinitely",
                    "model_conf": 153.39
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.69,
                    "duration": "indefinitely",
                    "model_conf": 185.58
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.65,
                    "duration": "indefinitely",
                    "model_conf": 111.84
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Vallejo, CA",
            "slug": "ded670ca-178a-4423-ac68-e06ac8b5e227"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/9/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/vallejo/173-plaza-dr-1002/",
        "index": 108.325,
        "franchise-entity-name": "SFFIT VAL LLC"
    },
    "b44e552c-e6c4-4e0f-af27-f7c0c9c2d232": {
        "name": "Orange Theory Valparaiso",
        "address": "510 Ports Vale Blvd., Suite 120 Valparaiso, Indiana 46383",
        "phone": "(219) 230-9098",
        "email": "studiomanager1168@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            41.4730911,
            -87.013672
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.45,
                    "duration": "3 Months",
                    "model_conf": 153.82
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.91,
                    "duration": "indefinitely",
                    "model_conf": 185.77
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.14,
                    "duration": "1 Months",
                    "model_conf": 112.01
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Valparaiso",
            "slug": "b44e552c-e6c4-4e0f-af27-f7c0c9c2d232"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/31/18",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/valparaiso/510-ports-vale-blvd-suite-120/",
        "index": 93.4,
        "franchise-entity-name": "CO FITNESS OF VAL PO LLC"
    },
    "a472d165-aed8-4b29-b399-1bcccc85bc51": {
        "name": "Orange Theory Lithia, FL",
        "address": "3311 Lithia Pine Crest Road Valrico, Florida 33594",
        "phone": "(813) 743-4683",
        "email": "studiomanager0931@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            27.896157,
            -82.243898
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.24,
                    "duration": "indefinitely",
                    "model_conf": 153.42
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.73,
                    "duration": "indefinitely",
                    "model_conf": 184.36
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.09,
                    "duration": "indefinitely",
                    "model_conf": 111.73
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Lithia, FL",
            "slug": "a472d165-aed8-4b29-b399-1bcccc85bc51"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/1/18",
        "url": "https://www.orangetheory.com/en-us/locations/florida/valrico/3313-lithia-pinecrest-rd/",
        "index": 98.425,
        "franchise-entity-name": "LITHIA COACHING, LLC"
    },
    "39a4704a-ad30-4194-b236-1d5e50e61ad7": {
        "name": "Orange Theory EAST VANCOUVER/CAMAS, WA",
        "address": "530 SE 192nd Ave #104 Vancouver, Oregon 98683",
        "phone": "(360) 524-4012",
        "email": "studiomanager0512@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 12:30PM",
            "SUNDAY": "6:00AM - 12:30PM"
        },
        "geolocation": [
            45.6172257,
            -122.47663
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.87,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.83,
                    "duration": "indefinitely",
                    "model_conf": 153.52
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 10.35,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.13,
                    "duration": "2 Months",
                    "model_conf": 181.54
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 15.57,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.67,
                    "duration": "indefinitely",
                    "model_conf": 111.25
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 19.58,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 32.62,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 43.41,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "EAST VANCOUVER/CAMAS, WA",
            "slug": "39a4704a-ad30-4194-b236-1d5e50e61ad7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/7/20",
        "url": "https://www.orangetheory.com/en-us/locations/washington/vancouver/530-se-192nd-ave-104/",
        "index": 98.425,
        "franchise-entity-name": "FITZONE 4, LLC"
    },
    "86d47c4f-45b0-48ef-a9f2-40f238f926d4": {
        "name": "Orange Theory Hazel Dell, WA",
        "address": "7902 NE 6th Avenue Suite 104 Vancouver, Oregon 98665",
        "phone": "(360) 851-1108",
        "email": "studiomanager0513@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:15AM",
            "SUNDAY": "7:00AM - 11:15AM"
        },
        "geolocation": [
            45.6793709,
            -122.66699
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.64,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 80.27,
                    "duration": "indefinitely",
                    "model_conf": 153.51
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 10,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.76,
                    "duration": "4 Months",
                    "model_conf": 183.02
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 15.04,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.98,
                    "duration": "indefinitely",
                    "model_conf": 111.09
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 18.9,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 31.5,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 41.92,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Hazel Dell, WA",
            "slug": "86d47c4f-45b0-48ef-a9f2-40f238f926d4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/9/18",
        "url": "https://www.orangetheory.com/en-us/locations/washington/vancouver/7902-ne-6th-avenue-suite-104/",
        "index": 98.425,
        "franchise-entity-name": "JM VANCOUVER, LLC"
    },
    "2ffdf311-bd3e-48c7-aabc-634856fefabb": {
        "name": "Orange Theory Vancouver Waterfront, WA",
        "address": "741 W Columbia Way Vancouver, Washington 98660",
        "phone": "(360) 602-6444",
        "email": "studiomanager1458@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            45.6243515,
            -122.67976
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 6.72,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.63,
                    "duration": "indefinitely",
                    "model_conf": 153.59
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 10.12,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.59,
                    "duration": "1 Months",
                    "model_conf": 182.22
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 15.22,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.85,
                    "duration": "indefinitely",
                    "model_conf": 111.23
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 19.12,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 31.88,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 42.42,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Vancouver Waterfront, WA",
            "slug": "2ffdf311-bd3e-48c7-aabc-634856fefabb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/31/22",
        "url": "https://www.orangetheory.com/en-us/locations/washington/vancouver/700-waterfront-way/",
        "index": 98.425,
        "franchise-entity-name": "JM Vancouver Waterfront LLC"
    },
    "4eb38b9e-9744-4774-a49d-2908aff25481": {
        "name": "Orange Theory Ventura, CA",
        "address": "4360-B1 E Main St Ventura, California 93001",
        "phone": "(805) 507-8080",
        "email": "studiomanager1032@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:30AM",
            "SUNDAY": "7:00AM - 11:30AM"
        },
        "geolocation": [
            34.2620049,
            -119.23348
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.14,
                    "duration": "indefinitely",
                    "model_conf": 152.99
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.29,
                    "duration": "indefinitely",
                    "model_conf": 184.52
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.06,
                    "duration": "indefinitely",
                    "model_conf": 111.81
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Ventura, CA",
            "slug": "4eb38b9e-9744-4774-a49d-2908aff25481"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/11/19",
        "url": "https://www.orangetheory.com/en-us/locations/california/ventura/4360-b1-e-main-st/",
        "index": 103.375,
        "franchise-entity-name": "MAXX FITNESS LLC"
    },
    "4f4684c8-fa78-4d6a-b8ef-a19137998b3a": {
        "name": "Orange Theory Vernon Hills, IL",
        "address": "700 N Milwaukee, Suite 139 Vernon Hills, Illinois 60061",
        "phone": "(847) 786-5545",
        "email": "studiomanager0987@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 1:00PM",
            "SUNDAY": "6:00AM - 1:00PM"
        },
        "geolocation": [
            42.2399521,
            -87.945946
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 90.69,
                    "duration": "indefinitely",
                    "model_conf": 143.85
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 127.48,
                    "duration": "4 Months",
                    "model_conf": 174.74
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 190.32,
                    "duration": "indefinitely",
                    "model_conf": 105.73
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Vernon Hills, IL",
            "slug": "4f4684c8-fa78-4d6a-b8ef-a19137998b3a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/vernon-hills/700-n-milwaukee-suite-139/",
        "index": 103.375,
        "franchise-entity-name": "PROVIDENCE VENTURE STUDIO #6 LLC"
    },
    "50d8ae92-c65f-4de8-a5c6-61b682415d85": {
        "name": "Orange Theory Vero Beach, FL",
        "address": "5240 US HWY 1 Vero Beach, Florida 32967",
        "phone": "(772) 205-3733",
        "email": "studiomanager0606@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            27.6922264,
            -80.412117
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.39,
                    "duration": "indefinitely",
                    "model_conf": 153.18
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.23,
                    "duration": "2 Months",
                    "model_conf": 185.46
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.12,
                    "duration": "indefinitely",
                    "model_conf": 112.09
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Vero Beach, FL",
            "slug": "50d8ae92-c65f-4de8-a5c6-61b682415d85"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/24/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/vero-beach/5240-us-hwy-1/",
        "index": 93.4,
        "franchise-entity-name": "CAPN FITNESS LLC"
    },
    "a84c97e7-a552-44d1-8d0e-fc09ea945a47": {
        "name": "Orange Theory Vestavia Hills, AL",
        "address": "790 Montgomery Hwy, Suite 132 Vestavia Hills, Alabama 35216",
        "phone": "(205) 823-5700",
        "email": "studiomanager0564@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:30AM",
            "SUNDAY": "7:30AM - 11:30AM"
        },
        "geolocation": [
            33.4442139,
            -86.792999
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 67.6,
                    "duration": "7 Months",
                    "model_conf": 151.13
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.28,
                    "duration": "indefinitely",
                    "model_conf": 185.37
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 167.77,
                    "duration": "2 Months",
                    "model_conf": 111.76
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Vestavia Hills, AL",
            "slug": "a84c97e7-a552-44d1-8d0e-fc09ea945a47"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/24/16",
        "url": "https://www.orangetheory.com/en-us/locations/alabama/vestavia-hills/790-montgomery-hwy-suite-132/",
        "index": 93.4,
        "franchise-entity-name": "NICHEFITNESS4, LLC"
    },
    "9259605b-e326-4b42-a1bc-d7a1ad8e874e": {
        "name": "Orange Theory Victor, NY",
        "address": "400 Commerce Dr. Suite 900 Victor, New York 14564",
        "phone": "(585) 207-0202",
        "email": "studiomanager0915@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            43.0182686,
            -77.437447
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.02,
                    "duration": "indefinitely",
                    "model_conf": 152.9
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.67,
                    "duration": "1 Months",
                    "model_conf": 184.98
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.01,
                    "duration": "indefinitely",
                    "model_conf": 112.04
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Victor, NY",
            "slug": "9259605b-e326-4b42-a1bc-d7a1ad8e874e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/21/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/victor/400-commerce-dr-suite-900/",
        "index": 98.425,
        "franchise-entity-name": "VICTOR FITNESS, LLC"
    },
    "5f3b7284-e7fc-4af4-b2c6-626fd68f5051": {
        "name": "Orange Theory Victory Park, TX",
        "address": "625 High Market Street, Suite 100 Victory Park, Texas 75219",
        "phone": "(972) 474-0420",
        "email": "studiomanager1028@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 9:00PM",
            "TUESDAY": "4:30AM - 9:00PM",
            "WEDNESDAY": "4:30AM - 9:00PM",
            "THURSDAY": "4:30AM - 9:00PM",
            "FRIDAY": "4:30AM - 9:00PM",
            "SATURDAY": "7:00AM - 2:10PM",
            "SUNDAY": "7:00AM - 2:10PM"
        },
        "geolocation": [
            32.7863083,
            -96.808998
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.25,
                    "duration": "3 Months",
                    "model_conf": 149.97
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.83,
                    "duration": "indefinitely",
                    "model_conf": 179.5
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.69,
                    "duration": "indefinitely",
                    "model_conf": 110.05
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Victory Park, TX",
            "slug": "5f3b7284-e7fc-4af4-b2c6-626fd68f5051"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/3/18",
        "url": "https://www.orangetheory.com/en-us/locations/texas/victory-park/625-high-market-street-suite-100/",
        "index": 103.375,
        "franchise-entity-name": "VALENCIA FITNESS-VICTORY PARK, LLC"
    },
    "62879aed-ecb9-4146-8f8a-d0b48ee0db69": {
        "name": "Orange Theory Vienna, VA",
        "address": "127 Maple Avenue W Vienna, Virginia 22180",
        "phone": "(571) 367-9931",
        "email": "studiomanager1113@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:30PM",
            "TUESDAY": "5:00AM - 8:30PM",
            "WEDNESDAY": "5:00AM - 8:30PM",
            "THURSDAY": "5:00AM - 8:30PM",
            "FRIDAY": "5:00AM - 8:30PM",
            "SATURDAY": "6:45AM - 11:45AM",
            "SUNDAY": "6:45AM - 11:45AM"
        },
        "geolocation": [
            38.9011726,
            -77.266235
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.42,
                    "duration": "indefinitely",
                    "model_conf": 113.65
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.31,
                    "duration": "2 Months",
                    "model_conf": 138.23
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.05,
                    "duration": "indefinitely",
                    "model_conf": 84.99
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Vienna, VA",
            "slug": "62879aed-ecb9-4146-8f8a-d0b48ee0db69"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/8/18",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/vienna/127-maple-avenue-w/",
        "index": 98.425,
        "franchise-entity-name": "Vienna Fitness LLC"
    },
    "466d2a84-4fcc-42d0-a3b1-1d1390093a49": {
        "name": "Orange Theory Dunn Loring, VA",
        "address": "2672 Avenir Place, Q Vienna, Virginia 22180",
        "phone": "(703) 222-2651",
        "email": "studiomanager0375@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            38.8817711,
            -77.230141
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.26,
                    "duration": "indefinitely",
                    "model_conf": 114.45
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.66,
                    "duration": "1 Months",
                    "model_conf": 141.41
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.82,
                    "duration": "indefinitely",
                    "model_conf": 86.3
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Dunn Loring, VA",
            "slug": "466d2a84-4fcc-42d0-a3b1-1d1390093a49"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/14/15",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/vienna/2672-avenir-place-q/",
        "index": 98.425,
        "franchise-entity-name": "HH FITNESS DUNN LORING, LLC"
    },
    "d36bea52-94dd-45bb-84aa-1edd550f29b3": {
        "name": "Orange Theory Vinings, GA",
        "address": "4300 Paces Ferry Rd SE Suite 240 Vinings, Georgia 30339",
        "phone": "(770) 727-5022",
        "email": "studiomanager1278@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            33.8657684,
            -84.466927
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.12,
                    "duration": "indefinitely",
                    "model_conf": 139.25
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.59,
                    "duration": "1 Months",
                    "model_conf": 170.94
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.16,
                    "duration": "indefinitely",
                    "model_conf": 103.18
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Vinings, GA",
            "slug": "d36bea52-94dd-45bb-84aa-1edd550f29b3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/29/19",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/vinings/4300-paces-ferry-rd-se-suite-240/",
        "index": 98.425,
        "franchise-entity-name": "JM VININGS, LLC"
    },
    "51c0ebb7-424d-4656-ae70-ad60dc274d2b": {
        "name": "Orange Theory Red Mill, VA",
        "address": "2111 Princess Anne Rd, Suite 106 Virginia Beach, Virginia 23456",
        "phone": "(757) 802-3280",
        "email": "studiomanager0634@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            36.7559662,
            -76.011536
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.85,
                    "duration": "indefinitely",
                    "model_conf": 152.44
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.19,
                    "duration": "indefinitely",
                    "model_conf": 184.99
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.63,
                    "duration": "indefinitely",
                    "model_conf": 111.47
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Red Mill, VA",
            "slug": "51c0ebb7-424d-4656-ae70-ad60dc274d2b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/5/16",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/virginia-beach/2111-princess-anne-rd-suite-106/",
        "index": 93.4,
        "franchise-entity-name": "DYER FITNESS, LLC"
    },
    "ee3060ee-3b72-468f-8716-c8d965a5e49f": {
        "name": "Orange Theory Virginia Beach - Hilltop, VA",
        "address": "737 First Colonial Road #216 Virginia Beach, Virginia 23451",
        "phone": "(757) 828-1228",
        "email": "studiomanager0913@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            36.8514748,
            -76.02494
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.6,
                    "duration": "2 Months",
                    "model_conf": 148.23
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.15,
                    "duration": "indefinitely",
                    "model_conf": 182.65
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.45,
                    "duration": "1 Months",
                    "model_conf": 108.73
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Virginia Beach - Hilltop, VA",
            "slug": "ee3060ee-3b72-468f-8716-c8d965a5e49f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/8/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/virginia-beach/737-first-colonial-road-216/",
        "index": 93.4,
        "franchise-entity-name": "DYER FITNESS 2, LLC"
    },
    "90bb967f-4f74-4fba-9565-5141c4fabc17": {
        "name": "Orange Theory Voorhees, NJ",
        "address": "148 Route 73 Suite 4 Voorhees Township, New Jersey 08043",
        "phone": "(856) 504-8286",
        "email": "studiomanager0902@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:15PM",
            "TUESDAY": "5:00AM - 8:15PM",
            "WEDNESDAY": "5:00AM - 8:15PM",
            "THURSDAY": "5:00AM - 8:15PM",
            "FRIDAY": "5:00AM - 8:15PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            39.8349266,
            -74.925209
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.5,
                    "duration": "indefinitely",
                    "model_conf": 152.32
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.48,
                    "duration": "1 Months",
                    "model_conf": 177.34
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.14,
                    "duration": "indefinitely",
                    "model_conf": 104.76
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Voorhees, NJ",
            "slug": "90bb967f-4f74-4fba-9565-5141c4fabc17"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/14/17",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/voorhees-township/148-route-73-suite-4/",
        "index": 98.425,
        "franchise-entity-name": "HAVEN MANAGEMENT, INC."
    },
    "ca2c9297-8c26-415c-98b6-55c6d76a21c8": {
        "name": "Orange Theory West Des Moines, IA",
        "address": "375 South Jordan Creek Parkway W. Des Moines, Iowa 50266",
        "phone": "(515) 421-8322",
        "email": "studiomanager1014@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:30PM",
            "SUNDAY": "6:30AM - 12:30PM"
        },
        "geolocation": [
            41.5622444,
            -93.809235
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.67,
                    "duration": "indefinitely",
                    "model_conf": 153.77
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.25,
                    "duration": "2 Months",
                    "model_conf": 183.72
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.55,
                    "duration": "indefinitely",
                    "model_conf": 111.89
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "West Des Moines, IA",
            "slug": "ca2c9297-8c26-415c-98b6-55c6d76a21c8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/8/18",
        "url": "https://www.orangetheory.com/en-us/locations/iowa/w-des-moines/375-south-jordan-creek-parkway/",
        "index": 93.4,
        "franchise-entity-name": "OT IOWA WEST DES MOINES, LLC"
    },
    "988466a0-4668-4fcc-b821-4dfe023b7b88": {
        "name": "Orange Theory West Melbourne, FL",
        "address": "1559 W. New Haven Avenue West Melbourne, Florida 32904",
        "phone": "(321) 369-9169",
        "email": "studiomanager0416@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:15AM",
            "SUNDAY": "7:00AM - 11:15AM"
        },
        "geolocation": [
            28.0773315,
            -80.648285
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.86,
                    "duration": "indefinitely",
                    "model_conf": 153.37
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.26,
                    "duration": "indefinitely",
                    "model_conf": 185.43
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.88,
                    "duration": "indefinitely",
                    "model_conf": 111.75
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "West Melbourne, FL",
            "slug": "988466a0-4668-4fcc-b821-4dfe023b7b88"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/8/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/west-melbourne/1559-w-new-haven-avenue/",
        "index": 93.4,
        "franchise-entity-name": "WEST MELBOURNE STUDIO LLC"
    },
    "c3130dbe-09d1-45e2-8b37-72b7043a15ad": {
        "name": "Orange Theory Woodbury, MN",
        "address": "530 Woodbury Drive, Suite 300 Woodbury, Minnesota 55125",
        "phone": "(612) 326-1234",
        "email": "studiomanager0351@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:00AM - 12:15PM",
            "SUNDAY": "6:00AM - 12:15PM"
        },
        "geolocation": [
            44.9426613,
            -92.903503
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.2,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.59,
                    "duration": "2 Months",
                    "model_conf": 148.57
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.05,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 98.42,
                    "duration": "2 Months",
                    "model_conf": 173.39
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.33,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.27,
                    "duration": "1 Months",
                    "model_conf": 102.01
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.18,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 25.58,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 35.55,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Woodbury, MN",
            "slug": "c3130dbe-09d1-45e2-8b37-72b7043a15ad"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/31/17",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/woodbury/530-woodbury-drive-suite-300/",
        "index": 88.45,
        "franchise-entity-name": "WOODBURY FITNESS LLC"
    },
    "4949b598-2b5c-4f20-97b2-641e48b6d780": {
        "name": "Orange Theory Woodland Hills, CA",
        "address": "21841 Ventura Fwy Woodland Hills, California 91367",
        "phone": "(818) 805-0900",
        "email": "studiomanager0414@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            34.1692696,
            -118.60276
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.85,
                    "duration": "indefinitely",
                    "model_conf": 153.42
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.87,
                    "duration": "indefinitely",
                    "model_conf": 185.5
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.96,
                    "duration": "indefinitely",
                    "model_conf": 111.53
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Woodland Hills, CA",
            "slug": "4949b598-2b5c-4f20-97b2-641e48b6d780"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/10/18",
        "url": "https://www.orangetheory.com/en-us/locations/california/woodland-hills/21841-ventura-fwy/",
        "index": 108.325,
        "franchise-entity-name": "LA BIRER GROUP, INC. AND/OR ITS CORPORATE ASSIGNEE"
    },
    "9d0621af-9234-4314-a132-e9b2dc387602": {
        "name": "Orange Theory Waco, TX",
        "address": "1428 Wooded Acres Dr., Suite 116 Waco, Texas 76710",
        "phone": "(254) 265-7222",
        "email": "studiomanager0651@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            31.5334568,
            -97.190666
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 58.41,
                    "duration": "3 Months",
                    "model_conf": 142.46
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 99.36,
                    "duration": "indefinitely",
                    "model_conf": 116.43
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 158.08,
                    "duration": "2 Months",
                    "model_conf": 106.13
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "Waco, TX",
            "slug": "9d0621af-9234-4314-a132-e9b2dc387602"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/5/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/waco/1428-wooded-acres-dr-suite-116/",
        "index": 88.45,
        "franchise-entity-name": "KMM FITNESS I LLC"
    },
    "07efd5ab-b59c-493d-b481-e75bad731f87": {
        "name": "Orange Theory St. Cloud, MN",
        "address": "110 2nd St., Suite 117 Waite Park, Minnesota 56387",
        "phone": "(320) 270-7000",
        "email": "studiomanager0825@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            45.5485344,
            -94.216888
        ],
        "memberships": {
            "Basic": {
                "cost": 59,
                "tax-amount": 4.35,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 60.15,
                    "duration": "indefinitely",
                    "model_conf": 153.66
                }
            },
            "Elite": {
                "cost": 99,
                "tax-amount": 7.3,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 97.1,
                    "duration": "7 Months",
                    "model_conf": 185.81
                }
            },
            "Premier": {
                "cost": 159,
                "tax-amount": 11.73,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 159.77,
                    "duration": "indefinitely",
                    "model_conf": 112.01
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.68,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 26.48,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 36.8,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 149,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 159,
            "basic": 59,
            "elite-family-addon-6-month-corp-2": 89,
            "elite": 99,
            "additional-session-2": 18,
            "name": "St. Cloud, MN",
            "slug": "07efd5ab-b59c-493d-b481-e75bad731f87"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/3/16",
        "url": "https://www.orangetheory.com/en-us/locations/minnesota/waite-park/110-2nd-st-suite-117/",
        "index": 88.45,
        "franchise-entity-name": "ST. CLOUD FITNESS MN LLC"
    },
    "05b24050-32c0-4195-ad26-fb071c883502": {
        "name": "Orange Theory Wake Forest, NC",
        "address": "12646 Capital Blvd., Suite 152 Wake Forest, North Carolina 27587",
        "phone": "(919) 761-7778",
        "email": "studiomanager0420@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            35.9870262,
            -78.53019
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.66,
                    "duration": "1 Months",
                    "model_conf": 149.97
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.62,
                    "duration": "indefinitely",
                    "model_conf": 181.93
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.48,
                    "duration": "1 Months",
                    "model_conf": 110.52
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Wake Forest, NC",
            "slug": "05b24050-32c0-4195-ad26-fb071c883502"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/18/15",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/wake-forest/12646-capital-blvd-suite-152/",
        "index": 98.425,
        "franchise-entity-name": "STRENGTH OF LIFE, LLC"
    },
    "e75306bd-5143-4b74-b28e-da4090f43c4c": {
        "name": "Orange Theory Walnut Creek, CA",
        "address": "1531 Locust Street Walnut Creek, California 94596",
        "phone": "(925) 278-6313",
        "email": "studiomanager0356@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            37.9002571,
            -122.06289
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.14,
                    "duration": "indefinitely",
                    "model_conf": 153.05
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.24,
                    "duration": "indefinitely",
                    "model_conf": 182.55
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.48,
                    "duration": "1 Months",
                    "model_conf": 110.82
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Walnut Creek, CA",
            "slug": "e75306bd-5143-4b74-b28e-da4090f43c4c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/30/15",
        "url": "https://www.orangetheory.com/en-us/locations/california/walnut-creek/1531-locust-street/",
        "index": 108.325,
        "franchise-entity-name": "KOMA VENTURES II, LLC"
    },
    "39f4f3dc-93cc-4690-9022-ff8c8d76f388": {
        "name": "Orange Theory Walpole, MA",
        "address": "985 Old Post Rd. Walpole, Massachusetts 02081",
        "phone": "(508) 505-4213",
        "email": "studiomanager0870@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 8:00PM",
            "TUESDAY": "4:30AM - 8:00PM",
            "WEDNESDAY": "4:30AM - 8:00PM",
            "THURSDAY": "4:30AM - 8:00PM",
            "FRIDAY": "4:30AM - 8:00PM",
            "SATURDAY": "6:00AM - 11:00AM",
            "SUNDAY": "6:00AM - 11:00AM"
        },
        "geolocation": [
            42.1244316,
            -71.232636
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.63,
                    "duration": "1 Months",
                    "model_conf": 151.9
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.9,
                    "duration": "indefinitely",
                    "model_conf": 184.18
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.6,
                    "duration": "indefinitely",
                    "model_conf": 111.24
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Walpole, MA",
            "slug": "39f4f3dc-93cc-4690-9022-ff8c8d76f388"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/6/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/walpole/985-old-post-rd/",
        "index": 103.375,
        "franchise-entity-name": "MK FITNESS, LLC"
    },
    "a384879f-56a1-4d82-aefe-26e657d7ac25": {
        "name": "Orange Theory Waltham, MA",
        "address": "500 Totten Pond Rd Waltham, Massachusetts 02451",
        "phone": "(781) 786-3145",
        "email": "studiomanager0846@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            42.3964729,
            -71.259117
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.29,
                    "duration": "7 Months",
                    "model_conf": 151.65
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 131.25,
                    "duration": "indefinitely",
                    "model_conf": 184.39
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.95,
                    "duration": "indefinitely",
                    "model_conf": 111.5
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Waltham, MA",
            "slug": "a384879f-56a1-4d82-aefe-26e657d7ac25"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/2/18",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/waltham/500-totten-pond-rd/",
        "index": 103.375,
        "franchise-entity-name": "QIG FITNESS WALTHAM LLC"
    },
    "9783527d-d62f-4df5-92be-8f21161211ec": {
        "name": "Orange Theory Warner Robins, GA",
        "address": "3030 Watson Blvd, Suite 900 Warner Robins, Georgia 31093",
        "phone": "(478) 845-6007",
        "email": "studiomanager1138@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:00PM",
            "SUNDAY": "7:30AM - 12:00PM"
        },
        "geolocation": [
            32.6189194,
            -83.691803
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.0,
                    "duration": "indefinitely",
                    "model_conf": 153.59
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.97,
                    "duration": "indefinitely",
                    "model_conf": 185.05
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.99,
                    "duration": "indefinitely",
                    "model_conf": 112.09
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Warner Robins, GA",
            "slug": "9783527d-d62f-4df5-92be-8f21161211ec"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/13/18",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/warner-robins/3030-watson-blvd-suite-900/",
        "index": 98.425,
        "franchise-entity-name": "TOP GUNN FITNESS LLC"
    },
    "112bd9d2-a392-407f-b302-0da3cdc59c07": {
        "name": "Orange Theory Warren, NJ",
        "address": "177 Washington Valley Rd Warren, New Jersey 07059",
        "phone": "(908) 864-5293",
        "email": "studiomanager1563@orangetheoryfitness.com",
        "hours": {},
        "geolocation": [
            40.615204,
            -74.495823
        ],
        "memberships": {},
        "services": {},
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Warren, NJ",
            "slug": "112bd9d2-a392-407f-b302-0da3cdc59c07"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": true,
        "opening_date": "6/30/23",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/warren/177-washington-valley-rd",
        "index": 0,
        "franchise-entity-name": ""
    },
    "576c787f-dcdb-4687-b826-6079f88e07cd": {
        "name": "Orange Theory Doylestown-Warrington, PA",
        "address": "1661 Easton Road Warrington, Pennsylvania 18976",
        "phone": "(215) 792-7952",
        "email": "studiomanager0234@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.2681503,
            -75.129211
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.93,
                    "duration": "4 Months",
                    "model_conf": 149.64
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.26,
                    "duration": "indefinitely",
                    "model_conf": 180.46
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.15,
                    "duration": "1 Months",
                    "model_conf": 108.12
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Doylestown-Warrington, PA",
            "slug": "576c787f-dcdb-4687-b826-6079f88e07cd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/warrington/1661-easton-road/",
        "index": 103.375,
        "franchise-entity-name": "F2 FITNESS, LLC"
    },
    "29357110-6157-4a16-a4fe-6686ab65bb0f": {
        "name": "Orange Theory Foggy Bottom, DC",
        "address": "2401 Pennsylvania Avenue NW Washington D.C., District Of Columbia 20037",
        "phone": "(202) 868-5980",
        "email": "studiomanager1105@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 8:00PM",
            "TUESDAY": "6:15AM - 8:00PM",
            "WEDNESDAY": "6:15AM - 8:00PM",
            "THURSDAY": "6:15AM - 8:00PM",
            "FRIDAY": "6:15AM - 8:00PM",
            "SATURDAY": "8:00AM - 12:45PM",
            "SUNDAY": "8:00AM - 12:45PM"
        },
        "geolocation": [
            38.9034195,
            -77.051964
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.69,
                    "duration": "indefinitely",
                    "model_conf": 107.78
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 159.23,
                    "duration": "indefinitely",
                    "model_conf": 128.63
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.06,
                    "duration": "indefinitely",
                    "model_conf": 79.3
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Foggy Bottom, DC",
            "slug": "29357110-6157-4a16-a4fe-6686ab65bb0f"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/12/18",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/washington-dc/2401-pennsylvania-avenue-nw/",
        "index": 118.225,
        "franchise-entity-name": "FOGGY BOTTOM FITNESS LLC"
    },
    "63136ab5-90c5-4012-b76a-6e15a71dbcef": {
        "name": "Orange Theory The Wharf",
        "address": "798 Maine Avenue SW Washington D.C., District Of Columbia 20024",
        "phone": "(202) 227-2794",
        "email": "studiomanager1262@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:05AM - 7:30PM",
            "TUESDAY": "6:05AM - 7:30PM",
            "WEDNESDAY": "6:05AM - 7:30PM",
            "THURSDAY": "6:05AM - 7:30PM",
            "FRIDAY": "6:05AM - 7:30PM",
            "SATURDAY": "9:40AM - 11:45AM",
            "SUNDAY": "9:40AM - 11:45AM"
        },
        "geolocation": [
            38.8794823,
            -77.024261
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.07,
                    "duration": "indefinitely",
                    "model_conf": 105.95
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.49,
                    "duration": "1 Months",
                    "model_conf": 126.41
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.0,
                    "duration": "indefinitely",
                    "model_conf": 79.07
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "The Wharf",
            "slug": "63136ab5-90c5-4012-b76a-6e15a71dbcef"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/12/19",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/washington-dc/798-maine-avenue-sw/",
        "index": 118.225,
        "franchise-entity-name": "MDMS CAPITAL DC2, L.L.C."
    },
    "c9efd7a8-dde6-42cd-9d5f-5fa33cb6f913": {
        "name": "Orange Theory Thomas Circle, DC",
        "address": "1101 14th Street NW Washington Dc, District Of Columbia 20005",
        "phone": "(202) 919-5500",
        "email": "studiomanager1101@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:15AM - 7:30PM",
            "TUESDAY": "6:15AM - 7:30PM",
            "WEDNESDAY": "6:15AM - 7:30PM",
            "THURSDAY": "6:15AM - 7:30PM",
            "FRIDAY": "6:15AM - 7:30PM",
            "SATURDAY": "8:45AM - 12:15PM",
            "SUNDAY": "8:45AM - 12:15PM"
        },
        "geolocation": [
            38.9040871,
            -77.031532
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.2,
                    "duration": "indefinitely",
                    "model_conf": 102.32
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 159.09,
                    "duration": "indefinitely",
                    "model_conf": 122.1
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.79,
                    "duration": "indefinitely",
                    "model_conf": 76.69
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Thomas Circle, DC",
            "slug": "c9efd7a8-dde6-42cd-9d5f-5fa33cb6f913"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/20/19",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/washington-dc/1101-14th-street-nw/",
        "index": 118.225,
        "franchise-entity-name": "HH FITNESS THOMAS CIRCLE, LLC"
    },
    "35b8e495-3dd9-4a6a-ae1c-c463f04816bb": {
        "name": "Orange Theory H Street, DC",
        "address": "609 H Street NE Washington Dc, District Of Columbia 20002",
        "phone": "(202) 991-5898",
        "email": "studiomanager1094@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "8:00AM - 1:00PM",
            "SUNDAY": "8:00AM - 1:00PM"
        },
        "geolocation": [
            38.9000664,
            -76.997902
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.43,
                    "duration": "indefinitely",
                    "model_conf": 112.85
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.86,
                    "duration": "indefinitely",
                    "model_conf": 134.96
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.06,
                    "duration": "indefinitely",
                    "model_conf": 84.41
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "H Street, DC",
            "slug": "35b8e495-3dd9-4a6a-ae1c-c463f04816bb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/16/19",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/washington-dc/609-h-street-ne/",
        "index": 118.225,
        "franchise-entity-name": "LZNF Ventures LLC"
    },
    "f627d35c-9e2b-452a-8017-bfbcccff5a4d": {
        "name": "Orange Theory 14th Street, DC",
        "address": "1925 14th Street NW Suite C Washington, District Of Columbia 20009",
        "phone": "(202) 869-1700",
        "email": "studiomanager0943@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "6:05AM - 8:30PM",
            "TUESDAY": "6:05AM - 8:30PM",
            "WEDNESDAY": "6:05AM - 8:30PM",
            "THURSDAY": "6:05AM - 8:30PM",
            "FRIDAY": "6:05AM - 8:30PM",
            "SATURDAY": "8:30AM - 1:00PM",
            "SUNDAY": "8:30AM - 1:00PM"
        },
        "geolocation": [
            38.9164734,
            -77.031975
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.14,
                    "duration": "indefinitely",
                    "model_conf": 109.35
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.57,
                    "duration": "1 Months",
                    "model_conf": 131.5
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.64,
                    "duration": "indefinitely",
                    "model_conf": 81.06
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "14th Street, DC",
            "slug": "f627d35c-9e2b-452a-8017-bfbcccff5a4d"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/2/17",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/washington/1925-14th-street-nwrnsuite-c/",
        "index": 118.225,
        "franchise-entity-name": "JKOLA DC FITNESS, LLC"
    },
    "204d5d22-c111-46e1-86e7-678c3512c980": {
        "name": "Orange Theory Cleveland Park",
        "address": "3412 Connecticut Avenue Northwest Washington, District Of Columbia 20008",
        "phone": "(202) 545-7811",
        "email": "studiomanager1158@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 2:00PM",
            "SUNDAY": "6:00AM - 2:00PM"
        },
        "geolocation": [
            38.9345512,
            -77.05822
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 118.74,
                    "duration": "indefinitely",
                    "model_conf": 119.74
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.42,
                    "duration": "1 Months",
                    "model_conf": 144.39
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.92,
                    "duration": "indefinitely",
                    "model_conf": 86.32
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Cleveland Park",
            "slug": "204d5d22-c111-46e1-86e7-678c3512c980"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/20/19",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/cleveland-park/3412-connecticut-avenue-nw/",
        "index": 118.225,
        "franchise-entity-name": "Mammoth Fitness DC 2, LLC"
    },
    "ccacdd6d-56c8-410b-8bd5-d863b4bf9193": {
        "name": "Orange Theory Mount Vernon Triangle / Shaw, DC",
        "address": "425 I Street, NW Washington, District Of Columbia 20001",
        "phone": "(202) 469-1035",
        "email": "studiomanager0437@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "8:00AM - 1:00PM",
            "SUNDAY": "8:00AM - 1:00PM"
        },
        "geolocation": [
            38.9016457,
            -77.016945
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 119.31,
                    "duration": "indefinitely",
                    "model_conf": 103.76
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.88,
                    "duration": "indefinitely",
                    "model_conf": 123.68
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 219.04,
                    "duration": "indefinitely",
                    "model_conf": 78.16
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Mount Vernon Triangle / Shaw, DC",
            "slug": "ccacdd6d-56c8-410b-8bd5-d863b4bf9193"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/19/16",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/washington/425-i-street-nw/",
        "index": 118.225,
        "franchise-entity-name": "S &amp; W FITNESS ENTERPRISE, LLC"
    },
    "1586633d-98a6-43fc-bac7-b7675693d140": {
        "name": "Orange Theory Tenleytown, DC",
        "address": "4600 Wisconsin Ave. NW #101 Washington, District Of Columbia 20016",
        "phone": "(202) 516-8147",
        "email": "studiomanager0903@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            38.9499435,
            -77.080826
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 118.36,
                    "duration": "2 Months",
                    "model_conf": 122.72
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 159.79,
                    "duration": "indefinitely",
                    "model_conf": 147.29
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.5,
                    "duration": "indefinitely",
                    "model_conf": 86.23
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Tenleytown, DC",
            "slug": "1586633d-98a6-43fc-bac7-b7675693d140"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/25/17",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/washington/4600-wisconsin-ave-nw-101/",
        "index": 118.225,
        "franchise-entity-name": "MAMMOTH FITNESS DC, LLC"
    },
    "3b36306d-4181-489c-898b-d61e0d21666a": {
        "name": "Orange Theory Navy Yard, DC",
        "address": "82 I Street SE Washington, District Of Columbia 20003",
        "phone": "(202) 688-0099",
        "email": "studiomanager0694@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:45AM",
            "SUNDAY": "7:30AM - 11:45AM"
        },
        "geolocation": [
            38.8798103,
            -77.005608
        ],
        "memberships": {
            "Basic": {
                "cost": 119,
                "tax-amount": 7.14,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 118.98,
                    "duration": "indefinitely",
                    "model_conf": 108.25
                }
            },
            "Elite": {
                "cost": 159,
                "tax-amount": 9.54,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 158.37,
                    "duration": "1 Months",
                    "model_conf": 128.28
                }
            },
            "Premier": {
                "cost": 219,
                "tax-amount": 13.14,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 218.83,
                    "duration": "indefinitely",
                    "model_conf": 80.93
                }
            }
        },
        "services": {
            "10 Session Pack - $280": {
                "cost": 280,
                "tax-amount": 16.8,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $540": {
                "cost": 540,
                "tax-amount": 32.4,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $750": {
                "cost": 750,
                "tax-amount": 45,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 280,
            "20-pack": 540,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 750,
            "premier": 219,
            "basic": 119,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 159,
            "additional-session-2": 22,
            "name": "Navy Yard, DC",
            "slug": "3b36306d-4181-489c-898b-d61e0d21666a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/23/17",
        "url": "https://www.orangetheory.com/en-us/locations/district-of-columbia/washington/82-i-street-se/",
        "index": 118.225,
        "franchise-entity-name": "MDMS CAPITAL DC1 LLC"
    },
    "33ba3f28-7ace-4b0a-8af4-091f89c01136": {
        "name": "Orange Theory Wauwatosa",
        "address": "7498 West State Street Wauwatosa, Wisconsin 53213",
        "phone": "(414) 409-7911",
        "email": "studiomanager1144@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45PM",
            "SUNDAY": "7:00AM - 11:45PM"
        },
        "geolocation": [
            43.0489616,
            -88.006096
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.32,
                    "duration": "3 Months",
                    "model_conf": 153.42
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 110.09,
                    "duration": "indefinitely",
                    "model_conf": 175.38
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.18,
                    "duration": "1 Months",
                    "model_conf": 110.03
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Wauwatosa",
            "slug": "33ba3f28-7ace-4b0a-8af4-091f89c01136"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/7/18",
        "url": "https://www.orangetheory.com/en-us/locations/wisconsin/wauwatosa/7498-west-state-street/",
        "index": 93.4,
        "franchise-entity-name": "OT WISCONSIN WAUWATOSA, LLC"
    },
    "bea0ac79-4026-48a8-9e9b-d02beda3c5d2": {
        "name": "Orange Theory Wayne, NJ",
        "address": "1160 Hamburg Turnpike, Store #4 Wayne, New Jersey 07470",
        "phone": "(973) 826-2788",
        "email": "studiomanager1206@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 12:45PM",
            "SUNDAY": "7:00AM - 12:45PM"
        },
        "geolocation": [
            40.9611282,
            -74.241623
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.62,
                    "duration": "6 Months",
                    "model_conf": 146.77
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 131.14,
                    "duration": "indefinitely",
                    "model_conf": 176.23
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.2,
                    "duration": "1 Months",
                    "model_conf": 109.66
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Wayne, NJ",
            "slug": "bea0ac79-4026-48a8-9e9b-d02beda3c5d2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/2/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/wayne/1160-hamburg-turnpike-store-4/",
        "index": 103.375,
        "franchise-entity-name": "EPOC FIVE LLC"
    },
    "9e6c176f-d0bc-4985-9df7-93e696ca50cd": {
        "name": "Orange Theory Devon, PA",
        "address": "821 W. Lancaster Ave Wayne, Pennsylvania 19087",
        "phone": "(610) 572-4789",
        "email": "studiomanager0539@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.0488434,
            -75.411201
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.91,
                    "duration": "indefinitely",
                    "model_conf": 150.05
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.15,
                    "duration": "2 Months",
                    "model_conf": 182.3
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.59,
                    "duration": "indefinitely",
                    "model_conf": 107.97
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Devon, PA",
            "slug": "9e6c176f-d0bc-4985-9df7-93e696ca50cd"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/26/17",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/wayne/821-w-lancaster-ave/",
        "index": 103.375,
        "franchise-entity-name": "5 WOOD, LLC"
    },
    "ec3e2743-772f-4230-9052-5171f700395e": {
        "name": "Orange Theory Baytowne",
        "address": "1900 Empire Blvd, Suite 125 Webster, New York 14580",
        "phone": "(585) 484-1355",
        "email": "studiomanager1220@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            43.1906586,
            -77.50312
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.33,
                    "duration": "3 Months",
                    "model_conf": 153.42
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.78,
                    "duration": "indefinitely",
                    "model_conf": 184.9
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.25,
                    "duration": "1 Months",
                    "model_conf": 112.03
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Baytowne",
            "slug": "ec3e2743-772f-4230-9052-5171f700395e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/12/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/webster/1900-empire-blvd-suite-125/",
        "index": 98.425,
        "franchise-entity-name": "BAYTOWNE FITNESS, LLC"
    },
    "5b2bee0d-94b5-4b7f-a4f9-8fbf9753582b": {
        "name": "Orange Theory Webster, TX",
        "address": "136 W. Bay Area Blvd Webster, Texas 77598",
        "phone": "(832) 598-7199",
        "email": "studiomanager0754@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            29.5491428,
            -95.130806
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.27,
                    "duration": "indefinitely",
                    "model_conf": 98.51
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.92,
                    "duration": "indefinitely",
                    "model_conf": 160.59
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.14,
                    "duration": "indefinitely",
                    "model_conf": 95.73
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Webster, TX",
            "slug": "5b2bee0d-94b5-4b7f-a4f9-8fbf9753582b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/23/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/webster/136-w-bay-area-blvd/",
        "index": 98.425,
        "franchise-entity-name": "WEBSTER COACHING LLC"
    },
    "0a963bab-5b7b-48c1-8749-dddc66630d78": {
        "name": "Orange Theory Wellesley, MA",
        "address": "56 Central St Wellesley, Massachusetts 02482",
        "phone": "(781) 431-8801",
        "email": "studiomanager1058@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            42.2959518,
            -71.296005
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.95,
                    "duration": "4 Months",
                    "model_conf": 151.59
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.94,
                    "duration": "indefinitely",
                    "model_conf": 185.05
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.56,
                    "duration": "indefinitely",
                    "model_conf": 111.77
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Wellesley, MA",
            "slug": "0a963bab-5b7b-48c1-8749-dddc66630d78"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/24/18",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/wellesley/56-central-st/",
        "index": 103.375,
        "franchise-entity-name": "PAR FIT LLC"
    },
    "acce4a1c-98ab-4b69-bc33-29fbf07a75ad": {
        "name": "Orange Theory Wellington, FL",
        "address": "2625 State Road 7 Wellington, Florida 33414",
        "phone": "(561) 296-0485",
        "email": "studiomanager0128@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:15AM - 8:00PM",
            "TUESDAY": "5:15AM - 8:00PM",
            "WEDNESDAY": "5:15AM - 8:00PM",
            "THURSDAY": "5:15AM - 8:00PM",
            "FRIDAY": "5:15AM - 8:00PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            26.6418457,
            -80.204765
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.8,
                    "duration": "indefinitely",
                    "model_conf": 151.19
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.41,
                    "duration": "indefinitely",
                    "model_conf": 166.81
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.98,
                    "duration": "indefinitely",
                    "model_conf": 109.77
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Wellington, FL",
            "slug": "acce4a1c-98ab-4b69-bc33-29fbf07a75ad"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/20/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/wellington/2625-state-road-7/",
        "index": 98.425,
        "franchise-entity-name": "SAE FITNESS LLC"
    },
    "20f57073-50af-4d9e-b06f-2a4fc1ba4fec": {
        "name": "Orange Theory Wesley Chapel, FL",
        "address": "28210 Paseo Drive, Suite 110 Wesley Chapel, Florida 33543",
        "phone": "(813) 235-0664",
        "email": "studiomanager0282@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:00AM - 3:00PM",
            "SUNDAY": "7:00AM - 3:00PM"
        },
        "geolocation": [
            28.1881447,
            -82.349739
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.96,
                    "duration": "indefinitely",
                    "model_conf": 152.84
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.97,
                    "duration": "indefinitely",
                    "model_conf": 183.34
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.07,
                    "duration": "indefinitely",
                    "model_conf": 111.16
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Wesley Chapel, FL",
            "slug": "20f57073-50af-4d9e-b06f-2a4fc1ba4fec"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/28/15",
        "url": "https://www.orangetheory.com/en-us/locations/florida/wesley-chapel/28210-paseo-drive-suite-110/",
        "index": 98.425,
        "franchise-entity-name": "GLOBAL FITNESS ENTERPRISES IV, LLC"
    },
    "c6066863-5b0f-4dca-afc0-a25a9ffa3bcb": {
        "name": "Orange Theory Babylon, NY",
        "address": "733 Sunrise Highway West Babylon, New York 11704",
        "phone": "(631) 983-4272",
        "email": "studiomanager0924@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:45AM - 11:30AM",
            "SUNDAY": "6:45AM - 11:30AM"
        },
        "geolocation": [
            40.7145653,
            -73.341255
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.45,
                    "duration": "indefinitely",
                    "model_conf": 148.91
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 138.13,
                    "duration": "2 Months",
                    "model_conf": 179.4
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 199.68,
                    "duration": "indefinitely",
                    "model_conf": 107.6
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "Babylon, NY",
            "slug": "c6066863-5b0f-4dca-afc0-a25a9ffa3bcb"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/23/19",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/west-babylon/733-sunrise-highway/",
        "index": 108.325,
        "franchise-entity-name": "OTF BABYLON LLC"
    },
    "770c50bb-a448-4075-b1bc-9fb66f8083b6": {
        "name": "Orange Theory West Chester",
        "address": "7641 Voice of America Centre Drive West Chester, Ohio 45069",
        "phone": "(513) 718-2535",
        "email": "studiomanager0508@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:30PM",
            "TUESDAY": "5:00AM - 9:30PM",
            "WEDNESDAY": "5:00AM - 9:30PM",
            "THURSDAY": "5:00AM - 9:30PM",
            "FRIDAY": "5:00AM - 9:30PM",
            "SATURDAY": "7:00AM - 11:30PM",
            "SUNDAY": "7:00AM - 11:30PM"
        },
        "geolocation": [
            39.355835,
            -84.363785
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.07,
                    "duration": "indefinitely",
                    "model_conf": 143.31
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.82,
                    "duration": "indefinitely",
                    "model_conf": 184.44
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.65,
                    "duration": "indefinitely",
                    "model_conf": 105.39
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "West Chester",
            "slug": "770c50bb-a448-4075-b1bc-9fb66f8083b6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/25/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/west-chester/7641-voice-of-america-centre-drive/",
        "index": 93.4,
        "franchise-entity-name": "WALTON, LLC"
    },
    "b88135a4-9b27-4f79-8365-fd08bf68ffb0": {
        "name": "Orange Theory West Chester, PA",
        "address": "672 Downingtown Pike West Chester, Pennsylvania 19380",
        "phone": "(484) 886-4500",
        "email": "studiomanager0741@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:45AM - 11:30AM",
            "SUNDAY": "6:45AM - 11:30AM"
        },
        "geolocation": [
            39.9595718,
            -75.620934
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.85,
                    "duration": "5 Months",
                    "model_conf": 152.45
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.52,
                    "duration": "indefinitely",
                    "model_conf": 185.02
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.99,
                    "duration": "2 Months",
                    "model_conf": 107.85
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West Chester, PA",
            "slug": "b88135a4-9b27-4f79-8365-fd08bf68ffb0"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/16",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/west-chester/672-downingtown-pike/",
        "index": 98.425,
        "franchise-entity-name": "TC BUSINESS WEST CHESTER, LLC"
    },
    "e06d36c3-2c57-4447-8923-0590a9c47650": {
        "name": "Orange Theory West Hartford, CT",
        "address": "345 N. Main St. West Hartford, Connecticut 06117",
        "phone": "(860) 288-5501",
        "email": "studiomanager0622@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            41.7851906,
            -72.74791
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.87,
                    "duration": "indefinitely",
                    "model_conf": 151.48
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.04,
                    "duration": "indefinitely",
                    "model_conf": 184.75
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.79,
                    "duration": "indefinitely",
                    "model_conf": 111.64
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West Hartford, CT",
            "slug": "e06d36c3-2c57-4447-8923-0590a9c47650"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/10/16",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/west-hartford/345-n-main-st/",
        "index": 98.425,
        "franchise-entity-name": "M2 WEST HARTFORD LLC"
    },
    "7991aee1-55a2-4639-bf9d-cd30e534b5c3": {
        "name": "Orange Theory West Lafayette, IN",
        "address": "1020 D Sagamore Parkway W West Lafayette, Indiana 47906",
        "phone": "(765) 575-4944",
        "email": "studiomanager1102@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:45AM - 12:30PM",
            "SUNDAY": "6:45AM - 12:30PM"
        },
        "geolocation": [
            40.4542084,
            -86.91851
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 70.0,
                    "duration": "indefinitely",
                    "model_conf": 149.41
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 107.88,
                    "duration": "3 Months",
                    "model_conf": 183.18
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.56,
                    "duration": "indefinitely",
                    "model_conf": 111.24
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "West Lafayette, IN",
            "slug": "7991aee1-55a2-4639-bf9d-cd30e534b5c3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/22/18",
        "url": "https://www.orangetheory.com/en-us/locations/indiana/west-lafayette/1020-d-sagamore-parkway-w/",
        "index": 93.4,
        "franchise-entity-name": "OT WEST LAFAYETTE INDIANA, LLC"
    },
    "6ba340fe-c50d-4315-9af6-dcafdf6e3328": {
        "name": "Orange Theory West Linn, OR",
        "address": "19151 Williamette Drive West Linn, Oregon 97068",
        "phone": "(503) 607-8488",
        "email": "studiomanager0552@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:00PM",
            "TUESDAY": "5:00AM - 7:00PM",
            "WEDNESDAY": "5:00AM - 7:00PM",
            "THURSDAY": "5:00AM - 7:00PM",
            "FRIDAY": "5:00AM - 7:00PM",
            "SATURDAY": "7:15AM - 11:45AM",
            "SUNDAY": "7:15AM - 11:45AM"
        },
        "geolocation": [
            45.3859024,
            -122.64146
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.8,
                    "duration": "5 Months",
                    "model_conf": 153.42
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.74,
                    "duration": "indefinitely",
                    "model_conf": 180.96
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.75,
                    "duration": "2 Months",
                    "model_conf": 111.12
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West Linn, OR",
            "slug": "6ba340fe-c50d-4315-9af6-dcafdf6e3328"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/3/16",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/west-linn/19151-williamette-drive/",
        "index": 98.425,
        "franchise-entity-name": "HH FITNESS WEST LINN"
    },
    "ab0a024f-b02c-48a4-8bfd-a65e27122296": {
        "name": "Orange Theory West Palm Beach, FL",
        "address": "1900 Okeechobee Blvd Bay A-1 West Palm Beach, Florida 33409",
        "phone": "(561) 771-1180",
        "email": "studiomanager0055@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 12:15PM",
            "SUNDAY": "7:30AM - 12:15PM"
        },
        "geolocation": [
            26.7056427,
            -80.081131
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.7,
                    "duration": "1 Months",
                    "model_conf": 150.52
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.58,
                    "duration": "indefinitely",
                    "model_conf": 183.62
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.83,
                    "duration": "indefinitely",
                    "model_conf": 111.03
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West Palm Beach, FL",
            "slug": "ab0a024f-b02c-48a4-8bfd-a65e27122296"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/29/16",
        "url": "https://www.orangetheory.com/en-us/locations/florida/west-palm-beach/1900-okeechobee-blvd-bay-a-1/",
        "index": 98.425,
        "franchise-entity-name": "MJL FITNESS, LLC"
    },
    "d0787239-37f9-4186-9f5f-a5a5dcfde797": {
        "name": "Orange Theory West Windsor, NJ",
        "address": "352 Princeton Hightstown Rd. Suite 7 West Windsor Township, New Jersey 08550",
        "phone": "(609) 799-4561",
        "email": "studiomanager0061@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.29002,
            -74.58123
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.86,
                    "duration": "indefinitely",
                    "model_conf": 152.06
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.12,
                    "duration": "indefinitely",
                    "model_conf": 184.2
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.76,
                    "duration": "indefinitely",
                    "model_conf": 110.04
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "West Windsor, NJ",
            "slug": "d0787239-37f9-4186-9f5f-a5a5dcfde797"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/15/13",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/west-windsor-township/352-princeton-hightstown-rd-suite-7/",
        "index": 98.425,
        "franchise-entity-name": "ORANGE ENERGY-NJ, LLC"
    },
    "635180bd-1176-4bc7-8aef-a2a34562502b": {
        "name": "Orange Theory Westbrook, ME",
        "address": "95 Rock Row, Ste 130 Westbrook, Maine 04092",
        "phone": "(207) 560-3503",
        "email": "studiomanager1366@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 12:30PM",
            "SUNDAY": "6:30AM - 12:30PM"
        },
        "geolocation": [
            43.675203,
            -70.332236
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.91,
                    "duration": "indefinitely",
                    "model_conf": 150.03
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 117.71,
                    "duration": "4 Months",
                    "model_conf": 185.71
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.33,
                    "duration": "indefinitely",
                    "model_conf": 105.95
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Westbrook, ME",
            "slug": "635180bd-1176-4bc7-8aef-a2a34562502b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/10/23",
        "url": "https://www.orangetheory.com/en-us/locations/maine/westbrook/95-rock-row-130/",
        "index": 98.425,
        "franchise-entity-name": "DK 2 LLC"
    },
    "cc794606-e2a8-4786-9959-b62e8882b812": {
        "name": "Orange Theory Westerville, OH",
        "address": "823 Polaris Parkway Westerville, Ohio 43082",
        "phone": "(614) 300-2925",
        "email": "studiomanager0722@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.1440048,
            -82.957947
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.38,
                    "duration": "3 Months",
                    "model_conf": 148.26
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.64,
                    "duration": "indefinitely",
                    "model_conf": 178.25
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.14,
                    "duration": "indefinitely",
                    "model_conf": 108.05
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Westerville, OH",
            "slug": "cc794606-e2a8-4786-9959-b62e8882b812"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/1/16",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/westerville/823-polaris-pkwy/",
        "index": 93.4,
        "franchise-entity-name": "YOUNG FITNESS 2, LLC"
    },
    "974ebfc2-cc9d-4483-a51d-4988002b0946": {
        "name": "Orange Theory Westford, MA",
        "address": "160 Littleton Road Westford, Massachusetts 01886",
        "phone": "(978) 237-8936",
        "email": "studiomanager0888@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45PM",
            "SUNDAY": "7:00AM - 11:45PM"
        },
        "geolocation": [
            42.5682678,
            -71.420128
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.59,
                    "duration": "1 Months",
                    "model_conf": 153.04
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.57,
                    "duration": "indefinitely",
                    "model_conf": 184.31
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.91,
                    "duration": "indefinitely",
                    "model_conf": 111.32
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Westford, MA",
            "slug": "974ebfc2-cc9d-4483-a51d-4988002b0946"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/28/17",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/westford/160-littleton-road/",
        "index": 103.375,
        "franchise-entity-name": "SIMPLE VENTURES, LLC"
    },
    "630d1e0e-f1ef-48a6-8b13-d3c077268bf7": {
        "name": "Orange Theory Westlake, OH",
        "address": "30676 Detroit Rd. Westlake, Ohio 44145",
        "phone": "(440) 772-1100",
        "email": "studiomanager0793@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "5:45AM - 12:45PM",
            "SUNDAY": "5:45AM - 12:45PM"
        },
        "geolocation": [
            41.4619255,
            -81.959816
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.35,
                    "duration": "indefinitely",
                    "model_conf": 151.4
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.16,
                    "duration": "indefinitely",
                    "model_conf": 185.43
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.14,
                    "duration": "indefinitely",
                    "model_conf": 109.16
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Westlake, OH",
            "slug": "630d1e0e-f1ef-48a6-8b13-d3c077268bf7"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/6/17",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/westlake/30676-detroit-rd/",
        "index": 93.4,
        "franchise-entity-name": "KSA2 LLC"
    },
    "4ec8bfa6-6d80-4f43-9f36-facd99f90d46": {
        "name": "Orange Theory West Lake Hills, TX",
        "address": "3300 Bee Caves Road #200 Westlake, Texas 78746",
        "phone": "(512) 807-0441",
        "email": "studiomanager0643@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:15PM",
            "SUNDAY": "7:00AM - 12:15PM"
        },
        "geolocation": [
            30.2749748,
            -97.798973
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.33,
                    "duration": "2 Months",
                    "model_conf": 146.18
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.94,
                    "duration": "indefinitely",
                    "model_conf": 172.1
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.37,
                    "duration": "1 Months",
                    "model_conf": 108.89
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "West Lake Hills, TX",
            "slug": "4ec8bfa6-6d80-4f43-9f36-facd99f90d46"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/20/16",
        "url": "https://www.orangetheory.com/en-us/locations/texas/westlake/3300-bee-caves-road-200/",
        "index": 108.325,
        "franchise-entity-name": "MAXIMUM FITNESS, WESTLAKE LLC"
    },
    "090cb168-7f83-4ae5-9dbe-9b9e4a5c7959": {
        "name": "Orange Theory Downers Grove - Westmont, IL",
        "address": "19 West Ogden Avenue Westmont, Illinois 60559",
        "phone": "(630) 326-3000",
        "email": "studiomanager0233@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 12:20PM",
            "SUNDAY": "6:30AM - 12:20PM"
        },
        "geolocation": [
            41.8096428,
            -87.978195
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.39,
                    "duration": "2 Months",
                    "model_conf": 107.64
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.85,
                    "duration": "indefinitely",
                    "model_conf": 130.39
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.35,
                    "duration": "1 Months",
                    "model_conf": 80.03
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Downers Grove - Westmont, IL",
            "slug": "090cb168-7f83-4ae5-9dbe-9b9e4a5c7959"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/30/15",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/westmont/19-west-ogden-avenue/",
        "index": 103.375,
        "franchise-entity-name": "DINAMI FITNESS LLC"
    },
    "f0874c0b-de20-4267-bff5-1d0356b8edfe": {
        "name": "Orange Theory Weston, FL",
        "address": "1132 Weston Road Weston, Florida 33326",
        "phone": "(954) 888-6660",
        "email": "studiomanager0008@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 1:30PM",
            "SUNDAY": "7:30AM - 1:30PM"
        },
        "geolocation": [
            26.1058025,
            -80.364403
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.32,
                    "duration": "indefinitely",
                    "model_conf": 146.72
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.84,
                    "duration": "indefinitely",
                    "model_conf": 180.81
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.36,
                    "duration": "indefinitely",
                    "model_conf": 111.02
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Weston, FL",
            "slug": "f0874c0b-de20-4267-bff5-1d0356b8edfe"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/9/11",
        "url": "https://www.orangetheory.com/en-us/locations/florida/weston/1132-weston-road/",
        "index": 98.425,
        "franchise-entity-name": "Broward Fitness Investments, LLC"
    },
    "4666d143-d76a-45e7-bf98-cdaa6f0b2afc": {
        "name": "Orange Theory Westport, CT",
        "address": "645 Post Road East Westport, Connecticut 06880",
        "phone": "(203) 883-9262",
        "email": "studiomanager0448@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:30AM - 8:15PM",
            "TUESDAY": "4:30AM - 8:15PM",
            "WEDNESDAY": "4:30AM - 8:15PM",
            "THURSDAY": "4:30AM - 8:15PM",
            "FRIDAY": "4:30AM - 8:15PM",
            "SATURDAY": "6:00AM - 12:45PM",
            "SUNDAY": "6:00AM - 12:45PM"
        },
        "geolocation": [
            41.1382484,
            -73.342628
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 5.02,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.2,
                    "duration": "8 Months",
                    "model_conf": 133.72
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 7.56,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 121.33,
                    "duration": "indefinitely",
                    "model_conf": 160.96
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 11.37,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.98,
                    "duration": "2 Months",
                    "model_conf": 99.22
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 14.29,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 23.81,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 31.69,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Westport, CT",
            "slug": "4666d143-d76a-45e7-bf98-cdaa6f0b2afc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/26/17",
        "url": "https://www.orangetheory.com/en-us/locations/connecticut/westport/645-post-road-east/",
        "index": 98.425,
        "franchise-entity-name": "EMPIRE WESTPORT LLC"
    },
    "5e522c9e-6f03-47a6-a7b5-90878709beab": {
        "name": "Orange Theory Westwood, MA",
        "address": "268 Washington Street Westwood, Massachusetts 02090",
        "phone": "(781) 904-3100",
        "email": "studiomanager1202@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            42.219729,
            -71.187762
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.58,
                    "duration": "1 Months",
                    "model_conf": 145.69
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.45,
                    "duration": "indefinitely",
                    "model_conf": 181.74
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.03,
                    "duration": "indefinitely",
                    "model_conf": 109.81
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Westwood, MA",
            "slug": "5e522c9e-6f03-47a6-a7b5-90878709beab"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/19/21",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/westwood/268-washington-st/",
        "index": 103.375,
        "franchise-entity-name": "Berry Health LLC"
    },
    "0cf2ad6c-d6d2-40a3-af28-b2ea8b246af8": {
        "name": "Orange Theory Wexford, PA",
        "address": "12003 Perry Highway Wexford, Pennsylvania 15090",
        "phone": "(724) 400-6773",
        "email": "studiomanager0312@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 1:00PM",
            "SUNDAY": "7:00AM - 1:00PM"
        },
        "geolocation": [
            40.6317368,
            -80.058296
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.15,
                    "duration": "indefinitely",
                    "model_conf": 153.21
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.04,
                    "duration": "indefinitely",
                    "model_conf": 178.95
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.2,
                    "duration": "indefinitely",
                    "model_conf": 110.13
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Wexford, PA",
            "slug": "0cf2ad6c-d6d2-40a3-af28-b2ea8b246af8"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/2/16",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/wexford/12003-perry-highway/",
        "index": 93.4,
        "franchise-entity-name": "TANGERINE RETAIL VENTURES II, LLC"
    },
    "67366823-4059-4651-939d-4ef8fe096f2e": {
        "name": "Orange Theory Applewood - Wheat Ridge, CO",
        "address": "3244 Youngfield St., Suite A Wheat Ridge, Colorado 80033",
        "phone": "(720) 307-5007",
        "email": "studiomanager0214@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:15AM - 12:30PM",
            "SUNDAY": "6:15AM - 12:30PM"
        },
        "geolocation": [
            39.7633667,
            -105.14149
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 77.51,
                    "duration": "7 Months",
                    "model_conf": 148.15
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.71,
                    "duration": "indefinitely",
                    "model_conf": 182.5
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.86,
                    "duration": "2 Months",
                    "model_conf": 110.18
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Applewood - Wheat Ridge, CO",
            "slug": "67366823-4059-4651-939d-4ef8fe096f2e"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "6/24/16",
        "url": "https://www.orangetheory.com/en-us/locations/colorado/wheat-ridge/3244-youngfield-st-suite-a/",
        "index": 98.425,
        "franchise-entity-name": "FIT IN GOLDEN"
    },
    "c868858b-257b-486e-9f42-f0b8e1e50c15": {
        "name": "Orange Theory Wheaton, IL",
        "address": "223 Rice Lake Square Wheaton, Illinois 60189",
        "phone": "(630) 914-6767",
        "email": "studiomanager0165@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:00AM - 12:00PM",
            "SUNDAY": "6:00AM - 12:00PM"
        },
        "geolocation": [
            41.8316841,
            -88.098785
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.15,
                    "duration": "indefinitely",
                    "model_conf": 131.85
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.02,
                    "duration": "2 Months",
                    "model_conf": 157.42
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.44,
                    "duration": "indefinitely",
                    "model_conf": 95.72
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Wheaton, IL",
            "slug": "c868858b-257b-486e-9f42-f0b8e1e50c15"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/21/14",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/wheaton/223-rice-lake-square/",
        "index": 103.375,
        "franchise-entity-name": "OTF WHEATON CORP."
    },
    "49268697-ab8f-46fe-964f-272d8a81eade": {
        "name": "Orange Theory White Marsh, MD",
        "address": "7902 Honeygo Boulevard White Marsh, Maryland 21236",
        "phone": "(443) 973-6896",
        "email": "studiomanager1301@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "7:00AM - 12:00PM",
            "SUNDAY": "7:00AM - 12:00PM"
        },
        "geolocation": [
            39.3744965,
            -76.472084
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 98.31,
                    "duration": "2 Months",
                    "model_conf": 146.34
                }
            },
            "Elite": {
                "cost": 139,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 139.88,
                    "duration": "indefinitely",
                    "model_conf": 175.9
                }
            },
            "Premier": {
                "cost": 199,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 198.73,
                    "duration": "indefinitely",
                    "model_conf": 111.02
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 189,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 199,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 129,
            "elite": 139,
            "additional-session-2": 22,
            "name": "White Marsh, MD",
            "slug": "49268697-ab8f-46fe-964f-272d8a81eade"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/14/20",
        "url": "https://www.orangetheory.com/en-us/locations/maryland/white-marsh/7902-honeygo-boulevard/",
        "index": 108.325,
        "franchise-entity-name": "TEAM PERRY HALL, LLC"
    },
    "7057a930-484d-471f-be64-605287ebe091": {
        "name": "Orange Theory White Plains, NY",
        "address": "245 Mamaroneck Ave. White Plains, New York 10605",
        "phone": "(914) 729-0996",
        "email": "studiomanager0697@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.0269,
            -73.76378
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 86.86,
                    "duration": "9 Months",
                    "model_conf": 139.39
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 131.53,
                    "duration": "indefinitely",
                    "model_conf": 168.61
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.15,
                    "duration": "3 Months",
                    "model_conf": 101.8
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "White Plains, NY",
            "slug": "7057a930-484d-471f-be64-605287ebe091"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/16/16",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/white-plains/245-mamaroneck-ave/",
        "index": 103.375,
        "franchise-entity-name": "3 FITNESS LLC"
    },
    "8bc0c360-084a-4259-ab19-a4885a3ddfc6": {
        "name": "Orange Theory Wichita East, KS",
        "address": "1423 N Webb Road, Suite 159 Wichita, Kansas 67206",
        "phone": "(316) 440-4640",
        "email": "studiomanager0589@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            37.7088013,
            -97.226227
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 5.18,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.4,
                    "duration": "indefinitely",
                    "model_conf": 151.38
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 8.18,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.44,
                    "duration": "1 Months",
                    "model_conf": 185.14
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 12.68,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.45
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.92,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 26.92,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 37.42,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Wichita East, KS",
            "slug": "8bc0c360-084a-4259-ab19-a4885a3ddfc6"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/kansas/wichita/1423-n-webb-road-suite-159/",
        "index": 93.4,
        "franchise-entity-name": "WAYMAN FITNESS I, LLC"
    },
    "49e360d1-f8ef-4091-a23f-61b321cb283c": {
        "name": "Orange Theory Wichita - West, KS",
        "address": "2835 N Maize Rd., Suite 161 Wichita, Kansas 67205",
        "phone": "(316) 239-6348",
        "email": "studiomanager0590@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:30AM - 1:00PM",
            "SUNDAY": "6:30AM - 1:00PM"
        },
        "geolocation": [
            37.7340775,
            -97.462692
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 5.18,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.1,
                    "duration": "indefinitely",
                    "model_conf": 151.86
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 8.18,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.85,
                    "duration": "indefinitely",
                    "model_conf": 184.86
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 12.68,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.78,
                    "duration": "indefinitely",
                    "model_conf": 111.72
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 14.92,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 26.92,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 37.42,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Wichita - West, KS",
            "slug": "49e360d1-f8ef-4091-a23f-61b321cb283c"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/13/17",
        "url": "https://www.orangetheory.com/en-us/locations/kansas/wichita/2835-n-maize-rd-suite-161/",
        "index": 93.4,
        "franchise-entity-name": "FITNESS OF WICHITA II, LLC"
    },
    "aea7a301-0feb-4c85-b215-13872e993134": {
        "name": "Orange Theory Williamsburg, VA",
        "address": "1430 Richmond Road, Suite B07-707 Williamsburg, Virginia 23185",
        "phone": "(757) 614-1118",
        "email": "studiomanager0937@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:15AM - 5:00PM",
            "SUNDAY": "6:15AM - 5:00PM"
        },
        "geolocation": [
            37.2882767,
            -76.724937
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.17,
                    "duration": "indefinitely",
                    "model_conf": 153.1
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.81,
                    "duration": "indefinitely",
                    "model_conf": 184.46
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.9,
                    "duration": "indefinitely",
                    "model_conf": 111.41
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Williamsburg, VA",
            "slug": "aea7a301-0feb-4c85-b215-13872e993134"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/13/17",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/williamsburg/1430-richmond-road-suite-b07-707/",
        "index": 93.4,
        "franchise-entity-name": "TUGBOAT FITNESS LLC"
    },
    "a83169d3-108f-4236-ad12-19c17526ef10": {
        "name": "Orange Theory Williston, VT",
        "address": "28 Walnut St, Unit C25 Williston, Vermont 05495",
        "phone": "(802) 243-3220",
        "email": "studiomanager1541@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:15PM",
            "TUESDAY": "5:00AM - 6:15PM",
            "WEDNESDAY": "5:00AM - 6:15PM",
            "THURSDAY": "5:00AM - 6:15PM",
            "FRIDAY": "5:00AM - 6:15PM",
            "SATURDAY": "8:00AM - 11:30AM",
            "SUNDAY": "8:00AM - 11:30AM"
        },
        "geolocation": [
            44.445154,
            -73.109092
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.65,
                    "duration": "1 Months",
                    "model_conf": 148.63
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.47,
                    "duration": "indefinitely",
                    "model_conf": 182.93
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.67,
                    "duration": "indefinitely",
                    "model_conf": 106.26
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Williston, VT",
            "slug": "a83169d3-108f-4236-ad12-19c17526ef10"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/16/23",
        "url": "https://www.orangetheory.com/en-us/locations/vermont/williston/28-walnut-st-c25/",
        "index": 98.425,
        "franchise-entity-name": "EMPIRE STUDIO 1541 LLC"
    },
    "19046b87-ba9d-49b1-a54f-24faf0c8b32b": {
        "name": "Orange Theory San Jose - Willow Glen, CA",
        "address": "1331 Lincoln Ave Willow Glen, California 95125",
        "phone": "(408) 297-2425",
        "email": "studiomanager0122@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:30PM",
            "SUNDAY": "7:00AM - 11:30PM"
        },
        "geolocation": [
            37.3044167,
            -121.89887
        ],
        "memberships": {
            "Basic": {
                "cost": 99,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 99.14,
                    "duration": "indefinitely",
                    "model_conf": 153.49
                }
            },
            "Elite": {
                "cost": 149,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 148.65,
                    "duration": "indefinitely",
                    "model_conf": 172.58
                }
            },
            "Premier": {
                "cost": 209,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 208.52,
                    "duration": "indefinitely",
                    "model_conf": 110.65
                }
            }
        },
        "services": {
            "10 Session Pack - $250": {
                "cost": 250,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $450": {
                "cost": 450,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $599": {
                "cost": 599,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 30,
            "late-cancel-2": 14,
            "10-pack": 250,
            "20-pack": 450,
            "premier-family-addon-6-month-corp-2": 209,
            "surcharge-2": 10,
            "30-pack": 599,
            "premier": 209,
            "basic": 99,
            "elite-family-addon-6-month-corp-2": 149,
            "elite": 149,
            "additional-session-2": 22,
            "name": "San Jose - Willow Glen, CA",
            "slug": "19046b87-ba9d-49b1-a54f-24faf0c8b32b"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/30/14",
        "url": "https://www.orangetheory.com/en-us/locations/california/san-jose/1331-lincoln-ave/",
        "index": 111.325,
        "franchise-entity-name": "WILLOW GLEN FITNESS LLC"
    },
    "f3441df1-d8a6-4cb6-ac50-50682da0bca3": {
        "name": "Orange Theory Willow Grove, PA",
        "address": "164 Park Avenue Willow Grove, Pennsylvania 19090",
        "phone": "(215) 866-8666",
        "email": "studiomanager0021@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.1442528,
            -75.117439
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.63,
                    "duration": "6 Months",
                    "model_conf": 149.69
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.83,
                    "duration": "indefinitely",
                    "model_conf": 179.75
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 187.83,
                    "duration": "2 Months",
                    "model_conf": 107.52
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Willow Grove, PA",
            "slug": "f3441df1-d8a6-4cb6-ac50-50682da0bca3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/2/13",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/willow-grove/164-park-avenue/",
        "index": 103.375,
        "franchise-entity-name": "OTF MAIN LINE, LLC"
    },
    "b6441055-60a6-4625-80dd-0696990a26bc": {
        "name": "Orange Theory Willowbrook-Hinsdale, IL",
        "address": "894 75th Street Willowbrook, Illinois 60527",
        "phone": "(630) 352-3330",
        "email": "studiomanager0163@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:15PM",
            "TUESDAY": "5:00AM - 7:15PM",
            "WEDNESDAY": "5:00AM - 7:15PM",
            "THURSDAY": "5:00AM - 7:15PM",
            "FRIDAY": "5:00AM - 7:15PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            41.772777,
            -87.947275
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.24,
                    "duration": "indefinitely",
                    "model_conf": 132.69
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 128.15,
                    "duration": "2 Months",
                    "model_conf": 161.88
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.72,
                    "duration": "indefinitely",
                    "model_conf": 98.17
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Willowbrook-Hinsdale, IL",
            "slug": "b6441055-60a6-4625-80dd-0696990a26bc"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "5/9/14",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/willowbrook/894-75th-street/",
        "index": 103.375,
        "franchise-entity-name": "FOWLER GROUP, LLC"
    },
    "acd4390b-2492-4de1-9463-75102a8c3ca3": {
        "name": "Orange Theory Pike Creek, DE",
        "address": "4754B Limestone Road Wilmington, Delaware 19808",
        "phone": "(302) 426-2030",
        "email": "studiomanager0779@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:45AM - 12:00PM",
            "SUNDAY": "6:45AM - 12:00PM"
        },
        "geolocation": [
            39.7411881,
            -75.691376
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.17,
                    "duration": "indefinitely",
                    "model_conf": 149.86
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.27,
                    "duration": "indefinitely",
                    "model_conf": 184.0
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.9,
                    "duration": "indefinitely",
                    "model_conf": 106.59
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Pike Creek, DE",
            "slug": "acd4390b-2492-4de1-9463-75102a8c3ca3"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "7/29/17",
        "url": "https://www.orangetheory.com/en-us/locations/delaware/wilmington/4754b-limestone-road/",
        "index": 93.4,
        "franchise-entity-name": "EXERCISEXTREME LLC"
    },
    "ecc09e87-dc44-4a04-8c95-c510f46d41ce": {
        "name": "Orange Theory South Wilmington, NC",
        "address": "1412 Barclay Pointe Blvd, Suite 1002 Wilmington, North Carolina 28412",
        "phone": "(910) 550-2009",
        "email": "studiomanager0765@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:30AM - 11:00AM",
            "SUNDAY": "7:30AM - 11:00AM"
        },
        "geolocation": [
            34.1805878,
            -77.918053
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.6,
                    "duration": "1 Months",
                    "model_conf": 152.33
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.34,
                    "duration": "indefinitely",
                    "model_conf": 185.47
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.72,
                    "duration": "indefinitely",
                    "model_conf": 112.01
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "South Wilmington, NC",
            "slug": "ecc09e87-dc44-4a04-8c95-c510f46d41ce"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/29/17",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/wilmington/1412-barclay-pointe-blvd-suite-1002/",
        "index": 98.425,
        "franchise-entity-name": "JOELE FITNESS TWO, LLC"
    },
    "70e76987-ed28-4c9b-8693-788ad538ad1a": {
        "name": "Orange Theory Wilmington, NC",
        "address": "6800 Parker Farm Drive Wilmington, North Carolina 28405",
        "phone": "(910) 660-0933",
        "email": "studiomanager0533@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 10:45AM",
            "SUNDAY": "7:15AM - 10:45AM"
        },
        "geolocation": [
            34.2348366,
            -77.831314
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.02,
                    "duration": "4 Months",
                    "model_conf": 150.53
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.22,
                    "duration": "indefinitely",
                    "model_conf": 185.48
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.23,
                    "duration": "1 Months",
                    "model_conf": 111.89
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Wilmington, NC",
            "slug": "70e76987-ed28-4c9b-8693-788ad538ad1a"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/22/16",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/wilmington/6800-parker-farm-drive/",
        "index": 98.425,
        "franchise-entity-name": "JOELE FITNESS, LLC"
    },
    "31c1ef3b-75ab-4d0c-ab03-94e06bce6937": {
        "name": "Orange Theory Wilsonville, OR",
        "address": "29756 SW Town Center Loop West Suite 7L Wilsonville, Oregon 97070",
        "phone": "(503) 374-1920",
        "email": "studiomanager0609@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:45PM",
            "TUESDAY": "5:00AM - 6:45PM",
            "WEDNESDAY": "5:00AM - 6:45PM",
            "THURSDAY": "5:00AM - 6:45PM",
            "FRIDAY": "5:00AM - 6:45PM",
            "SATURDAY": "7:00AM - 11:00AM",
            "SUNDAY": "7:00AM - 11:00AM"
        },
        "geolocation": [
            45.3043861,
            -122.76454
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.25,
                    "duration": "3 Months",
                    "model_conf": 153.42
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.49,
                    "duration": "indefinitely",
                    "model_conf": 184.69
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 177.78,
                    "duration": "2 Months",
                    "model_conf": 111.7
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Wilsonville, OR",
            "slug": "31c1ef3b-75ab-4d0c-ab03-94e06bce6937"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "1/6/17",
        "url": "https://www.orangetheory.com/en-us/locations/oregon/wilsonville/29756-sw-town-center-loop-west-suite-7l/",
        "index": 98.425,
        "franchise-entity-name": "JM WILSONVILLE, LLC"
    },
    "a5fa4519-b62b-454c-be06-9ff921432b87": {
        "name": "Orange Theory Winchester, VA",
        "address": "2016 S Pleasant Valley Rd Winchester, Virginia 22601",
        "phone": "(540) 228-3008",
        "email": "studiomanager1136@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 6:15PM",
            "TUESDAY": "5:30AM - 6:15PM",
            "WEDNESDAY": "5:30AM - 6:15PM",
            "THURSDAY": "5:30AM - 6:15PM",
            "FRIDAY": "5:30AM - 6:15PM",
            "SATURDAY": "8:00AM - 10:45AM",
            "SUNDAY": "8:00AM - 10:45AM"
        },
        "geolocation": [
            39.1617584,
            -78.166557
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.36,
                    "duration": "indefinitely",
                    "model_conf": 151.14
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.03,
                    "duration": "indefinitely",
                    "model_conf": 182.1
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.44,
                    "duration": "indefinitely",
                    "model_conf": 107.9
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Winchester, VA",
            "slug": "a5fa4519-b62b-454c-be06-9ff921432b87"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "11/23/18",
        "url": "https://www.orangetheory.com/en-us/locations/virginia/winchester/2016-s-pleasant-valley-rd/",
        "index": 93.4,
        "franchise-entity-name": "WINCHESTER FITNESS GROUP, LLC"
    },
    "e9e4b353-4d2c-42e6-9dba-cea71b115879": {
        "name": "Orange Theory Windermere, FL",
        "address": "13620 Summerport Village Pkwy Windermere, Florida 34786",
        "phone": "(407) 502-2656",
        "email": "studiomanager0459@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "7:15AM - 1:00PM",
            "SUNDAY": "7:15AM - 1:00PM"
        },
        "geolocation": [
            28.4766731,
            -81.583878
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.27,
                    "duration": "indefinitely",
                    "model_conf": 153.27
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.69,
                    "duration": "1 Months",
                    "model_conf": 182.3
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.03,
                    "duration": "indefinitely",
                    "model_conf": 110.94
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Windermere, FL",
            "slug": "e9e4b353-4d2c-42e6-9dba-cea71b115879"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "8/17/18",
        "url": "https://www.orangetheory.com/en-us/locations/florida/windermere/13450-summerport-village-pkwy/",
        "index": 98.425,
        "franchise-entity-name": "NASH FITNESS LLC"
    },
    "5af73738-8846-48ea-9695-2a0f896e2456": {
        "name": "Orange Theory Winter Garden, FL",
        "address": "2883 Maguire Rd. Units A1004 and A1005 Windermere, Florida 34786",
        "phone": "(407) 895-7002",
        "email": "studiomanager0525@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 9:00PM",
            "TUESDAY": "5:00AM - 9:00PM",
            "WEDNESDAY": "5:00AM - 9:00PM",
            "THURSDAY": "5:00AM - 9:00PM",
            "FRIDAY": "5:00AM - 9:00PM",
            "SATURDAY": "6:15AM - 12:30PM",
            "SUNDAY": "6:15AM - 12:30PM"
        },
        "geolocation": [
            28.5284767,
            -81.542595
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.06,
                    "duration": "indefinitely",
                    "model_conf": 152.65
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.94,
                    "duration": "indefinitely",
                    "model_conf": 181.43
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.84,
                    "duration": "indefinitely",
                    "model_conf": 110.25
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Winter Garden, FL",
            "slug": "5af73738-8846-48ea-9695-2a0f896e2456"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/20/17",
        "url": "https://www.orangetheory.com/en-us/locations/florida/windermere/2883-maguire-rd-units-a1004-and-a1005/",
        "index": 98.425,
        "franchise-entity-name": "OTF WINTER GARDEN, LLC"
    },
    "daae1f92-9887-4668-ac68-d08da76f5f20": {
        "name": "Orange Theory Winston Salem",
        "address": "367 Lower Mall Drive Winston Salem, North Carolina 27103",
        "phone": "(336) 701-5587",
        "email": "studiomanager1183@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "6:30AM - 11:45AM",
            "SUNDAY": "6:30AM - 11:45AM"
        },
        "geolocation": [
            36.0911865,
            -80.285065
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 79.85,
                    "duration": "indefinitely",
                    "model_conf": 151.61
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.24,
                    "duration": "2 Months",
                    "model_conf": 171.49
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 179.61,
                    "duration": "indefinitely",
                    "model_conf": 112.0
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Winston Salem",
            "slug": "daae1f92-9887-4668-ac68-d08da76f5f20"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/19/18",
        "url": "https://www.orangetheory.com/en-us/locations/north-carolina/winston-salem/367-lower-mall-drive/",
        "index": 98.425,
        "franchise-entity-name": "JACKELLE FITNESS FIVE LLC"
    },
    "61b49bca-677d-4a31-b284-22f4059899de": {
        "name": "Orange Theory Winter Park, FL",
        "address": "115 North Orlando Avenue Winter Park, Florida 32789",
        "phone": "(407) 915-3373",
        "email": "studiomanager0108@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:30AM - 7:00PM",
            "TUESDAY": "5:30AM - 7:00PM",
            "WEDNESDAY": "5:30AM - 7:00PM",
            "THURSDAY": "5:30AM - 7:00PM",
            "FRIDAY": "5:30AM - 7:00PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            28.59799,
            -81.365321
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.82,
                    "duration": "indefinitely",
                    "model_conf": 118.83
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.13,
                    "duration": "indefinitely",
                    "model_conf": 140.33
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.85,
                    "duration": "indefinitely",
                    "model_conf": 87.51
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Winter Park, FL",
            "slug": "61b49bca-677d-4a31-b284-22f4059899de"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "9/19/14",
        "url": "https://www.orangetheory.com/en-us/locations/florida/winter-park/115-n-orlando-ave/",
        "index": 98.425,
        "franchise-entity-name": "OTF STUDIOS, LLC"
    },
    "767b47a5-ac86-4002-a81d-fa96f7dd73df": {
        "name": "Orange Theory Winter Springs, FL",
        "address": "5701 Red Bug Lake Rd Winter Springs, Florida 32708",
        "phone": "(407) 278-2294",
        "email": "studiomanager1414@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 10:45AM",
            "SUNDAY": "7:15AM - 10:45AM"
        },
        "geolocation": [
            28.646094,
            -81.268639
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 76.7,
                    "duration": "11 Months",
                    "model_conf": 134.14
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 120.54,
                    "duration": "indefinitely",
                    "model_conf": 161.57
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 176.77,
                    "duration": "4 Months",
                    "model_conf": 98.21
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Winter Springs, FL",
            "slug": "767b47a5-ac86-4002-a81d-fa96f7dd73df"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": true,
        "opening_date": "7/17/23",
        "url": "https://www.orangetheory.com/en-us/locations/florida/winter-springs/5701-red-bug-lake-rd",
        "index": 98.425,
        "franchise-entity-name": "OTF Property Holdings, LLC"
    },
    "4da851eb-5fac-415a-8e68-d81218196036": {
        "name": "Orange Theory Woburn, Ma",
        "address": "348-A Cambridge Rd Woburn, Massachusetts 01801",
        "phone": "(781) 938-0028",
        "email": "studiomanager1059@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 12:30PM",
            "SUNDAY": "7:00AM - 12:30PM"
        },
        "geolocation": [
            42.4611359,
            -71.164436
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 87.92,
                    "duration": "4 Months",
                    "model_conf": 150.89
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.28,
                    "duration": "indefinitely",
                    "model_conf": 182.65
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.22,
                    "duration": "1 Months",
                    "model_conf": 110.38
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Woburn, Ma",
            "slug": "4da851eb-5fac-415a-8e68-d81218196036"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "3/16/18",
        "url": "https://www.orangetheory.com/en-us/locations/massachusetts/woburn/348-a-cambridge-rd/",
        "index": 103.375,
        "franchise-entity-name": "7 IRON LLC"
    },
    "643b8fb3-97d8-42a3-8d74-184243651907": {
        "name": "Orange Theory The Woodlands, TX",
        "address": "4747 Research Forest Dr. Suite 445 Woodlands, Texas 77381",
        "phone": "(832) 916-2115",
        "email": "studiomanager0753@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:00AM - 2:00PM",
            "SUNDAY": "7:00AM - 2:00PM"
        },
        "geolocation": [
            30.1854134,
            -95.49054
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.1,
                    "duration": "4 Months",
                    "model_conf": 141.66
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 118.87,
                    "duration": "indefinitely",
                    "model_conf": 178.42
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.82,
                    "duration": "indefinitely",
                    "model_conf": 110.37
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "The Woodlands, TX",
            "slug": "643b8fb3-97d8-42a3-8d74-184243651907"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/27/17",
        "url": "https://www.orangetheory.com/en-us/locations/texas/woodlands/4747-research-forest-dr-suite-445/",
        "index": 98.425,
        "franchise-entity-name": "WOODLANDS COACHING LLC"
    },
    "b1529196-0a0b-4350-8396-0b15faa02e30": {
        "name": "Orange Theory Woodridge",
        "address": "1001 75th St. Ste 127 Woodridge, Illinois 60517",
        "phone": "(630) 596-2144",
        "email": "studiomanager1088@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "4:55AM - 8:00PM",
            "TUESDAY": "4:55AM - 8:00PM",
            "WEDNESDAY": "4:55AM - 8:00PM",
            "THURSDAY": "4:55AM - 8:00PM",
            "FRIDAY": "4:55AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:30PM",
            "SUNDAY": "6:00AM - 12:30PM"
        },
        "geolocation": [
            41.749113,
            -88.014725
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.98,
                    "duration": "indefinitely",
                    "model_conf": 134.23
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.13,
                    "duration": "indefinitely",
                    "model_conf": 164.61
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.08,
                    "duration": "indefinitely",
                    "model_conf": 98.59
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Woodridge",
            "slug": "b1529196-0a0b-4350-8396-0b15faa02e30"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/5/18",
        "url": "https://www.orangetheory.com/en-us/locations/illinois/woodridge/1001-w-75th-street-suite-127/",
        "index": 103.375,
        "franchise-entity-name": "L5 FITNESS ILLINOIS-WEST, LLC"
    },
    "18316103-2c02-4319-bc42-07331998b192": {
        "name": "Orange Theory Woodstock, GA",
        "address": "200 Parkbrooke Drive, Suite 140 Woodstock, Georgia 30189",
        "phone": "(770) 833-4550",
        "email": "studiomanager0264@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:00AM - 12:15PM",
            "SUNDAY": "6:00AM - 12:15PM"
        },
        "geolocation": [
            34.1118126,
            -84.558647
        ],
        "memberships": {
            "Basic": {
                "cost": 79,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 78.45,
                    "duration": "2 Months",
                    "model_conf": 147.24
                }
            },
            "Elite": {
                "cost": 119,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 119.54,
                    "duration": "indefinitely",
                    "model_conf": 184.09
                }
            },
            "Premier": {
                "cost": 179,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 178.43,
                    "duration": "1 Months",
                    "model_conf": 107.77
                }
            }
        },
        "services": {
            "10 Session Pack - $225": {
                "cost": 225,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $375": {
                "cost": 375,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 225,
            "20-pack": 375,
            "premier-family-addon-6-month-corp-2": 169,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 179,
            "basic": 79,
            "elite-family-addon-6-month-corp-2": 109,
            "elite": 119,
            "additional-session-2": 18,
            "name": "Woodstock, GA",
            "slug": "18316103-2c02-4319-bc42-07331998b192"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/13/15",
        "url": "https://www.orangetheory.com/en-us/locations/georgia/woodstock/200-parkbrooke-drive-suite-140/",
        "index": 98.425,
        "franchise-entity-name": "JM WOODSTOCK, LLC"
    },
    "7317e4f7-bf80-4180-a7c8-de290dce1c06": {
        "name": "Orange Theory Worthington, OH",
        "address": "71 W Wilson Bridge Rd Worthington, Ohio 43085",
        "phone": "(614) 515-4908",
        "email": "studiomanager1508@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "8:15AM - 11:45AM",
            "SUNDAY": "8:15AM - 11:45AM"
        },
        "geolocation": [
            40.106894,
            -83.018243
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.77,
                    "duration": "1 Months",
                    "model_conf": 144.74
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.27,
                    "duration": "indefinitely",
                    "model_conf": 173.34
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.52,
                    "duration": "indefinitely",
                    "model_conf": 105.61
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Worthington, OH",
            "slug": "7317e4f7-bf80-4180-a7c8-de290dce1c06"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/30/22",
        "url": "https://www.orangetheory.com/en-us/locations/ohio/worthington/71-w-wilson-bridge-rd/",
        "index": 93.4,
        "franchise-entity-name": "YOUNG FITNESS 5, LLC"
    },
    "5b70d221-3c23-4f9b-8165-160e495e9ca4": {
        "name": "Orange Theory Wyckoff, NJ",
        "address": "327 Franklin Ave, Ste 13 Wyckoff, New Jersey 07481",
        "phone": "(201) 355-3532",
        "email": "studiomanager1209@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 6:30PM",
            "TUESDAY": "5:00AM - 6:30PM",
            "WEDNESDAY": "5:00AM - 6:30PM",
            "THURSDAY": "5:00AM - 6:30PM",
            "FRIDAY": "5:00AM - 6:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            41.012257,
            -74.168253
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 88.38,
                    "duration": "2 Months",
                    "model_conf": 140.67
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 130.07,
                    "duration": "indefinitely",
                    "model_conf": 167.32
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 188.7,
                    "duration": "indefinitely",
                    "model_conf": 108.44
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Wyckoff, NJ",
            "slug": "5b70d221-3c23-4f9b-8165-160e495e9ca4"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "4/11/22",
        "url": "https://www.orangetheory.com/en-us/locations/new-jersey/wyckoff/327-franklin-ave-suite-13/",
        "index": 103.375,
        "franchise-entity-name": "EMPIRE WYCKOFF LLC"
    },
    "c5a40f09-3e28-4323-a4a0-ed15315b7bf2": {
        "name": "Orange Theory Wyomissing, PA",
        "address": "2791 Paper Mill Rd, Suite 3 Wyomissing, Pennsylvania 19610",
        "phone": "(484) 450-8895",
        "email": "studiomanager1265@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:30PM",
            "TUESDAY": "5:00AM - 7:30PM",
            "WEDNESDAY": "5:00AM - 7:30PM",
            "THURSDAY": "5:00AM - 7:30PM",
            "FRIDAY": "5:00AM - 7:30PM",
            "SATURDAY": "7:00AM - 11:45AM",
            "SUNDAY": "7:00AM - 11:45AM"
        },
        "geolocation": [
            40.3608933,
            -75.987549
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 68.94,
                    "duration": "indefinitely",
                    "model_conf": 153.68
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 108.87,
                    "duration": "indefinitely",
                    "model_conf": 185.72
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 168.94,
                    "duration": "indefinitely",
                    "model_conf": 111.93
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Wyomissing, PA",
            "slug": "c5a40f09-3e28-4323-a4a0-ed15315b7bf2"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "2/7/20",
        "url": "https://www.orangetheory.com/en-us/locations/pennsylvania/wyomissing/2791-paper-mill-rd-suite-3/",
        "index": 93.4,
        "franchise-entity-name": "RYTE LLC"
    },
    "84c269ce-b180-4859-9463-303d30b01bc5": {
        "name": "Orange Theory Yorktown Heights, NY",
        "address": "650 Lee Blvd Yorktown Heights, New York 10598",
        "phone": "(914) 359-5515",
        "email": "studiomanager0993@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 8:00PM",
            "TUESDAY": "5:00AM - 8:00PM",
            "WEDNESDAY": "5:00AM - 8:00PM",
            "THURSDAY": "5:00AM - 8:00PM",
            "FRIDAY": "5:00AM - 8:00PM",
            "SATURDAY": "6:45AM - 12:30PM",
            "SUNDAY": "6:45AM - 12:30PM"
        },
        "geolocation": [
            41.3286171,
            -73.807716
        ],
        "memberships": {
            "Basic": {
                "cost": 89,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 89.07,
                    "duration": "indefinitely",
                    "model_conf": 153.08
                }
            },
            "Elite": {
                "cost": 129,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 129.57,
                    "duration": "indefinitely",
                    "model_conf": 184.88
                }
            },
            "Premier": {
                "cost": 189,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 189.62,
                    "duration": "indefinitely",
                    "model_conf": 108.03
                }
            }
        },
        "services": {
            "10 Session Pack - $245": {
                "cost": 245,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $380": {
                "cost": 380,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $510": {
                "cost": 510,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 245,
            "20-pack": 380,
            "premier-family-addon-6-month-corp-2": 179,
            "surcharge-2": 0,
            "30-pack": 510,
            "premier": 189,
            "basic": 89,
            "elite-family-addon-6-month-corp-2": 119,
            "elite": 129,
            "additional-session-2": 18,
            "name": "Yorktown Heights, NY",
            "slug": "84c269ce-b180-4859-9463-303d30b01bc5"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "12/28/18",
        "url": "https://www.orangetheory.com/en-us/locations/new-york/yorktown-heights/650-lee-blvd/",
        "index": 103.375,
        "franchise-entity-name": "EU Vortex VI LLC"
    },
    "fa2cbf04-e82b-4173-9ee4-ad6b360f2781": {
        "name": "Orange Theory Amelia Island, FL",
        "address": "463867 STATE ROAD 200 Yulee, Florida 32097",
        "phone": "(904) 639-6003",
        "email": "studiomanager1378@orangetheoryfitness.com",
        "hours": {
            "MONDAY": "5:00AM - 7:45PM",
            "TUESDAY": "5:00AM - 7:45PM",
            "WEDNESDAY": "5:00AM - 7:45PM",
            "THURSDAY": "5:00AM - 7:45PM",
            "FRIDAY": "5:00AM - 7:45PM",
            "SATURDAY": "7:15AM - 12:00PM",
            "SUNDAY": "7:15AM - 12:00PM"
        },
        "geolocation": [
            30.6275806,
            -81.54496
        ],
        "memberships": {
            "Basic": {
                "cost": 69,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "4 Classes Monthly (avg. usage of 1x/week)",
                "suggested": {
                    "cost": 69.14,
                    "duration": "indefinitely",
                    "model_conf": 153.78
                }
            },
            "Elite": {
                "cost": 109,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "8 Classes Monthly (avg. usage of 2x/week)",
                "suggested": {
                    "cost": 109.17,
                    "duration": "indefinitely",
                    "model_conf": 185.83
                }
            },
            "Premier": {
                "cost": 169,
                "tax-amount": 0,
                "per": "month",
                "term": "1-month",
                "description": "Unlimited Classes (recommended for usage of 3x/week or more)",
                "suggested": {
                    "cost": 169.13,
                    "duration": "indefinitely",
                    "model_conf": 111.93
                }
            }
        },
        "services": {
            "10 Session Pack - $199": {
                "cost": 199,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "20 Session Pack - $359": {
                "cost": 359,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            },
            "30 Session Pack - $499": {
                "cost": 499,
                "tax-amount": 0,
                "per": "",
                "term": "",
                "description": ""
            }
        },
        "tier_details": {
            "drop-in-fee-2": 28,
            "late-cancel-2": 12,
            "10-pack": 199,
            "20-pack": 359,
            "premier-family-addon-6-month-corp-2": 159,
            "surcharge-2": 0,
            "30-pack": 499,
            "premier": 169,
            "basic": 69,
            "elite-family-addon-6-month-corp-2": 99,
            "elite": 109,
            "additional-session-2": 18,
            "name": "Amelia Island, FL",
            "slug": "fa2cbf04-e82b-4173-9ee4-ad6b360f2781"
        },
        "brand": "Orangetheory Fitness",
        "coming_soon": false,
        "opening_date": "10/10/20",
        "url": "https://www.orangetheory.com/en-us/locations/florida/yulee/463867-state-road-200/",
        "index": 93.4,
        "franchise-entity-name": "F12 Enterprises, LLC"
    }
}