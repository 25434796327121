import {
  plotrMultiplayerData,
  TerritoryType,
} from '@plotr/plotr-multiplayer-data';

export const mergeTerritories = (
  data: {
    id: string;
    label: string;
    type: TerritoryType;
    group: string;
    geometry: string;
  }[]
) => {
  console.log(data);
  const territories = plotrMultiplayerData.methods?.territories;
  if (!territories) return;

  const territoriesToAdd = data.map((file) => {
    try {
      const geometry = JSON.parse(file.geometry);

      const coordinates = geometry.coordinates[0][0]?.[0]
        ? geometry.coordinates[0]
        : geometry.coordinates;

      return {
        id: file.id,
        label: file.label,
        group: file.group,
        type: file.type,
        boundaries: {
          [file.id]: { id: file.id, coordinates: coordinates },
        },
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  });

  territories.addTerritories(
    territoriesToAdd.filter((territory) => territory !== null)
  );
};

type pinResponse = {
  id?: string;
  label?: string;
  group?: string;
  geometry: string;
  keyValuePairs?: Record<string, string>;
  tags?: string[];
};

export const mergePins = (data: pinResponse[]) => {
  const pins = plotrMultiplayerData.methods?.pins;
  if (!pins) return;

  const pinsToAdd = data.map((file) => {
    try {
      const { coordinates } = JSON.parse(file.geometry);

      return {
        id: file.id ?? crypto.randomUUID(),
        label: file.label ?? 'Default Label',
        group: file.group ?? 'Default Group',
        keyValuePairs: file.keyValuePairs ?? {},
        pos: { lng: coordinates[0], lat: coordinates[1] },
        tags: file.tags ?? [],
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  });

  pins.addPins(pinsToAdd.filter((pin) => pin !== null));
};
