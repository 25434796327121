import { SourceType } from '@plotr/plotr-multiplayer-data/src';
import { z } from 'zod';

const AddTagRuleSchema = z.object({
  ruleType: z.nativeEnum(SourceType, { message: 'Select type to continue' }),
  ruleValue: z
    .string({ message: 'Value is required field' })
    .min(1, { message: 'Value is required field' }),
  ruleKeyValue: z.string().optional(),
});

const AddKeyRuleSchema = z.object({
  ruleType: z.nativeEnum(SourceType, { message: 'Select type to continue' }),
  ruleValue: z
    .string({ message: 'Value is required field' })
    .min(1, { message: 'Value is required field' }),
  ruleKeyValue: z
    .string({ message: 'Key is required field' })
    .min(1, { message: 'Key is required field' }),
});

type AddTagRuleSchemaType = z.infer<typeof AddTagRuleSchema>;
type AddKeyRuleSchemaType = z.infer<typeof AddKeyRuleSchema>;

export {
  AddKeyRuleSchema,
  AddKeyRuleSchemaType,
  AddTagRuleSchema,
  AddTagRuleSchemaType,
};
