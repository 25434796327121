import { SyntheticEvent, useMemo, useState } from 'react';

import styled from '@emotion/styled';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Box,
  Tab,
  Tabs,
} from '@mui/material';

import { plotrMultiplayerData } from '@plotr/plotr-multiplayer-data/src';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import PopupWithoutWheel from '~/src/features/dynamic-map/components/PopupWithoutWheel';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import GeneralTab from './EditPinTabs/GeneralTab';
import PropertiesTab from './EditPinTabs/PropertiesTab';

const StyledPopup = styled(PopupWithoutWheel)`
  .mapboxgl-popup-content {
    padding: 0.5rem;
    border-radius: 0.5rem;
    min-width: 25rem;
    position: relative;
    top: 0;
  }
`;

const defaultGroupName = 'Default Group';

const EditPinPopup: React.FC = () => {
  const selectPinId = useDynamicMapStore((state) => state.selectCustomPinId);
  const deselectPin = () => selectPinId(null);

  const selectedPinId = useDynamicMapStore(
    (state) => state.selectedCustomPinId
  );
  const customPins = useCustomPins();
  const customPinMethods = plotrMultiplayerData.methods?.pins;

  const pin = useMemo(
    () => customPins.find((p) => p.id === selectedPinId) ?? null,
    [customPins, selectedPinId]
  );
  const pinGroups = useMemo(
    () => [...new Set(customPins.map((p) => p.group))],
    [customPins]
  );

  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const paddingAroundContent = 1;

  return pin != null && customPinMethods != null ? (
    <Card>
      <StyledPopup
        offset={[0, -50] as [number, number]}
        longitude={pin.pos.lng}
        latitude={pin.pos.lat}
        closeOnClick={false}
        onClose={deselectPin}
      >
        <CardHeader
          title={
            <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <EditIcon />
                <Typography variant="h6" fontWeight={700}>
                  Edit Pin
                </Typography>
              </Box>
            </Box>
          }
        />

        <CardContent sx={{ padding: 2 }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="Edit pin tabs"
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="General" sx={{ fontSize: 11 }} />
            <Tab label="Properties" sx={{ fontSize: 11 }} />
          </Tabs>
          <Box p={paddingAroundContent}>
            {tabIndex === 0 && (
              <GeneralTab
                defaultGroupName={defaultGroupName}
                pinGroups={pinGroups}
                pin={pin}
              />
            )}
            {tabIndex === 1 && <PropertiesTab pin={pin} />}
          </Box>
        </CardContent>

        <CardActions>
          <Button
            variant="outlined"
            endIcon={<DeleteIcon />}
            disabled={pin == null}
            onClick={() => {
              customPinMethods?.removePin(pin.id);
              deselectPin();
            }}
            color="error"
          >
            Delete Pin
          </Button>
        </CardActions>
      </StyledPopup>
    </Card>
  ) : null;
};

export default EditPinPopup;
