import { parseEnv } from '@plotr/common-utils/src';
import axios from 'axios';

const env = parseEnv({ MAPBOX_API_KEY: process.env.MAPBOX_API_KEY });

export const getPointLocation = async (lng: number, lat: number) => {
  const { data: location } = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${env.MAPBOX_API_KEY}`
  );

  return {
    country:
      location.features.find((feature: { id: string; place_name: string }) =>
        feature.id.includes('country')
      ) ?? null,
    place:
      location.features.find((feature: { id: string; place_name: string }) =>
        feature.id.includes('address')
      ) ??
      location.features.find((feature: { id: string; place_name: string }) =>
        feature.id.includes('poi')
      ) ??
      null,
  };
};
