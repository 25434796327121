import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Input,
  Slider,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect } from 'react';
import useDemographicStore, {
  searchSettings,
} from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import { NumberInputSchema, numberInputSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { POINT_EVAL_COMMON_STYLE } from '~/src/features/demographic-point-lookup/components/PointEvalMenu';

const StyledInput = styled(Input)({
  '& input[type=number]::-webkit-inner-spin-button': {
    opacity: 1,
  },
  '& input[type=number]::-webkit-outer-spin-button': {
    opacity: 1,
  },
});

const BOUNDARY_TYPES = ['radius', 'driving', 'walking', 'cycling'] as const;

export function BoundaryController() {
  const { searchRadius, setSearchRadius, searchType, setSearchType } =
    useDemographicStore((state) => ({
      searchRadius: state.demographicSearchRadius,
      setSearchRadius: state.setDemographicSearchRadius,
      searchType: state.boundaryType,
      setSearchType: state.setDemographicBoundaryType,
    }));

  const { control, formState, setValue, watch, trigger } =
    useForm<NumberInputSchema>({
      mode: 'all',
      resolver: zodResolver(
        numberInputSchema({
          min: searchSettings[searchType].min,
          max: searchSettings[searchType].max,
          step: searchSettings[searchType].step,
        })
      ),
      defaultValues: {
        radius: searchRadius ?? searchSettings[searchType].min,
      },
    });

  const setLocalRadius = useCallback(
    (radius: number) => {
      setValue('radius', radius);
    },
    [setValue]
  );
  const localRadius = watch('radius');

  function handleNumberInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: number[] | string[]) => void
  ) {
    const textValue = e.target.value;
    const value = Number(textValue);

    if (textValue === '') {
      onChange('');
      return;
    }

    if (value < searchSettings[searchType].min) {
      onChange(searchSettings[searchType].min);
      return;
    }
    if (value > searchSettings[searchType].max) {
      onChange(searchSettings[searchType].max);
      return;
    }

    onChange(value);
  }

  useEffect(() => {
    setLocalRadius(searchRadius);
  }, [searchRadius, setLocalRadius]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (localRadius === null || formState.errors.radius) {
        return;
      }

      setSearchRadius(localRadius);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [localRadius, setSearchRadius, formState.errors.radius]);

  return (
    <Box display="flex" flexDirection="column" gap={2} alignItems="center">
      <Grid item xs={12} sx={POINT_EVAL_COMMON_STYLE}>
        <ButtonGroup fullWidth>
          {BOUNDARY_TYPES.map((type) => (
            <Button
              key={type}
              onClick={() => setSearchType(type as typeof searchType)}
              variant={searchType === type ? 'contained' : 'outlined'}
              fullWidth
            >
              {type}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ ...POINT_EVAL_COMMON_STYLE, alignItems: 'start', gap: '1rem' }}
      >
        <Slider
          value={localRadius ?? searchSettings[searchType].min}
          onChange={(_, radius) => {
            setLocalRadius(radius as number);
            trigger('radius');
          }}
          step={searchSettings[searchType].step}
          marks={searchSettings[searchType].marks}
          min={searchSettings[searchType].min}
          max={searchSettings[searchType].max}
          valueLabelDisplay="off"
        />
        <Controller
          name="radius"
          control={control}
          render={({ field }) => (
            <Grid>
              <Tooltip title={formState.errors.radius?.message}>
                <StyledInput
                  size="small"
                  {...field}
                  onChange={(e) => handleNumberInputChange(e, field.onChange)}
                  inputProps={{
                    step: searchSettings[searchType].numberInputStep,
                    type: 'number',
                  }}
                  error={Boolean(formState.errors.radius)}
                  sx={{
                    width: '3.5rem',
                  }}
                />
              </Tooltip>
              <Typography textAlign="end">
                {searchSettings[searchType].displayUnit}
              </Typography>
            </Grid>
          )}
        />
      </Grid>
    </Box>
  );
}
