import { Box, Divider, Typography } from '@mui/material';
import { FileCard } from './FileCard/FileCard';
import { useUserFiles } from './files-service';
import UploadFilesButton from './UploadFilesButton';

const FilesMenu = () => {
  const { data } = useUserFiles();

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop={-1}
        marginBottom={2}
      >
        <Typography variant="h6" marginRight={1}>
          YOUR FILES
        </Typography>
        <Box display="flex" alignItems="center">
          <Divider orientation="vertical" flexItem sx={{ marginRight: 1 }} />
        </Box>
      </Box>
      <Divider />
      <UploadFilesButton />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginTop={2}
        marginBottom={2}
        gap={1}
        overflow="auto"
        sx={{ marginBottom: 1 }}
      >
        {data?.map((file) => <FileCard key={file.id} file={file} />).reverse()}
      </Box>
    </Box>
  );
};

export default FilesMenu;
