import React from 'react';
import { Grid, Button, SxProps, Theme } from '@mui/material';

interface RelativeDateButtonProps {
  label: string;
  startDate: Date;
  endDate: Date;
  onClick: (start: Date, end: Date) => void;
  isLoading?: boolean;
  isActive?: boolean; // New prop to indicate if the button should be highlighted
}

const RelativeDateButton: React.FC<RelativeDateButtonProps> = ({
  label,
  startDate,
  endDate,
  onClick,
  isLoading = false,
  isActive = false, // Default to false
}) => {
  const buttonStyles: SxProps<Theme> = {
    fontSize: 7.2,
    color: 'black',
    borderRadius: 4,
    width: '100%',
    height: '100%',
    whiteSpace: 'nowrap', // Prevents text from wrapping
    backgroundColor: isActive ? 'orange' : 'white', // Highlight if active
    '&:hover': {
      backgroundColor: isActive ? 'darkorange' : 'lightgray', // Darker on hover when active
    },
  };

  return (
    <Grid item xs={4}>
      <Button
        variant="outlined"
        onClick={() => onClick(startDate, endDate)}
        sx={buttonStyles}
        disabled={isLoading}
      >
        {label}
      </Button>
    </Grid>
  );
};

export default RelativeDateButton;
