import React, { useEffect } from 'react';
import { HUBSPOT } from '~/src/constants';
import { HubspotModalType } from '~/src/features/dynamic-map-controller/PulseDrawer/HubspotModal';

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (options: {
          region?: string;
          portalId: string;
          formId: string;
          target: string;
        }) => void;
      };
    };
  }
}

interface HubspotContactFormProps {
  type: HubspotModalType;
  onFormSubmit?: () => void;
}

const HubspotContactForm: React.FC<HubspotContactFormProps> = ({
  type,
  onFormSubmit,
}) => {
  const formIds: Record<HubspotModalType, string> = {
    [HubspotModalType.Mobile]: HUBSPOT.MOBILE,
    [HubspotModalType.Sonar]: HUBSPOT.SONAR,
    [HubspotModalType.SonarBrand]: HUBSPOT.SONAR,
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://js.hsforms.net/forms/v2.js`;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: HUBSPOT.REGION,
          portalId: HUBSPOT.PORTAL_ID,
          formId: formIds[type],
          target: '#hubspotForm',
        });

        // Add an event listener to detect form submission
        const formElement = document.querySelector('#hubspotForm form');
        if (formElement) {
          formElement.addEventListener('submit', () => {
            if (onFormSubmit) {
              onFormSubmit();
            }
          });
        }
      }
    });

    // Cleanup function to remove the script and event listeners
    return () => {
      document.body.removeChild(script);
      const formElement = document.querySelector('#hubspotForm form');
      if (formElement) {
        formElement.removeEventListener('submit', () => {
          if (onFormSubmit) {
            onFormSubmit();
          }
        });
      }
    };
  }, [type, onFormSubmit]);

  return (
    <div>
      <div id="hubspotForm"></div>
    </div>
  );
};

export default HubspotContactForm;
