import {
  LayerConfig,
  LayerType,
} from '../../features/dynamic-map-controller/components/MapLayersMenu/types';
import { Color } from './colors';

const incomeBrackets = [
  {
    id: '5yr_proj_inc_a_under_30k',
    label: 'Under $30k',
    key: 'under_30k',
    thresholds: [0, 175, 350, 525, 700, 868],
  },
  {
    id: '5yr_proj_inc_b_30k_50k',
    label: '$30k-$50k',
    key: '30k_to_50k',
    thresholds: [0, 145, 290, 435, 580, 726],
  },
  {
    id: '5yr_proj_inc_c_50k_100k',
    label: '$50k-$100k',
    key: '50k_to_100k',
    thresholds: [0, 360, 720, 1080, 1440, 1800],
  },
  {
    id: '5yr_proj_inc_d_100k_200k',
    label: '$100k-$200k',
    key: '100k_to_200k',
    thresholds: [0, 662, 1324, 1986, 2648, 3311],
  },
  {
    id: '5yr_proj_inc_e_200k_500k',
    label: '$200k-$500k',
    key: '200k_to_500k',
    thresholds: [0, 363, 726, 1089, 1452, 1818],
  },
  {
    id: '5yr_proj_inc_f_over_500k',
    label: 'Over $500k',
    key: 'over_500k',
    thresholds: [0, 91, 182, 273, 364, 455],
  },
];

const fiveYearProjectionLayers: Record<string, LayerConfig> =
  incomeBrackets.reduce(
    (acc, bracket) => {
      acc[`5yr_proj_inc_${bracket.id}`] = {
        type: LayerType.Insights,
        featureType: 'polygon',
        displayName: `${bracket.label} - 5-Year Income Projection`,
        mergeGroup: '5yr_proj_inc',
        category: 'Population.Income',
        groupName: 'demographic_layers',
        class: 'income',
        sourceTileset: {
          label: '5yr Block Group Income Projections',
          source: 'luketruitt1.bg_5yr_inc_proj',
          source_layer: 'bg_5yr_inc_proj',
        },
        styleConfig: {
          threshold: bracket.thresholds,
          colors: [
            Color.transparent,
            Color.yellow,
            Color.yellowOrange,
            Color.orange,
            Color.darkOrange,
            Color.darkRed,
          ],
          fallback: 0,
          expression: ['get', `${bracket.key}`],
          opacityStops: [1, 1, 1, 1, 1, 1],
          unit: 'households',
        },
        dataConfig: {
          header: 'geoid',
          fields: [
            {
              [`${bracket.label} Households (5-Year Projection)`]: {
                key: `${bracket.key}`,
                format: 'integer',
              },
            },
          ],
        },
        permission: 'read:5yr_income_projection',
      };

      return acc;
    },
    {} as Record<string, LayerConfig>
  );

export default fiveYearProjectionLayers;
