import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { MobileDataConfig } from '~/src/constants';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import { calculateAgeOfStore } from './MobileDataUtils';

import MouseOverPopover from './MouseOverPopover';

const POIFeatureDetails: React.FC = () => {
  const { CBSA_LABEL } = MobileDataConfig;
  const clickedPOIFeature = useDynamicMapStore(
    (state) => state.clickedPOiFeature
  );

  const poiData = useDemographicStore((state) => state.poiData);
  const poiDataIsLoading = useDemographicStore(
    (state) => state.poiDataIsLoading
  );

  const matchedBrand = useDynamicMapStore((state) => state.matchedBrand);

  const { name, streetAddress, city, region, postalCode } =
    clickedPOIFeature?.properties || {};

  const { openedOn, closedOn, trackingClosedSince, cbsaName } = poiData || {};

  const getRawBrandImage = (): string => {
    const brandWebsite = matchedBrand?.website;
    if (brandWebsite) {
      const { protocol, hostname } = new URL(brandWebsite);
      const imageUrl = `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${protocol}//${hostname}&size=96`;
      return imageUrl;
    }
    return '';
  };

  const getStoreStatus = () => {
    if (closedOn && trackingClosedSince) {
      return `CLOSED SINCE ${trackingClosedSince}`;
    } else if (closedOn) {
      return `CLOSED`;
    } else return `OPEN`;
  };

  const getCBSAName = () => {
    if (cbsaName) {
      return `${cbsaName} ${CBSA_LABEL}`;
    }
    return '';
  };

  const Detail = ({ content }: { content: string }) => {
    return (
      <Typography fontSize={14} style={{ color: '#424242' }}>
        {content}
      </Typography>
    );
  };

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '16px',
        marginBottom: '16px',
        position: 'relative',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        minHeight: '100px',
        width: '98%',
      }}
    >
      {poiDataIsLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            <Avatar
              src={getRawBrandImage()}
              sx={{ width: 40, height: 40, marginRight: '12px' }}
            />
            <Box>
              <Typography fontWeight="bold" fontSize={20} marginBottom={2}>
                {name}
              </Typography>
              <Stack spacing={0.7}>
                <Detail
                  content={`${streetAddress}, ${city}, ${region}, ${postalCode}`}
                />
                <Detail content={getCBSAName()} />
                <Detail content={poiData?.subCategory || ''} />
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '20px',
              right: '16px',
              textAlign: 'right',
            }}
          >
            <MouseOverPopover text={getStoreStatus()}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '4px',
                }}
              >
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    backgroundColor: getStoreStatus().includes('CLOSED')
                      ? 'red'
                      : 'green',
                    borderRadius: '50%',
                    marginRight: '8px',
                  }}
                />
                <Typography fontWeight="bold" fontSize={10}>
                  {calculateAgeOfStore(openedOn || '')}
                </Typography>
              </Box>
            </MouseOverPopover>
          </Box>
        </>
      )}
    </Card>
  );
};

export default POIFeatureDetails;
