export function formatValue({
  value,
  valueType = 'absolute',
  valueUnit,
}: {
  value: number;
  valueType?: 'absolute' | 'percentage' | 'money';
  valueUnit?: string;
}) {
  if (valueType === 'percentage') {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'percent',
      maximumFractionDigits: 2,
    });

    return formatter.format(value);
  }

  if (valueType === 'money' || valueUnit === 'USD') {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      compactDisplay: 'short',
      maximumFractionDigits: 2,
    }).format(value);
  }

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);
}
