import { Box } from '@mui/material';
import { Rule, SourceType, TargetType } from '@plotr/plotr-multiplayer-data';
import CollapseCard from '~/src/common/components/CollapseCard';
import RulesCollapseSection from '~/src/features/rules-collapse';

export const getColor = (
  rulesets: Rule[],
  featureProperties: { [key: string]: string[] | string }
) => {
  const normalizedFeaturePropertiesTags = (
    featureProperties.tags as string[]
  )?.map((tag: string) => tag.toLowerCase());

  for (const rule of rulesets) {
    if (rule.targetType !== TargetType.Territories) {
      continue;
    }

    if (
      rule.sourceType === SourceType.KeyValue &&
      rule.propertyKey &&
      featureProperties[rule.propertyKey] === rule.evaluation.value
    ) {
      return rule.effect;
    }

    if (
      rule.sourceType === SourceType.Tag &&
      normalizedFeaturePropertiesTags?.includes(
        String(rule.evaluation.value).toLowerCase() as string
      )
    ) {
      return rule.effect;
    }
  }

  // Default color
  return '#888';
};

const GlobalTerritorySettingsMenu = () => {
  return (
    <CollapseCard title="Global Territory Settings">
      <Box p={2}>
        <RulesCollapseSection targetType={TargetType.Territories} />
      </Box>
    </CollapseCard>
  );
};

export default GlobalTerritorySettingsMenu;
