import { Grid, Typography } from '@mui/material';
import StatDisplay from '../StatDisplay';
import { MobileDataConfig } from '~/src/constants';
import BlurTextLock from '../BlurTextLock';
import React from 'react';
import RankingProgressBar from './RankingProgressBar';
import { calculatePercentile } from '../MobileDataUtils';
import { HubspotModalType } from '../../HubspotModal';

interface NationalRankDisplayProps {
  ntlRanking?: number;
  totalNumberOfNationalRankedBrandStores?: number;
  hasPermission: boolean | null;
  clickedPOIFeature: mapboxgl.MapboxGeoJSONFeature | null;
  contextString?: HubspotModalType;
}

const NationalRankDisplay: React.FC<NationalRankDisplayProps> = ({
  ntlRanking,
  totalNumberOfNationalRankedBrandStores,
  hasPermission,
  clickedPOIFeature,
  contextString = HubspotModalType.Sonar,
}) => {
  const {
    NATIONAL_RANKING_PERCENTILE,
    NATIONAL_RANKING,
    NATIONAL_RANKING_DESCRIPTION,
    MOBILE_STAT_FONT_SIZE,
    MOBILE_TOTAL_RANKING_NUMBER_FONT_SIZE,
  } = MobileDataConfig;

  return (
    <>
      <Grid item xs={12}>
        <StatDisplay
          label={NATIONAL_RANKING}
          value={
            ntlRanking && totalNumberOfNationalRankedBrandStores ? (
              <>
                <Typography
                  component="span"
                  fontWeight="bold"
                  fontSize={MOBILE_STAT_FONT_SIZE}
                  mr={1}
                >
                  {hasPermission ? (
                    ntlRanking
                  ) : (
                    <BlurTextLock intensity={7} contextString={contextString}>
                      4/1000
                    </BlurTextLock>
                  )}
                </Typography>
                {' / '}
                <Typography
                  component="span"
                  fontSize={MOBILE_TOTAL_RANKING_NUMBER_FONT_SIZE}
                  ml={2}
                >
                  {hasPermission && totalNumberOfNationalRankedBrandStores}
                </Typography>
              </>
            ) : (
              <BlurTextLock intensity={7} contextString={contextString}>
                4/1000
              </BlurTextLock>
            )
          }
          description={
            <>
              This rank is based off of{' '}
              <Typography component="span" fontWeight="bold">
                {hasPermission ? (
                  totalNumberOfNationalRankedBrandStores
                ) : (
                  <span
                    style={{
                      filter: `blur(3px)`,
                      userSelect: 'none', // don't allow selecting text
                    }}
                  >
                    99999
                  </span>
                )}
              </Typography>
              {' total locations. ' +
                NATIONAL_RANKING_DESCRIPTION(
                  clickedPOIFeature?.properties?.name
                )}
            </>
          }
        />
      </Grid>
      {ntlRanking &&
        totalNumberOfNationalRankedBrandStores &&
        hasPermission && (
          <Grid item xs={12}>
            <RankingProgressBar
              value={parseInt(
                calculatePercentile(
                  ntlRanking,
                  totalNumberOfNationalRankedBrandStores
                )
              )}
              title={NATIONAL_RANKING_PERCENTILE}
            />
          </Grid>
        )}
    </>
  );
};

export default NationalRankDisplay;
