import { InfoOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import useDemographicStore from '~/src/features/demographic-point-lookup/hooks/useDemographicStore';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useLayersStore from '~/src/features/dynamic-map/hooks/useLayersStore';
import LayerImageCategories from '../../components/MapLayersMenu/components/EditLayerModal/LayerImageCategories';
import getMapLayerDefinitions from '../../data/getMapLayerDefinitions';
import MouseOverPopover from '../MobileData/MouseOverPopover';
import {
  accordionDetailStyle,
  accordionStyles,
  accordionTitleStyle,
} from '../PulseDrawer';
import { PsychographicsTopTable } from './PsychographicsTopTable';
import { usePsychographicSearch } from './usePsychographicSearch';

export const PsychographicsAccordion = ({
  title,
  description,
  handleAccordionChange,
}: {
  title: string;
  description: string;
  handleAccordionChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const expandedMobileDataAccordion = useDynamicMapStore(
    (state) => state.expandedMobileDataAccordion
  );

  const { layers, setLayers } = useLayersStore((state) => ({
    layers: state.layers,
    setLayers: state.setLayers,
  }));

  const { boundaryData } = useDemographicStore((state) => ({
    boundaryData: state.boundaryData,
  }));

  const { data, isLoading, isFetching, isRefetching } =
    usePsychographicSearch(boundaryData);

  const isOpened = expandedMobileDataAccordion === 'psychographics';

  const [wasVisible, setWasVisible] = useState(false);

  useEffect(() => {
    !isOpened &&
      setWasVisible(
        layers.some((layer) => layer.id === 'lifestyle_segmentation')
      );
  }, [isOpened, layers]);

  const psychographicsLayer = useMemo(() => {
    const layerDefinition = getMapLayerDefinitions([]).lifestyle_segmentation;
    return {
      ...layerDefinition,
      id: 'lifestyle_segmentation',
      insight: 'lifestyle_segmentation',
      layerGroup: { layerIds: ['lifestyle_segmentation'] },
      opacity: 0,
      imageUrl: LayerImageCategories[layerDefinition.category],
      isLocked: false,
    };
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    if (!isFirstRender) {
      if (isOpened && !wasVisible) {
        setLayers([...layers, { ...psychographicsLayer, opacity: 1 }]);
      }
      if (expandedMobileDataAccordion !== 'psychographics' && !wasVisible) {
        const layerToRemove = layers.find(
          (layer) => layer.id === 'lifestyle_segmentation'
        );
        setLayers(layers.filter((layer) => layer.id !== layerToRemove?.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedMobileDataAccordion, isOpened]);

  return (
    <Accordion
      expanded={isOpened}
      onChange={handleAccordionChange('psychographics')}
      sx={accordionStyles}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={title}
        id={title}
      >
        <RecentActorsIcon />
        <Typography sx={accordionTitleStyle}>{title}</Typography>
        <Box marginLeft={1}>
          <MouseOverPopover text={description}>
            <InfoOutlined style={{ color: 'gray' }} />
          </MouseOverPopover>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={accordionDetailStyle}>
        <PsychographicsTopTable
          data={data ?? []}
          isLoading={isLoading || isFetching || isRefetching}
        />
      </AccordionDetails>
    </Accordion>
  );
};
