import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';

const env = parseEnv({
  API_V1: process.env.API_V1,
  API_V2: process.env.API_V2,
});

type BlockGroupId = string;

type CensusBlockGroupsByScore = {
  [score: string]: BlockGroupId[];
};

export interface CensusBlockGroupSet {
  geoIDFilter: Array<string | number>;
  groupName: string;
}

function getGeoIDFilter(
  data: CensusBlockGroupsByScore,
  scores: number | number[]
) {
  const scoreList = Array.isArray(scores) ? scores : [scores];

  return scoreList.reduce<number[]>(
    (acc, score) =>
      data[score.toString()]
        ? acc.concat(data[score.toString()].map((val) => parseInt(val)))
        : acc,
    []
  );
}

export async function fetchBlockGroups(
  apiPath: string
): Promise<CensusBlockGroupSet[]> {
  console.debug('Fetching block groups...');

  const response = await axios.get(env.API_V2 + apiPath);

  if (response.status !== 200) {
    const responseBody = await response.data;
    throw new Error(
      `HTTP ${response.status} ${response.statusText}: ${responseBody}`
    );
  }

  const blockGroupsByScore = response.data as CensusBlockGroupsByScore;

  const blockGroupsCount = Object.values(blockGroupsByScore).flat().length;

  const blockGroupSet = [
    {
      geoIDFilter: getGeoIDFilter(blockGroupsByScore, [7, 8, 9, 10]),
      groupName: 'A',
    },
    {
      geoIDFilter: getGeoIDFilter(blockGroupsByScore, [4, 5, 6]),
      groupName: 'B',
    },
    {
      geoIDFilter: getGeoIDFilter(blockGroupsByScore, [2, 3]),
      groupName: 'C',
    },
  ];

  if (blockGroupsByScore == null || blockGroupsCount === 0) {
    console.warn('No block groups found for the given client.');
    return [];
  }

  console.debug(
    `Block groups fetched! ${blockGroupsCount} block groups found.`
  );

  return blockGroupSet;
}
