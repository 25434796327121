export const getBoundingBoxFromBoundaries = (
  boundaries: {
    id: string;
    coordinates?: number[][] | undefined;
  }[]
) => {
  // Collect all coordinates from each boundary
  const allCoordinates: number[][] = [];
  boundaries.forEach((boundary) => {
    // Check if boundary has coordinates and they are in the correct format
    if (boundary?.coordinates && Array.isArray(boundary.coordinates)) {
      boundary.coordinates.forEach((coordinate) => {
        if (Array.isArray(coordinate) && coordinate.length === 2) {
          allCoordinates.push(coordinate);
        }
      });
    }
  });

  // Find extreme points
  let minLng = Number.POSITIVE_INFINITY,
    minLat = Number.POSITIVE_INFINITY,
    maxLng = Number.NEGATIVE_INFINITY,
    maxLat = Number.NEGATIVE_INFINITY;

  allCoordinates.forEach((coordinates) => {
    if (Array.isArray(coordinates) && coordinates.length === 2) {
      const [lng, lat] = coordinates;
      if (lng < minLng) minLng = lng;
      if (lat < minLat) minLat = lat;
      if (lng > maxLng) maxLng = lng;
      if (lat > maxLat) maxLat = lat;
    }
  });

  return [
    [
      [minLng, minLat],
      [maxLng, minLat],
      [maxLng, maxLat],
      [minLng, maxLat],
      [minLng, minLat],
    ],
  ];
};
