// RecenterControl.tsx
import type { IControl } from 'mapbox-gl';
import { useControl } from 'react-map-gl';

type RecenterControlProps = {
  onRecenter: () => void;
  position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
};

class RecenterMapControl implements IControl {
  _container: HTMLDivElement;
  _map?: mapboxgl.Map;
  _onRecenter: () => void;

  constructor(onRecenter: () => void) {
    this._onRecenter = onRecenter;
    this._container = document.createElement('div');
  }

  onAdd(map: mapboxgl.Map) {
    this._map = map;
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container.innerHTML = `
      <button 
        class="mapboxgl-ctrl-icon" 
        type="button" 
        title="Recenter map" 
        aria-label="Recenter map"
        style="display: block; padding: 5px"
      >
        <svg viewBox="0 0 24 24" style="width: 20px; height: 20px;">
          <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" fill="currentColor"/>
        </svg>
      </button>
    `;

    this._container.addEventListener('click', this._onRecenter);
    return this._container;
  }

  onRemove() {
    if (!this._container) return;
    this._container.removeEventListener('click', this._onRecenter);
    this._container.remove();
    this._map = undefined;
  }
}

export function RecenterControl({
  onRecenter,
  position = 'bottom-right',
}: RecenterControlProps) {
  useControl(() => new RecenterMapControl(onRecenter), { position });
  return null;
}
