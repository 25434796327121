import useCustomPins from '~/src/global/hooks/useCustomPins';
import PinCard from '~/src/features/custom-pins/pin-cards/PinCard';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import { Box } from '@mui/material';

const GroupCustomPinsMenu = () => {
  const customPins = useCustomPins();
  const selectedPinGroup = useDynamicMapStore(
    (state) => state.selectedPinGroup
  );
  const pinsInGroup = customPins.filter(
    (pin) => pin.group === selectedPinGroup
  );

  return (
    <Box sx={{ overflowY: 'auto' }}>
      {pinsInGroup.map((pin) => (
        <PinCard key={pin.id} pin={pin} />
      ))}
    </Box>
  );
};

export default GroupCustomPinsMenu;
