import { Box, DialogContentText } from '@mui/material';
import { RefObject } from 'react';

export const ACCEPTED_FILE_TYPES = '.geojson, .json, .shp, .csv, .kml';

export const UploadFileBlock = ({
  handleFileChange,
  inputRef,
}: {
  handleFileChange: (files: FileList | null) => void;
  inputRef: RefObject<HTMLInputElement>;
}) => {
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFileChange(event.dataTransfer.files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <Box
      border="2px solid"
      borderRadius="6px"
      borderColor="divider"
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'grey.200',
        },
      }}
      py={6}
      px={4}
      onClick={() => inputRef.current?.click()}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      width="20rem"
    >
      <DialogContentText variant="body1" textAlign="center">
        Please select or drop files to upload ({ACCEPTED_FILE_TYPES})
      </DialogContentText>
      <input
        type="file"
        accept={ACCEPTED_FILE_TYPES}
        hidden
        multiple
        ref={inputRef}
        onChange={(e) => handleFileChange(e.target.files)}
      />
    </Box>
  );
};
