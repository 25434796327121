import { DeleteOutline } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { FileResponse, useDeleteUserFile } from '../files-service';

export const DeleteFileButton = ({ file }: { file: FileResponse }) => {
  const [open, setOpen] = useState(false);
  const { mutate: deleteFile, isLoading } = useDeleteUserFile();

  const handleSubmit = () => {
    deleteFile(file.id);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Delete">
        <IconButton onClick={() => setOpen(true)}>
          {isLoading ? <CircularProgress size={20} /> : <DeleteOutline />}
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Delete file</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSubmit} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
