{
    "Solon": {
        "name": "Dogtopia: Solon",
        "address": "6025 Kruse Dr., Solon, Ohio 44139",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(440) 472-0072",
        "email": "solon@dogtopia.com",
        "url": "https://www.dogtopia.com/ohio-solon",
        "geolocation": [
            41.3932638,
            -81.4428341
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Bellevue": {
        "name": "Dogtopia: Bellevue",
        "address": "1188 106th Ave NE, Bellevue, Washington 98004",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(425) 516-7676",
        "email": "bellevue@dogtopia.com",
        "url": "https://www.dogtopia.com/washington-bellevue",
        "geolocation": [
            47.6207523,
            -122.1987185
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Waukee": {
        "name": "Dogtopia: Waukee",
        "address": "1500 NW Gettysburg Lane 101 & 102, Waukee, Iowa 50263",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(515) 220-8181",
        "email": "waukee@dogtopia.com",
        "url": "https://www.dogtopia.com/iowa-waukee",
        "geolocation": [
            41.6168502,
            -93.9058786
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Houston Washington Ave": {
        "name": "Dogtopia: Houston Washington Ave",
        "address": "2311 Washington Ave, Houston, Texas 77007",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(281) 869-8330",
        "email": "washingtonave@dogtopia.com",
        "url": "https://www.dogtopia.com/houston-washington-ave",
        "geolocation": [
            29.7680823,
            -95.3822995
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "North Worcester": {
        "name": "Dogtopia: North Worcester",
        "address": "71 Pullman St., Worcester, Massachusetts 01606",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(508) 964-3636",
        "email": "northworcester@dogtopia.com",
        "url": "https://www.dogtopia.com/massachusetts-north-worcester",
        "geolocation": [
            42.3241897,
            -71.798281
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "North Overland Park": {
        "name": "Dogtopia: North Overland Park",
        "address": "10556 Metcalf Ave, Overland Park, Kansas 66212",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(913) 346-2822",
        "email": "northoverlandpark@dogtopia.com",
        "url": "https://www.dogtopia.com/kansas-north-overlandpark",
        "geolocation": [
            38.936916524554825,
            -94.66899547116394
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Downtown Cleveland": {
        "name": "Dogtopia: Downtown Cleveland",
        "address": "784 Euclid Ave, Cleveland, Ohio 44114",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(216) 400-8444",
        "email": "downtowncleveland@dogtopia.com",
        "url": "https://www.dogtopia.com/downtown-cleveland",
        "geolocation": [
            41.50002730077963,
            -81.68764345767212
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Fort Myers": {
        "name": "Dogtopia: Fort Myers",
        "address": "4429 Cleveland Ave, Fort Myers, Florida 33901",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(239) 214-9400",
        "email": "fortmyers@dogtopia.com",
        "url": "https://www.dogtopia.com/florida-fort-myers",
        "geolocation": [
            26.597283,
            -81.8696626
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Parkside": {
        "name": "Dogtopia: Parkside",
        "address": "1200 2nd Ave S, Birmingham, Alabama 35233",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(205) 607-8989",
        "email": "parkside@dogtopia.com",
        "url": "https://www.dogtopia.com/alabama-birmingham-parkside",
        "geolocation": [
            33.506145,
            -86.81399250000001
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "West Frisco": {
        "name": "Dogtopia: West Frisco",
        "address": "303 Main Street, Frisco, Texas 75034",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(214) 807-0230",
        "email": "westfrisco@dogtopia.com",
        "url": "https://www.dogtopia.com/texas-west-frisco",
        "geolocation": [
            33.153374,
            -96.889365
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Brick": {
        "name": "Dogtopia: Brick",
        "address": "1930 Route 88, Brick, New Jersey 08724",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(732) 479-5700",
        "email": "brick@dogtopia.com",
        "url": "https://www.dogtopia.com/new-jersey-brick",
        "geolocation": [
            40.0742031,
            -74.1163799
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Wesmont Station": {
        "name": "Dogtopia: Wesmont Station",
        "address": "80 Rosie Square, Wood-Ridge, New Jersey 07075",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(973) 298-0700",
        "email": "wesmontstation@dogtopia.com",
        "url": "https://www.dogtopia.com/new-jersey-wesmont-station",
        "geolocation": [
            40.8611847,
            -74.0892623
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Midvale": {
        "name": "Dogtopia: Midvale",
        "address": "23 W 7200 South, Midvale, Utah 84047",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(801) 923-5117",
        "email": "midvale@dogtopia.com",
        "url": "https://www.dogtopia.com/salt-lake-city-midvale",
        "geolocation": [
            40.6206941,
            -111.8934828
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Orland Park": {
        "name": "Dogtopia: Orland Park",
        "address": "18030 Wolf Rd, Orland Park, Illinois 60467",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(708) 778-3880",
        "email": "orlandpark@dogtopia.com",
        "url": "https://www.dogtopia.com/orlandpark-illinois",
        "geolocation": [
            41.5620212,
            -87.8929114
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Davis": {
        "name": "Dogtopia: Davis",
        "address": "417 Mace Boulevard Suite S, Davis, California 95618",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(530) 316-4775",
        "email": "davis@dogtopia.com",
        "url": "https://www.dogtopia.com/sacramento-davis",
        "geolocation": [
            38.54984602027013,
            -121.69551475476992
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Summerville": {
        "name": "Dogtopia: Summerville",
        "address": "619 Myers Rd, Summerville, South Carolina 29486",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(843) 949-4333",
        "email": "summerville@dogtopia.com",
        "url": "https://www.dogtopia.com/southcarolina-summerville",
        "geolocation": [
            33.0480378,
            -80.1058801
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Orlando East": {
        "name": "Dogtopia: Orlando East",
        "address": "3016 N Alafaya Trl, Orlando, Florida 32826",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 AM"
        },
        "phone": "(407) 848-3818",
        "email": "orlandoeast@dogtopia.com",
        "url": "https://www.dogtopia.com/orlando-east",
        "geolocation": [
            28.5835744,
            -81.20924629999999
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Fort Worth City View": {
        "name": "Dogtopia: Fort Worth City View",
        "address": "4608 Bryant Irvin Rd, Fort Worth, Texas 76132",
        "hours": {
            "Monday": "6:30 AM - 8:00 PM",
            "Tuesday": "6:30 AM - 8:00 PM",
            "Wednesday": "6:30 AM - 8:00 PM",
            "Thursday": "6:30 AM - 8:00 PM",
            "Friday": "6:30 AM - 8:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(817) 886-4333",
        "email": "cityview@dogtopia.com",
        "url": "https://www.dogtopia.com/texas-fortworth-cityview",
        "geolocation": [
            32.6814966,
            -97.4154039
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 31,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 24.24,
                    "duration": "indefinitely",
                    "model_conf": 311.61
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 58,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 59.15,
                    "duration": "indefinitely",
                    "model_conf": 122.29
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 78,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 80.5,
                    "duration": "indefinitely",
                    "model_conf": 163.15
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 129,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 134.67,
                    "duration": "indefinitely",
                    "model_conf": 200.41
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 52,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 48.58,
                    "duration": "indefinitely",
                    "model_conf": 138.93
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 39,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 37.04,
                    "duration": "indefinitely",
                    "model_conf": 185.18
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 59,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 70.44,
                    "duration": "indefinitely",
                    "model_conf": 362.75
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 45.52,
                    "duration": "indefinitely",
                    "model_conf": 74.88
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.21,
                    "duration": "indefinitely",
                    "model_conf": 135.81
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.09,
                    "duration": "indefinitely",
                    "model_conf": 89.58
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.82,
                    "duration": "indefinitely",
                    "model_conf": 108.8
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.1,
        "coming_soon": false
    },
    "Four Seasons Chesterfield": {
        "name": "Dogtopia: Four Seasons Chesterfield",
        "address": "140 Four Seasons Shopping Center, Chesterfield, Missouri 63017",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(314) 464-2828",
        "email": "fourseasons@dogtopia.com",
        "url": "https://www.dogtopia.com/missouri-fourseasons-chesterfield",
        "geolocation": [
            38.6792038,
            -90.5038387
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.42,
                    "duration": "5 Months",
                    "model_conf": 403.35
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 69,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 68.56,
                    "duration": "2 Months",
                    "model_conf": 149.17
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 98.46,
                    "duration": "2 Months",
                    "model_conf": 211.0
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 149,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 149.97,
                    "duration": "indefinitely",
                    "model_conf": 307.89
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 43.43,
                    "duration": "indefinitely",
                    "model_conf": 188.48
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.99,
                    "duration": "indefinitely",
                    "model_conf": 74.47
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.56,
                    "duration": "indefinitely",
                    "model_conf": 177.73
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.6,
                    "duration": "indefinitely",
                    "model_conf": 97.84
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 19,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 18.37,
                    "duration": "indefinitely",
                    "model_conf": 152.78
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.8,
        "coming_soon": false
    },
    "Arlington By The Parks": {
        "name": "Dogtopia: Arlington By The Parks",
        "address": "4217 S Cooper St, Arlington, Texas 76015",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 AM"
        },
        "phone": "(682) 282-0919",
        "email": "bytheparks@dogtopia.com",
        "url": "https://www.dogtopia.com/texas-arlington-theparks",
        "geolocation": [
            32.6783678,
            -97.13431779999999
        ],
        "services": {},
        "memberships": {},
        "brand": "Dogtopia",
        "index": 0,
        "coming_soon": true
    },
    "Jacksonville Beach Boulevard": {
        "name": "Dogtopia: Jacksonville Beach Boulevard",
        "address": "11300 Beach Blvd, Jacksonville, Florida 32246",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(904) 493-4933",
        "email": "jaxbeachblvd@dogtopia.com",
        "url": "https://www.dogtopia.com/jacksonville-beach-boulevard",
        "geolocation": [
            30.2868027,
            -81.5277251
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.34,
                    "duration": "indefinitely",
                    "model_conf": 420.52
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 67.87,
                    "duration": "indefinitely",
                    "model_conf": 167.77
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 95.53,
                    "duration": "1 Months",
                    "model_conf": 237.48
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 138.95,
                    "duration": "2 Months",
                    "model_conf": 390.69
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 25,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 25.84,
                    "duration": "indefinitely",
                    "model_conf": 196.81
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 35,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 35.19,
                    "duration": "indefinitely",
                    "model_conf": 188.87
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 40,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 39.84,
                    "duration": "1 Months",
                    "model_conf": 75.28
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.7,
                    "duration": "indefinitely",
                    "model_conf": 208.26
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.85,
                    "duration": "5 Months",
                    "model_conf": 97.35
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.53,
                    "duration": "indefinitely",
                    "model_conf": 162.92
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.65,
        "coming_soon": false
    },
    "Garden Home": {
        "name": "Dogtopia: Garden Home",
        "address": "7219 SW Garden Home Rd, Portland, Oregon 97223",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(503) 765-5324",
        "email": "gardenhome@dogtopia.com",
        "url": "https://www.dogtopia.com/portland-gardenhome",
        "geolocation": [
            45.4661668,
            -122.7508839
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 46,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.14,
                    "duration": "indefinitely",
                    "model_conf": 201.77
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 299,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 296.86,
                    "duration": "2 Months",
                    "model_conf": 105.65
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 76.99,
                    "duration": "indefinitely",
                    "model_conf": 159.49
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 100.47,
                    "duration": "indefinitely",
                    "model_conf": 241.79
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 569,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 572.5,
                    "duration": "indefinitely",
                    "model_conf": 231.45
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 73.04,
                    "duration": "10 Months",
                    "model_conf": 248.8
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 65.74,
                    "duration": "indefinitely",
                    "model_conf": 191.93
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.03,
                    "duration": "indefinitely",
                    "model_conf": 199.74
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.82,
                    "duration": "7 Months",
                    "model_conf": 99.55
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.06,
                    "duration": "indefinitely",
                    "model_conf": 169.51
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.05,
        "coming_soon": false
    },
    "Huntsville Madison": {
        "name": "Dogtopia: Huntsville Madison",
        "address": "181 Hughes Rd, #15, Madison, Alabama 35758",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(256) 289-7811",
        "email": "huntsvillemadison@dogtopia.com",
        "url": "https://www.dogtopia.com/alabama-huntsville-madison",
        "geolocation": [
            34.7025917,
            -86.7412775
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.75,
                    "duration": "12 Months",
                    "model_conf": 203.67
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 239,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 238.46,
                    "duration": "indefinitely",
                    "model_conf": 109.91
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 64,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 63.89,
                    "duration": "indefinitely",
                    "model_conf": 170.52
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 83.97,
                    "duration": "indefinitely",
                    "model_conf": 239.91
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 120.05,
                    "duration": "indefinitely",
                    "model_conf": 389.0
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 479,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 476.7,
                    "duration": "1 Months",
                    "model_conf": 254.7
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 69,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.11,
                    "duration": "indefinitely",
                    "model_conf": 261.01
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 59,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 59.53,
                    "duration": "indefinitely",
                    "model_conf": 191.27
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.72,
                    "duration": "indefinitely",
                    "model_conf": 203.68
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.36,
                    "duration": "indefinitely",
                    "model_conf": 99.54
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 17.69,
                    "duration": "6 Months",
                    "model_conf": 169.32
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.175,
        "coming_soon": false
    },
    "North Gilbert": {
        "name": "Dogtopia: North Gilbert",
        "address": "876 N McQueen Rd, Suites 108, Gilbert, Arizona 85233",
        "hours": {
            "Monday": "5:00 AM - 10:00 PM",
            "Tuesday": "5:00 AM - 10:00 PM",
            "Wednesday": "5:00 AM - 10:00 PM",
            "Thursday": "5:00 AM - 10:00 PM",
            "Friday": "5:00 AM - 10:00 PM",
            "Saturday": "5:00 AM - 10:00 PM"
        },
        "phone": "(480) 360-2300",
        "email": "northgilbert@dogtopia.com",
        "url": "https://www.dogtopia.com/north-gilbert",
        "geolocation": [
            33.3655948,
            -111.8252786
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 41.45,
                    "duration": "indefinitely",
                    "model_conf": 371.41
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 109,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 107.87,
                    "duration": "4 Months",
                    "model_conf": 291.97
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 200,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 199.68,
                    "duration": "indefinitely",
                    "model_conf": 212.9
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 31.99,
                    "duration": "indefinitely",
                    "model_conf": 74.26
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.175,
        "coming_soon": false
    },
    "Orlando Sanford": {
        "name": "Dogtopia: Orlando Sanford",
        "address": "100 North Entrance Road, Sanford, Florida 32771",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(407) 289-1110",
        "email": "sanford@dogtopia.com",
        "url": "https://www.dogtopia.com/orlando-sanford",
        "geolocation": [
            28.8076106,
            -81.3357188
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.02,
                    "duration": "8 Months",
                    "model_conf": 201.36
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 39,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.19,
                    "duration": "indefinitely",
                    "model_conf": 425.1
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 70,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 71.57,
                    "duration": "indefinitely",
                    "model_conf": 167.6
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 95,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 96.77,
                    "duration": "indefinitely",
                    "model_conf": 240.56
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 143.02,
                    "duration": "indefinitely",
                    "model_conf": 373.46
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 24,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 22.42,
                    "duration": "indefinitely",
                    "model_conf": 254.21
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 20,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 20.01,
                    "duration": "indefinitely",
                    "model_conf": 190.97
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.46,
                    "duration": "indefinitely",
                    "model_conf": 182.33
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.06,
                    "duration": "indefinitely",
                    "model_conf": 98.1
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.65,
                    "duration": "indefinitely",
                    "model_conf": 162.74
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.5,
        "coming_soon": false
    },
    "North Attleborough": {
        "name": "Dogtopia: North Attleborough",
        "address": "21 East Street, Building B, North Attleborough, Massachusetts 02760",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(508) 659-9988",
        "email": "nattleboro@dogtopia.com",
        "url": "https://www.dogtopia.com/north-attleboro",
        "geolocation": [
            41.9806345,
            -71.33167759999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 48.65,
                    "duration": "indefinitely",
                    "model_conf": 200.73
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 42.2,
                    "duration": "indefinitely",
                    "model_conf": 455.94
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.65,
                    "duration": "indefinitely",
                    "model_conf": 165.38
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 118.54,
                    "duration": "4 Months",
                    "model_conf": 228.33
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 200.64,
                    "duration": "indefinitely",
                    "model_conf": 338.55
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.68,
                    "duration": "indefinitely",
                    "model_conf": 191.77
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.7,
                    "duration": "indefinitely",
                    "model_conf": 159.89
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.775,
        "coming_soon": false
    },
    "Fulshear Katy": {
        "name": "Dogtopia: Fulshear Katy",
        "address": "6434 FM 1463 Rd, Fulshear, Texas 77441",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 5:00 PM"
        },
        "phone": "(281) 606-3113",
        "email": "fulshearkaty@dogtopia.com",
        "url": "https://www.dogtopia.com/fulshear-katy",
        "geolocation": [
            29.69820005888239,
            -95.84723580283683
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 35,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 42.83,
                    "duration": "indefinitely",
                    "model_conf": 460.9
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 65,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 62.81,
                    "duration": "indefinitely",
                    "model_conf": 161.88
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 85.75,
                    "duration": "indefinitely",
                    "model_conf": 223.9
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 134.48,
                    "duration": "indefinitely",
                    "model_conf": 316.96
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 117,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 120.75,
                    "duration": "indefinitely",
                    "model_conf": 199.43
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.73,
                    "duration": "indefinitely",
                    "model_conf": 160.66
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.06,
                    "duration": "indefinitely",
                    "model_conf": 97.8
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.16,
                    "duration": "indefinitely",
                    "model_conf": 172.76
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.55,
        "coming_soon": false
    },
    "Bel Air": {
        "name": "Dogtopia: Bel Air",
        "address": "1427 Rock Spring Rd, Bel Air, Maryland 21014",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(410) 202-2021",
        "email": "belair@dogtopia.com",
        "url": "https://www.dogtopia.com/maryland-belair-north",
        "geolocation": [
            39.5579076,
            -76.36872629999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 82,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 82.14,
                    "duration": "indefinitely",
                    "model_conf": 171.23
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 117,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 117.65,
                    "duration": "indefinitely",
                    "model_conf": 245.29
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 171,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 170.27,
                    "duration": "1 Months",
                    "model_conf": 389.63
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.73,
                    "duration": "10 Months",
                    "model_conf": 208.67
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.36,
                    "duration": "indefinitely",
                    "model_conf": 100.13
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 20.59,
                    "duration": "indefinitely",
                    "model_conf": 173.05
                }
            }
        },
        "brand": "Dogtopia",
        "index": 107.8,
        "coming_soon": false
    },
    "Orlando Apopka": {
        "name": "Dogtopia: Orlando Apopka",
        "address": "2320 E. Semoran Blvd, Apopka, Florida 32703",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(407) 995-6337",
        "email": "apopka@dogtopia.com",
        "url": "https://www.dogtopia.com/orlando-apopka",
        "geolocation": [
            28.6727132,
            -81.4676035
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.75,
                    "duration": "2 Months",
                    "model_conf": 200.8
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 39,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 42.39,
                    "duration": "indefinitely",
                    "model_conf": 415.75
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 70,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 69.03,
                    "duration": "5 Months",
                    "model_conf": 163.89
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 95,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 93.27,
                    "duration": "7 Months",
                    "model_conf": 231.29
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 137.7,
                    "duration": "6 Months",
                    "model_conf": 361.86
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 24,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 26.54,
                    "duration": "indefinitely",
                    "model_conf": 249.84
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 20,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.79,
                    "duration": "4 Months",
                    "model_conf": 190.47
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.36,
                    "duration": "indefinitely",
                    "model_conf": 164.48
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.36,
                    "duration": "indefinitely",
                    "model_conf": 98.43
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.88,
                    "duration": "indefinitely",
                    "model_conf": 161.47
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.5,
        "coming_soon": false
    },
    "Denver Parker": {
        "name": "Dogtopia: Denver Parker",
        "address": "10140 Parkglenn Way #115, Parker, Colorado 80134",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(303) 276-7636",
        "email": "parker@dogtopia.com",
        "url": "https://www.dogtopia.com/denver-parker",
        "geolocation": [
            39.5340207,
            -104.7704622
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 46,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 46.06,
                    "duration": "indefinitely",
                    "model_conf": 425.79
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.77,
                    "duration": "indefinitely",
                    "model_conf": 152.62
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 117,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 117.45,
                    "duration": "indefinitely",
                    "model_conf": 213.68
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 170,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 170.0,
                    "duration": "indefinitely",
                    "model_conf": 311.76
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 79,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 79.09,
                    "duration": "indefinitely",
                    "model_conf": 236.58
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.97,
                    "duration": "1 Months",
                    "model_conf": 180.04
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.18,
                    "duration": "indefinitely",
                    "model_conf": 98.54
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.57,
                    "duration": "indefinitely",
                    "model_conf": 150.74
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.05,
        "coming_soon": false
    },
    "Woodmen Rd Colorado Springs": {
        "name": "Dogtopia: Woodmen Rd Colorado Springs",
        "address": "5780 E. Woodmen Rd, Colorado Springs, Colorado 80920",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(719) 370-4010",
        "email": "woodmenrd@dogtopia.com",
        "url": "https://www.dogtopia.com/coloradosprings-woodmenroad",
        "geolocation": [
            38.9403871,
            -104.7177812
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 37,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 20.09,
                    "duration": "indefinitely",
                    "model_conf": 473.71
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 71.8,
                    "duration": "indefinitely",
                    "model_conf": 165.13
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 102.55,
                    "duration": "indefinitely",
                    "model_conf": 194.98
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 153.93,
                    "duration": "indefinitely",
                    "model_conf": 385.69
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 23,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.07,
                    "duration": "indefinitely",
                    "model_conf": 264.64
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 20,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.37,
                    "duration": "indefinitely",
                    "model_conf": 175.84
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 43,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.9,
                    "duration": "indefinitely",
                    "model_conf": 615.55
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 40,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 40.22,
                    "duration": "indefinitely",
                    "model_conf": 74.41
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.49,
                    "duration": "indefinitely",
                    "model_conf": 94.14
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.91,
                    "duration": "indefinitely",
                    "model_conf": 157.4
                }
            }
        },
        "brand": "Dogtopia",
        "index": 94.6,
        "coming_soon": false
    },
    "Orlando Longwood": {
        "name": "Dogtopia: Orlando Longwood",
        "address": "1823 West State Road 434, Longwood, Florida 32750",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(407) 982-5111",
        "email": "longwood@dogtopia.com",
        "url": "https://www.dogtopia.com/orlando-longwood",
        "geolocation": [
            28.6925925,
            -81.3855292
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 39,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 40.61,
                    "duration": "indefinitely",
                    "model_conf": 392.01
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 70,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 70.18,
                    "duration": "indefinitely",
                    "model_conf": 155.0
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 95,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 94.39,
                    "duration": "2 Months",
                    "model_conf": 217.96
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 138.74,
                    "duration": "3 Months",
                    "model_conf": 330.29
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.91,
                    "duration": "indefinitely",
                    "model_conf": 170.06
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.11,
                    "duration": "indefinitely",
                    "model_conf": 97.07
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.61,
                    "duration": "indefinitely",
                    "model_conf": 152.91
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.15,
        "coming_soon": false
    },
    "Towson": {
        "name": "Dogtopia: Towson",
        "address": "1270 E. Joppa Rd. Suite 200, Towson, Maryland 21286",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 4:00 PM"
        },
        "phone": "(410) 541-5222",
        "email": "towson@dogtopia.com",
        "url": "https://www.dogtopia.com/baltimore-towson",
        "geolocation": [
            39.3966323,
            -76.5747796
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 47,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.74,
                    "duration": "indefinitely",
                    "model_conf": 159.35
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 92.63,
                    "duration": "indefinitely",
                    "model_conf": 129.16
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 126,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 130.34,
                    "duration": "indefinitely",
                    "model_conf": 185.29
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 195,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 202.71,
                    "duration": "indefinitely",
                    "model_conf": 291.27
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 40,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 36.57,
                    "duration": "indefinitely",
                    "model_conf": 248.91
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 26.67,
                    "duration": "indefinitely",
                    "model_conf": 176.39
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 70.1,
                    "duration": "indefinitely",
                    "model_conf": 594.04
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 44.32,
                    "duration": "5 Months",
                    "model_conf": 74.49
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.55,
                    "duration": "indefinitely",
                    "model_conf": 202.04
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.38,
                    "duration": "indefinitely",
                    "model_conf": 97.05
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 17.22,
                    "duration": "indefinitely",
                    "model_conf": 116.69
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.425,
        "coming_soon": false
    },
    "Berkeley Heights": {
        "name": "Dogtopia: Berkeley Heights",
        "address": "410D Springfield Avenue, Berkeley Heights, New Jersey 07922",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(908) 663-9449",
        "email": "berkeleyheights@dogtopia.com",
        "url": "https://www.dogtopia.com/berkeleyheights-newjersey",
        "geolocation": [
            40.6854393,
            -74.4381863
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 43,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 18.14,
                    "duration": "indefinitely",
                    "model_conf": 305.24
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 82,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 86.09,
                    "duration": "indefinitely",
                    "model_conf": 133.86
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 115,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 123.51,
                    "duration": "indefinitely",
                    "model_conf": 190.93
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 180,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 200.09,
                    "duration": "indefinitely",
                    "model_conf": 210.38
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.23,
                    "duration": "indefinitely",
                    "model_conf": 148.04
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 73.67,
                    "duration": "indefinitely",
                    "model_conf": 466.68
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 3.29,
                    "duration": "indefinitely",
                    "model_conf": 155.49
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 5.58,
                    "duration": "indefinitely",
                    "model_conf": 92.17
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.4,
        "coming_soon": false
    },
    "Latham": {
        "name": "Dogtopia: Latham",
        "address": "195 Troy Schenectady Road, Latham, New York 12110",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:00 AM - 6:00 PM"
        },
        "phone": "(518) 380-2400",
        "email": "latham@dogtopia.com",
        "url": "https://www.dogtopia.com/newyork-latham",
        "geolocation": [
            42.7404125,
            -73.7327627
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 41,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 44.68,
                    "duration": "indefinitely",
                    "model_conf": 451.23
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 75.22,
                    "duration": "3 Months",
                    "model_conf": 165.29
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 106.05,
                    "duration": "7 Months",
                    "model_conf": 241.23
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 160,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 155.9,
                    "duration": "10 Months",
                    "model_conf": 391.26
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.82,
                    "duration": "indefinitely",
                    "model_conf": 207.84
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.28,
                    "duration": "indefinitely",
                    "model_conf": 99.82
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.6,
                    "duration": "indefinitely",
                    "model_conf": 174.49
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.775,
        "coming_soon": false
    },
    "Dogtopia Of San Antonio Alamo Heights": {
        "name": "Dogtopia: Dogtopia Of San Antonio Alamo Heights",
        "address": "3814 Broadway, San Antonio, Texas 78209",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(210) 920-9090",
        "email": "alamoheights@dogtopia.com",
        "url": "https://www.dogtopia.com/sanantonio-alamoheights",
        "geolocation": [
            29.4615874,
            -98.46609199999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 50,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 50.5,
                    "duration": "indefinitely",
                    "model_conf": 201.05
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 45,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 46.22,
                    "duration": "indefinitely",
                    "model_conf": 364.62
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 73,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.29,
                    "duration": "3 Months",
                    "model_conf": 153.79
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 98,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 98.01,
                    "duration": "indefinitely",
                    "model_conf": 218.74
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 150,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 149.19,
                    "duration": "2 Months",
                    "model_conf": 328.39
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 69,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 70.57,
                    "duration": "indefinitely",
                    "model_conf": 221.85
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 59,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 58.01,
                    "duration": "6 Months",
                    "model_conf": 190.13
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.65,
                    "duration": "indefinitely",
                    "model_conf": 198.35
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.32,
                    "duration": "indefinitely",
                    "model_conf": 99.46
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 14,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.99,
                    "duration": "indefinitely",
                    "model_conf": 153.96
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.675,
        "coming_soon": false
    },
    "San Antonio Alamo Ranch": {
        "name": "Dogtopia: San Antonio Alamo Ranch",
        "address": "11726 Alamo Ranch Pkwy, San Antonio, Texas 78253",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(210) 858-6968",
        "email": "alamoranch@dogtopia.com",
        "url": "https://www.dogtopia.com/sanantonio-alamoranch",
        "geolocation": [
            29.4848368,
            -98.7263078
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.92,
                    "duration": "indefinitely",
                    "model_conf": 199.91
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 35,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.94,
                    "duration": "indefinitely",
                    "model_conf": 388.71
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 63,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 62.74,
                    "duration": "1 Months",
                    "model_conf": 146.6
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 88,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 88.32,
                    "duration": "indefinitely",
                    "model_conf": 216.58
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 119.78,
                    "duration": "indefinitely",
                    "model_conf": 331.23
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 69,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.74,
                    "duration": "indefinitely",
                    "model_conf": 209.42
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 59,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 58.33,
                    "duration": "4 Months",
                    "model_conf": 191.96
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.57,
                    "duration": "indefinitely",
                    "model_conf": 189.71
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.13,
                    "duration": "indefinitely",
                    "model_conf": 98.75
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 14,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.29,
                    "duration": "indefinitely",
                    "model_conf": 155.28
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.65,
        "coming_soon": false
    },
    "San Antonio Stone Oak": {
        "name": "Dogtopia: San Antonio Stone Oak",
        "address": "2106 E. Sonterra Blvd., Suite 101, San Antonio, Texas 78259",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 2:00 PM"
        },
        "phone": "(210) 866-8030",
        "email": "stoneoak@dogtopia.com",
        "url": "https://www.dogtopia.com/sanantonio-stoneoak",
        "geolocation": [
            29.6083306,
            -98.4593414
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.67,
                    "duration": "2 Months",
                    "model_conf": 200.07
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 40.42,
                    "duration": "indefinitely",
                    "model_conf": 380.43
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 69.06,
                    "duration": "indefinitely",
                    "model_conf": 143.05
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 94.03,
                    "duration": "indefinitely",
                    "model_conf": 215.47
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 130,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 129.81,
                    "duration": "indefinitely",
                    "model_conf": 319.96
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 69,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.09,
                    "duration": "indefinitely",
                    "model_conf": 211.98
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 59,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 60.86,
                    "duration": "indefinitely",
                    "model_conf": 191.95
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 49,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 48.87,
                    "duration": "1 Months",
                    "model_conf": 74.45
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 5.91,
                    "duration": "indefinitely",
                    "model_conf": 191.63
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.29,
                    "duration": "indefinitely",
                    "model_conf": 98.78
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 14,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.68,
                    "duration": "indefinitely",
                    "model_conf": 150.9
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.975,
        "coming_soon": false
    },
    "North Brookhaven": {
        "name": "Dogtopia: North Brookhaven",
        "address": "2152 Johnson Ferry Rd NE, Atlanta, Georgia 30319",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((40) ) 8-5-86",
        "email": "northbrookhaven@dogtopia.com",
        "url": "https://www.dogtopia.com/atlanta-northbrookhaven",
        "geolocation": [
            33.8924951,
            -84.3252175
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.9,
                    "duration": "indefinitely",
                    "model_conf": 200.44
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 199,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 198.11,
                    "duration": "1 Months",
                    "model_conf": 142.85
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 37,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.58,
                    "duration": "indefinitely",
                    "model_conf": 288.13
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 64,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 64.59,
                    "duration": "indefinitely",
                    "model_conf": 131.79
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 89,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 88.52,
                    "duration": "2 Months",
                    "model_conf": 177.87
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 137,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 139.09,
                    "duration": "indefinitely",
                    "model_conf": 226.53
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.79,
                    "duration": "1 Months",
                    "model_conf": 451.47
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.54,
                    "duration": "indefinitely",
                    "model_conf": 265.65
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.84,
                    "duration": "4 Months",
                    "model_conf": 151.18
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.88,
                    "duration": "3 Months",
                    "model_conf": 92.39
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.78,
                    "duration": "indefinitely",
                    "model_conf": 112.25
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.825,
        "coming_soon": false
    },
    "North Palm Beach": {
        "name": "Dogtopia: North Palm Beach",
        "address": "524 Northlake Blvd Suite B, North Palm Beach, Florida 33408",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(561) 880-9446",
        "email": "northpalmbeach@dogtopia.com",
        "url": "https://www.dogtopia.com/north-palm-beach",
        "geolocation": [
            26.8080454,
            -80.0638305
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 35.98,
                    "duration": "indefinitely",
                    "model_conf": 203.36
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 175,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 174.05,
                    "duration": "2 Months",
                    "model_conf": 230.5
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 239,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 238.78,
                    "duration": "indefinitely",
                    "model_conf": 109.71
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 539,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 541.59,
                    "duration": "indefinitely",
                    "model_conf": 254.75
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 66,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.84,
                    "duration": "indefinitely",
                    "model_conf": 653.5
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.975,
        "coming_soon": false
    },
    "Houston W Alabama Street": {
        "name": "Dogtopia: Houston W Alabama Street",
        "address": "1839 W Alabama S, Houston, Texas 77098",
        "hours": {
            "Monday": "6:30 AM - 7:30 PM",
            "Tuesday": "6:30 AM - 7:30 PM",
            "Wednesday": "6:30 AM - 7:30 PM",
            "Thursday": "6:30 AM - 7:30 PM",
            "Friday": "6:30 AM - 7:30 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(281) 985-5158",
        "email": "walabamastreet@dogtopia.com",
        "url": "https://www.dogtopia.com/houston-west-alabama-street",
        "geolocation": [
            29.7383826,
            -95.40597729999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 39.43,
                    "duration": "5 Months",
                    "model_conf": 380.6
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 75,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 75.61,
                    "duration": "indefinitely",
                    "model_conf": 145.88
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 100,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 99.89,
                    "duration": "indefinitely",
                    "model_conf": 212.36
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 140.76,
                    "duration": "indefinitely",
                    "model_conf": 313.75
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 59.56,
                    "duration": "2 Months",
                    "model_conf": 201.92
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.7,
                    "duration": "2 Months",
                    "model_conf": 186.95
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.6,
        "coming_soon": false
    },
    "Homewood": {
        "name": "Dogtopia: Homewood",
        "address": "1722 27th Court South, Homewood, Alabama 35209",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(205) 440-3888",
        "email": "homewood@dogtopia.com",
        "url": "https://www.dogtopia.com/alabama-birmingham-homewood",
        "geolocation": [
            33.4815432,
            -86.79252380000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 39,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 39.32,
                    "duration": "indefinitely",
                    "model_conf": 420.47
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 67.05,
                    "duration": "5 Months",
                    "model_conf": 164.49
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 92.15,
                    "duration": "3 Months",
                    "model_conf": 244.1
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 129,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 129.39,
                    "duration": "indefinitely",
                    "model_conf": 385.86
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 61.35,
                    "duration": "indefinitely",
                    "model_conf": 265.32
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.77,
                    "duration": "indefinitely",
                    "model_conf": 208.72
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.34,
                    "duration": "indefinitely",
                    "model_conf": 99.79
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.35,
                    "duration": "indefinitely",
                    "model_conf": 169.05
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.2,
        "coming_soon": false
    },
    "Rock Hill": {
        "name": "Dogtopia: Rock Hill",
        "address": "9745 Manchester Rd, St. Louis, Missouri 63119",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(314) 230-8181",
        "email": "rockhill@dogtopia.com",
        "url": "https://www.dogtopia.com/rockhill-stlouis",
        "geolocation": [
            38.6080666,
            -90.37182030000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 37,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.57,
                    "duration": "indefinitely",
                    "model_conf": 371.65
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 67.51,
                    "duration": "2 Months",
                    "model_conf": 144.37
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 94.81,
                    "duration": "4 Months",
                    "model_conf": 199.53
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 145,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 143.85,
                    "duration": "3 Months",
                    "model_conf": 255.7
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 65.6,
                    "duration": "indefinitely",
                    "model_conf": 173.53
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.75,
                    "duration": "1 Months",
                    "model_conf": 186.12
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.09,
                    "duration": "indefinitely",
                    "model_conf": 161.57
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.15,
                    "duration": "indefinitely",
                    "model_conf": 96.46
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.16,
                    "duration": "indefinitely",
                    "model_conf": 133.56
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.9,
        "coming_soon": false
    },
    "Fairfax City": {
        "name": "Dogtopia: Fairfax City",
        "address": "11039 Lee Hwy, Fairfax, Virginia 22030",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(703) 349-0300",
        "email": "fairfaxcity@dogtopia.com",
        "url": "https://www.dogtopia.com/fairfax-city",
        "geolocation": [
            38.85197739999999,
            -77.32605
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 43.27,
                    "duration": "indefinitely",
                    "model_conf": 201.04
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 199,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 197.9,
                    "duration": "2 Months",
                    "model_conf": 192.34
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 379,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 378.5,
                    "duration": "indefinitely",
                    "model_conf": 190.37
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.51,
                    "duration": "5 Months",
                    "model_conf": 377.48
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 70.3,
                    "duration": "indefinitely",
                    "model_conf": 147.05
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 97.64,
                    "duration": "indefinitely",
                    "model_conf": 203.84
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 145,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 146.59,
                    "duration": "indefinitely",
                    "model_conf": 287.38
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 63.79,
                    "duration": "7 Months",
                    "model_conf": 217.17
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.64,
                    "duration": "indefinitely",
                    "model_conf": 174.54
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.44,
                    "duration": "indefinitely",
                    "model_conf": 97.15
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.49,
                    "duration": "indefinitely",
                    "model_conf": 142.25
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.3,
        "coming_soon": false
    },
    "East Pasadena": {
        "name": "Dogtopia: East Pasadena",
        "address": "3410 E Foothill Blvd, Pasadena, California 91107",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:30 AM - 5:30 PM"
        },
        "phone": "(626) 778-0008",
        "email": "eastpasadena@dogtopia.com",
        "url": "https://www.dogtopia.com/california-eastpasadena",
        "geolocation": [
            34.1498081,
            -118.0800364
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 45,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 44.22,
                    "duration": "6 Months",
                    "model_conf": 432.34
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 78,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 77.78,
                    "duration": "1 Months",
                    "model_conf": 148.72
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 105,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 105.39,
                    "duration": "indefinitely",
                    "model_conf": 242.31
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 165,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 165.7,
                    "duration": "indefinitely",
                    "model_conf": 384.53
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.88,
                    "duration": "4 Months",
                    "model_conf": 138.23
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.15,
                    "duration": "indefinitely",
                    "model_conf": 100.0
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 14,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.0,
                    "duration": "indefinitely",
                    "model_conf": 127.14
                }
            }
        },
        "brand": "Dogtopia",
        "index": 103.15,
        "coming_soon": false
    },
    "South Tulsa": {
        "name": "Dogtopia: South Tulsa",
        "address": "8170 S. Lewis Ave., Tulsa, Oklahoma 74137",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(918) 891-3110",
        "email": "southtulsa@dogtopia.com",
        "url": "https://www.dogtopia.com/tulsa-south",
        "geolocation": [
            36.043994,
            -95.958298
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 32,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 32.23,
                    "duration": "indefinitely",
                    "model_conf": 483.22
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 60,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 59.94,
                    "duration": "indefinitely",
                    "model_conf": 162.94
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 80,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 80.7,
                    "duration": "indefinitely",
                    "model_conf": 223.81
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 135,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 134.91,
                    "duration": "indefinitely",
                    "model_conf": 321.53
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 74.56,
                    "duration": "2 Months",
                    "model_conf": 264.98
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 60.07,
                    "duration": "indefinitely",
                    "model_conf": 191.88
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.07,
                    "duration": "indefinitely",
                    "model_conf": 197.85
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.52,
                    "duration": "indefinitely",
                    "model_conf": 99.89
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 14,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.21,
                    "duration": "indefinitely",
                    "model_conf": 149.83
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.075,
        "coming_soon": false
    },
    "Fairmont Parkway": {
        "name": "Dogtopia: Fairmont Parkway",
        "address": "6015 Fairmont Pkwy, Pasadena, Texas 77505",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(281) 417-4660",
        "email": "fairmontparkway@dogtopia.com",
        "url": "https://www.dogtopia.com/fairmontparkway-pasadena",
        "geolocation": [
            29.6506639,
            -95.1476254
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.64,
                    "duration": "indefinitely",
                    "model_conf": 201.21
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 33,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 21.8,
                    "duration": "indefinitely",
                    "model_conf": 446.42
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 60,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 63.89,
                    "duration": "indefinitely",
                    "model_conf": 159.31
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 88.51,
                    "duration": "indefinitely",
                    "model_conf": 233.56
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 125,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 134.55,
                    "duration": "indefinitely",
                    "model_conf": 360.74
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 68.72,
                    "duration": "indefinitely",
                    "model_conf": 239.43
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 60.11,
                    "duration": "indefinitely",
                    "model_conf": 190.57
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.4,
        "coming_soon": false
    },
    "Norwell 2": {
        "name": "Dogtopia: Norwell 2",
        "address": "10 Washington St. Space 3B, Norwell, Massachusetts 02061",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(781) 328-0031",
        "email": "norwell@dogtopia.com",
        "url": "https://www.dogtopia.com/massachusetts-norwell",
        "geolocation": [
            42.1735855,
            -70.884342
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 47.5,
                    "duration": "4 Months",
                    "model_conf": 202.17
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 45.16,
                    "duration": "indefinitely",
                    "model_conf": 440.44
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 83.31,
                    "duration": "3 Months",
                    "model_conf": 165.74
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 118.08,
                    "duration": "6 Months",
                    "model_conf": 235.58
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 198.15,
                    "duration": "1 Months",
                    "model_conf": 310.61
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 30.05,
                    "duration": "indefinitely",
                    "model_conf": 190.4
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.86,
                    "duration": "indefinitely",
                    "model_conf": 164.11
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.775,
        "coming_soon": false
    },
    "Upland": {
        "name": "Dogtopia: Upland",
        "address": "1071 E 16th Street, Suite C, Upland, California 91784",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:30 AM - 5:30 PM"
        },
        "phone": "(909) 310-8900",
        "email": "upland@dogtopia.com",
        "url": "https://www.dogtopia.com/california-upland",
        "geolocation": [
            34.1218219,
            -117.6367233
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 71,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 70.69,
                    "duration": "1 Months",
                    "model_conf": 168.44
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 94.73,
                    "duration": "5 Months",
                    "model_conf": 243.54
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 152,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 151.72,
                    "duration": "indefinitely",
                    "model_conf": 380.58
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.64,
                    "duration": "indefinitely",
                    "model_conf": 199.92
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.92,
                    "duration": "3 Months",
                    "model_conf": 99.57
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.91,
                    "duration": "2 Months",
                    "model_conf": 169.63
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.225,
        "coming_soon": false
    },
    "Atascocita": {
        "name": "Dogtopia: Atascocita",
        "address": "7140 FM 1960 Rd E, Humble, Texas 77346",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(281) 417-4622",
        "email": "atascocita@dogtopia.com",
        "url": "https://www.dogtopia.com/houston-atascocita",
        "geolocation": [
            29.9982047,
            -95.1689516
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 37,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.04,
                    "duration": "indefinitely",
                    "model_conf": 200.97
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 33,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.31,
                    "duration": "indefinitely",
                    "model_conf": 466.54
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 64,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 63.81,
                    "duration": "1 Months",
                    "model_conf": 167.23
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 88.84,
                    "duration": "5 Months",
                    "model_conf": 239.99
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 137.9,
                    "duration": "5 Months",
                    "model_conf": 379.83
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 66.32,
                    "duration": "indefinitely",
                    "model_conf": 230.53
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 61.43,
                    "duration": "indefinitely",
                    "model_conf": 191.86
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.625,
        "coming_soon": false
    },
    "Ahwatukee": {
        "name": "Dogtopia: Ahwatukee",
        "address": "4747 E Elliot Rd., Phoenix, Arizona 85044",
        "hours": {
            "Monday": "5:00 AM - 10:00 PM",
            "Tuesday": "5:00 AM - 10:00 PM",
            "Wednesday": "5:00 AM - 10:00 PM",
            "Thursday": "5:00 AM - 10:00 PM",
            "Friday": "5:00 AM - 10:00 PM",
            "Saturday": "5:00 AM - 10:00 PM"
        },
        "phone": "(480) 741-1532",
        "email": "ahwatukee@dogtopia.com",
        "url": "https://www.dogtopia.com/ahwatukee-elliotrd",
        "geolocation": [
            33.347971,
            -111.9821513
        ],
        "services": {},
        "memberships": {
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 109,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 108.22,
                    "duration": "2 Months",
                    "model_conf": 324.78
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 200,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 196.55,
                    "duration": "6 Months",
                    "model_conf": 231.99
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 32.23,
                    "duration": "indefinitely",
                    "model_conf": 74.87
                }
            }
        },
        "brand": "Dogtopia",
        "index": 90.325,
        "coming_soon": false
    },
    "Garden City Park": {
        "name": "Dogtopia: Garden City Park",
        "address": "2489 Jericho Turnpike, Garden City Park, New York 11040",
        "hours": {
            "Monday": "6:00 AM - 8:00 PM",
            "Tuesday": "6:00 AM - 8:00 PM",
            "Wednesday": "6:00 AM - 8:00 PM",
            "Thursday": "6:00 AM - 8:00 PM",
            "Friday": "6:00 AM - 8:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(516) 703-1200",
        "email": "gardencitypark@dogtopia.com",
        "url": "https://www.dogtopia.com/longisland-garden-city-park",
        "geolocation": [
            40.7444497,
            -73.65845279999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 47.19,
                    "duration": "6 Months",
                    "model_conf": 201.47
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 31.07,
                    "duration": "indefinitely",
                    "model_conf": 251.18
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 87.15,
                    "duration": "indefinitely",
                    "model_conf": 131.82
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 125.47,
                    "duration": "indefinitely",
                    "model_conf": 173.75
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 209.3,
                    "duration": "indefinitely",
                    "model_conf": 181.43
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.6,
                    "duration": "indefinitely",
                    "model_conf": 173.39
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 50,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 65.86,
                    "duration": "indefinitely",
                    "model_conf": 404.38
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.44,
                    "duration": "indefinitely",
                    "model_conf": 118.19
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.125,
        "coming_soon": false
    },
    "Tempe Mcclintock": {
        "name": "Dogtopia: Tempe Mcclintock",
        "address": "1721 E Broadway, Tempe, Arizona 85282",
        "hours": {
            "Monday": "5:00 AM - 10:00 PM",
            "Tuesday": "5:00 AM - 10:00 PM",
            "Wednesday": "5:00 AM - 10:00 PM",
            "Thursday": "5:00 AM - 10:00 PM",
            "Friday": "5:00 AM - 10:00 PM",
            "Saturday": "5:00 AM - 10:00 PM"
        },
        "phone": "(480) 485-4204",
        "email": "tempe@dogtopia.com",
        "url": "https://www.dogtopia.com/tempe-mcclintock",
        "geolocation": [
            33.4065978,
            -111.9112279
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 41.69,
                    "duration": "indefinitely",
                    "model_conf": 382.22
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 70.4,
                    "duration": "8 Months",
                    "model_conf": 132.35
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 96.33,
                    "duration": "10 Months",
                    "model_conf": 200.11
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 97.11,
                    "duration": "7 Months",
                    "model_conf": 239.57
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 200,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 197.43,
                    "duration": "5 Months",
                    "model_conf": 215.28
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.22,
                    "duration": "indefinitely",
                    "model_conf": 167.93
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.06,
                    "duration": "indefinitely",
                    "model_conf": 141.04
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.25,
                    "duration": "indefinitely",
                    "model_conf": 312.71
                }
            },
            "SPA & GROOMING (Enzymatic Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.66,
                    "duration": "11 Months",
                    "model_conf": 122.33
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.7,
        "coming_soon": false
    },
    "Shelton": {
        "name": "Dogtopia: Shelton",
        "address": "850 Bridgeport Avenue, Shelton, Connecticut 06484",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(203) 973-7233",
        "email": "shelton@dogtopia.com",
        "url": "https://www.dogtopia.com/connecticut-shelton",
        "geolocation": [
            41.31149990000001,
            -73.09745490000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 46,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.19,
                    "duration": "6 Months",
                    "model_conf": 203.06
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 42,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 39.85,
                    "duration": "indefinitely",
                    "model_conf": 428.52
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 80,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 80.82,
                    "duration": "indefinitely",
                    "model_conf": 160.15
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 114,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 115.14,
                    "duration": "indefinitely",
                    "model_conf": 241.91
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 185.54,
                    "duration": "indefinitely",
                    "model_conf": 369.18
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 31.32,
                    "duration": "indefinitely",
                    "model_conf": 188.95
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.72,
                    "duration": "7 Months",
                    "model_conf": 172.03
                }
            }
        },
        "brand": "Dogtopia",
        "index": 103.375,
        "coming_soon": false
    },
    "Palm Harbor": {
        "name": "Dogtopia: Palm Harbor",
        "address": "30860 US Hwy 19 N, Palm Harbor, Florida 34684",
        "hours": {
            "Monday": "7:00 AM - 6:30 PM",
            "Tuesday": "7:00 AM - 6:30 PM",
            "Wednesday": "7:00 AM - 6:30 PM",
            "Thursday": "7:00 AM - 6:30 PM",
            "Friday": "7:00 AM - 6:30 PM",
            "Saturday": "8:00 AM - 6:00 PM"
        },
        "phone": "((72) ) 2-6-62",
        "email": "palmharbor@dogtopia.com",
        "url": "https://www.dogtopia.com/palm-harbor",
        "geolocation": [
            28.0528093,
            -82.73955289999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.67,
                    "duration": "indefinitely",
                    "model_conf": 203.47
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 175,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 175.8,
                    "duration": "indefinitely",
                    "model_conf": 231.48
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 239,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 238.68,
                    "duration": "indefinitely",
                    "model_conf": 109.89
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 539,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 538.44,
                    "duration": "indefinitely",
                    "model_conf": 255.18
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.825,
        "coming_soon": false
    },
    "Golden Valley": {
        "name": "Dogtopia: Golden Valley",
        "address": "9010 Olson Memorial Highway, Golden Valley, Minnesota 55427",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(952) 800-1300",
        "email": "goldenvalley@dogtopia.com",
        "url": "https://www.dogtopia.com/minneapolis-goldenvalley",
        "geolocation": [
            44.9844002,
            -93.39524469999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.47,
                    "duration": "5 Months",
                    "model_conf": 200.7
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 272,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 271.11,
                    "duration": "1 Months",
                    "model_conf": 106.38
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 500,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 499.28,
                    "duration": "indefinitely",
                    "model_conf": 226.04
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 5.17,
                    "duration": "indefinitely",
                    "model_conf": 198.69
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.39,
                    "duration": "indefinitely",
                    "model_conf": 99.49
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.85,
                    "duration": "2 Months",
                    "model_conf": 173.0
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.3,
        "coming_soon": false
    },
    "Dogtopia Myrtle Ave Brooklyn": {
        "name": "Dogtopia: Dogtopia Myrtle Ave Brooklyn",
        "address": "504 Myrtle Ave, Brooklyn, New York 11205",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 6:00 PM"
        },
        "phone": "(347) 343-5330",
        "email": "myrtleave@dogtopia.com",
        "url": "https://www.dogtopia.com/myrtleave-brooklyn",
        "geolocation": [
            40.6933403,
            -73.9644072
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 55,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 57.81,
                    "duration": "indefinitely",
                    "model_conf": 200.3
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 510,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 509.76,
                    "duration": "indefinitely",
                    "model_conf": 187.9
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 50,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 55.53,
                    "duration": "indefinitely",
                    "model_conf": 346.69
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 95,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 93.45,
                    "duration": "6 Months",
                    "model_conf": 138.86
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 135,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 134.41,
                    "duration": "1 Months",
                    "model_conf": 192.87
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 39,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.84,
                    "duration": "indefinitely",
                    "model_conf": 281.78
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 40,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 42.52,
                    "duration": "indefinitely",
                    "model_conf": 195.43
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.16,
                    "duration": "indefinitely",
                    "model_conf": 163.79
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 17.96,
                    "duration": "indefinitely",
                    "model_conf": 134.23
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.475,
        "coming_soon": false
    },
    "Montgomery": {
        "name": "Dogtopia: Montgomery",
        "address": "2311 US Hwy 206, Suite 111, Belle Mead, New Jersey 08502",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(908) 751-1007",
        "email": "montgomery@dogtopia.com",
        "url": "https://www.dogtopia.com/montgomery-newjersey",
        "geolocation": [
            40.4597187,
            -74.6582601
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 50,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 48.08,
                    "duration": "indefinitely",
                    "model_conf": 203.68
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 88,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 93.05,
                    "duration": "indefinitely",
                    "model_conf": 163.14
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 126,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 133.44,
                    "duration": "indefinitely",
                    "model_conf": 243.6
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 198,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 211.23,
                    "duration": "indefinitely",
                    "model_conf": 373.4
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 53,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 44.74,
                    "duration": "indefinitely",
                    "model_conf": 260.75
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 4.08,
                    "duration": "indefinitely",
                    "model_conf": 203.35
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.29,
                    "duration": "indefinitely",
                    "model_conf": 99.63
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.67,
                    "duration": "indefinitely",
                    "model_conf": 172.42
                }
            }
        },
        "brand": "Dogtopia",
        "index": 107.425,
        "coming_soon": false
    },
    "Park Lane": {
        "name": "Dogtopia: Park Lane",
        "address": "8060 Park Lane, Suite 126, Dallas, Texas 75231",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(214) 281-8282",
        "email": "parklane@dogtopia.com",
        "url": "https://www.dogtopia.com/dallas-park-lane",
        "geolocation": [
            32.8704909,
            -96.76903519999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 39,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.83,
                    "duration": "11 Months",
                    "model_conf": 201.98
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 35,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 30.66,
                    "duration": "indefinitely",
                    "model_conf": 405.36
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 62,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 63.77,
                    "duration": "indefinitely",
                    "model_conf": 138.1
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 81,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 83.47,
                    "duration": "indefinitely",
                    "model_conf": 219.73
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 163,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 166.59,
                    "duration": "indefinitely",
                    "model_conf": 323.63
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 59,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 56.99,
                    "duration": "indefinitely",
                    "model_conf": 153.73
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 35,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 35.34,
                    "duration": "indefinitely",
                    "model_conf": 182.12
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 69,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 74.14,
                    "duration": "indefinitely",
                    "model_conf": 469.43
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 44.77,
                    "duration": "1 Months",
                    "model_conf": 73.09
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.5,
                    "duration": "indefinitely",
                    "model_conf": 147.21
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.78,
                    "duration": "7 Months",
                    "model_conf": 97.12
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.96,
                    "duration": "indefinitely",
                    "model_conf": 157.34
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.55,
        "coming_soon": false
    },
    "Green Hills": {
        "name": "Dogtopia: Green Hills",
        "address": "4004 Hillsboro Pike, Nashville, Tennessee 37215",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(615) 393-6658",
        "email": "greenhills@dogtopia.com",
        "url": "https://www.dogtopia.com/green-hills",
        "geolocation": [
            36.1039806,
            -86.81491439999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 41.49,
                    "duration": "4 Months",
                    "model_conf": 202.66
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 199,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 197.49,
                    "duration": "2 Months",
                    "model_conf": 158.02
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 259,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 259.77,
                    "duration": "indefinitely",
                    "model_conf": 104.46
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 549,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 546.46,
                    "duration": "1 Months",
                    "model_conf": 215.38
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.69,
                    "duration": "indefinitely",
                    "model_conf": 475.11
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.92,
                    "duration": "indefinitely",
                    "model_conf": 147.96
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.07,
                    "duration": "indefinitely",
                    "model_conf": 99.81
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.79,
                    "duration": "5 Months",
                    "model_conf": 142.38
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.2,
        "coming_soon": false
    },
    "The Shire At Cityline": {
        "name": "Dogtopia: The Shire At Cityline",
        "address": "2121 Infocom Dr, Richardson, Texas 75082",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(469) 202-0787",
        "email": "theshire@dogtopia.com",
        "url": "https://www.dogtopia.com/richardson-theshire-cityline",
        "geolocation": [
            33.0008958,
            -96.6826498
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 41,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 41.0,
                    "duration": "indefinitely",
                    "model_conf": 202.19
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 63,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 60.06,
                    "duration": "indefinitely",
                    "model_conf": 152.75
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 85.74,
                    "duration": "indefinitely",
                    "model_conf": 221.55
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 155,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 148.11,
                    "duration": "indefinitely",
                    "model_conf": 322.98
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 85,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.82,
                    "duration": "indefinitely",
                    "model_conf": 599.63
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.87,
                    "duration": "indefinitely",
                    "model_conf": 185.88
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.51,
                    "duration": "indefinitely",
                    "model_conf": 97.11
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.1,
                    "duration": "indefinitely",
                    "model_conf": 157.23
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.95,
        "coming_soon": false
    },
    "Valley Forge": {
        "name": "Dogtopia: Valley Forge",
        "address": "2550 Boulevard of the Generals, Suite 100, Eagleville, Pennsylvania 19403",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:00 AM - 4:00 PM"
        },
        "phone": "(610) 492-6328",
        "email": "valleyforge@dogtopia.com",
        "url": "https://www.dogtopia.com/valley-forge",
        "geolocation": [
            40.12513149999999,
            -75.4025139
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.28,
                    "duration": "7 Months",
                    "model_conf": 203.56
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 66,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 64.54,
                    "duration": "8 Months",
                    "model_conf": 165.6
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 89.82,
                    "duration": "indefinitely",
                    "model_conf": 225.04
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 137.57,
                    "duration": "6 Months",
                    "model_conf": 376.8
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 56.59,
                    "duration": "indefinitely",
                    "model_conf": 254.07
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 45.29,
                    "duration": "indefinitely",
                    "model_conf": 190.04
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.72,
                    "duration": "indefinitely",
                    "model_conf": 196.74
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.9,
                    "duration": "indefinitely",
                    "model_conf": 99.47
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.69,
                    "duration": "indefinitely",
                    "model_conf": 171.41
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.15,
        "coming_soon": false
    },
    "Boise Greenbelt": {
        "name": "Dogtopia: Boise Greenbelt",
        "address": "3203 W Chinden Blvd, Garden City, Idaho 83714",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(208) 563-5116",
        "email": "greenbelt@dogtopia.com",
        "url": "https://www.dogtopia.com/boise-greenbelt",
        "geolocation": [
            43.621232,
            -116.23792
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 41,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 42.35,
                    "duration": "indefinitely",
                    "model_conf": 203.67
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 34,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 32.86,
                    "duration": "indefinitely",
                    "model_conf": 489.17
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 59,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 58.64,
                    "duration": "2 Months",
                    "model_conf": 154.59
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 79,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.62,
                    "duration": "1 Months",
                    "model_conf": 244.39
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 138,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 139.37,
                    "duration": "indefinitely",
                    "model_conf": 298.77
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 80,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 80.58,
                    "duration": "indefinitely",
                    "model_conf": 259.2
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 56,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 53.38,
                    "duration": "indefinitely",
                    "model_conf": 191.95
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.53,
                    "duration": "indefinitely",
                    "model_conf": 199.9
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.6,
                    "duration": "indefinitely",
                    "model_conf": 97.98
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.52,
                    "duration": "indefinitely",
                    "model_conf": 155.85
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.075,
        "coming_soon": false
    },
    "Uptown Phoenix": {
        "name": "Dogtopia: Uptown Phoenix",
        "address": "6148 N 7th Street, Phoenix, Arizona 85014",
        "hours": {
            "Monday": "5:00 AM - 10:00 PM",
            "Tuesday": "5:00 AM - 10:00 PM",
            "Wednesday": "5:00 AM - 10:00 PM",
            "Thursday": "5:00 AM - 10:00 PM",
            "Friday": "5:00 AM - 10:00 PM",
            "Saturday": "5:00 AM - 10:00 PM"
        },
        "phone": "(602) 607-2777",
        "email": "uptownphoenix@dogtopia.com",
        "url": "https://www.dogtopia.com/uptown-phoenix",
        "geolocation": [
            33.5272682,
            -112.0654753
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 46,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 44.45,
                    "duration": "indefinitely",
                    "model_conf": 320.25
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 82,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 83.28,
                    "duration": "indefinitely",
                    "model_conf": 129.27
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 114,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 114.42,
                    "duration": "indefinitely",
                    "model_conf": 186.06
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 115,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 116.04,
                    "duration": "indefinitely",
                    "model_conf": 248.8
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 200,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 200.8,
                    "duration": "indefinitely",
                    "model_conf": 198.11
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.5,
                    "duration": "indefinitely",
                    "model_conf": 151.35
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.07,
                    "duration": "indefinitely",
                    "model_conf": 132.75
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.41,
                    "duration": "indefinitely",
                    "model_conf": 293.41
                }
            },
            "SPA & GROOMING (Enzymatic Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.35,
                    "duration": "indefinitely",
                    "model_conf": 122.27
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.925,
        "coming_soon": false
    },
    "Bearden Knoxville": {
        "name": "Dogtopia: Bearden Knoxville",
        "address": "5213 Homberg Dr, Knoxville, Tennessee 37919",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(865) 622-2599",
        "email": "bearden@dogtopia.com",
        "url": "https://www.dogtopia.com/bearden-knoxville",
        "geolocation": [
            35.9372907,
            -83.9891042
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 41.35,
                    "duration": "6 Months",
                    "model_conf": 204.08
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.5,
                    "duration": "5 Months",
                    "model_conf": 435.95
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.29,
                    "duration": "indefinitely",
                    "model_conf": 138.25
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 102,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 103.15,
                    "duration": "indefinitely",
                    "model_conf": 232.67
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 160,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 159.94,
                    "duration": "indefinitely",
                    "model_conf": 379.84
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 65.23,
                    "duration": "indefinitely",
                    "model_conf": 218.42
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 58,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 57.82,
                    "duration": "1 Months",
                    "model_conf": 191.41
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.25,
                    "duration": "indefinitely",
                    "model_conf": 201.75
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.6,
                    "duration": "indefinitely",
                    "model_conf": 99.3
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.15,
                    "duration": "indefinitely",
                    "model_conf": 166.48
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.4,
        "coming_soon": false
    },
    "Woodbury": {
        "name": "Dogtopia: Woodbury",
        "address": "783 Radio Dr, Suite 106, Woodbury, Minnesota 55125",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(952) 209-9600",
        "email": "woodbury@dogtopia.com",
        "url": "https://www.dogtopia.com/woodbury",
        "geolocation": [
            44.9369075,
            -92.93254809999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.81,
                    "duration": "indefinitely",
                    "model_conf": 201.82
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 70,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 69.9,
                    "duration": "indefinitely",
                    "model_conf": 156.41
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 95.5,
                    "duration": "2 Months",
                    "model_conf": 234.21
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 150,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 148.98,
                    "duration": "2 Months",
                    "model_conf": 339.86
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.35,
                    "duration": "3 Months",
                    "model_conf": 246.35
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 66.67,
                    "duration": "indefinitely",
                    "model_conf": 191.48
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.3,
                    "duration": "indefinitely",
                    "model_conf": 202.02
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.76,
                    "duration": "indefinitely",
                    "model_conf": 99.16
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 20.67,
                    "duration": "indefinitely",
                    "model_conf": 171.82
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.875,
        "coming_soon": false
    },
    "Long Island City": {
        "name": "Dogtopia: Long Island City",
        "address": "549 49th Ave, Long Island City, New York 11101",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 6:00 PM"
        },
        "phone": "(917) 277-4020",
        "email": "LIC@dogtopia.com",
        "url": "https://www.dogtopia.com/long-island-city",
        "geolocation": [
            40.7437283,
            -73.9543223
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 55,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 56.42,
                    "duration": "indefinitely",
                    "model_conf": 200.57
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 510,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 510.1,
                    "duration": "indefinitely",
                    "model_conf": 183.38
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 95,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 94.16,
                    "duration": "3 Months",
                    "model_conf": 127.83
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 135,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 134.5,
                    "duration": "1 Months",
                    "model_conf": 175.0
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 195,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 192.22,
                    "duration": "5 Months",
                    "model_conf": 207.67
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 40,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 41.59,
                    "duration": "indefinitely",
                    "model_conf": 165.62
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 30.59,
                    "duration": "indefinitely",
                    "model_conf": 176.62
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 50.82,
                    "duration": "indefinitely",
                    "model_conf": 413.43
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 45.1,
                    "duration": "indefinitely",
                    "model_conf": 72.97
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.04,
                    "duration": "indefinitely",
                    "model_conf": 144.6
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.95,
                    "duration": "indefinitely",
                    "model_conf": 123.76
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.8,
        "coming_soon": false
    },
    "San Antonio Northwest": {
        "name": "Dogtopia: San Antonio Northwest",
        "address": "5563 De Zavala Rd, Suite 100, San Antonio, Texas 78249",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 2:00 PM"
        },
        "phone": "(210) 964-0555",
        "email": "northwestsa@dogtopia.com",
        "url": "https://www.dogtopia.com/sanantonio-northwest",
        "geolocation": [
            29.564247594041223,
            -98.5978326862915
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 46.81,
                    "duration": "indefinitely",
                    "model_conf": 199.06
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 42.6,
                    "duration": "indefinitely",
                    "model_conf": 332.0
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 67.11,
                    "duration": "5 Months",
                    "model_conf": 139.71
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 92.23,
                    "duration": "3 Months",
                    "model_conf": 204.44
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 125,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 123.07,
                    "duration": "6 Months",
                    "model_conf": 286.25
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 79,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 80.66,
                    "duration": "indefinitely",
                    "model_conf": 203.31
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 64,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 63.33,
                    "duration": "4 Months",
                    "model_conf": 190.33
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.04,
                    "duration": "indefinitely",
                    "model_conf": 196.63
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.7,
                    "duration": "indefinitely",
                    "model_conf": 99.15
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 14,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.82,
                    "duration": "indefinitely",
                    "model_conf": 138.79
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.575,
        "coming_soon": false
    },
    "Easton": {
        "name": "Dogtopia: Easton",
        "address": "669 S 25th St, Easton, Pennsylvania 18045",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(610) 839-0550",
        "email": "easton@dogtopia.com",
        "url": "https://www.dogtopia.com/easton",
        "geolocation": [
            40.6806415,
            -75.247519
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.96,
                    "duration": "indefinitely",
                    "model_conf": 204.16
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 66.53,
                    "duration": "8 Months",
                    "model_conf": 160.37
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 93.55,
                    "duration": "10 Months",
                    "model_conf": 224.07
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 150,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 145.33,
                    "duration": "12 Months",
                    "model_conf": 381.62
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 80,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.6,
                    "duration": "indefinitely",
                    "model_conf": 643.35
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.74,
                    "duration": "indefinitely",
                    "model_conf": 206.43
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.0,
                    "duration": "indefinitely",
                    "model_conf": 95.95
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.37,
                    "duration": "indefinitely",
                    "model_conf": 174.06
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.675,
        "coming_soon": false
    },
    "Westminster Broomfield": {
        "name": "Dogtopia: Westminster Broomfield",
        "address": "7719 W 92nd Ave, Westminster, Colorado 80021",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(720) 706-1090",
        "email": "westminster@dogtopia.com",
        "url": "https://www.dogtopia.com/westminster-broomfield",
        "geolocation": [
            39.8639017,
            -105.0830133
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.54,
                    "duration": "3 Months",
                    "model_conf": 202.16
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 69,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 71.11,
                    "duration": "indefinitely",
                    "model_conf": 155.54
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 93.97,
                    "duration": "indefinitely",
                    "model_conf": 217.93
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 146.19,
                    "duration": "indefinitely",
                    "model_conf": 343.35
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 68,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 63.94,
                    "duration": "indefinitely",
                    "model_conf": 253.79
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 63,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 61.63,
                    "duration": "8 Months",
                    "model_conf": 191.94
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.39,
                    "duration": "indefinitely",
                    "model_conf": 194.41
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.25,
                    "duration": "indefinitely",
                    "model_conf": 99.22
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.62,
                    "duration": "indefinitely",
                    "model_conf": 155.77
                }
            }
        },
        "brand": "Dogtopia",
        "index": 103.525,
        "coming_soon": false
    },
    "Short Pump": {
        "name": "Dogtopia: Short Pump",
        "address": "3601 Cox Rd, Richmond, Virginia 23233",
        "hours": {
            "Monday": "6:30 AM - 7:30 PM",
            "Tuesday": "6:30 AM - 7:30 PM",
            "Wednesday": "6:30 AM - 7:30 PM",
            "Thursday": "6:30 AM - 7:30 PM",
            "Friday": "6:30 AM - 7:30 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(804) 625-4444",
        "email": "shortpump@dogtopia.com",
        "url": "https://www.dogtopia.com/richmond-shortpump",
        "geolocation": [
            37.6427132,
            -77.58385
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.68,
                    "duration": "3 Months",
                    "model_conf": 200.05
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.73,
                    "duration": "indefinitely",
                    "model_conf": 156.82
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 67.67,
                    "duration": "1 Months",
                    "model_conf": 118.32
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 98.39,
                    "duration": "2 Months",
                    "model_conf": 156.96
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 138.15,
                    "duration": "5 Months",
                    "model_conf": 127.39
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 76,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.73,
                    "duration": "indefinitely",
                    "model_conf": 290.02
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.59,
                    "duration": "indefinitely",
                    "model_conf": 127.9
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.28,
                    "duration": "indefinitely",
                    "model_conf": 88.71
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.68,
                    "duration": "indefinitely",
                    "model_conf": 107.77
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 60,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 57.72,
                    "duration": "indefinitely",
                    "model_conf": 120.84
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.425,
        "coming_soon": false
    },
    "Greensburg": {
        "name": "Dogtopia: Greensburg",
        "address": "131 Donohoe Rd, Greensburg, Pennsylvania 15601",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 3:00 PM"
        },
        "phone": "(724) 461-5839",
        "email": "greensburg@dogtopia.com",
        "url": "https://www.dogtopia.com/greensburg",
        "geolocation": [
            40.3051619,
            -79.5081955
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.7,
                    "duration": "indefinitely",
                    "model_conf": 204.15
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 32,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 36.14,
                    "duration": "indefinitely",
                    "model_conf": 480.52
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 60,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 58.75,
                    "duration": "8 Months",
                    "model_conf": 164.36
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 82.59,
                    "duration": "6 Months",
                    "model_conf": 223.85
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 125,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 121.6,
                    "duration": "10 Months",
                    "model_conf": 381.88
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 32.19,
                    "duration": "indefinitely",
                    "model_conf": 261.16
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 40,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 39.73,
                    "duration": "2 Months",
                    "model_conf": 75.19
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.72,
                    "duration": "indefinitely",
                    "model_conf": 205.28
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.22,
                    "duration": "indefinitely",
                    "model_conf": 99.92
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.17,
                    "duration": "indefinitely",
                    "model_conf": 168.23
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.33,
                    "duration": "indefinitely",
                    "model_conf": 64.74
                }
            }
        },
        "brand": "Dogtopia",
        "index": 92.725,
        "coming_soon": false
    },
    "Fairview": {
        "name": "Dogtopia: Fairview",
        "address": "1401 Kings Highway, Fairfield, Connecticut 06824",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(203) 993-6757",
        "email": "fairfield@dogtopia.com",
        "url": "https://www.dogtopia.com/connecticut-fairfield",
        "geolocation": [
            41.1614543,
            -73.2359132
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 46,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.83,
                    "duration": "1 Months",
                    "model_conf": 202.99
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 42,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.97,
                    "duration": "indefinitely",
                    "model_conf": 465.68
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 80,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 80.63,
                    "duration": "indefinitely",
                    "model_conf": 151.91
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 114,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 114.59,
                    "duration": "indefinitely",
                    "model_conf": 231.83
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 187.49,
                    "duration": "indefinitely",
                    "model_conf": 373.46
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 30.3,
                    "duration": "indefinitely",
                    "model_conf": 189.27
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.55,
                    "duration": "11 Months",
                    "model_conf": 165.03
                }
            }
        },
        "brand": "Dogtopia",
        "index": 103.375,
        "coming_soon": false
    },
    "Norwalk": {
        "name": "Dogtopia: Norwalk",
        "address": "30 Belden Ave #2, Norwalk, Connecticut 06850",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(203) 993-6822",
        "email": "norwalk@dogtopia.com",
        "url": "https://www.dogtopia.com/connecticut-norwalk",
        "geolocation": [
            41.1183821,
            -73.4179561
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 46,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.66,
                    "duration": "2 Months",
                    "model_conf": 201.64
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 42,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 27.76,
                    "duration": "indefinitely",
                    "model_conf": 358.94
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 80,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 83.12,
                    "duration": "indefinitely",
                    "model_conf": 135.32
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 114,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 119.1,
                    "duration": "indefinitely",
                    "model_conf": 191.93
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 195.87,
                    "duration": "indefinitely",
                    "model_conf": 240.72
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 27.95,
                    "duration": "indefinitely",
                    "model_conf": 173.24
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 50,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 71.94,
                    "duration": "indefinitely",
                    "model_conf": 427.77
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.84,
                    "duration": "indefinitely",
                    "model_conf": 131.42
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.025,
        "coming_soon": false
    },
    "Pearl District": {
        "name": "Dogtopia: Pearl District",
        "address": "925 NW Davis St, Portland, Oregon 97209",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(503) 334-3705",
        "email": "pearldistrict@dogtopia.com",
        "url": "https://www.dogtopia.com/portland-pearldistrict",
        "geolocation": [
            45.5245715,
            -122.6807099
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 46,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 43.8,
                    "duration": "indefinitely",
                    "model_conf": 201.51
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 299,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 299.93,
                    "duration": "indefinitely",
                    "model_conf": 103.93
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 73.93,
                    "duration": "10 Months",
                    "model_conf": 155.44
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 96.14,
                    "duration": "11 Months",
                    "model_conf": 241.02
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 569,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 566.89,
                    "duration": "1 Months",
                    "model_conf": 221.93
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 77,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 79.61,
                    "duration": "indefinitely",
                    "model_conf": 242.71
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 67,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 66.89,
                    "duration": "indefinitely",
                    "model_conf": 191.5
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.47,
                    "duration": "indefinitely",
                    "model_conf": 198.23
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.17,
                    "duration": "indefinitely",
                    "model_conf": 99.13
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 17.09,
                    "duration": "indefinitely",
                    "model_conf": 167.67
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.2,
        "coming_soon": false
    },
    "Cranberry": {
        "name": "Dogtopia: Cranberry",
        "address": "802 Thomson Park Dr, Cranberry Twp, Pennsylvania 16066",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(724) 461-5855",
        "email": "cranberry@dogtopia.com",
        "url": "https://www.dogtopia.com/cranberry",
        "geolocation": [
            40.6951633,
            -80.1116689
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.28,
                    "duration": "indefinitely",
                    "model_conf": 203.47
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 34,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.12,
                    "duration": "indefinitely",
                    "model_conf": 465.8
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 64,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 64.2,
                    "duration": "indefinitely",
                    "model_conf": 168.59
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 89.63,
                    "duration": "1 Months",
                    "model_conf": 179.38
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 139.92,
                    "duration": "indefinitely",
                    "model_conf": 345.46
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 70.97,
                    "duration": "indefinitely",
                    "model_conf": 195.38
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.57,
                    "duration": "2 Months",
                    "model_conf": 191.29
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.88,
                    "duration": "indefinitely",
                    "model_conf": 180.59
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.56,
                    "duration": "indefinitely",
                    "model_conf": 96.53
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.7,
                    "duration": "indefinitely",
                    "model_conf": 137.93
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.675,
        "coming_soon": false
    },
    "The Woodlands West": {
        "name": "Dogtopia: The Woodlands West",
        "address": "6622 Durango Creek Dr, Magnolia, Texas 77354",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:00 AM - 4:00 PM"
        },
        "phone": "(832) 862-6882",
        "email": "thewoodlandswest@dogtopia.com",
        "url": "https://www.dogtopia.com/thewoodlandswest",
        "geolocation": [
            30.2314564,
            -95.56815929999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.51,
                    "duration": "4 Months",
                    "model_conf": 203.8
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.59,
                    "duration": "3 Months",
                    "model_conf": 233.13
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 35,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 36.3,
                    "duration": "indefinitely",
                    "model_conf": 110.1
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 600,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 600.13,
                    "duration": "indefinitely",
                    "model_conf": 254.29
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.3,
                    "duration": "indefinitely",
                    "model_conf": 203.47
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.22,
                    "duration": "indefinitely",
                    "model_conf": 99.98
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.86,
                    "duration": "3 Months",
                    "model_conf": 172.46
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.325,
        "coming_soon": false
    },
    "Fishkill": {
        "name": "Dogtopia: Fishkill",
        "address": "18 Westage Drive, Suite 9a, Fishkill, New York 12524",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(845) 351-8191",
        "email": "fishkill@dogtopia.com",
        "url": "https://www.dogtopia.com/fishkill",
        "geolocation": [
            41.5281811,
            -73.8945313
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.56,
                    "duration": "3 Months",
                    "model_conf": 202.4
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 42,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 41.57,
                    "duration": "3 Months",
                    "model_conf": 449.3
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 75,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 74.8,
                    "duration": "1 Months",
                    "model_conf": 169.92
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 98,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 97.82,
                    "duration": "indefinitely",
                    "model_conf": 244.27
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 145,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 145.1,
                    "duration": "indefinitely",
                    "model_conf": 390.31
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 40,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 40.14,
                    "duration": "indefinitely",
                    "model_conf": 190.66
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.24,
                    "duration": "indefinitely",
                    "model_conf": 202.56
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.87,
                    "duration": "5 Months",
                    "model_conf": 98.89
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.47,
                    "duration": "indefinitely",
                    "model_conf": 170.72
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.96,
                    "duration": "1 Months",
                    "model_conf": 63.42
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 20.75,
                    "duration": "indefinitely",
                    "model_conf": 312.41
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.9,
        "coming_soon": false
    },
    "Roseville": {
        "name": "Dogtopia: Roseville",
        "address": "2216 County Rd D West Suite R, Roseville, Minnesota 55112",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((65) )-2-3-08",
        "email": "roseville@dogtopia.com",
        "url": "https://www.dogtopia.com/minneapolis-roseville",
        "geolocation": [
            45.03512509999999,
            -93.19344819999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 42.99,
                    "duration": "indefinitely",
                    "model_conf": 200.32
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 39,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.54,
                    "duration": "4 Months",
                    "model_conf": 431.09
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 74,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.9,
                    "duration": "5 Months",
                    "model_conf": 156.89
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 96.65,
                    "duration": "indefinitely",
                    "model_conf": 238.79
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.89,
                    "duration": "1 Months",
                    "model_conf": 228.42
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.75,
                    "duration": "3 Months",
                    "model_conf": 189.17
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 5.49,
                    "duration": "indefinitely",
                    "model_conf": 432.13
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.93,
                    "duration": "3 Months",
                    "model_conf": 197.83
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.27,
                    "duration": "indefinitely",
                    "model_conf": 99.26
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.28,
                    "duration": "indefinitely",
                    "model_conf": 172.02
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 20.19,
                    "duration": "indefinitely",
                    "model_conf": 63.69
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.225,
        "coming_soon": false
    },
    "Southlake": {
        "name": "Dogtopia: Southlake",
        "address": "2225 W. Southlake Blvd., Suite 459, Southlake, Texas 76092",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(817) 252-4086",
        "email": "southlake@dogtopia.com",
        "url": "https://www.dogtopia.com/southlake",
        "geolocation": [
            32.9412997,
            -97.1771016
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.48,
                    "duration": "indefinitely",
                    "model_conf": 203.57
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 67.87,
                    "duration": "indefinitely",
                    "model_conf": 169.27
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 97,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 97.52,
                    "duration": "indefinitely",
                    "model_conf": 242.04
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 139.62,
                    "duration": "1 Months",
                    "model_conf": 373.77
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 70.49,
                    "duration": "indefinitely",
                    "model_conf": 257.19
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.69,
                    "duration": "2 Months",
                    "model_conf": 191.76
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 4,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 4.6,
                    "duration": "indefinitely",
                    "model_conf": 200.86
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 4.6,
                    "duration": "indefinitely",
                    "model_conf": 99.65
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.2,
        "coming_soon": false
    },
    "Mechanicsburg": {
        "name": "Dogtopia: Mechanicsburg",
        "address": "4910 Carlisle Pike, Suite 102, Mechanicsburg, Pennsylvania 17050",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(717) 660-0560",
        "email": "mechanicsburg@dogtopia.com",
        "url": "https://www.dogtopia.com/mechanicsburg",
        "geolocation": [
            40.2363035,
            -76.9694368
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 42.15,
                    "duration": "indefinitely",
                    "model_conf": 203.67
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 25.81,
                    "duration": "indefinitely",
                    "model_conf": 493.72
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 74.86,
                    "duration": "indefinitely",
                    "model_conf": 145.83
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 102,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 106.19,
                    "duration": "indefinitely",
                    "model_conf": 195.73
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 160,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 169.31,
                    "duration": "indefinitely",
                    "model_conf": 363.36
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 40,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 34.52,
                    "duration": "indefinitely",
                    "model_conf": 177.05
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 26.51,
                    "duration": "indefinitely",
                    "model_conf": 177.32
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 50,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.96,
                    "duration": "indefinitely",
                    "model_conf": 561.02
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 40,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 41.32,
                    "duration": "indefinitely",
                    "model_conf": 73.05
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 2.57,
                    "duration": "indefinitely",
                    "model_conf": 306.15
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 4.22,
                    "duration": "indefinitely",
                    "model_conf": 188.15
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.36,
                    "duration": "indefinitely",
                    "model_conf": 90.42
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.66,
                    "duration": "indefinitely",
                    "model_conf": 114.83
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.6,
        "coming_soon": false
    },
    "Arlington Shirlington": {
        "name": "Dogtopia: Arlington Shirlington",
        "address": "2606 South Oxford Street, Arlington, Virginia 22206",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((70) ) 8-5-36",
        "email": "arlingtonshirlington@dogtopia.com",
        "url": "https://www.dogtopia.com/arlington-shirlington",
        "geolocation": [
            38.8450728,
            -77.092541
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.44,
                    "duration": "indefinitely",
                    "model_conf": 198.82
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 41,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 41.85,
                    "duration": "indefinitely",
                    "model_conf": 302.92
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 74,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 74.38,
                    "duration": "indefinitely",
                    "model_conf": 134.58
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 102,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 102.72,
                    "duration": "indefinitely",
                    "model_conf": 184.88
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 136,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 135.96,
                    "duration": "indefinitely",
                    "model_conf": 242.13
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 75.31,
                    "duration": "indefinitely",
                    "model_conf": 193.49
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 70.16,
                    "duration": "indefinitely",
                    "model_conf": 185.6
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.06,
                    "duration": "indefinitely",
                    "model_conf": 96.02
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.35,
                    "duration": "indefinitely",
                    "model_conf": 130.38
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.325,
        "coming_soon": false
    },
    "South Ann Arbor": {
        "name": "Dogtopia: South Ann Arbor",
        "address": "3825 Carpenter Rd, Suite 101, Ypsilanti, Michigan 48197",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(734) 477-6881",
        "email": "southannarbor@dogtopia.com",
        "url": "https://www.dogtopia.com/south-ann-arbor",
        "geolocation": [
            42.2335946,
            -83.6763958
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 34,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 32.93,
                    "duration": "12 Months",
                    "model_conf": 203.92
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 58,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 58.07,
                    "duration": "indefinitely",
                    "model_conf": 171.18
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 81,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 80.34,
                    "duration": "3 Months",
                    "model_conf": 243.95
                }
            },
            "DAYCARE (5 Days a Week)": {
                "cost": 130,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 129.51,
                    "duration": "1 Months",
                    "model_conf": 160.3
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.79,
                    "duration": "1 Months",
                    "model_conf": 653.42
                }
            }
        },
        "brand": "Dogtopia",
        "index": 92.725,
        "coming_soon": false
    },
    "Downtown Evanston": {
        "name": "Dogtopia: Downtown Evanston",
        "address": "900 Clark St, Suite 900, Evanston, Illinois 60201",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(847) 604-0064",
        "email": "downtownevanston@dogtopia.com",
        "url": "https://www.dogtopia.com/downtown-evanston",
        "geolocation": [
            42.0497191,
            -87.6840102
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.94,
                    "duration": "indefinitely",
                    "model_conf": 202.01
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 42.23,
                    "duration": "indefinitely",
                    "model_conf": 449.8
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 74,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 74.16,
                    "duration": "indefinitely",
                    "model_conf": 161.3
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 105.37,
                    "duration": "9 Months",
                    "model_conf": 228.54
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 176,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 173.93,
                    "duration": "4 Months",
                    "model_conf": 362.93
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 30.76,
                    "duration": "indefinitely",
                    "model_conf": 190.17
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.89,
                    "duration": "indefinitely",
                    "model_conf": 194.94
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.99,
                    "duration": "indefinitely",
                    "model_conf": 151.11
                }
            },
            "SPA & GROOMING (Enzymatic Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.09,
                    "duration": "indefinitely",
                    "model_conf": 122.18
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.975,
        "coming_soon": false
    },
    "Milwaukee East Side": {
        "name": "Dogtopia: Milwaukee East Side",
        "address": "2219 N Farwell Ave., Milwaukee, Wisconsin 53202",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(414) 485-0600",
        "email": "milwaukeeeastside@dogtopia.com",
        "url": "https://www.dogtopia.com/milwaukee-east-side",
        "geolocation": [
            43.05977559999999,
            -87.8860795
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 46,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.78,
                    "duration": "1 Months",
                    "model_conf": 202.88
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 43,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 39.51,
                    "duration": "indefinitely",
                    "model_conf": 309.93
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 85.04,
                    "duration": "indefinitely",
                    "model_conf": 165.47
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 114,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 115.59,
                    "duration": "indefinitely",
                    "model_conf": 242.36
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 144,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 146.33,
                    "duration": "indefinitely",
                    "model_conf": 270.85
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 60.01,
                    "duration": "indefinitely",
                    "model_conf": 177.62
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.87,
                    "duration": "indefinitely",
                    "model_conf": 583.25
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.35,
                    "duration": "indefinitely",
                    "model_conf": 149.12
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.67,
                    "duration": "8 Months",
                    "model_conf": 96.89
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 18.95,
                    "duration": "indefinitely",
                    "model_conf": 167.92
                }
            }
        },
        "brand": "Dogtopia",
        "index": 107.2,
        "coming_soon": false
    },
    "Dedham": {
        "name": "Dogtopia: Dedham",
        "address": "630 Washington Street, Space 33A, Dedham, Massachusetts 02026",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 PM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(781) 455-1001",
        "email": "dedham@dogtopia.com",
        "url": "https://www.dogtopia.com/massachusetts-dedham",
        "geolocation": [
            42.2403232,
            -71.1790065
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.96,
                    "duration": "indefinitely",
                    "model_conf": 202.41
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 44.07,
                    "duration": "indefinitely",
                    "model_conf": 452.32
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 83.35,
                    "duration": "3 Months",
                    "model_conf": 140.08
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 117.92,
                    "duration": "6 Months",
                    "model_conf": 216.17
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 196.89,
                    "duration": "4 Months",
                    "model_conf": 322.11
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 31.95,
                    "duration": "indefinitely",
                    "model_conf": 190.86
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.43,
                    "duration": "indefinitely",
                    "model_conf": 168.46
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.775,
        "coming_soon": false
    },
    "Alpharetta At Halcyon": {
        "name": "Dogtopia: Alpharetta At Halcyon",
        "address": "6275 Cortland Walk, Alpharetta, Georgia 30005",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(770) 676-2283",
        "email": "halcyon@dogtopia.com",
        "url": "https://www.dogtopia.com/alpharetta-halcyon",
        "geolocation": [
            34.10973971971185,
            -84.2223898586197
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 41.87,
                    "duration": "1 Months",
                    "model_conf": 203.5
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 199,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 197.97,
                    "duration": "2 Months",
                    "model_conf": 219.7
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 259,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 258.06,
                    "duration": "1 Months",
                    "model_conf": 108.94
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 549,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 549.91,
                    "duration": "indefinitely",
                    "model_conf": 250.12
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.55,
                    "duration": "indefinitely",
                    "model_conf": 99.02
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.33,
                    "duration": "indefinitely",
                    "model_conf": 153.5
                }
            },
            "SPA & GROOMING (Enzymatic Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.25,
                    "duration": "indefinitely",
                    "model_conf": 122.49
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.125,
        "coming_soon": false
    },
    "Margate": {
        "name": "Dogtopia: Margate",
        "address": "3197 N. State Rd 7., Margate, Florida 33063",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((95) ) 9-1-00",
        "email": "margate@dogtopia.com",
        "url": "https://www.dogtopia.com/margate",
        "geolocation": [
            26.2694052,
            -80.2018087
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 32.91,
                    "duration": "indefinitely",
                    "model_conf": 201.72
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 175,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 173.23,
                    "duration": "3 Months",
                    "model_conf": 219.17
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 239,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 238.71,
                    "duration": "indefinitely",
                    "model_conf": 108.58
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 539,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 542.31,
                    "duration": "indefinitely",
                    "model_conf": 253.29
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.825,
        "coming_soon": false
    },
    "Fort Lauderdale Downtown": {
        "name": "Dogtopia: Fort Lauderdale Downtown",
        "address": "808 W Broward Blvd., Fort Lauderdale, Florida 33312",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((95) ) 7-3-41",
        "email": "ftldowntown@dogtopia.com",
        "url": "https://www.dogtopia.com/fortlauderdale-downtown",
        "geolocation": [
            26.1218481,
            -80.1522536
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 33.73,
                    "duration": "indefinitely",
                    "model_conf": 202.93
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 175,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 174.4,
                    "duration": "1 Months",
                    "model_conf": 228.45
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 239,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 238.27,
                    "duration": "1 Months",
                    "model_conf": 109.34
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 539,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 539.56,
                    "duration": "indefinitely",
                    "model_conf": 252.27
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.825,
        "coming_soon": false
    },
    "Boca Raton": {
        "name": "Dogtopia: Boca Raton",
        "address": "201 SW 1st Ave, Boca Raton, Florida 33432",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "((56) ) 3-2-22",
        "email": "bocaraton@dogtopia.com",
        "url": "https://www.dogtopia.com/boca-raton",
        "geolocation": [
            26.3473916,
            -80.08897929999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 35.18,
                    "duration": "8 Months",
                    "model_conf": 202.25
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 175,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 175.55,
                    "duration": "indefinitely",
                    "model_conf": 215.92
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 239,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 239.76,
                    "duration": "indefinitely",
                    "model_conf": 108.84
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 539,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 539.05,
                    "duration": "indefinitely",
                    "model_conf": 251.74
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.825,
        "coming_soon": false
    },
    "Downtown Orlando": {
        "name": "Dogtopia: Downtown Orlando",
        "address": "1452 Alden Rd, Orlando, Florida 32803",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 6:00 PM"
        },
        "phone": "(407) 801-6008",
        "email": "downtownorlando@dogtopia.com",
        "url": "https://www.dogtopia.com/downtown-orlando",
        "geolocation": [
            28.56495859999999,
            -81.37164039999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 42.85,
                    "duration": "indefinitely",
                    "model_conf": 202.73
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.26,
                    "duration": "indefinitely",
                    "model_conf": 410.31
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 71,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 71.96,
                    "duration": "indefinitely",
                    "model_conf": 157.55
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 100,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 102.14,
                    "duration": "indefinitely",
                    "model_conf": 220.87
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 68.29,
                    "duration": "9 Months",
                    "model_conf": 229.64
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.62,
                    "duration": "2 Months",
                    "model_conf": 187.99
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.5,
        "coming_soon": false
    },
    "Thunderbolt": {
        "name": "Dogtopia: Thunderbolt",
        "address": "2391 Downing Ave, Savannah, Georgia 31404",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(912) 217-4229",
        "email": "thunderbolt@dogtopia.com",
        "url": "https://www.dogtopia.com/savannah-thunderbolt",
        "geolocation": [
            32.038519,
            -81.055607
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.06,
                    "duration": "indefinitely",
                    "model_conf": 203.59
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 36.53,
                    "duration": "indefinitely",
                    "model_conf": 490.83
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 67.71,
                    "duration": "1 Months",
                    "model_conf": 171.43
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 89.64,
                    "duration": "1 Months",
                    "model_conf": 244.83
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 85,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 85.64,
                    "duration": "indefinitely",
                    "model_conf": 265.05
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 65.22,
                    "duration": "indefinitely",
                    "model_conf": 192.59
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.09,
                    "duration": "indefinitely",
                    "model_conf": 208.66
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.04,
                    "duration": "indefinitely",
                    "model_conf": 100.19
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.97,
                    "duration": "indefinitely",
                    "model_conf": 166.31
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.475,
        "coming_soon": false
    },
    "Roanoke": {
        "name": "Dogtopia: Roanoke",
        "address": "216 East Highway 114, Roanoke, Texas 76262",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(817) 837-9915",
        "email": "roanoke@dogtopia.com",
        "url": "https://www.dogtopia.com/roanoke",
        "geolocation": [
            33.0083253,
            -97.2212155
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 34,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 34.14,
                    "duration": "indefinitely",
                    "model_conf": 203.65
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 56,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 55.97,
                    "duration": "indefinitely",
                    "model_conf": 170.15
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 81,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 80.58,
                    "duration": "2 Months",
                    "model_conf": 243.97
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 118.81,
                    "duration": "3 Months",
                    "model_conf": 384.51
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 69,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 68.84,
                    "duration": "indefinitely",
                    "model_conf": 261.19
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 55.79,
                    "duration": "indefinitely",
                    "model_conf": 191.8
                }
            }
        },
        "brand": "Dogtopia",
        "index": 94.15,
        "coming_soon": false
    },
    "Blackhawk": {
        "name": "Dogtopia: Blackhawk",
        "address": "3630 Blackhawk Plaza Circle, Danville, California 94506",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(925) 412-3200",
        "email": "blackhawk@dogtopia.com",
        "url": "https://www.dogtopia.com/danville-blackhawk",
        "geolocation": [
            37.80069644419438,
            -121.91896721880187
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 49,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 50.42,
                    "duration": "indefinitely",
                    "model_conf": 203.67
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 43.47,
                    "duration": "4 Months",
                    "model_conf": 494.45
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.21,
                    "duration": "indefinitely",
                    "model_conf": 165.77
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 120.62,
                    "duration": "indefinitely",
                    "model_conf": 232.06
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 184,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 183.9,
                    "duration": "indefinitely",
                    "model_conf": 382.18
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 78,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 77.48,
                    "duration": "2 Months",
                    "model_conf": 252.15
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 72,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 72.04,
                    "duration": "indefinitely",
                    "model_conf": 191.8
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 9,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.09,
                    "duration": "indefinitely",
                    "model_conf": 195.28
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 17,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 17.59,
                    "duration": "indefinitely",
                    "model_conf": 171.53
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 22,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 22.18,
                    "duration": "indefinitely",
                    "model_conf": 64.33
                }
            }
        },
        "brand": "Dogtopia",
        "index": 107.35,
        "coming_soon": false
    },
    "Memorial": {
        "name": "Dogtopia: Memorial",
        "address": "10405 Katy Freeway, Suite D, Houston, Texas 77024",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(713) 364-9900",
        "email": "memorial@dogtopia.com",
        "url": "https://www.dogtopia.com/houston-memorial",
        "geolocation": [
            29.783271648042227,
            -95.55474521080471
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.27,
                    "duration": "indefinitely",
                    "model_conf": 197.86
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.82,
                    "duration": "1 Months",
                    "model_conf": 344.15
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.19,
                    "duration": "indefinitely",
                    "model_conf": 137.74
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 100,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 98.74,
                    "duration": "4 Months",
                    "model_conf": 205.08
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 140.41,
                    "duration": "indefinitely",
                    "model_conf": 295.6
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 81,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 80.35,
                    "duration": "3 Months",
                    "model_conf": 191.53
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 71,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 72.24,
                    "duration": "indefinitely",
                    "model_conf": 186.25
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.99,
                    "duration": "indefinitely",
                    "model_conf": 156.51
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.43,
                    "duration": "indefinitely",
                    "model_conf": 96.04
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 17,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.32,
                    "duration": "indefinitely",
                    "model_conf": 144.02
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.1,
        "coming_soon": false
    },
    "Greenwood": {
        "name": "Dogtopia: Greenwood",
        "address": "1709 S State Road 135, Greenwood, Indiana 46143",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(317) 886-3100",
        "email": "greenwood@dogtopia.com",
        "url": "https://www.dogtopia.com/greenwood",
        "geolocation": [
            39.5922007,
            -86.15717629999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 35,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 35.73,
                    "duration": "indefinitely",
                    "model_conf": 203.98
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 30,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 29.59,
                    "duration": "5 Months",
                    "model_conf": 327.32
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 56,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 56.85,
                    "duration": "indefinitely",
                    "model_conf": 132.17
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 78,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 79.03,
                    "duration": "indefinitely",
                    "model_conf": 185.34
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 61.29,
                    "duration": "indefinitely",
                    "model_conf": 180.6
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.8,
                    "duration": "1 Months",
                    "model_conf": 377.83
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.83,
                    "duration": "6 Months",
                    "model_conf": 166.9
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.52,
                    "duration": "indefinitely",
                    "model_conf": 97.41
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.38,
                    "duration": "indefinitely",
                    "model_conf": 169.87
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.5,
        "coming_soon": false
    },
    "Town Country": {
        "name": "Dogtopia: Town Country",
        "address": "13933 Manchester Road, Ballwin, Missouri 63011",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(314) 230-0550",
        "email": "townandcountry@dogtopia.com",
        "url": "https://www.dogtopia.com/town-country-ballwin",
        "geolocation": [
            38.5965655,
            -90.4862615
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 185,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 183.95,
                    "duration": "2 Months",
                    "model_conf": 183.68
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 35,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.22,
                    "duration": "indefinitely",
                    "model_conf": 363.16
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 66,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 66.72,
                    "duration": "indefinitely",
                    "model_conf": 138.32
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 89.72,
                    "duration": "1 Months",
                    "model_conf": 196.76
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 141.2,
                    "duration": "indefinitely",
                    "model_conf": 277.97
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 4.44,
                    "duration": "indefinitely",
                    "model_conf": 160.16
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.78,
                    "duration": "8 Months",
                    "model_conf": 96.51
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.43,
                    "duration": "indefinitely",
                    "model_conf": 140.41
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.4,
        "coming_soon": false
    },
    "Sandy Springs": {
        "name": "Dogtopia: Sandy Springs",
        "address": "6010-A Sandy Springs Circle, Atlanta, Georgia 30328",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(678) 973-0521",
        "email": "sandysprings@dogtopia.com",
        "url": "https://www.dogtopia.com/sandy-springs",
        "geolocation": [
            33.920614,
            -84.3844684
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.94,
                    "duration": "9 Months",
                    "model_conf": 201.33
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 199,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 198.2,
                    "duration": "1 Months",
                    "model_conf": 193.63
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 259,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 258.84,
                    "duration": "indefinitely",
                    "model_conf": 104.73
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 549,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 548.5,
                    "duration": "indefinitely",
                    "model_conf": 226.2
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.125,
        "coming_soon": false
    },
    "Cedar Mill": {
        "name": "Dogtopia: Cedar Mill",
        "address": "11780 NW Cedar Falls Dr, Portland, Oregon 97229",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(503) 288-7185",
        "email": "cedarmill@dogtopia.com",
        "url": "https://www.dogtopia.com/cedar-mill",
        "geolocation": [
            45.5208774,
            -122.8000389
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 46,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.47,
                    "duration": "indefinitely",
                    "model_conf": 202.41
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 299,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 299.48,
                    "duration": "indefinitely",
                    "model_conf": 106.56
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.11,
                    "duration": "indefinitely",
                    "model_conf": 161.66
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 102.38,
                    "duration": "indefinitely",
                    "model_conf": 241.95
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 569,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 573.35,
                    "duration": "indefinitely",
                    "model_conf": 235.77
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 77,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 73.17,
                    "duration": "indefinitely",
                    "model_conf": 250.02
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 67,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 66.92,
                    "duration": "indefinitely",
                    "model_conf": 191.97
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.47,
                    "duration": "indefinitely",
                    "model_conf": 196.39
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.7,
                    "duration": "11 Months",
                    "model_conf": 99.64
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.53,
                    "duration": "indefinitely",
                    "model_conf": 170.57
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.2,
        "coming_soon": false
    },
    "Preston": {
        "name": "Dogtopia: Preston",
        "address": "7227 Preston Rd, Frisco, Texas 75034",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(469) 778-7300",
        "email": "friscopreston@dogtopia.com",
        "url": "https://www.dogtopia.com/frisco-preston",
        "geolocation": [
            33.1384702,
            -96.80575309999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 39,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.78,
                    "duration": "2 Months",
                    "model_conf": 200.03
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 34,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 31.88,
                    "duration": "indefinitely",
                    "model_conf": 449.59
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 58,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 58.37,
                    "duration": "indefinitely",
                    "model_conf": 150.98
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 78,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.29,
                    "duration": "indefinitely",
                    "model_conf": 230.56
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 121.28,
                    "duration": "indefinitely",
                    "model_conf": 346.94
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 73.75,
                    "duration": "6 Months",
                    "model_conf": 221.44
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 59.88,
                    "duration": "indefinitely",
                    "model_conf": 188.66
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 3.69,
                    "duration": "indefinitely",
                    "model_conf": 180.72
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.33,
                    "duration": "indefinitely",
                    "model_conf": 98.97
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.67,
                    "duration": "8 Months",
                    "model_conf": 163.02
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.025,
        "coming_soon": false
    },
    "Katy": {
        "name": "Dogtopia: Katy",
        "address": "1817 South Mason Rd, Katy, Texas 77450",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(281) 417-6010",
        "email": "katy@dogtopia.com",
        "url": "https://www.dogtopia.com/katy",
        "geolocation": [
            29.7551324,
            -95.75090399999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.73,
                    "duration": "indefinitely",
                    "model_conf": 198.21
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 35,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.0,
                    "duration": "indefinitely",
                    "model_conf": 460.45
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 65,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 64.55,
                    "duration": "2 Months",
                    "model_conf": 162.41
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 87.06,
                    "duration": "12 Months",
                    "model_conf": 218.3
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 135,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 133.19,
                    "duration": "5 Months",
                    "model_conf": 296.35
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 85,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 86.73,
                    "duration": "indefinitely",
                    "model_conf": 191.94
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 68.75,
                    "duration": "6 Months",
                    "model_conf": 189.07
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.11,
                    "duration": "indefinitely",
                    "model_conf": 153.88
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.77,
                    "duration": "9 Months",
                    "model_conf": 97.34
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.59,
                    "duration": "indefinitely",
                    "model_conf": 170.69
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.175,
        "coming_soon": false
    },
    "Shrewsbury": {
        "name": "Dogtopia: Shrewsbury",
        "address": "980 Shrewsbury Ave, Tinton Falls, New Jersey 07724",
        "hours": {
            "Monday": "6:00 AM - 8:00 PM",
            "Tuesday": "6:00 AM - 8:00 PM",
            "Wednesday": "6:00 AM - 8:00 PM",
            "Thursday": "6:00 AM - 8:00 PM",
            "Friday": "6:00 AM - 8:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(732) 440-9080",
        "email": "shrewsbury@dogtopia.com",
        "url": "https://www.dogtopia.com/newjersey-shrewsbury",
        "geolocation": [
            40.3130198,
            -74.06654569999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.72,
                    "duration": "2 Months",
                    "model_conf": 199.16
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 53.26,
                    "duration": "indefinitely",
                    "model_conf": 163.31
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.9,
                    "duration": "indefinitely",
                    "model_conf": 121.33
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 103.13,
                    "duration": "indefinitely",
                    "model_conf": 167.34
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 174.53,
                    "duration": "indefinitely",
                    "model_conf": 209.81
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 27.09,
                    "duration": "indefinitely",
                    "model_conf": 316.73
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.15,
                    "duration": "indefinitely",
                    "model_conf": 134.93
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.76,
                    "duration": "indefinitely",
                    "model_conf": 117.18
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.575,
        "coming_soon": false
    },
    "Bethpage": {
        "name": "Dogtopia: Bethpage",
        "address": "4076 Hempstead Turnpike, Bethpage, New York 11714",
        "hours": {
            "Monday": "6:00 AM - 8:00 PM",
            "Tuesday": "6:00 AM - 8:00 PM",
            "Wednesday": "6:00 AM - 8:00 PM",
            "Thursday": "6:00 AM - 8:00 PM",
            "Friday": "6:00 AM - 8:00 PM",
            "Saturday": "8:00 AM - 7:00 PM"
        },
        "phone": "(516) 862-4514",
        "email": "bethpage@dogtopia.com",
        "url": "https://www.dogtopia.com/newyork-bethpage",
        "geolocation": [
            40.7252017,
            -73.48560859999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 48.05,
                    "duration": "indefinitely",
                    "model_conf": 201.67
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 36.38,
                    "duration": "indefinitely",
                    "model_conf": 382.34
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 85.91,
                    "duration": "indefinitely",
                    "model_conf": 156.54
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 123.33,
                    "duration": "indefinitely",
                    "model_conf": 208.05
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 205.41,
                    "duration": "indefinitely",
                    "model_conf": 281.32
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.86,
                    "duration": "indefinitely",
                    "model_conf": 185.88
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.88,
                    "duration": "indefinitely",
                    "model_conf": 142.31
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.775,
        "coming_soon": false
    },
    "Decatur": {
        "name": "Dogtopia: Decatur",
        "address": "2098 North Decatur Road, Decatur, Georgia 30033",
        "hours": {
            "Monday": "5:30 AM - 8:00 PM",
            "Tuesday": "5:30 AM - 8:00 PM",
            "Wednesday": "5:30 AM - 8:00 PM",
            "Thursday": "5:30 AM - 8:00 PM",
            "Friday": "5:30 AM - 8:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(470) 487-8005",
        "email": "decatur@dogtopia.com",
        "url": "https://www.dogtopia.com/decatur",
        "geolocation": [
            33.7912236,
            -84.3055377
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.89,
                    "duration": "indefinitely",
                    "model_conf": 201.73
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 199,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 196.47,
                    "duration": "4 Months",
                    "model_conf": 199.56
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 259,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 257.79,
                    "duration": "1 Months",
                    "model_conf": 106.29
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 549,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 551.23,
                    "duration": "indefinitely",
                    "model_conf": 231.89
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.12,
                    "duration": "indefinitely",
                    "model_conf": 186.98
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.53,
                    "duration": "indefinitely",
                    "model_conf": 97.58
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.8,
                    "duration": "indefinitely",
                    "model_conf": 151.3
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.025,
        "coming_soon": false
    },
    "Maplewood": {
        "name": "Dogtopia: Maplewood",
        "address": "2017 Woodlynn Ave, Maplewood, Minnesota 55109",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(651) 505-1848",
        "email": "maplewood@dogtopia.com",
        "url": "https://www.dogtopia.com/maplewood",
        "geolocation": [
            45.0336859,
            -93.0156291
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 42.03,
                    "duration": "indefinitely",
                    "model_conf": 202.58
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 39,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.86,
                    "duration": "1 Months",
                    "model_conf": 440.15
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 71.63,
                    "duration": "1 Months",
                    "model_conf": 158.46
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 96.77,
                    "duration": "indefinitely",
                    "model_conf": 234.17
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 20,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 21.09,
                    "duration": "indefinitely",
                    "model_conf": 362.22
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.3,
                    "duration": "indefinitely",
                    "model_conf": 201.14
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.18,
                    "duration": "indefinitely",
                    "model_conf": 99.29
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.6,
                    "duration": "10 Months",
                    "model_conf": 171.21
                }
            }
        },
        "brand": "Dogtopia",
        "index": 94.0,
        "coming_soon": false
    },
    "Belden Canton": {
        "name": "Dogtopia: Belden Canton",
        "address": "4413 Whipple Ave N.W., Canton, Ohio 44718",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(330) 970-2200",
        "email": "beldencanton@dogtopia.com",
        "url": "https://www.dogtopia.com/belden-canton",
        "geolocation": [
            40.8485495,
            -81.42334819999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 35,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 35.73,
                    "duration": "indefinitely",
                    "model_conf": 201.58
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 32,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 29.1,
                    "duration": "indefinitely",
                    "model_conf": 499.0
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 60,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 59.3,
                    "duration": "4 Months",
                    "model_conf": 171.23
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.96,
                    "duration": "indefinitely",
                    "model_conf": 216.79
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 122.2,
                    "duration": "indefinitely",
                    "model_conf": 330.24
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 50,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 47.92,
                    "duration": "indefinitely",
                    "model_conf": 260.75
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.06,
                    "duration": "indefinitely",
                    "model_conf": 205.38
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.69,
                    "duration": "12 Months",
                    "model_conf": 99.58
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.85,
                    "duration": "indefinitely",
                    "model_conf": 158.28
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.4,
        "coming_soon": false
    },
    "Plymouth": {
        "name": "Dogtopia: Plymouth",
        "address": "246 N Main St, Plymouth, Michigan 48170",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(734) 887-9090",
        "email": "plymouth@dogtopia.com",
        "url": "https://www.dogtopia.com/plymouth",
        "geolocation": [
            42.3741458,
            -83.4645103
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.68,
                    "duration": "indefinitely",
                    "model_conf": 203.83
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 170,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 168.32,
                    "duration": "3 Months",
                    "model_conf": 226.97
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 240,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 239.31,
                    "duration": "1 Months",
                    "model_conf": 109.61
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 520,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 521.78,
                    "duration": "indefinitely",
                    "model_conf": 251.86
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.39,
                    "duration": "indefinitely",
                    "model_conf": 204.79
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.97,
                    "duration": "1 Months",
                    "model_conf": 99.33
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.76,
                    "duration": "6 Months",
                    "model_conf": 170.0
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.075,
        "coming_soon": false
    },
    "Union": {
        "name": "Dogtopia: Union",
        "address": "1235 W Chestnut Street, Union, New Jersey 07083",
        "hours": {
            "Monday": "6:00 AM - 8:00 PM",
            "Tuesday": "6:00 AM - 8:00 PM",
            "Wednesday": "6:00 AM - 8:00 PM",
            "Thursday": "6:00 AM - 8:00 PM",
            "Friday": "6:00 AM - 8:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(908) 774-1400",
        "email": "union@dogtopia.com",
        "url": "https://www.dogtopia.com/union-newjersey",
        "geolocation": [
            40.6922763,
            -74.2892703
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.63,
                    "duration": "3 Months",
                    "model_conf": 201.59
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 32.98,
                    "duration": "indefinitely",
                    "model_conf": 342.95
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.32,
                    "duration": "indefinitely",
                    "model_conf": 141.1
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 110.86,
                    "duration": "indefinitely",
                    "model_conf": 204.87
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 190.82,
                    "duration": "indefinitely",
                    "model_conf": 244.03
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 28.51,
                    "duration": "indefinitely",
                    "model_conf": 181.12
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 56.72,
                    "duration": "indefinitely",
                    "model_conf": 531.74
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 2.49,
                    "duration": "indefinitely",
                    "model_conf": 168.45
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.07,
                    "duration": "indefinitely",
                    "model_conf": 139.9
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.75,
        "coming_soon": false
    },
    "Milford": {
        "name": "Dogtopia: Milford",
        "address": "40 Quirk Road, Milford, Connecticut 06460",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(203) 951-9970",
        "email": "milford@dogtopia.com",
        "url": "https://www.dogtopia.com/milford",
        "geolocation": [
            41.2379397,
            -73.0097605
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 46.22,
                    "duration": "indefinitely",
                    "model_conf": 203.42
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 225,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 222.52,
                    "duration": "4 Months",
                    "model_conf": 226.99
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 300,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 297.73,
                    "duration": "2 Months",
                    "model_conf": 108.77
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 680,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 681.73,
                    "duration": "indefinitely",
                    "model_conf": 235.93
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 80,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 85.98,
                    "duration": "indefinitely",
                    "model_conf": 646.1
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.72,
                    "duration": "indefinitely",
                    "model_conf": 200.23
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.47,
                    "duration": "indefinitely",
                    "model_conf": 97.26
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.94,
                    "duration": "indefinitely",
                    "model_conf": 169.77
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.4,
        "coming_soon": false
    },
    "Navy Yard": {
        "name": "Dogtopia: Navy Yard",
        "address": "900 M Street SE, Washington, D.C. 20003",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "((20) ) 6-9-27",
        "email": "navyyard@dogtopia.com",
        "url": "https://www.dogtopia.com/washington-navy-yard",
        "geolocation": [
            38.87662939999999,
            -76.9933835
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 55,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 56.86,
                    "duration": "indefinitely",
                    "model_conf": 198.81
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 48,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 50.05,
                    "duration": "indefinitely",
                    "model_conf": 373.82
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 89.97,
                    "duration": "indefinitely",
                    "model_conf": 141.13
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 126,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 126.31,
                    "duration": "indefinitely",
                    "model_conf": 195.01
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 225,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 222.97,
                    "duration": "3 Months",
                    "model_conf": 288.67
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 35,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 36.59,
                    "duration": "indefinitely",
                    "model_conf": 200.15
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.88,
                    "duration": "indefinitely",
                    "model_conf": 136.58
                }
            }
        },
        "brand": "Dogtopia",
        "index": 109.675,
        "coming_soon": false
    },
    "Downtown Dc": {
        "name": "Dogtopia: Downtown Dc",
        "address": "1232 9th St. NW, Washington, D.C. 20001",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "((20) ) 7-9-08",
        "email": "downtowndc@dogtopia.com",
        "url": "https://www.dogtopia.com/downtown-washington-dc",
        "geolocation": [
            38.9065614,
            -77.02440299999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 55,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 55.69,
                    "duration": "indefinitely",
                    "model_conf": 198.24
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 48,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 47.34,
                    "duration": "5 Months",
                    "model_conf": 334.08
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 90.16,
                    "duration": "indefinitely",
                    "model_conf": 135.02
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 126,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 125.67,
                    "duration": "1 Months",
                    "model_conf": 184.32
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 225,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 224.56,
                    "duration": "indefinitely",
                    "model_conf": 265.86
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 35,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 35.88,
                    "duration": "indefinitely",
                    "model_conf": 187.25
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.05,
                    "duration": "indefinitely",
                    "model_conf": 128.69
                }
            }
        },
        "brand": "Dogtopia",
        "index": 109.675,
        "coming_soon": false
    },
    "West Midtown Atlanta": {
        "name": "Dogtopia: West Midtown Atlanta",
        "address": "1201 West Lane NW, Atlanta, Georgia 30318",
        "hours": {
            "Monday": "6:30 AM - 7:30 PM",
            "Tuesday": "6:30 AM - 7:30 PM",
            "Wednesday": "6:30 AM - 7:30 PM",
            "Thursday": "6:30 AM - 7:30 PM",
            "Friday": "6:30 AM - 7:30 PM",
            "Saturday": "7:30 AM - 7:00 PM"
        },
        "phone": "(404) 267-4589",
        "email": "westmidtownatl@dogtopia.com",
        "url": "https://www.dogtopia.com/west-midtown-atlanta",
        "geolocation": [
            33.7873879,
            -84.4144352
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 43.76,
                    "duration": "indefinitely",
                    "model_conf": 201.71
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 199,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 199.82,
                    "duration": "indefinitely",
                    "model_conf": 186.32
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 259,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 257.75,
                    "duration": "1 Months",
                    "model_conf": 105.81
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 549,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 548.25,
                    "duration": "indefinitely",
                    "model_conf": 228.27
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.29,
                    "duration": "indefinitely",
                    "model_conf": 418.6
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.42,
                    "duration": "indefinitely",
                    "model_conf": 187.34
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.5,
                    "duration": "indefinitely",
                    "model_conf": 97.28
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.19,
                    "duration": "indefinitely",
                    "model_conf": 139.43
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.4,
        "coming_soon": false
    },
    "Denver Tech Center": {
        "name": "Dogtopia: Denver Tech Center",
        "address": "8755 E Orchard Rd., Suite 601-602, Greenwood Village, Colorado 80111",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(303) 495-1161",
        "email": "dtc@dogtopia.com",
        "url": "https://www.dogtopia.com/denver-tech-center",
        "geolocation": [
            39.6105315,
            -104.8884262
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 46,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 43.93,
                    "duration": "indefinitely",
                    "model_conf": 330.29
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.53,
                    "duration": "indefinitely",
                    "model_conf": 132.94
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 117,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 116.99,
                    "duration": "indefinitely",
                    "model_conf": 186.76
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 170,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 171.91,
                    "duration": "indefinitely",
                    "model_conf": 257.42
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.03,
                    "duration": "indefinitely",
                    "model_conf": 150.47
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.67,
                    "duration": "12 Months",
                    "model_conf": 96.9
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.01,
                    "duration": "indefinitely",
                    "model_conf": 136.85
                }
            }
        },
        "brand": "Dogtopia",
        "index": 103.525,
        "coming_soon": false
    },
    "Viera": {
        "name": "Dogtopia: Viera",
        "address": "5410 Murrell Road, Suite 117, Rockledge, Florida 32955",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(321) 294-4102",
        "email": "viera@dogtopia.com",
        "url": "https://www.dogtopia.com/viera",
        "geolocation": [
            28.2675445,
            -80.7199691
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 70,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.33,
                    "duration": "indefinitely",
                    "model_conf": 161.13
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 96.42,
                    "duration": "indefinitely",
                    "model_conf": 239.74
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 130,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 135.06,
                    "duration": "indefinitely",
                    "model_conf": 379.09
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 61.28,
                    "duration": "indefinitely",
                    "model_conf": 248.04
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 20,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.65,
                    "duration": "6 Months",
                    "model_conf": 189.82
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 30.06,
                    "duration": "indefinitely",
                    "model_conf": 75.07
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.58,
                    "duration": "indefinitely",
                    "model_conf": 191.71
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.53,
                    "duration": "indefinitely",
                    "model_conf": 99.58
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.69,
                    "duration": "indefinitely",
                    "model_conf": 173.97
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.875,
        "coming_soon": false
    },
    "Bethesda": {
        "name": "Dogtopia: Bethesda",
        "address": "4936 Fairmont Avenue, Bethesda, Maryland 20814",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(240) 283-7131",
        "email": "bethesda@dogtopia.com",
        "url": "https://www.dogtopia.com/bethesda",
        "geolocation": [
            38.9875259,
            -77.0970504
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 54,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 55.15,
                    "duration": "indefinitely",
                    "model_conf": 201.58
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 92,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 91.79,
                    "duration": "indefinitely",
                    "model_conf": 153.84
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 129,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 129.78,
                    "duration": "indefinitely",
                    "model_conf": 198.7
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 203.01,
                    "duration": "indefinitely",
                    "model_conf": 220.49
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 35,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 34.05,
                    "duration": "10 Months",
                    "model_conf": 229.65
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 35,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 32.28,
                    "duration": "indefinitely",
                    "model_conf": 180.63
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 46,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 55.15,
                    "duration": "indefinitely",
                    "model_conf": 642.78
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 44.6,
                    "duration": "3 Months",
                    "model_conf": 74.24
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 18.07,
                    "duration": "indefinitely",
                    "model_conf": 165.19
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.65,
                    "duration": "indefinitely",
                    "model_conf": 91.74
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 35,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 34.29,
                    "duration": "7 Months",
                    "model_conf": 130.28
                }
            }
        },
        "brand": "Dogtopia",
        "index": 110.725,
        "coming_soon": false
    },
    "Falls Church": {
        "name": "Dogtopia: Falls Church",
        "address": "108 W. Jefferson St., Falls Church, Virginia 22046",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(571) 356-9223",
        "email": "fallschurch@dogtopia.com",
        "url": "https://www.dogtopia.com/falls-church",
        "geolocation": [
            38.8866372,
            -77.1652099
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 46.66,
                    "duration": "10 Months",
                    "model_conf": 199.81
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 215,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 213.0,
                    "duration": "3 Months",
                    "model_conf": 181.91
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.74,
                    "duration": "indefinitely",
                    "model_conf": 371.14
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 77.67,
                    "duration": "indefinitely",
                    "model_conf": 141.4
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 105,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 106.02,
                    "duration": "indefinitely",
                    "model_conf": 194.08
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 144,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 147.18,
                    "duration": "indefinitely",
                    "model_conf": 241.84
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 62.88,
                    "duration": "12 Months",
                    "model_conf": 189.64
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 25,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 25.11,
                    "duration": "indefinitely",
                    "model_conf": 185.94
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.39,
                    "duration": "indefinitely",
                    "model_conf": 149.41
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.28,
                    "duration": "indefinitely",
                    "model_conf": 96.19
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 18.49,
                    "duration": "indefinitely",
                    "model_conf": 137.88
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.725,
        "coming_soon": false
    },
    "Purcellville": {
        "name": "Dogtopia: Purcellville",
        "address": "201 N Maple Ave, Suite A, Purcellville, Virginia 20132",
        "hours": {
            "Monday": "6:00 AM - 7:00 PM",
            "Tuesday": "6:00 AM - 7:00 PM",
            "Wednesday": "6:00 AM - 7:00 PM",
            "Thursday": "6:00 AM - 7:00 PM",
            "Friday": "6:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(703) 936-4121",
        "email": "purcellville@dogtopia.com",
        "url": "https://www.dogtopia.com/purcellville",
        "geolocation": [
            39.1383761,
            -77.7059493
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.26,
                    "duration": "indefinitely",
                    "model_conf": 203.01
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.13,
                    "duration": "8 Months",
                    "model_conf": 223.47
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 37,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 35.47,
                    "duration": "indefinitely",
                    "model_conf": 108.6
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 20,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 20.99,
                    "duration": "indefinitely",
                    "model_conf": 250.43
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 68,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.81,
                    "duration": "indefinitely",
                    "model_conf": 669.05
                }
            }
        },
        "brand": "Dogtopia",
        "index": 94.075,
        "coming_soon": false
    },
    "Arlington Heights": {
        "name": "Dogtopia: Arlington Heights",
        "address": "676 East Rand Road, Arlington Heights, Illinois 60004",
        "hours": {
            "Monday": "",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(847) 350-1115",
        "email": "arlingtonheights@dogtopia.com",
        "url": "https://www.dogtopia.com/arlington-heights",
        "geolocation": [
            42.1087723,
            -87.9740125
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 42.7,
                    "duration": "indefinitely",
                    "model_conf": 200.52
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 33.9,
                    "duration": "indefinitely",
                    "model_conf": 229.45
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.82,
                    "duration": "indefinitely",
                    "model_conf": 124.07
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 102,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 101.92,
                    "duration": "indefinitely",
                    "model_conf": 167.03
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 155,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 159.22,
                    "duration": "indefinitely",
                    "model_conf": 181.7
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 81.87,
                    "duration": "indefinitely",
                    "model_conf": 387.13
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.05,
        "coming_soon": false
    },
    "Omaha Millard": {
        "name": "Dogtopia: Omaha Millard",
        "address": "18920 Edna Street, Omaha, Nebraska 68136",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(402) 205-0074",
        "email": "millard@dogtopia.com",
        "url": "https://www.dogtopia.com/omaha-millard",
        "geolocation": [
            41.1895363,
            -96.2116482
        ],
        "services": {},
        "memberships": {
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 28,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 28.74,
                    "duration": "indefinitely",
                    "model_conf": 107.58
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 16,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 15.49,
                    "duration": "12 Months",
                    "model_conf": 254.75
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.15,
                    "duration": "indefinitely",
                    "model_conf": 173.57
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 25,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 28.05,
                    "duration": "indefinitely",
                    "model_conf": 449.79
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.4,
        "coming_soon": false
    },
    "Stamford Downtown": {
        "name": "Dogtopia: Stamford Downtown",
        "address": "421 Atlantic Street, Suite 101, Stamford, Connecticut 06901",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(203) 716-6013",
        "email": "stamford@dogtopia.com",
        "url": "https://www.dogtopia.com/stamford-downtown",
        "geolocation": [
            41.049865,
            -73.54018959999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 46,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 46.05,
                    "duration": "indefinitely",
                    "model_conf": 202.23
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 42,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 33.81,
                    "duration": "indefinitely",
                    "model_conf": 317.38
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 80,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 83.05,
                    "duration": "indefinitely",
                    "model_conf": 141.73
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 114,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 117.52,
                    "duration": "indefinitely",
                    "model_conf": 199.71
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 191.31,
                    "duration": "indefinitely",
                    "model_conf": 254.3
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 28.35,
                    "duration": "indefinitely",
                    "model_conf": 172.47
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.47,
                    "duration": "indefinitely",
                    "model_conf": 458.18
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.2,
                    "duration": "indefinitely",
                    "model_conf": 166.59
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.5,
                    "duration": "indefinitely",
                    "model_conf": 89.96
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.31,
                    "duration": "indefinitely",
                    "model_conf": 135.93
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.125,
        "coming_soon": false
    },
    "Port Chester": {
        "name": "Dogtopia: Port Chester",
        "address": "142 Midland Ave, Port Chester, New York 10573",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(914) 908-4710",
        "email": "portchester@dogtopia.com",
        "url": "https://www.dogtopia.com/newyork-portchester",
        "geolocation": [
            40.9913633,
            -73.67063639999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 48.62,
                    "duration": "indefinitely",
                    "model_conf": 202.06
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 36.24,
                    "duration": "indefinitely",
                    "model_conf": 398.59
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 85.78,
                    "duration": "indefinitely",
                    "model_conf": 138.57
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 123.95,
                    "duration": "indefinitely",
                    "model_conf": 196.96
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 205.54,
                    "duration": "indefinitely",
                    "model_conf": 226.71
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 30.06,
                    "duration": "indefinitely",
                    "model_conf": 176.44
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 50,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 62.46,
                    "duration": "indefinitely",
                    "model_conf": 448.14
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.37,
                    "duration": "indefinitely",
                    "model_conf": 137.73
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.125,
        "coming_soon": false
    },
    "Lake Oswego": {
        "name": "Dogtopia: Lake Oswego",
        "address": "16130 Boones Ferry Rd., Suite C,, Lake Oswego, Oregon 97035",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(503) 342-2919",
        "email": "lakeoswego@dogtopia.com",
        "url": "https://www.dogtopia.com/lake-oswego",
        "geolocation": [
            45.4087774,
            -122.72221910000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 46,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.03,
                    "duration": "indefinitely",
                    "model_conf": 202.86
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 299,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 298.62,
                    "duration": "indefinitely",
                    "model_conf": 107.45
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 77.76,
                    "duration": "indefinitely",
                    "model_conf": 164.67
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 100.4,
                    "duration": "indefinitely",
                    "model_conf": 242.85
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 569,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 569.15,
                    "duration": "indefinitely",
                    "model_conf": 241.85
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 77,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 75.04,
                    "duration": "9 Months",
                    "model_conf": 255.52
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 67,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 66.94,
                    "duration": "indefinitely",
                    "model_conf": 191.78
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.8,
                    "duration": "indefinitely",
                    "model_conf": 201.46
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.66,
                    "duration": "indefinitely",
                    "model_conf": 99.73
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.95,
                    "duration": "indefinitely",
                    "model_conf": 171.04
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.2,
        "coming_soon": false
    },
    "South Waterfront": {
        "name": "Dogtopia: South Waterfront",
        "address": "3706 S River Parkway, Portland, Oregon 97239",
        "hours": {
            "Monday": "6:30 AM - 7:30 PM",
            "Tuesday": "6:30 AM - 7:30 PM",
            "Wednesday": "6:30 AM - 7:30 PM",
            "Thursday": "6:30 AM - 7:30 PM",
            "Friday": "6:30 AM - 7:30 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(503) 446-4388",
        "email": "southwaterfront@dogtopia.com",
        "url": "https://www.dogtopia.com/portland-southwaterfront",
        "geolocation": [
            45.495999,
            -122.66939000000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 46,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.43,
                    "duration": "indefinitely",
                    "model_conf": 201.31
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 299,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 300.33,
                    "duration": "indefinitely",
                    "model_conf": 103.7
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 74.68,
                    "duration": "6 Months",
                    "model_conf": 154.36
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 97.54,
                    "duration": "5 Months",
                    "model_conf": 240.82
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 569,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 568.19,
                    "duration": "indefinitely",
                    "model_conf": 220.13
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 77,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 80.58,
                    "duration": "indefinitely",
                    "model_conf": 242.06
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 67,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 67.31,
                    "duration": "indefinitely",
                    "model_conf": 191.48
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.96,
                    "duration": "indefinitely",
                    "model_conf": 198.29
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.57,
                    "duration": "indefinitely",
                    "model_conf": 99.17
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 17.67,
                    "duration": "7 Months",
                    "model_conf": 167.32
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.2,
        "coming_soon": false
    },
    "Paradise Valley Village": {
        "name": "Dogtopia: Paradise Valley Village",
        "address": "13001 N Tatum Blvd Suite C01, Phoenix, Arizona 85032",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(480) 605-3044",
        "email": "pvvillage@dogtopia.com",
        "url": "https://www.dogtopia.com/phoenix-paradisevalleyvillage",
        "geolocation": [
            33.60423984336829,
            -111.97679198941039
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 42.9,
                    "duration": "indefinitely",
                    "model_conf": 200.42
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 33.96,
                    "duration": "indefinitely",
                    "model_conf": 281.51
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.63,
                    "duration": "indefinitely",
                    "model_conf": 129.01
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 105,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 108.52,
                    "duration": "indefinitely",
                    "model_conf": 172.48
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 144.85,
                    "duration": "indefinitely",
                    "model_conf": 219.44
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 75.24,
                    "duration": "indefinitely",
                    "model_conf": 179.28
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 85,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 91.4,
                    "duration": "indefinitely",
                    "model_conf": 454.98
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.725,
        "coming_soon": false
    },
    "Harrison Ave": {
        "name": "Dogtopia: Harrison Ave",
        "address": "243 Grande Heights Dr., Cary, North Carolina 27513",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:30 AM - 5:00 PM"
        },
        "phone": "(919) 297-2342",
        "email": "harrisonave@dogtopia.com",
        "url": "https://www.dogtopia.com/cary-harrisonave",
        "geolocation": [
            35.8047108,
            -78.78090179999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.42,
                    "duration": "indefinitely",
                    "model_conf": 202.1
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 35,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 33.28,
                    "duration": "indefinitely",
                    "model_conf": 308.86
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 66,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 66.29,
                    "duration": "indefinitely",
                    "model_conf": 127.5
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 92.15,
                    "duration": "3 Months",
                    "model_conf": 207.54
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 137,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 137.5,
                    "duration": "indefinitely",
                    "model_conf": 293.92
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.34,
                    "duration": "3 Months",
                    "model_conf": 195.58
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.97,
                    "duration": "indefinitely",
                    "model_conf": 190.48
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.84,
                    "duration": "indefinitely",
                    "model_conf": 169.11
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.22,
                    "duration": "indefinitely",
                    "model_conf": 97.14
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 19,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 18.13,
                    "duration": "indefinitely",
                    "model_conf": 135.69
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.025,
        "coming_soon": false
    },
    "South Summerlin": {
        "name": "Dogtopia: South Summerlin",
        "address": "6415 S Fort Apache Rd. Suite 130, Las Vegas, Nevada 89148",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(702) 659-5169",
        "email": "southsummerlin@dogtopia.com",
        "url": "https://www.dogtopia.com/vegas-southsummerlin",
        "geolocation": [
            36.07181440000001,
            -115.2987139
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.66,
                    "duration": "2 Months",
                    "model_conf": 203.87
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 41,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 40.81,
                    "duration": "1 Months",
                    "model_conf": 367.33
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 75,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 75.24,
                    "duration": "indefinitely",
                    "model_conf": 150.64
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 105,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 105.88,
                    "duration": "indefinitely",
                    "model_conf": 229.78
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 155,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 154.32,
                    "duration": "1 Months",
                    "model_conf": 344.3
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.22,
                    "duration": "indefinitely",
                    "model_conf": 191.81
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.82,
                    "duration": "7 Months",
                    "model_conf": 98.79
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.17,
                    "duration": "indefinitely",
                    "model_conf": 170.79
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.425,
        "coming_soon": false
    },
    "Mount Kisco": {
        "name": "Dogtopia: Mount Kisco",
        "address": "333 North Bedford Road, Mount Kisco, NY 10549",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(914) 218-8258",
        "email": "mountkisco@dogtopia.com",
        "url": "https://www.dogtopia.com/newyork-mountkisco",
        "geolocation": [
            41.2204834,
            -73.71946729999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 48.58,
                    "duration": "indefinitely",
                    "model_conf": 203.85
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 43.39,
                    "duration": "5 Months",
                    "model_conf": 451.17
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 83.48,
                    "duration": "2 Months",
                    "model_conf": 163.02
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 120.11,
                    "duration": "indefinitely",
                    "model_conf": 232.61
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 197.71,
                    "duration": "2 Months",
                    "model_conf": 371.78
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 30.77,
                    "duration": "indefinitely",
                    "model_conf": 191.87
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.31,
                    "duration": "indefinitely",
                    "model_conf": 154.66
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.125,
        "coming_soon": false
    },
    "Warwick": {
        "name": "Dogtopia: Warwick",
        "address": "30 Jefferson Blvd, Warwick, Rhode Island 02888",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(401) 903-4900",
        "email": "warwick@dogtopia.com",
        "url": "https://www.dogtopia.com/warwick",
        "geolocation": [
            41.7535147,
            -71.43511560000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.68,
                    "duration": "2 Months",
                    "model_conf": 201.99
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.66,
                    "duration": "indefinitely",
                    "model_conf": 449.21
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 75,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 74.77,
                    "duration": "1 Months",
                    "model_conf": 165.45
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108.75,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 108.44,
                    "duration": "1 Months",
                    "model_conf": 232.83
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 173.75,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 175.76,
                    "duration": "indefinitely",
                    "model_conf": 346.44
                }
            }
        },
        "brand": "Dogtopia",
        "index": 103.6,
        "coming_soon": false
    },
    "Yorktown": {
        "name": "Dogtopia: Yorktown",
        "address": "3655 Crompond Rd, Cortlandt Manor, New York 10567",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(914) 930-8131",
        "email": "yorktown@dogtopia.com",
        "url": "https://www.dogtopia.com/newyork-yorktown",
        "geolocation": [
            41.28875550000001,
            -73.84213609999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 47.47,
                    "duration": "4 Months",
                    "model_conf": 203.98
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 43.52,
                    "duration": "4 Months",
                    "model_conf": 470.45
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 83.48,
                    "duration": "2 Months",
                    "model_conf": 165.58
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 120.05,
                    "duration": "indefinitely",
                    "model_conf": 240.06
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 199.52,
                    "duration": "indefinitely",
                    "model_conf": 342.76
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 31.92,
                    "duration": "indefinitely",
                    "model_conf": 192.32
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.22,
                    "duration": "indefinitely",
                    "model_conf": 163.65
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.775,
        "coming_soon": false
    },
    "Historic Phoenix": {
        "name": "Dogtopia: Historic Phoenix",
        "address": "520 W McDowell Rd, Phoenix, Arizona 85003",
        "hours": {
            "Monday": "5:00 AM - 10:00 PM",
            "Tuesday": "5:00 AM - 10:00 PM",
            "Wednesday": "5:00 AM - 10:00 PM",
            "Thursday": "5:00 AM - 10:00 PM",
            "Friday": "5:00 AM - 10:00 PM",
            "Saturday": "5:00 AM - 10:00 PM"
        },
        "phone": "(602) 607-1500",
        "email": "historicphoenix@dogtopia.com",
        "url": "https://www.dogtopia.com/historic-phoenix",
        "geolocation": [
            33.4660318,
            -112.0806354
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 56,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 49.63,
                    "duration": "indefinitely",
                    "model_conf": 353.24
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 97.48,
                    "duration": "indefinitely",
                    "model_conf": 135.95
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 126,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 128.37,
                    "duration": "indefinitely",
                    "model_conf": 190.09
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 165,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 170.59,
                    "duration": "indefinitely",
                    "model_conf": 278.3
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 285,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 288.36,
                    "duration": "indefinitely",
                    "model_conf": 207.58
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.37,
                    "duration": "indefinitely",
                    "model_conf": 159.76
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.46,
                    "duration": "indefinitely",
                    "model_conf": 134.13
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.03,
                    "duration": "indefinitely",
                    "model_conf": 305.89
                }
            },
            "SPA & GROOMING (Enzymatic Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.23,
                    "duration": "indefinitely",
                    "model_conf": 122.35
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.175,
        "coming_soon": false
    },
    "Town Center Virginia Beach": {
        "name": "Dogtopia: Town Center Virginia Beach",
        "address": "4546 Columbus Street, Virginia Beach, Virginia 23462",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(757) 517-8010",
        "email": "towncenter@dogtopia.com",
        "url": "https://www.dogtopia.com/towncenter-virginiabeach",
        "geolocation": [
            36.8409808,
            -76.13400380000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.34,
                    "duration": "indefinitely",
                    "model_conf": 199.89
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.71,
                    "duration": "2 Months",
                    "model_conf": 404.41
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 71,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 70.77,
                    "duration": "1 Months",
                    "model_conf": 149.58
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 98,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 97.64,
                    "duration": "1 Months",
                    "model_conf": 224.22
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 145,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 144.78,
                    "duration": "indefinitely",
                    "model_conf": 390.46
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.47,
                    "duration": "3 Months",
                    "model_conf": 265.41
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 50,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 50.51,
                    "duration": "indefinitely",
                    "model_conf": 192.29
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.46,
                    "duration": "3 Months",
                    "model_conf": 74.78
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.375,
        "coming_soon": false
    },
    "Wolverine Rockford": {
        "name": "Dogtopia: Wolverine Rockford",
        "address": "9343 Courtland Drive NE, Rockford, Michigan 49351",
        "hours": {
            "Monday": "",
            "Tuesday": "7:00 AM - 6:00 PM",
            "Wednesday": "7:00 AM - 6:00 PM",
            "Thursday": "7:00 AM - 6:00 PM",
            "Friday": "",
            "Saturday": ""
        },
        "phone": "(616) 226-3755",
        "email": "wolverine@dogtopia.com",
        "url": "https://www.dogtopia.com/wolverine-rockford",
        "geolocation": [
            43.1347426,
            -85.54220850000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 25,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 25.37,
                    "duration": "indefinitely",
                    "model_conf": 204.26
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 115,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 115.96,
                    "duration": "indefinitely",
                    "model_conf": 232.49
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.88,
                    "duration": "indefinitely",
                    "model_conf": 207.33
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 5.74,
                    "duration": "indefinitely",
                    "model_conf": 100.04
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.17,
                    "duration": "indefinitely",
                    "model_conf": 173.42
                }
            }
        },
        "brand": "Dogtopia",
        "index": 88.9,
        "coming_soon": false
    },
    "Roswell": {
        "name": "Dogtopia: Roswell",
        "address": "1570 Holcomb Bridge Road #325, Roswell, Georgia 30076",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(770) 679-8373",
        "email": "roswell@dogtopia.com",
        "url": "https://www.dogtopia.com/roswell",
        "geolocation": [
            34.0191806,
            -84.31250130000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.12,
                    "duration": "indefinitely",
                    "model_conf": 202.95
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 199,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 196.42,
                    "duration": "5 Months",
                    "model_conf": 203.47
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 259,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 258.49,
                    "duration": "indefinitely",
                    "model_conf": 108.25
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 549,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 549.93,
                    "duration": "indefinitely",
                    "model_conf": 242.35
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.82,
                    "duration": "indefinitely",
                    "model_conf": 459.23
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.9,
                    "duration": "indefinitely",
                    "model_conf": 199.19
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.35,
                    "duration": "indefinitely",
                    "model_conf": 98.19
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.32,
                    "duration": "indefinitely",
                    "model_conf": 151.31
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.2,
        "coming_soon": false
    },
    "Olathe": {
        "name": "Dogtopia: Olathe",
        "address": "15270 W. 119th Street, Olathe, Kansas 66062",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(913) 318-6458",
        "email": "olathe@dogtopia.com",
        "url": "https://www.dogtopia.com/olathe",
        "geolocation": [
            38.9152469,
            -94.7616084
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.81,
                    "duration": "indefinitely",
                    "model_conf": 199.81
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 34,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 33.29,
                    "duration": "8 Months",
                    "model_conf": 490.0
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 60,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 59.2,
                    "duration": "5 Months",
                    "model_conf": 151.04
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 81,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 80.77,
                    "duration": "1 Months",
                    "model_conf": 174.87
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 126,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 125.22,
                    "duration": "2 Months",
                    "model_conf": 385.16
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 58,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 57.75,
                    "duration": "1 Months",
                    "model_conf": 220.59
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 52,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 50.27,
                    "duration": "indefinitely",
                    "model_conf": 186.16
                }
            }
        },
        "brand": "Dogtopia",
        "index": 94.45,
        "coming_soon": false
    },
    "Cherry Hill": {
        "name": "Dogtopia: Cherry Hill",
        "address": "2095 Marlton Pike East, Cherry Hill, New Jersey 08003",
        "hours": {
            "Monday": "7:00 AM - 6:00 PM",
            "Tuesday": "7:00 AM - 6:00 PM",
            "Wednesday": "7:00 AM - 6:00 PM",
            "Thursday": "7:00 AM - 6:00 PM",
            "Friday": "7:00 AM - 6:00 PM",
            "Saturday": "10:00 AM - 4:00 PM"
        },
        "phone": "(856) 254-3555",
        "email": "cherryhill@dogtopia.com",
        "url": "https://www.dogtopia.com/cherry-hill",
        "geolocation": [
            39.896315,
            -74.94853899999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 34,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 31.91,
                    "duration": "indefinitely",
                    "model_conf": 211.88
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 32,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 30.26,
                    "duration": "indefinitely",
                    "model_conf": 194.81
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 30,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 29.62,
                    "duration": "4 Months",
                    "model_conf": 161.03
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 240,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 239.55,
                    "duration": "indefinitely",
                    "model_conf": 109.71
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 32.82,
                    "duration": "indefinitely",
                    "model_conf": 458.79
                }
            }
        },
        "brand": "Dogtopia",
        "index": 94.525,
        "coming_soon": false
    },
    "Houston Cypress": {
        "name": "Dogtopia: Houston Cypress",
        "address": "8931 Fry Rd Suite 800, Cypress, Texas 77433",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(281) 213-5780",
        "email": "cypress@dogtopia.com",
        "url": "https://www.dogtopia.com/houston-cypress",
        "geolocation": [
            29.91231400000001,
            -95.72747290000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.85,
                    "duration": "10 Months",
                    "model_conf": 203.8
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 190,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 191.71,
                    "duration": "indefinitely",
                    "model_conf": 230.3
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 279,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 278.93,
                    "duration": "indefinitely",
                    "model_conf": 109.71
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.51,
                    "duration": "indefinitely",
                    "model_conf": 205.75
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.39,
                    "duration": "indefinitely",
                    "model_conf": 99.86
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.62,
                    "duration": "indefinitely",
                    "model_conf": 173.02
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.55,
        "coming_soon": false
    },
    "Fort Lauderdale Northeast": {
        "name": "Dogtopia: Fort Lauderdale Northeast",
        "address": "5435 N Federal Hwy, Fort Lauderdale, Florida 33308",
        "hours": {
            "Monday": "7:00 AM - 6:00 PM",
            "Tuesday": "7:00 AM - 6:00 PM",
            "Wednesday": "7:00 AM - 6:00 PM",
            "Thursday": "7:00 AM - 6:00 PM",
            "Friday": "7:00 AM - 6:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(954) 289-4330",
        "email": "fortlauderdale@dogtopia.com",
        "url": "https://www.dogtopia.com/fortlauderdale-northeast",
        "geolocation": [
            26.193325489647354,
            -80.1135886576721
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 34.53,
                    "duration": "indefinitely",
                    "model_conf": 202.71
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 175,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 173.72,
                    "duration": "2 Months",
                    "model_conf": 226.45
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 325,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 323.68,
                    "duration": "1 Months",
                    "model_conf": 196.13
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 239,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 239.0,
                    "duration": "indefinitely",
                    "model_conf": 108.79
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 529,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 529.67,
                    "duration": "indefinitely",
                    "model_conf": 253.71
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.975,
        "coming_soon": false
    },
    "8th Ave Nashville": {
        "name": "Dogtopia: 8th Ave Nashville",
        "address": "1802 8th Avenue S., Nashville, Tennessee 37203",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": ""
        },
        "phone": "(615) 486-2626",
        "email": "8thave@dogtopia.com",
        "url": "https://www.dogtopia.com/8thave-nashville",
        "geolocation": [
            36.13576,
            -86.77854500000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (10 Day Pass)": {
                "cost": 380,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 379.2,
                    "duration": "indefinitely",
                    "model_conf": 192.07
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 740,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 737.97,
                    "duration": "1 Months",
                    "model_conf": 160.8
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 256,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 255.97,
                    "duration": "indefinitely",
                    "model_conf": 105.42
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 552,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 549.99,
                    "duration": "1 Months",
                    "model_conf": 224.45
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.83,
                    "duration": "indefinitely",
                    "model_conf": 163.54
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.28,
                    "duration": "indefinitely",
                    "model_conf": 99.85
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.46,
                    "duration": "indefinitely",
                    "model_conf": 149.81
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.37,
                    "duration": "indefinitely",
                    "model_conf": 63.66
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.75,
        "coming_soon": false
    },
    "Harbor Steps": {
        "name": "Dogtopia: Harbor Steps",
        "address": "1300 Post Alley, Seattle, Washington 98101",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(206) 485-7568",
        "email": "harborsteps@dogtopia.com",
        "url": "https://www.dogtopia.com/seattle-harborsteps",
        "geolocation": [
            47.60665689999999,
            -122.339167
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 95,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 94.71,
                    "duration": "1 Months",
                    "model_conf": 165.26
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 135,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 135.12,
                    "duration": "indefinitely",
                    "model_conf": 233.48
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 206,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 207.52,
                    "duration": "indefinitely",
                    "model_conf": 370.71
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 72,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 70.07,
                    "duration": "10 Months",
                    "model_conf": 264.76
                }
            }
        },
        "brand": "Dogtopia",
        "index": 113.35,
        "coming_soon": false
    },
    "Columbus Downtown": {
        "name": "Dogtopia: Columbus Downtown",
        "address": "406 E Main Street, Columbus, Ohio 43215",
        "hours": {
            "Monday": "6:00 AM - 7:00 PM",
            "Tuesday": "6:00 AM - 7:00 PM",
            "Wednesday": "6:00 AM - 7:00 PM",
            "Thursday": "6:00 AM - 7:00 PM",
            "Friday": "6:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(614) 254-6722",
        "email": "columbusdowntown@dogtopia.com",
        "url": "https://www.dogtopia.com/columbus-downtown",
        "geolocation": [
            39.9576494,
            -82.98905460000003
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 33,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 30.7,
                    "duration": "indefinitely",
                    "model_conf": 240.27
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 62,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 62.63,
                    "duration": "indefinitely",
                    "model_conf": 127.93
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.32,
                    "duration": "indefinitely",
                    "model_conf": 172.78
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 133,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 136.64,
                    "duration": "indefinitely",
                    "model_conf": 193.95
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 68,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 67.06,
                    "duration": "5 Months",
                    "model_conf": 161.37
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 78,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 81.84,
                    "duration": "indefinitely",
                    "model_conf": 398.28
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 13,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.86,
                    "duration": "4 Months",
                    "model_conf": 141.37
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.37,
                    "duration": "indefinitely",
                    "model_conf": 91.21
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.16,
                    "duration": "indefinitely",
                    "model_conf": 121.47
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.375,
        "coming_soon": false
    },
    "Meridian North": {
        "name": "Dogtopia: Meridian North",
        "address": "9445 N Meridian St, Indianapolis, Indiana 46260",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(317) 280-3877",
        "email": "meridian.north@dogtopia.com",
        "url": "https://www.dogtopia.com/meridian-north",
        "geolocation": [
            39.925366,
            -86.15604059999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 43,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.37,
                    "duration": "indefinitely",
                    "model_conf": 201.33
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 39,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.85,
                    "duration": "11 Months",
                    "model_conf": 221.53
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 73,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.81,
                    "duration": "1 Months",
                    "model_conf": 125.77
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 103,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 104.34,
                    "duration": "indefinitely",
                    "model_conf": 165.1
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 161,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 161.01,
                    "duration": "indefinitely",
                    "model_conf": 174.71
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 77,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 77.63,
                    "duration": "indefinitely",
                    "model_conf": 365.37
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.175,
        "coming_soon": false
    },
    "Omaha Northwest": {
        "name": "Dogtopia: Omaha Northwest",
        "address": "15504 Spaulding Plaza c03, Omaha, Nebraska 68116",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((40) ) 4-6-40",
        "email": "omahanorthwest@dogtopia.com",
        "url": "https://www.dogtopia.com/omaha-northwest",
        "geolocation": [
            41.2943235,
            -96.1562528
        ],
        "services": {},
        "memberships": {
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 28,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 26.69,
                    "duration": "indefinitely",
                    "model_conf": 105.83
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 16,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 17.08,
                    "duration": "indefinitely",
                    "model_conf": 254.08
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.75,
                    "duration": "indefinitely",
                    "model_conf": 171.96
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 25,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 26.51,
                    "duration": "indefinitely",
                    "model_conf": 447.05
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.4,
        "coming_soon": false
    },
    "Mckinney": {
        "name": "Dogtopia: Mckinney",
        "address": "8416 Stacy Rd, McKinney, Texas 75070",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(972) 954-2322",
        "email": "mckinney@dogtopia.com",
        "url": "https://www.dogtopia.com/mckinney",
        "geolocation": [
            33.1525462,
            -96.7236861
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.51,
                    "duration": "5 Months",
                    "model_conf": 202.07
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 33,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 34.61,
                    "duration": "indefinitely",
                    "model_conf": 458.02
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 60,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 60.8,
                    "duration": "indefinitely",
                    "model_conf": 148.3
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 83.62,
                    "duration": "1 Months",
                    "model_conf": 232.34
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 145,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 144.4,
                    "duration": "1 Months",
                    "model_conf": 341.91
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 53,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.23,
                    "duration": "indefinitely",
                    "model_conf": 214.1
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 77,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 76.46,
                    "duration": "2 Months",
                    "model_conf": 74.38
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.48,
                    "duration": "indefinitely",
                    "model_conf": 427.44
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.44,
                    "duration": "indefinitely",
                    "model_conf": 182.68
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.44,
                    "duration": "indefinitely",
                    "model_conf": 99.15
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 17.31,
                    "duration": "indefinitely",
                    "model_conf": 161.42
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.1,
        "coming_soon": false
    },
    "Holladay": {
        "name": "Dogtopia: Holladay",
        "address": "1977 E Murray Holladay Rd, Salt Lake City, Utah 84117",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(801) 998-2826",
        "email": "holladay@dogtopia.com",
        "url": "https://www.dogtopia.com/holladay",
        "geolocation": [
            40.6662903,
            -111.83502090000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 60,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 60.97,
                    "duration": "indefinitely",
                    "model_conf": 168.11
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 80,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 81.24,
                    "duration": "indefinitely",
                    "model_conf": 225.39
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 114,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 116.06,
                    "duration": "indefinitely",
                    "model_conf": 376.44
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 55.14,
                    "duration": "indefinitely",
                    "model_conf": 252.09
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 44.86,
                    "duration": "1 Months",
                    "model_conf": 191.48
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.325,
        "coming_soon": false
    },
    "Eau Claire": {
        "name": "Dogtopia: Eau Claire",
        "address": "2706 Golf Rd, Eau Claire, Wisconsin 54701-8011",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(715) 616-03",
        "email": "eauclaire@dogtopia.com",
        "url": "https://www.dogtopia.com/eau-claire",
        "geolocation": [
            44.77176310000001,
            -91.4624643
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 37,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.86,
                    "duration": "1 Months",
                    "model_conf": 204.32
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 56,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 56.23,
                    "duration": "indefinitely",
                    "model_conf": 169.9
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 78,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.32,
                    "duration": "indefinitely",
                    "model_conf": 246.26
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 118,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 118.78,
                    "duration": "indefinitely",
                    "model_conf": 383.8
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 80,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 79.58,
                    "duration": "2 Months",
                    "model_conf": 261.03
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.82,
                    "duration": "1 Months",
                    "model_conf": 192.57
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.67,
                    "duration": "12 Months",
                    "model_conf": 199.38
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.64,
                    "duration": "indefinitely",
                    "model_conf": 99.72
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.56,
                    "duration": "8 Months",
                    "model_conf": 171.87
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.425,
        "coming_soon": false
    },
    "South Chandler": {
        "name": "Dogtopia: South Chandler",
        "address": "4901 South Arizona Ave, Chandler, Arizona 85248",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(480) 447-0655",
        "email": "southchandler@dogtopia.com",
        "url": "https://www.dogtopia.com/south-chandler",
        "geolocation": [
            33.2349012,
            -111.8409505
        ],
        "services": {},
        "memberships": {
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 137.67,
                    "duration": "6 Months",
                    "model_conf": 337.31
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 250,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 248.49,
                    "duration": "2 Months",
                    "model_conf": 236.29
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.95,
        "coming_soon": false
    },
    "Geneva": {
        "name": "Dogtopia: Geneva",
        "address": "2423 Fargo Blvd, Geneva, Illinois 60134",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 2:00 PM"
        },
        "phone": "(630) 283-1700",
        "email": "geneva@dogtopia.com",
        "url": "https://www.dogtopia.com/geneva",
        "geolocation": [
            41.876164,
            -88.34222399999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 37,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.17,
                    "duration": "indefinitely",
                    "model_conf": 202.63
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 66,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 67.22,
                    "duration": "indefinitely",
                    "model_conf": 158.61
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 94.43,
                    "duration": "indefinitely",
                    "model_conf": 244.96
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 150,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 152.07,
                    "duration": "indefinitely",
                    "model_conf": 368.07
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 59.69,
                    "duration": "2 Months",
                    "model_conf": 238.57
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.47,
                    "duration": "indefinitely",
                    "model_conf": 185.09
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.04,
                    "duration": "indefinitely",
                    "model_conf": 99.09
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.88,
                    "duration": "indefinitely",
                    "model_conf": 173.68
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.25,
        "coming_soon": false
    },
    "Charlottesville": {
        "name": "Dogtopia: Charlottesville",
        "address": "315 Rivanna Plaza Drive, #120, Charlottesville, Virginia 22901",
        "hours": {
            "Monday": "6:30 AM - 7:30 PM",
            "Tuesday": "6:30 AM - 7:30 PM",
            "Wednesday": "6:30 AM - 7:30 PM",
            "Thursday": "6:30 AM - 7:30 PM",
            "Friday": "6:30 AM - 7:30 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(434) 922-1500",
        "email": "charlottesville@dogtopia.com",
        "url": "https://www.dogtopia.com/charlottesville",
        "geolocation": [
            38.0916246,
            -78.46879690000003
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.1,
                    "duration": "8 Months",
                    "model_conf": 204.1
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.06,
                    "duration": "10 Months",
                    "model_conf": 499.79
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 69.11,
                    "duration": "indefinitely",
                    "model_conf": 171.15
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 100.27,
                    "duration": "indefinitely",
                    "model_conf": 238.21
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 141.27,
                    "duration": "indefinitely",
                    "model_conf": 367.74
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.15,
        "coming_soon": false
    },
    "Haywood Road": {
        "name": "Dogtopia: Haywood Road",
        "address": "547 Haywood Road, Greenville, South Carolina 29607",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(864) 326-3390",
        "email": "haywoodroad@dogtopia.com",
        "url": "https://www.dogtopia.com/haywood-road",
        "geolocation": [
            34.8464986,
            -82.34046459999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 32,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 31.1,
                    "duration": "11 Months",
                    "model_conf": 196.57
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 29,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 28.1,
                    "duration": "12 Months",
                    "model_conf": 179.29
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 55,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 54.87,
                    "duration": "indefinitely",
                    "model_conf": 126.98
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 75,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 73.34,
                    "duration": "8 Months",
                    "model_conf": 217.75
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 120.21,
                    "duration": "indefinitely",
                    "model_conf": 170.8
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.38,
                    "duration": "indefinitely",
                    "model_conf": 207.68
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.21,
                    "duration": "indefinitely",
                    "model_conf": 170.42
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 20.16,
                    "duration": "indefinitely",
                    "model_conf": 33.23
                }
            }
        },
        "brand": "Dogtopia",
        "index": 92.5,
        "coming_soon": false
    },
    "Crestview": {
        "name": "Dogtopia: Crestview",
        "address": "7301 Burnet Rd, Suite 110, Austin, Texas 78757",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(512) 580-4880",
        "email": "crestview@dogtopia.com",
        "url": "https://www.dogtopia.com/crestview",
        "geolocation": [
            30.348111,
            -97.7342864
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 48.77,
                    "duration": "indefinitely",
                    "model_conf": 201.62
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.34,
                    "duration": "indefinitely",
                    "model_conf": 348.1
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.42,
                    "duration": "indefinitely",
                    "model_conf": 135.75
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 99.41,
                    "duration": "indefinitely",
                    "model_conf": 231.53
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 135,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 138.14,
                    "duration": "indefinitely",
                    "model_conf": 328.86
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 49,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 46.65,
                    "duration": "indefinitely",
                    "model_conf": 188.42
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 59,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 58.85,
                    "duration": "indefinitely",
                    "model_conf": 75.03
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.2,
        "coming_soon": false
    },
    "Dogtopia Of Columbus Dublin": {
        "name": "Dogtopia: Dogtopia Of Columbus Dublin",
        "address": "2768 Festival Lane, Columbus, Ohio 43017",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(614) 639-6555",
        "email": "columbus.dublin@dogtopia.com",
        "url": "https://www.dogtopia.com/columbus-dublin",
        "geolocation": [
            40.0934247,
            -83.09014339999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.18,
                    "duration": "indefinitely",
                    "model_conf": 201.22
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 264,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 263.3,
                    "duration": "1 Months",
                    "model_conf": 108.11
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 560,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 560.01,
                    "duration": "indefinitely",
                    "model_conf": 235.08
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.59,
                    "duration": "indefinitely",
                    "model_conf": 196.49
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.57,
                    "duration": "indefinitely",
                    "model_conf": 98.94
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.09,
                    "duration": "indefinitely",
                    "model_conf": 165.72
                }
            }
        },
        "brand": "Dogtopia",
        "index": 103.825,
        "coming_soon": false
    },
    "St Peters Cottleville": {
        "name": "Dogtopia: St Peters Cottleville",
        "address": "6140 Mid Rivers Mall Dr, St Peters, Missouri 63304",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:00 AM - 4:00 PM"
        },
        "phone": "(636) 321-3245",
        "email": "stpeters@dogtopia.com",
        "url": "https://www.dogtopia.com/stpeters-cottleville",
        "geolocation": [
            38.74084939999999,
            -90.63222259999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 185,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 184.41,
                    "duration": "1 Months",
                    "model_conf": 191.45
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 35,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 36.97,
                    "duration": "indefinitely",
                    "model_conf": 362.92
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 66,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 65.48,
                    "duration": "3 Months",
                    "model_conf": 157.99
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 89.15,
                    "duration": "3 Months",
                    "model_conf": 202.24
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 138.93,
                    "duration": "2 Months",
                    "model_conf": 214.39
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.4,
                    "duration": "3 Months",
                    "model_conf": 611.55
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 5.93,
                    "duration": "indefinitely",
                    "model_conf": 161.17
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.31,
                    "duration": "indefinitely",
                    "model_conf": 90.29
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.24,
                    "duration": "indefinitely",
                    "model_conf": 111.37
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.225,
        "coming_soon": false
    },
    "Webster Nasa": {
        "name": "Dogtopia: Webster Nasa",
        "address": "310 Genesis Blvd, Webster, TX 77598",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(832) 403-2993",
        "email": "nasa@dogtopia.com",
        "url": "https://www.dogtopia.com/webster-nasa",
        "geolocation": [
            29.526165489281162,
            -95.13882094989015
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 34,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 34.95,
                    "duration": "indefinitely",
                    "model_conf": 201.06
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 150,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 152.16,
                    "duration": "indefinitely",
                    "model_conf": 131.06
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 710,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 711.96,
                    "duration": "indefinitely",
                    "model_conf": 181.19
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 32,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.29,
                    "duration": "indefinitely",
                    "model_conf": 201.16
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 57,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 56.84,
                    "duration": "1 Months",
                    "model_conf": 126.75
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 81,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 80.19,
                    "duration": "3 Months",
                    "model_conf": 168.9
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 117,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 115.18,
                    "duration": "6 Months",
                    "model_conf": 176.52
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 51,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 52.66,
                    "duration": "indefinitely",
                    "model_conf": 171.57
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 78,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 72.49,
                    "duration": "indefinitely",
                    "model_conf": 523.95
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.15,
        "coming_soon": false
    },
    "Fort Collins": {
        "name": "Dogtopia: Fort Collins",
        "address": "2101 S College Avenue, Fort Collins, Colorado 80525",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "6:30 AM - 7:00 PM"
        },
        "phone": "(970) 305-5715",
        "email": "fortcollins@dogtopia.com",
        "url": "https://www.dogtopia.com/fort-collins",
        "geolocation": [
            40.560536,
            -105.07743299999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 39,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.58,
                    "duration": "4 Months",
                    "model_conf": 204.08
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 179,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 178.19,
                    "duration": "1 Months",
                    "model_conf": 223.98
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 329,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 328.01,
                    "duration": "1 Months",
                    "model_conf": 194.64
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 269,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 268.32,
                    "duration": "indefinitely",
                    "model_conf": 109.93
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 59,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 58.04,
                    "duration": "6 Months",
                    "model_conf": 262.19
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.03,
                    "duration": "indefinitely",
                    "model_conf": 206.44
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.95,
                    "duration": "1 Months",
                    "model_conf": 99.62
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.95,
                    "duration": "indefinitely",
                    "model_conf": 173.11
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.7,
        "coming_soon": false
    },
    "Tanque Verde": {
        "name": "Dogtopia: Tanque Verde",
        "address": "7285 E. Tanque Verde Rd., Tucson, Arizona 85715",
        "hours": {
            "Monday": "5:00 AM - 10:00 PM",
            "Tuesday": "5:00 AM - 10:00 PM",
            "Wednesday": "5:00 AM - 10:00 PM",
            "Thursday": "5:00 AM - 10:00 PM",
            "Friday": "5:00 AM - 10:00 PM",
            "Saturday": "5:00 AM - 10:00 PM"
        },
        "phone": "(520) 834-8717",
        "email": "tanqueverde@dogtopia.com",
        "url": "https://www.dogtopia.com/tanque-verde",
        "geolocation": [
            32.2513162,
            -110.83700049999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 47,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 36.35,
                    "duration": "indefinitely",
                    "model_conf": 459.71
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 82,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 85.12,
                    "duration": "indefinitely",
                    "model_conf": 163.31
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 111,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 116.48,
                    "duration": "indefinitely",
                    "model_conf": 202.63
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 148.98,
                    "duration": "indefinitely",
                    "model_conf": 321.64
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 260,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 265.17,
                    "duration": "indefinitely",
                    "model_conf": 234.55
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.76,
                    "duration": "indefinitely",
                    "model_conf": 207.77
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.26,
                    "duration": "indefinitely",
                    "model_conf": 159.74
                }
            },
            "SPA & GROOMING (Enzymatic Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.22,
                    "duration": "indefinitely",
                    "model_conf": 122.33
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.8,
        "coming_soon": false
    },
    "Rocklin": {
        "name": "Dogtopia: Rocklin",
        "address": "6671 Stanford Ranch Rd Suite A, Rocklin, California 95677",
        "hours": {
            "Monday": "7:00 AM - 6:00 PM",
            "Tuesday": "7:00 AM - 6:00 PM",
            "Wednesday": "7:00 AM - 6:00 PM",
            "Thursday": "7:00 AM - 6:00 PM",
            "Friday": "7:00 AM - 6:00 PM",
            "Saturday": "9:00 AM - 1:00 PM"
        },
        "phone": "(916) 229-9994",
        "email": "rocklin@dogtopia.com",
        "url": "https://www.dogtopia.com/rocklin",
        "geolocation": [
            38.78222900000001,
            -121.26331299999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.92,
                    "duration": "indefinitely",
                    "model_conf": 203.55
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.81,
                    "duration": "12 Months",
                    "model_conf": 228.52
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 32,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 32.38,
                    "duration": "indefinitely",
                    "model_conf": 164.21
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.775,
        "coming_soon": false
    },
    "Deer Park": {
        "name": "Dogtopia: Deer Park",
        "address": "21540 W. Field Parkway, Deer Park, Illinois 60010",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 4:00 PM"
        },
        "phone": "(847) 607-1245",
        "email": "deerpark@dogtopia.com",
        "url": "https://www.dogtopia.com/deer-park",
        "geolocation": [
            42.15792049999999,
            -88.0543201
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 68.85,
                    "duration": "indefinitely",
                    "model_conf": 147.6
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 95.13,
                    "duration": "indefinitely",
                    "model_conf": 213.09
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 153,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 145.82,
                    "duration": "indefinitely",
                    "model_conf": 319.57
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 79.21,
                    "duration": "indefinitely",
                    "model_conf": 226.19
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 33,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 33.37,
                    "duration": "indefinitely",
                    "model_conf": 188.53
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.66,
                    "duration": "indefinitely",
                    "model_conf": 173.6
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.15,
                    "duration": "indefinitely",
                    "model_conf": 97.35
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.95,
                    "duration": "indefinitely",
                    "model_conf": 154.11
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.225,
        "coming_soon": false
    },
    "Pittsburgh Eastside": {
        "name": "Dogtopia: Pittsburgh Eastside",
        "address": "6620 Hamilton Ave, Pittsburgh, Pennsylvania 15206",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": ""
        },
        "phone": "(412) 515-3032",
        "email": "pittsburgheastside@dogtopia.com",
        "url": "https://www.dogtopia.com/pittsburgh-eastside",
        "geolocation": [
            40.4573397,
            -79.90996439999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.6,
                    "duration": "indefinitely",
                    "model_conf": 203.43
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 288,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 288.96,
                    "duration": "indefinitely",
                    "model_conf": 109.81
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.74,
                    "duration": "6 Months",
                    "model_conf": 203.64
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.6,
                    "duration": "10 Months",
                    "model_conf": 99.31
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.01,
                    "duration": "indefinitely",
                    "model_conf": 163.83
                }
            }
        },
        "brand": "Dogtopia",
        "index": 106.3,
        "coming_soon": false
    },
    "South Elgin": {
        "name": "Dogtopia: South Elgin",
        "address": "580 Randall Road, South Elgin, Illinois 60177",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(630) 538-3155",
        "email": "southelgin@dogtopia.com",
        "url": "https://www.dogtopia.com/south-elgin",
        "geolocation": [
            41.9727632,
            -88.3406503
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.35,
                    "duration": "indefinitely",
                    "model_conf": 202.01
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 35,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 33.11,
                    "duration": "indefinitely",
                    "model_conf": 483.65
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 64,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 64.12,
                    "duration": "indefinitely",
                    "model_conf": 152.02
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 89.31,
                    "duration": "2 Months",
                    "model_conf": 241.26
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 140.12,
                    "duration": "indefinitely",
                    "model_conf": 366.08
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 77,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 77.83,
                    "duration": "indefinitely",
                    "model_conf": 217.58
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 38,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 37.03,
                    "duration": "10 Months",
                    "model_conf": 191.25
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.18,
                    "duration": "indefinitely",
                    "model_conf": 170.57
                }
            },
            "SPA & GROOMING (Ear Plucking)": {
                "cost": 11,
                "per": "session",
                "term": "",
                "description": ""
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.69,
                    "duration": "10 Months",
                    "model_conf": 98.58
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.96,
                    "duration": "indefinitely",
                    "model_conf": 172.71
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.025,
        "coming_soon": false
    },
    "South Austin": {
        "name": "Dogtopia: South Austin",
        "address": "6705 Hwy 290 West, Suite 302, Austin, Texas 78735",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(512) 692-4747",
        "email": "southaustin@dogtopia.com",
        "url": "https://www.dogtopia.com/austin-south",
        "geolocation": [
            30.234017854623247,
            -97.8665646447418
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.53,
                    "duration": "indefinitely",
                    "model_conf": 203.85
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 180,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 182.13,
                    "duration": "indefinitely",
                    "model_conf": 229.13
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 640,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 643.23,
                    "duration": "indefinitely",
                    "model_conf": 162.76
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 30,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 31.07,
                    "duration": "indefinitely",
                    "model_conf": 108.33
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 26,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 25.69,
                    "duration": "4 Months",
                    "model_conf": 248.06
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.86,
                    "duration": "indefinitely",
                    "model_conf": 203.29
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 17.46,
                    "duration": "11 Months",
                    "model_conf": 63.72
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.625,
        "coming_soon": false
    },
    "Dallas Galleria": {
        "name": "Dogtopia: Dallas Galleria",
        "address": "13520 Inwood Rd., Farmers Branch, Texas 75244",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(214) 328-3206",
        "email": "dallasinwood@dogtopia.com",
        "url": "https://www.dogtopia.com/dallas-inwood",
        "geolocation": [
            32.9316689,
            -96.82232970000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 30,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 30.11,
                    "duration": "indefinitely",
                    "model_conf": 202.15
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 135,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 132.73,
                    "duration": "6 Months",
                    "model_conf": 206.6
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 200,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 199.56,
                    "duration": "indefinitely",
                    "model_conf": 107.17
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 420,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 420.77,
                    "duration": "indefinitely",
                    "model_conf": 235.99
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 5.01,
                    "duration": "indefinitely",
                    "model_conf": 186.42
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.4,
                    "duration": "indefinitely",
                    "model_conf": 98.32
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.45,
                    "duration": "indefinitely",
                    "model_conf": 160.62
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.625,
        "coming_soon": false
    },
    "Fort Mill": {
        "name": "Dogtopia: Fort Mill",
        "address": "829 Stockbridge Drive, Fort Mill, South Carolina 29708",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(803) 620-4990",
        "email": "fortmill@dogtopia.com",
        "url": "https://www.dogtopia.com/fort-mill",
        "geolocation": [
            35.0623963,
            -80.9945444
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.0,
                    "duration": "indefinitely",
                    "model_conf": 202.68
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 67.61,
                    "duration": "2 Months",
                    "model_conf": 162.28
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 96,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 95.53,
                    "duration": "1 Months",
                    "model_conf": 244.9
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 118.8,
                    "duration": "3 Months",
                    "model_conf": 350.14
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.97,
                    "duration": "indefinitely",
                    "model_conf": 243.59
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 55.5,
                    "duration": "indefinitely",
                    "model_conf": 191.92
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.23,
                    "duration": "4 Months",
                    "model_conf": 75.0
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.88,
                    "duration": "4 Months",
                    "model_conf": 181.47
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.09,
                    "duration": "indefinitely",
                    "model_conf": 98.93
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.88,
                    "duration": "3 Months",
                    "model_conf": 164.18
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.55,
        "coming_soon": false
    },
    "Torrance South Bay": {
        "name": "Dogtopia: Torrance South Bay",
        "address": "2360 W 205th Street, Torrance, California 90501",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(310) 953-9321",
        "email": "southbay@dogtopia.com",
        "url": "https://www.dogtopia.com/torrance-south-bay",
        "geolocation": [
            33.8450603,
            -118.3264011
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 49,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 48.84,
                    "duration": "1 Months",
                    "model_conf": 202.01
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 42.61,
                    "duration": "12 Months",
                    "model_conf": 368.13
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 78,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.37,
                    "duration": "indefinitely",
                    "model_conf": 169.67
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 111,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 112.51,
                    "duration": "indefinitely",
                    "model_conf": 233.91
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 175,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 176.18,
                    "duration": "indefinitely",
                    "model_conf": 221.34
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 80,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 78.79,
                    "duration": "5 Months",
                    "model_conf": 255.23
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 70.58,
                    "duration": "indefinitely",
                    "model_conf": 175.31
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 90,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 90.53,
                    "duration": "indefinitely",
                    "model_conf": 632.73
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 80,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 79.51,
                    "duration": "2 Months",
                    "model_conf": 73.78
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.17,
                    "duration": "indefinitely",
                    "model_conf": 154.7
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.54,
                    "duration": "indefinitely",
                    "model_conf": 97.71
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.06,
                    "duration": "indefinitely",
                    "model_conf": 170.25
                }
            }
        },
        "brand": "Dogtopia",
        "index": 106.975,
        "coming_soon": false
    },
    "Limerick": {
        "name": "Dogtopia: Limerick",
        "address": "33 W. Ridge Pike, Suite 387, Limerick, Pennsylvania 19468",
        "hours": {
            "Monday": "7:00 AM - 6:00 PM",
            "Tuesday": "7:00 AM - 6:00 PM",
            "Wednesday": "7:00 AM - 6:00 PM",
            "Thursday": "7:00 AM - 6:00 PM",
            "Friday": "7:00 AM - 6:00 PM",
            "Saturday": "10:00 AM - 4:00 PM"
        },
        "phone": "(610) 200-6441",
        "email": "Limerick@dogtopia.com",
        "url": "https://www.dogtopia.com/limerick",
        "geolocation": [
            40.21601121140885,
            -75.50241853134611
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 34,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.64,
                    "duration": "indefinitely",
                    "model_conf": 227.34
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 32,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 32.5,
                    "duration": "indefinitely",
                    "model_conf": 196.18
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 30,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 28.58,
                    "duration": "indefinitely",
                    "model_conf": 163.89
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 240,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 242.6,
                    "duration": "indefinitely",
                    "model_conf": 109.44
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 43.99,
                    "duration": "indefinitely",
                    "model_conf": 483.76
                }
            }
        },
        "brand": "Dogtopia",
        "index": 94.525,
        "coming_soon": false
    },
    "Gilbert": {
        "name": "Dogtopia: Gilbert",
        "address": "207 E Williams Field Rd, Gilbert, Arizona 85295",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(480) 424-5224",
        "email": "gilbert@dogtopia.com",
        "url": "https://www.dogtopia.com/gilbert",
        "geolocation": [
            33.3058593,
            -111.7853609
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 42.37,
                    "duration": "indefinitely",
                    "model_conf": 200.95
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 400,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 400.96,
                    "duration": "indefinitely",
                    "model_conf": 188.02
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 294,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 294.26,
                    "duration": "indefinitely",
                    "model_conf": 104.02
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 535,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 534.71,
                    "duration": "indefinitely",
                    "model_conf": 215.08
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 62.08,
                    "duration": "indefinitely",
                    "model_conf": 206.57
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 60.28,
                    "duration": "indefinitely",
                    "model_conf": 494.35
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.38,
                    "duration": "indefinitely",
                    "model_conf": 166.87
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.85,
                    "duration": "indefinitely",
                    "model_conf": 94.12
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.78,
                    "duration": "indefinitely",
                    "model_conf": 140.02
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.95,
        "coming_soon": false
    },
    "Frederick": {
        "name": "Dogtopia: Frederick",
        "address": "4959 Westview Dr Suite C, Frederick, MD 21703",
        "hours": {
            "Monday": "6:30 AM - 7:30 PM",
            "Tuesday": "6:30 AM - 7:30 PM",
            "Wednesday": "6:30 AM - 7:30 PM",
            "Thursday": "6:30 AM - 7:30 PM",
            "Friday": "6:30 AM - 7:30 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(301) 732-7372",
        "email": "frederick@dogtopia.com",
        "url": "https://www.dogtopia.com/frederick",
        "geolocation": [
            39.3693567,
            -77.41435919999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 75.84,
                    "duration": "3 Months",
                    "model_conf": 170.8
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 101.25,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 100.03,
                    "duration": "4 Months",
                    "model_conf": 244.56
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 157.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 155.4,
                    "duration": "5 Months",
                    "model_conf": 390.86
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 71.46,
                    "duration": "indefinitely",
                    "model_conf": 191.21
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.48,
                    "duration": "indefinitely",
                    "model_conf": 207.98
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.81,
                    "duration": "indefinitely",
                    "model_conf": 99.82
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.24,
                    "duration": "indefinitely",
                    "model_conf": 174.56
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.025,
        "coming_soon": false
    },
    "Dublin": {
        "name": "Dogtopia: Dublin",
        "address": "8000 Amador Valley Blvd, Dublin, California 94568",
        "hours": {
            "Monday": "7:00 AM - 6:00 PM",
            "Tuesday": "7:00 AM - 6:00 PM",
            "Wednesday": "7:00 AM - 6:00 PM",
            "Thursday": "7:00 AM - 6:00 PM",
            "Friday": "7:00 AM - 6:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(925) 587-4048",
        "email": "dublin@dogtopia.com",
        "url": "https://www.dogtopia.com/dublin-ca",
        "geolocation": [
            37.70543,
            -121.93613729999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 51,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 52.55,
                    "duration": "indefinitely",
                    "model_conf": 203.35
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 46,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 46.73,
                    "duration": "indefinitely",
                    "model_conf": 493.54
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 85,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.78,
                    "duration": "1 Months",
                    "model_conf": 166.68
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 122,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 121.65,
                    "duration": "1 Months",
                    "model_conf": 230.9
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 189,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 188.26,
                    "duration": "1 Months",
                    "model_conf": 380.56
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 81,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 80.97,
                    "duration": "indefinitely",
                    "model_conf": 250.56
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 74,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 74.22,
                    "duration": "indefinitely",
                    "model_conf": 191.77
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 9,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.18,
                    "duration": "indefinitely",
                    "model_conf": 195.23
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 17,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 18.14,
                    "duration": "indefinitely",
                    "model_conf": 171.82
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 22,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 21.87,
                    "duration": "2 Months",
                    "model_conf": 63.9
                }
            }
        },
        "brand": "Dogtopia",
        "index": 108.475,
        "coming_soon": false
    },
    "Winter Park": {
        "name": "Dogtopia: Winter Park",
        "address": "5477 Lake Howell Road, Winter Park, FL 32792",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 6:00 PM"
        },
        "phone": "(407) 561-57",
        "email": "winterpark@dogtopia.com",
        "url": "https://www.dogtopia.com/orlando-winter-park",
        "geolocation": [
            28.623083,
            -81.32472
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.14,
                    "duration": "7 Months",
                    "model_conf": 201.54
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 41.46,
                    "duration": "indefinitely",
                    "model_conf": 199.84
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 71,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 70.42,
                    "duration": "3 Months",
                    "model_conf": 126.19
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 100,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 98.53,
                    "duration": "5 Months",
                    "model_conf": 165.66
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 139.5,
                    "duration": "1 Months",
                    "model_conf": 172.87
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 60.63,
                    "duration": "indefinitely",
                    "model_conf": 151.32
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.34,
                    "duration": "4 Months",
                    "model_conf": 172.07
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled - Unlimited)": {
                "cost": 27,
                "per": "night",
                "term": "",
                "description": ""
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 72.8,
                    "duration": "11 Months",
                    "model_conf": 335.99
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.89,
                    "duration": "indefinitely",
                    "model_conf": 72.52
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled - Unlimited)": {
                "cost": 39,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 41.64,
                    "duration": "indefinitely",
                    "model_conf": 208.04
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.05,
        "coming_soon": false
    },
    "Grand Rapids": {
        "name": "Dogtopia: Grand Rapids",
        "address": "2321 E. Beltline Ave NE Suite H., Grand Rapids, Michigan 49525",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(616) 222-1630",
        "email": "grandrapids@dogtopia.com",
        "url": "https://www.dogtopia.com/grand-rapids",
        "geolocation": [
            43.0061749,
            -85.59072600000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.37,
                    "duration": "indefinitely",
                    "model_conf": 203.75
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 58,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 55.26,
                    "duration": "indefinitely",
                    "model_conf": 171.28
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 79.66,
                    "duration": "indefinitely",
                    "model_conf": 244.31
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 59,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 63.95,
                    "duration": "indefinitely",
                    "model_conf": 255.0
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 52,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 53.83,
                    "duration": "indefinitely",
                    "model_conf": 192.5
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 62,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 61.28,
                    "duration": "4 Months",
                    "model_conf": 75.42
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled - Unlimited)": {
                "cost": 130,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 139.61,
                    "duration": "indefinitely",
                    "model_conf": 440.89
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.58,
                    "duration": "indefinitely",
                    "model_conf": 200.3
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.69,
                    "duration": "indefinitely",
                    "model_conf": 99.1
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.55,
        "coming_soon": false
    },
    "Redmond": {
        "name": "Dogtopia: Redmond",
        "address": "2690 152nd Avenue NE, Suite #110, Redmond, Washington 98052",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(425) 201-7373",
        "email": "Redmond@dogtopia.com",
        "url": "https://www.dogtopia.com/redmond",
        "geolocation": [
            47.6337234,
            -122.1369454
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 110,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 110.03,
                    "duration": "indefinitely",
                    "model_conf": 171.13
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 156,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 153.83,
                    "duration": "5 Months",
                    "model_conf": 219.16
                }
            },
            "DAYCARE (5 Days a Week)": {
                "cost": 240,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 237.98,
                    "duration": "3 Months",
                    "model_conf": 160.27
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 34,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 31.37,
                    "duration": "indefinitely",
                    "model_conf": 571.17
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 26,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 26.88,
                    "duration": "indefinitely",
                    "model_conf": 442.68
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.47,
                    "duration": "indefinitely",
                    "model_conf": 192.59
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 16,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.4,
                    "duration": "indefinitely",
                    "model_conf": 138.57
                }
            },
            "SPA & GROOMING (Enzymatic Teeth Brushing)": {
                "cost": 16,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.31,
                    "duration": "indefinitely",
                    "model_conf": 122.58
                }
            }
        },
        "brand": "Dogtopia",
        "index": 112.825,
        "coming_soon": false
    },
    "Ellisville": {
        "name": "Dogtopia: Ellisville",
        "address": "15400 Manchester Rd, Ellisville, Missouri 63011",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(636) 283-2444",
        "email": "ellisville@dogtopia.com",
        "url": "https://www.dogtopia.com/ellisville",
        "geolocation": [
            38.5928128,
            -90.56215689999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 35,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 33.02,
                    "duration": "indefinitely",
                    "model_conf": 399.64
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 66,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 66.82,
                    "duration": "indefinitely",
                    "model_conf": 145.06
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 94.44,
                    "duration": "indefinitely",
                    "model_conf": 208.65
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 142.18,
                    "duration": "indefinitely",
                    "model_conf": 296.3
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 58.91,
                    "duration": "7 Months",
                    "model_conf": 194.58
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 55.39,
                    "duration": "indefinitely",
                    "model_conf": 188.4
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.16,
                    "duration": "indefinitely",
                    "model_conf": 174.9
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.84,
                    "duration": "7 Months",
                    "model_conf": 97.46
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 17.58,
                    "duration": "9 Months",
                    "model_conf": 148.69
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.6,
        "coming_soon": false
    },
    "Roundrock": {
        "name": "Dogtopia: Roundrock",
        "address": "1500 South A.W. Grimes Boulevard, Suite 150, Round Rock, Texas 78664",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(512) 333-1145",
        "email": "RoundRock@dogtopia.com",
        "url": "https://www.dogtopia.com/roundrock",
        "geolocation": [
            30.497978,
            -97.65705300000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.85,
                    "duration": "indefinitely",
                    "model_conf": 203.3
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 33,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 34.09,
                    "duration": "indefinitely",
                    "model_conf": 197.96
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 32,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 31.98,
                    "duration": "indefinitely",
                    "model_conf": 194.16
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 29,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 29.89,
                    "duration": "indefinitely",
                    "model_conf": 162.09
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 224,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 222.93,
                    "duration": "1 Months",
                    "model_conf": 105.69
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.82,
                    "duration": "1 Months",
                    "model_conf": 618.72
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 11,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.85,
                    "duration": "indefinitely",
                    "model_conf": 182.86
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 11,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.71,
                    "duration": "indefinitely",
                    "model_conf": 98.47
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.93,
                    "duration": "1 Months",
                    "model_conf": 59.49
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.3,
        "coming_soon": false
    },
    "Thewoodlandsnorth": {
        "name": "Dogtopia: Thewoodlandsnorth",
        "address": "463 FM 1488, Conroe, Texas 77384",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:00 AM - 4:00 PM"
        },
        "phone": "(832) 308-3664",
        "email": "thewoodlandsnorth@dogtopia.com",
        "url": "https://www.dogtopia.com/thewoodlandsnorth",
        "geolocation": [
            30.2356038,
            -95.4652322
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.98,
                    "duration": "indefinitely",
                    "model_conf": 203.73
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.74,
                    "duration": "2 Months",
                    "model_conf": 233.2
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 35,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 34.15,
                    "duration": "9 Months",
                    "model_conf": 110.05
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 600,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 598.56,
                    "duration": "indefinitely",
                    "model_conf": 253.96
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.76,
                    "duration": "indefinitely",
                    "model_conf": 207.79
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.4,
                    "duration": "indefinitely",
                    "model_conf": 100.0
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.09,
                    "duration": "indefinitely",
                    "model_conf": 172.51
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.325,
        "coming_soon": false
    },
    "Oakbrook Terrace": {
        "name": "Dogtopia: Oakbrook Terrace",
        "address": "17W711 E Roosevelt Rd, Oakbrook Terrace, Illinois 60181",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": ""
        },
        "phone": "(630) 835-0108",
        "email": "oakbrookterrace@dogtopia.com",
        "url": "https://www.dogtopia.com/oakbrook-terrace",
        "geolocation": [
            41.85880522619192,
            -87.97586479867249
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.98,
                    "duration": "indefinitely",
                    "model_conf": 199.93
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 264,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 266.37,
                    "duration": "indefinitely",
                    "model_conf": 101.31
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.26,
                    "duration": "indefinitely",
                    "model_conf": 267.57
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 66.66,
                    "duration": "7 Months",
                    "model_conf": 131.25
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 95.26,
                    "duration": "indefinitely",
                    "model_conf": 173.18
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 144,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 142.2,
                    "duration": "4 Months",
                    "model_conf": 219.2
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 575,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 573.06,
                    "duration": "1 Months",
                    "model_conf": 194.15
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 72.11,
                    "duration": "indefinitely",
                    "model_conf": 170.23
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 53.9,
                    "duration": "7 Months",
                    "model_conf": 178.2
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 80,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 78.16,
                    "duration": "9 Months",
                    "model_conf": 424.04
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 63.33,
                    "duration": "10 Months",
                    "model_conf": 73.32
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.53,
                    "duration": "indefinitely",
                    "model_conf": 125.9
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.45,
        "coming_soon": false
    },
    "Edmond": {
        "name": "Dogtopia: Edmond",
        "address": "3409 S Broadway, Suite 950, Edmond, Oklahoma 73013",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(405) 345-7268",
        "email": "Edmond@dogtopia.com",
        "url": "https://www.dogtopia.com/edmond",
        "geolocation": [
            35.62240989999999,
            -97.488317
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 34,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.13,
                    "duration": "indefinitely",
                    "model_conf": 203.79
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 32,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 31.17,
                    "duration": "10 Months",
                    "model_conf": 228.9
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 29,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 27.91,
                    "duration": "indefinitely",
                    "model_conf": 161.31
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 125,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 122.9,
                    "duration": "6 Months",
                    "model_conf": 384.36
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 53,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.13,
                    "duration": "indefinitely",
                    "model_conf": 671.88
                }
            }
        },
        "brand": "Dogtopia",
        "index": 91.975,
        "coming_soon": false
    },
    "Carriage Crossing": {
        "name": "Dogtopia: Carriage Crossing",
        "address": "10319 East Shelby Drive, Collierville, Tennessee 38017",
        "hours": {
            "Monday": "6:30 AM - 8:00 PM",
            "Tuesday": "6:30 AM - 8:00 PM",
            "Wednesday": "6:30 AM - 8:00 PM",
            "Thursday": "6:30 AM - 8:00 PM",
            "Friday": "6:30 AM - 8:00 PM",
            "Saturday": "6:30 AM - 8:00 PM"
        },
        "phone": "(901) 443-1880",
        "email": "carriagecrossing@dogtopia.com",
        "url": "https://www.dogtopia.com/carriage-crossing",
        "geolocation": [
            35.0186499,
            -89.7191489
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.18,
                    "duration": "indefinitely",
                    "model_conf": 199.38
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 34,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 34.13,
                    "duration": "indefinitely",
                    "model_conf": 425.68
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 64,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 64.15,
                    "duration": "indefinitely",
                    "model_conf": 168.46
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 85,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 85.41,
                    "duration": "indefinitely",
                    "model_conf": 231.85
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 133,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 132.65,
                    "duration": "1 Months",
                    "model_conf": 389.67
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 74.34,
                    "duration": "3 Months",
                    "model_conf": 261.1
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.575,
        "coming_soon": false
    },
    "Nimbus Winery": {
        "name": "Dogtopia: Nimbus Winery",
        "address": "12401 Folsom Blvd., Suite 120, Rancho Cordova, CA 95742",
        "hours": {
            "Monday": "7:00 AM - 6:00 PM",
            "Tuesday": "7:00 AM - 6:00 PM",
            "Wednesday": "7:00 AM - 6:00 PM",
            "Thursday": "7:00 AM - 6:00 PM",
            "Friday": "7:00 AM - 6:00 PM",
            "Saturday": "9:00 AM - 1:00 PM"
        },
        "phone": "((91) ) 7-4-DO",
        "email": "nimbuswinery@dogtopia.com",
        "url": "https://www.dogtopia.com/sacramento-nimbus-winery",
        "geolocation": [
            38.6303857,
            -121.2155194
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 39.96,
                    "duration": "indefinitely",
                    "model_conf": 203.14
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.5,
                    "duration": "5 Months",
                    "model_conf": 224.65
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 35,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 33.8,
                    "duration": "indefinitely",
                    "model_conf": 109.76
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 30,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 28.35,
                    "duration": "indefinitely",
                    "model_conf": 246.12
                }
            }
        },
        "brand": "Dogtopia",
        "index": 90.175,
        "coming_soon": false
    },
    "Jacksonville Southside": {
        "name": "Dogtopia: Jacksonville Southside",
        "address": "9365 Philips HWY, Jacksonville, Florida 32256",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(904) 297-3247",
        "email": "jaxavenues@dogtopia.com",
        "url": "https://www.dogtopia.com/jacksonville-southside",
        "geolocation": [
            30.197226061789326,
            -81.56957815952455
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 30,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 30.13,
                    "duration": "indefinitely",
                    "model_conf": 202.87
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 145,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 144.36,
                    "duration": "1 Months",
                    "model_conf": 222.7
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 60.36,
                    "duration": "indefinitely",
                    "model_conf": 541.17
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.24,
                    "duration": "indefinitely",
                    "model_conf": 208.33
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.75,
                    "duration": "9 Months",
                    "model_conf": 95.56
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.7,
                    "duration": "11 Months",
                    "model_conf": 163.94
                }
            }
        },
        "brand": "Dogtopia",
        "index": 94.9,
        "coming_soon": false
    },
    "Hickory Plaza Nashville": {
        "name": "Dogtopia: Hickory Plaza Nashville",
        "address": "5728 Hickory Plaza Dr, Nashville, Tennessee 37211",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "2:00 PM - 4:00 PM"
        },
        "phone": "(615) 257-0222",
        "email": "hickoryplaza@dogtopia.com",
        "url": "https://www.dogtopia.com/hickory-plaza-nashville",
        "geolocation": [
            36.04677780000001,
            -86.71612170000003
        ],
        "services": {},
        "memberships": {
            "DAYCARE (20 Day Pass)": {
                "cost": 33.3,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 34.41,
                    "duration": "indefinitely",
                    "model_conf": 163.58
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 244,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 244.0,
                    "duration": "indefinitely",
                    "model_conf": 109.06
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 490,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 488.63,
                    "duration": "1 Months",
                    "model_conf": 246.46
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.66,
                    "duration": "indefinitely",
                    "model_conf": 200.22
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.37,
                    "duration": "indefinitely",
                    "model_conf": 100.08
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.04,
                    "duration": "indefinitely",
                    "model_conf": 169.69
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.91,
                    "duration": "indefinitely",
                    "model_conf": 64.73
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.225,
        "coming_soon": false
    },
    "Lagrange": {
        "name": "Dogtopia: Lagrange",
        "address": "1416 West 55th St, La Grange, Illinois 60525",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 3:00 PM"
        },
        "phone": "(708) 581-8753",
        "email": "lagrange@dogtopia.com",
        "url": "https://www.dogtopia.com/lagrange",
        "geolocation": [
            41.790412,
            -87.88746100000003
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.04,
                    "duration": "indefinitely",
                    "model_conf": 202.78
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 170,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 169.31,
                    "duration": "1 Months",
                    "model_conf": 218.28
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 330,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 329.87,
                    "duration": "indefinitely",
                    "model_conf": 194.44
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 640,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 637.84,
                    "duration": "1 Months",
                    "model_conf": 162.54
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.875,
        "coming_soon": false
    },
    "Oro Valley": {
        "name": "Dogtopia: Oro Valley",
        "address": "7621 North Oracle Road, Oro Valley, Arizona 85704",
        "hours": {
            "Monday": "5:00 AM - 10:00 PM",
            "Tuesday": "5:00 AM - 10:00 PM",
            "Wednesday": "5:00 AM - 10:00 PM",
            "Thursday": "5:00 AM - 10:00 PM",
            "Friday": "5:00 AM - 10:00 PM",
            "Saturday": "5:00 AM - 10:00 PM"
        },
        "phone": "(520) 385-7382",
        "email": "orovalley@dogtopia.com",
        "url": "https://www.dogtopia.com/oro-valley",
        "geolocation": [
            32.3458096,
            -110.97795659999997
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 47,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.58,
                    "duration": "indefinitely",
                    "model_conf": 429.03
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 82,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 85.15,
                    "duration": "indefinitely",
                    "model_conf": 149.73
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 112.39,
                    "duration": "indefinitely",
                    "model_conf": 172.58
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 135,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 141.08,
                    "duration": "indefinitely",
                    "model_conf": 327.58
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 255,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 257.37,
                    "duration": "indefinitely",
                    "model_conf": 203.57
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.26,
                    "duration": "indefinitely",
                    "model_conf": 206.31
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.24,
                    "duration": "indefinitely",
                    "model_conf": 133.27
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 17.14,
                    "duration": "indefinitely",
                    "model_conf": 431.52
                }
            },
            "SPA & GROOMING (Enzymatic Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.1,
                    "duration": "indefinitely",
                    "model_conf": 122.37
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.45,
        "coming_soon": false
    },
    "Downers Grove": {
        "name": "Dogtopia: Downers Grove",
        "address": "909 Ogden Avenue, Downers Grove, Illinois 60515",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "((63) ) 3-4-00",
        "email": "downersgrove@dogtopia.com",
        "url": "https://www.dogtopia.com/downers-grove",
        "geolocation": [
            41.808516,
            -88.00813869999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.05,
                    "duration": "indefinitely",
                    "model_conf": 200.47
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.67,
                    "duration": "3 Months",
                    "model_conf": 293.6
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 71,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.15,
                    "duration": "indefinitely",
                    "model_conf": 134.27
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 101,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 99.04,
                    "duration": "7 Months",
                    "model_conf": 180.41
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 158,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 157.18,
                    "duration": "2 Months",
                    "model_conf": 231.66
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 62.51,
                    "duration": "indefinitely",
                    "model_conf": 176.91
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 74.49,
                    "duration": "2 Months",
                    "model_conf": 464.4
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.89,
                    "duration": "3 Months",
                    "model_conf": 130.59
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.925,
        "coming_soon": false
    },
    "New Orleans": {
        "name": "Dogtopia: New Orleans",
        "address": "9501 Airline Hwy, New Orleans, Louisiana 70118",
        "hours": {
            "Monday": "6:30 AM - 7:30 PM",
            "Tuesday": "6:30 AM - 7:30 PM",
            "Wednesday": "6:30 AM - 7:30 PM",
            "Thursday": "6:30 AM - 7:30 PM",
            "Friday": "6:30 AM - 7:30 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(504) 291-2777",
        "email": "neworleans@dogtopia.com",
        "url": "https://www.dogtopia.com/new-orleans",
        "geolocation": [
            29.9724847,
            -90.12489049999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 175,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 173.82,
                    "duration": "2 Months",
                    "model_conf": 205.58
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 575,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 573.27,
                    "duration": "1 Months",
                    "model_conf": 160.88
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 229,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 230.62,
                    "duration": "indefinitely",
                    "model_conf": 107.28
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 499,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 499.16,
                    "duration": "indefinitely",
                    "model_conf": 253.68
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.63,
                    "duration": "indefinitely",
                    "model_conf": 488.05
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.88,
                    "duration": "4 Months",
                    "model_conf": 199.95
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.42,
                    "duration": "indefinitely",
                    "model_conf": 99.56
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.81,
                    "duration": "indefinitely",
                    "model_conf": 131.92
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.525,
        "coming_soon": false
    },
    "Northville": {
        "name": "Dogtopia: Northville",
        "address": "20510 Haggerty Road, Northville, Michigan 48167",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(248) 289-3507",
        "email": "northville@dogtopia.com",
        "url": "https://www.dogtopia.com/northville",
        "geolocation": [
            42.437425,
            -83.43443300000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 160,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 158.05,
                    "duration": "4 Months",
                    "model_conf": 210.19
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 560,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 559.64,
                    "duration": "indefinitely",
                    "model_conf": 162.31
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 232,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 231.09,
                    "duration": "1 Months",
                    "model_conf": 108.32
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 500,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 500.6,
                    "duration": "indefinitely",
                    "model_conf": 243.7
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 64,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 62.26,
                    "duration": "10 Months",
                    "model_conf": 646.41
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.53,
                    "duration": "indefinitely",
                    "model_conf": 196.98
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.6,
                    "duration": "indefinitely",
                    "model_conf": 96.58
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.4,
                    "duration": "indefinitely",
                    "model_conf": 160.16
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.525,
        "coming_soon": false
    },
    "Albuquerque West": {
        "name": "Dogtopia: Albuquerque West",
        "address": "3301 Coors Boulevard Northwest S-17, Albuquerque, New Mexico 87120",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(505) 365-1512",
        "email": "albuquerquewest@dogtopia.com",
        "url": "https://www.dogtopia.com/albuquerque-west",
        "geolocation": [
            35.11994889999999,
            -106.70303619999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 29,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 29.58,
                    "duration": "indefinitely",
                    "model_conf": 395.07
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 54,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 53.73,
                    "duration": "1 Months",
                    "model_conf": 150.84
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 75,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 75.31,
                    "duration": "indefinitely",
                    "model_conf": 182.21
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 105,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 104.98,
                    "duration": "indefinitely",
                    "model_conf": 245.87
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 50,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 49.68,
                    "duration": "2 Months",
                    "model_conf": 177.88
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 61.04,
                    "duration": "indefinitely",
                    "model_conf": 686.26
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.59,
                    "duration": "indefinitely",
                    "model_conf": 166.57
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.85,
                    "duration": "4 Months",
                    "model_conf": 96.26
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.01,
                    "duration": "indefinitely",
                    "model_conf": 167.41
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.05,
        "coming_soon": false
    },
    "Arcadia": {
        "name": "Dogtopia: Arcadia",
        "address": "3031 East Indian School Road, Phoenix, Arizona 85016",
        "hours": {
            "Monday": "5:00 AM - 10:00 PM",
            "Tuesday": "5:00 AM - 10:00 PM",
            "Wednesday": "5:00 AM - 10:00 PM",
            "Thursday": "5:00 AM - 10:00 PM",
            "Friday": "5:00 AM - 10:00 PM",
            "Saturday": "5:00 AM - 10:00 PM"
        },
        "phone": "(480) 405-9646",
        "email": "arcadia@dogtopia.com",
        "url": "https://www.dogtopia.com/phoenix-arcadia",
        "geolocation": [
            33.4950096,
            -112.01640529999997
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 46,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 50.42,
                    "duration": "indefinitely",
                    "model_conf": 261.6
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 82,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 80.53,
                    "duration": "7 Months",
                    "model_conf": 119.62
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 114,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 112.1,
                    "duration": "6 Months",
                    "model_conf": 168.14
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 116.13,
                    "duration": "12 Months",
                    "model_conf": 212.32
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 240,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 237.52,
                    "duration": "4 Months",
                    "model_conf": 183.15
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.54,
                    "duration": "indefinitely",
                    "model_conf": 132.79
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.85,
                    "duration": "indefinitely",
                    "model_conf": 121.69
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 4.98,
                    "duration": "indefinitely",
                    "model_conf": 242.29
                }
            },
            "SPA & GROOMING (Enzymatic Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.21,
                    "duration": "indefinitely",
                    "model_conf": 122.21
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.225,
        "coming_soon": false
    },
    "Jacksonville Southbank": {
        "name": "Dogtopia: Jacksonville Southbank",
        "address": "1075 Hendricks Avenue, Jacksonville, Florida 32207",
        "hours": {
            "Monday": "6:30 AM - 7:30 PM",
            "Tuesday": "6:30 AM - 7:30 PM",
            "Wednesday": "6:30 AM - 7:30 PM",
            "Thursday": "6:30 AM - 7:30 PM",
            "Friday": "6:30 AM - 7:30 PM",
            "Saturday": "10:00 AM - 2:00 PM"
        },
        "phone": "(904) 600-3810",
        "email": "jaxsouthbank@dogtopia.com",
        "url": "https://www.dogtopia.com/jacksonville-southbank",
        "geolocation": [
            30.3146827,
            -81.65437500000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 37,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.16,
                    "duration": "indefinitely",
                    "model_conf": 203.57
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 175,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 175.7,
                    "duration": "indefinitely",
                    "model_conf": 222.66
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 248,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 246.37,
                    "duration": "2 Months",
                    "model_conf": 109.45
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 548,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 546.93,
                    "duration": "indefinitely",
                    "model_conf": 250.71
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 62.06,
                    "duration": "indefinitely",
                    "model_conf": 635.92
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.35,
        "coming_soon": false
    },
    "Pewaukee": {
        "name": "Dogtopia: Pewaukee",
        "address": "W238N1700 Rockwood Dr, Suite B, Pewaukee, Wisconsin 53188",
        "hours": {
            "Monday": "6:30 AM - 6:30 PM",
            "Tuesday": "6:30 AM - 6:30 PM",
            "Wednesday": "6:30 AM - 6:30 PM",
            "Thursday": "6:30 AM - 6:30 PM",
            "Friday": "6:30 AM - 6:30 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(262) 244-5366",
        "email": "pewaukee@dogtopia.com",
        "url": "https://www.dogtopia.com/pewaukee",
        "geolocation": [
            43.05597325082292,
            -88.22245870503235
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 35,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 35.92,
                    "duration": "indefinitely",
                    "model_conf": 232.4
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 31.5,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 30.14,
                    "duration": "indefinitely",
                    "model_conf": 164.05
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.71,
                    "duration": "indefinitely",
                    "model_conf": 469.6
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.61,
                    "duration": "indefinitely",
                    "model_conf": 193.89
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.87,
                    "duration": "4 Months",
                    "model_conf": 99.92
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.63,
                    "duration": "7 Months",
                    "model_conf": 64.43
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.075,
        "coming_soon": false
    },
    "San Jose": {
        "name": "Dogtopia: San Jose",
        "address": "1191 Branham Lane, San Jose, California 95118",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "8:00 AM - 6:00 PM"
        },
        "phone": "(408) 659-3065",
        "email": "sanjose@dogtopia.com",
        "url": "https://www.dogtopia.com/san-jose",
        "geolocation": [
            37.262868867413765,
            -121.87921044685055
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.37,
                    "duration": "5 Months",
                    "model_conf": 229.67
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 43,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 43.9,
                    "duration": "indefinitely",
                    "model_conf": 193.67
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 41,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 41.93,
                    "duration": "indefinitely",
                    "model_conf": 162.84
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 45,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 45.29,
                    "duration": "indefinitely",
                    "model_conf": 440.49
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.7,
        "coming_soon": false
    },
    "Overland Park": {
        "name": "Dogtopia: Overland Park",
        "address": "6905 W. 135th Street, Overland Park, Kansas 66223",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(913) 274-9033",
        "email": "overlandpark@dogtopia.com",
        "url": "https://www.dogtopia.com/kansas-overland-park",
        "geolocation": [
            38.88002849999999,
            -94.66443290000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 39,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.84,
                    "duration": "indefinitely",
                    "model_conf": 201.74
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 37,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.98,
                    "duration": "indefinitely",
                    "model_conf": 216.71
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 33,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 33.43,
                    "duration": "indefinitely",
                    "model_conf": 157.72
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 36,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 35.6,
                    "duration": "4 Months",
                    "model_conf": 109.44
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 32,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 31.94,
                    "duration": "indefinitely",
                    "model_conf": 220.07
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.84,
                    "duration": "1 Months",
                    "model_conf": 237.68
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.06,
                    "duration": "indefinitely",
                    "model_conf": 171.26
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 5.95,
                    "duration": "indefinitely",
                    "model_conf": 97.73
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.82,
                    "duration": "4 Months",
                    "model_conf": 173.06
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.325,
        "coming_soon": false
    },
    "Texas Plano": {
        "name": "Dogtopia: Texas Plano",
        "address": "1501 Preston Road, Suite 600, Plano, Texas 75093",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(972) 752-8204",
        "email": "plano@dogtopia.com",
        "url": "https://www.dogtopia.com/texas-plano",
        "geolocation": [
            33.01904650408395,
            -96.79622170783688
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 35,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 34.15,
                    "duration": "9 Months",
                    "model_conf": 198.12
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 31,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 26.11,
                    "duration": "indefinitely",
                    "model_conf": 304.17
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 58,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 59.61,
                    "duration": "indefinitely",
                    "model_conf": 125.7
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 81,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 82.95,
                    "duration": "indefinitely",
                    "model_conf": 189.17
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 132,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 135.6,
                    "duration": "indefinitely",
                    "model_conf": 235.21
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 99,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 97.53,
                    "duration": "5 Months",
                    "model_conf": 164.11
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 4.87,
                    "duration": "indefinitely",
                    "model_conf": 341.23
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 18.22,
                    "duration": "indefinitely",
                    "model_conf": 145.14
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.49,
                    "duration": "indefinitely",
                    "model_conf": 95.57
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 25,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 24.01,
                    "duration": "indefinitely",
                    "model_conf": 131.52
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 40,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 44.4,
                    "duration": "indefinitely",
                    "model_conf": 246.22
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.55,
        "coming_soon": false
    },
    "Baton Rouge": {
        "name": "Dogtopia: Baton Rouge",
        "address": "7150 Jefferson HWY, Baton Rouge, Louisiana 70806",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(225) 224-8585",
        "email": "batonrouge@dogtopia.com",
        "url": "https://www.dogtopia.com/baton-rouge",
        "geolocation": [
            30.4333014,
            -91.11414430000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 37,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.0,
                    "duration": "indefinitely",
                    "model_conf": 199.33
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 33,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 33.47,
                    "duration": "indefinitely",
                    "model_conf": 152.8
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 60,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 59.67,
                    "duration": "2 Months",
                    "model_conf": 118.03
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 78,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 77.72,
                    "duration": "1 Months",
                    "model_conf": 154.5
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 132,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 131.65,
                    "duration": "1 Months",
                    "model_conf": 124.01
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.86,
                    "duration": "indefinitely",
                    "model_conf": 133.69
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 48,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 48.7,
                    "duration": "indefinitely",
                    "model_conf": 173.82
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 63.56,
                    "duration": "8 Months",
                    "model_conf": 294.14
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.66,
                    "duration": "11 Months",
                    "model_conf": 125.12
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.46,
                    "duration": "indefinitely",
                    "model_conf": 89.69
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.41,
                    "duration": "indefinitely",
                    "model_conf": 108.64
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.21,
                    "duration": "indefinitely",
                    "model_conf": 24.84
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.5,
        "coming_soon": false
    },
    "Omaha West": {
        "name": "Dogtopia: Omaha West",
        "address": "14485 West Center Road, Omaha, Nebraska 68144",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(402) 204-3556",
        "email": "omahawest@dogtopia.com",
        "url": "https://www.dogtopia.com/omaha-west",
        "geolocation": [
            41.23228543477736,
            -96.14208268545838
        ],
        "services": {},
        "memberships": {
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 28,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 25.59,
                    "duration": "indefinitely",
                    "model_conf": 104.86
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 16,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 17.89,
                    "duration": "indefinitely",
                    "model_conf": 254.04
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.26,
                    "duration": "indefinitely",
                    "model_conf": 173.09
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 25,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.22,
                    "duration": "indefinitely",
                    "model_conf": 444.67
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.4,
        "coming_soon": false
    },
    "Galleria": {
        "name": "Dogtopia: Galleria",
        "address": "6240 Westheimer Road, Houston, Texas 77057",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "((34) ) 2-9-27",
        "email": "galleria@dogtopia.com",
        "url": "https://www.dogtopia.com/houston-galleria",
        "geolocation": [
            29.7380379,
            -95.4941123
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 37,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 35.96,
                    "duration": "11 Months",
                    "model_conf": 200.45
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 175,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 175.29,
                    "duration": "indefinitely",
                    "model_conf": 140.74
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 335,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 334.96,
                    "duration": "indefinitely",
                    "model_conf": 183.47
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 32,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 30.12,
                    "duration": "indefinitely",
                    "model_conf": 279.02
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 61,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 61.66,
                    "duration": "indefinitely",
                    "model_conf": 131.91
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 78,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.5,
                    "duration": "indefinitely",
                    "model_conf": 179.27
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 78,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 77.89,
                    "duration": "indefinitely",
                    "model_conf": 439.19
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 11,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.93,
                    "duration": "indefinitely",
                    "model_conf": 144.82
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 11,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.74,
                    "duration": "indefinitely",
                    "model_conf": 91.6
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 16,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.48,
                    "duration": "12 Months",
                    "model_conf": 126.56
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.275,
        "coming_soon": false
    },
    "Midlothian": {
        "name": "Dogtopia: Midlothian",
        "address": "13615 Hull Street Road, Midlothian, Virginia 23112",
        "hours": {
            "Monday": "6:30 AM - 7:30 PM",
            "Tuesday": "6:30 AM - 7:30 PM",
            "Wednesday": "6:30 AM - 7:30 PM",
            "Thursday": "6:30 AM - 7:30 PM",
            "Friday": "6:30 AM - 7:30 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(804) 482-8099",
        "email": "midlothian@dogtopia.com",
        "url": "https://www.dogtopia.com/midlothian",
        "geolocation": [
            37.4084423,
            -77.65322579999997
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.76,
                    "duration": "indefinitely",
                    "model_conf": 203.71
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 37.56,
                    "duration": "indefinitely",
                    "model_conf": 494.68
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 67.0,
                    "duration": "5 Months",
                    "model_conf": 169.38
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 97.78,
                    "duration": "4 Months",
                    "model_conf": 231.85
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 138.4,
                    "duration": "4 Months",
                    "model_conf": 385.44
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 56,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 57.13,
                    "duration": "indefinitely",
                    "model_conf": 258.98
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.84,
                    "duration": "indefinitely",
                    "model_conf": 205.58
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.76,
                    "duration": "indefinitely",
                    "model_conf": 99.38
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.31,
                    "duration": "indefinitely",
                    "model_conf": 172.11
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.275,
        "coming_soon": false
    },
    "Lexington South": {
        "name": "Dogtopia: Lexington South",
        "address": "3801 Nicholasville Centre Drive, Lexington, Kentucky 40503",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(859) 755-4777",
        "email": "lexingtonsouth@dogtopia.com",
        "url": "https://www.dogtopia.com/lexington-south",
        "geolocation": [
            37.98372854481511,
            -84.5283824465576
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 31,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 27.77,
                    "duration": "indefinitely",
                    "model_conf": 151.78
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 30,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 28.7,
                    "duration": "indefinitely",
                    "model_conf": 195.39
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 29,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 30.34,
                    "duration": "indefinitely",
                    "model_conf": 162.65
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 224,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 222.57,
                    "duration": "2 Months",
                    "model_conf": 108.31
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 520,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 522.85,
                    "duration": "indefinitely",
                    "model_conf": 253.26
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 64,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 76.36,
                    "duration": "indefinitely",
                    "model_conf": 624.4
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.7,
        "coming_soon": false
    },
    "Bloomington": {
        "name": "Dogtopia: Bloomington",
        "address": "5208 W 84th St, Bloomington, Minnesota 55437",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(612) 814-0616",
        "email": "bloomington@dogtopia.com",
        "url": "https://www.dogtopia.com/bloomington",
        "geolocation": [
            44.85176389999999,
            -93.34708760000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.43,
                    "duration": "indefinitely",
                    "model_conf": 201.31
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 500,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 499.65,
                    "duration": "indefinitely",
                    "model_conf": 220.29
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.31,
                    "duration": "3 Months",
                    "model_conf": 686.03
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 5.21,
                    "duration": "indefinitely",
                    "model_conf": 198.5
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.02,
                    "duration": "indefinitely",
                    "model_conf": 98.17
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 21.17,
                    "duration": "indefinitely",
                    "model_conf": 172.33
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.075,
        "coming_soon": false
    },
    "Louisville Hurstbourne": {
        "name": "Dogtopia: Louisville Hurstbourne",
        "address": "221 South Hurstbourne Parkway Suite 101, Louisville, Kentucky 40222",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(502) 554-9661",
        "email": "hurstbourne@dogtopia.com",
        "url": "https://www.dogtopia.com/louisville-hurstbourne",
        "geolocation": [
            38.24197950000001,
            -85.57622609999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 32,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 30.97,
                    "duration": "12 Months",
                    "model_conf": 204.14
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 30,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 29.46,
                    "duration": "7 Months",
                    "model_conf": 230.21
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 28,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 26.38,
                    "duration": "indefinitely",
                    "model_conf": 157.74
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 53.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 53.47,
                    "duration": "indefinitely",
                    "model_conf": 163.84
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 77.86,
                    "duration": "indefinitely",
                    "model_conf": 237.63
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 121.15,
                    "duration": "indefinitely",
                    "model_conf": 387.26
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 54,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.1,
                    "duration": "indefinitely",
                    "model_conf": 264.91
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 48.6,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 46.98,
                    "duration": "indefinitely",
                    "model_conf": 191.88
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 57.6,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 57.41,
                    "duration": "1 Months",
                    "model_conf": 75.33
                }
            }
        },
        "brand": "Dogtopia",
        "index": 92.575,
        "coming_soon": false
    },
    "Highland Village": {
        "name": "Dogtopia: Highland Village",
        "address": "1830 FM 407, Highland Village, Texas 75077",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(972) 264-1178",
        "email": "highlandvillage@dogtopia.com",
        "url": "https://www.dogtopia.com/highland-village",
        "geolocation": [
            33.0724376,
            -97.04488409999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 29,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 28.56,
                    "duration": "5 Months",
                    "model_conf": 227.8
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 28,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 27.89,
                    "duration": "1 Months",
                    "model_conf": 196.56
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 27,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 26.31,
                    "duration": "10 Months",
                    "model_conf": 163.64
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 50,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 51.52,
                    "duration": "indefinitely",
                    "model_conf": 254.4
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 62.85,
                    "duration": "indefinitely",
                    "model_conf": 690.44
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.75,
                    "duration": "9 Months",
                    "model_conf": 98.45
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.96,
                    "duration": "1 Months",
                    "model_conf": 165.56
                }
            }
        },
        "brand": "Dogtopia",
        "index": 94.525,
        "coming_soon": false
    },
    "Gainesville": {
        "name": "Dogtopia: Gainesville",
        "address": "5615 Wellington Road, #121, Gainesville, Virginia 20155",
        "hours": {
            "Monday": "5:30 AM - 7:00 PM",
            "Tuesday": "5:30 AM - 7:00 PM",
            "Wednesday": "5:30 AM - 7:00 PM",
            "Thursday": "5:30 AM - 7:00 PM",
            "Friday": "5:30 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(703) 483-9444",
        "email": "gainesville@dogtopia.com",
        "url": "https://www.dogtopia.com/gainesville",
        "geolocation": [
            38.7929736,
            -77.59357360000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.2,
                    "duration": "indefinitely",
                    "model_conf": 202.25
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 42.93,
                    "duration": "indefinitely",
                    "model_conf": 479.02
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 75.79,
                    "duration": "3 Months",
                    "model_conf": 150.6
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 106.42,
                    "duration": "5 Months",
                    "model_conf": 228.76
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 183.81,
                    "duration": "2 Months",
                    "model_conf": 370.69
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.29,
                    "duration": "9 Months",
                    "model_conf": 190.52
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.25,
        "coming_soon": false
    },
    "Raintree": {
        "name": "Dogtopia: Raintree",
        "address": "14747 North Northsight Blvd, Scottsdale, Arizona 85260",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(480) 494-8833",
        "email": "raintree@dogtopia.com",
        "url": "https://www.dogtopia.com/scottsdale-raintree",
        "geolocation": [
            33.61902328320639,
            -111.89623714841002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.56,
                    "duration": "3 Months",
                    "model_conf": 199.67
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 41,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 42.21,
                    "duration": "indefinitely",
                    "model_conf": 329.43
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 75.61,
                    "duration": "1 Months",
                    "model_conf": 134.25
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 106.65,
                    "duration": "4 Months",
                    "model_conf": 183.88
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 139.79,
                    "duration": "indefinitely",
                    "model_conf": 262.15
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 80,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 80.94,
                    "duration": "indefinitely",
                    "model_conf": 183.77
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.7,
                    "duration": "1 Months",
                    "model_conf": 185.43
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 74.97,
                    "duration": "indefinitely",
                    "model_conf": 73.72
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.8,
        "coming_soon": false
    },
    "New Rochelle": {
        "name": "Dogtopia: New Rochelle",
        "address": "505 5th Avenue, New Rochelle, New York 10801",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(914) 908-4707",
        "email": "newrochelle@dogtopia.com",
        "url": "https://www.dogtopia.com/new-rochelle",
        "geolocation": [
            40.929264,
            -73.77054799999996
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 48,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 49.15,
                    "duration": "indefinitely",
                    "model_conf": 202.19
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 40.83,
                    "duration": "indefinitely",
                    "model_conf": 425.37
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.86,
                    "duration": "indefinitely",
                    "model_conf": 137.44
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 120,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 121.86,
                    "duration": "indefinitely",
                    "model_conf": 193.28
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 201.06,
                    "duration": "indefinitely",
                    "model_conf": 231.64
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 32,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 31.61,
                    "duration": "4 Months",
                    "model_conf": 172.02
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 50,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 57.82,
                    "duration": "indefinitely",
                    "model_conf": 446.51
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.58,
                    "duration": "indefinitely",
                    "model_conf": 135.86
                }
            }
        },
        "brand": "Dogtopia",
        "index": 103.6,
        "coming_soon": false
    },
    "Birmingham": {
        "name": "Dogtopia: Birmingham",
        "address": "2300 Cole Street, Birmingham, Michigan 48009",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(248) 897-0280",
        "email": "birmingham@dogtopia.com",
        "url": "https://www.dogtopia.com/birmingham",
        "geolocation": [
            42.539985,
            -83.192184
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 36,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 36.4,
                    "duration": "indefinitely",
                    "model_conf": 204.07
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 175,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 175.96,
                    "duration": "indefinitely",
                    "model_conf": 169.45
                }
            },
            "DAYCARE (10 Day Pass)": {
                "cost": 330,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 329.83,
                    "duration": "indefinitely",
                    "model_conf": 188.63
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 620,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 621.2,
                    "duration": "indefinitely",
                    "model_conf": 161.47
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 68.85,
                    "duration": "6 Months",
                    "model_conf": 609.61
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.97,
                    "duration": "1 Months",
                    "model_conf": 188.97
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 20.04,
                    "duration": "indefinitely",
                    "model_conf": 154.83
                }
            }
        },
        "brand": "Dogtopia",
        "index": 103.225,
        "coming_soon": false
    },
    "Commerce Township": {
        "name": "Dogtopia: Commerce Township",
        "address": "3050 Union Lake Road #7a, Commerce, Michigan 48382",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "((24) ) 3-6-30",
        "email": "commerce@dogtopia.com",
        "url": "https://www.dogtopia.com/commerce",
        "geolocation": [
            42.5907501,
            -83.44741899999997
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 33,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 32.46,
                    "duration": "6 Months",
                    "model_conf": 318.98
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 58,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 57.79,
                    "duration": "1 Months",
                    "model_conf": 148.86
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.1,
                    "duration": "indefinitely",
                    "model_conf": 199.46
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 125,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 126.28,
                    "duration": "indefinitely",
                    "model_conf": 230.81
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 63.46,
                    "duration": "9 Months",
                    "model_conf": 179.6
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.28,
                    "duration": "5 Months",
                    "model_conf": 177.29
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 75.13,
                    "duration": "indefinitely",
                    "model_conf": 506.51
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.83,
                    "duration": "1 Months",
                    "model_conf": 74.04
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.675,
        "coming_soon": false
    },
    "Utica": {
        "name": "Dogtopia: Utica",
        "address": "45509 Schoenherr Rd, Utica, Michigan 48315",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "((58) ) 2-4-18",
        "email": "utica@dogtopia.com",
        "url": "https://www.dogtopia.com/utica",
        "geolocation": [
            42.6302021,
            -82.99341709999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 58,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 57.87,
                    "duration": "indefinitely",
                    "model_conf": 168.74
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.08,
                    "duration": "indefinitely",
                    "model_conf": 236.41
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 125,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 124.61,
                    "duration": "1 Months",
                    "model_conf": 281.99
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 60.46,
                    "duration": "indefinitely",
                    "model_conf": 205.54
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 50,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 48.93,
                    "duration": "8 Months",
                    "model_conf": 185.13
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 70.71,
                    "duration": "indefinitely",
                    "model_conf": 673.34
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 59.32,
                    "duration": "4 Months",
                    "model_conf": 75.22
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.34,
                    "duration": "indefinitely",
                    "model_conf": 199.63
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.96,
                    "duration": "1 Months",
                    "model_conf": 90.82
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.73,
                    "duration": "10 Months",
                    "model_conf": 170.59
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.85,
        "coming_soon": false
    },
    "Bloomfield Hills": {
        "name": "Dogtopia: Bloomfield Hills",
        "address": "1685 S Telegraph Road, Bloomfield Hills, Michigan 48302",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(248) 897-0299",
        "email": "bloomfield@dogtopia.com",
        "url": "https://www.dogtopia.com/bloomfield",
        "geolocation": [
            42.6158179,
            -83.31208629999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 34,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 34.44,
                    "duration": "indefinitely",
                    "model_conf": 320.59
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 64,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 64.07,
                    "duration": "indefinitely",
                    "model_conf": 147.03
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 90,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 91.19,
                    "duration": "indefinitely",
                    "model_conf": 192.26
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 135,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 134.96,
                    "duration": "indefinitely",
                    "model_conf": 254.23
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 59.47,
                    "duration": "3 Months",
                    "model_conf": 181.12
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 74.96,
                    "duration": "indefinitely",
                    "model_conf": 497.49
                }
            },
            "DOG BOARDING (Suite Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.13,
                    "duration": "5 Months",
                    "model_conf": 74.02
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.5,
                    "duration": "indefinitely",
                    "model_conf": 159.08
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.84,
                    "duration": "6 Months",
                    "model_conf": 91.05
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.01,
                    "duration": "indefinitely",
                    "model_conf": 132.58
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.525,
        "coming_soon": false
    },
    "Miramar": {
        "name": "Dogtopia: Miramar",
        "address": "5260 Eastgate Mall, San Diego, California 92121",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((85) ) 2-5-70",
        "email": "miramar@dogtopia.com",
        "url": "https://www.dogtopia.com/san-diego-miramar",
        "geolocation": [
            32.8825522,
            -117.1945748
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 76.85,
                    "duration": "indefinitely",
                    "model_conf": 142.51
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 100,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 101.39,
                    "duration": "indefinitely",
                    "model_conf": 215.48
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 199,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 198.25,
                    "duration": "1 Months",
                    "model_conf": 197.39
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 75.05,
                    "duration": "indefinitely",
                    "model_conf": 161.61
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 28.0,
                    "duration": "indefinitely",
                    "model_conf": 573.38
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.04,
                    "duration": "indefinitely",
                    "model_conf": 401.78
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 21.06,
                    "duration": "indefinitely",
                    "model_conf": 139.94
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.8,
        "coming_soon": false
    },
    "Anaheim Hills": {
        "name": "Dogtopia: Anaheim Hills",
        "address": "4570 E. Eisenhower Circle, Anaheim, California 92807",
        "hours": {
            "Monday": "6:30 AM - 6:00 PM",
            "Tuesday": "6:30 AM - 6:00 PM",
            "Wednesday": "6:30 AM - 6:00 PM",
            "Thursday": "6:30 AM - 6:00 PM",
            "Friday": "6:30 AM - 6:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(714) 777-3647",
        "email": "anaheimhills@dogtopia.com",
        "url": "https://www.dogtopia.com/anaheim-hills",
        "geolocation": [
            33.864979,
            -117.81651899999997
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 49,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 46.44,
                    "duration": "indefinitely",
                    "model_conf": 198.67
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 44,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 43.76,
                    "duration": "2 Months",
                    "model_conf": 317.65
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 78,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.36,
                    "duration": "indefinitely",
                    "model_conf": 166.13
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 111,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 110.42,
                    "duration": "2 Months",
                    "model_conf": 191.58
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 175,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 175.34,
                    "duration": "indefinitely",
                    "model_conf": 286.9
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 25,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 24.78,
                    "duration": "3 Months",
                    "model_conf": 207.38
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.79,
                    "duration": "indefinitely",
                    "model_conf": 143.64
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 16.01,
                    "duration": "indefinitely",
                    "model_conf": 99.75
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 13.81,
                    "duration": "indefinitely",
                    "model_conf": 159.85
                }
            }
        },
        "brand": "Dogtopia",
        "index": 105.175,
        "coming_soon": false
    },
    "Austin North": {
        "name": "Dogtopia: Austin North",
        "address": "13945 North Highway 183, Austin, Texas 78717",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:30 AM - 5:00 PM"
        },
        "phone": "((51) ) 3-3-11",
        "email": "northaustin@dogtopia.com",
        "url": "https://www.dogtopia.com/austin-north",
        "geolocation": [
            30.4722997,
            -97.79670950000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 40.86,
                    "duration": "indefinitely",
                    "model_conf": 202.4
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 190,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 188.66,
                    "duration": "2 Months",
                    "model_conf": 198.95
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 62,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 61.97,
                    "duration": "indefinitely",
                    "model_conf": 144.89
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 84.11,
                    "duration": "indefinitely",
                    "model_conf": 241.95
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 125,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 125.45,
                    "duration": "indefinitely",
                    "model_conf": 345.29
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 69,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 68.68,
                    "duration": "1 Months",
                    "model_conf": 235.67
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 49,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 48.38,
                    "duration": "4 Months",
                    "model_conf": 187.52
                }
            },
            "SPA & GROOMING (Blueberry Facial)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 4.4,
                    "duration": "indefinitely",
                    "model_conf": 421.08
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.51,
                    "duration": "indefinitely",
                    "model_conf": 189.08
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.03,
                    "duration": "indefinitely",
                    "model_conf": 99.0
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.68,
                    "duration": "6 Months",
                    "model_conf": 163.44
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.575,
        "coming_soon": false
    },
    "Lafayette": {
        "name": "Dogtopia: Lafayette",
        "address": "300 W South Boulder Rd, Lafayette, Colorado 80026",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "((72) ) 2-3-45",
        "email": "Lafayette@dogtopia.com",
        "url": "https://www.dogtopia.com/lafayette",
        "geolocation": [
            39.9867998,
            -105.0930912
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.53,
                    "duration": "indefinitely",
                    "model_conf": 202.17
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 46.15,
                    "duration": "indefinitely",
                    "model_conf": 480.97
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 64,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 60.97,
                    "duration": "indefinitely",
                    "model_conf": 162.61
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 87,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 82.54,
                    "duration": "indefinitely",
                    "model_conf": 230.09
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 132.25,
                    "duration": "indefinitely",
                    "model_conf": 356.33
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 80.16,
                    "duration": "indefinitely",
                    "model_conf": 257.94
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 60.59,
                    "duration": "indefinitely",
                    "model_conf": 191.56
                }
            }
        },
        "brand": "Dogtopia",
        "index": 99.325,
        "coming_soon": false
    },
    "Madison West": {
        "name": "Dogtopia: Madison West",
        "address": "3231 Laura Lane, Middleton, Wisconsin 53562",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((60) ) 4-6-33",
        "email": "madisonwest@dogtopia.com",
        "url": "https://www.dogtopia.com/middleton-madison-west",
        "geolocation": [
            43.110881,
            -89.51408200000003
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.49,
                    "duration": "indefinitely",
                    "model_conf": 203.95
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 132,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 134.31,
                    "duration": "indefinitely",
                    "model_conf": 370.03
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 35,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 35.35,
                    "duration": "indefinitely",
                    "model_conf": 192.43
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 3.63,
                    "duration": "indefinitely",
                    "model_conf": 206.8
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 20,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 19.68,
                    "duration": "6 Months",
                    "model_conf": 171.65
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.25,
        "coming_soon": false
    },
    "Highlands Ranch": {
        "name": "Dogtopia: Highlands Ranch",
        "address": "6448 E County Line Rd, Highlands Ranch, Colorado 80126",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "((30) ) 8-5-45",
        "email": "highlandsranch@dogtopia.com",
        "url": "https://www.dogtopia.com/highlands-ranch",
        "geolocation": [
            39.5654321,
            -104.91426260000003
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.37,
                    "duration": "indefinitely",
                    "model_conf": 197.32
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 40.97,
                    "duration": "indefinitely",
                    "model_conf": 339.44
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 77,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 75.69,
                    "duration": "6 Months",
                    "model_conf": 134.88
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 105.4,
                    "duration": "9 Months",
                    "model_conf": 188.87
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 220,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 219.73,
                    "duration": "indefinitely",
                    "model_conf": 246.44
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 76.02,
                    "duration": "indefinitely",
                    "model_conf": 194.24
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.89,
                    "duration": "indefinitely",
                    "model_conf": 154.3
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.98,
                    "duration": "indefinitely",
                    "model_conf": 96.91
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.94,
                    "duration": "2 Months",
                    "model_conf": 137.57
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.625,
        "coming_soon": false
    },
    "Omaha": {
        "name": "Dogtopia: Omaha",
        "address": "10411 Portal Rd #111, La Vista, Nebraska 68128",
        "hours": {
            "Monday": "6:30 AM - 7:00 PM",
            "Tuesday": "6:30 AM - 7:00 PM",
            "Wednesday": "6:30 AM - 7:00 PM",
            "Thursday": "6:30 AM - 7:00 PM",
            "Friday": "6:30 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "((40) ) 8-7-29",
        "email": "papillion@dogtopia.com",
        "url": "https://www.dogtopia.com/omaha",
        "geolocation": [
            41.171989432193506,
            -96.0762885227539
        ],
        "services": {},
        "memberships": {
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 28,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 29.65,
                    "duration": "indefinitely",
                    "model_conf": 106.47
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 16,
                "per": "day",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 16.57,
                    "duration": "indefinitely",
                    "model_conf": 254.15
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.65,
                    "duration": "indefinitely",
                    "model_conf": 171.73
                }
            },
            "SPA & GROOMING (Deshed)": {
                "cost": 25,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 27.06,
                    "duration": "indefinitely",
                    "model_conf": 448.76
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.4,
        "coming_soon": false
    },
    "Austin": {
        "name": "Dogtopia: Austin",
        "address": "2105 RR 620 South, Suite 101, Austin, Texas 78734",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "(512) 298-6817",
        "email": "austin@dogtopia.com",
        "url": "https://www.dogtopia.com/austin",
        "geolocation": [
            30.342227147910673,
            -97.96577177486876
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.3,
                    "duration": "7 Months",
                    "model_conf": 204.02
                }
            },
            "DAYCARE (5 Day Pass)": {
                "cost": 180,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 179.72,
                    "duration": "indefinitely",
                    "model_conf": 229.66
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 640,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 642.53,
                    "duration": "indefinitely",
                    "model_conf": 163.53
                }
            },
            "DAYCARE (BFF Deal 8 Days a month)": {
                "cost": 30,
                "per": "month",
                "term": "1-month",
                "description": "",
                "suggested": {
                    "cost": 29.95,
                    "duration": "indefinitely",
                    "model_conf": 108.82
                }
            },
            "DAYCARE (Top Dog Membership)": {
                "cost": 26,
                "per": "month",
                "term": "1-month",
                "description": "Top Dog (Unlimited days per month)",
                "suggested": {
                    "cost": 26.35,
                    "duration": "indefinitely",
                    "model_conf": 250.91
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 8,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 7.38,
                    "duration": "indefinitely",
                    "model_conf": 205.94
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 18,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 18.09,
                    "duration": "indefinitely",
                    "model_conf": 63.24
                }
            }
        },
        "brand": "Dogtopia",
        "index": 96.625,
        "coming_soon": false
    },
    "San Marcos": {
        "name": "Dogtopia: San Marcos",
        "address": "925 W. San Marcos Blvd, San Marcos, California 92078",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(760) 659-3298",
        "email": "sanmarcos@dogtopia.com",
        "url": "https://www.dogtopia.com/san-marcos",
        "geolocation": [
            33.13490329999999,
            -117.1854318
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 43,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.0,
                    "duration": "indefinitely",
                    "model_conf": 199.53
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 70.9,
                    "duration": "5 Months",
                    "model_conf": 143.97
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 102,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 98.83,
                    "duration": "12 Months",
                    "model_conf": 244.02
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 160,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 156.28,
                    "duration": "9 Months",
                    "model_conf": 387.89
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 73,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 77.07,
                    "duration": "indefinitely",
                    "model_conf": 245.81
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 65.92,
                    "duration": "indefinitely",
                    "model_conf": 191.62
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.85,
        "coming_soon": false
    },
    "Temecula": {
        "name": "Dogtopia: Temecula",
        "address": "27629 Commerce Center Dr, Temecula, California 92590",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "(951) 587-2882",
        "email": "temecula@dogtopia.com",
        "url": "https://www.dogtopia.com/temecula",
        "geolocation": [
            33.5113123,
            -117.1624081
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 39,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.31,
                    "duration": "6 Months",
                    "model_conf": 203.55
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 66,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 66.91,
                    "duration": "indefinitely",
                    "model_conf": 159.97
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 93.6,
                    "duration": "indefinitely",
                    "model_conf": 245.35
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 145,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 147.61,
                    "duration": "indefinitely",
                    "model_conf": 385.42
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 66,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 64.7,
                    "duration": "7 Months",
                    "model_conf": 262.33
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 61,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 61.27,
                    "duration": "indefinitely",
                    "model_conf": 191.3
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.95,
        "coming_soon": false
    },
    "Waco": {
        "name": "Dogtopia: Waco",
        "address": "5301 Bosque Blvd, Ste 300, Waco, Texas 76710",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((25) ) 6-3-28",
        "email": "waco@dogtopia.com",
        "url": "https://www.dogtopia.com/waco",
        "geolocation": [
            31.5336357,
            -97.19468919999997
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 31,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 29.97,
                    "duration": "indefinitely",
                    "model_conf": 472.83
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 58,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 56.89,
                    "duration": "7 Months",
                    "model_conf": 157.69
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 81,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 80.0,
                    "duration": "4 Months",
                    "model_conf": 241.02
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 125,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 125.69,
                    "duration": "indefinitely",
                    "model_conf": 371.45
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 49,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 47.86,
                    "duration": "9 Months",
                    "model_conf": 264.2
                }
            }
        },
        "brand": "Dogtopia",
        "index": 91.0,
        "coming_soon": false
    },
    "Herndon": {
        "name": "Dogtopia: Herndon",
        "address": "720 Jackson Street, Herndon, Virginia 20170",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "((70) ) 4-5-25",
        "email": "herndon@dogtopia.com",
        "url": "https://www.dogtopia.com/herndon",
        "geolocation": [
            38.9697419,
            -77.381234
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.59,
                    "duration": "3 Months",
                    "model_conf": 200.64
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 39.5,
                    "duration": "9 Months",
                    "model_conf": 423.81
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 77.85,
                    "duration": "indefinitely",
                    "model_conf": 150.25
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 109.38,
                    "duration": "indefinitely",
                    "model_conf": 208.47
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 185.48,
                    "duration": "indefinitely",
                    "model_conf": 325.21
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.99,
                    "duration": "indefinitely",
                    "model_conf": 185.59
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.11,
                    "duration": "indefinitely",
                    "model_conf": 172.82
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.45,
                    "duration": "indefinitely",
                    "model_conf": 97.08
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.97,
                    "duration": "indefinitely",
                    "model_conf": 150.13
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.075,
        "coming_soon": false
    },
    "Tysons Corner": {
        "name": "Dogtopia: Tysons Corner",
        "address": "1524 Spring Hill Road, Suite EE, McLean, Virginia 22102",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(703) 278-2135",
        "email": "tysonscorner@dogtopia.com",
        "url": "https://www.dogtopia.com/mclean-tysons-corner",
        "geolocation": [
            38.930197,
            -77.23665349999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 43.71,
                    "duration": "11 Months",
                    "model_conf": 200.71
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 44.17,
                    "duration": "indefinitely",
                    "model_conf": 337.26
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 77.16,
                    "duration": "indefinitely",
                    "model_conf": 140.07
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 106.07,
                    "duration": "6 Months",
                    "model_conf": 183.74
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 182.08,
                    "duration": "6 Months",
                    "model_conf": 234.88
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 30.82,
                    "duration": "indefinitely",
                    "model_conf": 179.59
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 39.08,
                    "duration": "indefinitely",
                    "model_conf": 480.76
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.76,
                    "duration": "indefinitely",
                    "model_conf": 154.25
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.54,
                    "duration": "indefinitely",
                    "model_conf": 92.98
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.3,
                    "duration": "indefinitely",
                    "model_conf": 126.74
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.225,
        "coming_soon": false
    },
    "Fredericksburg": {
        "name": "Dogtopia: Fredericksburg",
        "address": "4272 Plank Road, Fredericksburg, Virginia 22407",
        "hours": {
            "Monday": "6:00 AM - 7:00 PM",
            "Tuesday": "6:00 AM - 7:00 PM",
            "Wednesday": "6:00 AM - 7:00 PM",
            "Thursday": "6:00 AM - 7:00 PM",
            "Friday": "6:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "((54) ) 2-2-73",
        "email": "fredericksburg@dogtopia.com",
        "url": "https://www.dogtopia.com/fredericksburg",
        "geolocation": [
            38.28891309999999,
            -77.53300230000002
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 43,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 43.13,
                    "duration": "indefinitely",
                    "model_conf": 203.92
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.48,
                    "duration": "indefinitely",
                    "model_conf": 164.99
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 102,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 103.54,
                    "duration": "indefinitely",
                    "model_conf": 245.83
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 155,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 155.79,
                    "duration": "indefinitely",
                    "model_conf": 391.22
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 69.7,
                    "duration": "1 Months",
                    "model_conf": 248.55
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 59.57,
                    "duration": "2 Months",
                    "model_conf": 191.26
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.1,
        "coming_soon": false
    },
    "Springfield": {
        "name": "Dogtopia: Springfield",
        "address": "7401 Fullerton Road, Suite A, Springfield, Virginia 22153",
        "hours": {
            "Monday": "6:00 AM - 7:00 PM",
            "Tuesday": "6:00 AM - 7:00 PM",
            "Wednesday": "6:00 AM - 7:00 PM",
            "Thursday": "6:00 AM - 7:00 PM",
            "Friday": "6:00 AM - 7:00 PM",
            "Saturday": "9:00 AM - 5:00 PM"
        },
        "phone": "((70) ) 9-2-00",
        "email": "springfield@dogtopia.com",
        "url": "https://www.dogtopia.com/springfield",
        "geolocation": [
            38.741553,
            -77.200827
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 43,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 43.37,
                    "duration": "indefinitely",
                    "model_conf": 202.2
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 39,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 48.42,
                    "duration": "indefinitely",
                    "model_conf": 421.85
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 70.35,
                    "duration": "8 Months",
                    "model_conf": 160.56
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 102,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 99.02,
                    "duration": "11 Months",
                    "model_conf": 208.9
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 155,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 148.53,
                    "duration": "indefinitely",
                    "model_conf": 274.23
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 74.0,
                    "duration": "indefinitely",
                    "model_conf": 245.82
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 61.71,
                    "duration": "indefinitely",
                    "model_conf": 188.56
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.8,
        "coming_soon": false
    },
    "Chesterfield": {
        "name": "Dogtopia: Chesterfield",
        "address": "11004 Midlothian Turnpike, Richmond, Virginia 23235",
        "hours": {
            "Monday": "6:30 AM - 7:30 PM",
            "Tuesday": "6:30 AM - 7:30 PM",
            "Wednesday": "6:30 AM - 7:30 PM",
            "Thursday": "6:30 AM - 7:30 PM",
            "Friday": "6:30 AM - 7:30 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((80) ) 3-3-94",
        "email": "chesterfield@dogtopia.com",
        "url": "https://www.dogtopia.com/richmond-chesterfield",
        "geolocation": [
            37.5075001,
            -77.6005778
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 40,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 38.88,
                    "duration": "10 Months",
                    "model_conf": 202.9
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 36,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 34.43,
                    "duration": "indefinitely",
                    "model_conf": 474.62
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 68,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 68.6,
                    "duration": "indefinitely",
                    "model_conf": 161.93
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 99.61,
                    "duration": "indefinitely",
                    "model_conf": 232.77
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 140,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 141.86,
                    "duration": "indefinitely",
                    "model_conf": 359.72
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 66,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 65.8,
                    "duration": "1 Months",
                    "model_conf": 247.39
                }
            }
        },
        "brand": "Dogtopia",
        "index": 97.975,
        "coming_soon": false
    },
    "Woodbridge": {
        "name": "Dogtopia: Woodbridge",
        "address": "14885 Persistence Drive, Woodbridge, Virginia 22191",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(703) 278-2045",
        "email": "woodbridge@dogtopia.com",
        "url": "https://www.dogtopia.com/woodbridge",
        "geolocation": [
            38.6275407,
            -77.252929
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 43.55,
                    "duration": "12 Months",
                    "model_conf": 202.63
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.42,
                    "duration": "indefinitely",
                    "model_conf": 475.33
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.17,
                    "duration": "indefinitely",
                    "model_conf": 163.83
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 110.24,
                    "duration": "indefinitely",
                    "model_conf": 228.49
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 187.46,
                    "duration": "indefinitely",
                    "model_conf": 321.55
                }
            }
        },
        "brand": "Dogtopia",
        "index": 104.5,
        "coming_soon": false
    },
    "Canton": {
        "name": "Dogtopia: Canton",
        "address": "2706 O'Donnell Street, Baltimore, Maryland 21224",
        "hours": {
            "Monday": "6:00 AM - 8:00 PM",
            "Tuesday": "6:00 AM - 8:00 PM",
            "Wednesday": "6:00 AM - 8:00 PM",
            "Thursday": "6:00 AM - 8:00 PM",
            "Friday": "6:00 AM - 8:00 PM",
            "Saturday": "8:00 AM - 6:00 PM"
        },
        "phone": "((24) ) 2-2-55",
        "email": "canton@dogtopia.com",
        "url": "https://www.dogtopia.com/baltimore-canton",
        "geolocation": [
            39.2802563,
            -76.57799560000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 46,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 45.74,
                    "duration": "2 Months",
                    "model_conf": 441.75
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 84,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 82.9,
                    "duration": "5 Months",
                    "model_conf": 157.47
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 117,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 116.57,
                    "duration": "1 Months",
                    "model_conf": 231.54
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 170,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 169.7,
                    "duration": "indefinitely",
                    "model_conf": 369.91
                }
            }
        },
        "brand": "Dogtopia",
        "index": 108.325,
        "coming_soon": false
    },
    "Clarksville": {
        "name": "Dogtopia: Clarksville",
        "address": "12212 Clarksville Pike, Clarksville, Maryland 21029",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((41) ) 6-0-84",
        "email": "clarksville@dogtopia.com",
        "url": "https://www.dogtopia.com/clarksville",
        "geolocation": [
            39.212509,
            -76.93742299999997
        ],
        "services": {},
        "memberships": {
            "DAYCARE (5 Day Pass)": {
                "cost": 37,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 35.67,
                    "duration": "indefinitely",
                    "model_conf": 233.53
                }
            },
            "DAYCARE (20 Day Pass)": {
                "cost": 35,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 35.03,
                    "duration": "indefinitely",
                    "model_conf": 164.1
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 26,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 25.61,
                    "duration": "5 Months",
                    "model_conf": 264.85
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.65,
                    "duration": "indefinitely",
                    "model_conf": 207.77
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 7,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 6.16,
                    "duration": "indefinitely",
                    "model_conf": 100.02
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 12,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.05,
                    "duration": "indefinitely",
                    "model_conf": 173.02
                }
            },
            "SPA & GROOMING (Nail Grind)": {
                "cost": 25,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 25.0,
                    "duration": "indefinitely",
                    "model_conf": 64.84
                }
            }
        },
        "brand": "Dogtopia",
        "index": 93.925,
        "coming_soon": false
    },
    "Westchase": {
        "name": "Dogtopia: Westchase",
        "address": "11238 W. Hillsborough Ave, Tampa, Florida 33635",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((81) ) 8-4-03",
        "email": "westchase@dogtopia.com",
        "url": "https://www.dogtopia.com/tampa-westchase",
        "geolocation": [
            28.009644,
            -82.6096189
        ],
        "services": {},
        "memberships": {
            "DAYCARE (2 Days a Week)": {
                "cost": 70,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 70.49,
                    "duration": "indefinitely",
                    "model_conf": 169.55
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 93.58,
                    "duration": "indefinitely",
                    "model_conf": 245.1
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 135,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 135.72,
                    "duration": "indefinitely",
                    "model_conf": 391.19
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 65.83,
                    "duration": "indefinitely",
                    "model_conf": 256.09
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 55,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 54.26,
                    "duration": "5 Months",
                    "model_conf": 190.65
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 66.1,
                    "duration": "indefinitely",
                    "model_conf": 625.05
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.71,
                    "duration": "indefinitely",
                    "model_conf": 182.41
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.73,
                    "duration": "indefinitely",
                    "model_conf": 99.29
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.13,
                    "duration": "indefinitely",
                    "model_conf": 173.3
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.9,
        "coming_soon": false
    },
    "Wilmington": {
        "name": "Dogtopia: Wilmington",
        "address": "2101-A Concord Pike, Wilmington, Delaware 19803",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "(302) 414-0561",
        "email": "wilmington@dogtopia.com",
        "url": "https://www.dogtopia.com/wilmington",
        "geolocation": [
            39.7895713,
            -75.5463719
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.97,
                    "duration": "indefinitely",
                    "model_conf": 457.81
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 71.93,
                    "duration": "indefinitely",
                    "model_conf": 168.74
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 102,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 102.08,
                    "duration": "indefinitely",
                    "model_conf": 237.04
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 175,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 175.04,
                    "duration": "indefinitely",
                    "model_conf": 361.87
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 30.61,
                    "duration": "indefinitely",
                    "model_conf": 259.4
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.2,
                    "duration": "indefinitely",
                    "model_conf": 173.34
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.725,
        "coming_soon": false
    },
    "Elsmere": {
        "name": "Dogtopia: Elsmere",
        "address": "319 New Road, Elsmere, Delaware 19805",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "((30) ) 4-4-05",
        "email": "elsmere@dogtopia.com",
        "url": "https://www.dogtopia.com/elsmere",
        "geolocation": [
            39.7403841,
            -75.58878249999998
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 38,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.05,
                    "duration": "indefinitely",
                    "model_conf": 462.78
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 72,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 72.73,
                    "duration": "indefinitely",
                    "model_conf": 169.27
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 102,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 103.4,
                    "duration": "indefinitely",
                    "model_conf": 237.82
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 175,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 175.24,
                    "duration": "indefinitely",
                    "model_conf": 364.74
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.74,
                    "duration": "3 Months",
                    "model_conf": 260.11
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.77,
                    "duration": "5 Months",
                    "model_conf": 173.14
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.725,
        "coming_soon": false
    },
    "North Bethesda": {
        "name": "Dogtopia: North Bethesda",
        "address": "4920 Wyaconda Road, North Bethesda, Maryland 20852",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "((24) ) 3-9-51",
        "email": "whiteflint@dogtopia.com",
        "url": "https://www.dogtopia.com/north-bethesda-white-flint",
        "geolocation": [
            39.043823,
            -77.09833100000003
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.46,
                    "duration": "indefinitely",
                    "model_conf": 201.79
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 34.29,
                    "duration": "indefinitely",
                    "model_conf": 386.21
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 78.52,
                    "duration": "indefinitely",
                    "model_conf": 159.1
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 109.47,
                    "duration": "indefinitely",
                    "model_conf": 207.54
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 190.37,
                    "duration": "indefinitely",
                    "model_conf": 271.02
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 27.81,
                    "duration": "indefinitely",
                    "model_conf": 185.72
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.44,
                    "duration": "indefinitely",
                    "model_conf": 176.32
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.66,
                    "duration": "indefinitely",
                    "model_conf": 95.5
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 8.65,
                    "duration": "indefinitely",
                    "model_conf": 141.03
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.075,
        "coming_soon": false
    },
    "Old Bridge": {
        "name": "Dogtopia: Old Bridge",
        "address": "7 Jocama Blvd, Old Bridge, New Jersey 08857",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "((73) ) 3-9-34",
        "email": "oldbridge@dogtopia.com",
        "url": "https://www.dogtopia.com/old-bridge",
        "geolocation": [
            40.3767841,
            -74.30562509999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 46.66,
                    "duration": "indefinitely",
                    "model_conf": 203.13
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 53.03,
                    "duration": "indefinitely",
                    "model_conf": 465.23
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 73.07,
                    "duration": "indefinitely",
                    "model_conf": 165.47
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 103.96,
                    "duration": "indefinitely",
                    "model_conf": 238.9
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 175.64,
                    "duration": "indefinitely",
                    "model_conf": 350.98
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 36.3,
                    "duration": "indefinitely",
                    "model_conf": 257.91
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 5,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.11,
                    "duration": "indefinitely",
                    "model_conf": 181.73
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 12.2,
                    "duration": "indefinitely",
                    "model_conf": 156.07
                }
            }
        },
        "brand": "Dogtopia",
        "index": 98.125,
        "coming_soon": false
    },
    "North Raleigh": {
        "name": "Dogtopia: North Raleigh",
        "address": "4708 Hargrove Road, Raleigh, North Carolina 27616",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "((91) ) 7-6-05",
        "email": "raleigh@dogtopia.com",
        "url": "https://www.dogtopia.com/north-raleigh",
        "geolocation": [
            35.84995749999999,
            -78.59025869999999
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 38,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 37.39,
                    "duration": "6 Months",
                    "model_conf": 201.03
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 35,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 35.31,
                    "duration": "indefinitely",
                    "model_conf": 418.61
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 66,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 65.55,
                    "duration": "2 Months",
                    "model_conf": 146.6
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 93,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 91.67,
                    "duration": "5 Months",
                    "model_conf": 220.9
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 150,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 150.0,
                    "duration": "indefinitely",
                    "model_conf": 358.18
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.22,
                    "duration": "10 Months",
                    "model_conf": 191.18
                }
            }
        },
        "brand": "Dogtopia",
        "index": 95.05,
        "coming_soon": false
    },
    "Chester Springs": {
        "name": "Dogtopia: Chester Springs",
        "address": "50 Senn Drive, Chester Springs, Pennsylvania 19425",
        "hours": {
            "Monday": "7:00 AM - 7:00 PM",
            "Tuesday": "7:00 AM - 7:00 PM",
            "Wednesday": "7:00 AM - 7:00 PM",
            "Thursday": "7:00 AM - 7:00 PM",
            "Friday": "7:00 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "((48) ) 2-2-73",
        "email": "chestersprings@dogtopia.com",
        "url": "https://www.dogtopia.com/chester-springs",
        "geolocation": [
            40.07496220000001,
            -75.677975
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 44.08,
                    "duration": "7 Months",
                    "model_conf": 203.5
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 38.9,
                    "duration": "10 Months",
                    "model_conf": 231.05
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 75.93,
                    "duration": "indefinitely",
                    "model_conf": 137.13
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 107.53,
                    "duration": "1 Months",
                    "model_conf": 243.02
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 185.73,
                    "duration": "indefinitely",
                    "model_conf": 370.78
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 29.69,
                    "duration": "4 Months",
                    "model_conf": 190.41
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 45,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 46.91,
                    "duration": "indefinitely",
                    "model_conf": 566.35
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 9.75,
                    "duration": "9 Months",
                    "model_conf": 159.74
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 14.34,
                    "duration": "indefinitely",
                    "model_conf": 173.92
                }
            }
        },
        "brand": "Dogtopia",
        "index": 100.3,
        "coming_soon": false
    },
    "Manassas": {
        "name": "Dogtopia: Manassas",
        "address": "10352 Balls Ford Road, Manassas, Virginia 20109",
        "hours": {
            "Monday": "5:30 AM - 7:00 PM",
            "Tuesday": "5:30 AM - 7:00 PM",
            "Wednesday": "5:30 AM - 7:00 PM",
            "Thursday": "5:30 AM - 7:00 PM",
            "Friday": "5:30 AM - 7:00 PM",
            "Saturday": "7:00 AM - 7:00 PM"
        },
        "phone": "((70) ) 2-8-21",
        "email": "manassas@dogtopia.com",
        "url": "https://www.dogtopia.com/manassas",
        "geolocation": [
            38.8025359,
            -77.50677129999997
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 45,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 45.08,
                    "duration": "indefinitely",
                    "model_conf": 202.24
                }
            },
            "DAYCARE (1 Day a Week)": {
                "cost": 40.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 43.83,
                    "duration": "indefinitely",
                    "model_conf": 472.06
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 76.5,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 75.67,
                    "duration": "4 Months",
                    "model_conf": 155.98
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 108,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 106.05,
                    "duration": "7 Months",
                    "model_conf": 225.5
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 185,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 183.59,
                    "duration": "2 Months",
                    "model_conf": 367.99
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 30,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 30.03,
                    "duration": "indefinitely",
                    "model_conf": 190.11
                }
            }
        },
        "brand": "Dogtopia",
        "index": 102.25,
        "coming_soon": false
    },
    "Alexandria": {
        "name": "Dogtopia: Alexandria",
        "address": "3121 Colvin Street, Alexandria, Virginia 22314",
        "hours": {
            "Monday": "6:00 AM - 7:00 PM",
            "Tuesday": "6:00 AM - 7:00 PM",
            "Wednesday": "6:00 AM - 7:00 PM",
            "Thursday": "6:00 AM - 7:00 PM",
            "Friday": "6:00 AM - 7:00 PM",
            "Saturday": "8:00 AM - 5:00 PM"
        },
        "phone": "(703) 278-2110",
        "email": "alexandria@dogtopia.com",
        "url": "https://www.dogtopia.com/alexandria",
        "geolocation": [
            38.8072351,
            -77.0846381
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day a Week)": {
                "cost": 41,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 44.5,
                    "duration": "indefinitely",
                    "model_conf": 311.39
                }
            },
            "DAYCARE (2 Days a Week)": {
                "cost": 74,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 73.34,
                    "duration": "3 Months",
                    "model_conf": 141.51
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 102,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 100.91,
                    "duration": "4 Months",
                    "model_conf": 191.44
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 136,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 133.68,
                    "duration": "6 Months",
                    "model_conf": 241.83
                }
            },
            "DOG BOARDING (Standard Overnight)": {
                "cost": 75,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 76.34,
                    "duration": "indefinitely",
                    "model_conf": 208.06
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 65,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 65.25,
                    "duration": "indefinitely",
                    "model_conf": 185.61
                }
            },
            "SPA & GROOMING (Ear Cleaning)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 11.16,
                    "duration": "indefinitely",
                    "model_conf": 148.39
                }
            },
            "SPA & GROOMING (Teeth Brushing)": {
                "cost": 10,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 10.23,
                    "duration": "indefinitely",
                    "model_conf": 96.3
                }
            },
            "SPA & GROOMING (Nail Trim)": {
                "cost": 15,
                "per": "session",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 15.95,
                    "duration": "indefinitely",
                    "model_conf": 131.93
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.5,
        "coming_soon": false
    },
    "Dulles": {
        "name": "Dogtopia: Dulles",
        "address": "3850 Dulles South Court, Suite D, Chantilly, Virginia 20151",
        "hours": {
            "Monday": "6:00 AM - 7:00 PM",
            "Tuesday": "6:00 AM - 7:00 PM",
            "Wednesday": "6:00 AM - 7:00 PM",
            "Thursday": "6:00 AM - 7:00 PM",
            "Friday": "6:00 AM - 7:00 PM",
            "Saturday": "10:00 AM - 5:00 PM"
        },
        "phone": "((70) ) 2-8-20",
        "email": "dulles@dogtopia.com",
        "url": "https://www.dogtopia.com/chantilly-dulles",
        "geolocation": [
            38.9129588,
            -77.47046610000001
        ],
        "services": {},
        "memberships": {
            "DAYCARE (1 Day Pass)": {
                "cost": 42,
                "per": "purchase",
                "term": "",
                "description": "Passes must be used within 90 days of purchase.",
                "suggested": {
                    "cost": 42.7,
                    "duration": "indefinitely",
                    "model_conf": 202.84
                }
            },
            "DAYCARE (3 Days a Week)": {
                "cost": 99,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 100.19,
                    "duration": "indefinitely",
                    "model_conf": 202.02
                }
            },
            "DAYCARE (Unlimited Days a Week)": {
                "cost": 150,
                "per": "week",
                "term": "1-week",
                "description": "",
                "suggested": {
                    "cost": 150.93,
                    "duration": "indefinitely",
                    "model_conf": 333.88
                }
            },
            "DOG BOARDING (Standard Overnight - Enrolled)": {
                "cost": 60,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 59.63,
                    "duration": "2 Months",
                    "model_conf": 183.26
                }
            },
            "DOG BOARDING (Suite Overnight)": {
                "cost": 70,
                "per": "night",
                "term": "",
                "description": "",
                "suggested": {
                    "cost": 72.28,
                    "duration": "indefinitely",
                    "model_conf": 530.02
                }
            }
        },
        "brand": "Dogtopia",
        "index": 101.65,
        "coming_soon": false
    }
}