import { parseEnv } from '@plotr/common-utils';
import axios, { AxiosError } from 'axios';

const env = parseEnv({
  API_V1: process.env.API_V1,
  API_V2: process.env.API_V2,
});

export async function checkPermission(
  accessToken: string,
  permission: string,
  setPermissionStore: (permission: string, value: boolean) => void
): Promise<boolean> {
  try {
    const { data } = await axios.get<{ hasPermission: boolean }>(
      `${env.API_V2}/check_permission/${permission}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    setPermissionStore(permission, data.hasPermission);

    return data.hasPermission;
  } catch (error) {
    console.error(
      'There was a problem with the fetch operation:',
      error instanceof Error || error instanceof AxiosError
        ? error.message
        : error
    );
    return false;
  }
}
