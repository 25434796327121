import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMemo } from 'react';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import useDynamicMapStore from '../dynamic-map/hooks/useDynamicMapStore';
import { ReportItem, useReportMatrixes } from './services/pulse-service';

const ReportRow = ({
  report,
  index,
  isSelected,
  driveTime,
  driveDistance,
}: {
  report: ReportItem;
  index: number;
  isSelected: boolean;
  driveTime: number | null;
  driveDistance: number | null;
}) => {
  return (
    <TableRow hover>
      <TableCell
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: isSelected ? '#ff9900' : '#C3C3C3',
        }}
        width={8}
      >
        {index + 1}
      </TableCell>
      <TableCell>{report.name}</TableCell>
      <TableCell>{report.address}</TableCell>
      <TableCell>{driveTime ? Math.round(driveTime) : 'N/A'}</TableCell>
      <TableCell>{driveDistance ? driveDistance.toFixed(2) : 'N/A'}</TableCell>
    </TableRow>
  );
};

export const ReportTable = ({
  reports,
  selectedPinId = -1,
  isGroupConfigured = true,
  groupName = 'competitors',
}: {
  reports: ReportItem[];
  selectedPinId: number;
  isGroupConfigured?: boolean;
  groupName?: string;
}) => {
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const pins = useCustomPins();
  const evaluatedPin = useMemo(
    () => pins.find((pin) => pin.id === evaluatedPinId),
    [pins, evaluatedPinId]
  );

  const { data: matrixes } = useReportMatrixes(
    reports,
    evaluatedPin?.pos ?? null
  );

  const reportsWithDriveTime = useMemo(() => {
    return reports.map((report, index) => ({
      ...report,
      driveTime: matrixes
        ? matrixes[0]?.durations?.[0]?.[index + 1] / 60
        : null,
      driveDistance: matrixes
        ? matrixes[0]?.distances?.[0]?.[index + 1] * 0.000621371
        : null,
    }));
  }, [reports, matrixes]);

  return (
    <Box
      sx={{
        minHeight: '100px', // Set a minimum height for the table container
        maxHeight: '400px', // Set a maximum height for the table container
        overflow: 'auto', // Enable scrolling for the table container
      }}
    >
      <Table stickyHeader data-section="table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 0 }} />
            <TableCell>Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Drive Time Minutes</TableCell>
            <TableCell>Radial Miles</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportsWithDriveTime.length > 0 ? (
            reportsWithDriveTime.map((report, index) => (
              <ReportRow
                key={report.id + index}
                report={report}
                index={index}
                isSelected={selectedPinId === index}
                driveTime={report.driveTime}
                driveDistance={report.driveDistance}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <Typography variant="body1" textAlign="center">
                  {isGroupConfigured
                    ? 'No reports found for this point'
                    : `No ${groupName} grouping found. This insight requires a ${groupName} points of interest grouping`}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
