import { parseEnv } from '@plotr/common-utils';
import axios from 'axios';

const env = parseEnv({
  API_V2: process.env.API_V2,
});

const axiosInstance = axios.create({
  baseURL: `${env.API_V2}/one-schema-provider`,
});

type CreateEmbedSessionResponse = {
  id: number;
  token: string;
  status: string;
  user_jwt: string;
};

export const createEmbedSession = async (templateKey: string) => {
  try {
    const response = await axiosInstance.post<CreateEmbedSessionResponse>(
      `/create-embed-session`,
      {
        templateKey,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const uploadFile = async (file: File, embedId: number) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axiosInstance.post(
      `/upload-file/${embedId}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const uploadFileString = async (
  content: string[][],
  embedId: number
) => {
  try {
    const response = await axiosInstance.post(
      `/upload-file/${embedId}/string`,
      {
        content,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
