import { parseEnv } from '@plotr/common-utils/src';
import axios from 'axios';
import { useQuery } from 'react-query';

const { API_V2: API_V2_URL } = parseEnv({
  API_V2: process.env.API_V2,
});

export const PSYCHOTYPIC_TYPE_DETAILS_QUERY_KEY = 'psychographicTypeDetails';

export interface PsychographicTypeDetails {
  id: number;
  imageUrl: string;
  code: string;
  ownColor: string;
  parentGroup: string;
  parentColor: string;
  name: string;
  description: string;
  age: number;
  income: number;
  education: number;
  subscriptions: string;
  urbanicity: string;
  technology: string;
  politicalGroup: string;
  politicalContribution: string;
  donations: string;
  banking: string;
  television: string;
  commonVehicles: string;
  shopping: string;
  spendingPatterns: string;
  commonRestaurants: string;
  airlines: string;
  hotels: string;
}

const getPsychographicTypeDetails = async (
  psychographicTypeId: number | null
) => {
  if (!psychographicTypeId) return null;

  const { data } = await axios.get<PsychographicTypeDetails>(
    `${API_V2_URL}/lifestyle/psychographic-type-details/${psychographicTypeId}`
  );
  return data;
};

export const usePsychographicTypeDetails = (
  psychographicTypeId: number | null
) => {
  return useQuery({
    queryKey: [PSYCHOTYPIC_TYPE_DETAILS_QUERY_KEY, psychographicTypeId],
    queryFn: () => getPsychographicTypeDetails(psychographicTypeId),
    enabled: !!psychographicTypeId,
  });
};
