import {
  CustomPin,
  plotrMultiplayerData,
  supportedCountries,
} from '@plotr/plotr-multiplayer-data/src';
import { point } from '@turf/helpers';
import axios from 'axios';
import { Feature, Point } from 'geojson';
import { useEffect } from 'react';
import { getPointLocation } from '~/src/common/helpers/getPointPlace';
import usePrevious from '~/src/common/hooks/usePrevious';
import useAccessToken from '~/src/global/hooks/useAccessToken';
import useCustomPins from '~/src/global/hooks/useCustomPins';
import useSettingsStore from '~/src/global/hooks/useSettingsStore';
import useCustomTerritories from '../../dynamic-map/hooks/useCustomTerritories';
import useDynamicMapStore from '../../dynamic-map/hooks/useDynamicMapStore';
import useDemographicStore from '../hooks/useDemographicStore';
import demographicSearch from '../services/demographicSearch';
import getPointBoundary, {
  PointBoundaryType,
} from '../services/getPointBoundary';
import getTerritoryBoundary from '../services/getTerritoryBoundary';

export default function useDemographicSearch() {
  const boundaryType = useDemographicStore((state) => state.boundaryType);
  const radius = useDemographicStore((state) => state.demographicSearchRadius);
  const boundaryData = useDemographicStore((state) => state.boundaryData);
  const setBoundaryData = useDemographicStore((state) => state.setBoundaryData);
  const setSummaryData = useDemographicStore(
    (state) => state.setDemographicSummaryData
  );
  const setIsDemographicDataLoading = useDemographicStore(
    (state) => state.setIsDemographicDataLoading
  );
  const { accessToken } = useAccessToken();
  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );

  const customPinMethods = plotrMultiplayerData.methods?.pins;
  const evaluatedPinId = useDynamicMapStore((state) => state.evaluatedPinId);
  const customTerritories = useCustomTerritories();
  const customPins = useCustomPins();
  const selectedPin =
    evaluatedDemographicEntity?.type === 'point'
      ? customPins.find((p) => p.id === evaluatedPinId)
      : null;

  const userSettings = useSettingsStore((state) => state.userSettings);

  const isPoint = evaluatedDemographicEntity?.type === 'point';
  const isTerritory = evaluatedDemographicEntity?.type === 'territory';

  const pointPos = isPoint ? evaluatedDemographicEntity.pos : null;
  const territory = isTerritory
    ? customTerritories.find((t) => t.id === evaluatedDemographicEntity.id)
    : null;
  const previousTerritory = usePrevious(territory);

  const checkPinForCountryAndSetBoundary = async ({
    pointFeature,
    boundaryType,
    radius,
    pin,
    accessToken,
  }: {
    pointFeature: Feature<Point>;
    boundaryType: PointBoundaryType;
    radius: number;
    pin: CustomPin | null;
    accessToken: string;
  }) => {
    if (isTerritory && Object.keys(territory?.boundaries ?? {}).length < 1)
      return;

    if (pin && !pin.country) {
      const location = await getPointLocation(pin.pos.lng, pin.pos.lat);
      customPinMethods?.changePinCountry(pin.id, location.country.short_code);

      const boundary = await getPointBoundary({
        pointFeature,
        boundaryType,
        radius,
        pin: selectedPin
          ? {
              ...selectedPin,
              country: location.country.short_code as supportedCountries,
            }
          : null,
        accessToken,
      });

      setBoundaryData(boundary);
      return;
    }

    const boundary = await getPointBoundary({
      pointFeature,
      boundaryType,
      radius,
      pin: selectedPin ?? null,
      accessToken,
    });

    setBoundaryData(boundary);
  };

  // Set boundary data for demographic point search
  useEffect(() => {
    if (
      radius == null ||
      pointPos == null ||
      (isTerritory && Object.keys(territory?.boundaries ?? {}).length < 1)
    )
      return;
    // Ensure pointPos is defined and contains valid numbers
    if (
      pointPos &&
      typeof pointPos.lng === 'number' &&
      typeof pointPos.lat === 'number'
    ) {
      setBoundaryData(null);
      setIsDemographicDataLoading(true);
      checkPinForCountryAndSetBoundary({
        pointFeature: point([pointPos.lng, pointPos.lat]),
        boundaryType: boundaryType,
        radius: radius,
        pin: selectedPin ?? null,
        accessToken: accessToken ?? '',
      });
    } else {
      console.error('Invalid pointPos:', pointPos);
      setBoundaryData(null);
    }
  }, [
    pointPos,
    radius,
    boundaryType,
    setBoundaryData,
    setIsDemographicDataLoading,
  ]);

  // Set boundary data for demographic territory search
  useEffect(() => {
    if (
      territory == null ||
      accessToken == null ||
      previousTerritory?.id === territory.id ||
      (isTerritory && Object.keys(territory?.boundaries ?? {}).length < 1)
    )
      return;

    setBoundaryData(null);
    setIsDemographicDataLoading(true);
    getTerritoryBoundary({
      accessToken,
      territory,
    }).then((boundary) => {
      setBoundaryData(boundary);
    });
  }, [
    accessToken,
    territory,
    previousTerritory,
    setBoundaryData,
    setIsDemographicDataLoading,
  ]);

  // Fetch demographic search data (avoid race conditions with abort controllers)
  useEffect(() => {
    if (
      boundaryData == null ||
      accessToken == null ||
      (isTerritory && Object.keys(territory?.boundaries ?? {}).length < 1)
    )
      return;

    const abortController = new AbortController();
    demographicSearch({
      accessToken,
      feature: boundaryData,
      radius,
      userSettings,
      abortSignal: abortController.signal,
    })
      .then((data) => {
        setIsDemographicDataLoading(false);
        setSummaryData(data);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          throw err;
        }
      });

    return () => {
      abortController.abort();
    };
  }, [
    boundaryData,
    accessToken,
    radius,
    setIsDemographicDataLoading,
    setSummaryData,
    userSettings,
  ]);
}
