import mapboxgl from 'mapbox-gl';
import { makeReportPinElement } from './makeReportPinElement';
import html2canvas from 'html2canvas';
import { MapRef } from 'react-map-gl';
import { InitialViewState } from '../types/initialViewState';

interface GetMapImageProps {
  section: HTMLElement;
  map: MapRef | null;
  mapInitialViewState?: InitialViewState;
  mapboxApiKey: string;
  mapPins?: JSX.Element[];
  height?: string;
  isFullMap: boolean;
}

export async function getMapImage({
  section,
  map,
  mapInitialViewState,
  mapboxApiKey,
  mapPins,
  height = '1080px',
  isFullMap,
}: GetMapImageProps) {
  if (!map) {
    throw new Error('Map instance is required');
  }

  section.style.position = 'absolute';
  section.style.left = '-9999px';

  const mapContainer = section.querySelector('.mapboxgl-map') as HTMLElement;
  mapContainer.style.maxHeight = 'none';
  mapContainer.style.height = isFullMap ? height : '35rem';

  const mapParent = mapContainer.parentElement as HTMLElement;
  mapParent.style.minHeight = isFullMap ? height : '35rem';
  mapParent.style.maxHeight = isFullMap ? height : '35rem';
  mapParent.style.width = '1920px';

  document.body.appendChild(section);

  const clonedMap = new mapboxgl.Map({
    container: mapContainer,
    style: map.getStyle(),
    interactive: false,
    accessToken: mapboxApiKey,
    preserveDrawingBuffer: true,
    ...mapInitialViewState,
  });

  await new Promise((resolve) => clonedMap.on('render', resolve));

  const sources = map.getStyle().sources;
  for (const [sourceId, source] of Object.entries(sources)) {
    if (!clonedMap.getSource(sourceId)) {
      clonedMap.addSource(sourceId, source);
    }
  }

  map.getStyle().layers.forEach((layer) => {
    if (!clonedMap.getLayer(layer.id)) {
      clonedMap.addLayer(layer);
    }
  });

  mapPins?.forEach((marker) => {
    const {
      pos: { lat, lng },
      ...props
    } = marker.props;

    const markerEl = makeReportPinElement(props);

    new mapboxgl.Marker({
      element: markerEl,
      anchor: 'bottom',
    })
      .setLngLat([lng, lat])
      .addTo(clonedMap);
  });

  await new Promise((resolve) => clonedMap.on('idle', resolve));

  const mapCanvas = await html2canvas(mapContainer, {
    scale: 2,
    useCORS: true,
    allowTaint: false,
    logging: false,
  });

  const imageUrl = mapCanvas.toDataURL('image/png');

  mapContainer.innerHTML = `<img src="${imageUrl}" style="width: 100%; height: 100%" />`;

  document.body.removeChild(section);

  return imageUrl;
}
