import { Layer, Source } from 'react-map-gl';
import useDynamicMapStore from '~/src/features/dynamic-map/hooks/useDynamicMapStore';
import useMapImage from '~/src/features/dynamic-map/hooks/useMapImage';
import { defaultPOILayer, increasedIconSize } from '../POIHelpers';

interface POIPulseSourceLayerProps {
  brandId: string;
  brandPinImage: string;
  brandName: string;
}

const POIPulseSourceLayer = ({
  brandId,
  brandPinImage,
  brandName,
}: POIPulseSourceLayerProps) => {
  const evaluatedDemographicEntity = useDynamicMapStore(
    (state) => state.evaluatedDemographicEntity
  );

  const evaluatedDemographicPoint =
    evaluatedDemographicEntity?.type === 'point'
      ? evaluatedDemographicEntity.pos
      : null;

  const poisEnabled = useDynamicMapStore((state) => state.poisEnabled);
  const feature = useDynamicMapStore((state) => state.clickedPOiFeature);

  const isPinReady = useMapImage({
    id: `${brandId}-pin`,
    src: brandPinImage,
  });

  const poiLabelsEnabled = useDynamicMapStore(
    (state) => state.poiLabelsEnabled
  );

  return (
    <>
      {evaluatedDemographicPoint && feature && feature.properties && (
        <Source
          id={`pulse-source`}
          type="geojson"
          data={{
            type: 'Point',
            coordinates: [
              evaluatedDemographicPoint.lng,
              evaluatedDemographicPoint.lat,
            ],
          }}
        >
          <Layer
            type="symbol"
            id={`poi-pulse`}
            source={`pulse-source`}
            layout={{
              ...defaultPOILayer.layout,
              ...(isPinReady && brandId
                ? { 'icon-image': `${brandId}-pin` }
                : {}),
              visibility: evaluatedDemographicPoint ? 'visible' : 'none',
              'icon-size': increasedIconSize,
              'text-field': poiLabelsEnabled ? feature.properties.name : '',
              'text-offset': [0, 1.5],
              'text-anchor': 'top',
            }}
            paint={{
              'text-color': 'black',
              'text-halo-color': 'white',
              'text-halo-width': 1,
            }}
            metadata={{
              brandId: brandId,
              brandName: brandName,
            }}
          />
        </Source>
      )}
    </>
  );
};

export default POIPulseSourceLayer;
